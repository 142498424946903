import { Container } from '@mui/system'
import { usePlanningStore } from '@planning/AppProvider'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { VisitsHeader } from './Components/VisitsHeader'
import { VisitsTable } from './Components/VisitsTable'

export const VisitsPage = observer(() => {
  const { visitListViewStore, visitTableViewStore } = usePlanningStore()

  useEffect(() => {
    visitListViewStore.loadCurrentPage()
  }, [visitListViewStore])

  return (
    <>
      <VisitsHeader store={visitListViewStore} />
      <Container sx={{ paddingY: 2 }}>
        <VisitsTable store={visitListViewStore} tableStore={visitTableViewStore} />
      </Container>
    </>
  )
})
