import { InputBase, SxProps, TextField, TextFieldProps } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import get from 'lodash/get'
import { Controller, FieldValues, FormState, UseControllerProps } from 'react-hook-form'

type FieldProps = Pick<
  TextFieldProps,
  | 'label'
  | 'disabled'
  | 'fullWidth'
  | 'variant'
  | 'multiline'
  | 'maxRows'
  | 'minRows'
  | 'autoFocus'
  | 'margin'
  | 'size'
  | 'placeholder'
>

interface Props<T extends FieldValues> extends UseControllerProps<T>, FieldProps {
  type?: string
  uppercase?: boolean
  startAdornment?: React.ReactNode
  endAdornment?: React.ReactNode
  formState?: FormState<T>
  inputType?: 'base' | 'text-field'
  fontColor?: string
  helperText?: string
  maxLength?: number
  required?: boolean
  sx?: SxProps
}

export const ControlledTextField = <T extends FieldValues>({
  name,
  control,
  label,
  fullWidth,
  disabled,
  variant,
  maxRows,
  minRows,
  multiline,
  type,
  uppercase,
  endAdornment,
  startAdornment,
  autoFocus,
  size,
  margin,
  placeholder,
  formState,
  inputType = 'text-field',
  fontColor,
  helperText,
  maxLength,
  required,
  sx,
}: Props<T>) => {
  const { t } = useTranslate()

  const errors = formState?.errors
  const fieldError = get(errors, name)

  const inputProps = {
    required,
    error: !!fieldError,
    size: size ?? 'medium',
    multiline,
    type,
    placeholder,
    maxRows,
    minRows,
    fullWidth,
    autoFocus,
    disabled,
    sx,
  }

  return (
    <Controller
      render={({ field }) =>
        inputType === 'text-field' ? (
          <TextField
            {...inputProps}
            {...field}
            value={field.value ?? ''}
            helperText={fieldError ? t(fieldError.message as string) : helperText}
            label={label}
            margin={margin}
            variant={variant ?? 'outlined'}
            inputProps={{
              maxLength,
              style: { textTransform: uppercase ? 'uppercase' : 'none', color: fontColor ?? '' },
            }}
            InputProps={{
              endAdornment,
              startAdornment,
            }}
          />
        ) : (
          <InputBase
            {...inputProps}
            {...field}
            value={field.value ?? ''}
            inputProps={{
              style: { textTransform: uppercase ? 'uppercase' : 'none' },
            }}
            endAdornment={endAdornment}
            startAdornment={startAdornment}
          />
        )
      }
      name={name}
      control={control}
    />
  )
}
