import { ContainerStatus } from '@storage/app/api'
import { SelectOption } from '@storage/app/models'
import { EnumAsSelectOptionsUtil } from '@storage/utils/enums'
import { useMemo } from 'react'

const KEY_PREFIX = 'containerStatus'

export const useLocationStatusOptions = (): SelectOption[] => {
  return useMemo(
    () =>
      EnumAsSelectOptionsUtil.transform(ContainerStatus, KEY_PREFIX).map(option => ({
        ...option,
        label: option.label,
      })),
    [],
  )
}
