export interface JobFormProfile {
  cargoUnit: any
  origin?: string
  isToService?: boolean
  destinationService?: any
  yardBlock?: any
  hasToSelectEquipment?: boolean
  equipment?: any
  deadline?: Date | null
}

export const defaultValues: JobFormProfile = {
  cargoUnit: null,
  origin: '',
  isToService: true,
  destinationService: null,
  yardBlock: null,
  hasToSelectEquipment: true,
  equipment: null,
  deadline: null,
}
