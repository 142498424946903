import {
  Box,
  Card,
  CardContent,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Typography,
  styled,
} from '@mui/material'
import { EquipmentType } from '@operations/app/api'
import { useTranslate } from '@tolgee/react'
import { ChevronDownIcon, ChevronUpIcon, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Droppable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd'
import { DraggableEquipmentCard } from '../components/DragableEquipmentCard/DragableEquipmentCard'
import { EquipmentPlanningEquipmentsUIStore } from '../stores/equipment-planning-equipments-ui-store'

interface Props {
  uiStore: EquipmentPlanningEquipmentsUIStore
  workAreaId?: number
}

const AvailableEquipmentCard = styled(Card)(() => ({
  height: '100%',
  zIndex: 'unset',
  overflow: 'scroll',
  msOverflowStyle: 'none' /* Internet Explorer 10+ */,
  scrollbarWidth: 'none' /* Firefox */,
  '&::-webkit-scrollbar': { display: 'none' } /* Safari and Chrome */,
}))

export const EquipmentPlanningAvailableEquipmentsContainer = observer(({ uiStore }: Props) => {
  const theme = useMinimalsTheme()
  let draggableItemIndex = 0
  const getDraggableItemIndex = () => {
    draggableItemIndex = draggableItemIndex + 1
    return draggableItemIndex
  }

  const [equipmentTypes, setEquipmentTypes] = useState<{ type: EquipmentType; open: boolean }[]>([])
  const { t } = useTranslate()
  const handleEquipmentCollapse = (index: number) => {
    setEquipmentTypes(
      equipmentTypes.map((equipmentType, i) => {
        return {
          ...equipmentType,
          open: index === i ? !equipmentType.open : equipmentType.open,
        }
      }),
    )
  }

  useEffect(() => {
    const types: EquipmentType[] = []
    uiStore.availableEquipments
      ?.map(eq => eq.equipmentType)
      ?.forEach(type => {
        if (
          type !== EquipmentType.Sts &&
          type !== EquipmentType.Rtg &&
          type !== EquipmentType.Rmg &&
          !types.includes(type)
        ) {
          types.push(type)
        }
      })

    setEquipmentTypes(types.map(type => ({ type: type, open: false })))
  }, [uiStore.availableEquipments, setEquipmentTypes])

  return (
    <AvailableEquipmentCard>
      <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <Typography variant='h2'>{t('listOfEquipments')}</Typography>
        <Typography variant='body1'>{t('selectAndDragEquipments')}</Typography>
        <List sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          {equipmentTypes.map((equipmentType, index) => (
            <Paper elevation={4} key={equipmentType.type}>
              <ListItemButton onClick={() => handleEquipmentCollapse(index)}>
                <ListItemText primary={t(equipmentType.type)} />
                {equipmentType.open ? (
                  <ChevronUpIcon />
                ) : (
                  <ChevronDownIcon data-cy={`open-${equipmentType.type}-lists`} />
                )}
              </ListItemButton>
              <Collapse in={equipmentType.open} timeout='auto' unmountOnExit>
                <Droppable
                  type='Equipment'
                  droppableId={equipmentType.type + '_0'}
                  isDropDisabled={uiStore.isDndDisabled}
                >
                  {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '1rem',
                        flexDirection: 'column',
                        padding: '1rem',
                        backgroundColor: snapshot.isDraggingOver
                          ? theme.palette.grey[500]
                          : 'transparent',
                      }}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {uiStore.availableEquipments
                        ?.filter(e => e.equipmentType === equipmentType.type)
                        .map(e => (
                          <DraggableEquipmentCard
                            dataCy={`draggable-equipment-card-${e.name}`}
                            key={e.id}
                            draggableId={'EQ_' + e.id.toString()}
                            index={getDraggableItemIndex()}
                            equipment={e}
                            isInOperation={uiStore.getIsEquipmentInOperation(e.id)}
                            isDragDisabled={uiStore.isDndDisabled}
                            isAvailable={true}
                            assignOptions={uiStore.workAreasEquipments.map(wa => ({
                              label: wa.name ?? 'Unamed STS',
                              onClick: () => uiStore.assignEquipment(e.id, wa.workAreaId, null),
                              testId: `assign-equipment-menu-${wa.name ?? 'Unamed STS'}-option`,
                            }))}
                          />
                        ))}
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              </Collapse>
            </Paper>
          ))}
        </List>
      </CardContent>
    </AvailableEquipmentCard>
  )
})
