import { Box } from '@mui/material'
import { CustomAlert } from '@planning/pages/TallymanV2/Components'
import { FC } from 'react'

export const ErrorBox: FC<{ message: string }> = ({ message }) => {
  return (
    <Box m='1rem 0'>
      <CustomAlert severity='error' message={message} />
    </Box>
  )
}
