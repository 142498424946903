import { BooleanSelectOption } from '@storage/app/models'
import { hasValue } from '../has-value'

export const mapBooleanToBooleanOption = (value?: boolean | null): BooleanSelectOption => {
  return hasValue(value)
    ? value
      ? BooleanSelectOption.True
      : BooleanSelectOption.False
    : BooleanSelectOption.False
}
