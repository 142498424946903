import { Chip } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'

export const OrderItemChip = ({ label }: { label: React.ReactNode }) => {
  const theme = useMinimalsTheme()
  return (
    <Chip
      label={label}
      variant='filled'
      size='small'
      sx={{
        padding: 0,
        borderRadius: '2px',
        fontSize: '12px',
        fontWeight: 600,
        color: theme.palette.grey[600],
      }}
    />
  )
}
