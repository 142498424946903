import { Widgets } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { LengthMeasurementUnit, PackageDto } from '@storage/app/api'
import { useTranslate } from '@tolgee/react'

interface Props {
  data: PackageDto
}

const PackagingInfoCard = ({ data }: Props) => {
  const { t } = useTranslate()

  const extensions = () => {
    if (!data.lengthUnit) return null

    const unitKey = data.lengthUnit as keyof typeof LengthMeasurementUnit
    const unit = LengthMeasurementUnit[unitKey]

    const measures = [
      { value: data.length, label: t('length', 'Length') },
      { value: data.width, label: t('width', 'Width') },
      { value: data.height, label: t('height', 'Height') },
    ]

    const labels = measures.filter(e => !!e.value).map(e => e.label)
    const values = measures.filter(e => !!e.value).map(e => `${e.value}${t(unit.toString(), '')}`)

    if (values.length === 0) return null

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant='caption'>{labels.join(', ')} </Typography>
        <Typography variant='body2'>{values.join(' x ')}</Typography>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 2,
        p: 2,
      }}
    >
      <Widgets color='secondary' />
      <Typography variant='subtitle2'>{data.name}</Typography>
      {extensions()}
    </Box>
  )
}

export default PackagingInfoCard
