import { useBillingServiceStore } from '@billing/AppProvider'
import {
  ContainerTurnoversReportMode,
  containerTurnoversReportModes,
} from '@billing/app/models/container-turnovers-report-mode.enum'
import { Asterisk } from '@billing/components/asterisk/Asterisk'
import { ModeButton } from '@billing/components/button/ModeButton'
import CTReportFieldsForm from '@billing/features/reports/forms/ct-report-fields-form/CTReportFieldsForm'
import { Box, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'

export const CTReportFilter = observer(() => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const { containerTurnoversUIStore } = useBillingServiceStore()

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }} gap={theme.customSpacing.m} width='100%'>
      <Typography variant='subtitle2'>
        {t('containersType', 'Containers Type')}
        {Asterisk}
      </Typography>
      <Box sx={{ display: 'flex' }} gap={theme.customSpacing.m}>
        {containerTurnoversReportModes.map((reportMode: ContainerTurnoversReportMode) => (
          <ModeButton
            key={reportMode}
            title={
              reportMode === ContainerTurnoversReportMode.Standard
                ? t('standard', 'Standard')
                : reportMode === ContainerTurnoversReportMode.IMDG
                  ? t('imdg', 'IMDG')
                  : reportMode === ContainerTurnoversReportMode.DAMAGED
                    ? t('damaged', 'Damaged')
                    : ''
            }
            subtitle={
              reportMode === ContainerTurnoversReportMode.Standard
                ? t('allInYardContainers', 'All in yard containers')
                : reportMode === ContainerTurnoversReportMode.IMDG
                  ? t('onlyInYardIMDGContainers', 'Only in yard IMDG containers')
                  : reportMode === ContainerTurnoversReportMode.DAMAGED
                    ? t('onlyInYardDamagedContainers', 'Only in yard damaged containers')
                    : ''
            }
            onClick={() => containerTurnoversUIStore.setReportMode(reportMode)}
            isSelected={containerTurnoversUIStore.reportMode === reportMode}
          />
        ))}
      </Box>
      <Typography variant='subtitle2'>{t('fieldsToExport', 'Fields To Export')}</Typography>
      <CTReportFieldsForm />
    </Box>
  )
})
