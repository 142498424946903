import {
  AllocationRuleSummaryDto,
  CarrierVisitAllocationRuleDto,
  CarrierVisitDirection,
  CarrierVisitsUpdateCarrierVisitAllocationRulesRequest,
  CreateVisitAllocationRuleDto,
} from '@storage/app/api'
import { EntityStore } from '@storage/app/store/entity.store'
import { CarrierVisitsService } from '@storage/services/carrier-visits.service'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { mapCVAllocationRuleToCarrierVisitAllocationRulePropertiesDto } from '../forms/allocation-rule-templates-form/allocation-rule-templates-form.mapper'

export class CarrierVisitAllocationRulesV2Store extends EntityStore<CarrierVisitAllocationRuleDto> {
  allocationSummaries: Map<string, AllocationRuleSummaryDto> = new Map()

  constructor(private readonly _carrierVisitsService: CarrierVisitsService) {
    super()
    makeObservable(this, {
      allocationSummaries: observable,
      loadAllocationSummaries: action,
    })
  }

  async loadAll(carrierVisitId: number, carrierVisitDirection: CarrierVisitDirection) {
    const carrierVisitAllocationRules = await this._carrierVisitsService.getAllocationRules(
      carrierVisitId,
      carrierVisitDirection,
    )
    this.setAll(carrierVisitAllocationRules)

    await this.loadAllocationSummaries(carrierVisitId, carrierVisitDirection)
  }

  async loadAllocationSummaries(
    carrierVisitId: number,
    carrierVisitDirection: CarrierVisitDirection,
  ) {
    const allocationRuleIds = this.entries.map(rule => rule.id)

    if (!allocationRuleIds.length) return

    const summaries = await this._carrierVisitsService.getAllocationSummary(
      this.entries.map(carrierVisitAllocationRule =>
        mapCVAllocationRuleToCarrierVisitAllocationRulePropertiesDto(
          carrierVisitAllocationRule,
          carrierVisitId,
          carrierVisitDirection,
        ),
      ),
    )

    runInAction(() => {
      this.allocationSummaries.clear()
      summaries.forEach(summary => {
        this.allocationSummaries.set(summary.allocationRuleId, summary)
      })
    })
  }

  async saveChanges(
    carrierVisitId: number,
    carrierVisitDirection: CarrierVisitDirection,
    carrierVisitAllocationRules: CreateVisitAllocationRuleDto[],
  ) {
    await this._carrierVisitsService.createCarrierVisitAllocationAndRules({
      carrierVisitId,
      carrierVisitDirection,
      carrierVisitAllocationRules,
    })
  }

  async delete(id: string) {
    this.remove(id)
  }

  async add(carrierVisitAllocationRule: CarrierVisitAllocationRuleDto) {
    this.addOrUpdate(carrierVisitAllocationRule)
    return carrierVisitAllocationRule
  }

  async update_Deprecated(
    req: CarrierVisitsUpdateCarrierVisitAllocationRulesRequest,
  ): Promise<CarrierVisitAllocationRuleDto> {
    const allocationRule = await this._carrierVisitsService.updateCarrierVisitAllocationRules(req)
    this.addOrUpdate(allocationRule)
    return allocationRule
  }

  async delete_Deprecated(id: string) {
    await this._carrierVisitsService.deleteAllocationRules(id)
    this.remove(id)
  }

  async reorderAllocationRules(startIndex: number, endIndex: number) {
    runInAction(() => {
      const currentEntries = this.entries
      const [removed] = currentEntries.splice(startIndex, 1)
      currentEntries.splice(endIndex, 0, removed)

      this.setAll(currentEntries)
    })
  }
}
