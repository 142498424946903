import { PiersDialogContainer, PiersListContainer } from '@storage/features/berths/containers'
import { BerthsFeatureStore } from '@storage/features/berths/stores'
import { useStores } from '@storage/hooks/use-stores.hook'
import { Box, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { useEffect } from 'react'

const Piers = () => {
  const { berthStore, pierStore } = useStores()
  const { t } = useTranslate()
  const featureStore = new BerthsFeatureStore(berthStore, pierStore)

  useEffect(() => {
    const loadPageData = async () => {
      await pierStore.loadList()
    }

    loadPageData()
  }, [pierStore])

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Box textAlign='center'>
        <Typography variant='h3'>{t('piers', 'Piers')}</Typography>
      </Box>

      <PiersListContainer featureStore={featureStore} />

      <PiersDialogContainer featureStore={featureStore} />
    </Box>
  )
}

export default Piers
