import { CreateWeightClassDto, WeightClassDto, WeightClassesApi } from '@storage/app/api'

export class WeightClassService {
  constructor(private api: WeightClassesApi) {}

  async getAll(): Promise<Array<WeightClassDto>> {
    const { data: weightClasses } = await this.api.getAll()
    return weightClasses
  }

  async create(createWeightClassDto: Array<CreateWeightClassDto>): Promise<void> {
    await this.api.create({
      createWeightClassDto,
    })
  }
}
