import { createSvgIcon } from '@mui/material'

export const BulkIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2 3.25C2 2.55964 2.55964 2 3.25 2H20.75C21.4404 2 22 2.55964 22 3.25V20.75C22 21.4404 21.4404 22 20.75 22H3.25C2.55964 22 2 21.4404 2 20.75V3.25ZM20 18.7519V14.5852C20 13.8948 19.4404 13.3352 18.75 13.3352V18.7519H5.25V13.3352C4.55964 13.3352 4 13.8949 4 14.5852V18.7519C4 19.4422 4.55965 20.0019 5.25 20.0019H18.75C19.4404 20.0019 20 19.4422 20 18.7519ZM7.27675 13.3547C7.12391 13.3547 7 13.8025 7 14.3547C7 14.907 7.12391 15.3547 7.27675 15.3547L16.7232 15.3547C16.8761 15.3547 17 14.907 17 14.3547C17 13.8025 16.8761 13.3547 16.7232 13.3547L7.27675 13.3547Z'
      fill='currentColor'
    />
  </svg>,
  'BulkIcon',
)
