import {
  OperationType,
  OperatorFields,
  WeightMeasurementUnit,
  WorkInstructionJobDto,
} from '@operations/app/api'
import { convertOperationType, getPackageName } from '@operations/utils/helper'
import { useTranslate } from '@tolgee/react'
import { Tile } from '@tom-ui/ui'
import { DescriptionContainer } from './DescriptionContainer'

interface Props {
  job: WorkInstructionJobDto
  stepper?: boolean
  fieldsToShow?: OperatorFields
}
export const WorkInstructionJobDescription = ({
  job: workInstruction,
  stepper,
  fieldsToShow,
}: Props) => {
  const { t } = useTranslate()

  const checkFieldsToShow = (operatorField: OperatorFields) => {
    return !fieldsToShow || fieldsToShow.includes(operatorField)
  }

  const dashIfNull = (value?: string | null) => value ?? ' - '

  //TODO: this is copy from modules/planning/src/app/utils.ts
  const simplifyWeightMeasurementUnit = (unit?: WeightMeasurementUnit | null) => {
    switch (unit) {
      case WeightMeasurementUnit.Kilogram:
        return 'kg'
      case WeightMeasurementUnit.Ton:
        return 't'
      default:
        return ''
    }
  }

  return (
    <DescriptionContainer isForStepper={stepper}>
      {checkFieldsToShow(OperatorFields.Process) && (
        <Tile
          nowrap
          label={t('process', 'Process')}
          value={t(convertOperationType(workInstruction.operationType))}
        />
      )}

      {workInstruction.operationType === OperationType.Service && workInstruction.service?.type && (
        <Tile nowrap label={t('service', 'Service')} value={t(workInstruction.service.type)} />
      )}

      {checkFieldsToShow(OperatorFields.ReferenceNumber) && (
        <Tile
          nowrap
          label={t('referenceNumber', 'Reference number')}
          value={dashIfNull(workInstruction.order?.referenceNumber)}
        />
      )}

      {workInstruction.cargoUnit.amount > 0 && (
        <Tile
          nowrap
          label={t('plannedAmount', 'Planned amount')}
          value={workInstruction.cargoUnit.amount}
        />
      )}

      {workInstruction.cargoUnit.completedAmount > 0 && (
        <Tile
          nowrap
          label={t('completedAmount', 'Completed amount')}
          value={workInstruction.cargoUnit.completedAmount}
        />
      )}

      {workInstruction.cargoUnit.lotNumber && (
        <Tile
          nowrap
          label={t('lotNumber', 'Lot number')}
          value={workInstruction.cargoUnit.lotNumber}
        />
      )}

      {checkFieldsToShow(OperatorFields.ImoClasses) &&
        workInstruction.order &&
        workInstruction.order.imoClasses?.length > 0 && (
          <Tile
            nowrap
            label={t('imoClasses', 'IMO classes')}
            value={workInstruction?.order?.imoClasses.join(', ')}
          />
        )}

      {workInstruction.cargoUnit.package && (
        <Tile
          nowrap
          label={t('package', 'Package')}
          value={getPackageName(workInstruction.cargoUnit.package)}
        />
      )}
      {workInstruction.cargoUnit.handlingInstructions && (
        <Tile
          nowrap
          label={t('handlingInstructions', 'Handling instructions')}
          value={workInstruction.cargoUnit.handlingInstructions}
        />
      )}

      {checkFieldsToShow(OperatorFields.Weight) && workInstruction?.order?.grossWeight && (
        <Tile
          nowrap
          label={t('weight', 'Weight')}
          value={`${workInstruction.order.grossWeight} ${simplifyWeightMeasurementUnit(workInstruction?.cargoUnit?.package?.weightUnit ?? workInstruction?.cargoUnit?.weightUnit)}`}
        />
      )}

      {checkFieldsToShow(OperatorFields.Carrier) &&
        workInstruction.operationType !== OperationType.Internal &&
        workInstruction.carrierVisit?.carrierName && (
          <Tile
            nowrap
            label={t('carrier', 'Carrier')}
            value={workInstruction.carrierVisit?.carrierName}
          />
        )}

      {checkFieldsToShow(OperatorFields.Operator) && workInstruction.cargoUnit.operator && (
        <Tile nowrap label={t('operator', 'Operator')} value={workInstruction.cargoUnit.operator} />
      )}

      {checkFieldsToShow(OperatorFields.Consignee) && workInstruction?.order?.consignee && (
        <Tile nowrap label={t('consignee', 'Consignee')} value={workInstruction.order.consignee} />
      )}
    </DescriptionContainer>
  )
}
