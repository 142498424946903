import { createSvgIcon } from '@mui/material'

export const EmptyIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M20 4H4V20H20V4ZM4 2C2.89543 2 2 2.89543 2 4V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V4C22 2.89543 21.1046 2 20 2H4Z'
      fill='currentColor'
    />
    <path
      d='M8.82227 17V6.87799H15.5143L15.5073 8.34099H10.5373V11.176H14.9263V12.618H10.5233V15.516L15.6123 15.523V17H8.82227Z'
      fill='currentColor'
    />
  </svg>,
  'EmptyIcon',
)
