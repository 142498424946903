import { action, makeObservable, observable, reaction, runInAction } from 'mobx'

export interface IListStore<T> {
  items: T[]
  isLoading: boolean
  isNoMatches: boolean
  filter: string
  setFilter: (filter: string) => Promise<void> | void
  reset?: () => Promise<void> | void
}

export class SimpleListStore<T> implements IListStore<T> {
  items: T[] = []
  filter = ''
  isLoading = false
  isNoMatches = false

  constructor(protected fetchFunc: (query: any) => Promise<T[]>) {
    makeObservable(this, {
      items: observable,
      filter: observable,
      isLoading: observable,
      setLoading: action,
      setFilter: action,
      reset: action,
    })

    reaction(
      () => this.filter,
      filter => {
        if (filter.length > 0) {
          this.fetch(filter).catch(error => {
            // TODO: Handle error properly
            console.log(error)
          })
        }
      },
      { delay: 200 },
    )
  }

  setLoading = (isLoading: boolean) => {
    this.isLoading = isLoading
  }

  setFilter = (filter: string) => {
    if (filter !== '') {
      this.items = []
      this.isNoMatches = false
    }
    this.filter = filter
  }

  fetch = async (filter: string) => {
    this.setLoading(true)

    const data = await this.fetchFunc(filter)

    runInAction(() => {
      this.items = data
      this.isNoMatches = !data.length
    })

    this.setLoading(false)
  }

  reset() {
    this.setFilter('')
    this.items = []
    this.isLoading = false
    this.isNoMatches = false
  }
}
