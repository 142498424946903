import { Autocomplete, Grid, TextField } from '@mui/material'
import { Stack } from '@mui/system'
import { YardPositionDto } from '@storage/app/api'
import { SelectOption } from '@storage/app/models'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { ContainerMovementInfo } from '@tom-ui/utils'
import Item from 'antd/es/descriptions/Item'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import PositionRangeAutocomplete from './components/PositionRangeAutocomplete'
import { PositionField, YardPositionSelectorStore } from './stores/yard-position-selector.store'

interface Props {
  containers: ContainerMovementInfo[]
  handleChange: (position: YardPositionDto, isDangerousYardBlock: boolean) => void
}

const YardPositionSelector = observer(({ containers, handleChange }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const { yardBlockStackStore, yardBlockStore } = useStores()

  const positionSelectorStore = useMemo(
    () => new YardPositionSelectorStore(yardBlockStackStore, yardBlockStore),
    [yardBlockStackStore, yardBlockStore],
  )

  useEffect(() => {
    const loadBlocks = async () => {
      if (yardBlockStore.entries.length === 0) {
        await yardBlockStore.loadList()
      }
    }

    loadBlocks()

    positionSelectorStore.loadStacks()
  }, [positionSelectorStore, yardBlockStore])

  const handleYardBlockChange = (e: React.SyntheticEvent, value?: SelectOption | null) => {
    positionSelectorStore.setSelectedYardBlockId(value?.value)
    handlePositionFieldChange(PositionField.Block, value?.label)
  }

  const handlePositionFieldChange = (field: PositionField, value?: string | null) => {
    positionSelectorStore.setNewPositionField(field, value ?? undefined)
    handleChange(positionSelectorStore.newPosition, positionSelectorStore.isSelectedBlockDangerous)
  }

  return (
    <Stack gap={theme.customSpacing.s}>
      <Grid container spacing={theme.customSpacing.xs}>
        <Grid item xs={4}>
          <Item>
            <Autocomplete
              options={positionSelectorStore.yardBlocks}
              renderInput={params => <TextField {...params} label={t('yardBlock', 'Yard block')} />}
              onChange={handleYardBlockChange}
              fullWidth
            />
          </Item>
        </Grid>
        <Grid item xs={3}>
          <Item>
            <PositionRangeAutocomplete
              label={t('bay', 'Bay')}
              options={positionSelectorStore.bays}
              handleChange={value => handlePositionFieldChange(PositionField.Bay, value)}
            />
          </Item>
        </Grid>
        <Grid item xs={3}>
          <Item>
            <PositionRangeAutocomplete
              label={t('row', 'Row')}
              options={positionSelectorStore.rows}
              handleChange={value => handlePositionFieldChange(PositionField.Row, value)}
            />
          </Item>
        </Grid>
        <Grid item xs={2}>
          <Item>
            <Autocomplete
              options={positionSelectorStore.tiers}
              renderInput={params => <TextField {...params} label={t('tier', 'Tier')} />}
              onChange={(e, value) => handlePositionFieldChange(PositionField.Tier, value)}
              fullWidth
              disabled={containers.length > 1}
            />
          </Item>
        </Grid>
      </Grid>
    </Stack>
  )
})

export default YardPositionSelector
