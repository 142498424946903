import { YardPositionDto } from '@storage/app/api'
import { tolgee } from '@storage/app/translation'
import { YardBlockStackStore } from '@storage/stores/yard-block-stack.store'
import { YardBlockStore } from '@storage/stores/yard-block.store'
import { action, computed, makeObservable, observable } from 'mobx'

export enum ValidationResult {
  IsEqual,
  IsPrefix,
  IsInvalid,
}

interface StackMapItem {
  stackId: string
  position: YardPositionDto
  isFlexibleZone: boolean
}

export class YardPositionInputValidationStore {
  bayRowUniqueMode = false
  includeFlexibleZones = false
  input = ''

  constructor(
    private readonly stackStore: YardBlockStackStore,
    private readonly yardBlockStore: YardBlockStore,
  ) {
    makeObservable(this, {
      input: observable,
      bayRowUniqueMode: observable,
      includeFlexibleZones: observable,

      setInput: action,
      setBayRowUniqueMode: action,
      setIncludeFlexibleZones: action,

      stackMap: computed,
      validate: computed,
      position: computed,
    })
  }

  async loadStacks() {
    if (this.stackStore.entries.length === 0) {
      await this.stackStore.loadAll({})
    }
  }

  get stackMap() {
    const map = new Map<string, StackMapItem>()
    const stacks = this.stackStore.entries.filter(
      e => !e.isLocked && (this.includeFlexibleZones || !e.isFlexibleZone),
    )
    stacks.forEach(stack => {
      Array.from({ length: stack.numberOfSlots }, (_, i) => i + 1).forEach(tier => {
        const id = stack.isFlexibleZone
          ? stack.yardPosition.block?.toUpperCase() ?? ''
          : `${
              !this.bayRowUniqueMode ? stack.yardPosition.block?.toUpperCase() : ''
            }${stack.yardPosition.bay?.toUpperCase()}${stack.yardPosition.row?.toUpperCase()}${tier}`
        map.set(id, {
          stackId: stack.id,
          position: { ...stack.yardPosition, tier: tier },
          isFlexibleZone: stack.isFlexibleZone,
        })
      })
    })

    return map
  }

  setInput(input: string) {
    this.input = input.toUpperCase()
  }

  setBayRowUniqueMode(bayRowUnique: boolean) {
    this.bayRowUniqueMode = bayRowUnique
  }

  setIncludeFlexibleZones(include: boolean) {
    this.includeFlexibleZones = include
  }

  initPosition(position?: YardPositionDto, isFlexibleZone?: boolean) {
    if (!position || isFlexibleZone) {
      this.setInput('')
      return
    }

    this.setInput(
      `${!this.bayRowUniqueMode ? position.block : ''}${position.bay}${position.row}${
        position.tier
      }`,
    )
  }

  get validate() {
    if (this.input === '') {
      return ValidationResult.IsPrefix
    }

    const location = [...this.stackMap.keys()].find(stack => stack.startsWith(this.input))
    if (location) {
      if (location.length === this.input.length) {
        return ValidationResult.IsEqual
      }

      return ValidationResult.IsPrefix
    }

    return ValidationResult.IsInvalid
  }

  get position() {
    if (this.validate !== ValidationResult.IsEqual) {
      return undefined
    }

    return this.stackMap.get(this.input)?.position
  }

  validateDGPosition(isDangerousGoods?: boolean) {
    if (this.position) {
      const block = this.yardBlockStore.entries.find(
        x => x.name.toUpperCase() === this.position?.block?.toUpperCase(),
      )
      if (
        block?.usageOptions?.dangerousGoodsHandling === true &&
        (isDangerousGoods === false || isDangerousGoods === undefined)
      ) {
        return {
          isValidDangerousGoods: false,
          isDangerousAlertMessage: tolgee.t(
            'dangerousLocationNonDangerousSelection',
            'You are trying to put a non-dangerous good to a dangerous goods location',
          ),
        }
      } else if (
        block?.usageOptions?.dangerousGoodsHandling === false &&
        (isDangerousGoods === true || isDangerousGoods === undefined)
      ) {
        return {
          isValidDangerousGoods: false,
          isDangerousAlertMessage: tolgee.t(
            'nonDangerousLocationDangerousSelection',
            'You are trying to put a dangerous good to a non-dangerous goods location',
          ),
        }
      } else {
        return {
          isValidDangerousGoods: true,
          isDangerousAlertMessage: '',
        }
      }
    }
    return {
      isValidDangerousGoods: true,
      isDangerousAlertMessage: '',
    }
  }
}
