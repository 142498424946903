import {
  Box,
  Button,
  CardContent,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Typography,
  styled,
} from '@mui/material'
import Card from '@mui/material/Card'
import { EquipmentType, WorkAreaEquipmentBindingTypes } from '@operations/app/api'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { Droppable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd'
import { useNavigate } from 'react-router'
import { DraggableDropEquipmentCard } from '../components/DragableDropEquipmentCard.tsx/DragableDropEquipmentCard'
import { DraggableEquipmentCard } from '../components/DragableEquipmentCard/DragableEquipmentCard'
import { EquipmentTypeIcon } from '../components/EquipmentTypeIcon/EquipmentTypeIcon'
import { EquipmentPlanningEquipmentsUIStore } from '../stores/equipment-planning-equipments-ui-store'
interface Props {
  uiStore: EquipmentPlanningEquipmentsUIStore
}

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}))

const BayContainer = styled(Paper)(({ theme }) => ({
  textAlign: 'center',
  padding: '1rem',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  width: '100%',
  marginBottom: theme.spacing(2),
}))

const EquipmentContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1),
}))

const EquipmentsContent = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  borderRadius: '8px',
  width: '100%',
  height: '100%',
}))

export const EquipmentPlanningWorkAreaEquipmentsContainer = observer(({ uiStore }: Props) => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()
  const navigate = useNavigate()
  const workAreaWidth =
    uiStore.workAreasEquipments.length === 1 ? 3 : uiStore.workAreasEquipments.length > 2 ? 12 : 6

  let draggableItemIndex = 0
  const getDraggableItemIndex = () => {
    draggableItemIndex = draggableItemIndex + 1
    return draggableItemIndex
  }

  return (
    <Card variant='outlined' sx={{ width: '100%', minHeight: '100%', display: 'flex' }}>
      <CardContent
        sx={{
          minHeight: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        {!uiStore.hasCraneSplit ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', alignItems: 'center' }}>
            <Typography variant='h4' textAlign='center'>
              {t('craneSplitMissing')}
            </Typography>
            {uiStore.carrierVisitId && (
              <Button
                variant='contained'
                size='large'
                onClick={() => navigate(`/EquipmentPlanning/${uiStore.carrierVisitId}/craneSplit`)}
              >
                {t('navigateToCraneSplit')}
              </Button>
            )}
          </Box>
        ) : (
          <>
            <HeaderContainer>
              <Typography variant='h2'>{t('overview')}</Typography>
              <FormControl sx={{ minWidth: '10rem' }}>
                <Select
                  value={uiStore.assignmentType ?? WorkAreaEquipmentBindingTypes.Discharge}
                  size='small'
                  onChange={event =>
                    uiStore.setAssignmentType(event.target.value as WorkAreaEquipmentBindingTypes)
                  }
                  data-cy='equipment-planning-operation-type-dropdown'
                >
                  <MenuItem
                    key='discharge'
                    value={WorkAreaEquipmentBindingTypes.Discharge}
                    data-cy='equipment-planning-discharge-option'
                  >
                    {t('discharge')}
                  </MenuItem>
                  <MenuItem
                    key='load'
                    value={WorkAreaEquipmentBindingTypes.Load}
                    data-cy='equipment-planning-load-option'
                  >
                    {t('load')}
                  </MenuItem>
                </Select>
              </FormControl>
            </HeaderContainer>
            {!uiStore.hasCraneForSelectedAssignmentType && (
              <Typography variant='h4' textAlign='center' marginTop={4}>
                {t('createWorkQueuesBeforeAssignEquipments')}
              </Typography>
            )}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                [theme.breakpoints.down('sm')]: {
                  flexDirection: 'column',
                },
                gap: 2,
                height: '100%',
                width: '100%',
                overflowX: 'auto',
              }}
            >
              {uiStore.workAreasEquipments.map(workArea => (
                <Box
                  key={workArea.workAreaId}
                  sx={{
                    width: uiStore.workAreaWidth,
                    [theme.breakpoints.down('sm')]: {
                      width: '100%',
                    },
                    overflow: 'hidden',
                    flex: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                  data-cy={`work-area-${
                    workArea.workAreaEquipments.find(e => e.equipmentType === EquipmentType.Sts)
                      ?.name
                  }`}
                >
                  <BayContainer variant='outlined'>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                      <EquipmentTypeIcon zoom={120} />
                      <Typography variant='body1' fontWeight={600}>
                        {
                          workArea.workAreaEquipments.find(
                            e => e.equipmentType === EquipmentType.Sts,
                          )?.name
                        }
                      </Typography>
                    </Box>
                    <Divider sx={{ width: '100%' }} />
                    <Box sx={{ display: 'flex', gap: '2rem' }}>
                      <Typography variant='body1' fontWeight={600}>
                        {t('expectedContainers')}
                      </Typography>
                      <Typography>{workArea.expectedCount}</Typography>
                    </Box>
                  </BayContainer>

                  <Droppable
                    type='Equipment'
                    droppableId={'EQ_' + workArea.workAreaId.toString()}
                    isDropDisabled={
                      uiStore.isDndDisabled || workArea.expectedCount == workArea.finishedCount
                    }
                  >
                    {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                      <EquipmentsContent
                        variant='outlined'
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={{
                          backgroundColor: snapshot.isDraggingOver
                            ? theme.palette.grey[500]
                            : theme.palette.common.white,
                        }}
                      >
                        <EquipmentContainer container spacing={2}>
                          {workArea.workAreaEquipments
                            .filter(e => e.equipmentType !== EquipmentType.Sts)
                            .map(e => (
                              <Grid
                                key={workArea.workAreaId.toString() + '_' + e.id}
                                item={true}
                                xs={12}
                                lg={workAreaWidth}
                              >
                                <DraggableEquipmentCard
                                  draggableId={
                                    workArea.workAreaId.toString() + '_' + e.id.toString()
                                  }
                                  index={getDraggableItemIndex()}
                                  equipment={e}
                                  isInOperation={uiStore.getIsEquipmentInOperation(e.id)}
                                  isDragDisabled={
                                    uiStore.isDndDisabled ||
                                    workArea.expectedCount == workArea.finishedCount
                                  }
                                  onUnassignItem={(id: number) => {
                                    uiStore.unAssignEquipment(id, workArea.workAreaId)
                                  }}
                                />
                              </Grid>
                            ))}
                        </EquipmentContainer>
                        <DraggableDropEquipmentCard
                          dataCy={`drop-equipments-here-${
                            workArea.workAreaEquipments.find(
                              e => e.equipmentType === EquipmentType.Sts,
                            )?.name
                          }`}
                        ></DraggableDropEquipmentCard>
                        {provided.placeholder}
                      </EquipmentsContent>
                    )}
                  </Droppable>
                </Box>
              ))}
            </Box>
          </>
        )}
      </CardContent>
    </Card>
  )
})
