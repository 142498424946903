import { Stack, TextField, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { usePlanningStore } from '@planning/AppProvider'
import { CarrierVisitDirection, CarrierVisitStatus, VesselVisitDto } from '@planning/app/api'
import { IOrderItem } from '@planning/rt-stores/order/OrderItem'
import { ITruckVisitItem } from '@planning/rt-stores/truckVisit/TruckVisitItem'
import { useTranslate } from '@tolgee/react'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useState } from 'react'
import { FilterOption, TabOption } from './FilterOption'
import { GateOutWarningDialogs } from './GateOutWarningDialogs'
import { IInspectContainerFormData } from './InspectContainer'
import { DropOffVisit, PickUpVisit, TruckVisitCard } from './TruckVisitCard'

interface TabPanelProps {
  children?: any
  index: number
  value: number
}

export interface TabData {
  key: 'expected' | 'onTerminal' | 'departed'
  title: string
  data: ITruckVisitItem[]
  carrierVisitStatus: CarrierVisitStatus
}

interface ReusableTabsProps {
  tabs: TabData[]
  handleFilterChange: (value: any) => void
  handleSortingChange: (value: any) => void
  handleTextFilterChange: (value: any) => void
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index } = props

  return (
    <div hidden={value !== index}>{value === index && <Box sx={{ pt: 1 }}>{children}</Box>}</div>
  )
}

export const TabsList = observer(
  ({
    tabs,
    handleFilterChange,
    handleSortingChange,
    handleTextFilterChange,
  }: ReusableTabsProps) => {
    const { t } = useTranslate()
    const [value, setValue] = useState(0)
    const { orderItemStore, vesselVisitItemStore, truckVisitItemStore, gateInViewStore } =
      usePlanningStore()

    const onClickTruckAppointment = async (truckVisitId: number) => {
      gateInViewStore.setIsTruckAppointment(true)
      gateInViewStore.toggleGateInDialog(true)

      const truckVisit = truckVisitItemStore.filteredTruckVisits.expected.find(
        e => e.id === truckVisitId,
      )

      gateInViewStore.setTruckVisitId(truckVisitId)
      gateInViewStore.setLicensePlate(truckVisit?.truck?.data.licensePlate ?? '')
      gateInViewStore.setTruckAppointmentDate(truckVisit?.data.eta ?? '')
      gateInViewStore.setTruckAppointmentStartTime(truckVisit?.data.eta ?? '')
      gateInViewStore.setTruckAppointmentEndTime(truckVisit?.data.etd ?? '')
      gateInViewStore.setDriverName(truckVisit?.data.driverName ?? '')

      const orders = orderItemStore.ordersByCarrierVisitId[truckVisitId]

      const dropOffOrderType = orders.length
        ? orders[0].data.commodityId
          ? 'generalCargo'
          : 'container'
        : null

      gateInViewStore.setSearchType(
        orders.length ? (orders[0].data.commodityId ? 'generalCargo' : 'container') : null,
      )
      gateInViewStore.pickUpOrderSearchStore.setSearchType(
        orders.length ? (orders[0].data.commodityId ? 'generalCargo' : 'container') : null,
      )

      // todo: review gc- and container- orders. we should treat them as the same entity
      // why we have to maintain 2 lists of orders
      orders.forEach(o =>
        o.data.direction === CarrierVisitDirection.Inbound
          ? dropOffOrderType === 'generalCargo'
            ? gateInViewStore.upsertDropOffGeneralCargoOrder(o.data as IInspectContainerFormData)
            : gateInViewStore.upsertDropOffOrder(o.data as IInspectContainerFormData)
          : dropOffOrderType === 'generalCargo'
            ? gateInViewStore.upsertPickUpGeneralCargoOrder(o.data as IInspectContainerFormData)
            : gateInViewStore.upsertPickUpOrder(o.data as IInspectContainerFormData),
      )
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue)
    }

    const tabsFilteringOptions: TabOption = {
      expected: {
        defaultOption: 'expectedToday',
        options: [
          { value: 'expectedLast7Days', label: t('last7Days', 'Last 7 days') },
          { value: 'expectedToday', label: t('today', 'Today') },
          { value: 'expectedNext7Days', label: t('next7Days', 'Next 7 days') },
        ],
      },
      onTerminal: {
        defaultOption: 'onTerminal',
        options: [{ value: 'onTerminal', label: t('onTerminal', 'On Terminal') }],
      },
      departed: {
        defaultOption: 'departedToday',
        options: [
          { value: 'departedLast7Days', label: t('last7Days', 'Last 7 days') },
          { value: 'departedToday', label: t('today', 'Today') },
        ],
      },
    }

    const RenderFilterOptions = (tab: TabData) => {
      const filteringOptions = tabsFilteringOptions[tab.key]

      return (
        <Box key={`searchable-list-action-${tab.key}`} sx={{ ml: '1rem', minWidth: '140px' }}>
          <FilterOption
            key='dayFilter'
            onChange={handleFilterChange}
            defaultOption={filteringOptions.defaultOption}
            options={filteringOptions.options}
          />
        </Box>
      )
    }

    const RenderSortingOptions = (tab: TabData) => {
      const sortingOptions = [
        { value: 'old', label: t('oldToNew', 'Old to new') },
        { value: 'new', label: t('newToOld', 'New to old') },
      ]

      return (
        <Box key={`searchable-list-action-sorting`} sx={{ ml: '1rem', minWidth: '140px' }}>
          <FilterOption
            key='sortingFilter'
            onChange={handleSortingChange}
            options={sortingOptions}
          />
        </Box>
      )
    }

    return (
      <Box sx={{ width: '100%' }}>
        <Box display='flex' alignItems='center' mb='1rem' justifyContent='space-between'>
          <Box>
            <Tabs value={value} onChange={handleChange}>
              {tabs.map((tab, index) => (
                <Tab
                  data-cy={`tab-${tab.key}`}
                  key={index}
                  label={
                    <Stack spacing='8px' direction='row' alignItems='baseline'>
                      <Typography variant='subtitle1'>{tab.title}</Typography>
                      <Box
                        sx={{
                          backgroundColor: '#F9FAFB',
                          padding: '4px 8px',
                          borderRadius: '4px',
                        }}
                      >
                        <Typography variant='caption' fontWeight='bold'>
                          {tab.data.length}
                        </Typography>
                      </Box>
                    </Stack>
                  }
                />
              ))}
            </Tabs>
          </Box>
          <Box display='flex'>
            <TextField
              key='filter'
              label={t('search', 'Search')}
              defaultValue={truckVisitItemStore.filter}
              onChange={handleTextFilterChange}
            />
            {RenderFilterOptions(tabs[value])}
            {RenderSortingOptions(tabs[value])}
          </Box>
        </Box>

        <Box>
          {tabs.map((tab, index) => (
            <CustomTabPanel key={index} value={value} index={index}>
              {tab.data.map(item => {
                const dropOffOrders: DropOffVisit[] = item.inboundOrders.map(item => {
                  const linkedOutboundOrder: IOrderItem =
                    _.get(orderItemStore.elements, item.data.linkedOrderId ?? 0) ?? {}

                  const visit = vesselVisitItemStore.getById(
                    linkedOutboundOrder.data?.carrierVisitId ?? 0,
                  )

                  return {
                    dropOffOrder: item.data,
                    theOtherDirectionVisitType: linkedOutboundOrder.data?.carrierVisitType,
                    theOtherDirectionStatus: linkedOutboundOrder.data?.status,
                    theOtherDirectionVisit: linkedOutboundOrder.visit?.data as VesselVisitDto,
                    theOtherDirectionCarriers: visit?.vessels
                      ? visit.vessels.map(item => item.data)
                      : [],
                  }
                })

                const pickUpOrders: PickUpVisit[] = item.outboundOrders.map(item => {
                  const visit: ITruckVisitItem =
                    _.get(truckVisitItemStore.elements, item.data?.carrierVisitId ?? 0) ?? {}
                  return {
                    pickUpOrder: item.data,
                    carriers: visit?.truck ? [visit.truck.data] : [],
                  }
                })
                return (
                  <Box key={item.id} mb='16px'>
                    <TruckVisitCard
                      viewStore={gateInViewStore}
                      visit={item.data}
                      dropOffOrders={dropOffOrders}
                      pickUpOrders={pickUpOrders}
                      carrierVisitStatus={tab.carrierVisitStatus}
                      onClickTruckAppointment={onClickTruckAppointment}
                    />
                  </Box>
                )
              })}
            </CustomTabPanel>
          ))}
        </Box>
        <GateOutWarningDialogs viewStore={gateInViewStore} />
      </Box>
    )
  },
)
