import { Box, LinearProgress } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { ComponentStatus } from '@storage/helpers/enums'

interface LinearProgressBarProps {
  value: number
  state: ComponentStatus
}

export const LinearProgressBar = ({ value, state }: LinearProgressBarProps) => {
  const { palette } = useMinimalsTheme()

  const getBackgroundColor = () => {
    switch (state) {
      case ComponentStatus.Success:
        return palette.success.main
      case ComponentStatus.Warning:
        return palette.warning.main
      case ComponentStatus.Error:
        return palette.error.main
      default:
        return ''
    }
  }
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
      <Box
        sx={{
          width: '100%',
        }}
      >
        <LinearProgress
          variant='determinate'
          value={value}
          sx={{
            backgroundColor: palette.grey[200],
            '& .MuiLinearProgress-bar': {
              backgroundColor: getBackgroundColor(),
            },
          }}
        />
      </Box>
    </Box>
  )
}
