import { OperationType, OrderDto } from '@billing/app/api'
import { NorthEast, SouthEast } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { ColoredLabel, useMinimalsTheme } from '@tom-ui/ui'
import dayjs from 'dayjs'
import { CarrierTypeIcon } from './CarrierTypeIcon'

export const formatDateTime = (date: any) => {
  if (!date) {
    return
  }

  return dayjs(date).toDate().toLocaleString([], { dateStyle: 'short', timeStyle: 'short' })
}

interface IProps {
  order: OrderDto
}

export const GCOrdersCard = ({ order }: IProps) => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()

  const CommodityCharacteristics = () => (
    <Box display='flex' alignItems='center' gap={theme.customSpacing.m} mb={0.5}>
      <Typography
        sx={{
          maxWidth: '300px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        variant='h6'
      >
        {order.commodity?.name}
      </Typography>
      {order.grossWeight != null ? (
        <Typography variant='body1' color={theme.palette.secondary.dark}>
          {order.grossWeight} &nbsp;
          {order.weightMeasurementUnit}
        </Typography>
      ) : (
        ''
      )}
    </Box>
  )

  const OrderDetails = () => (
    <Box display='flex' gap={theme.customSpacing.m}>
      <Typography variant='body1'>{order.customer}</Typography>
      <Typography variant='body1'>{order.referenceNumber}</Typography>
      {order.lotNumber != null ? (
        <Typography variant='body1'>
          {t('lot', 'LOT')}: {order.lotNumber}
        </Typography>
      ) : (
        ''
      )}
    </Box>
  )

  const CarrierVisitDetails = () => (
    <Box display='flex' gap={theme.customSpacing.s}>
      {CarrierTypeIcon({ type: order.carrierVisit?.type })}
      <Typography variant='body1'>{order.carrierVisit?.carrierName}</Typography>

      {order.carrierVisit?.ata || order.carrierVisit?.eta ? (
        <Typography variant='body1'>
          {formatDateTime(new Date(order.carrierVisit?.ata ?? order.carrierVisit?.eta ?? ''))}
        </Typography>
      ) : (
        ''
      )}
    </Box>
  )

  const CarrierVisitDirectionDetails = () => (
    <Box display='flex' gap={theme.customSpacing.s}>
      {order.handlingDirection === OperationType.Inbound ? <SouthEast /> : <NorthEast />}

      <Typography variant='subtitle1'>
        {order.handlingDirection === OperationType.Inbound
          ? t('inbound', 'Inbound')
          : t('outbound', 'Outbound')}
      </Typography>
    </Box>
  )

  const QuantityAndStatusDetails = () => (
    <>
      {order.plannedAmount ? (
        <Typography variant='body1'>
          {t('planned', 'Planned')}: <b>{order.plannedAmount}</b>
        </Typography>
      ) : (
        ''
      )}

      {order.actualAmount || order.actualAmount === 0 ? (
        <Typography variant='body1' color={theme.palette.success.main}>
          {t('actual', 'Actual')}: <b>{order.actualAmount}</b>
        </Typography>
      ) : (
        ''
      )}

      <ColoredLabel label={t('fulfilled', 'Fulfilled')} color={'success'} />
    </>
  )

  return (
    <Box
      sx={{
        mb: '1rem',
        width: '1000px',
      }}
    >
      {/* CARD HEADER */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: theme.customSpacing.m,
          borderTopRightRadius: theme.customRadius.medium,
          borderTopLeftRadius: theme.customRadius.medium,
          borderBottom: `1px solid ${theme.palette.divider}`,
          backgroundColor: '#fff',
          boxShadow: theme.customShadows.card,
        }}
      >
        {/* Commodity characteritics */}
        <Box>
          <CommodityCharacteristics />
          <OrderDetails />
        </Box>

        <Box display='flex' gap={theme.customSpacing.m} alignItems={'center'}>
          <QuantityAndStatusDetails />
        </Box>
      </Box>

      {/* CARD CONTENT */}
      <Box
        sx={{
          display: 'flex',
          gap: theme.customSpacing.xl,
          padding: theme.customSpacing.m,
          borderBottomRightRadius: theme.customRadius.medium,
          borderBottomLeftRadius: theme.customRadius.medium,
          backgroundColor: '#F4F6F8',
          boxShadow: theme.customShadows.card,
        }}
      >
        {/* DIRECTION */}
        <CarrierVisitDirectionDetails />

        {/* CARRIER VISIT */}
        <CarrierVisitDetails />
      </Box>
    </Box>
  )
}
