import { createSvgIcon } from '@mui/material'

export const NonCargoIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4 2C2.89543 2 2 2.89543 2 4V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V4C22 2.89543 21.1046 2 20 2H4ZM16.7066 8.80619C16.3384 8.60206 15.9162 8.5 15.4402 8.5C14.8414 8.5 14.3279 8.63818 13.8998 8.91454C13.4747 9.18775 13.1483 9.58188 12.9208 10.0969C12.6932 10.6119 12.5795 11.2322 12.5795 11.9576C12.5795 12.6862 12.6932 13.3158 12.9208 13.8466C13.1513 14.3742 13.4791 14.7824 13.9043 15.0713C14.3324 15.3571 14.8444 15.5 15.4402 15.5C15.8653 15.5 16.2306 15.4372 16.536 15.3116C16.8443 15.1828 17.1003 15.0085 17.3039 14.7887C17.5075 14.5657 17.6647 14.3129 17.7755 14.0303C17.8892 13.7476 17.9641 13.4524 18 13.1447H16.8458C16.8009 13.3959 16.7171 13.6283 16.5943 13.8419C16.4746 14.0523 16.3159 14.2218 16.1183 14.3506C15.9207 14.4762 15.6842 14.539 15.4087 14.539C14.8908 14.539 14.4911 14.3349 14.2097 13.9266C13.9282 13.5184 13.7875 12.8668 13.7875 11.9717C13.7875 11.3813 13.8504 10.9009 13.9761 10.5303C14.1049 10.1597 14.289 9.88964 14.5285 9.72005C14.771 9.54733 15.0644 9.46097 15.4087 9.46097C15.6842 9.46097 15.9207 9.51907 16.1183 9.63526C16.3159 9.75146 16.4746 9.90848 16.5943 10.1063C16.7171 10.3042 16.8009 10.5287 16.8458 10.7799H18C17.9581 10.356 17.8234 9.97129 17.5958 9.62584C17.3713 9.28039 17.0749 9.00718 16.7066 8.80619ZM11.3397 8.59421H10.2484V13.4603L7.06884 8.59421H6V15.4058H7.09129V10.4078L10.3562 15.4058H11.3397V8.59421Z'
      fill='currentColor'
    />
  </svg>,
  'NonCargoIcon',
)
