import { SelectOption } from '@storage/app/models'
import { Box, Button } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { Control, Controller } from 'react-hook-form'
import { AllTranslationKey, AllValue } from './form-controls/constants'

interface ControlledButtonGroupProps {
  name: string
  allOption?: boolean
  control: Control<any, any>
  options: SelectOption[]
}

const ControlledButtonGroup = ({
  name,
  control,
  allOption,
  options,
}: ControlledButtonGroupProps) => {
  const { t } = useTranslate()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <Box display='flex' flexDirection='row' sx={{ gap: 1 }}>
          {allOption && (
            <Button
              disableElevation
              color={!value || value === AllValue ? 'warning' : 'inherit'}
              variant='contained'
              key={AllValue}
              size='small'
              onClick={() => onChange(AllValue)}
            >
              {t(AllTranslationKey)}
            </Button>
          )}
          {options.map(option => (
            <Button
              disableElevation
              color={value === option.value ? 'warning' : 'inherit'}
              variant='contained'
              key={option.label}
              size='small'
              onClick={() => onChange(option.value)}
            >
              {t(option.label)}
            </Button>
          ))}
        </Box>
      )}
    />
  )
}

export default ControlledButtonGroup
