import { Alert, Box, Card, CardContent, Typography } from '@mui/material'
import { CarrierVisitDirection, CarrierVisitDto, CarrierVisitStatus } from '@storage/app/api'
import { useHandlingDirectionTranslation } from '@storage/app/carriers/hooks'
import { LabelBox } from '@storage/components/LabelBox'
import StackedProgressBar from '@storage/components/StackedProgressBar'
import { CarrierVisitStore } from '@storage/stores/carrier-visit.store'
import { getHumanReadableDate } from '@storage/utils/date'
import { useTranslate } from '@tolgee/react'
import { TrainIcon, VesselIcon, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import { CarrierVisitArrivalStatusStore, PrefiltersStore } from '../stores'

interface Props {
  vesselVisit: CarrierVisitDto
  handlingDirection: CarrierVisitDirection
  prefiltersStore: PrefiltersStore
  vesselVisitStore: CarrierVisitStore
}

export const VesselVisitContext = observer(
  ({ vesselVisit, handlingDirection, prefiltersStore, vesselVisitStore }: Props) => {
    const { palette } = useMinimalsTheme()
    const { t } = useTranslate()

    const vesselVisitArrivalStatusStore = useMemo(() => new CarrierVisitArrivalStatusStore(), [])

    const resolveElements = () => {
      switch (true) {
        case vesselVisit.status === CarrierVisitStatus.InOperation:
          if (handlingDirection === CarrierVisitDirection.Inbound) {
            return [
              {
                label: t('stacked', 'Stacked'),
                value:
                  vesselVisitStore.carrierVisitSummaries.get(vesselVisit.id)?.inYardDischarge ?? 0,
                color: palette.primary.main,
              },
              {
                label: t('inProgress', 'In Progress'),
                value:
                  (vesselVisitStore.carrierVisitSummaries.get(vesselVisit.id)?.plannedDischarge ??
                    0) +
                  (vesselVisitStore.carrierVisitSummaries.get(vesselVisit.id)?.unplannedDischarge ??
                    0),
                color: palette.grey[300],
              },
            ]
          }
          return []
        default:
          if (handlingDirection === CarrierVisitDirection.Inbound) {
            return [
              {
                label: t('planned', 'Planned'),
                value: prefiltersStore.prefilters[0]?.planned,
                color: palette.info.main,
              },
              {
                label: t('unplanned', 'Unplanned'),
                value: prefiltersStore.prefilters[0]?.unplanned,
                color: palette.grey[300],
              },
            ]
          }
          return [
            {
              label: t('stacked', 'Stacked'),
              value:
                vesselVisitStore.carrierVisitSummaries.get(Number(vesselVisit.id))?.inYardLoad ?? 0,
              color: palette.primary.main,
            },
            {
              label: t('planned', 'Planned'),
              value: prefiltersStore.prefilters[0]?.planned,
              color: palette.info.main,
            },
            {
              label: t('unplanned', 'Unplanned'),
              value: prefiltersStore.prefilters[0]?.unplanned,
              color: palette.grey[300],
            },
          ]
      }
    }

    useEffect(() => {
      const resolveVesselArrival = () => {
        vesselVisitArrivalStatusStore.resolveVesselArrival(vesselVisit)
      }
      resolveVesselArrival()
    }, [vesselVisit, vesselVisitArrivalStatusStore])

    const handlingDirectionTranslated = useHandlingDirectionTranslation(handlingDirection)

    return (
      <Card>
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 1,
              alignItems: 'center',
              marginBottom: 2,
            }}
            data-cy='carrier-visit-status'
          >
            <Box>
              {vesselVisit.discriminator === 'RailVisit' ? <TrainIcon /> : <VesselIcon />}
              <Typography variant='h6' data-cy='carrier-visit-name-plan'>
                {vesselVisit.name}
              </Typography>
              <LabelBox label={handlingDirectionTranslated} />
            </Box>

            {vesselVisitArrivalStatusStore.arrivalValue && (
              <Box>
                <Typography
                  variant='subtitle1'
                  textTransform='capitalize'
                  data-cy='carrier-visit-arrival-label'
                >
                  {vesselVisitArrivalStatusStore.arrivalLabel}
                </Typography>

                <Typography variant='subtitle2' color={palette.text.disabled}>
                  {getHumanReadableDate(vesselVisitArrivalStatusStore.arrivalValue)}
                </Typography>
              </Box>
            )}
          </Box>
          {!!prefiltersStore.prefilters.length &&
          vesselVisit.status === CarrierVisitStatus.InOperation &&
          handlingDirection === CarrierVisitDirection.Outbound ? (
            <Alert
              severity={
                (vesselVisitStore.carrierVisitSummaries.get(vesselVisit.id)?.inYardLoad ?? 0) > 0
                  ? 'info'
                  : 'success'
              }
            >
              {t('containersToBeLoaded', '{n} containers remaining', {
                n: vesselVisitStore.carrierVisitSummaries.get(vesselVisit.id)?.inYardLoad ?? 0,
              })}
            </Alert>
          ) : (
            <StackedProgressBar elements={resolveElements()} />
          )}
        </CardContent>
      </Card>
    )
  },
)
