import { Box, Typography } from '@mui/material'
import { ContainerResponseDto } from '@planning/app/api'
import { getContainerTypeParameters } from '@planning/components/ContainerTypesMap'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'

interface IProps {
  container?: ContainerResponseDto
}

export const ContainerSummary = ({ container }: IProps) => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()
  const { icon } = getContainerTypeParameters('General', t)

  const renderContainerAttr = (attribute: string) => (
    <Typography variant='body2' color={theme.palette.grey[600]}>
      {attribute}
    </Typography>
  )

  if (!container) return <></>

  return (
    <Box
      sx={{
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: theme.customRadius.medium,
        bgcolor: theme.palette.grey[200],
        padding: theme.customSpacing.m,
        display: 'flex',
        alignItems: 'center',
        gap: theme.customSpacing.m,
      }}
    >
      {icon}
      <Typography variant='h4'>{container.number}</Typography>

      {container.isoCode && renderContainerAttr(`${container.isoCode}`)}
      {container.length && renderContainerAttr(`${container.length + 'ft'}`)}
      {container.type && renderContainerAttr(container.type)}
      {container.height && renderContainerAttr(`${container.height}`)}
    </Box>
  )
}
