import {
  BerthSide,
  CarrierType,
  CarrierVisitStatus,
  EquipmentType,
  OperationType,
  VesselArea,
  VesselBayType,
  WorkInstructionDto,
  WorkQueueActionDto,
  WorkQueueActionType,
  WorkQueueDto,
} from '@operations/app/api'
import { WorkInstructionConfirmedEventPayload } from '@operations/app/api/signalRDtos/workInstructionConfirmedEventPayload'
import { BayAmount } from '@operations/features/craneSplit/models/bay.model'
import { EventTypes } from '@operations/messages/eventsTypes'
import { IEvent, IMessageBus } from '@operations/messages/messageBus'
import { ContainerStowageLocationStore } from '@operations/stores/ContainerStowageLocationStore'
import { EquipmentStore } from '@operations/stores/EquipmentStore'
import { VesselVisitStore } from '@operations/stores/VesselVisitStore'
import { WorkInstructionStore } from '@operations/stores/WorkInstructionStore'
import { WorkQueueStore } from '@operations/stores/WorkQueueStore'
import dayjs from 'dayjs'
import _ from 'lodash'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { Bay, BayAreaAmount } from '../models/bay.model'
import { CraneColors } from '../models/crane-colors.model'
import { Metrics } from '../models/metric.model'
import {
  WorkQueue,
  WorkQueueChange,
  WorkQueueStack,
  WorkQueueStackChange,
  WorkQueueTiming,
} from '../models/work-queue.model'
import { UnknownBayNumber } from '../utils'

export class CraneSplitContainerUIStore {
  vesselId?: number
  initialCraneIds: number[] = []
  processingTime = 2

  workQueuesStack: WorkQueueStack[] = []
  stackPointer = 0
  isExporting = false

  constructor(
    private workQueueStore: WorkQueueStore,
    private workInstructionStore: WorkInstructionStore,
    private equipmentStore: EquipmentStore,
    private vesselVisitStore: VesselVisitStore,
    private containerStowageLocationStore: ContainerStowageLocationStore,
    private messageBus: IMessageBus,
  ) {
    makeObservable(this, {
      vesselId: observable,
      initialCraneIds: observable,
      processingTime: observable,
      workQueuesStack: observable,
      stackPointer: observable,
      isExporting: observable,
      setExporting: action,
      initVesselVisit: action,
      setProcessingTime: action,
      updateWorkQueueStartTime: action,
      addWorkQueuesToStack: action,
      redo: action,
      undo: action,
      clearWorkQueueStack: action,
      calculatedBays: computed,
      lastWorkQueueEndTime: computed,
      vesselVisit: computed,
      canModify: computed,
      canRedo: computed,
      canUndo: computed,
      vesselAtaOrEta: computed,
      vesselEtd: computed,
      startChartTime: computed,
      endChartTime: computed,
      charTimes: computed,
      workInstructionsLocated: computed,
      craneIds: computed,
      metrics: computed,
      equipments: computed,
    })

    this.messageBus.subscribeEvent<WorkInstructionConfirmedEventPayload>(
      EventTypes.WorkInstructionConfirmed,
      this.confirmWorkInstruction,
    )
  }

  confirmWorkInstruction = (res: IEvent<WorkInstructionConfirmedEventPayload>) => {
    const workInstruction = this.workInstructionStore.items.find(x => x.id === res.payload?.id)

    if (workInstruction) {
      runInAction(() => {
        workInstruction.isConfirmed = true
      })
    }
  }

  public initVesselVisit(vesselId: number): void {
    if (this.vesselId != vesselId) this.vesselId = vesselId

    this.initProcessingTime()
    this.initInitialCranes()
    this.clearWorkQueueStack()
  }

  public get lastWorkQueueEndTime() {
    return this.workQueueStore.items.reduce((prev?: Date, wq?: WorkQueueDto) => {
      if (!wq) return prev

      const startTime = dayjs(wq.startTime).set('second', 0).toDate()
      const endDate = calculateEndTime(startTime, wq.moves, wq.processingTimeSeconds)
      return !prev || endDate > prev ? endDate : prev
    }, undefined)
  }

  public initInitialCranes() {
    this.initialCraneIds = [...this.craneIds]
  }

  public setProcessingTime(processingTime: number) {
    this.processingTime = processingTime

    this.updateWorkQueuesProcessingTime(processingTime)
  }

  public updateWorkQueueStartTime(workQueue: WorkQueue, currentPosition: number) {
    const originalWorkQueueDto = this.workQueueStore.items.find(x => x.id === workQueue.id)
    if (!originalWorkQueueDto) {
      return
    }

    if (currentPosition < workQueue.boundary.top) {
      currentPosition = workQueue.boundary.top
    } else if (currentPosition > workQueue.boundary.bottom) {
      currentPosition = workQueue.boundary.bottom
    }

    const workQueueDto = { ...originalWorkQueueDto }
    const amountMoved = currentPosition - workQueue.currentPosition
    const timeMoved = (amountMoved * 60) / this.oneHourSegmentSize

    const newStartDate = new Date(workQueue.startTime.getTime())
    newStartDate.setSeconds(timeMoved * 60 + newStartDate.getSeconds())

    workQueueDto.startTime = newStartDate.toString()

    this.workQueueStore.updateStoreItem(workQueueDto, +workQueue.id)

    this.addWorkQueuesToStack([
      {
        previous: {
          workQueue: { ...originalWorkQueueDto },
        },
        current: {
          workQueue: { ...workQueueDto },
        },
        action: WorkQueueActionType.Edit,
      },
    ])
  }

  public addWorkQueuesToStack(changes: WorkQueueStackChange[]) {
    if (this.canRedo) {
      if (this.stackPointer === 0) {
        this.workQueuesStack = []
      } else {
        this.workQueuesStack = this.workQueuesStack.slice(0, this.stackPointer)
      }

      this.stackPointer = this.workQueuesStack.length
    }

    const changesToAdd = changes.filter(
      change =>
        change.action !== WorkQueueActionType.Edit ||
        !this.areWorkQueuesEqual(change.current!.workQueue, change.previous!.workQueue),
    )

    if (changesToAdd.length) {
      this.workQueuesStack.push({
        changes: changesToAdd,
      })

      this.stackPointer++
    }
  }

  public undo() {
    if (this.canUndo) {
      const stack = this.workQueuesStack[this.stackPointer - 1]

      if (stack) {
        this.stackPointer--
        const workQueues = this.getWorkQueuesForUndo(stack.changes)

        this.workQueueStore.updateStoreItems(workQueues)
      }
    }
  }

  public redo() {
    if (this.canRedo) {
      const stack = this.workQueuesStack[this.stackPointer]

      if (stack) {
        this.stackPointer++
        const workQueues = this.getWorkQueuesForRedo(stack.changes)

        this.workQueueStore.updateStoreItems(workQueues)
      }
    }
  }

  public setExporting(isExporting: boolean) {
    if (this.isExporting !== isExporting) {
      this.isExporting = isExporting
    }
  }

  public get vesselVisit() {
    return this.vesselId
      ? this.vesselVisitStore.items.find(
          i => i.id === this.vesselId && i.type === CarrierType.Vessel,
        )
      : undefined
  }

  public get canModify() {
    return (
      this.vesselVisit?.status === CarrierVisitStatus.Expected ||
      this.vesselVisit?.status === CarrierVisitStatus.Arrived ||
      this.vesselVisit?.status === CarrierVisitStatus.InOperation
    )
  }

  public get canUndo() {
    return this.stackPointer > 0
  }

  public get canRedo() {
    return this.stackPointer < this.workQueuesStack.length
  }

  public get equipments() {
    return this.equipmentStore.items.filter(eq => eq.equipmentType === EquipmentType.Sts)
  }

  public get craneIds() {
    return _(this.workQueueStore.items.map(wq => wq.craneId))
      .uniq()
      .sort()
      .value()
  }

  public get calculatedBays(): Bay[] {
    const bays = _(this.workQueueStore.items.map(x => x.bay))
      .uniq()
      .value()

    const items = bays.flatMap(bay => {
      const workQueues = _(
        this.getBayWorkQueues(this.workQueueStore.items.filter(wq => wq.bay === bay)),
      )
        .sortBy(wq => wq.startTime)
        .value()

      if (!workQueues.length) {
        return []
      }

      const bayAreaAmount: BayAreaAmount = {
        bayAmount: 0,
        nextBayAmount: 0,
        previousBayAmount: 0,
      }

      const item: Bay = {
        bay: bay,
        isSingleTwentyBay: workQueues.every(wq => wq.isTwentySingleBay),
        workQueues: workQueues,
        discharge: { totalMoves: 0, deck: { ...bayAreaAmount }, hold: { ...bayAreaAmount } },
        loading: { totalMoves: 0, deck: { ...bayAreaAmount }, hold: { ...bayAreaAmount } },
      }

      const bayWorkQueues = this.workQueueStore.items.filter(wq => wq.bay === bay)

      bayWorkQueues.forEach(wq => {
        if (wq.operationType === OperationType.Inbound) {
          this.fillBayAmount(bay, item.discharge, wq)
        } else if (wq.operationType === OperationType.Outbound) {
          this.fillBayAmount(bay, item.loading, wq)
        }
      })

      return item
    })

    const knownBays = _.orderBy(
      items.filter(x => x.bay !== UnknownBayNumber),
      ['bay'],
      this.vesselVisit?.berthSide === BerthSide.Starboard ? 'desc' : 'asc',
    )

    const unknownBay = items.find(x => x.bay === UnknownBayNumber)

    if (unknownBay) {
      knownBays.push(unknownBay)
    }

    return knownBays
  }

  public get vesselAtaOrEta() {
    return this.vesselVisit?.ata
      ? new Date(this.vesselVisit.ata)
      : this.vesselVisit?.eta
        ? new Date(this.vesselVisit.eta)
        : new Date(2023, 1, 1, 0)
  }

  public get vesselEtd() {
    return this.vesselVisit?.etd ? new Date(this.vesselVisit.etd) : undefined
  }

  public get startChartTime() {
    const startTime = new Date(this.vesselAtaOrEta)
    startTime.setMinutes(startTime.getMinutes() > 30 ? 30 : 0)
    startTime.setSeconds(0)

    return startTime
  }

  public get endChartTime() {
    const startTime = this.startChartTime
    const oneHour = 60 * 60000
    let endTime = new Date(this.lastWorkQueueEndTime ?? startTime.getTime() + oneHour)
    if (this.vesselEtd && this.vesselEtd.getTime() > endTime.getTime()) {
      endTime = new Date(this.vesselEtd)
    }

    endTime.setMinutes(endTime.getMinutes() === 0 ? 0 : endTime.getMinutes() > 30 ? 60 : 30)
    endTime.setSeconds(0)

    return endTime
  }

  public get endTimeBasedOnWorkQueues() {
    const endTime = new Date(this.lastWorkQueueEndTime!)
    endTime.setMinutes(endTime.getMinutes() === 0 ? 0 : endTime.getMinutes() > 30 ? 60 : 30)
    endTime.setSeconds(0)

    return endTime
  }

  public get charTimes(): WorkQueueTiming[] {
    const startTime = this.startChartTime
    const endTime = this.isExporting ? this.endTimeBasedOnWorkQueues : this.endChartTime
    const times: WorkQueueTiming[] = [
      {
        time: this.startChartTime,
      },
    ]
    const gapBetweenTimes = this.isExporting && this.isCraneSplitForMoreThanOneDay ? 2 : 1

    let date = dayjs(startTime).add(gapBetweenTimes, 'hour').toDate()
    while (date.getTime() < endTime.getTime()) {
      const previousTime = times[times.length - 1]
      previousTime.size = this.getTimeSegmentBetweenTwoDates(date, previousTime.time)

      times.push({
        time: date,
      })

      date = dayjs(date).add(gapBetweenTimes, 'hour').toDate()
    }

    const previousTime = times[times.length - 1]
    previousTime.size = this.getTimeSegmentBetweenTwoDates(endTime, previousTime.time)

    times.push({
      time: endTime,
    })

    return times
  }

  public get workInstructionsLocated() {
    const containerStowageLocationDict: { [id: string]: any } = {}
    this.containerStowageLocationStore.items.forEach(item => {
      containerStowageLocationDict[item.id] = item
    })

    //ignore WIs that should be ignored in CraneSplit
    return this.workInstructionStore.items
      .filter(x => !x.ignoreInCraneSplit)
      .map(x => {
        return {
          ...x,
          containerStowageLocation: x.containerStowageLocationId
            ? containerStowageLocationDict[x.containerStowageLocationId]
            : null,
        }
      })
  }

  public get metrics(): Metrics {
    const metrics: Metrics = {
      containers: [],
      hours: [],
      moves: [],
    }

    this.craneIds.forEach(craneId => {
      const color = this.getColorByCraneId(craneId) ?? ''
      const label = this.equipments.find(x => x.id === craneId)?.name ?? ''

      const craneWorkQueues = this.calculatedBays
        .flatMap(x => x.workQueues)
        .filter(x => x.craneId === craneId)

      metrics.containers.push({
        color: color,
        label: label,
        total: _.sumBy(craneWorkQueues, x => x.containersAmount),
        completed: _.sumBy(craneWorkQueues, x => x.containersAmountCompleted),
      })

      metrics.hours.push({
        color: color,
        label: label,
        total: _.sumBy(craneWorkQueues, x =>
          dayjs(x.endDate).diff(dayjs(x.startTime), 'hour', true),
        ),
        completed: _.sumBy(craneWorkQueues, x => {
          const endDate = calculateEndTime(x.startTime, x.movesCompleted, x.processingTime)
          return dayjs(endDate).diff(dayjs(x.startTime), 'hour', true)
        }),
      })

      metrics.moves.push({
        color: color,
        label: label,
        total: _.sumBy(craneWorkQueues, x => x.moves),
        completed: _.sumBy(craneWorkQueues, x => x.movesCompleted),
      })
    })

    return metrics
  }

  public calculateMoves(
    workQueue: WorkQueue | WorkQueueDto,
    twinContainers?: boolean,
    newContainersAmount?: number,
  ) {
    const containersAmount =
      newContainersAmount && newContainersAmount < workQueue.containersAmount
        ? newContainersAmount
        : workQueue.containersAmount

    const workInstructions = this.workInstructionsLocated.filter(
      x => x.workQueueId === workQueue.id,
    )

    twinContainers = twinContainers ?? workQueue.isTwinContainers

    return this.calculateMovesByWorkInstructions(workInstructions, containersAmount, twinContainers)
  }

  public getTimeSegmentBetweenTwoDates(firstDate: Date, secondDate: Date) {
    const timeDifference = firstDate.getTime() - secondDate.getTime()
    return this.getSegmentSizeByMinutes(Math.round(timeDifference / 60000))
  }

  public getColorByCraneId(craneId: number) {
    const index = this.craneIds.findIndex(c => c === craneId)

    return CraneColors.at(index)
  }

  public clearWorkQueueStack() {
    this.stackPointer = 0
    this.workQueuesStack = []
  }

  public async saveCraneSplit(): Promise<string | null> {
    if (!this.vesselId || this.workQueuesStack.length === 0 || this.stackPointer === 0) {
      return null
    }

    const workQueueActions: WorkQueueActionDto[] = []

    for (let i = this.stackPointer - 1; i >= 0; i--) {
      const stackPointer = this.workQueuesStack[i]

      for (const changedWorkQueue of stackPointer.changes) {
        const workQueue =
          changedWorkQueue.action === WorkQueueActionType.Delete
            ? changedWorkQueue.previous
            : changedWorkQueue.current

        const workQueueAction = workQueueActions.find(
          x => x.workQueue.id === workQueue!.workQueue.id,
        )

        if (!workQueueAction) {
          workQueueActions.push({
            action: changedWorkQueue.action,
            workQueue: {
              ...workQueue!.workQueue,
              startTime: dayjs(workQueue!.workQueue?.startTime).toISOString(),
              workInstructionIds: this.workInstructionStore.items
                .filter(x => x.workQueueId === workQueue!.workQueue.id)
                .map(x => x.id),
            },
          } as WorkQueueActionDto)
        } else if (changedWorkQueue.action === WorkQueueActionType.Add) {
          workQueueAction.action = WorkQueueActionType.Add
        }
      }
    }

    const saveResponse = await this.workQueueStore.saveCraneSplit({
      carrierVisitId: this.vesselId,
      workQueueActions: workQueueActions,
    })

    if (saveResponse.validationMessage) {
      return saveResponse.validationMessage
    }

    await Promise.all([
      this.workQueueStore.load(this.vesselId),
      this.workInstructionStore.load(this.vesselId),
    ])

    this.clearWorkQueueStack()

    return null
  }

  public getWorkInstructionsIdsByWorkQueueId(workQueueId: number) {
    return this.workInstructionStore.items.filter(x => x.workQueueId === workQueueId).map(x => x.id)
  }

  private get oneHourSegmentSize() {
    let segmentSize = 100
    if (this.isExporting && this.isCraneSplitForMoreThanOneDay) {
      segmentSize = 50
    }

    return segmentSize
  }

  private get isCraneSplitForMoreThanOneDay() {
    return dayjs(this.endTimeBasedOnWorkQueues).diff(this.startChartTime, 'h') > 16
  }

  private getWorkQueuesForRedo(changes: WorkQueueStackChange[]) {
    let workQueues = this.workQueueStore.items.map(x => ({ ...x }))

    changes.forEach(x => {
      switch (x.action) {
        case WorkQueueActionType.Edit:
          if (x.current) {
            const index = workQueues.findIndex(wq => wq.id === x.current!.workQueue.id)
            workQueues[index] = x.current.workQueue
            this.updateWorkInstructionsByWorkQueueChange(x.current)
          }

          break
        case WorkQueueActionType.Add:
          if (x.current) {
            workQueues.push(x.current.workQueue)
            this.updateWorkInstructionsByWorkQueueChange(x.current)
          }

          break
        case WorkQueueActionType.Delete:
          if (x.previous) {
            workQueues = workQueues.filter(wq => wq.id !== x.previous!.workQueue.id)
          }

          break
      }
    })

    return workQueues
  }

  private getWorkQueuesForUndo(changes: WorkQueueStackChange[]) {
    let workQueues = this.workQueueStore.items.map(x => ({ ...x }))
    changes.forEach(x => {
      switch (x.action) {
        case WorkQueueActionType.Edit:
          if (x.previous) {
            const index = workQueues.findIndex(wq => wq.id === x.previous!.workQueue.id)
            workQueues[index] = x.previous.workQueue
            this.updateWorkInstructionsByWorkQueueChange(x.previous)
          }

          break
        case WorkQueueActionType.Add:
          if (x.current) {
            workQueues = workQueues.filter(wq => wq.id !== x.current!.workQueue.id)
          }

          break
        case WorkQueueActionType.Delete:
          if (x.previous) {
            workQueues.push(x.previous.workQueue)
            this.updateWorkInstructionsByWorkQueueChange(x.previous)
          }

          break
      }
    })

    return workQueues
  }

  private updateWorkInstructionsByWorkQueueChange(workQueue: WorkQueueChange) {
    if (workQueue.workInstructionsIds?.length && !!workQueue.workQueue) {
      const workInstructionsDict: { [id: string]: WorkInstructionDto } = {}
      this.workInstructionStore.items.forEach(item => {
        workInstructionsDict[item.id] = item
      })

      workQueue.workInstructionsIds.forEach(
        id => (workInstructionsDict[id].workQueueId = workQueue.workQueue.id),
      )
    }
  }

  private getSegmentSizeByMinutes(minutes: number) {
    return (minutes * this.oneHourSegmentSize) / 60
  }

  private getBayWorkQueues(bayWorkQueues: WorkQueueDto[]) {
    const items = bayWorkQueues.flatMap(workQueue => {
      const workInstructions = this.workInstructionsLocated.filter(
        x => x.workQueueId === workQueue.id,
      )

      if (!workInstructions.length) {
        return []
      }

      let startTimeDayjs = dayjs(workQueue.startTime)

      if (!startTimeDayjs.isValid()) {
        startTimeDayjs = dayjs(this.startChartTime)
      }

      const totalMoves = this.calculateMovesByWorkInstructions(
        workInstructions,
        workInstructions.length,
        workQueue.isTwinContainers,
      )

      const startTime = dayjs(startTimeDayjs).set('second', 0).toDate()

      const endDate = calculateEndTime(startTime, totalMoves, workQueue.processingTimeSeconds)

      const position = this.getTimeSegmentBetweenTwoDates(startTime, this.startChartTime)
      const height = this.getTimeSegmentBetweenTwoDates(endDate, startTime)

      const workInstructionsConfirmed = workInstructions.filter(x => x.isConfirmed)

      const movesCompleted = this.calculateMovesByWorkInstructions(
        workInstructionsConfirmed,
        workInstructionsConfirmed.length,
        workQueue.isTwinContainers,
      )

      const completeness = (movesCompleted * 100) / totalMoves

      return {
        id: workQueue.id,
        bay: workQueue.bay,
        containersAmount: workInstructions.length,
        containersAmountCompleted: workInstructionsConfirmed.length,
        containersLeft: workInstructions.length - workInstructionsConfirmed.length,
        operationType: workQueue.operationType,
        vesselArea: workQueue.vesselArea,
        carrierVisitId: workQueue.carrierVisitId,
        startTime: startTime,
        endDate: endDate,
        craneId: workQueue.craneId,
        crane: this.equipments.find(x => x.id === workQueue.craneId)?.name,
        processingTime: workQueue.processingTimeSeconds,
        order: workQueue.order,
        isTwinContainers: workQueue.isTwinContainers,
        moves: totalMoves,
        movesCompleted: movesCompleted,
        height: height,
        currentPosition: position,
        color: this.getColorByCraneId(workQueue.craneId),
        completeness: completeness,
        boundary: {
          top: 0,
          bottom: 0,
        },
        canEdit: (workQueue.bay === UnknownBayNumber || !movesCompleted) && this.canModify,
        isUnknownBay: workQueue.bay === UnknownBayNumber,
        isTwentySingleBay: workQueue.bayType === VesselBayType.TwentySingle,
      } as WorkQueue
    })

    this.fillSortedWorkQueuesData(items)

    return items
  }

  private fillSortedWorkQueuesData(bayWorkQueues: WorkQueue[]) {
    const sortedItems = _(bayWorkQueues)
      .sortBy(wq => wq.startTime)
      .value()

    sortedItems.forEach((wq, index) => {
      if (index === 0) {
        wq.boundary.top = this.getTimeSegmentBetweenTwoDates(
          dayjs(this.vesselAtaOrEta).set('second', 0).toDate(),
          this.startChartTime,
        )
        wq.boundary.startTime = this.startChartTime
      }

      if (index === sortedItems.length - 1) {
        wq.boundary.bottom =
          this.getTimeSegmentBetweenTwoDates(this.endChartTime, this.startChartTime) - wq.height

        wq.boundary.endTime = this.endChartTime
      }

      const nextWorkQueue = sortedItems[index + 1]
      if (nextWorkQueue) {
        wq.boundary.bottom = nextWorkQueue.currentPosition - wq.height
        wq.boundary.endTime = nextWorkQueue.startTime

        nextWorkQueue.boundary.top = wq.currentPosition + wq.height
        nextWorkQueue.boundary.startTime = wq.endDate

        nextWorkQueue.previousInBayId = +wq.id
        nextWorkQueue.isMergeable =
          wq.canEdit &&
          nextWorkQueue.bay === wq.bay &&
          nextWorkQueue.operationType === wq.operationType &&
          nextWorkQueue.vesselArea === wq.vesselArea
      }
    })
  }

  private calculateMovesByWorkInstructions(
    workInstructions: any[],
    containersAmount: number,
    acceptTwin: boolean,
  ) {
    return Math.ceil(
      _(workInstructions)
        .sortBy(x => x.workQueueOrder)
        .take(containersAmount)
        .map(x => (acceptTwin && x.containerStowageLocation?.hasTwin ? 0.5 : 1))
        .sum(),
    )
  }

  private fillBayAmount(bay: number, bayAmount: BayAmount, workQueue: WorkQueueDto) {
    const workInstructions = this.workInstructionsLocated.filter(
      x => x.workQueueId === workQueue.id,
    )

    const getWorkInstructionsLengthByBay = (bay: number) => {
      return workInstructions.filter(x => x.containerStowageLocation?.bay === bay).length
    }

    let bayAreaAmountToUpdate

    if (workQueue.vesselArea === VesselArea.Deck) {
      bayAreaAmountToUpdate = bayAmount.deck
    } else {
      bayAreaAmountToUpdate = bayAmount.hold
    }

    bayAreaAmountToUpdate.bayAmount += getWorkInstructionsLengthByBay(bay)
    bayAreaAmountToUpdate.nextBayAmount += getWorkInstructionsLengthByBay(bay + 1)
    bayAreaAmountToUpdate.previousBayAmount += getWorkInstructionsLengthByBay(bay - 1)

    bayAmount.totalMoves += workQueue.moves
  }

  private initProcessingTime() {
    const workQueue = this.workQueueStore.items.find(x => x.carrierVisitId === this.vesselId)
    let processingTimeInMinutes = 2

    if (workQueue) {
      processingTimeInMinutes = workQueue.processingTimeSeconds / 60
    }

    this.processingTime = processingTimeInMinutes
  }

  private areWorkQueuesEqual(wq1: WorkQueueDto, wq2: WorkQueueDto) {
    return (
      wq1.containersAmount === wq2.containersAmount &&
      wq1.isTwinContainers === wq2.isTwinContainers &&
      wq1.moves === wq2.moves &&
      wq1.craneId === wq2.craneId &&
      wq1.processingTimeSeconds === wq2.processingTimeSeconds &&
      dayjs(wq1.startTime).diff(wq2.startTime, 'm') === 0
    )
  }

  private updateWorkQueuesProcessingTime(processingTime: number) {
    const originalWorkQueues = [...this.workQueueStore.items]
    const workQueues = originalWorkQueues.map(x => ({ ...x }))
    const newProcessingTimeInSeconds = processingTime * 60

    this.calculatedBays.forEach(bay => {
      bay.workQueues.forEach((wq, index) => {
        const currentWorkQueue = workQueues.find(x => x.id === wq.id)
        const startTime = dayjs(currentWorkQueue?.startTime).toDate()

        if (wq.canEdit) {
          currentWorkQueue!.processingTimeSeconds = newProcessingTimeInSeconds
        }

        if (currentWorkQueue && index < bay.workQueues.length - 1) {
          const nextWorkQueue = bay.workQueues[index + 1]
          const timeDifference = dayjs(nextWorkQueue.startTime).diff(wq.endDate, 'minutes')
          const nextCurrentWQEndTime = calculateEndTime(
            startTime,
            wq.moves,
            newProcessingTimeInSeconds,
          )

          const nextWQStartTime = dayjs(nextCurrentWQEndTime)
            .add(timeDifference <= 0 ? 0 : timeDifference, 'minutes')
            .toString()

          workQueues.find(x => x.id === nextWorkQueue.id)!.startTime = nextWQStartTime
        }
      })
    })

    this.workQueueStore.updateStoreItems(workQueues)

    const changes: WorkQueueStackChange[] = originalWorkQueues.map(x => ({
      previous: { workQueue: { ...x } },
      current: { workQueue: { ...workQueues.find(wq => wq.id === x.id)! } },
      action: WorkQueueActionType.Edit,
    }))
    this.addWorkQueuesToStack(changes)
  }
}

export const calculateEndTime = (startDate: Date, moves: number, processingTime: number) => {
  return dayjs(startDate)
    .add(moves * processingTime, 'second')
    .toDate()
}
