import { VesselArea } from '@operations/app/api'
import { FormProps, SelectOption } from '@operations/app/models'
import { ControlledNumberField, ControlledTextField } from '@operations/components/form-controls'
import { ControlledDateTimePicker } from '@operations/components/form-controls/ControlledDateTimePicker'
import { ControlledSelect } from '@operations/components/form-controls/ControlledSelect'
import { ControlledSwitch } from '@operations/components/form-controls/ControlledSwitch'
import { WorkQueueBoundary } from '@operations/features/craneSplit/models/work-queue.model'
import useFormWithSchema from '@operations/hooks/use-form-with-schema.hook'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useTranslate } from '@tolgee/react'
import { enumToOptions } from '@operations/utils/enum-extensions'
import { useState } from 'react'
import { WorkQueueFormProfile } from './workqueue-form.profile'
import { schema } from './workqueue-form.scheme'

interface Props extends FormProps {
  craneOptions: SelectOption[]
  operationTypeOptions: SelectOption[]
  workQueueDefaultValues: WorkQueueFormProfile
  workQueueBoundary?: WorkQueueBoundary
  isUnknownBay?: boolean
  onSubmit: (formValues: WorkQueueFormProfile) => Promise<void> | void
  onCalculateMoves: (
    twinContainers?: boolean,
    newContainersAmount?: number,
  ) => Promise<number> | void
}

export const WorkQueueForm = ({
  id,
  workQueueDefaultValues,
  craneOptions,
  operationTypeOptions,
  workQueueBoundary,
  isUnknownBay,
  onSubmit,
  onCalculateMoves,
}: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const [containersDiff, setContainersDiff] = useState<number>(0)

  const { handleSubmit, formState, control, getValues, setValue } =
    useFormWithSchema<WorkQueueFormProfile>(
      schema(workQueueDefaultValues.containersAmount, workQueueBoundary?.startTime ?? new Date()),
      workQueueDefaultValues,
    )

  const handleContainerChange = async (value: string | null) => {
    calculateMoves(getValues('twinContainers'), value ? +value : 0)

    const maxAmount = formState.defaultValues?.containersAmount ?? 1

    if (!value || +value === 0 || +value >= maxAmount) {
      setContainersDiff(0)
      return
    }

    setContainersDiff(maxAmount - +value)
  }

  const handleTwinContainerChange = async (value: boolean) => {
    calculateMoves(value, getValues('containersAmount'))
  }

  const calculateMoves = async (twinContainers: boolean, newContainersAmount: number) => {
    setValue('totalMoves', (await onCalculateMoves(twinContainers, newContainersAmount)) ?? 0)
  }

  return (
    <Box
      id={id}
      component='form'
      noValidate
      autoComplete='off'
      sx={{ display: 'flex', flexDirection: 'column', gap: theme.customSpacing.l }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Typography variant='subtitle2'>{t('generalInformation', 'General Information')}</Typography>

      <Box sx={{ display: 'flex', gap: '.5rem' }}>
        <ControlledTextField<WorkQueueFormProfile>
          name='bay'
          formState={formState}
          control={control}
          disabled={true}
          label={t('bay', 'Bay')}
          fullWidth
        />

        <ControlledSelect<WorkQueueFormProfile>
          name='position'
          formState={formState}
          control={control}
          label={t('position', 'Position')}
          disabled={true}
          options={enumToOptions(VesselArea)}
        />
      </Box>

      <ControlledSelect<WorkQueueFormProfile>
        name='operationType'
        formState={formState}
        control={control}
        label={t('operation', 'Operation')}
        disabled={true}
        options={operationTypeOptions}
      />

      <Typography variant='subtitle2'>{t('containers', 'Containers')}</Typography>

      <Box>
        <ControlledNumberField<WorkQueueFormProfile>
          name='containersAmount'
          formState={formState}
          control={control}
          label={t('totalCo', 'Total co.')}
          fullWidth
          onChange={handleContainerChange}
          disabled={isUnknownBay}
        />

        {containersDiff > 0 && (
          <Typography
            variant='caption'
            color={theme.palette.secondary.main}
            marginTop={1}
            marginLeft={1}
          >
            {t(
              'newQueueWillBeCreatedForContainers',
              'A new queue will be created for {amount} containers.',
              {
                amount: containersDiff,
              },
            )}
          </Typography>
        )}
      </Box>

      <ControlledSwitch<WorkQueueFormProfile>
        name='twinContainers'
        formState={formState}
        control={control}
        label={t('twinContainer', 'Twin containers')}
        onChange={handleTwinContainerChange}
        disabled={isUnknownBay}
      />

      <ControlledNumberField<WorkQueueFormProfile>
        name='totalMoves'
        formState={formState}
        control={control}
        label={t('totalMoves', 'Total moves')}
        disabled={true}
        fullWidth
      />

      <Typography variant='subtitle2'>{t('timeline', 'Timeline')}</Typography>

      <ControlledDateTimePicker<WorkQueueFormProfile>
        name='startDate'
        formState={formState}
        control={control}
        label={t('startTime', 'Start time')}
      />

      <Typography variant='subtitle2'>{t('crane', 'Crane')}</Typography>

      <ControlledSelect<WorkQueueFormProfile>
        name='craneId'
        formState={formState}
        control={control}
        label='Crane'
        options={craneOptions}
      />
    </Box>
  )
}
