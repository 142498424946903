export enum EventTypes {
  BerthUpsertedEvent = 'BerthUpsertedEvent',
  ContainerEventsUpsertedEvent = 'ContainerEventsUpsertedEvent',
  ContainerUpsertedEvent = 'ContainerUpsertedEvent',
  FeatureFlagDeleted = 'FeatureFlagDeletedEvent',
  FeatureFlagUpserted = 'FeatureFlagUpsertedEvent',
  NonNumericOrderUpsertedEvent = 'NonNumericOrderUpsertedEvent',
  OrdersDeletedEvent = 'OrdersDeletedEvent',
  OrdersUpsertedEvent = 'OrdersUpsertedEvent',
  ReleaseOrdersUpsertedEvent = 'ReleaseOrdersUpsertedEvent',
  TenantUpsertedEvent = 'TenantUpsertedEvent',
  TruckDeletedEvent = 'TruckDeletedEvent',
  TruckUpsertedEvent = 'TruckUpsertedEvent',
  TruckVisitUpsertedEvent = 'TruckVisitUpsertedEvent',
  TruckVisitDeletedEvent = 'TruckVisitDeletedEvent',
  VesselDeletedEvent = 'VesselDeletedEvent',
  VesselUpsertedEvent = 'VesselUpsertedEvent',
  VesselVisitCreated = 'VesselVisitCreated',
  CarrierVisitDeleted = 'CarrierVisitDeleted',
  VesselVisitUpdated = 'VesselVisitUpdated',
  CarrierVisitConflictUpdated = 'CarrierVisitConflictUpdated',
  RailVisitCreated = 'RailVisitCreated',
  RailVisitDeleted = 'RailVisitDeleted',
  RailVisitUpdated = 'RailVisitUpdated',
  PackageUpserted = 'PackageUpserted',
  PackageDeleted = 'PackageDeleted',
  RailTrackUpsertedEvent = 'RailTrackUpsertedEvent',
  RailTrackDeletedEvent = 'RailTrackDeletedEvent',
  RailcarTrackPositionUpsertedEvent = 'RailcarTrackPositionUpsertedEvent',
  RailcarTrackPositionDeletedEvent = 'RailcarTrackPositionDeletedEvent',
  RailcarDeletedEvent = 'RailcarDeletedEvent',
  RailcarUpsertedEvent = 'RailcarUpsertedEvent',
}
