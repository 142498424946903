import {
  AllocationsCreateManualRequest,
  YardBlockSlotOccupanciesCreateReservationRequest,
  YardPositionDto,
} from '@storage/app/api'
import {
  ContainerTurnoverFilterFormMapper,
  ContainerTurnoversFilterFormProfile,
} from '../container-turnovers-filter-form'
import { ManualPlanningFormProfile } from './manual-reservation-form.profile'

export const getYardPosition = (manualPosition: string): YardPositionDto => {
  const parts = manualPosition.split('.')
  const [block, bay, row] = parts

  return {
    block,
    bay,
    row,
  }
}

const mapFormValuesToReservationDto = (
  containerTurnoverId: string,
  blockName: string,
  bayName: string,
  rowName: string,
  tierString: string,
  forceCreation = false,
): YardBlockSlotOccupanciesCreateReservationRequest => {
  const tier = parseInt(tierString, 10)

  return {
    containerTurnoverId,
    yardPosition: {
      block: blockName,
      bay: bayName,
      row: rowName,
      tier,
    },
    forceCreation,
  }
}

const mapFormValuesToSingleContainerAllocationDto = (
  containerTurnoverId: string,
  { manualPosition }: ManualPlanningFormProfile,
  forceCreation = false,
): AllocationsCreateManualRequest => {
  return {
    containerTurnoverId: containerTurnoverId,
    yardPosition: getYardPosition(manualPosition),
    forceCreation,
  }
}

const mapFormValuesToAllocationDto = (
  filterFormProfile: ContainerTurnoversFilterFormProfile,
  { manualPosition }: ManualPlanningFormProfile,
  forceCreation = false,
): AllocationsCreateManualRequest => {
  return {
    filter: ContainerTurnoverFilterFormMapper.mapFormValuesToFilterDto(filterFormProfile),
    yardPosition: getYardPosition(manualPosition),
    forceCreation,
  }
}

export const ManualReservationFormMapper = {
  mapFormValuesToReservationDto,
  mapFormValuesToAllocationDto,
  mapFormValuesToSingleContainerAllocationDto,
}
