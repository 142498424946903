import {
  ContainerTurnoverGroupItemDto,
  ContainerTurnoverGroupSelector,
  YardBlockInformationDto,
} from '@storage/app/api'
import { EntityStore } from '@storage/app/store/entity.store'
import { action, computed, makeObservable, observable } from 'mobx'
import { ComponentStatus } from '@storage/helpers/enums'
import { YardBlockService } from '@storage/services/yard-blocks-service'

export const getProgressBarValue = (total: number, num: number) => (num / total) * 100

export const getOccupiedCapacityState = (total: number, num: number) => {
  const progress = getProgressBarValue(total, num)

  switch (true) {
    case progress <= 33:
      return ComponentStatus.Success
    case progress <= 66:
      return ComponentStatus.Warning
    default:
      return ComponentStatus.Error
  }
}

export interface IYardBlockSummary extends YardBlockInformationDto {
  groupedBySize: ContainerTurnoverGroupItemDto[]
  groupedByOperator: ContainerTurnoverGroupItemDto[]
  emptyCount: number
  fullCount: number
}

export interface IYardSummary {
  total: number
  occupied: number
  reserved: number
  free: number
  yardState: ComponentStatus
}

export class YardBlockSummaryStore extends EntityStore<YardBlockInformationDto> {
  showAll = false
  isBlockDetailsButtonClicked = false
  blockSummary: IYardBlockSummary = {
    groupedBySize: [],
    groupedByOperator: [],
    emptyCount: 0,
    fullCount: 0,
    id: '',
    name: '',
    stackHeight: 0,
    slots: 0,
    available: 0,
    occupiedByContainer: 0,
    occupiedByReservation: 0,
    free: 0,
    occupied: 0,
    groupedBy: [],
  }

  constructor(private readonly _yardBlockService: YardBlockService) {
    super()

    makeObservable(this, {
      showAll: observable,
      isBlockDetailsButtonClicked: observable,
      blockSummary: observable,
      expand: action,
      collapse: action,
      showExpand: computed,
      openContainerDetailsPopup: action,
      closeContainerDetailsPopup: action,
      getYardBlockSummary: action,
      blocks: computed,
    })
  }

  expand = () => {
    this.showAll = true
  }

  collapse = () => {
    this.showAll = false
  }

  openContainerDetailsPopup = () => {
    this.isBlockDetailsButtonClicked = true
  }
  closeContainerDetailsPopup = () => {
    this.isBlockDetailsButtonClicked = false
  }

  get showExpand() {
    return this.entries.length > 0
  }

  public async load(id: string): Promise<void> {
    const { data: summary } = await this._yardBlockService.getYardBlockSummary(id)

    this.addOrUpdate(summary)
  }

  public async loadList(): Promise<void> {
    const { data: yardBlocks } = await this._yardBlockService.getYardBlockSummaries()

    this.setAll(yardBlocks)
  }

  public async delete(yardBlockId: string): Promise<void> {
    this.remove(yardBlockId)
  }

  public update(id?: string | null) {
    if (id) {
      this.load(id)
    } else {
      this.loadList()
    }
  }

  public groupBy(id: string, selector: ContainerTurnoverGroupSelector) {
    const summary = this.data.get(id)
    return summary?.groupedBy.find(s => s.selector === selector)
  }

  get blocks() {
    return this.showAll
      ? this.entries.map(block => {
          return {
            ...block,
            state: getOccupiedCapacityState(block.slots, block.occupied),
            progressBarValue: getProgressBarValue(block.slots, block.occupied),
          }
        })
      : []
  }

  get yardSummary(): IYardSummary {
    const result = this.entries.reduce(
      (summary: IYardSummary, block) => {
        return {
          total: summary.total + block.available,
          occupied: summary.occupied + block.occupiedByContainer,
          reserved: summary.reserved + block.occupiedByReservation,
          free: summary.free + block.free,
          yardState: getOccupiedCapacityState(
            summary.total + block.available,
            summary.occupied + block.occupiedByContainer,
          ),
        }
      },
      { total: 0, occupied: 0, reserved: 0, free: 0, yardState: 0 },
    )

    return result
  }

  getYardBlockSummary(id: string): IYardBlockSummary | undefined {
    const summary = this.data.get(id)
    if (!summary) return undefined

    this.blockSummary = {
      ...summary,
      emptyCount:
        summary.groupedBy
          .find(s => s.selector === ContainerTurnoverGroupSelector.CargoStatus)
          ?.items.find(e => e.key.toLowerCase() === 'true')?.value ?? 0,
      fullCount:
        summary.groupedBy
          .find(s => s.selector === ContainerTurnoverGroupSelector.CargoStatus)
          ?.items.find(e => e.key.toLowerCase() === 'false')?.value ?? 0,
      groupedByOperator:
        summary.groupedBy.find(s => s.selector === ContainerTurnoverGroupSelector.ContainerOperator)
          ?.items ?? [],
      groupedBySize:
        summary.groupedBy.find(s => s.selector === ContainerTurnoverGroupSelector.Size)?.items ??
        [],
    }

    return this.blockSummary
  }
}
