import { Tab, Tabs } from '@mui/material'
import { CarrierVisitStatus } from '@planning/app/api'
import { VerticalOverflowBox } from '@planning/components/VerticalOverflowBox'
import { GateClerkTruckVisitEditStore } from '@planning/rt-stores/gateClerk/GateClerkTruckVisitEditStore'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { TabPanel } from '../../../components/TabPanel'
import { TruckAppointmentNNROrderListMobile } from './TruckAppointmentNNROrderListMobile'
import { TruckAppointmentOrderListMobile } from './TruckAppointmentOrderListMobile'

type Props = {
  editStore: GateClerkTruckVisitEditStore
  onBack: () => void
}

export const TruckAppointmentOrderSelectionTabs = observer(({ editStore, onBack }: Props) => {
  const [selectedTab, setSelectedTab] = useState<number>(0)

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }
  const { t } = useTranslate()

  return (
    <>
      <Tabs value={selectedTab} onChange={(_, newValue) => setSelectedTab(newValue)}>
        <Tab label={t('numeric', 'Numeric')} {...a11yProps(0)} />
        {editStore.truckVisit?.status === CarrierVisitStatus.Expected && (
          <Tab label={t('nonNumeric', 'Non-numeric')} {...a11yProps(1)} />
        )}
      </Tabs>
      <TabPanel value={selectedTab} index={0}>
        <VerticalOverflowBox sx={{ height: '100%', minWidth: '20rem' }}>
          <TruckAppointmentOrderListMobile editStore={editStore} onBack={onBack} />
        </VerticalOverflowBox>
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <VerticalOverflowBox sx={{ height: '100%', minWidth: '20rem' }}>
          <TruckAppointmentNNROrderListMobile editStore={editStore} onBack={onBack} />
        </VerticalOverflowBox>
      </TabPanel>
    </>
  )
})
