import { Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { DangerousGoodsStackingRuleDto } from '@storage/app/api'
import { FormProps } from '@storage/app/models'
import { ControlledTextField } from '@storage/components/form-controls'
import { ControlledSwitch } from '@storage/components/form-controls/ControlledSwitch'
import useFormWithSchema from '@storage/hooks/use-form-with-schema.hook'
import { useTranslate } from '@tolgee/react'
import { useEffect } from 'react'
import { mapDGStackingRuleDtoToDGStackingRuleFormProfile } from './dg-stacking-rules-table-form.mapper'
import { defaultValues, DGStackingRuleFormProfile } from './dg-stacking-rules-table-form.profile'
import { schema } from './dg-stacking-rules-table-form.scheme'

interface Props extends FormProps {
  dgStackingRuleDto: DangerousGoodsStackingRuleDto
  onSubmit: (formValues: DGStackingRuleFormProfile) => Promise<void> | void
}

const DGStackingRulesTableForm = ({ id, onSubmit, dgStackingRuleDto }: Props) => {
  const { handleSubmit, control, formState, reset } = useFormWithSchema<DGStackingRuleFormProfile>(
    schema,
    defaultValues,
  )

  const { t } = useTranslate()

  useEffect(() => {
    if (dgStackingRuleDto) reset(mapDGStackingRuleDtoToDGStackingRuleFormProfile(dgStackingRuleDto))
  }, [reset, dgStackingRuleDto])

  return (
    <Box
      id={id}
      component='form'
      noValidate
      autoComplete='off'
      onSubmit={handleSubmit(onSubmit)}
      my={1}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Stack direction='row' alignItems='center' justifyContent='space-between'>
          <Typography>
            {t('isStackingContainerOnTopAllowed', 'Is stacking any container on top allowed?')}
          </Typography>
          <ControlledSwitch
            formState={formState}
            control={control}
            label=''
            name='isStackingContainerOnTopAllowed'
          />
        </Stack>

        <Stack direction='row' alignItems='center' justifyContent='space-between'>
          <Typography>
            {t('maxStackFormInputDescription', 'Max tier IMO class can be stacked on')}
          </Typography>
          <ControlledTextField
            formState={formState}
            control={control}
            type='number'
            label={t('maxStack', 'Max Stack')}
            name='maxStack'
            sx={{ width: '100px' }}
          />
        </Stack>
      </Box>
    </Box>
  )
}

export default DGStackingRulesTableForm
