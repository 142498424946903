import { CommodityDto } from '@billing/app/api'
import { CommodityStore } from '@billing/stores/CommodityStore'
import { action, computed, makeObservable, observable } from 'mobx'

export class CommodityListUIStore {
  searchCommodity = ''

  constructor(private commodityStore: CommodityStore) {
    makeObservable(this, {
      searchCommodity: observable,

      commodities: computed,

      setSearchCommodity: action,
    })
  }

  public setSearchCommodity(commodity: string) {
    if (this.searchCommodity !== commodity) {
      this.searchCommodity = commodity
    }
  }

  public get commodities(): CommodityDto[] {
    return [...this.commodityStore.items]
      .sort(i => i.id)
      .filter(x => this.doesCommodityHaveName(x, this.searchCommodity))
  }

  private doesCommodityHaveName(commodity: CommodityDto, name?: string) {
    return !name || commodity.name.toLowerCase().includes(name.toLowerCase())
  }
}
