import {
  BerthDto,
  CarrierVisitDto,
  ContainerTurnoverDto,
  GeneralCargoStockDto,
  ReloadStacksNotification,
  YardBlockDto,
} from '@storage/app/api'
import { BaseHubConnection } from './base.hub-connection'

const METHODS = {
  BerthCreated: 'BerthCreated',
  BerthUpdated: 'BerthUpdated',
  BerthDeleted: 'BerthDeleted',

  YardBlockCreated: 'YardBlockCreated',
  YardBlockUpdated: 'YardBlockUpdated',
  YardBlockDeleted: 'YardBlockDeleted',

  ReloadStacks: 'ReloadStacks',
  ReloadYardBlock: 'ReloadYardBlock',
  ReloadRules: 'ReloadRules',

  CarrierVisitUpserted: 'CarrierVisitUpserted',
  CarrierVisitsDeleted: 'CarrierVisitsDeleted',

  OrdersUpserted: 'OrdersUpserted',
  TurnoverCharacteristicsChanged: 'TurnoverCharacteristicsChanged',
  TurnoverPositionChanged: 'TurnoverPositionChanged',

  GeneralCargoStockUpserted: 'GeneralCargoStockUpserted',
  GeneralCargoStockDeleted: 'GeneralCargoStockDeleted',
}

export interface YardManagementHubConnection extends BaseHubConnection {
  onBerthCreated: (action: (berth: BerthDto) => void) => void
  onBerthUpdated: (action: (berth: BerthDto) => void) => void
  onBerthDeleted: (action: (berthId: string) => void) => void

  onYardBlockCreated: (action: (yardBlock: YardBlockDto) => void) => void
  onYardBlockUpdated: (action: (yardBlockId: string) => void) => void
  onYardBlockDeleted: (action: (yardBlockId: string) => void) => void

  onReloadStacks: (action: (notification: ReloadStacksNotification) => void) => void
  onReloadYardBlock: (action: (yardBlockId: string) => void) => void
  onReloadRules: (action: () => void) => void

  onCarrierVisitUpserted: (action: (carrierVisit: CarrierVisitDto) => void) => void
  onCarrierVisitsDeleted: (action: (carrierVisitIds: number[]) => void) => void

  onOrdersUpserted: (action: (containerTurnovers: ContainerTurnoverDto[]) => void) => void

  onGeneralCargoStockUpserted: (action: (stock: GeneralCargoStockDto) => void) => void
  onGeneralCargoStockDeleted: (action: (stockId: string) => void) => void

  onTurnoverCharacteristicsChanged: (
    action: (containerTurnover: ContainerTurnoverDto) => void,
  ) => void
}

export class YardManagementHubConnectionImplementation
  extends BaseHubConnection
  implements YardManagementHubConnection
{
  constructor() {
    super('yard-management-hub')
  }
  public onBerthCreated(action: (berthDto: BerthDto) => void) {
    this._connection.on(METHODS.BerthCreated, action)
  }

  public onBerthUpdated(action: (berth: BerthDto) => void) {
    this._connection.on(METHODS.BerthUpdated, action)
  }

  public onBerthDeleted(action: (berthId: string) => void) {
    this._connection.on(METHODS.BerthDeleted, action)
  }

  public onYardBlockCreated(action: (yardBlock: YardBlockDto) => void) {
    this._connection.on(METHODS.YardBlockCreated, action)
  }

  public onYardBlockUpdated(action: (yardBlockId: string) => void) {
    this._connection.on(METHODS.YardBlockUpdated, action)
  }

  public onYardBlockDeleted(action: (yardBlockId: string) => void) {
    this._connection.on(METHODS.YardBlockDeleted, action)
  }

  public onReloadStacks(action: (notification: ReloadStacksNotification) => void) {
    this._connection.on(METHODS.ReloadStacks, action)
  }

  public onReloadRules(action: () => void) {
    this._connection.on(METHODS.ReloadRules, action)
  }

  public onCarrierVisitUpserted(action: (carrierVisit: CarrierVisitDto) => void) {
    this._connection.on(METHODS.CarrierVisitUpserted, action)
  }

  public onCarrierVisitsDeleted(action: (carrierVisitIds: number[]) => void) {
    this._connection.on(METHODS.CarrierVisitsDeleted, action)
  }

  public onReloadYardBlock(action: (yardBlockId: string) => void) {
    this._connection.on(METHODS.ReloadYardBlock, action)
  }

  public onOrdersUpserted(action: (containerTurnovers: ContainerTurnoverDto[]) => void) {
    this._connection.on(METHODS.OrdersUpserted, action)
  }

  public onTurnoverCharacteristicsChanged(
    action: (containerTurnover: ContainerTurnoverDto) => void,
  ) {
    this._connection.on(METHODS.TurnoverCharacteristicsChanged, action)
  }

  public onGeneralCargoStockUpserted(action: (stock: GeneralCargoStockDto) => void) {
    this._connection.on(METHODS.GeneralCargoStockUpserted, action)
  }

  public onGeneralCargoStockDeleted(action: (stockId: string) => void) {
    this._connection.on(METHODS.GeneralCargoStockDeleted, action)
  }
}

export const yardManagementHubConnection: YardManagementHubConnection =
  new YardManagementHubConnectionImplementation()
