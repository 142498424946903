import { AlertColor } from '@mui/material'
import axios, { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { ProblemDetails } from './app/api'
import { isProblemDetails } from './app/utils'

export const formatDate = (date: any) => {
  if (!date) {
    return
  }

  return dayjs(date).toDate().toLocaleString([], { dateStyle: 'medium' })
}

export const formatDayAndNumber = (date: any) => {
  if (!date) {
    return
  }

  return dayjs(date).format('ddd DD')
}

export const formatMonthAndYear = (date: any) => {
  if (!date) {
    return
  }

  return dayjs(date).format('MMMM YYYY')
}

export const formatTime = (date: any) => {
  if (!date) {
    return
  }

  return dayjs(date).toDate().toLocaleString([], { timeStyle: 'short' })
}

export const formatDateTime = (date: any) => {
  if (!date) {
    return
  }

  return dayjs(date).toDate().toLocaleString([], { dateStyle: 'short', timeStyle: 'short' })
}

export const parseDate = (date: any) => {
  if (!date) {
    return
  }

  return dayjs(date)
}

export interface IDeleteRecordActions {
  openConfirmDialog: (open: boolean, content?: string) => Promise<boolean>
  deleteRecord: (id: number) => Promise<void>
  showAlert: (severity?: AlertColor, message?: string) => void
  deletedCallback?: () => Promise<void>
}

export const useDeleteRecord = (actions: IDeleteRecordActions, recordName?: string) => {
  return async (id: number) => {
    const confirmed = await actions.openConfirmDialog(
      true,
      `Do you want to delete ${recordName ?? 'record'}?`,
    )
    if (confirmed) {
      try {
        await actions.deleteRecord(id)
        actions.showAlert('success', 'Vessel deleted successfully')

        if (actions.deletedCallback) {
          await actions.deletedCallback()
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError
          if (axiosError.response && isProblemDetails(axiosError.response.data)) {
            actions.showAlert(
              'error',
              (axiosError.response.data as ProblemDetails).detail ?? 'Failed to delete',
            )
          } else {
            actions.showAlert('error', 'Failed to delete')
          }
        }
      }
    }
  }
}
