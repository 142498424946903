import {
  OrderCustomerSequenceDto,
  OrderResponseDto,
  UpdateCustomerSequenceCommand,
} from '@planning/app/api'
import { orderService } from '@planning/services'
import { Entities, Group, GroupMap, Item, ItemMap, SequenceMap } from '@tom-ui/ui'
import _ from 'lodash'
import { computed, makeObservable } from 'mobx'
import { ISortDelegate, LocalDataStoreWrapper, PaginatedLocalStore } from '../PaginatedLocalStore'
import { IGroupingStore } from './IGroupingStore'

const orderSortingDelegate: ISortDelegate<OrderResponseDto> = (_, a, b) => {
  return (a.containerNumber ?? '').localeCompare(b.containerNumber ?? '')
}

export class OrderGroupingStore
  extends PaginatedLocalStore<OrderResponseDto>
  implements IGroupingStore
{
  defaultGroupId = 'ungrpd'

  constructor(getFunc: () => OrderResponseDto[], fetchFunc?: (query: any) => Promise<void>) {
    super(new LocalDataStoreWrapper<OrderResponseDto>(getFunc, fetchFunc), orderSortingDelegate)

    makeObservable(this, {
      entities: computed,
    })

    // [OCTA-806] TODO: refactor
    this.setPageSize(10000)
  }

  private isUngrouped = (groupName: string) => groupName === 'null' || groupName === 'undefined'

  get entities(): {
    groupOrder: string[]
    items: ItemMap
    groups: GroupMap
  } {
    const itemsByCustomerGrouping = _(this.pageItems)
      .groupBy(o => o.customerGrouping)
      .value()

    const groups = _(itemsByCustomerGrouping).reduce(
      (previous: GroupMap, current, key): GroupMap => {
        if (this.isUngrouped(key)) return previous

        previous[key] = {
          id: key,
          name: key,
          ItemIds: current
            .sort((a, b) => ((a.groupingSequence ?? -1) < (b.groupingSequence ?? -1) ? -1 : 1))
            .map(o => `${o.id}`),
          sequenced: current.some(o => o.groupingSequence),
          note: '',
        } as Group

        previous[key].sequence = current.reduce((prev: SequenceMap, cur, index) => {
          const isSequenced =
            cur.groupingSequence !== null &&
            cur.groupingSequence !== undefined &&
            cur.groupingSequence >= 0

          prev[cur.id] = isSequenced ? cur.groupingSequence ?? 0 : index

          return prev
        }, {})

        return previous
      },
      {},
    )

    const groupOrder = _(itemsByCustomerGrouping)
      .keys()
      .filter(key => !this.isUngrouped(key))
      .sort((a, b) => a.localeCompare(b))
      .value()

    const entityItems = this.pageItems.reduce((previous: ItemMap, current): ItemMap => {
      const key = `${current.id}`
      previous[key] = {
        id: key,
        content: current,
      } as Item
      return previous
    }, {})

    return {
      groupOrder: groupOrder,
      items: entityItems,
      groups: groups,
    }
  }

  saveChanges = async (entities: Entities) => {
    if (!entities?.groups) return

    const cmd = this.mapToUpdateCustomerSequenceCommand(entities.groups)

    await orderService.updateCustomerSequence(cmd)
  }

  mapToUpdateCustomerSequenceCommand = (group: GroupMap) => {
    return {
      orders: _(group)
        .flatMap(grp => {
          const customerGrouping = grp.defaultGroup ? null : grp.name
          const isSequencedGroup = customerGrouping && grp.sequenced

          return grp.ItemIds.map(
            item =>
              ({
                id: Number(item),
                groupingSequence: isSequencedGroup ? grp.sequence[item] : null,
                customerGrouping: customerGrouping,
              }) as OrderCustomerSequenceDto,
          )
        })
        .value(),
    } as UpdateCustomerSequenceCommand
  }

  reset() {
    this.setFilter('')
  }
}
