import { CarrierVisitDto, CarrierVisitsApi } from '@billing/app/api'
import { createApiClient } from '@billing/app/http-client'
import { BaseEntityStore } from './base/BaseEntityStore'

export class CarrierVisitStore extends BaseEntityStore<CarrierVisitDto> {
  async getVesselVisits(onlyDepartedVisit = false) {
    const { data } = await createApiClient(CarrierVisitsApi).getVesselVisits(onlyDepartedVisit)
    this.updateStoreItems(data)
  }

  async getTruckVisits(notDepartedVisit = false) {
    const { data } = await createApiClient(CarrierVisitsApi).getTruckVisits(notDepartedVisit)
    this.updateStoreItems(data)
  }
}
