import { RequiredFeatureFlag } from '@host/app/routes'
import { Box, ListItemButton, ListItemText, alpha, styled } from '@mui/material'
import { useOpenFeatureClient } from '@openfeature/react-sdk'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { authStore } from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { NavItemProps } from '../types'
import { NavArrow } from './NavArrow'

const StyledIconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: 0,
  width: theme.customSpacing.l,
  height: theme.customSpacing.l,
}))

const DotBox = styled(Box)(({ theme }) => ({
  width: 5,
  height: 5,
  borderRadius: theme.customRadius.oval,
}))

const NavItemContent = observer(
  ({
    route,
    depth,
    open,
    active,
    isExternalLink,
    hasChild,
    isMenuMobileOpen,
    onClick,
    onClose,
  }: NavItemProps) => {
    const featureFlagClient = useOpenFeatureClient()
    const theme = useMinimalsTheme()
    const { t } = useTranslate()
    const navigate = useNavigate()

    const checkHidingMenuByRequiredFeatureFlag = (requiredFeatureFlag?: RequiredFeatureFlag) => {
      return (
        requiredFeatureFlag &&
        featureFlagClient.getBooleanValue(requiredFeatureFlag.name, false) !==
          requiredFeatureFlag.enable
      )
    }

    if (
      checkHidingMenuByRequiredFeatureFlag(route.requiredFeatureFlag) ||
      (route.requiredPermission && !authStore.hasPermission(route.requiredPermission))
    ) {
      return <></>
    }

    const handleClick = (event: any) => {
      if (isExternalLink) {
        window.open(route.path, '_blank')
      } else if (route.children && onClick) {
        onClick(event)
      } else {
        if (onClose) {
          onClose()
        }
        navigate(route.path)
      }
    }

    const subItem = depth !== 1

    const activeStyle = {
      backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      color: theme.palette.primary.main,
      'svg > *': {
        fill: theme.palette.primary.main,
      },
    }

    return (
      <ListItemButton
        onClick={handleClick}
        sx={{
          position: 'relative',
          padding: theme.customSpacing.xxs,
          color: theme.palette.grey[800],
          flexDirection: isMenuMobileOpen ? 'row' : 'column',
          alignItems: 'center',
          justifyContent: isMenuMobileOpen ? 'space-between' : 'center',
          transition: '.4s all ease-in-out',
          maxHeight: '44px',
          borderBottom: subItem ? `1px solid rgba(145, 158, 171, 0.24)` : undefined,
          borderRadius: subItem ? undefined : theme.customRadius.medium,
          gap: isMenuMobileOpen ? theme.customSpacing.m : 0,

          // Active
          ...(active && {
            ...activeStyle,
            '&:hover': {
              ...activeStyle,
            },
          }),

          // Child
          ...(!active &&
            hasChild &&
            open && {
              backgroundColor: theme.palette.grey[200],
            }),
        }}
        data-cy={`menu-${route.id}-btn`}
      >
        {route.icon && <StyledIconContainer>{route.icon}</StyledIconContainer>}

        {subItem && isMenuMobileOpen && (
          <StyledIconContainer>
            <DotBox
              sx={{
                backgroundColor: active ? theme.palette.primary.main : theme.palette.common.black,
              }}
            />
          </StyledIconContainer>
        )}

        <ListItemText
          primary={t(route.title)}
          primaryTypographyProps={{
            noWrap: true,
            component: 'span',
            variant: isMenuMobileOpen ? 'subtitle1' : subItem ? 'body1' : 'mini',
            textAlign: 'start',
            padding: subItem ? `${theme.customSpacing.s} ${theme.customSpacing.m}` : undefined,
          }}
        />

        {!!route.children && <NavArrow open={open} small={!isMenuMobileOpen} />}
      </ListItemButton>
    )
  },
)

export default NavItemContent
