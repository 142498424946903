import {
  CreateYardBlockBayDto,
  UpdateYardBlockBayDto,
  YardBlockBayDto,
  YardBlockBaysApi,
} from '@storage/app/api'
import { SelectOption } from '@storage/app/models'
import { EntityStore } from '@storage/app/store/entity.store'
import { YardBlockSlotStore } from './yard-block-slot.store'

export class YardBlockBayStore extends EntityStore<YardBlockBayDto> {
  constructor(
    private api: YardBlockBaysApi,
    private yardBlockSlotStore: YardBlockSlotStore,
  ) {
    super()
  }

  public projectToOptions(entries: YardBlockBayDto[]): SelectOption[] {
    return entries.map(({ id, name }) => ({
      label: name,
      value: id,
    }))
  }

  public filterByYardBlock(yardBlockId: string): YardBlockBayDto[] {
    return this.entries.filter(bay => bay.yardBlockId === yardBlockId)
  }

  sorter(entityA: YardBlockBayDto, entityB: YardBlockBayDto): number {
    return entityA.sequenceNumber - entityB.sequenceNumber
  }

  public async loadList(): Promise<void> {
    const { data: yardBlockBays } = await this.api.get()

    this.setAll(yardBlockBays)
  }

  public async loadForYardBlock(yardBlockId: string): Promise<void> {
    const { data: yardBlockBays } = await this.api.get({ yardBlockId })

    const bays = this.getForBlock(yardBlockId)
    bays.forEach(bay => this.remove(bay.id))

    this.addOrUpdateMany(yardBlockBays)
  }

  public async add(dto: CreateYardBlockBayDto): Promise<void> {
    const { data: yardBlockBay } = await this.api.create({ yardBlockBaysCreateRequest: dto })

    this.addOrUpdate(yardBlockBay)
  }

  public async update(dto: UpdateYardBlockBayDto): Promise<void> {
    const { data: yardBlockBay } = await this.api.update({ yardBlockBaysUpdateRequest: dto })

    this.addOrUpdate(yardBlockBay)
  }

  public async lock(id: string, isLocked: boolean): Promise<void> {
    const { data: yardBlockSlots } = await this.api.setLockStatus({
      lockStatus: isLocked,
      yardBlockBayId: id,
    })

    this.addOrUpdate({
      ...this.data.get(id)!,
      isLocked,
    })

    this.yardBlockSlotStore.addOrUpdateList(yardBlockSlots)
  }

  public async delete(yardBlockBayId: string): Promise<void> {
    await this.api._delete({ yardBlockBayId })

    this.remove(yardBlockBayId)
  }

  public get(id: string) {
    return this.data.get(id)
  }

  public getForBlock(blockId: string) {
    const bays = this.entries.filter(bay => bay.yardBlockId === blockId)
    bays.sort((b1, b2) => b1.sequenceNumber - b2.sequenceNumber)

    return bays
  }

  public getSlots(id: string) {
    return this.yardBlockSlotStore.entries.filter(s => s.yardBlockBayId === id)
  }
}
