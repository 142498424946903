import { observer } from 'mobx-react-lite'
import { NavigationStack } from '@tom-ui/planning'
import { GateClerkHome } from './GateClerkHome'

export const GateClerkPage = observer(() => {
  return (
    <NavigationStack>
      <GateClerkHome />
    </NavigationStack>
  )
})
