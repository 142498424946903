import { ContainerProps } from '@mui/material'
import { Theme } from '@mui/material/styles'

// ----------------------------------------------------------------------

export default function Container(theme: Theme) {
  return {
    MuiContainer: {
      styleOverrides: {
        root: ({ ownerState }: { ownerState: ContainerProps }) => ({
          ...(ownerState && {
            paddingLeft: theme.customSpacing.m,
            paddingRight: theme.customSpacing.m,
            [theme.breakpoints.up('sm')]: {
              paddingLeft: theme.customSpacing.l,
              paddingRight: theme.customSpacing.l,
            },
            maxWidth: `100% !important`,
          }),
        }),
      },
    },
  }
}
