import { FC } from 'react'

export const IconHighCube: FC<{ zoom?: number; size?: number }> = ({ zoom, size }) => {
  return (
    <svg
      width={size ?? '24'}
      height={size ?? '24'}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ transform: `scale(${(zoom ?? 100) / 100})` }}
    >
      <rect
        y='24'
        width='24'
        height='24'
        rx='4'
        transform='rotate(-90 0 24)'
        fill='#919EAB'
        fillOpacity='0.24'
      />
      <rect
        x='0.25'
        y='23.75'
        width='23.5'
        height='23.5'
        rx='3.75'
        transform='rotate(-90 0.25 23.75)'
        stroke='#919EAB'
        strokeOpacity='0.24'
        strokeWidth='0.5'
      />
      <path
        d='M10.4133 12.331H4.95329V17H3.65829V6.878H4.95329V11.19H10.4133V6.878H11.7083V17H10.4133V12.331ZM18.101 6.738C18.815 6.738 19.4474 6.88733 19.998 7.186C20.5487 7.48 20.992 7.87433 21.328 8.369C21.664 8.86367 21.8624 9.40733 21.923 10H20.544C20.4694 9.60333 20.327 9.244 20.117 8.922C19.907 8.6 19.6317 8.34333 19.291 8.152C18.9504 7.96067 18.542 7.865 18.066 7.865C17.4547 7.865 16.9367 8.00967 16.512 8.299C16.092 8.58367 15.7724 9.02467 15.553 9.622C15.3384 10.2193 15.231 10.9847 15.231 11.918C15.231 13.3273 15.4714 14.3633 15.952 15.026C16.4374 15.684 17.142 16.013 18.066 16.013C18.542 16.013 18.9504 15.9127 19.291 15.712C19.6317 15.5113 19.907 15.243 20.117 14.907C20.327 14.571 20.4694 14.207 20.544 13.815H21.923C21.8717 14.2443 21.755 14.6597 21.573 15.061C21.3957 15.4577 21.1507 15.8123 20.838 16.125C20.5254 16.4377 20.1404 16.685 19.683 16.867C19.2304 17.049 18.703 17.14 18.101 17.14C17.191 17.14 16.414 16.9323 15.77 16.517C15.1307 16.1017 14.6407 15.5043 14.3 14.725C13.964 13.9457 13.796 13.0053 13.796 11.904C13.796 10.8073 13.964 9.874 14.3 9.104C14.636 8.334 15.1237 7.74833 15.763 7.347C16.407 6.941 17.1864 6.738 18.101 6.738Z'
        fill='black'
      />
    </svg>
  )
}

export default IconHighCube
