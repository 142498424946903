import { Stack } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { CarrierVisitDescriptionField } from '@planning/components/carrier-visit-planning/CarrierVisitDescriptionField'
import { IVesselPlanning } from '@planning/components/carrier-visit-planning/CarrierVisitPlanning.model'
import { formatTime } from '@planning/utils'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'

interface Props {
  visit: IVesselPlanning
}
export const VesselVisitCardDescription = observer(({ visit }: Props) => {
  const { t } = useTranslate()
  const { tenantStore } = usePlanningStore()

  return (
    <Stack>
      <Stack flexDirection='row' gap={1}>
        <CarrierVisitDescriptionField label={t('eta', 'ETA')} value={formatTime(visit?.eta)} />

        {!tenantStore.skipBerthTimestamp && (
          <CarrierVisitDescriptionField label={t('etb', 'ETB')} value={formatTime(visit?.etb)} />
        )}
        <CarrierVisitDescriptionField label={t('etd', 'ETD')} value={formatTime(visit?.etd)} />
      </Stack>
      <CarrierVisitDescriptionField
        label={t('draftInOut', 'Draft in/out')}
        value={`${visit?.draftIn}m/${visit?.draftOut}m`}
      />
      <Stack flexDirection='row' gap={1}>
        <CarrierVisitDescriptionField
          label={t('off', 'Off')}
          value={`${visit?.dischargeEstimate}`}
        />
        <CarrierVisitDescriptionField label={t('in', 'In')} value={`${visit?.loadEstimate}`} />
      </Stack>
    </Stack>
  )
})
