import { Permission } from '@storage/app/models'
import { AddEntityButton } from '@storage/components/AddEntityButton'
import { useStores } from '@storage/hooks/use-stores.hook'
import { Box } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { PiersList } from '../components/piers-list'
import { BerthsFeatureStore } from '../stores'

interface Props {
  featureStore: BerthsFeatureStore
}

export const PiersListContainer = observer(
  ({ featureStore: { piersList, piersDialog } }: Props) => {
    const { pierStore, authStore } = useStores()

    const handleEditItem = (id: string) => {
      piersDialog.toggle(id)
    }

    const handleDeleteItem = async (id: string) => {
      await pierStore.delete(id)
    }

    return (
      <Box>
        <PiersList
          items={piersList.filteredPiers}
          canWrite={authStore.hasPermission(Permission.WritePiers)}
          onEditItem={handleEditItem}
          onDeleteItem={handleDeleteItem}
        />
        {authStore.hasPermission(Permission.WritePiers) && (
          <AddEntityButton onAddEntity={() => piersDialog.toggle()} />
        )}
      </Box>
    )
  },
)
