import { Alert, Box, FormGroup } from '@mui/material'
import { BerthDto } from '@storage/app/api'
import { FormProps, SelectOption } from '@storage/app/models'
import { ControlledNumberField, ControlledSelect } from '@storage/components/form-controls'
import { ControlledTextField } from '@storage/components/form-controls/ControlledTextField'
import useFormWithSchema from '@storage/hooks/use-form-with-schema.hook'
import { useTranslate } from '@tolgee/react'
import { useEffect } from 'react'
import { BerthFormMapper } from './berth-form.mapper'
import { BerthFormProfile, defaultCleatMeterMarks, defaultValues } from './berth-form.profile'
import { schema } from './berth-form.scheme'
import BerthCleatMeterMarks from './BerthCleatMeterMarks'

interface Props extends FormProps {
  berth?: BerthDto
  pierOptions: SelectOption[]
  onSubmit: (formValues: BerthFormProfile) => Promise<void> | void
}

export const BerthForm = ({ id, berth, pierOptions, onSubmit }: Props) => {
  const { handleSubmit, formState, control, reset } = useFormWithSchema<BerthFormProfile>(
    schema,
    defaultValues,
  )
  const { t } = useTranslate()
  useEffect(() => {
    if (berth) reset(BerthFormMapper.mapDtoToFormValues(berth))
  }, [reset, berth])

  return (
    <Box id={id} component='form' noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
      <FormGroup sx={{ gap: '1rem', padding: '1rem' }}>
        <ControlledTextField<BerthFormProfile>
          name='name'
          formState={formState}
          control={control}
          label={t('name', 'Name')}
          fullWidth
          autoFocus
        />

        <ControlledNumberField<BerthFormProfile>
          name='maxDraft'
          formState={formState}
          control={control}
          label={t('maxDraft', 'Max draft')}
          fullWidth
        />

        <ControlledNumberField<BerthFormProfile>
          name='quayMeterMarkFrom'
          formState={formState}
          control={control}
          label={t('quayMetermarkFrom', 'Quay metermark from')}
          fullWidth
        />

        <ControlledNumberField<BerthFormProfile>
          name='quayMeterMarkTo'
          formState={formState}
          control={control}
          label={t('quayMetermarkTo', 'Quay metermark to')}
          fullWidth
        />

        <ControlledSelect<BerthFormProfile>
          name='pierId'
          formState={formState}
          control={control}
          label={t('pier', 'Pier')}
          options={pierOptions}
        />

        <BerthCleatMeterMarks
          formState={formState}
          control={control}
          defaultValue={defaultCleatMeterMarks}
        />
        {formState.errors.bollards?.root && (
          <Alert sx={{ boxShadow: 'none' }} severity='warning'>
            {formState.errors.bollards?.root?.message}
          </Alert>
        )}
      </FormGroup>
    </Box>
  )
}
