/* eslint-disable no-console */
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { Grid } from '@mui/material'
import { useOperationsStore } from '@operations/AppProvider'
import { EquipmentPlanningRailHeader } from '@operations/features/equipmentPlanning/components/EquipmentPlanningHeader/EquipmentPlanningRailHeader'
import { EquipmentPlanningRailAvailableEquipmentsContainer } from '@operations/features/equipmentPlanning/containers/EquipmentPlanningRailAvailableEquipmentsContainer'
import { EquipmentPlanningRailWorkAreaEquipmentsContainer } from '@operations/features/equipmentPlanning/containers/EquipmentPlanningRailWorkAreaEquipmentsContainer'

import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { FC, useEffect } from 'react'
import {
  DragDropContext,
  DragStart,
  DraggableLocation,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router'

const EquipmentPlanningRail: FC = withAuthenticationRequired(() => {
  const theme = useMinimalsTheme()
  const { equipmentPlanningRailUIStore } = useOperationsStore()

  const { id } = useParams()
  const { t } = useTranslate()

  useEffect(() => {
    const railVisitId = +id!
    const fetchData = async () => {
      await equipmentPlanningRailUIStore.fetchData(railVisitId)
    }
    fetchData()
  }, [id, equipmentPlanningRailUIStore])

  const handleDragStart = (start: DragStart) => {
    const id = +start.draggableId.split('_')[1]
    equipmentPlanningRailUIStore.equipmentPlanningRailEquipmentsUIStore.startDragging(id)
  }

  const handleDragEnd = (result: DropResult, _provided?: ResponderProvided) => {
    const destination: DraggableLocation | undefined | null = result.destination
    const origin: DraggableLocation | undefined | null = result.source
    const previousWorkAreaId: number | null = origin ? +origin.droppableId.substring(3) : null
    const newWorkAreaId: number | null = destination ? +destination.droppableId.substring(3) : null
    const isCancelled = result.reason === 'CANCEL'

    equipmentPlanningRailUIStore.equipmentPlanningRailEquipmentsUIStore.endDragging(
      isCancelled,
      newWorkAreaId === 0 ? null : newWorkAreaId, // for unassigned column droppableId is 0
      previousWorkAreaId === 0 ? null : previousWorkAreaId,
      destination?.index,
    )
  }

  return (
    <>
      <Helmet>
        <title>{t('equipmentPlanningRail', 'Equipment Planning')}</title>
      </Helmet>
      <Grid container columnSpacing={3} rowSpacing={2}>
        <Grid item={true} xs={12}>
          {/* this is just a temporary simple solution of rail assignment page header for now, 
          we are going to replace it by using the planning one in future. */}
          <EquipmentPlanningRailHeader uiStore={equipmentPlanningRailUIStore} />
        </Grid>
      </Grid>
      <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        <Grid
          container
          columnSpacing={3}
          rowSpacing={2}
          sx={{ minHeight: '70vh', padding: theme.spacing(1) }}
        >
          <Grid
            item={true}
            xs={12}
            md={3}
            sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
          >
            <EquipmentPlanningRailAvailableEquipmentsContainer
              uiStore={equipmentPlanningRailUIStore.equipmentPlanningRailEquipmentsUIStore}
            />
          </Grid>
          <Grid item={true} xs={12} md={9}>
            <EquipmentPlanningRailWorkAreaEquipmentsContainer
              uiStore={equipmentPlanningRailUIStore.equipmentPlanningRailEquipmentsUIStore}
            ></EquipmentPlanningRailWorkAreaEquipmentsContainer>
          </Grid>
        </Grid>
      </DragDropContext>
    </>
  )
})

export default EquipmentPlanningRail
