import { YardBlocksApi } from '@storage/app/api'
import { createApiClient } from '@storage/app/http-client'
import { AsyncValidator } from '@storage/app/models'

interface Options {
  name?: string
  id?: string
}

const IsUniqueValidator: AsyncValidator<Options> = {
  name: 'is-unique',
  errorMessage: 'yardBlockWithThisNameAlreadyExists', //'A yard block with the same name already exists',
  validate: async ({ name, id }) =>
    (await createApiClient(YardBlocksApi).isUnique({ name, id })).data,
}

export default IsUniqueValidator
