import { Box } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { CarrierVisitDirection } from '@planning/app/api'
import { ContainerDirectionChip } from '@planning/components/ContainerDirectionChip'
import { useTranslate } from '@tolgee/react'
import { FC } from 'react'

interface IProps {
  direction: CarrierVisitDirection
}

export const OperationTypeChip: FC<IProps> = ({ direction }) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const listItemLabel = direction === 'Inbound' ? t('dropOff', 'Drop off') : t('pickUp', 'Pick up')

  return (
    <Box
      sx={{
        mt: theme.customSpacing.xs,
      }}
    >
      <ContainerDirectionChip direction={direction} label={listItemLabel} />
    </Box>
  )
}
