import { withAuthenticationRequired } from '@auth0/auth0-react'
import { GeneralCargoOperatorContainer } from '@tom-ui/operations'
import { GeneralCargoWorkInstructionStepper } from './GeneralCargoWorkInstructionStepper'

export const GeneralCargoOperator = withAuthenticationRequired(() => {
  return (
    <>
      <GeneralCargoOperatorContainer />

      <GeneralCargoWorkInstructionStepper />
    </>
  )
})
