import { TEntity } from '@storage/app/models'
import { computed, makeObservable, observable, runInAction } from 'mobx'

export interface EntityStore<T extends TEntity> {
  sorter?(entityA: T, entityB: T): number
}

export abstract class EntityStore<T extends TEntity> {
  public data = new Map<string | number, T>()

  constructor() {
    makeObservable(this, {
      data: observable,

      entries: computed,
    })
  }

  public get entries(): T[] {
    let values = Array.from(this.data.values())

    if (this.sorter) {
      values = values.sort(this.sorter)
    }

    return values
  }

  public getById(id: string | number): T | undefined {
    return this.data.get(id)
  }

  protected addOrUpdate(entity: T): void {
    runInAction(() => {
      this.data.set(entity.id, entity)
    })
  }

  protected setAll(entities: T[]): void {
    runInAction(() => {
      this.data = new Map(entities.map(item => [item.id, item]))
    })
  }

  protected addOrUpdateMany(entities: T[]): void {
    runInAction(() => {
      entities.forEach(entity => this.addOrUpdate(entity))
    })
  }

  protected remove(id: string | number): void {
    runInAction(() => {
      this.data.delete(id)
    })
  }

  protected removeAll(): void {
    runInAction(() => {
      this.data = new Map()
    })
  }
}
