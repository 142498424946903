import { CargoUnitDto, ContainerPositionType } from '@operations/app/api'
import { FormProps, SelectOption } from '@operations/app/models'
import { ControlledTextField } from '@operations/components/form-controls'
import { ControlledAutocomplete } from '@operations/components/form-controls/ControlledAutocomplete'
import { ControlledDateTimePicker } from '@operations/components/form-controls/ControlledDateTimePicker'
import useFormWithSchema from '@operations/hooks/use-form-with-schema.hook'
import { Box } from '@mui/system'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useTranslate } from '@tolgee/react'
import { JobFormProfile, defaultValues } from './job-form.profile'
import { schema } from './job-form.scheme'

interface Props extends FormProps {
  containers: CargoUnitDto[]
  containerOptions: SelectOption[]
  yardBlockOptions: SelectOption[]
  equipmentOptions: SelectOption[]
  onSubmit: (formValues: JobFormProfile) => Promise<void> | void
  onContainerChange: (container?: CargoUnitDto) => void
}

export const JobForm = ({
  id,
  containers,
  containerOptions,
  yardBlockOptions,
  equipmentOptions,
  onSubmit,
  onContainerChange,
}: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const { formState, control, handleSubmit, reset, watch } = useFormWithSchema<JobFormProfile>(
    schema(),
    defaultValues,
  )

  const hasToSelectEquipment = watch('hasToSelectEquipment')
  const isToService = watch('isToService')

  const handleContainerChange = (containerOption: any) => {
    const container = containers.find(x => x.id === +containerOption?.value)
    onContainerChange(container)

    if (container) {
      const isOnYard = container.currentLocation.type === ContainerPositionType.Yard

      reset({
        cargoUnit: containerOption,
        origin: t(container.currentLocation.type.toLocaleLowerCase()),
        destinationService: null,
        yardBlock: null,
        equipment: null,
        hasToSelectEquipment: isOnYard,
        isToService: isOnYard,
      })
    }
  }

  const serviceOptions: SelectOption[] = [
    { label: t('customs', 'Customs'), value: ContainerPositionType.Customs },
    { label: t('repair', 'Repair'), value: ContainerPositionType.Repair },
    { label: t('warehouse', 'Warehouse'), value: ContainerPositionType.Warehouse },
    { label: t('weights', 'Weights'), value: ContainerPositionType.Weights },
  ]

  return (
    <Box
      id={id}
      component='form'
      noValidate
      autoComplete='off'
      sx={{ display: 'flex', flexDirection: 'column', gap: theme.customSpacing.l }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <ControlledAutocomplete<JobFormProfile>
        name='cargoUnit'
        formState={formState}
        control={control}
        label={t('containerNumber', 'Container no.')}
        options={containerOptions}
        onChange={handleContainerChange}
        fullWidth
      />

      <ControlledTextField
        name='origin'
        formState={formState}
        control={control}
        label={t('origin', 'Origin')}
        fullWidth
        disabled
      />

      {isToService ? (
        <ControlledAutocomplete<JobFormProfile>
          name='destinationService'
          formState={formState}
          control={control}
          label={t('destination', 'Destination')}
          options={serviceOptions}
          fullWidth
        />
      ) : (
        <ControlledAutocomplete<JobFormProfile>
          name='yardBlock'
          formState={formState}
          control={control}
          label={t('destinationOnYard', 'Destination on yard')}
          options={yardBlockOptions}
          fullWidth
        />
      )}

      {hasToSelectEquipment && (
        <ControlledAutocomplete<JobFormProfile>
          name='equipment'
          formState={formState}
          control={control}
          label={t('equipment', 'Equipment')}
          options={equipmentOptions}
          fullWidth
        />
      )}

      <ControlledDateTimePicker<JobFormProfile>
        name='deadline'
        formState={formState}
        control={control}
        label={t('deadline', 'Deadline')}
      />
    </Box>
  )
}
