import { ContainerHeight, ContainerType } from '@storage/app/api'
import { AllSelectOption, BooleanSelectOption } from '@storage/app/models'
import { tolgee } from '@storage/app/translation'
import { allocationRuleTemplatesService } from '@storage/services'
import * as yup from 'yup'
import {
  AllocationRuleTemplateFormProfile,
  defaultValues,
} from './allocation-rule-templates-form.profile'

const uniqueNameValidator = async (name: string, id?: string): Promise<boolean> => {
  try {
    const { data: isUnique } = await allocationRuleTemplatesService.isNameUnique(name, id)
    return isUnique
  } catch (error) {
    return false
  }
}

export const schema = (
  isLocationInValid: boolean,
  isATemplate?: boolean,
): yup.Schema<AllocationRuleTemplateFormProfile> => {
  return yup.object({
    name: yup
      .string()
      .required(tolgee.t('nameIsRequired', 'Name field is required'))
      .test(
        'is-name-unique',
        tolgee.t(
          'AllocationRuleNameMustBeUnique',
          'An allocation rule with the same name already exists',
        ),
        async (name, context) => {
          if (!isATemplate) return true

          const id = (context.parent as AllocationRuleTemplateFormProfile).id
          return await uniqueNameValidator(name, id)
        },
      ),
    destination: yup
      .string()
      .test('is-valid-destination', () => !isLocationInValid)
      .test(
        'is-required-depending-on-template',
        tolgee.t('locationIsRequired', 'Location field is required'),
        async value => (!value && !isATemplate ? false : true),
      ),
    facets: yup.object({
      size: yup.string().default(defaultValues.facets.size).required(),
      containerOperator: yup.string().default(defaultValues.facets.containerOperator),
      isEmpty: yup
        .string<BooleanSelectOption | AllSelectOption>()
        .default(defaultValues.facets.isEmpty)
        .required(),
      isReefer: yup
        .string<BooleanSelectOption | AllSelectOption>()
        .default(defaultValues.facets.isReefer)
        .required(),
      isDangerous: yup
        .string<BooleanSelectOption | AllSelectOption>()
        .default(defaultValues.facets.isDangerous)
        .required(),
      containerHeight: yup
        .string<ContainerHeight | AllSelectOption>()
        .default(defaultValues.facets.containerHeight)
        .required(),
      containerType: yup
        .string<ContainerType | AllSelectOption>()
        .default(defaultValues.facets.containerType)
        .required(),
    }),
  })
}
