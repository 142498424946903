//This function prevents users from using copy-paste to input invalid values, as onKeyDown doesn't handle this case
export const onlyAllowPositiveInt = (event: React.ChangeEvent<HTMLInputElement>) => {
  let value = Math.floor(Number(event.target.value))
  if (value < 0) value = -value
  event.target.value = value.toString()
}

export const notAllowPlusMinusPointSign = (event: React.KeyboardEvent<HTMLInputElement>) => {
  if (event?.key === '-' || event?.key === '+' || event?.key === ',' || event?.key === '.') {
    event.preventDefault()
  }
}

export const notAllowPlusMinusSign = (event: React.KeyboardEvent<HTMLInputElement>) => {
  if (event?.key === '-' || event?.key === '+') {
    event.preventDefault()
  }
}
