import { YardPositionDto } from '@storage/app/api'
import { useStores } from '@storage/hooks/use-stores.hook'
import { Close } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import { YardPositionSelectBody } from './YardPositionSelectBody'
import { YardPositionSelectHeader } from './YardPositionSelectHeader'
import { PositionContext, YardPositionSelectStore } from './yard-position-select.store'

interface YardPositionSelectProps {
  plannedPosition?: YardPositionDto
  locationSize?: number
  handleError: (text: string) => void
  onChange: (value?: YardPositionDto) => void
}

export const YardPositionSelectContainer = observer(
  ({ plannedPosition, locationSize, handleError, onChange }: YardPositionSelectProps) => {
    const { yardBlockStore, yardBlockBayStore, yardBlockRowStore } = useStores()

    const store = useMemo(
      () => new YardPositionSelectStore(yardBlockStore, yardBlockBayStore, yardBlockRowStore),
      [yardBlockStore, yardBlockBayStore, yardBlockRowStore],
    )

    useEffect(() => {
      const load = async () => {
        await store.loadStores()
      }

      load()
        .then(() => store.initPosition(plannedPosition))
        .catch(_ => handleError('databaseIOError'))
    }, [store, plannedPosition, handleError])

    const handleSelection = (value: any, next: PositionContext) => {
      store.setCurrent(value)
      if (!store.current.flexibleZone) {
        store.setContext(next)
      }

      onChange(store.position)
    }

    const handleSwitchContext = (context: PositionContext) => {
      store.setContext(context)
    }

    return (
      <Box sx={{ width: '50rem', margin: '2rem', border: 1, padding: '1rem' }}>
        <Box
          sx={{
            display: 'flex',
            position: 'sticky',
            top: 0,
            width: '100%',
            height: 'inherited',
            flexDirection: 'row',
            marginBottom: '2rem',
          }}
        >
          <YardPositionSelectHeader
            context={store.context}
            location={store.location}
            isFlexibleZone={store.current.block?.isFlexibleZone}
            onChangeContext={handleSwitchContext}
          ></YardPositionSelectHeader>
          <Box
            sx={{
              display: 'flex',
              minWidth: '6rem',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Button
              variant='outlined'
              startIcon={<Close />}
              onClick={() => {
                store.clearCurrent()
                onChange(store.position)
              }}
            >
              Clear
            </Button>
          </Box>
        </Box>
        <YardPositionSelectBody
          context={store.context}
          blocks={store.blocks}
          bays={store.bays}
          rows={store.rows}
          tiers={store.tiers}
          locationSize={locationSize ?? 1}
          onSelect={handleSelection}
        />
      </Box>
    )
  },
)
