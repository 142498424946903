import { Chip, FormControl, Stack, SxProps, Theme } from '@mui/material'
import { CargoType } from '@planning/app/api'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

interface Props {
  value: CargoType
  onChange: (cargoType: CargoType) => Promise<void> | void
  sx?: SxProps<Theme>
  disabled?: boolean
}

export const CargoTypeSelectChip: FC<Props> = observer(({ value, onChange, sx, disabled }) => {
  const { t } = useTranslate()

  return (
    <FormControl fullWidth>
      <Stack direction='row' spacing={1} mb={1}>
        <Chip
          disabled={disabled}
          label={t('container', 'Container')}
          variant={value === CargoType.Container ? 'filled' : 'outlined'}
          color={value === CargoType.Container ? 'primary' : 'default'}
          onClick={() => onChange(CargoType.Container)}
        />
        <Chip
          disabled={disabled}
          label={t('generalCargo', 'General Cargo')}
          variant={value === CargoType.GeneralCargo ? 'filled' : 'outlined'}
          color={value === CargoType.GeneralCargo ? 'primary' : 'default'}
          onClick={() => onChange(CargoType.GeneralCargo)}
        />
      </Stack>
    </FormControl>
  )
})
