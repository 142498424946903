import { BackButton } from '@tom-ui/ui'
import { useNavigationStore } from '@planning/stores'
import { useTranslate } from '@tolgee/react'

export const NavigationStackBackButton = () => {
  const navigationStore = useNavigationStore()
  const { t } = useTranslate()

  return <BackButton onGoBack={navigationStore.pop} tooltip={t('return', 'Return')} />
}
