import { InternalApiApi } from './api'
import { createApiClient } from './http-client'

class StorageResetDataService {
  httpClient = createApiClient(InternalApiApi)

  async resetData() {
    await this.httpClient.resetData()
  }

  async saveSnapshot() {
    await this.httpClient.saveDemoData()
  }
}

export default new StorageResetDataService()
