import { ContainerDamageLocationDto } from '@admin/app/api'
import { BaseDrawerStore } from '@admin/stores/base/BaseDrawerStore'
import { ContainerDamageLocationStore } from '@admin/stores/ContainerDamageLocationStore'
import { action, makeObservable, observable } from 'mobx'

export class ContainerDamageLocationDialogUIStore extends BaseDrawerStore<ContainerDamageLocationDto> {
  isDeleteOpen = false

  constructor(private containerDamageLocationStore: ContainerDamageLocationStore) {
    super(containerDamageLocationStore)
    makeObservable(this, {
      isDeleteOpen: observable,

      toggleDelete: action,
    })
  }

  public toggleDelete(): void {
    this.isDeleteOpen = !this.isDeleteOpen
  }
}
