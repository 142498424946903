import { Box, Card, Typography } from '@mui/material'
import { CarrierVisitDirection } from '@storage/app/api'
import { CarrierVisitInfo } from '@storage/stores/carrier-visit.store'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { CarrierVisitSummary } from './CarrierVisitSummary'

interface VesselVisitsWidgetProps {
  title: string
  handlePlan?: (
    carrierVisitId: number,
    handlingDirection: CarrierVisitDirection,
    discriminator: string,
  ) => void
  navigateToCarrierVisitDetails?: (carrierVisitId: number, discriminator: string) => void
  carrierVisits: CarrierVisitInfo[]
}

const CarrierVisitsWidget = ({
  title,
  handlePlan,
  navigateToCarrierVisitDetails,
  carrierVisits,
}: VesselVisitsWidgetProps) => {
  const { palette } = useMinimalsTheme()
  const { t } = useTranslate()

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '400px', height: '100%' }}>
      <Card
        sx={{
          display: 'flex',
          height: 40,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: '#1890FF29',
          gap: 2,
          mb: 2,
          mx: 2,
        }}
      >
        <Typography variant='subtitle1' data-cy='yard-planning-carrier-visit-widget'>
          {title}
        </Typography>
        {'-'}
        <Typography color={palette.text.disabled}>{carrierVisits.length}</Typography>
      </Card>

      {carrierVisits.length === 0 ? (
        <Card
          sx={{
            display: 'flex',
            height: 40,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography>{t('noCarrierVisit', 'No carrier visit to be planned')}</Typography>
        </Card>
      ) : (
        <Box
          sx={{
            height: '100%',
            overflowY: 'auto',
            p: 1,
          }}
        >
          {carrierVisits.map(carrierVisit => {
            return (
              <Box key={carrierVisit.id} mb={1}>
                <CarrierVisitSummary
                  carrierVisitInfo={carrierVisit}
                  onPlan={handlePlan}
                  onActionNavigate={navigateToCarrierVisitDetails}
                />
              </Box>
            )
          })}
        </Box>
      )}
    </Box>
  )
}

export default CarrierVisitsWidget
