enum Permission {
  ReadTenants = 'read:tenants',
  WriteTenants = 'write:tenants',
  ReadUsers = 'read:users',
  WriteUsers = 'write:users',
  AssignRoles = 'write:assign-roles',
  ReadDamageCatalogue = 'read:damage-catalogue',
  WriteDamageCatalogue = 'write:damage-catalogue',
}

export default Permission
