import { InternalApiApi } from '@billing/app/api'
import { createApiClient } from '@billing/app/http-client'

class BillingResetDataService {
  async saveSnapshot() {
    await createApiClient(InternalApiApi).saveDemoData()
  }

  async resetData() {
    await createApiClient(InternalApiApi).resetData()
  }
}

const billingResetDataService = new BillingResetDataService()

export default billingResetDataService
