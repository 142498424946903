import { ContainerDto } from '@planning/app/api'
import { CustomAlert } from '@planning/pages/TallymanV2/Components/CustomAlert'
import { useTranslate } from '@tolgee/react'
import { FC } from 'react'

interface IProps {
  availableContainers: ContainerDto[]
}

export const NoContainersAlert: FC<IProps> = ({ availableContainers }) => {
  const { t } = useTranslate()

  return availableContainers.length === 0 ? (
    <CustomAlert
      severity='warning'
      message={t(
        'noContainersAvailableToFulfillThisOrderCurrently',
        'No containers available to fulfil this order currently.',
      )}
    />
  ) : (
    <CustomAlert
      severity='warning'
      message={`${availableContainers.length} ${t(
        'containersLeftToFulfillThisOrderCannotAssignMore',
        'containers left to fulfill this order. Cannot assign more.',
      )}`}
    />
  )
}
