import { Button } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { TallymanEquipmentSelector, useOperationsStore } from '@tom-ui/operations'
import { CustomAlert, NavigationStackBackButton } from '@tom-ui/planning'
import { Header, StepperInfo } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { VesselTallyViewStore } from 'modules/planning/src/rt-stores/tallyman/VesselTallyViewStore'
import { FC, useState } from 'react'
import { EquipmentSelectionViewStore } from './EquipmentSelectionViewStore'

interface IProps {
  orderData: any
  tallymanViewStore: VesselTallyViewStore
  equipmentSelectionStore: EquipmentSelectionViewStore
  onSelectEquipment: (orderData: any, order: any) => void
}

export const EquipmentSelection: FC<IProps> = observer(
  ({ orderData, tallymanViewStore, onSelectEquipment }) => {
    const { t } = useTranslate()

    const { selectedOrder: order } = tallymanViewStore.searchContainerStore
    const [showAlert, setShowAlert] = useState<boolean>(false)

    const onCheckContainer = () => {
      if (!tallymanOperationsUIStore.selectedEquipmentId) {
        setShowAlert(true)
        return
      }

      onSelectEquipment(orderData, order)
    }

    const { tallymanOperationsUIStore } = useOperationsStore()

    const buttonText = () =>
      `${t('check', 'Check')} ${
        tallymanViewStore.selectedOperation === 'Discharge' ? t('in', 'in') : t('out', 'out')
      }`

    return (
      <>
        <Header
          leftRenderOption={<NavigationStackBackButton />}
          title={order?.data.containerNumber || ''}
          rightRenderOption={
            <Button
              data-cy='check-container-btn'
              variant='contained'
              onClick={onCheckContainer}
              sx={{ width: '15%', minWidth: '110px' }}
            >
              {buttonText()}
            </Button>
          }
        />
        <StepperInfo
          title={t('selectEquipment', 'Select equipment')}
          steps={`${orderData.hasDamage ? 3 : 2} ${t('of', 'of')} ${orderData.hasDamage ? 3 : 2}`}
        ></StepperInfo>

        {showAlert && (
          <CustomAlert
            message={t(
              'pleaseAssignATransportFromTheFollowingList',
              'Please assign a transport from the following list',
            )}
            severity='error'
          />
        )}
        <TallymanEquipmentSelector vesselVisitId={order?.data.carrierVisitId} orderId={order?.id} />
      </>
    )
  },
)
