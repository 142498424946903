import { Box, Typography } from '@mui/material'
import { ContainerDto } from '@planning/app/api'
import { getContainerTypeParameters } from '@planning/components/ContainerTypesMap'
import { emptyIndicator } from '@planning/constants'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

type Props = {
  container?: ContainerDto
}

export const ContainerCard: FC<Props> = observer(({ container }) => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()
  const { icon } = getContainerTypeParameters('General', t)

  const renderContainerAttr = (attribute: string, label: string) => (
    <Box sx={{ mr: '2rem' }}>
      <Typography variant='body2' color={theme.palette.grey[600]}>
        {attribute}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: '.25rem' }}>
        {icon}
        <Typography variant='subtitle2' sx={{ ml: '.5rem' }}>
          {label}
        </Typography>
      </Box>
    </Box>
  )

  if (!container) return <></>

  return (
    <Box
      sx={{
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: theme.customRadius.medium,
        mt: '1rem',
      }}
    >
      {/* Card Header */}
      <Box sx={{ padding: '1rem', display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <Typography variant='h4'>{container.number}</Typography>
        </Box>
      </Box>

      {/* Card Body */}
      <Box
        sx={{
          bgcolor: theme.palette.grey[200],
          borderTop: `1px solid ${theme.palette.grey[300]}`,
          display: 'flex',
          padding: '1rem',
        }}
      >
        {renderContainerAttr('Type', container.type || emptyIndicator)}
        {renderContainerAttr(
          'Length',
          `${container.length ? container.length + 'ft' : emptyIndicator}`,
        )}
        {renderContainerAttr(
          'Tare weight',
          `${container.tare ? container.tare + ' kg' : emptyIndicator}`,
        )}
        {renderContainerAttr(
          'Max gross weight',
          `${container.maxGross ? container.maxGross + ' kg' : emptyIndicator}`,
        )}
      </Box>
    </Box>
  )
})
