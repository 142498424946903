import { Box, Typography } from '@mui/material'

interface Props {
  label: string
  value: string | number
}

export const StaticFieldLabel = ({ label, value }: Props) => {
  return (
    <>
      <Box>
        <Typography variant='body1'>{label}:</Typography>
      </Box>

      <Box>
        <Typography variant='body2'>{value}</Typography>
      </Box>
    </>
  )
}
