import { Typography } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'

interface LabelBoxProps {
  label: string
  bgColor?: string
  color?: string
}

export const LabelBox = ({ label, bgColor, color }: LabelBoxProps) => {
  const theme = useMinimalsTheme()

  return (
    <Typography
      variant='body2'
      component='span'
      sx={{
        display: 'inline-block',
        fontWeight: 'bold',
        textAlign: 'center',
        padding: `${theme.customSpacing.xxs} ${theme.customSpacing.xs}`,
        borderRadius: theme.customRadius.small,
        bgcolor: bgColor ?? theme.palette.grey[300],
        color,
        cursor: 'default',
      }}
      data-cy='label-box'
    >
      {label}
    </Typography>
  )
}
