import { BerthStore } from '@storage/stores/berth.store'
import { PierStore } from '@storage/stores/pier.store'
import { computed, makeObservable } from 'mobx'
import { BerthDetails } from '../models'

export class BerthsListFeatureStore {
  constructor(
    private berthStore: BerthStore,
    private pierStore: PierStore,
  ) {
    makeObservable(this, {
      filteredBerths: computed,
    })
  }

  public get filteredBerths(): BerthDetails[] {
    return this.berthStore.entries.map(berth => ({
      ...berth,
      pierName: this.pierStore.data.get(berth.pierId)?.name ?? '',
    }))
  }
}
