import { AppState, Auth0Provider } from '@auth0/auth0-react'
import { FC, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { getReactAppAuth0Keys } from '../lib/get-react-app-auth0-keys'

export const Auth0ProviderWithHistory: FC<{ children: ReactNode }> = ({ children }) => {
  const navigate = useNavigate()

  const onRedirectCallback = (appState: AppState | undefined) => {
    navigate(appState?.returnTo ?? window.location.pathname)
  }

  const auth0Keys = getReactAppAuth0Keys()

  return (
    <Auth0Provider
      domain={auth0Keys.domain}
      clientId={auth0Keys.clientId}
      authorizationParams={{
        audience: auth0Keys.audience,
        redirect_uri: `${window.location.origin}`,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  )
}
