import { Box, Container, Typography } from '@mui/material'
import { useMinimalsTheme } from '../../../hooks'
import { ContainerMobile } from '../containerMobile/ContainerMobile'

interface Props {
  title: string
  steps?: string
  mobile?: boolean
}

export const StepperInfo = ({ title, steps, mobile }: Props) => {
  const theme = useMinimalsTheme()

  const content = (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Typography variant='subtitle1' color={theme.palette.grey[800]}>
        {title}
      </Typography>
      {steps && (
        <Typography variant='subtitle2' color={theme.palette.grey[500]}>
          {steps}
        </Typography>
      )}
    </Box>
  )

  return (
    <Box
      sx={{
        py: '1rem',
        bgcolor: theme => theme.palette.grey[200],
        borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
      }}
    >
      {mobile ? <ContainerMobile>{content}</ContainerMobile> : <Container>{content}</Container>}
    </Box>
  )
}
