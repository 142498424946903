import { useBillingServiceStore } from '@billing/AppProvider'
import ReportStep from '@billing/app/models/report-step.enum'
import exportService from '@billing/services/ExportService'
import { observer } from 'mobx-react-lite'
import { BillableItemsReportFilter } from '../components/billableItemsReport/BillableItemsReportFilter'
import { ReportNavigationButtonContainer } from './ReportNavigationButtonContainer'

export const BillableItemsServicesContainer = observer(() => {
  const { reportsUIStore, customerBillingUIStore } = useBillingServiceStore()

  const finish = () => {
    customerBillingUIStore.resetData()
    reportsUIStore.resetStepper()
  }

  return (
    <>
      {reportsUIStore.currentStep === ReportStep.Filter && <BillableItemsReportFilter />}
      <ReportNavigationButtonContainer
        disableNext={
          !customerBillingUIStore.customerId ||
          (!customerBillingUIStore.from && !customerBillingUIStore.to)
        }
        downloadAction={() =>
          exportService.exportBillableItemsData(
            parseInt(customerBillingUIStore.customerId),
            customerBillingUIStore.from,
            customerBillingUIStore.to,
          )
        }
        finishAction={finish}
      />
    </>
  )
})
