import { DialogUtilStore } from '@storage/stores/dialog.util-store'
import { RailTracksStore } from './rail-tracks.store'
import { action, makeObservable, observable } from 'mobx'

export class RailTracksUIStore {
  searchQuery = ''

  constructor(
    private readonly _railTracksStore: RailTracksStore,
    public readonly dialogUtilStore: DialogUtilStore,
  ) {
    makeObservable(this, {
      searchQuery: observable,
      setSearchQuery: action,
      resetSearchFilter: action,
    })
  }

  resetSearchFilter() {
    this.searchQuery = ''
  }

  async loadRailTracks() {
    this.resetSearchFilter()
    await this._railTracksStore.loadAll()
  }

  get railTracks() {
    if (!this.searchQuery) return this._railTracksStore.entries

    return this._railTracksStore.entries.filter(entry =>
      entry.name.toLowerCase().includes(this.searchQuery),
    )
  }

  setSearchQuery(searchQuery: string) {
    const trimmedSearchQuery = searchQuery.trim()
    if (this.searchQuery !== trimmedSearchQuery) {
      this.searchQuery = trimmedSearchQuery.toLocaleLowerCase()
    }
  }
}
