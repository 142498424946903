import { Point } from '@storage/app/models'
import { ContainerInformationCard } from '@storage/components/ContainerInformationCard'
import { ExpandMoreButton } from '@storage/components/ExpandMoreButton'
import { Close, ExpandMore } from '@mui/icons-material'
import { Box, Collapse, IconButton, Paper, Stack, Typography } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { IYardBlockSummary } from '@storage/stores/yard-block-summary.store'
import { useTranslate } from '@tolgee/react'
import { useState } from 'react'

interface Props {
  summary: IYardBlockSummary
  position: Point
  onClick: () => void
}

export const YardBlockSummary = ({ summary, position, onClick }: Props) => {
  const { palette } = useMinimalsTheme()
  const [expanded, setExpanded] = useState(false)
  const [containerInfoExpanded, setContainerInfoExpanded] = useState(false)
  const { t } = useTranslate()

  const {
    name,
    available,
    emptyCount,
    free,
    fullCount,
    groupedByOperator,
    groupedBySize,
    occupied,
    stackHeight,
  } = summary

  return (
    <Paper
      sx={{
        position: 'fixed',
        top: position.y,
        left: position.x,
        display: 'flex',
        flexFlow: 'column',
        padding: 2,
        background: palette.background.neutral,
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IconButton onClick={onClick}>
          <Close />
        </IconButton>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant='h6'>{name}</Typography>

        <ExpandMoreButton expanded={expanded} onClick={() => setExpanded(!expanded)}>
          <ExpandMore fontSize='small' />
        </ExpandMoreButton>
      </Box>
      <Box sx={{ display: 'flex', flexFlow: 'column', gap: 1, marginBottom: 0 }}>
        <Box>
          <Typography variant='caption'>
            {t('maxStackHeight', 'Max stack height')}: {stackHeight}
          </Typography>
        </Box>
        <Box>
          <Typography variant='subtitle2'>
            {t('total', 'Total')}: {available}
          </Typography>
          <Typography
            color={occupied / available > 0.8 ? palette.error.dark : palette.text.primary}
            variant='subtitle2'
          >
            {t('occupied', 'Occupied')}: {occupied}
          </Typography>
          <Typography variant='subtitle2'>
            {t('free', 'Free')}: {free}
          </Typography>
        </Box>
      </Box>

      <Collapse in={expanded} timeout='auto'>
        <Stack spacing={2}>
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography color={palette.secondary.dark} variant='subtitle2'>
                {t('containerInformation', 'Container information')}
              </Typography>
              <ExpandMoreButton
                expanded={containerInfoExpanded}
                onClick={() => setContainerInfoExpanded(!containerInfoExpanded)}
              >
                <ExpandMore fontSize='small' />
              </ExpandMoreButton>
            </Box>

            <Collapse in={containerInfoExpanded} timeout='auto'>
              <ContainerInformationCard
                groupedBySize={groupedBySize}
                groupedByOperator={groupedByOperator}
                fullCount={fullCount}
                emptyCount={emptyCount}
              />
            </Collapse>
          </Box>
        </Stack>
      </Collapse>
    </Paper>
  )
}
