import { ContainerStatus } from '@storage/app/api'
import { useLocationStatusOptions } from '@storage/app/container-turnovers/hooks'
import ControlledButtonGroup from '@storage/components/ControlledButtonGroup'
import { Box, Typography } from '@mui/material'
import { TenantConfigStore } from '@storage/stores/tenantConfig.store'
import { useTranslate } from '@tolgee/react'
import { useFormContext } from 'react-hook-form'
import { ContainerTurnoversFilterFormProfile } from '../container-turnovers-filter-form.profile'

interface LocationStatusControlsProps {
  defaultOption?: ContainerStatus
  tenantConfigStore?: TenantConfigStore
}

export const LocationStatusControls = ({
  defaultOption,
  tenantConfigStore,
}: LocationStatusControlsProps) => {
  const { t } = useTranslate()
  const { control } = useFormContext<ContainerTurnoversFilterFormProfile>()

  const locationStatusOptions = useLocationStatusOptions()

  return (
    <Box>
      <Typography variant='h6' my={2}>
        {t('allocationStatus', 'Allocation status')}
      </Typography>
      <ControlledButtonGroup
        allOption={!defaultOption}
        name='locationStatus'
        control={control}
        options={
          defaultOption
            ? locationStatusOptions.filter(e => e.value === defaultOption)
            : tenantConfigStore?.skipYardPlanning
              ? locationStatusOptions.filter(x => x.value == 'OnTheYard')
              : locationStatusOptions
        }
      />
    </Box>
  )
}
