import html2canvas from 'html2canvas'
import { jsPDF, jsPDFOptions } from 'jspdf'
import { downloadFile } from './download-file'

export const downloadHtmlAsPDF = async (
  elementId: string,
  fileName: string,
  orientation: 'landscape' | 'portrait' = 'landscape',
  width?: number,
  height?: number,
) => {
  const canvas = await convertHTMLElementIdToCanvas(elementId, width, height)
  const contentDataURL = canvas.toDataURL('image/png')

  const options: jsPDFOptions = {
    orientation: orientation,
    unit: 'px',
    format: 'a4',
  }

  const pdf = new jsPDF(options)

  const pageWidth = pdf.internal.pageSize.getWidth()
  const pageHeight = pdf.internal.pageSize.getHeight()

  const widthRatio = pageWidth / canvas.width
  const heightRatio = pageHeight / canvas.height
  const ratio = widthRatio > heightRatio ? heightRatio : widthRatio

  const canvasWidth = canvas.width * ratio
  const canvasHeight = canvas.height * ratio

  const margin = 0

  pdf.addImage(
    contentDataURL,
    'PNG',
    margin,
    margin,
    canvasWidth - margin * 2,
    canvasHeight,
    '',
    'FAST',
  )

  pdf.save(`${fileName}.pdf`)
}

export const downloadHtmlAsJPG = async (
  elementId: string,
  fileName: string,
  width?: number,
  height?: number,
) => {
  const canvas = await convertHTMLElementIdToCanvas(elementId, width, height)

  canvas.toBlob(content => {
    if (content) downloadFile(content, `${fileName}.jpg`)
  })
}

const convertHTMLElementIdToCanvas = async (elementId: string, width?: number, height?: number) => {
  const element = document.getElementById(elementId) as HTMLElement

  const $style = document.createElement('style')
  $style.innerHTML = `* {animation-duration: 0s !important;}`
  element.appendChild($style)

  const canvas = await html2canvas(element, {
    scrollX: -window.scrollX,
    scrollY: -window.scrollY,
    scale: 2,
    windowWidth: width ?? document.documentElement.scrollWidth,
    windowHeight: height ?? document.documentElement.scrollHeight,
  }).finally(() => $style.remove())

  return canvas
}
