import { PierDto } from '@storage/app/api'
import { PierStore } from '@storage/stores/pier.store'
import { computed, makeObservable } from 'mobx'

export class PiersListFeatureStore {
  constructor(private pierStore: PierStore) {
    makeObservable(this, {
      filteredPiers: computed,
    })
  }

  public get filteredPiers(): PierDto[] {
    return this.pierStore.entries
  }
}
