import { Box, Typography } from '@mui/material'
import { FC } from 'react'

interface Props {
  number: number
}

export const NumberBox: FC<Props> = ({ number }: Props) => {
  return (
    <Box bgcolor='lightcyan' sx={{ minWidth: '1rem' }}>
      <Typography variant='body2' sx={{ mb: '1px' }}>
        {number}
      </Typography>
    </Box>
  )
}
