import { YardOperationControlStore } from '@storage/features/yard-operation-control/yard-operation-control.store'
import { ReactNode, createContext, useContext } from 'react'

export type IAppStore = {
  yardOperationControlStore: YardOperationControlStore
}

const yardOperationControlStore = new YardOperationControlStore()

const store: IAppStore = {
  yardOperationControlStore,
}

const StorageContext = createContext<IAppStore>({ ...store })

export const StorageStoreProvider = ({ children }: { children: ReactNode }) => {
  return <StorageContext.Provider value={store}>{children}</StorageContext.Provider>
}

export const useStorageStore = () => useContext(StorageContext)
