import { IconButton } from '@mui/material'
import { Box } from '@mui/system'
import { CloseIcon } from '@tom-ui/ui'
import { useRef } from 'react'
import SignatureCanvas from 'react-signature-canvas'

interface Props {
  handleChange: (png?: string) => void
}

export const SignPad = ({ handleChange }: Props) => {
  const sigPadRef = useRef<SignatureCanvas | null>(null)

  const onClean = () => {
    sigPadRef.current?.clear()
    handleChange(undefined)
  }

  const onStrokeEnd = () => {
    handleChange(sigPadRef.current?.toDataURL('png'))
  }

  return (
    <Box>
      <IconButton
        sx={{
          position: 'absolute',
        }}
        onClick={onClean}
      >
        <CloseIcon />
      </IconButton>
      <SignatureCanvas
        penColor='black'
        backgroundColor='whitesmoke'
        ref={ref => {
          sigPadRef.current = ref
        }}
        onEnd={onStrokeEnd}
        canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
      />
    </Box>
  )
}
