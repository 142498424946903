import { Paper, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { FileIcon, useMinimalsTheme } from '@tom-ui/ui'
import { FC } from 'react'
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone'

export const DragAndDropzone: FC<{
  rootProps: DropzoneRootProps
  inputProps: DropzoneInputProps
}> = ({ rootProps, inputProps }) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  return (
    <Paper
      variant='outlined'
      {...rootProps}
      sx={{
        height: '100%',
        padding: '2rem',
        backgroundColor: theme.palette.grey[200],
        textAlign: 'center',
      }}
    >
      <input {...inputProps} data-cy='upload-documents-drag-zone' />

      <FileIcon sx={{ color: theme.palette.grey[500], fontSize: '80px' }} />

      <Typography variant='body1'>
        {t('chooseAFileOrDropItHere', 'Choose a file or drop it here')}
      </Typography>
      <Typography variant='body2' color='secondary'>
        {t(
          'weAcceptBapliesOrCsvsThatUseOurTemplate',
          'We accept baplie’s or csv’s that use our template',
        )}
      </Typography>
    </Paper>
  )
}
