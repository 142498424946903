import { CommodityDto } from '@billing/app/api'
import { CommodityStore } from '@billing/stores/CommodityStore'
import { BaseDrawerStore } from '@billing/stores/base/BaseDrawerStore'
import { action, makeObservable, observable } from 'mobx'

export class CommodityDialogUIStore extends BaseDrawerStore<CommodityDto> {
  isDeleteOpen = false

  constructor(private commodityStore: CommodityStore) {
    super(commodityStore)
    makeObservable(this, {
      isDeleteOpen: observable,

      toggleDelete: action,
    })
  }

  public toggleDelete(): void {
    this.isDeleteOpen = !this.isDeleteOpen
  }
}
