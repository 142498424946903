import Permission from '@admin/app/models/permission.enum'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useAdminStore } from '@admin/AppProvider'
import { UserDialogContainer } from './containers/UserDialogContainer'
import { UserDrawerContainer } from './containers/UserDrawerContainer'
import { UsersContainer } from './containers/UsersContainer'

const Users = observer(() => {
  const { t } = useTranslate()
  const { userUIStore, roleStore, tenantStore, authStore } = useAdminStore()

  const canManageTenant = authStore.hasPermission(Permission.WriteTenants)

  useEffect(() => {
    const pageData = async () => {
      const requests = [userUIStore.store.load()]

      if (canManageTenant) {
        requests.push(tenantStore.load())
      }

      await Promise.all(requests)
    }
    pageData()
  }, [canManageTenant, tenantStore, userUIStore.store])

  useEffect(() => {
    const rolesData = async () => {
      await roleStore.load()
    }
    rolesData()
  }, [roleStore])

  return (
    <>
      <Helmet>
        <title>{t('users', 'Users')}</title>
      </Helmet>

      <UsersContainer />
      <UserDrawerContainer />
      <UserDialogContainer />
    </>
  )
})

export default Users
