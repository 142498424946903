import { Stack, TextField, Typography } from '@mui/material'
import { DangerousGoodAutoComplete } from '@planning/components'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

interface IProps {
  isOrderFulfilled: boolean
  onValidationParamsChanged: (params: any) => void
}

export const HandlingInstructionsControl: FC<IProps> = observer(
  ({ isOrderFulfilled, onValidationParamsChanged }) => {
    const { t } = useTranslate()
    const {
      control,
      formState: { errors },
      register,
      getValues,
    } = useFormContext()

    const [isDangerous, setIsDangerous] = useState<boolean>(!!getValues('imoClasses')?.length)

    return (
      <Stack gap={2}>
        <Typography variant='subtitle1'>
          {t('handlingInstructions', 'Handling instructions')}
        </Typography>
        <Controller
          control={control}
          name={`imoClasses`}
          rules={{ required: isDangerous }}
          render={({ field: { onChange } }) => (
            <DangerousGoodAutoComplete
              disable={isOrderFulfilled}
              assignedCodes={getValues(`imoClasses`) ?? []}
              enableSelect={isDangerous}
              onToggleSelectDangerousGoods={() => setIsDangerous(!isDangerous)}
              onDangerousGoodChange={newValues => {
                onChange(newValues as [])
                onValidationParamsChanged({ imoClasses: newValues })
              }}
              error={!!errors.imoClasses}
              helperText={errors.imoClasses ? t('fieldIsRequired', 'Field is required.') : ''}
            />
          )}
        />
        <TextField
          fullWidth
          disabled={isOrderFulfilled}
          label={`${t('additionalHandlingInstructions', 'Additional instructions')}`}
          {...register('handlingInstructions')}
          variant='outlined'
          type='text'
          name='handlingInstructions'
        />
      </Stack>
    )
  },
)
