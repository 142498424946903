import { Alert, AlertTitle, Typography } from '@mui/material'
import { WorkInstructionWarningReason } from '@operations/app/api'
import { useTranslate } from '@tolgee/react'

interface Props {
  warningReason?: WorkInstructionWarningReason | null
}
export const JobAlert = ({ warningReason }: Props) => {
  const { t } = useTranslate()

  const getTitle = () => {
    switch (warningReason) {
      case WorkInstructionWarningReason.CarrierNotReadyForOperation:
        return t('theCarrierIsNotReadyForOperation', 'The carrier is not ready for operation')
      case WorkInstructionWarningReason.LinkedInboundNotFinished:
        return t('theDischargeIsNotCompleted', 'The discharge is not completed')
      case WorkInstructionWarningReason.LinkedInboundForceFinishedByGateOutWithoutLocation:
      case WorkInstructionWarningReason.OnYardWithoutPosition:
        return t('weAreUnableToFindaTheLocation', 'We are unable to find the location')
      case WorkInstructionWarningReason.RequiresReservation:
        return t('weAreUnableToFindAReservation', 'We are unable to find a reservation')
      case WorkInstructionWarningReason.StuffingAndContainerCriticallyDamaged:
        return t('containerIsCriticallyDamaged', 'Container is critically damaged')
      case WorkInstructionWarningReason.TallyNotConfirmed:
        return t('theTallyClerkHasntConfirmed', `The tally clerk hasn't confirmed`)
      case WorkInstructionWarningReason.FutureOutbound:
        return t('futureLoad', 'Future load')
      case WorkInstructionWarningReason.RequiresPlugOut:
        return t('unplugWarning', 'Careful: reefer unplug has not been confirmed')
      default:
        return t('somethingWentWrong', 'Something went wrong')
    }
  }

  const hasContactControlRoomMessage = () => {
    switch (warningReason) {
      case WorkInstructionWarningReason.LinkedInboundNotFinished:
      case WorkInstructionWarningReason.LinkedInboundForceFinishedByGateOutWithoutLocation:
      case WorkInstructionWarningReason.OnYardWithoutPosition:
      case WorkInstructionWarningReason.RequiresReservation:
      case WorkInstructionWarningReason.StuffingAndContainerCriticallyDamaged:
        return true
      case WorkInstructionWarningReason.CarrierNotReadyForOperation:
      case WorkInstructionWarningReason.TallyNotConfirmed:
      case WorkInstructionWarningReason.FutureOutbound:
      default:
        return false
    }
  }

  return (
    <>
      {warningReason && (
        <Alert severity='warning'>
          <AlertTitle>{getTitle()}</AlertTitle>
          {hasContactControlRoomMessage() && (
            <Typography variant='body2'>
              {t(
                'contactControlRoomForInformation',
                'Contact the control room to get further information',
              )}
            </Typography>
          )}
        </Alert>
      )}
    </>
  )
}
