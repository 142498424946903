import {
  CreateEquipmentCommand,
  EquipmentDto,
  EquipmentsApi,
  UnassignEquipmentCommand,
  UpdateEquipmentCommand,
} from '@operations/app/api'

import { createApiClient } from '@operations/app/http-client'

import { BaseEntityStore } from './base/BaseEntityStore'

export class EquipmentStore extends BaseEntityStore<EquipmentDto> {
  async load() {
    const { data } = await createApiClient(EquipmentsApi).get()
    this.updateStoreItems(data)
  }

  async loadOne(id: number) {
    const { data } = await createApiClient(EquipmentsApi).getOne(id)
    this.updateStoreItem(data, data.id)
  }

  async loadBy(vesselVisitId: number, orderId?: number) {
    const { data } = await createApiClient(EquipmentsApi).getForTallymanOfDischarging(
      vesselVisitId,
      orderId,
    )
    this.updateStoreItems(data)
  }

  async loadPlanned(carrierVisitId: number) {
    const { data } = await createApiClient(EquipmentsApi).getPlanned(carrierVisitId)

    return data
  }

  async add(createEquipmentCommand: CreateEquipmentCommand) {
    const { data } = await createApiClient(EquipmentsApi).create(createEquipmentCommand)
    this.loadOne(data)
  }

  async update(updateEquipmentCommand: UpdateEquipmentCommand) {
    await createApiClient(EquipmentsApi).update(updateEquipmentCommand)
    this.loadOne(updateEquipmentCommand.id)
  }

  async delete(id: number) {
    await createApiClient(EquipmentsApi)._delete(id)
    this.deleteStoreItem(id)
  }

  async unassign(updateEquipmentCommand: UnassignEquipmentCommand) {
    await createApiClient(EquipmentsApi).unassign(updateEquipmentCommand)
    this.loadOne(updateEquipmentCommand.equipmentId)
  }

  async loadIdsAlreadyInUseByOtherVisits(vesselId: number) {
    const { data } = await createApiClient(EquipmentsApi).getIdsAlreadyInUseByOtherVisits(vesselId)

    return data
  }
}
