import { Typography } from '@mui/material'
import { CargoType } from '@planning/app/api'
import { emptyIndicator } from '@planning/constants'
import { useTranslate } from '@tolgee/react'
import { GeneralCargoOrderIcon, GeneralIcon } from '@tom-ui/ui'
import { FC } from 'react'

interface Props {
  cargoType: CargoType
  showLabel?: boolean
}

export const CargoTypeIconLabel: FC<Props> = ({ cargoType, showLabel = true }) => {
  const { t } = useTranslate()

  const cargoTypeIcon = cargoType === 'Container' ? <GeneralIcon /> : <GeneralCargoOrderIcon />
  let cargoTypeLabel = emptyIndicator

  switch (cargoType) {
    case 'Container':
      cargoTypeLabel = t('container', 'Container')
      break
    case 'GeneralCargo':
      cargoTypeLabel = t('generalCargo', 'General Cargo')
      break
  }

  return (
    <Typography sx={{ display: 'flex', gap: '8px' }}>
      {cargoTypeIcon} {showLabel ? cargoTypeLabel : ''}
    </Typography>
  )
}
