import { Box, DialogContent, Typography } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import {
  CarrierType,
  CarrierVisitDirection,
  OrderResponseDto,
  TransportData,
  UpdateContainerVisitCommand,
} from '@planning/app/api'
import { HoldsDrawerButton } from '@planning/components/hold/HoldsDrawerButton'
import { containerService } from '@planning/services'
import { useTranslate } from '@tolgee/react'
import { ConfirmationActions, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import {
  CreateOrderPageForm,
  ICreateOrderFormData,
  IFormCargoData,
} from './components/CreateOrderPageForm'
import { IAmAContainerVisitToo } from './stores/SelectOrderViewStore'

interface IProps {
  forcedDirection?: CarrierVisitDirection
}

export interface IOrderRailInfo {
  railcarTrackPositionId?: number
  railTrackId?: string
  railcarId?: number
}

// [Test] TODO: UT fuctions below
function getVisitId(carrierType: CarrierType, order?: OrderResponseDto, visitId?: number) {
  if (carrierType === CarrierType.Truck) {
    // Keep truck id if already truck. Remove id for other types of carriers bacause we switched types
    if (order?.carrierVisitType === CarrierType.Truck) {
      return order.carrierVisitId
    }
    return undefined
  }

  return visitId
}

export function getOrderRailInfo(
  direction: CarrierVisitDirection,
  formData: ICreateOrderFormData,
): IOrderRailInfo | null {
  if (
    direction === CarrierVisitDirection.Inbound &&
    formData.inboundCarrierType === CarrierType.Train
  ) {
    return {
      railcarTrackPositionId: formData.inboundRailcarTrackPositionId,
      railTrackId: formData.inboundRailTrackId,
      railcarId: formData.inboundRailcarId,
    }
  }
  if (
    direction === CarrierVisitDirection.Outbound &&
    formData.outboundCarrierType === CarrierType.Train
  ) {
    return {
      railcarTrackPositionId: formData.outboundRailcarTrackPositionId,
      railTrackId: formData.outboundRailTrackId,
      railcarId: formData.outboundRailcarId,
    }
  }

  return null
}

export function getTransportData(
  direction: CarrierVisitDirection,
  formData: ICreateOrderFormData,
  order?: OrderResponseDto,
): TransportData | undefined {
  const carrierType =
    direction === CarrierVisitDirection.Inbound
      ? formData.inboundCarrierType
      : formData.outboundCarrierType

  if (!carrierType) return

  const visitId =
    direction === CarrierVisitDirection.Inbound
      ? formData.inboundVisit?.id
      : formData.outboundVisit?.id

  const orderRailInfo = getOrderRailInfo(direction, formData)

  return {
    carrierType: carrierType,
    carrierVisitId: getVisitId(carrierType, order, visitId),
    releaseExpired:
      direction === CarrierVisitDirection.Outbound ? formData.releaseExpires : undefined,
    railcarTrackPositionId: orderRailInfo?.railcarTrackPositionId,
    railTrackId: orderRailInfo?.railTrackId,
    railcarId: orderRailInfo?.railcarId,
    doorDirection:
      direction === CarrierVisitDirection.Outbound ? formData?.doorDirection : undefined,
  }
}

function getCargoData(cargo?: IFormCargoData) {
  if (!cargo || cargo.isEmpty) {
    return undefined
  }

  return {
    imoClasses: cargo?.imoClasses,
    temperature: cargo?.temperature,
    dangerousGoodsDefinitionIds:
      cargo?.dangerousGoodsDefinitions?.map(dgd => dgd.id as number) ?? [],
    grossWeight: cargo?.grossWeight,
    content: cargo?.content,
  }
}

function getVisitOrders(selectedContainerVisit?: IAmAContainerVisitToo) {
  if (!selectedContainerVisit) return { inbound: undefined, outbound: undefined }

  return { inbound: selectedContainerVisit[0]?.order, outbound: selectedContainerVisit[1]?.order }
}

export function mapCreateOrderFormDataToUpdateContainerVisitCommand(
  formData: ICreateOrderFormData,
  inbound?: OrderResponseDto,
  outbound?: OrderResponseDto,
) {
  const cmd: UpdateContainerVisitCommand = {
    inboundOrderId: formData.inboundCarrierType ? formData.inboundOrderId : undefined,
    outboundOrderId: formData.outboundCarrierType ? formData.outboundOrderId : undefined,
    containerId: formData.containerId,
    containerNumber: formData.containerNumber,
    containerIsoCode: formData.containerIsoCode,
    referenceNumber: formData.referenceNumber,
    notes: formData.notes,
    operationalInstructions: formData?.operationalInstructions,
    consignee: formData.consignee,
    customerId: formData.customer?.id,
    inboundTransportData: getTransportData(CarrierVisitDirection.Inbound, formData, inbound),
    outboundTransportData: getTransportData(CarrierVisitDirection.Outbound, formData, outbound),
    inboundCargo: getCargoData(formData.inboundCargo),
    outboundCargo: getCargoData(formData.outboundCargo),
    inboundHasSeals: formData.inboundHasSeals,
    outboundHasSeals: formData.outboundHasSeals,
    inboundSeals: formData.inboundSeals,
    outboundSeals: formData.outboundSeals,
  }

  return cmd
}

export const OrderDetails: FC = () => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()

  return (
    <Box
      sx={{
        display: 'flex',
        mb: '2rem',
        p: '1rem 2rem',
        alignItems: 'center',
        bgcolor: theme.palette.grey[200],
        borderTop: '1px solid rgba(145, 158, 171, 0.24)',
        borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
      }}
    >
      <Box mr='1rem'>
        <Typography variant='subtitle1'>{t('orderDetails', 'Order Details')}</Typography>
      </Box>
      <Box>
        <ContainerVisitHoldsDrawerButton />
      </Box>
    </Box>
  )
}

export const ContainerVisitHoldsDrawerButton: FC = () => {
  const { selectOrderViewStore } = usePlanningStore()
  const { inbound, outbound } = getVisitOrders(selectOrderViewStore.selectedContainerVisit)

  if (!inbound && !outbound) return <></>

  if (!inbound && outbound) return <HoldsDrawerButton order={outbound} />

  if (inbound && !outbound) return <HoldsDrawerButton order={inbound} />

  return <HoldsDrawerButton order={inbound!} linkedOrder={outbound} />
}

export const EditOrdersPage: FC<IProps> = observer(({ forcedDirection }) => {
  const { appViewStore, selectOrderViewStore, dialogStore } = usePlanningStore()
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const onEditOrderFormSubmit = async (data: ICreateOrderFormData) => {
    const { inbound, outbound } = getVisitOrders(selectOrderViewStore.selectedContainerVisit)

    const cmd = mapCreateOrderFormDataToUpdateContainerVisitCommand(data, inbound, outbound)

    try {
      await containerService.updateContainerVisit(cmd)
      appViewStore.setShowAlert('success', t('savedSuccessfully', 'Saved Successfully'))

      selectOrderViewStore.reset()
      dialogStore.close()
    } catch (error) {
      appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
    }
  }

  return (
    <>
      <Typography variant='h4' m='1rem 0' align='center'>
        {t('editOrder', 'Edit Order')}
      </Typography>
      <DialogContent sx={{ paddingX: '0 !important' }}>
        <OrderDetails />
        <Box paddingX={theme.customSpacing.l}>
          <CreateOrderPageForm
            forcedDirection={forcedDirection}
            store={selectOrderViewStore}
            onSubmit={onEditOrderFormSubmit}
          />
        </Box>
      </DialogContent>
      <ConfirmationActions
        formId='order-form'
        primaryActionText={t('submit', 'Submit')}
        closeLabel={t('close', 'Close')}
        onClose={() => {
          dialogStore.close()
        }}
      />
    </>
  )
})
