import { action, computed, makeObservable, observable } from 'mobx'
import { ContainerJourneyDataStore } from './ContainerJourneyDataStore'

export type StuffingSearchType = 'numeric' | 'nonNumeric'

export class ServiceOrdersListViewStore {
  isLoading = false

  constructor(private containerJourneyDataStore: ContainerJourneyDataStore) {
    makeObservable(this, {
      isLoading: observable,
      containerJourneyListItems: computed,
      fetchData: action,
      setLoading: action,
    })
  }

  setLoading = (isLoading: boolean) => {
    this.isLoading = isLoading
  }

  fetchData = async (containerId: number) => {
    this.setLoading(true)

    await this.containerJourneyDataStore.fetch({ containerId, activeOrderIds: [] })

    this.setLoading(false)
  }

  get containerJourneyListItems() {
    return [
      ...this.containerJourneyDataStore.items.filter(
        item => item.strippingOrderId || item.stuffingOrderId || item.coolingOrderId,
      ),
    ]
  }
}
