import { Theme } from '@mui/material/styles'

// ----------------------------------------------------------------------

export default function Badge(theme: Theme) {
  return {
    MuiBadge: {
      styleOverrides: {
        dot: {
          width: 12,
          height: 12,
          borderRadius: theme.customRadius.oval,
        },
      },
    },
  }
}
