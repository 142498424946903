import { messageBus, usePlanningStore } from '@planning/AppProvider'
import { GetNnrOrdersByVisitIdQuery } from '@planning/messages/queries'
import { INnrOrderItem, NnrOrderItem } from '@planning/rt-stores/nnrOrder/NnrOrderItem'
import {
  IFilterDelegate,
  ISortDelegate,
  LocalDataStoreWrapper,
  PaginatedLocalStore,
} from '@planning/stores/PaginatedLocalStore'
import { SortingModel } from '@planning/stores/PaginatedStore'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router'
import { NNROrdersTable } from './Components/NNROrdersTable'

export const nnrOrdersSortingDelegate: ISortDelegate<INnrOrderItem> = (sortingModel, a, b) => {
  const getValue = (item: INnrOrderItem, sortingModel: SortingModel<INnrOrderItem>) => {
    if (sortingModel.orderBy && _.has(item.data, sortingModel.orderBy)) {
      return _.get(item.data, sortingModel.orderBy)
    }

    return item.data.shippingLine
  }

  const valA = getValue(a, sortingModel)
  const valB = getValue(b, sortingModel)
  let sort = 0
  if ((typeof valA === 'number' || typeof valB === 'number') && valA !== valB) {
    sort = (valA ?? 0) > (valB ?? 0) ? -1 : 1
  } else if (typeof valA === 'string' && typeof valB === 'string') {
    sort = valA.localeCompare(valB)
  }

  return sortingModel.isDescending ? -sort : sort
}

export const nnrOrdersFilterDelegate: IFilterDelegate<INnrOrderItem> = (
  filter: string,
  item: INnrOrderItem,
) => {
  const filterableProperties: string[] = ['data.shippingLine', 'data.referenceNumber']

  if (filter !== '')
    return filterableProperties
      .map(p => p as keyof NnrOrderItem)
      .some(p => {
        const prop = _.get(item, p)
        if (typeof prop === 'string')
          return (prop as string).toLowerCase().includes(filter.toLowerCase())

        return false
      })

  return true
}

export const NNROrdersPage = observer(() => {
  const { nnrOrderViewStore, nnrOrderItemStore } = usePlanningStore()

  const { vesselVisitId } = useParams()

  const visitId = Number(vesselVisitId)

  const nnrOrderViewStoreForVisit = useMemo(
    () =>
      visitId > 0
        ? new PaginatedLocalStore<INnrOrderItem>(
            new LocalDataStoreWrapper(
              () => _.values(nnrOrderItemStore.ordersForVesselVisitId[visitId]),
              async () => messageBus.dispatchQuery(new GetNnrOrdersByVisitIdQuery(visitId)),
            ),
            nnrOrdersSortingDelegate,
            nnrOrdersFilterDelegate,
          )
        : nnrOrderViewStore,
    [nnrOrderItemStore.ordersForVesselVisitId, nnrOrderViewStore, visitId],
  )

  useEffect(() => {
    nnrOrderViewStoreForVisit.loadCurrentPage()
  }, [nnrOrderViewStoreForVisit])

  return <NNROrdersTable viewStore={nnrOrderViewStoreForVisit} />
})
