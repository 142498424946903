import { IconButton, IconButtonProps } from '@mui/material'
import { styled } from '@mui/material/styles'

interface ExpandMoreProps extends IconButtonProps {
  expanded: boolean
}

export const ExpandMoreButton = styled((props: ExpandMoreProps) => {
  const { expanded, ...other } = props
  return <IconButton aria-expanded={expanded} {...other} />
})(({ theme, expanded }) => ({
  transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)',
  padding: 0,
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))
