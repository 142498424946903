import { YardBlockDto, YardBlocksApi } from '@operations/app/api'
import { createApiClient } from '@operations/app/http-client'
import { BaseEntityStore } from './base/BaseEntityStore'

export class YardBlockStore extends BaseEntityStore<YardBlockDto> {
  async loadYardBlockByWorkInstruction() {
    const { data } = await createApiClient(YardBlocksApi).getYardBlockByWorkInstruction(true)
    this.updateStoreItems(data)
  }

  async loadYardBlocks() {
    const { data } = await createApiClient(YardBlocksApi).getYardBlockByWorkInstruction(false)
    this.updateStoreItems(data)
  }
}
