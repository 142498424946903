import DGSegregationRulesTable from '@storage/features/dg-segregation-rules/components/DGSegregationRulesTable'
import DGSegregationRulesTableDialog from '@storage/features/dg-segregation-rules/components/DGSegregationRulesTableDialog'

const DGSegregationRulesPage = () => {
  return (
    <>
      <DGSegregationRulesTable />
      <DGSegregationRulesTableDialog />
    </>
  )
}

export default DGSegregationRulesPage
