import { UserDto } from '@admin/app/api'
import { FormProps, SelectOption } from '@admin/app/models'
import Permission from '@admin/app/models/permission.enum'
import { ControlledSelect } from '@admin/components/form-controls'
import { ControlledTextField } from '@admin/components/form-controls/ControlledTextField'
import useFormWithSchema from '@admin/hooks/use-form-with-schema.hook'
import { Box, FormGroup } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { useEffect } from 'react'
import { useAdminStore } from '@admin/AppProvider'
import { UserFormMapper } from './user-form.mapper'
import { UserFormProfile, defaultValues } from './user-form.profile'
import { schema } from './user-form.scheme'

interface Props extends FormProps {
  user?: UserDto
  tenantOptions: SelectOption[]
  onSubmit: (formValues: UserFormProfile) => Promise<void> | void
}

export const UserForm = ({ id, user, tenantOptions, onSubmit }: Props) => {
  const { authStore } = useAdminStore()

  const canManageTenant = authStore.hasPermission(Permission.WriteTenants)

  const { handleSubmit, formState, control, reset } = useFormWithSchema<UserFormProfile>(
    schema(canManageTenant),
    defaultValues,
  )

  const { t } = useTranslate()

  useEffect(() => {
    if (user) {
      reset(UserFormMapper.mapDtoToFormValues(user))
    }
  }, [reset, user])

  return (
    <Box id={id} component='form' noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
      <FormGroup sx={{ gap: theme => theme.customSpacing.l }}>
        <ControlledTextField<UserFormProfile>
          name='email'
          formState={formState}
          control={control}
          label={t('email', 'Email')}
          fullWidth
        />
        <ControlledTextField<UserFormProfile>
          name='firstName'
          formState={formState}
          control={control}
          label={t('firstName', 'First name')}
          fullWidth
        />
        <ControlledTextField<UserFormProfile>
          name='lastName'
          formState={formState}
          control={control}
          label={t('lastName', 'Last name')}
          fullWidth
        />

        {canManageTenant && (
          <ControlledSelect<UserFormProfile>
            name='tenantId'
            formState={formState}
            control={control}
            label={t('tenant', 'Tenant')}
            options={tenantOptions}
            fullWidth
          />
        )}
      </FormGroup>
    </Box>
  )
}
