import { Box, Typography } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'

interface IProps {
  title: string
  actions?: JSX.Element[]
}

export const Header = observer(({ title, actions }: IProps) => {
  const theme = useMinimalsTheme()
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        mb: theme.customSpacing.l,
        justifyContent: 'space-between',
        padding: `${theme.customSpacing.m} ${theme.customSpacing.l}`,
        borderBottom: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Box>
        <Typography variant='h2'>{title}</Typography>
      </Box>
      <Box display='flex'>
        {actions?.map((action, index) => {
          return <Box key={`general-cargo-orders-header-action-${index}`}>{action}</Box>
        })}
      </Box>
    </Box>
  )
})
