import {
  Container,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { IEnumKeyValue } from '@planning/constants'
import { IPaginatedStoreWithItems } from '@planning/stores/PaginatedStore'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'

interface Props {
  store: IPaginatedStoreWithItems<any>
  options: IEnumKeyValue[]
  title: string
}

export const StatusRadioGroupFilter = observer(({ store, options, title }: Props) => {
  const { drawerStore } = usePlanningStore()
  const { t } = useTranslate()
  return (
    <>
      <Typography variant='subtitle1' margin='1rem'>
        {title}
      </Typography>
      <Container sx={{ width: '100%' }}>
        <FormControlLabel
          control={
            <Switch
              checked={store.showCompleted}
              onClick={async () => await store.toggleShowCompleted()}
            />
          }
          label={t('showCompleted', 'Show Completed')}
        />
      </Container>
      <Container
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}
      >
        <FormControl fullWidth>
          <RadioGroup
            aria-labelledby='status-filter-radio-buttons-group-label'
            defaultValue={'all'}
            value={store.filterStatus ?? 'all'}
            name='status-filter-radio-buttons-group'
            onChange={e => {
              store.setFilterStatus(e.target.value === 'all' ? undefined : (e.target.value as any))
              drawerStore.close()
            }}
          >
            <FormControlLabel
              key='status-filter-form-control-all'
              value={'all'}
              control={<Radio />}
              label={'All'}
            />
            {options.map(option => {
              return (
                <FormControlLabel
                  key={`status-filter-form-control-${option.id}`}
                  value={option.id}
                  control={<Radio />}
                  label={option.value}
                />
              )
            })}
          </RadioGroup>
        </FormControl>
      </Container>
    </>
  )
})
