import { GeneralCargoItemPlanningDto, HandlingDirection } from '@storage/app/api'
import { tolgee } from '@storage/app/translation'
import { GeneralCargoStorageTrackerUIStore } from '@storage/features/general-cargo-storage-tracker/stores/general-cargo-storage-tracker.ui-store'
import { GeneralCargoPlanningStore } from '@storage/stores/general-cargo-planning.store'
import { SnackbarStore } from '@storage/stores/snackbar.store'
import { ObservableMap, action, computed, makeObservable, observable, runInAction } from 'mobx'

export class GeneralCargoOrderReservationUIStore {
  isDialogOpen = false
  dialogActiveGeneralCargoOrderId?: number
  dialogActiveCargoItemId?: number
  dialogActiveQuantity?: number
  generalCargoOrdersReservationData: ObservableMap<string, GeneralCargoItemPlanningDto[]> =
    new ObservableMap()

  constructor(
    private readonly _generalCargoPlanningStore: GeneralCargoPlanningStore,
    private readonly _generalCargoStorageTrackerUIStore: GeneralCargoStorageTrackerUIStore,
    private readonly _snackbarStore: SnackbarStore,
  ) {
    makeObservable(this, {
      isDialogOpen: observable,
      toggleDialog: action,
      dialogActiveGeneralCargoOrderId: observable,
      dialogActiveCargoItemId: observable,
      dialogActiveQuantity: observable,

      generalCargoOrdersReservationData: observable,
      loadGeneralCargoOrdersReservationData: action,

      totalNewQuantity: computed,
    })
  }

  generalCargoOrderReservations(
    generalCargoOrderId: number,
    cargoItemId?: number,
  ): GeneralCargoItemPlanningDto[] {
    const key = this.generateKey(generalCargoOrderId, cargoItemId)

    return this.generalCargoOrdersReservationData.get(key) ?? []
  }

  async loadGeneralCargoOrdersReservationData(generalCargoOrderId: number, cargoItemId?: number) {
    return this._generalCargoPlanningStore
      .getPlannedOccupanciesByOrderId(generalCargoOrderId, cargoItemId)
      .then((generalCargoOrderPlanningDto: GeneralCargoItemPlanningDto[]) =>
        this.upsertGeneralCargoOrdersReservationData(
          generalCargoOrderId,
          generalCargoOrderPlanningDto,
          cargoItemId,
        ),
      )
  }

  get totalNewQuantity() {
    return this._generalCargoStorageTrackerUIStore.totalNewQuantity
  }

  toggleDialog(generalCargoOrderId?: number, cargoItemId?: number, quantity?: number) {
    this.dialogActiveGeneralCargoOrderId = generalCargoOrderId
    this.dialogActiveCargoItemId = cargoItemId
    this.dialogActiveQuantity = quantity
    this.isDialogOpen = !this.isDialogOpen
  }

  onConfirm = () => {
    if (!this.dialogActiveGeneralCargoOrderId) {
      return
    }

    this._generalCargoStorageTrackerUIStore
      .onConfirm(
        HandlingDirection.Inbound,
        this.dialogActiveGeneralCargoOrderId,
        this.dialogActiveCargoItemId,
      )
      .then(() => {
        this._snackbarStore.showMessage(
          tolgee.t(
            'generalCargoOrderReservationSuccess',
            'The reservation has been successfully created',
          ),
          'success',
        )
        this.loadGeneralCargoOrdersReservationData(
          this.dialogActiveGeneralCargoOrderId!,
          this.dialogActiveCargoItemId,
        )
        this.toggleDialog()
      })
      .catch(() =>
        this._snackbarStore.showMessage(
          tolgee.t(
            'generalCargoOrderReservationFailure',
            'An unexpected error occurred while creating the reservation',
          ),
          'error',
        ),
      )
  }

  private upsertGeneralCargoOrdersReservationData(
    generalCargoOrderId: number,
    planningDto: GeneralCargoItemPlanningDto[],
    cargoItemId?: number,
  ) {
    const key = this.generateKey(generalCargoOrderId, cargoItemId)

    runInAction(() => {
      this.generalCargoOrdersReservationData.set(key, planningDto)
    })
  }

  private generateKey(orderId: number, cargoItemId?: number): string {
    return cargoItemId ? `${orderId}_${cargoItemId}` : `${orderId}`
  }
}
