import { StackDto, YardBlockDto, YardPositionDto } from '@storage/app/api'
import { SelectOption } from '@storage/app/models'
import { YardBlockStackStore } from '@storage/stores/yard-block-stack.store'
import { YardBlockStore } from '@storage/stores/yard-block.store'
import _ from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'

export enum PositionField {
  Block,
  Bay,
  Row,
  Tier,
}

export const hasMixedSizes = (sizes: number[]) => {
  return _.uniq(sizes).length > 1
}

export const yardBlocks = (stackItens: StackDto[]) => {
  const stacks = stackItens.filter(e => !e.isLocked)

  return _.sortBy(
    _.uniqBy(
      stacks.map<SelectOption>(stack => {
        return {
          label: stack.yardPosition.block?.toUpperCase() ?? '',
          value: stack.blockId,
        }
      }),
      x => x.value,
    ),
    x => x.label,
  )
}

export const yardBlockStacks = (stackItens: StackDto[], selectedYardBlockId?: string) => {
  if (!selectedYardBlockId) return []

  return stackItens.filter(e => !e.isLocked && e.blockId === selectedYardBlockId)
}

export const bays = (yardBlockStacks: StackDto[], selectedYardBlockId?: string) => {
  if (!selectedYardBlockId) return []

  return _.sortBy(
    _.uniq(
      yardBlockStacks.map(yardBlockStack => {
        return yardBlockStack.yardPosition.bay?.toUpperCase() ?? ''
      }),
    ),
  )
}

export const rows = (yardBlockStacks: StackDto[], selectedYardBlockId?: string) => {
  if (!selectedYardBlockId) return []

  return _.sortBy(
    _.uniq(
      yardBlockStacks.map(yardBlockStack => {
        return yardBlockStack.yardPosition.row?.toUpperCase() ?? ''
      }),
    ),
  )
}

export const tiers = (yardBlockStacks: StackDto[], selectedYardBlockId?: string) => {
  if (!selectedYardBlockId) return []

  const maxTier = yardBlockStacks[0]?.numberOfSlots ?? 0

  return Array.from({ length: maxTier }, (_, i) => i + 1).map(tier => tier.toString())
}

export const isSelectedBlockDangerous = (
  yardBlockStacks: YardBlockDto[],
  selectedYardBlockId?: string,
) => {
  return (
    (selectedYardBlockId !== undefined &&
      yardBlockStacks.find(x => x.id === selectedYardBlockId)?.usageOptions
        ?.dangerousGoodsHandling) ??
    false
  )
}

export class YardPositionSelectorStore {
  selectedYardBlockId?: string
  newPosition: YardPositionDto = {}

  constructor(
    private readonly stackStore: YardBlockStackStore,
    private readonly yardBlockStore: YardBlockStore,
  ) {
    makeObservable(this, {
      selectedYardBlockId: observable,
      newPosition: observable,

      setSelectedYardBlockId: action,
      setNewPositionField: action,

      yardBlocks: computed,
      yardBlockStacks: computed,
      bays: computed,
      rows: computed,
      tiers: computed,

      isSelectedBlockDangerous: computed,
    })
  }

  async loadStacks() {
    if (this.stackStore!.entries.length === 0) {
      await this.stackStore!.loadAll({})
    }
  }

  setSelectedYardBlockId(id?: string) {
    this.selectedYardBlockId = id
  }

  setNewPositionField(field: PositionField, value?: string) {
    switch (field) {
      case PositionField.Block:
        this.newPosition.block = value
        break
      case PositionField.Bay:
        this.newPosition.bay = value
        break
      case PositionField.Row:
        this.newPosition.row = value
        break
      case PositionField.Tier:
        this.newPosition.tier = value ? +value : null
        break
    }
  }

  get yardBlocks() {
    return yardBlocks(this.stackStore!.entries)
  }

  get yardBlockStacks() {
    return yardBlockStacks(this.stackStore!.entries, this.selectedYardBlockId)
  }

  get bays() {
    return bays(this.yardBlockStacks, this.selectedYardBlockId)
  }

  get rows() {
    return rows(this.yardBlockStacks, this.selectedYardBlockId)
  }

  get tiers() {
    return tiers(this.yardBlockStacks, this.selectedYardBlockId)
  }

  get isSelectedBlockDangerous() {
    return isSelectedBlockDangerous(this.yardBlockStore.entries, this.selectedYardBlockId)
  }
}
