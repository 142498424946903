import { ContainerTurnoverGroupSelector, YardBlockInformationDto } from '@storage/app/api'
import { EntityStore } from '@storage/app/store/entity.store'
import { ContainerInformationWidgetProfile } from '@storage/pages/container-turnovers/components/container-information-widget-form/container-information-widget-form.profile'
import { action, makeObservable, observable } from 'mobx'
import { LocalStorageService } from '@storage/services/local-storage.service'
import { YardBlockService } from '@storage/services/yard-blocks-service'

interface AggregatedYardBlockSummaries {
  name: string
  value: number
}

/**
 * Container Information Widget Store
 *
 * Responsible for managing the state and preferences of the Container Information Widget
 * It extends the `EntityStore` to handle data fetching and manipulation. This class provides
 * functionality to aggregate yard block summaries information, toggle edit mode
 * and store widget preferences in local storage
 *
 * @class ContainerInformationWidgetStore
 * @extends EntityStore<YardBlockInformationDto>
 * @implements IYardBlockSummaryStore
 */
export class ContainerInformationWidgetStore extends EntityStore<YardBlockInformationDto> {
  isInEditMode = false

  isSizeSelected = true
  isCargoStatusSelected = true
  isOperatorSelected = true
  isPortOfDischargeSelected = true

  groupedYardBlockSummaries: AggregatedYardBlockSummaries[] = []

  constructor(
    private readonly _yardBlockService: YardBlockService,
    private readonly _localStorageService: LocalStorageService<ContainerInformationWidgetProfile>,
  ) {
    super()

    this.init()

    makeObservable(this, {
      loadYardBlockSummaries: action,
      groupedYardBlockSummaries: observable,
      isInEditMode: observable,
      toggleEditMode: action,
      setWidgetsPreferences: action,
      aggregateGroupedYardBlockSummaries: action,
    })
  }

  init() {
    const storedData = this._localStorageService.getData()
    if (storedData) {
      this.setWidgetsPreferences(storedData)
    }
  }
  public toggleEditMode() {
    this.isInEditMode = !this.isInEditMode
  }

  public setWidgetsPreferences(preferences: ContainerInformationWidgetProfile) {
    this.isSizeSelected = preferences.isSizeSelected
    this.isCargoStatusSelected = preferences.isCargoStatusSelected
    this.isOperatorSelected = preferences.isOperatorSelected
    this.isPortOfDischargeSelected = preferences.isPortOfDischargeSelected
    this.aggregateGroupedYardBlockSummaries()
    this._localStorageService.saveData({ ...preferences })
  }

  // Set widget preferences based on the provided data
  get getDefaultWidgetPreferences(): ContainerInformationWidgetProfile {
    return {
      isSizeSelected: this.isSizeSelected,
      isCargoStatusSelected: this.isCargoStatusSelected,
      isOperatorSelected: this.isOperatorSelected,
      isPortOfDischargeSelected: this.isPortOfDischargeSelected,
    }
  }

  public async loadYardBlockSummaries(): Promise<void> {
    const { data: yardBlockSummaries } = await this._yardBlockService.getYardBlockSummaries()
    this.setAll(yardBlockSummaries)
    this.aggregateGroupedYardBlockSummaries()
  }

  // Aggregate grouped yard block summaries based on selection criteria
  public aggregateGroupedYardBlockSummaries() {
    this.groupedYardBlockSummaries = this.entries
      .map(item => item.groupedBy)
      .flat()
      .reduce((output: AggregatedYardBlockSummaries[], item) => {
        if (item.selector === ContainerTurnoverGroupSelector.Size && this.isSizeSelected) {
          item.items.forEach(sizeItem => {
            const existingSizeItem = output.find(outputItem => outputItem.name === sizeItem.key)
            if (existingSizeItem) {
              existingSizeItem.value += sizeItem.value
            } else {
              output.push({ name: sizeItem.key, value: sizeItem.value })
            }
          })
        } else if (
          item.selector === ContainerTurnoverGroupSelector.PortOfDischarge &&
          this.isPortOfDischargeSelected &&
          item.items.length > 0
        ) {
          item.items.forEach(portItem => {
            const existingPortItem = output.find(outputItem => outputItem.name === portItem.key)
            if (existingPortItem) {
              existingPortItem.value += portItem.value
            } else {
              output.push({ name: portItem.key, value: portItem.value })
            }
          })
        } else if (
          item.selector === ContainerTurnoverGroupSelector.CargoStatus &&
          this.isCargoStatusSelected
        ) {
          const trueItem = item.items.find(cargoItem => cargoItem.key === 'True')
          const falseItem = item.items.find(cargoItem => cargoItem.key === 'False')

          if (trueItem) {
            const existingTrueItem = output.find(outputItem => outputItem.name === 'Empty')
            if (existingTrueItem) {
              existingTrueItem.value += trueItem.value
            } else {
              output.push({ name: 'Empty', value: trueItem.value })
            }
          }

          if (falseItem) {
            const existingFalseItem = output.find(outputItem => outputItem.name === 'Full')
            if (existingFalseItem) {
              existingFalseItem.value += falseItem.value
            } else {
              output.push({ name: 'Full', value: falseItem.value })
            }
          }
        } else if (
          item.selector === ContainerTurnoverGroupSelector.ContainerOperator &&
          this.isOperatorSelected
        ) {
          item.items.forEach(containerItem => {
            const existingContainerItem = output.find(
              outputItem => outputItem.name === containerItem.key,
            )
            if (existingContainerItem) {
              existingContainerItem.value += containerItem.value
            } else {
              output.push({ name: containerItem.key, value: containerItem.value })
            }
          })
        }

        return output
      }, [])
  }
}
