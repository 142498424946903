import { ContainerStatus } from '@storage/app/api'
import { ContainerTurnoversFilterStore } from '../stores/container-turnovers-filter.store'
import {
  ManualPlanningDialogStore,
  ManualPlanningTypes,
} from '../stores/manual-planning-dialog.store'

interface Deps {
  manualPlanningDialogStore: ManualPlanningDialogStore
  containerTurnoversFilterStore: ContainerTurnoversFilterStore
}

export const useHandlePlanManually = ({
  manualPlanningDialogStore,
  containerTurnoversFilterStore,
}: Deps) => {
  const handleSingleContainerPlanning = (
    containerTurnoverId: string,
    planningType: ManualPlanningTypes,
  ) => {
    manualPlanningDialogStore.setType(planningType)
    manualPlanningDialogStore.setIsSingleContainer(true)
    manualPlanningDialogStore.setContainerTurnoverId(containerTurnoverId)
    manualPlanningDialogStore.toggle()
  }

  const handleListPlanning = () => {
    manualPlanningDialogStore.setIsSingleContainer(
      manualPlanningDialogStore.numberOfMatchingFilters === 1,
    )
    containerTurnoversFilterStore.filter?.locationStatus === ContainerStatus.OnTheYard
      ? manualPlanningDialogStore.setType('internalMove')
      : manualPlanningDialogStore.setType('planning')

    manualPlanningDialogStore.preplanningValidation()
  }

  return (containerTurnoverId?: string, planningType?: ManualPlanningTypes) => {
    return containerTurnoverId && planningType
      ? handleSingleContainerPlanning(containerTurnoverId, planningType)
      : handleListPlanning()
  }
}
