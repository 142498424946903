import { CustomerDto } from '@billing/app/api'
import { FormProps } from '@billing/app/models'
import { ControlledTextField } from '@billing/components/form-controls/ControlledTextField'
import useFormWithSchema from '@billing/hooks/use-form-with-schema.hook'
import { Box, FormGroup } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useEffect } from 'react'
import { CustomerFormMapper } from './customer-form.mapper'
import { CustomerFormProfile, defaultValues } from './customer-form.profile'
import { schema } from './customer-form.scheme'

interface Props extends FormProps {
  customer?: CustomerDto
  onSubmit: (formValues: CustomerFormProfile) => Promise<void> | void
}

export const CustomerForm = ({ id, customer, onSubmit }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const { handleSubmit, formState, control, reset } = useFormWithSchema<CustomerFormProfile>(
    schema(),
    defaultValues,
  )

  useEffect(() => {
    if (customer) {
      reset(CustomerFormMapper.mapDtoToFormValues(customer))
    }
  }, [reset, customer])

  return (
    <Box
      id={id}
      component='form'
      noValidate
      autoComplete='off'
      onSubmit={handleSubmit(onSubmit)}
      pb={theme.customSpacing.xs}
    >
      <FormGroup sx={{ gap: theme.customSpacing.l }}>
        <ControlledTextField<CustomerFormProfile>
          name='name'
          formState={formState}
          control={control}
          label={t('companyName', 'Company name')}
          fullWidth
          showAsterisk
        />

        <ControlledTextField<CustomerFormProfile>
          name='abbreviation'
          formState={formState}
          control={control}
          label={t('companyAbbreviation', 'Company abbreviation')}
          fullWidth
          showAsterisk
        />

        <ControlledTextField<CustomerFormProfile>
          name='address'
          formState={formState}
          control={control}
          label={t('address', 'Address')}
          fullWidth
        />
      </FormGroup>
    </Box>
  )
}
