import { CarrierVisitDto, CarrierVisitStatus } from '@storage/app/api'

export interface CarrierVisitListItem {
  id: number
  name: string
  status: CarrierVisitStatus
  eta: Date | null
}

export const CarrierVisitListItemFactory = ({
  id,
  name,
  status,
  eta,
}: CarrierVisitDto): CarrierVisitListItem => ({
  id,
  name,
  status,
  eta: eta ? new Date(eta) : null,
})
