import { Box, Card, CardContent, Typography, cardContentClasses, styled } from '@mui/material'
import { OperationType } from '@operations/app/api'
import { Bay } from '@operations/features/craneSplit/models/bay.model'
import { UnknownBayNumber } from '@operations/features/craneSplit/utils'
import { useTranslate } from '@tolgee/react'
import { BayOperationContainer } from './BayOperationContainer'

interface Props {
  bay: Bay
}

export const BayCard = ({ bay }: Props) => {
  const { t } = useTranslate()

  const StyledCardContent = styled(CardContent)(() => ({
    padding: '.8rem',
    [`&.${cardContentClasses.root}:last-child`]: {
      paddingBottom: '.8rem',
    },
  }))

  return (
    <Card
      sx={{
        height: '12.5rem',
        width: '9.5rem',
        flexShrink: 0,
      }}
      variant='outlined'
    >
      <StyledCardContent>
        <Typography variant='subtitle2' gutterBottom>
          {bay.bay === UnknownBayNumber
            ? t('unknownBay', 'Unknown Bay')
            : `${t('bay', 'Bay')} ${bay.bay.toString().padStart(2, '0')}`}
        </Typography>
        <Box sx={{ display: 'flex', gap: '1rem' }}>
          <BayOperationContainer
            operationType={OperationType.Inbound}
            bayAmount={bay.discharge}
            isSingleTwentyBay={bay.isSingleTwentyBay}
          />
          <BayOperationContainer
            operationType={OperationType.Outbound}
            bayAmount={bay.loading}
            isSingleTwentyBay={bay.isSingleTwentyBay}
          />
        </Box>
      </StyledCardContent>
    </Card>
  )
}
