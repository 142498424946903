import { MenuItem, Select } from '@mui/material'
import { useTolgee } from '@tolgee/react'
import React from 'react'

const state: Record<string, string> = {
  language: 'en',
}

export const LANGUAGE_LOCALSTORAGE = 'omoqo_language'

export const LangSelector: React.FC = () => {
  const tolgee = useTolgee(['pendingLanguage'])

  const propagateLanguageChange = (language: string) => {
    localStorage.setItem(LANGUAGE_LOCALSTORAGE, language)
    tolgee.changeLanguage(language)
    state.language = language
  }

  return (
    <Select
      onChange={e => propagateLanguageChange(e.target.value)}
      value={tolgee.getPendingLanguage()}
    >
      <MenuItem value='en'>GB English</MenuItem>
      <MenuItem value='de'>DE Deutsch</MenuItem>
      <MenuItem value='es'>ES Español</MenuItem>
      <MenuItem value='ru'>RU Русский</MenuItem>
    </Select>
  )
}
