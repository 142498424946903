import { SelectOption } from '@storage/app/models'
import { EnumAsSelectOptionsUtil } from '@storage/utils/enums'
import { useMemo } from 'react'
import { LabelingPatternType } from '../yard-block-form.profile'

const KEY_PREFIX = 'bayRowLabelingPattern'

export const useLabelingPatternOptions = (): SelectOption[] =>
  useMemo(
    () =>
      EnumAsSelectOptionsUtil.transform(LabelingPatternType, KEY_PREFIX).map(option => ({
        ...option,
        label: option.label,
      })),
    [],
  )
