import { Box, Checkbox, ListItem, ListItemIcon, Typography } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { BerthResponseDto } from '@planning/app/api'
import { useTranslate } from '@tolgee/react'
import { FC, HTMLAttributes } from 'react'

interface Props {
  berth: BerthResponseDto
  assignedBerths?: BerthResponseDto[]
  props: HTMLAttributes<HTMLLIElement>
}

export const BerthAssignmentOptionListItem: FC<Props> = ({ berth, assignedBerths, props }) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  return (
    <ListItem {...props}>
      <ListItemIcon>
        <Checkbox edge='start' checked={assignedBerths?.includes(berth)} disableRipple />
      </ListItemIcon>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant='body2'>{berth.name}</Typography>
        <Typography variant='caption' color={theme.palette.grey[500]}>
          {t('maxDraft', 'Max. Draft')}: {berth.maxDraft} {t('length', 'Length')}: {berth.meterSize}
        </Typography>
        <Typography variant='caption' color={theme.palette.grey[500]}>
          {t('meterMarks', 'Meter marks')}: {berth.quayMeterMarkFrom} {'>'} {berth.quayMeterMarkTo}
        </Typography>
      </Box>
    </ListItem>
  )
}
