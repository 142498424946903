import { Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'

interface Props {
  numberOfUnallocatedContainers: number
}

const UnAllocatedContainersHeader = ({ numberOfUnallocatedContainers }: Props) => {
  const { palette } = useMinimalsTheme()
  const { t } = useTranslate()
  return (
    <Stack direction='row' alignItems='center' gap={1} mx={2} my={1}>
      <Box
        sx={{
          top: '0',
          left: '0',
          zIndex: 1,
          marginLeft: '14px',
          width: '40px',
          height: '40px',
          borderRadius: '4px',
          padding: '0 4px',
          color: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          placeContent: 'center',
          backgroundColor: palette.secondary.darker,
        }}
      >
        {numberOfUnallocatedContainers}
      </Box>
      <Typography variant='h6'>{t('unAllocatedContainers', 'Unallocated')}</Typography>
    </Stack>
  )
}

export default UnAllocatedContainersHeader
