import { CarrierVisitStatus } from '@planning/app/api'
import { VesselVisitOperation } from '@planning/pages/TallymanV2/Components/VesselSelectionStepper'
import _ from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import { IEntityStore } from '../types'
import { IVesselVisitItem } from '../vesselVisit/VesselVisitItem'
import { CheckOrderNotificationStore } from './CheckOrderNotificationStore'
import { ReportContainerNotificationStore } from './ReportContainerNotificationStore'
import { SearchContainerViewStore } from './SearchContainerViewStore'

export interface IVesselTallyViewStore {
  vesselVisits: IVesselVisitItem[]
}

export class VesselTallyViewStore implements IVesselTallyViewStore {
  selectedVisitId?: number
  selectedOperation?: VesselVisitOperation

  searchContainerStore: SearchContainerViewStore
  checkNotificationStore: CheckOrderNotificationStore
  reportNotificationStore: ReportContainerNotificationStore

  constructor(private vesselVisitItemStore: IEntityStore<IVesselVisitItem>) {
    makeObservable(this, {
      selectedVisitId: observable,
      selectedOperation: observable,

      vesselVisits: computed,
      orders: computed,
      selectedVisit: computed,

      selectVesselVisit: action,
      selectOperation: action,
    })

    this.searchContainerStore = new SearchContainerViewStore(this)
    this.checkNotificationStore = new CheckOrderNotificationStore()
    this.reportNotificationStore = new ReportContainerNotificationStore()
  }

  get vesselVisits() {
    return _(this.vesselVisitItemStore.elements)
      .filter(vv => vv.data.status === CarrierVisitStatus.InOperation)
      .value()
  }

  get orders() {
    return this.selectedOperation === 'Discharge'
      ? this.selectedVisit?.discharge.orders
      : this.selectedVisit?.load.orders
  }

  get selectedVisit() {
    return _(this.vesselVisitItemStore.elements).find(vv => vv.id === this.selectedVisitId)
  }

  selectVesselVisit = (id: number) => {
    this.selectedVisitId = id
  }

  selectOperation = (operation: VesselVisitOperation) => {
    this.selectedOperation = operation
  }
}
