import { createSvgIcon } from '@mui/material'

export const AutoIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M8.27604 8.27604L7.26042 11.3229H16.7396L15.724 8.27604H8.27604Z'
      fill='currentColor'
    />
    <path
      d='M15.0058 14.4109C15.1963 14.6013 15.4546 14.7083 15.724 14.7083C15.9933 14.7083 16.2516 14.6013 16.4421 14.4109C16.6326 14.2204 16.7396 13.9621 16.7396 13.6927C16.7396 13.4233 16.6326 13.165 16.4421 12.9746C16.2516 12.7841 15.9933 12.6771 15.724 12.6771C15.4546 12.6771 15.1963 12.7841 15.0058 12.9746C14.8153 13.165 14.7083 13.4233 14.7083 13.6927C14.7083 13.9621 14.8153 14.2204 15.0058 14.4109Z'
      fill='currentColor'
    />
    <path
      d='M7.55789 14.4109C7.74835 14.6013 8.00668 14.7083 8.27604 14.7083C8.5454 14.7083 8.80373 14.6013 8.9942 14.4109C9.18466 14.2204 9.29167 13.9621 9.29167 13.6927C9.29167 13.4233 9.18466 13.165 8.9942 12.9746C8.80373 12.7841 8.5454 12.6771 8.27604 12.6771C8.00668 12.6771 7.74835 12.7841 7.55789 12.9746C7.36742 13.165 7.26042 13.4233 7.26042 13.6927C7.26042 13.9621 7.36742 14.2204 7.55789 14.4109Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4 2C2.89543 2 2 2.89543 2 4V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V4C22 2.89543 21.1046 2 20 2H4ZM15.724 7.26042C16.1708 7.26042 16.55 7.54479 16.6854 7.9375L18.0938 12V17.4167C18.0938 17.5962 18.0224 17.7685 17.8954 17.8954C17.7685 18.0224 17.5962 18.0938 17.4167 18.0938H16.7396C16.56 18.0938 16.3878 18.0224 16.2608 17.8954C16.1338 17.7685 16.0625 17.5962 16.0625 17.4167V16.7396H7.9375V17.4167C7.9375 17.5962 7.86616 17.7685 7.73919 17.8954C7.61221 18.0224 7.43999 18.0938 7.26042 18.0938H6.58333C6.40376 18.0938 6.23154 18.0224 6.10456 17.8954C5.97759 17.7685 5.90625 17.5962 5.90625 17.4167V12L7.31458 7.9375C7.45 7.54479 7.82917 7.26042 8.27604 7.26042H15.724Z'
      fill='currentColor'
    />
  </svg>,
  'AutoIcon',
)
