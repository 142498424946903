import { useOperationsStore } from '@operations/AppProvider'
import { JobSteps } from '@operations/features/equipmentOperator/stores/equipment-operator-work-instruction-stepper.ui-store'
import { observer } from 'mobx-react-lite'
import { ConfirmAmount } from './ConfirmAmount'
import { ConfirmSignature } from './ConfirmSignature'
import { GeneralCargoEquipmentOperatorProps } from './confirm-destination.interface'

export const WorkInstructionJobStepper = observer(
  ({ DestinationComponent }: GeneralCargoEquipmentOperatorProps) => {
    const { equipmentOperatorWorkInstructionStepperUIStore } = useOperationsStore()

    return (
      <>
        {equipmentOperatorWorkInstructionStepperUIStore.currentStep === JobSteps.confirmAmount && (
          <ConfirmAmount
            job={equipmentOperatorWorkInstructionStepperUIStore.selectedJob!}
            DestinationComponent={DestinationComponent}
            stepperUiStore={equipmentOperatorWorkInstructionStepperUIStore}
          />
        )}

        {equipmentOperatorWorkInstructionStepperUIStore.currentStep === JobSteps.signature && (
          <ConfirmSignature
            job={equipmentOperatorWorkInstructionStepperUIStore.selectedJob!}
            stepperUiStore={equipmentOperatorWorkInstructionStepperUIStore}
          />
        )}
      </>
    )
  },
)
