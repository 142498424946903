import { ContainerDamageLocationsApi } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'

class ContainerDamageLocationService {
  httpClient = createApiClient(ContainerDamageLocationsApi)

  get = async () => {
    const { data } = await this.httpClient.get()
    return data
  }
}

const containerDamageLocationService = new ContainerDamageLocationService()

export default containerDamageLocationService
