import { EmptyIcon, FullIcon } from '@tom-ui/ui'
import { FC } from 'react'
import { IconColor } from './IconColor'

export const IconFullOrEmptyContainer: FC<{ isEmpty: boolean; color?: IconColor }> = ({
  isEmpty,
  color,
}) => {
  return isEmpty ? <EmptyIcon color={color} /> : <FullIcon color={color} />
}

export default IconFullOrEmptyContainer
