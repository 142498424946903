import { Box, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { useOperationsStore } from '@operations/AppProvider'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

interface Props {
  vesselVisitId: number
  orderId?: number
}

export const TallymanEquipmentSelector = observer(({ vesselVisitId, orderId }: Props) => {
  const { equipmentStore, tallymanOperationsUIStore } = useOperationsStore()
  const { t } = useTranslate()

  useEffect(() => {
    tallymanOperationsUIStore.selectEquipment()
    equipmentStore.loadBy(vesselVisitId, orderId)
  }, [equipmentStore, vesselVisitId, orderId, tallymanOperationsUIStore])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const eqId = +event.target.value
    tallymanOperationsUIStore.selectEquipment(eqId)
  }

  return (
    <Box sx={{ paddingLeft: '14px' }}>
      <h5>{t('selectEquipment')}</h5>
      <FormControl>
        <RadioGroup defaultValue={0} onChange={handleChange}>
          {equipmentStore.items.map(eq => (
            <FormControlLabel
              data-cy={`equipment-selector`}
              key={eq.id}
              value={eq.id}
              control={<Radio />}
              label={eq.name}
              sx={{ paddingX: '.5rem' }}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  )
})
