import { RailVisitResponseDto, RailVisitsApi } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'
import { Event, IEvent, IQueryHandler, IQueryMessage } from '../messageBus'

export class GetRailVisitByIdQuery implements IQueryMessage {
  static type = 'GetRailVisitByIdQuery'
  type = GetRailVisitByIdQuery.type

  constructor(public id: number) {}

  getKey = () => {
    return JSON.stringify(this)
  }
}

const httpClient = createApiClient(RailVisitsApi)

export const getRailVisitByIdQueryHandler: IQueryHandler<
  GetRailVisitByIdQuery,
  IEvent<RailVisitResponseDto>
> = async query => {
  const { data } = await httpClient.getById(query.id)

  return new Event(GetRailVisitByIdQuery.type, data)
}
