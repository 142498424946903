import { CoolingOrderDto } from '@operations/app/api'

import { ReeferTemperatureStore } from '@operations/stores/ReeferTemperatureStore'
import _ from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import { ReeferMonitoringFiltersUIStore } from './reefer-monitoring-filters.ui-store'

export class ReeferMonitoringUIStore {
  historicalCoolingOrders: CoolingOrderDto[] = []

  constructor(
    private reeferTemperatureStore: ReeferTemperatureStore,
    private filtersUIStore: ReeferMonitoringFiltersUIStore,
  ) {
    makeObservable(this, {
      historicalCoolingOrders: observable,

      getCoolingOrdersHistoryByContainer: action,

      sortedItems: computed,
      filteredItems: computed,
      items: computed,
    })
  }

  public get sortedItems(): CoolingOrderDto[] {
    return this.reeferTemperatureStore.coolingOrders
  }

  public get filteredItems(): CoolingOrderDto[] {
    if (this.filtersUIStore.areFiltersAndSearchEmpty) {
      return this.sortedItems
    }

    if (this.filtersUIStore.containerNumber) {
      return this.sortedItems.filter(item =>
        this.doesItemHaveContainerNumber(item, this.filtersUIStore.containerNumber!),
      )
    }

    const filters = this.filtersUIStore.filter!

    return this.sortedItems.filter(
      item =>
        this.doesItemHaveYardBlock(item, filters.yardBlockIds) &&
        this.doesItemHaveCustomer(item, filters.customerIds) &&
        this.doesItemHaveInboundCarrierType(item, filters.inboundCarrierType) &&
        this.doesItemHaveOutboundCarrierType(item, filters.outboundCarrierType),
    )
  }

  public get items(): CoolingOrderDto[] {
    return [...this.filteredItems, ...this.historicalCoolingOrders]
  }

  public async getCoolingOrdersHistoryByContainer(): Promise<void> {
    if (!this.filtersUIStore.containerNumber) {
      this.historicalCoolingOrders = []
      return
    }

    const data = await this.reeferTemperatureStore.getCoolingOrdersHistoryByContainer(
      this.filtersUIStore.containerNumber,
    )

    this.historicalCoolingOrders = _.orderBy(data, x => x.lastRecordedTemperatureDateTime, 'desc')
  }

  private doesItemHaveContainerNumber(item: CoolingOrderDto, containerNumber: string) {
    return (
      item.containerNumber &&
      item.containerNumber.toLowerCase().includes(containerNumber.toLowerCase())
    )
  }

  private doesItemHaveYardBlock(item: CoolingOrderDto, selectedYardBlockIds?: string[] | null) {
    return (
      !selectedYardBlockIds?.length ||
      (item.yardBlock && selectedYardBlockIds.includes(item.yardBlock))
    )
  }

  private doesItemHaveCustomer(item: CoolingOrderDto, selectedCustomerIds?: string[] | null) {
    return (
      !selectedCustomerIds?.length || (item.customer && selectedCustomerIds.includes(item.customer))
    )
  }

  private doesItemHaveInboundCarrierType(item: CoolingOrderDto, carrierTypes?: string[] | null) {
    return (
      !carrierTypes?.length ||
      (item.inboundCarrierType && carrierTypes.includes(item.inboundCarrierType))
    )
  }

  private doesItemHaveOutboundCarrierType(item: CoolingOrderDto, carrierTypes?: string[] | null) {
    return (
      !carrierTypes?.length ||
      (item.outboundCarrierType && carrierTypes.includes(item.outboundCarrierType))
    )
  }
}
