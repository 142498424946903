import { withAuthenticationRequired } from '@auth0/auth0-react'
import { CustomersContainer } from '@billing/features/customer/containers/CustomersContainer'
import { CustomersDialogContainer } from '@billing/features/customer/containers/CustomersDialogContainer'
import { useTranslate } from '@tolgee/react'
import { FC } from 'react'
import { Helmet } from 'react-helmet-async'

const Customers: FC = withAuthenticationRequired(() => {
  const { t } = useTranslate()

  return (
    <>
      <Helmet>
        <title>{t('customersTomBillingService', 'Customers - TOM Billing Service')}</title>
      </Helmet>

      <CustomersContainer />
      <CustomersDialogContainer />
    </>
  )
})

export default Customers
