import { Box, Stack } from '@mui/material'
import { CarrierVisitPlanningCard } from '@planning/components/carrier-visit-planning/CarrierVisitPlanningCard'
import { observer } from 'mobx-react-lite'
import { IRailVisitPlanning } from '../../stores/RailVisitPlanningViewStore'
import { RailVisitCardDescription } from './RailVisitCardDescription'

interface Props {
  pixelPerHour: number
  plannedRailTrackVisits: IRailVisitPlanning[]
  emptySpaceHeight: number
  onClick: (id: number) => void
}

export const RailPlanningVisualization = observer(
  ({ plannedRailTrackVisits, pixelPerHour, emptySpaceHeight, onClick }: Props) => {
    return (
      <Stack gap={`${emptySpaceHeight}px`} width='100%' height='100%'>
        {plannedRailTrackVisits.map(plannedRailTrackVisit => (
          <Box
            key={`planned_rail_${plannedRailTrackVisit.railTrack.id}`}
            width='100%'
            height='100%'
            sx={{
              backgroundColor: 'rgb(223 227 232 / 40%)',
              backgroundImage: `
                linear-gradient(rgba(145, 158, 171, 0) 2px, transparent 2px), 
                linear-gradient(90deg, rgba(145, 158, 171, 0.24) 2px, transparent 1px),
                linear-gradient(90deg, rgba(145, 158, 171, 0.1) 1px, transparent 1px)
              `,
              backgroundSize: `150px 150px, ${pixelPerHour * 24}px ${pixelPerHour * 24}px, ${pixelPerHour * 4}px ${pixelPerHour * 4}px, ${pixelPerHour * 4}px ${pixelPerHour * 4}px`,
              backgroundPosition: '-2px -2px, -2px -2px, -1px -1px, -1px -1px',
            }}
            position='relative'
          >
            {plannedRailTrackVisit.visits.map(visit => (
              <CarrierVisitPlanningCard
                key={'visit_' + visit.id}
                visit={visit}
                onClick={onClick}
                descriptionComponent={<RailVisitCardDescription visit={visit} />}
              />
            ))}
          </Box>
        ))}
      </Stack>
    )
  },
)
