import { Box, Button, Card, CardContent } from '@mui/material'
import { CarrierVisitDirection, CarrierVisitDto } from '@storage/app/api'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useGoBack } from '@storage/pages/yard-planning-dashboard-details/hooks'
import { useTranslate } from '@tolgee/react'
import { Tile, useMinimalsTheme } from '@tom-ui/ui'
import { format } from 'date-fns'
import { observer } from 'mobx-react-lite'

interface Props {
  carrierVisit: CarrierVisitDto
  direction: CarrierVisitDirection
}

const formatDate = (dateString: string) => {
  const date = new Date(dateString)
  return format(date, 'H:mm, dd/MM/yyyy')
}

const CarrierVisitDetails = observer(({ carrierVisit, direction }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const { carrierVisitAllocationRulesV2UIStore } = useStores()

  const getFieldValue = (value?: string | null) => value ?? '-'

  const handleGoBack = useGoBack()

  const handleSave = async () => {
    await carrierVisitAllocationRulesV2UIStore.persistAllChanges(carrierVisit.id, direction)
    handleGoBack()
  }

  return (
    <Card>
      <CardContent sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
        <Box sx={{ display: 'flex', gap: theme.customSpacing.xl, flexWrap: 'wrap' }}>
          <Tile
            nowrap
            label={t('carrierYardPlan', 'Carrier yard plan')}
            value={getFieldValue(carrierVisit?.name)}
          />
          <Tile nowrap label={t('carrierDirection', 'Direction')} value={direction} />
          {carrierVisit?.status === 'Expected' && carrierVisit?.eta && (
            <Tile
              nowrap
              label={t('carrierEta', 'ETA')}
              value={getFieldValue(carrierVisit.eta) !== '-' ? formatDate(carrierVisit.eta) : '-'}
            />
          )}
          {carrierVisit?.status === 'Arrived' && carrierVisit?.ata && (
            <Tile
              nowrap
              label={t('carrierAta', 'ATA')}
              value={getFieldValue(carrierVisit.ata) !== '-' ? formatDate(carrierVisit.ata) : '-'}
            />
          )}
          {carrierVisit?.status === 'InOperation' && carrierVisit.etd && (
            <Tile
              nowrap
              label={t('carrierEtd', 'ETD')}
              value={getFieldValue(carrierVisit.etd) !== '-' ? formatDate(carrierVisit.etd) : '-'}
            />
          )}
          <Tile
            nowrap
            label={t('berth', 'Berth')}
            value={getFieldValue(carrierVisit?.berths.join(', '))}
          />
          {carrierVisit?.qmmFrom && carrierVisit?.qmmTo && (
            <Tile
              nowrap
              label={t('meterMarks', 'Meter marks')}
              value={`${carrierVisit.qmmFrom} - ${carrierVisit.qmmTo}`}
            />
          )}
        </Box>
        <Box>
          <Button onClick={handleGoBack}>{t('cancel', 'Cancel')}</Button>
          <Button
            onClick={handleSave}
            variant='contained'
            disabled={carrierVisitAllocationRulesV2UIStore.persistAllChangesIsLoading}
          >
            {carrierVisitAllocationRulesV2UIStore.persistAllChangesIsLoading
              ? t('savingChanges', 'Saving Changes...')
              : t('save', 'Save')}
          </Button>
        </Box>
      </CardContent>
    </Card>
  )
})

export default CarrierVisitDetails
