import { withAuthenticationRequired } from '@auth0/auth0-react'
import { EquipmentDialogContainer } from '@operations/features/equipments/containers/EquipmentDialogContainer'
import { EquipmentsContainer } from '@operations/features/equipments/containers/EquipmentsContainer'
import { useTranslate } from '@tolgee/react'
import { FC } from 'react'
import { Helmet } from 'react-helmet-async'

const Equipment: FC = withAuthenticationRequired(() => {
  const { t } = useTranslate()

  return (
    <>
      <Helmet>
        <title>{t('equipmentTomOperation', 'Equipment - Tom Operation')}</title>
      </Helmet>

      <EquipmentsContainer />
      <EquipmentDialogContainer />
    </>
  )
})
export default Equipment
