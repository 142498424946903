import {
  Box,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { ReeferTemperatureDto } from '@operations/app/api'
import { useTranslate } from '@tolgee/react'
import { EditIcon } from '@tom-ui/ui'
import dayjs from 'dayjs'

interface Props {
  coolingOrderId: number
  items?: ReeferTemperatureDto[] | null
  onEditItem?: (id: number, recordId: number) => Promise<void> | void
}

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  padding: '1rem 10%',
}))

const TableContainer = styled(Table)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  minWidth: '100%',
}))

const TableRowContainer = styled(TableRow)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.grey[400]}`,
}))

export const ReeferHistoryTable = ({ coolingOrderId, items, onEditItem }: Props) => {
  const { t } = useTranslate()

  return (
    <Container>
      {items?.length ? (
        <TableContainer>
          <TableHead>
            <TableRow>
              <TableCell>{t('temperature', 'Temperature')}</TableCell>
              <TableCell>{t('pluggedIn', 'Plugged in')}</TableCell>
              <TableCell>{t('recordedAt', 'Recorded at')}</TableCell>
              <TableCell>{t('additionalInfo', 'Additional info')}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items?.map(item => (
              <TableRowContainer key={item.id}>
                <TableCell>{item.temperature}</TableCell>
                <TableCell>{item.isPluggedIn ? t('yes', 'Yes') : t('no', 'No')}</TableCell>
                <TableCell>
                  {item.recordedAt ? dayjs(item.recordedAt).format('MM/DD/YYYY hh:mm A') : '-'}
                </TableCell>
                <TableCell>{item.additionalInfo}</TableCell>
                <TableCell align='right' width='25px'>
                  {onEditItem && (
                    <Tooltip title={t('edit', 'Edit')}>
                      <IconButton onClick={() => onEditItem(coolingOrderId, item.id)}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRowContainer>
            ))}
          </TableBody>
        </TableContainer>
      ) : (
        <Typography variant='h4' textAlign='center'>
          {t('noTemperatureRecords', 'No temperature records')}
        </Typography>
      )}
    </Container>
  )
}
