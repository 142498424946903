import { Tooltip } from '@mui/material'
import { TrainIcon, TruckIcon, VesselIcon } from '@tom-ui/ui'
import { useTranslate } from '@tolgee/react'

export const CarrierType = {
  Universal: 'Universal',
  Truck: 'Truck',
  Vessel: 'Vessel',
  Train: 'Train',
} as const

export type CarrierType = (typeof CarrierType)[keyof typeof CarrierType]
interface Props {
  type?: CarrierType
}

export const CarrierTypeIcon = ({ type }: Props) => {
  const { t } = useTranslate()
  switch (type) {
    case CarrierType.Truck:
      return (
        <Tooltip title={t('truck', 'Truck')}>
          <TruckIcon />
        </Tooltip>
      )

    case CarrierType.Vessel:
      return (
        <Tooltip title={t('vessel', 'Vessel')}>
          <VesselIcon />
        </Tooltip>
      )

    case CarrierType.Train:
      return (
        <Tooltip title={t('train', 'Train')}>
          <TrainIcon />
        </Tooltip>
      )

    default:
      return <></>
  }
}
