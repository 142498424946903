import { ContainerEventDto, ContainersApi } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'
import { Event, IEvent, IQueryHandler, IQueryMessage } from '../messageBus'

export class GetContainerEventsQuery implements IQueryMessage {
  static type = 'GetContainerEventsQuery'
  type = GetContainerEventsQuery.type

  constructor(public containerNumber: string) {}

  getKey = () => {
    return JSON.stringify(this)
  }
}

const httpClient = createApiClient(ContainersApi)

export const getContainerEventsQueryHandler: IQueryHandler<
  GetContainerEventsQuery,
  IEvent<ContainerEventDto[]>
> = async query => {
  const { data } = await httpClient.events(query.containerNumber)

  return new Event(GetContainerEventsQuery.type, data)
}
