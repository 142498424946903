import { Box, Card, CardContent } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { ReactNode } from 'react'
import { TextButton } from './TextButton'
import { WidgetHeader, WidgetHeaderProps } from './WidgetHeader'

export interface WidgetStore {
  showAll?: boolean
  showExpand?: boolean
  expand?: () => void
  collapse?: () => void
}

interface Props extends WidgetHeaderProps {
  children: ReactNode
  expandable: boolean
  store?: WidgetStore
}

export const Widget = observer(({ children, title, icon, expandable, onAction, store }: Props) => {
  const { t } = useTranslate()
  return (
    <Card sx={{ overflowY: 'auto', width: '370px' }}>
      <CardContent>
        <WidgetHeader title={title} icon={icon} onAction={onAction} />
        {children}
        {expandable && store?.showExpand && (
          <Box sx={{ textAlign: 'right' }}>
            {store?.showAll ? (
              <TextButton label={t('showLess', 'Show less')} onClick={store.collapse} />
            ) : (
              <TextButton label={t('showMore', 'Show more')} onClick={store.expand} />
            )}
          </Box>
        )}
      </CardContent>
    </Card>
  )
})
