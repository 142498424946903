import { ContainerJourneysApi } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'
import { ContainerJourneyNonNumericFilter } from '@planning/pages/ServiceOrders/Models/NonNumeric.model'

class ContainerJourneysService {
  httpClient = createApiClient(ContainerJourneysApi)

  async get(containerId: number, includeCompleted?: boolean) {
    const { data } = await this.httpClient.get(containerId, includeCompleted)

    return data
  }

  async getByNonNumeric({
    length,
    height,
    type,
    operator,
    isoCode,
  }: ContainerJourneyNonNumericFilter) {
    const { data } = await this.httpClient.getByNonNumeric(
      length ? +length : undefined,
      height,
      type,
      operator,
      isoCode,
    )

    return data
  }
}

const containerJourneysService = new ContainerJourneysService()

export default containerJourneysService
