import { Box, Stack, Typography } from '@mui/material'
import {
  ContainerSection,
  StackDto,
  YardBlockBayDto,
  YardBlockDto,
  YardBlockRowDto,
} from '@storage/app/api'
import { useTranslate } from '@tolgee/react'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import YardPlanningDashboardStacks from './YardPlanningDashboardStacks'

interface YardPlanningDashboardBlockProps {
  yardBlock: YardBlockDto
  rows: YardBlockRowDto[]
  bays: YardBlockBayDto[]
  stacks: StackDto[]
}

const YardPlanningDashboardBlock = observer(
  ({ yardBlock, rows, bays, stacks }: YardPlanningDashboardBlockProps) => {
    const { t } = useTranslate()
    const [availableSpace, setAvailableSpace] = useState(0)

    const stackWidthPixels = '70px'
    const rowLabelPixels = '30px'

    useEffect(() => {
      setAvailableSpace(
        stacks
          .filter(stack => stack.section === ContainerSection.Head)
          .reduce((sum, stack) => sum + (stack.numberOfSlots - stack.numberOfOccupiedSlots), 0),
      )
    }, [stacks])

    return (
      <Stack gap={1}>
        <Stack flexDirection='row' marginLeft={rowLabelPixels} gap={1} alignItems='center'>
          <Typography variant='h4'>{yardBlock.name}</Typography>
          {availableSpace > 0 ? (
            <>
              <Typography variant='body1'>{t('freeSpace', 'Free space')}</Typography>|
              <Typography variant='body1'>20ft: {availableSpace}</Typography>
            </>
          ) : (
            <Typography>{t('fullyOccupied', 'Fully occupied')}</Typography>
          )}
        </Stack>

        <Stack>
          {_(rows)
            .orderBy(x => x.sequenceNumber)
            .value()
            .map(row => (
              <Stack key={`row_${row.id}`} flexDirection='row' height={'24px'}>
                <Typography width={rowLabelPixels} variant='subtitle2' flex='none'>
                  {row.name}
                </Typography>

                <YardPlanningDashboardStacks
                  stackWidthPixels={stackWidthPixels}
                  stacks={_(stacks)
                    .filter(
                      stack => stack.rowId === row.id && stack.section === ContainerSection.Head,
                    )
                    .orderBy(x => x.baySequenceNumber)
                    .value()}
                  maxTier={yardBlock.maxTier}
                  bayCount={bays.length}
                />
              </Stack>
            ))}
        </Stack>

        <Stack flexDirection='row'>
          <Box width={rowLabelPixels} flex='none'></Box>
          {_(bays)
            .orderBy(x => x.sequenceNumber)
            .value()
            .map(bay => (
              <Typography
                key={`bay_${bay.id}`}
                width={stackWidthPixels}
                textAlign='center'
                variant='subtitle2'
                flex='none'
              >
                {bay.name}
              </Typography>
            ))}
        </Stack>
      </Stack>
    )
  },
)

export default YardPlanningDashboardBlock
