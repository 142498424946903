import { SxProps, Theme } from '@mui/material'
import { orderStatus } from '@planning/constants'
import { IReleaseOrderItem } from '@planning/rt-stores/releaseOrder/ReleaseOrderItem'
import { IPaginatedStoreWithItems } from '@planning/stores/PaginatedStore'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FilterPaper } from './FilterPaper'
import { StatusRadioGroupFilter } from './StatusRadioGroupFilter'

interface Props {
  sx?: SxProps<Theme>
  store: IPaginatedStoreWithItems<IReleaseOrderItem>
}
export const ReleaseOrderFilter = observer(({ sx, store }: Props) => {
  const { t } = useTranslate()
  return (
    <FilterPaper sx={{ minWidth: '24rem', ...sx }}>
      <StatusRadioGroupFilter
        store={store}
        options={orderStatus}
        title={t('pick-upOrderStatus', 'Pick-up order status')}
      />
    </FilterPaper>
  )
})
