import { usePlanningStore } from '@planning/AppProvider'
import { ReleaseOrderEditStore } from '@planning/rt-stores/releaseOrder/ReleaseOrderEditStore'
import {
  IReleaseOrderItem,
  ReleaseOrderItem,
} from '@planning/rt-stores/releaseOrder/ReleaseOrderItem'
import { IFilterDelegate, ISortDelegate } from '@planning/stores/PaginatedLocalStore'
import { SortingModel } from '@planning/stores/PaginatedStore'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { ReleaseOrdersTable } from './Components/ReleaseOrdersTable'

export const releaseOrdersSortingDelegate: ISortDelegate<IReleaseOrderItem> = (
  sortingModel,
  a,
  b,
) => {
  const getValue = (item: IReleaseOrderItem, sortingModel: SortingModel<IReleaseOrderItem>) => {
    if (sortingModel.orderBy && _.has(item.data, sortingModel.orderBy)) {
      return _.get(item.data, sortingModel.orderBy)
    }

    return item.data.orderReferenceNumber
  }

  const valA = getValue(a, sortingModel)
  const valB = getValue(b, sortingModel)
  let sort = 0

  // todo: [north] sort pickup window

  if ((typeof valA === 'number' || typeof valB === 'number') && valA !== valB) {
    sort = (valA ?? 0) > (valB ?? 0) ? -1 : 1
  } else if (typeof valA === 'string' && typeof valB === 'string') {
    sort = valA.localeCompare(valB)
  }

  return sortingModel.isDescending ? -sort : sort
}

export const releaseOrdersFilterDelegate: IFilterDelegate<IReleaseOrderItem> = (
  filter: string,
  item: IReleaseOrderItem,
) => {
  const filterableProperties: string[] = [
    'data.orderReferenceNumber',
    'data.containerNumber',
    'data.status',
  ]

  if (filter !== '')
    return filterableProperties
      .map(p => p as keyof ReleaseOrderItem)
      .some(p => {
        const prop = _.get(item, p)
        if (typeof prop === 'string') return prop.toLowerCase().includes(filter.toLowerCase())

        return false
      })

  return true
}

export const ReleaseOrdersPage = observer(() => {
  const { releaseOrderViewStore } = usePlanningStore()

  const editStore = new ReleaseOrderEditStore()

  useEffect(() => {
    releaseOrderViewStore.loadCurrentPage()
  }, [releaseOrderViewStore])

  return <ReleaseOrdersTable viewStore={releaseOrderViewStore} editStore={editStore} />
})
