import { SelectOption } from '@operations/app/models'

export interface WorkInstructionFilter {
  origin?: string | null
  destination?: string | null
  process?: string[] | null
  status?: string[] | null
  equipment?: SelectOption | null
}

export const defaultValues: WorkInstructionFilter = {
  origin: '',
  destination: '',
  process: [],
  status: [],
  equipment: null,
}
