import { Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { LabelBox } from '@storage/components/LabelBox'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useMemo } from 'react'
import { CarrierVisitAllocationRuleDto } from '@storage/app/api'
import { useStores } from '@storage/hooks/use-stores.hook'
import IconActionMenu from '@storage/features/carrier-visit-allocation-rules/components/IconActionMenu'
import { mapYardPositionDtoToYardPositionDescriptor } from '@storage/features/carrier-visit-allocation-rules/forms/allocation-rule-templates-form/allocation-rule-templates-form.mapper'

interface CarrierVisitAllocationRulesListItemProps {
  allocationRule: CarrierVisitAllocationRuleDto
  selected?: boolean
  onClick?: () => void
}
const CarrierVisitAllocationRulesListItem = ({
  allocationRule,
  selected,
  onClick,
}: CarrierVisitAllocationRulesListItemProps) => {
  const { palette } = useMinimalsTheme()

  const { carrierVisitAllocationRulesV2UIStore } = useStores()
  const dialogUtilStore = carrierVisitAllocationRulesV2UIStore.listItemDialogUtilStore

  const ruleItemStyle = {
    bgcolor: selected ? '#1890FF14' : '',
    '&:hover': {
      backgroundColor: '#1890FF14',
    },
  }

  const destination = useMemo(
    () => mapYardPositionDtoToYardPositionDescriptor(allocationRule.destination),
    [allocationRule.destination],
  )

  const handleOnEdit = () => dialogUtilStore.toggleDialog('Edit', allocationRule.id)

  const handleOnDelete = () => dialogUtilStore.toggleDialog('Delete', allocationRule.id)

  return (
    <Stack
      px={2}
      py={0.5}
      sx={ruleItemStyle}
      onClick={onClick}
      direction='row'
      justifyContent='space-between'
      alignItems='center'
    >
      <Typography sx={{ maxWidth: '190px' }} noWrap variant='body1'>
        {allocationRule.name}
      </Typography>
      <Stack direction='row' alignItems='center' gap={1.5}>
        <Box>
          <LabelBox
            label={destination}
            color={palette.info.main}
            bgColor={`${palette.info.main}14`}
          />
        </Box>
        <IconActionMenu onEdit={handleOnEdit} onDelete={handleOnDelete} />
      </Stack>
    </Stack>
  )
}

export default CarrierVisitAllocationRulesListItem
