import { Chip } from '@mui/material'
import { Stack } from '@mui/system'

interface ChipListContainerProps {
  items: string[]
}

const ChipListContainer = ({ items }: ChipListContainerProps) => (
  <Stack direction='row' spacing={1}>
    {items.map((item, index) => (
      <Chip key={index} label={item} color='secondary' />
    ))}
  </Stack>
)

export default ChipListContainer
