import {
  CarrierVisitDirection,
  CarrierVisitDto,
  CarrierVisitStatus,
  CarrierVisitSummaryDto,
  CarrierVisitsApi,
  CarrierVisitsApiGetAllRequest,
} from '@storage/app/api'
import { SelectOption } from '@storage/app/models'
import { EntityStore } from '@storage/app/store/entity.store'
import {
  CarrierVisitListItem,
  CarrierVisitListItemFactory,
} from '@storage/components/vessel-visits-list/models'
import { YardManagementHubConnection } from '@storage/hubs/yard-management.hub-connection'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { AlertStore, AppAlert } from './alert.store'
import { IssueStore } from './issue.store'

export interface CarrierVisitInfo extends CarrierVisitDto {
  summary?: CarrierVisitSummaryDto
}

export class CarrierVisitStore extends EntityStore<CarrierVisitDto> {
  carrierVisitSummaries: Map<number, CarrierVisitSummaryDto> = new Map()
  constructor(
    private carrierVisitApi: CarrierVisitsApi,
    private _issueStore: IssueStore,
    yardManagementHubConnection: YardManagementHubConnection,
  ) {
    super()

    makeObservable(this, {
      carrierVisitSummaries: observable,
      loadCarrierVisitSummary: action,
      loadCarrierVisitSummaryV2: action,
    })

    yardManagementHubConnection.onCarrierVisitUpserted(carrierVisit => {
      this.addOrUpdate(carrierVisit)
    })
    yardManagementHubConnection.onCarrierVisitsDeleted(carrierVisitIds => {
      carrierVisitIds.forEach(id => this.remove(id))
    })
  }

  static isLoadingDataNotEmpty(carrierVisitSummary: CarrierVisitSummaryDto | undefined) {
    return (
      carrierVisitSummary &&
      (carrierVisitSummary.plannedLoad > 0 ||
        carrierVisitSummary.unplannedLoad > 0 ||
        carrierVisitSummary.inYardLoad > 0)
    )
  }

  static isDischargeDataNotEmpty(carrierVisitSummary: CarrierVisitSummaryDto | undefined) {
    return (
      carrierVisitSummary &&
      (carrierVisitSummary.plannedDischarge > 0 ||
        carrierVisitSummary.unplannedDischarge > 0 ||
        carrierVisitSummary.inYardDischarge > 0)
    )
  }

  static isDischargeAndLoadingEmpty(carrierVisitSummary: CarrierVisitSummaryDto | undefined) {
    return (
      !CarrierVisitStore.isLoadingDataNotEmpty(carrierVisitSummary) &&
      !CarrierVisitStore.isDischargeDataNotEmpty(carrierVisitSummary)
    )
  }

  public sortActiveCarrierVisits(status: CarrierVisitStatus): CarrierVisitInfo[] {
    return (
      this.entries
        .filter(v => v.status === status)
        .map(vv => {
          return {
            ...vv,
            summary: this.carrierVisitSummaries.get(vv.id),
          }
        })
        // Sort by unplanned carrier visits
        .sort((a, b) => {
          const checkCondition = (item: any) =>
            item.summary?.unplannedLoad > 0 ||
            item.summary?.unplannedDischarge > 0 ||
            CarrierVisitStore.isDischargeAndLoadingEmpty(item.summary)

          const aPriority = checkCondition(a) ? 0 : 1
          const bPriority = checkCondition(b) ? 0 : 1

          return aPriority - bPriority
        })
    )
  }

  public mapToListItems(carrierVisits: CarrierVisitDto[]): CarrierVisitListItem[] {
    return carrierVisits.map(CarrierVisitListItemFactory)
  }

  public mapToOptions(entries: CarrierVisitDto[]): SelectOption[] {
    return entries.map(({ id, name }) => ({ label: name, value: id.toString() }))
  }

  public async load(id: number): Promise<CarrierVisitDto> {
    const { data: carrierVisit } = await this.carrierVisitApi.get({ id })

    this.addOrUpdate(carrierVisit)

    return carrierVisit
  }

  public async loadList(status?: CarrierVisitsApiGetAllRequest): Promise<void> {
    const { data: carrierVisits } = await this.carrierVisitApi.getAll(status)

    this.setAll(carrierVisits)
  }

  public getById(id: number): CarrierVisitDto | undefined {
    return this.data.get(id)
  }

  public getActiveCarrierVisits(): CarrierVisitDto[] {
    return this.entries.filter(
      v =>
        v.status === CarrierVisitStatus.Expected ||
        v.status === CarrierVisitStatus.InOperation ||
        v.status === CarrierVisitStatus.Arrived,
    )
  }

  public getArrivalTimeToString(arrival: any) {
    return new Date(arrival).toLocaleDateString('de', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      hour12: false,
      minute: '2-digit',
    })
  }

  async loadCarrierVisitSummary(id: number, visitDirection?: CarrierVisitDirection) {
    const { data: carrierVisitSummary } = await this.carrierVisitApi.getCarrierVisitSummary({
      id,
      visitDirection,
    })

    runInAction(() => {
      this.carrierVisitSummaries.set(id, carrierVisitSummary)
    })
  }

  async loadCarrierVisitSummaryV2(id: number, visitDirection?: CarrierVisitDirection) {
    const { data: carrierVisitSummary } = await this.carrierVisitApi.getCarrierVisitSummaryV2({
      id,
      visitDirection,
    })

    runInAction(() => {
      this.carrierVisitSummaries.set(id, carrierVisitSummary)
    })
  }

  loadCarrierVisitsIssues() {
    this._issueStore.loadByType('planning')
  }

  getCarrierVisitIssues(carrierVisitId: number) {
    return this._issueStore.issues.filter(i => i.context?.carrierVisitId === carrierVisitId)
  }

  /**
   * Transform issues into alerts
   * @param carrierVisitId
   * @returns Alert[]
   */
  getCarrierVisitAlerts(carrierVisitId: number): AppAlert[] {
    return AlertStore.getAlertsFromIssues(this.getCarrierVisitIssues(carrierVisitId))
  }
}
