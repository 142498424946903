import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { BerthResponseDto, BerthSide, VesselVisitDto } from '@planning/app/api'
import { vesselVisitService } from '@planning/services'
import { useTranslate } from '@tolgee/react'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import { FC, useMemo } from 'react'
import { BerthAssignmentAutoComplete } from '../Berth/BerthAssignmentAutoComplete'
import { VesselVisitHeaderContainer } from '../Header/VesselVisitHeaderContainer'

interface Props {
  vesselVisit: VesselVisitDto
  assignedBerths?: BerthResponseDto[]
}

export const VesselVisitHeaderBerth: FC<Props> = observer(({ vesselVisit, assignedBerths }) => {
  const { appViewStore, berthItemStore } = usePlanningStore()
  const { t } = useTranslate()

  const berths = useMemo(
    () => _.values(berthItemStore.elements)?.map(item => item.data) ?? [],
    [berthItemStore.elements],
  )

  if (!vesselVisit) return <></>

  return (
    <VesselVisitHeaderContainer title='Berth'>
      <Grid container display='flex' justifyContent='space-between' spacing={4}>
        <Grid item xs={12} lg={7}>
          <BerthAssignmentAutoComplete
            berths={berths}
            assignedBerths={assignedBerths}
            onBerthAssignmentChange={async (assignedBerths: BerthResponseDto[]) => {
              try {
                await vesselVisitService.assignBerths(vesselVisit.id, assignedBerths)
              } catch (error) {
                appViewStore.setShowAlert(
                  'error',
                  t('failedToAssignBerths', 'Failed to assign berths'),
                )
              }
            }}
          />
        </Grid>

        <Grid item xs={12} lg={5}>
          <FormControl fullWidth>
            <InputLabel>{t('side', 'Side')}</InputLabel>
            <Select
              data-cy='berth-side-select'
              value={vesselVisit.berthSide ?? ''}
              label='Side'
              onChange={async e => {
                if (e.target.value && vesselVisit) {
                  const berthSide = e.target.value as BerthSide
                  await vesselVisitService.put({
                    ...vesselVisit,
                    berthSide: berthSide,
                    vesselIds: vesselVisit.carrierIds,
                  })
                }
              }}
            >
              <MenuItem value={BerthSide.Port}>{BerthSide.Port}</MenuItem>
              <MenuItem value={BerthSide.Starboard}>{BerthSide.Starboard}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </VesselVisitHeaderContainer>
  )
})
