import { ControlledCheckbox } from '@billing/components/form-controls'
import { Box, Button, FormGroup } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { HandlingServicesPreBillingUIStore } from '../../stores/handling-services-prebilling-report.ui-store'
import { GCOrdersCard } from '../storagePreBillingReport/GCOrdersCard'

interface Props {
  handlingStore: HandlingServicesPreBillingUIStore
}

export const HandlingPreBillingReportForm = observer(({ handlingStore }: Props) => {
  const { t } = useTranslate()

  const { control, formState, reset, setValue, watch } = useForm<FieldValues>({
    defaultValues: {
      OrderId: handlingStore.orders.reduce(
        (acc, order) => {
          acc[order.externalOrderId] = false
          return acc
        },
        {} as Record<number, boolean>,
      ),
    },
  })
  const [initialized, setInitialized] = useState(false)

  const formValues = watch()

  useEffect(() => {
    const loadHandlingPrebillingOrders = async () => {
      await handlingStore.loadData()
    }

    loadHandlingPrebillingOrders()
  }, [handlingStore])

  useEffect(() => {
    const computeFormValues = () => {
      if (handlingStore.orders.length > 0) {
        const defaultValues = handlingStore.orders.reduce(
          (acc, order) => {
            acc[order.externalOrderId] = false
            return acc
          },
          {} as Record<string, boolean>,
        )
        reset({ OrderId: defaultValues })
      }
      setInitialized(true)
    }
    computeFormValues()
  }, [reset, handlingStore.orders])

  useEffect(() => {
    const setIds = () => {
      const selectedOrderIds = Object.entries(formValues.OrderId)
        .filter(([, value]) => value === true)
        .map(([key]) => Number(key))

      handlingStore.setIdsToSubmit(selectedOrderIds)
    }
    setIds()
  }, [formValues, handlingStore])

  const handleSelectAll = () => {
    handlingStore.orders.forEach(order => {
      setValue(`OrderId.${order.externalOrderId}`, true)
    })
  }

  const handleUnselectAll = () => {
    handlingStore.orders.forEach(order => {
      setValue(`OrderId.${order.externalOrderId}`, false)
    })
  }

  if (!initialized) {
    return <p>Loading...</p>
  }

  return (
    <Box>
      {handlingStore.orders.length > 1 && (
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            mb: 1,
            ml: 4,
          }}
        >
          <Button size='small' variant='outlined' onClick={handleSelectAll}>
            {t('selectAll', 'Select all')}
          </Button>
          <Button size='small' variant='outlined' onClick={handleUnselectAll}>
            {t('unselectAll', 'Unselect all')}
          </Button>
        </Box>
      )}
      <Box
        component='form'
        noValidate
        autoComplete='off'
        sx={{ display: 'flex', flexDirection: 'column', maxHeight: 500, overflow: 'auto' }}
      >
        <FormGroup>
          {handlingStore.orders.map(order => (
            <ControlledCheckbox
              key={order.externalOrderId}
              control={control}
              formState={formState}
              name={`OrderId.${order.externalOrderId}`}
              label={<GCOrdersCard order={order} />}
            />
          ))}
        </FormGroup>
      </Box>
    </Box>
  )
})
