import {
  CarrierVisitDirection,
  CarrierVisitSummaryFilterDto,
  CarrierVisitsApi,
  ContainerStatus,
} from '@storage/app/api'
import { CarrierVisitAllocationRulesV2UIStore } from '@storage/features/carrier-visit-allocation-rules/stores/carrier-visit-allocation-rules-v2.ui-store'
import { action, makeObservable, observable, runInAction } from 'mobx'

interface SelectedPrefilterIdentifier {
  index: number
  locationStatus?: ContainerStatus
}

export class PrefiltersStore {
  prefilters: CarrierVisitSummaryFilterDto[] = []

  selectedPrefilter?: SelectedPrefilterIdentifier = {
    index: 0,
    locationStatus: 'NotReserved',
  }

  constructor(
    private readonly api: CarrierVisitsApi,
    private readonly vesselVisitId: number,
    private readonly handlingDirection: CarrierVisitDirection,
    private readonly _carrierVisitAllocationRulesUIStore: CarrierVisitAllocationRulesV2UIStore,
  ) {
    makeObservable(this, {
      prefilters: observable,
      selectedPrefilter: observable,
      setSelectedPrefilter: action,
    })
  }

  public async load(): Promise<void> {
    const { data } = await this.api.getCarrierVisitSummary({
      id: this.vesselVisitId,
      visitDirection: this.handlingDirection,
    })

    runInAction(() => {
      this.prefilters = data.filters.filter((x, i) => i === 0 || x.planned > 0 || x.unplanned > 0)
    })
  }

  setSelectedPrefilter(selected: SelectedPrefilterIdentifier | undefined) {
    if (selected) {
      // Disable selected allocation rule
      this._carrierVisitAllocationRulesUIStore.setSelectedAllocationRule()
    }
    this.selectedPrefilter = selected
  }
}
