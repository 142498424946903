import { Tooltip } from '@mui/material'
import { CloseIcon } from '../../icons'
import { IconButton } from './IconButton'
import { ButtonSize } from './button.model'

interface Props {
  onClose: (event?: any) => Promise<void> | void
  tooltip?: string
  tooltipPlacement?: 'left' | 'right' | 'top' | 'bottom'
  size?: ButtonSize
}

export const CloseButton = ({ onClose, tooltip, tooltipPlacement, size }: Props) => {
  return (
    <Tooltip title={tooltip ?? 'close'} placement={tooltipPlacement ?? 'bottom'}>
      <IconButton size={size ?? 'medium'} color='default' onClick={onClose}>
        <CloseIcon fontSize={size ?? 'medium'} />
      </IconButton>
    </Tooltip>
  )
}
