import { usePlanningStore } from '@planning/AppProvider'
import { ITruckVisitItem } from '@planning/rt-stores/truckVisit/TruckVisitItem'
import { NavigationStack } from '@planning/stores/NavigationStack'
import {
  IFilterDelegate,
  ISortDelegate,
  useWrappedLocalPagination,
} from '@planning/stores/PaginatedLocalStore'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { useEffect } from 'react'
import { TruckAppointmentMobileViewTable } from './components/TruckAppointmentMobileViewTable'

const sortDelegate: ISortDelegate<ITruckVisitItem> = (_, a, b) => {
  const valA = a.arrival
  const valB = b.arrival

  if (moment(valA).isValid() && moment(valB).isValid()) {
    const dateA = moment(valA)
    const dateB = moment(valB)
    return dateA.diff(dateB)
  }

  return 0
}

const filterDelegate: IFilterDelegate<ITruckVisitItem> = (
  filter: string,
  item: ITruckVisitItem,
) => {
  if (
    [...item.inboundOrders, ...item.outboundOrders].some(
      c =>
        c.data.containerNumber?.toLowerCase().includes(filter.toLowerCase()) ||
        c.data.referenceNumber?.toLowerCase().includes(filter.toLowerCase()),
    )
  )
    return true

  const filterableProperties: string[] = ['truck.data.licensePlate']

  if (filter !== '')
    return filterableProperties.some(p => {
      const prop = _.get(item, p)
      if (prop && typeof prop === 'string') return prop.toLowerCase().includes(filter.toLowerCase())

      return false
    })

  return true
}

export const TruckAppointmentMobileViewPage = observer(() => {
  const { truckVisitItemStore, truckVisitQueryService } = usePlanningStore()

  const gateClerkTruckVisitPaginatedStore = useWrappedLocalPagination(
    () => _.values(truckVisitItemStore.elements),
    truckVisitQueryService.fetch,
    sortDelegate,
    filterDelegate,
  )

  useEffect(() => {
    gateClerkTruckVisitPaginatedStore.loadCurrentPage()
  }, [gateClerkTruckVisitPaginatedStore])

  return (
    <NavigationStack>
      <TruckAppointmentMobileViewTable paginatedStore={gateClerkTruckVisitPaginatedStore} />
    </NavigationStack>
  )
})
