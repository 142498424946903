import { Button, Card, CardContent, CardHeader, Stack, Typography } from '@mui/material'
import { RailTrackResponseDto } from '@planning/app/api'
import { NoInfoMessage } from '@planning/components/dashboard-cards/NoInfoMessage'
import { LengthConflictChip } from '@planning/components/LengthConflictChip'
import { MissingRailcarLengthConflictChip } from '@planning/components/MissingRailcarLengthConflictChip'
import { TrackConflictChip } from '@planning/components/TrackConflictChip'
import { IRailVisitItem } from '@planning/rt-stores/railVisit/RailVisitItem'
import { useTranslate } from '@tolgee/react'

import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { useNavigate } from 'react-router'

interface IProps {
  railVisit: IRailVisitItem
}

const RailTrackNames: FC<{ railTracks: RailTrackResponseDto[] }> = ({ railTracks }) => {
  if (railTracks.length) {
    const railTrackNames = railTracks.map(i => i.name).join(', ')
    return <Typography variant='body1'>{railTrackNames}</Typography>
  } else {
    return NoInfoMessage()
  }
}

export const RailTracksPlanningView = observer(({ railVisit }: IProps) => {
  const { t } = useTranslate()
  const navigate = useNavigate()

  const handleUpdate = () => {
    navigate(`/rail-visits-planning/${railVisit.id}`)
  }

  const isRailcarLengthConflicted =
    railVisit.data.isInboundRailcarLengthConflicted ||
    railVisit.data.isOutboundRailcarLengthConflicted

  return (
    <Card>
      <CardHeader
        action={
          <Stack direction='row' spacing={2} alignItems='center'>
            {railVisit.data.isConflicted && <TrackConflictChip />}
            {railVisit.data.hasAssignedRailcarsWithoutLength && (
              <MissingRailcarLengthConflictChip />
            )}

            {isRailcarLengthConflicted && <LengthConflictChip />}

            <Button color='primary' onClick={handleUpdate} data-cy='update-rail-visit-planning-btn'>
              {t('update', 'Update')}
            </Button>
          </Stack>
        }
        title={t('railTrackPlanning', 'Rail track planning')}
      />
      <CardContent>
        <Stack direction='row' spacing={2.5}>
          <Stack>
            <Typography variant='subtitle1'>
              {t('estimatedRailcars', 'Estimated Railcars')}
            </Typography>
            <Typography paragraph component='span' variant='h4' sx={{ cursor: 'pointer' }}>
              {railVisit.data.railcarEstimate ?? '-'}
            </Typography>
          </Stack>
          <Stack>
            <Typography variant='subtitle1'>{t('tracks', 'Tracks')}</Typography>
            <RailTrackNames railTracks={railVisit.railTracks} />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
})
