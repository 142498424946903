import { usePlanningStore } from '@planning/AppProvider'
import { FormType } from '@planning/constants'
import { DialogTitles } from '@planning/stores/utils/FormDialogUtilStore'
import { useTranslate } from '@tolgee/react'
import { ConfirmationDialog } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { DangerousGoodsDefinitionFormProfile } from '../utils/dangerousGoodsDefinitionsTableFormUtils'
import DangerousGoodsDefinitionsTableForm from './DangerousGoodsDefinitionsTableForm'

const DangerousGoodsDefinitionsTableDialog = observer(() => {
  const formId = 'un-numbers-table-form'

  const { t } = useTranslate()
  const {
    dangerousGoodsDefinitionTableViewStore,
    dangerousGoodsDefinitionDataStore,
    appViewStore,
  } = usePlanningStore()
  const formDialogUtilStore = dangerousGoodsDefinitionTableViewStore.formDialogUtilStore

  const dialogTitles: DialogTitles = {
    Add: t('addUNNumber', 'Add New UN Number'),
    Edit: t('updateUNNumber', 'Edit UN Number'),
    Delete: t('deleteUNNumber', 'Delete UN Number?'),
  }

  const getDefaultDialogLabels = (action: FormType) => {
    const saveLabel = t('save', 'Save')
    const cancelLabel = t('cancel', 'Cancel')

    switch (action) {
      case 'Add':
      case 'Edit':
        return {
          title: dialogTitles[action] ?? '',
          buttons: { primary: saveLabel, secondary: cancelLabel },
        }
      case 'Delete':
        return {
          title: dialogTitles[action] ?? '',
          buttons: { primary: t('delete', 'Delete'), secondary: cancelLabel },
        }
    }
  }
  const dialogLabels = getDefaultDialogLabels(formDialogUtilStore.dialogAction)
  // const dialogLabels = formDialogUtilStore.defaultDialogLabels

  const dialogEntity = formDialogUtilStore.dialogEntityId
    ? dangerousGoodsDefinitionDataStore.getById(formDialogUtilStore.dialogEntityId)
    : undefined

  const handleOnClose = () => formDialogUtilStore.toggleDialog()

  const handleOnSubmit = async (formValues: DangerousGoodsDefinitionFormProfile) => {
    try {
      if (dangerousGoodsDefinitionTableViewStore.formDialogUtilStore.dialogAction === 'Add') {
        await dangerousGoodsDefinitionDataStore.add(formValues)
        appViewStore.setShowAlert(
          'success',
          t('unNumberCreationSuccess', 'The UN Number is successfully created'),
        )
      } else {
        await dangerousGoodsDefinitionDataStore.update(formValues)
        appViewStore.setShowAlert(
          'success',
          t('unNumberUpdateSuccess', 'The UN Number is successfully updated'),
        )
      }
    } catch (e) {
      appViewStore.setShowAlert(
        'error',
        t('unNumberOperationFailure', 'An unexpected error occurred'),
      )
    } finally {
      handleOnClose()
    }
  }

  const handleOnDelete = async () => {
    try {
      await dangerousGoodsDefinitionDataStore.delete(
        dangerousGoodsDefinitionTableViewStore.formDialogUtilStore.dialogEntityId!,
      )
      appViewStore.setShowAlert(
        'success',
        t('unNumberDeletionSuccess', 'The UN Number is successfully deleted'),
      )
    } catch (error) {
      appViewStore.setShowAlert(
        'error',
        t('unNumberDeletionFailure', 'An unexpected error occurred while deleting the UN Number'),
      )
    } finally {
      handleOnClose()
    }
  }

  return (
    <ConfirmationDialog
      open={formDialogUtilStore.isDialogOpen}
      title={dialogLabels.title}
      primaryActionText={dialogLabels.buttons.primary}
      closeLabel={dialogLabels.buttons.secondary}
      formId={formId}
      maxWidth='sm'
      onClose={handleOnClose}
      hasBorderTop={formDialogUtilStore.dialogAction !== 'Delete'}
      blockClickOutside={formDialogUtilStore.dialogAction === 'Delete'}
      message={
        formDialogUtilStore.dialogAction === 'Delete'
          ? t(
              'unNumberWillBeDeleted',
              'This UN number will be deleted and you cannot undo this action!',
            )
          : undefined
      }
      onConfirm={formDialogUtilStore.dialogAction === 'Delete' ? handleOnDelete : undefined}
    >
      {formDialogUtilStore.dialogAction !== 'Delete' && (
        <DangerousGoodsDefinitionsTableForm
          id={formId}
          onSubmit={handleOnSubmit}
          dangerousGoodsDefinition={dialogEntity}
        />
      )}
    </ConfirmationDialog>
  )
})

export default DangerousGoodsDefinitionsTableDialog
