import { Theme } from '@mui/material/styles'

// ----------------------------------------------------------------------

export default function ControlLabel(theme: Theme) {
  return {
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          ...theme.typography.body2,
        },
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        component: 'div',
      },
      styleOverrides: {
        root: {
          marginTop: theme.customSpacing.xs,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: theme.palette.text.disabled,
          '&.Mui-focused': {
            color: theme.palette.text.primary,
          },
        },
        asterisk: {
          color: theme.palette.error.main,
          '&$error': {
            color: theme.palette.error.main,
          },
        },
      },
    },
  }
}
