import { createSvgIcon } from '@mui/material'

export const VisitsIcon = createSvgIcon(
  <svg width='20' height='18' viewBox='0 0 20 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.3231 3.31189V7.44204C12.3231 7.79085 12.0377 8.07623 11.6889 8.07623H6.13974V10.1215C6.13974 10.3752 5.98516 10.6051 5.7513 10.7042C5.67203 10.7398 5.58879 10.7557 5.50555 10.7557C5.34304 10.7557 5.18053 10.6962 5.06162 10.5773L0.190257 5.83279C0.0673826 5.71388 0 5.55137 0 5.37696C0 5.20256 0.0673826 5.04005 0.190257 4.92114L5.06162 0.180577C5.24395 0.00221114 5.51745 -0.0493166 5.7513 0.0497754C5.98516 0.148867 6.13974 0.378761 6.13974 0.632437V2.6777H11.6889C12.0377 2.6777 12.3231 2.96308 12.3231 3.31189ZM14.9375 7.38655L19.8089 12.1311C19.9318 12.25 19.9992 12.4085 19.9992 12.5829C19.9992 12.7573 19.9318 12.9198 19.8089 13.0388L14.9375 17.7833C14.8147 17.8982 14.6561 17.9617 14.4936 17.9617C14.4104 17.9617 14.3271 17.9418 14.2479 17.9101C14.014 17.811 13.8594 17.5811 13.8594 17.3275V15.2822H8.31025C7.96145 15.2822 7.67607 14.9968 7.67607 14.648V10.5179C7.67607 10.1691 7.96145 9.88367 8.31025 9.88367H13.8594V7.83841C13.8594 7.58473 14.014 7.35484 14.2479 7.25575C14.4817 7.15666 14.7552 7.20818 14.9375 7.38655Z'
      fill='currentColor'
    />
  </svg>,
  'VisitsIcon',
)
