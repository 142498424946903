import { Tab, Tabs } from '@mui/material'
import { ContainerDamageResponseDto } from '@planning/app/api'
import { NumberBox } from '@planning/components/NumberBox'
import { TabPanel } from '@planning/components/TabPanel'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import { DamageList } from './DamageList'

interface Props {
  activeDamages: ContainerDamageResponseDto[]
  resolvedDamages: ContainerDamageResponseDto[]
  selected: number[]
  listMaxHeight?: string
  onToggleSelectDamage: (damageId: number) => void
  onSelectAllDamages: (isSelectAll: boolean) => void
}

export const ContainerDamageTabs: FC<Props> = observer(
  ({
    activeDamages,
    resolvedDamages,
    selected,
    listMaxHeight,
    onToggleSelectDamage,
    onSelectAllDamages,
  }: Props) => {
    const { t } = useTranslate()
    const [selectedTab, setSelectedTab] = useState<number>(0)

    const a11yProps = (index: number) => {
      return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
      }
    }

    return (
      <>
        <Tabs
          value={selectedTab}
          onChange={(_, newValue) => setSelectedTab(newValue)}
          sx={{ p: '1rem', maxHeight: '65vh' }}
        >
          <Tab
            icon={<NumberBox number={activeDamages.length} />}
            iconPosition='start'
            label={t('active', 'Active')}
            {...a11yProps(0)}
          />
          <Tab
            icon={<NumberBox number={resolvedDamages.length} />}
            iconPosition='start'
            label={t('resolved', 'Resolved')}
            {...a11yProps(1)}
          />
        </Tabs>
        <TabPanel value={selectedTab} index={0}>
          <DamageList
            damages={activeDamages}
            checkedIds={selected}
            handleCheck={onToggleSelectDamage}
            handleSelectAll={onSelectAllDamages}
            listMaxHeight={listMaxHeight}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <DamageList damages={resolvedDamages} listMaxHeight={listMaxHeight} />
        </TabPanel>
      </>
    )
  },
)
