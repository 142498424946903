import { EquipmentOperatorStepperUIStore } from '@operations/features/equipmentOperator/stores/equipment-operator-stepper.ui-store'
import { BackButton, CloseButton } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'

interface Props {
  uiStore: EquipmentOperatorStepperUIStore
  onClose?: () => void
}
export const CloseStepper = observer(({ uiStore, onClose }: Props) => {
  const handleClose = async () => {
    await uiStore.cancelStartJob(uiStore.selectedJob!.workInstructionId)
    if (onClose) {
      onClose()
    }
    uiStore.closeStepper()
  }

  const handleBack = () => {
    if (onClose) {
      onClose()
    }
    uiStore.setHasError()
    uiStore.previousStep()
  }

  return uiStore.activeStep.stepNumber > 1 ? (
    <BackButton onGoBack={handleBack} />
  ) : (
    <CloseButton onClose={handleClose} />
  )
})
