import { OrderListOrderDto, OrderResponseDto } from '@planning/app/api'

export const mapToOrderListOrderDto = (toBeMapped: OrderResponseDto) => {
  return {
    id: toBeMapped.id,
    containerNumber: toBeMapped.containerNumber,
    isoCode: toBeMapped.containerIsoCode,
    referenceNumber: toBeMapped.referenceNumber,
    imoClasses: toBeMapped.imoClasses,
    grossWeight: toBeMapped.grossWeight,
    isEmpty: toBeMapped.isEmpty,
    linkedOrderId: toBeMapped.linkedOrderId,
    operator: toBeMapped.operator,
    portOfDischarge: toBeMapped.portOfDischarge,
    portOfLoading: toBeMapped.portOfLoading,
    temperature: toBeMapped.temperature,
    railTrackId: toBeMapped.railTrackId,
    waggon: toBeMapped.waggon,
  } as OrderListOrderDto
}
