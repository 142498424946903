import { AlertColor } from '@mui/material/Alert'
import { action, makeObservable, observable } from 'mobx'

export class CraneSplitMessageUIStore {
  message?: string
  severity: AlertColor = 'error'

  constructor() {
    makeObservable(this, {
      message: observable,
      severity: observable,
      setMessage: action,
    })
  }

  public setMessage(message?: string, severity: AlertColor = 'error') {
    this.message = message
    this.severity = severity
  }
}
