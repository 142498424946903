export interface CustomerFormProfile {
  id?: number
  name: string
  abbreviation: string
  address?: string | null
}

export const defaultValues: CustomerFormProfile = {
  name: '',
  abbreviation: '',
  address: '',
}
