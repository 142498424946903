import { Box } from '@mui/material'
import { FC } from 'react'
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone'
import { DragAndDropzone } from './DragAndDropzone'
import { DropzoneFilePreview } from './DropzonFilePreview'

export interface IProps {
  file?: File
  rootProps: DropzoneRootProps
  inputProps: DropzoneInputProps
  setFile: (file?: File) => void
}

export const DragAndDropZoneWithFilePreview: FC<IProps> = ({
  file,
  rootProps,
  inputProps,
  setFile,
}) => {
  return file ? (
    <DropzoneFilePreview
      rootProps={rootProps}
      file={{
        name: file.name,
        size: file.size,
      }}
      setFile={setFile}
    />
  ) : (
    <Box height='100%'>
      <DragAndDropzone rootProps={rootProps} inputProps={inputProps} />
    </Box>
  )
}
