import { Point } from '@storage/app/models'
import useHoverKonva from '@storage/hooks/use-hover-konva.hook'
import { useMinimalsTheme } from '@tom-ui/ui'
import Konva from 'konva'
import { useRef } from 'react'
import { Circle, Group, Text } from 'react-konva'

interface Props extends Omit<Konva.TextConfig, 'onMouseLeave' | 'onMouseEnter'> {
  onShowInfo?: (position: Point) => void
}

export const KonvaText = (props: Props) => {
  const { palette } = useMinimalsTheme()
  const hoverHandlers = useHoverKonva()
  const textRef = useRef<Konva.Text>(null)

  const { onShowInfo } = props

  return (
    <Group>
      <Text ref={textRef} {...props} {...(props.onClick ? hoverHandlers : {})} />

      {onShowInfo && textRef.current && (
        <Group
          {...hoverHandlers}
          onClick={({ evt }) => onShowInfo({ x: evt.clientX, y: evt.clientY })}
          x={textRef.current.getWidth() / 2 + textRef.current.getTextWidth()}
        >
          <Circle radius={50} fill={palette.grey[500]} width={14} />
          <Text y={-5} x={-1} text='i' fill='white' />
        </Group>
      )}
    </Group>
  )
}
