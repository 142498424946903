import {
  ContainerStatus,
  StackDto,
  YardBlockStacksApi,
  YardBlockStacksApiGetYardBlockStacksAdvancedRequest,
  YardBlockStacksApiGetYardBlockStacksRequest,
  YardUnit,
} from '@storage/app/api'
import { EntityStore } from '@storage/app/store/entity.store'
import { tolgee } from '@storage/app/translation'
import { YardManagementHubConnection } from '@storage/hubs/yard-management.hub-connection'
import {
  ContainerTurnoverFilterFormMapper,
  ContainerTurnoversFilterFormProfile,
  defaultValues,
} from '@storage/pages/container-turnovers/components/container-turnovers-filter-form'
import isEqual from 'lodash/isEqual'
import { makeObservable, observable, runInAction } from 'mobx'
import { SnackbarStore } from './snackbar.store'
import { YardBlockSummaryStore } from './yard-block-summary.store'

export class YardBlockStackStore extends EntityStore<StackDto> {
  // TODO: This needs to be placed in yard-filter.store.ts
  public stacksMatchingFilter: StackDto[] = []

  constructor(
    private api: YardBlockStacksApi,
    private readonly _snackbarStore: SnackbarStore,
    yardManagementConnection: YardManagementHubConnection,
    public yardBlockSummaryStore: YardBlockSummaryStore,
  ) {
    super()

    makeObservable(this, {
      stacksMatchingFilter: observable,
    })

    yardManagementConnection.onReloadStacks(({ unit, unitId }) => {
      this.load(unit, unitId ?? undefined)
      yardBlockSummaryStore.update(unit === YardUnit.Block ? unitId : undefined)
    })
  }

  public async yardAdvancedFilter(
    filterFormProfile: ContainerTurnoversFilterFormProfile,
  ): Promise<void> {
    const trimmedProfile = ContainerTurnoverFilterFormMapper.trimFormValues(filterFormProfile)
    const containerTurnoverFilterDto = ContainerTurnoverFilterFormMapper.mapFormValuesToFilterDto({
      ...trimmedProfile,
      locationStatus: ContainerStatus.OnTheYard,
    })

    if (isEqual(trimmedProfile, defaultValues)) {
      this.clearAdvancedFilterStacks()
    } else {
      //TODO: Create service for yard block stacks
      const { data: stacks } = await this.api.getYardBlockStacksAdvanced(
        containerTurnoverFilterDto as YardBlockStacksApiGetYardBlockStacksAdvancedRequest,
      )

      if (!stacks.length) {
        if (
          filterFormProfile.containerOrReferenceNumber !== defaultValues.containerOrReferenceNumber
        ) {
          this._snackbarStore.showMessage(
            tolgee.t('containerNNotLocatedInTheYard', {
              n: filterFormProfile.containerOrReferenceNumber,
            }),
            'warning',
          )
        } else {
          this._snackbarStore.showMessage(
            tolgee.t(
              'noOccupiedStacksMatchingCriteria',
              'No occupied stacks found that match the specified filter criteria',
            ),
            'warning',
          )
        }
      }

      runInAction(() => {
        this.stacksMatchingFilter = stacks
      })
    }
  }

  public clearAdvancedFilterStacks() {
    runInAction(() => {
      this.stacksMatchingFilter = []
    })
  }

  public async loadAll(query: YardBlockStacksApiGetYardBlockStacksRequest): Promise<void> {
    const { data: stacks } = await this.api.getYardBlockStacks(query)

    this.setAll(stacks)
  }

  public async loadForBlock(yardBlockId: string): Promise<void> {
    const { data: yardBlockStacks } = await this.api.getYardBlockStacks({ yardBlockId })

    const stacks = this.getForBlock(yardBlockId)
    stacks.forEach(stack => this.remove(stack.id))

    this.addOrUpdateMany(yardBlockStacks)
  }

  public async loadForBay(bayId: string): Promise<void> {
    const { data: stacks } = await this.api.getYardBlockStacks({ yardBlockBayId: bayId })

    this.addOrUpdateMany(stacks)
  }

  public async loadForRow(rowId: string): Promise<void> {
    const { data: stacks } = await this.api.getYardBlockStacks({ yardBlockRowId: rowId })

    this.addOrUpdateMany(stacks)
  }

  public async loadForStack(bayId: string, rowId: string): Promise<void> {
    const { data: stack } = await this.api.getYardBlockStacks({
      yardBlockBayId: bayId,
      yardBlockRowId: rowId,
    })

    this.addOrUpdateMany(stack)
  }

  public async load(unit: YardUnit, unitId?: string) {
    if (unit === YardUnit.Bay && unitId) {
      await this.loadForBay(unitId)
      return
    }

    if (unit === YardUnit.Row && unitId) {
      await this.loadForRow(unitId)
      return
    }

    if (unit === YardUnit.Block && unitId) {
      await this.loadForBlock(unitId)
      return
    }

    if (unit === YardUnit.Stack && unitId) {
      const ids = unitId.split('#')
      if (ids.length > 1) {
        await this.loadForStack(ids[0], ids[1])
      }
    }
    await this.loadAll({})
  }

  public get(id: string) {
    return this.data.get(id)
  }

  public getForBlock(blockId: string) {
    return this.entries.filter(stack => stack.blockId === blockId)
  }

  public getForBay(bayId: string) {
    return this.entries.filter(stack => stack.bayId === bayId)
  }

  public getForRow(rowId: string) {
    return this.entries.filter(stack => stack.rowId === rowId)
  }

  public delete(stackIds: string[]) {
    stackIds.forEach(s => this.remove(s))
  }

  public async toggleStackLock(bayId: string, rowId: string) {
    const stack = this.data.get(bayId + '#' + rowId)
    await this.api.setLockStatus({ bayId: bayId, rowId: rowId, lockStatus: !stack?.isLocked })
    await this.loadForStack(bayId, rowId)
  }
}
