import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import { ButtonOption } from '@tom-ui/ui'
import { useRef, useState } from 'react'

interface Props {
  options: ButtonOption[]
  size?: 'small' | 'medium' | 'large'
  variant?: 'contained' | 'outlined'
  color?: 'primary' | 'secondary' | 'info'
  position?: 'right' | 'left'
  disabled?: boolean
  label: string
}

export const SplitButton = ({
  options,
  label,
  size,
  position,
  color,
  variant,
  disabled,
}: Props) => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)

  const handleMenuItemClick = (option: ButtonOption) => {
    if (option.onClick) option.onClick()
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = (event: Event) => {
    if (anchorRef.current?.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  return (
    <>
      <ButtonGroup ref={anchorRef}>
        <Button
          onClick={handleToggle}
          size={size ?? 'small'}
          variant={variant}
          color={color}
          disabled={disabled}
          sx={{ height: '100%' }}
          endIcon={<ArrowDropDownIcon fontSize={size ?? 'small'} />}
        >
          {label}
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 100,
        }}
        open={open}
        placement={position ?? 'right'}
        anchorEl={anchorRef.current}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem>
                  {options.map(option => (
                    <MenuItem
                      key={option.label.split(' ').join('_')}
                      disabled={option.disabled}
                      divider={option.divider}
                      onClick={() => handleMenuItemClick(option)}
                    >
                      <Typography sx={{ marginLeft: '-4px' }} color={option.color}>
                        {option.label}
                      </Typography>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}
