import { Box } from '@mui/system'
import { CarrierVisitDirection, OrderResponseDto } from '@planning/app/api'
import { GateInViewStore } from '@planning/stores/gateControl/GateInViewStore'
import { useTranslate } from '@tolgee/react'
import { ConfirmationDialog } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

export const IncompleteJobsWarningMessageOrder: FC<{
  order: OrderResponseDto
}> = observer(({ order }) => {
  const { t } = useTranslate()

  const isDropOff = order.direction === CarrierVisitDirection.Inbound

  const isContainer = !!order.containerId

  const containerMessage = isDropOff
    ? t('containerDropOff', 'Container drop off')
    : t('containerPickUp', 'Container pick up')
  const nnOrderMessage = isDropOff
    ? t('nnOrderDropOff', 'NN order drop off ref')
    : t('nnOrderPickUp', 'NN order pick up ref')
  const cargoMessage = isDropOff
    ? t('cargoDropOff', 'Cargo drop off ref')
    : t('cargoPickUp', 'Cargo pick up ref')

  let message = cargoMessage
  let ref = order.referenceNumber

  if (isContainer) {
    message = order.containerNumber ? containerMessage : nnOrderMessage
    ref = order.containerNumber ? order.containerNumber : order.referenceNumber
  }

  return (
    <Box>
      {message}: <b>{ref}</b>
    </Box>
  )
})

export const IncompleteJobsWarningMessageBody: FC<{
  orders: OrderResponseDto[]
}> = observer(({ orders }) => {
  const { t } = useTranslate()

  return (
    <>
      {t(
        'truckVisitHasIncompleteJobsWarning',
        'The following orders have incomplete jobs during operations. Check & confirm the following:',
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '1rem 0',
          gap: '.5rem',
        }}
      >
        {orders.map(order => (
          <IncompleteJobsWarningMessageOrder order={order} key={order.id} />
        ))}
      </Box>
    </>
  )
})

export const ManualGateOutWarningDialog: FC<{
  viewStore: GateInViewStore
}> = observer(({ viewStore }) => {
  const { t } = useTranslate()

  return (
    <ConfirmationDialog
      title='Incomplete jobs for gate out'
      warning={t(
        'confirmGateOutMarkOrdersForInvestigationWarning',
        'Confirming gate out will mark these orders for investigation',
      )}
      warningPosition='bottom'
      open={viewStore.isGateOutWithPendenciesConfirmationDialogOpen}
      onClose={() => viewStore.closeGateOutWithPendenciesConfirmationDialog()}
      onConfirm={() => viewStore.gateOut()}
      closeLabel={t('cancel', 'Cancel')}
      primaryActionText={t('confirm', 'Confirm')}
      maxWidth='xs'
    >
      <IncompleteJobsWarningMessageBody
        orders={viewStore.selectedVisitWithOrders.filter(o => !!o).map(o => o.order!)}
      />
    </ConfirmationDialog>
  )
})
