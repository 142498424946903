import { useStores } from '@storage/hooks/use-stores.hook'
import { ConfirmationDialog } from '@tom-ui/ui'
import { YardSetupStore } from '@storage/pages/yard-setup/stores/yard-setup.store'
import { YardBlockStore } from '@storage/stores/yard-block.store'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { YardElementType } from '../types/yard-setup.types'

interface Props {
  yardSetupStore: YardSetupStore
  yardBlockStore: YardBlockStore
}

export const DeleteYardObjectConfirmationDialog = observer(
  ({ yardSetupStore, yardBlockStore }: Props) => {
    const { t } = useTranslate()
    const { berthStore, snackbarStore } = useStores()

    const confirmationDialogContent = {
      [YardElementType.Berth]: {
        title: t('deleteBerth', 'Delete Berth'),
        message: t('confirmDeleteBerth', 'Are you sure you want to delete this berth area?'),
      },
      [YardElementType.YardBlock]: {
        title: t('deleteYardBlock', 'Delete Yard Block'),
        message: t('confirmDeleteYardBlock', 'Are you sure you want to delete this yard block?'),
      },
      [YardElementType.FlexibleZone]: {
        title: t('deleteFlexibleZone', 'Delete Flexible Zone'),
        message: t(
          'confirmDeleteFlexibleZone',
          'Are you sure you want to delete this flexible zone?',
        ),
      },
    }

    const handleClose = () => {
      yardSetupStore.toggleDeletionConfirmationDialog(yardSetupStore.deletionConfirmationOjectType)
      yardSetupStore.setSelectedBerth()
    }

    const handleDelete = async () => {
      try {
        switch (yardSetupStore.deletionConfirmationOjectType) {
          case YardElementType.Berth:
            if (!yardSetupStore.selectedBerth) {
              handleClose()
              return
            }
            await berthStore.delete(yardSetupStore.selectedBerth.id)
            break
          case YardElementType.YardBlock:
          case YardElementType.FlexibleZone:
            if (!yardSetupStore.yardBlockId) {
              handleClose()
              return
            }
            await yardBlockStore.delete(yardSetupStore.yardBlockId)
            break
          default:
            throw new Error(
              `Unknown delete confirmation object ${yardSetupStore.deletionConfirmationOjectType}`,
            )
        }
      } catch (error) {
        snackbarStore.showMessage(
          t(
            'deleteYardObjectConfirmationDialog',
            'An unexpected error occurred while delete yard object',
          ),
          'error',
        )
      } finally {
        handleClose()
      }
    }

    return (
      <ConfirmationDialog
        title={confirmationDialogContent[yardSetupStore.deletionConfirmationOjectType].title}
        message={confirmationDialogContent[yardSetupStore.deletionConfirmationOjectType].message}
        open={yardSetupStore.showDeletionConfirmationDialog}
        onClose={handleClose}
        primaryActionText={t('delete', 'Delete')}
        closeLabel={t('cancel', 'Cancel')}
        onConfirm={handleDelete}
      />
    )
  },
)
