import { FormProps } from '@operations/app/models'
import { ControlledNumberField } from '@operations/components/form-controls'
import { WorkQueue } from '@operations/features/craneSplit/models/work-queue.model'
import useFormWithSchema from '@operations/hooks/use-form-with-schema.hook'
import { Stack, TextField, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { useEffect, useState } from 'react'
import { SplitFormProfile, defaultValues } from './split-form.profile'
import { schema } from './split-form.scheme'

interface Props extends FormProps {
  workQueue: WorkQueue
  onSubmit: (formValues: SplitFormProfile) => Promise<void> | void
  onCalculateMoves: (
    twinContainers?: boolean,
    newContainersAmount?: number,
  ) => Promise<number> | void
}

export const SplitForm = ({ id, workQueue, onSubmit, onCalculateMoves }: Props) => {
  const { t } = useTranslate()

  const [containersAmount, setContainersAmount] = useState<number>(0)
  const [nextQueueMoves, setNextQueueMoves] = useState<number>(0)

  const { handleSubmit, formState, control, reset, getValues, setValue } =
    useFormWithSchema<SplitFormProfile>(
      schema(1, workQueue ? workQueue.containersLeft - (workQueue.completeness === 0 ? 1 : 0) : 1),
      defaultValues,
    )

  const handleContainerChange = async (value: string | null) => {
    const diff = value ? +value : 0

    setNextQueueMoves(
      diff > workQueue.containersAmount
        ? diff
        : (await onCalculateMoves(getValues('twinContainers'), diff)) ?? 0,
    )

    const currentQueueContainerAmount = workQueue.containersAmount - diff
    setContainersAmount(currentQueueContainerAmount)
    await updateMoves(getValues('twinContainers'), currentQueueContainerAmount)
  }

  const updateMoves = async (twinContainers: boolean, newContainersAmount: number) => {
    setValue('totalMoves', (await onCalculateMoves(twinContainers, newContainersAmount)) ?? 0)
  }

  useEffect(() => {
    if (workQueue) {
      setContainersAmount(workQueue.containersAmount)
      reset({
        totalMoves: workQueue.moves,
        twinContainers: workQueue.isTwinContainers,
      })
    }
  }, [reset, workQueue])

  return (
    <Stack
      id={id}
      component='form'
      noValidate
      autoComplete='off'
      sx={{ gap: theme => theme.customSpacing.l }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Typography variant='subtitle2'>{t('newQueue', 'A new queue')}</Typography>

      <ControlledNumberField<SplitFormProfile>
        name='containersAmount'
        formState={formState}
        control={control}
        label={t('totalCo', 'Total co.')}
        fullWidth
        onChange={handleContainerChange}
      />
      <TextField
        value={nextQueueMoves}
        label={t('totalMoves', 'Total moves')}
        type='number'
        fullWidth
        disabled
      />

      <Typography variant='subtitle2'>{t('currentQueue', 'Current queue')}</Typography>
      <TextField
        value={containersAmount}
        label={t('totalCo', 'Total co.')}
        type='number'
        fullWidth
        disabled
      />
      <ControlledNumberField<SplitFormProfile>
        name='totalMoves'
        formState={formState}
        control={control}
        label={t('totalMoves', 'Total moves')}
        disabled
        fullWidth
      />
    </Stack>
  )
}
