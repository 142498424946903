import { Box, Button, Chip, Grid, TextField, Typography } from '@mui/material'
import { CarrierVisitDirection, CreateHoldCommand, OrderStatus } from '@planning/app/api'
import { LoaderButton } from '@planning/components/LoaderButton'
import { HoldViewStore } from '@planning/stores/hold/HoldViewStore'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'

const defaultFormState: CreateHoldCommand = {
  reason: '',
  contact: '',
  orderId: -1,
}

interface Props {
  store: HoldViewStore
  onSubmit: (cmd: CreateHoldCommand) => Promise<void> | void
  onCancel: () => Promise<void> | void
}

export const CreateHoldForm: FC<Props> = observer(({ store, onSubmit, onCancel }) => {
  const { t } = useTranslate()
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: { ...defaultFormState, orderId: store.orderForSelectedDirection?.id ?? -1 },
  })

  const [isActive, setIsActive] = useState<string>(store.selectedDirection)

  const inbound =
    store.selectedOrder?.direction === CarrierVisitDirection.Inbound
      ? store.selectedOrder
      : store.selectedLinkedOrder

  store.selectDirection(
    inbound?.status === OrderStatus.Fulfilled
      ? CarrierVisitDirection.Outbound
      : CarrierVisitDirection.Inbound,
  )

  const DirectionSelectionChip: FC<{ direction: CarrierVisitDirection }> = observer(
    ({ direction }) => (
      <Chip
        label={
          direction === CarrierVisitDirection.Inbound
            ? t('inbound', 'Inbound')
            : t('outbound', 'Outbound')
        }
        variant={isActive !== direction ? 'outlined' : undefined}
        color='primary'
        sx={{ mr: '.75rem' }}
        onClick={() => {
          setIsActive(direction)
          store.selectDirection(direction)
          setValue('orderId', store.orderForSelectedDirection?.id ?? -1)
        }}
      />
    ),
  )

  return (
    <>
      <Typography variant='h4' sx={{ marginBottom: '20px', marginTop: '20px' }}>
        {t('createHold', 'Create hold')}
      </Typography>
      {store.isBothDirections && (
        <Box display='flex' alignItems='center' mb='1rem'>
          {inbound?.status === OrderStatus.Open && (
            <DirectionSelectionChip direction={CarrierVisitDirection.Inbound} />
          )}
          <DirectionSelectionChip direction={CarrierVisitDirection.Outbound} />
        </Box>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction='column' rowSpacing={{ xs: 1.5 }}>
          <Grid item>
            <TextField
              fullWidth
              {...register(`reason`, { required: true })}
              label={`${t('reason', 'Reason')} *`}
              error={!!errors.reason}
              helperText={errors.reason ? t('fieldIsRequired', 'Field is required.') : ''}
              multiline
              rows={3}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              {...register(`contact`, { required: true })}
              label={`${t('contact', 'Contact')} *`}
              error={!!errors.contact}
              helperText={errors.contact ? t('fieldIsRequired', 'Field is required.') : ''}
            />
          </Grid>
        </Grid>
        <Grid container direction='row' spacing={'12px'} marginTop='1rem'>
          <Grid item>
            <LoaderButton variant='contained' type='submit'>
              {t('create', 'Create')}
            </LoaderButton>
          </Grid>
          <Grid item>
            <Button variant='outlined' type='button' color='error' onClick={onCancel}>
              {t('close', 'Close')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  )
})
