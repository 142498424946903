import { SxProps, Theme } from '@mui/material'
import { OrderResponseDto } from '@planning/app/api'
import { IEnumKeyValue, orderStatus } from '@planning/constants'
import { IPaginatedStoreWithItems } from '@planning/stores/PaginatedStore'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { FilterPaper } from './FilterPaper'
import { StatusRadioGroupFilter } from './StatusRadioGroupFilter'

interface Props {
  sx?: SxProps<Theme>
  store: IPaginatedStoreWithItems<OrderResponseDto>
}
export const DischargeOrderFilter = observer(({ sx, store }: Props) => {
  const [options, setOptions] = useState<IEnumKeyValue[]>(orderStatus)
  const { t } = useTranslate()
  useEffect(() => {
    setOptions(
      store.showCompleted ? orderStatus : orderStatus.filter(s => s.value !== 'Off Terminal'),
    )
  }, [store.showCompleted])

  return (
    <FilterPaper sx={sx}>
      <StatusRadioGroupFilter
        store={store}
        options={options}
        title={t('dischargeOrderStatus', 'Discharge Order Status')}
      />
    </FilterPaper>
  )
})
