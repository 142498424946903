import { Stack } from '@mui/system'
import { CarrierVisitDirection, CarrierVisitSummaryDto } from '@storage/app/api'
import CarrierVisitAllocationRulesContainerV2 from '@storage/features/carrier-visit-allocation-rules/CarrierVisitAllocationRulesContainerV2'
import UnAllocatedContainers from './UnAllocatedContainers'

interface YardPlanningDashboardDetailsSidePanelProps {
  carrierVisitId: number
  carrierVisitDirection: CarrierVisitDirection
  carrierVisitSummary: CarrierVisitSummaryDto
}

const YardPlanningDashboardDetailsSidePanel = ({
  carrierVisitId,
  carrierVisitDirection,
  carrierVisitSummary,
}: YardPlanningDashboardDetailsSidePanelProps) => {
  return (
    <Stack gap={2}>
      <CarrierVisitAllocationRulesContainerV2
        carrierVisitId={carrierVisitId}
        carrierVisitDirection={carrierVisitDirection}
      />
      {carrierVisitSummary && <UnAllocatedContainers carrierVisitSummary={carrierVisitSummary} />}
    </Stack>
  )
}

export default YardPlanningDashboardDetailsSidePanel
