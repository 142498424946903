import { Box, Stack, Tooltip, Typography } from '@mui/material'
import { useOperationsStore } from '@operations/AppProvider'
import { EquipmentType, OperationType } from '@operations/app/api'
import { JobTypes } from '@operations/app/models/operator-pages'
import { useTranslate } from '@tolgee/react'
import { ContainerMobile, IconButton, SearchIcon, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { HeaderWithLogout } from '../components/Header/HeaderWithLogout'
import { WorkInstructionJobList } from '../components/JobList/WorkInstructionJobList'
import { WorkInstructionJobsNavigation } from '../components/JobsNavigation/WorkInstructionJobsNavigation'

export const EquipmentOperatorWorkInstructionListContainer = observer(() => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const {
    equipmentOperatorUIStore,
    equipmentOperatorWorkInstructionsUIStore,
    equipmentOperatorSearchUIStore,
  } = useOperationsStore()

  const handleNavChange = (newValue: JobTypes) => {
    equipmentOperatorWorkInstructionsUIStore.setJobType(newValue)
  }

  const handleOperationType = (value?: OperationType) => {
    equipmentOperatorWorkInstructionsUIStore.setOperationType(value)
  }

  const handleSearch = () => {
    equipmentOperatorSearchUIStore.toggleSearch()
  }

  return (
    <>
      <HeaderWithLogout
        title={t('generalCargoOperator', 'General cargo operator')}
        subtitle={equipmentOperatorUIStore.subtitle}
        action={
          <>
            {equipmentOperatorUIStore.typeOfSelectedEquipment !== EquipmentType.Tt &&
              equipmentOperatorWorkInstructionsUIStore.filteredJobs.length > 0 && (
                <Tooltip title={t('search', 'Search')}>
                  <IconButton onClick={handleSearch}>
                    <SearchIcon />
                  </IconButton>
                </Tooltip>
              )}
          </>
        }
      />
      <ContainerMobile
        sx={{
          paddingY: theme.customSpacing.m,
          backgroundColor: theme.palette.grey[200],
          minHeight: 'calc(100vh - 65px)',
        }}
      >
        <Stack gap={theme.customSpacing.m}>
          <Typography variant='subtitle1'>{t('workAssignments', 'Work assignments')}</Typography>

          {equipmentOperatorUIStore.hasJobNavigation && (
            <WorkInstructionJobsNavigation
              jobs={equipmentOperatorWorkInstructionsUIStore.jobsWithWIIds}
              jobType={equipmentOperatorWorkInstructionsUIStore.jobType}
              operationType={
                equipmentOperatorWorkInstructionsUIStore.equipmentOperatorLandingUIStore
                  .selectedOperationType
              }
              jobNavigationDto={
                equipmentOperatorWorkInstructionsUIStore.carrierTypeFilteredJobsNavigationDto
              }
              selectedEquipmentType={equipmentOperatorUIStore.typeOfSelectedEquipment}
              navChange={handleNavChange}
              operationTypeChange={handleOperationType}
            />
          )}

          <WorkInstructionJobList
            jobGroups={equipmentOperatorWorkInstructionsUIStore.filteredJobs}
          />

          {equipmentOperatorUIStore.selectedEquipmentId &&
            equipmentOperatorWorkInstructionsUIStore.filteredJobs.length === 0 && (
              <Box
                sx={{
                  height: '50vh',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1rem',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography variant='subtitle1' color='secondary'>
                  {t('noWorkAssignmentsToDisplay', 'No work assignments to display at this time')}
                </Typography>
              </Box>
            )}
        </Stack>
      </ContainerMobile>
    </>
  )
})
