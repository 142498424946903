import { TenantDto } from '@admin/app/api'
import { TenantStore } from '@admin/stores/tenant.store'
import { action, computed, makeObservable, observable } from 'mobx'

export class TenantUIStore {
  toBeRemoveTenantId?: number
  searchTenant?: string

  constructor(public store: TenantStore) {
    makeObservable(this, {
      toBeRemoveTenantId: observable,
      searchTenant: observable,

      sortedData: computed,

      setToBeRemoveTenantId: action,
      setSearchTenant: action,
    })
  }

  setSearchTenant(value?: string) {
    if (value !== this.searchTenant) {
      this.searchTenant = value
    }
  }

  get tenants() {
    if (!this.searchTenant) return this.sortedData

    return this.sortedData.filter(x =>
      x.name?.toLowerCase().includes(this.searchTenant!.toLowerCase()),
    )
  }

  get sortedData() {
    return [...this.store.items].sort((a, b) => a.id - b.id)
  }

  isUnique(dto: TenantDto) {
    return (
      this.store.items.filter(e => dto.name.toUpperCase() == e.name.toUpperCase() && dto.id != e.id)
        .length == 0
    )
  }

  public setToBeRemoveTenantId(toBeRemoveTenantId?: number) {
    this.toBeRemoveTenantId = toBeRemoveTenantId
  }
}
