import { action, makeObservable, observable } from 'mobx'
import { ReactNode } from 'react'

export class DialogStore {
  view: ReactNode | undefined = undefined
  open = false

  constructor() {
    makeObservable(this, {
      view: observable,
      open: observable,
      openDialog: action,
      close: action,
    })
  }

  openDialog(content: ReactNode) {
    this.view = content
    this.open = true
  }

  close = () => {
    this.view = undefined
    this.open = false
  }
}
