import { useBillingServiceStore } from '@billing/AppProvider'
import { SummaryEntryDto } from '@billing/app/api'
import exportService from '@billing/services/ExportService'
import NorthEastIcon from '@mui/icons-material/NorthEast'
import SouthEastIcon from '@mui/icons-material/SouthEast'
import { Stack } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { YardIcon } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { ReportSummaryCard } from '../reportSummaryCard/ReportSummaryCard'
import { ReportSummaryTable } from '../reportSummaryTable/ReportSummaryTable'

export const VesselReport = observer(() => {
  const { t } = useTranslate()

  const { vesselReportUIStore } = useBillingServiceStore()

  useEffect(() => {
    async function fetchData() {
      vesselReportUIStore.setVesselSummary(
        await exportService.getVesselSummary(+vesselReportUIStore.selectedVesselVisitId!),
      )
    }

    fetchData()
  }, [vesselReportUIStore])

  const dischargeContainers = vesselReportUIStore.vesselSummary?.discharge ?? []
  const loadContainers = vesselReportUIStore.vesselSummary?.load ?? []

  const fullContainers = (containers: SummaryEntryDto[]) =>
    containers.filter(c => c.isEmpty === false)

  const emptyContainers = (containers: SummaryEntryDto[]) =>
    containers.filter(c => c.isEmpty === true)

  return (
    <Stack width='100%' gap={theme => theme.customSpacing.m}>
      <Stack direction='row' gap={theme => theme.customSpacing.m}>
        <ReportSummaryCard
          title={t('total', 'Total')}
          totalNumber={vesselReportUIStore.vesselSummaryMetrics.total}
          numberDescription='containers'
          icon={<YardIcon />}
        />
        <ReportSummaryCard
          title={t('discharge', 'Discharge')}
          totalNumber={vesselReportUIStore.vesselSummaryMetrics.discharge}
          numberDescription='containers'
          icon={<SouthEastIcon />}
        />
        <ReportSummaryCard
          title={t('load', 'Load')}
          totalNumber={vesselReportUIStore.vesselSummaryMetrics.load}
          numberDescription='containers'
          icon={<NorthEastIcon />}
        />
      </Stack>

      {dischargeContainers.length > 0 && (
        <ReportSummaryTable
          title={t('discharge', 'Discharge')}
          subtitle=''
          sections={[
            {
              nameColumn: t('full', 'Full'),
              valueColumn: t('containers', 'Containers'),
              entries: fullContainers(dischargeContainers),
            },
            {
              nameColumn: t('empty', 'Empty'),
              valueColumn: t('containers', 'Containers'),
              entries: emptyContainers(dischargeContainers),
            },
          ]}
        />
      )}

      {loadContainers.length > 0 && (
        <ReportSummaryTable
          title={t('load', 'Load')}
          subtitle=''
          sections={[
            {
              nameColumn: t('full', 'Full'),
              valueColumn: t('containers', 'Containers'),
              entries: fullContainers(loadContainers),
            },
            {
              nameColumn: t('empty', 'Empty'),
              valueColumn: t('containers', 'Containers'),
              entries: emptyContainers(loadContainers),
            },
          ]}
        />
      )}
    </Stack>
  )
})
