import { Box, Typography } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'

interface SubtitleProps {
  subtitle: string
  bodyText: string
}

export const Subtitle = ({ subtitle, bodyText }: SubtitleProps) => {
  const { palette } = useMinimalsTheme()
  return (
    <Box>
      <Box>
        <Typography variant='subtitle1' sx={{ mt: '1rem', mb: '0.5rem' }}>
          {subtitle}
        </Typography>
        <Typography variant='body1' color={palette.text.secondary}>
          {bodyText}
        </Typography>
      </Box>
    </Box>
  )
}
