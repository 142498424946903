import { Box, Button, Card, CardContent, Typography } from '@mui/material'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { SearchContainerAutocomplete } from './SearchContainerAutoComplete'
import { ContainerShiftingsForm } from './form/ContainerShiftingsForm'

interface Props {
  containerNumber: string
  onPositionUpdate?: (id: string) => void
}

export const ContainerShiftings = observer(({ containerNumber, onPositionUpdate }: Props) => {
  const { t } = useTranslate()
  const { containerShiftingsStore, containerAutoCompleteStore, containerShiftingsFormStore } =
    useStores()

  useEffect(() => {
    const loadContainersToBeShifted = async () => {
      await containerShiftingsStore.fetchContainersToBeShifted(containerNumber)
    }
    loadContainersToBeShifted()
  }, [containerNumber, containerShiftingsStore])

  const handleOnAddShiftClick = () => {
    containerShiftingsFormStore.addNewFormItem()
    containerAutoCompleteStore.setValue(null)
    containerAutoCompleteStore.setFilter('')
  }

  return (
    <Box>
      <Card
        sx={{
          backgroundColor:
            containerShiftingsStore.numberOfContainersToBeShifted > 0 ? 'warning.main' : '#e0e0e0',
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'left',
            gap: 3,
          }}
        >
          <Typography variant='body1'>
            {containerShiftingsStore.numberOfContainersToBeShifted > 0
              ? t(
                  'containersToBeShifted',
                  'Container shiftings have been detected as necessary for this job',
                )
              : t('recordShifts', 'Record any shifts made to collect this container')}
          </Typography>
          <Box sx={{ flex: 1 }}>
            <SearchContainerAutocomplete store={containerAutoCompleteStore} />
          </Box>
          <Button
            variant='contained'
            disabled={!containerAutoCompleteStore.value?.containerNumber}
            onClick={handleOnAddShiftClick}
          >
            {t('addShift', 'Add Shift')}
          </Button>
        </CardContent>
      </Card>
      {containerShiftingsFormStore.formItems.map(item => (
        <ContainerShiftingsForm
          key={item.id}
          item={item}
          autoCompleteStore={containerAutoCompleteStore}
          formStore={containerShiftingsFormStore}
          shiftingsStore={containerShiftingsStore}
          onPositionUpdate={onPositionUpdate}
        />
      ))}
    </Box>
  )
})
