import { TEntity } from '@operations/app/models'
import { makeObservable, observable, runInAction } from 'mobx'

export class BaseEntityStore<T extends TEntity> {
  items: T[] = []

  constructor() {
    makeObservable(this, {
      items: observable,
    })
  }

  updateStoreItems(items: T[]) {
    runInAction(() => {
      this.items = items ?? []
    })
  }

  updateStoreItem(item: T, id: number) {
    const existingItems = this.items.find(i => i.id === id)
    runInAction(() => {
      if (existingItems) this.items = this.items.map(i => (i.id === id ? item : i))
      else this.items.push(item)
    })
  }

  deleteStoreItem(id: number) {
    runInAction(() => {
      this.items = this.items.filter(i => i.id !== id)
    })
  }

  deleteStoreItems(ids?: number[]) {
    if (!ids) return
    runInAction(() => {
      this.items = this.items.filter(i => !ids.includes(+i.id))
    })
  }
}
