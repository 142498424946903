import {
  AssignRailcarTrackPositionCommand,
  CarrierVisitDirection,
  OrderResponseDto,
  UpdateRailcarSequenceCommand,
} from '@planning/app/api'
import { RailVisitItemStore } from '@planning/rt-stores/railVisit/RailVisitItemStore'
import { railVisitService } from '@planning/services'
import { Entities, Group, GroupMap, Id, Item, ItemMap, SequenceMap } from '@tom-ui/ui'
import { action, computed, makeObservable, observable } from 'mobx'
import { MultiDragAwareReorderProps } from 'modules/ui/src/theme/components/grouping/utils/utils'
import { ISortDelegate, LocalDataStoreWrapper, PaginatedLocalStore } from '../PaginatedLocalStore'
import { IRailcarSequencingEntities, IRailcarSequencingStore } from './IRailcarSequencingStore'

export interface RailcarTrackPosition {
  railCarId: number
  railCar: string
  checkedIn: boolean
  checkedOut: boolean
  orders: OrderResponseDto[]
}

const orderSortingDelegate: ISortDelegate<OrderResponseDto> = (_, a, b) => {
  return (a.containerNumber ?? '').localeCompare(b.containerNumber ?? '')
}

// [Test] TODO: UT the stores pls!
export class RailcarSequencingStore
  extends PaginatedLocalStore<OrderResponseDto>
  implements IRailcarSequencingStore
{
  private railVisitItemId: number
  private railVisitItemStore?: RailVisitItemStore
  isLoading = false

  constructor(
    railVisitItemId: number,
    railVisitItemStore: RailVisitItemStore,
    getFunc: () => OrderResponseDto[],
    fetchFunc?: (query: any) => Promise<void>,
  ) {
    super(new LocalDataStoreWrapper<OrderResponseDto>(getFunc, fetchFunc), orderSortingDelegate)

    makeObservable(this, {
      entities: computed,
      railVisitItem: computed,
      isLoading: observable,
      setIsLoading: action,
    })

    // [OCTA-806] TODO: refactor
    this.setPageSize(10000)

    this.railVisitItemId = railVisitItemId
    this.railVisitItemStore = railVisitItemStore
  }

  get railVisitItem() {
    return this.railVisitItemStore?.elements[this.railVisitItemId]
  }

  get entities(): IRailcarSequencingEntities {
    return {
      load: this.getEntitiesOfDirection(CarrierVisitDirection.Outbound),
      discharge: this.getEntitiesOfDirection(CarrierVisitDirection.Inbound),
    }
  }

  getEntitiesOfDirection = (direction: CarrierVisitDirection): Entities => {
    const groups = this.getGroups(direction)
    const groupOrder = Object.keys(groups)
    const entityItems = this.getEntityItems(direction)

    const map = {
      groupOrder: groupOrder,
      items: entityItems,
      groups: groups,
    }

    return map
  }

  getGroups = (direction: CarrierVisitDirection) => {
    if (!this.railVisitItem?.railTracks) {
      return {}
    }

    const railcarTrackPositions = this.railVisitItem?.railcarTrackPositions.filter(
      x => x.data.direction === direction,
    )

    const groups = this.railVisitItem.railTracks.reduce<GroupMap>((map, railTrack) => {
      const currentGroup: Group = {
        id: railTrack.id,
        name: railTrack.name,
        ItemIds: [],
        sequence: {},
        sequenced: true,
        note: '',
      }

      const railTrackPositions = railcarTrackPositions?.filter(
        x => x.railTrack?.id === railTrack.id,
      )

      if (railTrackPositions.length > 0) {
        const itemsSequenceMap = railTrackPositions.reduce<SequenceMap>(
          (sequenceMap, railcarTrackPosition) => {
            sequenceMap[railcarTrackPosition.id] =
              railcarTrackPosition.data.railcarSequenceNumber - 1

            return sequenceMap
          },
          {},
        )

        currentGroup.sequence = itemsSequenceMap
        currentGroup.ItemIds = Object.keys(itemsSequenceMap)
      }

      map[railTrack.id] = currentGroup
      return map
    }, {})

    Object.values(groups).forEach(group => {
      group.ItemIds = group.ItemIds.sort((a, b) => this.sortSequence(group, a, b))
    })

    return groups
  }

  sortSequence = (group: Group, a: string, b: string) => group.sequence[a] - group.sequence[b]

  getEntityItems = (direction: CarrierVisitDirection) => {
    const railcarTrackPositions = this.railVisitItem?.railcarTrackPositions.filter(
      x => x.data.direction === direction,
    )

    if (!railcarTrackPositions || railcarTrackPositions.length === 0) {
      return {}
    }

    const entityItems = railcarTrackPositions.reduce<ItemMap>((map, railcarTrackPosition) => {
      const content: RailcarTrackPosition = {
        railCarId: railcarTrackPosition.data.railcarId,
        railCar: railcarTrackPosition.data.railcarName,
        checkedIn: !!railcarTrackPosition.data.checkinDate,
        checkedOut: !!railcarTrackPosition.data.checkoutDate,
        orders: this.getOrdersFromRailcarTrackPositionInDirection(
          railcarTrackPosition.id,
          direction,
        ),
      }

      const currentItem: Item = {
        id: `${railcarTrackPosition.id}`,
        content,
      }

      map[railcarTrackPosition.id] = currentItem
      return map
    }, {})

    return entityItems
  }

  getOrdersFromRailcarTrackPositionInDirection = (
    railcarTrackPositionId: number,
    direction: CarrierVisitDirection,
  ) =>
    this.getOrdersFromRailcarTrackPosition(railcarTrackPositionId).filter(
      order => order.direction === direction,
    )

  getOrdersFromRailcarTrackPosition = (railcarTrackPositionId: number) =>
    this.pageItems.filter(order => order.railcarTrackPositionId === railcarTrackPositionId)

  onGroupChange = async (
    direction: CarrierVisitDirection,
    sourceGroup: Group,
    destinationGroup: Group,
    railcars: Item[],
    destinationItemSequence?: number,
  ) => {
    const railcarIds = railcars.map(r => parseInt(r.id))
    const destinationSequence = (destinationItemSequence ?? 0) + 1
    const destinationGroupId = destinationGroup.id

    try {
      if (sourceGroup.id === destinationGroupId) {
        await this.saveChanges(direction, destinationGroupId, railcarIds, destinationSequence)
      } else {
        await this.assignRailcarTrackPosition(railcars, destinationGroupId)
      }
    } catch (err) {
      return false
    }

    return true
  }

  saveChanges = async (
    direction: CarrierVisitDirection,
    railTrackId: string,
    railcarTrackPositionIds: number[],
    railcarSequenceNumber: number,
  ) => {
    const cmd = this.mapToUpdateRailcarSequenceCommand(
      direction,
      railTrackId,
      railcarTrackPositionIds,
      railcarSequenceNumber,
    )
    if (cmd) await railVisitService.updateRailcarSequence(cmd)
  }

  assignRailcarTrackPosition = async (railcars: Item[], newTrackId: Id) => {
    const cmd = this.mapToAssignRailcarTrackPositionCommand(railcars, newTrackId)
    await railVisitService.assignRailcarTrackPosition(cmd)
  }

  mapToUpdateRailcarSequenceCommand = (
    direction: CarrierVisitDirection,
    railTrackId: string,
    railcarTrackPositionIds: number[],
    railcarSequenceNumber: number,
  ) => {
    if (!this.railVisitItem) return

    const railVisitId = this.railVisitItem.id
    const cmd: UpdateRailcarSequenceCommand = {
      railTrackId,
      railcarTrackPositionIds,
      railVisitId,
      railcarSequenceNumber,
      direction,
    }

    return cmd
  }

  mapToAssignRailcarTrackPositionCommand = (railcars: Item[], newTrackId: Id) => {
    const ids = railcars.map(railcar => Number(railcar.id))
    const cmd: AssignRailcarTrackPositionCommand = {
      railVisitId: this.railVisitItemId,
      ids,
      newTrackId,
    }

    return cmd
  }

  willUpdateCheckedInRailcar = (args: MultiDragAwareReorderProps) => {
    if (!args.filteredItems) return true

    let willUpdateCheckedInRailcar = false
    const itemsArray = args.filteredItems

    if (args.selectedItemIds?.length > 0) {
      args.selectedItemIds.forEach((selectedItemId: string) => {
        const selectedRailcar = itemsArray.find(item => item.id === selectedItemId)?.content
        const selectedRailcarIndex = itemsArray.findIndex(item => item.id === selectedItemId)

        if (
          this.isSelectedRailcarCheckedInOrWillSelectedRailcarAffectAnyCheckedInRailcar(
            selectedRailcar,
            itemsArray,
            selectedRailcarIndex,
            args.destinationItemIndex,
          )
        ) {
          willUpdateCheckedInRailcar = true
        }
      })
    } else {
      const sourceItem = itemsArray[args.sourceItemIndex]?.content
      if (
        this.isSelectedRailcarCheckedInOrWillSelectedRailcarAffectAnyCheckedInRailcar(
          sourceItem,
          itemsArray,
          args.sourceItemIndex,
          args.destinationItemIndex,
        )
      ) {
        willUpdateCheckedInRailcar = true
      }
    }

    return willUpdateCheckedInRailcar
  }

  isSelectedRailcarCheckedInOrWillSelectedRailcarAffectAnyCheckedInRailcar = (
    railcar: any,
    railcarList: Item[],
    selectedRailcarIndex: number,
    destinationRailcarIndex: number,
  ) =>
    railcar?.checkedIn ||
    this.willSelectedRailcarAffectAnyCheckedInRailcar(
      railcarList,
      selectedRailcarIndex,
      destinationRailcarIndex,
    )

  willSelectedRailcarAffectAnyCheckedInRailcar = (
    railcarList: Item[],
    selectedRailcarIndex: number,
    destinationRailcarIndex: number,
  ): boolean => {
    let willAffect = false
    railcarList.forEach((railcar, railcarIndex) => {
      if (
        this.willSelectedItemAffectCheckedInRailcar(
          railcarIndex,
          railcar,
          selectedRailcarIndex,
          destinationRailcarIndex,
        )
      ) {
        willAffect = true
      }
    })

    return willAffect
  }

  willSelectedItemAffectCheckedInRailcar = (
    comparedRailcarIndex: number,
    comparedRailcar: Item,
    selectedRailcarIndex: number,
    destinationRailcarIndex: number,
  ) => {
    const isSelectedRailcarAfterCheckedInRailcarAndBeingMovedBeforeIt =
      comparedRailcarIndex < selectedRailcarIndex &&
      destinationRailcarIndex - 1 < comparedRailcarIndex &&
      comparedRailcar.content.checkedIn

    const isSelectedRailcarBeforeCheckedInRailcarAndBeingMovedAfterIt =
      comparedRailcarIndex > selectedRailcarIndex &&
      destinationRailcarIndex + 1 > comparedRailcarIndex &&
      comparedRailcar.content.checkedIn

    return (
      isSelectedRailcarAfterCheckedInRailcarAndBeingMovedBeforeIt ||
      isSelectedRailcarBeforeCheckedInRailcarAndBeingMovedAfterIt
    )
  }

  deleteRailcar = async (railcarTrackId: Id[]) => {
    try {
      return await railVisitService.deleteRailCar({
        railVisitId: this.railVisitItemId,
        ids: railcarTrackId.map(id => Number(id)),
      })
    } catch (error) {
      console.log(error)
      return false
    }
  }

  setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading)

  reset() {
    this.setFilter('')
  }
}
