import { GCStorageReportMode } from '@billing/app/models/gc-storage-report-mode.enum'
import { ControlledCheckbox } from '@billing/components/form-controls'
import { Box, Button, FormGroup } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { GCStoragePreBillingUIStore } from '../../stores/gcStorage-prebilling-report.ui-store'
import { GCOrdersCard } from './GCOrdersCard'
import { GCStockCard } from './GCStockCard'

interface Props {
  store: GCStoragePreBillingUIStore
}

export const StoragePreBillingReportForm = observer(({ store }: Props) => {
  const { t } = useTranslate()

  const { control, formState, reset, setValue, watch } = useForm<FieldValues>({
    defaultValues: {
      OrderId: store.inboundOrders.reduce(
        (acc, order) => {
          acc[order.externalOrderId] = false
          return acc
        },
        {} as Record<number, boolean>,
      ),
      StockId: store.stockInventories.reduce(
        (acc, stock) => {
          acc[stock.stockId] = false
          return acc
        },
        {} as Record<number, boolean>,
      ),
    },
  })
  const [initialized, setInitialized] = useState(false)

  const formValues = watch()

  useEffect(() => {
    const loadInboundOrders = async () => {
      await store.loadData()
    }

    loadInboundOrders()
  }, [store])

  useEffect(() => {
    const computeFormValues = () => {
      if (store.reportMode === GCStorageReportMode.InboundOrder) {
        if (store.inboundOrders.length > 0) {
          const defaultValues = store.inboundOrders.reduce(
            (acc, order) => {
              acc[order.externalOrderId] = false
              return acc
            },
            {} as Record<string, boolean>,
          )
          reset({ OrderId: defaultValues })
          setInitialized(true)
        }
      } else {
        if (store.stockInventories.length > 0) {
          const defaultValues = store.stockInventories.reduce(
            (acc, stock) => {
              acc[stock.stockId] = false
              return acc
            },
            {} as Record<string, boolean>,
          )
          reset({ StockId: defaultValues })
          setInitialized(true)
        }
      }
    }
    computeFormValues()
  }, [reset, store.inboundOrders, store.reportMode, store.stockInventories])

  useEffect(() => {
    const setIds = () => {
      if (store.reportMode === GCStorageReportMode.InboundOrder) {
        const selectedOrderIds = Object.entries(formValues.OrderId)
          .filter(([, value]) => value === true)
          .map(([key]) => Number(key))

        store.setIdsToSubmit(selectedOrderIds)
      } else {
        const selectedStockIds = Object.entries(formValues.StockId)
          .filter(([, value]) => value === true)
          .map(([key]) => Number(key))

        store.setIdsToSubmit(selectedStockIds)
      }
    }
    setIds()
  }, [formValues, store])

  const handleSelectAll = () => {
    if (store.reportMode === GCStorageReportMode.InboundOrder) {
      store.inboundOrders.forEach(order => {
        setValue(`OrderId.${order.externalOrderId}`, true)
      })
    } else {
      store.stockInventories.forEach(inventory => {
        setValue(`StockId.${inventory.stockId}`, true)
      })
    }
  }

  const handleUnselectAll = () => {
    if (store.reportMode === GCStorageReportMode.InboundOrder) {
      store.inboundOrders.forEach(order => {
        setValue(`OrderId.${order.externalOrderId}`, false)
      })
    } else {
      store.stockInventories.forEach(inventory => {
        setValue(`StockId.${inventory.stockId}`, false)
      })
    }
  }

  if (!initialized) {
    return <p>Loading...</p>
  }

  return (
    <Box>
      {(store.inboundOrders.length > 1 || store.stockInventories.length > 1) && (
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            mb: 1,
            ml: 4,
          }}
        >
          <Button size='small' variant='outlined' onClick={handleSelectAll}>
            {t('selectAll', 'Select all')}
          </Button>
          <Button size='small' variant='outlined' onClick={handleUnselectAll}>
            {t('unselectAll', 'Unselect all')}
          </Button>
        </Box>
      )}
      <Box
        component='form'
        noValidate
        autoComplete='off'
        sx={{ display: 'flex', flexDirection: 'column', maxHeight: 500, overflow: 'auto' }}
      >
        <FormGroup>
          {store.reportMode === GCStorageReportMode.InboundOrder
            ? store.inboundOrders.map(inboundOrder => (
                <ControlledCheckbox
                  key={inboundOrder.externalOrderId}
                  control={control}
                  formState={formState}
                  name={`OrderId.${inboundOrder.externalOrderId}`}
                  label={<GCOrdersCard order={inboundOrder} />}
                />
              ))
            : store.stockInventories.map(inventory => (
                <ControlledCheckbox
                  key={inventory.stockId}
                  control={control}
                  formState={formState}
                  name={`StockId.${inventory.stockId}`}
                  label={<GCStockCard stock={inventory} />}
                />
              ))}
        </FormGroup>
      </Box>
    </Box>
  )
})
