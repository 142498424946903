import ViewModuleIcon from '@mui/icons-material/ViewModule' // TODO: use from minimal theme
import { Typography } from '@mui/material'
import { IconColor } from '@planning/components/icon/IconColor'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

export const ContainerLocation: FC<{ location?: string; color?: IconColor }> = observer(
  ({ location, color }) => {
    const { t } = useTranslate()

    return (
      <>
        <ViewModuleIcon color={color} />
        <Typography variant='caption' color={color}>
          {location ?? t('storage', 'Storage')}
        </Typography>
      </>
    )
  },
)
