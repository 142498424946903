import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { BreakTimeResponseDto } from '@planning/app/api'
import { OrderItemChip } from '@planning/pages/Order/components/OrderItemChip'
import { useTranslate } from '@tolgee/react'
import { BinIcon } from '@tom-ui/ui'
import dayjs, { Dayjs } from 'dayjs'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

export interface ICreateVisitBreakFormData {
  breakFrom: Dayjs
  breakTo: Dayjs
  description: string
}

interface IProps {
  open: boolean
  onClose: () => void
  onDelete: (id: number) => void
  onSubmit: (data: ICreateVisitBreakFormData, id?: number) => Promise<void> | void
  editMode?: boolean
  initialFormData: BreakTimeResponseDto | null
}

export const VisitBreakDialog = observer(
  ({ onSubmit, onClose, onDelete, open, initialFormData, editMode }: IProps) => {
    const { t } = useTranslate()
    const [breakFrom, setBreakFrom] = useState<Dayjs>(dayjs())
    const [breakTo, setBreakTo] = useState<Dayjs>(dayjs())
    const now = dayjs()
    const {
      register,
      control,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm<ICreateVisitBreakFormData>()

    const resetData = () => {
      reset({ breakFrom: dayjs(), breakTo: dayjs().add(1, 'hour'), description: '' })
      setBreakFrom(dayjs())
      setBreakTo(dayjs())
    }

    useEffect(() => {
      if (editMode) {
        reset({
          breakFrom: dayjs(initialFormData?.from),
          breakTo: dayjs(initialFormData?.to),
          description: initialFormData?.description,
        })

        setBreakFrom(dayjs(initialFormData?.from))
        setBreakTo(dayjs(initialFormData?.to))
      } else {
        resetData()
      }
    }, [initialFormData, reset])

    const calcTimeDiff = (startTimeStr: Dayjs, endTimeStr: Dayjs): string | null => {
      const startTime = dayjs(startTimeStr)
      const endTime = dayjs(endTimeStr)

      const diffMinutes = endTime.diff(startTime, 'minute')

      let hours = Math.floor(diffMinutes / 60)
      let minutes = diffMinutes % 60

      hours = Math.max(hours, 0)
      minutes = Math.max(minutes, 0)

      if (hours === 0 && minutes === 0) {
        return null
      } else if (hours === 0 && minutes !== 0) {
        return `${minutes} minutes`
      } else if (hours !== 0 && minutes === 0) {
        return `${hours} hours`
      } else {
        return `${hours} hours, ${minutes} minutes`
      }
    }

    const title = editMode ? t('editBreak', 'Edit break') : t('addBreak', 'Add break')

    return (
      <Dialog open={open}>
        <form
          onSubmit={handleSubmit(async (data: ICreateVisitBreakFormData) => {
            const editId = !editMode ? initialFormData?.id : undefined
            await onSubmit(data, editId)
            resetData()
          })}
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogContent
            sx={{
              width: '560px',
            }}
          >
            <Grid container spacing={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name={`breakFrom`}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <DateTimePicker
                        label={t('from', 'From')}
                        value={value}
                        onChange={(newValue: any) => {
                          onChange(newValue)
                          setBreakFrom(newValue)
                        }}
                        disablePast
                        format='DD/MM/YYYY hh:mm A'
                        slotProps={{
                          textField: {
                            variant: 'outlined',
                            helperText: errors.breakFrom
                              ? t('fieldIsRequired', 'Field is required.')
                              : '',
                            error: !!errors.breakFrom,
                            sx: { width: '100%', marginTop: '10px' },
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name={`breakTo`}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <DateTimePicker
                        label={t('to', 'To')}
                        value={value}
                        onChange={(newValue: any) => {
                          onChange(newValue)
                          setBreakTo(newValue)
                        }}
                        disablePast
                        format='DD/MM/YYYY hh:mm A'
                        slotProps={{
                          textField: {
                            variant: 'outlined',
                            helperText: errors.breakTo
                              ? t('fieldIsRequired', 'Field is required.')
                              : '',
                            error: !!errors.breakTo,
                            sx: { width: '100%', marginTop: '10px' },
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
              </LocalizationProvider>

              {calcTimeDiff(breakFrom, breakTo) && (
                <Grid item xs={12}>
                  <Typography
                    variant='subtitle2'
                    mb={'.5rem'}
                  >{`${t('total', 'Total')}`}</Typography>
                  <OrderItemChip label={calcTimeDiff(breakFrom, breakTo)} />
                </Grid>
              )}

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  defaultValue={initialFormData?.description}
                  label={`${t('description', 'Description')}*`}
                  variant='outlined'
                  {...register('description', { required: true })}
                  name='description'
                  error={!!errors.description}
                  multiline
                  rows={2}
                  helperText={errors.description ? t('fieldIsRequired', 'Field is required.') : ''}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Button variant='contained' type='submit'>
                {t('save', 'Save')}
              </Button>
              <Button
                variant='text'
                color='secondary'
                onClick={() => {
                  onClose()
                  reset()
                }}
              >
                {t('cancel', 'Cancel')}
              </Button>
            </Box>

            {editMode && (
              <IconButton onClick={() => onDelete(initialFormData!.id)}>
                <BinIcon />
              </IconButton>
            )}
          </DialogActions>
        </form>
      </Dialog>
    )
  },
)
