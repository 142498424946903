import {
  AllocationsApi,
  AllocationsApiDangerousGoodsValidationRequest,
  AllocationsCreateInternalMoveManualRequest,
  AllocationsCreateManualRequest,
  AllocationsPrePlanningValidationRequest,
  YardBlockSlotOccupanciesApi,
  YardBlockSlotOccupanciesCreateReservationRequest,
  YardPositionDto,
} from '@storage/app/api'
import { createApiClient } from '@storage/app/http-client'

export class ContainerPlanningService {
  public async createReservation(
    yardBlockSlotOccupanciesCreateReservationRequest: YardBlockSlotOccupanciesCreateReservationRequest,
  ): Promise<void> {
    await createApiClient(YardBlockSlotOccupanciesApi).createReservation({
      yardBlockSlotOccupanciesCreateReservationRequest,
    })
  }

  public async createAllocationForSingleTurnover(
    allocationsCreateManualRequest: AllocationsCreateManualRequest,
  ): Promise<void> {
    await createApiClient(AllocationsApi).createManualForSingleTurnover({
      allocationsCreateManualRequest,
    })
  }

  public async createAllocation(allocationsCreateManualRequest: AllocationsCreateManualRequest) {
    await createApiClient(AllocationsApi).createManual({
      allocationsCreateManualRequest,
    })
  }

  public async prePlanningValidation(
    allocationsPrePlanningValidationRequest: AllocationsPrePlanningValidationRequest,
  ) {
    await createApiClient(AllocationsApi).prePlanningValidation({
      allocationsPrePlanningValidationRequest,
    })
  }

  public async dangerousGoodsPrePlanningValidation(
    allocationsDangerousGoodsValidationRequest: AllocationsApiDangerousGoodsValidationRequest,
  ) {
    await createApiClient(AllocationsApi).dangerousGoodsValidation(
      allocationsDangerousGoodsValidationRequest,
    )
  }
  public async updateContainerPosition(containerTurnoverId: string, location: YardPositionDto) {
    await createApiClient(YardBlockSlotOccupanciesApi).updateContainerPosition({
      yardBlockSlotOccupanciesUpdateContainerPositionRequest: {
        containerTurnoverId,
        location,
      },
    })
  }

  public async createInternalMoveManual(
    allocationsCreateInternalMoveManualRequest: AllocationsCreateInternalMoveManualRequest,
  ) {
    const { data } = await createApiClient(AllocationsApi).createInternalMoveManual({
      allocationsCreateInternalMoveManualRequest,
    })

    return data
  }
}
