import { FC } from 'react'

export const IconDryContainer: FC<{ zoom?: number; size?: number }> = ({ zoom, size }) => {
  return (
    <svg
      width={size ?? '24'}
      height={size ?? '24'}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ transform: `scale(${(zoom ?? 100) / 100})` }}
    >
      <rect
        y='24'
        width='24'
        height='24'
        rx='4'
        transform='rotate(-90 0 24)'
        fill='#919EAB'
        fillOpacity='0.24'
      />
      <rect
        x='0.25'
        y='23.75'
        width='23.5'
        height='23.5'
        rx='3.75'
        transform='rotate(-90 0.25 23.75)'
        stroke='#919EAB'
        strokeOpacity='0.24'
        strokeWidth='0.5'
      />
      <path
        d='M3.66415 6.878C3.69682 6.878 3.79482 6.88033 3.95815 6.885C4.12615 6.885 4.32915 6.885 4.56715 6.885C4.80515 6.88033 5.05248 6.88033 5.30915 6.885C5.56582 6.885 5.80148 6.88733 6.01615 6.892C7.25748 6.91067 8.26315 7.11367 9.03315 7.501C9.80782 7.88367 10.3748 8.44133 10.7341 9.174C11.0981 9.90667 11.2801 10.8073 11.2801 11.876C11.2801 12.996 11.0841 13.9363 10.6921 14.697C10.3048 15.4577 9.71215 16.0317 8.91415 16.419C8.12082 16.8063 7.11048 17 5.88315 17H3.66415V6.878ZM4.99415 7.991V15.887H5.88315C6.71848 15.887 7.43715 15.7587 8.03915 15.502C8.64115 15.2407 9.10315 14.8183 9.42515 14.235C9.75182 13.6517 9.91515 12.8723 9.91515 11.897C9.91515 10.9217 9.75648 10.1493 9.43915 9.58C9.12648 9.01067 8.67382 8.60467 8.08115 8.362C7.48848 8.11467 6.77448 7.991 5.93915 7.991H4.99415ZM17.0952 6.738C17.8092 6.738 18.4415 6.88733 18.9922 7.186C19.5428 7.48 19.9862 7.87433 20.3222 8.369C20.6582 8.86367 20.8565 9.40733 20.9172 10H19.5382C19.4635 9.60333 19.3212 9.244 19.1112 8.922C18.9012 8.6 18.6258 8.34333 18.2852 8.152C17.9445 7.96067 17.5362 7.865 17.0602 7.865C16.4488 7.865 15.9308 8.00967 15.5062 8.299C15.0862 8.58367 14.7665 9.02467 14.5472 9.622C14.3325 10.2193 14.2252 10.9847 14.2252 11.918C14.2252 13.3273 14.4655 14.3633 14.9462 15.026C15.4315 15.684 16.1362 16.013 17.0602 16.013C17.5362 16.013 17.9445 15.9127 18.2852 15.712C18.6258 15.5113 18.9012 15.243 19.1112 14.907C19.3212 14.571 19.4635 14.207 19.5382 13.815H20.9172C20.8658 14.2443 20.7492 14.6597 20.5672 15.061C20.3898 15.4577 20.1448 15.8123 19.8322 16.125C19.5195 16.4377 19.1345 16.685 18.6772 16.867C18.2245 17.049 17.6972 17.14 17.0952 17.14C16.1852 17.14 15.4082 16.9323 14.7642 16.517C14.1248 16.1017 13.6348 15.5043 13.2942 14.725C12.9582 13.9457 12.7902 13.0053 12.7902 11.904C12.7902 10.8073 12.9582 9.874 13.2942 9.104C13.6302 8.334 14.1178 7.74833 14.7572 7.347C15.4012 6.941 16.1805 6.738 17.0952 6.738Z'
        fill='black'
      />
    </svg>
  )
}

export default IconDryContainer
