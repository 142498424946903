import { Stack } from '@mui/material'
import { ContainerService, OperationType, WorkInstructionJobDto } from '@operations/app/api'
import { useMinimalsTheme } from '@tom-ui/ui'
import { EquipmentOperatorWorkInstructionStepperUIStore } from '../../stores/equipment-operator-work-instruction-stepper.ui-store'
import { WorkInstructionJobInformationWrapper } from '../JobInformation/WorkInstructionJobInformationWrapper'
import { GeneralCargoEquipmentOperatorProps } from './confirm-destination.interface'

interface Props extends GeneralCargoEquipmentOperatorProps {
  job: WorkInstructionJobDto
  stepperUiStore: EquipmentOperatorWorkInstructionStepperUIStore
}

export const ConfirmAmount = ({ job, DestinationComponent, stepperUiStore: uiStore }: Props) => {
  const theme = useMinimalsTheme()

  const getHandlingDirection = () => {
    if (job.operationType === OperationType.Service) {
      if (job.service?.type === ContainerService.Stripping) return OperationType.Inbound
      else return OperationType.Outbound
    }

    return job.operationType
  }

  const onUpdateQuantity = (quantity: number) => {
    uiStore.setHasQuantity(quantity)
  }

  return (
    <WorkInstructionJobInformationWrapper
      job={job}
      currentStep={uiStore.currentStepNumber}
      stepsTotal={uiStore.stepsTotal}
    >
      <Stack gap={theme.customSpacing.m} marginY={theme.customSpacing.m}>
        {DestinationComponent && (
          <DestinationComponent
            quantity={job.cargoUnit.amount}
            handlingDirection={getHandlingDirection()}
            generalCargoOrderId={job.order!.externalId! ?? job.service?.externalId}
            cargoItemId={job.cargoUnit.externalId!}
            context='occupancy'
            onUpdateQuantity={onUpdateQuantity}
          />
        )}
      </Stack>
    </WorkInstructionJobInformationWrapper>
  )
}
