import { Grid, Typography } from '@mui/material'
import { JobDto } from '@operations/app/api'
import { useOperationsStore } from '@operations/AppProvider'
import { JobGroupDto } from '@operations/features/equipmentOperator/models/job-group.model'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useSnackbar } from 'notistack'
import { JobCard } from '../JobCard/JobCard'

interface Props {
  jobGroups: JobGroupDto[]
  highlightText?: string
}

export const JobList = observer(({ jobGroups, highlightText }: Props) => {
  const { t } = useTranslate()

  const { appStore, equipmentOperatorUIStore, equipmentOperatorStepperUIStore, tenantConfigStore } =
    useOperationsStore()

  const { closeSnackbar } = useSnackbar()

  const handleConfirm = async (job: JobDto) => {
    closeSnackbar()

    if (equipmentOperatorStepperUIStore.canSkipStepper(job)) {
      equipmentOperatorUIStore.hideJob(job.workInstructionId)

      appStore.showAlertForCancellableRequest(
        'success',
        `${job.container!.containerNumber} ${t('isBeingConfirmed', 'is being confirmed')}`,
        async () => {
          await equipmentOperatorStepperUIStore
            .finishJobByWorkInstructionId(job.workInstructionId)
            .catch(() => equipmentOperatorUIStore.showJob(job.workInstructionId))
          equipmentOperatorUIStore.closeSearchIfFinishingLastNotification(job.workInstructionId)
        },
        undefined,
        () => {
          equipmentOperatorUIStore.showJob(job.workInstructionId)
        },
      )
    } else {
      await equipmentOperatorStepperUIStore.startJob(job)
      await equipmentOperatorStepperUIStore.openStepper(job)
    }
  }

  return (
    <>
      {jobGroups.map((group, index) => (
        <Grid
          key={`${group.groupName}_${index}`}
          container
          gap={theme => theme.customSpacing.m}
          marginBottom={theme => theme.customSpacing.xll}
        >
          {(jobGroups.length > 1 || group.groupName !== '') && (
            <Grid item xs={12}>
              <Typography variant='h3'>
                {group.groupName !== ''
                  ? group.groupName
                  : t('ungroupedContainers', 'Ungrouped containers')}
              </Typography>
            </Grid>
          )}

          {group.jobs.map(job => (
            <Grid key={`${job.workInstructionId}`} item xs={12}>
              <JobCard
                job={job}
                isConfirm={!!equipmentOperatorStepperUIStore.canSkipStepper(job)}
                onConfirm={handleConfirm}
                highlightText={highlightText}
                fieldsToShow={tenantConfigStore.getOperatorFieldsByEquipmentAndOperationTypeAndCarrierType(
                  equipmentOperatorUIStore.typeOfSelectedEquipment,
                  job.operationType,
                  job.carrierVisit?.type,
                )}
              ></JobCard>
            </Grid>
          ))}
        </Grid>
      ))}
    </>
  )
})
