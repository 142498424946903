import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { StockDto } from '@tom-ui/storage'
import { observer } from 'mobx-react-lite'
import { GeneralCargoStockOccupancyList } from 'modules/storage/src/pages/general-cargo-inventory/components/GeneralCargoStockOccupancyList'

interface IProps {
  open: boolean
  customerId?: number
  customerName?: string
  onSelectCommodity: (commodity: StockDto) => void
  onClose: () => void
}

export const CommodityDialog = observer(
  ({ open, customerId, customerName, onClose, onSelectCommodity }: IProps) => {
    const { t } = useTranslate()

    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth='lg'>
        <DialogTitle
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          {t('selectCustomerCommodity', 'Select {customerName} Commodity', {
            customerName: customerName ?? '',
          })}
          <Button color='secondary' size='large' onClick={onClose}>
            {t('close', 'Close')}
          </Button>
        </DialogTitle>

        <DialogContent>
          {(customerId && (
            <Grid container spacing={2}>
              {/* [stuffing] todo: filter for commodity */}
              {/* <Grid item xs={12}>
              <TextField
                label={t(
                  'searchCommodityLotNumberPackage',
                  'Search commodity, lot number, package',
                )}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid> */}
              <Grid item xs={12}>
                <GeneralCargoStockOccupancyList
                  customerId={customerId}
                  handleOnSelect={onSelectCommodity}
                />
              </Grid>
            </Grid>
          )) || (
            <Typography variant='subtitle1' mb={'.5rem'} color={'#637381'}>
              {t(
                'thereIsNoCustomerDetailsForThisSelectedContainer',
                'There is no customer details for this selected container',
              )}
            </Typography>
          )}
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    )
  },
)
