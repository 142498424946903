import { CarrierVisitsApi, YardBlocksApi } from '@storage/app/api'
import { createApiClient } from '@storage/app/http-client'
import { useStores } from '@storage/hooks/use-stores.hook'
import { ContainerTurnoversFilterFormProfile } from '@storage/pages/container-turnovers/components/container-turnovers-filter-form'
import { ContainerTurnoversFilterStore } from '@storage/pages/container-turnovers/stores/container-turnovers-filter.store'
import { ContainerTurnoversListStore } from '@storage/pages/container-turnovers/stores/container-turnovers-list.store'
import { ContainerTurnoversPaginationStore } from '@storage/pages/container-turnovers/stores/container-turnovers-pagination.store'
import { ManualInputValidationStore } from '@storage/pages/container-turnovers/stores/manual-input-validation.store'
import { ManualPlanningDialogStore } from '@storage/pages/container-turnovers/stores/manual-planning-dialog.store'
import { PrefiltersStore } from '@storage/pages/yard-planning-dashboard-details/stores/prefilters.store'
import { containerPlanningService } from '@storage/services'
import { AlertStore } from '@storage/stores/alert.store'
import { useMemo } from 'react'
import { useParams } from 'react-router'
import { Params } from '../YardPlanningDashboardDetails'
import { VesselVisitDetailsStore, YardBlockDetailsStore } from '../stores'

export const useTodoDetailsStores = (
  filterDefaultValues: Partial<ContainerTurnoversFilterFormProfile>,
) => {
  const { vesselVisitId, handlingDirection } = useParams<Params>()
  const {
    containerTurnoverStore,
    carrierVisitStore: vesselVisitStore,
    yardBlockStore,
    yardBlockBayStore,
    yardBlockRowStore,
    snackbarStore,
    tenantConfigStore,
    carrierVisitAllocationRulesV2UIStore,
  } = useStores()

  const prefiltersStore = useMemo(
    () =>
      new PrefiltersStore(
        createApiClient(CarrierVisitsApi),
        Number(vesselVisitId),
        handlingDirection!,
        carrierVisitAllocationRulesV2UIStore,
      ),
    [handlingDirection, vesselVisitId, carrierVisitAllocationRulesV2UIStore],
  )

  const vesselVisitDetailsStore = useMemo(
    () => new VesselVisitDetailsStore(Number(vesselVisitId), vesselVisitStore),
    [vesselVisitId, vesselVisitStore],
  )

  const containerTurnoversPaginationStore = useMemo(
    () => new ContainerTurnoversPaginationStore(),
    [],
  )

  const containerTurnoversFilterStore = useMemo(
    () => new ContainerTurnoversFilterStore(containerTurnoversPaginationStore, filterDefaultValues),
    [containerTurnoversPaginationStore, filterDefaultValues],
  )

  const containerTurnoverListStore = useMemo(
    () =>
      new ContainerTurnoversListStore(
        containerTurnoverStore,
        containerTurnoversFilterStore,
        containerTurnoversPaginationStore,
      ),
    [containerTurnoverStore, containerTurnoversFilterStore, containerTurnoversPaginationStore],
  )

  const yardBlockDetailsStore = useMemo(
    () => new YardBlockDetailsStore(createApiClient(YardBlocksApi)),
    [],
  )

  const manualInputValidationStore = useMemo(
    () =>
      new ManualInputValidationStore(
        yardBlockStore,
        yardBlockBayStore,
        yardBlockRowStore,
        tenantConfigStore,
      ),
    [yardBlockStore, yardBlockBayStore, yardBlockRowStore, tenantConfigStore],
  )

  const alertStore = useMemo(() => new AlertStore(), [])

  const manualPlanningDialogStore = useMemo(
    () =>
      new ManualPlanningDialogStore(
        containerPlanningService,
        containerTurnoverListStore,
        containerTurnoversFilterStore,
        containerTurnoverStore,
        manualInputValidationStore,
        tenantConfigStore,
        yardBlockStore,
        yardBlockBayStore,
        snackbarStore,
        alertStore,

        {
          prefiltersStore,
          handlingDirection: handlingDirection!,
          vesselVisitId: Number(vesselVisitId),
        },
      ),
    [
      containerTurnoverListStore,
      containerTurnoversFilterStore,
      containerTurnoverStore,
      manualInputValidationStore,
      tenantConfigStore,
      yardBlockStore,
      yardBlockBayStore,
      snackbarStore,
      alertStore,
      prefiltersStore,
      handlingDirection,
      vesselVisitId,
    ],
  )

  return {
    containerTurnoversPaginationStore,
    containerTurnoversFilterStore,
    containerTurnoverListStore,
    manualPlanningDialogStore,
    prefiltersStore,
    yardBlockDetailsStore,
    vesselVisitDetailsStore,
  }
}
