import { BerthResponseDto, BerthsApi } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'
import { Event, IEvent, IQueryHandler, IQueryMessage } from '../messageBus'

export class GetBerthsQuery implements IQueryMessage {
  static type = 'GetBerthsQuery'
  type = GetBerthsQuery.type

  getKey = () => {
    return JSON.stringify(this)
  }
}

const httpClient = createApiClient(BerthsApi)

export const getBerthsQueryHandler: IQueryHandler<
  GetBerthsQuery,
  IEvent<BerthResponseDto[]>
> = async _ => {
  const { data } = await httpClient.get()

  return new Event(GetBerthsQuery.type, data)
}
