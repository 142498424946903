import { ChipProps } from '@mui/material'
import { alpha, Theme } from '@mui/material/styles'
//
import { COLORS } from '../palette'
import { CloseIcon } from './CustomIcons'

// ----------------------------------------------------------------------

export default function Chip(theme: Theme) {
  const rootStyle = (ownerState: ChipProps) => {
    const hasClick = !!ownerState.onClick
    const defaultColor = ownerState.color === 'default'

    const filledVariant = ownerState.variant === 'filled'

    const outlinedVariant = ownerState.variant === 'outlined'

    const softVariant = ownerState.variant === 'soft'

    const defaultStyle = {
      ...(defaultColor && {
        color: theme.palette.text.primary,
        ...(hasClick && {
          '&:hover': {
            backgroundColor: theme.palette.grey[300],
          },
          '&:active': {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.grey[400],
          },
        }),
        '& .MuiChip-avatar': {
          backgroundColor: alpha(theme.palette.grey[500], 0.48),
        },
        // OUTLINED
        ...(outlinedVariant && {
          border: `solid 1px ${alpha(theme.palette.grey[500], 0.32)}`,
        }),
        // SOFT
        ...(softVariant && {
          backgroundColor: theme.palette.grey[200],
          ...(hasClick && {
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
            '&:active': {
              backgroundColor: theme.palette.grey[400],
            },
          }),
        }),
      }),
      ...{
        ...theme.typography.body2,
        padding: `${theme.customSpacing.xxs} ${theme.customSpacing.xs}`,
        borderRadius: theme.customRadius.large,
      },
    }

    const colorStyle = COLORS.map(color => ({
      ...(ownerState.color === color && {
        '& .MuiChip-avatar': {
          color: theme.palette[color].lighter,
          backgroundColor: theme.palette[color].dark,
        },
        '& .MuiChip-icon > path': {
          fill: theme.palette[color].contrastText,
        },
        // FILLED
        ...(filledVariant && {
          backgroundColor: alpha(theme.palette[color].main, 0.8),
          ...(hasClick && {
            '&:hover': {
              backgroundColor: theme.palette[color].dark,
            },
            '&:active': {
              backgroundColor: theme.palette[color].darker,
            },
          }),
          '& .MuiChip-deleteIcon': {
            color: alpha(theme.palette[color].contrastText, 0.56),
            '&:hover': {
              color: theme.palette[color].contrastText,
            },
          },
        }),
        // SOFT
        ...(softVariant && {
          color: theme.palette[color].contrastText,
          backgroundColor: alpha(theme.palette[color].main, 0.7),
          ...(hasClick && {
            '&:hover': {
              backgroundColor: theme.palette[color].dark,
            },
            '&:active': {
              backgroundColor: theme.palette[color].darker,
            },
          }),
          '& .MuiChip-deleteIcon': {
            color: alpha(theme.palette[color].contrastText, 0.48),
            '&:hover': {
              color: theme.palette[color].contrastText,
            },
          },
        }),
      }),
    }))

    return [...colorStyle, defaultStyle]
  }

  return {
    MuiChip: {
      defaultProps: {
        deleteIcon: <CloseIcon />,
      },

      styleOverrides: {
        root: ({ ownerState }: { ownerState: ChipProps }) => rootStyle(ownerState),
      },
    },
  }
}
