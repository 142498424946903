import {
  AllowTruckVisitEntryCommand,
  AllowTruckVisitExitCommand,
  CarrierVisitStatus,
  TruckVisitsApi,
} from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'

class TruckVisitService {
  httpClient = createApiClient(TruckVisitsApi)

  allowEntry = async (cmd: AllowTruckVisitEntryCommand) =>
    await this.httpClient.allowTruckVisitEntry(cmd.id, cmd)

  allowExit = async (cmd: AllowTruckVisitExitCommand) =>
    await this.httpClient.allowTruckVisitExit(cmd.id, cmd)

  getByIds = async (ids?: Array<number>) => await this.httpClient.getByIds(ids)

  get = async (page: number, pageSize: number, status: CarrierVisitStatus, filter?: string) => {
    const { data } = await this.httpClient.get(page, pageSize, status, undefined, undefined, filter)

    return data.payload
  }
}

const truckVisitService = new TruckVisitService()

export default truckVisitService
