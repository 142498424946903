import { Button } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'

export const CarrierVisitNavigateButton = observer(() => {
  const { appViewStore } = usePlanningStore()
  const navigate = useNavigate()

  return (
    <Button
      color='inherit'
      size='small'
      onClick={() => {
        if (appViewStore.createdVesselVisitId) {
          navigate(`/vessel-visits/${appViewStore.createdVesselVisitId}`)
        } else if (appViewStore.createdRailVisitId) {
          navigate(`/rail-visits/${appViewStore.createdRailVisitId}`)
        }
      }}
    >
      {'View Dashboard'}
    </Button>
  )
})
