import { Autocomplete, Chip, Popper, TextField, Tooltip } from '@mui/material'
import { DangerousGoodsDefinitionDto } from '@planning/app/api'
import { UnNumbersOptionListItem } from '@planning/pages/DeliveryOrders/Components/UnNumbersOptionListItem'
import { useTranslate } from '@tolgee/react'
import { FC } from 'react'

interface Props {
  enableSelect?: boolean
  error?: boolean
  helperText?: React.ReactNode
  disable?: boolean
  selectedUnNumbers?: DangerousGoodsDefinitionDto[]
  options: DangerousGoodsDefinitionDto[]
  onUnNumbersChange: (unNumbers: DangerousGoodsDefinitionDto[]) => void
  handleOnChange?: (value?: DangerousGoodsDefinitionDto[]) => void
  onInputChange: (filter?: string) => void
}

// React components should not be nested typescript:S6478
export const PopperWithPlacement = (props: any) => {
  return <Popper {...props} placement='bottom-start' />
}

export const UnNumbersAutoComplete: FC<Props> = ({
  enableSelect,
  error,
  helperText,
  disable,
  selectedUnNumbers,
  options,
  onUnNumbersChange,
  handleOnChange,
  onInputChange,
}) => {
  const { t } = useTranslate()

  const onDeleteUnNumber = (imdg: string) => {
    if (selectedUnNumbers) {
      const index = selectedUnNumbers.findIndex(c => c.unNumber === imdg)
      if (index !== -1) {
        selectedUnNumbers.splice(index, 1)
        onUnNumbersChange([...selectedUnNumbers])
      }
    }
  }

  return (
    enableSelect && (
      <Autocomplete
        sx={{ mt: '10px' }}
        disabled={disable}
        fullWidth
        multiple
        getOptionLabel={responseDto => responseDto.unNumber || ''}
        options={options}
        onInputChange={(_, newInputValue) => {
          onInputChange(newInputValue)
        }}
        noOptionsText={t('noMatchingUNNumberFound', 'No matching UN numbers found.')}
        renderOption={(props, unNumberDto) => (
          <UnNumbersOptionListItem
            unNumber={unNumberDto.unNumber!}
            material={unNumberDto.material!}
            primaryImoClass={unNumberDto.primaryImoClass!}
            secondaryImoClasses={unNumberDto.secondaryImoClasses}
            props={props}
            key={`codes-list-option-${unNumberDto.id}`}
          />
        )}
        PopperComponent={PopperWithPlacement}
        slotProps={{
          popper: {
            sx: { minWidth: '16rem' },
          },
        }}
        isOptionEqualToValue={(
          option: DangerousGoodsDefinitionDto,
          value: DangerousGoodsDefinitionDto,
        ) => option.id === value.id}
        value={selectedUnNumbers || []}
        onChange={(_, unNumber) => {
          onUnNumbersChange(unNumber)
          if (handleOnChange) handleOnChange(unNumber)
        }}
        renderTags={value => (
          <>
            {value.map(unNumberItem => (
              <Tooltip
                key={`imo-class-option-tooltip-${unNumberItem.id}`}
                title={`${unNumberItem.material}`}
              >
                <Chip
                  key={`imo-class-option-chip-${unNumberItem.id}`}
                  label={`${unNumberItem.unNumber} - ${unNumberItem.material}`}
                  onDelete={() => onDeleteUnNumber(unNumberItem.unNumber!)}
                />
              </Tooltip>
            ))}
          </>
        )}
        renderInput={params => (
          <TextField
            {...params}
            placeholder={selectedUnNumbers?.length ? '' : 'Un Numbers'}
            label={t('unNumbers', 'UN Numbers')}
            error={error}
            helperText={helperText}
          />
        )}
      />
    )
  )
}
