import { ContainerTurnoverDto, SlotDto, YardBlockSlotsApi, YardUnit } from '@storage/app/api'
import { EntityStore } from '@storage/app/store/entity.store'
import { YardManagementHubConnection } from '@storage/hubs/yard-management.hub-connection'
import _ from 'lodash'
import { action, makeObservable, observable } from 'mobx'

export interface BlockBaySummary {
  fullCount: number
  emptyCount: number
  groupedBySize: any[]
  groupedByOperator: any[]
}

export class YardBlockSlotStore extends EntityStore<SlotDto> {
  blockBaySummary: BlockBaySummary = {
    fullCount: 0,
    emptyCount: 0,
    groupedBySize: [],
    groupedByOperator: [],
  }

  constructor(
    private api: YardBlockSlotsApi,
    private readonly yardManagementConnection: YardManagementHubConnection,
  ) {
    super()
    makeObservable(this, {
      blockBaySummary: observable,
      setBlockBaySummary: action,
    })

    // Reload yard block slots when a notification is received
    yardManagementConnection.onReloadStacks(({ unit, unitId }) => {
      if (unit === YardUnit.Block && unitId) {
        this.loadForYardBlock(unitId)
      }
    })
  }

  public async loadList(): Promise<void> {
    const { data: yardBlockSlots } = await this.api.get()

    this.setAll(yardBlockSlots)
  }

  public async loadForYardBlock(yardBlockId: string): Promise<void> {
    const { data: yardBlockSlots } = await this.api.get({ yardBlockId })

    this.addOrUpdateMany(yardBlockSlots)
  }

  setBlockBaySummary(summary: BlockBaySummary) {
    this.blockBaySummary = summary
  }

  public async loadForYardBlockBay(yardBlockBayId: string): Promise<void> {
    const { data: yardBlockSlots } = await this.api.get({ yardBlockBayId })
    this.addOrUpdateMany(yardBlockSlots)

    this.setBlockBaySummary(this.getBlockBaySummary(yardBlockSlots))
  }

  public async lock(id: string, isLocked: boolean): Promise<void> {
    const { data: yardBlockSlots } = await this.api.setLockStatus({
      yardBlockSlotId: id,
      lockStatus: isLocked,
    })

    this.addOrUpdateList(yardBlockSlots)
  }

  public addOrUpdateList(yardBlockSlots: SlotDto[]) {
    yardBlockSlots.forEach(slot => this.addOrUpdate(slot))
  }

  public getStackSlots(bayId: string, rowId: string) {
    return this.entries.filter(
      ({ yardBlockRowId, yardBlockBayId }) => yardBlockRowId === rowId && yardBlockBayId == bayId,
    )
  }

  public getGroundSlots(blockId: string) {
    return this.entries.filter(s => s.yardBlockId === blockId && s.tier === 1)
  }

  public delete(slots: string[]) {
    slots.forEach(s => this.remove(s))
  }

  public getSlotInNextBay(slot: SlotDto) {
    return this.entries.find(
      s =>
        s.yardBlockBaySequenceNumber === slot.yardBlockBaySequenceNumber + 1 &&
        s.yardBlockRowId === slot.yardBlockRowId &&
        s.tier === slot.tier,
    )
  }

  getBlockBaySummary(yardBlockSlots: SlotDto[]) {
    const groupedBySize = this.getGroupedData(yardBlockSlots, 'size')

    const groupedByCargoStatus = this.getGroupedData(yardBlockSlots, 'isEmptyContainerCargoState')

    const groupedByOperator = this.getGroupedData(yardBlockSlots, 'containerOperator')

    return {
      groupedBySize: this.transformGroupedData(groupedBySize),
      emptyCount:
        this.transformGroupedData(groupedByCargoStatus).find(x => x.key === 'true')?.value ?? 0,
      fullCount:
        this.transformGroupedData(groupedByCargoStatus).find(x => x.key === 'false')?.value ?? 0,
      groupedByOperator: this.transformGroupedData(groupedByOperator),
    }
  }

  private getGroupedData(yardBlockSlots: SlotDto[], groupingKey: keyof ContainerTurnoverDto) {
    return _.chain(yardBlockSlots)
      .groupBy(item => {
        if (item.occupiedTurnovers.length > 0) {
          return item.occupiedTurnovers[0][groupingKey]
        }
      })
      .mapValues(group => group.length)
      .omit('undefined')
      .value()
  }

  private transformGroupedData(groupedData: Record<string, number>) {
    return Object.keys(groupedData).length > 0
      ? Object.entries(groupedData).map(([key, value]) => {
          return {
            key: key,
            value: value,
          }
        })
      : []
  }
}
