import { Box, Button, debounce } from '@mui/material'
import { OrderResponseDto } from '@planning/app/api'
import { PickUpOrderSearchStore } from '@planning/stores/gateClerk/PickUpOrderSearchStore'
import { ValidateOutboundOrderFunc } from '@planning/stores/gateControl/ValidateOutboundDto'
import { NonNumericOrderWithPickUpAmount } from '@planning/stores/truckAppointment/TruckAppointmentDetailsViewStore'
import { useTranslate } from '@tolgee/react'
import { ContainerMobile, Header, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { NavigationStackBackButton } from '../TallymanV2/Components/HeaderComponents/NavigationStackBackButton'
import { GateClerkGeneralCargoResult } from './Components/GateClerkGeneralCargoResult'
import { GateClerkSearchResult } from './Components/GateClerkSearchResult'
import { IInspectContainerFormData } from './Components/InspectContainer'
import { NNROrderHandlingBox } from './Components/NNROrderHandlingBox'
import { SearchOrdersState } from './SearchOrdersState'

interface IProps {
  store: PickUpOrderSearchStore
  selectedOrders: IInspectContainerFormData[]
  selectedNNROrders: NonNumericOrderWithPickUpAmount[]
  onClickOrder: (order: OrderResponseDto) => void
  onClickNNROrder: (order: NonNumericOrderWithPickUpAmount) => void
  validateOutboundRequest?: ValidateOutboundOrderFunc
}

export const SearchPickUpOrders: FC<IProps> = observer(
  ({
    store,
    onClickOrder,
    onClickNNROrder,
    validateOutboundRequest,
    selectedOrders,
    selectedNNROrders,
  }) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()
    const [filter, setFilter] = useState('')
    const sendRequest = useCallback(
      (value: string) => {
        store.setFilter(value)
      },
      [store],
    )

    const debouncedSendRequest = useMemo(() => {
      return debounce(sendRequest, 1000)
    }, [sendRequest])

    useEffect(() => {
      window.scrollTo(0, 0)
      if (store && store.reset) store.reset()
    }, [store])

    const InspectOrAddContainerAction: FC = () => {
      return (
        <Button
          data-cy='gate-clerk-add-container-btn'
          variant='contained'
          size='large'
          sx={{ width: '100%', mt: '1rem' }}
        >
          {t('add', 'Add')}
        </Button>
      )
    }

    const orderSearchItems = store.orders.filter(
      item => !selectedOrders.find(order => order.id === item.id),
    )

    const nnrOrderSearchItems = store.nnrOrders.filter(
      ({ referenceNumber: id1 }) =>
        !selectedNNROrders.some(({ referenceNumber: id2 }) => id2 === id1),
    )

    const SearchItems: FC = () => {
      return [
        orderSearchItems.map(o =>
          o.containerNumber === null && o.commodityId !== null ? (
            <GateClerkGeneralCargoResult
              key={`gc-search-result-${o.id}-${o.containerNumber}`}
              highlight={store.filter}
              order={o}
              direction={'Outbound'}
              onClick={() => onClickOrder(o)}
              validateOutboundRequest={validateOutboundRequest}
            >
              <InspectOrAddContainerAction />
            </GateClerkGeneralCargoResult>
          ) : (
            <GateClerkSearchResult
              key={`container-search-result-${o.id}-${o.containerNumber}`}
              containerNumber={o.containerNumber ?? ''}
              holds={o.holds}
              highlight={store.filter}
              direction={'Outbound'}
              order={o}
              onClick={() => onClickOrder(o)}
              releaseEnd={o.releaseEnd}
              isOnTerminal={o.isOnTerminal}
            >
              <InspectOrAddContainerAction />
            </GateClerkSearchResult>
          ),
        ),
        nnrOrderSearchItems.map(o => (
          <NNROrderHandlingBox
            key={`${o.referenceNumber}`}
            order={o}
            onClickAdd={onClickNNROrder}
          />
        )),
      ]
    }

    const onSearchFilterChange = (newFilter: string) => {
      setFilter(newFilter)

      debouncedSendRequest(newFilter)
    }

    return (
      <>
        <Header
          filterTestId='search-pick-up-orders-autocomplete'
          leftRenderOption={<NavigationStackBackButton />}
          enableSearchField
          onSearchFieldChange={onSearchFilterChange}
          searchFilter={filter}
        />

        <Box style={{ backgroundColor: theme.palette.grey[200], minHeight: '100vh' }}>
          <ContainerMobile sx={{ pt: theme.customSpacing.l }}>
            <SearchOrdersState store={store} />
            <SearchItems />
          </ContainerMobile>
        </Box>
      </>
    )
  },
)
