import {
  GeneralCargoInventoryDto,
  GeneralCargoItemPlanningDto,
  GeneralCargoOrderDto,
  GeneralCargoOrdersApi,
  GeneralCargoOutboundValidationResultDto,
  GeneralCargoStockDto,
  GeneralCargoStockOccupanciesApi,
  GeneralCargoStocksApi,
  GeneralCargoStocksApiGetByQueryParamsRequest,
  GeneralCargoStocksApiValidateOutboundOrderRequest,
  UpdateGeneralCargoOrderPlanningDto,
  UpdateGeneralCargoStockOccupancyDto,
} from '@storage/app/api'

export class GeneralCargoStockService {
  constructor(
    private api: GeneralCargoStocksApi,
    private occupancyApi: GeneralCargoStockOccupanciesApi,
    private readonly _generalCargoOrdersApi: GeneralCargoOrdersApi,
  ) {}

  async getAll(): Promise<Array<GeneralCargoStockDto>> {
    const { data: GeneralCargoStocks } = await this.api.getAll()
    return GeneralCargoStocks
  }

  //TODO: separate occupancy/planning from stock
  async getPlannedOccupanciesByOrderId(
    orderId: number,
    cargoItemId?: number,
  ): Promise<Array<GeneralCargoItemPlanningDto>> {
    const { data: plannedOccupancies } = await this.occupancyApi.getInboundOrderPlanning({
      orderId,
      cargoItemId,
    })
    return plannedOccupancies
  }

  async getByQuery(
    params: GeneralCargoStocksApiGetByQueryParamsRequest,
  ): Promise<Array<GeneralCargoStockDto>> {
    const { data: GeneralCargoStocks } = await this.api.getByQueryParams(params)
    return GeneralCargoStocks
  }

  async getByOrderId(orderId: number, cargoItemId?: number): Promise<GeneralCargoStockDto> {
    const { data: GeneralCargoStock } = await this.api.getByOrderId({
      orderId,
      cargoItemId,
    })
    return GeneralCargoStock
  }

  async getGeneralCargoOrderById(orderId: number): Promise<GeneralCargoOrderDto> {
    const { data: generalCargoOrder } = await this._generalCargoOrdersApi.getByOrderId({
      orderId,
    })
    return generalCargoOrder
  }

  async getInventory(
    params: GeneralCargoStocksApiGetByQueryParamsRequest,
  ): Promise<Array<GeneralCargoInventoryDto>> {
    const { data: GeneralCargoInventory } = await this.api.getInventory(params)
    return GeneralCargoInventory
  }

  async updateOccupancy(
    dto: UpdateGeneralCargoStockOccupancyDto,
    inbound: boolean,
  ): Promise<GeneralCargoStockDto> {
    const { data: GeneralCargoStock } = inbound
      ? await this.occupancyApi.putToStock({
          generalCargoStockOccupanciesPutToStockRequest: dto,
        })
      : await this.occupancyApi.takeFromStock({
          generalCargoStockOccupanciesPutToStockRequest: dto,
        })

    return GeneralCargoStock
  }

  async planDropOff(
    dto: UpdateGeneralCargoOrderPlanningDto,
  ): Promise<GeneralCargoItemPlanningDto[]> {
    const { data: orderInboundPlanning } = await this.occupancyApi.planInboundOrder({
      generalCargoStockOccupanciesPlanInboundOrderRequest: dto,
    })
    return orderInboundPlanning
  }

  async uploadDamageFile(file: File): Promise<string> {
    const resp = await this.occupancyApi.uploadDamageFile({ file: file })
    return resp.data
  }

  async validateOutboundRequest(
    params: GeneralCargoStocksApiValidateOutboundOrderRequest,
  ): Promise<GeneralCargoOutboundValidationResultDto> {
    const { data: validationResult } = await this.api.validateOutboundOrder(params)

    return validationResult
  }
}
