import { CarrierType, NonNumericOrderDto, NonNumericOrdersApi } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'
import { axiosUnwrapper } from '@planning/app/utils'
import { PaginatedStore } from '@planning/stores/PaginatedStore'
import { makeObservable } from 'mobx'

export function createNNROrderViewStore(modeOfTransport?: CarrierType) {
  return new NNROrderViewStore(modeOfTransport)
}

export class NNROrderViewStore extends PaginatedStore<NonNumericOrderDto> {
  constructor(modeOfTransport?: CarrierType) {
    super(async (currentPageIndex, pageSize, sortingModel, filter, showCompleted) => {
      return axiosUnwrapper(
        createApiClient(NonNumericOrdersApi).get(
          currentPageIndex + 1,
          pageSize,
          modeOfTransport,
          filter,
          showCompleted,
          sortingModel.orderBy,
          sortingModel.isDescending,
        ),
      )
    })

    makeObservable(this, {})
  }
}
