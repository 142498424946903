import { Box, Card, CardContent, Stack, Typography } from '@mui/material'
import { CoolingOrderDto } from '@operations/app/api'
import { formatContainerNumber } from '@operations/utils/helper'
import { useTranslate } from '@tolgee/react'
import { HighlightedText, Tile, useMinimalsTheme } from '@tom-ui/ui'
import dayjs from 'dayjs'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import { CoolingOrderWarnings } from './CoolingOrderWarnings'

interface Props {
  order: CoolingOrderDto
  highlightText?: string
  onClick: (id: number) => void
}
export const CoolingOrderCard = observer(({ order, highlightText, onClick }: Props) => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()

  return (
    <Card onClick={() => onClick(order.id)} sx={{ cursor: 'pointer' }}>
      <CardContent>
        <Stack flexDirection='row' justifyContent='space-between'>
          <Typography variant='body1'>{order.yardBlock ?? '-'}</Typography>
          <CoolingOrderWarnings order={order} />
        </Stack>
        <Typography variant='h1'>
          <HighlightedText highlight={highlightText}>
            {order.containerNumber
              ? formatContainerNumber(order.containerNumber)
              : t('noContainerNumber', 'No container no.')}
          </HighlightedText>
        </Typography>
      </CardContent>
      <Box
        sx={{
          padding: theme.customSpacing.m,
          gap: theme.customSpacing.xl,
          display: 'flex',
          backgroundColor: theme.palette.grey[100],
          overflowX: 'auto',
        }}
      >
        <Tile
          label={t('lastTemperature', 'Last temperature')}
          value={
            _.isNumber(order.lastRecordedTemperature) ? `${order.lastRecordedTemperature} ºC` : '-'
          }
        />

        <Tile
          label={t('lastCheck', 'Last check')}
          value={`${order.lastRecordedTemperatureDateTime ? dayjs(order.lastRecordedTemperatureDateTime).format('MM/DD/YYYY hh:mm A') : '-'}`}
        />

        <Tile
          label={t('nextCheck', 'Next check')}
          value={`${order.nextCheckDateTime ? dayjs(order.nextCheckDateTime).format('MM/DD/YYYY hh:mm A') : '-'}`}
        />
      </Box>
    </Card>
  )
})
