import { SelectOption } from '@operations/app/models'
import { Autocomplete, TextField, TextFieldProps } from '@mui/material'
import { OverridableStringUnion } from '@mui/types'
import { defaultSelectOption } from '@operations/utils/helper'
import { matchSorter } from 'match-sorter'
import { Controller, FieldValues, FormState, UseControllerProps } from 'react-hook-form'

type FieldProps = Pick<TextFieldProps, 'label' | 'disabled' | 'fullWidth' | 'variant'>

interface Props<T extends FieldValues> extends UseControllerProps<T>, FieldProps {
  formState: FormState<T>
  label: string
  fullWidth?: boolean
  options: SelectOption[]
  multiple?: boolean
  hasDefaultOption?: boolean
  size?: OverridableStringUnion<'small' | 'medium'>
  onChange?: (option: SelectOption | SelectOption[] | null) => Promise<void> | void
  disabledOption?: (option: SelectOption) => boolean
}

export const ControlledAutocomplete = <T extends FieldValues>({
  name,
  control,
  label,
  disabled,
  fullWidth,
  options,
  size,
  variant,
  multiple,
  hasDefaultOption,
  onChange,
  disabledOption,
  formState: { errors },
}: Props<T>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Autocomplete
          {...field}
          options={hasDefaultOption ? [defaultSelectOption, ...options] : options}
          onChange={(_, newValue: any) => {
            if (onChange) {
              onChange(newValue)
            }

            field.onChange(newValue)
          }}
          groupBy={option => option.groupBy ?? undefined}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          getOptionDisabled={disabledOption ? option => disabledOption(option) : undefined}
          multiple={multiple}
          renderInput={params => (
            <TextField
              {...params}
              fullWidth={fullWidth}
              error={!!errors[name]}
              helperText={errors[name]?.message as string}
              size={size ?? 'medium'}
              disabled={disabled}
              label={label}
              variant={variant ?? 'outlined'}
            />
          )}
          filterOptions={(options, { inputValue }) => {
            return matchSorter(options, inputValue.replace(/\s/g, ''), {
              keys: [item => item.label.replace(/\./g, '').replace(/\s/g, '')],
              threshold: matchSorter.rankings.CONTAINS,
            })
          }}
        />
      )}
    />
  )
}
