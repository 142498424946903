import {
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { RepeatByDateKind } from '@planning/app/api'
import { useTranslate } from '@tolgee/react'
import dayjs, { Dayjs } from 'dayjs'

export interface ICarrierVisitSchedule {
  showTimingOptions: number
  selectedRadioOption: string
  repeatEvery: number
  repeatBy: RepeatByDateKind
  endsOn?: Dayjs
  endsAfter?: number
}

export const carrierVisitScheduleDefaultValues: ICarrierVisitSchedule = {
  showTimingOptions: 0,
  selectedRadioOption: 'on',
  repeatEvery: 1,
  repeatBy: RepeatByDateKind.Week,
  endsOn: dayjs().add(1, 'day'),
  endsAfter: 1,
}

interface IProps {
  schedule: ICarrierVisitSchedule
  handleSchedule: (newValue: ICarrierVisitSchedule) => void
}
export const CarrierVisitSchedule = ({ schedule, handleSchedule }: IProps) => {
  const { t } = useTranslate()

  return (
    <>
      <Grid container direction='row' columnSpacing={{ xs: 1 }}>
        <Grid item xs={6} mt={'1rem'}>
          <FormControl fullWidth>
            <InputLabel color='secondary'>{t('repeatingVisit', 'Repeating Visit')}</InputLabel>
            <Select
              fullWidth
              defaultValue={carrierVisitScheduleDefaultValues.showTimingOptions}
              label={t('repeatingVisit', 'Repeating Visit')}
              onChange={e =>
                handleSchedule({
                  ...schedule,
                  showTimingOptions: e.target.value as number,
                })
              }
            >
              <MenuItem value={0}>{t('singleVisit', 'Single Visit')}</MenuItem>
              <MenuItem value={1}>{t('repeatingVisit', 'Repeating Visit')}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {schedule.showTimingOptions > 0 && (
        <>
          <Grid container direction='row' columnSpacing={{ xs: 1 }} mt={'1rem'}>
            <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant='subtitle2'>{t('repeatsEvery', 'Repeats every')}</Typography>
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                type='number'
                defaultValue={carrierVisitScheduleDefaultValues.repeatEvery}
                name='repeatEvery'
                inputProps={{
                  min: 1,
                  max: 12,
                  step: '1',
                }}
                onChange={event =>
                  handleSchedule({
                    ...schedule,
                    repeatEvery: +event.target.value,
                  })
                }
                onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
                sx={{ margin: 0 }}
              />
            </Grid>
            <Grid item xs={5}>
              <Select
                fullWidth
                defaultValue={carrierVisitScheduleDefaultValues.repeatBy}
                required
                onChange={event =>
                  handleSchedule({
                    ...schedule,
                    repeatBy: event.target.value as RepeatByDateKind,
                  })
                }
              >
                <MenuItem value={RepeatByDateKind.Day}>{t('days', 'Days')}</MenuItem>
                <MenuItem value={RepeatByDateKind.Week}>{t('weeks', 'Weeks')}</MenuItem>
                <MenuItem value={RepeatByDateKind.Month}>{t('months', 'Months')}</MenuItem>
              </Select>
            </Grid>
          </Grid>
          <RadioGroup
            value={schedule.selectedRadioOption}
            onChange={event =>
              handleSchedule({
                ...schedule,
                selectedRadioOption: event.target.value,
              })
            }
          >
            <Grid container direction='row' columnSpacing={{ xs: 1 }}>
              <Grid item xs={12}>
                <Typography variant='subtitle2'>{t('ends', 'Ends')}</Typography>
              </Grid>

              <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel value='on' control={<Radio />} label='On' />
              </Grid>
              <Grid item xs={10}>
                <DatePicker
                  value={schedule.endsOn}
                  disabled={schedule.selectedRadioOption !== 'on'}
                  onChange={newValue =>
                    handleSchedule({
                      ...schedule,
                      endsOn: newValue ?? undefined,
                    })
                  }
                  slotProps={{
                    textField: {
                      variant: 'outlined',
                      helperText:
                        schedule.selectedRadioOption === 'on' && !schedule.endsOn
                          ? t('fieldIsRequired', 'Field is required')
                          : '',
                      error: schedule.selectedRadioOption === 'on' && !schedule.endsOn,
                      required: true,
                      sx: { width: '100%' },
                    },
                  }}
                />
              </Grid>

              <Grid item xs={2} mt={'1rem'} sx={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel value='after' control={<Radio />} label='After' />
              </Grid>
              <Grid item xs={10} mt={'1rem'}>
                <TextField
                  type='number'
                  name='endsAfter'
                  fullWidth
                  disabled={schedule.selectedRadioOption !== 'after'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        {t('occurences', 'Occurences')}
                      </InputAdornment>
                    ),
                  }}
                  defaultValue={carrierVisitScheduleDefaultValues.endsAfter}
                  inputProps={{
                    min: 1,
                    step: '1',
                  }}
                  onChange={event =>
                    handleSchedule({
                      ...schedule,
                      endsAfter: +event.target.value,
                    })
                  }
                  onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </>
      )}
    </>
  )
}
