import { ArrowOutward } from '@mui/icons-material'
import { Box, Card, Popover, Stack, Typography } from '@mui/material'
import { CarrierType, CarrierVisitDto, ContainerTurnoverDto, ContainerType } from '@storage/app/api'
import { TRANS_KEY_PREFIXES } from '@storage/app/container-turnovers/constants/container-turnovers.const'
import { getContainerTurnoverAttributeLabel } from '@storage/app/container-turnovers/utils/container-turnover-attributes.util'
import { TileWithIcon } from '@storage/components/TileWithIcon'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { IconButton, InfoIcon, Tile } from '@tom-ui/ui'
import { ReactElement, cloneElement, useMemo, useState } from 'react'
interface Props {
  item: ContainerTurnoverDto
  variation?: 'standard' | 'compact'
}

interface AttributeComponentType {
  [key: string]: ReactElement | null
}

export const ContainerTurnoverAttributes = ({ item, variation = 'standard' }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const [carrierVisit, setCarrierVisit] = useState<CarrierVisitDto>()
  const { carrierVisitStore } = useStores()

  const { t } = useTranslate()

  const handleClose = () => {
    setAnchorEl(null)
  }

  // The attribute keys and order for each variation
  const variationAttributeKeys = useMemo(() => {
    return {
      standard: [
        'containerType',
        'referenceNumber',
        'containerIsoCode',
        'containerOperator',
        'portOfDischarge',
        'portOfLoading',
        'grossWeight',
        'isEmpty',
        'isDangerous',
        'imoClasses',
        'isDamaged',
        'isRestow',
        'size',
        'containerHeight',
        'inboundCarrierLabel',
        'inboundCarrierVisitName',
        'outboundCarrierLabel',
        'outboundCarrierVisitName',
        'isReefer',
        'consignee',
      ],
      compact: [
        'referenceNumber',
        'isEmpty',
        'size',
        'consignee',
        'outboundCarrierVisitName',
        'portOfDischarge',
      ],
    }
  }, [])

  const attributeComponents = useMemo(() => {
    const {
      containerIsoCode,
      inboundCarriers,
      inboundCarrierType,
      outboundOrderId,
      outboundCarrierType,
      outboundCarriers,
      isDangerous,
      grossWeight,
      isDamaged,
      isReefer,
      isRestow,
      isEmpty,
      isEmptyContainerCargoState,
      size,
      containerHeight,
      containerType,
      imoClasses,
      containerOperator,
      portOfDischarge,
      portOfLoading,
      consignee,
      referenceNumber,
    } = item

    const handleCarrierVisitClick = async (
      e: React.MouseEvent<HTMLButtonElement>,
      id: number | null | undefined,
    ) => {
      if (id) {
        setAnchorEl(e.currentTarget)
        setCarrierVisit(await carrierVisitStore.load(id))
      }
    }

    const carrierTypesLabels: Record<CarrierType, string> = {
      Vessel: t('vessel', 'Vessel'),
      Truck: t('truck', 'Truck'),
      Train: t('train', 'Train'),
      Universal: t('universal', 'Universal'),
    }

    const excludedContainerTypes: ContainerType[] = [ContainerType.Unknown, ContainerType.Reefer]

    const inboundCarrierLabel = inboundCarrierType
      ? carrierTypesLabels[inboundCarrierType]
      : undefined

    const outboundCarrierLabel = outboundCarrierType
      ? carrierTypesLabels[outboundCarrierType]
      : undefined

    const components: AttributeComponentType = {
      containerType:
        containerType && !excludedContainerTypes.includes(containerType) ? (
          <Tile
            key={containerType}
            label={t('containerType', 'Type')}
            value={t(
              getContainerTurnoverAttributeLabel(TRANS_KEY_PREFIXES.CONTAINER_TYPE, containerType),
            )}
          />
        ) : null,
      containerIsoCode: containerIsoCode ? (
        <Tile key={containerIsoCode} label={t('isoCode', 'ISO')} value={containerIsoCode} />
      ) : null,
      containerOperator: containerOperator ? (
        <Tile label={t('containerOperator', 'Operator')} value={containerOperator} />
      ) : null,
      portOfDischarge: portOfDischarge ? (
        <Tile label={t('dischargePort', 'POD')} value={portOfDischarge} />
      ) : null,
      portOfLoading: portOfLoading ? (
        <Tile label={t('loadingPort', 'POL')} value={portOfLoading} />
      ) : null,
      grossWeight: grossWeight ? (
        <Tile label={t('grossWeight', 'Weight')} value={`${grossWeight.toLocaleString()} kg`} />
      ) : null,
      isEmpty: (
        <>
          {isEmpty !== isEmptyContainerCargoState && (
            <Tile
              label={
                outboundOrderId
                  ? t('outboundCargoStatus', 'Outbound cargo status ')
                  : t('inboundCargoStatus', 'Inbound cargo status ')
              }
              value={isEmpty ? t('empty', 'Empty') : t('full', 'Full')}
            />
          )}
          <Tile
            label={t('cargoStatus', 'Cargo status ')}
            value={isEmptyContainerCargoState ? t('empty', 'Empty') : t('full', 'Full')}
          />
        </>
      ),
      isDangerous: isDangerous ? (
        <Tile label={t('dangerous', 'Dangerous')} value={t('yes', 'Yes')} />
      ) : null,
      imoClasses:
        !!imoClasses && imoClasses?.length > 0 ? (
          <Tile
            label={t('imoClasses', 'IMO Classes')}
            value={imoClasses.sort((a, b) => a.localeCompare(b)).join(', ')}
          />
        ) : null,
      isDamaged: isDamaged ? (
        <Tile label={t('damaged', 'Damaged')} value={t('yes', 'Yes')} />
      ) : null,
      isRestow: isRestow ? <Tile label={t('restow', 'Restow')} value={t('yes', 'Yes')} /> : null,
      size: size ? <Tile label={t('size', 'Size')} value={`${size}ft`} /> : null,
      containerHeight: containerHeight ? (
        <Tile
          label={t('containerHeight', 'Height')}
          value={t(
            getContainerTurnoverAttributeLabel(
              TRANS_KEY_PREFIXES.CONTAINER_HEIGHT,
              containerHeight,
            ),
          )}
        />
      ) : null,
      inboundCarrierLabel: inboundCarrierLabel ? (
        <TileWithIcon
          icon={
            <ArrowOutward fontSize='small' sx={{ transform: 'rotate(90deg)', height: '18px' }} />
          }
          label={t('inboundCarrierType', 'Inbound')}
          value={inboundCarrierLabel}
        />
      ) : null,
      inboundCarrierVisitName:
        inboundCarriers.length > 0 ? (
          <TileWithIcon
            icon={
              <IconButton
                size='small'
                variant='text'
                sx={{ height: '18px' }}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                  handleCarrierVisitClick(e, item.inboundCarrierVisitId)
                }
              >
                <InfoIcon fontSize='small' sx={{ height: '18px' }} />
              </IconButton>
            }
            label={
              item.inboundCarrierType === 'Vessel' ? t('vessel', 'Vessel') : t('train', 'Train')
            }
            value={inboundCarriers.map(x => x.name).join()}
            iconAfterLabel
          />
        ) : null,
      outboundCarrierLabel: outboundCarrierLabel ? (
        <TileWithIcon
          icon={
            <ArrowOutward fontSize='small' sx={{ transform: 'rotate(-90deg)', height: '18px' }} />
          }
          label={t('outboundCarrierType', 'Outbound')}
          value={outboundCarrierLabel}
        />
      ) : null,
      outboundCarrierVisitName:
        outboundCarriers.length > 0 && variation === 'standard' ? (
          <TileWithIcon
            icon={
              <IconButton
                size='small'
                variant='text'
                sx={{ height: '18px' }}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                  handleCarrierVisitClick(e, item.outboundCarrierVisitId)
                }
              >
                <InfoIcon fontSize='small' sx={{ height: '18px' }} />
              </IconButton>
            }
            label={
              item.outboundCarrierType === 'Vessel' ? t('vessel', 'Vessel') : t('train', 'Train')
            }
            value={outboundCarriers.map(x => x.name).join()}
            iconAfterLabel
          />
        ) : outboundCarriers.length > 0 && variation === 'compact' ? (
          <Tile
            key={outboundCarriers.map(x => x.name).join()}
            label={
              item.outboundCarrierType === 'Vessel' ? t('vessel', 'Vessel') : t('train', 'Train')
            }
            value={outboundCarriers.map(x => x.name).join()}
          />
        ) : null,
      isReefer: isReefer ? <Tile label={t('reefer', 'Reefer')} value={t('yes', 'Yes')} /> : null,
      referenceNumber: referenceNumber ? (
        <Tile label={t('refNumber', 'Ref')} value={referenceNumber} />
      ) : null,
      consignee: consignee ? <Tile label={t('consignee', 'Consignee')} value={consignee} /> : null,
    }

    return components
  }, [item, t, variation, carrierVisitStore])

  const attributeComponentsToShow = useMemo(() => {
    return variationAttributeKeys[variation]
      .map(key => {
        const component = attributeComponents[key]
        return component ? cloneElement(component, { key }) : null
      })
      .filter(Boolean)
  }, [variationAttributeKeys, variation, attributeComponents])

  return (
    <Box>
      <Stack direction='row' spacing={1} sx={{ flexWrap: 'wrap', gap: 1 }}>
        {attributeComponentsToShow}
      </Stack>

      <Popover
        id={'carrierVisit'}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Card sx={{ p: 2 }}>
          <Typography variant='body2'>
            {carrierVisit?.etd
              ? `ETD: ${carrierVisitStore.getArrivalTimeToString(carrierVisit.etd)}`
              : carrierVisit?.ata
                ? `ATA: ${carrierVisitStore.getArrivalTimeToString(carrierVisit.ata)}`
                : `ETA: ${carrierVisitStore.getArrivalTimeToString(carrierVisit?.eta)}`}
          </Typography>
        </Card>
      </Popover>
    </Box>
  )
}
