import { createSvgIcon } from '@mui/material'

export const MapIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      opacity='0.48'
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.25 6.375C13.25 3.9625 15.2125 2 17.625 2C20.0375 2 22 3.9625 22 6.375C22 8.62 18.49 12.5962 18.09 13.0425C17.9713 13.1737 17.8025 13.25 17.625 13.25C17.4475 13.25 17.2787 13.1737 17.16 13.0425C16.76 12.5962 13.25 8.62 13.25 6.375ZM15.75 6.375C15.75 7.41 16.59 8.25 17.625 8.25C18.66 8.25 19.5 7.41 19.5 6.375C19.5 5.34 18.66 4.5 17.625 4.5C16.59 4.5 15.75 5.34 15.75 6.375Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.3925 9.34249C2.15625 9.43749 2 9.66749 2 9.92374V21.375C2 21.5825 2.10375 21.7762 2.275 21.8925C2.38 21.9625 2.50125 22 2.625 22C2.70375 22 2.7825 21.985 2.8575 21.955L7.62147 20.049C8.00108 19.8971 8.25 19.5294 8.25 19.1205V8.47691C8.25 7.76948 7.53553 7.28572 6.87868 7.5484L2.3925 9.34249ZM19.02 13.8775C18.665 14.2725 18.1562 14.5 17.625 14.5C17.0938 14.5 16.585 14.2725 16.23 13.8775C16.1025 13.7362 15.9388 13.55 15.75 13.3287V20.5231C15.75 21.2305 16.4645 21.7143 17.1213 21.4516L21.6075 19.6575C21.845 19.5637 22 19.3325 22 19.0775V9.88874C20.9762 11.6225 19.6075 13.2225 19.02 13.8775ZM10.8714 7.54855L12.3938 8.15749C12.8675 9.38749 13.7013 10.6837 14.5 11.76V20.3205C14.5 21.0279 13.7855 21.5117 13.1286 21.2489L10.1286 20.0489C9.74895 19.8971 9.5 19.5294 9.5 19.1205V8.47702C9.5 7.76956 10.2145 7.2858 10.8714 7.54855Z'
      fill='currentColor'
    />
  </svg>,
  'MapIcon',
)
