import { Alert, AlertColor, AlertTitle } from '@mui/material'
import { FC, ReactNode } from 'react'

interface IProps {
  message: string
  severity: AlertColor
  title?: string
  action?: ReactNode
  margin?: string
  testId?: string
}

export const CustomAlert: FC<IProps> = ({ message, title, severity, action, margin, testId }) => {
  return (
    <Alert
      data-cy={testId}
      sx={{ width: '100%', display: 'flex', alignItems: 'center', margin: margin }}
      severity={severity}
      action={action}
    >
      {title && <AlertTitle>{title}</AlertTitle>}
      {message}
    </Alert>
  )
}
