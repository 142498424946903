import { FC } from 'react'

export const IconRestowSvg: FC<{ zoom?: number; size?: number }> = ({ zoom, size }) => {
  return (
    <svg
      width={size ?? '24'}
      height={size ?? '24'}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ transform: `scale(${(zoom ?? 100) / 100})` }}
    >
      <rect width='24' height='24' rx='4' fill='#637381' />
      <rect x='0.5' y='0.5' width='23' height='23' rx='3.5' stroke='#919EAB' strokeOpacity='0.24' />
      <path
        d='M16.0263 15.9707H14.2083L12.7983 12.6287H11.0403V15.9707H9.29427V7.2947H12.9543C13.6463 7.2947 14.2123 7.4047 14.6523 7.6247C15.0923 7.8447 15.4163 8.1507 15.6243 8.5427C15.8323 8.9307 15.9363 9.3787 15.9363 9.8867C15.9363 10.3427 15.8643 10.7287 15.7203 11.0447C15.5803 11.3567 15.3943 11.6167 15.1623 11.8247C14.9343 12.0327 14.6843 12.2027 14.4123 12.3347L16.0263 15.9707ZM12.6423 11.2787C13.1103 11.2787 13.4803 11.1527 13.7523 10.9007C14.0283 10.6487 14.1663 10.3247 14.1663 9.9287C14.1663 9.5287 14.0343 9.2127 13.7703 8.9807C13.5063 8.7487 13.1723 8.6327 12.7683 8.6327H11.0403V11.2787H12.6423Z'
        fill='white'
      />
    </svg>
  )
}

export default IconRestowSvg
