import { DateTimePicker, DateTimePickerProps, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { Controller, FieldValues, FormState, UseControllerProps } from 'react-hook-form'

type FieldProps = Pick<DateTimePickerProps<AdapterDayjs>, 'disabled'>

interface Props<T extends FieldValues> extends UseControllerProps<T>, FieldProps {
  formState: FormState<T>
  label: string
  onChange?: (value?: Date) => Promise<void> | void
}

export const ControlledDateTimePicker = <T extends FieldValues>({
  name,
  control,
  label,
  disabled,
  formState: { errors },
  onChange,
}: Props<T>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            label={label}
            value={dayjs(field.value)}
            slotProps={{
              textField: {
                helperText: errors[name]?.message as string,
                error: !!errors[name],
                fullWidth: true,
              },
            }}
            disabled={disabled}
            onChange={newValue => {
              if (onChange) {
                onChange(newValue?.toDate())
              }
              field.onChange(newValue)
            }}
          />
        </LocalizationProvider>
      )}
    />
  )
}
