import {
  CarrierType,
  OperationType,
  WorkInstructionDto,
  WorkInstructionsApi,
} from '@operations/app/api'
import { CarrierVisitRefreshEventPayload } from '@operations/app/api/signalRDtos/carrierVisitRefreshEventPayload'
import { CarrierVisitWorkInstructionUpsertedPayload } from '@operations/app/api/signalRDtos/carrierVisitWorkInstructionUpsertedPayload'
import { WorkInstructionConfirmedEventPayload } from '@operations/app/api/signalRDtos/workInstructionConfirmedEventPayload'
import { WorkInstructionDeletedEventPayload } from '@operations/app/api/signalRDtos/workInstructionDeletedEventPayload'
import { createApiClient } from '@operations/app/http-client'
import { EventTypes } from '@operations/messages/eventsTypes'
import { IEvent, IMessageBus } from '@operations/messages/messageBus'
import { AppStore } from '@tom-ui/utils'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'

export class CraneOperatorNoGeometryUIStore {
  loadingSignalR = false

  selectedVesselVisitId?: number
  selectedEquipmentId?: number
  selectedOperationType?: OperationType = OperationType.Inbound

  workInstructions?: WorkInstructionDto[]

  constructor(
    private messageBus: IMessageBus,
    private appStore: AppStore,
  ) {
    makeObservable(this, {
      selectedVesselVisitId: observable,
      selectedEquipmentId: observable,
      selectedOperationType: observable,

      workInstructions: observable,

      filteredWorkInstructions: computed,

      setSelectedVesselVisitId: action,
      setSelectedEquipmentId: action,
      setSelectedOperationType: action,

      loadWorkInstruction: action,
    })

    this.messageBus.subscribeEvent(
      EventTypes.CarrierVisitRefresh,
      this.reloadVesselGeometryBySignalR,
    )

    this.messageBus.subscribeEvent(
      EventTypes.CarrierVisitWorkInstructionUpserted,
      async (res: IEvent<CarrierVisitWorkInstructionUpsertedPayload>) => {
        if (res.payload?.carrierVisitId === this.selectedVesselVisitId) await this.reloadVisit()
      },
    )

    this.messageBus.subscribeEvent(EventTypes.WorkInstructionDeleted, this.deleteWorkInstructions)

    this.messageBus.subscribeEvent<WorkInstructionConfirmedEventPayload>(
      EventTypes.WorkInstructionConfirmed,
      this.confirmWorkInstruction,
    )
  }

  public get filteredWorkInstructions() {
    return this.workInstructions?.filter(
      x => !x.isConfirmed && x.operationType === this.selectedOperationType,
    )
  }

  setSelectedVesselVisitId(id?: number): void {
    this.selectedVesselVisitId = id
  }

  setSelectedEquipmentId(id?: number): void {
    this.selectedEquipmentId = id
  }

  setSelectedOperationType(operationType?: OperationType): void {
    this.selectedOperationType = operationType
  }

  loadWorkInstruction = async () => {
    if (!this.selectedVesselVisitId) return
    const { data } = await createApiClient(WorkInstructionsApi).get(
      this.selectedVesselVisitId,
      this.selectedEquipmentId,
    )
    runInAction(() => {
      this.workInstructions = data
    })
  }

  reloadVesselGeometryBySignalR = async (res: IEvent<CarrierVisitRefreshEventPayload>) => {
    if (
      res.payload?.carrierVisits?.length &&
      !res.payload?.carrierVisits.find(
        c =>
          c.carrierType === CarrierType.Vessel && c.carrierVisitId === this.selectedVesselVisitId,
      )
    )
      return

    await this.reloadVisit()
  }

  deleteWorkInstructions = async (res: IEvent<WorkInstructionDeletedEventPayload>) => {
    if (
      this.selectedVesselVisitId &&
      res.payload?.carrierVisitIds.includes(this.selectedVesselVisitId)
    ) {
      await this.reloadVisit()
    }
  }

  confirmWorkInstruction = (res: IEvent<WorkInstructionConfirmedEventPayload>) => {
    const foundWorkInstruction = this.workInstructions?.find(x => x.id === res.payload?.id)

    if (foundWorkInstruction) {
      runInAction(() => {
        foundWorkInstruction.isConfirmed = true
      })
    }
  }

  private async reloadVisit() {
    if (!this.loadingSignalR) {
      this.loadingSignalR = true

      await this.appStore.triggerReloadBySignalR(
        this.loadWorkInstruction,
        '/operatorViews/Crane/NoGeometryView/',
      )

      this.loadingSignalR = false
    }
  }
}
