import { createSvgIcon } from '@mui/material'

export const RTGIcon = createSvgIcon(
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4.63547 23.9823H1.58782C1.27364 23.9823 1.01953 23.8036 1.01953 23.5827V7.90271C1.01953 7.68182 1.27364 7.50317 1.58782 7.50317H4.63547C4.94965 7.50317 5.20376 7.68182 5.20376 7.90271C5.20376 8.12359 4.94965 8.30224 4.63547 8.30224H2.15611V23.1838H4.06718V10.1949C4.06718 9.97401 4.32129 9.79536 4.63547 9.79536C4.94965 9.79536 5.20376 9.97401 5.20376 10.1949V23.5827C5.20376 23.8036 4.94965 23.9823 4.63547 23.9823Z'
      fill='currentColor'
    />
    <path
      d='M10.4119 6.11806H5.30649C5.18455 6.11806 5.08594 5.86395 5.08594 5.54977V3.10369C5.08594 2.78951 5.18455 2.5354 5.30649 2.5354H9.70676C9.8287 2.5354 9.92731 2.78951 9.92731 3.10369C9.92731 3.41787 9.8287 3.67198 9.70676 3.67198H5.52704V4.98148H10.1913V3.10369C10.1913 2.78951 10.29 2.5354 10.4119 2.5354C10.5338 2.5354 10.6324 2.78951 10.6324 3.10369V5.54977C10.6324 5.86314 10.5338 6.11806 10.4119 6.11806Z'
      fill='currentColor'
    />
    <path
      d='M18.3878 6.11336H13.6799C13.5675 6.11336 13.4766 5.86 13.4766 5.54675V3.10787C13.4766 2.79462 13.5675 2.54126 13.6799 2.54126H17.7376C17.85 2.54126 17.9409 2.79462 17.9409 3.10787C17.9409 3.42113 17.85 3.67449 17.7376 3.67449H13.8833V4.98013H18.1844V3.10787C18.1844 2.79462 18.2753 2.54126 18.3878 2.54126C18.5002 2.54126 18.5912 2.79462 18.5912 3.10787V5.54675C18.5912 5.85919 18.5002 6.11336 18.3878 6.11336Z'
      fill='currentColor'
    />
    <path
      d='M11.8495 11.9007C11.5354 11.9007 11.2812 11.6465 11.2812 11.3324V9.10711C11.2812 8.79292 11.5354 8.53882 11.8495 8.53882C12.1637 8.53882 12.4178 8.79292 12.4178 9.10711V11.3324C12.4178 11.6465 12.1637 11.9007 11.8495 11.9007Z'
      fill='currentColor'
    />
    <path
      d='M5.66016 8.64043H0.56829C0.254107 8.64043 0 8.38632 0 8.07214V0.568289C0 0.254106 0.254107 0 0.56829 0H5.66016C5.97434 0 6.22845 0.254106 6.22845 0.568289V8.07214C6.22845 8.38551 5.97434 8.64043 5.66016 8.64043ZM1.13658 7.50385H5.09187V1.13658H1.13658V7.50385Z'
      fill='currentColor'
    />
    <path
      d='M19.0896 24H22.4513C22.7978 24 23.0781 23.8198 23.0781 23.5971V7.78736C23.0781 7.56465 22.7978 7.38452 22.4513 7.38452H19.0896C18.743 7.38452 18.4627 7.56465 18.4627 7.78736C18.4627 8.01007 18.743 8.1902 19.0896 8.1902H21.8244V23.1948H19.7164V10.0985C19.7164 9.8758 19.4361 9.69567 19.0896 9.69567C18.743 9.69567 18.4627 9.8758 18.4627 10.0985V23.5971C18.4627 23.8198 18.743 24 19.0896 24Z'
      fill='currentColor'
    />
    <path
      d='M18.1284 8.66533H23.4105C23.7364 8.66533 24 8.41122 24 8.09704V0.593191C24 0.279009 23.7364 0.0249023 23.4105 0.0249023H18.1284C17.8025 0.0249023 17.5389 0.279009 17.5389 0.593191V8.09704C17.5389 8.41041 17.8025 8.66533 18.1284 8.66533ZM22.821 7.52875H18.718V1.16148H22.821V7.52875Z'
      fill='currentColor'
    />
    <path
      d='M13.2049 16.4519H10.577C10.374 16.4519 10.1865 16.3439 10.085 16.1678L8.77142 13.8922C8.66994 13.716 8.66994 13.5001 8.77142 13.3239L10.085 11.0483C10.1865 10.8721 10.374 10.7642 10.577 10.7642H13.2049C13.4078 10.7642 13.5954 10.8721 13.6969 11.0483L15.0112 13.3239C15.1127 13.5001 15.1127 13.7168 15.0112 13.8922L13.6969 16.1678C13.5954 16.3439 13.4078 16.4519 13.2049 16.4519ZM10.9049 15.3153H12.8769L13.8633 13.608L12.8769 11.9007H10.9049L9.91936 13.608L10.9049 15.3153Z'
      fill='currentColor'
    />
    <path
      d='M13.6556 9.67544H10.1152C9.80098 9.67544 9.54688 9.42134 9.54688 9.10715V2.25359C9.54688 1.93941 9.80098 1.6853 10.1152 1.6853H13.6556C13.9698 1.6853 14.2239 1.93941 14.2239 2.25359V9.10715C14.2239 9.42134 13.9698 9.67544 13.6556 9.67544ZM10.6835 8.53887H13.0873V2.82188H10.6835V8.53887Z'
      fill='currentColor'
    />
  </svg>,
  'RTG',
)
