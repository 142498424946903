import { v4 as uuidv4 } from 'uuid'

export interface WeightClass {
  id: string
  name: string
  min?: number
  max?: number | null
  maxFieldIsInfinite: boolean
}

export interface WeightClassFormProfile {
  weightClasses: WeightClass[]
}

export const defaultWeightClass: WeightClass = {
  id: uuidv4(),
  name: 'New Class',
  maxFieldIsInfinite: false,
  min: '' as any,
  max: '' as any,
}

export const defaultValues: WeightClassFormProfile = {
  weightClasses: [defaultWeightClass],
}
