import { Alert, Card, CardContent, Stack, Typography } from '@mui/material'
import { EquipmentRadioOption } from '@operations/app/models/operator-pages'
import { EquipmentTypeIcon } from '@operations/features/equipmentPlanning/components/EquipmentTypeIcon/EquipmentTypeIcon'
import { useTranslate } from '@tolgee/react'
import { RadioOffIcon, RadioOnIcon, useMinimalsTheme } from '@tom-ui/ui'

interface Props {
  value?: number
  equipments: EquipmentRadioOption[]
  hasError?: boolean
  onEquipmentChange: (id: number) => void
}
export const EquipmentRadio = ({ value, equipments, hasError, onEquipmentChange }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const getColor = (id: number) => (value === id ? 'primary' : undefined)

  const getColorIncludingError = (id: number) =>
    hasError
      ? theme.palette.error.main
      : value === id
        ? theme.palette.primary.dark
        : theme.palette.grey[300]

  return (
    <Stack gap={theme => theme.customSpacing.xs}>
      {hasError && (
        <Alert severity='error'>
          {t(
            'pleaseAssignATransportFromTheFollowingList',
            'Please assign a transport from the following list',
          )}
        </Alert>
      )}
      {equipments.map(equipment => (
        <Card
          key={equipment.id}
          variant='outlined'
          sx={{
            boxShadow: 'none',
            border: `1px solid ${getColorIncludingError(equipment.id)}`,
            backgroundColor:
              value === equipment.id ? theme.palette.primary.lighter : theme.palette.common.white,
          }}
          onClick={() => onEquipmentChange(equipment.id)}
          data-cy={`equipment-assignable-for-job-${equipment.title.toLowerCase()}`}
        >
          <CardContent>
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: theme => theme.customSpacing.m,
              }}
            >
              {value === equipment.id ? <RadioOnIcon color='primary' /> : <RadioOffIcon />}
              <EquipmentTypeIcon
                type={equipment.type}
                backgroundColorTransparent
                color={getColor(equipment.id)}
              />
              <Stack>
                <Typography variant='subtitle2' color={getColor(equipment.id)}>
                  {equipment.title}
                </Typography>
                {equipment.subtitle && (
                  <Typography variant='body2' color={getColor(equipment.id)}>
                    {equipment.subtitle}
                  </Typography>
                )}
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      ))}
    </Stack>
  )
}
