import { createSvgIcon } from '@mui/material'

export const JobsIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2 5V4C2 3.45 2.45 3 3 3H20C20.55 3 21 3.45 21 4V5C21 5.55 20.55 6 20 6H3C2.45 6 2 5.55 2 5ZM20 8H3C2.45 8 2 8.45 2 9V15C2 15.55 2.45 16 3 16H20C20.55 16 21 15.55 21 15V9C21 8.45 20.55 8 20 8ZM3 21H20C20.55 21 21 20.55 21 20V19C21 18.45 20.55 18 20 18H3C2.45 18 2 18.45 2 19V20C2 20.55 2.45 21 3 21Z'
      fill='currentColor'
    />
  </svg>,
  'JobsIcon',
)
