import { HandlingDirection } from '@storage/app/api'
import GeneralCargoStorageTracker from '@storage/features/general-cargo-storage-tracker/GeneralCargoStorageTracker'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { ConfirmationDialog } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'

const GeneralCargoOrderReservationDialog = observer(() => {
  const { generalCargoOrderReservationUIStore } = useStores()
  const { t } = useTranslate()

  const handleOnClose = () => {
    generalCargoOrderReservationUIStore.toggleDialog()
  }

  const handleOnConfirm = () => {
    generalCargoOrderReservationUIStore.onConfirm()
  }

  return (
    <ConfirmationDialog
      maxWidth='md'
      title={t('generalCargoReservation', 'General Cargo Reservation')}
      open={generalCargoOrderReservationUIStore.isDialogOpen}
      onClose={handleOnClose}
      onConfirm={handleOnConfirm}
      primaryActionDisabled={!generalCargoOrderReservationUIStore.totalNewQuantity}
      primaryActionText={t('confirm', 'Confirm')}
      closeLabel={t('cancel', 'Cancel')}
    >
      <GeneralCargoStorageTracker
        generalCargoOrderId={generalCargoOrderReservationUIStore.dialogActiveGeneralCargoOrderId!}
        cargoItemId={generalCargoOrderReservationUIStore.dialogActiveCargoItemId}
        quantity={generalCargoOrderReservationUIStore.dialogActiveQuantity!}
        handlingDirection={HandlingDirection.Inbound}
        context='planning'
      />
    </ConfirmationDialog>
  )
})

export default GeneralCargoOrderReservationDialog
