import { ContainerDamageLocationDto } from '@admin/app/api'
import { ContainerDamageLocationStore } from '@admin/stores/ContainerDamageLocationStore'
import { action, computed, makeObservable, observable } from 'mobx'

export class ContainerDamageLocationListUIStore {
  searchField = ''

  constructor(private containerDamageLocationStore: ContainerDamageLocationStore) {
    makeObservable(this, {
      searchField: observable,

      containerDamageLocations: computed,

      setSearchField: action,
    })
  }

  public setSearchField(newSearchValue: string) {
    if (this.searchField !== newSearchValue) {
      this.searchField = newSearchValue
    }
  }

  public get containerDamageLocations(): ContainerDamageLocationDto[] {
    return [...this.containerDamageLocationStore.items]
      .sort(i => i.id)
      .filter(x => this.doesContainerDamageLocationHaveName(x, this.searchField))
  }

  private doesContainerDamageLocationHaveName(
    containerDamageLocation: ContainerDamageLocationDto,
    name?: string,
  ) {
    return !name || containerDamageLocation.name.toLowerCase().includes(name.toLowerCase())
  }
}
