import { TextField } from '@mui/material'
import { JobDto } from '@operations/app/api'
import { useTranslate } from '@tolgee/react'

interface Props {
  job: JobDto
}
export const DoorDirectionInfo = ({ job }: Props) => {
  const { t } = useTranslate()

  return (
    job.order?.doorDirection && (
      <TextField
        label={t('doorDirection', 'Door direction')}
        value={job.order?.doorDirection}
        disabled
        fullWidth
      />
    )
  )
}
