import { FormProps, FormType } from '@storage/app/models'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from '@mui/material'
import { useTranslate } from '@tolgee/react'
import React from 'react'

interface Props {
  form: React.ReactElement<FormProps>
  open: boolean
  formType?: FormType
  dialogTitle?: string
  entityTitle?: string
  onClose: () => void
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  confirmButtonLabel?: string
  isLoading?: boolean
  disabled?: boolean
}

export const DialogFormContainer = ({
  form,
  open,
  formType,
  entityTitle,
  dialogTitle,
  size,
  onClose,
  confirmButtonLabel,
  isLoading,
  disabled,
}: Props) => {
  if (!form.props.id)
    throw new Error(
      'For DialogFormContainer to work properly id property must be set for the inner form',
    )

  const { t } = useTranslate()

  return (
    <Dialog onClose={onClose} open={open} maxWidth={size} fullWidth>
      <DialogTitle>{dialogTitle ?? `${formType} ${entityTitle?.toLowerCase()}`}</DialogTitle>

      <DialogContent>{form}</DialogContent>

      <Divider />
      <DialogActions>
        {/* TODO: Create button component with all possible props */}
        <Button
          disabled={disabled || isLoading}
          startIcon={isLoading && <CircularProgress size={20} color='inherit' />}
          variant='contained'
          color='primary'
          type='submit'
          form={form.props.id}
        >
          {confirmButtonLabel ?? t('confirm', 'Confirm')}
          {isLoading && '...'}
        </Button>
        <Button variant='text' color='inherit' onClick={onClose} disabled={isLoading}>
          {t('cancel', 'Cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
