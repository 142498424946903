import { SelectOption } from '@operations/app/models'
import { FilterChip } from '@operations/components/filter/filter.model'
import { EquipmentStore } from '@operations/stores/EquipmentStore'
import { YardBlockStore } from '@operations/stores/YardBlockStore'
import _ from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import { EquipmentFilter, defaultValues } from '../components/filter/equipment-filter.model'

export class EquipmentFiltersUIStore {
  isOpen = false
  filter?: EquipmentFilter
  equipmentName?: string

  constructor(
    private equipmentStore: EquipmentStore,
    private yardBlockStore: YardBlockStore,
  ) {
    makeObservable(this, {
      isOpen: observable,
      filter: observable,
      equipmentName: observable,
      toggle: action,
      setFilter: action,
      setEquipmentName: action,
      removeFilter: action,
      isFilterFilled: computed,
      areFiltersAndSearchEmpty: computed,
      yardBlocks: computed,
      filterChips: computed,
    })
  }

  public setFilter(filter?: EquipmentFilter) {
    if (!filter) {
      this.filter = defaultValues
    } else {
      this.filter = filter
    }
  }

  public setEquipmentName(equipment?: string) {
    if (this.equipmentName !== equipment) {
      this.equipmentName = equipment
    }
  }

  public toggle(): void {
    this.isOpen = !this.isOpen
  }

  public removeFilter(filter: FilterChip): void {
    if (!this.filter) {
      return
    }

    const key = filter.key as keyof EquipmentFilter
    const filterValue = this.filter[key]

    if (filterValue !== undefined && filterValue !== null) {
      if (Array.isArray(filterValue)) {
        this.filter[key] = (filterValue as any).filter((x: string) => x !== filter.value)
      } else if (typeof filterValue === 'boolean') {
        this.filter[key] = false as any
      } else {
        this.filter[key] = null
      }
    }
  }

  public get filterChips() {
    if (!this.filter) {
      return []
    }

    const filterChips: FilterChip[] = []

    for (const propName in this.filter) {
      const key = propName as keyof EquipmentFilter
      const filterValue = this.filter[key]

      if (!filterValue) continue

      switch (key) {
        case 'equipmentTypes':
          ;(filterValue as string[]).forEach(value =>
            filterChips.push({ key, value, label: value }),
          )
          break

        case 'yardBlockIds':
          this.yardBlockStore.items
            .filter(x => (filterValue as string[]).includes(x.id))
            .forEach(yardBlock =>
              filterChips.push({ key, value: yardBlock.id, label: yardBlock.name }),
            )
          break

        case 'isOnMaintenance':
          filterChips.push({
            key,
            value: filterValue + '',
            label: 'onMaintenance',
          })
          break
      }
    }

    return filterChips
  }

  public get yardBlocks(): SelectOption[] {
    return _(this.equipmentStore.items.filter(x => x.yardBlocks?.length).flatMap(x => x.yardBlocks))
      .uniqBy(x => x!.id)
      .map(x => ({ label: x?.name, value: x?.id }) as SelectOption)
      .value()
  }

  public get areFiltersAndSearchEmpty() {
    return !this.isFilterFilled && !this.equipmentName
  }

  public get isFilterFilled(): boolean {
    return (
      !!this.filter &&
      (!!this.filter.equipmentTypes?.length ||
        !!this.filter.isOnMaintenance ||
        !!this.filter.yardBlockIds?.length)
    )
  }
}
