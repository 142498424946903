/* eslint-disable react/prop-types */
import { Chip, IconButton, Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import { useTranslate } from '@tolgee/react'
import { BinIcon, EditIcon, useMinimalsTheme } from '@tom-ui/ui'
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import ChipListContainer from './ChipListContainer'
import { DangerousGoodsDefinitionDto } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'

const DangerousGoodsDefinitionsTable = observer(() => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const { dangerousGoodsDefinitionTableViewStore } = usePlanningStore()
  const formDialogUtilStore = dangerousGoodsDefinitionTableViewStore.formDialogUtilStore

  useEffect(() => {
    dangerousGoodsDefinitionTableViewStore.loadData()
  }, [dangerousGoodsDefinitionTableViewStore])

  const overwriteMinimalTableHeadCellStyle = {
    sx: {
      backgroundColor: theme.palette.background.neutral,
    },
  }

  const columns = useMemo<MRT_ColumnDef<DangerousGoodsDefinitionDto>[]>(
    () => [
      {
        accessorKey: 'unNumber',
        header: t('unNumber', 'UN Number'),
      },
      {
        accessorKey: 'material',
        header: t('material', 'Material'),
      },
      {
        accessorKey: 'primaryImoClass',
        header: t('primaryImoClass', 'Primary IMO Class'),
        Cell: ({ row }) =>
          row.original.primaryImoClass ? <Chip label={row.original.primaryImoClass} /> : <></>,
      },
      {
        accessorKey: 'secondaryImoClasses',
        header: t('secondaryImoClasses', 'Secondary IMO Classes'),
        Cell: ({ row }) =>
          row.original.secondaryImoClasses ? (
            <ChipListContainer items={row.original.secondaryImoClasses} />
          ) : (
            <></>
          ),
      },
      {
        accessorKey: 'handlingNotes',
        header: t('handlingNotes', 'Handling Notes'),
        minSize: 500,
      },
    ],
    [t],
  )

  return (
    <MaterialReactTable
      state={{
        pagination: dangerousGoodsDefinitionTableViewStore.pagination,
      }}
      rowCount={dangerousGoodsDefinitionTableViewStore.totalCount}
      muiTableHeadCellProps={overwriteMinimalTableHeadCellStyle}
      columns={columns}
      enableSorting={false}
      data={dangerousGoodsDefinitionTableViewStore.pageItems}
      autoResetPageIndex={false}
      enableColumnActions={false}
      enableTopToolbar={false}
      manualPagination
      onPaginationChange={dangerousGoodsDefinitionTableViewStore.setPagination}
      enableRowActions
      renderRowActions={({ row }) => [
        <Box key={0} sx={{ minWidth: '120px' }}>
          <Tooltip title={t('edit', 'Edit')}>
            <IconButton
              aria-label='edit'
              onClick={() => formDialogUtilStore.toggleDialog('Edit', row.original.id)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title={t('remove', 'Remove')}>
            <IconButton
              aria-label='delete'
              onClick={() => formDialogUtilStore.toggleDialog('Delete', row.original.id)}
            >
              <BinIcon />
            </IconButton>
          </Tooltip>
        </Box>,
      ]}
      positionActionsColumn='last'
      displayColumnDefOptions={{
        'mrt-row-actions': {
          header: '',
          muiTableBodyCellProps: {
            align: 'right',
          },
        },
      }}
    />
  )
})

export default DangerousGoodsDefinitionsTable
