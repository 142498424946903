import { EquipmentsApi } from '@operations/app/api'
import { createApiClient } from '@operations/app/http-client'
import { EquipmentStore } from '@operations/stores/EquipmentStore'
import { action, makeObservable, observable } from 'mobx'

export class TallymanOperationsUIStore {
  selectedEquipmentId?: number

  constructor(private equipmentStore: EquipmentStore) {
    makeObservable(this, {
      selectedEquipmentId: observable,
      selectEquipment: action,
      confirmSelectedEquipment: action,
    })
  }

  public selectEquipment(id?: number): void {
    this.selectedEquipmentId = id
  }

  public async confirmSelectedEquipment(carrierVisitId: number, orderId: number) {
    if (!this.selectedEquipmentId) return //TODO: what happens then?

    await createApiClient(EquipmentsApi).assignToJobByTallyman({
      equipmentId: this.selectedEquipmentId,
      carrierVisitId: carrierVisitId,
      orderId: orderId,
    })
  }
}
