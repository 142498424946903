import { Box, SxProps, Typography } from '@mui/material'
import { SelectOption } from '@storage/app/models'
import { ControlledAutocomplete } from '@storage/components/form-controls/ControlledAutocomplete'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { ContainerTurnoversFilterFormProfile } from '../container-turnovers-filter-form.profile'

interface Props {
  sx?: SxProps
}

export const GrossWeightControls = ({ sx }: Props) => {
  const { t } = useTranslate()
  const { control, formState } = useFormContext<ContainerTurnoversFilterFormProfile>()
  const { weightClassContainerUIStore } = useStores()
  const [weightClassesOptions, setweightClassesOptions] = useState<SelectOption[]>([])

  useEffect(() => {
    weightClassContainerUIStore.loadWeightClasses()
  }, [weightClassContainerUIStore])

  useEffect(() => {
    const transformWeightClassesToSelectionOptions = (): SelectOption[] => {
      return weightClassContainerUIStore.weightClasses.map((weightClass): SelectOption => {
        const maxWeight =
          weightClass.maxWeight !== null && weightClass.maxWeight !== undefined
            ? weightClass.maxWeight.toString()
            : '∞'
        return {
          label: `${weightClass.name}: ${weightClass.minWeight.toString()} to ${maxWeight}`,
          value: weightClass.name,
        }
      })
    }

    setweightClassesOptions(transformWeightClassesToSelectionOptions())
  }, [weightClassContainerUIStore.weightClasses])

  return (
    <Box sx={sx}>
      <Typography variant='h6' mb={1}>
        {t('weightClasses', 'Weight Classes')}
      </Typography>

      <ControlledAutocomplete
        multiple
        formState={formState}
        control={control}
        label={t('weightClasses', 'Weight Classes')}
        name='weightClasses'
        options={weightClassesOptions}
        useValueAsSelectedLabel={true}
      />
    </Box>
  )
}
