import { MoreVert } from '@mui/icons-material'
import { Box, Menu, MenuItem } from '@mui/material'
import { OrderStatus } from '@planning/app/api'
import { ValidateOutboundOrderFunc } from '@planning/stores/gateControl/ValidateOutboundDto'
import { useTranslate } from '@tolgee/react'
import { IconButton, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { GeneralCargoOrderCard } from './GeneralCargoOrderCard'
import { IInspectContainerFormData } from './InspectContainer'

interface IProps {
  order: IInspectContainerFormData
  highlight?: string
  damageAmount?: number
  onEdit?: (order: IInspectContainerFormData) => void
  onRemove?: (order: IInspectContainerFormData) => void
  validateOutboundRequest?: ValidateOutboundOrderFunc
}

export const GateInGeneralCargoOrderListItem = observer(
  ({ order, damageAmount, highlight, onEdit, onRemove, validateOutboundRequest }: IProps) => {
    const theme = useMinimalsTheme()
    const { t } = useTranslate()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const handleClose = () => {
      setAnchorEl(null)
    }

    return (
      <Box mb={theme.customSpacing.m}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ fontSize: '1rem !important', width: '95%' }}>
            <GeneralCargoOrderCard
              key={`gate-control-general-cargo-item-${order.id}`}
              order={order}
              damageAmount={damageAmount}
              highlight={highlight}
              validateOutboundRequest={validateOutboundRequest}
            />
          </Box>

          {(onEdit || onRemove) && (
            <Box>
              <IconButton
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)}
              >
                <MoreVert />
              </IconButton>

              <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {onEdit && <MenuItem onClick={() => onEdit(order)}>{t('edit', 'Edit')}</MenuItem>}
                {onRemove && order.status !== OrderStatus.Fulfilled && (
                  <MenuItem onClick={() => onRemove(order)}>{t('remove', 'Remove')}</MenuItem>
                )}
              </Menu>
            </Box>
          )}
        </Box>
      </Box>
    )
  },
)
