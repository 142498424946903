import { Build, Height, Thermostat, Warning } from '@mui/icons-material'
import { SxProps } from '@mui/material'
import { CarrierVisitDirection } from '@storage/app/api'
import { useCarrierTypeOptions } from '@storage/app/carriers/hooks'
import { HandlingDirectionIconProvider } from '@storage/app/carriers/providers'
import {
  useCargoStatusOptions,
  useContainerSizeOptions,
  useDamagedOptions,
  useDangerousOptions,
  useLocationStatusOptions,
  useReeferOptions,
} from '@storage/app/container-turnovers/hooks'
import { useContainerHeightOptions } from '@storage/app/container-turnovers/hooks/use-container-height-options.hook'
import { useContainerTypeOptions } from '@storage/app/container-turnovers/hooks/use-container-type-options.hook'
import { useRestowOptions } from '@storage/app/container-turnovers/hooks/use-restow-options.hook'
import { SelectChip, SelectChipProps } from '@storage/components/custom-controls/SelectChip'
import { useStores } from '@storage/hooks/use-stores.hook'
import {
  ContainerTurnoversFilterFormProfile,
  ContainerTurnoversFilterFormProfileKey,
} from '@storage/pages/container-turnovers/components/container-turnovers-filter-form'
import { TenantConfigStore } from '@storage/stores/tenantConfig.store'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme, WeightIcon } from '@tom-ui/ui'
import { Control, useWatch } from 'react-hook-form'

interface ContainerTurnoversQuickFilterChipsProps {
  control: Control<ContainerTurnoversFilterFormProfile, any>
  defaultValues: ContainerTurnoversFilterFormProfile
  disabledFilters?: ContainerTurnoversFilterFormProfileKey[]
  hiddenFilters?: ContainerTurnoversFilterFormProfileKey[]
  tenantConfigStore?: TenantConfigStore
}

const ContainerTurnoversQuickFilterChips = ({
  control,
  defaultValues,
  disabledFilters,
  hiddenFilters,
  tenantConfigStore,
}: ContainerTurnoversQuickFilterChipsProps) => {
  const { t } = useTranslate()
  const { carrierVisitStore } = useStores()

  const { palette } = useMinimalsTheme()

  const ICON_PROPS: SxProps = {
    color: palette.grey[600],
  }

  const inboundCarrierType = useWatch({ control, name: 'inboundCarrierType' })

  const outboundCarrierType = useWatch({
    control,
    name: 'outboundCarrierType',
  })

  const inboundCarriers =
    inboundCarrierType === 'Train'
      ? carrierVisitStore.entries.filter(x => x.discriminator === 'RailVisit')
      : carrierVisitStore.entries.filter(x => x.discriminator === 'VesselVisit')

  const outboundCarriers =
    outboundCarrierType === 'Train'
      ? carrierVisitStore.entries.filter(x => x.discriminator === 'RailVisit')
      : carrierVisitStore.entries.filter(x => x.discriminator === 'VesselVisit')

  const inboundCarrierOptions = carrierVisitStore.mapToOptions(inboundCarriers)

  const outboundCarrierOptions = carrierVisitStore.mapToOptions(outboundCarriers)

  const locationStatusOptions = useLocationStatusOptions()
  const containerSizeOptions = useContainerSizeOptions()
  const cargoStatusOptions = useCargoStatusOptions()
  const carrierTypeOptions = useCarrierTypeOptions()
  const reeferOptions = useReeferOptions()
  const damagedOptions = useDamagedOptions()
  const dangerousOptions = useDangerousOptions()
  const restowOptions = useRestowOptions()
  const containerTypeOptions = useContainerTypeOptions()
  const containerHeightOptions = useContainerHeightOptions()

  const filterConfig: SelectChipProps<ContainerTurnoversFilterFormProfile>[] = []

  if (tenantConfigStore && !tenantConfigStore.skipYardPlanning) {
    filterConfig.push({
      name: 'locationStatus',
      label: t('allocationStatus', 'Allocation status'),
      options: locationStatusOptions,
      defaultValue: defaultValues.locationStatus,
    })
  }

  filterConfig.push(
    {
      name: 'isEmpty',
      label: t('cargoStatus', 'Cargo status'),
      options: cargoStatusOptions,
      defaultValue: defaultValues.isEmpty,
    },
    {
      name: 'size',
      label: t('size', 'Size'),
      options: containerSizeOptions,
      defaultValue: defaultValues.size,
    },
    {
      name: 'containerType',
      label: t('type', 'Type'),
      options: containerTypeOptions,
      defaultValue: defaultValues.containerType,
      showOnlyWhenHasValue: true,
    },
    {
      name: 'containerHeight',
      label: t('height', 'Height'),
      options: containerHeightOptions,
      defaultValue: defaultValues.containerHeight,
      icon: <Height fontSize='small' sx={ICON_PROPS} />,
      showOnlyWhenHasValue: true,
    },
    {
      name: 'inboundCarrierType',
      label: t('inboundCarrierType', 'Inbound carrier type'),
      options: carrierTypeOptions,
      defaultValue: defaultValues.inboundCarrierType,
      icon: HandlingDirectionIconProvider.get(CarrierVisitDirection.Inbound, ICON_PROPS),
      showOnlyWhenHasValue: true,
    },
    {
      name: 'inboundCarrier',
      label: t('inboundCarrier', 'Inbound carrier'),
      options: inboundCarrierOptions,
      defaultValue: defaultValues.inboundCarrier,
      icon: HandlingDirectionIconProvider.get(CarrierVisitDirection.Inbound, ICON_PROPS),
      showOnlyWhenHasValue: true,
    },
    {
      name: 'outboundCarrierType',
      label: t('outboundCarrierType', 'Outbound carrier type'),
      options: carrierTypeOptions,
      defaultValue: defaultValues.outboundCarrierType,
      icon: HandlingDirectionIconProvider.get(CarrierVisitDirection.Outbound, ICON_PROPS),
      showOnlyWhenHasValue: true,
    },
    {
      name: 'outboundCarrier',
      label: t('outboundCarrier', 'Outbound carrier'),
      options: outboundCarrierOptions,
      defaultValue: defaultValues.outboundCarrier,
      icon: HandlingDirectionIconProvider.get(CarrierVisitDirection.Outbound, ICON_PROPS),
      showOnlyWhenHasValue: true,
    },
    {
      name: 'containerIsoCode',
      label: t('containerIsoCode', 'Container ISO Code'),
      control: control,
      defaultValue: defaultValues.containerIsoCode,
      showOnlyWhenHasValue: true,
    },
    {
      name: 'typeCode',
      label: t('typeCode', 'Type Code'),
      control: control,
      defaultValue: defaultValues.typeCode,
      showOnlyWhenHasValue: true,
    },
    {
      name: 'portOfDischarge',
      label: t('portOfDischarge', 'Port of Discharge'),
      control: control,
      defaultValue: defaultValues.portOfDischarge,
      icon: HandlingDirectionIconProvider.get(CarrierVisitDirection.Inbound, ICON_PROPS),
      showOnlyWhenHasValue: true,
    },
    {
      name: 'portOfLoading',
      label: t('portOfLoading', 'Port of Loading'),
      control: control,
      defaultValue: defaultValues.portOfLoading,
      icon: HandlingDirectionIconProvider.get(CarrierVisitDirection.Outbound, ICON_PROPS),
      showOnlyWhenHasValue: true,
    },
    {
      name: 'containerOperator',
      label: t('containerOperator', 'Container Operator'),
      control: control,
      defaultValue: defaultValues.containerOperator,
      showOnlyWhenHasValue: true,
    },
    {
      name: 'weightClasses',
      label: t('weightClasses', 'Weight classes'),
      control: control,
      defaultValue: defaultValues.weightClasses,
      showOnlyWhenHasValue: true,
      icon: <WeightIcon fontSize='small' sx={ICON_PROPS} />,
    },
    {
      name: 'isReefer',
      label: t('isReefer', 'Is Reefer'),
      options: reeferOptions,
      defaultValue: defaultValues.isReefer,
      icon: <Thermostat fontSize='small' sx={ICON_PROPS} />,
      showOnlyWhenHasValue: true,
    },
    {
      name: 'isDamaged',
      label: t('isDamaged', 'Is Damaged'),
      options: damagedOptions,
      defaultValue: defaultValues.isDamaged,
      icon: <Build fontSize='small' sx={ICON_PROPS} />,
      showOnlyWhenHasValue: true,
    },
    {
      name: 'isDangerous',
      label: t('isDangerous', 'Is Dangerous'),
      options: dangerousOptions,
      defaultValue: defaultValues.isDangerous,
      icon: <Warning fontSize='small' sx={ICON_PROPS} />,
      showOnlyWhenHasValue: true,
    },
    {
      name: 'imoClasses',
      label: t('imoClasses', 'IMO Classes'),
      control: control,
      defaultValue: defaultValues.imoClasses,
      showOnlyWhenHasValue: true,
    },
    {
      name: 'isRestow',
      label: t('isRestow', 'Is Restow'),
      options: restowOptions,
      defaultValue: defaultValues.isRestow,
      showOnlyWhenHasValue: true,
    },
  )

  return (
    <>
      {filterConfig.map(filter => {
        if (hiddenFilters?.includes(filter.name as keyof ContainerTurnoversFilterFormProfile)) {
          return null
        }

        return (
          <SelectChip
            key={filter.name}
            label={filter.label}
            options={filter.options}
            control={control}
            name={filter.name}
            defaultValue={defaultValues?.[filter.name as keyof ContainerTurnoversFilterFormProfile]}
            readonly={disabledFilters?.includes(
              filter.name as keyof ContainerTurnoversFilterFormProfile,
            )}
            icon={filter.icon}
            showOnlyWhenHasValue={filter.showOnlyWhenHasValue}
          />
        )
      })}
    </>
  )
}

export default ContainerTurnoversQuickFilterChips
