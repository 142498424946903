import { Box, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { ReactElement, useState } from 'react'

interface GeneralCargoInventoryPreviewColumnProps {
  items: ReactElement[]
  preview: number
}

export const GeneralCargoInventoryPreviewColumn = ({
  items,
  preview,
}: GeneralCargoInventoryPreviewColumnProps) => {
  const { t } = useTranslate()
  const { palette } = useMinimalsTheme()
  const [collapsed, setCollapsed] = useState<boolean>(true)

  const showItem = (index: number) => {
    return !collapsed || index < preview
  }

  const showMoreOrLess = () => {
    if (items.length <= preview) return null

    return collapsed ? t('showMore', 'Show more') : t('showLess', 'show less')
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.2rem' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '0.2rem',
          flexWrap: 'wrap',
        }}
      >
        {items.map((item, index) => showItem(index) && item)}
      </Box>
      <Typography
        variant='subtitle2'
        onClick={() => setCollapsed(!collapsed)}
        color={palette.grey[600]}
        sx={{ display: 'flex', marginTop: 1, justifyContent: 'flex-start', cursor: 'pointer' }}
      >
        {showMoreOrLess()}
      </Typography>
    </Box>
  )
}
