import { createSvgIcon } from '@mui/material'

export const CraneIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9 1C7.89543 1 7 1.89543 7 3V6L6 6C6 4.89543 5.10457 4 4 4L3 4C1.89543 4 1 4.89543 1 6V10C1 11.1046 1.89543 12 3 12H4C5.10457 12 6 11.1046 6 10H7V21C7 22.1046 7.89543 23 9 23C10.1046 23 11 22.1046 11 21V10H16V13H15C13.8954 13 13 13.8954 13 15V16C13 17.1046 13.8954 18 15 18H19C20.1046 18 21 17.1046 21 16V15C21 13.8954 20.1046 13 19 13H18V10H21C22.1046 10 23 9.10457 23 8C23 7.1918 22.5206 6.49557 21.8307 6.18014C21.7846 6.15145 21.7356 6.12615 21.6839 6.10469L9.9467 1.23781C9.66488 1.08609 9.34249 1 9 1ZM11 3.83969L16.2099 6L11 6V3.83969Z'
      fill='currentColor'
    />
  </svg>,
  'CraneIcon',
)
