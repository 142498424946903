import { CreateHoldCommand } from '@planning/app/api'
import holdService from '@planning/services/holdService'
import { HoldViewStore } from '@planning/stores/hold/HoldViewStore'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { CreateHoldForm } from './CreateHoldForm'
import { SearchableHoldTabsBox } from './SearchableHoldTabsBox'

interface Props {
  store: HoldViewStore
  onClose?: () => void
}

export const HoldsDrawerContent: FC<Props> = observer(({ store, onClose }) => {
  const handleSubmitAddHold = async (cmd: CreateHoldCommand) => {
    await holdService.create(cmd)
    store.setIsCreateMode(false)
  }

  const handleCancelAddHold = () => store.setIsCreateMode(false)

  return (
    <>
      {!store.isCreateMode ? (
        <SearchableHoldTabsBox store={store} onCancel={onClose} />
      ) : (
        <CreateHoldForm
          store={store}
          onSubmit={handleSubmitAddHold}
          onCancel={handleCancelAddHold}
        />
      )}
    </>
  )
})
