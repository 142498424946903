import {
  CarrierVisitStatus,
  GetTruckAppointmentsQueryType,
  TruckAppointmentDto,
  TruckAppointmentsApi,
} from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'
import { axiosUnwrapper } from '@planning/app/utils'
import { PaginatedStore } from '@planning/stores/PaginatedStore'
import { action, computed, makeObservable, observable } from 'mobx'
import {
  TruckAppointmentDetailsViewStore,
  createTruckAppointmentDetailsViewStore,
} from './TruckAppointmentDetailsViewStore'
import {
  TruckAppointmentOrderStore,
  createTruckAppointmentOrderViewStore,
} from './TruckAppointmentOrderViewStore'

export const createTruckAppointmentViewStore = (type: GetTruckAppointmentsQueryType) =>
  new TruckAppointmentViewStore(type, createApiClient(TruckAppointmentsApi))

export class TruckAppointmentViewStore extends PaginatedStore<TruckAppointmentDto> {
  selectedTruckVisitId?: number = undefined
  orderStore: TruckAppointmentOrderStore
  detailsStore: TruckAppointmentDetailsViewStore
  isCreateMode = false

  constructor(
    type: GetTruckAppointmentsQueryType,
    public httpClient: TruckAppointmentsApi,
  ) {
    super(async (currentPageIndex, pageSize, sortingModel, filter) => {
      return axiosUnwrapper(
        httpClient.get(
          currentPageIndex + 1,
          pageSize,
          type,
          this.filterStatus as CarrierVisitStatus,
          filter,
          this.showCompleted,
          sortingModel.orderBy,
          sortingModel.isDescending,
        ),
      )
    })

    makeObservable(this, {
      selectedTruckVisitId: observable,
      isCreateMode: observable,

      selectedTruckAppointment: computed,

      selectTruckVisitId: action,
      setIsCreateMode: action,
    })

    this.detailsStore = createTruckAppointmentDetailsViewStore(this)
    this.orderStore = createTruckAppointmentOrderViewStore(this)
  }

  setIsCreateMode(isCreateMode: boolean) {
    this.isCreateMode = isCreateMode
  }

  selectTruckVisitId(selectedTruckAppointmentId?: number) {
    this.selectedTruckVisitId = selectedTruckAppointmentId
    this.detailsStore.setAppointment(
      this.pageItems.find(ta => ta.truckVisitId === this.selectedTruckVisitId),
    )
  }

  get selectedTruckAppointment() {
    if (!this.selectedTruckVisitId) return undefined
    return this.pageItems.find(ta => ta.truckVisitId === this.selectedTruckVisitId)
  }
}
