import { Box, Checkbox, ListItem, ListItemIcon, Typography } from '@mui/material'
import { FC, HTMLAttributes } from 'react'

interface Props {
  code: string
  assignedCodes?: string[]
  props: HTMLAttributes<HTMLLIElement>
}

export const DangerousGoodOptionListItem: FC<Props> = ({ code, assignedCodes, props }) => {
  return (
    <ListItem {...props}>
      <ListItemIcon>
        <Checkbox edge='start' checked={assignedCodes?.includes(code)} disableRipple />
      </ListItemIcon>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant='body2'>{code}</Typography>
      </Box>
    </ListItem>
  )
}
