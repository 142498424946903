import { Chip, SxProps, Theme } from '@mui/material'
import { IEnumKeyValue } from '@planning/constants'

interface Props<T> {
  readonly value: T
  readonly options: IEnumKeyValue[]
  readonly color: (
    value: T,
  ) => 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
  readonly variant?: 'filled' | 'outlined' | 'soft'
  readonly size?: 'small' | 'medium'
  readonly sx?: SxProps<Theme>
  readonly testId?: string
}

export function EnumChip<T>({ value, options, color, variant, size, sx, testId }: Props<T>) {
  return (
    <Chip
      data-cy={testId}
      label={options.find(cvs => cvs.id === value)?.value}
      color={color(value)}
      variant={variant ?? 'filled'}
      sx={sx}
      size={size ? size : 'medium'}
    />
  )
}
