import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import AllocationRulesTemplatesListItem from './AllocationRulesTemplatesListItem'
import { useEffect } from 'react'
import { Box, Stack } from '@mui/system'
import { CarrierVisitAllocationRuleDto, CarrierVisitDirection } from '@storage/app/api'
import { mapYardPositionDescriptorToYardPositionDto } from '../forms/allocation-rule-templates-form/allocation-rule-templates-form.mapper'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  InputAdornment,
  Typography,
} from '@mui/material'
import { Search } from '@mui/icons-material'

interface CarrierVisitAllocationRulesDialogProps {
  carrierVisitId: number
  carrierVisitDirection: CarrierVisitDirection
  setAllocationRuleState: (allocationRule?: CarrierVisitAllocationRuleDto) => void
  //TODO: prop to be removed after removing yard-planning-dashboard-details-v-2 feature flag
  deprecatedVersion: boolean
}

const CarrierVisitAllocationRulesDialog = observer(
  ({
    carrierVisitId,
    carrierVisitDirection,
    setAllocationRuleState,
    deprecatedVersion,
  }: CarrierVisitAllocationRulesDialogProps) => {
    const { t } = useTranslate()
    const {
      allocationRuleTemplatesV2UIStore,
      manualInputValidationStore,
      carrierVisitAllocationRulesV2UIStore,
    } = useStores()

    const allocationRuleTemplatesDialogUtilStore = allocationRuleTemplatesV2UIStore.dialogUtilStore

    const carrierVisitAllocationRulesDialogUtilStore =
      carrierVisitAllocationRulesV2UIStore.containerDialogUtilStore

    const handleOnClose = () => {
      carrierVisitAllocationRulesDialogUtilStore.toggleDialog()
      allocationRuleTemplatesV2UIStore.resetSearchFilter()
    }

    const handleOnCreateNew = () => allocationRuleTemplatesDialogUtilStore.toggleDialog('Add')

    useEffect(() => {
      allocationRuleTemplatesV2UIStore.loadAllocationRuleTemplates()
    }, [allocationRuleTemplatesV2UIStore])

    const handleCopyToVisit = async (allocationRuleId: string, destination: string) => {
      if (deprecatedVersion) {
        await allocationRuleTemplatesV2UIStore.copyRuleTemplateToCarrierVisit_Deprecated(
          carrierVisitId,
          allocationRuleId,
          carrierVisitDirection,
          mapYardPositionDescriptorToYardPositionDto(destination),
          setAllocationRuleState,
          handleOnClose,
        )
      } else {
        await allocationRuleTemplatesV2UIStore.copyRuleTemplateToCarrierVisit(
          carrierVisitId,
          allocationRuleId,
          carrierVisitDirection,
          mapYardPositionDescriptorToYardPositionDto(destination),
          setAllocationRuleState,
          handleOnClose,
        )
      }
    }

    const handleOnSearchChange = (e: React.ChangeEvent<HTMLInputElement>) =>
      allocationRuleTemplatesV2UIStore.setSearchQuery(e.target.value)

    return (
      <Dialog
        onClose={handleOnClose}
        open={carrierVisitAllocationRulesDialogUtilStore.isDialogOpen}
        maxWidth='md'
        fullWidth
      >
        <DialogTitle>
          <Stack direction='row' justifyContent='space-between'>
            {t('allocationRulesTemplates', 'Allocation Rules Templates')}
            <Box display='flex' gap={2}>
              <Button variant={'contained'} color={'primary'} onClick={handleOnCreateNew}>
                {t('createNew', 'Create New')}
              </Button>
              <Button variant='text' color='inherit' onClick={handleOnClose}>
                {t('close', 'Close')}
              </Button>
            </Box>
          </Stack>
        </DialogTitle>

        <DialogContent sx={{ mb: 3 }}>
          <Stack>
            <FormControl variant='standard' sx={{ m: 2 }}>
              <Input
                placeholder={t('searchByName', 'Search by name')}
                onChange={handleOnSearchChange}
                startAdornment={
                  <InputAdornment position='start'>
                    <Search />
                  </InputAdornment>
                }
              />
            </FormControl>
            <Stack gap={1} sx={{ maxHeight: '50vh', overflow: 'auto' }} p={0.5}>
              {allocationRuleTemplatesV2UIStore.allocationRuleTemplates
                .reverse()
                .map(allocationRuleTemplate => (
                  <AllocationRulesTemplatesListItem
                    key={allocationRuleTemplate.id}
                    allocationRuleTemplate={allocationRuleTemplate}
                    manualInputValidationStore={manualInputValidationStore}
                    onActionClick={handleCopyToVisit}
                  />
                ))}
            </Stack>
            {allocationRuleTemplatesV2UIStore.searchInfoMessage && (
              <Box m='auto' p={3}>
                <Typography>{allocationRuleTemplatesV2UIStore.searchInfoMessage}</Typography>
              </Box>
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    )
  },
)

export default CarrierVisitAllocationRulesDialog
