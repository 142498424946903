import { Button, Card, CardContent, Grid } from '@mui/material'
import { ReactNode } from 'react'

export interface ListCardActionProps {
  onClick?: () => void
  children: ReactNode
}

export const ListCardAction = ({ onClick, children }: ListCardActionProps) => {
  const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    e.stopPropagation()

    if (onClick) onClick()
  }

  return (
    <Grid item xs={1} display='flex' alignContent='center' justifyContent='center'>
      <Button size='small' onClick={handleOnClick}>
        {children}
      </Button>
    </Grid>
  )
}

export interface ListCardProps {
  children: ReactNode
  onClick?: () => void
  onDelete?: () => Promise<void> | void
  gridSpacing?: string
}

export const ListCard = ({ onClick, gridSpacing, children }: ListCardProps) => {
  return (
    <Card onClick={onClick} sx={{ marginTop: '0.25rem' }}>
      <CardContent>
        <Grid container alignItems='center' justifyItems='center' spacing={gridSpacing}>
          {children}
        </Grid>
      </CardContent>
    </Card>
  )
}
