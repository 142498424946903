import {
  CreateReeferTemperatureCommand,
  ReeferTemperatureDto,
  UpdateReeferTemperatureCommand,
} from '@operations/app/api'
import dayjs from 'dayjs'
import { ReeferMonitoringFormProfile } from './reefer-monitoring-form.profile'

const mapFormValuesToCreateDto = ({
  cargoUnitId,
  isPluggedIn,
  temperature,
  recordedAt,
  additionalInfo,
}: ReeferMonitoringFormProfile): CreateReeferTemperatureCommand => ({
  cargoUnitId: +cargoUnitId!,
  isPluggedIn,
  temperature: temperature ? +temperature : undefined,
  recordedAt: dayjs(recordedAt).toISOString(),
  additionalInfo: additionalInfo,
})

const mapFormValuesToUpdateDto = ({
  id,
  isPluggedIn,
  temperature,
  recordedAt,
  additionalInfo,
}: ReeferMonitoringFormProfile): UpdateReeferTemperatureCommand => ({
  id: id!,
  isPluggedIn,
  temperature: temperature ? +temperature : undefined,
  recordedAt: dayjs(recordedAt).toISOString(),
  additionalInfo: additionalInfo,
})

const mapDtoToFormValues = ({
  id,
  cargoUnitId,
  isPluggedIn,
  temperature,
  recordedAt,
  additionalInfo,
}: ReeferTemperatureDto): ReeferMonitoringFormProfile => ({
  id,
  cargoUnitId,
  isPluggedIn,
  temperature,
  recordedAt: new Date(recordedAt),
  additionalInfo: additionalInfo ?? undefined,
})

export const ReeferMonitoringFormMapper = {
  mapFormValuesToCreateDto,
  mapFormValuesToUpdateDto,
  mapDtoToFormValues,
}
