/* eslint-disable jsx-a11y/anchor-is-valid */
import { MenuIcon, MenuPopperIconButton } from '@tom-ui/ui'
import { FC, ReactElement } from 'react'

interface Props {
  children?: ReactElement[] | ReactElement
  color?: string
}

export const BurgerMenu: FC<Props> = ({ children, color }: Props) => {
  return (
    <MenuPopperIconButton icon={<MenuIcon sx={{ color: color ?? '#fff' }} />}>
      {children}
    </MenuPopperIconButton>
  )
}
