import { FormProps } from '@operations/app/models'
import { ControlledNumberField } from '@operations/components/form-controls'
import useFormWithSchema from '@operations/hooks/use-form-with-schema.hook'
import { Box } from '@mui/system'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useEffect } from 'react'
import { ProcessingTimeFormProfile, defaultValues } from './processing-time-form.profile'
import { schema } from './processing-time-form.scheme'

interface Props extends FormProps {
  processingTime?: number
  onSubmit: (formValues: ProcessingTimeFormProfile) => Promise<void> | void
}

export const ProcessingTime = ({ id, processingTime, onSubmit }: Props) => {
  const { handleSubmit, formState, control, reset } = useFormWithSchema<ProcessingTimeFormProfile>(
    schema(),
    defaultValues,
  )
  const theme = useMinimalsTheme()

  useEffect(() => {
    if (processingTime) {
      reset({ processingTime: processingTime })
    }
  }, [reset, processingTime])

  return (
    <Box
      id={id}
      component='form'
      noValidate
      autoComplete='off'
      sx={{ gap: theme.customSpacing.l }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <ControlledNumberField<ProcessingTimeFormProfile>
        name='processingTime'
        formState={formState}
        control={control}
        label='Processing Time(min/container)'
        fullWidth
      />
    </Box>
  )
}
