import { Box, Typography } from '@mui/material'

interface YardPositionSelectHeaderFieldProps {
  label: string
  descriptor?: string
  color: string
  textColor: string
  onChangeContext: () => void
}

export const YardPositionSelectHeaderField = ({
  label,
  descriptor,
  color,
  textColor,
  onChangeContext,
}: YardPositionSelectHeaderFieldProps) => {
  return (
    <Box
      onClick={onChangeContext}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: color,
        borderRadius: 2,
        minWidth: '6rem',
        minHeight: '3.6rem',
        padding: '0.2rem',
      }}
    >
      <Typography
        variant='caption'
        color={textColor}
        sx={{
          justifySelf: 'flex-start',
        }}
      >
        {label}
      </Typography>
      <Typography
        variant='h6'
        color={textColor}
        sx={{
          alignSelf: 'center',
        }}
      >
        {descriptor}
      </Typography>
    </Box>
  )
}
