import { ServiceOrderDialog, usePlanningStore } from '@tom-ui/planning'
import {
  GeneralCargoOrderReservation,
  GeneralCargoOrderReservationDialog,
  StockDto,
} from '@tom-ui/storage'
import { observer } from 'mobx-react-lite'
import { ServiceOrdersViewStore } from 'modules/planning/src/pages/ServiceOrders/Stores/ServiceOrdersViewStore'
import { CommodityDialog } from './CommodityDialog'

interface IProps {
  store: ServiceOrdersViewStore
  onClose: () => void
}

const renderReservations = (orderId: number, plannedAmout: number, cargoItemId: number) => (
  <GeneralCargoOrderReservation
    generalCargoOrderId={orderId} // TODO [Stuffing] Check why generalCargoOrderId is mandatory for stripping orders
    quantity={plannedAmout}
    cargoItemId={cargoItemId}
    showActionButton={true}
  />
)

export const CreateServiceOrderDialog = observer(({ store, onClose }: IProps) => {
  const { tenantStore } = usePlanningStore()

  return (
    <ServiceOrderDialog
      store={store}
      onClose={onClose}
      renderReservations={tenantStore.skipYardPlanning ? undefined : renderReservations}
    >
      <CommodityDialog
        open={store.showCommodityDialog}
        customerId={store.selectedCustomerData.selectedCustomerReferenceId}
        customerName={store.selectedCustomerData.selectedCustomerName}
        onSelectCommodity={(stock: StockDto) => {
          store.upsertSelectedCommodity(stock)
          store.closeActiveDialog()
        }}
        onClose={() => store.closeActiveDialog()}
      />
      <GeneralCargoOrderReservationDialog />
    </ServiceOrderDialog>
  )
})
