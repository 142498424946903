import { Skeleton } from '@mui/material'
import { Stack } from '@mui/system'

const GeneralCargoStorageTrackerSkeleton = () => {
  const skeletonHeights = [90, 90, 45]

  return (
    <Stack gap={2}>
      {skeletonHeights.map((height, index) => (
        <Skeleton key={index} variant='rounded' height={height} />
      ))}
    </Stack>
  )
}

export default GeneralCargoStorageTrackerSkeleton
