import { useAuth0 } from '@auth0/auth0-react'
import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Avatar as MuiAvatar,
  Paper,
  Popper,
  Stack,
  Typography,
} from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { appStore } from '@host/stores/AppStore'
import { useTranslate } from '@tolgee/react'
import { useRef, useState } from 'react'
import { useNavigate } from 'react-router'

interface Props {
  withName?: boolean
}
export const Avatar = ({ withName }: Props) => {
  const { user, logout } = useAuth0()
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const navigate = useNavigate()

  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = (event?: Event | React.SyntheticEvent) => {
    if (anchorRef?.current?.contains(event?.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  const handleNavigate = () => {
    navigate(`/Profile`)
    handleClose()
  }

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } })
    handleClose()
  }

  const avatarInitials = user?.name
    ?.split(' ')
    .map(word => word.charAt(0))
    .join('')

  return (
    <>
      <Stack flexDirection='row' alignItems='center' gap={1}>
        <MuiAvatar
          ref={anchorRef}
          sx={{ bgcolor: theme.palette.grey[400], cursor: 'pointer', fontSize: '0.9rem' }}
          onClick={handleToggle}
        >
          {avatarInitials}
        </MuiAvatar>
        {withName && user?.given_name && (
          <Typography
            variant='subtitle2'
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '70px',
            }}
          >
            {user.given_name}
          </Typography>
        )}
      </Stack>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement={appStore.isMenuMobileOpen ? 'top' : 'right'}
        transition
        sx={{ zIndex: theme.zIndex.drawer }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper
              sx={{
                marginBottom: appStore.isMenuMobileOpen ? theme.customSpacing.xs : undefined,
                marginLeft: !appStore.isMenuMobileOpen ? theme.customSpacing.l : undefined,
                boxShadow: theme.customShadows.dropdown,
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} disablePadding>
                  <MenuItem onClick={handleNavigate} sx={{ whiteSpace: 'normal' }}>
                    {t('profile', 'Profile')}
                  </MenuItem>
                  {!appStore.isMenuMobileOpen && (
                    <MenuItem onClick={handleLogout} sx={{ whiteSpace: 'normal' }}>
                      {t('logout', 'Logout')}
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}
