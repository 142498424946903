import { FormType } from '@storage/app/models'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { ConfirmationDialog } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import {
  mapFormValuesToCreateRailTrackDto,
  mapFormValuesToRailTrackDto,
} from '../forms/rail-tracks-form/rail-tracks.mapper'
import RailTracksForm from '../forms/rail-tracks-form/RailTracksForm'
import { RailTrackFormProfile } from '../forms/rail-tracks-form/rail-tracks-form.profile'

const RailTracksTableDialog = observer(() => {
  const { t } = useTranslate()
  const { railTracksUIStore, railTracksStore, snackbarStore } = useStores()
  const dialogUtilStore = railTracksUIStore.dialogUtilStore
  const formId = 'rail-tracks-form'

  const saveLabel = t('save', 'Save')
  const cancelLabel = t('cancel', 'Cancel')

  const getDialogLabels = (action: FormType) => {
    switch (action) {
      case 'Add':
        return {
          title: t('addRailTrack', 'Add New Rail Track'),
          buttons: { primary: saveLabel, secondary: cancelLabel },
        }
      case 'Edit':
        return {
          title: t('editRailTrack', 'Edit Rail Track'),
          buttons: { primary: saveLabel, secondary: cancelLabel },
        }
      case 'Delete':
        return {
          title: t('deleteRailTrack', 'Delete Rail Track?'),
          buttons: { primary: t('delete', 'Delete'), secondary: cancelLabel },
        }
    }
  }

  const dialogLabels = getDialogLabels(dialogUtilStore.dialogAction)

  const handleOnClose = () => {
    dialogUtilStore.toggleDialog()
  }

  const handleOnSubmit = (formValues: RailTrackFormProfile) => {
    if (dialogUtilStore.dialogAction === 'Add') {
      railTracksStore
        .add(mapFormValuesToCreateRailTrackDto(formValues))
        .then(() =>
          snackbarStore.showMessage(
            t('railTrackCreationSuccess', 'The rail track is successfully created'),
            'success',
          ),
        )
        .catch(() =>
          snackbarStore.showMessage(
            t(
              'railTrackCreationFailure',
              'An unexpected error occurred while creating the rail track',
            ),
            'error',
          ),
        )
        .finally(() => handleOnClose())
    } else if (dialogUtilStore.dialogAction === 'Edit') {
      railTracksStore
        .update(mapFormValuesToRailTrackDto(formValues))
        .then(() =>
          snackbarStore.showMessage(
            t('railTrackUpdateSuccess', 'The rail track is successfully updated'),
            'success',
          ),
        )
        .catch(() =>
          snackbarStore.showMessage(
            t(
              'railTrackUpdateFailure',
              'An unexpected error occurred while updating the rail track',
            ),
            'error',
          ),
        )
        .finally(() => handleOnClose())
    }
  }

  const handleOnDelete = () => {
    railTracksStore
      .delete(dialogUtilStore.dialogEntityId!)
      .then(() =>
        snackbarStore.showMessage(
          t('railTrackDeletionSuccess', 'The rail track is successfully deleted'),
          'success',
        ),
      )
      .catch(() =>
        snackbarStore.showMessage(
          t(
            'railTrackDeletionFailure',
            'An unexpected error occurred while deleting the rail track',
          ),
          'error',
        ),
      )
      .finally(() => handleOnClose())
  }

  return (
    <ConfirmationDialog
      open={dialogUtilStore.isDialogOpen}
      title={dialogLabels.title}
      primaryActionText={dialogLabels.buttons.primary}
      closeLabel={dialogLabels.buttons.secondary}
      formId={formId}
      maxWidth='sm'
      onClose={handleOnClose}
      hasBorderTop={dialogUtilStore.dialogAction !== 'Delete'}
      blockClickOutside={dialogUtilStore.dialogAction === 'Delete'}
      message={
        dialogUtilStore.dialogAction === 'Delete'
          ? t(
              'railTrackWillBeDeleted',
              'This rail track will be deleted and you cannot undo this action!',
            )
          : undefined
      }
      onConfirm={dialogUtilStore.dialogAction === 'Delete' ? handleOnDelete : undefined}
    >
      {dialogUtilStore.dialogAction !== 'Delete' && (
        <RailTracksForm
          id={formId}
          onSubmit={handleOnSubmit}
          railTrack={
            dialogUtilStore.dialogEntityId
              ? railTracksStore.getById(dialogUtilStore.dialogEntityId)
              : undefined
          }
        />
      )}
    </ConfirmationDialog>
  )
})

export default RailTracksTableDialog
