import {
  GateInForm,
  GateOutForm,
  SearchArrivedTruckVisit,
  SelectOperation,
  useNavigationStore,
  usePlanningStore,
} from '@tom-ui/planning'
import { OutboundRequestValidationControl } from '@tom-ui/storage'
import { observer } from 'mobx-react-lite'
import { OutboundOrderValidationDto } from 'modules/planning/src/stores/gateControl/ValidateOutboundDto'
import { useCallback, useEffect } from 'react'
import { GateInRouteInfoPage } from './GateInRouteInfoPage'

interface NonNumeric {
  pickUpAmount: number
  containerHeight: string
  containerLength: number
  shippingLine: string
}

export const GateClerkHome = observer(() => {
  const { gateClerkViewStore, containerDamageReportViewStore } = usePlanningStore()
  const { notificationStore } = gateClerkViewStore
  const navigationStore = useNavigationStore()

  useEffect(() => {
    const fetchData = async () => {
      await containerDamageReportViewStore.fetch()
    }

    fetchData()
  }, [containerDamageReportViewStore])

  const populateGateOutFormData = async (visit: any) => {
    const { gateOutViewStore } = gateClerkViewStore
    gateOutViewStore.setVisit(visit)
    await gateOutViewStore.fetch()
  }

  const onSelectTruckVisitForGateOut = async (visit: any) => {
    await populateGateOutFormData(visit)

    gateClerkViewStore.arrivedTruckVisitSearchStore.setFilter('')

    navigationStore.push(<GateOutForm store={gateClerkViewStore.gateOutViewStore} />)
  }

  const onGateInFormSubmit = async (data: any) => {
    await notificationStore.createGateInRequest(data)

    gateClerkViewStore.gateInViewStore.reset()

    navigationStore.clear()
  }

  const goToRouteInfoPage = (data: any) => {
    const dropOffs: string[] =
      gateClerkViewStore.gateInViewStore.dropOffOrders.map((o: any) => o.containerNumber) ?? []
    const pickUps: string[] =
      gateClerkViewStore.gateInViewStore.pickUpOrders.map((o: any) => o.containerNumber) ?? []
    const nonNumeric: NonNumeric[] =
      gateClerkViewStore.gateInViewStore.nnrOrders.map(
        (o: any) =>
          ({
            pickUpAmount: o.pickUpAmount,
            containerHeight: o.containerHeight,
            containerLength: o.containerLength,
            shippingLine: o.shippingLine,
          }) as NonNumeric,
      ) ?? []

    navigationStore.push(
      <GateInRouteInfoPage
        dropOffs={dropOffs}
        pickUps={pickUps}
        nonNumeric={nonNumeric}
        onSubmit={async () => await onGateInFormSubmit(data)}
        onClose={() => navigationStore.pop()}
      />,
    )
  }

  const onSelectOperationNext = (operation: any) => {
    if (operation === 'Inbound')
      navigationStore.push(
        <GateInForm store={gateClerkViewStore.gateInViewStore} onSubmit={goToRouteInfoPage} />,
      )
    else {
      gateClerkViewStore.arrivedTruckVisitSearchStore.setFilter('')
      navigationStore.push(
        <SearchArrivedTruckVisit
          store={gateClerkViewStore.arrivedTruckVisitSearchStore}
          onClick={onSelectTruckVisitForGateOut}
        />,
      )
    }
  }

  gateClerkViewStore.gateInViewStore.validateOutboundRequest = useCallback(
    (params: OutboundOrderValidationDto): React.ReactElement => (
      <OutboundRequestValidationControl
        isOutbound={params.isOutbound}
        customerId={params.customerId}
        commodityId={params.commodityId}
        lotNumber={params.lotNumber}
        packageId={params.packageId}
        quantity={params.quantity}
        unitIds={params.unitIds}
        imoClasses={params.imoClasses}
        hideSuccessfulValidation
      />
    ),
    [],
  )

  return <SelectOperation store={gateClerkViewStore} navigateNext={onSelectOperationNext} />
})
