import { Button, Divider, Menu, MenuItem, Typography } from '@mui/material'
import { CarrierType, ContainerTurnoverDto } from '@storage/app/api'
import Multi9plus from '@storage/components/icons/svg//MultipleIndicators/Multi9plus.svg'
import Damaged from '@storage/components/icons/svg/Damaged.svg'
import Dangerous from '@storage/components/icons/svg/Dangerous.svg'
import Empty from '@storage/components/icons/svg/Empty.svg'
import Full from '@storage/components/icons/svg/Full.svg'
import More from '@storage/components/icons/svg/More.svg'
import Multi2 from '@storage/components/icons/svg/MultipleIndicators/Multi2.svg'
import Multi3 from '@storage/components/icons/svg/MultipleIndicators/Multi3.svg'
import Multi4 from '@storage/components/icons/svg/MultipleIndicators/Multi4.svg'
import Multi5 from '@storage/components/icons/svg/MultipleIndicators/Multi5.svg'
import Multi6 from '@storage/components/icons/svg/MultipleIndicators/Multi6.svg'
import Multi7 from '@storage/components/icons/svg/MultipleIndicators/Multi7.svg'
import Multi8 from '@storage/components/icons/svg/MultipleIndicators/Multi8.svg'
import Multi9 from '@storage/components/icons/svg/MultipleIndicators/Multi9.svg'
import Reefer from '@storage/components/icons/svg/Reefer.svg'
import useContextMenu from '@storage/hooks/use-context-menu.hook'
import {
  DEFAULT_OCCUPANCY_BACKGROUND,
  EXPORT_OCCUPANCY_BACKGROUND,
  IMPORT_OCCUPANCY_BACKGROUND,
  RESERVATION_OCCUPANCY_BACKGROUND,
} from '@storage/pages/yard-management/constants'
import { TenantConfigStore } from '@storage/stores/tenantConfig.store'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { Group, Image, Rect, Text } from 'react-konva'
import { Html } from 'react-konva-utils'
import useImage from 'use-image'
import { YardBlockBayOverviewItem } from './models'

export interface YardBlockSlotProps {
  item: YardBlockBayOverviewItem
  left: number
  top: number
  slotWidth: number
  onMenuAction?: (action: string, turnover: ContainerTurnoverDto) => void
  tenantConfigStore: TenantConfigStore
}

export const YardBlockBayOverviewSlotItem = ({
  item,
  left,
  top,
  slotWidth,
  onMenuAction,
  tenantConfigStore,
}: YardBlockSlotProps) => {
  const { palette } = useMinimalsTheme()
  const { menuProps, handleOpenMenu } = useContextMenu()
  const { t } = useTranslate()

  const [reefer] = useImage(Reefer)
  const [empty] = useImage(Empty)
  const [full] = useImage(Full)
  const [dangerous] = useImage(Dangerous)
  const [damaged] = useImage(Damaged)
  const [moreVertMenu] = useImage(More)

  const [multi2] = useImage(Multi2)
  const [multi3] = useImage(Multi3)
  const [multi4] = useImage(Multi4)
  const [multi5] = useImage(Multi5)
  const [multi6] = useImage(Multi6)
  const [multi7] = useImage(Multi7)
  const [multi8] = useImage(Multi8)
  const [multi9] = useImage(Multi9)
  const [multi9plus] = useImage(Multi9plus)

  const multiImages = [multi2, multi3, multi4, multi5, multi6, multi7, multi8, multi9]

  const { width, height, padding, rowHeight, iconSize, iconBarSplit } = {
    width: slotWidth,
    height: slotWidth,
    padding: slotWidth / 30,
    rowHeight: slotWidth / 5,
    iconSize: (slotWidth / 6 / 32) * 32,
    iconBarSplit: slotWidth * 0.3,
  }

  const turnover = item.renderedTurnover
  const isReservation = item.reservations?.find(e => e.id === turnover?.id)
  const isOccupancy = item.occupancies?.find(e => e.id === turnover?.id)
  const isExport = turnover?.outboundCarrierType === CarrierType.Vessel
  const isImport = !isExport && turnover?.inboundCarrierType === CarrierType.Vessel
  const otherCarrierTypeThanVessel = !isExport && !isImport && isOccupancy
  const occupancyCount = item.occupancies?.length ?? 0

  const multipleStackingIndicator = () => {
    if (occupancyCount >= 2 && occupancyCount <= 9) return multiImages[occupancyCount - 2]

    return multi9plus
  }

  const fillColor = () => {
    if (turnover) {
      if (isReservation) {
        return RESERVATION_OCCUPANCY_BACKGROUND
      }
      if (isOccupancy) {
        return isImport
          ? IMPORT_OCCUPANCY_BACKGROUND
          : isExport
            ? EXPORT_OCCUPANCY_BACKGROUND
            : DEFAULT_OCCUPANCY_BACKGROUND
      }
    }
    return DEFAULT_OCCUPANCY_BACKGROUND
  }

  const iconBar = (turnover: ContainerTurnoverDto) => {
    return (
      <Group x={0} y={height - padding - iconSize}>
        <Image
          image={turnover.isEmptyContainerCargoState ? empty : full}
          x={padding}
          width={iconSize}
          height={iconSize}
        />
        {turnover.isReefer && (
          <Image image={reefer} x={iconBarSplit} width={iconSize} height={iconSize} />
        )}
        {turnover.isDamaged && (
          <Image
            image={damaged}
            x={iconBarSplit + iconSize + padding}
            width={iconSize}
            height={iconSize}
          />
        )}
        {turnover.isDangerous && (
          <Image
            image={dangerous}
            x={iconBarSplit + 2 * (iconSize + padding)}
            width={iconSize}
            height={iconSize}
          />
        )}
        {isOccupancy && (
          <>
            <Image
              x={width - 15}
              rotation={0}
              width={iconSize}
              onClick={({ evt }) => handleOpenMenu(evt.clientX, evt.clientY)}
              image={moreVertMenu}
            />
            <Html>
              <Menu {...menuProps}>
                <MenuItem disabled onClick={e => e.stopPropagation()}>
                  <Typography variant='body2'>{t('updatePosition', 'Update Position')}</Typography>
                </MenuItem>
                <Divider />
                {item.occupancies?.map(occ => (
                  <MenuItem
                    key={occ.id}
                    style={{ fontSize: 12 }}
                    onClick={() => onMenuAction && onMenuAction('update', occ)}
                  >
                    <Button
                      size='small'
                      variant='contained'
                      onClick={() => onMenuAction && onMenuAction('update', occ)}
                    >
                      {occ.containerNumber}
                    </Button>
                  </MenuItem>
                ))}
              </Menu>
            </Html>
          </>
        )}
      </Group>
    )
  }

  return (
    <Group width={width} height={height} x={left} y={top} borderRadius='20px' overflow='hidden'>
      <Rect
        width={width}
        height={height}
        fill={fillColor()}
        strokeWidth={
          otherCarrierTypeThanVessel
            ? 2
            : !tenantConfigStore.skipYardPlanning && item.slot.isAllocated
              ? 3
              : 1
        }
        stroke={
          otherCarrierTypeThanVessel
            ? palette.grey[500]
            : !tenantConfigStore.skipYardPlanning && item.slot.isAllocated
              ? palette.primary.main
              : palette.grey[300]
        }
        hitStrokeWidth={0}
      />
      {turnover && (
        <>
          <Text
            x={padding}
            y={padding}
            text={isImport ? turnover.portOfLoading ?? '' : turnover.portOfDischarge ?? ''}
            fontSize={10}
            fill='black'
            width={width / 2 - padding}
            ellipsis
            wrap='none'
          />
          {occupancyCount > 1 && (
            <Image
              image={multipleStackingIndicator()}
              x={(width - iconSize) / 2 + padding}
              y={2}
              width={iconSize}
              height={iconSize}
            />
          )}
          <Text
            x={width / 2}
            y={padding}
            width={width / 2 - padding}
            align='right'
            text={turnover.containerOperator ?? ''}
            fontSize={10}
            fill='black'
            ellipsis
            wrap='none'
          />
          <Text
            x={padding}
            y={rowHeight}
            width={width - 2 * padding}
            align='center'
            text={turnover.containerNumber}
            fontSize={15}
            fontStyle='bold'
            fill='black'
            ellipsis
            wrap='none'
          />
          <Text
            x={padding}
            y={2 * rowHeight}
            text={turnover.containerIsoCode ?? ''}
            fontSize={10}
            fill='black'
          />
          <Text
            x={-padding}
            y={2 * rowHeight}
            width={width}
            align='right'
            text={Math.round((turnover.grossWeight ?? 0) / 1000).toString() + 't'}
            fontSize={10}
            fill='black'
          />
          <Text
            x={padding}
            y={3 * rowHeight}
            text={
              isImport
                ? turnover.inboundCarriers.map(x => x.name).join() ?? ''
                : turnover.outboundCarriers.map(x => x.name).join() ?? ''
            }
            fontSize={12}
            fill='black'
            width={width - 2 * padding}
            ellipsis
            wrap='none'
          />
          {iconBar(turnover)}
        </>
      )}
    </Group>
  )
}
