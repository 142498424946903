import { Chip, Stack } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'

interface Props {
  selectedYardBlocks: string[]
  yardBlocks: string[]
  onSelectYardBlock: (yardBlock: string) => void
  onRemoveYardBlock: (yardBlock: string) => void
}
export const YardBlockChips = observer(
  ({ selectedYardBlocks, yardBlocks, onSelectYardBlock, onRemoveYardBlock }: Props) => {
    const theme = useMinimalsTheme()

    return (
      <Stack flexDirection='row' gap={theme.customSpacing.xs}>
        {yardBlocks.map((yardBlock, i) => {
          const isSelected = selectedYardBlocks.includes(yardBlock)

          return (
            <Chip
              key={'yard_block_' + i}
              label={yardBlock}
              color={isSelected ? 'primary' : undefined}
              onClick={() => onSelectYardBlock(yardBlock)}
              onDelete={isSelected ? () => onRemoveYardBlock(yardBlock) : undefined}
            />
          )
        })}
      </Stack>
    )
  },
)
