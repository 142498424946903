import { Box, Button, Card, IconButton, Stack, Typography } from '@mui/material'
import { CheckType } from '@planning/app/api'
import { useTranslate } from '@tolgee/react'
import { BinIcon, GeneralIcon, HighlightedText, useMinimalsTheme } from '@tom-ui/ui'
import { FC } from 'react'
import { checkedSuffixText } from '../InspectRailcar'

interface Props {
  containers: string[]
  railcar: string
  sequence: number
  operation?: CheckType
  searchHighlight?: string
  onCheckIn: () => void
  onDelete?: () => void
}

const ContainerChip: FC<{ containerNumber: string }> = ({ containerNumber }) => {
  const theme = useMinimalsTheme()
  return (
    <Box
      sx={{
        display: 'inline-block',
        p: '.25rem .75rem',
        bgcolor: theme.palette.grey[200],
        fontSize: '14px',
        borderRadius: '8px',
        mr: '6px',
        mb: '6px',
      }}
    >
      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        <GeneralIcon />
        <Typography variant='subtitle2'>{containerNumber}</Typography>
      </Stack>
    </Box>
  )
}

export const RailCarCard: FC<Props> = ({
  containers,
  railcar,
  sequence,
  searchHighlight,
  operation,
  onDelete,
  onCheckIn,
}) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  return (
    <Card
      sx={{
        padding: '1rem',
      }}
    >
      <Stack direction={'row'} spacing={2} alignItems={'center'} mb={theme.customSpacing.s}>
        <Box
          sx={{
            display: 'inline-block',
            p: '.5rem .75rem',
            bgcolor: theme.palette.grey[900],
            color: '#fff',
            borderRadius: '8px',
          }}
        >
          {sequence}
        </Box>
        <Typography variant='h2'>
          <HighlightedText color={theme.palette.info.main} highlight={searchHighlight}>
            {railcar}
          </HighlightedText>
        </Typography>
      </Stack>

      <Box alignItems={'center'} mb={theme.customSpacing.s}>
        {containers.map(item => (
          <ContainerChip key={item} containerNumber={item} />
        ))}
      </Box>
      <Stack direction={'row'} spacing={2} alignItems={'center'}>
        <Button
          variant='contained'
          size='large'
          sx={{ width: '100%', mt: '1rem' }}
          onClick={onCheckIn}
        >
          {`${t('check', 'Check')} ${checkedSuffixText(operation, t)}`}
        </Button>

        {onDelete && (
          <IconButton
            onClick={onDelete}
            sx={{
              bgcolor: theme.palette.grey[200],
            }}
          >
            <BinIcon />
          </IconButton>
        )}
      </Stack>
    </Card>
  )
}
