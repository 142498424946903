import { TruckVisitDto, TruckVisitsApi } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'
import { Event, IEvent, IQueryHandler, IQueryMessage } from '../messageBus'

export class GetTruckVisitsByIdsQuery implements IQueryMessage {
  static type = 'GetTruckVisitsByIdsQuery'
  type = GetTruckVisitsByIdsQuery.type

  constructor(public ids: number[]) {}

  getKey = () => {
    return JSON.stringify(this)
  }
}

const httpClient = createApiClient(TruckVisitsApi)

export const getTruckVisitsByIdsQueryHandler: IQueryHandler<
  GetTruckVisitsByIdsQuery,
  IEvent<TruckVisitDto[]>
> = async query => {
  const { data } = await httpClient.getByIds(query.ids)

  return new Event(GetTruckVisitsByIdsQuery.type, data)
}
