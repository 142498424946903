import {
  CreateTruckAppointmentCommand,
  TruckAppointmentsApi,
  UpdateTruckAppointmentCommand,
} from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'

class TruckAppointmentService {
  httpClient = createApiClient(TruckAppointmentsApi)

  post = async (cmd: CreateTruckAppointmentCommand) => {
    const { data } = await this.httpClient.post(cmd)
    return data
  }

  put = async (cmd: UpdateTruckAppointmentCommand) => {
    const { data } = await this.httpClient.put(cmd)
    return data
  }

  delete = async (id: number) => {
    await this.httpClient.remove(id)
  }
}

const truckAppointmentService = new TruckAppointmentService()

export default truckAppointmentService
