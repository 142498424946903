import Permission from '@billing/app/models/permission.enum'
import { useBillingServiceStore } from '@billing/AppProvider'
import { Box, Button, Container, Stack } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { Header, NoListResult, PlusIcon } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { Customer } from '../components/customer/Customer'

export const CustomersContainer = observer(() => {
  const { t } = useTranslate()
  const { customerStore, customerTableUIStore, customerDialogUIStore, authStore } =
    useBillingServiceStore()
  const canEdit = authStore.hasPermission(Permission.WriteCustomers)
  const { closeSnackbar } = useSnackbar()

  useEffect(() => {
    async function fetchData() {
      await customerStore.load()
    }

    fetchData()
  }, [customerStore])

  const handleAddClick = () => {
    closeSnackbar()
    customerDialogUIStore.toggle()
  }

  const handleEditClick = (id: number) => {
    closeSnackbar()
    customerDialogUIStore.toggle(id)
  }

  return (
    <>
      <Header
        title={t('customers', 'Customers')}
        searchInputLabel={t('searchForAName', 'Search for a name')}
        onSearchFieldChange={name => customerTableUIStore.setSearchCustomer(name)}
        displaySearchIcon
        enableSearchField
        searchFilter={customerTableUIStore.searchCustomer}
        rightRenderOption={
          canEdit ? (
            <Box flex='none'>
              <Button
                color='inherit'
                variant='contained'
                startIcon={<PlusIcon />}
                onClick={handleAddClick}
              >
                {t('addNew', 'Add new')}
              </Button>
            </Box>
          ) : undefined
        }
      />
      <Container>
        <Stack gap={2} paddingY={4}>
          {customerTableUIStore.customers.map(x => (
            <Customer
              key={x.id}
              customer={x}
              canEdit={canEdit}
              onClick={c => (canEdit ? handleEditClick(c.id) : false)}
            />
          ))}
          {!customerTableUIStore.customers.length && (
            <NoListResult
              result={t('noCustomerFound', 'No customer found')}
              filtering={!!customerTableUIStore.searchCustomer}
              onClearFilter={() => customerTableUIStore.setSearchCustomer('')}
            />
          )}
        </Stack>
      </Container>
    </>
  )
})
