import { Button } from '@mui/material'
import Stack from '@mui/material/Stack'
import { CarrierType } from '@planning/app/api'
import { VisitTableViewStore } from '@planning/rt-stores/visit/VisitTableViewStore'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'

interface Props {
  store: VisitTableViewStore
}
export const VisitCarrierTypeFilter = observer(({ store }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  return (
    <Stack
      direction='row'
      alignItems='center'
      gap={1}
      padding={1}
      sx={{
        backgroundColor: theme.palette.grey[300],
      }}
    >
      <Button
        variant='contained'
        size='small'
        color={!store.carrierType ? 'secondary' : 'inherit'}
        onClick={() => store.setCarrierType()}
      >
        {t('all', 'All')} {store.carrierTypeCount.all}
      </Button>
      <Button
        variant='contained'
        size='small'
        color={store.carrierType === CarrierType.Vessel ? 'secondary' : 'inherit'}
        onClick={() => store.setCarrierType(CarrierType.Vessel)}
      >
        {t('vessels', 'Vessels')} {store.carrierTypeCount.vessel}
      </Button>
      <Button
        variant='contained'
        size='small'
        color={store.carrierType === CarrierType.Train ? 'secondary' : 'inherit'}
        onClick={() => store.setCarrierType(CarrierType.Train)}
      >
        {t('trains', 'Trains')} {store.carrierTypeCount.train}
      </Button>
    </Stack>
  )
})
