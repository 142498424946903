import { ErrorCodes, YardPositionDto } from '@storage/app/api'
import { isApplicationDomainException } from '@storage/app/http-client/interceptors/domain-exception.response-interceptor'
import { tolgee } from '@storage/app/translation'
import { ContainerPlanningService } from '@storage/services/container-planning.service'
import { AlertStore } from '@storage/stores/alert.store'
import { SnackbarStore } from '@storage/stores/snackbar.store'
import { action, makeObservable, observable } from 'mobx'

export class OperatorContainerTurnoverStore {
  yardPosition?: YardPositionDto
  previousPageLocation = ''

  constructor(
    private readonly _containerPlanningService: ContainerPlanningService,
    private readonly _snackbarStore: SnackbarStore,
    private readonly _alertStore: AlertStore,
  ) {
    makeObservable(this, {
      yardPosition: observable,
      previousPageLocation: observable,
      setPreviousPageLocation: action,
      setYardPosition: action,
      updateContainerPosition: action,
    })
  }

  public setYardPosition(yardPosition?: YardPositionDto) {
    this.yardPosition = yardPosition
  }

  public setPreviousPageLocation(location: string) {
    this.previousPageLocation = location
  }

  public async updateContainerPosition(containerTurnoverId?: string) {
    if (this.yardPosition && containerTurnoverId) {
      await this._containerPlanningService
        .updateContainerPosition(containerTurnoverId, this.yardPosition)
        .then(() =>
          this._snackbarStore.showMessage(
            tolgee.t(
              'containerPositionUpdateSuccess',
              `The container's position has been successfully updated`,
            ),
            'success',
          ),
        )
        .catch(error => {
          // Check for dg stacking rules violations
          if (
            isApplicationDomainException(error, ErrorCodes.DgStackingRulesViolationStackingOnTop)
          ) {
            this._alertStore.showAlert(
              ErrorCodes.DgStackingRulesViolationStackingOnTop,
              tolgee.t(
                'stackingValidationStackingOnTopOperator',
                `We can't use this location. There is a stacking rule that prohibits stacking on top.`,
              ),
            )
          } else if (
            isApplicationDomainException(error, ErrorCodes.DgStackingRulesViolationMaxAllowedTier)
          ) {
            this._alertStore.showAlert(
              ErrorCodes.DgStackingRulesViolationMaxAllowedTier,
              tolgee.t(
                'stackingValidationMaxStackTierOperator',
                `We can't use this location. There is a maximum tier stacking rule in place for this position.`,
              ),
            )
          } else if (isApplicationDomainException(error, ErrorCodes.DgSegregationRulesViolation)) {
            this._alertStore.showAlert(
              ErrorCodes.DgSegregationRulesViolation,
              tolgee.t(
                'segregationRulesViolationOperator',
                `We can't use this location, you action violates the current segreation rules.`,
              ),
            )
          } else {
            // Generic error
            this._snackbarStore.showMessage(
              tolgee.t(
                'cantPlanAtThisPosition',
                `Something went wrong. We can't use this location.`,
              ),
              'error',
            )
          }
        })
    }
  }

  get validationAlerts() {
    return this._alertStore.getAlerts()
  }

  clearAlerts() {
    this._alertStore.clearAlerts()
  }
}
