import { IOrderList } from '@planning/pages/Order/stores/OrderListUploadViewStoreV2'
import { CarrierVisitDirection } from '../api'
import { BaseAPI } from '../api/base'

export class BaplieParserApi extends BaseAPI {
  API_PATH = '/baplie-parser/api/v1/vesselVisits'

  public async uploadBaplie(
    carrierVisitId: number,
    direction: CarrierVisitDirection,
    portCodes: string[],
    baplieFile: File,
  ) {
    const url = `${this.basePath}${this.API_PATH}/${carrierVisitId}/uploadBaplieFile`

    const formData = new FormData()
    formData.append('baplieFile', baplieFile)
    formData.append('handlingDirection', direction)
    formData.append('portCodes', JSON.stringify(portCodes))

    return await this.axios.put<IOrderList>(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }
}
