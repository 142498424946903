import { Grid, Typography } from '@mui/material'
import { useOperationsStore } from '@operations/AppProvider'
import { WorkInstructionJobDto } from '@operations/app/api'
import { WorkInstructionJobGroupDto } from '@operations/features/equipmentOperator/models/job-group.model'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useSnackbar } from 'notistack'
import { WorkInstructionJobCard } from '../JobCard/WorkInstructionJobCard'

interface Props {
  jobGroups: WorkInstructionJobGroupDto[]
  highlightText?: string
}

export const WorkInstructionJobList = observer(({ jobGroups, highlightText }: Props) => {
  const { t } = useTranslate()

  const {
    equipmentOperatorUIStore,
    equipmentOperatorWorkInstructionStepperUIStore,
    tenantConfigStore,
  } = useOperationsStore()

  const { closeSnackbar } = useSnackbar()

  const handleConfirm = async (job: WorkInstructionJobDto) => {
    closeSnackbar()

    const jobsCountByThisVisit = jobGroups
      .flatMap(group => group.jobs)
      .filter(
        j => j.carrierVisit?.id === job.carrierVisit?.id && j.operationType === job.operationType,
      ).length

    equipmentOperatorWorkInstructionStepperUIStore.setIsLastInCarrierVisit(
      jobsCountByThisVisit === 1,
    )

    await equipmentOperatorWorkInstructionStepperUIStore.openStepper(job)
  }

  return (
    <>
      {jobGroups.map((group, index) => (
        <Grid
          key={`${group.groupName}_${index}`}
          container
          gap={theme => theme.customSpacing.m}
          marginBottom={theme => theme.customSpacing.xll}
        >
          {(jobGroups.length > 1 || group.groupName !== '') && (
            <Grid item xs={12}>
              <Typography variant='h3'>
                {group.groupName !== '' ? group.groupName : t('ungrouped', 'Ungrouped')}
              </Typography>
            </Grid>
          )}

          {group.jobs.map(job => (
            <Grid key={job.workInstructionId} item xs={12}>
              <WorkInstructionJobCard
                workInstructionJob={job}
                isConfirm={false}
                onConfirm={handleConfirm}
                highlightText={highlightText}
                fieldsToShow={tenantConfigStore.getOperatorFieldsByEquipmentAndOperationTypeAndCarrierType(
                  equipmentOperatorUIStore.typeOfSelectedEquipment,
                  job.operationType,
                  job.carrierVisit?.type,
                )}
              ></WorkInstructionJobCard>
            </Grid>
          ))}
        </Grid>
      ))}
    </>
  )
})
