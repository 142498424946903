import { Paper } from '@mui/material'
import { OrderGroupingStore } from '@planning/stores/grouping/OrderGroupingStore'
import { FC } from 'react'
import { ObserverGrouping } from './Grouping/ObserverGrouping'

export interface IProps {
  store: OrderGroupingStore
  disableSequencing?: boolean
}

export const OrderGrouping: FC<IProps> = ({ store, disableSequencing }) => {
  return (
    <Paper variant='elevation' elevation={1}>
      <ObserverGrouping store={store} disableSequencing={disableSequencing} />
    </Paper>
  )
}
