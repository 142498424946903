import { Box, SxProps, Typography } from '@mui/material'
import { ContainerTurnoverDto } from '@storage/app/api'
import { ManualPlanningTypes } from '@storage/pages/container-turnovers/stores/manual-planning-dialog.store'
import { TenantConfigStore } from '@storage/stores/tenantConfig.store'
import { useTranslate } from '@tolgee/react'
import { ContainerMovementInfo } from '@tom-ui/utils'
import { ContainerTurnoversListItem } from './ContainerTurnoversListItem'

interface Props {
  items: ContainerTurnoverDto[]
  onPlanItemManually: (id: string, planningType: ManualPlanningTypes) => void
  onMoveItemManually: (containers: ContainerMovementInfo[]) => void
  onPlanItemOnYard: (id: string) => void
  listSx?: SxProps
  tenantConfigStore?: TenantConfigStore
}

export const ContainerTurnoversList = ({
  items,
  onPlanItemManually,
  onPlanItemOnYard,
  onMoveItemManually,
  listSx,
  tenantConfigStore,
}: Props) => {
  const { t } = useTranslate()

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, pt: 1, pb: 4, ...listSx }}>
      {items.length > 0 ? (
        items.map(containerTurnover => (
          <ContainerTurnoversListItem
            key={containerTurnover.id}
            item={containerTurnover}
            onManualPlanning={onPlanItemManually}
            onPlanOnYard={onPlanItemOnYard}
            tenantConfigStore={tenantConfigStore}
            onMoveItemManually={onMoveItemManually}
          />
        ))
      ) : (
        <Box sx={{ textAlign: 'center' }}>
          <Typography>{t('noDataAvailable', 'No data available')}</Typography>
        </Box>
      )}
    </Box>
  )
}
