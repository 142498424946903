import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
  debounce,
} from '@mui/material'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { usePlanningStore } from '@planning/AppProvider'
import { CargoType, CreateVesselVisitCommand, VesselDto } from '@planning/app/api'
import { CarrierVisitNavigateButton } from '@planning/components/CarrierVisitNavigateButton'
import {
  CarrierVisitSchedule,
  ICarrierVisitSchedule,
  carrierVisitScheduleDefaultValues,
} from '@planning/components/CarrierVisitSchedule'
import { CargoTypeSelect } from '@planning/components/visit/CargoTypeSelect'
import { vesselVisitService } from '@planning/services'
import vesselService from '@planning/services/vesselService'
import { useTranslate } from '@tolgee/react'
import { BinIcon } from '@tom-ui/ui'
import dayjs, { Dayjs } from 'dayjs'
import { observer } from 'mobx-react-lite'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

export interface ICreateVesselVisitFormData {
  eta?: Dayjs
  etb?: Dayjs
  etd?: Dayjs
  vesselIds: number[]
  importVoyage: string
  exportVoyage: string
  importDraft: string
  exportDraft: string
  dischargeEstimate: string
  loadEstimate: string
  cargoType: CargoType
}

export const CreateVesselVisitForm: FC<{ onClose?: () => void }> = observer(({ onClose }) => {
  const { appViewStore, tenantStore } = usePlanningStore()
  const { t } = useTranslate()
  const [schedule, setSchedule] = useState<ICarrierVisitSchedule>(carrierVisitScheduleDefaultValues)

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      eta: dayjs(),
      etb: dayjs(),
      etd: dayjs().add(1, 'day'),
      vesselIds: [],
      importVoyage: '',
      exportVoyage: '',
      importDraft: '',
      exportDraft: '',
      dischargeEstimate: '',
      loadEstimate: '',
      repeatingVisit: '',
      cargoType: CargoType.Container,
    } as ICreateVesselVisitFormData,
  })

  const onFormSubmit = async (data: ICreateVesselVisitFormData) => {
    try {
      const createCmd = {
        vesselIds: data.vesselIds,
        eta: dayjs(data.eta) as any,
        etb: dayjs(data.etb) as any,
        etd: dayjs(data.etd) as any,
        inboundTripIds: tripIdsInputs.flatMap(obj => [obj.inbound]),
        outboundTripIds: tripIdsInputs.flatMap(obj => [obj.outbound]),
        importDraft: data.importDraft ? parseFloat(data.importDraft.toString()) : 0.0,
        exportDraft: data.exportDraft ? parseFloat(data.exportDraft.toString()) : 0.0,
        loadEstimate: data.loadEstimate ? parseInt(data.loadEstimate.toString()) : 0,
        dischargeEstimate: data.dischargeEstimate ? parseInt(data.dischargeEstimate.toString()) : 0,
        cargoType: data.cargoType,
        schedule: schedule.showTimingOptions
          ? {
              repeatEvery: parseFloat(schedule.repeatEvery.toString()),
              repeatBy: schedule.repeatBy,
              ...(schedule.endsOn &&
                schedule.selectedRadioOption === 'on' && { endsOn: schedule.endsOn }),
              ...(schedule.endsAfter &&
                schedule.selectedRadioOption === 'after' && {
                  endsAfter: parseFloat(schedule.endsAfter.toString()),
                }),
            }
          : null,
      } as CreateVesselVisitCommand

      const responseDto = await vesselVisitService.post(createCmd)

      appViewStore.setCreatedVesselVisitId(responseDto[0].id)
      appViewStore.setShowAlert(
        'success',
        'Vessel visit created successfully',
        <CarrierVisitNavigateButton />,
      )
      onClose?.()
    } catch (error) {
      appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
    }
  }

  const handleDateChange = (newValue: dayjs.Dayjs | null, onChange: any) => {
    if (newValue) {
      onChange(newValue)
    }
  }

  const OnSearchFilterChange = debounce(async (filter?: string) => {
    setOptions((await vesselService.get(1, 10, filter)).payload)
  }, 500)

  const [open, setOpen] = useState(false)
  const [tripIdsInputs, setTripIdsInputs] = useState([{ id: 1, inbound: '', outbound: '' }])

  const duplicateTripIdsInputs = () => {
    const newId = Math.max(...tripIdsInputs.map(ti => ti.id), 0) + 1
    setTripIdsInputs([...tripIdsInputs, { id: newId, inbound: '', outbound: '' }])
  }

  const removeTripIds = (idToRemove: number) => {
    if (tripIdsInputs.length === 1) {
      setTripIdsInputs([{ id: 1, inbound: '', outbound: '' }])
    } else {
      const filteredInputs = tripIdsInputs.filter(input => input.id !== idToRemove)
      setTripIdsInputs(filteredInputs)
    }
  }

  const handleTripIdInputChanges = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    id: number,
    type: string,
  ) => {
    const { value } = event.target
    const updatedTripIds = tripIdsInputs.map(tripId => {
      if (tripId.id === id) {
        return { ...tripId, [type]: value }
      }
      return tripId
    })

    setTripIdsInputs(updatedTripIds)
  }

  const [options, setOptions] = useState<readonly VesselDto[]>([])

  useEffect(() => {
    if (open) {
      const fetch = async () => {
        return (await vesselService.get(1, 10)).payload
      }

      fetch().then(payload => setOptions(payload))
    } else {
      setOptions([])
    }
  }, [open])

  return (
    <form id='create-vessel-visit' onSubmit={handleSubmit(onFormSubmit)}>
      <Controller
        control={control}
        name={`vesselIds`}
        rules={{ required: true }}
        render={({ field: { onChange } }) => (
          <Autocomplete
            sx={{ mb: '1rem' }}
            disablePortal
            multiple
            getOptionLabel={(vessel: VesselDto) => vessel.name}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            options={options}
            isOptionEqualToValue={(option: VesselDto, value: VesselDto) => option.id === value.id}
            onChange={(_, selectedVessel: VesselDto[] | null) => {
              onChange(selectedVessel?.map(v => v.id) ?? -1)
            }}
            renderInput={params => (
              <TextField
                {...params}
                data-cy='create-vessel-visit-form-vessel-field'
                label={t('vessel', 'Vessel')}
                helperText={errors.vesselIds ? t('fieldIsRequired', 'Field is required.') : ''}
                error={!!errors.vesselIds}
                onChange={e => OnSearchFilterChange(e.target.value)}
              />
            )}
          />
        )}
      />
      <Controller
        control={control}
        name={`cargoType`}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <CargoTypeSelect value={value} onChange={onChange} />
        )}
      />

      <Typography variant='subtitle1' marginTop='1rem' marginBottom='0.5rem'>
        {t('timingsEstimated', 'Timings (Estimated)')}
      </Typography>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container direction='row' columnSpacing={{ xs: 1 }}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={`eta`}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <DateTimePicker
                  label={t('eta', 'ETA')}
                  value={value}
                  onChange={newValue => handleDateChange(newValue, onChange)}
                  slotProps={{
                    textField: {
                      variant: 'outlined',
                      helperText: errors.eta ? t('fieldIsRequired', 'Field is required.') : '',
                      error: !!errors.eta,
                      sx: { width: '100%', marginTop: '10px' },
                    },
                  }}
                />
              )}
            />
          </Grid>
          {!tenantStore.skipBerthTimestamp && (
            <Grid item xs={12}>
              <Controller
                control={control}
                name={`etb`}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <DateTimePicker
                    label={t('etb', 'ETB')}
                    value={value}
                    onChange={newValue => handleDateChange(newValue, onChange)}
                    slotProps={{
                      textField: {
                        variant: 'outlined',
                        helperText: errors.etb ? t('fieldIsRequired', 'Field is required.') : '',
                        error: !!errors.etb,
                        sx: { width: '100%', marginTop: '10px' },
                      },
                    }}
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Controller
              control={control}
              name={`etd`}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <DateTimePicker
                  label={t('etd', 'ETD')}
                  value={value}
                  onChange={newValue => handleDateChange(newValue, onChange)}
                  slotProps={{
                    textField: {
                      variant: 'outlined',
                      helperText: errors.etd ? t('fieldIsRequired', 'Field is required.') : '',
                      error: !!errors.etd,
                      sx: { width: '100%', marginTop: '10px' },
                    },
                  }}
                />
              )}
            />
          </Grid>
        </Grid>

        <CarrierVisitSchedule schedule={schedule} handleSchedule={setSchedule} />
      </LocalizationProvider>

      <Typography variant='subtitle1' marginTop='1rem' marginBottom='0.5rem'>
        {t('tripIds', 'Trip IDs')}
      </Typography>
      <Grid container>
        {tripIdsInputs.map((input, index) => (
          <Grid key={index} container direction='row' columnSpacing={{ xs: 1 }} mb={'.5rem'}>
            <Grid item xs={5}>
              <TextField
                size='small'
                fullWidth
                value={input.inbound}
                label={t('inboundTripId', 'Inbound Trip ID')}
                variant='outlined'
                name='importVoyage'
                onChange={event => handleTripIdInputChanges(event, input.id, 'inbound')}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                size='small'
                fullWidth
                value={input.outbound}
                label={t('outboundTripId', 'Outbound Trip ID')}
                variant='outlined'
                name='exportVoyage'
                onChange={event => handleTripIdInputChanges(event, input.id, 'outbound')}
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton color='error' onClick={() => removeTripIds(input.id)}>
                <BinIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}

        <Grid item mt={'.5rem'}>
          <Button size='small' onClick={duplicateTripIdsInputs}>
            + Add
          </Button>
        </Grid>
      </Grid>
      <Typography variant='subtitle1' marginTop='1rem' marginBottom='0.5rem'>
        {t('vesselDrafts', 'Vessel Drafts')}
      </Typography>
      <Grid container direction='row' columnSpacing={{ xs: 1 }}>
        <Grid item xs={6}>
          <TextField
            data-cy='create-vessel-visit-form-import-draft-field'
            fullWidth
            type='number'
            inputProps={{
              min: 0.1,
              max: 13,
              step: '0.01',
            }}
            label={t('importDraftM', 'Import Draft (m)')}
            variant='outlined'
            {...register('importDraft')}
            name='importDraft'
            onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            data-cy='create-vessel-visit-form-export-draft-field'
            type='number'
            inputProps={{
              min: 0.1,
              max: 13,
              step: '0.01',
            }}
            label={t('exportDraftM', 'Export Draft (m)')}
            variant='outlined'
            {...register('exportDraft')}
            name='exportDraft'
            onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
          />
        </Grid>
      </Grid>
      <Typography variant='subtitle1' marginTop='1rem' marginBottom='0.5rem'>
        {t('dischargeEstimates', 'Discharge Estimates')}
      </Typography>
      <TextField
        fullWidth
        type='number'
        data-cy='create-vessel-visit-form-discharge-estimate-field'
        label={t('dischargeEstimate', 'Discharge Estimate')}
        variant='outlined'
        {...register('dischargeEstimate')}
        name='dischargeEstimate'
        onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
        inputProps={{ min: 1 }}
      />
      <Typography variant='subtitle1' marginTop='1rem' marginBottom='0.5rem'>
        {t('loadEstimates', 'Load Estimates')}
      </Typography>
      <TextField
        fullWidth
        type='number'
        data-cy='create-vessel-visit-form-load-estimate-field'
        label={t('loadEstimate', 'Load Estimate')}
        variant='outlined'
        {...register('loadEstimate')}
        name='loadEstimate'
        onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
        inputProps={{ min: 1 }}
      />
    </form>
  )
})
