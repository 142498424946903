import { Box, Card, Typography } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { LinearProgressBarWithTextAndButton } from './LinearProgressBarWithTextAndButton'
import { useTranslate } from '@tolgee/react'
import {
  getOccupiedCapacityState,
  getProgressBarValue,
} from '@storage/stores/yard-block-summary.store'

interface YardBlockDetailsProps {
  blockName: string
  totalSpace: number
  occupiedSpace: number
  filterMatchedContainers: number
  nonFilterMatchedContainers: number
  onDetailsClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export const YardBlockDetails = ({
  blockName,
  totalSpace,
  occupiedSpace,
  filterMatchedContainers,
  nonFilterMatchedContainers,
  onDetailsClick,
}: YardBlockDetailsProps) => {
  const { palette } = useMinimalsTheme()
  const { t } = useTranslate()
  return (
    <Card sx={{ p: 2, width: '288px', borderRadius: '8px' }}>
      <LinearProgressBarWithTextAndButton
        text={blockName}
        progressBarValue={getProgressBarValue(totalSpace, occupiedSpace)}
        state={getOccupiedCapacityState(totalSpace, occupiedSpace)}
        buttonLabel={t('details', 'Details')}
        buttonOnClickAction={onDetailsClick}
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }} pt={1}>
        <Typography variant='caption' color={palette.text.disabled}>
          {`${occupiedSpace}/${totalSpace}`}
        </Typography>
        <Typography variant='caption' color={palette.text.disabled}>{`${
          totalSpace - occupiedSpace
        } ${t('left', 'left')}`}</Typography>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between' }} pt={1}>
        <Typography variant='body2'>{t('filerMatch', 'Filter match')}</Typography>
        <Typography variant='body2'>{t('others', 'Others')}</Typography>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant='caption' color={palette.text.disabled}>
          {filterMatchedContainers}
        </Typography>
        <Typography variant='caption' color={palette.text.disabled}>
          {nonFilterMatchedContainers}
        </Typography>
      </Box>
    </Card>
  )
}
