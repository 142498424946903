import { Stack } from '@mui/material'
import { CoolingOrderDto } from '@operations/app/api'
import { useOperationsStore } from '@operations/AppProvider'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { CoolingOrderCard } from '../CoolingOrderCard/CoolingOrderCards'

interface Props {
  orders: CoolingOrderDto[]
  highlightText?: string
}
export const CoolingOrderList = observer(({ orders, highlightText }: Props) => {
  const { coolingOrdersUIStore } = useOperationsStore()
  const theme = useMinimalsTheme()

  return (
    <Stack gap={theme.customSpacing.m} paddingBottom={theme.customSpacing.m}>
      {orders.map(x => (
        <CoolingOrderCard
          key={x.id}
          order={x}
          highlightText={highlightText}
          onClick={id => coolingOrdersUIStore.toggleStepper(id)}
        />
      ))}
    </Stack>
  )
})
