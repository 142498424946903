import { Button, Stack, Typography } from '@mui/material'
import { useOperationsStore } from '@operations/AppProvider'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'
import { SelectEquipment } from '../components/SelectEquipment/SelectEquipment'
import { SelectOperationType } from '../components/SelectEquipment/SelectOperationType'
import { SelectVesselVisit } from '../components/SelectEquipment/SelectVesselVisit'

export const EquipmentOperatorLandingContainer = observer(() => {
  const { t } = useTranslate()
  const navigate = useNavigate()

  const { equipmentOperatorLandingUIStore } = useOperationsStore()

  return (
    <Stack justifyContent='center' alignItems='center' height={'70vh'}>
      {equipmentOperatorLandingUIStore.equipmentOptions.length ? (
        <Stack gap={theme => theme.customSpacing.l} width={'270px'} alignItems='center'>
          <Typography variant='h1'>{t('selectEquipment', 'Select equipment')}</Typography>

          <SelectEquipment uiStore={equipmentOperatorLandingUIStore} />
          {equipmentOperatorLandingUIStore.displayVesselVisitSelector && (
            <SelectVesselVisit uiStore={equipmentOperatorLandingUIStore} />
          )}
          {equipmentOperatorLandingUIStore.displayOperationsTypeSelector && (
            <SelectOperationType uiStore={equipmentOperatorLandingUIStore} />
          )}

          <Button
            variant='contained'
            color='primary'
            fullWidth
            disabled={!equipmentOperatorLandingUIStore.selectedEquipmentId}
            onClick={() => {
              navigate(`${equipmentOperatorLandingUIStore.selectedEquipmentId}`)
            }}
            data-cy='operator-landing-continue-btn'
          >
            {t('continue', 'Continue')}
          </Button>
        </Stack>
      ) : (
        <Typography variant='subtitle1' color='secondary'>
          {t('noEquipmentFound', 'No equipment found')}
        </Typography>
      )}
    </Stack>
  )
})
