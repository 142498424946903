import { Box, Step, StepLabel, Stepper } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { OrderListUploadViewStoreV2 } from '@planning/pages/Order/stores/OrderListUploadViewStoreV2'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

export interface IProps {
  store: OrderListUploadViewStoreV2
}

export interface IStep {
  id: number
  title: string
  optional?: boolean
}

export const OrderListUploadStepper: FC<IProps> = observer(({ store }) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const steps: IStep[] = [
    { id: 0, title: t('addFile', 'Add file') },
    { id: 1, title: t('reviewAndEdit', 'Review & Edit') },
  ]

  const isCompleted = (step: IStep) => store.activeStep > step.id
  const isError = (step: IStep) => !!store.parsingStore.uploadFailedReason && step.id === 0

  const getStepLabel = (step: IStep) => {
    return (
      <Step key={step.title} completed={isCompleted(step)}>
        <StepLabel error={isError(step)}>{step.title}</StepLabel>
      </Step>
    )
  }

  return (
    <Box
      sx={{
        p: '1rem',
        bgcolor: theme.palette.grey[200],
        borderTop: '1px solid rgba(145, 158, 171, 0.24)',
        borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
      }}
    >
      <Stepper activeStep={store.activeStep} alternativeLabel>
        {steps.map(getStepLabel)}
      </Stepper>
    </Box>
  )
})
