import { Box, InputAdornment, TextField } from '@mui/material'
import { SearchIcon, useMinimalsTheme } from '@tom-ui/ui'
import { usePlanningStore } from '@planning/AppProvider'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'

interface IProps {
  onClickFilter: () => void
  onSearchChange: (filter: string) => void
}

export const SearchBar = observer(({ onClickFilter, onSearchChange }: IProps) => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()
  const { generalCargoViewStore } = usePlanningStore()

  return (
    <Box
      display='flex'
      alignContent={'space-between'}
      alignItems={'center'}
      gap={theme.customSpacing.m}
      mb={theme.customSpacing.l}
    >
      <Box width={'100%'}>
        <TextField
          fullWidth
          value={generalCargoViewStore.generalCargoSearchStore.filter}
          placeholder={t('searchCustomerOrReferenceNo', 'Search customer or reference no')}
          onChange={event => onSearchChange(event?.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      {/* <Box>
        <Button
          variant='contained'
          color='inherit'
          sx={{ bgcolor: theme.palette.secondary.light }}
          endIcon={<FilterIcon />}
          onClick={onClickFilter}
        >
          Filter
        </Button>
      </Box> */}
    </Box>
  )
})
