import { Box, Container, Stack, Typography } from '@mui/material'
import { FC } from 'react'

interface Props {
  title: string
  children: React.ReactElement
  actions?: React.ReactElement
}

export const VesselVisitHeaderContainer: FC<Props> = ({ title, actions, children }) => {
  return (
    <Container sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
      <Stack direction='row' justifyContent='space-between' mt='0.5rem' sx={{ minHeight: '2rem' }}>
        <Typography variant='subtitle1'>{title}</Typography>
        {actions}
      </Stack>
      <Box sx={{ mt: '0.5rem', height: '100%' }}>{children}</Box>
    </Container>
  )
}
