import { KonvaEventObject } from 'konva/lib/Node'

type KonvaMouseEvent = KonvaEventObject<MouseEvent>

const useHoverKonva = () => {
  const getContainer = (event: KonvaMouseEvent) => event.target.getStage()?.container()

  const setContainerCursor = (
    container?: HTMLDivElement,
    cursor: 'pointer' | 'default' = 'default',
  ) => {
    if (!container) return

    container.style.cursor = cursor
  }

  return {
    onMouseEnter: (event: KonvaMouseEvent) => {
      const container = getContainer(event)

      setContainerCursor(container, 'pointer')
    },
    onMouseLeave: (event: KonvaMouseEvent) => {
      const container = getContainer(event)

      setContainerCursor(container)
    },
  }
}

export default useHoverKonva
