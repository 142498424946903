import { VesselDto, VesselsApi } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'
import { Event, IEvent, IQueryHandler, IQueryMessage } from '../messageBus'

export class GetVesselsByIdsQuery implements IQueryMessage {
  static type = 'GetVesselsByIdsQuery'
  type = GetVesselsByIdsQuery.type

  constructor(public ids: number[]) {}

  getKey = () => {
    return JSON.stringify(this)
  }
}

const httpClient = createApiClient(VesselsApi)

export const getVesselsByIdsQueryHandler: IQueryHandler<
  GetVesselsByIdsQuery,
  IEvent<VesselDto[]>
> = async query => {
  const { data } = await httpClient.getByIds(query.ids)

  return new Event(GetVesselsByIdsQuery.type, data)
}
