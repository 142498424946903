import { useBillingServiceStore } from '@billing/AppProvider'
import ReportStep from '@billing/app/models/report-step.enum'
import exportService from '@billing/services/ExportService'
import { observer } from 'mobx-react-lite'
import { CustomerBillingReportFilter } from '../components/customerBillingReport/CustomerBillingReportFilter'
import { ReportNavigationButtonContainer } from './ReportNavigationButtonContainer'

export const GCServicesPrebillingContainer = observer(() => {
  const { reportsUIStore, customerBillingUIStore } = useBillingServiceStore()

  const finish = () => {
    customerBillingUIStore.resetData()
    reportsUIStore.resetStepper()
  }
  return (
    <>
      {reportsUIStore.currentStep === ReportStep.Filter && <CustomerBillingReportFilter />}
      <ReportNavigationButtonContainer
        disableNext={false}
        downloadAction={() =>
          exportService.exportServicesPreBillingData(
            parseInt(customerBillingUIStore.customerId),
            customerBillingUIStore.from,
            customerBillingUIStore.to,
          )
        }
        finishAction={finish}
      />
    </>
  )
})
