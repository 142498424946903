import { ContainerTurnoverStore } from '@storage/stores/container-turnover.store'
import { IReactionDisposer, reaction } from 'mobx'
import { ContainerTurnoverFilterFormMapper } from '../components/container-turnovers-filter-form'
import { ContainerTurnoversFilterStore } from './container-turnovers-filter.store'
import { ContainerTurnoversPaginationStore } from './container-turnovers-pagination.store'
import { ContainerStatus } from '@storage/app/api'

export class ContainerTurnoversListStore {
  constructor(
    private readonly _containerTurnoverStore: ContainerTurnoverStore,
    private readonly _containerTurnoversFilterStore: ContainerTurnoversFilterStore,
    private readonly _containerTurnoversPaginationStore: ContainerTurnoversPaginationStore,
  ) {}

  public loadContainersEffect(): IReactionDisposer {
    return reaction(
      () => ({
        filter: this._containerTurnoversFilterStore.filter,
        pagination: this._containerTurnoversPaginationStore.pagination,
      }),
      ({ filter, pagination }) => {
        this._containerTurnoverStore.loadAll(
          ContainerTurnoverFilterFormMapper.mapFormValuesToFilterDto(filter),
          pagination,
        )
      },
      { fireImmediately: true },
    )
  }

  public async reload(): Promise<void> {
    await this._containerTurnoverStore.loadAll(
      ContainerTurnoverFilterFormMapper.mapFormValuesToFilterDto(
        this._containerTurnoversFilterStore.filter,
      ),
      this._containerTurnoversPaginationStore.pagination,
    )
  }

  public loadEffectForContainersOnTerminal() {
    return reaction(
      () => ({
        filter: this._containerTurnoversFilterStore.filter,
        pagination: this._containerTurnoversPaginationStore.pagination,
      }),
      ({ filter, pagination }) => {
        this._containerTurnoverStore.loadAll(
          ContainerTurnoverFilterFormMapper.mapFormValuesToFilterDto({
            ...filter,
            locationStatus: ContainerStatus.OnTheYard,
          }),
          pagination,
        )
      },
      { fireImmediately: true },
    )
  }
}
