import { EquipmentOperatorWorkInstructionStepperUIStore } from '@operations/features/equipmentOperator/stores/equipment-operator-work-instruction-stepper.ui-store'
import { CloseButton } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'

interface Props {
  uiStore: EquipmentOperatorWorkInstructionStepperUIStore
  onClose: () => Promise<void> | null
}
export const CloseWorkInstructionStepper = observer(({ uiStore, onClose }: Props) => {
  const handleClose = async () => {
    if (onClose) {
      onClose()
    }

    uiStore.closeStepper()
  }

  return <CloseButton onClose={handleClose} />
})
