import {
  DGSegregationRuleApi,
  DGSegregationRuleDeleteBulkRequest,
  DGSegregationRuleDto,
  DGSegregationRuleUpsertBulkRequest,
} from '@storage/app/api'

export class DGSegregationRuleService {
  constructor(private api: DGSegregationRuleApi) {}

  async getAll(): Promise<Array<DGSegregationRuleDto>> {
    const { data: dbSegregationRules } = await this.api.getAll()
    return dbSegregationRules
  }

  async upsertBulk(
    dGSegregationRuleUpsertBulkRequest?: DGSegregationRuleUpsertBulkRequest,
  ): Promise<Array<DGSegregationRuleDto>> {
    const { data: dbSegregationRules } = await this.api.upsertBulk({
      dGSegregationRuleUpsertBulkRequest,
    })
    return dbSegregationRules
  }

  async deleteBulk(
    dGSegregationRuleDeleteBulkRequest?: DGSegregationRuleDeleteBulkRequest,
  ): Promise<void> {
    await this.api.deleteBulk({
      dGSegregationRuleDeleteBulkRequest,
    })
  }
}
