import { Stack } from '@mui/material'
import { ColoredLabel } from '@tom-ui/ui'
import { useTranslate } from '@tolgee/react'

interface Props {
  isInOperation?: boolean
  isUnavailable?: boolean
  alignment?: 'start' | 'end'
}

export const EquipmentStatusChip = ({ isInOperation, isUnavailable, alignment }: Props) => {
  const { t } = useTranslate()

  return (
    <Stack alignItems={alignment ?? 'start'}>
      {isInOperation && <ColoredLabel label={t('inOperation', 'In operation')} color='busy' />}
      {!isUnavailable && isInOperation === false && (
        <ColoredLabel label={t('available', 'Available')} color='success' />
      )}

      {!isInOperation && isUnavailable && (
        <ColoredLabel label={t('unavailable', 'Unavailable')} color='warning' />
      )}
    </Stack>
  )
}
