import { downloadFile, getFileNameFromAxiosResponse } from '@tom-ui/utils'
import { GcsApi } from '../api'
import { createApiClient } from '../http-client'

class GcsService {
  httpClient = createApiClient(GcsApi)

  async downloadFiles(paths: string[]) {
    const promises = paths.map(path => {
      return this.download(path)
    })

    const files = await Promise.all(promises)

    files.map(file => downloadFile(file.blob, file.filename))
  }

  async download(path: string): Promise<{ blob: Blob; filename: string }> {
    const response = await this.httpClient.download(
      { path: path },
      {
        responseType: 'blob',
      },
    )
    const data = response.data as unknown

    const filename = getFileNameFromAxiosResponse(response)
    const blob = new Blob([data as BlobPart])
    return { blob, filename }
  }

  async deleteFiles(paths: string[]) {
    const promises = paths.map(path => {
      return this.delete(path)
    })

    return await Promise.all(promises)
  }

  async delete(path: string): Promise<void> {
    await this.httpClient._delete({ path: path })
  }
}

export default new GcsService()
