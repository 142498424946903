import { createSvgIcon } from '@mui/material'

export const OrdersIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17 21H7V7H8V5.5C8 4.11929 9.11929 3 10.5 3H13.5C14.8807 3 16 4.11929 16 5.5V7H17V21ZM10.5 5C10.2239 5 10 5.22386 10 5.5V7H14V5.5C14 5.22386 13.7761 5 13.5 5H10.5Z'
      fill='currentColor'
    />
    <path
      d='M19 7V21C20.6569 21 22 19.6569 22 18V10C22 8.34315 20.6569 7 19 7Z'
      fill='currentColor'
    />
    <path d='M5 7C3.34315 7 2 8.34315 2 10V18C2 19.6569 3.34315 21 5 21V7Z' fill='currentColor' />
  </svg>,
  'OrdersIcon',
)
