import {
  ContainerDamageTypeDto,
  ContainerDamageTypesApi,
  CreateContainerDamageTypeCommand,
  UpdateContainerDamageTypeCommand,
} from '@admin/app/api'
import { createApiClient } from '@admin/app/http-client'
import { BaseEntityStore } from './base/BaseEntityStore'

export class ContainerDamageTypeStore extends BaseEntityStore<ContainerDamageTypeDto> {
  async load() {
    const { data } = await createApiClient(ContainerDamageTypesApi).containerDamageTypesGet()
    this.updateStoreItems(data)
  }

  async add(containerDamageType: CreateContainerDamageTypeCommand) {
    const { data } =
      await createApiClient(ContainerDamageTypesApi).containerDamageTypesPost(containerDamageType)
    this.updateStoreItem({ ...containerDamageType, isDefault: false, id: data }, data)
  }

  async update(containerDamageType: UpdateContainerDamageTypeCommand, isDefault?: boolean) {
    await createApiClient(ContainerDamageTypesApi).containerDamageTypesPut(
      containerDamageType.id,
      containerDamageType,
    )
    this.updateStoreItem({ ...containerDamageType, isDefault: !!isDefault }, containerDamageType.id)
  }

  async delete(id: number) {
    await createApiClient(ContainerDamageTypesApi).containerDamageTypesDelete(id)
    this.deleteStoreItem(id)
  }
}
