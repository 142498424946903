import { ContainerDamageTypeDto } from '@admin/app/api'
import { BaseDrawerStore } from '@admin/stores/base/BaseDrawerStore'
import { ContainerDamageTypeStore } from '@admin/stores/ContainerDamageTypeStore'
import { action, makeObservable, observable } from 'mobx'

export class ContainerDamageTypeDialogUIStore extends BaseDrawerStore<ContainerDamageTypeDto> {
  isDeleteOpen = false

  constructor(private containerDamageTypeStore: ContainerDamageTypeStore) {
    super(containerDamageTypeStore)
    makeObservable(this, {
      isDeleteOpen: observable,

      toggleDelete: action,
    })
  }

  public toggleDelete(): void {
    this.isDeleteOpen = !this.isDeleteOpen
  }
}
