import {
  CreateRequest2,
  FinishWorkInstructionJobCommand,
  WorkInstructionDto,
  WorkInstructionsApi,
} from '@operations/app/api'
import { createApiClient } from '@operations/app/http-client'
import { BaseEntityStore } from './base/BaseEntityStore'

export class WorkInstructionStore extends BaseEntityStore<WorkInstructionDto> {
  async load(vesselId?: number) {
    const { data } = await createApiClient(WorkInstructionsApi).get(vesselId)
    this.updateStoreItems(data)
  }

  async loadWorkInstructionsAsJobs() {
    const { data } = await createApiClient(WorkInstructionsApi).getWorkInsturctionsAsJobs()

    return data
  }

  async getRailcarTrackPositions(railVisitId?: number) {
    const { data } =
      await createApiClient(WorkInstructionsApi).railcarTrackPositionsByRailVisitId(railVisitId)

    return data
  }

  async create(instruction: CreateRequest2) {
    await createApiClient(WorkInstructionsApi).create(instruction)
  }

  async finishJob(finishJobCommand: FinishWorkInstructionJobCommand) {
    await createApiClient(WorkInstructionsApi).finishWorkInstructionJob(finishJobCommand)
  }
}
