import {
  CarrierType,
  CarrierVisitDirection,
  OrderListOrderDto,
  RailTrackResponseDto,
} from '@planning/app/api'
import { BaplieParserApi } from '@planning/app/baplie-parser-api/baplie-parser-api'
import { createApiClient } from '@planning/app/http-client'
import { IOrderDto, IOrderList } from '@planning/pages/Order/stores/OrderListUploadViewStoreV2'
import { parseDate } from '@planning/utils'
import CSVFileValidator, { FieldSchema, ParsedResults, RowError } from 'csv-file-validator'
import Papa from 'papaparse'

export class FileFormatError extends Error {
  constructor(msg: string) {
    super(msg)

    Object.setPrototypeOf(this, FileFormatError.prototype)
  }
}

export class DuplicateRailcarsWithDifferentRailTracksError extends Error {
  constructor(public railcars: string[]) {
    super(`Duplicate railcars with different rail tracks`)

    Object.setPrototypeOf(this, DuplicateRailcarsWithDifferentRailTracksError.prototype)
  }
}
export class DuplicateRailcarsWithDifferentSequenceError extends Error {
  constructor(public railcars: string[]) {
    super(`Duplicate rail cars with different sequence`)

    Object.setPrototypeOf(this, DuplicateRailcarsWithDifferentSequenceError.prototype)
  }
}
export class DuplicateSequencesWithDifferentRailcarsError extends Error {
  constructor(public lineNumbers: number[]) {
    super(`Duplicate sequences with different railcars`)

    Object.setPrototypeOf(this, DuplicateSequencesWithDifferentRailcarsError.prototype)
  }
}

export class MissingRailtrackError extends Error {
  constructor(public railcars: string[]) {
    super(`Found railcars with missing rail track`)

    Object.setPrototypeOf(this, MissingRailtrackError.prototype)
  }
}

export class InvalidRailtrackError extends Error {
  constructor(public railTracks: string[]) {
    super(
      `Found railcars with invalid tracks. Either use the tracks assigned to the visit or assign the missing track`,
    )

    Object.setPrototypeOf(this, InvalidRailtrackError.prototype)
  }
}

export class NotPlannedRailtrackError extends Error {
  constructor(public railTracks: string[]) {
    super(
      `Found railcars with invalid tracks. Either use the tracks assigned to the visit or assign the missing track`,
    )

    Object.setPrototypeOf(this, NotPlannedRailtrackError.prototype)
  }
}

const csvHeadersConfigBase = [
  {
    name: 'Reference Number',
    inputName: 'referenceNumber',
    required: true,
  },
  {
    name: 'Container Number',
    inputName: 'containerNumber',
    required: true,
  },
  {
    name: 'IsoCode',
    inputName: 'isoCode',
  },
  {
    name: 'Gross Weight',
    inputName: 'grossWeight',
  },
  {
    name: 'ImoClasses',
    inputName: 'imoClasses',
  },
  {
    name: 'IsEmpty',
    inputName: 'isEmpty',
  },
  {
    name: 'PortOfLoading',
    inputName: 'portOfLoading',
  },
  {
    name: 'PortOfDischarge',
    inputName: 'portOfDischarge',
  },
  {
    name: 'Operator',
    inputName: 'operator',
  },
  {
    name: 'Temperature',
    inputName: 'temperature',
  },
  {
    name: 'TypeCode',
    inputName: 'typeCode',
  },
  {
    name: 'FinalDestination',
    inputName: 'finalDestination',
  },
  {
    name: 'UnNumber',
    inputName: 'unNumber',
  },
  {
    name: 'Consignee',
    inputName: 'consignee',
  },
  {
    name: 'AtConsignee',
    inputName: 'atConsignee',
  },
  {
    name: 'Notes',
    inputName: 'notes',
  },
  {
    name: 'Content',
    inputName: 'content',
  },
  { name: 'HasSeals', inputName: 'hasSeals' },
  { name: 'Seals', inputName: 'seals' },
]

const csvFieldSchemaMap = new Map<CarrierType, FieldSchema[] | undefined>([
  [CarrierType.Vessel, [...csvHeadersConfigBase]],
  [CarrierType.Train, [...csvHeadersConfigBase, { name: 'Waggon', inputName: 'waggon' }]],
  [CarrierType.Truck, undefined],
  [CarrierType.Universal, undefined],
])

export interface IOrderListCsvData {
  referenceNumber: string
  containerNumber: string
  grossWeight?: number
  isEmpty?: string
  portOfLoading?: string
  portOfDischarge?: string
  operator?: string
  imoClasses?: string
  temperature?: string
  typeCode?: string
  finalDestination?: string
  unNumber?: string
  consignee?: string
  atConsignee?: string
  notes?: string
  operationalInstructions?: string
  content?: string
  waggon?: string
  sequence?: string
  track?: string
  doorDirection?: string
  hasSeals?: string
  seals?: string
}

class OrderListParsingService {
  private baplieParserClient = createApiClient(BaplieParserApi)

  private booleanTrueString = 'TRUE'
  private convertBool = (str?: string) => str === this.booleanTrueString

  private splitStringIntoArray = (str?: string) =>
    str?.split('/').filter((str: string) => str !== '') ?? []

  private cleanUpAndSortParsedResults = (data: any, headerConfig: any) => {
    const schema = headerConfig
    const dataArr: { [key: string]: any }[] = []

    data.forEach((d: any) => {
      const objectKeys = Object.keys(d).filter(key =>
        schema.some((field: { name: string }) => field.name === key),
      )

      objectKeys.sort((a, b) => {
        const indexA = schema.findIndex((field: { name: string }) => field.name === a)
        const indexB = schema.findIndex((field: { name: string }) => field.name === b)
        return indexA - indexB
      })

      const sortedObject: {
        [key: string]: any
      } = {}

      objectKeys.forEach(key => {
        const fieldName = schema.find((field: { name: string }) => field.name === key)?.name
        if (fieldName) {
          sortedObject[fieldName] = d[key as keyof typeof d]
        }
      })

      dataArr.push(sortedObject)
    })

    return dataArr
  }

  private async parseData(upload: File) {
    return await new Promise((resolve, reject) => {
      try {
        Papa.parse(upload, {
          dynamicTyping: true,
          header: true,
          skipEmptyLines: true,
          complete: r => {
            resolve(r.data)
          },
        })
      } catch (e) {
        reject(e)
      }
    })
  }

  parseCsv = async (
    visitId: number,
    handlingDirection: CarrierVisitDirection,
    upload: File,
    visitType: CarrierType,
    railTrackNameMap?: Map<string, RailTrackResponseDto>,
    withRailcarSequenceAndRailTrackData?: boolean,
    visitRailTrackIds?: string[] | null,
  ) => {
    let fieldSchema = csvFieldSchemaMap.get(visitType)

    if (!fieldSchema) throw new Error(`System does not support ${visitType}`)

    // TODO: since optional and required flags do not work
    // add new fields to csvFieldSchemaMap and remove this hack once rail-orders feature flag is integrated
    if (withRailcarSequenceAndRailTrackData) {
      fieldSchema = [
        ...fieldSchema,
        { name: 'Sequence', inputName: 'sequence' },
        { name: 'Track', inputName: 'track' },
      ]
    }

    const data = (await this.parseData(upload)) as [any] // hack to make DoorDirection optional

    // hack to make DoorDirection optional
    if (data.filter(i => i.DoorDirection).length && handlingDirection === 'Outbound') {
      fieldSchema = [...fieldSchema, { name: 'DoorDirection', inputName: 'doorDirection' }]
    }

    if (data.filter(i => i.OperationalInstructions).length) {
      fieldSchema = [
        ...fieldSchema,
        { name: 'OperationalInstructions', inputName: 'operationalInstructions' },
      ]
    }

    const sortedAndCleanedData = this.cleanUpAndSortParsedResults(data, fieldSchema)
    const formattedCsv = Papa.unparse(sortedAndCleanedData)

    const csvData: ParsedResults<IOrderListCsvData, RowError> = await CSVFileValidator(
      formattedCsv,
      { headers: fieldSchema },
    )

    if (csvData.inValidData.length > 0) {
      const invalid = csvData.inValidData.pop()

      if (invalid?.message.toLowerCase().includes('Number of fields mismatch'.toLowerCase())) {
        const missingFields: string[] = []
        const invalidRow = sortedAndCleanedData[invalid?.rowIndex ? invalid.rowIndex - 1 : 0]
        fieldSchema
          .map(fs => fs.name)
          .forEach(fieldName => {
            if (invalidRow[fieldName] === undefined) {
              missingFields.push(fieldName)
            }
          })

        if (missingFields.length) {
          throw new FileFormatError(
            `row: ${invalid?.rowIndex}${invalid?.columnIndex ? `, column: ${invalid.columnIndex}` : ''}, message: Missing columns ${missingFields.join(', ')}`,
          )
        }
      }

      throw new FileFormatError(
        `row: ${invalid?.rowIndex}, column: ${invalid?.columnIndex}, message: ${invalid?.message}`,
      )
    }

    const orderList = {
      carrierVisitId: visitId,
      direction: handlingDirection,
      orders: csvData.data.map(row => {
        const railTrackId = row.track
          ? railTrackNameMap?.get(row.track.toLocaleLowerCase())?.id ?? '-1'
          : undefined

        const seals = this.splitStringIntoArray(row.seals)
        const imoClasses = this.splitStringIntoArray(row.imoClasses)
        const doorDirection =
          !row.doorDirection || row.doorDirection.trim() === '' ? null : row.doorDirection

        return {
          ...row,
          grossWeight: Number(row.grossWeight),
          portOfLoading: row.portOfLoading,
          portOfDischarge: row.portOfDischarge,
          isEmpty: this.convertBool(row.isEmpty?.toLocaleUpperCase()),
          operator: row.operator,
          temperature: row.temperature,
          imoClasses: imoClasses,
          atConsignee: parseDate(row.atConsignee),
          waggon: row.waggon,
          sequence: row.sequence,
          railTrackId: railTrackId,
          railTrack: row.track,
          doorDirection: doorDirection,
          hasSeals: this.convertBool(row.hasSeals?.toLocaleUpperCase()),
          seals: seals,
        } as IOrderDto
      }),
    } as IOrderList

    let warningMessages: string[] = []

    if (withRailcarSequenceAndRailTrackData) {
      if (orderList.orders) {
        this.verifyMissingRailTrack(orderList.orders)
        this.verifyInvalidRailTracks(orderList.orders)
        this.verifyNotPlannedRailTracks(orderList.orders, visitRailTrackIds)
        this.verifyDuplicateRailcarsWithDifferentRailTracks(orderList.orders)
        this.validateMissingSequence(orderList.orders)
        this.verifyDuplictedRailcarsWithDifferentSequence(orderList.orders)
        this.verifyDuplicateSequencesWithDifferentRailcars(orderList.orders)

        warningMessages = this.validateRailWarningMessages(orderList.orders)
      }
    }

    return {
      orderList,
      warningMessages,
    }
  }

  parseBaplie = async (
    vesselVisitId: number,
    handlingDirection: CarrierVisitDirection,
    portCodes: string[],
    upload: File,
  ) => {
    const fileFormatErrorPrefix = 'FileFormatError: '
    let response
    try {
      response = await this.baplieParserClient.uploadBaplie(
        vesselVisitId,
        handlingDirection,
        portCodes,
        upload,
      )
    } catch (error: any) {
      const responseError = error.response.data.error as string

      if (responseError.startsWith(fileFormatErrorPrefix))
        throw new FileFormatError(responseError.split(fileFormatErrorPrefix)[1])

      throw error
    }

    return response.data
  }

  verifyMissingRailTrack = (orders: OrderListOrderDto[]) => {
    if (!orders.some(x => x.railTrackId)) return

    const ordersWithMissingRailTrack = orders
      .map((order, index) => ({ ...order, index }))
      .filter(order => !order.railTrackId)

    if (ordersWithMissingRailTrack?.length) {
      const railcars = ordersWithMissingRailTrack
        .map(o => o.waggon ?? '')
        .filter((value, index, self) => self.indexOf(value) === index)

      throw new MissingRailtrackError(railcars)
    }
  }

  verifyInvalidRailTracks = (orders: IOrderDto[]) => {
    if (orders.some(x => !x.railTrackId)) return

    const ordersWithInvalidRailTrack = orders
      .map((order, index) => ({ ...order, index }))
      .filter(order => order.railTrackId && order.railTrackId === '-1')

    if (ordersWithInvalidRailTrack?.length) {
      const railTracks = ordersWithInvalidRailTrack
        .map(o => o.railTrack ?? '')
        .filter((value, index, self) => self.indexOf(value) === index)

      throw new InvalidRailtrackError(railTracks)
    }
  }

  verifyNotPlannedRailTracks = (orders: IOrderDto[], visitRailTrackIds?: string[] | null) => {
    if (orders.some(x => !x.railTrackId)) return

    const ordersWithInvalidRailTrack = orders
      .map((order, index) => ({ ...order, index }))
      .filter(
        order =>
          order.railTrackId &&
          (!visitRailTrackIds || !visitRailTrackIds.includes(order.railTrackId)),
      )

    if (ordersWithInvalidRailTrack?.length) {
      const railTracks = ordersWithInvalidRailTrack
        .map(o => o.railTrack ?? '')
        .filter((value, index, self) => self.indexOf(value) === index)

      throw new NotPlannedRailtrackError(railTracks)
    }
  }

  verifyDuplicateRailcarsWithDifferentRailTracks = (orders: OrderListOrderDto[]) => {
    const duplicateRailcarsWithDifferentRailTracks = orders.filter((order, index) => {
      return orders.some((o, i) => {
        return i !== index && o.waggon === order.waggon && o.railTrackId !== order.railTrackId
      })
    })

    if (duplicateRailcarsWithDifferentRailTracks?.length) {
      const railTracks = duplicateRailcarsWithDifferentRailTracks
        .map(o => o.waggon ?? '')
        .filter((value, index, self) => self.indexOf(value) === index)

      throw new DuplicateRailcarsWithDifferentRailTracksError(railTracks)
    }
  }

  verifyDuplictedRailcarsWithDifferentSequence = (orders: OrderListOrderDto[]) => {
    const duplicateRailcarsWithDifferentSequence = orders.filter((order, index) => {
      return orders.some((o, i) => {
        return i !== index && o.waggon === order.waggon && o.sequence !== order.sequence
      })
    })

    if (duplicateRailcarsWithDifferentSequence?.length) {
      const railTracks = duplicateRailcarsWithDifferentSequence
        .map(o => o.waggon ?? '')
        .filter((value, index, self) => self.indexOf(value) === index)

      throw new DuplicateRailcarsWithDifferentSequenceError(railTracks)
    }
  }

  verifyDuplicateSequencesWithDifferentRailcars = (orders: OrderListOrderDto[]) => {
    const duplicateSequencesWithDifferentRailcars = orders
      .map((order, index) => ({ ...order, index }))
      .filter((order, index) => {
        return orders.some((o, i) => {
          return (
            i !== index &&
            o.sequence &&
            o.sequence === order.sequence &&
            o.railTrackId === order.railTrackId &&
            o.waggon !== order.waggon
          )
        })
      })

    if (duplicateSequencesWithDifferentRailcars?.length) {
      const lineNumbers = duplicateSequencesWithDifferentRailcars.map(o => o.index + 2)

      throw new DuplicateSequencesWithDifferentRailcarsError(lineNumbers)
    }
  }

  validateMissingSequence = (orders: OrderListOrderDto[]) => {
    const ordersWithMissingSequence = orders
      .map((order, index) => ({ ...order, index })) // Add index to each order
      .filter(order => !order.sequence)

    if (ordersWithMissingSequence.length > 0) {
      const invalidLineNumber = ordersWithMissingSequence.map(o => o.index + 2)

      throw new Error(
        `You are trying to upload a file that contains railcars without a sequence. Please make sure each line of your file provides the correct sequence of the railcar. The following lines are in the csv don't have a sequence: ${invalidLineNumber.join(', ')}`,
      )
    }
  }

  validateRailWarningMessages = (orders: OrderListOrderDto[]) => {
    const messages = []

    const noAssignedRailTrackMessage = this.checkVisitWithNoRailTracks(orders)
    if (noAssignedRailTrackMessage) messages.push(noAssignedRailTrackMessage)

    return messages
  }

  checkVisitWithNoRailTracks = (orders: OrderListOrderDto[]) => {
    const noRailTrackOrders = orders.some(o => !o.railTrackId)
    if (noRailTrackOrders) {
      return 'Orders in this file do not have a rail track. They will be added in the first available rail track assigned to the visit'
    }
  }
}

const orderListParsingService = new OrderListParsingService()

export default orderListParsingService
