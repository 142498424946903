import { action, computed, makeObservable, observable } from 'mobx'

class AppStore {
  isMenuOpen = true
  isMobile = false

  constructor() {
    makeObservable(this, {
      isMenuOpen: observable,
      isMobile: observable,

      toggleMenu: action,
      setIsMobile: action,

      drawerWidth: computed,
      isMenuMobileOpen: computed,
    })
  }

  toggleMenu(isOpen: boolean) {
    if (this.isMenuOpen !== isOpen) {
      this.isMenuOpen = isOpen
    }
  }

  setIsMobile(value: boolean) {
    if (this.isMobile !== value) {
      this.isMobile = value
    }

    this.toggleMenu(!this.isMobile)
  }

  get drawerWidth() {
    return this.isMobile ? 240 : 80
  }

  get isMenuMobileOpen() {
    return this.isMenuOpen && this.isMobile
  }

  get contentMarginleft() {
    return this.isMobile ? '0' : this.drawerWidth + 'px'
  }
}

export const appStore = new AppStore()
