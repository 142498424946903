import { SelectOption, SelectValue } from '@storage/app/models'
import { AllTranslationKey } from '@storage/components/form-controls/constants'
import { hasValue } from '@storage/utils/has-value'

export const mapSelectValueToLabel = (
  value: SelectValue | undefined | null,
  options: SelectOption[],
): string => {
  return hasValue(value)
    ? options.find(({ value }) => value === value)?.label ?? ''
    : AllTranslationKey
}
