import { useOperationsStore } from '@operations/AppProvider'
import { useTranslate } from '@tolgee/react'
import { DrawerFormContainer } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { ReeferMonitoringForm } from '../components/form/ReeferMonitoringForm'
import { ReeferMonitoringFormMapper } from '../components/form/reefer-monitoring-form.mapper'
import { ReeferMonitoringFormProfile } from '../components/form/reefer-monitoring-form.profile'

export const ReeferMonitoringDrawerContainer = observer(() => {
  const { t } = useTranslate()

  const { appStore, reeferMonitoringDrawerUIStore, reeferTemperatureStore } = useOperationsStore()

  const handleSubmit = async (formValues: ReeferMonitoringFormProfile) => {
    if (reeferMonitoringDrawerUIStore.formType === 'Add') {
      await reeferTemperatureStore.create(
        ReeferMonitoringFormMapper.mapFormValuesToCreateDto(formValues),
      )
    } else {
      await reeferTemperatureStore.update(
        ReeferMonitoringFormMapper.mapFormValuesToUpdateDto(formValues),
      )
    }

    appStore.setShowAlert('success', t('recordSaved', 'Record Saved'))
    reeferMonitoringDrawerUIStore.toggle()
  }

  return (
    <DrawerFormContainer
      open={reeferMonitoringDrawerUIStore.isOpen}
      title={`${reeferMonitoringDrawerUIStore.formType} ${t('record', 'Record')}`}
      primaryActionLabel={t('save', 'Save')}
      closeActionLabel={t('close', 'Close')}
      formId='reefer-monitoring-form'
      onClose={() => reeferMonitoringDrawerUIStore.toggle()}
    >
      <ReeferMonitoringForm
        id='reefer-monitoring-form'
        dto={reeferMonitoringDrawerUIStore.editingRecord}
        cargoUnitId={reeferMonitoringDrawerUIStore.editingEntity?.cargoUnitId ?? 0}
        containerNumber={reeferMonitoringDrawerUIStore.editingEntity?.containerNumber}
        requestedTemperature={
          reeferMonitoringDrawerUIStore.editingEntity?.requestedTemperature ?? 0
        }
        isTemperatureRequired={
          reeferMonitoringDrawerUIStore.editingEntity?.isTemperatureRequired ?? false
        }
        isCurrentlyPluggedIn={reeferMonitoringDrawerUIStore.editingEntity?.isPluggedIn ?? false}
        lastCheck={reeferMonitoringDrawerUIStore.editingEntity?.lastRecordedTemperatureDateTime}
        onSubmit={handleSubmit}
      />
    </DrawerFormContainer>
  )
})
