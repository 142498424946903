import { ContainerTurnoverGroupItemDto } from '@storage/app/api'
import { Box } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { InformationCard } from './InformationCard'

interface ContainerInformationCardProps {
  groupedBySize: ContainerTurnoverGroupItemDto[]
  groupedByOperator: ContainerTurnoverGroupItemDto[]
  fullCount: number
  emptyCount: number
}

export const ContainerInformationCard = ({
  groupedBySize,
  groupedByOperator,
  fullCount,
  emptyCount,
}: ContainerInformationCardProps) => {
  const { t } = useTranslate()
  return (
    <Box>
      <Box>
        {groupedBySize.map(info => (
          <InformationCard label={`${info.key}'`} value={info.value} key={info.key} />
        ))}
        <InformationCard label={t('full', 'Full')} value={fullCount} />
        <InformationCard label={t('empty', 'Empty')} value={emptyCount} />
      </Box>

      {groupedByOperator.map(info => (
        <InformationCard label={info.key} value={info.value} key={info.key} />
      ))}
    </Box>
  )
}
