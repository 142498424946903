import { Box, Stack, Tooltip, Typography } from '@mui/material'
import { CargoType, CarrierVisitStatus } from '@planning/app/api'
import { useTranslate } from '@tolgee/react'
import { InfoIcon, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC, ReactNode } from 'react'
import { CargoTypeIconLabel } from '../CargoTypeIconLabel'
import { CarrierVisitStatusChip } from '../CarrierVisitStatusChip'
import { ICarrierVisitPlanning } from './CarrierVisitPlanning.model'

interface Props {
  visit: ICarrierVisitPlanning
  descriptionComponent?: ReactNode
  onClick: (id: number) => void
}

const ConflictsTooltip: FC<{ visit: ICarrierVisitPlanning }> = ({ visit }) => {
  const { t } = useTranslate()
  const conflicts = []

  if (visit.overlaps) {
    conflicts.push(t('visitOverlapsAnother', 'Visit overlaps another'))
  }

  if (visit.lengthConflict) {
    conflicts.push(
      t(
        'railcarLengthHigherThanTrackLength',
        'Total length of railcars allocated to track exceeds the length of the rail track',
      ),
    )
  }

  if (visit.railcarLengthMissing) {
    conflicts.push(
      t(
        'railcarLengthMissing',
        'Unable to calculate the train length due to railcars with missing lengths',
      ),
    )
  }

  return (
    <Tooltip
      title={
        <ul>
          {conflicts.map(conflict => (
            <li key={conflict}>{conflict}</li>
          ))}
        </ul>
      }
    >
      <InfoIcon color='error' />
    </Tooltip>
  )
}

export const CarrierVisitPlanningCard = observer(
  ({ visit, descriptionComponent, onClick }: Props) => {
    const theme = useMinimalsTheme()

    const getBorder = () => {
      if (visit.selected) return `4px solid ${theme.palette.primary.main}`

      if (visit.overlaps) return `2px solid ${theme.palette.error.main}`

      return undefined
    }

    const getBackground = () => {
      if (visit.overlaps)
        return `linear-gradient(135deg, ${theme.palette.error.main} 12.5%, ${theme.palette.common.white} 12.5%, 
                ${theme.palette.common.white} 55%, ${theme.palette.error.main} 55%, ${theme.palette.error.main} 60%, 
                ${theme.palette.common.white} 60%, ${theme.palette.common.white} 100%)`

      return theme.palette.common.white
    }

    const visitConflicted = visit.overlaps || visit.lengthConflict || visit.railcarLengthMissing

    return (
      <Box
        sx={{
          position: 'absolute',
          top: `${visit.y}px`,
          left: `${visit.x}px`,
          height: `${visit.height}px`,
          width: `${visit.width}px`,
          background: getBackground(),
          backgroundSize: '6px 6px',
          overflow: 'hidden',
          borderRadius: theme.customRadius.medium,
          boxSizing: 'border-box',
          padding: theme.customSpacing.m,
          border: getBorder(),
          boxShadow: '-1px 1px 5px 4px rgba(223,227,232,1)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 100,
          cursor: visit.status !== CarrierVisitStatus.Departed ? 'pointer' : undefined,
        }}
        onClick={() => {
          if (visit.status !== CarrierVisitStatus.Departed) onClick(visit.id)
        }}
      >
        <Box overflow='hidden'>
          {visitConflicted && (
            <Stack alignItems='center'>
              <ConflictsTooltip visit={visit} />
            </Stack>
          )}
          <Stack flexDirection='row' gap={2} alignItems='center' width='100% !important'>
            <Typography variant='h2' overflow='hidden' whiteSpace='nowrap' textOverflow='ellipsis'>
              {visit.name}
            </Typography>
            {visit.width > 200 && (
              <>
                <CargoTypeIconLabel
                  cargoType={visit?.cargoType ?? CargoType.Unknown}
                  showLabel={false}
                />
                <Box flex='none'>
                  <CarrierVisitStatusChip status={visit.status} />
                </Box>
              </>
            )}
          </Stack>
          {visit.height > 100 && descriptionComponent}
        </Box>
      </Box>
    )
  },
)
