import { NonNumericOrderDto } from '@planning/app/api'
import _ from 'lodash'
import { computed, makeObservable } from 'mobx'
import { EntityItem } from '../base/EntityItem'
import { IOrderItem } from '../order/OrderItem'
import { OrderItemStore } from '../order/OrderItemStore'
import { IEntity } from '../types'

export interface INnrOrderItem extends IEntity<NonNumericOrderDto> {
  orders: IOrderItem[]
}

export class NnrOrderItem extends EntityItem<NonNumericOrderDto> implements INnrOrderItem {
  constructor(
    id: number,
    data: NonNumericOrderDto,
    private orderItemStore?: OrderItemStore,
  ) {
    super(id, data)
    makeObservable(this, {
      orders: computed,
    })
  }

  get orders() {
    return _.get(this.orderItemStore?.ordersByNnrOrderId, this.id) ?? []
  }
}
