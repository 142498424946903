import {
  ContainerDamageLocationDto,
  ContainerDamageLocationsApi,
  CreateContainerDamageLocationCommand,
  UpdateContainerDamageLocationCommand,
} from '@admin/app/api'
import { createApiClient } from '@admin/app/http-client'
import { BaseEntityStore } from './base/BaseEntityStore'

export class ContainerDamageLocationStore extends BaseEntityStore<ContainerDamageLocationDto> {
  async load() {
    const { data } = await createApiClient(
      ContainerDamageLocationsApi,
    ).containerDamageLocationsGet()
    this.updateStoreItems(data)
  }

  async add(containerDamageLocation: CreateContainerDamageLocationCommand) {
    const { data } = await createApiClient(
      ContainerDamageLocationsApi,
    ).containerDamageLocationsPost(containerDamageLocation)
    this.updateStoreItem({ ...containerDamageLocation, isDefault: false, id: data }, data)
  }

  async update(containerDamageLocation: UpdateContainerDamageLocationCommand, isDefault?: boolean) {
    await createApiClient(ContainerDamageLocationsApi).containerDamageLocationsPut(
      containerDamageLocation.id,
      containerDamageLocation,
    )
    this.updateStoreItem(
      { ...containerDamageLocation, isDefault: !!isDefault },
      containerDamageLocation.id,
    )
  }

  async delete(id: number) {
    await createApiClient(ContainerDamageLocationsApi).containerDamageLocationsDelete(id)
    this.deleteStoreItem(id)
  }
}
