import { FC } from 'react'

export const VesselIcon: FC<{ zoom?: number }> = ({ zoom }) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13 6.16667C13 5.52233 13.4477 5 14 5H16C16.5523 5 17 5.52233 17 6.16667V7.33333H17.5C18.0523 7.33333 18.5 7.85567 18.5 8.5V9.66667H19C19.5523 9.66667 20 10.189 20 10.8333V11.2222H21.0476C21.3907 11.2222 21.7073 11.4375 21.8764 11.7859C22.0454 12.1342 22.0408 12.5617 21.8643 12.905L19.0071 18.4605C18.835 18.7952 18.525 19 18.1905 19H5.33333C5.00496 19 4.69975 18.8026 4.52572 18.4778L2.14476 14.0333C1.96127 13.6908 1.95155 13.2591 2.11939 12.9058C2.28722 12.5525 2.60617 12.3333 2.95238 12.3333V7.88888C2.95238 7.27523 3.37878 6.77777 3.90476 6.77777H6.76191C7.28789 6.77777 7.71429 7.27523 7.71429 7.88888V8.44446H5.80969C5.54669 8.44446 5.3335 8.69319 5.3335 9.00002C5.3335 9.30684 5.54669 9.55557 5.80969 9.55557L7.71429 9.55557V12.3333H10.0414C10.0145 12.2277 10 12.1158 10 12V10.8333C10 10.189 10.4477 9.66667 11 9.66667H11.5V8.5C11.5 7.85567 11.9477 7.33333 12.5 7.33333H13V6.16667ZM16 11.2222V10.8333H15.5H14.5H14V12H14.5464C14.6369 11.8787 14.7435 11.7526 14.8649 11.6393C15.0507 11.4659 15.3781 11.2222 15.8095 11.2222H16ZM17 9.66667H17.5V8.5H16H15.5V9.66667H16H17ZM19 11.2222V10.8333H17.5H17V11.2222H19ZM16 6.16667V7.33333H15.5H14.5H14V6.16667H16ZM12.5 8.5H14H14.5V9.66667H14H13H12.5V8.5ZM11 10.8333H12.5H13V12H11V10.8333Z'
        fill='#212B36'
      />
    </svg>
  )
}
export default VesselIcon
