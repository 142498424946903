import { Alert, Box, Typography } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'

export type HeaderMessage = {
  message: string
  severity: 'success' | 'warning'
}

interface IProps {
  title: string
  actions?: JSX.Element[]
  noMarginBottom?: boolean
  noBorder?: boolean
  headerMessage?: HeaderMessage
}

export const HeaderWithActions = observer(
  ({ title, actions, noMarginBottom, noBorder, headerMessage }: IProps) => {
    const theme = useMinimalsTheme()

    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: noMarginBottom ? '0' : '1rem',
          height: '80px',
          justifyContent: 'space-between',
          padding: `${theme.customSpacing.s} ${theme.customSpacing.l}`,
          borderBottom: noBorder ? 'none' : `1px solid ${theme.palette.divider}`,
        }}
      >
        <Box>
          <Typography variant='h3'>{title}</Typography>
        </Box>
        {headerMessage && (
          <Box>
            <Alert severity={headerMessage.severity}>{headerMessage.message}</Alert>
          </Box>
        )}
        <Box display='flex' gap={2}>
          {actions?.map(action => <Box key={`header-action-${action.key}`}>{action}</Box>)}
        </Box>
      </Box>
    )
  },
)
