import {
  BerthDto,
  SlotDto,
  YardBlockBayDto,
  YardBlockDto,
  YardBlockInformationDto,
  YardElementGeometryDto,
  YardSummaryDto,
} from '@storage/app/api'
import { FormType, Point } from '@storage/app/models'
import { YardBayViewStore } from '@storage/pages/yard-management/stores/YardBayViewStore'
import { YardDataStore } from '@storage/pages/yard-management/stores/YardDataStore'
import { YardStageStore } from '@storage/pages/yard-management/stores/YardStageStore'
import { action, computed, makeObservable, observable } from 'mobx'
import { YardElement, YardElementType } from '../types/yard-setup.types'

export interface StackIndication {
  blockId: string
  bayId: string
  rowId: string
  bay: number
  row: number
  value: number
  text?: string
  color?: string
  slotSize: number
  highlight: boolean
}

//TODO: To refactor with YardViewStore
export class YardSetupStore {
  yardDataStore: YardDataStore
  yardBayViewStore: YardBayViewStore
  yardStageStore: YardStageStore

  selectedYardBlock?: YardBlockDto
  selectedFlexibleZone?: YardBlockDto
  selectedYardBlockBay?: YardBlockBayDto
  selectedBerth?: BerthDto
  selectedVesselVisitId?: number

  yardSummary?: YardSummaryDto
  yardBlockSummary?: YardBlockInformationDto

  isBayViewDialogOpen = false

  drawerAction?: FormType
  isDrawerOpen = false
  isBerthDialogOpen = false
  isFlexibleZoneDialogOpen = false
  initGeometry: Point = { x: 0, y: 0 }

  groundSlotsFromSelectedRules: SlotDto[] = []
  showMoveContainerDialog = false
  showSelectAllocationNotification = false
  showDeletionConfirmationDialog = false

  yardBlockId = ''
  deletionConfirmationOjectType: YardElementType = YardElementType.Berth

  constructor(yardDataStore: YardDataStore, yardBayViewStore: YardBayViewStore) {
    this.yardStageStore = new YardStageStore()

    makeObservable(this, {
      visibleBlocks: computed,
      visibleFlexibleZones: computed,
      selectedYardBlock: observable,
      selectedFlexibleZone: observable,
      yardSummary: observable,
      yardBlockSummary: observable,
      setSelectedYardBlock: action,
      setSelectedFlexibleZone: action,
      setSelectedYardBlockBay: action,
      setIsBayViewDialogOpen: action,
      isBayViewDialogOpen: observable,
      drawerAction: observable,
      isDrawerOpen: observable,
      groundSlotsFromSelectedRules: observable,
      showMoveContainerDialog: observable,
      toggleMoveContainerDialog: action,
      setGroundSlotsFromSelectedRules: action,
      showSelectAllocationNotification: observable,
      setShowSelectAllocationNotification: action,
      isAllocationMode: computed,
      selectedBerth: observable,
      setSelectedBerth: action,
      showDeletionConfirmationDialog: observable,
      deletionConfirmationOjectType: observable,
      toggleDeletionConfirmationDialog: action,
      selectedVesselVisitId: observable,
      setSelectedVesselVisitId: action,
      toggleEditing: action,
      toggleYardBlockSummary: action,
      isBerthDialogOpen: observable,
      isFlexibleZoneDialogOpen: observable,
      toggleBerthDialog: action,
      toggleFlexibleZoneDialog: action,
      setDrawerAction: action,
      yardBlockId: observable,
      setYardBlockId: action,
      deselectYardObjects: action,
    })

    this.yardDataStore = yardDataStore
    this.yardBayViewStore = yardBayViewStore

    // Toggle editing by default
    this.toggleEditing()
  }

  toggleBerthDialog() {
    this.isBerthDialogOpen = !this.isBerthDialogOpen
  }

  toggleFlexibleZoneDialog() {
    this.isFlexibleZoneDialogOpen = !this.isFlexibleZoneDialogOpen
  }

  get visibleBlocks() {
    return this.yardDataStore.yardBlocks.filter(e => !!e.geometry && !e.isFlexibleZone)
  }

  get visibleFlexibleZones() {
    return this.yardDataStore.yardBlocks.filter(e => !!e.geometry && e.isFlexibleZone)
  }

  get isAllocationMode(): boolean {
    return false
  }

  setSelectedVesselVisitId(vesselVisitId?: number) {
    this.selectedVesselVisitId = vesselVisitId
  }

  async setSelectedYardBlock(yardBlock?: YardBlockDto) {
    this.deselectYardObjects()
    this.selectedYardBlock = yardBlock
  }

  setSelectedFlexibleZone(flexibleZone?: YardBlockDto) {
    this.deselectYardObjects()
    this.selectedFlexibleZone = flexibleZone
  }

  setSelectedBerth(berth?: BerthDto) {
    this.deselectYardObjects()
    this.selectedBerth = berth
  }

  deselectYardObjects() {
    this.selectedBerth = undefined
    this.selectedYardBlock = undefined
    this.selectedFlexibleZone = undefined
  }

  setYardBlockId(yardBlockId: string) {
    this.yardBlockId = yardBlockId
  }

  toggleDeletionConfirmationDialog(deletionConfirmationOjectType: YardElementType) {
    this.deletionConfirmationOjectType = deletionConfirmationOjectType
    this.showDeletionConfirmationDialog = !this.showDeletionConfirmationDialog
  }

  setShowSelectAllocationNotification(value: boolean) {
    this.showSelectAllocationNotification = value
  }

  setDrawerAction(action?: FormType, block?: YardBlockDto) {
    this.selectedYardBlock = block
    this.drawerAction = action
    this.isDrawerOpen = !!action
  }

  toggleMoveContainerDialog() {
    this.showMoveContainerDialog = !this.showMoveContainerDialog
  }

  setGroundSlotsFromSelectedRules(slots: string[]) {
    this.groundSlotsFromSelectedRules = this.yardDataStore.yardBlockSlotStore.entries.filter(
      s => slots.includes(s.id) && s.tier === 1,
    )
  }

  isStackSelectedForAllocation(stack?: StackIndication) {
    if (stack) {
      return (
        this.groundSlotsFromSelectedRules.find(
          s => s.yardBlockBayId === stack.bayId && s.yardBlockRowId === stack.rowId,
        ) !== undefined
      )
    }

    return false
  }

  setInitGeometry(p: Point) {
    this.initGeometry = p
  }

  setSelectedYardBlockBay(bayId?: string) {
    if (!bayId) {
      this.selectedYardBlock = undefined
      this.selectedYardBlockBay = undefined
      this.isBayViewDialogOpen = false
      return
    }
    this.selectedYardBlockBay = this.yardDataStore.yardBlockBays.find(b => b.id === bayId)
    this.selectedYardBlock = this.yardDataStore.yardBlocks.find(
      b => b.id === this.selectedYardBlockBay?.yardBlockId,
    )

    this.setIsBayViewDialogOpen(true)
  }

  async toggleEditing() {
    this.setSelectedYardBlock()
    this.yardStageStore.onToggleGrid()
  }

  setIsBayViewDialogOpen(open: boolean) {
    this.isBayViewDialogOpen = open
  }

  async toggleYardBlockSummary(yardBlockId?: string) {
    if (yardBlockId === this.yardBlockSummary?.id) return

    this.yardBlockSummary = yardBlockId
      ? this.yardDataStore.yardBlockSummaryStore.data.get(yardBlockId)
      : undefined
  }

  async saveTransformation(element: YardElement) {
    switch (element.type) {
      case YardElementType.Berth:
        {
          const berthDto = this.yardDataStore.berthStore.get(element.id)
          if (berthDto) {
            const toUpdate: BerthDto = {
              ...berthDto,
              geometry: { ...berthDto.geometry, ...element.geometry },
            } as BerthDto

            await this.yardDataStore.berthStore.saveBerthGeometry(
              element.id,
              toUpdate.geometry as YardElementGeometryDto,
            )

            this.yardDataStore.berthStore.set(toUpdate)
          }
        }
        break
      case YardElementType.FlexibleZone:
        {
          const blockDto = this.yardDataStore.yardBlockStore.get(element.id)
          if (blockDto) {
            const toUpdate: YardBlockDto = {
              ...blockDto,
              geometry: { ...blockDto.geometry, ...element.geometry },
            } as YardBlockDto

            await this.yardDataStore.yardBlockStore.saveYardBlockGeometry(element.id, {
              ...toUpdate.geometry,
              width: Math.round(element.geometry.width ?? 200),
            } as YardElementGeometryDto)

            await this.yardDataStore.yardBlockStore.load(element.id)

            this.yardDataStore.yardBlockStore.set(toUpdate)
          }
        }
        break
      default:
        {
          const dtoGeometry: YardElementGeometryDto = {
            top: Math.round(element.geometry.top ?? 0),
            left: Math.round(element.geometry.left ?? 0),
            rotation: Math.round(element.geometry.rotation ?? 0),
          }
          await this.yardDataStore.yardBlockStore.saveYardBlockGeometry(element.id, dtoGeometry)
          await this.yardDataStore.yardBlockStore.load(element.id)
        }
        break
    }
  }
}
