import { Box } from '@mui/material'
import { ContainerStatus, YardPositionDto } from '@storage/app/api'
import { LabelBox } from '@storage/components/LabelBox'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { ContainerPositionChip } from '.'

interface ContainerPositionBoxProps {
  currentPosition?: YardPositionDto[]
  targetPosition?: YardPositionDto[]
  targetAllocation?: YardPositionDto[]
  status: ContainerStatus
  terminalEntry?: string | null
  terminalExit?: string | null
}

export const ContainerPositionBox = observer(
  ({
    currentPosition,
    status,
    targetPosition,
    targetAllocation,
    terminalEntry,
    terminalExit,
  }: ContainerPositionBoxProps) => {
    const { t } = useTranslate()

    const targetPos =
      targetPosition && targetPosition.length > 0 ? targetPosition : targetAllocation

    const renderPosition = useMemo(() => {
      const containerIsStackedIn = !!currentPosition?.length
      const containerIsMoving = !containerIsStackedIn && terminalEntry && !terminalExit
      const containerHasNoPosition = status === ContainerStatus.NotReserved
      const containerIsReservedOrAllocated =
        !containerIsStackedIn && !containerIsMoving && !!targetPos?.length

      switch (true) {
        //  Case of no position
        case containerHasNoPosition:
          return <ContainerPositionChip status={ContainerStatus.NotReserved} />
        // Case of only stacked in (has current position & no target position)
        case containerIsStackedIn:
          return <ContainerPositionChip position={currentPosition} status={status} />
        // Case of only planned container (not stacked in && not moving && reserved or allocated)
        case containerIsReservedOrAllocated:
          return <ContainerPositionChip position={targetPos} status={ContainerStatus.Reserved} />
        // Case of moving (not internal move)
        case containerIsMoving:
          return (
            <Box sx={{ display: 'flex', gap: 1.5 }}>
              <LabelBox label={t('Moving', 'Moving')} bgColor='#E5D1FF' color='#5911B8' />
              <ContainerPositionChip
                position={targetPos?.length ? targetPos : currentPosition}
                status={targetPos?.length ? ContainerStatus.Reserved : ContainerStatus.OnTheYard}
              />
            </Box>
          )

        default:
          return <ContainerPositionChip status={ContainerStatus.NotReserved} />
      }
    }, [currentPosition, terminalEntry, terminalExit, status, targetPos, t])
    return (
      <Box
        sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
        data-cy='container-turnovers-position-box'
      >
        {renderPosition}
      </Box>
    )
  },
)
