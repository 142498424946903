import { Chip } from '@mui/material'
import { LengthIcon } from '@tom-ui/ui'

interface Props {
  containerLength: number
}

export const ContainerLengthChip = ({ containerLength }: Props) => {
  const icon = <LengthIcon />
  const label = `${containerLength}ft`

  return (
    <Chip
      label={label}
      icon={icon}
      variant='filled'
      sx={{
        marginBottom: '0.25rem',
        pl: '.5rem',
        '& .MuiChip-label': {
          pl: '.25rem',
        },
      }}
    />
  )
}
