import { EquipmentStore } from '@operations/stores/EquipmentStore'
import { RailVisitStore } from '@operations/stores/RailVisitStore'
import { WorkAreaStore } from '@operations/stores/WorkAreaStore'
import { WorkInstructionStore } from '@operations/stores/WorkInstructionStore'
import { makeObservable, observable, runInAction } from 'mobx'
import { EquipmentPlanningRailEquipmentsUIStore } from './equipment-planning-rail-equipmentsl-ui-store'

export class EquipmentPlanningRailUIStore {
  railVisitId?: number

  constructor(
    private equipmentStore: EquipmentStore,
    private workAreaStore: WorkAreaStore,
    private workInstructionStore: WorkInstructionStore,
    public railVisitStore: RailVisitStore,
    public equipmentPlanningRailEquipmentsUIStore: EquipmentPlanningRailEquipmentsUIStore,
  ) {
    makeObservable(this, {
      railVisitId: observable,
    })
  }

  async fetchData(id: number) {
    runInAction(() => {
      this.railVisitId = id
    })

    const fetches = [
      this.equipmentStore.loadIdsAlreadyInUseByOtherVisits(id),
      this.railVisitStore.loadOne(id),
      this.equipmentStore.load(),
      this.workAreaStore.load(id),
      this.workInstructionStore.load(id),
    ]

    const [equipmentIdsInUse] = await Promise.all(fetches)

    this.equipmentPlanningRailEquipmentsUIStore.setCarrierVisitId(id)
    this.equipmentPlanningRailEquipmentsUIStore.setEquipmentIdsInUseForOtherVisits(
      equipmentIdsInUse as number[],
    )
  }
}
