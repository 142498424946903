import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  ClickAwayListener,
  Grid,
  InputAdornment,
  Popper,
  PopperPlacementType,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { DateCalendar, LocalizationProvider, TimeField } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { formatDateTime } from '@planning/utils'
import { useTranslate } from '@tolgee/react'
import { ChevronDownIcon, ChevronUpIcon, IconButton } from '@tom-ui/ui'
import dayjs, { Dayjs } from 'dayjs'
import { FC, useCallback, useEffect, useRef, useState } from 'react'

interface Props {
  label: string
  value?: string | null
  popperPlacement?: PopperPlacementType
  defaultOpen?: boolean
  confirmButtonTestId?: string
  onChange: (value: Dayjs | null) => void
  onClose?: () => void
  onOpen?: () => void
}

export const CustomDateTimePicker: FC<Props> = ({
  label,
  value,
  popperPlacement,
  defaultOpen,
  confirmButtonTestId,
  onChange,
  onClose,
  onOpen,
}) => {
  const dateValue = dayjs(value ?? new Date().toISOString())

  const anchorRef = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dateValue)
  const { t } = useTranslate()

  const togglePopup = () => {
    if (open) {
      closePopup()
    } else {
      openPopup()
    }
  }

  const openPopup = useCallback(() => {
    setOpen(true)
    if (onOpen) onOpen()
  }, [onOpen])

  const closePopup = () => {
    setOpen(false)
    if (onClose) onClose()
  }

  const handleSubmit = (value: Dayjs | null) => {
    onChange(value)
    closePopup()
  }

  useEffect(() => {
    if (defaultOpen ?? false) {
      openPopup()
    }
  }, [openPopup, defaultOpen])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box onClick={togglePopup}>
        <TextField
          fullWidth
          value={formatDateTime(selectedDate)}
          label={label}
          InputProps={{
            sx: { width: '100%' },
            ref: anchorRef,
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton size='small'>
                  {(open && <ChevronDownIcon />) || <ChevronUpIcon />}
                </IconButton>
              </InputAdornment>
            ),
            autoComplete: 'new-password',
          }}
        />
      </Box>
      {anchorRef && (
        <Popper
          open={open}
          anchorEl={anchorRef?.current}
          placement={popperPlacement ?? 'bottom-end'}
          sx={{ zIndex: 10000 }}
        >
          <ClickAwayListener
            onClickAway={() => {
              closePopup()
            }}
          >
            <Card
              variant={'outlined'}
              sx={{ width: 'fit-content', alignItems: 'center', margin: '0.125rem' }}
            >
              <CardContent sx={{ display: 'flex', flexDirection: 'column', pl: '1.5rem' }}>
                <Grid container direction='row' spacing={12} alignItems='center'>
                  <Grid item xs={6}>
                    <Typography variant='h4'>{label}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Button variant='outlined' onClick={() => setSelectedDate(dayjs(new Date()))}>
                      {t('now', 'Now')}
                    </Button>
                  </Grid>
                </Grid>

                <Grid container direction='column' alignItems='center' justifyItems='center'>
                  <Grid item xs={4}>
                    <DateCalendar
                      value={selectedDate}
                      onChange={setSelectedDate}
                      sx={{ width: '280px' }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TimeField
                      value={selectedDate}
                      onChange={setSelectedDate}
                      sx={{ width: '250px' }}
                      //format='HH:MM:ss'
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Stack
                  direction='row'
                  display='flex'
                  justifyContent='flex-end'
                  width='100%'
                  sx={{ pl: '1rem', pr: '1rem' }}
                  spacing='0.5rem'
                >
                  <Button variant='text' onClick={closePopup}>
                    {t('cancel', 'Cancel')}
                  </Button>
                  <Button
                    data-cy={confirmButtonTestId}
                    variant='text'
                    onClick={() => handleSubmit(selectedDate)}
                  >
                    {t('ok', 'Ok')}
                  </Button>
                </Stack>
              </CardActions>
            </Card>
          </ClickAwayListener>
        </Popper>
      )}
    </LocalizationProvider>
  )
}
