import { GcsApi } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'
import { downloadFile, getFileNameFromAxiosResponse } from '@tom-ui/utils'

class GcsService {
  httpClient = createApiClient(GcsApi)

  downloadFile = async (path: string) => {
    const response = await this.httpClient.get(path, {
      responseType: 'blob',
    })

    const data = response.data as unknown

    const filename = getFileNameFromAxiosResponse(response)
    const blob = new Blob([data as BlobPart])
    return { blob, filename }
  }

  downloadFiles = async (paths: string[]) => {
    const promises = paths.map(path => {
      return this.downloadFile(path)
    })

    const files = await Promise.all(promises)

    files.map(file => downloadFile(file.blob, file.filename))
  }
}

const gcsService = new GcsService()

export default gcsService
