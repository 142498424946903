import { GeneralCargoAreaType } from '@storage/app/api'
import { tolgee } from '@storage/app/translation'
import { generalCargoAreaService } from '@storage/services'
import * as yup from 'yup'
import { GeneralCargoAreaFormProfile } from './general-cargo-area-form.profile'

const uniqueNameValidator = async (name: string, id?: string): Promise<boolean> => {
  try {
    const { data: isUnique } = await generalCargoAreaService.isNameUnique(name, id)
    return isUnique
  } catch (error) {
    return false
  }
}

export const schema: yup.Schema<GeneralCargoAreaFormProfile> = yup.object({
  name: yup
    .string()
    .required(tolgee.t('nameIsRequired', 'Name field is required'))
    .test(
      'is-unique-name',
      tolgee.t('GCAreaNameMustBeUnique', 'An area with the same name already exists'),
      async (name, context) => {
        const id = (context.parent as GeneralCargoAreaFormProfile).id
        return await uniqueNameValidator(name, id)
      },
    ),
  type: yup.mixed<GeneralCargoAreaType>().required(),
  locations: yup
    .array()
    .of(
      yup.object({
        name: yup.string().required(tolgee.t('locationIsRequired', 'Location field is required')),
      }),
    )
    .required()
    .test(
      'unique-location-names',
      tolgee.t(
        'locationNamesMustBeUniqueInsideArea',
        'Location names must be unique inside the area',
      ),
      locations => {
        if (Array.isArray(locations)) {
          const names = locations.map(location => location.name)
          return new Set(names).size === names.length
        }
        return false
      },
    ),
})
