import { createSvgIcon } from '@mui/material'

export const ChevronRightIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M9.9914 18.9982C9.75971 18.9986 9.53518 18.9175 9.35677 18.7687C9.15398 18.5995 9.02642 18.3561 9.00224 18.0922C8.97805 17.8282 9.05923 17.5655 9.22786 17.3618L13.6703 12.0137L9.38652 6.65557C9.22016 6.44943 9.14232 6.18507 9.17024 5.92102C9.19815 5.65696 9.32952 5.41499 9.53526 5.24869C9.74267 5.06506 10.0168 4.97682 10.2916 5.00523C10.5665 5.03364 10.817 5.17613 10.983 5.39836L15.7725 11.3851C16.0732 11.7533 16.0732 12.2841 15.7725 12.6523L10.8144 18.639C10.6127 18.8839 10.307 19.0173 9.9914 18.9982Z'
      fill='currentColor'
    />
  </svg>,
  'ChevronRightIcon',
)
