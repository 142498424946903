import { Theme } from '@mui/material/styles'

// ----------------------------------------------------------------------

export default function Dialog(theme: Theme) {
  return {
    MuiDialog: {
      styleOverrides: {
        paper: {
          boxShadow: theme.customShadows.dialog,
          '&.MuiPaper-rounded': {
            borderRadius: theme.customRadius.large,
          },
          '&.MuiDialog-paperFullScreen': {
            borderRadius: 0,
          },
          '&.MuiDialog-paper .MuiDialogActions-root': {
            padding: theme.customSpacing.m,
          },
          '@media (max-width: 600px)': {
            margin: theme.customSpacing.m,
          },
          '@media (max-width: 663.95px)': {
            '&.MuiDialog-paperWidthSm.MuiDialog-paperScrollBody': {
              maxWidth: '100%',
            },
          },
        },
        paperFullWidth: {
          width: '100%',
        },
      },
    },
    MuiDialogTitle: {
      defaultProps: {
        variant: 'h4',
      },
      styleOverrides: {
        root: {
          padding: theme.customSpacing.l,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: `${theme.customSpacing.xs} ${theme.customSpacing.l}`,
        },
        dividers: {
          borderTop: 0,
          borderBottomStyle: 'dashed',
          paddingBottom: theme.customSpacing.l,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: `${theme.customSpacing.m} ${theme.customSpacing.l} !important`,
          justifyContent: 'flex-start',
          '& > :not(:first-of-type)': {
            marginLeft: theme.customSpacing.s,
          },
        },
      },
    },
  }
}
