import { Box, Typography } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import {
  EXPORT_OCCUPANCY_BACKGROUND,
  IMPORT_OCCUPANCY_BACKGROUND,
  RESERVATION_OCCUPANCY_BACKGROUND,
} from '@storage/pages/yard-management/constants'
import { TenantConfigStore } from '@storage/stores/tenantConfig.store'
import { useTranslate } from '@tolgee/react'

interface Props {
  tenantConfigStore: TenantConfigStore
}

export const YardBlockBayOverviewLegend = ({ tenantConfigStore }: Props) => {
  const { palette } = useMinimalsTheme()
  const { t } = useTranslate()

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px', paddingLeft: '1rem' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px' }}>
        <Box
          sx={{
            width: 25,
            height: 25,
            border: `0.5px groove`,
            background: EXPORT_OCCUPANCY_BACKGROUND,
          }}
        />
        <Typography variant='body2'>{t('Export', 'Export')}</Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px' }}>
        <Box
          sx={{
            width: 25,
            height: 25,
            border: `0.5px groove`,
            background: IMPORT_OCCUPANCY_BACKGROUND,
          }}
        />
        <Typography variant='body2'>{t('Import', 'Import')}</Typography>
      </Box>
      {!tenantConfigStore.skipYardPlanning && (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px' }}>
          <Box
            sx={{
              width: 25,
              height: 25,
              border: `3px solid ${palette.primary.main}`,
            }}
          />
          <Typography variant='body2'>{t('allocated', 'Allocated')}</Typography>
        </Box>
      )}

      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px' }}>
        <Box
          sx={{
            width: 25,
            height: 25,
            border: `1px solid ${palette.grey[500]}`,
          }}
        />
        <Box sx={{ width: 193 }}>
          <Typography variant='body2'>
            {t('OtherCarrierTypeThanVessel', 'Other carrier type than vessel')}
          </Typography>
        </Box>
      </Box>
      {!tenantConfigStore.skipYardPlanning && (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px' }}>
          <Box
            sx={{
              width: 25,
              height: 25,
              border: `0.5px groove`,
              background: RESERVATION_OCCUPANCY_BACKGROUND,
            }}
          />
          <Typography variant='body2'>{t('reserved', 'Reserved')}</Typography>
        </Box>
      )}
    </Box>
  )
}
