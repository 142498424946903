import { HandlingDirection } from '@storage/app/api'
import { tolgee } from '@storage/app/translation'
import { v4 as uuidv4 } from 'uuid'
import { mapOccupancyItemsToUpdateGeneralCargoStockOccupancyDto } from '../mappers/general-cargo-storage-tracker.mapper'
import { GeneralCargoStorageTrackerUIStore } from '../stores/general-cargo-storage-tracker.ui-store'
import { GeneralCargoContextStrategy } from './general-cargo-context-strategy'
import { OccupancyItem, StockOccupancy, StockTrackingMethod } from './occupancy-item'

export class GeneralCargoOccupancyStrategy implements GeneralCargoContextStrategy {
  constructor(
    private readonly _generalCargoStorageTrackerUIStore: GeneralCargoStorageTrackerUIStore,
  ) {}

  loadItems(handlingDirection: HandlingDirection, _generalCargoOrderId?: number) {
    this._generalCargoStorageTrackerUIStore.stockOccupancies.clear()

    const stockTrackingMethod: StockTrackingMethod =
      this._generalCargoStorageTrackerUIStore.getStockTrackingMethod(handlingDirection)

    const generalCargoStockOccupancies =
      this._generalCargoStorageTrackerUIStore.generalCargoStock?.occupancies

    const generalCargoPlannedOccupancies =
      this._generalCargoStorageTrackerUIStore.generalCargoPlannedOccupancies

    const mergedOccupancyItems = new Map<string, StockOccupancy>()

    // Add stock occupancies to stockOccupancies
    generalCargoStockOccupancies?.forEach(occupancy => {
      mergedOccupancyItems.set(occupancy.id, {
        id: occupancy.id,
        isNewOccupancy: false,
        newQuantity: 0,
        locationName: occupancy.generalCargoLocation,
        areaName: occupancy.generalCargoArea,
        quantity: occupancy.quantity,
        damageDescription: '',
        isDamaged: false,
        properties: occupancy.properties,
        newProperties: [],
        reservedQuantity: 0,
        damageImagePaths: [],
      })
    })

    if (handlingDirection === HandlingDirection.Inbound) {
      // Merge planned occupancies into stockOccupancies
      generalCargoPlannedOccupancies?.forEach(plannedOccupancy => {
        const match = Array.from(mergedOccupancyItems.values()).find(
          occ =>
            occ.locationName === plannedOccupancy.generalCargoLocation &&
            occ.areaName === plannedOccupancy.generalCargoArea,
        )

        if (match) {
          // Update reserved quantity if a matching area and location is found
          match.reservedQuantity += plannedOccupancy.quantity
          match.newQuantity = plannedOccupancy.quantity
        } else {
          // Add new entry if no matching area and location is found
          mergedOccupancyItems.set(plannedOccupancy.id, {
            id: plannedOccupancy.id,
            isNewOccupancy: true, // TODO: to be renamed
            // Only prefill new quantity input for stocks tracked by quantity
            newQuantity:
              stockTrackingMethod === StockTrackingMethod.QUANTITY ? plannedOccupancy.quantity : 0,
            locationName: plannedOccupancy.generalCargoLocation,
            areaName: plannedOccupancy.generalCargoArea,
            quantity: 0,
            damageDescription: '',
            isDamaged: false,
            // Commodity properties r not relevant for planned occupancies
            properties: [],
            newProperties: [],
            reservedQuantity: plannedOccupancy.quantity,
            damageImagePaths: [],
          })
        }
      })
    }

    // Convert mergedOccupancies map to stockOccupancies map
    this._generalCargoStorageTrackerUIStore.stockOccupancies = mergedOccupancyItems

    // Choose default active occupancy item
    if (mergedOccupancyItems.size) {
      this._generalCargoStorageTrackerUIStore.activeOccupancyItemId = mergedOccupancyItems
        .entries()
        .next().value[0]
    }
  }

  addNewItem() {
    const id = uuidv4()
    const defaultArea = this._generalCargoStorageTrackerUIStore.generalCargoAreas[0]
    if (defaultArea) {
      this._generalCargoStorageTrackerUIStore.stockOccupancies.set(id, {
        id,
        isNewOccupancy: true,
        newQuantity: 0,
        areaName: defaultArea.name,
        locationName: defaultArea.locations[0]?.name,
        quantity: 0,
        damageDescription: '',
        isDamaged: false,
        properties: [],
        newProperties: [],
        reservedQuantity: 0,
        damageImagePaths: [],
      })
    }

    this._generalCargoStorageTrackerUIStore.activeOccupancyItemId = id
  }

  updateItem<T extends keyof OccupancyItem>(id: string, key: T, value: OccupancyItem[T]): void {
    const item = this._generalCargoStorageTrackerUIStore.stockOccupancies.get(id)
    if (item) {
      item[key] = value as StockOccupancy[T]
    }
  }

  dropOffMessage() {
    return this._generalCargoStorageTrackerUIStore.occupancyItems.length
      ? tolgee.t('dropOffInAnotherLocation', 'Drop off in another location')
      : tolgee.t('dropOffInNewLocation', 'Drop off in a new location')
  }

  quantityAlertMessage(enteredQuantity: number, handlingDirection: HandlingDirection) {
    const amount = this._generalCargoStorageTrackerUIStore.totalNewQuantity
    if (amount === enteredQuantity) {
      return undefined
    }
    switch (true) {
      case handlingDirection === HandlingDirection.Outbound &&
        this._generalCargoStorageTrackerUIStore.generalCargoStock?.totalQuantity === 0:
        return tolgee.t('noAmountRemainingInStock', 'No amount remaining in stock')

      case this._generalCargoStorageTrackerUIStore.totalNewQuantity > enteredQuantity:
        return tolgee.t('amountGreaterThanPlanned', 'The entered amount exceeds the planned amount')

      case this._generalCargoStorageTrackerUIStore.totalNewQuantity === 0:
        return tolgee.t(
          'NoAmountHasBeenEntered',
          'No amount has been entered. Please specify it to proceed',
        )

      case this._generalCargoStorageTrackerUIStore.totalNewQuantity < enteredQuantity:
        return tolgee.t(
          'amountLowerThanPlanned',
          'The entered amount is less than the planned amount',
        )
    }
  }

  async onConfirm(
    handlingDirection: HandlingDirection,
    generalCargoOrderId: number,
    _planDropOff: (dto: any) => Promise<void>,
    putToStock: (dto: any) => Promise<void>,
    takeFromStock: (dto: any) => Promise<void>,
    cargoItemId?: number,
  ): Promise<void> {
    // Drop off / pick up occupancy
    const operation = handlingDirection === 'Inbound' ? putToStock : takeFromStock

    // Condition on quantity for non identifiable items
    const occupancyItemsToUpdate = this._generalCargoStorageTrackerUIStore.occupancyItems.filter(
      occupancy =>
        occupancy.newQuantity > 0 || (occupancy as StockOccupancy).newProperties.length > 0,
    )

    await operation(
      mapOccupancyItemsToUpdateGeneralCargoStockOccupancyDto(
        occupancyItemsToUpdate,
        generalCargoOrderId,
        cargoItemId,
      ),
    )
    this._generalCargoStorageTrackerUIStore.clearAllFiles()
  }
}
