import { InputAdornment, InputBase, Paper } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { ChangeEvent } from 'react'
import { Search } from '@mui/icons-material'
import { useTranslate } from '@tolgee/react'

interface Props {
  value: string
  onChange: (value: string) => void
}

export const ContainerTurnoversSearchInput = observer(({ value, onChange }: Props) => {
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value)
  }

  const { t } = useTranslate()

  return (
    <Paper
      elevation={5}
      sx={{
        display: 'flex',
        padding: 1.5,
      }}
    >
      <InputBase
        value={value}
        onChange={handleInputChange}
        startAdornment={
          <InputAdornment position='start'>
            <Search />
          </InputAdornment>
        }
        placeholder={t('searchContainer', 'Search by container or reference number')}
        fullWidth
      />
    </Paper>
  )
})
