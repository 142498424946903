import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { PierResponseDto } from '@planning/app/api'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

interface IProps {
  selectedPierId: string
  options: PierResponseDto[]
  onChange: (value: string) => void
}

export const BerthPlanningPierSelection = observer(
  ({ selectedPierId, options, onChange }: IProps) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()

    const [pier, setPier] = useState('')

    useEffect(() => {
      setPier(selectedPierId)
    }, [setPier, selectedPierId])

    const handleChange = (event: SelectChangeEvent) => onChange(event.target.value)

    if (!options.length) return <></>

    return (
      <Box
        sx={{
          pt: theme.customSpacing.m,
          pr: theme.customSpacing.l,
          pl: theme.customSpacing.m,
          pb: theme.customSpacing.m,
        }}
      >
        <FormControl fullWidth>
          <InputLabel>{t('pier', 'Pier')}</InputLabel>
          <Select onChange={handleChange} value={pier} label={t('pier', 'Pier')} size='small'>
            {options.map(opt => (
              <MenuItem key={opt.id} value={opt.id}>
                {opt.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    )
  },
)
