import { Box, Button, Grid, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import {
  CarrierType,
  CarrierVisitStatus,
  OrderResponseDto,
  OrderStatus,
  TruckDto,
  TruckVisitDto,
  VesselDto,
  VesselVisitDto,
} from '@planning/app/api'
import IconTruck from '@planning/components/icon/IconTruck'
import { GateInViewStore } from '@planning/stores/gateControl/GateInViewStore'
import { formatDate, formatTime } from '@planning/utils'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { zip } from 'lodash'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { FC } from 'react'
import { DropOffOrderStateCard } from './DropOffOrderStateCard'
import { GeneralCargoOrderStateCard } from './GeneralCargoOrderStateCard'
import { PickUpOrderStateCard } from './PickUpOrderStateCard'

type Props = {
  visit: TruckVisitDto
  dropOffOrders: DropOffVisit[]
  pickUpOrders: PickUpVisit[]
  carrierVisitStatus: CarrierVisitStatus
  viewStore: GateInViewStore
  onClickTruckAppointment: (truckVisitId: number) => void
}

export type DropOffVisit = {
  dropOffOrder: OrderResponseDto
  theOtherDirectionStatus?: OrderStatus
  theOtherDirectionVisitType?: CarrierType
  theOtherDirectionVisit?: VesselVisitDto
  theOtherDirectionCarriers?: VesselDto[]
}

export type PickUpVisit = {
  pickUpOrder: OrderResponseDto
  carriers: TruckDto[]
}

// TODO: This component is getting huge - refactor
export const TruckVisitCard: FC<Props> = observer(
  ({
    visit,
    dropOffOrders,
    pickUpOrders,
    carrierVisitStatus,
    viewStore,
    onClickTruckAppointment,
  }) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()

    const renderVisitItems = () => {
      return zip(dropOffOrders, pickUpOrders).map(zipped => {
        const dropOff = zipped[0]
        const pickUp = zipped[1]
        const gridSize = dropOff && pickUp ? 6 : 12

        return (
          <Grid
            key={`${dropOff?.dropOffOrder.id}-${pickUp?.pickUpOrder.id}`}
            container
            sx={{
              borderStyle: 'solid',
              borderColor: `${theme.palette.grey[300]}`,
              borderWidth: '0px 1px 1px 1px',
            }}
          >
            {dropOff && (
              <Grid
                item
                xs={gridSize}
                sx={{
                  borderLeft: `1px solid ${theme.palette.grey[300]}`,
                  '&:first-of-type': { borderLeft: 'none' },
                }}
              >
                {(dropOff.dropOffOrder.commodityId && (
                  <GeneralCargoOrderStateCard type='DropOff' order={dropOff.dropOffOrder} />
                )) || (
                  <DropOffOrderStateCard
                    dropOffOrder={dropOff.dropOffOrder}
                    theOtherDirectionStatus={dropOff.theOtherDirectionStatus}
                    theOtherDirectionVisitType={dropOff.theOtherDirectionVisitType}
                    theOtherDirectionVisit={dropOff.theOtherDirectionVisit}
                    theOtherDirectionCarriers={dropOff.theOtherDirectionCarriers}
                    withBorderLineStyle={false}
                    withBorderAtTheEnd={!!pickUp}
                  />
                )}
              </Grid>
            )}
            {pickUp && (
              <Grid
                item
                xs={gridSize}
                sx={{
                  borderLeft: `1px solid ${theme.palette.grey[300]}`,
                  '&:first-of-type': { borderLeft: 'none' },
                }}
              >
                {(pickUp.pickUpOrder.commodityId && (
                  <GeneralCargoOrderStateCard type='PickUp' order={pickUp.pickUpOrder} />
                )) || (
                  <PickUpOrderStateCard
                    pickUpOrder={pickUp.pickUpOrder}
                    carriers={pickUp.carriers}
                    withBorderLineStyle={false}
                  />
                )}
              </Grid>
            )}
          </Grid>
        )
      })
    }

    const validateGateOut = () => {
      // Getting the actual orders (OrderResponseDto) from the DropOffVisit and PickUpVisit objects
      const _dropOffOrders = dropOffOrders.map(o => o.dropOffOrder)
      const _pickUpOrders = pickUpOrders.map(o => o.pickUpOrder)

      viewStore.validateManualGateOut(visit, [..._dropOffOrders, ..._pickUpOrders])
    }

    return (
      <div
        onClick={() =>
          carrierVisitStatus === CarrierVisitStatus.Expected
            ? onClickTruckAppointment(visit.id)
            : undefined
        }
        aria-hidden='true'
      >
        <Box
          data-cy={`truck-visit-card`}
          sx={{
            cursor: carrierVisitStatus === CarrierVisitStatus.Expected ? 'pointer' : undefined,
            backgroundColor: `${theme.palette.background.paper}`,
          }}
        >
          <Box
            sx={{
              borderTopLeftRadius: theme.customRadius.medium,
              borderTopRightRadius: theme.customRadius.medium,
              borderRadius:
                carrierVisitStatus === CarrierVisitStatus.Expected
                  ? theme.customRadius.medium
                  : undefined,
              border: `1px solid ${theme.palette.grey[300]}`,
              p: '0 16px',
              backgroundColor: `${theme.palette.background.paper}`,
            }}
          >
            <Stack direction='row' justifyContent='space-between'>
              <Stack direction='row' spacing='8px' alignSelf='center'>
                <IconTruck />
                <Typography variant='subtitle1'>{visit.identifier}</Typography>
              </Stack>

              {carrierVisitStatus === CarrierVisitStatus.Expected && (
                <Stack
                  direction='row'
                  sx={{ height: '64px', display: 'flex', alignItems: 'center' }}
                >
                  <Typography variant='caption' alignSelf='center'>
                    {t('expected', 'Expected')}:{' '}
                    {visit.eta ? `${formatDate(visit.eta)}   ${formatTime(visit.eta)}` : 'unknown'}{' '}
                    - {visit.etd ? formatTime(visit.etd) : 'unknown'}
                  </Typography>
                </Stack>
              )}

              {carrierVisitStatus !== CarrierVisitStatus.Expected && (
                <Stack
                  direction='row'
                  sx={{ height: '4rem', display: 'flex', alignItems: 'center' }}
                >
                  <Typography variant='caption' alignSelf='center'>
                    {carrierVisitStatus === CarrierVisitStatus.Arrived
                      ? `${t('arrived', 'Arrived')}: ${moment(visit.ata).fromNow()}`
                      : `${t('departed', 'Departed')}: ${moment(visit.atd).fromNow()}`}
                  </Typography>
                  {carrierVisitStatus === CarrierVisitStatus.Arrived && (
                    <Button
                      sx={{ marginLeft: '1rem' }}
                      variant='outlined'
                      color='primary'
                      size='small'
                      onClick={validateGateOut}
                    >
                      {t('gateOut', 'Gate Out')}
                    </Button>
                  )}
                </Stack>
              )}
            </Stack>
          </Box>
          {carrierVisitStatus !== CarrierVisitStatus.Expected && renderVisitItems()}
        </Box>
      </div>
    )
  },
)
