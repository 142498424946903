import { alpha, Theme } from '@mui/material/styles'

// ----------------------------------------------------------------------

export default function Autocomplete(theme: Theme) {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& span.MuiAutocomplete-tag': {
            ...theme.typography.body2,
            width: 24,
            height: 24,
            lineHeight: '24px',
            textAlign: 'center',
            borderRadius: theme.customRadius.medium,
            backgroundColor: alpha(theme.palette.grey[500], 0.16),
          },
        },
        paper: {
          boxShadow: theme.customShadows.dropdown,
        },
        listbox: {
          padding: `0 ${theme.customSpacing.xs}`,
        },
        option: {
          ...theme.typography.body2,
          padding: theme.customSpacing.xs,
          margin: `${theme.customSpacing.xxs} 0`,
          borderRadius: theme.customRadius.medium,
        },
      },
    },
  }
}
