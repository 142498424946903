import { BerthResponseDto } from '@planning/app/api'
import { IEvent, IMessageBus } from '@planning/messages'
import { EventTypes } from '@planning/messages/eventsTypes'
import { GetBerthsQuery } from '@planning/messages/queries'
import _ from 'lodash'
import { action, computed, makeObservable } from 'mobx'
import { EntityItem } from '../base/EntityItem'
import { ItemStore } from '../base/ItemStore'
import { IEntityStore } from '../types'
import { IBerthItem } from './BerthItem'

export class BerthItemStore
  extends ItemStore<BerthResponseDto, IBerthItem>
  implements IEntityStore<IBerthItem>
{
  private hasBeenLoaded = false

  constructor(private messageBus: IMessageBus) {
    super((key, data) => new EntityItem<BerthResponseDto>(key, data))
    makeObservable(this, {
      berthsByPier: computed,

      receiveBerthsMessage: action,
      receiveBerthUpsertedEventMessage: action,
    })

    messageBus.subscribeEvent(GetBerthsQuery.type, this.receiveBerthsMessage)
    messageBus.subscribeEvent(EventTypes.BerthUpsertedEvent, this.receiveBerthUpsertedEventMessage)
  }

  fetch = async () => {
    if (this.hasBeenLoaded) return

    this.hasBeenLoaded = true

    await this.messageBus.dispatchQuery(new GetBerthsQuery())
  }

  get berthsByPier() {
    return _(this.elements)
      .filter(x => !x.data.isDeleted)
      .orderBy(b => b.data.quayMeterMarkFrom)
      .groupBy(berth => berth.data.pierId)
      .value()
  }

  getBerthsByPier(pier: string) {
    return (
      _(this.berthsByPier[pier])
        ?.map(b => b.data)
        .value() ?? []
    )
  }

  receiveBerthsMessage = (event: IEvent<BerthResponseDto[]>): void => {
    if (event.payload) {
      this.upsertBulk(event.payload)
    }
  }

  receiveBerthUpsertedEventMessage = (event: IEvent<BerthResponseDto>) => {
    if (event.payload) {
      this.upsert(event.payload)
    }
  }
}
