import { IReleaseOrderItem } from '@planning/rt-stores/releaseOrder/ReleaseOrderItem'
import { action, makeObservable, observable } from 'mobx'

export class ReleaseOrderEditStore {
  selectedIds: string[] = []
  selectedOrders: IReleaseOrderItem[] = []

  constructor() {
    makeObservable(this, {
      selectedIds: observable,
      selectedOrders: observable,
      setSelectedIds: action,
      setSelectedOrders: action,
    })
  }

  setSelectedIds(selectedIds: string[]) {
    this.selectedIds = selectedIds
  }

  setSelectedOrders(selectedOrders: IReleaseOrderItem[]) {
    this.selectedOrders = selectedOrders
  }
}
