import { Box, Chip, ClickAwayListener } from '@mui/material'
import { ReactElement, ReactNode, useState } from 'react'
import { useMinimalsTheme } from '../../../hooks'
import { ChevronDownIcon } from '../../icons'

interface Props {
  icon?: ReactElement
  options: ReactNode
  label?: string
  value?: string
  disabled?: boolean
  dataCy?: string
  onReset?: () => void
}
export const ChipWithOptions = ({
  icon,
  value,
  label,
  options,
  disabled,
  dataCy,
  onReset,
}: Props) => {
  const theme = useMinimalsTheme()
  const [showOptions, setShowOptions] = useState<boolean>(false)
  const normalizedLabel = value ?? label

  const handleClickAway = () => {
    if (showOptions) {
      setShowOptions(false)
    }
  }

  const handleReset = () => {
    if (value && !!onReset) {
      setShowOptions(false)
      onReset()
    } else if (!value || !onReset) {
      setShowOptions(true)
    }
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box>
        <Chip
          label={normalizedLabel}
          icon={icon}
          disabled={disabled}
          color={value ? 'primary' : undefined}
          deleteIcon={!value || !onReset ? <ChevronDownIcon /> : undefined}
          onDelete={handleReset}
          onClick={() => setShowOptions(true)}
          data-cy={dataCy}
        />
        <Box marginTop={theme.customSpacing.xxs} onClick={handleClickAway}>
          {showOptions && options}
        </Box>
      </Box>
    </ClickAwayListener>
  )
}
