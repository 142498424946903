import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'

export const NoInfoMessage = () => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  return (
    <Box
      sx={{
        p: theme.customSpacing.s,
        borderRadius: theme.customRadius.medium,
        bgcolor: theme.palette.grey[200],
        textAlign: 'center',
      }}
    >
      <Typography variant='body1'>{t('noInformation', 'No information')}</Typography>
    </Box>
  )
}
