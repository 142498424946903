import { Box, Button, Tooltip } from '@mui/material'
import { useOpenFeatureClient } from '@openfeature/react-sdk'
import { usePlanningStore } from '@planning/AppProvider'
import { CargoType, CarrierType, CarrierVisitDirection, OrderResponseDto } from '@planning/app/api'
import { AddOrderButton } from '@planning/components/AddOrderButton'
import { DischargeOrderFilter } from '@planning/components/DischargeOrderFilter'
import { GridToolbar } from '@planning/components/GridToolbar'
import { LoadOrderFilter } from '@planning/components/LoadOrderFilter'
import { CreateOrdersPage } from '@planning/pages/Order'
import { CreateGeneralCargoOrder } from '@planning/pages/Order/CreateGeneralCargoOrders'
import { IVesselVisitItem } from '@planning/pages/Order/stores/ContainerVisitItem'
import { IAmAContainerVisitToo } from '@planning/pages/Order/stores/SelectOrderViewStore'
import { IRailVisitItem } from '@planning/rt-stores/railVisit/RailVisitItem'
import { IPaginatedStoreWithItems } from '@planning/stores/PaginatedStore'
import { ValidateOutboundOrderFunc } from '@planning/stores/gateControl/ValidateOutboundDto'
import { RailcarSequencingStore } from '@planning/stores/railcarSequencing/RailcarSequencingStore'
import { useTranslate } from '@tolgee/react'
import { computed } from 'mobx'
import { observer } from 'mobx-react-lite'
import { FC, useMemo } from 'react'
import { OrderListUploadDialogV2 } from './Upload/OrderListUploadDialog'

interface Props {
  store: IPaginatedStoreWithItems<OrderResponseDto>
  direction: CarrierVisitDirection
  visitId?: number
  cargoType?: CargoType
  visitType: CarrierType
  validationOutboundRequest?: ValidateOutboundOrderFunc
}

export const OrderListGridToolbar: FC<Props> = observer(
  ({ store, direction, visitId, visitType, cargoType, validationOutboundRequest }) => {
    const {
      dialogStore,
      drawerStore,
      orderListUploadViewStoreV2,
      vesselVisitItemStore,
      railVisitItemStore,
      generalCargoViewStore,
    } = usePlanningStore()
    const { t } = useTranslate()
    const featureFlagClient = useOpenFeatureClient()

    const handleUpload = async () => {
      if (!visitId) return

      orderListUploadViewStoreV2.reset()
      if (visitType === CarrierType.Train) {
        await orderListUploadViewStoreV2.setRailVisitId(visitId)
      } else {
        await orderListUploadViewStoreV2.setVesselVisitId(visitId)
      }
      orderListUploadViewStoreV2.setDirection(direction)
      orderListUploadViewStoreV2.setOpen(true)
    }

    const handleShowFilter = () => {
      if (direction === CarrierVisitDirection.Inbound) {
        drawerStore.showView(<DischargeOrderFilter store={store} />)
      } else {
        drawerStore.showView(<LoadOrderFilter store={store} />)
      }
    }

    const isContainerVisit = cargoType === CargoType.Container

    const handleShowCreateOrderForm = () => {
      if (!visitId) return

      let carrierVisitItem: IVesselVisitItem | IRailVisitItem

      if (visitType === CarrierType.Train) {
        carrierVisitItem = computed(() => railVisitItemStore.elements[visitId]).get()
      } else {
        carrierVisitItem = computed(() => vesselVisitItemStore.elements[visitId]).get()
      }

      // TODO: Figure out what this is being used for! Object is bronken and does not appear to be used correctly
      const containerVisitOrderItem = {
        visit: carrierVisitItem.data,
        order: {
          carrierVisitId: visitId,
          carrierVisitType: visitType,
          direction: direction,
        },
      } as any

      const inbound =
        direction === CarrierVisitDirection.Inbound ? containerVisitOrderItem : undefined

      const outbound =
        direction === CarrierVisitDirection.Outbound ? containerVisitOrderItem : undefined

      const containerVisit: IAmAContainerVisitToo = [inbound, outbound]

      generalCargoViewStore.getPackages()
      generalCargoViewStore.selectOrder()

      dialogStore.openDialog(
        isContainerVisit ? (
          <CreateOrdersPage forcedDirection={direction} containerVisit={containerVisit} />
        ) : (
          <CreateGeneralCargoOrder
            visit={containerVisit}
            validateOutboundRequest={validationOutboundRequest}
          />
        ),
      )
    }

    const isUploadDisabled = useMemo(() => {
      let isSomeRailcarCheckedIn = false

      if (visitType === CarrierType.Train) {
        const railStore = store as RailcarSequencingStore
        const items =
          direction === CarrierVisitDirection.Inbound
            ? railStore.entities?.discharge?.items
            : railStore.entities?.load?.items
        const itemsArray = Object.values(items || {})
        isSomeRailcarCheckedIn = itemsArray.some(item => item.content.checkedIn)
      }

      return isSomeRailcarCheckedIn
    }, [visitType])

    return (
      <>
        <GridToolbar
          store={store}
          onShowFilter={() => handleShowFilter()}
          actions={
            <>
              <AddOrderButton onClick={handleShowCreateOrderForm} />

              {isContainerVisit && (
                <Tooltip
                  title={
                    isUploadDisabled
                      ? t(
                          'cantUpload',
                          'You can no longer upload files because the inspection already started',
                        )
                      : undefined
                  }
                >
                  <Box>
                    <Button
                      disabled={isUploadDisabled}
                      variant='contained'
                      size='large'
                      onClick={handleUpload}
                      data-cy='upload-documents-vessel-visit-btn'
                    >
                      {t('uploadDocuments', 'Upload Documents')}
                    </Button>
                  </Box>
                </Tooltip>
              )}
            </>
          }
        />
        {isContainerVisit && (
          <OrderListUploadDialogV2
            visitType={visitType}
            enableFieldSelectionFromOrdersView={false}
            open={orderListUploadViewStoreV2.open}
            handleDialogClose={() => orderListUploadViewStoreV2.reset()}
          />
        )}
      </>
    )
  },
)
