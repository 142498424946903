export enum GCStorageReportMode {
  Stock,
  InboundOrder,
}

export const gcStorageReportModes: GCStorageReportMode[] = [
  GCStorageReportMode.Stock,
  GCStorageReportMode.InboundOrder,
]

export default gcStorageReportModes
