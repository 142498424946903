import { TruckDto } from '@planning/app/api'
import { IEvent, IMessageBus } from '@planning/messages'
import { EventTypes } from '@planning/messages/eventsTypes'
import { GetTrucksQuery } from '@planning/messages/queries'
import _ from 'lodash'
import { action, makeObservable } from 'mobx'
import { ItemStore } from '../base/ItemStore'
import { IEntityStore } from '../types'
import { ITruckItem, TruckItem } from './TruckItem'

export class TruckItemStore
  extends ItemStore<TruckDto, ITruckItem>
  implements IEntityStore<ITruckItem>
{
  constructor(messageBus: IMessageBus) {
    super((key, data) => new TruckItem(key, data), {
      messageBus,
      bulkFetchFunc: (_: number[]) => new GetTrucksQuery(1, 10000), // todo: [north] GetTrucksByIdsQuery
    })
    makeObservable(this, {
      receiveTrucksMessage: action,
      receiveTruckUpsertedEventMessage: action,
      receiveTruckDeletedEventMessage: action,
    })

    messageBus.subscribeEvent(GetTrucksQuery.type, this.receiveTrucksMessage)
    messageBus.subscribeEvent(EventTypes.TruckUpsertedEvent, this.receiveTruckUpsertedEventMessage)
    messageBus.subscribeEvent(EventTypes.TruckDeletedEvent, this.receiveTruckDeletedEventMessage)
  }

  receiveTrucksMessage = (event: IEvent<TruckDto[]>): void => {
    if (event.payload) {
      this.upsertBulk(event.payload)
    }
  }

  receiveTruckUpsertedEventMessage = (event: IEvent<TruckDto>): void => {
    if (event.payload) {
      this.upsert(event.payload)
    }
  }

  receiveTruckDeletedEventMessage = (event: IEvent<number>): void => {
    if (event.payload && _.has(this.elements, event.payload)) {
      this.delete(event.payload)
    }
  }
}
