import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from '@mui/material'
import { ContainerTurnoverDto, YardPositionDto } from '@storage/app/api'
import { YardPositionInputContainer } from '@storage/features/yard-operation-control/Variants/YardPositionInput/YardPositionInputContainer'
import { AppAlert } from '@storage/stores/alert.store'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'

interface Props {
  turnover?: ContainerTurnoverDto
  open: boolean
  disableConfirm: boolean
  onChange: (position?: YardPositionDto) => void
  onSubmit: () => Promise<void> | void
  onClose: () => void
  isLoading: boolean
  validationAlerts?: AppAlert[]
}

export const UpdateContainerPositionForm = observer(
  ({
    turnover,
    open,
    disableConfirm,
    onChange,
    onSubmit,
    onClose,
    validationAlerts,
    isLoading,
  }: Props) => {
    const { t } = useTranslate()
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{`${t('updateContainerPosition', 'Update Position for Container')} ${
          turnover?.containerNumber
        }`}</DialogTitle>
        <DialogContent>
          <YardPositionInputContainer
            disableSelectFlexibleZones={true}
            onChange={onChange}
            turnover={turnover}
            alertSx={{ width: '335px' }}
            validationAlerts={validationAlerts}
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            disabled={disableConfirm || isLoading}
            variant='contained'
            color='primary'
            onClick={onSubmit}
            startIcon={isLoading && <CircularProgress size={20} color='inherit' />}
          >
            {t('confirm', 'Confirm')}
          </Button>
          <Button variant='text' color='inherit' onClick={onClose}>
            {t('cancel', 'Cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  },
)
