import { Stack } from '@mui/material'
import { CarrierType } from '@operations/app/api'
import { FormProps, SelectOption } from '@operations/app/models'
import { ControlledCheckbox } from '@operations/components/form-controls'
import { ControlledCustomer } from '@operations/features/equipments/components/form/controlledCustomer/ControlledCustomer'
import { ControlledYardBlock } from '@operations/features/equipments/components/form/controlledYardBlock/ControlledYardBlock'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { defaultValues, ReeferMonitoringFilter } from './reefer-monitoring-filter.model'

interface Props extends FormProps {
  filter?: ReeferMonitoringFilter
  yardBlocks: SelectOption[]
  customers: SelectOption[]
  onSubmit: (formValues: ReeferMonitoringFilter) => Promise<void> | void
}

export const ReeferMonitoringFiltersForm = ({
  id,
  filter,
  yardBlocks,
  customers,
  onSubmit,
}: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const carrierTypeOptions = useMemo<SelectOption[]>(
    () => [
      { label: t('vessel', 'Vessel'), value: CarrierType.Vessel },
      { label: t('truck', 'Truck'), value: CarrierType.Truck },
      { label: t('train', 'Train'), value: CarrierType.Train },
    ],
    [t],
  )

  const { handleSubmit, formState, control, reset } = useForm<ReeferMonitoringFilter>({
    defaultValues: defaultValues,
  })

  useEffect(() => {
    if (filter) {
      reset(filter)
    }
  }, [filter, reset])

  return (
    <Stack
      id={id}
      component='form'
      noValidate
      autoComplete='off'
      gap={theme.customSpacing.l}
      onSubmit={handleSubmit(onSubmit)}
    >
      {yardBlocks.length > 0 && (
        <ControlledYardBlock
          name='yardBlockIds'
          formState={formState}
          control={control}
          label={t('yardBlocks', 'Yard blocks')}
          options={yardBlocks}
        />
      )}

      {customers.length > 0 && (
        <ControlledCustomer
          name='customerIds'
          formState={formState}
          control={control}
          label={t('customers', 'Customers')}
          options={customers}
        />
      )}

      <Stack>
        <ControlledCheckbox
          name='inboundCarrierType'
          formState={formState}
          control={control}
          options={carrierTypeOptions}
          multiple
          label={t('inboundCarrierType', 'Inbound carrier type')}
        />
      </Stack>

      <Stack>
        <ControlledCheckbox
          name='outboundCarrierType'
          formState={formState}
          control={control}
          options={carrierTypeOptions}
          multiple
          label={t('outboundCarrierType', 'Outbound carrier type')}
        />
      </Stack>
    </Stack>
  )
}
