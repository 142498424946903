import { AppStore } from '@storage/stores/app.store'
import { SnackbarStore } from '@storage/stores/snackbar.store'
import axios, { AxiosInstance } from 'axios'
import { Configuration } from '../api'
import {
  DomainExceptionResponseInterceptor,
  SetAccessTokenRequestInterceptor,
  ToggleLoadingRequestInterceptor,
  ToggleLoadingResponseInterceptor,
} from './interceptors'

const httpClient = axios.create()

export const setupInterceptors = (appStore: AppStore, snackbarStore: SnackbarStore) => {
  httpClient.interceptors.request.use(SetAccessTokenRequestInterceptor.onFulfilled)
  httpClient.interceptors.request.use(
    ToggleLoadingRequestInterceptor.onFulfilled(appStore),
    ToggleLoadingRequestInterceptor.onRejected(appStore),
  )

  httpClient.interceptors.response.use(
    undefined,
    DomainExceptionResponseInterceptor.onRejected(snackbarStore),
  )

  httpClient.interceptors.response.use(
    ToggleLoadingResponseInterceptor.onFulfilled(appStore),
    ToggleLoadingResponseInterceptor.onRejected(appStore),
  )
}

export const createApiClient = <T>(service: {
  new (configuration: Configuration, basePath: string, axios: AxiosInstance): T
}): T => {
  return new service(new Configuration(), '', httpClient)
}

export default httpClient
