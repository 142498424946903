import { Grid, TextField, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { CustomInputAdornment } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useFormContext } from 'react-hook-form'

export const VesselVisitPlanningDraftsForm = observer(() => {
  const { t } = useTranslate()

  const { register, watch } = useFormContext()

  const importDraftValue = watch('importDraft')
  const exportDraftValue = watch('exportDraft')

  return (
    <>
      <Typography variant='subtitle1' marginTop='1rem' marginBottom='0.5rem'>
        {t('vesselDrafts', 'Vessel Drafts')}
      </Typography>
      <Grid container direction='row' columnSpacing={{ xs: 1 }}>
        <Grid item xs={6}>
          <TextField
            data-cy='create-vessel-visit-form-import-draft-field'
            fullWidth
            type='number'
            inputProps={{
              min: 0.1,
              max: 13,
              step: '0.01',
            }}
            InputProps={{
              endAdornment: (
                <CustomInputAdornment position='end' color='secondary'>
                  m
                </CustomInputAdornment>
              ),
            }}
            label={t('importDraftIn', 'Import Draft (In)')}
            variant='outlined'
            value={importDraftValue || ''}
            {...register('importDraft')}
            name='importDraft'
            onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            data-cy='create-vessel-visit-form-export-draft-field'
            type='number'
            inputProps={{
              min: 0.1,
              max: 13,
              step: '0.01',
            }}
            InputProps={{
              endAdornment: (
                <CustomInputAdornment position='end' color='secondary'>
                  m
                </CustomInputAdornment>
              ),
            }}
            label={t('exportDraftOut', 'Export Draft (out)')}
            variant='outlined'
            value={exportDraftValue || ''}
            {...register('exportDraft')}
            name='exportDraft'
            onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
          />
        </Grid>
      </Grid>
    </>
  )
})
