import { ReeferTemperatureStore } from '@operations/stores/ReeferTemperatureStore'
import _ from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'

export class CoolingOrdersUIStore {
  selectedYardBlocks: string[] = []

  isSearchOpen = false
  searchText?: string

  isStepperOpen = false
  coolingOrderId?: number

  constructor(private reeferTemperatureStore: ReeferTemperatureStore) {
    makeObservable(this, {
      coolingOrderId: observable,
      isSearchOpen: observable,
      isStepperOpen: observable,
      searchText: observable,
      selectedYardBlocks: observable,

      setSearchText: action,
      setYardBlock: action,
      toggleSearch: action,
      toggleStepper: action,

      coolingOrders: computed,
      coolingOrdersFiltered: computed,
      isSearching: computed,
      searchedCoolingOrders: computed,
      selectedCoolingOrder: computed,
      yardBlocks: computed,
    })
  }

  public toggleSearch() {
    this.isSearchOpen = !this.isSearchOpen
    this.setSearchText()
  }

  public toggleStepper(orderId?: number) {
    this.isStepperOpen = !this.isStepperOpen
    this.coolingOrderId = orderId
  }

  public setSearchText(newValue?: string) {
    if (this.searchText !== newValue) {
      this.searchText = newValue
    }
  }

  public setYardBlock(yardBlock: string) {
    if (!this.selectedYardBlocks.includes(yardBlock)) {
      this.selectedYardBlocks = [...this.selectedYardBlocks, yardBlock]
    } else {
      this.selectedYardBlocks = this.selectedYardBlocks.filter(x => x !== yardBlock)
    }
  }

  public get coolingOrdersFiltered() {
    if (!this.selectedYardBlocks.length) return this.coolingOrders

    return this.coolingOrders.filter(
      x => x.yardBlock && this.selectedYardBlocks.includes(x.yardBlock),
    )
  }

  public get searchedCoolingOrders() {
    if (!this.searchText) return []

    const searchedContainerWithoutSpace = this.searchText.toUpperCase().replace(/\s/g, '')

    return this.coolingOrders.filter(x =>
      x.containerNumber?.includes(searchedContainerWithoutSpace),
    )
  }

  public get isSearching() {
    return !!this.searchText
  }

  public get coolingOrders() {
    return this.reeferTemperatureStore.coolingOrders
  }

  public get yardBlocks(): string[] {
    return (
      _(this.coolingOrders.filter(x => x.yardBlock).map(x => x.yardBlock!))
        .uniqBy(x => x)
        .value() ?? []
    )
  }

  public get selectedCoolingOrder() {
    return this.coolingOrders.find(x => x.id === this.coolingOrderId)
  }
}
