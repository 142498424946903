import { ReleaseOrderDto } from '@planning/app/api'
import { IEvent, IMessageBus } from '@planning/messages'
import { EventTypes } from '@planning/messages/eventsTypes'
import { GetReleaseOrdersQuery } from '@planning/messages/queries/getReleaseOrdersQueryHandler'
import _ from 'lodash'
import { action, makeObservable } from 'mobx'
import { ItemStore } from '../base/ItemStore'
import { IReleaseOrderItem, ReleaseOrderItem } from './ReleaseOrderItem'

// todo: [north] merge to orderItemStore when we have a proper loading condition for DeliverOrders page
export class ReleaseOrderItemStore extends ItemStore<ReleaseOrderDto, IReleaseOrderItem, string> {
  constructor(messageBus: IMessageBus) {
    super((key, data) => new ReleaseOrderItem(key, data))
    makeObservable(this, {
      receiveDeliveryOrderMessage: action,
      receiveOrdersUpsertedMessage: action,
      receiveOrdersDeletedMessage: action,
    })

    messageBus.subscribeEvent(GetReleaseOrdersQuery.type, this.receiveDeliveryOrderMessage)
    messageBus.subscribeEvent(
      EventTypes.ReleaseOrdersUpsertedEvent,
      this.receiveOrdersUpsertedMessage,
    )
    messageBus.subscribeEvent(EventTypes.OrdersDeletedEvent, this.receiveOrdersDeletedMessage)
  }

  receiveDeliveryOrderMessage = (event: IEvent<ReleaseOrderDto[]>) => {
    if (event.payload) {
      this.upsertBulk(event.payload)
    }
  }

  receiveOrdersUpsertedMessage = (event: IEvent<ReleaseOrderDto[]>): void => {
    if (event.payload) {
      event.payload.forEach(dto => this.customUpsert(dto))
    }
  }

  customUpsert = (data: ReleaseOrderDto) => {
    if (_.has(this.elements, data.id)) {
      _.get(this.elements, data.id).update(data)
    } else if (_.has(this.elements, data.containerNumber)) {
      this.delete(data.containerNumber)
      this.upsert(data)
    } else {
      this.upsert(data)
    }
  }

  receiveOrdersDeletedMessage = (event: IEvent<number[]>): void => {
    event.payload?.forEach(id => {
      const key = id.toString()
      if (_.has(this.elements, key)) this.delete(key)
    })
  }
}
