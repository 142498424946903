import { createSvgIcon } from '@mui/material'

export const FoldableIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 2.28872L14.625 6.63848L17.4251 1.99863C18.3254 2.1935 19 2.99466 19 3.95336V20.044C19 21.0021 18.3263 21.8029 17.4268 21.9984L14.625 17.3556L12.0005 21.7046L9.37598 17.3556L6.57405 21.9986C5.67411 21.8034 5 21.0024 5 20.044V3.95336C5 2.99465 5.67455 2.1935 6.57493 1.99863L9.375 6.63848L12 2.28872ZM9.375 7.47961C9.65114 7.47961 9.875 7.70346 9.875 7.97961L9.875 15.9796C9.875 16.2557 9.65114 16.4796 9.375 16.4796C9.09886 16.4796 8.875 16.2557 8.875 15.9796L8.875 7.97961C8.875 7.70346 9.09886 7.47961 9.375 7.47961ZM15.124 7.97961C15.124 7.70346 14.9002 7.47961 14.624 7.47961C14.3479 7.47961 14.124 7.70346 14.124 7.97961L14.124 15.9796C14.124 16.2557 14.3479 16.4796 14.624 16.4796C14.9002 16.4796 15.124 16.2557 15.124 15.9796L15.124 7.97961Z'
      fill='currentColor'
    />
  </svg>,
  'FoldableIcon',
)
