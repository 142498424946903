import { Typography, Button, CircularProgress } from '@mui/material'
import { Box, Stack } from '@mui/system'
import StackedAlert from '@storage/components/StackedAlert'
import { AppAlert } from '@storage/stores/alert.store'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'

interface CarrierVisitAllocationRulePanelProps {
  ruleName: string
  filteredContainersCount: number
  position: string
  alerts: AppAlert[]
  actionButtonLabel: string
  isLoading: boolean
  onAllocate: () => void
}

const CarrierVisitAllocationRulePanel = ({
  ruleName,
  filteredContainersCount,
  position,
  isLoading,
  alerts,
  actionButtonLabel,
  onAllocate,
}: CarrierVisitAllocationRulePanelProps) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  return (
    <Stack>
      <Stack direction='row' gap={1} alignItems='center' justifyContent='space-between'>
        <Box
          sx={{
            flex: 1,
            textAlign: 'center',
            borderRadius: theme.customRadius.medium,
            p: 1,
            bgcolor: theme.palette.grey[200],
          }}
        >
          <Typography variant='subtitle2'>
            {t(
              'allocationRulePanelMessage',
              '{ruleName} rule will allocate {filteredContainersCount} selected containers to {position}',
              {
                ruleName,
                filteredContainersCount,
                position,
              },
            )}
          </Typography>
        </Box>

        <Button
          onClick={onAllocate}
          disabled={isLoading}
          startIcon={isLoading && <CircularProgress size={20} color='inherit' />}
          variant='contained'
          color='primary'
        >
          {actionButtonLabel}
        </Button>
      </Stack>
      {!!alerts.length && (
        <Box mt={1} mb={0.5}>
          <StackedAlert alerts={alerts} />
        </Box>
      )}
    </Stack>
  )
}

export default CarrierVisitAllocationRulePanel
