import {
  CarrierVisitStatus,
  EquipmentDto,
  EquipmentType,
  OperationType,
  WorkAreaEquipmentBindingTypes,
} from '@operations/app/api'
import { EquipmentStore } from '@operations/stores/EquipmentStore'
import { VesselVisitStore } from '@operations/stores/VesselVisitStore'
import { WorkAreaStore } from '@operations/stores/WorkAreaStore'
import { WorkQueueStore } from '@operations/stores/WorkQueueStore'
import _ from 'lodash'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { WorkAreaEquipments } from '../models/work-area-equipments.model'

export class EquipmentPlanningEquipmentsUIStore {
  carrierVisitId?: number
  selectedEquipmentIds: number[] = []
  draggingEquipmentId?: number
  assignmentType: WorkAreaEquipmentBindingTypes = WorkAreaEquipmentBindingTypes.Discharge
  equipmentIdsOnUseForOtherVistis: number[] = []

  constructor(
    private equipmentStore: EquipmentStore,
    private workAreaStore: WorkAreaStore,
    private vesselVisitStore: VesselVisitStore,
    private workQueueStore: WorkQueueStore,
  ) {
    makeObservable(this, {
      carrierVisitId: observable,
      selectedEquipmentIds: observable,
      draggingEquipmentId: observable,
      assignmentType: observable,
      equipmentIdsOnUseForOtherVistis: observable,

      setCarrierVisitId: action,
      setAssignmentType: action,
      setEquipmentIdsInUseForOtherVisits: action,

      workAreasOfVesselVisit: computed,
      availableEquipments: computed,
      isDndDisabled: computed,
      assignedSTS: computed,
      workAreasEquipments: computed,
      workAreaWidth: computed,
      hasCraneSplit: computed,
      hasCraneForSelectedAssignmentType: computed,
    })
  }

  public setCarrierVisitId(carrierVisitId: number): void {
    if (this.carrierVisitId !== carrierVisitId) this.carrierVisitId = carrierVisitId
  }

  public setAssignmentType(assignmentType: WorkAreaEquipmentBindingTypes): void {
    this.assignmentType = assignmentType
  }

  public setEquipmentIdsInUseForOtherVisits(equipmentIds: number[]) {
    this.equipmentIdsOnUseForOtherVistis = equipmentIds
  }

  public getIsEquipmentInOperation(equipmentId: number) {
    return this.equipmentIdsOnUseForOtherVistis.includes(equipmentId)
  }

  public get workAreasOfVesselVisit() {
    return this.carrierVisitId
      ? _(this.workAreaStore.items.filter(i => i.carrierVisit?.id === this.carrierVisitId))
          .orderBy(a => a.id)
          .value()
      : undefined
  }

  public get hasCraneForSelectedAssignmentType(): boolean {
    const operationType = this.assignmentTypeMappedToOperationType

    return this.workQueueStore.items.some(
      i => i.carrierVisitId === this.carrierVisitId && i.operationType === operationType,
    )
  }

  public get hasCraneSplit() {
    return this.workQueueStore.items.some(i => i.carrierVisitId === this.carrierVisitId)
  }

  public get workAreasEquipments(): WorkAreaEquipments[] {
    if (!this.workAreasOfVesselVisit) {
      return []
    }

    const operationType = this.assignmentTypeMappedToOperationType
    const workQueues = this.workQueueStore.items.filter(
      i => i.carrierVisitId === this.carrierVisitId && i.operationType === operationType,
    )

    return this.workAreasOfVesselVisit
      .map(w => {
        const equipmentIds = w.workAreaEquipments
          .filter(e => e.assignmentType.includes(this.assignmentType))
          .map(e => e.equipmentId)

        return {
          workAreaId: w.id,
          name: w.crane?.name,
          finishedCount: workQueues.reduce(
            (prev, curr) =>
              prev +
              (equipmentIds.includes(curr.craneId) ? curr.finishedWorkInstructionsAmount : 0),
            0,
          ),
          expectedCount: workQueues.reduce(
            (prev, curr) =>
              prev + (equipmentIds.includes(curr.craneId) ? curr.containersAmount : 0),
            0,
          ),
          workAreaEquipments: _(
            this.equipmentStore.items.filter(item => equipmentIds.includes(item.id)),
          )
            .orderBy(i => [i.name, i.equipmentType])
            .value(),
        }
      })
      .filter(wa => wa.expectedCount)
  }

  public get workAreaWidth() {
    const count = this.workAreasEquipments.length
    const width = 100 / count

    return width === 100 ? '100%' : width < 25 ? '23%' : `${width - (1 + (count * 2) / 10)}%`
  }

  public get availableEquipments(): Array<EquipmentDto> | undefined {
    return this.carrierVisitId
      ? _(this.equipmentStore.items)
          .orderBy(i => i.name)
          .value()
      : undefined
  }

  public get assignedSTS() {
    const sts: EquipmentDto[] = []

    this.workAreasEquipments
      .flatMap(wa => wa.workAreaEquipments)
      .forEach(eq => {
        if (eq.equipmentType === EquipmentType.Sts && !sts.includes(eq)) {
          sts.push(eq)
        }
      })

    return sts
  }

  unselectAll() {
    this.selectedEquipmentIds = []
  }

  startDragging(id: number) {
    const selected: number | undefined = this.selectedEquipmentIds.find(
      (eqId: number): boolean => eqId === id,
    )

    if (!selected) this.unselectAll()

    this.draggingEquipmentId = id
  }

  async endDragging(
    isCancelled: boolean,
    workAreaId: number | null,
    workAreaOriginId: number | null,
    index?: number,
  ) {
    // nothing to do
    if (index === undefined || isCancelled) {
      this.draggingEquipmentId = undefined
      return
    }

    // nothing to move (shouldn't happen)
    if (this.selectedEquipmentIds.length === 0 && !this.draggingEquipmentId) {
      return
    }

    const equipmentIds: number[] =
      this.selectedEquipmentIds.length > 0
        ? this.selectedEquipmentIds
        : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          [this.draggingEquipmentId!]

    try {
      equipmentIds.forEach(async equipmentId => {
        const eq = this.equipmentStore.items.find(eq => eq.id == equipmentId)
        if (!eq) return
        this.assignEquipment(eq.id, workAreaId, workAreaOriginId)
      })
    } finally {
      runInAction(async () => {
        this.selectedEquipmentIds = []
        this.draggingEquipmentId = undefined
      })
    }
  }

  async assignEquipment(
    equipmentId: number,
    workAreaId: number | null,
    workAreaOriginId: number | null,
  ) {
    await this.workAreaStore.assignEquipmentToWorkArea(
      equipmentId,
      this.assignmentType,
      workAreaId,
      workAreaOriginId,
    )
  }

  async unAssignEquipment(equipmentId: number, workAreaId: number) {
    await this.workAreaStore.unassignEquipmentFromWorkArea(
      equipmentId,
      workAreaId,
      this.assignmentType,
    )
  }

  public get isDndDisabled() {
    return this.carrierVisitId
      ? this.vesselVisitStore.items.find(i => i.id === this.carrierVisitId)?.status ===
          CarrierVisitStatus.Departed
      : true
  }

  private get assignmentTypeMappedToOperationType() {
    return this.assignmentType === WorkAreaEquipmentBindingTypes.Discharge
      ? OperationType.Inbound
      : OperationType.Outbound
  }
}
