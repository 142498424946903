import { ContainerHeight, ContainerPositionType, EquipmentType } from '@operations/app/api'

export const isServiceLocation = (locationType: ContainerPositionType) =>
  locationType === ContainerPositionType.Repair ||
  locationType === ContainerPositionType.Warehouse ||
  locationType === ContainerPositionType.Customs ||
  locationType === ContainerPositionType.Weights

export const getContainerHeight = (containerHeight: ContainerHeight) => {
  switch (containerHeight) {
    case 'Standard':
      return 'DC'

    case 'High':
      return 'HC'

    case 'Low':
      return 'FR'
    default:
      return ' - '
  }
}

export const isCraneEquipmentType = (equipmentType: EquipmentType) =>
  equipmentType === EquipmentType.Sts || equipmentType === EquipmentType.Rmg
