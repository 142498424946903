import { makeObservable, observable } from 'mobx'

export class BaseDataStore<T> {
  data: T[] = []

  constructor() {
    makeObservable(this, {
      data: observable,
    })
  }
}
