import { Box, Button, Chip, ListItem, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { FC, HTMLAttributes } from 'react'

interface Props {
  unNumber: string
  material: string
  primaryImoClass: string
  secondaryImoClasses?: string[] | null
  props: HTMLAttributes<HTMLLIElement>
}

export const UnNumbersOptionListItem: FC<Props> = ({
  unNumber,
  material,
  primaryImoClass,
  secondaryImoClasses,
  props,
}) => {
  const { t } = useTranslate()

  return (
    <ListItem {...props}>
      <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <Typography variant='body2'>
            {unNumber} - {material}
          </Typography>
        </Box>
        <Box display='flex' justifyContent='flex-end' sx={{ flex: 'none' }}>
          <Chip
            sx={{ mr: '10px;' }}
            key={`imo-class-option-chip-${primaryImoClass}`}
            label={primaryImoClass}
          />
          {secondaryImoClasses?.map(imoClass => (
            <Chip sx={{ mr: '10px;' }} key={`imo-class-option-chip-${imoClass}`} label={imoClass} />
          ))}

          <Button variant='contained'>{t('Add', 'Add')}</Button>
        </Box>
      </Box>
    </ListItem>
  )
}
