import { AutoGenerateWorkQueuesCommand, WorkQueueApi, WorkQueueDto } from '@operations/app/api'
import { createApiClient } from '@operations/app/http-client'
import { SaveWorkQueueCommand } from './../app/api/api'
import { BaseEntityStore } from './base/BaseEntityStore'

export class WorkQueueStore extends BaseEntityStore<WorkQueueDto> {
  async load(vesselVisitId: number) {
    const { data } = await createApiClient(WorkQueueApi).get(vesselVisitId)

    this.updateStoreItems(data)
  }

  async autoGenerateWorkQueues(autoGenerateWorkQueues: AutoGenerateWorkQueuesCommand) {
    await createApiClient(WorkQueueApi).autoGenerateWorkQueues(autoGenerateWorkQueues)
  }

  async saveCraneSplit(saveWorkQueueCommand: SaveWorkQueueCommand) {
    const { data } = await createApiClient(WorkQueueApi).save(saveWorkQueueCommand)

    return data
  }
}
