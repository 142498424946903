import { VesselDto } from '@planning/app/api'
import { IEvent, IMessageBus } from '@planning/messages'
import { EventTypes } from '@planning/messages/eventsTypes'
import { GetVesselsByIdsQuery, GetVesselsQuery } from '@planning/messages/queries'
import _ from 'lodash'
import { action, makeObservable } from 'mobx'
import { ItemStore } from '../base/ItemStore'
import { IEntityStore } from '../types'
import { IVesselItem, VesselItem } from './VesselItem'

export class VesselItemStore
  extends ItemStore<VesselDto, IVesselItem>
  implements IEntityStore<IVesselItem>
{
  constructor(messageBus: IMessageBus) {
    super((key, data) => new VesselItem(key, data), {
      messageBus,
      bulkFetchFunc: (ids: number[]) => new GetVesselsByIdsQuery(ids),
    })
    makeObservable(this, {
      receiveVesselsMessage: action,
      receiveVesselUpsertedEventMessage: action,
      receiveVesselDeletedEventMessage: action,
    })

    messageBus.subscribeEvent(GetVesselsQuery.type, this.receiveVesselsMessage)
    messageBus.subscribeEvent(GetVesselsByIdsQuery.type, this.receiveVesselsMessage)
    messageBus.subscribeEvent(
      EventTypes.VesselUpsertedEvent,
      this.receiveVesselUpsertedEventMessage,
    )
    messageBus.subscribeEvent(EventTypes.VesselDeletedEvent, this.receiveVesselDeletedEventMessage)
  }

  receiveVesselsMessage = (event: IEvent<VesselDto[]>): void => {
    if (event.payload) {
      this.upsertBulk(event.payload)
    }
  }

  receiveVesselUpsertedEventMessage = (event: IEvent<VesselDto>): void => {
    if (event.payload) {
      this.upsert(event.payload)
    }
  }

  receiveVesselDeletedEventMessage = (event: IEvent<number>): void => {
    if (event.payload && _.has(this.elements, event.payload)) {
      this.delete(event.payload)
    }
  }
}
