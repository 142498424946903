import { Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { FormProps } from '@storage/app/models'
import { ControlledTextField } from '@storage/components/form-controls'
import useFormWithSchema from '@storage/hooks/use-form-with-schema.hook'
import { useTranslate } from '@tolgee/react'
import { schema } from './dg-segregation-rules-table-form.scheme'
import { DGSegregationRuleFormProfile } from './dg-segregation-rules-table-form.profile'

interface Props extends FormProps {
  onSubmit: (formValues: DGSegregationRuleFormProfile) => Promise<void> | void
}

const DGSegregationRulesTableForm = ({ id, onSubmit }: Props) => {
  const { handleSubmit, control, formState } = useFormWithSchema<DGSegregationRuleFormProfile>(
    schema,
    {},
  )

  const { t } = useTranslate()

  return (
    <Box
      id={id}
      component='form'
      noValidate
      autoComplete='off'
      onSubmit={handleSubmit(onSubmit)}
      my={1}
    >
      <Stack gap={1}>
        <Typography>
          {t(
            'isStackingContainerOnTopAllowed',
            'Set distance (in slots) between IMO classes or clear existing selected rules.',
          )}
        </Typography>
        <ControlledTextField
          fullWidth
          required
          type='number'
          formState={formState}
          control={control}
          label={t('distance', 'Distance')}
          name='distance'
        />
      </Stack>
    </Box>
  )
}

export default DGSegregationRulesTableForm
