import { Button } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { CargoType, CarrierVisitStatus } from '@planning/app/api'
import { PaginatedList } from '@planning/components/PaginatedList'
import { MobileAppBar } from '@planning/pages/Shell'
import { ITruckVisitItem } from '@planning/rt-stores/truckVisit/TruckVisitItem'
import { useNavigationStore } from '@planning/stores/NavigationStack'
import { IPaginatedStoreWithItems } from '@planning/stores/PaginatedStore'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { TruckAppointmentsDetailsMobile } from '../TruckAppointmentsDetailsMobile'
import { TruckAppointmentMobileViewListCard } from './TruckAppointmentMobileViewListCard'

interface Props {
  paginatedStore: IPaginatedStoreWithItems<ITruckVisitItem>
}

export const TruckAppointmentMobileViewTable = observer(({ paginatedStore: viewStore }: Props) => {
  const { t } = useTranslate()
  const { gateClerkTruckVisitEditStore } = usePlanningStore()
  const navigationStore = useNavigationStore()

  const handleCreate = () => {
    gateClerkTruckVisitEditStore.reset()

    gateClerkTruckVisitEditStore.setTruckVisit({
      id: 0,
      carrierIds: [0],
      status: CarrierVisitStatus.Expected,
      cargoType: CargoType.Container,
    })
    navigationStore.push(
      <TruckAppointmentsDetailsMobile editStore={gateClerkTruckVisitEditStore} />,
    )
  }

  const handleSelect = (truckVisit: ITruckVisitItem) => {
    gateClerkTruckVisitEditStore.setTruckVisit(truckVisit.data)
    gateClerkTruckVisitEditStore.setInboundOrders(truckVisit.inboundOrders.map(o => o.data))
    gateClerkTruckVisitEditStore.setOutboundOrders(truckVisit.outboundOrders.map(o => o.data))
    navigationStore.push(
      <TruckAppointmentsDetailsMobile editStore={gateClerkTruckVisitEditStore} />,
    )
  }

  return (
    <>
      <MobileAppBar
        title={t('gateClerk', 'Gate clerk')}
        backButton={navigationStore.canGoBack}
        onGoBack={navigationStore.pop}
      >
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            handleCreate()
          }}
        >
          {t('create', 'Create')}
        </Button>
      </MobileAppBar>
      <PaginatedList
        filterable
        paginatedStore={viewStore}
        hidePaginationControl
        startEmpty
        defaultPageSize={10}
        searchForResultsText={t('searchToViewAppointments', 'Search to view appointments')}
        searchPlaceholderText={t(
          'truckPlateContainerNoOrderRef',
          'Truck Plate, Container No., Order ref.',
        )}
      >
        {viewStore.pageItems.map(item => (
          <TruckAppointmentMobileViewListCard
            truckVisit={item}
            key={item.id}
            handleClick={() => {
              handleSelect(item)
            }}
          />
        ))}
      </PaginatedList>
    </>
  )
})
