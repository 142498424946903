import { useStores } from '@storage/hooks/use-stores.hook'
import { observer } from 'mobx-react-lite'
import GeneralCargoAreaForm from '../forms/general-cargo-area-form/GeneralCargoAreaForm'
import { GeneralCargoAreaFormProfile } from '../forms/general-cargo-area-form/general-cargo-area-form.profile'
import {
  mapFormValuesToCreateGeneralCargoAreaDto,
  mapFormValuesToUpdateGeneralCargoAreaDto,
} from '../forms/general-cargo-area-form/general-cargo-area.mapper'
import { useTranslate } from '@tolgee/react'
import { ConfirmationDialog } from '@tom-ui/ui'
import { FormType } from '@storage/app/models'

const GeneralCargoAreasTableDialog = observer(() => {
  const { t } = useTranslate()
  const { generalCargoAreaUIStore, generalCargoAreaStore, snackbarStore } = useStores()

  const saveLabel = t('save', 'Save')
  const cancelLabel = t('cancel', 'Cancel')

  const getDialogLabels = (action: FormType) => {
    switch (action) {
      case 'Add':
        return {
          title: t('addGeneralCargoArea', 'Add New General Cargo Area'),
          buttons: { primary: saveLabel, secondary: cancelLabel },
        }
      case 'Edit':
        return {
          title: t('updateGeneralCargoArea', 'Edit General Cargo Area'),
          buttons: { primary: saveLabel, secondary: cancelLabel },
        }
      case 'Delete':
        return {
          title: t('deleteGeneralCargoArea', 'Delete General Cargo Area?'),
          buttons: { primary: t('delete', 'Delete'), secondary: cancelLabel },
        }
    }
  }

  const dialogLabels = getDialogLabels(generalCargoAreaUIStore.dialogAction)

  const handleOnClose = () => {
    generalCargoAreaUIStore.toggleDialog()
  }

  const handleOnSubmit = (formValues: GeneralCargoAreaFormProfile) => {
    if (generalCargoAreaUIStore.dialogAction === 'Add') {
      generalCargoAreaStore
        .add(mapFormValuesToCreateGeneralCargoAreaDto(formValues))
        .then(() =>
          snackbarStore.showMessage(
            t('generalCargoAreaCreationSuccess', 'The area is successfully created'),
            'success',
          ),
        )
        .catch(() =>
          snackbarStore.showMessage(
            t(
              'generalCargoAreaCreationFailure',
              'An unexpected error occurred while creating the area',
            ),
            'error',
          ),
        )
        .finally(() => handleOnClose())
    } else if (generalCargoAreaUIStore.dialogAction === 'Edit') {
      generalCargoAreaStore
        .update(mapFormValuesToUpdateGeneralCargoAreaDto(formValues))
        .then(() =>
          snackbarStore.showMessage(
            t('generalCargoAreaUpdateSuccess', 'The area is successfully updated'),
            'success',
          ),
        )
        .catch(() =>
          snackbarStore.showMessage(
            t(
              'generalCargoAreaUpdateFailure',
              'An unexpected error occurred while updating the area',
            ),
            'error',
          ),
        )
        .finally(() => handleOnClose())
    }
  }

  const handleOnDelete = () => {
    generalCargoAreaStore
      .delete(generalCargoAreaUIStore.dialogEntityId!)
      .then(() =>
        snackbarStore.showMessage(
          t('generalCargoAreaDeletionSuccess', 'The area is successfully deleted'),
          'success',
        ),
      )
      .catch(() =>
        snackbarStore.showMessage(
          t(
            'generalCargoAreaDeletionFailure',
            'An unexpected error occurred while deleting the area',
          ),
          'error',
        ),
      )
      .finally(() => handleOnClose())
  }

  return (
    <ConfirmationDialog
      open={generalCargoAreaUIStore.isDialogOpen}
      title={dialogLabels.title}
      primaryActionText={dialogLabels.buttons.primary}
      closeLabel={dialogLabels.buttons.secondary}
      formId='general-cargo-area-form'
      maxWidth='sm'
      onClose={handleOnClose}
      hasBorderTop={generalCargoAreaUIStore.dialogAction !== 'Delete'}
      blockClickOutside={generalCargoAreaUIStore.dialogAction === 'Delete'}
      message={
        generalCargoAreaUIStore.dialogAction === 'Delete'
          ? t(
              'generalCargoAreaWillBeDeleted',
              'This area will be deleted and you cannot undo this action!',
            )
          : undefined
      }
      onConfirm={generalCargoAreaUIStore.dialogAction === 'Delete' ? handleOnDelete : undefined}
    >
      {generalCargoAreaUIStore.dialogAction !== 'Delete' && (
        <GeneralCargoAreaForm
          id='general-cargo-area-form'
          onSubmit={handleOnSubmit}
          generalCargoArea={
            generalCargoAreaUIStore.dialogEntityId
              ? generalCargoAreaStore.getById(generalCargoAreaUIStore.dialogEntityId)
              : undefined
          }
        />
      )}
    </ConfirmationDialog>
  )
})

export default GeneralCargoAreasTableDialog
