import { DragAndDropZoneWithFilePreview } from '@planning/components/DragAndDropZoneWithFilePreview'
import { OrderListUploadViewStoreV2 } from '@planning/pages/Order/stores/OrderListUploadViewStoreV2'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { useDropzone } from 'react-dropzone'

export interface IProps {
  store: OrderListUploadViewStoreV2
}

export const OrderListUploadDragAndDropZone: FC<IProps> = observer(({ store }) => {
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      'text/csv': ['.csv'],
      'application/EDIFACT': ['.edi', '.EDI'],
    },
    onDrop: (acceptedFiles: File[]) => {
      store.setUploadFailedReason()

      if (acceptedFiles.length > 0) {
        store.setFile(acceptedFiles[0])
      }
    },
  })

  return (
    <DragAndDropZoneWithFilePreview
      file={store.parsingStore.file}
      inputProps={getInputProps()}
      rootProps={getRootProps()}
      setFile={store.setFile}
    />
  )
})
