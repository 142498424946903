import { TenantResponseDto, TenantsApi } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'
import { Event, IEvent, IQueryHandler, IQueryMessage } from '../messageBus'

export class GetTenantQuery implements IQueryMessage {
  static type = 'GetTenantQuery'
  type = GetTenantQuery.type

  getKey = () => {
    return JSON.stringify(this)
  }
}

const httpClient = createApiClient(TenantsApi)

export const getTenantQueryHandler: IQueryHandler<
  GetTenantQuery,
  IEvent<TenantResponseDto>
> = async _ => {
  const { data } = await httpClient.get()

  return new Event(GetTenantQuery.type, data)
}
