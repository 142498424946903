import { RoleDto, RolesApi } from '@admin/app/api'
import { BaseDataStore } from '@admin/app/store/basedata.store'
import { makeObservable, runInAction } from 'mobx'

export class RoleStore extends BaseDataStore<RoleDto> {
  constructor(private api: RolesApi) {
    super()
    makeObservable(this, {})
  }

  async load() {
    const { data } = await this.api.rolesGet()
    runInAction(() => {
      this.data = data
    })
  }
}
