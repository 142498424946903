import { withAuthenticationRequired } from '@auth0/auth0-react'
import { Button, Stack, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { billingResetDataService } from '@tom-ui/billing'
import { useOperationsStore } from '@tom-ui/operations'
import { planningResetDataService, usePlanningStore } from '@tom-ui/planning'
import { storageResetDataService } from '@tom-ui/storage'
import { observer } from 'mobx-react-lite'

const ResetDemo = withAuthenticationRequired(
  observer(() => {
    const { dataSnapshotStore, appStore } = useOperationsStore()
    const { appViewStore } = usePlanningStore()

    const { t } = useTranslate()

    async function handleResetDemoData() {
      const isConfirmed = await appViewStore.setOpenConfirmDialog(
        true,
        t(
          'thisResetYourExistingDataWillBeRemoved',
          'This will recreate the state stored in your current data snapshot. Your data will be lost. Continue?',
        ),
        t('confirm', 'Confirm'),
      )

      if (isConfirmed) {
        try {
          appStore.increaseLoadingCounter()

          await dataSnapshotStore.resetSnapshot()
          await planningResetDataService.resetData()
          await storageResetDataService.resetData()
          await billingResetDataService.resetData()

          appStore.decreaseLoadingCounter()
          appStore.setShowAlert('success', t('dataSuccessfullyReset', 'Data successfully Reset'))
        } catch (error) {
          appStore.decreaseLoadingCounter()
          appStore.setShowAlert('error', t('failedToReset', 'Failed to reset'))
        }
      }
    }

    async function handleSaveDemoData() {
      const isConfirmed = await appViewStore.setOpenConfirmDialog(
        true,
        t(
          'thisWillReplaceTheExistingDataSnapshot',
          "This will replace the existing data snapshot. Future 'Reset' will restore to the new state. Continue?",
        ),
        t('confirm', 'Confirm'),
      )

      if (isConfirmed) {
        try {
          appStore.increaseLoadingCounter()

          await dataSnapshotStore.saveSnapshot()
          await planningResetDataService.saveSnapshot()
          await storageResetDataService.saveSnapshot()
          await billingResetDataService.saveSnapshot()

          appStore.decreaseLoadingCounter()
          appStore.setShowAlert(
            'success',
            t('snapshotSuccessfullySaved', 'Data snapshot successfully saved'),
          )
        } catch (error) {
          appStore.decreaseLoadingCounter()
          appStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
        }
      }
    }

    return (
      <Stack alignItems='center'>
        <Typography variant='h3'>{t('resetData', 'Reset Data')}</Typography>
        <Stack flexDirection='row' p='1rem'>
          <Button
            variant='outlined'
            color='error'
            sx={{ display: 'flex', alignItems: 'center', mr: '1rem' }}
            onClick={handleResetDemoData}
            data-cy='reset-data-btn'
          >
            {t('resetData', 'Reset data')}
          </Button>
          <Button
            variant='contained'
            color='error'
            sx={{ display: 'flex', alignItems: 'center' }}
            onClick={handleSaveDemoData}
            data-cy='save-snapshot-btn'
          >
            {t('saveSnapshot', 'Save Snapshot')}
          </Button>
        </Stack>
        <Typography color='error' variant='body2'>
          {t(
            'useWithCautionRemoveAllData',
            'Use with caution. Both actions above are likely to result in loss of data.',
          )}
        </Typography>
      </Stack>
    )
  }),
)

export default ResetDemo
