import { CancellableTimeoutAlert } from '@planning/pages/GateClerk/Components/CancellableTimeoutAlert'
import { ReportContainerNotificationStore } from '@planning/rt-stores/tallyman/ReportContainerNotificationStore'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

interface IProps {
  store: ReportContainerNotificationStore
  onCancel?: () => void
}

export const ReportSuccessAlert: FC<IProps> = observer(({ store, onCancel }) => {
  const { t } = useTranslate()
  const { containerNumber } = store

  const successText = () => `${containerNumber} ${t('reported', 'reported')}`

  const cancelledText = () =>
    `${t('reportingOf', 'Reporting of')} ${containerNumber} ${t('cancelled', 'cancelled')}`

  const errorText = () =>
    `${t('reportingOf', 'Reporting of')} ${containerNumber} ${t('failed', 'failed')}`

  return (
    <CancellableTimeoutAlert
      store={store}
      successText={successText()}
      cancelledText={cancelledText()}
      errorText={errorText()}
      onCancel={onCancel}
      cancellable={store.cancellable}
    />
  )
})
