import { Card, CardContent, Grid } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { CarrierVisitPlanningDateAxis } from '@planning/components/carrier-visit-planning/CarrierVisitPlanningDateAxis'
import { CarrierVisitPlanningHeader } from '@planning/components/carrier-visit-planning/CarrierVisitPlanningHeader'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { RailVisitPlanningViewStore } from '../../stores/RailVisitPlanningViewStore'
import { RailPlanningVisualization } from './RailPlanningVisualization'
import { RailTrackAxis } from './RailTrackAxis'

interface Props {
  store: RailVisitPlanningViewStore
}
export const RailVisitPlanning = observer(({ store }: Props) => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()
  const { railVisitPlanningStore } = usePlanningStore()
  const navigate = useNavigate()

  useEffect(() => {
    const gettingBethViewSize = () => {
      const mainElement = document.querySelector(`#planning-visualization`)

      if (mainElement) {
        store.setVisualizationWidth(Math.floor(mainElement.getBoundingClientRect().width))
      }
    }

    const handleResize = () => {
      gettingBethViewSize()
    }

    gettingBethViewSize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [store])

  return (
    <Card sx={{ overflowY: 'auto' }}>
      <CardContent>
        <Grid container sx={{ paddingBottom: 2 }}>
          <Grid
            item
            xs={12}
            sx={{
              borderBottom: `1px solid ${theme.palette.divider}`,
            }}
          >
            <CarrierVisitPlanningHeader
              title={t('railPlan', 'Rail plan')}
              visitDate={store.startDate}
              onClickToday={() => store.setStartDate(new Date())}
              onClickPrev={() => store.loadPrevDate()}
              onClickNext={() => store.loadNextDate()}
              onChangeDateRange={(dateRange: number) => store.setDaysVisualization(dateRange)}
            />
          </Grid>

          <Grid
            item
            xs={2}
            sx={{
              borderRight: `1px solid ${theme.palette.divider}`,
              borderBottom: `1px solid ${theme.palette.divider}`,
            }}
          ></Grid>

          <Grid
            item
            xs={10}
            sx={{
              borderBottom: `1px solid ${theme.palette.divider}`,
            }}
          >
            <CarrierVisitPlanningDateAxis
              itemCount={store.daysVisualization}
              startDate={store.startDate}
              pixelPerHour={store.pixelPerHour}
            />
          </Grid>

          <Grid
            item
            xs={2}
            sx={{
              borderRight: `1px solid ${theme.palette.divider}`,
            }}
          >
            <RailTrackAxis
              railTracks={store.plannedVisits.map(x => x.railTrack)}
              emptySpaceHeight={store.emptySpaceHeight}
              railTrackHeight={store.railTrackHeight}
            />
          </Grid>
          <Grid item xs={10} id='planning-visualization' overflow='hidden'>
            <RailPlanningVisualization
              pixelPerHour={store.pixelPerHour}
              plannedRailTrackVisits={store.plannedVisits}
              emptySpaceHeight={store.emptySpaceHeight}
              onClick={(id: number) => {
                if (store.visitSelectedId === 0) {
                  return
                }

                if (
                  store.currentFormValues &&
                  store.visitSelectedId !== 0 &&
                  railVisitPlanningStore.railVisit?.id !== id &&
                  railVisitPlanningStore.isEditMode
                ) {
                  store.setNextVisitIdToBeEdited(id)
                  store.toggleConfirmationDialog(true)
                } else {
                  store.setFormValues()
                  navigate(`/rail-visits-planning/${id}`)
                }
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
})
