import { Box, Button } from '@mui/material'
import { useMinimalsTheme } from '../../../hooks'
import { ColorSchema } from '../../palette'

interface Props {
  formId?: string
  primaryActionLabel?: string
  disablePrimaryAction?: boolean

  secondaryActionLabel?: string
  secondaryActionColor?: ColorSchema
  disableSecondaryAction?: boolean

  closeActionLabel?: string

  onClose: () => void
  onPrimaryAction?: () => void
  onSecondaryAction?: () => void
}

export const DrawerActions = ({
  formId,
  primaryActionLabel,
  disablePrimaryAction,
  secondaryActionLabel,
  secondaryActionColor,
  disableSecondaryAction,
  closeActionLabel,
  onClose,
  onPrimaryAction,
  onSecondaryAction,
}: Props) => {
  const theme = useMinimalsTheme()

  return (
    <Box height='4rem'>
      <Box
        sx={{
          backgroundColor: theme.palette.common.white,
          position: 'fixed',
          bottom: 0,
          display: 'flex',
          gap: theme.customSpacing.m,
          padding: theme.customSpacing.m,
          height: '4rem',
          width: '100%',
        }}
      >
        {primaryActionLabel ? (
          <Button
            variant='contained'
            color='primary'
            type='submit'
            form={formId}
            onClick={onPrimaryAction}
            disabled={disablePrimaryAction}
            data-cy='drawer-form-submit-btn'
          >
            {primaryActionLabel}
          </Button>
        ) : (
          ''
        )}

        {secondaryActionLabel && onSecondaryAction && (
          <Button
            variant='contained'
            color={secondaryActionColor ?? 'secondary'}
            onClick={onSecondaryAction}
            disabled={disableSecondaryAction}
          >
            {secondaryActionLabel}
          </Button>
        )}

        {closeActionLabel ? (
          <Button variant='text' color='inherit' onClick={onClose}>
            {closeActionLabel}
          </Button>
        ) : (
          ''
        )}
      </Box>
    </Box>
  )
}
