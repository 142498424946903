import { CreateRailTrackDto, RailTrackDto } from '@storage/app/api'
import { RailTrackFormProfile } from './rail-tracks-form.profile'

export const mapFormValuesToCreateRailTrackDto = ({
  name,
  length,
}: RailTrackFormProfile): CreateRailTrackDto => ({
  name,
  length,
})

export const mapFormValuesToRailTrackDto = ({
  id,
  name,
  length,
}: RailTrackFormProfile): RailTrackDto => ({
  id: id!,
  name,
  length,
  lengthUnit: 'Meter',
})

export const mapRailTrackDtoToFormValues = ({
  id,
  name,
  length,
}: RailTrackDto): RailTrackFormProfile => ({
  id,
  name,
  length,
})
