import { Chip } from '@mui/material'
import { HeightIcon } from '@tom-ui/ui'
import { ContainerHeight } from '@planning/app/api'
import { useTranslate } from '@tolgee/react'

interface Props {
  containerHeight: ContainerHeight
}

export const ContainerHeightChip = ({ containerHeight }: Props) => {
  const { t } = useTranslate()

  const labels = {
    Standard: t('standard', 'Standard'),
    Low: t('low', 'Low'),
    High: t('high', 'High'),
  }

  const label = labels[containerHeight ?? 'Low']

  return (
    <Chip
      label={label}
      icon={<HeightIcon />}
      variant='filled'
      sx={{
        marginBottom: '0.25rem',
        pl: '.5rem',
        '& .MuiChip-label': {
          pl: '.25rem',
        },
      }}
    />
  )
}
