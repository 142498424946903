import { CarrierVisitStatus, TruckVisitDto } from '@planning/app/api'
import { IEvent, IMessageBus } from '@planning/messages'
import { EventTypes } from '@planning/messages/eventsTypes'
import { GetTruckVisitsByIdsQuery } from '@planning/messages/queries'
import { GetTruckVisitsQuery } from '@planning/messages/queries/getTruckVisitsQueryHandler'
import _ from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import moment from 'moment'
import { ItemStore } from '../base/ItemStore'
import { OrderItemStore } from '../order/OrderItemStore'
import { TruckItemStore } from '../truck/TruckItemStore'
import { IDateRange, IEntityStore } from '../types'
import { ITruckVisitItem, TruckVisitItem } from './TruckVisitItem'

export interface ITruckVisitStore extends IEntityStore<TruckVisitItem> {
  isLoading: boolean
  loadedDateRange: IDateRange
  fetch: (query: IDateRange) => Promise<void>
}

export class TruckVisitItemStore extends ItemStore<TruckVisitDto, ITruckVisitItem> {
  private orderItemStore?: OrderItemStore
  private truckStore?: TruckItemStore
  private fetchedDateRange: Set<string> = new Set()

  sorting?: string
  from?: Date
  to?: Date
  filter = ''
  filterBy: 'eta' | 'ata' | 'atd' = 'eta'

  constructor(private messageBus: IMessageBus) {
    super((key, data) => new TruckVisitItem(key, data, this, this.truckStore), {
      messageBus,
      bulkFetchFunc: (ids: number[]) => new GetTruckVisitsByIdsQuery(ids),
    })
    makeObservable(this, {
      sortedTruckVisits: computed,
      filteredTruckVisits: computed,
      ordersByTruckVisitId: computed,
      receiveTruckVisitsMessage: action,
      receiveTruckVisitUpsertedEventMessage: action,
      receiveTruckVisitDeletedEventMessage: action,
      setSorting: action,
      setDateRange: action,
      setFilter: action,
      setFilterBy: action,
      sorting: observable,
      from: observable,
      to: observable,
      filter: observable,
      filterBy: observable,
    })

    messageBus.subscribeEvent(GetTruckVisitsQuery.type, this.receiveTruckVisitsMessage)
    messageBus.subscribeEvent(GetTruckVisitsByIdsQuery.type, this.receiveTruckVisitsMessage)
    messageBus.subscribeEvent(
      EventTypes.TruckVisitUpsertedEvent,
      this.receiveTruckVisitUpsertedEventMessage,
    )
    messageBus.subscribeEvent(
      EventTypes.TruckVisitDeletedEvent,
      this.receiveTruckVisitDeletedEventMessage,
    )
  }

  connect = (orderItemStore: OrderItemStore, truckStore: TruckItemStore) => {
    this.orderItemStore = orderItemStore
    this.truckStore = truckStore
  }

  fetch = (query: GetTruckVisitsQuery) => {
    const key = query.getKey()
    if (this.fetchedDateRange.has(key)) return

    this.fetchedDateRange.add(key)

    this.messageBus.dispatchQuery(query)
  }

  get ordersByTruckVisitId() {
    return this.orderItemStore?.ordersByCarrierVisitId
  }

  get sortedTruckVisits() {
    let truckVisits = _.values(this.elements)

    truckVisits = truckVisits.sort((a: any, b: any) => {
      const dateA = a.data?.[this.filterBy] ? new Date(a.data[this.filterBy]).getTime() : 0
      const dateB = b.data?.[this.filterBy] ? new Date(b.data[this.filterBy]).getTime() : 0

      return this.sorting === 'new' ? dateB - dateA : dateA - dateB
    })

    return truckVisits
  }

  get filteredTruckVisits() {
    const filteredVisits = this.sortedTruckVisits.filter(v =>
      v.truck?.data.licensePlate.toLocaleLowerCase().includes(this.filter.toLocaleLowerCase()),
    )

    const timeIsBetweenFromTo = (date?: string | null) => {
      return moment(date).isBetween(moment(this.from), moment(this.to), 'm', '[]')
    }

    const inProgressVisits = filteredVisits.filter(
      v => !this.from || !this.to || timeIsBetweenFromTo(v.data.ata),
    )

    // todo: split on each computed status since filter are different
    return {
      expected: filteredVisits
        .filter(v => !this.from || !this.to || timeIsBetweenFromTo(v.data[this.filterBy]))
        .filter(v => v.data.status === CarrierVisitStatus.Expected),
      arrived: inProgressVisits.filter(v => v.data.status === CarrierVisitStatus.Arrived),
      departed: filteredVisits
        .filter(v => !this.from || !this.to || timeIsBetweenFromTo(v.data[this.filterBy]))
        .filter(v => v.data.status === CarrierVisitStatus.Departed),
    }
  }

  setSorting(sorting: string) {
    this.sorting = sorting
  }

  setDateRange(from?: Date, to?: Date) {
    this.from = from
    this.to = to
  }

  setFilter(filter: string) {
    this.filter = filter
  }

  setFilterBy(filterBy: 'eta' | 'ata' | 'atd') {
    this.filterBy = filterBy
  }

  receiveTruckVisitsMessage = (event: IEvent<TruckVisitDto[]>): void => {
    if (event.payload) {
      this.upsertBulk(event.payload)
    }
  }

  receiveTruckVisitUpsertedEventMessage = (event: IEvent<TruckVisitDto>) => {
    if (event.payload) {
      this.upsert(event.payload)
    }
  }

  receiveTruckVisitDeletedEventMessage = (event: IEvent<number>) => {
    if (event.payload) {
      this.delete(event.payload)
    }
  }
}
