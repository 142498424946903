import { setupInterceptors } from '@billing/app/http-client'
import { setupValidation } from '@billing/app/validation'
import { CommodityDialogUIStore } from '@billing/features/commodity/stores/commodity-dialog.ui-store'
import { CommodityListUIStore } from '@billing/features/commodity/stores/commodity-list.ui-store'
import { CustomerDialogUIStore } from '@billing/features/customer/stores/customer-dialog.ui-store'
import { CustomerTableUIStore } from '@billing/features/customer/stores/customer-table.ui-store'
import { ContainerTurnoversUIStore } from '@billing/features/reports/stores/container-turnovers.ui-store'
import { CustomerBillingUIStore } from '@billing/features/reports/stores/customer-billing-report.ui-store'
import { HandlingServicesPreBillingUIStore } from '@billing/features/reports/stores/handling-services-prebilling-report.ui-store'
import { ReportsUIStore } from '@billing/features/reports/stores/reports.ui-store'
import { StorageReportUIStore } from '@billing/features/reports/stores/storage-report.ui-store'
import { VesselReportUIStore } from '@billing/features/reports/stores/vessel-report.ui-store'
import { CarrierVisitStore } from '@billing/stores/CarrierVisitStore'
import { CommodityStore } from '@billing/stores/CommodityStore'
import { CustomerStore } from '@billing/stores/CustomerStore'
import {
  AppStore,
  AuthStore,
  appStore as appStoreObject,
  authStore as authStoreObject,
} from '@tom-ui/utils'
import { ReactNode, createContext, useContext } from 'react'
import { PackageDialogUIStore } from './features/packaging/stores/package-dialog.ui-store'
import { PackageListUIStore } from './features/packaging/stores/package-list.ui-store'
import { DeliveryNoteReportUIStore } from './features/reports/stores/delivery-note-report.ui-store'
import { GCStoragePreBillingUIStore } from './features/reports/stores/gcStorage-prebilling-report.ui-store'
import { PackageStore } from './stores/PackageStore'
import { TenantStore } from './stores/TenantStore'

type IStore = {
  appStore: AppStore
  authStore: AuthStore
  customerStore: CustomerStore
  commodityStore: CommodityStore
  packageStore: PackageStore
  carrierVisitStore: CarrierVisitStore
  tenantStore: TenantStore
}

const appStore = appStoreObject
const authStore = authStoreObject
const customerStore = new CustomerStore()
const commodityStore = new CommodityStore()
const packageStore = new PackageStore()
const carrierVisitStore = new CarrierVisitStore()
const tenantStore = new TenantStore()

type IViewStore = {
  reportsUIStore: ReportsUIStore
  vesselReportUIStore: VesselReportUIStore
  storageReportUIStore: StorageReportUIStore
  customerDialogUIStore: CustomerDialogUIStore
  customerTableUIStore: CustomerTableUIStore
  commodityDialogUIStore: CommodityDialogUIStore
  commodityListUIStore: CommodityListUIStore
  packageDialogUIStore: PackageDialogUIStore
  packageListUIStore: PackageListUIStore
  containerTurnoversUIStore: ContainerTurnoversUIStore
  customerBillingUIStore: CustomerBillingUIStore
  gcPreBillingStorageUIStore: GCStoragePreBillingUIStore
  deliveryNoteReportUIStore: DeliveryNoteReportUIStore
  handlingServicesPrebillingUIStore: HandlingServicesPreBillingUIStore
}

const reportsUIStore = new ReportsUIStore()
const vesselReportUIStore = new VesselReportUIStore(carrierVisitStore)
const storageReportUIStore = new StorageReportUIStore()

const customerDialogUIStore = new CustomerDialogUIStore(customerStore)
const customerTableUIStore = new CustomerTableUIStore(customerStore)

const commodityDialogUIStore = new CommodityDialogUIStore(commodityStore)
const commodityListUIStore = new CommodityListUIStore(commodityStore)

const packageDialogUIStore = new PackageDialogUIStore(packageStore)
const packageListUIStore = new PackageListUIStore(packageStore)

const containerTurnoversUIStore = new ContainerTurnoversUIStore()
const customerBillingUIStore = new CustomerBillingUIStore(customerStore)

const gcPreBillingStorageUIStore = new GCStoragePreBillingUIStore(customerStore)

const handlingServicesPrebillingUIStore = new HandlingServicesPreBillingUIStore(customerStore)

const deliveryNoteReportUIStore = new DeliveryNoteReportUIStore(
  carrierVisitStore,
  customerStore,
  tenantStore,
)

export interface IAppStore extends IStore, IViewStore {}

const store: IAppStore = {
  appStore,
  authStore,
  customerStore,
  commodityStore,
  packageStore,
  carrierVisitStore,
  tenantStore,
  customerDialogUIStore,
  customerTableUIStore,
  commodityDialogUIStore,
  commodityListUIStore,
  packageDialogUIStore,
  packageListUIStore,
  reportsUIStore,
  vesselReportUIStore,
  storageReportUIStore,
  containerTurnoversUIStore,
  customerBillingUIStore,
  gcPreBillingStorageUIStore,
  deliveryNoteReportUIStore,
  handlingServicesPrebillingUIStore,
}

setupValidation()
setupInterceptors(appStore)

const BillingServiceContext = createContext<IAppStore>({ ...store })

export const BillingServiceStoreProvider = ({ children }: { children: ReactNode }) => {
  return <BillingServiceContext.Provider value={store}>{children}</BillingServiceContext.Provider>
}

export const useBillingServiceStore = () => useContext(BillingServiceContext)
