import { usePlanningStore } from '@planning/AppProvider'
import { NavigationStack } from '@planning/stores'
import { ContainerStackOutFunc } from '@planning/stores/gateControl/ContainerStackOutDto'
import { ValidateOutboundOrderFunc } from '@planning/stores/gateControl/ValidateOutboundDto'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { GateControlHome } from './GateControlHome'

interface IProps {
  containerStackOutFunc?: ContainerStackOutFunc
  validateOutboundRequest?: ValidateOutboundOrderFunc
}

export const GateControlPage = observer(
  ({ containerStackOutFunc, validateOutboundRequest }: IProps) => {
    const { gateInViewStore, containerDamageReportViewStore } = usePlanningStore()

    gateInViewStore.getContainersWithStackOutSequenceByReference = containerStackOutFunc
    gateInViewStore.validateOutboundRequest = validateOutboundRequest

    useEffect(() => {
      containerDamageReportViewStore.fetch()
    }, [])

    return (
      <NavigationStack>
        <GateControlHome />
      </NavigationStack>
    )
  },
)
