import {
  CreateCustomerCommand,
  CustomerDto,
  CustomersApi,
  UpdateCustomerCommand,
} from '@billing/app/api'
import { createApiClient } from '@billing/app/http-client'
import { BaseEntityStore } from './base/BaseEntityStore'

export class CustomerStore extends BaseEntityStore<CustomerDto> {
  async load() {
    const { data } = await createApiClient(CustomersApi).get()
    this.updateStoreItems(data)
  }

  async add(customer: CreateCustomerCommand) {
    const { data } = await createApiClient(CustomersApi).create(customer)

    this.updateStoreItem({ ...customer, id: data }, data)
  }

  async update(customer: UpdateCustomerCommand) {
    const { data } = await createApiClient(CustomersApi).update(customer)

    this.updateStoreItem({ ...customer, id: data }, data)
  }

  async delete(id: number) {
    await createApiClient(CustomersApi)._delete(id)

    this.deleteStoreItem(id)
  }
}
