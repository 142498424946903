import { ContainerDamageLocationDto, ContainerDamageTypeDto } from '@admin/app/api'
import { FormProps } from '@admin/app/models'
import { ControlledTextField } from '@admin/components/form-controls'
import useFormWithSchema from '@admin/hooks/use-form-with-schema.hook'
import { Box, FormGroup } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useEffect } from 'react'
import { ContainerDamageCatalogueFormMapper } from './container-damage-form.mapper'
import { ContainerDamageCatalogueFormProfile, defaultValues } from './container-damage-form.profile'
import { schema } from './container-damage-form.scheme'

interface Props extends FormProps {
  containerDamageCatalogue?: ContainerDamageLocationDto | ContainerDamageTypeDto
  onSubmit: (formValues: ContainerDamageCatalogueFormProfile) => Promise<void> | void
}

export const ContainerDamageCatalogueForm = ({ id, containerDamageCatalogue, onSubmit }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const { handleSubmit, formState, control, reset } =
    useFormWithSchema<ContainerDamageCatalogueFormProfile>(schema(), defaultValues)

  useEffect(() => {
    if (containerDamageCatalogue) {
      reset(ContainerDamageCatalogueFormMapper.mapDtoToFormValues(containerDamageCatalogue))
    }
  }, [reset, containerDamageCatalogue])

  return (
    <Box
      id={id}
      component='form'
      noValidate
      autoComplete='off'
      onSubmit={handleSubmit(onSubmit)}
      pb={theme.customSpacing.xs}
    >
      <FormGroup sx={{ gap: theme.customSpacing.l }}>
        <ControlledTextField<ContainerDamageCatalogueFormProfile>
          name='name'
          formState={formState}
          control={control}
          label={t('name', 'Name')}
          fullWidth
          required
          disabled={containerDamageCatalogue?.isDefault}
        />

        <ControlledTextField<ContainerDamageCatalogueFormProfile>
          name='code'
          formState={formState}
          control={control}
          label={t('code', 'Code')}
          fullWidth
        />
      </FormGroup>
    </Box>
  )
}
