import { createSvgIcon } from '@mui/material'

export const DraggableIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M7 5.94737C8.10457 5.94737 9 5.06372 9 3.97368C9 2.88365 8.10457 2 7 2C5.89543 2 5 2.88365 5 3.97368C5 5.06372 5.89543 5.94737 7 5.94737Z'
      fill='currentColor'
    />
    <path
      d='M7 13.9737C8.10457 13.9737 9 13.09 9 12C9 10.91 8.10457 10.0263 7 10.0263C5.89543 10.0263 5 10.91 5 12C5 13.09 5.89543 13.9737 7 13.9737Z'
      fill='currentColor'
    />
    <path
      d='M7 22C8.10457 22 9 21.1164 9 20.0263C9 18.9363 8.10457 18.0526 7 18.0526C5.89543 18.0526 5 18.9363 5 20.0263C5 21.1164 5.89543 22 7 22Z'
      fill='currentColor'
    />
    <path
      d='M17 6C18.1046 6 19 5.10457 19 4C19 2.89543 18.1046 2 17 2C15.8954 2 15 2.89543 15 4C15 5.10457 15.8954 6 17 6Z'
      fill='currentColor'
    />
    <path
      d='M17 13.9737C18.1046 13.9737 19 13.09 19 12C19 10.91 18.1046 10.0263 17 10.0263C15.8954 10.0263 15 10.91 15 12C15 13.09 15.8954 13.9737 17 13.9737Z'
      fill='currentColor'
    />
    <path
      d='M17 22C18.1046 22 19 21.1164 19 20.0263C19 18.9363 18.1046 18.0526 17 18.0526C15.8954 18.0526 15 18.9363 15 20.0263C15 21.1164 15.8954 22 17 22Z'
      fill='currentColor'
    />
  </svg>,
  'DraggableIcon',
)
