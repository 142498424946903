import { Button, Tooltip } from '@mui/material'
import { IconButton, PlusIcon } from '@tom-ui/ui'

import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

interface Props {
  onClick?: () => Promise<void> | void
  variant?: 'text' | 'outlined' | 'contained'
}

export const AddOrderButton: FC<Props> = observer(({ onClick, variant }) => {
  const { t } = useTranslate()

  return !variant ? (
    <Tooltip title={t('addOrder', 'Add Order')}>
      <IconButton onClick={onClick} data-cy='add-container-visit-icon-btn'>
        <PlusIcon />
      </IconButton>
    </Tooltip>
  ) : (
    <Button variant={variant} onClick={onClick}>
      {t('add', 'Add')}
    </Button>
  )
})
