import { Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { CarrierVisitSummaryFilterDto } from '@storage/app/api'
import { BooleanSelectOption } from '@storage/app/models'
import { useTranslate } from '@tolgee/react'
import { ChevronLeftIcon, IconButton } from '@tom-ui/ui'

interface Props {
  filter: CarrierVisitSummaryFilterDto
  variant: 'standard' | 'special'
  onClick: () => void
}

const UnAllocatedContainerGroupDetails = ({ filter, variant, onClick }: Props) => {
  const { t } = useTranslate()
  const mapFilterToLabels = (filter: { [key: string]: string }): string[] => {
    const labels: string[] = []

    if (filter.isEmpty) {
      filter.isEmpty === BooleanSelectOption.True
        ? labels.push(t('empty', 'Empty'))
        : labels.push(t('full', 'Full'))
    }

    if (filter.isReefer === BooleanSelectOption.True) {
      labels.push(t('Reefer', 'Reefer'))
    }

    if (filter.isDangerous === BooleanSelectOption.True) {
      labels.push(t('dangerous', 'Dangerous'))
    }

    return labels
  }

  return (
    <Stack direction='row' gap={1} alignItems='center' my={1} mx={2}>
      <IconButton onClick={onClick}>
        <ChevronLeftIcon />
      </IconButton>
      <Typography variant='captionBold'>
        {variant === 'standard' ? t('standard', 'Standard') : t('special', 'Special')}
      </Typography>
      {variant === 'standard' && (
        <Typography variant='captionBold'>{`${filter.settings?.size}ft`}</Typography>
      )}
      <Typography variant='captionBold'>{mapFilterToLabels(filter.settings!)}</Typography>
    </Stack>
  )
}

export default UnAllocatedContainerGroupDetails
