import { CSSObject, IconButton as MaterialIconButton, styled } from '@mui/material'

export const IconButton = styled(MaterialIconButton)<{
  variant?: 'contained' | 'text' | 'outlined'
}>(({ theme, variant, color }) => {
  const overrides: CSSObject = {}

  const colorAsVariant =
    color === undefined || color === 'inherit' || color === 'default' ? 'primary' : color

  switch (variant) {
    case 'contained':
      if (color === 'default') {
        overrides.color = theme.palette.grey[800]
        overrides.backgroundColor = theme.palette.grey[200]
        overrides['& svg > *'] = {
          fill: theme.palette.grey[600],
        }

        overrides['&:hover'] = {
          boxShadow: theme.customShadows.z8,
          backgroundColor: theme.palette.grey[300],
        }
      } else {
        overrides.backgroundColor = (theme.palette as any)[colorAsVariant].main
        overrides.color = theme.palette.grey[800]
        overrides['& svg > *'] = {
          fill: (theme.palette as any)[colorAsVariant].contrastText,
        }

        overrides[':hover'] = {
          boxShadow: theme.customShadows.z8,
          backgroundColor: (theme.palette as any)[colorAsVariant].dark,
        }
      }

      overrides['&.Mui-disabled'] = {
        backgroundColor: theme.palette.grey[200],
        '& svg > *': {
          fill: theme.palette.grey[400],
        },
      }
      break

    case 'outlined':
      overrides.backgroundColor = 'transparent'
      overrides.border = `1px solid ${theme.palette[colorAsVariant].main}`
      overrides.color = theme.palette[colorAsVariant].main
      overrides['& svg > *'] = {
        fill: (theme.palette as any)[colorAsVariant].main,
      }

      overrides[':hover'] = {
        border: `1px solid ${theme.palette[colorAsVariant].dark}`,
        '& svg > *': {
          fill: (theme.palette as any)[colorAsVariant].dark,
        },
      }

      overrides['&.Mui-disabled'] = {
        border: `1px solid ${theme.palette.grey[300]}`,
        '& svg > *': {
          fill: theme.palette.grey[300],
        },
      }
      break

    default:
      if (color === undefined || color === 'inherit' || color === 'default') {
        overrides.color = theme.palette.grey[700]
        overrides.backgroundColor = 'transparent'
        overrides['& svg > *'] = {
          fill: theme.palette.grey[700],
        }

        overrides['&:hover'] = {
          backgroundColor: 'transparent',
          '& svg > *': {
            fill: theme.palette.grey[500],
          },
        }

        overrides['&.Mui-disabled'] = {
          backgroundColor: 'transparent',
          '& svg > *': {
            fill: theme.palette.grey[400],
          },
        }
      } else {
        overrides.color = (theme.palette as any)[colorAsVariant].main
        overrides.backgroundColor = 'transparent'
        overrides['& svg > *'] = {
          fill: (theme.palette as any)[colorAsVariant].main,
        }

        overrides['&:hover'] = {
          backgroundColor: 'transparent',
          '& svg > *': {
            fill: (theme.palette as any)[colorAsVariant].dark,
          },
        }

        overrides['&.Mui-disabled'] = {
          backgroundColor: 'transparent',
          '& svg > *': {
            fill: theme.palette.grey[400],
          },
        }
      }
      break
  }

  return {
    ...overrides,
  }
})
