import { IEntity } from '@planning/rt-stores/types'
import { ISortDelegate } from '@planning/stores/PaginatedLocalStore'
import _ from 'lodash'
import { SortingModel } from './PaginatedStore'

export const entitySortDelegate =
  (defaultSortBy: string): ISortDelegate<IEntity<any>> =>
  (sortingModel, a, b) => {
    const getValue = (item: IEntity<any>, sortingModel: SortingModel<IEntity<any>>) => {
      if (sortingModel.orderBy && _.has(item.data, sortingModel.orderBy)) {
        return _.get(item.data, sortingModel.orderBy)
      }

      return _.get(item.data, defaultSortBy)
    }

    const valA = getValue(a, sortingModel)
    const valB = getValue(b, sortingModel)
    let sort = 0
    if ((typeof valA === 'number' || typeof valB === 'number') && valA !== valB) {
      sort = (valA ?? 0) > (valB ?? 0) ? -1 : 1
    } else if (typeof valA === 'string' && typeof valB === 'string') {
      sort = valA.localeCompare(valB)
    }

    return sortingModel.isDescending ? -sort : sort
  }
