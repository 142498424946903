import { Box } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { FC } from 'react'
import { CustomAlert } from '../../TallymanV2/Components/CustomAlert'

export const SelectPickUpAmountAlert: FC<{ show?: boolean }> = ({ show }) => {
  const { t } = useTranslate()

  return show ? (
    <Box mb={'1rem'}>
      <CustomAlert
        severity='error'
        message={t('selectAPickUpAmount', 'Select pick up amount for all non-numeric orders')}
      />
    </Box>
  ) : (
    <></>
  )
}
