import * as yup from 'yup'
import { PackageFormProfile } from './package-form.profile'

export const schema = (): yup.Schema<PackageFormProfile> =>
  yup.object({
    id: yup.number().optional(),
    name: yup.string().label('Name').emptyAsUndefined().required(),
    height: yup.number().label('Height').emptyAsUndefined().min(0),
    length: yup.number().label('Length').emptyAsUndefined().min(0),
    width: yup.number().label('Width').emptyAsUndefined().min(0),
    weightUnit: yup.string().label('Weight unit').emptyAsUndefined().required(),
    lengthUnit: yup
      .string()
      .label('Unit')
      .emptyAsUndefined()
      .when(['height', 'length', 'width'], ([height, length, width], schema) => {
        return !!height || !!length || !!width ? schema.required() : schema.nullable()
      }),
  })
