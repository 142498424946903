import { FormControl, Stack, TextField, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { ConfirmPickUpProps } from './confirm-pickup.interface'

interface Props {
  DestinationComponent?: React.ComponentType<ConfirmPickUpProps>
  containerNumber: string
  handlePicks?: (id: string) => void
}

export const ConfirmPicks = ({ handlePicks, DestinationComponent, containerNumber }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  return (
    <Stack gap={theme.customSpacing.m} paddingY={theme.customSpacing.m}>
      {DestinationComponent ? (
        <DestinationComponent containerNumber={containerNumber} onPositionUpdate={handlePicks} />
      ) : (
        <>
          <Typography variant='subtitle1'>
            {t('shifts(IfNecessary)', 'Shifts (if necessary)')}
          </Typography>

          <FormControl fullWidth>
            <TextField
              type='number'
              label={t('numberOfShifts', 'Number of Shifts')}
              onChange={() => console.log('to do')}
            />
          </FormControl>
        </>
      )}
    </Stack>
  )
}
