import { ContainerTurnoversReportMode } from '@billing/app/models/container-turnovers-report-mode.enum'
import { ContainerTurnoversUIStore } from '../stores/container-turnovers.ui-store'

export interface ExportContainerTurnoverOptionsDto {
  isDangerous?: boolean
  isDamaged?: boolean
  includeContainerNumber?: boolean
  includeIsoCode?: boolean
  includeOperator?: boolean
  includeGrossWeight?: boolean
  includeIsOOG?: boolean
  includeIsReefer?: boolean
  includeIsEmpty?: boolean
  includeIsDangerous?: boolean
  includeEntryDateTime?: boolean
  includeIsDamaged?: boolean
}

export const mapCTUIStorePropertiesToOptionsDto = (
  containerTurnoversUIStore: ContainerTurnoversUIStore,
): ExportContainerTurnoverOptionsDto => {
  return {
    isDangerous:
      containerTurnoversUIStore.reportMode === ContainerTurnoversReportMode.IMDG ? true : undefined,
    isDamaged:
      containerTurnoversUIStore.reportMode === ContainerTurnoversReportMode.DAMAGED
        ? true
        : undefined,
    ...containerTurnoversUIStore.fieldsToExport,
  }
}
