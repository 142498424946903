import { GetTokenSilentlyOptions } from '@auth0/auth0-react'

type GetAccessTokenSilently = (options?: GetTokenSilentlyOptions) => Promise<string>

export const accessTokenProvider = {
  get: undefined as GetAccessTokenSilently | undefined,
  setAccessTokenFactory: (getAccessTokenSilently: GetAccessTokenSilently) => {
    accessTokenProvider.get = getAccessTokenSilently
  },
}
