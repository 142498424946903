import { CommoditiesApi } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'

class CommodityService {
  httpClient = createApiClient(CommoditiesApi)

  async getByPartialName(filter: string) {
    const { data } = await this.httpClient.getByPartialName(filter)

    return data
  }

  async getByIds(ids: number[]) {
    const { data } = await this.httpClient.getByIds(ids)

    return data
  }

  async getById(id: number) {
    const { data } = await this.httpClient.getByIds([id])

    return data[0]
  }
}

const commodityService = new CommodityService()

export default commodityService
