import { useOperationsStore } from '@operations/AppProvider'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import CraneOperatorNoGeometryViewContainer from '@operations/features/craneOperator/containers/CraneOperatorNoGeometryViewContainer'

import { useTranslate } from '@tolgee/react'
import { FC, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router'

const CraneOperatorNoGeometryView: FC = withAuthenticationRequired(() => {
  const { t } = useTranslate()

  const { craneOperatorNoGeometryUIStore } = useOperationsStore()
  const { vesselVisitId, equipmentId } = useParams()

  useEffect(() => {
    const loadPageData = async () => {
      await craneOperatorNoGeometryUIStore.loadWorkInstruction()
    }

    craneOperatorNoGeometryUIStore.setSelectedVesselVisitId(Number(vesselVisitId))
    craneOperatorNoGeometryUIStore.setSelectedEquipmentId(Number(equipmentId))

    loadPageData()
  }, [craneOperatorNoGeometryUIStore, equipmentId, vesselVisitId])

  return (
    <>
      <Helmet>
        <title>{t('sts', 'STS')}</title>
      </Helmet>

      <CraneOperatorNoGeometryViewContainer />
    </>
  )
})

export default CraneOperatorNoGeometryView
