import { Chip, Stack } from '@mui/material'
import { SuccessIcon, useMinimalsTheme } from '@tom-ui/ui'

interface MultipleChipSelectProps {
  items: string[]
  selectedItems: string[]
  disabledItems: string[]
  onChange: (selectedItems: string[]) => void
}

const MultipleChipSelect = ({
  items,
  selectedItems,
  disabledItems,
  onChange,
}: MultipleChipSelectProps) => {
  const theme = useMinimalsTheme()

  const handleChipClick = (value: string) => {
    const newSelectedItems = selectedItems.includes(value)
      ? selectedItems.filter(item => item !== value)
      : [...selectedItems, value]
    onChange(newSelectedItems)
  }

  return (
    <Stack flexDirection='row' flexWrap='wrap' gap={theme.customSpacing.xs}>
      {items?.map(option => (
        <Chip
          key={option}
          label={option}
          disabled={disabledItems.includes(option)}
          onClick={() => handleChipClick(option)}
          color={selectedItems.includes(option) ? 'primary' : 'default'}
          icon={selectedItems.includes(option) ? <SuccessIcon /> : undefined}
        />
      ))}
    </Stack>
  )
}

export default MultipleChipSelect
