// @mui
//

// ----------------------------------------------------------------------

export interface CustomRadiusOptions {
  small: '4px'
  medium: '8px'
  large: '16px'
  oval: '999px'
}

// ----------------------------------------------------------------------

export default function customRadius() {
  return {
    small: '4px',
    medium: '8px',
    large: '16px',
    oval: '999px',
  } as CustomRadiusOptions
}
