import { Typography } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useTranslate } from '@tolgee/react'
import { FC } from 'react'

export const EmptyOrderList: FC = () => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  return (
    <Typography color={theme.palette.grey[500]} textAlign={'center'}>
      {t('exitsEmpty', 'Exits empty')}
    </Typography>
  )
}
