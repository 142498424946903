import { Theme } from '@mui/material/styles'

// ----------------------------------------------------------------------

export default function IconButton(theme: Theme) {
  const rootStyle = () => {
    const defaultStyle = {
      ...{
        borderRadius: theme.customRadius.medium,
        padding: theme.customSpacing.xs,
        '.MuiTouchRipple-ripple .MuiTouchRipple-child': {
          borderRadius: theme.customRadius.medium,
        },
      },
    }

    return [defaultStyle]
  }

  return {
    MuiIconButton: {
      styleOverrides: {
        root: () => rootStyle(),
      },
    },
  }
}
