import { Box, Grid } from '@mui/material'
import { ContainerInformationWidget } from '@storage/components/ContainerInformationWidget'
import { YardCapacityWidget } from '@storage/components/YardCapacityWidget'
import { ContainerTurnoversList } from '@storage/components/container-turnovers-list'
import { Pagination } from '@storage/components/pagination'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useMinimalsTheme } from '@tom-ui/ui'
import { ContainerMovementInfo, useUtilsStore } from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import DashboardPreferencesDialog from './components/DashboardPreferencesDialog'
import { DashboardToolbar } from './components/DashboardToolbar'
import { ManualPlanningDialog } from './components/ManualPlanningDialog'
import { ContainerTurnoversFilterForm } from './components/container-turnovers-filter-form'
import { useContainerTurnoversStores } from './hooks/container-turnovers-stores.hook'
import { useHandlePlanManually } from './hooks/use-handle-plan-manually.hook'
import { useHandlePlanOnYard } from './hooks/use-handle-plan-on-yard.hook'

const ContainerTurnovers = observer(() => {
  const {
    containerTurnoverStore,
    yardBlockSummaryStore,
    containerInformationWidgetStore,
    yardBlockStore,
    yardBlockBayStore,
    yardBlockRowStore,
    tenantConfigStore,
  } = useStores()

  const {
    containerTurnoversFilterStore,
    containerTurnoversPaginationStore,
    dashboardStore,
    manualPlanningDialogStore,
    containerTurnoversListStore,
    manualInputValidationStore,
  } = useContainerTurnoversStores()

  const { containerMovementDialogStore } = useUtilsStore()

  useEffect(() => {
    const loadYard = async () => {
      await yardBlockStore.loadList()
      await yardBlockBayStore.loadList()
      await yardBlockRowStore.loadAll()
    }

    const dispose = containerTurnoversListStore.loadContainersEffect()

    loadYard()

    return () => dispose()
  }, [
    containerTurnoversListStore,
    manualInputValidationStore,
    yardBlockStore,
    yardBlockBayStore,
    yardBlockRowStore,
  ])

  const { breakpoints } = useMinimalsTheme()

  const handlePlanOnYard = useHandlePlanOnYard({ containerTurnoversFilterStore })

  const handlePlanManually = useHandlePlanManually({
    containerTurnoversFilterStore,
    manualPlanningDialogStore,
  })

  const handleMoveManually = (containers: ContainerMovementInfo[]) => {
    containerMovementDialogStore.openDialog(containers)
  }

  return (
    <>
      <Grid
        container
        spacing={2}
        p={3}
        sx={{ height: '100%', overflowY: 'auto', minWidth: '400px' }}
      >
        <Grid
          item
          xs
          sx={{
            position: 'sticky',
            top: 0,
            alignSelf: 'start',
            [breakpoints.down('lg')]: {
              position: 'relative',
            },
          }}
        >
          <ContainerTurnoversFilterForm
            containersNumberMatchingFilter={containerTurnoverStore.pagination.totalCount}
            filter={containerTurnoversFilterStore.filter}
            advancedFiltersShown={containerTurnoversFilterStore.showAdvancedFilters}
            onToggleAdvancedFilters={() =>
              containerTurnoversFilterStore.toggleShowAdvancedFilters()
            }
            onPlanManually={handlePlanManually}
            onPlanOnYard={handlePlanOnYard}
            onMoveManually={handleMoveManually}
            onSubmit={formValues => containerTurnoversFilterStore.setFilter(formValues)}
            tenantConfigStore={tenantConfigStore}
          />

          <ContainerTurnoversList
            items={containerTurnoverStore.entries}
            onPlanItemManually={handlePlanManually}
            onPlanItemOnYard={handlePlanOnYard}
            tenantConfigStore={tenantConfigStore}
            onMoveItemManually={handleMoveManually}
          />
        </Grid>

        <Grid item xs='auto'>
          <Box
            sx={{
              paddingY: 1,
              mb: 1,
            }}
          >
            <DashboardToolbar dashboardStore={dashboardStore} />
          </Box>
          <Grid
            container
            spacing={1}
            sx={{
              [breakpoints.down('xl')]: {
                width: 'min-content',
              },
            }}
          >
            {dashboardStore.isActive && (
              <Grid item xs={12} xl={6}>
                {dashboardStore.isYardCapacityWidgetVisible && (
                  <YardCapacityWidget
                    yardCapacityWidgetStore={yardBlockSummaryStore}
                    tenantConfigStore={tenantConfigStore}
                  />
                )}
              </Grid>
            )}
            {dashboardStore.isActive && (
              <Grid item xs={12} xl={6}>
                {dashboardStore.isContainerAttributesWidgetVisible && (
                  <ContainerInformationWidget
                    containerInformationWidgetStore={containerInformationWidgetStore}
                  />
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Pagination
        paginatedEntityStore={containerTurnoverStore}
        paginationStore={containerTurnoversPaginationStore}
      />

      <ManualPlanningDialog manualPlanningDialogStore={manualPlanningDialogStore} />

      <DashboardPreferencesDialog dashboardStore={dashboardStore} />
    </>
  )
})

export default ContainerTurnovers
