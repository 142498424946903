import { GeneralCargoItemPlanningDto, UpdateGeneralCargoOrderPlanningDto } from '@storage/app/api'
import { EntityStore } from '@storage/app/store/entity.store'
import { GeneralCargoStockService } from '@storage/services/general-cargo-stock.service'

export class GeneralCargoPlanningStore extends EntityStore<GeneralCargoItemPlanningDto> {
  constructor(private readonly _generalCargoStockService: GeneralCargoStockService) {
    super()
  }

  public async getPlannedOccupanciesByOrderId(
    orderId: number,
    cargoItemId?: number,
  ): Promise<GeneralCargoItemPlanningDto[]> {
    const plannedOccupancies = await this._generalCargoStockService.getPlannedOccupanciesByOrderId(
      orderId,
      cargoItemId,
    )
    return plannedOccupancies
  }

  public async getIdentifiableItemsByOrderId(
    orderId: number,
    cargoItemId?: number,
  ): Promise<string[]> {
    const identifiableItems = await this._generalCargoStockService.getGeneralCargoOrderById(orderId)

    const items = cargoItemId
      ? identifiableItems.items.filter(item => item.cargoItemId === cargoItemId)
      : identifiableItems.items

    return items.flatMap(i => i.properties.map(p => p.unitId)) ?? []
  }

  public async loadPlannedOccupanciesByOrderId(orderId: number, cargoItemId?: number) {
    const plannedOccupancies = await this._generalCargoStockService.getPlannedOccupanciesByOrderId(
      orderId,
      cargoItemId,
    )
    this.setAll(plannedOccupancies)
  }

  public async planDropOff(dto: UpdateGeneralCargoOrderPlanningDto) {
    await this._generalCargoStockService.planDropOff(dto)
  }

  public clearAll() {
    this.removeAll()
  }
}
