import { CoolingOrderDto, ReeferTemperatureDto } from '@operations/app/api'
import { FormType } from '@operations/app/models'
import { BaseDrawerStore } from '@operations/stores/base/BaseDrawerStore'
import { ReeferTemperatureStore } from '@operations/stores/ReeferTemperatureStore'
import { action, computed, makeObservable, observable } from 'mobx'

export class ReeferMonitoringDrawerUIStore extends BaseDrawerStore<CoolingOrderDto> {
  recordId?: number

  constructor(reeferTemperatureStore: ReeferTemperatureStore) {
    super(reeferTemperatureStore)
    makeObservable(this, {
      recordId: observable,

      setEditingRecord: action,

      editingRecord: computed,
    })
  }

  public override toggle(id?: number, recordId?: number): void {
    super.toggle(id)

    this.setEditingRecord(recordId)
  }

  public override get formType(): FormType {
    return this.recordId ? 'Edit' : 'Add'
  }

  public setEditingRecord(recordId?: number): void {
    if (this.recordId !== recordId) this.recordId = recordId
  }

  public get editingRecord(): ReeferTemperatureDto | undefined {
    return this.recordId && this.editingEntity
      ? this.editingEntity.reeferTemperatures?.find(i => i.id === this.recordId)
      : undefined
  }
}
