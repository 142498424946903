import { RailTrackDto } from '@storage/app/api'
import { defaultValues, RailTrackFormProfile } from './rail-tracks-form.profile'
import useFormWithSchema from '@storage/hooks/use-form-with-schema.hook'
import { schema } from './rail-tracks-form.scheme'
import { FormProps } from '@storage/app/models'
import { useTranslate } from '@tolgee/react'
import { useEffect } from 'react'
import { Box } from '@mui/system'
import { ControlledTextField } from '@storage/components/form-controls'
import { mapRailTrackDtoToFormValues } from './rail-tracks.mapper'

interface Props extends FormProps {
  railTrack?: RailTrackDto
  onSubmit: (formValues: RailTrackFormProfile) => Promise<void> | void
}

const RailTracksForm = ({ id, onSubmit, railTrack }: Props) => {
  const { handleSubmit, control, formState, reset } = useFormWithSchema<RailTrackFormProfile>(
    schema,
    defaultValues,
  )

  const { t } = useTranslate()

  useEffect(() => {
    if (railTrack) reset(mapRailTrackDtoToFormValues(railTrack))
  }, [reset, railTrack])

  return (
    <Box
      id={id}
      component='form'
      noValidate
      autoComplete='off'
      onSubmit={handleSubmit(onSubmit)}
      my={1}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <ControlledTextField
          formState={formState}
          control={control}
          name='name'
          label={t('name', 'Name')}
          required
        />
        <ControlledTextField
          formState={formState}
          control={control}
          type='number'
          label={t('lengthM', 'Length (m)')}
          name='length'
        />
      </Box>
    </Box>
  )
}

export default RailTracksForm
