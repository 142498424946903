import { action, makeObservable, observable } from 'mobx'

export abstract class FilterFeatureStore<T> {
  private initialValues: T

  constructor(public filter: T) {
    this.initialValues = { ...this.filter }

    makeObservable(this, {
      filter: observable,
      setFilter: action,
      reset: action,
      resetAttribute: action,
    })
  }

  setFilter(newFilter: T) {
    this.filter = { ...newFilter }
  }

  public reset() {
    this.filter = this.initialValues
  }

  public resetAttribute(key: keyof T) {
    this.filter = {
      ...this.filter,
      [key]: this.initialValues[key],
    }
  }
}
