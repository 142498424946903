import { CargoUnitDto, EquipmentType } from '@operations/app/api'
import { SelectOption } from '@operations/app/models'
import { CargoUnitStore } from '@operations/stores/CargoUnitStore'
import { EquipmentStore } from '@operations/stores/EquipmentStore'
import { WorkInstructionStore } from '@operations/stores/WorkInstructionStore'
import { YardBlockStore } from '@operations/stores/YardBlockStore'
import _ from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import { JobFormProfile } from '../components/JobForm/job-form.profile'

export class JobMonitoringDrawerUIStore {
  isOpen = false
  selectedContainer?: CargoUnitDto

  constructor(
    private cargoUnitStore: CargoUnitStore,
    private equipmentStore: EquipmentStore,
    private workInstruction: WorkInstructionStore,
    private yardBlockStore: YardBlockStore,
  ) {
    makeObservable(this, {
      isOpen: observable,
      selectedContainer: observable,

      selectContainer: action,
      toggle: action,

      containers: computed,
      containerOptions: computed,
      yardBlockOptions: computed,
      equipmentOptions: computed,
    })
  }

  public toggle(): void {
    this.isOpen = !this.isOpen
  }

  public selectContainer(container?: CargoUnitDto): void {
    this.selectedContainer = container
  }

  public async createWorkInstruction(job: JobFormProfile) {
    await this.workInstruction.create({
      cargoUnitId: job.cargoUnit.value,
      equipmentId: job.equipment?.value,
      yardBlockId: job.yardBlock?.value,
      deadline: job.deadline?.toISOString(),
      destinationLocationType: job.destinationService?.value,
    })
    await this.cargoUnitStore.loadOnTerminal()
  }

  public get containers() {
    return this.cargoUnitStore.items
  }

  public get containerOptions(): SelectOption[] {
    return _(this.containers)
      .sortBy(x => x.containerNumber)
      .map(x => ({ label: x.containerNumber, value: x.id.toString() }))
      .value()
  }

  public get yardBlockOptions(): SelectOption[] {
    return _(
      this.yardBlockStore.items.map(x => ({
        label: x.name,
        value: x.id,
      })),
    )
      .sortBy(i => i.label)
      .value()
  }

  public get equipmentOptions(): SelectOption[] {
    return _(
      this.equipmentStore.items
        .filter(
          x =>
            (this.selectedContainer?.isEmpty && x.equipmentType === EquipmentType.Ech) ||
            x.equipmentType === EquipmentType.Rs,
        )
        .map(x => ({ label: x.name, value: x.id })),
    )
      .sortBy(x => x.label)
      .value()
  }
}
