import * as yup from 'yup'
import IsUniqueValidator from './is-unique.validator'
import { PierFormProfile } from './pier-form.profile'

export const schema: yup.Schema<PierFormProfile> = yup.object({
  id: yup.string().optional(),
  name: yup
    .string()
    .label('Name')
    .emptyAsUndefined()
    .required()
    .test(IsUniqueValidator.name, IsUniqueValidator.errorMessage, async (name, context) => {
      const { id } = context.parent as PierFormProfile

      return await IsUniqueValidator.validate({ id, name })
    }),
})
