import { Box, Button } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { CarrierVisitDirection, OrderResponseDto } from '@planning/app/api'
import { ValidateOutboundOrderFunc } from '@planning/stores/gateControl/ValidateOutboundDto'
import { GetOrderDamagesAmountFunc } from '@planning/stores/generalCargo/OrderDamageDto'
import { useTranslate } from '@tolgee/react'
import { Loader, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import { CreateGeneralCargoOrder } from '../Order/CreateGeneralCargoOrders'
import { GeneralCargoOrdersCard } from './Components/GeneralCargoOrdersCard'
import { Header } from './Components/Header'
import { SearchBar } from './Components/SearchBar'

export interface IGeneralCargoPageProps {
  renderReservations?: (order: OrderResponseDto) => React.ReactElement
  renderDamage?: (orderId: number) => React.ReactElement
  getOrderDamagesAmountFunc?: GetOrderDamagesAmountFunc
  validateOutboundRequest?: ValidateOutboundOrderFunc
}

export const GeneralCargoPage: FC<IGeneralCargoPageProps> = observer(
  ({
    renderReservations,
    renderDamage,
    getOrderDamagesAmountFunc,
    validateOutboundRequest,
  }: IGeneralCargoPageProps) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()
    const { generalCargoViewStore, dialogStore } = usePlanningStore()

    generalCargoViewStore.getOrderDamagesAmountByOrderIds = getOrderDamagesAmountFunc

    const onChangeSearchField = (filter: string) => {
      if (filter.trim() !== '') {
        generalCargoViewStore.generalCargoSearchStore.setFilter(filter)
      } else {
        generalCargoViewStore.generalCargoSearchStore.reset()
      }
    }

    useEffect(() => {
      generalCargoViewStore.generalCargoSearchStore.reset()
      generalCargoViewStore.getPackages()
    }, [generalCargoViewStore])

    const onClickFilter = () => {
      console.log('open filter')
    }

    return (
      <Box sx={{ backgroundColor: theme.palette.grey[200], minHeight: '100%' }}>
        <Header
          title={t('generalCargoOrders', 'General cargo orders')}
          actions={[
            <Button
              key='addOrderButton'
              variant='contained'
              size='large'
              onClick={() => {
                generalCargoViewStore.selectOrder(undefined)
                dialogStore.openDialog(
                  <CreateGeneralCargoOrder validateOutboundRequest={validateOutboundRequest} />,
                )
              }}
            >
              {t('create', 'Create')}
            </Button>,
          ]}
        />
        <Box
          sx={{
            padding: `0 ${theme.customSpacing.l}`,
          }}
        >
          <SearchBar onClickFilter={onClickFilter} onSearchChange={onChangeSearchField} />

          {generalCargoViewStore.generalCargoSearchStore.isLoading ? (
            <Loader show />
          ) : generalCargoViewStore.generalCargoSearchStore.items.length > 0 ? (
            generalCargoViewStore.generalCargoSearchStore.items.map(o => (
              <GeneralCargoOrdersCard
                key={`general-cargo-orders-card-${o.id}`}
                order={o}
                damageAmount={
                  generalCargoViewStore.damageOrders.find(x => x.orderId === o.id)?.amount
                }
                carrierVisits={generalCargoViewStore.carrierVisits}
                onEdit={order => {
                  generalCargoViewStore.selectOrder(order)
                  dialogStore.openDialog(
                    <CreateGeneralCargoOrder
                      renderDamage={renderDamage}
                      validateOutboundRequest={validateOutboundRequest}
                    />,
                  )
                }}
                renderReservations={
                  o.direction === CarrierVisitDirection.Inbound ? renderReservations : undefined
                }
              />
            ))
          ) : (
            ''
          )}
        </Box>
      </Box>
    )
  },
)
