import {
  AllocationRulesTemplateApi,
  AllocationRulesTemplateCopyRuleTemplateToCarrierVisitRequest,
  AllocationRulesTemplateCreateRequest,
  AllocationRulesTemplateUpdateRequest,
  AllocationRuleTemplateDto,
  CarrierVisitAllocationRuleDto,
} from '@storage/app/api'
import { AxiosPromise } from 'axios'

export class AllocationRuleTemplatesService {
  constructor(private api: AllocationRulesTemplateApi) {}

  async getAll(): Promise<Array<AllocationRuleTemplateDto>> {
    const { data: allocationRuleTemplates } = await this.api.getAll()
    return allocationRuleTemplates
  }

  async create(
    allocationRulesTemplateCreateRequest: AllocationRulesTemplateCreateRequest,
  ): Promise<AllocationRuleTemplateDto> {
    const { data: allocationRuleTemplate } = await this.api.create({
      allocationRulesTemplateCreateRequest,
    })
    return allocationRuleTemplate
  }

  async update(
    allocationRulesTemplateUpdateRequest: AllocationRulesTemplateUpdateRequest,
  ): Promise<AllocationRuleTemplateDto> {
    const { data: allocationRuleTemplate } = await this.api.update({
      allocationRulesTemplateUpdateRequest,
    })
    return allocationRuleTemplate
  }

  async delete(id: string) {
    await this.api._delete({ id })
  }

  async isNameUnique(name: string, id?: string): AxiosPromise<boolean> {
    return await this.api.isNameUnique({
      name,
      id,
    })
  }

  async copyRuleTemplateToCarrierVisit(
    allocationRulesTemplateCopyRuleTemplateToCarrierVisitRequest?: AllocationRulesTemplateCopyRuleTemplateToCarrierVisitRequest,
  ): Promise<CarrierVisitAllocationRuleDto> {
    const { data: carrierVisitAllocationRule } = await this.api.copyRuleTemplateToCarrierVisit({
      allocationRulesTemplateCopyRuleTemplateToCarrierVisitRequest,
    })

    return carrierVisitAllocationRule
  }
}
