import {
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  PopperPlacementType,
  Typography,
} from '@mui/material'
import { NestedMenuItem } from 'mui-nested-menu'
import { useRef, useState } from 'react'
import { useMinimalsTheme } from '../../../hooks'
import { ChevronLeftIcon, ChevronRightIcon } from '../../icons'
import { MoreOptionsButton } from './MoreOptionsButton'
import { ButtonOption, ButtonSize } from './button.model'

interface RecursiveProps {
  options: ButtonOption[]
  open: boolean
  handleClickCallback: (option: ButtonOption) => void
}
const RecursiveMenuItems = (props: RecursiveProps) => {
  return (
    <>
      {props.options.map(option =>
        option?.children && option.children.length > 0 ? (
          <NestedMenuItem
            key={option.label.split(' ').join('_')}
            label={option.label}
            parentMenuOpen={props.open}
            divider={option.divider}
            leftIcon={option.openToLeft ? <ChevronLeftIcon /> : undefined}
            rightIcon={option.openToLeft ? <></> : <ChevronRightIcon />}
            MenuProps={{
              disableScrollLock: true,
              anchorOrigin: {
                vertical: 'top',
                horizontal: option.openToLeft ? 'left' : 'right',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: option.openToLeft ? 'right' : 'left',
              },
            }}
          >
            <RecursiveMenuItems
              options={option.children}
              open={props.open}
              handleClickCallback={props.handleClickCallback}
            />
          </NestedMenuItem>
        ) : (
          <MenuItem
            data-cy={option.testId}
            key={option.label.split(' ').join('_')}
            onClick={() => props.handleClickCallback(option)}
            divider={option.divider}
            disabled={option.disabled}
          >
            <Typography sx={{ marginLeft: '-4px' }} color={option.color}>
              {option.label}
            </Typography>
          </MenuItem>
        ),
      )}
    </>
  )
}

interface Props {
  options: ButtonOption[]
  position?: PopperPlacementType
  size?: ButtonSize
  tooltip?: string
  testId?: string
}
export const OptionsButton = ({ options, position, size, tooltip, testId }: Props) => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)
  const theme = useMinimalsTheme()

  const handleMenuItemClick = (option: ButtonOption) => {
    if (option.onClick) option.onClick()
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = (event: Event) => {
    if (anchorRef?.current?.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  return (
    <>
      <ButtonGroup ref={anchorRef}>
        <MoreOptionsButton
          testId={testId}
          size={size}
          tooltip={tooltip ?? ''}
          onClick={handleToggle}
        />
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: theme.zIndex.fab,
        }}
        open={open}
        placement={position ?? 'left'}
        anchorEl={anchorRef.current}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id='split-button-menu' autoFocusItem>
                  <RecursiveMenuItems
                    options={options}
                    open={open}
                    handleClickCallback={handleMenuItemClick}
                  />
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}
