import { useOperationsStore } from '@operations/AppProvider'
import { SplitButton } from '@operations/components/split-button/SplitButton'
import { CraneSplitContainerUIStore } from '@operations/features/craneSplit/stores/crane-split-container.ui-store'
import { CraneSplitDialogUIStore } from '@operations/features/craneSplit/stores/crane-split-dialog.ui-store'
import { CraneSplitDrawerUIStore } from '@operations/features/craneSplit/stores/crane-split-drawer.ui-store'
import { CraneSplitMessageUIStore } from '@operations/features/craneSplit/stores/crane-split-message.ui-store'
import { SelectCranesDialogUIStore } from '@operations/features/craneSplit/stores/select-cranes-dialog.ui-store'
import { Box, Button } from '@mui/material'
import { ButtonOption, OptionsButton } from '@tom-ui/ui'
import { useTranslate } from '@tolgee/react'
import { delay } from '@operations/utils/delay'
import { downloadHtmlAsJPG, downloadHtmlAsPDF } from '@operations/utils/export-html'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

interface Props {
  uiDialogStore: CraneSplitDialogUIStore
  uiDrawerStore: CraneSplitDrawerUIStore
  uiContainerStore: CraneSplitContainerUIStore
  uiMessageStore: CraneSplitMessageUIStore
  uiSelectCranesDialogStore: SelectCranesDialogUIStore
}

export const CraneSplitActions = observer(
  ({
    uiDialogStore,
    uiDrawerStore,
    uiContainerStore,
    uiMessageStore,
    uiSelectCranesDialogStore,
  }: Props) => {
    const { t } = useTranslate()
    const { appStore } = useOperationsStore()

    const downloadHtmlAsFile = async (type: 'pdf' | 'image' = 'pdf') => {
      appStore.increaseLoadingCounter()
      uiContainerStore.setExporting(true)

      requestAnimationFrame(async () => {
        const craneSplitElement = document.getElementById('crane-split') as HTMLElement
        const $style = document.createElement('style')
        $style.innerHTML = `#crane-split-content > * {overflow: hidden; z-index: 1;} 
          #metric-list {
            padding: 1rem;
            margin-bottom: -3rem;
            z-index: 100;
          }
        `
        craneSplitElement.appendChild($style)

        const craneSplitBaysElement = document.getElementById('crane-split-bays') as HTMLElement

        const hoursElement = document.getElementById('crane-split-hours') as HTMLElement
        const multiplier = document.getElementsByTagName('main').length ? 5 : 2

        if (type === 'pdf') {
          await downloadHtmlAsPDF(
            'crane-split',
            'crane-split',
            'landscape',
            craneSplitBaysElement.scrollWidth +
              (craneSplitBaysElement.scrollWidth === craneSplitBaysElement.offsetWidth
                ? hoursElement.scrollWidth
                : hoursElement.scrollWidth * multiplier),
          )
        } else {
          await downloadHtmlAsJPG(
            'crane-split',
            'crane-split',
            craneSplitBaysElement.scrollWidth +
              (craneSplitBaysElement.scrollWidth === craneSplitBaysElement.offsetWidth
                ? hoursElement.scrollWidth
                : hoursElement.scrollWidth * multiplier),
          )
        }

        $style.remove()
        uiContainerStore.setExporting(false)

        await delay(1000)
        appStore.decreaseLoadingCounter()
      })
    }

    const handleSave = async () => {
      uiMessageStore.setMessage()
      const saveResponseMessage = await uiContainerStore.saveCraneSplit()

      if (saveResponseMessage) {
        uiMessageStore.setMessage(saveResponseMessage)
      } else {
        appStore.setShowAlert('success', t('craneSplitSaved', 'Crane split saved!'))
        if (!_(uiContainerStore.initialCraneIds).isEqual(uiContainerStore.craneIds)) {
          uiMessageStore.setMessage(
            t(
              'pleaseTakeALookAtTheEquipmentPlanningCranesModified',
              'Please take a look at the Equipment Planning if you already planned because some Cranes were modified',
            ),
            'warning',
          )
        }

        uiContainerStore.initInitialCranes()
      }
    }

    const options: ButtonOption[] = [
      {
        label: t('open', 'Open'),
        divider: true,
        children: [
          {
            label: t('dischargingPlan', 'Discharging plan'),
            disabled: true,
          },
          {
            label: t('LoadingPlan', 'Loading plan'),
            disabled: true,
          },
        ],
        openToLeft: true,
      },
      {
        label: t('undo', 'Undo (Ctrl + Z)'),
        divider: true,
        disabled: !uiContainerStore.canUndo,
        onClick: () => {
          uiContainerStore.undo()
        },
      },
      {
        label: t('redo', 'Redo (Ctrl + Shift + Z)'),
        divider: true,
        disabled: !uiContainerStore.canRedo,
        onClick: () => {
          uiContainerStore.redo()
        },
      },
      {
        label: t('reset', 'Reset'),
        color: 'error',
        divider: true,
        disabled: !uiContainerStore.canUndo,
        onClick: () => {
          uiDialogStore.toggleResetDialog()
        },
      },
      {
        label: t('settings', 'Settings'),
        children: [
          {
            label: t('editCranes', 'Edit cranes'),
            onClick: () => {
              uiSelectCranesDialogStore.toggleWithSelectedCranes(uiContainerStore.craneIds)
            },
          },
          {
            label: t('processingTime', 'Processing time'),
            onClick: () => {
              uiDrawerStore.toggleProcessingTime()
            },
          },
        ],
        openToLeft: true,
      },
    ]

    const exportOptions: ButtonOption[] = [
      {
        label: t('exportAsPDF', 'Export as PDF'),
        divider: true,
        onClick: () => {
          downloadHtmlAsFile()
        },
      },
      {
        label: t('exportAsImage', 'Export as Image'),
        onClick: () => {
          downloadHtmlAsFile('image')
        },
      },
    ]

    useEffect(() => {
      const handleKeyboardEvents = (event: KeyboardEvent) => {
        const pressedControlZ = (event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'z' //meta is command for mac
        const drawersAreClosed =
          !uiDrawerStore.isOpen && !uiDrawerStore.processingTimeIsOpen && !uiDrawerStore.splitIsOpen
        const dialogsAreClosed =
          !uiDialogStore.resetDialog && !uiDialogStore.detailsDialog && !uiDialogStore.removeDialog

        if (
          uiContainerStore.canUndo &&
          drawersAreClosed &&
          dialogsAreClosed &&
          pressedControlZ &&
          !event.shiftKey
        ) {
          event.preventDefault()
          uiContainerStore.undo()
        }

        if (
          uiContainerStore.canRedo &&
          drawersAreClosed &&
          dialogsAreClosed &&
          pressedControlZ &&
          event.shiftKey
        ) {
          event.preventDefault()
          uiContainerStore.redo()
        }
      }

      document.addEventListener('keydown', handleKeyboardEvents)

      return () => document.removeEventListener('keydown', handleKeyboardEvents)
    }, [uiContainerStore, uiDrawerStore, uiDialogStore])

    return (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <SplitButton
          variant='outlined'
          color='info'
          size='large'
          options={exportOptions}
          disabled={!uiDialogStore.hasWorkQueues}
          label={t('export', 'Export')}
        />

        {uiContainerStore.canModify && (
          <Button variant='contained' disabled={!uiContainerStore.canUndo} onClick={handleSave}>
            {t('save', 'Save')}
          </Button>
        )}

        {uiContainerStore.canModify && (
          <OptionsButton
            tooltip={t('moreOptions', 'More options')}
            size='large'
            options={options}
          />
        )}
      </Box>
    )
  },
)
