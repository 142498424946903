import { ContainerStatus } from '@storage/app/api'
import { ValueBox } from '@storage/components/ValueBox'
import { Box, Card, Typography } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import {
  ContainerTurnoversFilterFormProfile,
  defaultValues,
} from '@storage/pages/container-turnovers/components/container-turnovers-filter-form/container-turnovers-filter-form.profile'
import { ContainerTurnoversFilterStore } from '@storage/pages/container-turnovers/stores/container-turnovers-filter.store'
import { useTranslate } from '@tolgee/react'
import { ComponentStatus, Size } from '@storage/helpers/enums'
import { PrefiltersStore } from '../stores'

interface Props {
  stackedInContainersCount: number
  prefiltersStore: PrefiltersStore
  containerTurnoversFilterStore: ContainerTurnoversFilterStore
  filterDefaultValues: Partial<ContainerTurnoversFilterFormProfile>
}

export const StackedInWidget = ({
  stackedInContainersCount,
  prefiltersStore,
  containerTurnoversFilterStore,
  filterDefaultValues,
}: Props) => {
  const { palette } = useMinimalsTheme()
  const { t } = useTranslate()

  return (
    <Card sx={{ borderRadius: '8px' }}>
      <Box
        sx={{
          py: 1,
          px: 2,
          display: 'flex',
          justifyContent: 'space-between',
          bgcolor: prefiltersStore.selectedPrefilter?.index === -1 ? '#1890FF14' : '',
          '&:hover': {
            backgroundColor: '#1890FF14',
          },
        }}
        onClick={() => {
          prefiltersStore.setSelectedPrefilter({
            index: -1,
            locationStatus: ContainerStatus.OnTheYard,
          })
          containerTurnoversFilterStore.setFilter({
            ...defaultValues,
            ...filterDefaultValues,
            locationStatus: ContainerStatus.OnTheYard,
          })
        }}
      >
        <Box>
          <Typography variant='h6'>{t('onTheYard', 'On the yard')} </Typography>
        </Box>
        <ValueBox
          value={stackedInContainersCount}
          color={`${palette.primary.main}14`}
          valueColor={palette.primary.main}
          size={Size.Small}
          state={ComponentStatus.Success}
          sx={{
            minWidth: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        />
      </Box>
    </Card>
  )
}
