import { TextField, TextFieldProps } from '@mui/material'
import { OverridableStringUnion } from '@mui/types'
import { isNumber } from 'lodash'
import { Controller, FieldValues, FormState, UseControllerProps } from 'react-hook-form'

type FieldProps = Pick<
  TextFieldProps,
  'label' | 'disabled' | 'fullWidth' | 'variant' | 'multiline' | 'maxRows' | 'minRows'
>

interface Props<T extends FieldValues> extends UseControllerProps<T>, FieldProps {
  type?: string
  uppercase?: boolean
  endAdornment?: React.ReactNode
  formState: FormState<T>
  size?: OverridableStringUnion<'small' | 'medium'>
  showAsterisk?: boolean
}

export const ControlledTextField = <T extends FieldValues>({
  name,
  control,
  label,
  fullWidth,
  disabled,
  variant,
  maxRows,
  minRows,
  multiline,
  type,
  uppercase,
  endAdornment,
  size,
  formState: { errors },
  showAsterisk,
}: Props<T>) => {
  const error = () => {
    const nameSplit = name.split('.')
    if (nameSplit.length > 2) {
      return (
        errors[nameSplit[0]] &&
        isNumber(+nameSplit[1]) &&
        (errors[nameSplit[0]] as any)[nameSplit[1]][nameSplit[2]]
      )
    }

    return errors[name]
  }

  return (
    <Controller
      render={({ field }) => (
        <TextField
          {...field}
          error={!!error()}
          size={size ?? 'medium'}
          helperText={error()?.message as string}
          label={label}
          multiline={multiline}
          required={showAsterisk}
          type={type}
          maxRows={maxRows}
          minRows={minRows}
          fullWidth={fullWidth}
          disabled={disabled}
          variant={variant ?? 'outlined'}
          inputProps={{
            style: { textTransform: uppercase ? 'uppercase' : 'none' },
          }}
          InputProps={{
            endAdornment: endAdornment,
          }}
        />
      )}
      name={name}
      control={control}
    />
  )
}
