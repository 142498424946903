import { RailTrackResponseDto } from '@planning/app/api'
import { IEvent, IMessageBus } from '@planning/messages'
import { EventTypes } from '@planning/messages/eventsTypes'
import { GetRailTracksQuery } from '@planning/messages/queries'
import _ from 'lodash'
import { action, computed, makeObservable } from 'mobx'
import { EntityItem } from '../base/EntityItem'
import { ItemStore } from '../base/ItemStore'
import { IEntityStore } from '../types'

export class RailTrackItem extends EntityItem<RailTrackResponseDto, string> {}

export class RailTrackItemStore
  extends ItemStore<RailTrackResponseDto, RailTrackItem, string>
  implements IEntityStore<RailTrackItem>
{
  private hasBeenLoaded = false

  constructor(private messageBus: IMessageBus) {
    super((key, data) => new RailTrackItem(key, data))
    makeObservable(this, {
      activeRailTrackNameMap: computed,
      activeRailTracks: computed,
      receiveQuery: action,
      receiveUpsertedEvent: action,
      receiveDeletedEvent: action,
    })

    messageBus.subscribeEvent(GetRailTracksQuery.type, this.receiveQuery)
    messageBus.subscribeEvent(EventTypes.RailTrackUpsertedEvent, this.receiveUpsertedEvent)
    messageBus.subscribeEvent(EventTypes.RailTrackDeletedEvent, this.receiveDeletedEvent)
  }

  fetch = async () => {
    if (this.hasBeenLoaded) return

    this.hasBeenLoaded = true

    await this.messageBus.dispatchQuery(new GetRailTracksQuery())
  }

  get activeRailTracks() {
    return _(this.elements)
      .map(item => item.data)
      .filter(item => !item.isDeleted)
      .orderBy(item => item.name)
      .value()
  }

  get activeRailTrackNameMap() {
    const result = _(this.activeRailTracks)
      .map((item): [string, RailTrackResponseDto] => [item.name.toLocaleLowerCase(), item])
      .value()

    return new Map<string, RailTrackResponseDto>(result)
  }

  receiveQuery = (event: IEvent<RailTrackResponseDto[]>): void => {
    if (event.payload) {
      this.upsertBulk(event.payload)
    }
  }

  receiveUpsertedEvent = (event: IEvent<RailTrackResponseDto>) => {
    if (event.payload) {
      this.upsert(event.payload)
    }
  }

  receiveDeletedEvent = (event: IEvent<string>): void => {
    if (event.payload && _.has(this.elements, event.payload)) {
      this.upsert({ ..._.get(this.elements, event.payload).data, isDeleted: true })
    }
  }
}
