import { VesselDto, VesselsApi } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'
import { Event, IEvent, IQueryHandler, IQueryMessage } from '../messageBus'

export class GetVesselsQuery implements IQueryMessage {
  static type = 'GetVesselsQuery'
  type = GetVesselsQuery.type

  constructor(
    public page: number,
    public pageSize: number,
  ) {}

  getKey = () => {
    return JSON.stringify(this)
  }
}

const httpClient = createApiClient(VesselsApi)

export const getVesselsQueryHandler: IQueryHandler<
  GetVesselsQuery,
  IEvent<VesselDto[]>
> = async query => {
  const { data } = await httpClient.get(query.page, query.pageSize)

  return new Event(GetVesselsQuery.type, data.payload)
}
