import { CustomerDto } from '@billing/app/api'
import { CustomerStore } from '@billing/stores/CustomerStore'
import { BaseDrawerStore } from '@billing/stores/base/BaseDrawerStore'
import { action, makeObservable, observable } from 'mobx'

export class CustomerDialogUIStore extends BaseDrawerStore<CustomerDto> {
  isDeleteOpen = false

  constructor(private customerStore: CustomerStore) {
    super(customerStore)
    makeObservable(this, {
      isDeleteOpen: observable,

      toggleDelete: action,
    })
  }

  public toggleDelete(): void {
    this.isDeleteOpen = !this.isDeleteOpen
  }
}
