import { Box, DialogActions } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { UpsertOrderListCommandV2 } from '@planning/app/api'
import { LoaderButton } from '@planning/components/LoaderButton'
import { OrderListUploadViewStoreV2 } from '@planning/pages/Order/stores/OrderListUploadViewStoreV2'
import { carrierVisitService } from '@planning/services'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { UseFormReset } from 'react-hook-form'

export interface IProps {
  store: OrderListUploadViewStoreV2
  resetForm: UseFormReset<any>
}

export const OrderListUploadDialogFooter: FC<IProps> = observer(({ store, resetForm }) => {
  const { appViewStore, drawerStore } = usePlanningStore()

  const { t } = useTranslate()

  const upsertOrderList = async () => {
    if (!store.orderUpdates) return

    const { amended, created, deleted } = store.orderUpdates

    if (amended.length || created.length || deleted.length) {
      const cmd = {
        listName: store.listName,
        carrierVisitId: store.vesselVisitId ?? store.railVisitId,
        direction: store.direction,
        customerId: store.customer?.id,
        addOrders: created.filter(o => store.acceptUpdates[o.containerNumber]),
        updateOrders: amended
          .filter(o => store.acceptUpdates[o.containerNumber ?? ''])
          .map(amendment => amendment.update),
        deleteOrders: deleted
          .filter(o => store.acceptUpdates[o.containerNumber ?? ''])
          .map(o => o.id),
      } as UpsertOrderListCommandV2

      try {
        await carrierVisitService.upsertOrderListV2(cmd)
      } catch (error) {
        appViewStore.setShowAlert('error', t('failToUploadFile', 'Fail to upload file'))
      }

      appViewStore.setShowAlert(
        'success',
        t('fileUploadedSuccessfully', 'File uploaded successfully'),
      )
    }

    store.reset()
    resetForm()
    drawerStore.close()
  }

  const actions = () => {
    if (store.activeStep === 1)
      return (
        <Box data-cy='upload-documents-confirm-updates-btn'>
          <LoaderButton
            onClick={async () => await upsertOrderList()}
            color='primary'
            variant='contained'
          >
            {t('confirmUpdates', 'Confirm updates')}
          </LoaderButton>
        </Box>
      )

    return (
      <Box data-cy='upload-documents-next-btn'>
        <LoaderButton color='primary' variant='contained' type='submit'>
          {t('next', 'Next')}
        </LoaderButton>
      </Box>
    )
  }

  return (
    <DialogActions
      sx={{
        width: '100%',
        p: '1rem 2rem!important',
        borderTop: '1px solid rgba(145, 158, 171, 0.24)',
      }}
    >
      <Box display='flex' alignItems='center' justifyContent='space-between' width='100%'>
        {actions()}
      </Box>
    </DialogActions>
  )
})
