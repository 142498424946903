import { Box } from '@mui/material'
import { ContainerMobile, Header, useMinimalsTheme } from '@tom-ui/ui'
import { CarrierVisitDirection } from '@planning/app/api'
import { IGateClerkViewStore } from '@planning/stores/gateClerk/GateClerkViewStore'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import { HeaderAvatar } from './../TallymanV2/Components/HeaderComponents/HeaderAvatar'
import { GatePassageResultAlert } from './Components/GatePassageResultAlert'
import { TruckDirection } from './Components/TruckDirection'

interface IProps {
  store: IGateClerkViewStore
  navigateNext: (operation: CarrierVisitDirection) => void
}

export const SelectOperation: FC<IProps> = observer(({ store, navigateNext }) => {
  const { notificationStore } = store

  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const setOperation = (operation: CarrierVisitDirection) => {
    store.setOperation(operation)
    notificationStore.reset()

    navigateNext(operation)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Header title={t('gateClerk', 'Gate Clerk')} rightRenderOption={<HeaderAvatar />} />
      <Box style={{ backgroundColor: theme.palette.grey[200], minHeight: '100vh' }}>
        <ContainerMobile sx={{ pt: theme.customSpacing.l }}>
          <GatePassageResultAlert store={notificationStore} />
          <TruckDirection
            direction={CarrierVisitDirection.Inbound}
            onClick={() => setOperation(CarrierVisitDirection.Inbound)}
          />
          <TruckDirection
            direction={CarrierVisitDirection.Outbound}
            onClick={() => setOperation(CarrierVisitDirection.Outbound)}
          />
        </ContainerMobile>
      </Box>
    </>
  )
})
