import { Box, Divider } from '@mui/material'
import { Draggable } from 'react-beautiful-dnd'
import { CarrierVisitAllocationRuleDto } from '@storage/app/api' // Adjust the import path as needed
import CarrierVisitAllocationRulesListItem from './CarrierVisitAllocationRulesListItem' // Adjust the import path as needed

interface DraggableAllocationRuleListItemProps {
  allocationRule: CarrierVisitAllocationRuleDto
  index: number
  isLast: boolean
}

const DraggableAllocationRuleListItem = ({
  allocationRule,
  index,
  isLast,
}: DraggableAllocationRuleListItemProps) => (
  <Draggable draggableId={allocationRule.id.toString()} index={index}>
    {provided => (
      <Box
        ref={provided.innerRef}
        {...provided.draggableProps}
        sx={{
          background: 'white',
        }}
      >
        <CarrierVisitAllocationRulesListItem
          allocationRule={allocationRule}
          dragHandleProps={provided.dragHandleProps}
        />
        {!isLast && <Divider />}
      </Box>
    )}
  </Draggable>
)

export default DraggableAllocationRuleListItem
