import { useBillingServiceStore } from '@billing/AppProvider'
import Permission from '@billing/app/models/permission.enum'
import { Stack } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { NoListResult } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useSnackbar } from 'notistack'
import { PackageCard } from '../components/packageCard/PackageCard'

export const PackagesContainer = observer(() => {
  const { t } = useTranslate()
  const { packageListUIStore, packageDialogUIStore, authStore } = useBillingServiceStore()
  const canEdit = authStore.hasPermission(Permission.WriteCommodities)

  const { closeSnackbar } = useSnackbar()

  const handleEditClick = (id: number) => {
    closeSnackbar()
    packageDialogUIStore.toggle(id)
  }

  return (
    <Stack gap={2} paddingY={4}>
      {packageListUIStore.packages.map(x => (
        <PackageCard
          key={x.id}
          packageItem={x}
          canEdit={canEdit}
          onClick={c => (canEdit ? handleEditClick(c.id) : false)}
        />
      ))}
      {!packageListUIStore.packages.length && (
        <NoListResult
          result={t('noPackageFound', 'No package found')}
          filtering={!!packageListUIStore.searchPackage}
          onClearFilter={() => packageListUIStore.setSearchPackage('')}
        />
      )}
    </Stack>
  )
})
