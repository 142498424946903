import { ApproveHoldsCommand, CreateHoldCommand, HoldsApi } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'

class HoldService {
  httpClient = createApiClient(HoldsApi)

  async create(cmd: CreateHoldCommand) {
    await this.httpClient.post(cmd)
  }

  async approve(holdIds: number[]) {
    await this.httpClient.approveHolds({ ids: holdIds } as ApproveHoldsCommand)
  }
}

const holdService = new HoldService()

export default holdService
