import { useStores } from '@storage/hooks/use-stores.hook'
import { Box } from '@mui/material'
import { useContainerTurnoversStores } from '@storage/pages/container-turnovers/hooks/container-turnovers-stores.hook'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { YardViewStore } from '../stores/YardViewStore'
import { StacksAllocationStore } from '../stores/stacks-allocation.store'
import { PlanningInformation } from './PlanningInformation'
import { ContainerTurnoversFilterDto } from '@storage/app/api'
import {
  mapContainerTurnoversFilterDtoToFormProfile,
  mapQueryParamsToFilterDto,
} from '@storage/pages/container-turnovers/components/container-turnovers-filter-form/container-turnovers-filter-form.mapper'
import isEmpty from 'lodash/isEmpty'
import { YardFilterForm } from './YardFilterForm'

interface Props {
  yardViewStore: YardViewStore
  stacksAllocationStore: StacksAllocationStore
  yardManagementQueryParams: Record<string, string>
}

export const YardStateToolbar = observer(
  ({ yardViewStore, stacksAllocationStore, yardManagementQueryParams }: Props) => {
    const { containerTurnoverStore, yardBlockStackStore } = useStores()
    const { containerTurnoversFilterStore, containerTurnoversListStore } =
      useContainerTurnoversStores()

    useEffect(() => {
      if (!isEmpty(yardManagementQueryParams)) {
        const containerTurnoverFilterDto: ContainerTurnoversFilterDto =
          mapQueryParamsToFilterDto(yardManagementQueryParams)

        // Sync the advanced filter
        containerTurnoversFilterStore.setFilter(
          mapContainerTurnoversFilterDtoToFormProfile(containerTurnoverFilterDto),
        )
        // Filter the yard
        yardBlockStackStore.yardAdvancedFilter(
          mapContainerTurnoversFilterDtoToFormProfile(containerTurnoverFilterDto),
        )
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      yardBlockStackStore.yardAdvancedFilter(containerTurnoversFilterStore.filter)
    }, [containerTurnoversFilterStore.filter, yardBlockStackStore])

    useEffect(() => {
      const loadContainers = async () => {
        await containerTurnoverStore.loadAll()
      }
      loadContainers()
      const dispose = containerTurnoversListStore.loadContainersEffect()
      return () => dispose()
    }, [containerTurnoverStore, containerTurnoversListStore])

    return (
      <Box
        sx={{
          position: 'absolute',
          top: 15,
          left: 15,
          padding: 1,
          gap: 2,
        }}
      >
        {yardViewStore.isPlanning && (
          <PlanningInformation
            filter={yardViewStore.planningStore.filter}
            numberOfContainers={yardViewStore.planningStore.numberOfContainersMatchingFilter}
            numberOfSelectedSlots={stacksAllocationStore.numberOfAllocatedStacks}
          />
        )}

        <Box sx={{ width: '700px' }}>
          <YardFilterForm
            filter={containerTurnoversFilterStore.filter}
            advancedFiltersShown={containerTurnoversFilterStore.showAdvancedFilters}
            onToggleAdvancedFilters={() =>
              containerTurnoversFilterStore.toggleShowAdvancedFilters()
            }
            onSubmit={formValues => containerTurnoversFilterStore.setFilter(formValues)}
          />
        </Box>
      </Box>
    )
  },
)
