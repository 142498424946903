import { CommodityDto, PackageDto } from '@billing/app/api'
import { AttributesContainer } from '@billing/components/AttributesContainer/AttributesContainer'
import { getPackageName } from '@billing/utils/helper'
import { Card, CardHeader, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { Tile } from '@tom-ui/ui'
import { CommodityAttributes } from './CommodityAttributes'

interface Props {
  commodity: CommodityDto
  packages: PackageDto[]
  canEdit: boolean
  onClick: (commodity: CommodityDto) => void
}
export const Commodity = ({ commodity, packages, canEdit, onClick }: Props) => {
  const { t } = useTranslate()

  return (
    <Card onClick={() => onClick(commodity)} sx={{ cursor: canEdit ? 'pointer' : undefined }}>
      <CardHeader
        title={<Typography variant='h4'>{commodity.name}</Typography>}
        sx={{ paddingBottom: theme => theme.customSpacing.m }}
      />

      {(commodity.attributes.length > 0 || packages.length > 0) && (
        <AttributesContainer>
          <CommodityAttributes attributes={commodity.attributes} />
          {packages.length > 0 && (
            <Tile
              label={t('packages', 'Packages')}
              value={packages.map(x => getPackageName(x)).join(', ')}
            />
          )}
        </AttributesContainer>
      )}
    </Card>
  )
}
