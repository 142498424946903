import { createSvgIcon } from '@mui/material'

export const MoreIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <circle cx='12' cy='12' r='2' fill='currentColor' />
    <circle cx='12' cy='5' r='2' fill='currentColor' />
    <circle cx='12' cy='19' r='2' fill='currentColor' />
  </svg>,
  'MoreIcon',
)
