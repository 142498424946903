import {
  Alert,
  Box,
  Breakpoint,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { ReactNode } from 'react'
import { ConfirmationActions, ConfirmationProps } from './ConfirmationActions'

interface Props extends ConfirmationProps {
  children?: ReactNode
  open: boolean
  title: string
  blockClickOutside?: boolean
  message?: string
  warning?: string
  warningPosition?: 'top' | 'bottom'
  maxWidth?: Breakpoint
  fullScreen?: boolean
}

export const ConfirmationDialog = ({
  children,
  open,
  title,
  blockClickOutside,
  message,
  warning,
  warningPosition = 'top',
  maxWidth,
  fullScreen,
  formId,
  primaryActionText,
  primaryActionDisabled,
  primaryActionDataCy,
  secondaryActionText,
  secondaryActionColor,
  secondaryActionDisabled,
  secondaryType,
  closeLabel,
  mobile,
  hasBorderTop,
  rightRenderOption,
  onClose,
  onConfirm,
  onSecondaryAction,
}: Props) => {
  return (
    <Dialog
      onClose={!blockClickOutside ? onClose : undefined}
      open={open}
      fullWidth={!!maxWidth}
      maxWidth={maxWidth ?? 'md'}
      fullScreen={fullScreen}
    >
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        {!!warning && warningPosition == 'top' && (
          <Box mb={2}>
            <Alert severity='warning'>{warning}</Alert>
          </Box>
        )}
        {!!message && <DialogContentText>{message}</DialogContentText>}
        {children}
        {!!warning && warningPosition == 'bottom' && (
          <Box mb={2}>
            <Alert severity='warning'>{warning}</Alert>
          </Box>
        )}
      </DialogContent>

      <ConfirmationActions
        formId={formId}
        primaryActionText={primaryActionText}
        primaryActionDisabled={primaryActionDisabled}
        primaryActionDataCy={primaryActionDataCy}
        secondaryActionText={secondaryActionText}
        secondaryActionColor={secondaryActionColor}
        secondaryActionDisabled={secondaryActionDisabled}
        secondaryType={secondaryType}
        closeLabel={closeLabel}
        mobile={mobile}
        hasBorderTop={hasBorderTop}
        rightRenderOption={rightRenderOption}
        onClose={onClose}
        onConfirm={onConfirm}
        onSecondaryAction={onSecondaryAction}
      />
    </Dialog>
  )
}
