import { Box, Button, Typography } from '@mui/material'
import { AdvancedSearchField } from '@storage/components/AdvancedSearchField'
import { ControlledTextField } from '@storage/components/form-controls'
import { TenantConfigStore } from '@storage/stores/tenantConfig.store'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { Fragment } from 'react'
import { Control, FormState } from 'react-hook-form'
import {
  CarrierTypeFilterControls,
  DestinationControls,
  GeneralAttributesControls,
  HandlingInstructionsControls,
  LocationStatusControls,
} from './components'
import { GrossWeightControls } from './components/GrossWeightControls'
import {
  ContainerTurnoversFilterFormProfile,
  ContainerTurnoversFilterFormProfileKey,
} from './container-turnovers-filter-form.profile'

export const ContainerTurnoversAdvancedFilterFormId = 'ContainerTurnoversAdvancedFilterForm'

export enum CTAdvanceFilterSection {
  CarrierTypeFilterControls = 'CarrierTypeFilterControls',
  LocationStatusControls = 'LocationStatusControls',
  GeneralAttributesControls = 'GeneralAttributesControls',
  DestinationControls = 'DestinationControls',
  ContainerOperatorAndGrossWeightControls = 'ContainerOperatorAndGrossWeightControls',
  HandlingInstructionsControls = 'HandlingInstructionsControls',
}

interface CTAdvancedSearchFieldProps {
  control: Control<ContainerTurnoversFilterFormProfile, any>
  formState: FormState<ContainerTurnoversFilterFormProfile>
  disabledFilters?: ContainerTurnoversFilterFormProfileKey[]
  hideSections?: CTAdvanceFilterSection[]
  handleToggleAdvancedFilters: () => void
  advancedFiltersShown: boolean
  tenantConfigStore?: TenantConfigStore
}

export const CTAdvancedSearchField = observer(
  ({
    control,
    formState,
    disabledFilters,
    handleToggleAdvancedFilters,
    advancedFiltersShown,
    hideSections,
    tenantConfigStore,
  }: CTAdvancedSearchFieldProps) => {
    const { t } = useTranslate()

    const sections = [
      {
        key: CTAdvanceFilterSection.CarrierTypeFilterControls,
        element: <CarrierTypeFilterControls disabledFilters={disabledFilters} />,
      },
      {
        key: CTAdvanceFilterSection.LocationStatusControls,
        element: <LocationStatusControls tenantConfigStore={tenantConfigStore} />,
      },
      {
        key: CTAdvanceFilterSection.GeneralAttributesControls,
        element: <GeneralAttributesControls />,
      },
      {
        key: CTAdvanceFilterSection.DestinationControls,
        element: <DestinationControls />,
      },
      {
        key: CTAdvanceFilterSection.ContainerOperatorAndGrossWeightControls,
        element: (
          <Box display='flex' flexDirection='row' sx={{ gap: 1, maxWidth: '75%' }}>
            <Box flex={2}>
              <Typography variant='h6' mb={1}>
                {t('containerOperator', 'Container Operator')}
              </Typography>
              <ControlledTextField
                name='containerOperator'
                formState={formState}
                fullWidth
                control={control}
                label={t('containerOperator', 'Container Operator')}
              />
            </Box>
            <GrossWeightControls sx={{ flex: 2 }} />
          </Box>
        ),
      },
      {
        key: CTAdvanceFilterSection.HandlingInstructionsControls,
        element: <HandlingInstructionsControls />,
      },
    ]

    const isSectionHidden = (section: CTAdvanceFilterSection) => hideSections?.includes(section)

    return (
      <AdvancedSearchField
        control={control}
        name='containerOrReferenceNumber'
        placeholder={t(
          'searchByContainerOrReferenceNumberPlaceholder',
          'Search by container or reference number or use the advanced filter',
        )}
        filtersButtonTooltip={t('advancedFilter', 'Advanced Filter')}
        actions={
          <>
            <Button
              form={ContainerTurnoversAdvancedFilterFormId}
              type='reset'
              variant='outlined'
              color='inherit'
            >
              {t('reset', 'Reset')}
            </Button>

            <Button
              form={ContainerTurnoversAdvancedFilterFormId}
              type='submit'
              variant='contained'
              color='primary'
            >
              {t('apply', 'Apply')}
            </Button>
          </>
        }
        toggleDropDown={handleToggleAdvancedFilters}
        showDropDown={advancedFiltersShown}
      >
        {sections
          .filter(section => !isSectionHidden(section.key))
          .map(section => (
            <Fragment key={section.key}>{section.element}</Fragment>
          ))}
      </AdvancedSearchField>
    )
  },
)
