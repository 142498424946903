import { action, makeObservable, observable } from 'mobx'
import { Issue, IssueSeverityType } from './issue.store'
import { SeverityType } from './snackbar.store'

export interface AppAlert {
  key: string
  message: string
  severity: SeverityType
  date: Date
  onClickFix?: () => void
}

/**
 * A MobX store that manages the state and operations of alert notifications.
 * It provides functionality to show and hide alerts, check for the existence of alerts,
 * clear all alerts, and convert issues into alerts. Alerts are stored in an observable array,
 * allowing reactive components to update automatically when the alert state changes.
 */
export class AlertStore {
  alerts: AppAlert[] = []

  constructor() {
    makeObservable(this, {
      alerts: observable,
      showAlert: action,
      hideAlert: action,
      clearAlerts: action,
    })
  }

  showAlert(key: string, message: string, severity: SeverityType = 'warning') {
    const existingAlertIndex = this.alerts.findIndex(alert => alert.key === key)

    if (existingAlertIndex !== -1) {
      const existingAlert = this.alerts[existingAlertIndex]

      if (existingAlert.message !== message || existingAlert.severity !== severity) {
        existingAlert.message = message
        existingAlert.severity = severity
      }
    } else {
      const newAlert: AppAlert = {
        key,
        message,
        severity,
        date: new Date(),
      }
      this.alerts.push(newAlert)
    }
  }

  static getAlertsFromIssues(issues: Issue[]) {
    const alerts: AppAlert[] = issues.map(i => ({
      key: i.id,
      message: i.description,
      severity: AlertStore.mapIssueSeverityTypeToAlertSeverityType(i.severity),
      date: i.reportedAt,
    }))
    return alerts
  }

  hideAlert(key: string) {
    const index = this.alerts.findIndex(alert => alert.key === key)
    if (index !== -1) {
      this.alerts.splice(index, 1)
    }
  }

  getAlerts() {
    return this.alerts.slice().sort((a, b) => b.date.getTime() - a.date.getTime())
  }

  doesAlertExist(key: string) {
    return this.alerts.findIndex(alert => alert.key === key) !== -1
  }

  clearAlerts() {
    this.alerts = []
  }

  static mapIssueSeverityTypeToAlertSeverityType(
    issueSeverityType: IssueSeverityType,
  ): SeverityType {
    switch (true) {
      case issueSeverityType === 'minor':
        return 'info'
      case issueSeverityType === 'moderate':
        return 'warning'
      case issueSeverityType === 'critical':
        return 'error'
      default:
        return 'info'
    }
  }
}
