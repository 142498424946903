import { EquipmentType } from '@storage/app/api'
import { SelectOption } from '@storage/app/models'
import { tolgee } from '@storage/app/translation'
import { useMemo } from 'react'

export const useEquipmentTypesOptions = (): SelectOption[] =>
  useMemo(
    () =>
      Object.values(EquipmentType).map(value => {
        return {
          value: value,
          label: tolgee.t(value),
        }
      }),
    [],
  )
