import { ContainerSlotDto } from '@operations/app/api'
import IconDangerous from '@operations/assets/icons/handlingInstructions/IconDangerous'
import IconEmpty from '@operations/assets/icons/handlingInstructions/IconEmpty'
import IconFull from '@operations/assets/icons/handlingInstructions/IconFull'
import IconHolds from '@operations/assets/icons/handlingInstructions/IconHolds'
import IconOutOfGauge from '@operations/assets/icons/handlingInstructions/IconOutOfGauge'
import IconReefer from '@operations/assets/icons/handlingInstructions/IconReefer'
import IconRestowSvg from '@operations/assets/icons/handlingInstructions/IconRestowSvg'
import {
  hasActiveHolds,
  isDangerous,
  isEmpty,
  isFull,
  isOutOfGauge,
  isReefer,
} from '@operations/features/craneOperator/utils'

interface Props {
  size: number
  isRestow: boolean
  slotContainer?: ContainerSlotDto | null
}
export const SlotIcons = ({ size, slotContainer, isRestow }: Props) => {
  return (
    <>
      {isEmpty(slotContainer) && <IconEmpty size={size} />}
      {isFull(slotContainer) && <IconFull size={size} />}
      {isRestow && <IconRestowSvg size={size} />}
      {isReefer(slotContainer) && <IconReefer size={size} />}
      {isDangerous(slotContainer) && <IconDangerous size={size} />}
      {isOutOfGauge(slotContainer) && <IconOutOfGauge size={size} />}
      {hasActiveHolds(slotContainer) && <IconHolds size={size} />}
    </>
  )
}
