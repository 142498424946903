import { useStores } from '@storage/hooks/use-stores.hook'
import {
  Box,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { getHumanReadableDate } from '@storage/utils/date'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

const severityChipColors: Record<string, 'info' | 'warning' | 'error'> = {
  minor: 'info',
  moderate: 'warning',
  critical: 'error',
}

const statusChipColors: Record<string, 'default' | 'success'> = {
  open: 'default',
  resolved: 'success',
}

const YardPlanningIssues = observer(() => {
  const { t } = useTranslate()
  const { issueStore } = useStores()

  useEffect(() => {
    const loadIssues = () => {
      issueStore.loadAll()
    }

    loadIssues()
  }, [issueStore])

  return (
    <Box sx={{ width: '100%', height: '100%', p: 3 }}>
      <Typography variant='h4' pb={2}>
        {t('yardPlanningIssues', 'Yard Planning Issues')}
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>{t('id', 'Id')}</TableCell>
              <TableCell>{t('description', 'Description')}</TableCell>
              <TableCell>{t('severity', 'Severity')}</TableCell>
              <TableCell>{t('status', 'Status')}</TableCell>
              <TableCell>{t('reportedAt', 'Reported at')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {issueStore.issues.map(row => (
              <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{row.id}</TableCell>
                <TableCell component='th' scope='row'>
                  {row.description}
                </TableCell>
                <TableCell>
                  <Chip color={severityChipColors[row.severity]} label={row.severity} />
                </TableCell>
                <TableCell>
                  <Chip color={statusChipColors[row.status]} label={row.status} />
                </TableCell>
                <TableCell>{getHumanReadableDate(row.reportedAt)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
})

export default YardPlanningIssues
