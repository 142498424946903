import { CarrierVisitDto, CarrierVisitsApi } from '@operations/app/api'
import { createApiClient } from '@operations/app/http-client'
import { BaseEntityStore } from './base/BaseEntityStore'

export type RailVisitDto = CarrierVisitDto

export class RailVisitStore extends BaseEntityStore<RailVisitDto> {
  async loadOne(id: number) {
    const { data } = await createApiClient(CarrierVisitsApi).getOne(id)

    this.updateStoreItem(data, data.id)
  }
}
