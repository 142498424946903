import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useSyncQueryParams = (
  values: Record<string, string>,
  setter?: (queryParams: URLSearchParams) => void,
) => {
  const [queryParams, setQueryParams] = useSearchParams()

  useEffect(() => {
    setQueryParams(new URLSearchParams(values), {
      preventScrollReset: true,
      replace: true,
    })
  }, [values, setQueryParams])

  useEffect(() => {
    setter?.(queryParams)
  }, [queryParams, setter])
}
