import { observer } from 'mobx-react-lite'
import { LegendHeader } from '../components/Legend/LegendHeader'
import { CraneOperatorUIStore } from '../stores/crane-operator.ui-store'
import {
  hasActiveHolds,
  hasDischarge,
  hasLoading,
  hasTransit,
  isDangerous,
  isEmpty,
  isFlatRack,
  isFull,
  isHighCube,
  isOutOfGauge,
  isReefer,
} from '../utils'

export interface Props {
  uiStore: CraneOperatorUIStore
}

const ListViewLegendHeaderContainer = observer(({ uiStore }: Props) => {
  return (
    <LegendHeader
      legendOptions={{
        hasFull: uiStore.vesselBayshasContainer(isFull),
        hasEmpty: uiStore.vesselBayshasContainer(isEmpty),
        hasRestow: uiStore.vesselBayshasContainer(c => uiStore.isRestow(c)),
        hasDangerous: uiStore.vesselBayshasContainer(isDangerous),
        hasReefer: uiStore.vesselBayshasContainer(isReefer),
        hasOutOfGauge: uiStore.vesselBayshasContainer(isOutOfGauge),
        hasHighCube: uiStore.vesselBayshasContainer(isHighCube),
        // hasDryContainer: uiStore.itemBayhasContainer(isDryContainer), TODO: use this later
        hasDryContainer: true,
        hasFlatRack: uiStore.vesselBayshasContainer(isFlatRack),
        hasOtherCrane: uiStore.vesselBayshasContainer(c => uiStore.isOtherCrane(c)),
        hasTransit: uiStore.vesselBaysContain(hasTransit),
        hasLoading: uiStore.vesselBaysContain(hasLoading),
        hasDischarge: uiStore.vesselBaysContain(hasDischarge),
        hasActiveHolds: uiStore.vesselBayshasContainer(hasActiveHolds),
      }}
      loadPorts={uiStore.loadPorts}
    />
  )
})

export default ListViewLegendHeaderContainer
