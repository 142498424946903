import { Dialog } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { observer } from 'mobx-react-lite'

export const DialogStack = observer(() => {
  const { dialogStore } = usePlanningStore()

  return (
    <Dialog
      open={dialogStore.open}
      onClose={() => dialogStore.close()}
      maxWidth='lg'
      fullWidth
      PaperProps={{ sx: { minHeight: '90%' } }}
    >
      {dialogStore.view}
    </Dialog>
  )
})
