import { VesselDto } from '@planning/app/api'
import { EntityItem } from '../base/EntityItem'
import { IEntity } from '../types'

export type IVesselItem = IEntity<VesselDto>

export class VesselItem extends EntityItem<VesselDto> implements IVesselItem {
  constructor(id: number, data: VesselDto) {
    super(id, data)
  }
}
