import { WorkQueueActionType } from '@operations/app/api'
import { WorkInstructionStore } from '@operations/stores/WorkInstructionStore'
import { WorkQueueStore } from '@operations/stores/WorkQueueStore'
import { action, makeObservable, observable } from 'mobx'
import { WorkQueue, WorkQueueStackChange } from '../models/work-queue.model'
import { CraneSplitContainerUIStore } from './crane-split-container.ui-store'

export class CraneSplitDialogUIStore {
  vesselId?: number
  workQueueId?: number
  workQueue?: WorkQueue

  removeDialog = false
  resetDialog = false
  detailsDialog = false

  constructor(
    private workQueueStore: WorkQueueStore,
    private craneSplitContainerUIStore: CraneSplitContainerUIStore,
    private workInstructionStore: WorkInstructionStore,
  ) {
    makeObservable(this, {
      removeDialog: observable,
      resetDialog: observable,
      detailsDialog: observable,
      workQueueId: observable,
      workQueue: observable,
      vesselId: observable,

      toggleResetDialog: action,
      toggleRemoveDialog: action,
      toggleDetailsDialog: action,
      setVesselId: action,
      removeWorkQueue: action,
    })
  }

  public setVesselId(vesselId: number): void {
    if (this.vesselId != vesselId) this.vesselId = vesselId
  }

  public get hasWorkQueues() {
    return !!this.workQueueStore.items.length
  }

  public toggleRemoveDialog(workQueueId?: number) {
    this.workQueueId = workQueueId
    this.removeDialog = !this.removeDialog
  }

  public toggleResetDialog() {
    this.resetDialog = !this.resetDialog
  }

  public toggleDetailsDialog(workQueue?: WorkQueue) {
    this.workQueue = workQueue
    this.detailsDialog = !this.detailsDialog
  }

  public async removeWorkQueue() {
    if (this.workQueueId && this.vesselId) {
      const calculatedWorkQueue = this.craneSplitContainerUIStore.calculatedBays
        .flatMap(x => x.workQueues)
        .find(x => x.id === this.workQueueId)

      const previousWorkQueueDto = this.workQueueStore.items.find(
        x => x.id === calculatedWorkQueue?.previousInBayId,
      )

      if (!calculatedWorkQueue || !previousWorkQueueDto) {
        return
      }

      const workQueueWorkInstructions = this.workInstructionStore.items.filter(
        x => x.workQueueId === this.workQueueId,
      )
      const nextWorkQueueWorkInstructionIds =
        this.craneSplitContainerUIStore.getWorkInstructionsIdsByWorkQueueId(previousWorkQueueDto.id)

      const changes: WorkQueueStackChange[] = [
        {
          action: WorkQueueActionType.Edit,
          previous: {
            workQueue: { ...previousWorkQueueDto },
            workInstructionsIds: nextWorkQueueWorkInstructionIds,
          },
        },
        {
          action: WorkQueueActionType.Delete,
          previous: {
            workQueue: { ...this.workQueueStore.items.find(x => x.id === this.workQueueId)! },
            workInstructionsIds: workQueueWorkInstructions.map(wi => wi.id),
          },
        },
      ]

      workQueueWorkInstructions.forEach(x => (x.workQueueId = previousWorkQueueDto.id))

      previousWorkQueueDto.containersAmount += workQueueWorkInstructions.length
      previousWorkQueueDto.moves =
        this.craneSplitContainerUIStore.calculateMoves(previousWorkQueueDto)

      this.workQueueStore.updateStoreItem(previousWorkQueueDto, previousWorkQueueDto.id)

      changes[0].current = {
        workQueue: { ...previousWorkQueueDto },
        workInstructionsIds: [
          ...nextWorkQueueWorkInstructionIds,
          ...workQueueWorkInstructions.map(wi => wi.id),
        ],
      }

      this.workQueueStore.deleteStoreItem(this.workQueueId)

      this.craneSplitContainerUIStore.addWorkQueuesToStack(changes)
    }
  }

  public reset() {
    for (let i = this.craneSplitContainerUIStore.stackPointer - 1; i >= 0; i--) {
      this.craneSplitContainerUIStore.undo()
    }

    this.craneSplitContainerUIStore.clearWorkQueueStack()
  }
}
