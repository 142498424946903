import { ContainerTurnoversApiGetAllRequest } from '@storage/app/api'
import { ShiftedContainerDto } from '@storage/components/container-shiftings/SearchContainerAutoComplete'
import { containerTurnoverService } from '@storage/services'
import { action, makeObservable, observable, runInAction } from 'mobx'

export class ContainerAutoCompleteStore {
  filter = ''
  items: ShiftedContainerDto[] = []
  value: ShiftedContainerDto | null = null

  constructor() {
    makeObservable(this, {
      filter: observable,
      items: observable,
      value: observable,
      setFilter: action,
      setValue: action,
    })
  }

  setFilter(filter: string) {
    this.filter = filter
  }

  setValue(value: ShiftedContainerDto | null | string) {
    if (typeof value !== 'string') {
      this.value = value
    }
  }

  async fetchContainers(filter: string) {
    const containersList = await containerTurnoverService.getContainerPositionByNumber({
      containerOrReferenceNumber: filter,
    } as ContainerTurnoversApiGetAllRequest)

    runInAction(() => {
      this.items = containersList.map(x => ({
        id: x.containerTurnoverId,
        containerNumber: x.containerNumber,
        previousLocation: x.currentPosition!,
      }))
    })
  }
}
