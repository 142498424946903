import Permission from '@admin/app/models/permission.enum'
import { useAdminStore } from '@admin/AppProvider'
import { Box, Container, Tab, Tabs } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { ButtonWithDropdown, Header, PlusIcon } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { ContainerDamageLocationsContainer } from './ContainerDamageLocationsContainer'
import { ContainerDamageTypesContainer } from './ContainerDamageTypesContainer'

export const DamageCatalogueContainer = observer(() => {
  const { t } = useTranslate()
  const {
    containerDamageLocationStore,
    containerDamageLocationListUIStore,
    containerDamageLocationDialogUIStore,
    containerDamageTypeStore,
    containerDamageTypeListUIStore,
    containerDamageTypeDialogUIStore,
    authStore,
  } = useAdminStore()
  const canEdit = authStore.hasPermission(Permission.WriteDamageCatalogue)

  const [selectedTab, setSelectedTab] = useState<'location' | 'type'>('location')
  const { closeSnackbar } = useSnackbar()

  useEffect(() => {
    async function fetchData() {
      await Promise.all([containerDamageLocationStore.load(), containerDamageTypeStore.load()])
    }

    fetchData()
  }, [containerDamageLocationStore, containerDamageTypeStore])

  const handleAddContainerDamageLocationClick = () => {
    closeSnackbar()
    containerDamageLocationDialogUIStore.toggle()
  }

  const handleAddContainerDamageTypeClick = () => {
    closeSnackbar()
    containerDamageTypeDialogUIStore.toggle()
  }

  const handleSearch = (name: string) => {
    if (selectedTab === 'location') {
      containerDamageLocationListUIStore.setSearchField(name)
    } else {
      containerDamageTypeListUIStore.setSearchField(name)
    }
  }

  return (
    <>
      <Header
        title={t('damageCatalogue', 'Damage catalogue')}
        searchInputLabel={t('searchForA', 'Search for a {name}', {
          name: t(selectedTab).toLowerCase(),
        })}
        onSearchFieldChange={handleSearch}
        displaySearchIcon
        enableSearchField
        searchFilter={
          selectedTab === 'location'
            ? containerDamageLocationListUIStore.searchField
            : containerDamageTypeListUIStore.searchField
        }
        rightRenderOption={
          canEdit ? (
            <Box flex='none'>
              <ButtonWithDropdown
                label={t('addNew', 'Add new')}
                startIcon={<PlusIcon />}
                color='inherit'
                options={[
                  {
                    label: t('location', 'Location'),
                    onClick: handleAddContainerDamageLocationClick,
                  },
                  {
                    label: t('type', 'Type'),
                    onClick: handleAddContainerDamageTypeClick,
                  },
                ]}
              />
            </Box>
          ) : undefined
        }
      />
      <Container>
        <Tabs
          value={selectedTab}
          onChange={(_, value) => setSelectedTab(value)}
          aria-label='basic tabs example'
        >
          <Tab label={t('locations', 'locations')} value='location' />
          <Tab label={t('types', 'types')} value='type' />
        </Tabs>

        {selectedTab === 'location' && <ContainerDamageLocationsContainer />}
        {selectedTab === 'type' && <ContainerDamageTypesContainer />}
      </Container>
    </>
  )
})
