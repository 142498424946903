import { NonNumericOrderDto } from '@planning/app/api'
import { getContainerTypeParameters } from '@planning/components/ContainerTypesMap'
import { OrderStatusChip } from '@planning/components/OrderStatusChip'
import { GridColumn, StickyHeaderTable } from '@planning/components/PaginatedTable'
import { emptyIndicator } from '@planning/constants'
import { IOrderItem } from '@planning/rt-stores/order/OrderItem'
import { IPaginatedStoreWithItems } from '@planning/stores/PaginatedStore'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { NNROrderDetailsToolbar } from './NNROrderDetailsToolbar'
import { UnassignContainerButton } from './UnassignContainerButton'

interface Props {
  nnrOrder: NonNumericOrderDto
  orderStore: IPaginatedStoreWithItems<IOrderItem>
}

export const NNROrderDetailsTable = observer(({ nnrOrder, orderStore }: Props) => {
  const { t } = useTranslate()

  if (!nnrOrder) {
    return <></>
  }

  const getCarrierIdentifier = (order: IOrderItem) => {
    if (!order.visit) return emptyIndicator

    if ('vessels' in order.visit && order.visit.vessels) return order.visit.vessels[0].data.name
    if ('truck' in order.visit && order.visit.truck) return order.visit.truck.data.licensePlate

    return emptyIndicator
  }

  const columns: GridColumn<IOrderItem>[] = [
    {
      field: 'containerNumber',
      headerName: 'Container no',
      flex: 1,
      minWidth: 100,
      sortable: true,
      orderBy: 'containerNumber',
      renderCell: params => {
        return params.row.data.containerNumber ?? t('toBeAssigned', 'To be assigned')
      },
    },
    {
      field: 'containerLength',
      headerName: 'Length',
      flex: 1,
      minWidth: 100,
      valueGetter: params => {
        return params.row.data.containerLength
          ? `${params.row.data.containerLength}ft`
          : emptyIndicator
      },
      sortable: true,
      orderBy: 'containerLength',
    },
    {
      field: 'containerHeight',
      headerName: 'Height',
      flex: 1,
      minWidth: 100,
      valueGetter: params => {
        return params.row.data.containerHeight ? params.row.data.containerHeight : emptyIndicator
      },
      sortable: true,
      orderBy: 'containerHeight',
    },
    {
      field: 'containerType',
      headerName: 'Type',
      flex: 1,
      minWidth: 100,
      valueGetter: params => {
        return params.row.data.containerType
          ? getContainerTypeParameters(params.row.data.containerType, t)['label']
          : emptyIndicator
      },
      sortable: true,
      orderBy: 'containerType',
    },
    {
      field: 'containerIsoCode',
      headerName: 'ISO Code',
      flex: 1,
      minWidth: 100,
      valueGetter: params => {
        return params.row.data.containerIsoCode ? params.row.data.containerIsoCode : emptyIndicator
      },
      sortable: true,
      orderBy: 'containerIsoCode',
    },
    {
      field: 'carrierIdentifier',
      headerName: 'Carrier Identifier',
      flex: 1,
      minWidth: 120,
      valueGetter: params => getCarrierIdentifier(params.row),
      sortable: true,
      orderBy: 'carrierIdentifier',
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 120,
      valueGetter: params => params.row.data.status,
      renderCell: params => <OrderStatusChip status={params.row.data.status} />,
      sortable: true,
      orderBy: 'carrierIdentifier',
    },
    // TODO It is not clear yet, how MaxPayload will get into the system. Add after clarification.
    // {
    //   field: 'containerMaxPayload',
    //   headerName: 'Max. Payload',
    //   flex: 1,
    //   minWidth: 100,
    //   sortable: true,
    //   valueGetter: params => {
    //     const payload = params.row.containerMaxPayload;
    //     if (!payload) return '';

    //     return `${payload}kg`;
    //   },
    //   orderBy: 'containerMaxPayload',
    // },
    {
      field: 'actions',
      headerName: '',
      flex: 0.5,
      minWidth: 75,
      sortable: false,
      disableExport: true,
      renderCell: params => {
        return (
          <UnassignContainerButton
            order={params.row.data}
            isNNROrderClosed={nnrOrder.isClosed}
            isTruckVisitArrived
          />
        )
      },
    },
  ]

  return (
    <StickyHeaderTable
      store={orderStore}
      columns={columns}
      getRowId={(row: any) => row.id}
      toolBar={<NNROrderDetailsToolbar nnrOrder={nnrOrder} orderStore={orderStore} />}
      isExportable
      exportFileName={`${nnrOrder.shippingLine}-${nnrOrder.referenceNumber}`}
      rowsPerPageOptions={[10, 25, 50]}
    />
  )
})
