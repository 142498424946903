import { Grid } from '@mui/material'
import { CheckType } from '@planning/app/api'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { IInspectionRailCar, RailTallyViewStore } from '../../Stores/RailTallyViewStore'
import { RailCarCard } from '../RailCarCard'

interface IProps {
  railcars: IInspectionRailCar[]
  searchHighlight?: string
  operation?: CheckType
  store: RailTallyViewStore
  onSelectRailcar: (railcarId?: number) => void
  onDeleteRailcar?: (railcar: IInspectionRailCar) => void
}

// [RailTally] TODO: RailTally shows Load- and Discharge containers
export const InspectRailcarList: FC<IProps> = observer(
  ({ searchHighlight, railcars, operation, store, onSelectRailcar, onDeleteRailcar }) => {
    return (
      <Grid container marginTop={1.5} flexDirection={'column'} gap={1.5}>
        {railcars
          .filter(railcar => {
            if (operation === CheckType.DischargePreparation) {
              return !railcar.dischargeCheck
            }
            if (operation === CheckType.LoadPreparation) {
              return !railcar.loadCheck
            }
            if (operation === CheckType.DepartureCheck) {
              return !railcar.departureCheck
            }

            return !railcar.dischargeCheck && !railcar.loadCheck && !railcar.departureCheck
          })
          .filter(railcar => {
            if (store.confirmedRailcarIds.includes(railcar.railcarId)) return false

            if (
              store &&
              store.selectedRailTrackPosition &&
              store.selectedRailTrackPosition.data.checkinDate !== null
            ) {
              return railcar.railcarId !== store.selectedRailTrackPosition.data.railcarId
            }

            return railcar
          })
          .sort((a, b) => a.sequence - b.sequence)
          .map(railcar => (
            <Grid item key={`railcar-${railcar.name}`}>
              <RailCarCard
                sequence={railcar.sequence}
                containers={operation !== CheckType.LoadPreparation ? railcar.containers : []}
                operation={operation}
                railcar={railcar.name}
                searchHighlight={searchHighlight}
                onCheckIn={() => {
                  onSelectRailcar(railcar.railcarId)
                }}
                onDelete={onDeleteRailcar ? () => onDeleteRailcar(railcar) : undefined}
              />
            </Grid>
          ))}
      </Grid>
    )
  },
)
