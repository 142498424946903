import { ContainerBlockInfoDto } from '@storage/app/api'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { containerRoutingInfoService } from '@storage/services'

export class RoutingInfoStore {
  dropOffInfo: ContainerBlockInfoDto[] = []
  pickUpInfo: ContainerBlockInfoDto[] = []
  notPlannedWarnings = 0

  constructor() {
    makeObservable(this, {
      dropOffInfo: observable,
      pickUpInfo: observable,
      notPlannedWarnings: observable,
      incrementWarnings: action,
    })
  }

  async fetchDropOffInfo(containerNumbers: string[]) {
    const info = await containerRoutingInfoService.fetchContainerInfo(containerNumbers)

    runInAction(() => {
      this.dropOffInfo = info
      this.notPlannedWarnings = 0
    })
  }

  async fetchPickUpInfo(containerNumbers: string[]) {
    const info = await containerRoutingInfoService.fetchContainerInfo(containerNumbers)

    runInAction(() => {
      this.pickUpInfo = info
      this.notPlannedWarnings = 0
    })
  }

  incrementWarnings() {
    this.notPlannedWarnings++
  }
}
