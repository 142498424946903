import {
  CarrierVisitDirection,
  CreateGeneralCargoOrderCommand,
  GeneralCargoApi,
  UpdateGeneralCargoOrderCommand,
} from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'

class GeneralCargoService {
  httpClient = createApiClient(GeneralCargoApi)

  async getByCustomerOrReferenceNumber(filter?: string, direction?: CarrierVisitDirection) {
    const { data } = await this.httpClient.getByCustomerOrReferenceNumber(filter, direction)

    return data
  }

  async create(cmd: CreateGeneralCargoOrderCommand) {
    const { data } = await this.httpClient.post(cmd)

    return data
  }

  async update(id: number, cmd: UpdateGeneralCargoOrderCommand) {
    const { data } = await this.httpClient.put(id, cmd)

    return data
  }
}

const generalCargoService = new GeneralCargoService()

export default generalCargoService
