import { Box } from '@mui/material'
import { ReactNode } from 'react'
import { useMinimalsTheme } from '../../../hooks'
import { GroupingContextAction } from './GroupingContextMenu'
import GroupingItem from './GroupingItem'
import { Group, Id, Item } from './types'

interface IProps {
  props: any
  group: Group
  selectedItemIds: Id[]
  draggingItemId?: Id
  actions?: GroupingContextAction[]
  handleOpenMenu: (x: number, y: number) => void
  toggleSelection: (itemId: Id) => void
  toggleSelectionInGroup: (itemId: Id) => void
  multiSelectTo: (itemId: Id) => void
  renderData?: (item: Item, group: Group) => ReactNode
  onSequenceChange: (groupId: Id, item: Item, previousSequence: number, newSequence: number) => void
}

type ItemIdMap = {
  [taskId: Id]: true
}

const getSelectedMap = (selectedItemIds: Id[]) =>
  selectedItemIds.reduce((previous: ItemIdMap, current: Id) => {
    previous[current] = true
    return previous
  }, {})

export const Row = ({
  props,
  group,
  selectedItemIds,
  draggingItemId,
  actions,
  handleOpenMenu,
  toggleSelection,
  toggleSelectionInGroup,
  multiSelectTo,
  renderData,
  onSequenceChange,
}: IProps) => {
  const { palette } = useMinimalsTheme()

  const { data, index, style } = props
  const item = data[index]

  if (!item) return <></>

  const itemSequence = (itemId: Id) => group.sequence[itemId] + 1

  const isSelected = item ? Boolean(getSelectedMap(selectedItemIds)[item.id]) : false
  const isGhosting = item && isSelected && Boolean(draggingItemId) && draggingItemId !== item.id

  return (
    <Box
      key={`grp-row-outer-${item.id}`}
      style={style}
      sx={{
        display: 'flex',
        gap: 1,
        alignItems: 'center',
        alignContent: 'center',
      }}
      onContextMenu={e => {
        e.preventDefault()

        if (selectedItemIds.find(id => id === item.id) === undefined) {
          toggleSelection(item.id)
        }

        handleOpenMenu(e.clientX, e.clientY + 20)
      }}
    >
      {group.sequenced && (
        <Box
          onClick={event => event.stopPropagation()}
          sx={{
            position: 'absolute',
            top: '0',
            left: '0',
            zIndex: 1,
            transform: 'translate(0, 50%)',
            marginLeft: '14px',
            width: '30px',
            height: '30px',
            borderRadius: '4px',
            padding: '0 4px',
            backgroundColor: palette.secondary.darker,
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            placeContent: 'center',
          }}
        >
          <input
            tabIndex={index + 1}
            onFocus={e => e.target.select()}
            onBlur={e => {
              let newSequence = parseInt(e.target.value)
              if (isNaN(newSequence)) return

              if (newSequence > group.ItemIds.length) {
                e.target.value = group.ItemIds.length.toString()
                newSequence = group.ItemIds.length
              }

              if (newSequence < 1) {
                e.target.value = '1'
                newSequence = 1
              }

              onSequenceChange(group.id, item, itemSequence(item.id), newSequence)
            }}
            type='number'
            defaultValue={itemSequence(item.id)}
            color='white'
            style={{
              color: 'white',
              backgroundColor: 'transparent',
              border: 'none',
              width: '100%',
              textAlign: 'center',
            }}
          />
        </Box>
      )}
      <Box sx={{ flexGrow: 2 }}>
        <GroupingItem
          actions={actions}
          group={group}
          item={item}
          index={index}
          isSelected={isSelected}
          isGhosting={isGhosting}
          selectionCount={selectedItemIds.length}
          toggleSelection={toggleSelection}
          toggleSelectionInGroup={toggleSelectionInGroup}
          multiSelectTo={multiSelectTo}
          renderData={renderData}
        />
      </Box>
    </Box>
  )
}
