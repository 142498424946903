import { createSvgIcon } from '@mui/material'

export const GeneralIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <mask id='path-1-inside-1_279_17532' fill='white'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4 5C2.89543 5 2 5.89543 2 7V17C2 18.1046 2.89543 19 4 19H20C21.1046 19 22 18.1046 22 17V7C22 5.89543 21.1046 5 20 5H4ZM11 15.5C11 16.0523 11.4477 16.5 12 16.5C12.5523 16.5 13 16.0523 13 15.5V8.5C13 7.94772 12.5523 7.5 12 7.5C11.4477 7.5 11 7.94772 11 8.5V15.5ZM6 16.5C5.44772 16.5 5 16.0523 5 15.5L5 8.5C5 7.94772 5.44772 7.5 6 7.5C6.55229 7.5 7 7.94772 7 8.5L7 15.5C7 16.0523 6.55228 16.5 6 16.5ZM17 15.5C17 16.0523 17.4477 16.5 18 16.5C18.5523 16.5 19 16.0523 19 15.5V8.5C19 7.94772 18.5523 7.5 18 7.5C17.4477 7.5 17 7.94772 17 8.5V15.5Z'
      />
    </mask>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4 5C2.89543 5 2 5.89543 2 7V17C2 18.1046 2.89543 19 4 19H20C21.1046 19 22 18.1046 22 17V7C22 5.89543 21.1046 5 20 5H4ZM11 15.5C11 16.0523 11.4477 16.5 12 16.5C12.5523 16.5 13 16.0523 13 15.5V8.5C13 7.94772 12.5523 7.5 12 7.5C11.4477 7.5 11 7.94772 11 8.5V15.5ZM6 16.5C5.44772 16.5 5 16.0523 5 15.5L5 8.5C5 7.94772 5.44772 7.5 6 7.5C6.55229 7.5 7 7.94772 7 8.5L7 15.5C7 16.0523 6.55228 16.5 6 16.5ZM17 15.5C17 16.0523 17.4477 16.5 18 16.5C18.5523 16.5 19 16.0523 19 15.5V8.5C19 7.94772 18.5523 7.5 18 7.5C17.4477 7.5 17 7.94772 17 8.5V15.5Z'
      fill='currentColor'
    />
    <path
      d='M5 15.5H4H5ZM5 8.5H6H5ZM7 8.5H8H7ZM7 15.5H6H7ZM3 7C3 6.44772 3.44772 6 4 6V4C2.34315 4 1 5.34315 1 7H3ZM3 17V7H1V17H3ZM4 18C3.44772 18 3 17.5523 3 17H1C1 18.6569 2.34315 20 4 20V18ZM20 18H4V20H20V18ZM21 17C21 17.5523 20.5523 18 20 18V20C21.6569 20 23 18.6569 23 17H21ZM21 7V17H23V7H21ZM20 6C20.5523 6 21 6.44772 21 7H23C23 5.34315 21.6569 4 20 4V6ZM4 6H20V4H4V6ZM12 15.5H10C10 16.6046 10.8954 17.5 12 17.5V15.5ZM12 15.5V17.5C13.1046 17.5 14 16.6046 14 15.5H12ZM12 8.5V15.5H14V8.5H12ZM12 8.5H14C14 7.39543 13.1046 6.5 12 6.5V8.5ZM12 8.5V6.5C10.8954 6.5 10 7.39543 10 8.5H12ZM12 15.5V8.5H10V15.5H12ZM4 15.5C4 16.6046 4.89543 17.5 6 17.5V15.5H4ZM4 8.5L4 15.5H6V8.5H4ZM6 6.5C4.89543 6.5 4 7.39543 4 8.5H6H6V6.5ZM8 8.5C8 7.39543 7.10457 6.5 6 6.5V8.5H8ZM8 15.5L8 8.5H6L6 15.5H8ZM6 17.5C7.10457 17.5 8 16.6046 8 15.5H6V17.5ZM18 15.5H16C16 16.6046 16.8954 17.5 18 17.5V15.5ZM18 15.5V17.5C19.1046 17.5 20 16.6046 20 15.5H18ZM18 8.5V15.5H20V8.5H18ZM18 8.5H20C20 7.39543 19.1046 6.5 18 6.5V8.5ZM18 8.5V6.5C16.8954 6.5 16 7.39543 16 8.5H18ZM18 15.5V8.5H16V15.5H18Z'
      fill='currentColor'
      mask='url(#path-1-inside-1_279_17532)'
    />
  </svg>,
  'GeneralIcon',
)
