import { Permission } from '@storage/app/models'
import { AddEntityButton } from '@storage/components/AddEntityButton'
import { useStores } from '@storage/hooks/use-stores.hook'
import { Box } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { BerthsList } from '../components/berths-list'
import { BerthsFeatureStore } from '../stores'

interface Props {
  featureStore: BerthsFeatureStore
}

export const BerthsListContainer = observer(
  ({ featureStore: { berthsList, berthsDialog } }: Props) => {
    const { berthStore, authStore } = useStores()

    const handleEditItem = (id: string) => {
      berthsDialog.toggle(id)
    }

    const handleDeleteItem = async (id: string) => {
      await berthStore.delete(id)
    }

    return (
      <Box>
        <BerthsList
          canWrite={authStore.hasPermission(Permission.WriteBerths)}
          items={berthsList.filteredBerths}
          onEditItem={handleEditItem}
          onDeleteItem={handleDeleteItem}
        />

        {authStore.hasPermission(Permission.WriteBerths) && (
          <AddEntityButton onAddEntity={() => berthsDialog.toggle()} />
        )}
      </Box>
    )
  },
)
