import { CreateWeightClassDto, WeightClassDto } from '@storage/app/api'
import { EntityStore } from '@storage/app/store/entity.store'
import { WeightClassService } from '@storage/services/weight-class.service'

export class WeightClassStore extends EntityStore<WeightClassDto> {
  constructor(private readonly _weightClassService: WeightClassService) {
    super()
  }

  public async loadAll(): Promise<void> {
    const weightClasses = await this._weightClassService.getAll()
    this.setAll(weightClasses)
  }

  public async add(createWeightClassDto: CreateWeightClassDto[]): Promise<void> {
    await this._weightClassService.create(createWeightClassDto)
  }
}
