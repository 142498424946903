import {
  YardBlockBayDto,
  YardBlockDto,
  YardBlockRowDto,
  YardBlockSlotLocation,
} from '@storage/app/api'
import { tolgee } from '@storage/app/translation'
import { TenantConfigStore } from '@storage/stores/tenantConfig.store'
import { YardBlockBayStore } from '@storage/stores/yard-block-bay.store'
import { YardBlockRowStore } from '@storage/stores/yard-block-row.store'
import { YardBlockStore } from '@storage/stores/yard-block.store'
import { action, computed, makeObservable, observable } from 'mobx'

interface TierOption {
  name: string
}

export interface Hint {
  type: string
  text: string
}

const sectionPart = ['yardBlocks.Blocks', 'yardBays.Bays', 'yardRows.Rows', 'tiers.Tiers']
export class ManualInputValidationStore {
  blockStore: YardBlockStore
  bayStore: YardBlockBayStore
  rowStore: YardBlockRowStore
  tenantConfigStore: TenantConfigStore

  block?: YardBlockDto
  bayMin?: YardBlockBayDto
  bayMax?: YardBlockBayDto
  rowMin?: YardBlockRowDto
  rowMax?: YardBlockRowDto
  tier?: number

  options: YardBlockDto[] | YardBlockBayDto[] | YardBlockRowDto[] | TierOption[] = []

  isInvalid = false
  isLocation = false
  isAllocation = false
  hint: Hint = { type: '', text: '' }
  isSingleContainer = false

  constructor(
    blockStore: YardBlockStore,
    bayStore: YardBlockBayStore,
    rowStore: YardBlockRowStore,
    tenantConfigStore: TenantConfigStore,
  ) {
    makeObservable(this, {
      setHint: action,
      setIsInvalid: action,
      setIsLocation: action,
      setIsAllocation: action,
      setIsSingleContainer: action,
      isInvalid: observable,
      isLocation: observable,
      isAllocation: observable,
      isSingleContainer: observable,
      isAccepted: computed,
      hint: observable,
      optionCount: computed,
    })

    this.blockStore = blockStore
    this.bayStore = bayStore
    this.rowStore = rowStore
    this.tenantConfigStore = tenantConfigStore
  }

  setHint(type: string, text: string) {
    this.hint.type = type
    this.hint.text = text
  }

  setIsInvalid(value: boolean) {
    this.isInvalid = value
  }

  setIsLocation(value: boolean) {
    this.isLocation = value
  }

  setIsAllocation(value: boolean) {
    this.isAllocation = value
  }

  setIsSingleContainer(value: boolean) {
    this.isSingleContainer = value
  }

  get isAccepted() {
    return !this.isInvalid && (this.isLocation || this.isAllocation)
  }

  get optionCount() {
    return this.options.length
  }

  public async init() {
    await this.blockStore.loadList()
    await this.bayStore.loadList()
    await this.rowStore.loadAll()
    await this.tenantConfigStore.loadAppConfigs()
  }

  public reset() {
    this.block = undefined
    this.bayMin = undefined
    this.bayMax = undefined
    this.rowMin = undefined
    this.rowMax = undefined
    this.tier = undefined

    this.setIsInvalid(false)
    this.setHint('', '')
  }

  private invalidate(sections: string[]) {
    const isValidInput = sections.length > 0 && !!this.options && this.options.length > 0
    this.setIsInvalid(!isValidInput)
    this.validateAllocation(sections)
    this.setIsLocation(
      !this.isInvalid && !!this.block && !!this.bayMin && !!this.rowMin && !!this.tier,
    )

    if (this.isInvalid) {
      this.setHint('illegalInput.Illegal input', '')
    } else {
      this.setHint(
        sectionPart[
          this.tenantConfigStore.isBayRowUniqueIdentificationModeActivated
            ? sections.length
            : sections.length - 1
        ],
        Array.from(new Set(this.options.map(e => e.name.toString()))).join(', '),
      )
    }
  }
  private isBlockBayRowModeAllocationValid(sections: string[]) {
    let valid = !this.isInvalid && !!this.block && sections.length <= 3 && !this.isLocation
    if (sections.length > 1) {
      valid = valid && !!this.bayMin

      if (sections[1].split('-').length > 1) {
        valid = valid && !!this.bayMax
      }
    }
    if (sections.length > 2) {
      valid = valid && !!this.rowMin

      if (sections[2].split('-').length > 1) {
        valid = valid && !!this.rowMax
      }
    }

    return valid
  }

  private isBayRowModeAllocationValid(sections: string[]) {
    let valid = !this.isInvalid && sections.length <= 2 && !this.isLocation
    if (sections.length > 0) {
      valid = valid && !!this.bayMin

      if (sections[0].split('-').length > 1) {
        valid = valid && !!this.bayMax
      }
    }
    if (sections.length > 1) {
      valid = valid && !!this.rowMin

      if (sections[1].split('-').length > 1) {
        valid = valid && !!this.rowMax
      }
    }

    return valid
  }

  private validateAllocation(sections: string[]) {
    let isValid

    if (this.tenantConfigStore.isBayRowUniqueIdentificationModeActivated) {
      isValid = this.isBayRowModeAllocationValid(sections)
    } else {
      isValid = this.isBlockBayRowModeAllocationValid(sections)
    }

    this.setIsAllocation(isValid)
  }

  private illegalSyntax() {
    this.setIsInvalid(true)
    this.setHint('illegalInput.Illegal input', '')
  }

  private validateBlock(block: string) {
    this.options =
      block.length > 0
        ? this.blockStore.entries.filter(e => e.name.toUpperCase().startsWith(block))
        : this.blockStore.entries
    if (block.length > 0) {
      this.block = this.blockStore.entries.find(e => e.name.toUpperCase() === block)
    }
  }

  private validateBays(bay: string) {
    const range = bay.split('-')

    if (this.tenantConfigStore.isBayRowUniqueIdentificationModeActivated) {
      if (range.length > 2) {
        this.illegalSyntax()
        return
      }
    } else if (!this.block || range.length > 2) {
      this.illegalSyntax()
      return
    }
    const value = range[0]
    this.options = this.tenantConfigStore.isBayRowUniqueIdentificationModeActivated
      ? this.bayStore.entries.filter(e => e.name.toUpperCase().startsWith(value))
      : this.bayStore.entries.filter(
          e => e.yardBlockId === this.block!.id && e.name.toUpperCase().startsWith(value),
        )

    if (value.length > 0) {
      this.bayMin = this.bayStore.entries.find(e => e.name.toUpperCase() === value)
    }
    if (range.length === 2) {
      if (!this.bayMin) {
        this.illegalSyntax()
        return
      }
      const value = range[1]
      this.options = this.tenantConfigStore.isBayRowUniqueIdentificationModeActivated
        ? this.bayStore.entries.filter(
            e =>
              e.sequenceNumber > this.bayMin!.sequenceNumber &&
              e.name.toUpperCase().startsWith(value),
          )
        : this.bayStore.entries.filter(
            e =>
              e.yardBlockId === this.block!.id &&
              e.sequenceNumber > this.bayMin!.sequenceNumber &&
              e.name.toUpperCase().startsWith(value),
          )

      if (value.length > 0) {
        this.bayMax = this.bayStore.entries.find(e => e.name.toUpperCase() === value)
      }
    }
  }

  private validateRows(bay: string, row: string) {
    const range = row.split('-')
    if (!this.bayMin || (bay.split('-').length > 1 && !this.bayMax) || range.length > 2) {
      this.illegalSyntax()
      return
    }

    const value = range[0]
    if (this.tenantConfigStore.isBayRowUniqueIdentificationModeActivated) {
      // find the block that has that bay
      const blockBay = this.bayStore.entries.find(e => e.name === this.bayMin?.name)
      this.block = this.blockStore.entries.find(e => e.id === blockBay?.yardBlockId)
    }

    this.options = this.rowStore.entries.filter(
      e => e.yardBlockId === this.block!.id && e.name.toUpperCase().startsWith(value),
    )
    if (value.length > 0) {
      this.rowMin = this.rowStore.entries.find(e => e.name.toUpperCase() === value)
    }

    if (range.length === 2) {
      if (!this.rowMin) {
        this.illegalSyntax()
        return
      }
      const value = range[1]
      this.options = this.rowStore.entries.filter(
        e =>
          e.yardBlockId === this.block!.id &&
          e.sequenceNumber > this.rowMin!.sequenceNumber &&
          e.name.toUpperCase().startsWith(value),
      )

      if (value.length > 0) {
        this.rowMax = this.rowStore.entries.find(e => e.name.toUpperCase() === value)
      }
    }
  }

  private validateTier(row: string, tier: string) {
    if (!this.rowMin || (row.split('-').length > 1 && !this.rowMax)) {
      this.illegalSyntax()
      return
    }
    const value = tier
    if (value.length > 0) {
      const val = parseInt(value)
      this.options =
        isNaN(val) || val < 1 || val > this.block!.maxTier ? [] : [{ name: val.toString() }]
    } else {
      this.options = Array.from({ length: this.block!.maxTier }, (_, i) => i + 1).map(e => {
        return {
          name: e.toString(),
        } as TierOption
      })
    }
    if (value.length > 0) {
      this.tier = parseInt(value)
    }
  }

  private validateBlockBayRowTierInput(sections: string[]) {
    this.validateBlock(sections[0])
    if (sections.length > 1) {
      this.validateBays(sections[1])
    }
    if (sections.length > 2) {
      this.validateRows(sections[1], sections[2])
    }

    if (sections.length > 3) {
      this.validateTier(sections[2], sections[3])
    }
  }

  private validateBayRowTierInput(sections: string[]) {
    this.validateBays(sections[0])

    if (sections.length > 1) {
      this.validateRows(sections[0], sections[1])
    }

    if (sections.length > 2) {
      this.validateTier(sections[1], sections[2])
    }
  }

  validate(input: string) {
    this.reset()

    if (input.length === 0) {
      return
    }

    const sections = input.split('.')

    if (this.tenantConfigStore.isBayRowUniqueIdentificationModeActivated) {
      if (sections.length > 3) {
        this.illegalSyntax()
        return
      }
      if (!this.isSingleContainer && sections.length > 2) {
        this.illegalSyntax()
        return
      }
      this.validateBayRowTierInput(sections)
    } else {
      if (sections.length > 4) {
        this.illegalSyntax()
        return
      }
      if (!this.isSingleContainer && sections.length > 3) {
        this.illegalSyntax()
        return
      }
      this.validateBlockBayRowTierInput(sections)
    }

    this.invalidate(sections)
  }

  locationSegments = (): YardBlockSlotLocation[] => {
    if (!this.isInvalid && this.isLocation) {
      const lastBay = this.bayMax ?? this.bayMin
      return this.bayStore
        .filterByYardBlock(this.block!.id)
        .sort((a, b) => a.sequenceNumber - b.sequenceNumber)
        .reduce(
          (list, bay) =>
            bay.sequenceNumber >= this.bayMin!.sequenceNumber &&
            bay.sequenceNumber <= lastBay!.sequenceNumber
              ? [
                  ...list,
                  {
                    block: this.block!.name,
                    bay: bay.name,
                    row: this.rowMin!.name,
                    tier: this.tier,
                  } as YardBlockSlotLocation,
                ]
              : list,

          [] as YardBlockSlotLocation[],
        )
    }

    return []
  }

  validateDGLocation = (isDangerous?: boolean | null, isAllocationTemplate = false) => {
    if (this.block) {
      if (
        this.block.usageOptions?.dangerousGoodsHandling === true &&
        (isDangerous === false || isDangerous === undefined)
      ) {
        return {
          isValidDangerousGoods: false,
          isDangerousAlertMessage: isAllocationTemplate
            ? tolgee.t(
                'dangerousLocationNonDangerousAllocationTemp',
                'You are trying to assign non-dangerous goods to a dangerous goods destination',
              )
            : tolgee.t(
                'dangerousLocationNonDangerousSelection',
                'You are trying to allocate non-dangerous goods to a dangerous goods location',
              ),
        }
      } else if (
        this.block.usageOptions?.dangerousGoodsHandling === false &&
        isDangerous === true
      ) {
        return {
          isValidDangerousGoods: false,
          isDangerousAlertMessage: isAllocationTemplate
            ? tolgee.t(
                'dangerousGoodsNonDangerousLocationAllocationTemp',
                'You are trying to assign dangerous goods to a non-dangerous goods destination',
              )
            : tolgee.t(
                'nonDangerousLocationDangerousSelection',
                'You are trying to allocate dangerous goods to a non-dangerous goods location',
              ),
        }
      } else {
        return {
          isValidDangerousGoods: true,
          isDangerousAlertMessage: '',
        }
      }
    }
    return {
      isValidDangerousGoods: true,
      isDangerousAlertMessage: '',
    }
  }
}
