import { ContainerDto, NonNumericOrdersApi } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'
import { axiosUnwrapper } from '@planning/app/utils'
import { PaginatedStore } from '@planning/stores/PaginatedStore'
import { makeObservable } from 'mobx'

export class NnrOrderContainerAssignmentPaginationStore extends PaginatedStore<ContainerDto> {
  constructor(nnrOrderId: number) {
    super(async (_, __, sortingModel, filter, showCompleted) => {
      return axiosUnwrapper(
        createApiClient(NonNumericOrdersApi).getSuitableContainers(
          nnrOrderId,
          1,
          25,
          nnrOrderId,
          filter,
          showCompleted,
          sortingModel.orderBy,
          sortingModel.isDescending,
        ),
      )
    })
    makeObservable(this, {})
  }
}
