import { Stack, TextField, Typography } from '@mui/material'
import { JobDto } from '@operations/app/api'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'

interface Props {
  job: JobDto
}
export const JobInformationOperationalInstructions = ({ job }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  return (
    job.order?.operationalInstructions && (
      <Stack gap={theme.customSpacing.m} marginY={theme.customSpacing.m}>
        <Typography variant='subtitle1'>
          {t('operationalInstructions', 'Operational instructions')}
        </Typography>

        <TextField
          label={t('instructions', 'Instructions')}
          value={job.order.operationalInstructions}
          disabled
          fullWidth
          multiline
          minRows={2}
          maxRows={4}
        />
      </Stack>
    )
  )
}
