import { useBillingServiceStore } from '@billing/AppProvider'
import carrierVisitsService from '@billing/services/CarrierVisitsService'
import { simplifyWeightUnit } from '@billing/utils/helper'
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useTranslate } from '@tolgee/react'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { DeliveryNoteInfoBox } from './DeliveryNoteInfoBox'

export const DeliveryNoteReport = observer(() => {
  const { t } = useTranslate()
  const { deliveryNoteReportUIStore } = useBillingServiceStore()

  useEffect(() => {
    async function fetchData() {
      deliveryNoteReportUIStore.setTruckVisitOrders(
        await carrierVisitsService.getTruckVisitOrders(
          +deliveryNoteReportUIStore.selectedTruckVisit!.id,
        ),
      )
      deliveryNoteReportUIStore.setTruckVisitSignatures(
        await carrierVisitsService.getTruckVisitSignatures(
          +deliveryNoteReportUIStore.selectedTruckVisit!.id,
        ),
      )
    }

    fetchData()
  }, [deliveryNoteReportUIStore])

  return (
    <Stack
      id='delivery-note-repo'
      width='600'
      sx={{ padding: 10 }}
      gap={theme => theme.customSpacing.m}
    >
      <Typography variant='h2'>Delivery Note</Typography>

      {deliveryNoteReportUIStore.tenant && (
        <DeliveryNoteInfoBox
          label={t('terminal', 'Terminal')}
          name={deliveryNoteReportUIStore.tenant.name}
          address={deliveryNoteReportUIStore.tenant.address}
        />
      )}

      {deliveryNoteReportUIStore.customersFromTruckVisitOrders.map(customer => (
        <DeliveryNoteInfoBox
          key={customer.id}
          label={t('customer', 'Customer')}
          name={customer.name}
          address={customer.address}
        />
      ))}

      <Typography variant='subtitle1'>
        {t('truck', 'Truck')}: {deliveryNoteReportUIStore.selectedTruckVisit?.carrierName}
        {' ('}
        {t('ata', 'ATA')}{' '}
        {dayjs(deliveryNoteReportUIStore.selectedTruckVisit?.ata)
          .toDate()
          .toLocaleString([], { dateStyle: 'short', timeStyle: 'short' })}
        {')'}
      </Typography>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow>
              <TableCell>{t('nameOfGoods', 'Name Of Goods')}</TableCell>
              <TableCell>{t('package', 'Package')}</TableCell>
              <TableCell>{t('lotNumber', 'Lot number')}</TableCell>
              <TableCell align='right'>{t('quantity', 'Quantity')}</TableCell>
              <TableCell align='right'>{t('weight', 'Weight')}</TableCell>
              <TableCell align='right'>
                {t('orderReferenceNumber', 'Order Reference Number')}
              </TableCell>
              <TableCell align='right'> {t('handlingDirection', 'Handling Direction')}</TableCell>
              <TableCell align='right'>{t('customerName', 'Customer Name')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {deliveryNoteReportUIStore.truckVisitOrders?.map(d => (
              <TableRow key={d.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component='th' scope='row'>
                  {d.nameOfGoods}
                </TableCell>
                <TableCell component='th' scope='row'>
                  {d.packageName ?? ' - '}
                </TableCell>
                <TableCell component='th' scope='row'>
                  {d.lotNumber ?? ' - '}
                </TableCell>
                <TableCell align='right'>{d.quantity}</TableCell>
                <TableCell align='right'>
                  {`${d.grossWeight} ${simplifyWeightUnit(d.weightMeasurementUnit)}`}
                </TableCell>
                <TableCell align='right'>{d.referenceNumber}</TableCell>
                <TableCell align='right'>{d.handlingDirection}</TableCell>
                <TableCell align='right'>{d.customerName}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <Stack direction={'row'} gap={theme => theme.customSpacing.m} justifyContent='space-between'>
        <Stack gap={theme => theme.customSpacing.m}>
          <Typography variant='body1'>{t('operatorSignature', 'Operator Signature')}:</Typography>
          {deliveryNoteReportUIStore.truckVisitSignatures?.operatorSignature ? (
            <Stack gap={theme => theme.customSpacing.m}>
              <img
                width='250'
                alt='Operator Signature'
                src={deliveryNoteReportUIStore.truckVisitSignatures!.operatorSignature}
              />
              {deliveryNoteReportUIStore.truckVisitSignatures!.operatorName} <br />
              {dayjs(
                deliveryNoteReportUIStore.truckVisitSignatures!.operatorSignatureCreatedTimestamp,
              )
                .toDate()
                .toLocaleString([], { dateStyle: 'short', timeStyle: 'short' })}
            </Stack>
          ) : (
            <Typography variant='overline'>{t('noSignature', 'XXX No Signature XXX')}</Typography>
          )}
        </Stack>
        <Stack gap={theme => theme.customSpacing.m}>
          <Typography variant='body1'>{t('driverSignature', 'Driver Signature')}:</Typography>
          {deliveryNoteReportUIStore.truckVisitSignatures?.driverSignature ? (
            <Stack gap={theme => theme.customSpacing.m}>
              <img
                alt='Driver Signature'
                width='250'
                src={deliveryNoteReportUIStore.truckVisitSignatures!.driverSignature}
              />
              {dayjs(
                deliveryNoteReportUIStore.truckVisitSignatures!.driverSignatureCreatedTimestamp,
              )
                .toDate()
                .toLocaleString([], { dateStyle: 'short', timeStyle: 'short' })}
            </Stack>
          ) : (
            <Typography variant='overline'>{t('noSignature', 'XXX No Signature XXX')}</Typography>
          )}
        </Stack>
      </Stack>
    </Stack>
  )
})
