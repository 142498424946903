import { Box, Stack, Typography, useTheme } from '@mui/material'
// import { ArrowForward } from '@mui/icons-material'
import { useTranslate } from '@tolgee/react'

interface AllocationRuleSummaryProps {
  nbrOfContainers?: number
  nbrOfSlots?: number
}
const AllocationRuleSummary = ({ nbrOfContainers, nbrOfSlots }: AllocationRuleSummaryProps) => {
  const theme = useTheme()
  const { t } = useTranslate()

  return (
    <Stack
      bgcolor={`${theme.palette.info.main}14`}
      direction='row'
      color={theme.palette.info.main}
      justifyContent='space-between'
      borderRadius={theme.customRadius.small}
      padding={`${theme.customSpacing.xxs} ${theme.customSpacing.xs}`}
      width='fit-content'
    >
      <Typography variant='body2' component='div'>
        <Box component='span' fontWeight='bold'>
          {nbrOfContainers}
        </Box>{' '}
        {t('AllocationRuleSummaryContainers', 'containers')}
      </Typography>
      {/* <ArrowForward sx={{ mx: 2 }} />
      <Typography variant='body2' component='div'>
        <Box component='span' fontWeight='bold'>
          {nbrOfSlots}
        </Box>{' '}
        {t('AllocationRuleSummaryContainersSlotsAllocated', 'slots allocated')}
      </Typography> */}
    </Stack>
  )
}

export default AllocationRuleSummary
