import { action, makeObservable, observable, runInAction } from 'mobx'
import { LocalStorageService } from '@storage/services/local-storage.service'
import {
  DashboardPreferences,
  DashboardWidgetsVisibilityFormProfile,
} from '../components/dashboard-preferences-form/dashboard-preferences-form.profile'

/**
 * Dashboard Store
 *
 * Represents the store for managing dashboard preferences and widgets visibility.
 * It allows toggling the dashboard's visibility & preferences through editing mode.
 * The preferences are stored and retrieved from local storage.
 */
export class ContainerTurnoversDashboardStore implements DashboardPreferences {
  isActive = true
  isInEditMode = false

  isYardCapacityWidgetVisible = true
  isContainerAttributesWidgetVisible = true
  isVesselVisitsWidgetVisible = true

  localStorageService: LocalStorageService<DashboardPreferences>
  readonly localStorageKey = '__app_dashboardPreferences'

  constructor() {
    this.localStorageService = new LocalStorageService(this.localStorageKey)
    this.init()
    makeObservable(this, {
      isActive: observable,
      isInEditMode: observable,
      isYardCapacityWidgetVisible: observable,
      isContainerAttributesWidgetVisible: observable,
      isVesselVisitsWidgetVisible: observable,
      toggleDashboard: action,
      toggleEditMode: action,
      setWidgetsVisibility: action,
    })
  }

  init() {
    runInAction(() => {
      const storedData = this.localStorageService.getData()
      if (storedData) {
        this.isActive = storedData.isActive
        this.setWidgetsVisibility(storedData)
      }
    })
  }

  public toggleDashboard() {
    // If not widget is visible > toggle edit mode
    if (!this.isYardCapacityWidgetVisible && !this.isContainerAttributesWidgetVisible) {
      this.toggleEditMode()
    }

    this.isActive = !this.isActive
    this.localStorageService.saveData({
      ...this.getDefaultWidgetVisibilityValues(),
      isActive: this.isActive,
    })
  }

  public toggleEditMode() {
    this.isInEditMode = !this.isInEditMode
  }

  public setWidgetsVisibility(preferences: DashboardWidgetsVisibilityFormProfile) {
    this.isYardCapacityWidgetVisible = preferences.isYardCapacityWidgetVisible
    this.isContainerAttributesWidgetVisible = preferences.isContainerAttributesWidgetVisible
    this.isVesselVisitsWidgetVisible = preferences.isVesselVisitsWidgetVisible

    // If all widgets are hidden > make dashboard not visible
    const isActive =
      preferences.isYardCapacityWidgetVisible || preferences.isContainerAttributesWidgetVisible

    this.isActive = isActive

    this.localStorageService.saveData({ ...preferences, isActive })
  }

  public getDefaultWidgetVisibilityValues(): DashboardWidgetsVisibilityFormProfile {
    return {
      isYardCapacityWidgetVisible: this.isYardCapacityWidgetVisible,
      isContainerAttributesWidgetVisible: this.isContainerAttributesWidgetVisible,
      isVesselVisitsWidgetVisible: this.isVesselVisitsWidgetVisible,
    }
  }
}
