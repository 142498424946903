import { useBillingServiceStore } from '@billing/AppProvider'
import { Asterisk } from '@billing/components/asterisk/Asterisk'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { Box, Stack } from '@mui/system'
import { MobileDatePicker } from '@mui/x-date-pickers'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

export const HandlingPreBillingReportFilter = observer(() => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const { handlingServicesPrebillingUIStore, reportsUIStore, customerStore } =
    useBillingServiceStore()
  const localFormat = dayjs.Ls[dayjs.locale()].formats.L

  useEffect(() => {
    async function fetchData() {
      await customerStore.load()
    }

    fetchData()
  }, [customerStore])

  const customerInputHasError =
    reportsUIStore.nextButtonWasBlocked && !handlingServicesPrebillingUIStore.customerId

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }} gap={theme.customSpacing.m}>
      <FormControl required error={customerInputHasError}>
        <InputLabel id='customer-select-label'>{t('customer', 'Customer')}</InputLabel>
        <Select
          labelId='customer-select-label'
          value={handlingServicesPrebillingUIStore.customerId}
          label={t('customer', 'Customer')}
          onChange={(event: SelectChangeEvent) =>
            handlingServicesPrebillingUIStore.setCustomerId(event.target.value)
          }
        >
          {handlingServicesPrebillingUIStore.customerOptions.map(c => (
            <MenuItem key={c.value} value={c.value}>
              {c.label}
            </MenuItem>
          ))}
        </Select>
        {customerInputHasError && (
          <FormHelperText>{t('thisFieldIsRequired', 'This field is required')}</FormHelperText>
        )}
      </FormControl>
      <Stack gap={theme.customSpacing.xs}>
        <Typography variant='subtitle2'>
          {t('period', 'Period')} {Asterisk}
        </Typography>
        <Stack
          gap={theme.customSpacing.m}
          sx={{
            [theme.breakpoints.up('sm')]: {
              flexDirection: 'row',
            },
          }}
        >
          <MobileDatePicker
            label={t('from', 'From')}
            value={handlingServicesPrebillingUIStore.from}
            onChange={newValue => handlingServicesPrebillingUIStore.setFrom(newValue)}
            disableFuture={true}
            maxDate={handlingServicesPrebillingUIStore.to}
            slotProps={{
              actionBar: {
                actions: ['clear', 'accept', 'today'],
              },
              textField: {
                required: true,
                error:
                  reportsUIStore.nextButtonWasBlocked && !handlingServicesPrebillingUIStore.from
                    ? true
                    : undefined,
                helperText: localFormat,
              },
            }}
            sx={{
              width: '100%',
              [theme.breakpoints.up('sm')]: {
                width: '268px',
              },
            }}
          />
          <MobileDatePicker
            label={t('to', 'To')}
            value={handlingServicesPrebillingUIStore.to}
            onChange={newValue => handlingServicesPrebillingUIStore.setTo(newValue)}
            disableFuture={true}
            minDate={handlingServicesPrebillingUIStore.from}
            slotProps={{
              actionBar: {
                actions: ['clear', 'accept', 'today'],
              },
              textField: {
                required: true,
                error:
                  reportsUIStore.nextButtonWasBlocked && !handlingServicesPrebillingUIStore.to
                    ? true
                    : undefined,
                helperText: localFormat,
              },
            }}
            sx={{
              width: '100%',
              [theme.breakpoints.up('sm')]: {
                width: '268px',
              },
            }}
          />
        </Stack>
      </Stack>
    </Box>
  )
})
