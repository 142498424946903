import { Box, Typography, styled } from '@mui/material'
import { translateEnumValue } from '@operations/utils/enum-extensions'
import { formatStringDate } from '@operations/utils/format-date'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { EquipmentPlanningRailUIStore } from '../../stores/equipment-planning-rail-ui-store'

interface Props {
  uiStore: EquipmentPlanningRailUIStore
}

const FieldLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 14,
}))

const FieldValue = styled(Typography)(() => ({
  fontSize: 14,
}))

const ContentCard = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '2rem',
  [theme.breakpoints.down('sm')]: {
    gap: '1rem',
    justifyContent: 'space-between',
  },
  alignItems: 'center',
  border: '1px solid rgba(145, 158, 171, 0.24)',
  borderRadius: '8px',
  padding: '10px',
}))

const ContentTitle = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 600,
}))

const ContentBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
}))

export const EquipmentPlanningRailHeader = observer(({ uiStore }: Props) => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()

  const carrierVisit = uiStore.railVisitStore.items.find(item => item.id === uiStore.railVisitId)

  return (
    <>
      {carrierVisit && (
        <Box
          sx={{
            display: 'flex',
            gap: '2vw',
            justifyContent: 'space-between',
            padding: '1rem',
            backgroundColor: '#F4F6F8',
            [theme.breakpoints.down('sm')]: {
              gap: '1rem',
              flexDirection: 'column',
              justifyContent: 'space-between',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              gap: '.2rem',
            }}
          >
            <ContentTitle>{carrierVisit?.carrierName ?? '-'}</ContentTitle>

            <Box
              sx={{
                backgroundColor: 'rgba(24, 144, 255, 0.16)',
                color: '#0C53B7',
                padding: '1px 8px',
                borderRadius: '6px',
                width: 'auto',
                marginTop: '.3rem',
              }}
            >
              <Typography fontWeight={600} fontSize={12}>
                {t(carrierVisit?.status, translateEnumValue(carrierVisit?.status)) ?? '-'}
              </Typography>
            </Box>
          </Box>

          <ContentBox>
            <ContentTitle>{t('timings')}</ContentTitle>
            <ContentCard>
              <Box>
                <FieldLabel>{t('eta')}</FieldLabel>
                <FieldValue>{formatStringDate(carrierVisit?.eta)}</FieldValue>
              </Box>
              <Box>
                <FieldLabel>{t('etd')}</FieldLabel>
                <FieldValue>{formatStringDate(carrierVisit?.etd)}</FieldValue>
              </Box>
            </ContentCard>
          </ContentBox>
        </Box>
      )}
    </>
  )
})
