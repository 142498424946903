import { Theme } from '@mui/system'

export const overwriteMinimalTableHeadCellStyle = {
  sx: {
    backgroundColor: 'rgb(244, 246, 248)',
  },
}

export const overwriteMinimalTableDetailPanelStyle = {
  sx: (theme: Theme) => ({
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
  }),
}

export const overwriteMinimalTableBodyCellStyle = {
  sx: (theme: Theme) => ({
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
  }),
}

export const overwriteMinimalTableBodyRowStyle = (isExpanded: boolean) => ({
  sx: (theme: Theme) => ({
    backgroundColor: isExpanded ? theme.palette.grey[200] : 'current',
  }),
})
