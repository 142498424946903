import { createSvgIcon } from '@mui/material'

export const Yard2Icon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M2 3C2 2.44772 2.38851 2 2.86777 2H10.6777C11.1569 2 11.5455 2.44772 11.5455 3V7C11.5455 7.55228 11.1569 8 10.6777 8H2.86777C2.38851 8 2 7.55228 2 7V3Z'
      fill='currentColor'
    />
    <path
      d='M2 10C2 9.44772 2.38851 9 2.86777 9H10.6777C11.1569 9 11.5455 9.44772 11.5455 10V14C11.5455 14.5523 11.1569 15 10.6777 15H2.86777C2.38851 15 2 14.5523 2 14V10Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.77273 13.9999C6.5331 13.9999 6.33884 13.776 6.33884 13.4999V10.4999C6.33884 10.2238 6.5331 9.9999 6.77273 9.9999C7.01236 9.9999 7.20661 10.2238 7.20661 10.4999V13.4999C7.20661 13.776 7.01236 13.9999 6.77273 13.9999Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.90841 13.9999C3.66879 13.9999 3.47453 13.776 3.47453 13.4999L3.47453 10.4999C3.47453 10.2238 3.66879 9.9999 3.90841 9.9999C4.14804 9.9999 4.3423 10.2238 4.3423 10.4999L4.3423 13.4999C4.3423 13.776 4.14804 13.9999 3.90841 13.9999Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.63704 13.9999C9.39741 13.9999 9.20316 13.776 9.20316 13.4999V10.4999C9.20316 10.2238 9.39741 9.9999 9.63704 9.9999C9.87667 9.9999 10.0709 10.2238 10.0709 10.4999V13.4999C10.0709 13.776 9.87667 13.9999 9.63704 13.9999Z'
      fill='white'
    />
    <path
      d='M2 17C2 16.4477 2.38851 16 2.86777 16H10.6777C11.1569 16 11.5455 16.4477 11.5455 17V21C11.5455 21.5523 11.1569 22 10.6777 22H2.86777C2.38851 22 2 21.5523 2 21V17Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.77273 20.9999C6.5331 20.9999 6.33884 20.776 6.33884 20.4999V17.4999C6.33884 17.2238 6.5331 16.9999 6.77273 16.9999C7.01236 16.9999 7.20661 17.2238 7.20661 17.4999V20.4999C7.20661 20.776 7.01236 20.9999 6.77273 20.9999Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.90841 20.9999C3.66879 20.9999 3.47453 20.776 3.47453 20.4999L3.47453 17.4999C3.47453 17.2238 3.66879 16.9999 3.90841 16.9999C4.14804 16.9999 4.3423 17.2238 4.3423 17.4999L4.3423 20.4999C4.3423 20.776 4.14804 20.9999 3.90841 20.9999Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.63704 20.9999C9.39741 20.9999 9.20316 20.776 9.20316 20.4999V17.4999C9.20316 17.2238 9.39741 16.9999 9.63704 16.9999C9.87667 16.9999 10.0709 17.2238 10.0709 17.4999V20.4999C10.0709 20.776 9.87667 20.9999 9.63704 20.9999Z'
      fill='white'
    />
    <path
      d='M12.4545 17C12.4545 16.4477 12.8431 16 13.3223 16H21.1322C21.6115 16 22 16.4477 22 17V21C22 21.5523 21.6115 22 21.1322 22H13.3223C12.8431 22 12.4545 21.5523 12.4545 21V17Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.2273 20.9999C16.9876 20.9999 16.7934 20.776 16.7934 20.4999V17.4999C16.7934 17.2238 16.9876 16.9999 17.2273 16.9999C17.4669 16.9999 17.6612 17.2238 17.6612 17.4999V20.4999C17.6612 20.776 17.4669 20.9999 17.2273 20.9999Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.363 20.9999C14.1233 20.9999 13.9291 20.776 13.9291 20.4999V17.4999C13.9291 17.2238 14.1233 16.9999 14.363 16.9999C14.6026 16.9999 14.7968 17.2238 14.7968 17.4999L14.7968 20.4999C14.7968 20.776 14.6026 20.9999 14.363 20.9999Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M20.0916 20.9999C19.852 20.9999 19.6577 20.776 19.6577 20.4999V17.4999C19.6577 17.2238 19.852 16.9999 20.0916 16.9999C20.3312 16.9999 20.5255 17.2238 20.5255 17.4999V20.4999C20.5255 20.776 20.3312 20.9999 20.0916 20.9999Z'
      fill='white'
    />
    <path
      d='M12.4545 10C12.4545 9.44772 12.8431 9 13.3223 9H21.1322C21.6115 9 22 9.44772 22 10V14C22 14.5523 21.6115 15 21.1322 15H13.3223C12.8431 15 12.4545 14.5523 12.4545 14V10Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.2273 13.9999C16.9876 13.9999 16.7934 13.776 16.7934 13.4999V10.4999C16.7934 10.2238 16.9876 9.9999 17.2273 9.9999C17.4669 9.9999 17.6612 10.2238 17.6612 10.4999V13.4999C17.6612 13.776 17.4669 13.9999 17.2273 13.9999Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.363 13.9999C14.1233 13.9999 13.9291 13.776 13.9291 13.4999V10.4999C13.9291 10.2238 14.1233 9.9999 14.363 9.9999C14.6026 9.9999 14.7968 10.2238 14.7968 10.4999L14.7968 13.4999C14.7968 13.776 14.6026 13.9999 14.363 13.9999Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M20.0916 13.9999C19.852 13.9999 19.6577 13.776 19.6577 13.4999V10.4999C19.6577 10.2238 19.852 9.9999 20.0916 9.9999C20.3312 9.9999 20.5255 10.2238 20.5255 10.4999V13.4999C20.5255 13.776 20.3312 13.9999 20.0916 13.9999Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.77281 6.99991C6.53318 6.99991 6.33892 6.77605 6.33892 6.49991V3.49991C6.33892 3.22377 6.53318 2.99991 6.77281 2.99991C7.01244 2.99991 7.20669 3.22377 7.20669 3.49991V6.49991C7.20669 6.77605 7.01244 6.99991 6.77281 6.99991Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.90849 6.99991C3.66887 6.99991 3.47461 6.77605 3.47461 6.49991L3.47461 3.49991C3.47461 3.22377 3.66887 2.99991 3.90849 2.99991C4.14812 2.99991 4.34238 3.22377 4.34238 3.49991V6.49991C4.34238 6.77605 4.14812 6.99991 3.90849 6.99991Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.63712 6.99991C9.39749 6.99991 9.20324 6.77605 9.20324 6.49991V3.49991C9.20324 3.22377 9.39749 2.99991 9.63712 2.99991C9.87675 2.99991 10.071 3.22377 10.071 3.49991V6.49991C10.071 6.77605 9.87675 6.99991 9.63712 6.99991Z'
      fill='white'
    />
  </svg>,
  'Yard2Icon',
)
