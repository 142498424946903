import { AllInclusive } from '@mui/icons-material'
import {
  CheckboxProps,
  Chip,
  FormControl,
  FormHelperText,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { SelectOption, WeightClassSelectOption } from '@storage/app/models'
import { useTranslate } from '@tolgee/react'
import { SuccessIcon, useMinimalsTheme } from '@tom-ui/ui'
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
  FormState,
  Path,
  UseControllerProps,
} from 'react-hook-form'

type FieldProps = Pick<CheckboxProps, 'disabled'>

interface Props<T extends FieldValues> extends UseControllerProps<T>, FieldProps {
  formState: FormState<T>
  options?: (SelectOption | WeightClassSelectOption)[]
  label?: string
  hasTooltip?: boolean
}

export const ControlledMultipleChipSelect = <T extends FieldValues>({
  name,
  control,
  label,
  options,
  formState: { errors },
  hasTooltip,
}: Props<T>) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const isSelected = (field: any, option: SelectOption) => {
    return field.value?.some((x: any) => x === option.value)
  }

  const handleChipClick = (field: ControllerRenderProps<T, Path<T>>, option: SelectOption) => {
    const newValue = field.value ? [...field.value] : []

    if (newValue.length > 0) {
      const index = newValue.findIndex(x => x === option.value)

      if (index === -1) {
        newValue.push(option.value)
      } else {
        newValue.splice(index, 1)
      }
    } else {
      newValue.push(option.value)
    }

    field.onChange(newValue)
  }

  const handleChipDelete = (field: ControllerRenderProps<T, Path<T>>, option: SelectOption) => {
    return isSelected(field, option)
      ? () => {
          const newValue = [...field.value]

          if (newValue.length > 0) {
            const index = newValue.findIndex(x => x === option.value)

            if (index >= 0) {
              newValue.splice(index, 1)
            }
          }

          field.onChange(newValue)
        }
      : undefined
  }

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <FormControl component='fieldset' variant='standard' fullWidth>
            {label && (
              <Typography variant='subtitle2' marginBottom={theme.customSpacing.m}>
                {label}
              </Typography>
            )}
            <Stack flexDirection='row' flexWrap='wrap' gap={theme.customSpacing.xs}>
              {options?.map(option =>
                hasTooltip ? (
                  <Tooltip
                    title={
                      <Typography sx={{ fontSize: '0.8rem' }}>
                        {(option as WeightClassSelectOption).minWeight} -{' '}
                        <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                          {(option as WeightClassSelectOption).maxWeight ?? (
                            <AllInclusive sx={{ fontSize: '0.6rem' }} />
                          )}
                        </span>
                      </Typography>
                    }
                    key={option.value}
                    placement='top'
                  >
                    <Chip
                      label={option.label}
                      onClick={() => {
                        handleChipClick(field, option)
                      }}
                      color={isSelected(field, option) ? 'primary' : 'default'}
                      onDelete={handleChipDelete(field, option)}
                      icon={isSelected(field, option) ? <SuccessIcon /> : undefined}
                    ></Chip>
                  </Tooltip>
                ) : (
                  <Chip
                    key={option.value}
                    label={option.label}
                    onClick={() => {
                      handleChipClick(field, option)
                    }}
                    color={isSelected(field, option) ? 'primary' : 'default'}
                    onDelete={handleChipDelete(field, option)}
                    icon={isSelected(field, option) ? <SuccessIcon /> : undefined}
                  ></Chip>
                ),
              )}
              {!options?.length && (
                <Typography variant='body2' color='secondary'>
                  {t(
                    'noYardBlockAvailableForEquipmentType',
                    'No yard block available for this type of equipment',
                  )}
                </Typography>
              )}
            </Stack>
          </FormControl>
        )}
      />
      {errors[name] && <FormHelperText>{errors[name]?.message as string}</FormHelperText>}
    </>
  )
}
