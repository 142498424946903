import { DragIndicator } from '@mui/icons-material'
import { Box, Stack } from '@mui/system'
import { useMinimalsTheme } from '@tom-ui/ui'
import React from 'react'

interface ListItemSelectionIndicatorProps {
  selected?: boolean
  dragHandleProps?: any
}

const ListItemSelectionIndicator: React.FC<ListItemSelectionIndicatorProps> = ({
  selected,
  dragHandleProps,
}) => {
  const { palette } = useMinimalsTheme()

  return (
    <Stack direction='row' {...dragHandleProps}>
      <Box
        sx={{
          width: '4px',
          flex: 1,
          bgcolor: selected ? palette.info.main : 'transparent',
          transition: 'background-color 0.3s',
        }}
      />
      <Box>
        <DragIndicator sx={{ mt: 0.5 }} />
      </Box>
    </Stack>
  )
}

export default ListItemSelectionIndicator
