import { DrawerFormContainer } from '@tom-ui/ui'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { ProcessingTime } from '../components/ProcessingTime/ProcessingTime'
import { ProcessingTimeFormProfile } from '../components/ProcessingTime/processing-time-form.profile'
import { SplitForm } from '../components/SplitForm/SplitForm'
import { SplitFormProfile } from '../components/SplitForm/split-form.profile'
import { WorkQueueForm } from '../components/WorkQueueForm/WorkQueueForm'
import { WorkQueueFormProfile } from '../components/WorkQueueForm/workqueue-form.profile'
import { CraneSplitDialogUIStore } from '../stores/crane-split-dialog.ui-store'
import { CraneSplitDrawerUIStore } from '../stores/crane-split-drawer.ui-store'

interface Props {
  uiStore: CraneSplitDrawerUIStore
  uiDialogStore: CraneSplitDialogUIStore
}

export const CraneSplitDrawerContainer = observer(({ uiStore }: Props) => {
  const { t } = useTranslate()

  const handleProcessingTimeSubmit = (processingTime: ProcessingTimeFormProfile) => {
    uiStore.setProcessingTime(processingTime.processingTime)
    uiStore.toggleProcessingTime()
  }

  const handleSplitSubmit = (splitWorkQueue: SplitFormProfile) => {
    uiStore.splitWorkQueue(splitWorkQueue)
    uiStore.toggleSplit()
  }

  const handleCalculateMoves = async (twinContainers?: boolean, newContainersAmount?: number) => {
    return uiStore.craneSplitContainerUIStore.calculateMoves(
      uiStore.workQueue!,
      twinContainers,
      newContainersAmount,
    )
  }

  const handleWorkQueueSubmit = async (workQueue: WorkQueueFormProfile) => {
    await uiStore.editWorkQueue(workQueue)
    uiStore.toggleEditDrawer()
  }

  return (
    <>
      <DrawerFormContainer
        open={uiStore.processingTimeIsOpen}
        title='Edit processing time'
        primaryActionLabel={t('save', 'Save')}
        closeActionLabel={t('close', 'Close')}
        formId='processing-time-form'
        onClose={() => uiStore.toggleProcessingTime()}
      >
        <ProcessingTime
          id='processing-time-form'
          processingTime={uiStore.processingTime}
          onSubmit={handleProcessingTimeSubmit}
        />
      </DrawerFormContainer>

      <DrawerFormContainer
        open={uiStore.splitIsOpen}
        title='Split work queue'
        primaryActionLabel={t('save', 'Save')}
        closeActionLabel={t('close', 'Close')}
        formId='split-form'
        onClose={() => uiStore.toggleSplit()}
      >
        <SplitForm
          id='split-form'
          workQueue={uiStore.workQueue!}
          onSubmit={handleSplitSubmit}
          onCalculateMoves={handleCalculateMoves}
        />
      </DrawerFormContainer>

      <DrawerFormContainer
        open={uiStore.isOpen}
        title={t('edit', 'Edit')}
        primaryActionLabel={t('save', 'Save')}
        closeActionLabel={t('close', 'Close')}
        formId='workqueue-form'
        onClose={() => uiStore.toggle()}
      >
        <WorkQueueForm
          id='workqueue-form'
          onSubmit={handleWorkQueueSubmit}
          workQueueDefaultValues={uiStore.workQueueDefaultValues}
          craneOptions={uiStore.equipmentOptions}
          workQueueBoundary={uiStore.workQueue?.boundary}
          operationTypeOptions={uiStore.operationTypeOptions}
          onCalculateMoves={handleCalculateMoves}
          isUnknownBay={uiStore.workQueue?.isUnknownBay}
        />
      </DrawerFormContainer>
    </>
  )
})
