import { Box, IconButton, Stack, TextField, Tooltip } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { FilterIcon, FilterPopUp, Header } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { IssuesViewStore } from '../Stores/IssuesViewStore'

interface Props {
  store: IssuesViewStore
}

export const IssuesPageHeader = observer(({ store }: Props) => {
  const { t } = useTranslate()

  const [openFilter, setOpenFilter] = useState<boolean>(false)

  return (
    <Header
      title={t('issues', 'Issues')}
      rightRenderOption={
        <Stack flexDirection='row' alignItems='center' gap={2}>
          <Box width='300px'>
            <TextField
              label={t('searchContainerNumber', 'Search container number')}
              sx={{ width: '100%', marginTop: 0 }}
              data-cy='issues-page-search-field'
              onChange={event => store.setFilter(event.currentTarget.value)}
            />
          </Box>

          <Box flex='none'>
            <Tooltip title={t('filter', 'Filter')}>
              <IconButton onClick={() => setOpenFilter(true)} size='small' sx={{ ml: 2 }}>
                <FilterIcon />
              </IconButton>
            </Tooltip>
            <FilterPopUp
              open={openFilter}
              onClose={() => {
                console.log('closed')
                setOpenFilter(false)
              }}
              filterActionLabel={t('showResults', 'Show results')}
              resetActionLabel={t('reset', 'Reset')}
              onFilter={() => {
                console.log('filtered')
                setOpenFilter(false)
              }}
              onReset={() => {
                console.log('reseted')
                setOpenFilter(false)
              }}
            >
              <>WIP</>
            </FilterPopUp>
          </Box>
        </Stack>
      }
    />
  )
})
