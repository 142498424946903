import { Alert, Container, Typography } from '@mui/material'
import { useOperationsStore } from '@operations/AppProvider'
import { useTranslate } from '@tolgee/react'
import { BackButton, Header } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { WorkInstructionJobList } from '../JobList/WorkInstructionJobList'

interface Props {
  handleSearch: (newValue: string) => void
  handleReturn: () => void
}
export const WorkInstructionSearchContent = observer(({ handleSearch, handleReturn }: Props) => {
  const { t } = useTranslate()

  const { equipmentOperatorWorkInstructionsUIStore, equipmentOperatorSearchUIStore } =
    useOperationsStore()

  return (
    <>
      <Header
        leftRenderOption={<BackButton tooltip={t('return', 'Return')} onGoBack={handleReturn} />}
        searchInputType='text'
        onSearchFieldChange={handleSearch}
        enableSearchField={!equipmentOperatorSearchUIStore.selectedJobWorkInstructionIds}
        searchInputLabel={t('searchFor', 'Search for {label}', {
          label: t(
            'aCommodityOrRefNumberOrLicensePlateOrContainerNumber',
            'a commodity / lot number / ref. number / truck license plate / container number',
          ),
        })}
      />
      <Container sx={{ paddingY: theme => theme.customSpacing.m }}>
        {!equipmentOperatorSearchUIStore.isSearching && (
          <Alert severity='info'>
            <Typography variant='subtitle2'>
              {t('hintTryTheNameOfTheCommodity', 'Hint, try the name of the commodity')}
            </Typography>
          </Alert>
        )}

        {equipmentOperatorSearchUIStore.isSearching &&
          equipmentOperatorWorkInstructionsUIStore.searchedAndFilteredJobs.flatMap(x => x.jobs)
            .length === 0 && (
            <Alert severity='warning'>
              <Typography variant='subtitle2'>
                {t('noMatchingResults', 'No matching results')}
              </Typography>
            </Alert>
          )}

        <WorkInstructionJobList
          jobGroups={equipmentOperatorWorkInstructionsUIStore.searchedAndFilteredJobs}
          highlightText={equipmentOperatorSearchUIStore.searchText}
        />
      </Container>
    </>
  )
})
