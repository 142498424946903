import { Box, Stack, Typography } from '@mui/material'
import { RailTrackResponseDto } from '@planning/app/api'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'

interface Props {
  railTracks?: RailTrackResponseDto[]
  emptySpaceHeight: number
  railTrackHeight: number
}
export const RailTrackAxis = observer(
  ({ railTracks, railTrackHeight, emptySpaceHeight }: Props) => {
    const theme = useMinimalsTheme()
    const { t } = useTranslate()

    return (
      <>
        {railTracks?.map((railTrack, i) => {
          return (
            <Stack key={'railTracks_' + i}>
              <Stack
                gap={theme.customSpacing.xxs}
                height={`${railTrackHeight}px`}
                py={theme.customSpacing.m}
                px={theme.customSpacing.s}
                overflow='hidden'
              >
                <Typography variant='h6'>{railTrack.name}</Typography>
                {railTrackHeight > 50 && (
                  <Typography variant='body2'>
                    {t('length', 'Length')}: {railTrack.length ?? '-'}m
                  </Typography>
                )}
              </Stack>

              {i < railTracks.length - 1 && (
                <Box
                  sx={{
                    height: `${emptySpaceHeight}px`,
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    borderTop: `1px solid ${theme.palette.divider}`,
                  }}
                ></Box>
              )}
            </Stack>
          )
        })}
      </>
    )
  },
)
