import { Loader } from '@tom-ui/ui'
import { FC, ReactElement } from 'react'

type Props = {
  isInitializing: boolean
  children?: ReactElement
}

export const InitializationWrapper: FC<Props> = ({ isInitializing, children }) => {
  return ((isInitializing && <Loader show />) || <>{children}</>) as ReactElement
}
