import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { ContainerTurnoversDashboardStore } from '../stores/container-turnovers-dashboard.store'
import { DashboardPreferencesForm } from './dashboard-preferences-form/DashboardPreferencesForm'
import { DashboardWidgetsVisibilityFormProfile } from './dashboard-preferences-form/dashboard-preferences-form.profile'
import { ConfirmationDialog } from '@tom-ui/ui'
import { dashboardPreferencesFormId } from './dashboard-preferences-form/dashboard-preferences-form.scheme'

interface DashboardPreferencesDialog {
  dashboardStore: ContainerTurnoversDashboardStore
}
const DashboardPreferencesDialog = observer(({ dashboardStore }: DashboardPreferencesDialog) => {
  const { t } = useTranslate()

  const handleSubmit = (formValues: DashboardWidgetsVisibilityFormProfile) => {
    dashboardStore.setWidgetsVisibility(formValues)
    dashboardStore.toggleEditMode()
  }
  return (
    <ConfirmationDialog
      open={dashboardStore.isInEditMode}
      title={t('dashboardWidgetsPreferences', 'Dashboard Widgets Preferences')}
      onClose={() => dashboardStore.toggleEditMode()}
      primaryActionText={t('apply', 'Apply')}
      closeLabel={t('cancel', 'Cancel')}
      message={t(
        'widgetPreferencesDialogMessage',
        'Select a section you want to appear as a widget',
      )}
      formId={dashboardPreferencesFormId}
    >
      <DashboardPreferencesForm
        id={dashboardPreferencesFormId}
        defaultValues={dashboardStore.getDefaultWidgetVisibilityValues()}
        onSubmit={handleSubmit}
      />
    </ConfirmationDialog>
  )
})

export default DashboardPreferencesDialog
