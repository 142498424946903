import { UserDto } from '@admin/app/api'
import Permission from '@admin/app/models/permission.enum'
import { Box, Button, Container, IconButton, Stack, Tooltip } from '@mui/material'
import { BinIcon, EditIcon, Header, PlusIcon, UsersIcon, useMinimalsTheme } from '@tom-ui/ui'
import { useTranslate } from '@tolgee/react'
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { useAdminStore } from '@admin/AppProvider'
import { overwriteMinimalTableHeadCellStyle } from '@admin/utils/minimal-theme-overwrite'

export const UsersContainer = observer(() => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const { userStore, userUIStore, userDrawerUIStore, userDialogUIStore, authStore } =
    useAdminStore()

  const canManageTenant = authStore.hasPermission(Permission.WriteTenants)
  const canEdit = authStore.hasPermission(Permission.WriteUsers)

  const columns = useMemo<MRT_ColumnDef<UserDto>[]>(
    () => [
      {
        accessorKey: 'email',
        header: t('email', 'Email'),
        size: 15,
      },
      {
        accessorKey: 'given_name',
        header: t('firstName', 'First Name'),
        size: 5,
      },
      {
        accessorKey: 'family_name',
        header: t('lastName', 'Last Name'),
        size: 5,
      },
      {
        accessorKey: 'tenant_id',
        header: t('tenantId', 'Tenant Id'),
        size: 2,
      },
    ],
    [t],
  )

  const handleAssign = async (userId: string) => {
    await userStore.fetchUserRoles(userId)

    userDialogUIStore.toggleAssignRoles(userId)
  }

  return (
    <>
      <Header
        title={t('users', 'Users')}
        searchInputLabel={t('searchForAName', 'Search for a name')}
        onSearchFieldChange={value => userUIStore.setSearchUser(value)}
        displaySearchIcon
        enableSearchField
        rightRenderOption={
          <Stack flexDirection='row' gap={theme.customSpacing.xs}>
            {canEdit && (
              <Button
                color='inherit'
                variant='contained'
                sx={{ flex: 'none' }}
                startIcon={<PlusIcon />}
                onClick={() => userDrawerUIStore.toggle()}
              >
                {t('addNew', 'Add new')}
              </Button>
            )}
          </Stack>
        }
      />

      <Container
        sx={{
          paddingY: theme.customSpacing.m,
        }}
      >
        <MaterialReactTable
          muiTableHeadCellProps={overwriteMinimalTableHeadCellStyle}
          columns={columns}
          data={userUIStore.users}
          autoResetPageIndex={false}
          enableColumnActions={false}
          enableTopToolbar={false}
          initialState={{ columnVisibility: { tenant_id: canManageTenant } }}
          enableRowActions
          renderRowActions={({ row }) => [
            <Box key={0} sx={{ minWidth: '120px' }}>
              {authStore.hasPermission(Permission.AssignRoles) && (
                <Tooltip title={t('assignRoles', 'Assign roles')}>
                  <IconButton
                    aria-label='assign'
                    onClick={() => handleAssign(row.original.user_id)}
                  >
                    <UsersIcon />
                  </IconButton>
                </Tooltip>
              )}

              {canEdit && (
                <>
                  <Tooltip title={t('edit', 'Edit')}>
                    <IconButton
                      aria-label='edit'
                      onClick={() => userDrawerUIStore.toggle(row.original.user_id)}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title={t('remove', 'Remove')}>
                    <IconButton
                      aria-label='delete'
                      onClick={() => userUIStore.setToBeRemoveUserId(row.original.user_id)}
                    >
                      <BinIcon />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </Box>,
          ]}
          positionActionsColumn='last'
          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: '',
              muiTableBodyCellProps: {
                align: 'right',
              },
            },
          }}
        />
      </Container>
    </>
  )
})
