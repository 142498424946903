import { Box, Typography, styled } from '@mui/material'
import { useTranslate } from '@tolgee/react'

const DropEquipmentCard = styled(Box)(({ theme }) => ({
  border: '0.5px dashed #919EAB',
  borderRadius: '16px',
  backgroundColor: theme.palette.grey[200],
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  width: '100%',
}))

interface Props {
  dataCy?: string
}

export const DraggableDropEquipmentCard = ({ dataCy }: Props) => {
  const { t } = useTranslate()
  return (
    <DropEquipmentCard>
      <Typography variant='body1' data-cy={dataCy}>
        {t('dropEquipmentsHere')}
      </Typography>
    </DropEquipmentCard>
  )
}
