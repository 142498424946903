import { Settings } from '@mui/icons-material'
import { Box, IconButton, Tooltip } from '@mui/material'
import { VesselVisitDto } from '@operations/stores/VesselVisitStore'
import { useTranslate } from '@tolgee/react'
import { translateEnumValue } from '@operations/utils/enum-extensions'
import { formatStringDate } from '@operations/utils/format-date'
import { overwriteMinimalTableHeadCellStyle } from '@operations/utils/minimal-theme-overwrite'
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table'
import { useMemo } from 'react'
import { useNavigate } from 'react-router'

interface Props {
  items: VesselVisitDto[]
}

export const VesselVisitTable = ({ items }: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslate()
  const handleClickIconBtn = (url: string) => {
    navigate(url)
  }

  const columns = useMemo<MRT_ColumnDef<VesselVisitDto>[]>(
    () => [
      {
        accessorKey: 'id',
        header: t('id', 'Id'),
      },
      {
        accessorKey: 'carrierName',
        header: t('name', 'Name'),
      },
      {
        header: t('status', 'Status'),
        accessorFn: row => `${t(row.status, translateEnumValue(row.status))}`,
      },
      {
        header: t('eta', 'ETA'),
        accessorFn: row => formatStringDate(row.eta),
      },
      {
        header: t('etd', 'ETD'),
        accessorFn: row => formatStringDate(row.etd),
      },
    ],
    [t],
  )

  return (
    <>
      <h2>{t('vesselVisits', 'Vessel Visits')}</h2>
      <MaterialReactTable
        muiTableHeadCellProps={overwriteMinimalTableHeadCellStyle}
        columns={columns}
        data={items}
        enableEditing
        muiTablePaginationProps={{
          backIconButtonProps: { color: 'default' },
          nextIconButtonProps: { color: 'default' },
        }}
        renderRowActions={({ row }) => (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Tooltip arrow placement='left' title={t('equipmentPlanning', 'Equipment Planning')}>
              <IconButton
                onClick={() => handleClickIconBtn(`/EquipmentPlanning/${row.original.id}`)}
              >
                <Settings />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      />
    </>
  )
}
