import {
  CreatePackageCommand,
  LengthMeasurementUnit,
  PackageDto,
  WeightMeasurementUnit,
} from '@billing/app/api'
import { PackageFormProfile } from './package-form.profile'

const mapFormValuesToCreateDto = ({
  name,
  height,
  width,
  length,
  lengthUnit,
  weightUnit,
}: PackageFormProfile): CreatePackageCommand => ({
  name,
  height,
  width,
  length,
  lengthUnit: lengthUnit as LengthMeasurementUnit,
  weightUnit: weightUnit as WeightMeasurementUnit,
})

const mapDtoToFormValues = ({
  id,
  name,
  height,
  width,
  length,
  weightUnit,
  lengthUnit,
}: PackageDto): PackageFormProfile => ({
  id,
  name,
  height,
  width,
  length,
  weightUnit,
  lengthUnit,
})

export const PackageFormMapper = {
  mapFormValuesToCreateDto,
  mapDtoToFormValues,
}
