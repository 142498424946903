import { ReportSummarySectionModel } from '@billing/features/reports/models/report-summary-section.model'
import { Box, Card, CardContent, Typography } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { ReportSummarySection } from './ReportSummarySection'

interface Props {
  title: string
  subtitle: string
  sections: ReportSummarySectionModel[]
}

export const ReportSummaryTable = ({ title, subtitle, sections }: Props) => {
  const theme = useMinimalsTheme()

  return (
    <Card sx={{ width: '100%', height: '100%' }}>
      <CardContent sx={{ padding: '0 !important' }}>
        <Box padding={theme.customSpacing.s}>
          <Typography variant='h4'>{title}</Typography>
          <Typography variant='subtitle2' color='secondary'>
            {subtitle}
          </Typography>
        </Box>

        {sections.map((s, i) => (
          <ReportSummarySection
            key={`${title}-${s.nameColumn}-${i.toString()}`}
            nameColumn={s.nameColumn}
            valueColumn={s.valueColumn}
            entries={s.entries}
          />
        ))}
      </CardContent>
    </Card>
  )
}
