export interface ReeferMonitoringFormProfile {
  id?: number
  cargoUnitId: number | null
  isPluggedIn: boolean
  temperature?: number | string | null
  recordedAt: Date
  additionalInfo?: string
  isTemperatureRequired?: boolean
}

export const defaultValues: ReeferMonitoringFormProfile = {
  cargoUnitId: null,
  isPluggedIn: false,
  temperature: '',
  recordedAt: new Date(),
  isTemperatureRequired: false,
}
