import { FormControl, InputLabel, MenuItem, Select, SxProps, Theme } from '@mui/material'
import { CargoType } from '@planning/app/api'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

interface Props {
  value: CargoType
  onChange: (cargoType: CargoType) => Promise<void> | void
  sx?: SxProps<Theme>
  disabled?: boolean
}

export const CargoTypeSelect: FC<Props> = observer(({ value, onChange, sx, disabled }) => {
  const { t } = useTranslate()

  return (
    <FormControl fullWidth>
      <InputLabel color='secondary'>{t('cargoType', 'Cargo Type')}</InputLabel>
      <Select
        disabled={disabled}
        fullWidth
        defaultValue={value}
        value={value}
        label={t('cargoType', 'Cargo Type')}
        data-cy='create-vessel-visit-form-cargotype-field'
        onChange={async e => onChange(e.target.value as CargoType)}
        sx={sx}
      >
        <MenuItem value={CargoType.Container}>{CargoType.Container}</MenuItem>
        <MenuItem value={CargoType.GeneralCargo}>{CargoType.GeneralCargo}</MenuItem>
      </Select>
    </FormControl>
  )
})
