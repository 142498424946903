import { TenantDto } from '@admin/app/api'
import { BaseDrawerStore } from '@admin/stores/base/BaseDrawerStore'
import { TenantStore } from '@admin/stores/tenant.store'
import { action, makeObservable, observable } from 'mobx'

export class TenantDrawerUIStore extends BaseDrawerStore<TenantDto> {
  isPopupOpen = false
  popupText?: string

  constructor(private tenantStore: TenantStore) {
    super(tenantStore)

    makeObservable(this, {
      isPopupOpen: observable,
      popupText: observable,

      openPopup: action,
      closePopup: action,
    })
  }

  public openPopup(text: string) {
    this.isPopupOpen = !this.isPopupOpen
    this.popupText = text
  }

  public closePopup() {
    this.isPopupOpen = false
    this.popupText = undefined
  }
}
