import { LengthMeasurementUnit, PackageDto, WeightMeasurementUnit } from '@billing/app/api'
import { SelectOption } from '@billing/app/models'

export const defaultSelectOption = {
  label: 'please select an option',
  value: '',
} as SelectOption

export const getPackageName = ({ name, length, width, height, lengthUnit }: PackageDto) => {
  let packageName = name
  const divider = 'x'
  const space = ' '

  if (length) packageName += ` ${length}`

  if (width) packageName += (length ? divider : space) + `${width}`

  if (height) packageName += (length || width ? divider : space) + `${height}`

  if (lengthUnit) packageName += ` ${simplifyLengthMeasurementUnit(lengthUnit)}`

  return packageName
}

export const simplifyLengthMeasurementUnit = (unit?: LengthMeasurementUnit | null) => {
  switch (unit) {
    case LengthMeasurementUnit.Millimeters:
      return 'mm'
    case LengthMeasurementUnit.Centimeter:
      return 'cm'
    case LengthMeasurementUnit.Meter:
      return 'm'
    case LengthMeasurementUnit.Feet:
      return 'ft'
    case LengthMeasurementUnit.Inches:
      return 'in'
    default:
      return ''
  }
}

export const simplifyWeightUnit = (volume?: WeightMeasurementUnit | null) => {
  switch (volume) {
    case WeightMeasurementUnit.Kilogram:
      return 'kg'
    case WeightMeasurementUnit.Ton:
      return 't'
    default:
      return ''
  }
}
