import { Autocomplete, Chip, List, Popper, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { ImdgCodes } from '@planning/constants'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { FC } from 'react'
import { DangerousGoodOptionListItem } from '../../pages/DeliveryOrders/Components/DangerousGoodsOptionListItem'
import { YesNoChipBox } from './YesNoChipBox'

interface Props {
  assignedCodes?: string[]
  enableSelect?: boolean
  error?: boolean
  helperText?: React.ReactNode
  disable?: boolean
  onDangerousGoodChange: (assignedCodes: string[]) => void
  onToggleSelectDangerousGoods: () => void
}

// React components should not be nested typescript:S6478
export const PopperWithPlacement = (props: any) => {
  return <Popper {...props} placement='bottom-start' />
}

export const DangerousGoodAutoComplete: FC<Props> = ({
  assignedCodes,
  enableSelect,
  error,
  helperText,
  disable,
  onToggleSelectDangerousGoods,
  onDangerousGoodChange,
}) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const onClickYes = () => {
    if (!enableSelect) onToggleSelectDangerousGoods()
  }

  const onClickNo = () => {
    if (enableSelect) {
      onDangerousGoodChange([])
      onToggleSelectDangerousGoods()
    }
  }

  const onDeleteDangerousGoods = (imdg: string) =>
    onDangerousGoodChange(assignedCodes?.filter(imo => imo !== imdg) ?? [])

  return (
    <>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <Typography variant='subtitle2' color={disable ? theme.palette.text.disabled : ''}>
          {t('dangerousGoods', 'Dangerous goods')}?
        </Typography>
        <YesNoChipBox
          disableYes={disable}
          disableNo={disable}
          activeOption={enableSelect ? 'Yes' : 'No'}
          onClickYes={onClickYes}
          onClickNo={onClickNo}
        />
      </Box>
      {enableSelect && (
        <Autocomplete
          disabled={disable}
          multiple
          disableCloseOnSelect
          fullWidth
          ListboxComponent={List}
          options={ImdgCodes}
          getOptionLabel={code => code}
          PopperComponent={PopperWithPlacement}
          slotProps={{
            popper: {
              sx: { minWidth: '16rem' },
            },
          }}
          renderOption={(props, code) => (
            <DangerousGoodOptionListItem
              code={code}
              assignedCodes={assignedCodes}
              props={props}
              key={`codes-list-option-${code}`}
            />
          )}
          value={assignedCodes}
          renderTags={value => (
            <>
              {value
                .sort((a, b) => a.localeCompare(b))
                .map(imdg => (
                  <Chip
                    key={`imo-class-option-chip-${imdg}`}
                    label={imdg}
                    onDelete={() => onDeleteDangerousGoods(imdg)}
                  />
                ))}
            </>
          )}
          onChange={(_, assignedCodes) => {
            onDangerousGoodChange(assignedCodes)
          }}
          renderInput={params => (
            <TextField
              {...params}
              placeholder={assignedCodes?.length ? '' : 'IMO Class'}
              sx={{ marginTop: '1rem' }}
              label='IMO Class'
              error={error}
              helperText={helperText}
            />
          )}
        />
      )}
    </>
  )
}
