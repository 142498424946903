export const YARD_DIMENSIONS = {
  height: 1510,
  width: 2010,
}

export const ZOOM_STEP = 0.01
export const ZOOM_MIN = 0.2
export const ZOOM_MAX = 2
export const STAGE_OFFSET = 100

export const IMPORT_OCCUPANCY_BACKGROUND = '#D6E4FF'
export const EXPORT_OCCUPANCY_BACKGROUND = '#C8FACD'
export const DEFAULT_OCCUPANCY_BACKGROUND = 'white'
export const RESERVATION_OCCUPANCY_BACKGROUND = '#F4F6F8'

export const stackWidthInPixels = 59
export const stackHeightInPixels = 23.5

export const colorEmptyOccupancy = '#FFFFFF'
export const colorFullOccupancy = '#000000'

export const FLEXIBLE_ZONE_WIDTH = 128
export const FLEXIBLE_ZONE_HEIGHT = 64
export const FLEXIBLE_ZONE_ICON_SIZE = 24
