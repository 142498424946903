import { Box } from '@mui/material'
import { IVesselPlanning } from '@planning/components/carrier-visit-planning/CarrierVisitPlanning.model'
import { CarrierVisitPlanningCard } from '@planning/components/carrier-visit-planning/CarrierVisitPlanningCard'
import { observer } from 'mobx-react-lite'
import { VesselVisitCardDescription } from './VesselVisitCardDescription'

interface Props {
  pixelPerHour: number
  visits: IVesselPlanning[]
  onClick: (id: number) => void
}

export const BerthVisualization = observer(({ visits, pixelPerHour, onClick }: Props) => {
  return (
    <Box
      width='100%'
      height='100%'
      position='relative'
      sx={{
        backgroundColor: 'rgb(223 227 232 / 40%)',
        backgroundImage: `
        linear-gradient(rgba(145, 158, 171, 0) 2px, transparent 2px), 
        linear-gradient(90deg, rgba(145, 158, 171, 0.24) 2px, transparent 1px),
        linear-gradient(rgba(145, 158, 171, 0.1) 1px, transparent 1px),
        linear-gradient(90deg, rgba(145, 158, 171, 0.1) 1px, transparent 1px)
      `,
        backgroundSize: `150px 150px, ${pixelPerHour * 24}px ${pixelPerHour * 24}px, ${pixelPerHour * 4}px ${pixelPerHour * 4}px, ${pixelPerHour * 4}px ${pixelPerHour * 4}px`,
        backgroundPosition: '-2px -2px, -2px -2px, -1px -1px, -1px -1px',
      }}
    >
      {visits.map(visit => (
        <CarrierVisitPlanningCard
          key={'visit_' + visit.id}
          visit={visit}
          onClick={onClick}
          descriptionComponent={<VesselVisitCardDescription visit={visit} />}
        />
      ))}
    </Box>
  )
})
