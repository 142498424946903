import { Grid, TextField, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { CustomInputAdornment } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { Controller, useFormContext } from 'react-hook-form'

export const VesselVisitPlanningBerthAllocation = observer(() => {
  const { t } = useTranslate()

  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <>
      <Typography variant='subtitle1' marginTop='1rem' marginBottom='0.5rem'>
        {t('quayMeterMarkings', `Quay's meter markings`)}
      </Typography>
      <Grid container direction='row' columnSpacing={{ xs: 1 }}>
        <Grid item xs={6}>
          <Controller
            control={control}
            name={`qmmFrom`}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                required
                data-cy='vessel-visit-planning-qmm-from-field'
                fullWidth
                type='number'
                value={field.value ?? ''}
                inputProps={{
                  min: 0.1,
                  max: 13,
                  step: '0.01',
                }}
                InputProps={{
                  endAdornment: (
                    <CustomInputAdornment position='end' color='secondary'>
                      m
                    </CustomInputAdornment>
                  ),
                }}
                error={!!errors.qmmFrom}
                helperText={errors.qmmFrom ? t('fieldIsRequired', 'Field is required.') : ''}
                label={t('from', 'From')}
                variant='outlined'
                name='qmmFrom'
                onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            control={control}
            name={`qmmTo`}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                required
                fullWidth
                data-cy='vessel-visit-planning-qmm-to-field'
                type='number'
                value={field.value ?? ''}
                inputProps={{
                  min: 0.1,
                  max: 13,
                  step: '0.01',
                }}
                InputProps={{
                  endAdornment: (
                    <CustomInputAdornment position='end' color='secondary'>
                      m
                    </CustomInputAdornment>
                  ),
                }}
                error={!!errors.qmmTo}
                helperText={errors.qmmTo ? t('fieldIsRequired', 'Field is required.') : ''}
                label={t('to', 'To')}
                variant='outlined'
                name='qmmTo'
                onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  )
})
