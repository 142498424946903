import { Stack } from '@mui/material'
import {
  CarrierType,
  CarrierVisitDirection,
  CommodityResponseDto,
  CustomerResponseDto,
  OrderStatus,
  VesselVisitDto,
  WeightMeasurementUnit,
} from '@planning/app/api'
import {
  OutboundOrderValidationDto,
  ValidateOutboundOrderFunc,
} from '@planning/stores/gateControl/ValidateOutboundDto'
import { GeneralCargoViewStore } from '@planning/stores/generalCargo/GeneralCargoViewStore'
import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { IAmAContainerVisitToo, IOrderWithVisit } from '../stores/SelectOrderViewStore'
import { CargoControl } from './GeneralCargo/CargoControl'
import { CustomerControl } from './GeneralCargo/CustomerControl'
import { HandlingDirectionControl } from './GeneralCargo/HandlingDirectionControl'
import { HandlingInstructionsControl } from './GeneralCargo/HandlingInstructionsControl'
import { IdentifiableItemsControl } from './GeneralCargo/IdentifiableItemsControl'

interface IProps {
  formId: string
  store: GeneralCargoViewStore
  visit?: IAmAContainerVisitToo
  disableCarrierVisitSelection?: boolean
  isTruckAppointment?: boolean
  forcedDirection?: CarrierVisitDirection
  onSubmit: (data: ICreateGeneralCargoOrderFormData) => Promise<void> | void
  renderDamage?: (orderId: number) => React.ReactElement
  validateOutboundRequest?: ValidateOutboundOrderFunc
}

export interface ICreateGeneralCargoOrderFormData {
  id?: number
  referenceNumber?: string
  billOfLading?: string
  lotNumber?: string

  status?: OrderStatus

  commodity?: CommodityResponseDto
  plannedCargoAmount?: number
  actualCargoAmount?: number

  customer?: CustomerResponseDto

  imoClasses: string[]
  handlingInstructions?: string

  direction?: CarrierVisitDirection
  transportAssigned?: boolean

  carrierVisit?: VesselVisitDto
  carrierVisitType?: CarrierType
  waggon?: string
  identifiableItems?: string

  grossWeight?: number
  weightUnit?: WeightMeasurementUnit
  packageId?: number
}

export const CreateGeneralCargoOrderPageForm: FC<IProps> = observer(
  ({
    formId,
    store,
    onSubmit,
    visit,
    disableCarrierVisitSelection,
    isTruckAppointment,
    forcedDirection,
    renderDamage,
    validateOutboundRequest,
  }) => {
    const visitData: IOrderWithVisit | undefined = visit?.[0] ?? visit?.[1]

    const getContainerVisitInfo = () => {
      if (!store.selectedOrder) return undefined

      const carrierVisit = store.carrierVisits?.find(
        (v: { id: number | undefined }) => v.id === store.selectedOrder?.carrierVisitId,
      )

      if (!carrierVisit) return undefined

      const visitInfo = {
        id: store.selectedOrder?.id,
        data: { ...store.selectedOrder },
        visit: carrierVisit,
      }

      return visitInfo
    }

    const initOutboundRequestValidationParams = (): OutboundOrderValidationDto => {
      return {
        isOutbound:
          (forcedDirection ??
            visitData?.order?.direction ??
            store.selectedOrder?.direction ??
            CarrierVisitDirection.Inbound) === CarrierVisitDirection.Outbound,
        orderId: store.selectedOrder?.id,
        customerId: store.selectedOrder?.customerReferenceId ?? undefined,
        commodityId: store.selectedOrder?.commodityId ?? undefined,
        lotNumber: store.selectedOrder?.lotNumber ?? undefined,
        packageId: store.selectedOrder?.packageId ?? undefined,
        quantity: store.selectedOrder?.plannedCargoAmount ?? undefined,
        unitIds: store.selectedOrder?.identifiableItems ?? undefined,
        imoClasses: store.selectedOrder?.imoClasses,
      }
    }

    const order = getContainerVisitInfo()

    const isOrderFulfilled = store.selectedOrder?.status === OrderStatus.Fulfilled

    const form = useForm({
      defaultValues: {
        customer: store.selectedOrder?.customerId
          ? ({
              id: store.selectedOrder.customerId,
              name: store.selectedOrder.customerName,
            } as CustomerResponseDto)
          : undefined,
        referenceNumber: store.selectedOrder?.referenceNumber,
        status: store.selectedOrder?.status,
        lotNumber: store.selectedOrder?.lotNumber,
        direction:
          forcedDirection ??
          visitData?.order?.direction ??
          store.selectedOrder?.direction ??
          CarrierVisitDirection.Inbound,
        carrierVisitType: isTruckAppointment
          ? CarrierType.Truck
          : visitData?.order?.carrierVisitType ?? store.selectedOrder?.carrierVisitType,
        transportAssigned:
          isTruckAppointment ||
          !!visitData ||
          !!order ||
          store.selectedOrder?.carrierVisitType === CarrierType.Truck,
        carrierVisit: visitData?.visit ?? order?.visit,
        waggon: visitData?.order?.waggon ?? order?.data.waggon,
        commodity: store.selectedOrder?.commodityId
          ? ({
              id: store.selectedOrder.commodityId,
              name: '', // TODO Standardize to be like customer?
            } as CommodityResponseDto)
          : undefined,
        plannedCargoAmount: store.selectedOrder?.plannedCargoAmount,
        actualCargoAmount: store.selectedOrder?.actualCargoAmount,
        handlingInstructions: store.selectedOrder?.handlingInstructions,
        imoClasses: store.selectedOrder?.imoClasses ?? [],
        identifiableItems: store.selectedOrder?.identifiableItems?.join() ?? undefined,
        packageId: store.selectedOrder?.packageId,
        grossWeight: store.selectedOrder?.grossWeight,
        weightUnit: store.selectedOrder?.weightUnit,
      } as ICreateGeneralCargoOrderFormData,
    })

    const { handleSubmit } = form

    const [validationParams, setValidationParams] = useState<OutboundOrderValidationDto>(
      initOutboundRequestValidationParams(),
    )

    const handleValidationParamsChanged = (params: any) => {
      setValidationParams({ ...validationParams, ...params })
    }

    return (
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)} id={formId}>
          <Stack gap={4}>
            <CustomerControl
              isOrderFulfilled={isOrderFulfilled}
              store={store}
              onValidationParamsChanged={handleValidationParamsChanged}
            />
            <HandlingDirectionControl
              isTruckAppointment={isTruckAppointment}
              isOrderFulfilled={isOrderFulfilled}
              order={order}
              visitData={visitData}
              store={store}
              disableCarrierVisitSelection={disableCarrierVisitSelection}
              onValidationParamsChanged={handleValidationParamsChanged}
            />
            <CargoControl
              store={store}
              isOrderFulfilled={isOrderFulfilled}
              renderDamage={renderDamage}
              validationParams={validationParams}
              onValidationParamsChanged={handleValidationParamsChanged}
              validateOutboundRequest={validateOutboundRequest}
            />
            <IdentifiableItemsControl
              isOrderFulfilled={isOrderFulfilled}
              onValidationParamsChanged={handleValidationParamsChanged}
            />
            <HandlingInstructionsControl
              isOrderFulfilled={isOrderFulfilled}
              onValidationParamsChanged={handleValidationParamsChanged}
            />
          </Stack>
        </form>
      </FormProvider>
    )
  },
)
