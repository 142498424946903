import { CreateTruckCommand, TrucksApi } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'

class TruckService {
  httpClient = createApiClient(TrucksApi)

  async get(page: number, pageSize: number, filter?: string) {
    const { data } = await this.httpClient.get(page, pageSize, filter)

    return data
  }

  async create(cmd: CreateTruckCommand) {
    await this.httpClient.post(cmd)
  }

  async delete(id: number) {
    await this.httpClient._delete(id)
  }
}

const truckService = new TruckService()

export default truckService
