import { createSvgIcon } from '@mui/material'

export const FishIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M14.8484 12C14.8484 12.3796 15.147 12.6875 15.5151 12.6875C15.8831 12.6875 16.1817 12.3796 16.1817 12C16.1817 11.6202 15.8834 11.3125 15.5151 11.3125C15.147 11.3125 14.8484 11.6202 14.8484 12Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4 2C2.89543 2 2 2.89543 2 4V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V4C22 2.89543 21.1046 2 20 2H4ZM7.19008 10.3279C8.4048 8.9856 10.5873 7.41667 13.0865 7.41667C16.9048 7.41667 20.0004 11.0833 20.0004 12C20.0004 12.9167 16.9048 16.5833 13.0865 16.5833C10.5873 16.5833 8.40453 15.0144 7.1898 13.6723L4.76397 15.5644C4.42703 15.8271 3.92397 15.5472 4.01008 15.1445L4.68203 12L4.01036 8.85583C3.92425 8.45307 4.4273 8.17292 4.76425 8.43589L7.19008 10.3279Z'
      fill='currentColor'
    />
  </svg>,
  'FishIcon',
)
