import { useTranslate } from '@tolgee/react'
import { ColoredLabel } from '@tom-ui/ui'

export const TrackConflictChip = () => {
  const { t } = useTranslate()

  return (
    <ColoredLabel
      label={t('trackConflict', 'track Conflict')}
      color='warning'
      sx={{
        width: 'fit-content',
      }}
    />
  )
}
