import { GetTokenSilentlyOptions } from '@auth0/auth0-react'
import { jwtDecode } from 'jwt-decode'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { accessTokenProvider } from '../lib/access-token.provider'

interface CustomClaims {
  permissions: string[]
  sub: string
}

export interface IAuthStore {
  token: string
  permissions: string[]
  isLoaded: boolean
  claims: CustomClaims

  fetchAuth(
    getAccessTokenSilently: (options?: GetTokenSilentlyOptions) => Promise<string>,
  ): Promise<void>
}

export class AuthStore implements IAuthStore {
  token = ''
  permissions: string[] = []
  isLoaded = false
  claims = {} as CustomClaims

  constructor() {
    makeObservable(this, {
      token: observable,
      permissions: observable,
      isLoaded: observable,
      claims: observable,
      fetchAuth: action,
    })
  }

  async fetchAuth(getAccessTokenSilently: (options?: GetTokenSilentlyOptions) => Promise<string>) {
    accessTokenProvider.setAccessTokenFactory(getAccessTokenSilently)
    const accessToken = await getAccessTokenSilently()

    runInAction(() => {
      this.claims = jwtDecode(accessToken)
      this.token = accessToken
      this.permissions = this.claims.permissions
      this.isLoaded = true
    })
  }

  hasPermission(permission: string): boolean {
    return this.permissions.includes(permission)
  }
}

export const authStore = new AuthStore()
