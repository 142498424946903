export interface EquipmentFilter {
  equipmentTypes?: string[] | null
  isOnMaintenance?: boolean | null
  yardBlockIds?: string[] | null
}

export const defaultValues: EquipmentFilter = {
  equipmentTypes: [],
  isOnMaintenance: false,
  yardBlockIds: null,
}
