import { StoreContext } from '@storage/contexts/store.context-provider'
import { RootStore } from '@storage/stores/root.store'
import { useContext } from 'react'

export const useStores = (): RootStore => {
  const rootStore = useContext(StoreContext)

  if (!rootStore) {
    throw new Error('Store provider with root store is not provided.')
  }

  return rootStore
}
