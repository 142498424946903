import { withAuthenticationRequired } from '@auth0/auth0-react'
import { Grid } from '@mui/material'
import { CraneSplitSummary, EquipmentPlanningSummary, useOperationsStore } from '@tom-ui/operations'
import {
  BerthPlanningCard,
  OrderMovementEditableCard,
  VisitBreak,
  vesselVisitDetailPages,
} from '@tom-ui/planning'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { CarrierType } from 'modules/planning/src/app/api'
import { FeatureFlag } from 'modules/utils/src/components/FeatureFlags'
import { FC } from 'react'
import { CarrierVisitDirection } from './VesselVisitDetails'

export const VesselVisitDashboard: FC<{
  vesselVisitItem: any
  handlePageChange: (page: string) => void
}> = withAuthenticationRequired(
  observer(({ vesselVisitItem, handlePageChange }) => {
    const theme = useMinimalsTheme()
    const { tenantConfigStore: operationsTenantConfigStore } = useOperationsStore()

    if (!vesselVisitItem) return <></>

    return (
      <Grid
        sx={{ paddingLeft: theme.spacing(3) }}
        container
        justifyContent='flex-start'
        spacing={{ xs: 1, md: 3 }}
      >
        <Grid item sm={12} md={6} lg={4}>
          <OrderMovementEditableCard
            visitType={CarrierType.Vessel}
            vesselVisitItem={vesselVisitItem}
            direction={CarrierVisitDirection.Inbound}
            onViewClick={() => handlePageChange(vesselVisitDetailPages.discharge)}
          />
        </Grid>
        <Grid item sm={12} md={6} lg={4}>
          <OrderMovementEditableCard
            visitType={CarrierType.Vessel}
            vesselVisitItem={vesselVisitItem}
            direction={CarrierVisitDirection.Outbound}
            onViewClick={() => handlePageChange(vesselVisitDetailPages.load)}
          />
        </Grid>

        <FeatureFlag name='visit-break-info-display'>
          <Grid item sm={12} md={6} lg={4}>
            <VisitBreak vesselVisitItem={vesselVisitItem} />
          </Grid>
        </FeatureFlag>
        <FeatureFlag name='vessel-visit-berth-planning'>
          <Grid item sm={12} md={6} lg={4}>
            <BerthPlanningCard vesselVisitItem={vesselVisitItem} />
          </Grid>
        </FeatureFlag>

        {!operationsTenantConfigStore.skipCraneSplit && (
          <>
            <Grid item sm={12} md={8}>
              <CraneSplitSummary />
            </Grid>
            <Grid item sm={12} md={8}>
              <EquipmentPlanningSummary
                navigateToUrl={`/EquipmentPlanning/${vesselVisitItem?.id}/equipments`}
              />
            </Grid>
          </>
        )}
      </Grid>
    )
  }),
)
