import { createSvgIcon } from '@mui/material'

export const LivestockIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M14.334 10.0378C14.7027 10.0378 15.0006 10.3364 15.0006 10.7044C15.0006 11.0727 14.7027 11.3712 14.334 11.3712C13.9651 11.3712 13.6673 11.073 13.6673 10.7044C13.6673 10.3367 13.9651 10.0378 14.334 10.0378Z'
      fill='currentColor'
    />
    <path
      d='M10.3334 10.0378C9.9645 10.0378 9.66668 10.3367 9.66668 10.7044C9.66668 11.073 9.9645 11.3712 10.3334 11.3712C10.7021 11.3712 11 11.0727 11 10.7044C11 10.3364 10.7021 10.0378 10.3334 10.0378Z'
      fill='currentColor'
    />
    <path
      d='M9.66668 16.4172C9.66668 16.0495 9.96447 15.7507 10.3333 15.7507C10.7021 15.7507 11 16.0492 11 16.4172C11 16.7856 10.7021 17.0841 10.3333 17.0841C9.96447 17.0841 9.66668 16.7859 9.66668 16.4172Z'
      fill='currentColor'
    />
    <path
      d='M14.334 15.7507C13.9651 15.7507 13.6673 16.0495 13.6673 16.4172C13.6673 16.7859 13.9651 17.0841 14.334 17.0841C14.7027 17.0841 15.0007 16.7856 15.0007 16.4172C15.0007 16.0492 14.7027 15.7507 14.334 15.7507Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4 2C2.89543 2 2 2.89543 2 4V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V4C22 2.89543 21.1046 2 20 2H4ZM19.0583 8.94864C18.6872 8.45108 17.9238 7.94935 16.974 7.82664C17.0613 7.54851 17.1174 7.24155 17.1406 6.90959C17.1851 6.27185 17.0938 5.74725 17.0899 5.72529C17.0433 5.46294 16.8382 5.25751 16.5759 5.21062C16.5385 5.2039 16.501 5.20061 16.4639 5.20061C16.2412 5.20061 16.0312 5.31789 15.9157 5.51467C15.6184 6.01863 15.1147 6.57377 14.783 6.6889C14.1329 6.2785 13.3163 6.05705 12.4021 6.04522C12.3879 6.04425 12.3736 6.04378 12.3592 6.04378L12.3425 6.04397L12.3259 6.04378C12.3114 6.04378 12.297 6.04425 12.2828 6.04522C11.3687 6.0571 10.5522 6.2785 9.90207 6.6889C9.57025 6.57374 9.06642 6.01837 8.76918 5.51433C8.65353 5.31818 8.44354 5.20112 8.22128 5.20109C8.18389 5.20109 8.14619 5.20443 8.10849 5.21121C7.84671 5.25846 7.64176 5.46328 7.59527 5.72518C7.5677 5.88032 7.40949 6.87864 7.70738 7.82684C6.7594 7.95033 5.99742 8.45108 5.62683 8.94796C5.3651 9.29887 5.27471 9.67271 5.37228 10.0006C5.56465 10.6471 6.33959 11.0488 7.39467 11.0488C7.81454 11.0488 8.22874 10.9836 8.60725 10.8621L9.15045 14.9082C9.12578 14.9783 9.0903 15.063 9.05345 15.1509C8.92141 15.466 8.75704 15.8723 8.75704 16.3343C8.75704 17.4747 9.64362 18.2852 10.9131 18.2852H13.772C15.0414 18.2852 15.928 17.4747 15.928 16.3343C15.928 15.8723 15.7636 15.4731 15.6316 15.1579C15.5947 15.07 15.5592 14.9818 15.5346 14.9118L16.0778 10.8638C16.4563 10.9853 16.8705 11.0497 17.2904 11.0497C18.3455 11.0497 19.1205 10.6475 19.3128 10.001C19.4103 9.67316 19.32 9.29948 19.0583 8.94864Z'
      fill='currentColor'
    />
  </svg>,
  'LivestockIcon',
)
