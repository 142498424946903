import { Theme } from '@mui/material/styles'

// ----------------------------------------------------------------------

export default function Card(theme: Theme) {
  return {
    MuiCard: {
      styleOverrides: {
        root: {
          position: 'relative',
          boxShadow: theme.customShadows.card,
          borderRadius: theme.customRadius.medium,
          zIndex: 0, // Fix Safari overflow: hidden with border radius
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: { variant: 'h4' },
        subheaderTypographyProps: { variant: 'body2', marginTop: theme.customSpacing.xxs },
      },
      styleOverrides: {
        root: {
          padding: `${theme.customSpacing.m} ${theme.customSpacing.m} 0`,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: theme.customSpacing.m,
          '&:last-child': {
            paddingBottom: theme.customSpacing.m,
          },
        },
      },
    },
  }
}
