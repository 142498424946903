import { HandlingDirection } from '@storage/app/api'
import gcsService from '@storage/app/gcs/gcs.service'
import { SelectOption } from '@storage/app/models'
import { GeneralCargoAreaStore } from '@storage/pages/general-cargo-areas/stores/general-cargo-area.store'
import { GeneralCargoStockStore } from '@storage/pages/general-cargo-inventory/stores/general-cargo-stock.store'
import { GeneralCargoPlanningStore } from '@storage/stores/general-cargo-planning.store'
import { compareFiles } from '@tom-ui/utils'
import sumBy from 'lodash/sumBy'
import { action, computed, makeObservable, observable } from 'mobx'
import { GeneralCargoContextStrategy } from '../interfaces/general-cargo-context-strategy'
import { GeneralCargoOccupancyStrategy } from '../interfaces/general-cargo-occupancy-strategy'
import { GeneralCargoPlanningStrategy } from '../interfaces/general-cargo-planning-strategy'
import { GeneralCargoStorageTrackerContext } from '../interfaces/general-cargo-storage-tracker-interface'
import { OccupancyItem, PlannedOccupancy, StockOccupancy } from '../interfaces/occupancy-item'
export type FileUploadResult = { name: string; pathToFile: string | null; file: File }

enum StockTrackingMethod {
  QUANTITY,
  IDENTIFIABLE_ITEMS,
}
export class GeneralCargoStorageTrackerUIStore {
  private strategy?: GeneralCargoContextStrategy

  context?: GeneralCargoStorageTrackerContext

  // occupancy context main map
  stockOccupancies: Map<string, StockOccupancy> = new Map()

  // planning context main map
  plannedOccupancies: Map<string, PlannedOccupancy> = new Map()

  activeOccupancyItemId?: string

  plannedIdentifiableItems: string[] = []

  fileOccupancies: Record<string, FileUploadResult[]> = {}

  constructor(
    private readonly _generalCargoAreaStore: GeneralCargoAreaStore,
    private readonly _generalCargoStockStore: GeneralCargoStockStore,
    private readonly _generalCargoPlanningStore: GeneralCargoPlanningStore,
  ) {
    makeObservable(this, {
      generalCargoAreas: computed,
      generalCargoStock: computed,
      areasAsOptions: computed,
      generalCargoPlannedOccupancies: computed,

      context: observable,
      setContext: action,

      stockOccupancies: observable,
      plannedOccupancies: observable,

      occupancyItems: computed,
      loadOccupancyItems: action,
      addNewOccupancyItem: action,
      updateOccupancyItem: action,

      totalNewQuantity: computed,
      isLoading: computed,

      activeOccupancyItemId: observable,
      setActiveOccupancyItemId: action,
      activeOccupancyItem: computed,

      plannedIdentifiableItems: observable,
      setPlannedIdentifiableItems: action,
      fileOccupancies: observable,
    })
  }

  setContext(context: GeneralCargoStorageTrackerContext) {
    this.context = context
    this.strategy =
      context === 'occupancy'
        ? new GeneralCargoOccupancyStrategy(this)
        : new GeneralCargoPlanningStrategy(this)
  }

  get occupancyItems() {
    if (this.context === 'occupancy') {
      return Array.from(this.stockOccupancies.values())
    } else if (this.context === 'planning') {
      return Array.from(this.plannedOccupancies.values())
    } else {
      return []
    }
  }

  get activeOccupancyItem() {
    return this.occupancyItems?.find(occItem => occItem.id === this.activeOccupancyItemId)
  }

  get generalCargoAreas() {
    return this._generalCargoAreaStore.entries
  }

  get generalCargoStock() {
    return this._generalCargoStockStore.entries[0]
  }

  get generalCargoPlannedOccupancies() {
    return this._generalCargoPlanningStore.entries
  }

  getStockTrackingMethod(handlingDirection: HandlingDirection): StockTrackingMethod {
    /*   
    TODO: a stock should have predefined tracking method determined from the 1st order instead of loose condition
    or mix both methods but the UI should be different
   */
    if (this.context !== 'occupancy') {
      return StockTrackingMethod.QUANTITY
    }

    return handlingDirection === 'Inbound' && this.plannedIdentifiableItems.length
      ? StockTrackingMethod.IDENTIFIABLE_ITEMS
      : StockTrackingMethod.QUANTITY
  }

  async loadPlannedIdentifiableItems(generalCargoOrderId: number, cargoItemId?: number) {
    const plannedIdentifiableItems =
      await this._generalCargoPlanningStore.getIdentifiableItemsByOrderId(
        generalCargoOrderId,
        cargoItemId,
      )
    this.setPlannedIdentifiableItems(plannedIdentifiableItems)
  }

  setPlannedIdentifiableItems(plannedIdentifiableItems: string[]) {
    this.plannedIdentifiableItems = plannedIdentifiableItems
  }

  get areasAsOptions(): SelectOption[] {
    return this.generalCargoAreas
      .filter(area => area.locations?.length)
      .map(({ name }) => ({ label: name, value: name }))
  }

  get totalNewQuantity() {
    return sumBy(this.occupancyItems as Array<{ newQuantity: number }>, x => x.newQuantity)
  }

  setActiveOccupancyItemId(occupancyItemId: string) {
    this.activeOccupancyItemId = occupancyItemId
  }

  areaLocationsAsOptions(generalCargoAreaName: string): SelectOption[] {
    const area = this.generalCargoAreas.find(area => area.name === generalCargoAreaName)

    return area
      ? area.locations.map(({ name }) => ({
          label: name,
          value: name,
        }))
      : []
  }

  loadOccupancyItems(handlingDirection: HandlingDirection, generalCargoOrderId: number) {
    this.strategy?.loadItems(handlingDirection, generalCargoOrderId)
  }

  addNewOccupancyItem(generalCargoOrderId?: number) {
    this.strategy?.addNewItem(generalCargoOrderId)
  }

  updateOccupancyItem<T extends keyof OccupancyItem>(id: string, key: T, value: OccupancyItem[T]) {
    this.strategy?.updateItem(id, key, value)
  }

  addOrUpdateFiles(id: string, files: FileUploadResult[]) {
    if (this.fileOccupancies[id] === undefined) {
      this.fileOccupancies[id] = files
    } else {
      const existing = this.fileOccupancies[id]

      files.forEach(newFile => {
        const index = existing.findIndex(e => compareFiles(e.file, newFile.file))
        if (index !== -1) {
          existing[index] = { ...existing, ...newFile }
        } else {
          existing.push(newFile)
        }
      })
    }
  }

  clearByKey(id: string) {
    delete this.fileOccupancies[id]
  }

  clearAllFiles() {
    this.fileOccupancies = {}
  }

  getFiles(id: string) {
    return this.fileOccupancies[id]?.map(x => x.file)
  }

  getAllPathToFiles(id: string) {
    return this.fileOccupancies[id]
      ?.filter(x => x.pathToFile !== null)
      .map(x => x.pathToFile as string)
  }

  async resolveFilesToUpload(id: string, filesToUpload: File[]): Promise<File[]> {
    if (this.fileOccupancies[id] === undefined) {
      return filesToUpload
    }

    // clear all files
    if (filesToUpload.length === 0) {
      await this.deleteUploadedFiles(this.getAllPathToFiles(id))
      this.clearByKey(id)
      return []
    }

    // add a new files
    if (filesToUpload.length > this.fileOccupancies[id].length) {
      return filesToUpload.filter(fileToUpload => {
        const file = this.fileOccupancies[id].find(f => compareFiles(f.file, fileToUpload))
        if (file === undefined || file.pathToFile === null) {
          return fileToUpload
        }
      })
    }

    // remove file
    const pathsToRemove = this.fileOccupancies[id]
      .filter(fileUploadResult => filesToUpload.some(i => compareFiles(fileUploadResult.file, i)))
      .filter(x => x.pathToFile !== null)
      .map(x => x.pathToFile as string)

    await this.deleteUploadedFiles(pathsToRemove)

    this.fileOccupancies[id] = this.fileOccupancies[id].filter(fileUploadResult =>
      filesToUpload.some(i => !compareFiles(fileUploadResult.file, i)),
    )

    return this.filesToUpload(id)
  }

  filesToUpload(id: string): File[] {
    return this.fileOccupancies[id].filter(f => f.pathToFile === null).map(f => f.file)
  }

  async uploadDamageFile(file: File): Promise<string> {
    return await this._generalCargoStockStore.uploadDamageFile(file)
  }

  async uploadDamageFiles(files: File[]): Promise<FileUploadResult[]> {
    const promises = files.map(async file => {
      try {
        return await this.uploadDamageFile(file).then(
          res => ({ name: file.name, pathToFile: res, file }) as FileUploadResult,
        )
      } catch (error) {
        return { name: file.name, pathToFile: null, file } as FileUploadResult
      }
    })

    return await Promise.all(promises)
  }

  public async deleteAllUploadedFiles() {
    await this.deleteUploadedFiles(
      Array.from(this.stockOccupancies.values()).flatMap(sto => sto.damageImagePaths),
    )
  }

  public async deleteUploadedFiles(paths: string[]) {
    return gcsService.deleteFiles(paths)
  }

  get isLoading(): boolean {
    if (this.context === 'occupancy') {
      return !this.generalCargoStock
    }
    return false
  }

  dropOffMessage(): string | undefined {
    return this.strategy?.dropOffMessage()
  }

  quantityAlertMessage(
    enteredQuantity: number,
    handlingDirection: HandlingDirection,
  ): string | undefined {
    return this.strategy?.quantityAlertMessage(enteredQuantity, handlingDirection)
  }

  onConfirm = async (
    handlingDirection: HandlingDirection,
    generalCargoOrderId: number,
    cargoItemId?: number,
  ) => {
    await this.strategy?.onConfirm(
      handlingDirection,
      generalCargoOrderId,
      this._generalCargoPlanningStore.planDropOff.bind(this._generalCargoPlanningStore),
      this._generalCargoStockStore.putToStock.bind(this._generalCargoStockStore),
      this._generalCargoStockStore.takeFromStock.bind(this._generalCargoStockStore),
      cargoItemId,
    )
  }

  getImoClasses(): string[] | undefined | null {
    const imoClasses = this._generalCargoStockStore.entries.at(0)?.imoClasses
    return imoClasses ? [...imoClasses].sort((a, b) => a.localeCompare(b)) : imoClasses
  }
}
