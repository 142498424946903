import { VesselVisitDto, VesselVisitsApi } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'
import { Event, IEvent, IQueryHandler, IQueryMessage } from '../messageBus'

export class GetVesselVisitByIdQuery implements IQueryMessage {
  static type = 'GetVesselVisitByIdQuery'
  type = GetVesselVisitByIdQuery.type

  constructor(public id: number) {}

  getKey = () => {
    return JSON.stringify(this)
  }
}

const httpClient = createApiClient(VesselVisitsApi)

export const getVesselVisitByIdQueryHandler: IQueryHandler<
  GetVesselVisitByIdQuery,
  IEvent<VesselVisitDto>
> = async query => {
  const { data } = await httpClient.getById(query.id)

  return new Event(GetVesselVisitByIdQuery.type, data)
}
