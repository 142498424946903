import { tolgee } from '@storage/app/translation'

export const getInsufficientPlanningSpaceMsg = (locationsAvailable: number) => {
  switch (true) {
    case locationsAvailable === 1:
      return tolgee.t('insufficientPlanningSpaceSingle')
    case locationsAvailable > 1:
      return tolgee.t('insufficientPlanningSpaceMultiple', {
        n: locationsAvailable,
      })
    default:
      return tolgee.t('insufficientPlanningSpace')
  }
}

export const getPlanningHasReservedMsg = (count: number) => {
  if (count > 1) {
    return tolgee.t(
      'PreplanningHasReservedMultiple',
      `The allocation rule won't be applied to {count} reserved containers in the list`,
      {
        count,
      },
    )
  }

  return tolgee.t(
    'PreplanningHasReservedSingle',
    "The allocation rule won't be applied to 1 reserved container in the list",
  )
}
