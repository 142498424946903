import { TextField, TextFieldProps } from '@mui/material'
import { OverridableStringUnion } from '@mui/types'
import { Controller, FieldValues, FormState, UseControllerProps } from 'react-hook-form'

type FieldProps = Pick<
  TextFieldProps,
  'label' | 'disabled' | 'fullWidth' | 'variant' | 'multiline' | 'maxRows' | 'minRows'
>

interface Props<T extends FieldValues> extends UseControllerProps<T>, FieldProps {
  type?: string
  uppercase?: boolean
  endAdornment?: React.ReactNode
  formState: FormState<T>
  size?: OverridableStringUnion<'small' | 'medium'>
  formArrayIndex?: number
}

export const ControlledTextField = <T extends FieldValues>({
  name,
  control,
  label,
  fullWidth,
  disabled,
  variant,
  maxRows,
  minRows,
  multiline,
  type,
  uppercase,
  endAdornment,
  size,
  formArrayIndex,
  formState: { errors },
}: Props<T>) => {
  const error =
    errors['values'] && formArrayIndex !== undefined && (errors['values'] as any)[formArrayIndex]
      ? (errors['values'] as any)[formArrayIndex]['name']
      : errors[name]

  return (
    <Controller
      render={({ field }) => (
        <TextField
          {...field}
          error={!!error}
          size={size ?? 'medium'}
          helperText={error?.message as string}
          label={label}
          multiline={multiline}
          type={type}
          maxRows={maxRows}
          minRows={minRows}
          fullWidth={fullWidth}
          disabled={disabled}
          variant={variant ?? 'outlined'}
          inputProps={{
            style: { textTransform: uppercase ? 'uppercase' : 'none' },
          }}
          InputProps={{
            endAdornment: endAdornment,
          }}
        />
      )}
      name={name}
      control={control}
    />
  )
}
