import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import { ConfirmationActions, useMinimalsTheme } from '@tom-ui/ui'
import { IResolveDamageViewStore } from '@planning/rt-stores/tallyman/ResolveDamageViewStore'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

interface IProps {
  store: IResolveDamageViewStore
}

export const DamageReportResolveDialog: FC<IProps> = observer(({ store }) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const resolveValues = [
    {
      label: 'Repaired',
      value: 'Repaired',
    },
    {
      label: 'Mistakenly created',
      value: 'MistakenlyCreated',
    },
    {
      label: 'Unknown',
      value: 'Unknown',
    },
  ]

  return (
    <Dialog
      open={store.isOpen}
      onClose={() => {
        store.setOpen(false)
      }}
      fullWidth
    >
      <DialogTitle>{t('resolutionReason', 'Resolution Reason')}</DialogTitle>

      <DialogContent>
        <FormControl fullWidth>
          <RadioGroup>
            {resolveValues.map((item, index) => (
              <FormControlLabel
                key={index + 1}
                value={item.value}
                control={<Radio />}
                label={item.label}
                sx={{
                  border: '1px solid #8d939a3d',
                  borderRadius: theme.customRadius.medium,
                  mr: '0',
                  padding: '.5rem',
                  mb: '.5rem',
                }}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </DialogContent>

      <ConfirmationActions
        primaryActionText={t('submit', 'Submit')}
        closeLabel={t('cancel', 'Cancel')}
        onConfirm={() => store.resolve()}
        onClose={() => store.setOpen(false)}
        mobile
      />
    </Dialog>
  )
})
