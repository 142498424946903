import { YardBlockBayDto, YardBlockDto, YardBlockRowDto, YardPositionDto } from '@storage/app/api'
import { YardBlockBayStore } from '@storage/stores/yard-block-bay.store'
import { YardBlockRowStore } from '@storage/stores/yard-block-row.store'
import { YardBlockStore } from '@storage/stores/yard-block.store'
import { action, computed, makeObservable, observable } from 'mobx'

interface CurrentPosition {
  block?: YardBlockDto
  bay?: YardBlockBayDto
  row?: YardBlockRowDto
  tier?: number
  flexibleZone?: boolean
}

export enum PositionContext {
  None,
  Block,
  Bay,
  Row,
  Tier,
}

export class YardPositionSelectStore {
  current: CurrentPosition = {}
  context = PositionContext.Block

  constructor(
    private readonly blockStore: YardBlockStore,
    private readonly bayStore: YardBlockBayStore,
    private readonly rowStore: YardBlockRowStore,
  ) {
    makeObservable(this, {
      context: observable,
      current: observable,

      setCurrent: action,
      clearCurrent: action,
      setContext: action,

      zones: computed,
      blocks: computed,
      bays: computed,
      rows: computed,
      tiers: computed,

      location: computed,
      position: computed,
    })
  }

  public async loadStores() {
    if (this.blockStore.entries.length === 0) {
      await this.blockStore.loadList()
    }
    if (this.bayStore.entries.length === 0) {
      await this.bayStore.loadList()
    }
    if (this.rowStore.entries.length === 0) {
      await this.rowStore.loadAll()
    }
  }

  setCurrent(actual: CurrentPosition) {
    if (actual.block && this.current.block?.id !== actual.block?.id) this.clearCurrent()

    this.current = { ...this.current, ...actual }
  }

  setContext(actual: PositionContext) {
    if (this.current.block) this.context = actual
  }

  clearCurrent() {
    this.setCurrent({ block: undefined, bay: undefined, row: undefined, tier: undefined })
    this.context = PositionContext.Block
  }

  get blocks() {
    // return this.blockStore.entries.filter(e => !e.isFlexibleZone);
    // for testing:

    // when refactor, split into zones & blocks after changin layout (flex zones underneath blocks in own flex box)
    return [
      ...this.blockStore.entries.filter(e => !e.isFlexibleZone),
      ...this.blockStore.entries.filter(e => e.isFlexibleZone),
    ]
  }

  get zones() {
    return this.blockStore.entries.filter(e => e.isFlexibleZone)
  }

  get bays() {
    if (!this.current.block) return []

    return this.bayStore.filterByYardBlock(this.current.block.id)
  }

  get rows() {
    if (!this.current.block) return []

    return this.rowStore.filterByYardBlock(this.current.block.id)
  }

  get tiers() {
    if (!this.current.block) return []

    return Array.from({ length: this.current.block.maxTier }, (_, i) => i + 1)
  }

  initPosition(position?: YardPositionDto) {
    if (!position) {
      this.clearCurrent()
      return
    }
    const block = this.blockStore.entries.find(e => e.name === position.block)
    if (block) {
      this.setCurrent({
        block: block,
        bay: this.bayStore.entries.find(e => e.yardBlockId === block.id && e.name === position.bay),
        row: this.rowStore.entries.find(e => e.yardBlockId === block.id && e.name === position.row),
        tier: position.tier ?? 1,
      })
    }
  }

  get location(): YardPositionDto | undefined {
    if (this.current.block && this.current.flexibleZone) {
      return {
        block: this.current.block?.name,
        bay: this.bayStore.entries.find(e => e.yardBlockId === this.current.block?.id)?.name,
        row: this.rowStore.entries.find(e => e.yardBlockId === this.current.block?.id)?.name,
        tier: 1,
      } as YardPositionDto
    }

    const location = {
      block: this.current.block?.name,
      bay: this.current.bay?.name,
      row: this.current.row?.name,
      tier: this.current.tier,
    } as YardPositionDto

    return location
  }

  get position() {
    return this.location?.block && this.location.bay && this.location.row && this.location.tier
      ? this.location
      : undefined
  }
}
