import { Chip, Paper, Typography } from '@mui/material'
import { PinInfo } from '@planning/rt-stores/railTrack/RailcarTrackPositionItem'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

interface IProps {
  pins: PinInfo[]
}

export const InspectRailcarPins: FC<IProps> = observer(({ pins }) => {
  const { t } = useTranslate()

  return (
    <Paper variant='outlined' sx={{ p: '1rem', mt: 2 }}>
      <Typography variant='body1' fontWeight='bold'>
        {t('railPins', 'Pins')}
      </Typography>
      {pins.map((p, i) => (
        <Chip
          sx={{
            borderRadius: 1,
            m: '0.5rem',
          }}
          key={i}
          label={`${p.length}${t('ft', 'ft')} x${p.amount}`}
        />
      ))}
    </Paper>
  )
})
