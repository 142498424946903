import {
  ContainerTurnoverDto,
  YardBlockBayDto,
  YardBlockDto,
  YardBlockSlotOccupanciesApi,
  YardPositionDto,
} from '@storage/app/api'
import { createApiClient } from '@storage/app/http-client'
import { YardBlockBayOverviewItem } from '@storage/components/yard-block-bay-overview/models'
import { action, computed, makeObservable, observable } from 'mobx'
import { YardDataStore } from './YardDataStore'

export class YardBayViewStore {
  yardDataStore: YardDataStore

  turnover?: ContainerTurnoverDto
  yardBlock?: YardBlockDto
  yardBlockBay?: YardBlockBayDto
  isLoading = false

  constructor(yardDataStore: YardDataStore) {
    makeObservable(this, {
      turnover: observable,
      isLoading: observable,
      setSelectedTurnover: action,
      bayViewData: computed,
      yardBlock: observable,
      yardBlockBay: observable,
      setSelectedContext: action,
      previous: action,
      next: action,
      hasPrevious: computed,
      hasNext: computed,
      yardBlockBays: computed,
      setSelectedBay: action,
    })

    this.yardDataStore = yardDataStore
  }

  setSelectedTurnover(turnover?: ContainerTurnoverDto) {
    this.turnover = turnover
  }

  setSelectedContext(yardBlock: YardBlockDto, yardBlockBay: YardBlockBayDto) {
    this.yardBlock = yardBlock
    this.yardBlockBay = yardBlockBay
  }

  async store(targetSlot: YardPositionDto) {
    if (this.turnover && targetSlot) {
      this.setLoading(true)
      await createApiClient(YardBlockSlotOccupanciesApi).updateContainerPosition({
        yardBlockSlotOccupanciesUpdateContainerPositionRequest: {
          containerTurnoverId: this.turnover.id,
          location: targetSlot,
        },
      })
      await this.yardDataStore.yardBlockSlotStore.loadList()
      this.setLoading(false)
    }
  }

  get bayViewData() {
    if (!this.yardBlockBay || !this.yardBlock) return []

    const yardBlockRows = this.yardDataStore.yardBlockRows.filter(
      row => row.yardBlockId === this.yardBlockBay!.yardBlockId,
    )
    const yardBlockSlots = this.yardDataStore.yardBlockSlots.filter(
      slot => slot.yardBlockBayId === this.yardBlockBay!.id,
    )

    if (yardBlockSlots.length == 0) return []

    const tiers = [...Array(this.yardBlock.maxTier).keys()]
      .map(tier => tier + 1)
      .sort((a, b) => b - a)

    return tiers.map(tier =>
      yardBlockRows.map<YardBlockBayOverviewItem>(row => {
        const slot = yardBlockSlots.find(
          slot => slot.tier === tier && slot.yardBlockRowId === row.id,
        )!

        return {
          slot: slot,
          bay: this.yardBlockBay!,
          row: row,
          block: this.yardBlock!,
          tier: tier,
          occupancies: slot?.occupiedTurnovers,
          reservations: slot?.reservedTurnovers,
          renderedTurnover:
            slot?.occupiedTurnovers.length > 0
              ? slot?.occupiedTurnovers[0]
              : slot?.reservedTurnovers.length > 0
                ? slot?.reservedTurnovers[0]
                : undefined,
        }
      }),
    )
  }

  previous() {
    if (!this.yardBlock || !this.yardBlockBay?.sequenceNumber) return

    const previousSequenceNumber = this.yardBlockBay?.sequenceNumber - 1

    this.yardBlockBay = this.yardBlockBays.find(
      bay => bay.sequenceNumber === previousSequenceNumber,
    )

    if (!this.yardBlockBay) return

    this.setSelectedContext(this.yardBlock, this.yardBlockBay)
  }

  next() {
    if (!this.yardBlock || !this.yardBlockBay?.sequenceNumber) return

    const nextSequenceNumber = this.yardBlockBay?.sequenceNumber + 1

    this.yardBlockBay = this.yardBlockBays.find(bay => bay.sequenceNumber === nextSequenceNumber)

    if (!this.yardBlockBay) return

    this.setSelectedContext(this.yardBlock, this.yardBlockBay)
  }

  get hasPrevious(): boolean {
    if (this.yardBlockBay?.sequenceNumber && this.yardBlockBay?.sequenceNumber > 1) {
      return true
    }
    return false
  }

  get hasNext(): boolean {
    if (
      this.yardBlockBay?.sequenceNumber &&
      this.yardBlock &&
      this.yardBlockBay?.sequenceNumber < this.yardBlock?.bayCount
    ) {
      return true
    }

    return false
  }

  get yardBlockBays() {
    return this.yardDataStore.yardBlockBays.filter(x => x.yardBlockId === this.yardBlock?.id)
  }

  setSelectedBay(bay: YardBlockBayDto) {
    this.yardBlockBay = bay
  }

  setLoading = (isLoading: boolean) => {
    this.isLoading = isLoading
  }
}
