import { ServiceOrdersType } from '@planning/pages/ServiceOrders/Stores/ServiceOrdersViewStore'
import { useTranslate } from '@tolgee/react'
import { ReeferIcon } from '@tom-ui/ui'
import { FC } from 'react'
import { TooltipChip } from './TooltipChip'

export const CoolingServiceTooltipChip: FC<{
  onClick: (serviceOrdersType: ServiceOrdersType) => void
}> = ({ onClick }) => {
  const { t } = useTranslate()

  return (
    <TooltipChip title={t('coolingOrder', 'Cooling order')} onClick={() => onClick('cooling')}>
      <ReeferIcon />
    </TooltipChip>
  )
}
