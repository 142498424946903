import { SelectOption } from '@admin/app/models'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from '@mui/material'
import { Controller, FieldValues, FormState, UseControllerProps } from 'react-hook-form'

export type AllSelectOption = 'All'

type FieldProps = Pick<SelectProps, 'disabled' | 'multiple' | 'size'>

interface Props<T extends FieldValues> extends UseControllerProps<T>, FieldProps {
  formState: FormState<T>
  label: string
  fullWidth?: boolean
  allOption?: boolean
  options: SelectOption[]
}

export const ControlledSelect = <T extends FieldValues>({
  name,
  control,
  label,
  disabled,
  multiple,
  fullWidth,
  options,
  size,
  allOption,
  formState: { errors },
}: Props<T>) => {
  return (
    <FormControl fullWidth={fullWidth ?? true}>
      <InputLabel>{label}</InputLabel>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Select
            {...field}
            error={!!errors[name]}
            size={size}
            multiple={multiple}
            disabled={disabled}
            label={label}
          >
            {allOption && <MenuItem value='All'>All</MenuItem>}
            {options.map(({ value, label }) => (
              <MenuItem key={label} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        )}
      />
      {errors[name] && (
        <FormHelperText error={!!errors[name]}>{errors[name]?.message as string}</FormHelperText>
      )}
    </FormControl>
  )
}
