import { action, makeObservable, observable } from 'mobx'

export class EquipmentSelectionViewStore {
  checkRequest?: NodeJS.Timeout

  constructor(
    private tallymanOperationsUIStore: any,
    private checkNotificationStore: any,
  ) {
    makeObservable(this, {
      checkRequest: observable,
      checkOrder: action,
      cancelRequest: action,
    })
  }

  checkOrder = (orderData: any, order: any) => {
    this.checkNotificationStore.createCheckInRequest(
      orderData,
      order,
      this.tallymanOperationsUIStore,
    )
  }

  cancelRequest = () => {
    clearTimeout(this.checkRequest)
    this.checkRequest = undefined
    this.checkNotificationStore.cancelRequest()
  }
}
