import { FC } from 'react'
import { ContainerJourney } from '../Stores/ContainerJourneyDataStore'
import { ServiceOrdersType } from '../Stores/ServiceOrdersViewStore'
import { ServiceOrdersSearchListCard } from './ServiceOrdersSearchListCard'

export const ServiceOrderCardCollection: FC<{
  containerJourney: ContainerJourney
  handleCardClick: (containerJourney: ContainerJourney, serviceType: ServiceOrdersType) => void
}> = ({ containerJourney, handleCardClick }) => (
  <>
    {containerJourney.strippingOrderId && (
      <ServiceOrdersSearchListCard
        key={`stripping-service-${containerJourney.id}`}
        serviceType={'stripping'}
        containerJourney={containerJourney}
        onClickCard={handleCardClick}
      />
    )}
    {containerJourney.stuffingOrderId && (
      <ServiceOrdersSearchListCard
        key={`stuffing-service-${containerJourney.id}`}
        serviceType={'stuffing'}
        containerJourney={containerJourney}
        onClickCard={handleCardClick}
      />
    )}
    {containerJourney.coolingOrderId && (
      <ServiceOrdersSearchListCard
        key={`cooling-service-${containerJourney.id}`}
        serviceType={'cooling'}
        containerJourney={containerJourney}
        onClickCard={handleCardClick}
      />
    )}
  </>
)
