import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import { YardBlockDto } from '@storage/app/api'
import { FormProps } from '@storage/app/models'
import { ControlledTextField } from '@storage/components/form-controls'
import { ControlledMultipleChipSelect } from '@storage/components/form-controls/ControlledMultipleChipSelect'
import useFormWithSchema from '@storage/hooks/use-form-with-schema.hook'
import { useTranslate } from '@tolgee/react'
import { InfoIcon } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useYardBlockUsageOptions } from '../yard-block-form/hooks/use-yard-block-usage-options.hook'
import { YardFlexibleZoneFormProfile, defaultValues } from './yard-flexible-zone-form.profile'
import { schema } from './yard-flexible-zone-form.scheme'
import { YardFlexibleZoneMapper } from './yard-flexible-zone-mapper'

interface Props extends FormProps {
  flexibleZone?: YardBlockDto
  onSubmit: (formValues: YardFlexibleZoneFormProfile) => Promise<void> | void
}

const YardFlexibleZoneForm = observer(({ id, flexibleZone, onSubmit }: Props) => {
  const { handleSubmit, control, formState, reset } =
    useFormWithSchema<YardFlexibleZoneFormProfile>(schema, defaultValues)

  const { t } = useTranslate()
  const usageOptions = useYardBlockUsageOptions()

  useEffect(() => {
    if (flexibleZone) reset(YardFlexibleZoneMapper.mapDtoToFormValues(flexibleZone))
  }, [flexibleZone, reset])

  return (
    <Box
      id={id}
      component='form'
      noValidate
      autoComplete='off'
      onSubmit={handleSubmit(onSubmit)}
      my={1}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <ControlledTextField<YardFlexibleZoneFormProfile>
          formState={formState}
          control={control}
          name={'name'}
          label={t('flexibleZoneName', 'Name')}
          endAdornment={
            <Tooltip
              title={t(
                'blockNameFieldHelpTooltip',
                'Please enter the name using numbers and letters. Special characters, spacing are not allowed. The max number of characters is 10.',
              )}
            >
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          }
        />
        <Typography variant='subtitle1'>{t('usageOptions', 'Usage Options')}</Typography>
        <ControlledMultipleChipSelect
          formState={formState}
          control={control}
          options={usageOptions}
          name='usageOptions'
        />
      </Box>
    </Box>
  )
})

export default YardFlexibleZoneForm
