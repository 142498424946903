import { CarrierType, CarrierVisitDirection } from '@planning/app/api'
import { CarrierVisitViewStatus } from '@planning/constants'
import { IFilterDelegate, ISortDelegate } from '@planning/stores/PaginatedLocalStore'
import { SortingModel } from '@planning/stores/PaginatedStore'
import dayjs from 'dayjs'
import _ from 'lodash'
import { computed, makeObservable } from 'mobx'
import moment from 'moment'
import { getCarrierVisitViewStatus } from '../helpers'
import { IRailVisitItem } from '../railVisit/RailVisitItem'
import { IEntityStore } from '../types'
import { IVesselVisitItem } from '../vesselVisit/VesselVisitItem'

export const visitSortingDelegate: ISortDelegate<VisitItem> = (sortingModel, a, b) => {
  const getValue = (item: VisitItem, sortingModel: SortingModel<VisitItem>) => {
    if (sortingModel.orderBy) {
      if (_.has(item, sortingModel.orderBy)) return _.get(item, sortingModel.orderBy)
      if (_.has(item.data, sortingModel.orderBy)) return _.get(item.data, sortingModel.orderBy)
    }
    return item.arrival
  }

  const valA = getValue(a, sortingModel)
  const valB = getValue(b, sortingModel)

  let sort = 0
  if (moment(valA).isValid() && moment(valB).isValid()) {
    sort = moment(valA).diff(moment(valB))
  } else if (typeof valA === 'string' && typeof valB === 'string') {
    sort = valA.localeCompare(valB)
  }

  return sortingModel.isDescending ? -sort : sort
}

export const visitFilterDelegate: IFilterDelegate<VisitItem> = (
  filter: string,
  item: VisitItem,
  showCompleted?: boolean,
  from?: Date,
  to?: Date,
  filterStatus?: string,
) => {
  const filterableProperties: string[] = ['data.cargoType', 'carrierName', 'shippingLine']

  if (filterStatus && item.data?.status !== filterStatus) {
    return false
  }

  if (dayjs(from) > dayjs(item.arrival) || dayjs(item.arrival) > dayjs(to)) {
    return false
  }

  if (filter !== '') {
    return filterableProperties
      .map(p => p as keyof VisitItem)
      .some(p => {
        const prop = _.get(item, p)

        if (typeof prop === 'string') return prop.toLowerCase().includes(filter.toLowerCase())
        if (typeof prop === 'number') return prop === parseFloat(filter)

        return false
      })
  }

  return true
}

export type VisitItem = {
  carrierType: CarrierType
  arrival: string | null | undefined
  departure: string | null | undefined
  carrierName?: string | null
  shippingLine?: string | null
  dischargeCount: number
  loadCount: number
  inboundTripIds: string[]
  outboundTripIds: string[]
  berthOrTrack: string
  status: CarrierVisitViewStatus
} & (Partial<IVesselVisitItem> | Partial<IRailVisitItem>)

const noneIndicator = 'None'

export class VisitViewStore {
  constructor(
    private vesselVisitStore: IEntityStore<IVesselVisitItem>,
    private railVisitStore: IEntityStore<IRailVisitItem>,
  ) {
    makeObservable(this, {
      elements: computed,
    })
  }

  getDischargeCount = (visit: IVesselVisitItem | IRailVisitItem) => {
    const total = visit.getCount('total', CarrierVisitDirection.Inbound, visit.data.cargoType)

    if (total === 0 || total == null) {
      return visit.discharge.orderCounts.estimated
    }
    return total
  }

  getLoadCount = (visit: IVesselVisitItem | IRailVisitItem) => {
    const total = visit.getCount('total', CarrierVisitDirection.Outbound, visit.data.cargoType)

    if (total === 0 || total == null) {
      return visit.load.orderCounts.estimated
    }
    return total
  }

  get elements(): VisitItem[] {
    const mergedVisits: VisitItem[] = [
      ..._(this.vesselVisitStore?.elements)
        .map(v => ({
          ...v,
          arrival: v.arrival,
          departure: v.departure,
          carrierType: CarrierType.Vessel,
          carrierName:
            v.vessels.length === 1
              ? v.vessels[0]?.data.name
              : `${v.vessels[0]?.data.name} +${v.vessels?.length - 1}`,
          shippingLine: v.vessels[0]?.data.shippingLine,
          dischargeCount: this.getDischargeCount(v),
          loadCount: this.getLoadCount(v),
          inboundTripIds: v.data.inboundTripIds ?? [],
          outboundTripIds: v.data.outboundTripIds ?? [],
          vessels: v.vessels,
          berthOrTrack: `${v.berths?.length ? v.berths.map(b => b.name).join(', ') : noneIndicator} ${
            v.data?.berthSide ? '(' + v.data?.berthSide + ')' : ''
          }`,
          status: getCarrierVisitViewStatus(v.data.status, v.data.atb),
        }))
        .value(),
      ..._(this.railVisitStore?.elements)
        .map(v => ({
          ...v,
          arrival: v.arrival,
          departure: v.departure,
          carrierType: CarrierType.Train,
          carrierName: v.data.name,
          dischargeCount: this.getDischargeCount(v),
          loadCount: this.getLoadCount(v),
          inboundTripIds: v.data.inboundTripIds ?? [],
          outboundTripIds: v.data.outboundTripIds ?? [],
          berthOrTrack: `${v.railTracks.length ? v.railTracks.map(rt => rt.name).join(', ') : noneIndicator}`,
          status: v.data.status,
        }))
        .value(),
    ]

    return mergedVisits
  }
}
