import { Grid } from '@mui/material'
import { CarrierType, CarrierVisitDirection } from '@planning/app/api'
import { FeatureFlag } from '@planning/components/FeatureFlags'
import { RailTracksPlanningView } from '@planning/pages/RailVisit/Components/RailTracksPlanningView'
import { OrderMovementEditableCard } from '@planning/pages/VesselVisit/Components/OrderMovementEditableCard'
import { IRailVisitItem } from '@planning/rt-stores/railVisit/RailVisitItem'
import { IVesselVisitItem } from '@planning/rt-stores/vesselVisit/VesselVisitItem'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { vesselVisitDetailPages } from '../VesselVisitsPage'
import { IDetailPages } from './CarrierVisitActionBar'
import { VisitBreak } from './VisitBreak'

export const CarrierVisitDashboard: FC<{
  visitType: CarrierType
  detailPages?: IDetailPages
  vesselVisitItem?: IVesselVisitItem
  railVisitItem?: IRailVisitItem
  handlePageChange: (page: string) => void
}> = observer(({ vesselVisitItem, railVisitItem, visitType, detailPages, handlePageChange }) => {
  if (!vesselVisitItem && !railVisitItem) return <></>
  const selectDetailPages = detailPages ?? vesselVisitDetailPages

  return (
    <>
      <Grid container justifyContent='flex-start' spacing={{ xs: 1, md: 3 }} padding='1.5rem'>
        <Grid item sm={12} md={6} lg={4}>
          <OrderMovementEditableCard
            visitType={visitType}
            vesselVisitItem={vesselVisitItem}
            railVisitItem={railVisitItem}
            direction={CarrierVisitDirection.Inbound}
            onViewClick={() => handlePageChange(selectDetailPages.discharge)}
          />
        </Grid>
        <Grid item sm={12} md={6} lg={4}>
          <OrderMovementEditableCard
            visitType={visitType}
            vesselVisitItem={vesselVisitItem}
            railVisitItem={railVisitItem}
            direction={CarrierVisitDirection.Outbound}
            onViewClick={() => handlePageChange(selectDetailPages.load)}
          />
        </Grid>

        <FeatureFlag name='visit-break-info-display'>
          <Grid item sm={12} md={6} lg={4}>
            <VisitBreak vesselVisitItem={vesselVisitItem} railVisitItem={railVisitItem} />
          </Grid>
        </FeatureFlag>

        <FeatureFlag name='rail-orders'>
          {railVisitItem && (
            <Grid item sm={12} md={6} lg={4}>
              <RailTracksPlanningView railVisit={railVisitItem} />
            </Grid>
          )}
        </FeatureFlag>
      </Grid>
    </>
  )
})
