import { SxProps, Theme, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { OrderStatus } from '@planning/app/api'
import { useMinimalsTheme } from '@tom-ui/ui'

interface Props {
  status: string
  sx?: SxProps<Theme>
}

export const OrderStatusChip = ({ status, sx }: Props) => {
  const theme = useMinimalsTheme()

  const getChipBg = (value: string) => {
    switch (value) {
      case OrderStatus.Open:
        return theme.palette.info.lighter
      case OrderStatus.Fulfilled:
        return theme.palette.success.lighter
      default:
        return theme.palette.grey[200]
    }
  }

  const getChipColor = (value: string) => {
    switch (value) {
      case OrderStatus.Open:
        return theme.palette.info.dark
      case OrderStatus.Fulfilled:
        return theme.palette.success.dark
      default:
        return theme.palette.grey[600]
    }
  }

  return (
    <Box
      sx={{
        background: getChipBg(status),
        padding: '4px 8px',
        borderRadius: '4px',
        display: 'flex',
        height: '32px',
      }}
    >
      <Typography
        fontSize={'14px'}
        alignSelf={'center'}
        alignItems={'center'}
        fontWeight={500}
        color={getChipColor(status)}
      >
        {status}
      </Typography>
    </Box>
  )
}
