import {
  CreateGeneralCargoAreaDtoGeometry,
  GeneralCargoAreaType,
  GeneralCargoLocationDto,
} from '@storage/app/api'

export interface GeneralCargoAreaFormProfile {
  id?: string
  name: string
  type: GeneralCargoAreaType
  geometry?: CreateGeneralCargoAreaDtoGeometry
  locations: GeneralCargoLocationDto[]
}

export const defaultGCLocationValue: GeneralCargoLocationDto = {
  id: null,
  name: '',
}

export const defaultValues: GeneralCargoAreaFormProfile = {
  name: '',
  type: GeneralCargoAreaType.Warehouse,
  locations: [defaultGCLocationValue],
  // TODO: to remove
  geometry: {
    top: 0,
    left: 0,
    rotation: 0,
    width: 0,
  },
}
