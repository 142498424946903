import { CreateWeightClassDto, WeightClassDto } from '@storage/app/api'
import { WeightClass, WeightClassFormProfile } from './weight-classes-form.profile'

const mapWeightClassDtoToWeightClass = ({
  id,
  name,
  minWeight,
  maxWeight,
}: WeightClassDto): WeightClass => ({
  id,
  name,
  min: minWeight,
  max: maxWeight!,
  maxFieldIsInfinite: maxWeight ? false : true,
})

export const mapWeightClassDtoListToWeightClassFormProfile = (
  WeightClassDtos: WeightClassDto[],
): WeightClassFormProfile => ({
  weightClasses: WeightClassDtos.map(mapWeightClassDtoToWeightClass),
})

export const mapWeightClassFormProfileToCreateWeightClassDto = (
  weightClassFormProfile: WeightClassFormProfile,
): CreateWeightClassDto[] => {
  return weightClassFormProfile.weightClasses.map(weightClass => ({
    name: weightClass.name,
    minWeight: weightClass.min!,
    maxWeight: weightClass.max,
  }))
}
