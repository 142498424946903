import {
  BerthResponseDto,
  CargoType,
  CarrierVisitStatus,
  CreateVesselVisitCommand,
  UpdateVesselVisitCommand,
  UpsertOrderListCommandV2,
  VesselVisitsApi,
} from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'

class VesselVisitService {
  httpClient = createApiClient(VesselVisitsApi)

  async get(page: number, pageSize: number, filter?: string, cargoType?: CargoType) {
    const { data } = await this.httpClient.get(page, pageSize, undefined, cargoType, filter)

    return data
  }

  async getById(id: number) {
    const { data } = await this.httpClient.getById(id)

    return data
  }

  async post(cmd: CreateVesselVisitCommand) {
    const { data } = await this.httpClient.create(cmd)
    return data
  }

  async put(cmd: UpdateVesselVisitCommand) {
    await this.httpClient.put(cmd)
  }

  assignBerths = async (vesselVisitId: number, berths: BerthResponseDto[]) => {
    await this.httpClient.updateBerths(vesselVisitId, {
      vesselVisitId,
      berthIds: berths.map(b => b.id),
    })
  }

  async startOperations(vesselVisitId: number) {
    await this.httpClient.updateVesselVisitStatus(vesselVisitId, {
      id: vesselVisitId,
      status: CarrierVisitStatus.InOperation,
    })
  }

  async endOperations(id: number) {
    await this.httpClient.operationEnd(id)
  }

  async updateVesselVisitStatus(vesselVisitId: number, status: CarrierVisitStatus) {
    await this.httpClient.updateVesselVisitStatus(vesselVisitId, {
      id: vesselVisitId,
      status: status,
    })
  }

  async upsertOrderList(upsertOrderListCommand: UpsertOrderListCommandV2) {
    await this.httpClient.upsertOrderList(upsertOrderListCommand)
  }

  async upsertOrderListV2(upsertOrderListCommand: UpsertOrderListCommandV2) {
    await this.httpClient.orders(upsertOrderListCommand)
  }

  getByIds = async (ids: Array<number>) => await this.httpClient.getByIds(ids)
}

const vesselVisitService = new VesselVisitService()

export default vesselVisitService
