import { ContainerService, OperationType, WorkInstructionJobDto } from '@operations/app/api'
import {
  JobTypes,
  doesJobOperationTypeOrCarrierMatchesJobType,
  getWorkInstructionJobAmountByType,
} from '@operations/app/models/operator-pages'
import { WorkInstructionStore } from '@operations/stores/WorkInstructionStore'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { WorkInstructionJobGroupDto } from '../models/job-group.model'
import { JobNavigationDto } from '../models/job-navigation.model'
import { EquipmentOperatorLandingUIStore } from './equipment-operator-landing.ui-store'
import { EquipmentOperatorSearchUIStore } from './equipment-operator-search.ui-store'

export class EquipmentOperatorWorkInstructionsUIStore {
  items: WorkInstructionJobDto[] = []
  hiddenJobWorkInstructionIds: number[] = []
  jobType: JobTypes = JobTypes.vessel

  constructor(
    private workInstructionStore: WorkInstructionStore,
    private equipmentOperatorSearchUIStore: EquipmentOperatorSearchUIStore,
    public equipmentOperatorLandingUIStore: EquipmentOperatorLandingUIStore,
  ) {
    makeObservable(this, {
      items: observable,

      hiddenJobWorkInstructionIds: observable,
      jobType: observable,

      handleFirstSelectedJobType: action,
      hideJob: action,
      loadJobs: action,
      setJobType: action,
      setOperationType: action,
      showJob: action,

      carrierTypeFilteredJobsNavigationDto: computed,
      filteredJobs: computed,
      jobsWithWIIds: computed,
      searchedAndFilteredJobs: computed,
    })
  }

  updateStoreItems(items: WorkInstructionJobDto[]) {
    runInAction(() => {
      this.items = items ?? []
    })
  }

  updateStoreItem(item: WorkInstructionJobDto, workInstructionId: number) {
    const existingItems = this.items.find(i => i.workInstructionId === workInstructionId)
    runInAction(() => {
      if (existingItems)
        this.items = this.items.map(i => (i.workInstructionId === workInstructionId ? item : i))
      else this.items.push(item)
    })
  }

  deleteStoreItems(workInstructionIds?: number[]) {
    if (!workInstructionIds) return
    runInAction(() => {
      this.items = this.items.filter(i => !workInstructionIds.includes(+i.workInstructionId))
    })
  }

  loadJobs = async () => {
    const workInstructions = await this.workInstructionStore.loadWorkInstructionsAsJobs()
    this.updateStoreItems(workInstructions)
  }

  public hideJob(workInstructionId?: number) {
    if (workInstructionId) {
      this.hiddenJobWorkInstructionIds = [...this.hiddenJobWorkInstructionIds, workInstructionId]
    }
  }

  public showJob(workInstructionId?: number) {
    this.hiddenJobWorkInstructionIds = this.hiddenJobWorkInstructionIds.filter(
      x => x !== workInstructionId,
    )
  }

  public setJobType(jobType: JobTypes): void {
    if (jobType !== this.jobType) {
      this.jobType = jobType
      this.equipmentOperatorLandingUIStore.selectOperationType(null)
    }
  }

  public setOperationType(operationType?: OperationType): void {
    this.equipmentOperatorLandingUIStore.selectOperationType(operationType ?? null)
  }

  public handleFirstSelectedJobType() {
    if (this.jobsWithWIIds.length === 0) {
      return
    }

    Object.entries(JobTypes).every(x => {
      if (getWorkInstructionJobAmountByType(x[1], this.jobsWithWIIds) > 0) {
        this.setJobType(x[1])
        return false
      }

      return true
    })
  }

  public getWorkInstructionJobDto(workInstructionId: number) {
    return this.items.find(x => x.workInstructionId === workInstructionId)
  }

  public closeSearchIfFinishingLastNotification(jobWorkInstructionId: number) {
    if (
      this.equipmentOperatorSearchUIStore.isSearchOpen &&
      this.equipmentOperatorSearchUIStore.selectedJobWorkInstructionIds?.includes(
        jobWorkInstructionId,
      ) &&
      this.searchedAndFilteredJobs.flatMap(x => x.jobs).length === 0
    ) {
      this.equipmentOperatorSearchUIStore.toggleSearch()
    }
  }

  public get jobsWithWIIds() {
    return this.items.filter(x => !this.hiddenJobWorkInstructionIds.includes(x.workInstructionId))
  }

  public get searchedAndFilteredJobs() {
    if (this.equipmentOperatorSearchUIStore.selectedJobWorkInstructionIds) {
      return createEmptyGroupToShowAllJobs(
        this.jobsWithWIIds.filter(x =>
          this.equipmentOperatorSearchUIStore.selectedJobWorkInstructionIds?.includes(
            x.workInstructionId,
          ),
        ),
      )
    }

    if (this.equipmentOperatorSearchUIStore.searchText) {
      const searchedContainerLowerCase =
        this.equipmentOperatorSearchUIStore.searchText.toLowerCase()

      const searchedContainerWithoutSpace = searchedContainerLowerCase.replace(/\s/g, '')

      return createEmptyGroupToShowAllJobs(
        this.jobsWithWIIds.filter(
          x =>
            x.displayName?.toLowerCase()?.includes(searchedContainerWithoutSpace) ||
            x.cargoUnit.lotNumber?.toLowerCase()?.includes(searchedContainerWithoutSpace) ||
            x.order?.referenceNumber?.toLowerCase()?.includes(searchedContainerLowerCase) ||
            x.carrierVisit?.carrierName?.toLowerCase()?.includes(searchedContainerLowerCase) ||
            this.searchByContainerNumber(x, searchedContainerLowerCase),
        ),
      )
    }

    return []
  }

  public get carrierTypeFilteredJobsNavigationDto(): JobNavigationDto {
    const dischargeAmount = this.jobsFilteredByJobType.filter(
      x => x.operationType === OperationType.Inbound,
    ).length

    const loadAmount = this.jobsFilteredByJobType.filter(
      x => x.operationType === OperationType.Outbound,
    ).length

    return {
      dischargeAmount,
      loadAmount,
    }
  }

  public get filteredJobs(): WorkInstructionJobGroupDto[] {
    const filteredJobs = this.jobsFilteredByJobType.filter(
      x =>
        !this.equipmentOperatorLandingUIStore.selectedOperationType ||
        x.operationType === this.equipmentOperatorLandingUIStore.selectedOperationType,
    )

    return this.jobType === JobTypes.service
      ? this.groupAndSortJobsByContainer(filteredJobs)
      : this.groupAndSortJobsByCarrierVisit(filteredJobs)
  }

  private groupAndSortJobsByContainer(jobs: WorkInstructionJobDto[]) {
    const jobGroups = jobs.reduce((groups: WorkInstructionJobGroupDto[], job) => {
      const containerNumber = this.getContainerNumberFromJob(job)

      const groupName = containerNumber ?? ''
      const existingGroup = groups.find(group => group.identifier === containerNumber)

      if (existingGroup) {
        existingGroup.jobs.push(job)
      } else {
        groups.push({ identifier: containerNumber!, groupName, jobs: [job] })
      }

      return groups
    }, [])

    return jobGroups
  }

  private getContainerNumberFromJob(job: WorkInstructionJobDto) {
    const location = (
      job.service?.type === ContainerService.Stuffing ? job.destination : job.origin
    ).split(' - ')
    if (location.length >= 1) return location[1].trim()

    return ''
  }

  private groupAndSortJobsByCarrierVisit(jobs: WorkInstructionJobDto[]) {
    const jobGroups = jobs.reduce((groups: WorkInstructionJobGroupDto[], job) => {
      const carrierVisitId = job.carrierVisit?.id

      const groupName = job.carrierVisit?.carrierName ?? ''
      const existingGroup = groups.find(group => group.identifier === carrierVisitId)

      if (existingGroup) {
        existingGroup.jobs.push(job)
      } else {
        groups.push({ identifier: carrierVisitId!, groupName, jobs: [job] })
      }

      return groups
    }, [])

    return jobGroups.sort((a, b) => {
      const aAta = new Date(a.jobs[0].carrierVisit?.ata ?? '')
      const bAta = new Date(b.jobs[0].carrierVisit?.ata ?? '')

      return aAta.getTime() - bAta.getTime()
    })
  }

  private searchByContainerNumber(job: WorkInstructionJobDto, containerNumber: string) {
    return job.operationType === OperationType.Service
      ? this.getContainerNumberFromJob(job).toLowerCase().includes(containerNumber)
      : false
  }

  private get jobsFilteredByJobType() {
    return this.jobsWithWIIds.filter(x =>
      doesJobOperationTypeOrCarrierMatchesJobType(
        this.jobType,
        x.operationType,
        x.carrierVisit?.type,
      ),
    )
  }
}

const createEmptyGroupToShowAllJobs = (
  jobs: WorkInstructionJobDto[],
): WorkInstructionJobGroupDto[] => {
  return [
    {
      identifier: 0,
      groupName: '',
      jobs,
    },
  ]
}
