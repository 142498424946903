// @mui
//

// ----------------------------------------------------------------------

export interface CustomSpacingOptions {
  xxxs: '2px'
  xxs: '4px'
  xs: '8px'
  s: '12px'
  m: '16px'
  l: '24px'
  xl: '32px'
  xll: '40px'
  xxl: '48px'
}

// ----------------------------------------------------------------------

export default function customSpacing() {
  return {
    xxxs: '2px',
    xxs: '4px',
    xs: '8px',
    s: '12px',
    m: '16px',
    l: '24px',
    xl: '32px',
    xll: '40px',
    xxl: '48px',
  } as CustomSpacingOptions
}
