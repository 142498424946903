import { useStores } from '@storage/hooks/use-stores.hook'
import { AlertStore } from '@storage/stores/alert.store'
import { useMemo } from 'react'
import { containerPlanningService } from '@storage/services'
import { ContainerTurnoversDashboardStore } from '../stores/container-turnovers-dashboard.store'
import { ContainerTurnoversFilterStore } from '../stores/container-turnovers-filter.store'
import { ContainerTurnoversListStore } from '../stores/container-turnovers-list.store'
import { ContainerTurnoversPaginationStore } from '../stores/container-turnovers-pagination.store'
import { ManualInputValidationStore } from '../stores/manual-input-validation.store'
import { ManualPlanningDialogStore } from '../stores/manual-planning-dialog.store'

export const useContainerTurnoversStores = () => {
  const {
    containerTurnoverStore,
    yardBlockStore,
    yardBlockBayStore,
    yardBlockRowStore,
    snackbarStore,
    tenantConfigStore,
  } = useStores()

  const dashboardStore = useMemo(() => new ContainerTurnoversDashboardStore(), [])

  const containerTurnoversPaginationStore = useMemo(
    () => new ContainerTurnoversPaginationStore(),
    [],
  )

  const containerTurnoversFilterStore = useMemo(
    () => new ContainerTurnoversFilterStore(containerTurnoversPaginationStore),
    [containerTurnoversPaginationStore],
  )

  const containerTurnoversListStore = useMemo(
    () =>
      new ContainerTurnoversListStore(
        containerTurnoverStore,
        containerTurnoversFilterStore,
        containerTurnoversPaginationStore,
      ),
    [containerTurnoverStore, containerTurnoversFilterStore, containerTurnoversPaginationStore],
  )

  const manualInputValidationStore = useMemo(
    () =>
      new ManualInputValidationStore(
        yardBlockStore,
        yardBlockBayStore,
        yardBlockRowStore,
        tenantConfigStore,
      ),
    [yardBlockStore, yardBlockBayStore, yardBlockRowStore, tenantConfigStore],
  )

  const alertStore = useMemo(() => new AlertStore(), [])

  const manualPlanningDialogStore = useMemo(
    () =>
      new ManualPlanningDialogStore(
        containerPlanningService,
        containerTurnoversListStore,
        containerTurnoversFilterStore,
        containerTurnoverStore,
        manualInputValidationStore,
        tenantConfigStore,
        yardBlockStore,
        yardBlockBayStore,
        snackbarStore,
        alertStore,
      ),
    [
      containerTurnoversListStore,
      containerTurnoversFilterStore,
      containerTurnoverStore,
      manualInputValidationStore,
      tenantConfigStore,
      yardBlockStore,
      yardBlockBayStore,
      snackbarStore,
      alertStore,
    ],
  )

  return {
    containerTurnoversFilterStore,
    containerTurnoversPaginationStore,
    dashboardStore,
    manualPlanningDialogStore,
    containerTurnoversListStore,
    manualInputValidationStore,
  }
}
