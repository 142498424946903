/* eslint-disable no-console */
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { Grid } from '@mui/material'
import { useOperationsStore } from '@operations/AppProvider'
import { BerthSide, WorkInstructionDto } from '@operations/app/api'
import { Submenu } from '@operations/components/submenu/Submenu'
import { CraneSplitActions } from '@operations/features/craneSplit/components/CraneSplitActions/CraneSplitActions'
import { CraneSplitContainer } from '@operations/features/craneSplit/containers/CraneSplitContainer'
import { CraneSplitDialogContainer } from '@operations/features/craneSplit/containers/CraneSplitDialogContainer'
import { CraneSplitDrawerContainer } from '@operations/features/craneSplit/containers/CraneSplitDrawerContainer'
import { CraneSplitErrorMessageContainer } from '@operations/features/craneSplit/containers/CraneSplitErrorMessageContainer'
import { SelectCranesDialog } from '@operations/features/craneSplit/containers/SelectCranesDialog'
import { EquipmentPlanningAvailableEquipmentsContainer } from '@operations/features/equipmentPlanning/containers/EquipmentPlanningAvailableEquipmentsContainer'
import { EquipmentPlanningHeaderContainer } from '@operations/features/equipmentPlanning/containers/EquipmentPlanningHeaderContainer'
import { EquipmentPlanningWorkAreaEquipmentsContainer } from '@operations/features/equipmentPlanning/containers/EquipmentPlanningWorkAreaEquipmentsContainer'
import { WorkInstructionsContainer } from '@operations/features/job/containers/WorkInstructionsContainer'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { MRT_TableInstance } from 'material-react-table'
import { FC, useEffect, useRef } from 'react'
import {
  DragDropContext,
  DragStart,
  DraggableLocation,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router'
const planningPages = {
  equipments: 'equipments',
  jobs: 'jobs',
  craneSplit: 'craneSplit',
}

const EquipmentPlanning: FC = withAuthenticationRequired(() => {
  const theme = useMinimalsTheme()
  const { equipmentPlanningUIStore } = useOperationsStore()
  const workInstructionsTableInstanceRef = useRef<MRT_TableInstance<WorkInstructionDto> | null>(
    null,
  )

  const { id, page } = useParams()
  const { t } = useTranslate()

  const currentPage =
    Object.keys(planningPages).find(key => key.toLowerCase() === page?.toLowerCase()) ??
    planningPages.craneSplit

  const submenu = (
    <Submenu
      dataCy='equipment-planning-submenu-dropdown'
      options={[
        { label: t('craneSplit'), value: `/EquipmentPlanning/${id}/craneSplit` },
        { label: t('equipment'), value: `/EquipmentPlanning/${id}/equipments` },
        { label: t('jobs'), value: `/EquipmentPlanning/${id}/jobs` },
      ]}
    />
  )

  useEffect(() => {
    const vesselVisitId = +id!
    const fetchData = async () => {
      equipmentPlanningUIStore.selectCranesDialogUIStore.toggle(false)
      equipmentPlanningUIStore.craneSplitMessageUIStore.setMessage()

      await equipmentPlanningUIStore.fetchData(vesselVisitId)

      if (currentPage === planningPages.craneSplit) {
        if (
          !equipmentPlanningUIStore.craneSplitContainerUIStore.calculatedBays.length ||
          !equipmentPlanningUIStore.selectCranesDialogUIStore.vesselVisit?.berthSide ||
          equipmentPlanningUIStore.selectCranesDialogUIStore.vesselVisit?.berthSide ===
            BerthSide.Unspecified ||
          equipmentPlanningUIStore.selectCranesDialogUIStore.vesselVisit?.berthNames.length === 0
        )
          equipmentPlanningUIStore.selectCranesDialogUIStore.toggleWithRedirect()
        else if (
          equipmentPlanningUIStore.selectCranesDialogUIStore.vesselVisit?.berthSide !==
          equipmentPlanningUIStore.selectCranesDialogUIStore.vesselVisit?.craneSplitBerthSide
        )
          equipmentPlanningUIStore.selectCranesDialogUIStore.toggleWithRedirect(
            equipmentPlanningUIStore.craneSplitContainerUIStore.craneIds,
          )
      }
    }

    fetchData()
  }, [id, currentPage, equipmentPlanningUIStore])

  const handleDragStart = (start: DragStart) => {
    const id = +start.draggableId.split('_')[1]
    equipmentPlanningUIStore.equipmentPlanningEquipmentsUIStore.startDragging(id)
  }

  const handleDragEnd = (result: DropResult, _provided?: ResponderProvided) => {
    const destination: DraggableLocation | undefined | null = result.destination
    const origin: DraggableLocation | undefined | null = result.source
    const previousWorkAreaId: number | null = origin ? +origin.droppableId.substring(3) : null
    const newWorkAreaId: number | null = destination ? +destination.droppableId.substring(3) : null
    const isCancelled = result.reason === 'CANCEL'

    equipmentPlanningUIStore.equipmentPlanningEquipmentsUIStore.endDragging(
      isCancelled,
      newWorkAreaId === 0 ? null : newWorkAreaId, // for unassigned column droppableId is 0
      previousWorkAreaId === 0 ? null : previousWorkAreaId,
      destination?.index,
    )
  }

  return (
    <>
      <Helmet>
        <title>{t('equipmentPlanning', 'Equipment Planning')}</title>
      </Helmet>

      <Grid container columnSpacing={3} rowSpacing={2}>
        <Grid item={true} xs={12}>
          <EquipmentPlanningHeaderContainer
            uiStore={equipmentPlanningUIStore.equipmentPlanningInfoUIStore}
          />
        </Grid>
      </Grid>
      {currentPage === planningPages.equipments && (
        <>
          <Grid container>
            <Grid item={true} xs={12} sx={{ margin: '0.5rem' }}>
              {submenu}
            </Grid>
          </Grid>
          <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
            <Grid
              container
              columnSpacing={3}
              rowSpacing={2}
              sx={{ minHeight: '70vh', padding: theme.spacing(1) }}
            >
              <Grid
                item={true}
                xs={12}
                md={3}
                sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
              >
                <EquipmentPlanningAvailableEquipmentsContainer
                  uiStore={equipmentPlanningUIStore.equipmentPlanningEquipmentsUIStore}
                />
              </Grid>
              <Grid item={true} xs={12} md={9}>
                <EquipmentPlanningWorkAreaEquipmentsContainer
                  uiStore={equipmentPlanningUIStore.equipmentPlanningEquipmentsUIStore}
                ></EquipmentPlanningWorkAreaEquipmentsContainer>
              </Grid>
            </Grid>
          </DragDropContext>
        </>
      )}
      {currentPage === planningPages.craneSplit && (
        <>
          <Grid container>
            <Grid
              item={true}
              xs={12}
              sx={{ margin: '0.5rem', display: 'flex', justifyContent: 'space-between' }}
            >
              {submenu}
              <CraneSplitActions
                uiDialogStore={equipmentPlanningUIStore.craneSplitDialogUIStore}
                uiDrawerStore={equipmentPlanningUIStore.craneSplitDrawerUIStore}
                uiContainerStore={equipmentPlanningUIStore.craneSplitContainerUIStore}
                uiMessageStore={equipmentPlanningUIStore.craneSplitMessageUIStore}
                uiSelectCranesDialogStore={equipmentPlanningUIStore.selectCranesDialogUIStore}
              />
            </Grid>
            <CraneSplitErrorMessageContainer
              uiStore={equipmentPlanningUIStore.craneSplitMessageUIStore}
            />
          </Grid>
          <>
            <CraneSplitContainer
              uiContainerStore={equipmentPlanningUIStore.craneSplitContainerUIStore}
              uiDrawerStore={equipmentPlanningUIStore.craneSplitDrawerUIStore}
              uiDialogStore={equipmentPlanningUIStore.craneSplitDialogUIStore}
            />
            <CraneSplitDialogContainer
              uiStore={equipmentPlanningUIStore.craneSplitDialogUIStore}
              uiDrawerStore={equipmentPlanningUIStore.craneSplitDrawerUIStore}
            />
            <CraneSplitDrawerContainer
              uiStore={equipmentPlanningUIStore.craneSplitDrawerUIStore}
              uiDialogStore={equipmentPlanningUIStore.craneSplitDialogUIStore}
            />
            <SelectCranesDialog
              uiStore={equipmentPlanningUIStore.selectCranesDialogUIStore}
              uiMessageStore={equipmentPlanningUIStore.craneSplitMessageUIStore}
              uiContainerStore={equipmentPlanningUIStore.craneSplitContainerUIStore}
              uiEquipmentPlanningStore={equipmentPlanningUIStore.equipmentPlanningEquipmentsUIStore}
            />
          </>
        </>
      )}
      {currentPage === planningPages.jobs && (
        <WorkInstructionsContainer
          store={equipmentPlanningUIStore.workInstructionUIStore}
          filterStore={equipmentPlanningUIStore.workInstructionFiltersUIStore}
          subMenu={submenu}
          workInstructionsTableInstanceRef={workInstructionsTableInstanceRef}
        />
      )}
    </>
  )
})

export default EquipmentPlanning
