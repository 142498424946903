import { CarrierVisitStatus, RailTrackResponseDto, RailVisitResponseDto } from '@planning/app/api'
import {
  getPixelsPerHour,
  getXAxisAndWidthForVisit,
  isDateWithinRange,
} from '@planning/components/carrier-visit-planning/carrier-visit-planning.helper'
import { IRailPlanning } from '@planning/components/carrier-visit-planning/CarrierVisitPlanning.model'
import { RailcarTrackPositionItemStore } from '@planning/rt-stores/railTrack/RailcarTrackPositionItemStore'
import { RailTrackItemStore } from '@planning/rt-stores/railTrack/RailTrackItemStore'
import { RailVisitItemStore } from '@planning/rt-stores/railVisit/RailVisitItemStore'
import { IRailVisitPlanningFormData } from '@planning/rt-stores/railVisit/RailVisitPlanningStore'
import dayjs from 'dayjs'
import _ from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'

export interface IRailVisitPlanning {
  railTrack: RailTrackResponseDto
  visits: IRailPlanning[]
}

// [Test] TODO: UT the stores pls!
export class RailVisitPlanningViewStore {
  pixelPerWaterMark = 1
  startDate = dayjs().startOf('day').toDate()
  daysVisualization = 4
  visualizationWidth = 700
  isDialogOpen = false
  visitSelectedId = 0
  nextVisitIdToBeEdited = 0

  currentFormValues?: IRailVisitPlanningFormData

  constructor(
    private railTrackItemStore: RailTrackItemStore,
    private railVisitItemStore: RailVisitItemStore,
    private railcarTrackPositionItemStore: RailcarTrackPositionItemStore,
  ) {
    makeObservable(this, {
      startDate: observable,
      daysVisualization: observable,
      visualizationWidth: observable,
      currentFormValues: observable,
      isDialogOpen: observable,
      visitSelectedId: observable,
      nextVisitIdToBeEdited: observable,

      plannedVisits: computed,
      endDate: computed,
      pixelPerHour: computed,
      emptySpaceHeight: computed,
      railTrackHeight: computed,
      isRailcarLengthSumExceedsAnyRailtrackLength: computed,
      isAnyRailcarOfVisitWithoutLength: computed,

      toggleConfirmationDialog: action,
      setDaysVisualization: action,
      setVisualizationWidth: action,
      setStartDate: action,
      loadNextDate: action,
      loadPrevDate: action,
      setFormValues: action,
      setVisitSelectedId: action,
      setNextVisitIdToBeEdited: action,
    })
  }

  toggleConfirmationDialog = (shouldOpen: boolean) => {
    this.isDialogOpen = shouldOpen
  }

  setDaysVisualization(value: number) {
    this.daysVisualization = value
  }

  setVisualizationWidth(value: number) {
    this.visualizationWidth = value
  }

  setStartDate(date: Date) {
    const midnightDate = dayjs(date).startOf('day').toDate()
    this.startDate = midnightDate
    this.fetchVisits()
  }

  loadNextDate() {
    const newDate = dayjs(this.startDate).add(this.daysVisualization, 'day').toDate()
    this.setStartDate(newDate)
  }

  loadPrevDate() {
    const newDate = dayjs(this.startDate).subtract(this.daysVisualization, 'day').toDate()
    this.setStartDate(newDate)
  }

  setVisitSelectedId(id: number) {
    this.visitSelectedId = id
  }

  setNextVisitIdToBeEdited(id: number) {
    this.nextVisitIdToBeEdited = id
  }

  setFormValues(value?: IRailVisitPlanningFormData) {
    this.currentFormValues = value

    if (value?.eta && value?.etd && value.railTrackIds?.length > 0) {
      this.setStartDateIfNewDateHasADifferentStart(value.eta.format())
    }
  }

  public setStartDateIfNewDateHasADifferentStart(date?: string) {
    if (
      date &&
      dayjs(this.startDate.toDateString()).diff(dayjs(date).toDate().toDateString(), 'days') !== 0
    ) {
      this.setStartDate(dayjs(date).toDate())
    }
  }

  async fetchVisits() {
    const fromDate = dayjs(this.startDate).toDate()
    const toDate = dayjs(this.startDate).add(this.daysVisualization, 'day').toDate()

    await this.railVisitItemStore.fetch(fromDate, toDate)
  }

  public get plannedVisits(): IRailVisitPlanning[] {
    const formVisit = this.getFormValuesMappedToVisitDto()
    let exisitingVisits = this.railVisits

    if (formVisit) {
      exisitingVisits = exisitingVisits.filter(x => x.id !== formVisit?.id)
      exisitingVisits = [...exisitingVisits, formVisit]
    }

    const visits = _(exisitingVisits)
      .filter(visit => {
        const isOutsideDateRange =
          dayjs(visit.eta).diff(this.endDate, 'hours') >= 0 ||
          dayjs(visit.etd).diff(this.startDate, 'hours') <= 0

        return !isOutsideDateRange
      })
      .orderBy(x => x.eta)
      .value()

    return this.railTrackItemStore.activeRailTracks.map(railTrack => ({
      railTrack: railTrack,
      visits: visits
        .filter(visit => visit.assignedRailTrackIds?.includes(railTrack.id))
        .map(visit => {
          const xAxis = getXAxisAndWidthForVisit(
            this.startDate,
            this.endDate,
            this.pixelPerHour,
            visit.eta,
            visit.etd,
          )
          const yAxis = this.getYAxisPositionsForVisit()

          return {
            id: visit.id,
            name: this.getVisitIdentifier(
              railTrack.id,
              visit.assignedRailTrackIds,
              visit.identifier,
            ),
            x: xAxis.x,
            width: xAxis.width,
            y: yAxis.y,
            height: yAxis.height,
            cargoType: visit.cargoType,
            status: visit.status,
            eta: visit.eta ?? '',
            etd: visit.etd ?? '',
            railcarEstimate: visit.railcarEstimate ?? 0,
            loadEstimate: visit.loadEstimate ?? 0,
            dischargeEstimate: visit.dischargeEstimate ?? 0,
            overlaps: this.doesVisitHasAnOverlapWithAnotherVisit(visits, visit, railTrack.id),
            lengthConflict:
              this.railcarTrackPositionItemStore.isRailcarLengthSumExceedsRailtrackLength(
                visit.id,
                railTrack.id,
              ),
            railcarLengthMissing:
              this.railcarTrackPositionItemStore.isAnyRailcarOnTrackWithoutLength(
                visit.id,
                railTrack.id,
              ),
            selected: visit.id === this.visitSelectedId,
          }
        })
        .filter(visit => visit.width > 0 && visit.height > 0),
    }))
  }

  public get endDate() {
    return dayjs(this.startDate).add(this.daysVisualization, 'day').subtract(1, 'minute').toDate()
  }

  public get pixelPerHour() {
    return getPixelsPerHour(this.visualizationWidth, this.daysVisualization)
  }

  public get railVisits() {
    return (
      _(this.railVisitItemStore.elements)
        ?.map(b => b.data)
        .value() ?? []
    )
  }

  public get emptySpaceHeight() {
    return 24
  }

  public get railTrackHeight() {
    return 150
  }

  private getYAxisPositionsForVisit() {
    return {
      y: 0,
      height: this.railTrackHeight,
    }
  }

  get isRailcarLengthSumExceedsAnyRailtrackLength() {
    return this.railcarTrackPositionItemStore.isRailcarLengthSumExceedsAnyRailtrackLength(
      this.visitSelectedId,
    )
  }

  get isAnyRailcarOfVisitWithoutLength() {
    return this.railcarTrackPositionItemStore.isAnyRailcarOfVisitWithoutLength(this.visitSelectedId)
  }

  private doesVisitHasAnOverlapWithAnotherVisit(
    visits: RailVisitResponseDto[],
    visit: RailVisitResponseDto,
    railTrackId: string,
  ): boolean {
    return visits
      .filter(x => x.id !== visit.id)
      .some(x => {
        if (!visit.assignedRailTrackIds?.length) {
          return false
        }

        const overlapsOnXAxis =
          isDateWithinRange(visit.eta, x.eta, x.etd) ||
          isDateWithinRange(visit.etd, x.eta, x.etd) ||
          isDateWithinRange(x.eta, visit.eta, visit.etd)

        const overlapsOnYAxis =
          x.assignedRailTrackIds?.includes(railTrackId) &&
          visit.assignedRailTrackIds.includes(railTrackId)

        return overlapsOnXAxis && overlapsOnYAxis
      })
  }

  private getFormValuesMappedToVisitDto(): RailVisitResponseDto | undefined {
    if (!this.currentFormValues) return

    const existingVisit = this.railVisits.find(x => x.id === this.currentFormValues?.id)

    return {
      id: this.currentFormValues.id ?? 0,
      cargoType: this.currentFormValues.cargoType,
      status: existingVisit?.status ?? CarrierVisitStatus.Expected,
      eta: this.currentFormValues.eta?.format(),
      etd: this.currentFormValues.etd?.format(),
      identifier: this.currentFormValues.name,
      carrierIds: [],
      dischargeConfirmed: existingVisit?.dischargeConfirmed ?? 0,
      dischargeTotal: existingVisit?.dischargeTotal ?? 0,
      dischargeEstimate: this.currentFormValues?.dischargeEstimate ?? 0,
      loadEstimate: this.currentFormValues?.loadEstimate ?? 0,
      loadConfirmed: existingVisit?.loadConfirmed ?? 0,
      loadTotal: existingVisit?.loadTotal ?? 0,
      railcarTrackPositionIds: existingVisit?.railcarTrackPositionIds ?? [],
      railTrackIds: this.currentFormValues?.railTrackIds,
      railcarEstimate: this.currentFormValues?.railcarEstimate,
      orderIds: [],
      assignedRailTrackIds: this.currentFormValues?.railTrackIds ?? [],
    }
  }

  private getVisitIdentifier(
    currentRailTrackId: string,
    railTrackIds?: string[] | null,
    identifier?: string | null,
  ) {
    let newIdentifier = identifier ?? '-'

    if (railTrackIds && railTrackIds.length > 1) {
      const currentPosition =
        this.railTrackItemStore.activeRailTracks
          .filter(x => railTrackIds.includes(x.id))
          .findIndex(x => x.id === currentRailTrackId) + 1

      newIdentifier += ` (${currentPosition}/${railTrackIds.length})`
    }

    return newIdentifier
  }
}
