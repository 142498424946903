import { Box, Divider, Typography } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { OrderResponseDto } from '@planning/app/api'
import { NonNumericOrderWithPickUpAmount } from '@planning/stores/truckAppointment/TruckAppointmentDetailsViewStore'
import { useTranslate } from '@tolgee/react'
import _ from 'lodash'
import { FC } from 'react'
import { EmptyOrderList } from './EmptyOrderList'
import { GateOutOrderListItem } from './GateOutOrderListItem'
import { IInspectContainerFormData } from './InspectContainer'
import { NNROrderListItem } from './NNROrderListItem'
import { GCOrderListItem } from './GCOrderListItem'

interface Props {
  orders: IInspectContainerFormData[]
  checkedOrders: IInspectContainerFormData[]
  onCheckGCOrder: (order: IInspectContainerFormData) => void
  onCheckOrder: (order: IInspectContainerFormData) => void
  onRemoveOrder: (order: IInspectContainerFormData) => void
  onRemoveNNROrder: (nnrOrder: NonNumericOrderWithPickUpAmount) => void
  error: boolean
}

export const GateOutOrderHandlingBox: FC<Props> = ({
  orders,
  checkedOrders,
  onCheckGCOrder,
  onCheckOrder,
  onRemoveOrder,
  onRemoveNNROrder,
  error,
}) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const mapToNNROrders = (orders: OrderResponseDto[]) => {
    const ordersByNNROrderId = _(orders)
      .filter(o => !o.containerNumber && !o.commodityId)
      .groupBy(o => o.nonNumericOrderId)
      .value()

    const nnrOrders = _(ordersByNNROrderId)
      .flatMap(orders => {
        return {
          id: orders[0].nonNumericOrderId,
          // [GateClerk] TODO: we need to get the proper NNR order referenceNumber
          referenceNumber: orders[0].referenceNumber,
          pickUpAmount: orders.length,
        } as NonNumericOrderWithPickUpAmount
      })
      .value()

    return nnrOrders
  }

  const nnrOrders = mapToNNROrders(orders)

  const generalCargoOrders = orders.filter(o => !o.containerNumber && o.commodityId !== null)

  return (
    <Box
      sx={{
        border: '1px solid rgba(145, 158, 171, 0.32)',
        borderRadius: theme.customRadius.medium,
        mb: '1rem !important',
        borderColor: !error ? 'rgba(145, 158, 171, 0.32)' : theme.palette.error.main,
      }}
    >
      <Box
        sx={{
          padding: '1rem',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant='subtitle2'>{t('containers', 'Containers')}</Typography>
      </Box>
      <Divider sx={{ borderColor: !error ? '' : theme.palette.error.main }} />
      <Box
        sx={{
          padding: '1rem',
          bgcolor: !error ? theme.palette.grey[100] : theme.palette.error.lighter,
          borderBottomLeftRadius: theme.customRadius.medium,
          borderBottomRightRadius: theme.customRadius.medium,
        }}
      >
        {orders
          .filter(o => o.containerNumber)
          .map(o => {
            return (
              <GateOutOrderListItem
                key={`order-list-item-${o.containerNumber}`}
                order={o}
                onCheck={onCheckOrder}
                onRemove={onRemoveOrder}
                error={error}
              />
            )
          })}

        {checkedOrders.map(o => {
          if (!o.containerId && o.commodityId) {
            return (
              <GCOrderListItem
                key={`gc-order-list-item-${o.commodityId}`}
                onCheck={onCheckGCOrder}
                order={o}
                onRemove={onRemoveOrder}
                isChecked
              />
            )
          } else {
            return (
              <GateOutOrderListItem
                key={`checked-order-list-item-${o.containerNumber}`}
                order={o}
                onCheck={onCheckOrder}
                onRemove={onRemoveOrder}
                error={error}
                isChecked
              />
            )
          }
        })}

        {nnrOrders.map((nnr, i) => {
          return (
            <NNROrderListItem
              key={`nnr-order-list-item-${i}`}
              nnrOrder={nnr}
              onRemove={onRemoveNNROrder}
              showNoContainerAlert
            />
          )
        })}

        {generalCargoOrders.map((gco, i) => {
          return (
            <GCOrderListItem
              key={`gc-order-list-item-${i}`}
              onCheck={onCheckGCOrder}
              order={gco}
              onRemove={onRemoveOrder}
            />
          )
        })}

        {!orders.length && !checkedOrders.length && !generalCargoOrders.length && (
          <EmptyOrderList />
        )}
      </Box>
    </Box>
  )
}
