import { SelectOption } from '@storage/app/models'
import { ContainerTurnoverSizes } from '@storage/app/models/container-turnover-size.model'
import { EnumAsSelectOptionsUtil } from '@storage/utils/enums'
import { useMemo } from 'react'

export const useContainerSizeOptions = (): SelectOption[] =>
  useMemo(
    () => EnumAsSelectOptionsUtil.transform(ContainerTurnoverSizes, 'CONTAINER_TURNOVER_SIZE'),
    [],
  )
