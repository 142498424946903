/* eslint-disable react/prop-types */
import { setupInterceptors } from '@storage/app/http-client'
import { setupValidation } from '@storage/app/validation'
import { RootStore } from '@storage/stores/root.store'
import { configure } from 'mobx'
import { createContext, FC, ReactElement, ReactNode, useEffect } from 'react'

setupValidation()

configure({
  enforceActions: 'always',
})

export const StoreContext = createContext<RootStore | undefined>(undefined)

export type StoreComponent = FC<{
  store: RootStore
  children: ReactNode
}>

export const StoreProvider: StoreComponent = ({ children, store }): ReactElement => {
  useEffect(() => {
    setupInterceptors(store.appStore, store.snackbarStore)
  }, [store])

  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
}
