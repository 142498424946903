import { OrderResponseDto, OrderStatus, OrdersApi } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'
import { Event, IEvent, IQueryHandler, IQueryMessage } from '../messageBus'

export class GetDeliveryOrdersQuery implements IQueryMessage {
  static type = 'GetDeliveryOrdersQuery'
  type = GetDeliveryOrdersQuery.type

  constructor(
    public filter?: string,
    public orderStatus?: OrderStatus,
    public includeCompleted?: boolean,
  ) {}

  getKey = () => {
    return JSON.stringify(this)
  }
}

const httpClient = createApiClient(OrdersApi)

export const getDeliveryOrdersQueryHandler: IQueryHandler<
  GetDeliveryOrdersQuery,
  IEvent<OrderResponseDto[]>
> = async query => {
  const { data } = await httpClient.deliveries(
    query.filter,
    query.orderStatus,
    query.includeCompleted,
  )

  return new Event(GetDeliveryOrdersQuery.type, data)
}
