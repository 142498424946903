import { createSvgIcon } from '@mui/material'

export const FullIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4 2C2.89543 2 2 2.89543 2 4V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V4C22 2.89543 21.1046 2 20 2H4ZM15.6157 6.87799H8.99365V17H10.7227V12.653H15.0347V11.232H10.7227V8.31999H15.6087L15.6157 6.87799Z'
      fill='currentColor'
    />
  </svg>,
  'FullIcon',
)
