import { ReeferIcon } from '@tom-ui/ui'
import { FC } from 'react'

export const IconReefer: FC<{ zoom?: number; size?: number }> = ({ zoom, size }) => {
  return (
    <ReeferIcon
      sx={{
        height: `${size ?? 32}px`,
        width: `${size ?? 32}px`,
        transform: `scale(${(zoom ?? 100) / 100})`,
      }}
    />
  )
}

export default IconReefer
