import { DangerousGoodsStackingRuleDto } from '@storage/app/api'
import { DGStackingRuleFormProfile } from './dg-stacking-rules-table-form.profile'

export const mapDGStackingRuleDtoToDGStackingRuleFormProfile = ({
  id,
  maxStack,
  isStackingContainerOnTopAllowed,
  imoClass,
}: DangerousGoodsStackingRuleDto): DGStackingRuleFormProfile => ({
  id,
  isParentClass: imoClass.includes('.') ? false : true,
  maxStack,
  isStackingContainerOnTopAllowed,
})
