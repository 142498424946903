import { createSvgIcon } from '@mui/material'

export const WeightIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.94 15.675 15.372 5.404C15.239 4.871 14.794 4.5 14.287 4.5h-2.118a3.42 3.42 0 0 0 0.207 -1.125 3.375 3.375 0 0 0 -6.75 0c0 0.397 0.081 0.771 0.207 1.125H3.715c-0.507 0 -0.951 0.371 -1.085 0.904L0.061 15.675C-0.232 16.846 0.576 18 1.688 18h14.623c1.112 0 1.921 -1.154 1.627 -2.325M9 4.5c-0.62 0 -1.125 -0.505 -1.125 -1.125S8.38 2.25 9 2.25s1.125 0.505 1.125 1.125S9.62 4.5 9 4.5'
      fill='currentColor'
    />
  </svg>,
  'WeightIcon',
)
