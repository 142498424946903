import { Done } from '@mui/icons-material'
import { Button, Chip, Grid, TextField, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { HeaderMessage, HeaderWithActions } from '@planning/components'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { ServiceOrdersViewStore } from '../Stores/ServiceOrdersViewStore'
import { ContainerJourneyOptions } from './ContainerJourneyOptions'
import { NonNumericForm } from './NonNumericForm'
import { NumericForm } from './NumericForm'
import { RenderServiceTable } from './RenderServiceTable'

export const CreateServiceOrderDialogRender: FC<{
  isStuffing: boolean
  store: ServiceOrdersViewStore
  onClose: () => void
  onSubmit: () => void
  onDelete: () => void
  renderReservations?: (orderId: number, plannedAmout: number, cargoItemId: number) => JSX.Element
}> = observer(({ isStuffing, store, onClose, onSubmit, onDelete, renderReservations }) => {
  const { t } = useTranslate()

  const getActions = () => {
    const actions = [
      <Button key='service-orders-cancel-btn' color='secondary' size='large' onClick={onClose}>
        {t('cancel', 'Cancel')}
      </Button>,
    ]

    if (!store.isInProgress && (!isStuffing || !store.isCriticalContainerDamage)) {
      actions.push(
        <Button key='service-orders-submit-btn' variant='contained' size='large' onClick={onSubmit}>
          {t('submit', 'Submit')}
        </Button>,
      )
      if (store.isEditMode) {
        actions.splice(
          0,
          0,
          <Button key='service-orders-cancel-btn' color='error' size='large' onClick={onDelete}>
            {t('delete', 'Delete')}
          </Button>,
        )
      }
    }

    return actions
  }

  const stuffingHeader = store.isEditMode
    ? t('editStuffingOrder', 'Edit stuffing order')
    : t('createStuffingOrder', 'Create stuffing order')

  const strippingHeader = store.isEditMode
    ? t('editStrippingOrder', 'Edit stripping order')
    : t('createStrippingOrder', 'Create stripping order')

  const headerMessage: HeaderMessage | undefined = store.isFulfilled
    ? {
        message: t(
          'blockedFulfilledServiceOrderModification',
          'Order is fulfilled: You cannot make any changes to orders that have been completed',
        ),
        severity: 'success',
      }
    : store.isInProgress
      ? {
          message: t(
            'blockedInOperationServiceOrderModification',
            'Order in operation: You cannot make any changes once operation has started',
          ),
          severity: 'warning',
        }
      : store.isCriticalContainerDamage
        ? {
            message: t('criticallyDamaged ', 'Critically damaged'),
            severity: 'warning',
          }
        : undefined

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <HeaderWithActions
            noBorder
            title={isStuffing ? stuffingHeader : strippingHeader}
            actions={getActions()}
            headerMessage={headerMessage}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} padding={'0 24px'}>
        {/* LEFT PANEL */}
        <Grid item xs={4}>
          <Box mb={'2rem'}>
            <Typography variant='subtitle1' mb={'.5rem'}>
              {t('container', 'Container')}
            </Typography>

            {isStuffing && !store.selectedContainerJourney && (
              <Stack direction='row' spacing={1} mb={1}>
                <Chip
                  icon={store.stuffingSearchType === 'numeric' ? <Done /> : <></>}
                  variant={store.stuffingSearchType === 'numeric' ? 'filled' : 'outlined'}
                  label={t('numeric', 'Numeric')}
                  onClick={() => store.setStuffingSearchType('numeric')}
                />

                <Chip
                  icon={store.stuffingSearchType === 'nonNumeric' ? <Done /> : <></>}
                  label={t('nonNumeric', 'Non-numeric')}
                  variant={store.stuffingSearchType === 'nonNumeric' ? 'filled' : 'outlined'}
                  onClick={() => store.setStuffingSearchType('nonNumeric')}
                />
              </Stack>
            )}

            {store.stuffingSearchType === 'numeric' && (
              <NumericForm store={store} isEmpty={isStuffing} />
            )}

            {isStuffing && store.stuffingSearchType === 'nonNumeric' && (
              <>
                {store.selectedContainerJourney ? (
                  <ContainerJourneyOptions
                    options={[store.selectedContainerJourney]}
                    isEdited
                    onSelect={() => store.setSelectedContainerJourney()}
                  />
                ) : (
                  <>
                    <Stack mt={2} mb={2}>
                      <Typography variant='subtitle1' mb={'.5rem'}>
                        {t('containerRequirements', 'Container requirements')}
                      </Typography>
                      <Typography variant='body2' color={'#637381'}>
                        {t(
                          'dataProvidedToOperatorsForContainerSelection.',
                          'Operators will be provided the following data to ensure they select a suitable container.',
                        )}
                      </Typography>
                    </Stack>

                    <NonNumericForm
                      containerJourneyTotal={store?.containerVisits.length}
                      onClickChooseContainer={() => store.setActiveDialog('container')}
                      onFormDataChange={formData =>
                        store.fetchContainerVisitsByNonNumeric(formData)
                      }
                    />
                  </>
                )}
              </>
            )}
          </Box>

          <Box mb={'2rem'}>
            <Typography variant='subtitle1' mb={'.5rem'}>
              {t('handlingInstructions', 'Handling instructions')}
            </Typography>
            <TextField
              label={t('hint', 'Hint')}
              fullWidth
              multiline
              rows={4}
              onChange={e => store.setHandlingInstructions(e.target.value)}
            />
          </Box>
        </Grid>

        {/* RIGHT PANEL */}
        <Grid item xs={8}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: '.5rem',
            }}
          >
            <Typography variant='subtitle1'>{t('packingList', 'Packing list')}</Typography>
            {store.selectedContainerJourney !== null && (
              <Button
                sx={{
                  ml: '2rem',
                }}
                color='secondary'
                size='large'
                onClick={() =>
                  isStuffing
                    ? store.setActiveDialog('commodity')
                    : store.setActiveDialog('strippingSelection')
                }
              >
                {t('addCommodity', 'Add commodity')}
              </Button>
            )}
          </Box>
          <Box
            mb={'2rem'}
            sx={{
              display: 'flex',
              alignItems:
                store.selectedStuffingCommodities.length ||
                store.selectedStrippingCommodities.length
                  ? 'start'
                  : 'center',
              justifyContent:
                store.selectedStuffingCommodities.length ||
                store.selectedStrippingCommodities.length
                  ? 'start'
                  : 'center',
              bgcolor: '#F9FAFB',
              height: '80vh',
              width: '100%',
              padding: '1rem',
            }}
          >
            <RenderServiceTable
              store={store}
              isStuffing={isStuffing}
              showDeleteConfirmationDialog={() => store.setActiveDialog('confirmation')}
              serviceOrderId={store.selectedContainerJourney?.strippingOrderId}
              renderReservations={renderReservations}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  )
})
