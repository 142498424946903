import { Box } from '@mui/material'
import { Stack } from '@mui/system'
import { useMinimalsTheme } from '@tom-ui/ui'
import { FC } from 'react'

export type Props = {
  qmmFrom: number
  qmmTo: number
  pixelPerWaterMark: number
  berthViewTotalWidth: number
}

export const EmptyBerth: FC<Props> = ({
  qmmFrom,
  qmmTo,
  pixelPerWaterMark,
  berthViewTotalWidth,
}: Props) => {
  const theme = useMinimalsTheme()

  return (
    <Stack
      gap={theme.customSpacing.xxs}
      height={(qmmTo - qmmFrom) * pixelPerWaterMark}
      py={theme.customSpacing.l}
      px={theme.customSpacing.s}
      position='relative'
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: '101%',
          backgroundColor: theme.palette.common.white,
          width: `calc(${berthViewTotalWidth}px - 1%)`,
          height: `${(qmmTo - qmmFrom) * pixelPerWaterMark}px`,
          zIndex: 50,
        }}
      ></Box>
    </Stack>
  )
}
