import { withAuthenticationRequired } from '@auth0/auth0-react'
import { ElementType, lazy, Suspense } from 'react'

// eslint-disable-next-line react/display-name
const Loadable = (Component: ElementType) => (props: any) => (
  <Suspense fallback={<div>Loading...</div>}>
    <Component {...props} />
  </Suspense>
)

export const NotFoundPage = Loadable(lazy(() => import('@storage/pages/NotFound')))

export const PiersPage = withAuthenticationRequired(
  Loadable(lazy(() => import('@storage/pages/Piers'))),
)

export const BerthsPage = withAuthenticationRequired(
  Loadable(lazy(() => import('@storage/pages/Berths'))),
)
