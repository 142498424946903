import { Chip, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { useTranslate } from '@tolgee/react'
import { TrainIcon, VesselIcon } from '@tom-ui/ui'
import { CarrierType } from 'modules/planning/src/app/api'
import { FC } from 'react'

interface Props {
  carrierType: CarrierType
  vesselCount?: number
  trainCount?: number
  onChange: (carrierType: CarrierType) => void
}

export const CarrierTypeSelection: FC<Props> = ({
  carrierType,
  vesselCount,
  trainCount,
  onChange,
}) => {
  const { t } = useTranslate()

  return (
    <Stack direction='row' spacing={1} mb={1}>
      <Chip
        icon={<VesselIcon />}
        label={
          <Typography variant='body2'>
            {t('vessels', 'Vessels')} <b>{vesselCount}</b>
          </Typography>
        }
        variant='filled'
        color={carrierType === CarrierType.Vessel ? 'primary' : 'default'}
        onClick={() => onChange(CarrierType.Vessel)}
      />
      <Chip
        icon={<TrainIcon />}
        label={
          <Typography variant='body2'>
            {t('trains', 'Trains')} <b>{trainCount}</b>
          </Typography>
        }
        variant='filled'
        color={carrierType === CarrierType.Train ? 'primary' : 'default'}
        onClick={() => onChange(CarrierType.Train)}
      />
    </Stack>
  )
}
