/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material'
import { CargoType, CarrierType, CarrierVisitDirection } from '@planning/app/api'
import { INnrOrderSummary } from '@planning/rt-stores/nnrOrder/NnrOrderItemStore'
import { useTranslate } from '@tolgee/react'
import { ThreeDotMenu, useMinimalsTheme } from '@tom-ui/ui'
import { FC } from 'react'

interface Props {
  isInboundRailcarLengthConflicted: boolean
  isOutboundRailcarLengthConflicted: boolean
  hasAssignedRailcarsWithoutLength: boolean
  visitType: CarrierType
  direction?: CarrierVisitDirection
  estimate?: number | null
  actual?: number | null
  confirmed?: number | null
  onViewClick: (e: any) => void
  onClickEdit?: () => void
  onFileUpload?: (direction: CarrierVisitDirection) => void
  handleAssignNnrOrder: () => void
  nnrOrderSummary: INnrOrderSummary
  cargoType?: CargoType
}

export const OrderMovementCard: FC<Props> = ({
  isInboundRailcarLengthConflicted,
  isOutboundRailcarLengthConflicted,
  hasAssignedRailcarsWithoutLength,
  estimate,
  actual,
  confirmed,
  onViewClick,
  onClickEdit,
  direction,
  onFileUpload,
  handleAssignNnrOrder,
  nnrOrderSummary,
  cargoType,
  visitType,
}: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const getMovementType = () => {
    switch (direction) {
      case CarrierVisitDirection.Inbound:
        return t('discharged', 'Discharged')
      case CarrierVisitDirection.Outbound:
        return 'Loaded'
      default:
        return 'Restowed'
    }
  }

  const getTitle = () => {
    switch (direction) {
      case CarrierVisitDirection.Inbound:
        return t('discharge', 'Discharge')
      case CarrierVisitDirection.Outbound:
        if (visitType === CarrierType.Train) {
          return t('load', 'Load')
        } else {
          return t('loading', 'Loading')
        }
      default:
        return t('restows', 'Restows')
    }
  }

  const getUnassignedReleasesAlertText = (totalAmount: number, totalUnassigned: number) => {
    return `${totalAmount} ${
      totalUnassigned > 1
        ? t('containerPlannedForNonNumericLoadidng', 'containers planned for non-numeric loading')
        : t('containerPlannedForNonNumericLoadidng', 'container planned for non-numeric loading')
    } (${totalUnassigned} ${t('notAssignedYet', 'not assigned yet')})`
  }

  return (
    <Card sx={{ minWidth: 360 }}>
      <CardHeader
        action={
          <Stack direction='row'>
            <Button
              color='info'
              onClick={onViewClick}
              data-cy={
                direction === 'Inbound'
                  ? 'view-vessel-visit-discharge-btn'
                  : 'view-vessel-visit-loading-btn'
              }
            >
              {t('view', 'View')}
            </Button>
            <ThreeDotMenu color='info'>
              <MenuItem onClick={onClickEdit}>{t('editEstimate', 'Edit Estimate')}</MenuItem>
              {(cargoType && cargoType !== CargoType.GeneralCargo && onFileUpload && (
                <MenuItem
                  onClick={() => {
                    if (direction) onFileUpload(direction)
                  }}
                >
                  {t('upload', 'Upload')} {getTitle()} {t('manifest', 'Manifest')}
                </MenuItem>
              )) || <></>}
            </ThreeDotMenu>
          </Stack>
        }
        title={getTitle()}
      />
      <CardContent>
        <Stack direction='row' spacing={2.5}>
          <Stack>
            <Typography variant='subtitle1'>{t('estimate', 'Estimate')}</Typography>
            <a>
              <Typography
                onClick={onClickEdit}
                paragraph
                component='span'
                variant='h4'
                sx={{ cursor: 'pointer' }}
              >
                {estimate ?? '-'}
              </Typography>
            </a>
          </Stack>
          <Stack>
            {(actual && (
              <Stack direction='row' spacing={2.5}>
                <Stack>
                  <Typography variant='subtitle1'>{t('expected', 'Expected')}</Typography>
                  <Typography variant='h4'>{actual}</Typography>
                </Stack>
                <Stack>
                  <Typography variant='subtitle1'>{getMovementType()}</Typography>
                  <Typography variant='h4'>{confirmed}</Typography>
                </Stack>
              </Stack>
            )) || (
              <Box
                sx={{
                  p: 2,
                  borderRadius: theme.customRadius.medium,
                  bgcolor: theme.palette.grey[200],
                }}
              >
                <Typography variant='body1'>
                  {actual ? actual : t('noContainerRecordsFound', 'No container records found')}
                </Typography>
              </Box>
            )}
          </Stack>
        </Stack>
        {direction === CarrierVisitDirection.Outbound &&
          nnrOrderSummary?.totalUnassignedContainersForVisit > 0 && (
            <Stack>
              {direction === CarrierVisitDirection.Outbound && (
                <Alert
                  severity='warning'
                  action={
                    <Button color='inherit' size='small' onClick={handleAssignNnrOrder}>
                      Assign
                    </Button>
                  }
                >
                  {getUnassignedReleasesAlertText(
                    nnrOrderSummary.totalAmount,
                    nnrOrderSummary.totalUnassignedContainersForVisit,
                  )}
                </Alert>
              )}
            </Stack>
          )}
      </CardContent>
    </Card>
  )
}
