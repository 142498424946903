import { CarrierType, CarrierVisitDto, CarrierVisitsApi } from '@operations/app/api'
import { createApiClient } from '@operations/app/http-client'
import { BaseEntityStore } from './base/BaseEntityStore'

export interface VesselVisitDto extends CarrierVisitDto {
  discharge: number
  load: number
  imo: number
  shippingLine: string
  restowsExpected: number
}

export class VesselVisitStore extends BaseEntityStore<VesselVisitDto> {
  async loadOne(id: number) {
    const { data } = await createApiClient(CarrierVisitsApi).getOne(id)
    const vesselVisit: VesselVisitDto = {
      //TOLATER: this is just a mock, remove it when we have real info
      ...data,
      discharge: 152,
      load: 50,
      imo: 456453,
      shippingLine: '<Shipping Line>',
      restowsExpected: 0,
    }
    this.updateStoreItem(vesselVisit, data.id)
  }

  async load() {
    const { data } = await createApiClient(CarrierVisitsApi).get(CarrierType.Vessel)
    this.updateStoreItems(data.map(cv => ({ ...cv }) as VesselVisitDto))
  }
}
