import { rowSeq } from '@operations/features/craneOperator/utils'
import { Typography, styled } from '@mui/material'
import { Box } from '@mui/system'
import { observer } from 'mobx-react-lite'

interface Props {
  maxRow: number
  hasZero: boolean
  zoom: number
}

const RowField = styled(Typography)<{ zoom: number }>(({ zoom }) => ({
  flex: 'none',
  height: `${2.5 * zoom}rem`,
  width: `${7 * zoom}rem`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: `${4 * zoom}px`,
  marginTop: `${10 * zoom}px`,
  fontWeight: 'bold',
  fontSize: `${1 * zoom}rem`,
}))

export const RowHeader = observer(({ maxRow, hasZero, zoom }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: `${0.5 * zoom}rem`,
        flexWrap: 'nowrap',
      }}
    >
      {rowSeq(maxRow, hasZero).map(row => {
        return (
          <RowField zoom={zoom} key={'row-header-' + row}>
            {row}
          </RowField>
        )
      })}
    </Box>
  )
})
