import { createSvgIcon } from '@mui/material'

export const FileIcon = createSvgIcon(
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g id='icon/file'>
      <path
        id='Vector'
        d='M19.74 7.33L15.3 2.33C15.2065 2.2264 15.0924 2.14353 14.9649 2.08669C14.8375 2.02986 14.6995 2.00033 14.56 2H6.56C6.22775 1.99603 5.89797 2.05756 5.5895 2.18105C5.28103 2.30455 4.9999 2.4876 4.76218 2.71975C4.52446 2.9519 4.33479 3.22861 4.20402 3.53406C4.07324 3.83952 4.00392 4.16775 4 4.5V19.5C4.00392 19.8322 4.07324 20.1605 4.20402 20.4659C4.33479 20.7714 4.52446 21.0481 4.76218 21.2802C4.9999 21.5124 5.28103 21.6954 5.5895 21.8189C5.89797 21.9424 6.22775 22.004 6.56 22H17.44C17.7723 22.004 18.102 21.9424 18.4105 21.8189C18.719 21.6954 19.0001 21.5124 19.2378 21.2802C19.4755 21.0481 19.6652 20.7714 19.796 20.4659C19.9268 20.1605 19.9961 19.8322 20 19.5V8C19.9994 7.75216 19.9067 7.51339 19.74 7.33ZM14 4L17.74 8H14.74C14.6353 7.99367 14.5329 7.96656 14.4387 7.92023C14.3446 7.8739 14.2607 7.80929 14.1918 7.73016C14.1229 7.65103 14.0704 7.55898 14.0375 7.45937C14.0046 7.35976 13.9918 7.25459 14 7.15V4Z'
        fill='currentColor'
      />
    </g>
  </svg>,
  'FilterIcon',
)
