import { Button, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { TallymanV2ViewStore } from '@planning/rt-stores/tallyman/TallymanV2ViewStore'
import { useTranslate } from '@tolgee/react'
import { SearchIcon } from '@tom-ui/ui'
import { FC } from 'react'

interface Props {
  store: TallymanV2ViewStore
  onSearchClick: () => void
}

export const ContainerSearchBox: FC<Props> = ({ store, onSearchClick }) => {
  const { t } = useTranslate()

  const { vesselTallyStore } = store
  const { selectedVisit, selectedOperation: operation } = vesselTallyStore

  if (!operation) return <></>

  const checkedItems =
    (operation === 'Discharge'
      ? selectedVisit?.discharge.orderCounts.container.confirmed
      : selectedVisit?.load.orderCounts.container.confirmed) ?? 0

  const totalOrders =
    (operation === 'Discharge'
      ? selectedVisit?.discharge.orderCounts.container.total
      : selectedVisit?.load.orderCounts.container.total) ?? 0

  const pendingReportOrder = vesselTallyStore.reportNotificationStore.request ? 1 : 0

  return (
    <Box
      sx={{
        maxWidth: '420px',
        width: '100%',
        margin: '0 auto 1rem auto!important',
        textAlign: 'center',
      }}
    >
      <Typography variant='h3'>{`${checkedItems + pendingReportOrder}/${
        totalOrders + pendingReportOrder
      }`}</Typography>
      <Typography>
        {t('containers', 'Containers')} {t(operation, operation)}
      </Typography>
      <Button
        data-cy='search-container'
        variant='contained'
        size='large'
        startIcon={<SearchIcon />}
        sx={{ width: '100%', mt: '1rem' }}
        onClick={onSearchClick}
      >
        {t('searchContainer', 'Search container')}
      </Button>
    </Box>
  )
}
