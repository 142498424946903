import { IconButton, Tooltip } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { FC, ReactNode } from 'react'

export const TooltipChip: FC<{
  children: ReactNode
  title: string
  onClick: () => void
}> = ({ children, title, onClick }) => {
  const theme = useMinimalsTheme()

  return (
    <Tooltip title={title}>
      <IconButton
        sx={{
          display: 'flex',
          backgroundColor: theme.palette.grey[300],
          padding: '6px 12px',
          height: '36px',
          borderRadius: '2px',
          color: theme.palette.grey[600],
        }}
        onClick={onClick}
      >
        {children}
      </IconButton>
    </Tooltip>
  )
}
