import { useOperationsStore } from '@operations/AppProvider'
import { useTranslate } from '@tolgee/react'
import { BinIcon, ConfirmationDialog, IconButton } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { EquipmentForm } from '../components/form/equipment/EquipmentForm'
import { EquipmentFormMapper } from '../components/form/equipment/equipment-form.mapper'
import { EquipmentFormProfile } from '../components/form/equipment/equipment-form.profile'

export const EquipmentDialogContainer = observer(() => {
  const { appStore, equipmentDialogUIStore, equipmentStore, yardBlockStore } = useOperationsStore()
  const { t } = useTranslate()

  const handleSubmit = async (formValues: EquipmentFormProfile) => {
    if (equipmentDialogUIStore.formType === 'Add') {
      appStore.showAlertForCancellableRequest(
        'success',
        `${formValues.name} ${t('isBeingCreated', 'is being created')}`,
        () =>
          appStore.triggerRequestWithoutLoader(
            async () =>
              await equipmentStore.add(EquipmentFormMapper.mapFormValuesToCreateDto(formValues)),
          ),
        `${formValues.name} ${t('creationCancelled', 'creation cancelled')}`,
      )
    } else {
      appStore.showAlertForCancellableRequest(
        'success',
        `${formValues.name} ${t('isBeingUpdated', 'is being updated')}`,
        () =>
          appStore.triggerRequestWithoutLoader(async () => {
            await equipmentStore.update(EquipmentFormMapper.mapFormValuesToUpdateDto(formValues))
            await yardBlockStore.loadYardBlocks()
          }),
        `${formValues.name} ${t('updateCancelled', 'update cancelled')}`,
      )
    }

    equipmentDialogUIStore.toggle()
  }

  const handleClose = () => {
    equipmentDialogUIStore.toggle()
  }

  const handleTogleDelete = () => {
    equipmentDialogUIStore.toggle(equipmentDialogUIStore.editingEntity?.id)
    equipmentDialogUIStore.toggleDelete()
  }

  const handleCloseDelete = () => {
    equipmentDialogUIStore.toggleDelete()
  }

  const handleDelete = async () => {
    if (equipmentDialogUIStore.editingEntity) {
      const customerName = equipmentDialogUIStore.editingEntity.name
      await equipmentStore.delete(equipmentDialogUIStore.editingEntity.id)
      handleCloseDelete()

      appStore.setShowAlert('success', `${customerName} ${t('deleted', 'deleted')}`)
    }
  }

  return (
    <>
      <ConfirmationDialog
        open={equipmentDialogUIStore.isOpen}
        formId='equipment-form'
        maxWidth='sm'
        title={`${
          equipmentDialogUIStore.formType === 'Add' ? t('addNew', 'Add new') : t('update', 'Update')
        } ${t('equipment', 'Equipment')}`}
        primaryActionText={t('save', 'Save')}
        closeLabel={t('close', 'Close')}
        onClose={handleClose}
        rightRenderOption={
          equipmentDialogUIStore.formType === 'Edit' ? (
            <IconButton variant='contained' color='default' onClick={handleTogleDelete}>
              <BinIcon />
            </IconButton>
          ) : undefined
        }
        hasBorderTop
      >
        <EquipmentForm
          id='equipment-form'
          equipment={equipmentDialogUIStore.editingEntity}
          yardBlocks={yardBlockStore.items}
          yardBlocksWithEquipmentsDictionary={equipmentDialogUIStore.yardBlocksWithEquipments}
          onSubmit={handleSubmit}
        />
      </ConfirmationDialog>

      <ConfirmationDialog
        open={equipmentDialogUIStore.isDeleteOpen}
        title={t('deleteEquipment', 'Delete equipment')}
        message={t(
          'entityWillBeDeleted',
          '{name} will be deleted and you cannot undo this action!',
          {
            name: equipmentDialogUIStore.editingEntity?.name ?? t('equipment'),
          },
        )}
        primaryActionText={t('delete', 'Delete')}
        closeLabel={t('keepEditing', 'Keep editing')}
        maxWidth='xs'
        blockClickOutside
        onConfirm={handleDelete}
        onClose={handleTogleDelete}
      />
    </>
  )
})
