import { CarrierType, HoldStatus, OrderResponseDto } from '@planning/app/api'
import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material'
import { EditIcon, IconButton } from '@tom-ui/ui'
import { usePlanningStore } from '@planning/AppProvider'
import { HoldsAccordion } from '@planning/components/hold/HoldsAccordion'
import { DangerousGoodAutoComplete } from '@planning/pages/DeliveryOrders/Components/DangerousGoodsAutoComplete'
import orderService from '@planning/services/orderService'
import { useNavigationStore } from '@planning/stores/NavigationStack'
import { HoldWithDirection } from '@planning/stores/hold/HoldViewStore'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { FullWidthFooter } from '../../components/FullWidthFooter'
import { ContainerDamagesPage } from '../Container'

export const TruckAppointmentMobileViewEditOrderPage: FC<{
  order: OrderResponseDto | undefined
}> = observer(({ order }) => {
  if (!order) return <></>

  const { t } = useTranslate()
  const { appViewStore, containerItemStore } = usePlanningStore()
  const navigationStore = useNavigationStore()

  useEffect(() => {
    if (order?.containerId) {
      containerItemStore.fetchById(order?.containerId)
    }
  }, [containerItemStore, order?.containerId])

  // TODO: [north] attached to orderItem?
  const containerItem = containerItemStore.elements[order?.containerId ?? 0]

  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...order,
      carrierType: CarrierType.Truck,
    },
  })

  const handleOnClose = () => {
    navigationStore.pop()
  }

  const onFormSubmit = async (data: any) => {
    try {
      await orderService.update(data)
      appViewStore.setShowAlert('success', t('savedSuccessfully', 'Saved successfully'))
      handleOnClose()
    } catch (error) {
      appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
    }
  }

  return (
    <>
      {order && (
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Grid container spacing='0.5rem' p='0.5rem'>
            <Grid item xs={7}>
              <TextField
                fullWidth
                label={t('containerNumber', 'Container Number')}
                variant='outlined'
                {...register('containerNumber', { required: true })}
                name='containerNumber'
                error={!!errors.containerNumber}
                helperText={
                  errors.containerNumber ? t('fieldIsRequired', 'Field is required.') : ''
                }
                sx={{ marginTop: '1rem' }}
                disabled
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                fullWidth
                label={t('isoCode', 'ISO Code')}
                variant='outlined'
                {...register('containerIsoCode')}
                name='containerIsoCode'
                sx={{ marginTop: '1rem' }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                name={`isEmpty`}
                render={({ field: { onChange } }) => (
                  <FormControl fullWidth sx={{ marginTop: '1rem' }}>
                    <InputLabel color='secondary'>{t('filledState', 'Filled State')}</InputLabel>
                    <Select
                      defaultValue={order.isEmpty}
                      label={t('filledState', 'Filled State')}
                      onChange={(e: any) => {
                        onChange(e)
                      }}
                      disabled
                    >
                      <MenuItem value={true as any}>{t('empty', 'Empty')}</MenuItem>
                      <MenuItem value={false as any}>{t('full', 'Full')}</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label={t('grossWeightKg', 'Gross Weight (kg)')}
                variant='outlined'
                type='number'
                {...register('grossWeight', { required: true, valueAsNumber: true })}
                name='grossWeight'
                error={!!errors.grossWeight}
                helperText={errors.grossWeight ? t('fieldIsRequired', 'Field is required.') : ''}
                sx={{ marginTop: '1rem' }}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={`imoClasses`}
                render={({ field: { onChange } }) => (
                  <DangerousGoodAutoComplete
                    assignedCodes={getValues(`imoClasses`) ?? []}
                    onDangerousGoodChange={newValues => {
                      onChange(newValues)
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl sx={{ mt: '1rem' }} variant='outlined' fullWidth>
                <InputLabel>{t('damaged', 'Damaged')}</InputLabel>
                <OutlinedInput
                  value={containerItem?.activeDamages.length ? t('yes', 'Yes') : t('no', 'No')}
                  label={t('damaged', 'Damaged')}
                  disabled
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        color='primary'
                        onClick={() =>
                          navigationStore.push(<ContainerDamagesPage container={containerItem} />)
                        }
                        edge='end'
                      >
                        <EditIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <HoldsAccordion
                holds={
                  order.holds
                    .map(h => ({ ...h, direction: order.direction }) as HoldWithDirection)
                    .filter(h => h.status === HoldStatus.Active) ?? []
                }
              />
            </Grid>
          </Grid>

          <FullWidthFooter>
            <Button
              variant='contained'
              sx={{ m: '1rem' }}
              fullWidth
              color='secondary'
              onClick={() => onFormSubmit(getValues())}
            >
              Save
            </Button>
            <Button
              variant='contained'
              sx={{ m: '1rem' }}
              fullWidth
              color='secondary'
              onClick={handleOnClose}
            >
              Cancel
            </Button>
          </FullWidthFooter>
        </form>
      )}
    </>
  )
})
