import { Box, Typography } from '@mui/material'
import { ComponentStatus } from '@storage/helpers/enums'
import { ValueBox } from './ValueBox'

interface ValueBoxWithTextProps {
  text: string
  value: number
  state: ComponentStatus
}

export const ValueBoxWithText = ({ text, value, state }: ValueBoxWithTextProps) => {
  return (
    <Box
      sx={{
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 0.2,
      }}
    >
      <ValueBox value={value} state={state} sx={{ width: '60px' }} />
      <Typography variant='body2' color='text.secondary' textAlign={'center'}>
        {text}
      </Typography>
    </Box>
  )
}
