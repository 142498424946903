import { useOperationsStore } from '@operations/AppProvider'
import { Button, Stack, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'

export const JobMonitoringContainer = observer(() => {
  const { t } = useTranslate()
  const { jobMonitoringDrawerUIStore } = useOperationsStore()

  return (
    <Stack paddingY={2}>
      <Stack flexDirection='row' justifyContent='space-between'>
        <Typography textAlign='center' width='90%'>
          {t('jobMonitoringPage', 'Job monitoring page will be here (one day)')}
        </Typography>
        <Button
          color='primary'
          variant='contained'
          onClick={() => jobMonitoringDrawerUIStore.toggle()}
        >
          {t('createJob', 'Create Job')}
        </Button>
      </Stack>
    </Stack>
  )
})
