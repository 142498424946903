import {
  CreateGeneralCargoAreaDto,
  GeneralCargoAreaDto,
  GeneralCargoAreasApi,
} from '@storage/app/api'
import { AxiosPromise } from 'axios'

export class GeneralCargoAreaService {
  constructor(private api: GeneralCargoAreasApi) {}

  async getAll(): Promise<Array<GeneralCargoAreaDto>> {
    const { data: GeneralCargoAreas } = await this.api.getAll()
    return GeneralCargoAreas
  }

  async create(createGeneralCargoAreaDto: CreateGeneralCargoAreaDto): Promise<GeneralCargoAreaDto> {
    const { data: generalCargoArea } = await this.api.create({
      generalCargoAreasCreateRequest: createGeneralCargoAreaDto,
    })
    return generalCargoArea
  }

  async update(generalCargoAreaDto: GeneralCargoAreaDto) {
    await this.api.update({
      id: generalCargoAreaDto.id,
      generalCargoAreasUpdateRequest: generalCargoAreaDto,
    })
  }

  async isNameUnique(name: string, id?: string): AxiosPromise<boolean> {
    return await this.api.isNameUnique({
      name,
      id,
    })
  }

  async delete(generalCargoAreaId: string) {
    await this.api._delete({ id: generalCargoAreaId })
  }
}
