import { SelectOption } from '@storage/app/models'
import { tolgee } from '@storage/app/translation'
import { useMemo } from 'react'
import { StackingStrategy } from '../yard-block-form.profile'

export const useStackingStrategyOptions = (): SelectOption[] =>
  useMemo(
    () =>
      Object.values(StackingStrategy).map(value => {
        return {
          value: value,
          label: tolgee.t(value),
        }
      }),
    [],
  )
