import { Alert, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { useOperationsStore } from '@operations/AppProvider'
import { HeaderWithLogout } from '@operations/features/equipmentOperator/components/Header/HeaderWithLogout'
import { useTranslate } from '@tolgee/react'
import { ContainerMobile, SearchIcon } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { CoolingOrderList } from '../components/CoolingOrderList/CoolingOrderList'
import { YardBlockChips } from '../components/YardBlockChips/YardBlockChips'

export const CoolingOrderListContainer = observer(() => {
  const { coolingOrdersUIStore } = useOperationsStore()
  const { t } = useTranslate()

  const handleSearch = () => {
    coolingOrdersUIStore.toggleSearch()
  }

  return (
    <>
      <HeaderWithLogout
        title={t('reeferOperator', 'Reefer operator')}
        hideNotification
        action={
          <>
            {coolingOrdersUIStore.coolingOrdersFiltered.length > 0 && (
              <Tooltip title={t('search', 'Search')}>
                <IconButton onClick={handleSearch}>
                  <SearchIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        }
      />
      <ContainerMobile>
        <Stack gap={theme => theme.customSpacing.m} paddingTop={theme => theme.customSpacing.m}>
          {coolingOrdersUIStore.yardBlocks.length > 0 && (
            <>
              <Typography variant='subtitle1'>{t('yardBlocks', 'Yard blocks')}</Typography>
              <Stack flexDirection='row' justifyContent='space-between'>
                <YardBlockChips
                  yardBlocks={coolingOrdersUIStore.yardBlocks}
                  selectedYardBlocks={coolingOrdersUIStore.selectedYardBlocks}
                  onSelectYardBlock={yardBlock => coolingOrdersUIStore.setYardBlock(yardBlock)}
                  onRemoveYardBlock={yardBlock => coolingOrdersUIStore.setYardBlock(yardBlock)}
                />
              </Stack>
            </>
          )}

          <CoolingOrderList orders={coolingOrdersUIStore.coolingOrdersFiltered} />

          {coolingOrdersUIStore.coolingOrders.length === 0 && (
            <Alert severity='warning'>
              <Typography variant='subtitle2'>
                {t('noMatchingResults', 'No matching results')}
              </Typography>
            </Alert>
          )}
        </Stack>
      </ContainerMobile>
    </>
  )
})
