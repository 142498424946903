import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useTranslate } from '@tolgee/react'

interface Props {
  open: boolean
  title: string
  content: React.ReactElement
  onClose: () => void
  onConfirm: () => void
}

export const ConfirmationDialogContainer = ({
  open,
  title,
  content,
  onClose,
  onConfirm,
}: Props) => {
  const { t } = useTranslate()

  return (
    <Dialog onClose={onClose} open={open} fullWidth>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>{content}</DialogContent>

      <DialogActions>
        <Button variant='outlined' color='inherit' onClick={onClose}>
          {t('cancel', 'Cancel')}
        </Button>
        <Button variant='contained' color='primary' type='button' onClick={onConfirm}>
          {t('confirm', 'Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
