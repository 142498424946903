import { createSvgIcon } from '@mui/material'

export const ArrowUpLeftIcon = createSvgIcon(
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M6.3434 17.6569H8.33744L8.34451 9.75847L16.95 18.364L18.3642 16.9497L9.75873 8.34426L17.6571 8.35133L17.6571 6.34315H6.3434V17.6569Z'
      fill='#212B36'
    />
  </svg>,
  'ArrowUpLeftIcon',
)
