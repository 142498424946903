import { Card, CardContent, CardHeader, Stack, Typography } from '@mui/material'
import { ReactNode } from 'react'
import { useMinimalsTheme } from '../../../hooks'

interface Props {
  label: string
  required?: boolean
  rightRender: ReactNode
  hasDivider?: boolean
  hasError?: boolean
  children?: ReactNode
  titleColor?: string
}
export const FieldBox = ({
  label,
  required,
  rightRender,
  hasDivider,
  hasError,
  children,
  titleColor,
}: Props) => {
  const theme = useMinimalsTheme()

  return (
    <Card
      variant='outlined'
      sx={{
        boxShadow: 'none',
        border: `1px solid ${hasError ? theme.palette.error.main : theme.palette.grey[300]}`,
      }}
    >
      <CardHeader
        title={
          <Stack flexDirection='row' gap={0.5}>
            <Typography variant='subtitle2' color={titleColor}>
              {label}{' '}
            </Typography>{' '}
            {required && (
              <Typography variant='subtitle2' color='error'>
                *
              </Typography>
            )}
          </Stack>
        }
        action={rightRender}
        sx={{ paddingBottom: theme => theme.customSpacing.m }}
      ></CardHeader>

      {children && (
        <CardContent
          sx={{
            borderTop: theme => (hasDivider ? `1px solid ${theme.palette.grey[300]}` : undefined),
          }}
        >
          {children}
        </CardContent>
      )}
    </Card>
  )
}
