import { MenuProps } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'

interface Position {
  x: number
  y: number
}

const useContextMenu = () => {
  const [contextPosition, setContextPosition] = useState<Position | null>(null)
  const [anchorElement, setAnchorElement] = useState<Element | null>(null)

  const handleOpenMenu = useCallback(
    (x: number, y: number) => {
      setContextPosition(
        contextPosition === null
          ? {
              x: x + 2,
              y: y - 6,
            }
          : null,
      )
    },
    [contextPosition],
  )

  const handleAttachMenu = useCallback(
    (element: Element) => {
      setAnchorElement(anchorElement === null ? element : null)
    },
    [anchorElement],
  )

  const handleClose = useCallback(() => {
    setContextPosition(null)
    setAnchorElement(null)
  }, [])

  const menuProps = useMemo(
    (): MenuProps => ({
      open: !!contextPosition || !!anchorElement,
      onClose: () => handleClose(),
      onClick: () => handleClose(),
      anchorReference: contextPosition ? 'anchorPosition' : anchorElement ? 'anchorEl' : 'none',
      anchorPosition: contextPosition
        ? { top: contextPosition.y, left: contextPosition.x }
        : undefined,
      anchorEl: anchorElement,
    }),
    [anchorElement, contextPosition, handleClose],
  )

  return {
    menuProps,
    handleOpenMenu,
    handleAttachMenu,
  }
}

export default useContextMenu
