import { action, makeObservable, observable } from 'mobx'

type SubmitDamageReportMethod = (data: DamageReport) => void

export interface DamageReport {
  description?: string
  created?: Date
  resolved?: Date
  quantity?: number
  imagePaths?: string[] | null
  damageImageFiles?: File[]
}

export class DamageReportStore {
  isOpen = false
  damageReport?: DamageReport = {}
  editingDamageReport?: DamageReport = {}

  submitMethod?: SubmitDamageReportMethod

  constructor() {
    makeObservable(this, {
      isOpen: observable,
      editingDamageReport: observable,
      damageReport: observable,
      closeDialog: action,
      openDialog: action,
      setDamageReport: action,
    })
  }

  openDialog(submitMethod: SubmitDamageReportMethod, damageReport?: DamageReport) {
    this.editingDamageReport = damageReport

    this.submitMethod = submitMethod
    this.isOpen = true
  }

  closeDialog() {
    this.isOpen = false

    this.reset()
  }

  setDamageReport = (damageReport?: DamageReport) => {
    this.damageReport = damageReport
  }

  onSubmit() {
    if (this.submitMethod && this.damageReport) {
      this.submitMethod(this.damageReport)
    }

    this.closeDialog()
  }

  private reset() {
    this.setDamageReport()
    this.editingDamageReport = {}
    this.submitMethod = undefined
  }
}

export const damageReportStore = new DamageReportStore()
