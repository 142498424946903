import { NonNumericOrdersApi, OrderResponseDto } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'
import { Event, IEvent, IQueryHandler, IQueryMessage } from '../messageBus'

export class GetOrdersByNnrOrderIdQuery implements IQueryMessage {
  static type = 'GetOrdersByNnrOrderIdQuery'
  type = GetOrdersByNnrOrderIdQuery.type

  constructor(public nnrOrderId: number) {}

  getKey = () => {
    return JSON.stringify(this)
  }
}

const httpClient = createApiClient(NonNumericOrdersApi)

export const getOrdersByNnrOrderIdQueryHandler: IQueryHandler<
  GetOrdersByNnrOrderIdQuery,
  IEvent<OrderResponseDto[]>
> = async query => {
  const { data } = await httpClient.orders(query.nnrOrderId)

  return new Event(GetOrdersByNnrOrderIdQuery.type, data)
}
