import { TenantAppConfigApi, TenantConfigDto } from '@storage/app/api'
import { createApiClient } from '@storage/app/http-client'

export class TenantConfigService {
  async loadAppConfigs(): Promise<TenantConfigDto> {
    const { data: info } = await createApiClient(TenantAppConfigApi).getAll()

    return info
  }
}
