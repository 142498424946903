import { Stack, TextField, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

interface IProps {
  isOrderFulfilled: boolean
  onValidationParamsChanged: (params: any) => void
}

export const IdentifiableItemsControl = ({
  isOrderFulfilled,
  onValidationParamsChanged,
}: IProps) => {
  const { t } = useTranslate()
  const {
    formState: { errors },
    control,
  } = useFormContext()

  const plannedCargoAmount = useWatch({ control, name: 'plannedCargoAmount' })

  return (
    <Stack gap={1}>
      <Typography variant='subtitle1'>{t('identifiableItems', 'ID')}</Typography>
      <Typography variant='caption'>
        {t(
          'identifiableItemsCaption',
          'Please assign unique identifiers to each item using comma. This functionality could be applied only if all items have each unique number',
        )}
      </Typography>
      <Controller
        name='identifiableItems'
        control={control}
        rules={{
          validate: (value: string) => {
            if (value !== '' && value !== undefined) {
              const arrayValue = value.split(',').map(item => item.trim())
              return parseInt(plannedCargoAmount) === arrayValue.length
            }
          },
        }}
        render={({ field: { onChange, value } }) => (
          <TextField
            label={`${t('identifiers', 'Unique identifiers for each item')}`}
            onChange={onChange}
            value={value}
            disabled={isOrderFulfilled}
            multiline
            error={!!errors.identifiableItems}
            helperText={
              errors.identifiableItems
                ? t(
                    'itemsLessThanPlannedQuantity',
                    'Enter the identifiable items for each planned cargo',
                  )
                : ''
            }
            onBlur={e =>
              onValidationParamsChanged({
                unitIds: e.currentTarget.value
                  .split(',')
                  .map(e => e.trim())
                  .filter(e => e.length > 0),
              })
            }
          />
        )}
      />
    </Stack>
  )
}
