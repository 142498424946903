import { Card, SxProps, Typography } from '@mui/material'
import { ComponentStatus, Size } from '@storage/helpers/enums'
import { useMinimalsTheme } from '@tom-ui/ui'

interface ValueBoxProps {
  value: number
  state?: ComponentStatus
  size?: Size
  color?: string
  valueColor?: string
  sx?: SxProps
  onClick?: (event: React.MouseEvent) => void
}

export const ValueBox = ({ value, state, size, color, valueColor, sx, onClick }: ValueBoxProps) => {
  const { palette } = useMinimalsTheme()

  const getColor = (state?: ComponentStatus) => {
    switch (state) {
      case ComponentStatus.Success:
        return palette.success.lighter
      case ComponentStatus.Warning:
        return palette.warning.main
      case ComponentStatus.Error:
        return palette.error.lighter
      case ComponentStatus.Info:
        return '#1890FF29'
      default:
        return ''
    }
  }

  const cardStyle: SxProps = {
    textAlign: 'center',
    boxShadow: 0,
    px: 0.5,
    backgroundColor: color ?? getColor(state),
    cursor: onClick ? 'pointer' : 'default',
    borderRadius: 1,
    ...sx,
  }

  return (
    <Card sx={{ ...cardStyle }} onClick={onClick}>
      <Typography
        variant={size === Size.Small ? 'body1' : 'h6'}
        sx={{ color: valueColor, fontWeight: 700 }}
      >
        {value}
      </Typography>
    </Card>
  )
}
