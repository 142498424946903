import { Button } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { NavigationStackBackButton } from '@tom-ui/planning'
import { RoutingInfo } from '@tom-ui/storage'
import { Header, StepperInfo } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

type Props = {
  dropOffs: string[]
  pickUps: string[]
  nonNumeric: any[]
  onSubmit: () => Promise<void>
  onClose: () => void
}

export const GateInRouteInfoPage: FC<Props> = observer(
  ({ dropOffs, pickUps, nonNumeric, onSubmit, onClose }) => {
    const { t } = useTranslate()

    return (
      <>
        <Header
          title={t('gateIn', 'Gate in')}
          leftRenderOption={<NavigationStackBackButton />}
          rightRenderOption={
            <Button
              data-cy='gate-in-route-info-page-gate-in-btn'
              variant='contained'
              sx={{ width: '15%', minWidth: '100px' }}
              onClick={onSubmit}
            >
              {t('gateIn', 'Gate In')}
            </Button>
          }
        />
        <StepperInfo
          title={t('provideRoutingInfo', 'Provide routing info')}
          steps={`2 ${t('of', 'of')} 2`}
        />
        <RoutingInfo
          dropOffContainers={dropOffs}
          pickUpContainers={pickUps}
          nonNumeric={nonNumeric}
        />
      </>
    )
  },
)
