import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { ConfirmationDialog } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import DGStackingRulesTableForm from '../forms/dg-stacking-rules-table-form/DGStackingRulesTableForm'
import { DGStackingRuleFormProfile } from '../forms/dg-stacking-rules-table-form/dg-stacking-rules-table-form.profile'

const DGStackingRulesTableDialog = observer(() => {
  const { t } = useTranslate()
  const { dgStackingRulesTableUIStore } = useStores()
  const dialogUtilStore = dgStackingRulesTableUIStore.dialogUtilStore

  const formId = 'dg-tracking-rules-table-form'

  const handleOnClose = () => {
    dialogUtilStore.toggleDialog()
  }

  const handleOnSubmit = async (formValues: DGStackingRuleFormProfile) =>
    await dgStackingRulesTableUIStore.update(formValues, handleOnClose)

  const handleOnDelete = async () => await dgStackingRulesTableUIStore.clear(handleOnClose)

  return (
    <ConfirmationDialog
      open={dialogUtilStore.isDialogOpen}
      title={dialogUtilStore.dialogConfig.title}
      primaryActionText={dialogUtilStore.dialogConfig.primaryButtonLabel}
      closeLabel={dialogUtilStore.dialogConfig.secondaryButtonLabel}
      formId={formId}
      maxWidth='xs'
      onClose={handleOnClose}
      hasBorderTop={dialogUtilStore.dialogAction !== 'Delete'}
      blockClickOutside={dialogUtilStore.dialogAction === 'Delete'}
      message={
        dialogUtilStore.dialogAction === 'Delete'
          ? t(
              'ruleWillBeCleared',
              'This DG stacking rule will be cleared and you cannot undo this action!',
            )
          : undefined
      }
      onConfirm={dialogUtilStore.dialogAction === 'Delete' ? handleOnDelete : undefined}
    >
      {dialogUtilStore.dialogAction !== 'Delete' && (
        <DGStackingRulesTableForm
          id={formId}
          onSubmit={handleOnSubmit}
          dgStackingRuleDto={dgStackingRulesTableUIStore.dialogEntity!}
        />
      )}
    </ConfirmationDialog>
  )
})

export default DGStackingRulesTableDialog
