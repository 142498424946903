import { CreatePierDto, PierDto, PiersApi, UpdatePierDto } from '@storage/app/api'
import { SelectOption } from '@storage/app/models'
import { EntityStore } from '@storage/app/store/entity.store'

export class PierStore extends EntityStore<PierDto> {
  constructor(private api: PiersApi) {
    super()
  }

  public get entriesAsOptions(): SelectOption[] {
    return this.entries.map(({ id, name }) => ({ label: name, value: id }))
  }

  public async loadList(): Promise<void> {
    const { data: piers } = await this.api.getAll()

    this.setAll(piers)
  }

  public async add(dto: CreatePierDto): Promise<void> {
    const { data: pier } = await this.api.create({ piersCreateRequest: dto })

    this.addOrUpdate(pier)
  }

  public async update(dto: UpdatePierDto): Promise<void> {
    const { data: pier } = await this.api.update({ piersUpdateRequest: dto })

    this.addOrUpdate(pier)
  }

  public async delete(pierId: string): Promise<void> {
    await this.api._delete({ id: pierId })

    this.remove(pierId)
  }
}
