import { InputBase, Paper } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { CloseButton, SearchIcon, useMinimalsTheme } from '@tom-ui/ui'
import { ReactNode, RefObject } from 'react'

interface Props {
  search: string
  label: string
  searchRef: RefObject<HTMLDivElement>
  showFilter?: boolean
  advancedFilter?: ReactNode
  onSearch: (value: string) => void
  onClearSearch?: () => void
  dataCy?: string
  removeElevation?: boolean
}

export const SearchInput = ({
  search,
  label,
  searchRef,
  showFilter,
  advancedFilter,
  onSearch,
  onClearSearch,
  dataCy,
  removeElevation,
}: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const handleSearch = (event: any) => {
    onSearch(event.target.value)
  }

  return (
    <Paper
      elevation={removeElevation ? 0 : 4}
      sx={{
        p: '8px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,
        width: '100%',
      }}
      ref={searchRef}
    >
      <SearchIcon sx={{ paddingLeft: '4px' }} />
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        value={search}
        placeholder={
          showFilter
            ? t('searchWithAdvancedFilters', 'Search for {label} or use the advanced filter', {
                label: label,
              })
            : t('searchFor', 'Search for {label}', { label: label })
        }
        onChange={handleSearch}
        fullWidth
        data-cy={`${dataCy}-input`}
      />
      {onClearSearch && search && (
        <CloseButton tooltip={t('clearSearch', 'Clear search')} onClose={onClearSearch} />
      )}

      {!!advancedFilter && advancedFilter}
    </Paper>
  )
}
