import {
  CarrierType,
  CarrierVisitDto,
  CarrierVisitStatus,
  CarrierVisitsApi,
  EquipmentDto,
  EquipmentType,
  EquipmentsApi,
} from '@operations/app/api'
import { CarrierVisitRefreshEventPayload } from '@operations/app/api/signalRDtos/carrierVisitRefreshEventPayload'
import { createApiClient } from '@operations/app/http-client'
import { EventTypes } from '@operations/messages/eventsTypes'
import { IEvent, IMessageBus } from '@operations/messages/messageBus'
import { AppStore } from '@tom-ui/utils'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'

export class CraneOperatorLandingContainerUIStore {
  loadingSignalR = false

  vesselVisitsWithGeometry: CarrierVisitDto[] = []
  plannedCranesOfSelectedVisit: EquipmentDto[] = []

  selectedVesselVisitId?: number
  selectedEquipmentId?: number

  constructor(
    private messageBus: IMessageBus,
    private appStore: AppStore,
  ) {
    makeObservable(this, {
      vesselVisitsWithGeometry: observable,
      plannedCranesOfSelectedVisit: observable,

      selectedVesselVisitId: observable,
      selectedEquipmentId: observable,

      equipmentIsRequired: computed,

      setSelectedVesselVisitId: action,
      setSelectedEquipmentId: action,
    })

    this.messageBus.subscribeEvent(
      EventTypes.CarrierVisitRefresh,
      this.reloadVisitsAndCranesBySignalR,
    )
  }

  setSelectedVesselVisitId(id?: number): void {
    if (this.selectedVesselVisitId != id) {
      this.selectedVesselVisitId = id
      this.setSelectedEquipmentId()
      this.loadPlannedCranesOfSelectedVisit()
    }
  }

  setSelectedEquipmentId(id?: number): void {
    if (this.selectedEquipmentId != id) this.selectedEquipmentId = id
  }

  async loadPlannedCranesOfSelectedVisit() {
    const { data } = await createApiClient(EquipmentsApi).get(
      this.selectedVesselVisitId,
      EquipmentType.Sts,
    )
    runInAction(() => (this.plannedCranesOfSelectedVisit = data))
  }

  async loadNotLeftTerminalVesselVisits() {
    const { data } = await createApiClient(CarrierVisitsApi).get(CarrierType.Vessel, undefined, [
      CarrierVisitStatus.InOperation,
      CarrierVisitStatus.Arrived,
      CarrierVisitStatus.Expected,
    ])
    runInAction(() => (this.vesselVisitsWithGeometry = data))
  }

  reloadVisitsAndCranesBySignalR = async (res: IEvent<CarrierVisitRefreshEventPayload>) => {
    //refresh only if event update is about everything(carrierVisits is null) or about vessel visits
    if (
      res.payload?.carrierVisits?.length &&
      !res.payload?.carrierVisits.find(c => c.carrierType === CarrierType.Vessel)
    )
      return

    if (!this.loadingSignalR) {
      this.loadingSignalR = true
      await this.appStore.triggerReloadBySignalR(
        this.reloadVisitsAndCranes,
        '/operatorViews',
        '/Crane/Landing',
      )
      this.loadingSignalR = false
    }
  }

  async reloadVisitsAndCranes() {
    await this.loadNotLeftTerminalVesselVisits()
    await this.loadPlannedCranesOfSelectedVisit()
  }

  public get equipmentIsRequired() {
    return (
      this.vesselVisitsWithGeometry.find(vv => vv.id === this.selectedVesselVisitId)?.status ==
      CarrierVisitStatus.InOperation
    )
  }
}
