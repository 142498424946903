import { BerthStore } from '@storage/stores/berth.store'
import { PierStore } from '@storage/stores/pier.store'
import { BerthsDialogFeatureStore } from './berths-dialog.feature-store'
import { BerthsListFeatureStore } from './berths-list.feature-store'
import { PiersDialogFeatureStore } from './piers-dialog.feature-store'
import { PiersListFeatureStore } from './piers-list.feature-store'

export class BerthsFeatureStore {
  public berthsList: BerthsListFeatureStore
  public berthsDialog: BerthsDialogFeatureStore

  public piersList: PiersListFeatureStore
  public piersDialog: PiersDialogFeatureStore

  constructor(berthStore: BerthStore, pierStore: PierStore) {
    this.berthsList = new BerthsListFeatureStore(berthStore, pierStore)
    this.berthsDialog = new BerthsDialogFeatureStore(berthStore)

    this.piersList = new PiersListFeatureStore(pierStore)
    this.piersDialog = new PiersDialogFeatureStore(pierStore)
  }
}
