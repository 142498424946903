import { Typography } from '@mui/material'
import { emptyIndicator } from '@planning/constants'
import { ReactNode } from 'react'
interface Props {
  label: string
  text?: string | null
  minWidth?: string
  disableGutters?: boolean
  elem?: ReactNode
  labelBelow?: boolean
  alignItems?: string
}

export const LabeledTypography = ({
  label,
  text,
  minWidth,
  elem,
  labelBelow,
  alignItems,
}: Props) => {
  const labelComponent = () => {
    return (
      <Typography variant='caption' color='secondary' justifyContent='left'>
        {label}
      </Typography>
    )
  }

  const textComponent = () => {
    return elem ? (
      <> {elem} </>
    ) : (
      <Typography variant='body2'>{!text || text === '' ? emptyIndicator : text}</Typography>
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: alignItems ?? 'center',
        justifyItems: 'center',
        paddingLeft: '4px',
        paddingRight: '4px',
        minWidth: minWidth,
        width: '100%',
      }}
    >
      {!labelBelow && labelComponent()}
      {textComponent()}
      {labelBelow && labelComponent()}
    </div>
  )
}
