import {
  Autocomplete,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { JobDto, RailcarTrackPositionDto } from '@operations/app/api'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { matchSorter } from 'match-sorter'
import { useState } from 'react'
import { DoorDirectionInfo } from '../JobInformation/DoorDirectionInfo'
import { JobInformationJourney } from '../JobInformation/JobInformationJourney'
import { StepperContentWrapper } from './StepperContentWrapper'

interface Props {
  job: JobDto
  railcarTrackPositions: RailcarTrackPositionDto[]
  containerOrigin?: string
  currentWeight: number
  currentStep: number
  stepsTotal: number
  hasError?: boolean
  showDoorDirectIfExists?: boolean
  handleRailcarTrackPosition: (id?: number) => void
}

export const ConfirmWagonNumber = ({
  job,
  railcarTrackPositions,
  containerOrigin,
  currentWeight,
  currentStep,
  stepsTotal,
  hasError,
  showDoorDirectIfExists,
  handleRailcarTrackPosition,
}: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const [railcarTrackPositionId, setRailcarTrackPositionId] = useState<number | null>()

  const handleChangeContainer = (event: any, newValue: RailcarTrackPositionDto | null) => {
    setRailcarTrackPositionId(newValue?.id)
    handleRailcarTrackPosition(newValue?.id)
  }

  const getHelperMessageContent = () => {
    if (hasError) return <>{t('wagonNumberIsMandatory', 'Wagon number is mandatory')}</>

    const id = railcarTrackPositionId ?? job.railcarTrackPositionId
    if (id) {
      const railCar = railcarTrackPositions.find(x => id === x.id)

      if (!railCar)
        return (
          <>
            <Typography variant='subtitle2'>
              {t(
                'wagonNumberDoesNotExistInOurSystem',
                'Wagon number does not exist in our system!',
              )}
            </Typography>
            <Typography variant='body2'>
              {t(
                'enterTheFullWagonNumberToAddANewWagon',
                'Enter the full wagon no. to add a new wagon.',
              )}
            </Typography>
          </>
        )
      else
        return (
          <Stack flexDirection='row' gap={1}>
            <Typography variant='subtitle2'>
              {t('wagonNumber', 'Wagon no.')} {railCar.railcarName}
            </Typography>
            <Typography variant='body2'>
              {t('alreadyCarryOnATotalGrossWeightOf', 'already carry on a total gross weight of')}{' '}
              {(railCar.totalGrossWeight ?? 0) + currentWeight} kg
            </Typography>
          </Stack>
        )
    }

    return (
      <Stack flexDirection='row' gap={1}>
        <Typography variant='subtitle2'>{t('hint', 'Hint')}:</Typography>
        <Typography variant='body2'>
          {t('tryTheLastDigitsOfTheWagonNumber', 'Try the last 3 digits of the wagon number')}
        </Typography>
      </Stack>
    )
  }

  return (
    <StepperContentWrapper
      title={t('enterWagon', 'Enter wagon')}
      steps={`${currentStep} ${t('of', 'of')} ${stepsTotal}`}
    >
      <JobInformationJourney job={job} origin={containerOrigin}>
        <FormControl fullWidth error={hasError} sx={{ marginTop: theme.customSpacing.m }}>
          <Autocomplete
            options={railcarTrackPositions}
            getOptionLabel={option => option.railcarName}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={handleChangeContainer}
            defaultValue={railcarTrackPositions.find(x => x.id === job.railcarTrackPositionId)}
            fullWidth
            renderInput={params => (
              <TextField
                {...params}
                error={hasError}
                required
                label={t('wagonNumber', 'Wagon no.')}
                data-cy='confirm-container-waggon-number-input'
              />
            )}
            filterOptions={(options, { inputValue }) => {
              return matchSorter(options, inputValue.replace(/\s/g, ''), {
                keys: [item => item.railcarName.replace(/\./g, '').replace(/\s/g, '')],
                threshold: matchSorter.rankings.CONTAINS,
              })
            }}
          />
          <FormHelperText component='span'>{getHelperMessageContent()}</FormHelperText>
        </FormControl>
      </JobInformationJourney>

      {showDoorDirectIfExists && <DoorDirectionInfo job={job} />}
    </StepperContentWrapper>
  )
}
