import { DialogContent, DialogTitle, TextField } from '@mui/material'
import { ConfirmationActions, useMinimalsTheme } from '@tom-ui/ui'
import { IDamageReportViewStore } from '@planning/rt-stores/tallyman/DamageReportViewStore'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { useForm, useWatch } from 'react-hook-form'

interface IProps {
  store: IDamageReportViewStore
}

export const DamageReportForm: FC<IProps> = observer(({ store }) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const { damageReport } = store

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...damageReport,
    },
  })

  const data = useWatch({ control })
  store.setData(data)

  return (
    <form>
      <DialogTitle>{t('recordDamage', 'Record damage')}</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          multiline
          rows={4}
          label={`${t('description', 'Description')}*`}
          variant='outlined'
          {...register('description', { required: true })}
          name='description'
          error={!!errors.description}
          helperText={errors.description ? t('fieldIsRequired', 'Field is required.') : ''}
          sx={{ marginTop: '1rem' }}
        />
      </DialogContent>
      <ConfirmationActions
        primaryActionText={t('submit', 'Submit')}
        closeLabel={t('cancel', 'Cancel')}
        onConfirm={handleSubmit(store.saveChanges)}
        onClose={store.reset}
        mobile
      />
    </form>
  )
})
