import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { ConfirmationDialog } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { DGSegregationRuleFormProfile } from '../forms/dg-segregation-rules-form/dg-segregation-rules-table-form.profile'
import DGSegregationRulesTableForm from '../forms/dg-segregation-rules-form/DGSegregationRulesTableForm'

const DGSegregationRulesTableDialog = observer(() => {
  const { t } = useTranslate()
  const { dgSegregationRulesTableUIStore } = useStores()
  const dialogUtilStore = dgSegregationRulesTableUIStore.dialogUtilStore

  const formId = 'dg-segregation-rules-table-form'

  const handleOnClose = () => dgSegregationRulesTableUIStore.closeDialog()

  const handleOnClear = async () => await dgSegregationRulesTableUIStore.clear()

  const handleOnSubmit = async (formValues: DGSegregationRuleFormProfile) => {
    const { distance } = formValues
    distance === 0
      ? await dgSegregationRulesTableUIStore.clear()
      : await dgSegregationRulesTableUIStore.save(formValues.distance)
  }

  return (
    <ConfirmationDialog
      open={dialogUtilStore.isDialogOpen}
      title={dialogUtilStore.dialogConfig.title}
      primaryActionText={dialogUtilStore.dialogConfig.primaryButtonLabel}
      secondaryActionText={t('clearSelection', 'Clear Selection')}
      onSecondaryAction={handleOnClear}
      closeLabel={dialogUtilStore.dialogConfig.secondaryButtonLabel}
      formId={formId}
      maxWidth='xs'
      onClose={handleOnClose}
      blockClickOutside
    >
      <DGSegregationRulesTableForm id={formId} onSubmit={handleOnSubmit} />
    </ConfirmationDialog>
  )
})

export default DGSegregationRulesTableDialog
