import { Point } from '@storage/app/models'
import { ZOOM_MAX, ZOOM_STEP } from '@storage/pages/yard-management/constants'
import { action, makeObservable, observable } from 'mobx'
import { BoundingBox } from '@storage/types'

export class StageStore {
  position: Point = { x: 0, y: 0 }
  initialBoundingBox: BoundingBox = {
    minX: 0,
    maxX: 0,
    minY: 0,
    maxY: 0,
  }
  width = 0
  height = 0
  zoom = 1
  showGrid = false
  showBorders = true
  draggable = false
  zoomable = false
  showZoomToolbar = true

  constructor() {
    makeObservable(this, {
      position: observable,
      initialBoundingBox: observable,
      setInitialBoundingBox: action,
      setPosition: action,
      onDragEnd: action,

      width: observable,
      height: observable,
      onResize: action,

      zoom: observable,
      onZoom: action,
      setZoom: action,

      showGrid: observable,
      onToggleGrid: action,

      showBorders: observable,
      onToggleBorders: action,
    })
  }

  onDragEnd(position: Point) {
    if (!this.draggable) return

    this.setPosition(position)
  }

  setInitialBoundingBox(boundingBox: BoundingBox) {
    this.initialBoundingBox = boundingBox
  }

  setPosition(position: Point) {
    this.position = position
  }

  onZoom(delta: number) {
    if (!this.zoomable) return

    this.zoom = Math.max(0.1, Math.min(this.zoom - ZOOM_STEP * Math.sign(delta), ZOOM_MAX))
  }

  setZoom(zoom: number) {
    if (!this.zoomable) return

    this.zoom = zoom
  }

  onResize(width: number, height: number) {
    this.width = width
    this.height = height
  }

  onToggleGrid() {
    this.showGrid = !this.showGrid
  }

  onToggleBorders() {
    this.showBorders = !this.showBorders
  }
}
