export interface EquipmentFormProfile {
  id?: number
  name: string
  equipmentType: number | string
  isOnMaintenance: boolean
  maxWeight?: number | null
  maxHeight?: number | null
  yardBlockIds?: string[]
}

export const defaultValues: EquipmentFormProfile = {
  name: '',
  equipmentType: '',
  isOnMaintenance: false,
  maxWeight: undefined,
  maxHeight: undefined,
  yardBlockIds: [],
}
