import { Grid, TextField } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { CustomInputAdornment } from '@tom-ui/ui'
import { notAllowPlusMinusPointSign, onlyAllowPositiveInt } from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { FieldError, useFormContext } from 'react-hook-form'
import { ICreateRailcarFormData } from '../../Stores/RailTallyViewStore'

interface IProps {
  lastSequenceNumber?: number
}

export const CreateRailcarForm: FC<IProps> = observer(({ lastSequenceNumber }) => {
  const { t } = useTranslate()

  const {
    register,
    formState: { errors },
  } = useFormContext<ICreateRailcarFormData>()

  const getSequenceHelperText = (isSequenceError?: FieldError, isLastSequenceNumber?: number) => {
    if (isSequenceError) {
      return t('fieldIsRequired', 'Field is required.')
    } else if (lastSequenceNumber) {
      return t('lastSequenceNumberInput', 'Last sequence number input {lastSequenceNumber}', {
        lastSequenceNumber,
      })
    }
    return ''
  }

  return (
    <form>
      <Grid container spacing='1rem'>
        {/* //TODO Add Track selection */}
        <Grid item xs={12}>
          <TextField
            data-cy='railcar-number'
            fullWidth
            label={t('railcarNo', 'Railcar no.')}
            required
            variant='outlined'
            type='text'
            {...register('railcarNumber', { required: true })}
            name='railcarNumber'
            error={!!errors.railcarNumber}
            helperText={errors.railcarNumber ? t('fieldIsRequired', 'Field is required.') : ''}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            data-cy='railcar-sequence'
            fullWidth
            label={t('sequenceNo', 'Sequence no.')}
            required
            variant='outlined'
            type='number'
            {...register('sequence', { required: true, valueAsNumber: true, min: 1 })}
            name='sequence'
            onKeyDown={notAllowPlusMinusPointSign}
            onChange={onlyAllowPositiveInt}
            error={!!errors.sequence}
            helperText={getSequenceHelperText(errors.sequence, lastSequenceNumber)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            data-cy='railcar-length'
            fullWidth
            label={`${t('length', 'Length')}`}
            variant='outlined'
            type='number'
            InputProps={{
              endAdornment: (
                <CustomInputAdornment position='end' color='secondary'>
                  m
                </CustomInputAdornment>
              ),
            }}
            {...register('length', { valueAsNumber: true })}
            name='length'
            onKeyDown={notAllowPlusMinusPointSign}
            onChange={onlyAllowPositiveInt}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            data-cy='railcar-max-payload'
            fullWidth
            label={`${t('maxPayload', 'Max. payload')}`}
            variant='outlined'
            type='number'
            InputProps={{
              endAdornment: (
                <CustomInputAdornment position='end' color='secondary'>
                  kg
                </CustomInputAdornment>
              ),
            }}
            {...register('maxPayload', { valueAsNumber: true })}
            name='maxPayload'
            onKeyDown={notAllowPlusMinusPointSign}
            onChange={onlyAllowPositiveInt}
          />
        </Grid>
      </Grid>
    </form>
  )
})
