import {
  CarrierType,
  CarrierVisitDirection,
  OrderListOrderDto,
  OrderResponseDto,
  OrderStatus,
} from '@planning/app/api'
import { RailTrackItemStore } from '@planning/rt-stores/railTrack/RailTrackItemStore'
import { computed, makeObservable, runInAction } from 'mobx'
import { openOrderFromOtherVisitsKey, orderKey } from './OrderListUploadDataStore'
import { OrderListUploadViewStoreV2 } from './OrderListUploadViewStoreV2'
import { mapToOrderListOrderDto } from './OrderMappingHelper'

export interface IOrderUpdates {
  created: OrderListOrderDto[]
  deleted: OrderResponseDto[]
  unchanged: OrderListOrderDto[]
  amended: IOrderAmendment[]
}

export interface IFieldDifference {
  fieldName: string
  existingValue: string
  updateValue: string
}

export type OrderIssueType = 'MatchingOrderWithDifferentTransport'
export interface IOrderIssue {
  type: OrderIssueType
  containerNumber: string
  matchingOrders: OrderResponseDto[]
}

export interface IOrderAmendment extends OrderResponseDto {
  update: OrderListOrderDto
  differences: IFieldDifference[]
}

export class OrderListUploadComparisonStore {
  constructor(
    public parentStore: OrderListUploadViewStoreV2,
    private railTrackStore: RailTrackItemStore,
  ) {
    makeObservable(this, {
      orderUpdates: computed,
      issues: computed,
    })
  }

  get orderUpdates() {
    if (!this.parentStore.vesselVisitId && !this.parentStore.railVisitId) return undefined

    const { dataStore, direction, listName } = this.parentStore

    const created = dataStore.ordersFromUpdate.filter(
      update =>
        update.containerNumber &&
        !dataStore.existingOrdersByContainerNumber.has(
          orderKey(update.containerNumber, direction, listName),
        ),
    )

    created.filter(o => o.containerNumber).forEach(this.linkToAdjacentOrder)

    const { amended, unchanged } = this.findAmendedAndUnchangedOrders()

    const deleted = dataStore.ordersFromVisit.filter(
      existing =>
        existing.containerNumber &&
        existing.direction === direction &&
        existing.status === OrderStatus.Open &&
        (existing.listName ?? OrderListUploadViewStoreV2.defaultListName) === listName &&
        !dataStore.updatingOrdersByContainerNumber.has(existing.containerNumber),
    )

    return {
      created,
      deleted,
      amended,
      unchanged,
    } as IOrderUpdates
  }

  private findAmendedAndUnchangedOrders = () => {
    const amended = [] as IOrderAmendment[]
    const unchanged = [] as OrderListOrderDto[]

    const { dataStore, direction, listName } = this.parentStore

    dataStore.ordersFromUpdate.forEach(update => {
      if (!update.containerNumber) return

      if (
        dataStore.existingOrdersByContainerNumber.has(
          orderKey(update.containerNumber, direction, listName),
        )
      ) {
        const existing = dataStore.existingOrdersByContainerNumber.get(
          orderKey(update.containerNumber, direction, listName),
        )[0]

        const amendment = this.mapToAmendment(update, existing)

        if (amendment.differences.length) amended.push(amendment)
        else unchanged.push(update)
      }
    })

    return { amended, unchanged }
  }

  // We might want to reconsider this logic
  // upload inbound -> usual linking
  //   * no service orders exist since only created orders are considered
  //   * only unlinked outbounds are considered - hence also not connected to service orders
  // upload outbound
  //   * inbound does not reference service orders - usual linking
  //   * inbound has service orders - link it or not?
  //      + inbound full with StrippingOrder: link if outbound is empty?
  //      + inbound empty with StuffingOrder: link if outbound is full?
  // ~~ For now we do not link orders referencing Stripping-/StuffingOrders
  private linkToAdjacentOrder = (createdOrder: OrderListOrderDto) => {
    const { dataStore, direction } = this.parentStore
    const { openOrdersFromOtherVisitsByContainerNumber } = dataStore

    const oppositeDirection =
      direction === CarrierVisitDirection.Inbound
        ? CarrierVisitDirection.Outbound
        : CarrierVisitDirection.Inbound

    const lookupKey = openOrderFromOtherVisitsKey(createdOrder.containerNumber, oppositeDirection)
    if (openOrdersFromOtherVisitsByContainerNumber.has(lookupKey)) {
      const openOrders = openOrdersFromOtherVisitsByContainerNumber.get(lookupKey).filter(o => {
        if (!o.hasServiceOrders) {
          return (createdOrder.isEmpty ?? false) === o.isEmpty
        }

        return false
      })

      if (openOrders.length) {
        runInAction(() => {
          createdOrder.linkedOrderId = openOrders[0].id
        })
      }
    }
  }

  get issues() {
    const issues = [] as IOrderIssue[]
    const { dataStore, direction } = this.parentStore

    dataStore.ordersFromUpdate.forEach(o => {
      if (
        dataStore.existingUpdateRelatedOrdersByContainerNumber.has(
          orderKey(o.containerNumber, direction),
        )
      ) {
        const openOrdersWithDifferentVisit = dataStore.existingUpdateRelatedOrdersByContainerNumber
          .get(orderKey(o.containerNumber, direction))
          .filter(
            o =>
              o.status === OrderStatus.Open &&
              o.direction === this.parentStore.direction &&
              o.carrierVisitId !==
                (this.parentStore.vesselVisitId || this.parentStore.railVisitId) &&
              o.carrierVisitType === CarrierType.Vessel,
          )

        if (openOrdersWithDifferentVisit.length) {
          issues.push({
            type: 'MatchingOrderWithDifferentTransport',
            containerNumber: o.containerNumber,
            matchingOrders: openOrdersWithDifferentVisit,
          })
        }
      }
    })

    return issues
  }

  mapToAmendment = (update: OrderListOrderDto, existing: OrderResponseDto) =>
    ({
      ...existing,
      update: { ...update, id: existing.id },
      differences: this.findDifferences(
        update,
        mapToOrderListOrderDto(existing),
        existing.customerName ?? '',
      ),
    }) as IOrderAmendment

  private findDifferences = (
    update: OrderListOrderDto,
    existing: OrderListOrderDto,
    existingCustomerName?: string,
  ) => {
    const differences = [] as IFieldDifference[]
    const { customer } = this.parentStore

    if (customer?.name !== existingCustomerName) {
      differences.push({
        fieldName: 'customerName',
        existingValue: existingCustomerName ?? '',
        updateValue: customer?.name ?? '',
      })
    }

    if (update.grossWeight !== existing.grossWeight) {
      differences.push({
        fieldName: 'grossWeight',
        existingValue: existing.grossWeight?.toString() ?? '',
        updateValue: update.grossWeight?.toString() ?? '',
      })
    }

    if (update.isEmpty !== existing.isEmpty)
      differences.push({
        fieldName: 'isEmpty',
        existingValue: existing.isEmpty?.toString() ?? '',
        updateValue: update.isEmpty?.toString() ?? '',
      })

    if (update.isoCode !== existing.isoCode)
      differences.push({
        fieldName: 'isoCode',
        existingValue: existing.isoCode?.toString() ?? '',
        updateValue: update.isoCode?.toString() ?? '',
      })

    if (update.operator !== existing.operator)
      differences.push({
        fieldName: 'operator',
        existingValue: existing.operator?.toString() ?? '',
        updateValue: update.operator?.toString() ?? '',
      })

    if (update.portOfDischarge !== existing.portOfDischarge)
      differences.push({
        fieldName: 'portOfDischarge',
        existingValue: existing.portOfDischarge?.toString() ?? '',
        updateValue: update.portOfDischarge?.toString() ?? '',
      })

    if (update.portOfLoading !== existing.portOfLoading)
      differences.push({
        fieldName: 'portOfLoading',
        existingValue: existing.portOfLoading?.toString() ?? '',
        updateValue: update.portOfLoading?.toString() ?? '',
      })

    if (update.referenceNumber !== existing.referenceNumber)
      differences.push({
        fieldName: 'referenceNumber',
        existingValue: existing.referenceNumber?.toString() ?? '',
        updateValue: update.referenceNumber?.toString() ?? '',
      })

    if ((update.temperature ?? '') !== (existing.temperature ?? ''))
      differences.push({
        fieldName: 'temperature',
        existingValue: existing.temperature?.toString() ?? '',
        updateValue: update.temperature?.toString() ?? '',
      })

    if (!this.isEqual(update.imoClasses, existing.imoClasses))
      differences.push({
        fieldName: 'imoClasses',
        existingValue: existing.imoClasses?.join(', ') ?? '',
        updateValue: update.imoClasses?.join(', ') ?? '',
      })

    if ((update.railTrackId ?? '') !== (existing.railTrackId ?? '')) {
      const currentTrack = this.railTrackStore.activeRailTracks.find(
        x => x.id === existing.railTrackId,
      )
      const nextTrack = this.railTrackStore.activeRailTracks.find(x => x.id === update.railTrackId)

      differences.push({
        fieldName: 'railTrack',
        existingValue: currentTrack?.name ?? '',
        updateValue: nextTrack?.name ?? '',
      })
    }

    if ((update.waggon ?? '') !== (existing.waggon ?? '')) {
      differences.push({
        fieldName: 'railcarName',
        existingValue: existing.waggon ?? '',
        updateValue: update.waggon ?? '',
      })
    }

    return differences
  }

  private isEqual = (left: string[], right: string[]) =>
    left.length === right.length && left.every(leftElem => right.includes(leftElem))
}
