import _ from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import { VesselTallyViewStore } from './VesselTallyViewStore'

export class SearchContainerViewStore {
  selectedOrderId?: number
  searchFilter = ''

  constructor(public parentStore: VesselTallyViewStore) {
    makeObservable(this, {
      selectedOrderId: observable,
      searchFilter: observable,
      selectedOrder: computed,
      filteredOrders: computed,
      selectOrder: action,
      setSearchFilter: action,
    })
  }

  get selectedOrder() {
    return _(this.parentStore.orders).find(vv => vv.id === this.selectedOrderId)
  }

  get filteredOrders() {
    return this.parentStore.orders?.filter(
      o =>
        o.data.status === 'Open' &&
        o.data.containerNumber?.toLocaleLowerCase().includes(this.searchFilter.toLocaleLowerCase()),
    )
  }

  selectOrder = (id: number) => {
    this.selectedOrderId = id
  }

  setSearchFilter = (filter: string) => {
    this.searchFilter = filter
  }
}
