import {
  ContainerDamageLocationDto,
  ContainerDamageTypeDto,
  CreateContainerDamageLocationCommand,
  CreateContainerDamageTypeCommand,
  UpdateContainerDamageLocationCommand,
  UpdateContainerDamageTypeCommand,
} from '@admin/app/api'
import { ContainerDamageCatalogueFormProfile } from './container-damage-form.profile'

const mapFormValuesToCreateDto = ({
  name,
  code,
}: ContainerDamageCatalogueFormProfile):
  | CreateContainerDamageLocationCommand
  | CreateContainerDamageTypeCommand => ({
  name,
  code,
})

const mapFormValuesToUpdateDto = ({
  id,
  name,
  code,
}: ContainerDamageCatalogueFormProfile):
  | UpdateContainerDamageLocationCommand
  | UpdateContainerDamageTypeCommand => ({
  id: id!,
  name,
  code,
})

const mapDtoToFormValues = ({
  id,
  name,
  code,
}: ContainerDamageLocationDto | ContainerDamageTypeDto): ContainerDamageCatalogueFormProfile => ({
  id,
  name,
  code,
})

export const ContainerDamageCatalogueFormMapper = {
  mapFormValuesToCreateDto,
  mapFormValuesToUpdateDto,
  mapDtoToFormValues,
}
