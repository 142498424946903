import { AlertColor } from '@mui/material'
import { appStore } from '@tom-ui/utils'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { ReactNode } from 'react'

export class AppViewStore {
  openDialog = false
  dialogContent = ''
  dialogWarning = ''
  dialogTitle: string | undefined
  showAlertWithAction = false
  openFileUploadDialog = false
  isLoading = false
  createdVesselVisitId?: number
  createdRailVisitId?: number

  confirmHandler?: (cancel: boolean) => void = undefined

  constructor() {
    makeObservable(this, {
      openDialog: observable,
      dialogTitle: observable,
      dialogContent: observable,
      dialogWarning: observable,
      isLoading: observable,
      openFileUploadDialog: observable,
      showAlertWithAction: observable,
      createdVesselVisitId: observable,
      createdRailVisitId: observable,

      setOpenDialog: action,
      setOpenConfirmDialog: action,
      setShowAlert: action,
      showToDoAlert: action,
    })
  }

  setOpenConfirmDialog = (
    open: boolean,
    content?: string,
    title?: string,
    warning?: string,
  ): Promise<boolean> => {
    this.openDialog = open
    this.dialogContent = content ?? ''
    this.dialogWarning = warning ?? ''
    this.dialogTitle = title
    return new Promise(resolve => {
      runInAction(() => {
        this.confirmHandler = resolve
      })
    })
  }

  setShowAlert = (severity?: AlertColor, message?: string, action?: ReactNode) => {
    if (message) {
      appStore.setShowAlert(severity ?? 'success', message, action)
    }
  }

  showToDoAlert = () => {
    appStore.setShowAlert('error', 'Under construction...')
  }

  setOpenDialog(open: boolean) {
    this.openDialog = open
  }

  setCreatedVesselVisitId(id: number) {
    this.createdVesselVisitId = id
    this.createdRailVisitId = undefined
  }

  setCreatedRailVisitId(id: number) {
    this.createdRailVisitId = id
    this.createdVesselVisitId = undefined
  }

  showLoader = () => {
    runInAction(() => {
      this.isLoading = true
    })
  }

  hideLoader = () => {
    runInAction(() => {
      this.isLoading = false
    })
  }
}
