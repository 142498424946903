import { OperationType } from '@operations/app/api'
import { EquipmentOperatorLandingUIStore } from '@operations/features/equipmentOperator/stores/equipment-operator-landing.ui-store'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'

interface Props {
  uiStore: EquipmentOperatorLandingUIStore
}

export const SelectOperationType = observer(({ uiStore }: Props) => {
  const { t } = useTranslate()

  return (
    <FormControl fullWidth>
      <InputLabel>{t('process', 'Process')}</InputLabel>
      <Select
        label={t('process', 'Process')}
        value={uiStore.selectedOperationType ?? 'All'}
        onChange={(event: SelectChangeEvent) =>
          uiStore.selectOperationType(
            event.target.value === 'All' ? null : (event.target.value as OperationType),
          )
        }
      >
        <MenuItem value='All'>{t('all', 'All')}</MenuItem>
        <MenuItem value={OperationType.Inbound}>{t('discharge', 'Discharge')}</MenuItem>
        <MenuItem value={OperationType.Outbound}>{t('load', 'Load')}</MenuItem>
      </Select>
    </FormControl>
  )
})
