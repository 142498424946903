export interface SplitFormProfile {
  containersAmount: number
  twinContainers: boolean
  totalMoves: number
}

export const defaultValues: SplitFormProfile = {
  containersAmount: 0,
  twinContainers: false,
  totalMoves: 0,
}
