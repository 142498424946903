import { Stack, Typography } from '@mui/material'
import { RailVisitResponseDto } from '@planning/app/api'
import { CargoTypeIconLabel } from '@planning/components/CargoTypeIconLabel'
import { CarrierVisitStatusChip } from '@planning/components/CarrierVisitStatusChip'
import { emptyIndicator } from '@planning/constants'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { RailVisitHeaderContainer } from './RailVisitHeaderContainer'

export const RailVisitHeaderVisit: FC<{
  railVisit: RailVisitResponseDto
}> = observer(({ railVisit }) => {
  const { t } = useTranslate()

  if (!railVisit) return <></>

  const trainName = railVisit.name?.toUpperCase() ?? emptyIndicator

  return (
    <RailVisitHeaderContainer title={trainName}>
      <Stack alignItems='space' justifyContent='space-between' sx={{ height: '100%' }}>
        <CargoTypeIconLabel cargoType={railVisit.cargoType} />
        <Stack spacing={0}>
          <Typography variant='body2' color='secondary'>
            {railVisit.inboundTripIds &&
            railVisit.inboundTripIds.length &&
            railVisit.inboundTripIds[0] !== ''
              ? `${t('tripIds', 'Trip IDs')} In: ${railVisit.inboundTripIds
                  ?.filter((id: string) => id.trim() !== '')
                  .join(', ')}`
              : emptyIndicator}
            {' / '}
            {railVisit.outboundTripIds &&
            railVisit.outboundTripIds.length &&
            railVisit.outboundTripIds[0] !== ''
              ? `${t('tripIds', 'Trip IDs')} Out: ${railVisit.outboundTripIds
                  ?.filter((id: string) => id.trim() !== '')
                  .join(', ')}`
              : emptyIndicator}
          </Typography>
        </Stack>
        <CarrierVisitStatusChip status={railVisit.status} />
      </Stack>
    </RailVisitHeaderContainer>
  )
})
