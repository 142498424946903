import { SouthEastOutlined } from '@mui/icons-material'
import NorthWestIcon from '@mui/icons-material/NorthWest'
import { Box, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { OrderResponseDto, OrderStatus } from '@planning/app/api'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { GeneralCargoOrderCard } from './GeneralCargoOrderCard'

type Props = {
  order: OrderResponseDto
  type: 'DropOff' | 'PickUp'
}

export const GeneralCargoOrderStateCard: FC<Props> = observer(({ order, type }) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  return (
    <Stack direction='row' bgcolor={theme.palette.grey[100]} sx={{ p: '0 1rem 0 0' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '8px 16px 8px 16px',
          borderWidth: '0px 1px 0px 0px',
          marginRight: '.5rem',
          borderStyle: 'solid',
          borderColor: `${theme.palette.grey[300]}`,
        }}
      >
        <Stack>
          <Typography
            color={order.status === OrderStatus.Fulfilled ? 'secondary' : ''}
            sx={{ margin: 0, padding: 0, display: 'flex', placeContent: 'center' }}
          >
            {type === 'PickUp' ? <NorthWestIcon /> : ''}
            {type === 'DropOff' ? <SouthEastOutlined /> : ''}
          </Typography>

          <Typography
            variant='caption'
            fontWeight='bold'
            color={order.status === OrderStatus.Fulfilled ? 'secondary' : ''}
          >
            {type === 'PickUp' ? t('pick', 'Pick').toUpperCase() : ''}
            {type === 'DropOff' ? t('drop', 'Drop').toUpperCase() : ''}
          </Typography>
        </Stack>
      </Box>
      <GeneralCargoOrderCard order={order} />
    </Stack>
  )
})
