import { YardBlocksApi, YardInconsistencyDto } from '@storage/app/api'
import { createApiClient } from '@storage/app/http-client'
import { action, computed, makeObservable, observable } from 'mobx'

export class YardInconsistenciesStore {
  issueRepository = new Map<string, YardInconsistencyDto>()
  api: YardBlocksApi = createApiClient(YardBlocksApi)

  constructor() {
    makeObservable(this, {
      issues: computed,
      count: computed,
      setIssues: action,
      issueRepository: observable,
    })
  }

  get issues() {
    return Array.from(this.issueRepository.values()).filter(e => e.inconsistencies.length > 0)
  }

  get count() {
    return this.issues.reduce((sum, issue) => (sum += issue.inconsistencies.length), 0)
  }

  setIssues(issues: YardInconsistencyDto[]) {
    issues.forEach(issue => this.issueRepository.set(issue.yardBlockId, issue))
  }

  public async loadAll(): Promise<void> {
    const { data: issues } = await this.api.getYardInconsistencies()
    this.setIssues(issues)
  }
}
