import { InternalAxiosRequestConfig } from 'axios'
import { accessTokenProvider } from 'modules/utils/src'

export const onFulfilled = async (request: InternalAxiosRequestConfig<any>) => {
  if (accessTokenProvider.get) {
    const accessToken = await accessTokenProvider.get()
    request.headers.Authorization = `Bearer ${accessToken}`
  }

  return request
}
