import { Dialog } from '@mui/material'
import { IDamageReportViewStore } from '@planning/rt-stores/tallyman/DamageReportViewStore'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { DamageReportForm } from './DamageReportForm'

interface IProps {
  store: IDamageReportViewStore
}

export const DamageReportDialog: FC<IProps> = observer(({ store }) => {
  return (
    <Dialog
      open={store.isOpen}
      onClose={() => {
        store.setOpen(false)
      }}
      fullWidth
    >
      <DamageReportForm store={store} />
    </Dialog>
  )
})
