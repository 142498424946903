import { AppStore } from '@tom-ui/utils'
import { AxiosError, InternalAxiosRequestConfig } from 'axios'
import { ShouldTriggerLoadingProvider } from '../exclude-from-loading'

export const onFulfilled = (appStore: AppStore) => {
  return (request: InternalAxiosRequestConfig<any>) => {
    if (ShouldTriggerLoadingProvider.get(request)) {
      appStore.increaseLoadingCounter()
    }

    return request
  }
}

export const onRejected = (appStore: AppStore) => {
  return (error: AxiosError) => {
    appStore.decreaseLoadingCounter()

    return Promise.reject(error)
  }
}
