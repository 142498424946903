import {
  AllowOrderPassageCommand,
  CarrierVisitDirection,
  CreateOrdersCommand,
} from '@planning/app/api'
import { orderService } from '@planning/services'
import { CancellableTimeoutStore } from '@planning/stores/gateClerk/CancellableTimeoutStore'
import { AxiosError } from 'axios'
import { action, makeObservable, observable } from 'mobx'

export class ReportContainerNotificationStore extends CancellableTimeoutStore {
  containerNumber?: string

  constructor(delay?: number) {
    super(delay)
    makeObservable(this, {
      containerNumber: observable,
      setContainerNumber: action,
    })
  }

  setContainerNumber = (containerNumber?: string) => {
    this.containerNumber = containerNumber
  }

  createReportRequest = async (cmd: CreateOrdersCommand) => {
    if (cmd.orders) {
      this.setContainerNumber(cmd.orders[0].containerNumber ?? '')
      this.createRequest(async () => {
        try {
          const { data: orderId } = await orderService.create(cmd)

          await orderService.allowPassage({
            id: orderId[0],
            carrierVisitDirection: CarrierVisitDirection.Inbound,
          } as AllowOrderPassageCommand)
        } catch (error) {
          const err = error as AxiosError
          this.setRequestErrorMessage(err.message)
        }
        this.reset()
      })
    }
  }

  cancelRequest = () => {
    if (!this.request) return

    super.cancelRequest()

    setTimeout(async () => {
      this.reset()
    }, this.delay)
  }

  reset() {
    super.reset()
    this.setContainerNumber()
  }
}
