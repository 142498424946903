import { Checkbox, Grid, Paper, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { FC } from 'react'

interface Props {
  checked: boolean
  onCheck: () => void
}

export const SelectAllCard: FC<Props> = ({ checked, onCheck }) => {
  const { t } = useTranslate()

  return (
    <Paper variant='outlined' sx={{ width: '100%', pb: '0.25rem', pt: '0.25rem', mt: 1 }}>
      <Grid container>
        <Grid item xs={1} mr='0.5rem' display='flex' justifyContent='center'>
          <Checkbox checked={checked} onClick={onCheck} />
        </Grid>
        <Grid item xs display='flex' flexDirection='column' justifyContent='center'>
          <Typography variant='subtitle2'>{t('selectAll', 'Select all')}</Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}
