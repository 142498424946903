import { useAdminStore } from '@admin/AppProvider'
import { TenantDto, TerminalType } from '@admin/app/api'
import Permission from '@admin/app/models/permission.enum'
import { overwriteMinimalTableHeadCellStyle } from '@admin/utils/minimal-theme-overwrite'
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { BinIcon, EditIcon, Header, InfoIcon, PlusIcon, useMinimalsTheme } from '@tom-ui/ui'
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'

export const TenantsContainer = observer(() => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const { tenantUIStore, tenantDrawerUIStore, timezonesStore, authStore } = useAdminStore()
  const canEdit = authStore.hasPermission(Permission.WriteTenants)

  const getActiveConfigurations = (tenant: TenantDto) => {
    const activeConfigs: string[] = []

    if (tenant.configs) {
      for (const key in tenant.configs) {
        if (Object.prototype.hasOwnProperty.call(tenant.configs, key)) {
          const element = (tenant.configs as any)[key]
          const notBooleanConfigs = ['currentOperatorViewSettings', 'selectedOperatorViewConfigs']

          if (element && !notBooleanConfigs.includes(key)) {
            activeConfigs.push(t(key))
          }
        }
      }
    }

    return activeConfigs.length ? activeConfigs.join(', ') : ' - '
  }

  const columns = useMemo<MRT_ColumnDef<TenantDto>[]>(
    () => [
      {
        accessorKey: 'id',
        header: t('id', 'Id'),
      },
      {
        accessorKey: 'name',
        header: t('name', 'Name'),
      },
      {
        accessorKey: 'timezoneId',
        header: t('timezone', 'Time Zone'),
        accessorFn: row =>
          `${
            row.timezoneId
              ? timezonesStore.timezones.find(tz => tz.id === row.timezoneId)?.name
              : ''
          }`,
      },
      {
        accessorKey: 'cultureId',
        header: t('cultureName', 'Culture'),
        accessorFn: row =>
          `${row.cultureId ? timezonesStore.cultures.find(c => c.id === row.cultureId)?.name : ''}`,
      },
      {
        accessorKey: 'configs.skipCraneSplit',
        header: t('activeConfigurations', 'Active configurations'),
        accessorFn: row => getActiveConfigurations(row),
      },
      {
        accessorKey: 'configs.operatorViewConfigs',
        header: t('operatorView', 'Operator view settings'),
        accessorFn: (dto: TenantDto) => {
          switch (dto.configs?.selectedOperatorViewConfigs) {
            case TerminalType.Hig:
              return t('default', 'Default')

            case TerminalType.Inland:
              return (
                <>
                  {t('inland', 'Inland')}
                  <IconButton
                    onClick={() =>
                      tenantDrawerUIStore.openPopup(dto.configs?.currentOperatorViewSettings ?? '')
                    }
                  >
                    <InfoIcon />
                  </IconButton>
                </>
              )
            default:
              return t('unknown', 'Unknown')
          }
        },
      },
    ],
    [t, timezonesStore, tenantDrawerUIStore],
  )

  return (
    <>
      <Header
        title={t('tenants', 'Tenants')}
        searchInputLabel={t('searchForAName', 'Search for a name')}
        onSearchFieldChange={value => tenantUIStore.setSearchTenant(value)}
        displaySearchIcon
        enableSearchField
        rightRenderOption={
          <Stack flexDirection='row' gap={theme.customSpacing.xs}>
            {canEdit && (
              <Button
                color='inherit'
                variant='contained'
                sx={{ flex: 'none' }}
                startIcon={<PlusIcon />}
                onClick={() => tenantDrawerUIStore.toggle()}
              >
                {t('addNew', 'Add new')}
              </Button>
            )}
          </Stack>
        }
      />

      <Container
        sx={{
          paddingY: theme.customSpacing.m,
        }}
      >
        <MaterialReactTable
          muiTableHeadCellProps={overwriteMinimalTableHeadCellStyle}
          columns={columns}
          data={tenantUIStore.tenants}
          enableRowActions
          autoResetPageIndex={false}
          enableColumnActions={false}
          enableTopToolbar={false}
          renderRowActions={({ row }) =>
            canEdit
              ? [
                  <Tooltip key={1} title={t('edit', 'Edit')}>
                    <IconButton
                      aria-label='edit'
                      onClick={() => tenantDrawerUIStore.toggle(row.original.id)}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>,
                  <Tooltip key={2} title={t('remove', 'Remove')}>
                    <IconButton
                      aria-label='delete'
                      onClick={() => tenantUIStore.setToBeRemoveTenantId(row.original.id)}
                    >
                      <BinIcon />
                    </IconButton>
                  </Tooltip>,
                ]
              : undefined
          }
          positionActionsColumn='last'
          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: '',
              muiTableBodyCellProps: {
                align: 'right',
              },
            },
          }}
        />
      </Container>

      <Dialog
        fullWidth
        maxWidth='lg'
        open={tenantDrawerUIStore.isPopupOpen}
        onClose={() => tenantDrawerUIStore.closePopup()}
      >
        <DialogTitle>{t('operatorView', 'Operator view settings')}</DialogTitle>
        <DialogContent>
          <pre style={{ fontFamily: 'inherit' }}>
            <Typography sx={{ p: 2 }}>{tenantDrawerUIStore.popupText}</Typography>
          </pre>
        </DialogContent>
      </Dialog>
    </>
  )
})
