import { Box } from '@mui/material'
import { TruckVisitDto } from '@planning/app/api'
import { IListStore } from '@planning/components/list/SimpleListStore'
import { useTranslate } from '@tolgee/react'
import { ContainerMobile, Header, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import { ContainerSearchLoader } from '../TallymanV2/Components/ContainerSearchLoader'
import { CustomAlert } from '../TallymanV2/Components/CustomAlert'
import { NavigationStackBackButton } from '../TallymanV2/Components/HeaderComponents/NavigationStackBackButton'
import { TruckVisitSearchResult } from './Components/TruckVisitSearchResult'

interface IProps {
  store: IListStore<TruckVisitDto>
  onClick: (visit: TruckVisitDto) => Promise<void>
}

export const SearchArrivedTruckVisit: FC<IProps> = observer(({ store, onClick }) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  useEffect(() => {
    window.scrollTo(0, 0)
    if (store && store.reset) store.reset()
  }, [store])

  return (
    <>
      <Header
        leftRenderOption={<NavigationStackBackButton />}
        enableSearchField
        onSearchFieldChange={store.setFilter}
        searchFilter={store.filter}
        searchInputType='text'
        searchInputLabel={t('truckPlateNumber', 'Truck plate number')}
        filterTestId='search-arrived-truck-plate-field'
      />

      <Box style={{ backgroundColor: theme.palette.grey[200], minHeight: '100vh' }}>
        <ContainerMobile sx={{ pt: theme.customSpacing.l }}>
          {!store.filter && (
            <CustomAlert
              message={t('searchForTruckPlateNumber', 'Search for truck plate number')}
              severity='info'
            />
          )}
          {!!store.filter && !store.isLoading && store.isNoMatches && (
            <CustomAlert
              message={t(
                'noTruckPlateNumbersFoundContactControlRoom',
                'No truck plate numbers found, contact control room',
              )}
              severity='warning'
            />
          )}
          {store.isLoading && <ContainerSearchLoader />}
          {store.items.map(visit => (
            <TruckVisitSearchResult
              key={`truck-visit-search-result-${visit.id}`}
              visit={visit}
              highlight={store.filter}
              onClick={() => onClick(visit)}
            />
          ))}
        </ContainerMobile>
      </Box>
    </>
  )
})
