import ReportStep from '@billing/app/models/report-step.enum'
import { useBillingServiceStore } from '@billing/AppProvider'
import exportService from '@billing/services/ExportService'
import { observer } from 'mobx-react-lite'
import { StorageReport } from '../components/storageReport/StorageReport'
import { StorageReportFilter } from '../components/storageReport/StorageReportFilter'
import { ReportNavigationButtonContainer } from './ReportNavigationButtonContainer'

export const StorageReportContainer = observer(() => {
  const { reportsUIStore, storageReportUIStore } = useBillingServiceStore()

  const finish = () => {
    storageReportUIStore.resetData()

    reportsUIStore.resetStepper()
  }

  return (
    <>
      {reportsUIStore.currentStep === ReportStep.Filter && <StorageReportFilter />}
      {reportsUIStore.currentStep === ReportStep.Report && <StorageReport />}
      <ReportNavigationButtonContainer
        disableNext={
          !storageReportUIStore.storageReportMode ||
          !storageReportUIStore.from ||
          !storageReportUIStore.to
        }
        downloadAction={() =>
          exportService.exportNonVesselContainers(
            storageReportUIStore.from!,
            storageReportUIStore.to!,
            storageReportUIStore.storageReportMode!,
          )
        }
        finishAction={finish}
      />
    </>
  )
})
