import { BerthDto } from '@storage/app/api'
import ContextMenuIcon from '@storage/components/icons/svg/ContextMenuIcon.svg'
import { KonvaText } from '@storage/components/konva-text'
import useContextMenu from '@storage/hooks/use-context-menu.hook'
import { Menu, MenuItem } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useTransformer } from '@storage/pages/yard-management/hooks'
import { YardElement, YardElementType } from '@storage/pages/yard-setup/types/yard-setup.types'
import { useTranslate } from '@tolgee/react'
import Konva from 'konva'
import { Vector2d } from 'konva/lib/types'
import { useRef } from 'react'
import { Group, Image, Rect, Transformer } from 'react-konva'
import { Html, useImage } from 'react-konva-utils'
import { BERTH_DIMENSIONS } from './constants'

interface Props {
  berth: BerthDto
  canBeModified: boolean
  stagePosition: Vector2d
  onResize: (width: number) => void
  onSelect: () => void
  onEdit: () => void
  onDelete: () => void
  onGeometryChange: (element: YardElement) => void
}

export const BerthYardElement = ({
  berth,
  canBeModified,
  stagePosition,
  onSelect,
  onEdit,
  onDelete,
  onResize,
  onGeometryChange,
}: Props) => {
  const { t } = useTranslate()
  const { palette, typography } = useMinimalsTheme()
  const [contextMenuIcon] = useImage(ContextMenuIcon)
  const berthRef = useRef<Konva.Group>(null)

  const { handleOpenMenu, menuProps } = useContextMenu()

  const { transformerProps, handleTransformEnd } = useTransformer({
    refreshDeps: [canBeModified],
    stagePosition,
    transformerFor: berthRef,
    onRotate: (x, y, rotation) => {
      onGeometryChange({
        type: YardElementType.Berth,
        id: berth.id,
        geometry: {
          left: x,
          top: y,
          rotation: Math.round(rotation),
        },
      })
    },
    onResize,
    currentWidth: berth.geometry?.width ?? BERTH_DIMENSIONS.MIN_WIDTH,
    maxResizeWidth: BERTH_DIMENSIONS.MAX_WIDTH,
    minResizeWidth: BERTH_DIMENSIONS.MIN_WIDTH,
    currentRotation: berth.geometry?.rotation,
    resizeAngles: ['middle-left', 'middle-right'],
  })

  const handleDragEnd = async (p: Vector2d) => {
    onGeometryChange({
      type: YardElementType.Berth,
      id: berth.id,
      geometry: {
        top: Math.round(p.y),
        left: Math.round(p.x),
      },
    })
  }

  if (!berth.geometry) {
    return <></>
  }

  return (
    <>
      <Group
        x={berth.geometry.left}
        y={berth.geometry.top}
        height={BERTH_DIMENSIONS.MIN_HEIGHT}
        width={berth.geometry?.width ?? BERTH_DIMENSIONS.MIN_WIDTH}
        ref={berthRef}
        rotation={berth.geometry.rotation}
        onTransformEnd={handleTransformEnd}
        draggable={canBeModified}
        onDragEnd={e => handleDragEnd(e.currentTarget.position())}
      >
        <Rect
          height={BERTH_DIMENSIONS.MIN_HEIGHT}
          width={berth.geometry?.width ?? BERTH_DIMENSIONS.MIN_WIDTH}
          fill={palette.secondary.light}
          cornerRadius={8}
          fontStyle={typography.fontWeightBold}
        />

        <KonvaText
          height={BERTH_DIMENSIONS.MIN_HEIGHT}
          width={berth.geometry.width}
          text={berth.name}
          align='center'
          verticalAlign='middle'
          onClick={onSelect}
        />

        {canBeModified && (
          <>
            <Image
              image={contextMenuIcon}
              x={berth.geometry?.width ?? BERTH_DIMENSIONS.MIN_WIDTH}
              onClick={({ evt }) => handleOpenMenu(evt.clientX, evt.clientY)}
            />
            <Html>
              <Menu {...menuProps}>
                <MenuItem onClick={onEdit}>{t('edit', 'Edit')}</MenuItem>
                <MenuItem onClick={onDelete}>{t('delete', 'Delete')}</MenuItem>
              </Menu>
            </Html>
          </>
        )}
      </Group>

      {canBeModified && <Transformer {...transformerProps} />}
    </>
  )
}
