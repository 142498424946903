import { IconButton, List, ListItem, Typography } from '@mui/material'
import { BreakTimeResponseDto } from '@planning/app/api'
import { NoInfoMessage } from '@planning/components/dashboard-cards/NoInfoMessage'
import { EditIcon } from '@tom-ui/ui'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'

interface IProps {
  breakTimes?: BreakTimeResponseDto[] | null
  onEdit?: (data: BreakTimeResponseDto) => void
}

export const VisitBreakList = observer(({ breakTimes, onEdit }: IProps) => {
  const renderBreakTimeInfo = (data: BreakTimeResponseDto): string => {
    const fromDate = dayjs(data.from)
    const toDate = dayjs(data.to)

    const sameDate = fromDate.isSame(toDate, 'day')

    if (sameDate) {
      return `${fromDate.format('DD/MM/YYYY')} - ${data.description} - ${data.duration?.toFixed(1)} hours`
    } else {
      return `${fromDate.format('DD/MM/YYYY HH:mm')} - ${toDate.format('HH:mm')} - ${data.description} - ${data.duration?.toFixed(1)} hours`
    }
  }

  if (!breakTimes || breakTimes.length === 0) return <NoInfoMessage />

  return (
    <List>
      {breakTimes.map((item: BreakTimeResponseDto) => (
        <ListItem
          key={item.id}
          sx={{
            display: 'flex',
            padding: '0.25rem',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant='body1'>{renderBreakTimeInfo(item)}</Typography>
          <IconButton aria-label='edit' onClick={() => onEdit && onEdit(item)}>
            <EditIcon />
          </IconButton>
        </ListItem>
      ))}
    </List>
  )
})
