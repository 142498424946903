import { FilterFeatureStore } from '@storage/app/store/filter.feature-store'
import { action, computed, makeObservable, observable } from 'mobx'
import { ContainerTurnoverFilterFormMapper } from '../components/container-turnovers-filter-form'
import {
  ContainerTurnoversFilterFormProfile,
  defaultValues,
} from '../components/container-turnovers-filter-form/container-turnovers-filter-form.profile'
import { ContainerTurnoversPaginationStore } from './container-turnovers-pagination.store'

export class ContainerTurnoversFilterStore extends FilterFeatureStore<ContainerTurnoversFilterFormProfile> {
  showAdvancedFilters = false

  constructor(
    private readonly _containerTurnoversPaginationStore: ContainerTurnoversPaginationStore,
    predefinedValues?: Partial<ContainerTurnoversFilterFormProfile>,
  ) {
    super({ ...defaultValues, ...predefinedValues })

    makeObservable(this, {
      showAdvancedFilters: observable,
      toggleShowAdvancedFilters: action,
      clearAllFilters: action,
      filterAsQueryParams: computed,
    })
  }

  get filterAsQueryParams(): Record<string, string> {
    return ContainerTurnoverFilterFormMapper.mapFormValuesToQueryParams(this.filter)
  }

  public setFilter(newFilter: ContainerTurnoversFilterFormProfile): void {
    super.setFilter(newFilter)

    this._containerTurnoversPaginationStore.reset()

    this.showAdvancedFilters = false
  }

  public toggleShowAdvancedFilters() {
    this.showAdvancedFilters = !this.showAdvancedFilters
  }

  public clearAllFilters() {
    super.reset()
  }
}
