import { Drawer } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { FC, ReactElement } from 'react'

interface Props {
  open: boolean
  onClose: () => void | Promise<void>
  children: ReactElement
  anchor?: 'right' | 'left' | 'top' | 'bottom'
}

export const FullScreenDrawer: FC<Props> = observer(({ children, open, onClose, anchor }) => {
  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor={anchor ?? 'bottom'}
      PaperProps={{
        sx: { height: '100vh', width: '100%' },
      }}
    >
      {children}
    </Drawer>
  )
})
