import {
  CarrierVisitStatus,
  EquipmentDto,
  WorkAreaEquipmentBindingTypes,
  WorkInstructionStatus,
} from '@operations/app/api'
import { EquipmentStore } from '@operations/stores/EquipmentStore'
import { RailVisitStore } from '@operations/stores/RailVisitStore'
import { WorkAreaStore } from '@operations/stores/WorkAreaStore'
import _ from 'lodash'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { WorkAreaEquipments } from '../models/work-area-equipments.model'

export class EquipmentPlanningRailEquipmentsUIStore {
  carrierVisitId?: number
  selectedEquipmentIds: number[] = []
  draggingEquipmentId?: number
  assignmentType: WorkAreaEquipmentBindingTypes = WorkAreaEquipmentBindingTypes.Discharge
  equipmentIdsOnUseForOtherVistis: number[] = []

  constructor(
    private equipmentStore: EquipmentStore,
    private workAreaStore: WorkAreaStore,
    private railVisitStore: RailVisitStore,
  ) {
    makeObservable(this, {
      carrierVisitId: observable,
      selectedEquipmentIds: observable,
      draggingEquipmentId: observable,
      assignmentType: observable,
      equipmentIdsOnUseForOtherVistis: observable,

      setCarrierVisitId: action,
      setAssignmentType: action,
      setEquipmentIdsInUseForOtherVisits: action,

      workAreaOfVisit: computed,
      availableEquipments: computed,
      isDndDisabled: computed,

      workAreasEquipments: computed,

      hasWorkArea: computed,
    })
  }

  public setCarrierVisitId(carrierVisitId: number): void {
    if (this.carrierVisitId !== carrierVisitId) this.carrierVisitId = carrierVisitId
  }

  public setAssignmentType(assignmentType: WorkAreaEquipmentBindingTypes): void {
    this.assignmentType = assignmentType
  }

  public setEquipmentIdsInUseForOtherVisits(equipmentIds: number[]) {
    this.equipmentIdsOnUseForOtherVistis = equipmentIds
  }

  public getIsEquipmentInOperation(equipmentId: number) {
    return this.equipmentIdsOnUseForOtherVistis.includes(equipmentId)
  }

  public get workAreaOfVisit() {
    return this.carrierVisitId
      ? this.workAreaStore.items.find(i => i.carrierVisit?.id === this.carrierVisitId)
      : undefined
  }

  public get hasWorkArea() {
    return !!this.workAreaOfVisit
  }

  public get workAreasEquipments(): WorkAreaEquipments {
    if (!this.workAreaOfVisit) {
      return {
        workAreaId: 0,
        name: '',
        finishedCount: 0,
        expectedCount: 0,
        workAreaEquipments: [],
      }
    }

    const workInstructions =
      this.assignmentType === WorkAreaEquipmentBindingTypes.Discharge
        ? this.workAreaOfVisit.originWorkInstructions
        : this.workAreaOfVisit.destinationWorkInstructions

    const equipmentIds = this.workAreaOfVisit.workAreaEquipments
      .filter(e => e.assignmentType.includes(this.assignmentType))
      .map(e => e.equipmentId)

    return {
      workAreaId: this.workAreaOfVisit.id,
      name: this.workAreaOfVisit.name,
      finishedCount: workInstructions.filter(w => w.status === WorkInstructionStatus.Finished)
        .length,
      expectedCount: workInstructions.length,
      workAreaEquipments: _(
        this.equipmentStore.items.filter(item => equipmentIds.includes(item.id)),
      )
        .orderBy(i => [i.name, i.equipmentType])
        .value(),
    }
  }

  public get availableEquipments(): Array<EquipmentDto> | undefined {
    return this.carrierVisitId
      ? _(this.equipmentStore.items)
          .orderBy(i => i.name)
          .value()
      : undefined
  }

  unselectAll() {
    this.selectedEquipmentIds = []
  }

  startDragging(id: number) {
    const selected: number | undefined = this.selectedEquipmentIds.find(
      (eqId: number): boolean => eqId === id,
    )

    if (!selected) this.unselectAll()

    this.draggingEquipmentId = id
  }

  async endDragging(
    isCancelled: boolean,
    workAreaId: number | null,
    workAreaOriginId: number | null,
    index?: number,
  ) {
    // nothing to do
    if (index === undefined || isCancelled) {
      this.draggingEquipmentId = undefined
      return
    }

    // nothing to move (shouldn't happen)
    if (this.selectedEquipmentIds.length === 0 && !this.draggingEquipmentId) {
      return
    }

    const equipmentIds: number[] =
      this.selectedEquipmentIds.length > 0
        ? this.selectedEquipmentIds
        : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          [this.draggingEquipmentId!]

    try {
      equipmentIds.forEach(async equipmentId => {
        const eq = this.equipmentStore.items.find(eq => eq.id == equipmentId)
        if (!eq) return
        this.assignEquipment(eq.id, workAreaId, workAreaOriginId)
      })
    } finally {
      runInAction(async () => {
        this.selectedEquipmentIds = []
        this.draggingEquipmentId = undefined
      })
    }
  }

  async assignEquipment(
    equipmentId: number,
    workAreaId: number | null,
    workAreaOriginId: number | null,
  ) {
    await this.workAreaStore.assignEquipmentToWorkArea(
      equipmentId,
      this.assignmentType,
      workAreaId,
      workAreaOriginId,
    )
  }

  async unAssignEquipment(equipmentId: number, workAreaId: number) {
    await this.workAreaStore.unassignEquipmentFromWorkArea(
      equipmentId,
      workAreaId,
      this.assignmentType,
    )
  }

  public get isDndDisabled() {
    return this.carrierVisitId
      ? this.railVisitStore.items.find(i => i.id === this.carrierVisitId)?.status ===
          CarrierVisitStatus.Departed
      : true
  }
}
