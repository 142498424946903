import { useBillingServiceStore } from '@billing/AppProvider'
import ReportStep from '@billing/app/models/report-step.enum'
import exportService from '@billing/services/ExportService'
import { observer } from 'mobx-react-lite'
import { HandlingPreBillingReportFilter } from '../components/handlingPreBillingReport/HandlingPreBillingReportFilter'
import { HandlingPreBillingReportForm } from '../components/handlingPreBillingReport/HandlingPreBillingReportForm'
import { ReportNavigationButtonContainer } from './ReportNavigationButtonContainer'

export const HandlingServicesContainer = observer(() => {
  const { reportsUIStore, handlingServicesPrebillingUIStore, customerBillingUIStore } =
    useBillingServiceStore()

  const finish = () => {
    handlingServicesPrebillingUIStore.resetFilter()
    customerBillingUIStore.resetData()
    reportsUIStore.resetStepper()
  }
  return (
    <>
      {reportsUIStore.currentStep === ReportStep.Filter && <HandlingPreBillingReportFilter />}
      {reportsUIStore.currentStep === ReportStep.Report && (
        <HandlingPreBillingReportForm handlingStore={handlingServicesPrebillingUIStore} />
      )}
      <ReportNavigationButtonContainer
        disableNext={
          !handlingServicesPrebillingUIStore.from && !handlingServicesPrebillingUIStore.to
        }
        downloadAction={() =>
          exportService.exportHandlingServicesPreBillingData(
            handlingServicesPrebillingUIStore.ordersToBePreBilled!,
          )
        }
        finishAction={finish}
        disableDownload={
          !handlingServicesPrebillingUIStore.ordersToBePreBilled ||
          handlingServicesPrebillingUIStore.ordersToBePreBilled.length === 0
        }
      />
    </>
  )
})
