import { DialogContent } from '@mui/material'
import { CarrierType } from '@planning/app/api'
import { OrderListUploadViewStoreV2 } from '@planning/pages/Order/stores/OrderListUploadViewStoreV2'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { OrderListReviewAndEditContent } from './OrderListReviewAndEditContent'
import { OrderListUploadDocumentsContent } from './OrderListUploadDocumentsContent'

export interface IProps {
  store: OrderListUploadViewStoreV2
  enableVisitSelectField: boolean
  visitType?: CarrierType
  register: any
  errors: any
  setValue: any
  control: any
}

export const OrderListUploadDialogBody: FC<IProps> = observer(
  ({ store, visitType, register, errors, setValue, control, enableVisitSelectField }) => {
    const content = () => {
      if (store.activeStep === 0)
        return (
          <OrderListUploadDocumentsContent
            enableVisitSelectField={enableVisitSelectField}
            visitType={visitType}
            store={store}
            register={register}
            errors={errors}
            setValue={setValue}
            control={control}
          />
        )
      if (store.activeStep === 1) return <OrderListReviewAndEditContent store={store} />

      return <></>
    }

    return (
      <DialogContent sx={{ padding: 0, overflow: 'auto', height: '65vh' }}>
        {content()}
      </DialogContent>
    )
  },
)
