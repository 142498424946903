import { YardBlockBayDto, YardBlockDto, YardBlockRowDto } from '@storage/app/api'
import { Box, Button, Typography } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { PositionContext } from './yard-position-select.store'

export interface YardPositionSelectBodyProps {
  context: PositionContext
  blocks: YardBlockDto[]
  bays: YardBlockBayDto[]
  rows: YardBlockRowDto[]
  tiers: number[]
  locationSize: number
  onSelect: (value: any, next: PositionContext) => void
}

type PositionContextType = YardBlockDto | YardBlockBayDto | YardBlockRowDto | number

export const YardPositionSelectBody = ({
  context,
  blocks,
  bays,
  rows,
  tiers,
  locationSize,
  onSelect,
}: YardPositionSelectBodyProps) => {
  const { palette } = useMinimalsTheme()
  const getContext = () => {
    switch (context) {
      case PositionContext.Block:
        return {
          items: blocks,
          getValue: (item: PositionContextType) => {
            return {
              current: { block: item, flexibleZone: isFlexibleZone(item) },
              value: (item as YardBlockDto).name,
            }
          },
          next: PositionContext.Bay,
        }
      case PositionContext.Bay:
        return {
          items: bays,
          getValue: (item: PositionContextType) => {
            return { current: { bay: item }, value: (item as YardBlockBayDto).name }
          },
          next: PositionContext.Row,
        }
      case PositionContext.Row:
        return {
          items: rows,
          getValue: (item: PositionContextType) => {
            return { current: { row: item }, value: (item as YardBlockRowDto).name }
          },
          next: PositionContext.Tier,
        }
      case PositionContext.Tier:
        return {
          items: tiers,
          getValue: (item: PositionContextType) => {
            return { current: { tier: item }, value: item.toString() }
          },
          next: PositionContext.Tier,
        }
      default:
        return { items: [], getValue: () => undefined, next: PositionContext.None }
    }
  }

  const disableButton = (index: number) => {
    return context === PositionContext.Bay && index > bays.length - locationSize
  }

  const isFlexibleZone = (object: any): object is YardBlockDto => {
    if (!isNaN(object)) {
      return false
    }

    if ('maxTier' in object) {
      return (object as YardBlockDto).isFlexibleZone
    }

    return false
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        overflow: 'auto',
        gap: '1rem',
        width: 'inherited',
        height: 'inherited',
      }}
    >
      {getContext().items.map((e, index) => (
        <Button
          key={getContext().getValue(e)?.value}
          variant='contained'
          sx={{
            display: 'flex',
            backgroundColor: isFlexibleZone(e) ? palette.warning.lighter : palette.grey[300],
            color: palette.getContrastText(palette.grey[300]),
            borderRadius: 2,
            minWidth: '20%',
            height: '15%',
            opacity: disableButton(index) ? 0.5 : 1,
          }}
          onClick={() =>
            !disableButton(index) && onSelect(getContext().getValue(e)?.current, getContext().next)
          }
        >
          <Typography variant='body1'>{getContext().getValue(e)?.value}</Typography>
        </Button>
      ))}
    </Box>
  )
}
