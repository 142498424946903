import { Box, Divider, Paper, Skeleton } from '@mui/material'
import { FC } from 'react'

export const ContainerVisitPaperLoader: FC = () => {
  const renderBox = (index: number) => (
    <Box
      width='100%'
      key={index}
      display='flex'
      flexDirection='column'
      alignItems='center'
      padding='0 1rem'
    >
      <Skeleton variant='rectangular' width='32px' height='32px' sx={{ mb: '.5rem' }} />
      <Skeleton variant='rounded' width='100%' height='18px' sx={{ mb: '.5rem' }} />
      <Skeleton variant='rounded' width='100%' height='18px' sx={{ mb: '.5rem' }} />
    </Box>
  )

  return (
    <Paper elevation={6} sx={{ padding: 2 }}>
      <Box display='flex'>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='flex-start'
          justifyContent='flex-start'
          minWidth='30%'
        >
          <Skeleton variant='rounded' width={170} height={32} />

          <Box display='flex' mt={'1rem'}>
            {[...Array(4).keys()].map((_, index) => (
              <Skeleton
                key={index}
                variant='rounded'
                width={48}
                height={28}
                sx={{ margin: '0 .25rem' }}
              />
            ))}
          </Box>
        </Box>

        <Divider orientation='vertical' flexItem />

        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          flexDirection='row'
          width='70%'
          padding={'1rem'}
        >
          {[...Array(3).keys()].map((_, index) => renderBox(index))}
        </Box>
      </Box>
    </Paper>
  )
}
