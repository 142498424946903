import { Drawer } from '@mui/material'
import { useOperationsStore } from '@operations/AppProvider'
import { observer } from 'mobx-react-lite'
import { JobSearchContent } from '../components/Search/JobSearchContent'
import { WorkInstructionSearchContent } from '../components/Search/WorkInstructionSearchContent'

export const EquipmentOperatorSearchContainer = observer(() => {
  const { equipmentOperatorUIStore, equipmentOperatorSearchUIStore } = useOperationsStore()

  const handleSearch = (newValue: string) => {
    equipmentOperatorSearchUIStore.setSearchText(newValue)
  }

  const handleReturn = () => {
    equipmentOperatorSearchUIStore.setSelectedJobWorkInstructionIds()
    equipmentOperatorSearchUIStore.toggleSearch()
  }

  return (
    <Drawer
      open={equipmentOperatorSearchUIStore.isSearchOpen}
      anchor='right'
      PaperProps={{
        sx: {
          width: '100vw',
        },
      }}
    >
      {equipmentOperatorUIStore.isContainerOperation ? (
        <JobSearchContent handleReturn={handleReturn} handleSearch={handleSearch} />
      ) : (
        <WorkInstructionSearchContent handleReturn={handleReturn} handleSearch={handleSearch} />
      )}
    </Drawer>
  )
})
