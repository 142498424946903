import { DashboardCustomize } from '@mui/icons-material'
import { Box, FormControlLabel, IconButton, Switch } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { ContainerTurnoversDashboardStore } from '../stores/container-turnovers-dashboard.store'

interface DashboardToolbarProps {
  dashboardStore: ContainerTurnoversDashboardStore
}
export const DashboardToolbar = observer(({ dashboardStore }: DashboardToolbarProps) => {
  const { t } = useTranslate()
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      <FormControlLabel
        sx={{ userSelect: 'none' }}
        control={
          <Switch
            checked={dashboardStore.isActive}
            onChange={() => dashboardStore.toggleDashboard()}
          />
        }
        label={t('dashboard', 'Dashboard')}
      />
      <IconButton onClick={() => dashboardStore.toggleEditMode()}>
        <DashboardCustomize />
      </IconButton>
    </Box>
  )
})
