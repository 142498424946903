import { createSvgIcon } from '@mui/material'

export const PlataformIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.90913 4.75C2.90913 4.33579 3.21439 4 3.59095 4C3.96751 4 4.27277 4.33579 4.27277 4.75V5.5L2.91091 5.5L2.90913 5.5V4.75ZM2.91091 7H4.27277V8.5H2.91091L2.90913 8.5V6.99999L2.91091 7ZM2.90913 9.99999V11.5L2.91091 11.5H4.27277V9.99999H2.91091L2.90913 9.99999ZM2.90913 14.5V13L2.91091 13H4.27277V14.5H2.91091L2.90913 14.5ZM4.27277 16L20.1817 16L20.1835 16H21.5454V16.677C21.8283 17.0296 22 17.4927 22 18C22 19.1046 21.186 20 20.1818 20H3.81818C2.81403 20 2 19.1046 2 18C2 17.2597 2.36565 16.6133 2.90913 16.2675V16L2.91091 16H4.27277V16ZM20.1817 4.75C20.1817 4.33579 20.487 4 20.8636 4C21.2401 4 21.5454 4.33579 21.5454 4.75V5.5L20.1835 5.5L20.1817 5.5V4.75ZM20.1835 7H21.5454V8.5H20.1835L20.1817 8.5V6.99999L20.1835 7ZM20.1817 9.99999V11.5L20.1835 11.5H21.5454V9.99999H20.1835L20.1817 9.99999ZM20.1817 14.5V13L20.1835 13H21.5454V14.5H20.1835L20.1817 14.5Z'
      fill='currentColor'
    />
  </svg>,
  'PlataformIcon',
)
