import { Grid } from '@mui/material'
import { Box } from '@mui/system'
import { WorkInstructionDto } from '@operations/app/api'
import { FilterChips } from '@operations/components/filter/FilterChips'
import { SearchWithFilter } from '@operations/components/filter/SearchWithFilter'
import { FilterChip } from '@operations/components/filter/filter.model'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { MRT_TableInstance } from 'material-react-table'
import { observer } from 'mobx-react-lite'
import { MutableRefObject, useEffect } from 'react'
import { WorkInstructionFilterForm } from '../components/Filters/WorkInstructionFilterForm'
import { WorkInstructionFilter } from '../components/Filters/work-instructions-filters.model'
import { WorkInstructionInfoCard } from '../components/WorkInstructionInfoCard/WorkInstructionInfocard'
import { WorkInstructionsTable } from '../components/table/WorkInstructionsTable'
import { WorkInstructionFiltersUIStore } from '../stores/work-instruction-filters.ui-store'
import { WorkInstructionUIStore } from '../stores/work-instruction.ui-store'

interface Props {
  store: WorkInstructionUIStore
  filterStore: WorkInstructionFiltersUIStore
  subMenu: React.ReactElement
  workInstructionsTableInstanceRef: MutableRefObject<MRT_TableInstance<WorkInstructionDto> | null>
}

export const WorkInstructionsContainer = observer(
  ({
    filterStore,
    store: { workInstructions, pendingAmount, completedAmount, inProgressAmount },
    subMenu,
    workInstructionsTableInstanceRef,
  }: Props) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()

    const handleSubmit = (filter: WorkInstructionFilter) => {
      filterStore.setFilter(filter)
      filterStore.toggle()
      workInstructionsTableInstanceRef?.current?.resetPageIndex()
    }

    const handleReset = () => {
      filterStore.setFilter()
      filterStore.toggle()
    }

    const handleRemoveFilter = (value: FilterChip) => {
      filterStore.removeFilter(value)
    }

    useEffect(() => {
      const clearFilters = () => {
        filterStore.setFilter()
        filterStore.setContainerNumber()
      }

      return clearFilters()
    }, [filterStore])

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ margin: '0.5rem', display: 'flex', justifyContent: 'space-between' }}>
          {subMenu}
          <Box minWidth='50%'>
            <SearchWithFilter
              dataCy='wi-search'
              label={t('aContainerNumber', 'a container number')}
              onSearch={container => filterStore.setContainerNumber(container)}
              onReset={handleReset}
              form={
                <WorkInstructionFilterForm
                  id='work-instructions-filters'
                  equipments={filterStore.equipments}
                  filter={filterStore.filter}
                  hasJobs={filterStore.hasJobs}
                  onSubmit={handleSubmit}
                />
              }
            />
          </Box>
        </Box>
        <FilterChips
          filters={filterStore.filterChips}
          onRemoveFilter={handleRemoveFilter}
          onClearAll={handleReset}
        />
        <Box padding={theme.spacing(1)}>
          <Grid container spacing={4} paddingBottom={theme.spacing(2)}>
            <Grid item={true} xs={12} sm={4}>
              <WorkInstructionInfoCard value={pendingAmount} label={t('pendingContainers')} />
            </Grid>
            <Grid item={true} xs={12} sm={4}>
              <WorkInstructionInfoCard value={inProgressAmount} label={t('inProgressContainers')} />
            </Grid>
            <Grid item={true} xs={12} sm={4}>
              <WorkInstructionInfoCard value={completedAmount} label={t('completedContainers')} />
            </Grid>
          </Grid>

          <WorkInstructionsTable
            items={workInstructions}
            isFiltering={filterStore.isFilterFilled}
            workInstructionsTableInstanceRef={workInstructionsTableInstanceRef}
            onResetFilters={handleReset}
          />
        </Box>
      </Box>
    )
  },
)
