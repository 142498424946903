import { CarrierVisitAllocationRuleDto } from '@storage/app/api'
import { StackWithOverlapDto } from '../interfaces'

export const isStackHighlighted = (
  stack: StackWithOverlapDto,
  selectedAllocationRule?: CarrierVisitAllocationRuleDto,
) => {
  if (!selectedAllocationRule) return false

  const { block, bay, row } = selectedAllocationRule.destination
  const stackPosition = stack.yardPosition

  const blockLower = block?.toLowerCase()
  const stackBlockLower = stackPosition.block?.toLowerCase()

  if (blockLower !== stackBlockLower) return false

  const isBayMatch =
    !bay ||
    (bay.includes('-')
      ? comparePositionRange(bay, stackPosition.bay)
      : stackPosition.bay?.toLowerCase() === bay.toLowerCase())

  const isRowMatch =
    !row ||
    (row.includes('-')
      ? comparePositionRange(row, stackPosition.row)
      : stackPosition.row?.toLowerCase() === row.toLowerCase())

  return isBayMatch && isRowMatch
}

const comparePositionRange = (rulePos: string, stackPos?: string | null) => {
  if (!stackPos) {
    return false
  }
  const [start, end] = rulePos.split('-').map(p => p.toLowerCase())
  const pos = stackPos.toLowerCase()

  if (isNaN(Number(start)) && isNaN(Number(end))) {
    // Both are non-numeric, use string comparison
    return (pos >= start && pos <= end) || (pos <= start && pos >= end)
  } else if (!isNaN(Number(start)) && !isNaN(Number(end)) && !isNaN(Number(pos))) {
    // All are numeric, use number comparison
    const numPos = Number(pos)
    const numStart = Number(start)
    const numEnd = Number(end)
    return (numPos >= numStart && numPos <= numEnd) || (numPos <= numStart && numPos >= numEnd)
  } else {
    // Mixed case (some numeric, some alphabetic) - use string comparison
    return (pos >= start && pos <= end) || (pos <= start && pos >= end)
  }
}
