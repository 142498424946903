import { withAuthenticationRequired } from '@auth0/auth0-react'
import { EquipmentOperatorContainer } from '@tom-ui/operations'
import { EquipmentOperatorStepper } from './EquipmentOperatorStepper'

export const EquipmentOperator = withAuthenticationRequired(() => {
  return (
    <>
      <EquipmentOperatorContainer />

      <EquipmentOperatorStepper />
    </>
  )
})
