import { Button, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { ContainerType } from '@planning/app/api'
import { HeaderMessage, HeaderWithActions } from '@planning/components'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { ServiceOrdersViewStore } from '../../Stores/ServiceOrdersViewStore'
import { NumericForm } from '../NumericForm'
import { CoolingOrderForm } from './CoolingOrderForm'

export const CoolingOrderDialogRender: FC<{
  store: ServiceOrdersViewStore
  onClose: () => void
  onSubmit: () => void
  onDelete: () => void
}> = observer(({ store, onClose, onSubmit, onDelete }) => {
  const { t } = useTranslate()

  const getActions = () => {
    const actions = [
      <Button key='service-orders-cancel-btn' color='secondary' size='large' onClick={onClose}>
        {t('cancel', 'Cancel')}
      </Button>,
    ]

    if (!store.isInProgress) {
      actions.push(
        <Button key='service-orders-submit-btn' variant='contained' size='large' onClick={onSubmit}>
          {t('submit', 'Submit')}
        </Button>,
      )
      if (store.isEditMode) {
        actions.splice(
          0,
          0,
          <Button key='service-orders-cancel-btn' color='error' size='large' onClick={onDelete}>
            {t('delete', 'Delete')}
          </Button>,
        )
      }
    }

    return actions
  }

  const coolingHeader = store.isEditMode
    ? t('editCoolingOrder', 'Edit cooling order')
    : t('createCoolingOrder', 'Create cooling order')

  const headerMessage: HeaderMessage | undefined = store.isFulfilled
    ? {
        message: t(
          'blockedFulfilledServiceOrderModification',
          'Order is fulfilled: You cannot make any changes to orders that have been completed',
        ),
        severity: 'success',
      }
    : store.isInProgress
      ? {
          message: t(
            'blockedInOperationServiceOrderModification',
            'Order in operation: You cannot make any changes once operation has started',
          ),
          severity: 'warning',
        }
      : undefined

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <HeaderWithActions
            noBorder
            title={coolingHeader}
            actions={getActions()}
            headerMessage={headerMessage}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} padding={'0 24px'}>
        {/* LEFT PANEL */}
        <Grid item xs={4}>
          <Box mb={'2rem'}>
            <Typography variant='subtitle1' mb={'.5rem'}>
              {t('container', 'Container')}
            </Typography>

            <NumericForm store={store} isEmpty={false} type={ContainerType.Reefer} />
          </Box>
        </Grid>

        {/* RIGHT PANEL */}
        <Grid item xs={8}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: '.5rem',
            }}
          >
            <Typography variant='subtitle1'>{t('coolingOrder', 'Cooling order')}</Typography>
          </Box>

          <CoolingOrderForm store={store} />
        </Grid>
      </Grid>
    </>
  )
})
