import { useEffect } from 'react'

interface Props {
  when: boolean
}

export const Prompt = ({ when }: Props) => {
  //ToLater: use the usePrompt from ReactRouterDom to block for every link (but it needs to be stable)
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (when) {
        event.preventDefault()
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [when])

  return <></>
}
