import {
  CarrierVisitDirection,
  CarrierVisitStatus,
  OrdersApi,
  TruckAppointmentOrderDto,
} from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'
import { axiosUnwrapper } from '@planning/app/utils'
import { PaginatedStore } from '@planning/stores/PaginatedStore'
import { action, computed, makeObservable, observable } from 'mobx'
import { TruckAppointmentViewStore } from './TruckAppointmentViewStore'

export function createTruckAppointmentOrderViewStore(
  parentStore: TruckAppointmentViewStore,
  defaultPageSize?: number,
) {
  return new TruckAppointmentOrderStore(parentStore, createApiClient(OrdersApi), defaultPageSize)
}

export class TruckAppointmentOrderStore extends PaginatedStore<TruckAppointmentOrderDto> {
  direction?: CarrierVisitDirection

  constructor(
    private parentStore: TruckAppointmentViewStore,
    public httpClient: OrdersApi,
    defaultPageSize?: number,
  ) {
    super(async (currentPageIndex, pageSize, _, filter) => {
      return axiosUnwrapper(
        httpClient.truckAppointmentOrders(
          currentPageIndex + 1,
          pageSize,
          this.direction as CarrierVisitDirection,
          filter,
        ),
      )
    })

    makeObservable(this, {
      direction: observable,
      relevantOrdersForPassageCheck: computed,
      setDirection: action,
    })

    this.pageSize = defaultPageSize ?? 10
  }

  async setDirection(direction?: CarrierVisitDirection) {
    this.direction = direction
    await this.loadCurrentPage()
  }

  get relevantOrdersForPassageCheck() {
    const appointment = this.parentStore.detailsStore.appointment
    const isOutbound = (appointment && appointment?.status !== CarrierVisitStatus.Expected) ?? false
    const relevantItems = [...this.pageItems, ...this.parentStore.detailsStore.removedOrders]
      .filter(
        o =>
          this.parentStore.detailsStore.removedOrders.map(o => o.orderId).includes(o.orderId) ||
          !this.parentStore.detailsStore.appointment?.orders
            .map(o => o.orderId)
            .includes(o.orderId),
      )
      .filter(o => !isOutbound || o.carrierVisitDirection === CarrierVisitDirection.Outbound)

    return relevantItems
  }
}
