import { Box, Button, DialogActions, DialogContent, Grid, Typography } from '@mui/material'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { usePlanningStore } from '@planning/AppProvider'
import {
  CarrierType,
  CarrierVisitDirection,
  CreateGeneralCargoOrderCommand,
  OrderStatus,
  UpdateGeneralCargoOrderCommand,
} from '@planning/app/api'
import { orderService } from '@planning/services'
import generalCargoService from '@planning/services/generalCargoService'
import { ValidateOutboundOrderFunc } from '@planning/stores/gateControl/ValidateOutboundDto'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import {
  CreateGeneralCargoOrderPageForm,
  ICreateGeneralCargoOrderFormData,
} from './components/CreateGeneralCargoOrderPageForm'
import { IAmAContainerVisitToo } from './stores/SelectOrderViewStore'
interface Props {
  visit?: IAmAContainerVisitToo
  disableCarrierVisitSelection?: boolean
  isTruckAppointment?: boolean
  forcedDirection?: CarrierVisitDirection
  renderDamage?: (orderId: number) => React.ReactElement
  validateOutboundRequest?: ValidateOutboundOrderFunc
}

export const CreateGeneralCargoOrder: FC<Props> = observer(
  ({
    visit,
    disableCarrierVisitSelection,
    isTruckAppointment,
    forcedDirection,
    renderDamage,
    validateOutboundRequest,
  }) => {
    const { generalCargoViewStore, appViewStore, dialogStore } = usePlanningStore()

    const { t } = useTranslate()
    const theme = useMinimalsTheme()

    const [submitAndCreateAnother, setSubmitAndCreateAnother] = useState<boolean>()

    const isAllowGeneralCargoOrderToChangeStatusFeatureEnabled = useBooleanFlagValue(
      'gc-update-order-status',
      false,
    )

    const validateGeneralCargoOrderForm = async (data: ICreateGeneralCargoOrderFormData) => {
      if (data.status === OrderStatus.Fulfilled) {
        if (data.actualCargoAmount === 0) {
          appViewStore
            .setOpenConfirmDialog(
              true,
              t(
                'actualCargoQuantityIsZeroConfirmation',
                'Actual cargo quantity is 0. Do you want to continue?',
              ),
            )
            .then(confirmed => confirmed && setOrderFormDataAndSubmit(data))
          return
        } else if (!data.actualCargoAmount) {
          appViewStore
            .setOpenConfirmDialog(
              true,
              t(
                'actualCargoQuantityIsNotSetConfirmation',
                'Actual cargo quantity is not set. Do you want to continue? (Actual quantity will be set to 0)',
              ),
            )
            .then(confirmed => confirmed && setOrderFormDataAndSubmit(data, 0))
          return
        } else if (data.actualCargoAmount !== data.plannedCargoAmount) {
          appViewStore
            .setOpenConfirmDialog(
              true,
              t(
                'actualCargoQuantityIsDifferentConfirmation',
                'Actual cargo quantity is different from planned. Do you want to continue?',
              ),
            )
            .then(confirmed => confirmed && setOrderFormDataAndSubmit(data))
          return
        }
      }

      setOrderFormDataAndSubmit(data)
    }

    const setOrderFormDataAndSubmit = (
      data: ICreateGeneralCargoOrderFormData,
      actualAmount?: number,
    ) => {
      const orderFormData =
        actualAmount || actualAmount === 0 ? { ...data, actualCargoAmount: actualAmount } : data
      generalCargoViewStore.setOrderFormData(orderFormData)
      onSubmitGeneralCargoOrderForm()
    }

    const onSubmitGeneralCargoOrderForm = async () => {
      const data = generalCargoViewStore.orderFormData
      if (!data) return

      if (generalCargoViewStore.selectedOrder) {
        await onUpdateGeneralCargoOrderFormSubmit(data)
      } else {
        await onCreateGeneralCargoOrderFormSubmit(data)
      }
    }

    const onCreateGeneralCargoOrderFormSubmit = async (data: ICreateGeneralCargoOrderFormData) => {
      try {
        const cmd = {
          referenceNumber: data.referenceNumber,
          direction: data.direction,
          imoClasses: data.imoClasses,
          carrierVisitType: data.carrierVisitType,
          carrierVisitId: data.carrierVisit?.id,
          customerId: data.customer?.id,
          billOfLading: data.billOfLading,
          lotNumber: data.lotNumber,
          commodityId: data.commodity?.id,
          plannedAmount: data.plannedCargoAmount,
          handlingInstructions: data.handlingInstructions,
          waggon: data.carrierVisitType === CarrierType.Train ? data.waggon : null,
          identifiableItems:
            data.identifiableItems === ''
              ? undefined
              : data.identifiableItems?.split(',').map(x => x.trim()),
          packageId: data.packageId,
          grossWeight: data.grossWeight,
          weightUnit: data.weightUnit,
        } as CreateGeneralCargoOrderCommand

        await generalCargoService.create(cmd)

        if (!submitAndCreateAnother) {
          dialogStore.close()
        }

        await generalCargoViewStore.generalCargoSearchStore.fetch(
          generalCargoViewStore.generalCargoSearchStore.filter,
        )

        appViewStore.setShowAlert(
          'success',
          t('generalCargoOrderCreatedSuccessfully', 'General Cargo Order created successfully'),
        )
      } catch (error) {
        appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
      }
    }

    const onUpdateGeneralCargoOrderFormSubmit = async (data: ICreateGeneralCargoOrderFormData) => {
      try {
        const cmd = {
          id: generalCargoViewStore.selectedOrder!.id,
          status: data.status,
          referenceNumber: data.referenceNumber,
          direction: data.direction,
          imoClasses: data.imoClasses,
          carrierVisitType: data.carrierVisitType,
          carrierVisitId: data.carrierVisit?.id,
          customerId: data.customer?.id,
          billOfLading: data.billOfLading,
          lotNumber: data.lotNumber,
          commodityId: data.commodity?.id,
          plannedAmount: data.plannedCargoAmount,
          actualAmount: data.actualCargoAmount,
          handlingInstructions: data.handlingInstructions,
          waggon: data.carrierVisitType === CarrierType.Train ? data.waggon : null,
          identifiableItems:
            data.identifiableItems === ''
              ? undefined
              : data.identifiableItems?.split(',').map(x => x.trim()),
          packageId: data.packageId,
          grossWeight: data.grossWeight,
          weightUnit: data.weightUnit,
        } as UpdateGeneralCargoOrderCommand

        await generalCargoService.update(generalCargoViewStore.selectedOrder!.id, cmd)

        generalCargoViewStore.selectOrder()

        generalCargoViewStore.generalCargoSearchStore.fetch(
          generalCargoViewStore.generalCargoSearchStore.filter,
        )

        dialogStore.close()
        appViewStore.setShowAlert(
          'success',
          t('generalCargoOrderUpdatedSuccessfully', 'General Cargo Order updated successfully'),
        )
      } catch (error) {
        appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
      }
    }

    const onDeleteOrder = async (id: number) => {
      try {
        const confirmed = await appViewStore.setOpenConfirmDialog(
          true,
          t('doYouWantToDeleteOrder', 'Do you want to delete order?'),
        )
        if (confirmed && id) {
          await orderService.delete(id, true)
          generalCargoViewStore.generalCargoSearchStore.fetch(
            generalCargoViewStore.generalCargoSearchStore.filter,
          )
          dialogStore.close()
          appViewStore.setShowAlert(
            'success',
            t('orderDeletedSuccessfully', 'Order deleted successfully'),
          )
        }
      } catch (error) {
        appViewStore.setShowAlert('error', t('failedToDelete', 'Failed to delete'))
      }
    }

    const Title = () =>
      generalCargoViewStore.selectedOrder ? (
        <Typography variant='h4' m='1rem 0' align='center'>
          {t('editGeneralCargoOrder', 'Edit general cargo order')}
        </Typography>
      ) : (
        <Typography variant='h4' m='1rem 0' align='center'>
          {t('createGeneralCargoOrder', 'Create general cargo order')}
        </Typography>
      )

    const formId = generalCargoViewStore.selectedOrder ? 'gc-order-edit-form' : 'gc-order-form'

    return (
      <>
        <Title />
        <DialogContent sx={{ paddingX: '0 !important' }}>
          <Box paddingX={theme.customSpacing.l}>
            <CreateGeneralCargoOrderPageForm
              visit={visit}
              formId={formId}
              store={generalCargoViewStore}
              disableCarrierVisitSelection={disableCarrierVisitSelection}
              isTruckAppointment={isTruckAppointment}
              forcedDirection={forcedDirection}
              onSubmit={validateGeneralCargoOrderForm}
              renderDamage={renderDamage}
              validateOutboundRequest={validateOutboundRequest}
            />
          </Box>
        </DialogContent>
        <Grid container>
          <Grid item xs={11}>
            <DialogActions>
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  width: '100%',
                }}
              >
                {(isAllowGeneralCargoOrderToChangeStatusFeatureEnabled ||
                  generalCargoViewStore.selectedOrder?.status !== OrderStatus.Fulfilled) && (
                  <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={() => setSubmitAndCreateAnother(false)}
                    type='submit'
                    form={formId}
                  >
                    {t('submit', 'Submit')}
                  </Button>
                )}

                {!generalCargoViewStore.selectedOrder && (
                  <Button
                    variant={'outlined'}
                    color={'primary'}
                    onClick={() => setSubmitAndCreateAnother(true)}
                    type='submit'
                    form={formId}
                  >
                    {t('submitAndCreateAnother', 'Submit & create another')}
                  </Button>
                )}

                <Button
                  variant='text'
                  onClick={() => {
                    generalCargoViewStore.selectOrder()
                    dialogStore.close()
                  }}
                  color='inherit'
                >
                  {t('cancel', 'Cancel')}
                </Button>
              </Box>
            </DialogActions>
          </Grid>
          {generalCargoViewStore.selectedOrder &&
            generalCargoViewStore.selectedOrder.status === OrderStatus.Open && (
              <Grid item xs={1} alignSelf={'center'}>
                <Button
                  variant='contained'
                  color={'error'}
                  onClick={() => {
                    onDeleteOrder(generalCargoViewStore.selectedOrder!.id)
                  }}
                >
                  {t('delete', 'Delete')}
                </Button>
              </Grid>
            )}
        </Grid>
      </>
    )
  },
)
