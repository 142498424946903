import DownloadIcon from '@mui/icons-material/Download'
import { Button, Tooltip, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { useTranslate } from '@tolgee/react'
import { IconButton, Tile, useMinimalsTheme } from '@tom-ui/ui'
import dayjs from 'dayjs'
import { DamageReport } from '../../stores/DamageReportStore'
import { ImageUploader } from '../ImageUploader/ImageUploader'
import { UploadedImageList } from '../ImageUploader/UploadedImageList'

interface Props {
  damageReport: DamageReport
  hasActions?: boolean
  onEdit?: () => void
  onRemove?: () => void
  onUpdateDamagedFiles?: (files: File[]) => void
  onDownloadFiles?: (paths: string[]) => void
}
export const DamageReportCard = ({
  damageReport,
  hasActions,
  onEdit,
  onRemove,
  onUpdateDamagedFiles,
  onDownloadFiles,
}: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  return (
    <Stack gap={1} padding={theme.customSpacing.m} width='100%'>
      <Stack direction='row' justifyContent='space-between'>
        <Typography sx={{ wordWrap: 'break-word' }} variant='body1'>
          {damageReport.description}
        </Typography>

        {hasActions && (
          <ImageUploader
            damageImageFiles={damageReport.damageImageFiles}
            onUpdateDamagedFiles={onUpdateDamagedFiles}
          />
        )}

        {damageReport.imagePaths && damageReport.imagePaths?.length > 0 && (
          <Tooltip title={t('downloadImages', 'Download images')}>
            <IconButton
              onClick={() =>
                onDownloadFiles ? onDownloadFiles(damageReport.imagePaths!) : undefined
              }
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        )}
      </Stack>

      <UploadedImageList
        damageImageFiles={damageReport.damageImageFiles}
        onUpdateDamagedFiles={onUpdateDamagedFiles}
      />

      <Stack
        sx={{
          gap: theme.customSpacing.xl,
          flexDirection: 'row',
        }}
      >
        {damageReport.quantity && (
          <Tile nowrap label={t('amount', 'Amount')} value={damageReport.quantity} />
        )}

        {damageReport.created && (
          <Tile
            nowrap
            label={t('createdAt', 'Created at')}
            value={dayjs(damageReport.created)
              .toDate()
              .toLocaleString([], { dateStyle: 'short', timeStyle: 'short' })}
          />
        )}

        {damageReport.resolved && (
          <Tile
            nowrap
            label={t('resolvedAt', 'Resolved at')}
            value={dayjs(damageReport.resolved)
              .toDate()
              .toLocaleString([], { dateStyle: 'short', timeStyle: 'short' })}
          />
        )}
      </Stack>

      {hasActions && (
        <Stack flexDirection='row' gap={2}>
          <Button size='small' onClick={onEdit} color='inherit'>
            {t('edit', 'Edit')}
          </Button>

          <Button size='small' onClick={onRemove} color='inherit'>
            {t('remove', 'Remove')}
          </Button>
        </Stack>
      )}
    </Stack>
  )
}
