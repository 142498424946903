import { CreateGeneralCargoAreaDto, GeneralCargoAreaDto } from '@storage/app/api'
import { SelectOption } from '@storage/app/models'
import { EntityStore } from '@storage/app/store/entity.store'
import { GeneralCargoAreaService } from '@storage/services/general-cargo-area.service'

export class GeneralCargoAreaStore extends EntityStore<GeneralCargoAreaDto> {
  constructor(private readonly _generalCargoAreaService: GeneralCargoAreaService) {
    super()
  }

  public get entriesAsOptions(): SelectOption[] {
    return this.entries.map(({ id, name }) => ({ label: name, value: id }))
  }

  public async loadAll(): Promise<void> {
    const generalCargoAreas = await this._generalCargoAreaService.getAll()
    this.setAll(generalCargoAreas)
  }

  public getById(id: string): GeneralCargoAreaDto | undefined {
    return this.data.get(id)
  }

  public async add(generalCargoAreaDto: CreateGeneralCargoAreaDto): Promise<void> {
    const generalCargoArea = await this._generalCargoAreaService.create(generalCargoAreaDto)
    this.addOrUpdate(generalCargoArea)
  }

  public async update(generalCargoAreaDto: GeneralCargoAreaDto): Promise<void> {
    await this._generalCargoAreaService.update(generalCargoAreaDto)
    this.addOrUpdate(generalCargoAreaDto)
  }

  public async delete(id: string): Promise<void> {
    await this._generalCargoAreaService.delete(id)
    this.remove(id)
  }
}
