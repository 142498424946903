import { BerthStore } from '@storage/stores/berth.store'
import { CarrierVisitStore } from '@storage/stores/carrier-visit.store'
import { PierStore } from '@storage/stores/pier.store'
import { RootStore } from '@storage/stores/root.store'
import { YardBlockBayStore } from '@storage/stores/yard-block-bay.store'
import { YardBlockRowStore } from '@storage/stores/yard-block-row.store'
import { YardBlockSlotStore } from '@storage/stores/yard-block-slot.store'
import { YardBlockStackStore } from '@storage/stores/yard-block-stack.store'
import { YardBlockSummaryStore } from '@storage/stores/yard-block-summary.store'
import { YardBlockStore } from '@storage/stores/yard-block.store'
import { computed, makeObservable } from 'mobx'

export class YardDataStore {
  yardBlockStore: YardBlockStore
  yardBlockBayStore: YardBlockBayStore
  yardBlockRowStore: YardBlockRowStore
  yardBlockSlotStore: YardBlockSlotStore
  yardBlockSummaryStore: YardBlockSummaryStore
  yardBlockStackStore: YardBlockStackStore
  vesselVisitStore: CarrierVisitStore
  berthStore: BerthStore
  pierStore: PierStore

  constructor({
    yardBlockStore,
    yardBlockBayStore,
    yardBlockRowStore,
    yardBlockSlotStore,
    yardBlockSummaryStore,
    carrierVisitStore: vesselVisitStore,
    berthStore,
    pierStore,
    yardBlockStackStore,
  }: RootStore) {
    makeObservable(this, {
      yardBlocks: computed,
      yardBlockBays: computed,
      yardBlockRows: computed,
      yardBlockSlots: computed,
      yardBlockStacks: computed,
      yardBlockSummaries: computed,
    })

    this.yardBlockStore = yardBlockStore
    this.yardBlockBayStore = yardBlockBayStore
    this.yardBlockRowStore = yardBlockRowStore
    this.yardBlockSlotStore = yardBlockSlotStore
    this.vesselVisitStore = vesselVisitStore
    this.berthStore = berthStore
    this.pierStore = pierStore
    this.yardBlockStackStore = yardBlockStackStore
    this.yardBlockSummaryStore = yardBlockSummaryStore
  }

  async load() {
    await Promise.all([
      this.yardBlockStore.loadList(),
      this.yardBlockBayStore.loadList(),
      this.yardBlockRowStore.loadAll(),
      this.yardBlockSummaryStore.loadList(),
      this.vesselVisitStore.loadList(),
      this.berthStore.loadList(),
      this.pierStore.loadList(),
      this.yardBlockStackStore.loadAll({}),
    ])
  }

  get yardBlocks() {
    return this.yardBlockStore.entries
  }

  get yardBlockBays() {
    return this.yardBlockBayStore.entries
  }

  get yardBlockRows() {
    return this.yardBlockRowStore.entries
  }

  get yardBlockSlots() {
    return this.yardBlockSlotStore.entries
  }

  get yardBlockSummaries() {
    return this.yardBlockSummaryStore.entries
  }

  get yardBlockStacks() {
    return this.yardBlockStackStore.entries
  }
}
