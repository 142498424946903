import { Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { EmptyStateView } from './EmptyStateView'
import IconNoResults from './icon/IconNoResults'

interface Props {
  text?: string
}

export const NoResultsView = ({ text }: Props) => {
  const { t } = useTranslate()
  return (
    <EmptyStateView>
      <IconNoResults zoom={40} />
      <Typography variant='h4'>{text ?? t('noMatches', 'No matches')}</Typography>
    </EmptyStateView>
  )
}
