import { createSvgIcon } from '@mui/material'

export const GeneralCargoOrderIcon = createSvgIcon(
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M7.78424 5.78411C7.69847 5.46403 7.88842 5.13502 8.2085 5.04926L14.2938 3.4187C14.6139 3.33293 14.9429 3.52288 15.0287 3.84296L15.8828 7.03052C15.9685 7.3506 15.7786 7.6796 15.4585 7.76536L9.37319 9.39592C9.05311 9.48169 8.7241 9.29174 8.63834 8.97166L7.78424 5.78411Z'
      fill='currentColor'
    />
    <path
      d='M9.76141 10.8448C9.44133 10.9306 9.25138 11.2596 9.33715 11.5797L9.70085 12.937C10.8897 13.1554 11.9728 13.7807 12.7563 14.7011L19.9092 12.7845C20.2293 12.6987 20.4192 12.3697 20.3335 12.0496L19.4794 8.86206C19.3936 8.54198 19.0646 8.35203 18.7445 8.43779L9.76141 10.8448Z'
      fill='currentColor'
    />
    <path
      d='M13.9969 17.7851C13.9756 17.4395 13.9196 17.091 13.8266 16.7437C13.7335 16.3963 13.6077 16.0665 13.4534 15.7566L18.6259 14.3706C19.1861 14.2205 19.7618 14.553 19.9119 15.1131C20.062 15.6732 19.7296 16.249 19.1695 16.3991L13.9969 17.7851Z'
      fill='currentColor'
    />
    <path
      d='M2.07604 4.12653C1.4996 4.06333 0.981067 4.47941 0.917874 5.05585C0.85468 5.6323 1.27075 6.15083 1.8472 6.21402L4.11249 6.46236L6.00374 13.6299C6.42635 13.3701 6.8932 13.1663 7.39687 13.0313C7.5929 12.9788 7.78931 12.9381 7.98534 12.9088L5.95901 5.22931C5.84816 4.80922 5.49005 4.50079 5.05818 4.45345L2.07604 4.12653Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.72613 21.7247C11.7266 21.1886 12.9138 19.1324 12.3778 17.1319C11.8417 15.1314 9.78548 13.9442 7.78498 14.4802C5.78448 15.0163 4.5973 17.0725 5.13333 19.073C5.66936 21.0735 7.72563 22.2607 9.72613 21.7247ZM9.3379 20.2758C10.5382 19.9542 11.2505 18.7204 10.9289 17.5201C10.6073 16.3198 9.37351 15.6075 8.17321 15.9291C6.97291 16.2507 6.2606 17.4845 6.58222 18.6848C6.90384 19.8851 8.1376 20.5974 9.3379 20.2758Z'
      fill='currentColor'
    />
  </svg>,
  'GeneralCargoOrderIcon',
)
