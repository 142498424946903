import { Typography } from '@mui/material'
import { WeightMeasurementUnit } from '@planning/app/api'
import { useTranslate } from '@tolgee/react'
import { FC } from 'react'
import { SelectedCommoditiesTable, StuffingCommodityDto } from './SelectedCommoditiesTable'
import { StrippingOrderSelectedCommoditiesTable } from './StrippingOrders/StrippingOrderSelectedCommoditiesTable'

export type StrippingCommodityDto = {
  id?: number
  customerId: number
  customerName: string
  commodityId: number
  commodityName: string
  referenceNumber?: string | null
  lotNumber?: string | null
  packageId?: number | null
  packageName?: string | null
  weightUnit?: WeightMeasurementUnit
  plannedAmount: number
}

interface IRenderServiceTableProps {
  store: {
    selectedStuffingCommodities: StuffingCommodityDto[]
    selectedStrippingCommodities: StrippingCommodityDto[]
    updateSelectedStuffingCommodity: (commodity: StuffingCommodityDto) => void
    setItemToBeDeleted: (item: StrippingCommodityDto | StuffingCommodityDto | undefined) => void
    isInProgress: boolean
  }
  isStuffing: boolean
  showDeleteConfirmationDialog: () => void
  serviceOrderId?: number | null
  renderReservations?: (orderId: number, plannedAmout: number, cargoItemId: number) => JSX.Element
}

export const RenderServiceTable: FC<IRenderServiceTableProps> = ({
  store,
  serviceOrderId,
  isStuffing,
  showDeleteConfirmationDialog,
  renderReservations,
}) => {
  const { t } = useTranslate()

  if (isStuffing && store.selectedStuffingCommodities.length) {
    return (
      <SelectedCommoditiesTable
        isInProgress={store.isInProgress}
        commodities={store.selectedStuffingCommodities}
        onClickDelete={data => {
          store.setItemToBeDeleted(data)
          showDeleteConfirmationDialog()
        }}
        onCommodityUpdate={store.updateSelectedStuffingCommodity}
      />
    )
  } else if (!isStuffing && store.selectedStrippingCommodities.length) {
    return (
      <StrippingOrderSelectedCommoditiesTable
        strippingOrderId={serviceOrderId}
        commodities={store.selectedStrippingCommodities}
        onClickDelete={(data: any) => {
          store.setItemToBeDeleted(data)
          showDeleteConfirmationDialog()
        }}
        renderReservations={renderReservations}
      />
    )
  } else {
    return (
      <Typography variant='subtitle1' mb={'.5rem'} color={'#637381'}>
        {t('noCommoditiesChosenYet', 'No commodities chosen yet')}
      </Typography>
    )
  }
}
