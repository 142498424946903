import { ContainerInformationCard } from '@storage/components/ContainerInformationCard'
import { Box, Card, SxProps, Typography } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { BlockBaySummary } from '@storage/stores/yard-block-slot.store'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'

interface Props {
  blockBaySummary: BlockBaySummary
  containerSx?: SxProps
}

export const YardBlockBayOverviewContainerInformation = observer(
  ({ blockBaySummary, containerSx }: Props) => {
    const { palette } = useMinimalsTheme()
    const { t } = useTranslate()
    return (
      <Card sx={{ ...containerSx, backgroundColor: palette.grey[200] }}>
        <Typography variant='overline'>
          {t('containerInformation', 'Container Information')}:
        </Typography>
        <Box sx={{ marginTop: '0.5rem' }}>
          <ContainerInformationCard
            groupedBySize={blockBaySummary.groupedBySize}
            groupedByOperator={blockBaySummary.groupedByOperator}
            fullCount={blockBaySummary.fullCount}
            emptyCount={blockBaySummary.emptyCount}
          />
        </Box>
      </Card>
    )
  },
)
