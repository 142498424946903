import { SearchAutocomplete } from '@planning/components/list/SearchAutocomplete'
import { ContainerAutocompleteListItem } from '@planning/pages/Order/components/ContainerAutocompleteListItem'
import { ContainerAutocompleteListItemLoader } from '@planning/pages/Order/components/ContainerAutocompleteListItemLoader'
import { IOrderItem } from '@planning/rt-stores/order/OrderItem'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { LocalSearchAutocompleteStore } from './LocalSearchAutocompleteStore'

interface IProps {
  orders: IOrderItem[]
  onChange?: (filter: string, value: string | IOrderItem | null) => Promise<void> | void
}

const RenderAutocompleteOption = (option: IOrderItem) => {
  if (!option.container) return <></>
  return <ContainerAutocompleteListItem container={option.container.data} />
}

export const RailcarOrderAssignmentOrderAutoComplete = observer(({ orders, onChange }: IProps) => {
  const { t } = useTranslate()

  const store = useMemo(
    () =>
      new LocalSearchAutocompleteStore(
        () => orders,
        (filter, item) => !!item.data.containerNumber?.includes(filter),
      ),
    [orders],
  )

  return (
    <SearchAutocomplete
      label={t('containerNumber', 'Container number')}
      store={store}
      renderOption={RenderAutocompleteOption}
      renderLoadingOption={() => <ContainerAutocompleteListItemLoader />}
      getOptionLabel={orderItem => orderItem.container?.data.number ?? ''}
      onChange={onChange}
    />
  )
})
