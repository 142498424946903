import {
  DangerousGoodsStackingRuleDto,
  DangerousGoodsStackingRulesApi,
  DangerousGoodsStackingRulesUpdatePrimaryImoClassStackingRuleRequest,
} from '@storage/app/api'

export class DGStackingRuleService {
  constructor(private api: DangerousGoodsStackingRulesApi) {}

  async getAll(): Promise<Array<DangerousGoodsStackingRuleDto>> {
    const { data: dbStackingRules } = await this.api.getAll()
    return dbStackingRules
  }

  async updatePrimaryImoClassStackingRule(
    dangerousGoodsStackingRulesUpdatePrimaryImoClassStackingRuleRequest: DangerousGoodsStackingRulesUpdatePrimaryImoClassStackingRuleRequest,
  ): Promise<void> {
    await this.api.updatePrimaryImoClassStackingRule({
      dangerousGoodsStackingRulesUpdatePrimaryImoClassStackingRuleRequest,
    })
  }

  async updateSecondaryImoClassStackingRule(
    dangerousGoodsStackingRulesUpdatePrimaryImoClassStackingRuleRequest: DangerousGoodsStackingRulesUpdatePrimaryImoClassStackingRuleRequest,
  ): Promise<void> {
    await this.api.updateSecondaryImoClassStackingRule({
      dangerousGoodsStackingRulesUpdatePrimaryImoClassStackingRuleRequest,
    })
  }
}
