import { InternalAxiosRequestConfig } from 'axios'
import { ENDPOINTS_EXCLUDED_FROM_LOADING } from './endpoints'

export const get = (request: InternalAxiosRequestConfig) => {
  if (!request.url) return true

  return !ENDPOINTS_EXCLUDED_FROM_LOADING.some(
    endpoint => new URL(request.url!).pathname === endpoint,
  )
}
