import { ArrowDropDown, Check, Close } from '@mui/icons-material'
import { Box, ListItemText, Menu, MenuItem, Typography } from '@mui/material'
import { SelectOption } from '@storage/app/models'
import useContextMenu from '@storage/hooks/use-context-menu.hook'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useMemo } from 'react'
import {
  Controller,
  FieldValues,
  Path,
  PathValue,
  UseControllerProps,
  useController,
  useWatch,
} from 'react-hook-form'

export interface SelectChipProps<T extends FieldValues> extends UseControllerProps<T> {
  icon?: React.ReactElement
  label?: string
  readonly?: boolean
  showOnlyWhenHasValue?: boolean
  options?: SelectOption[]
  defaultValue?: PathValue<T, Path<T>>
}

interface InputProps {
  icon?: React.ReactElement
  hasValue: boolean
  label?: string
  valueToRender: string
  optionsMenu?: JSX.Element
  readonly?: boolean
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void
  onReset: () => void
}

const Input = ({
  hasValue,
  valueToRender,
  label,
  optionsMenu,
  icon,
  readonly,
  onClick,
  onReset,
}: InputProps) => {
  const { palette } = useMinimalsTheme()
  const { t } = useTranslate()

  const normalizedLabel = t(hasValue ? valueToRender : label ?? '')

  return (
    <Box
      onClick={onClick}
      sx={{
        display: 'flex',
        background: hasValue ? palette.grey[300] : palette.grey[200],
        cursor: optionsMenu && !readonly ? 'pointer' : 'default',
        borderRadius: '6px',
      }}
      data-cy='container-turnovers-quick-filter'
    >
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          alignItems: 'center',
          padding: 0.5,
          borderTopLeftRadius: 8,
          borderBottomLeftRadius: 8,
          borderTopRightRadius: readonly ? 8 : 0,
          borderBottomRightRadius: readonly ? 8 : 0,
          '&:hover': {
            background: hasValue && !readonly ? palette.grey[400] : undefined,
          },
        }}
      >
        {hasValue && (icon ?? <Check fontSize='small' sx={{ color: palette.grey[600] }} />)}

        <Typography variant='button' sx={{ color: palette.grey[600], userSelect: 'none' }}>
          {normalizedLabel}
        </Typography>
      </Box>

      {!readonly && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: 0.5,
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
            '&:hover': {
              background: hasValue ? palette.grey[400] : undefined,
            },
          }}
        >
          {hasValue ? (
            <Close
              fontSize='small'
              onClick={e => {
                e.stopPropagation()
                onReset()
              }}
              sx={{ cursor: 'pointer', color: palette.grey[600] }}
            />
          ) : (
            <ArrowDropDown fontSize='small' sx={{ color: palette.grey[600] }} />
          )}
        </Box>
      )}

      {optionsMenu}
    </Box>
  )
}

export const SelectChip = <T extends FieldValues>({
  control,
  name,
  label,
  icon,
  options,
  showOnlyWhenHasValue,
  readonly,
  defaultValue,
}: SelectChipProps<T>) => {
  const { menuProps, handleAttachMenu } = useContextMenu()
  const { t } = useTranslate()

  const { field } = useController({
    name,
    control,
  })

  const selectedValue = useWatch({
    control,
    name,
  })

  const valueToRender = useMemo(
    () =>
      options
        ? options.find(option => option.value === selectedValue)?.label ?? ''
        : String(selectedValue),
    [options, selectedValue],
  )

  const hasValue = useMemo(
    () =>
      selectedValue !== undefined &&
      selectedValue !== null &&
      selectedValue !== '' &&
      selectedValue !== 'All',
    [selectedValue],
  )

  const handleOnItemClick = ({ currentTarget }: React.MouseEvent<HTMLDivElement>) => {
    !readonly && options && handleAttachMenu(currentTarget)
  }

  const menu = options && (
    <Menu {...menuProps}>
      {options.map(({ value, label }) => (
        <MenuItem
          key={value}
          selected={value === selectedValue}
          value={value}
          onClick={() => {
            field.onChange(value)
          }}
          data-cy='quick-filter-menu-item'
        >
          <ListItemText
            primary={t(label, label)}
            data-cy='container-turnovers-quick-filter-option'
          />
        </MenuItem>
      ))}
    </Menu>
  )

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) =>
        !showOnlyWhenHasValue || hasValue ? (
          Array.isArray(field.value) ? (
            <>
              {(field.value as unknown[]).map(valueItem => (
                <Input
                  key={String(valueItem)}
                  icon={icon}
                  hasValue={hasValue}
                  valueToRender={String(valueItem)}
                  label={label}
                  readonly={readonly}
                  onClick={handleOnItemClick}
                  onReset={() => {
                    field.onChange(
                      (selectedValue as unknown[]).filter(value => value !== valueItem),
                    )
                  }}
                />
              ))}
            </>
          ) : (
            <Input
              hasValue={hasValue}
              icon={icon}
              valueToRender={valueToRender}
              onClick={handleOnItemClick}
              label={label}
              readonly={readonly}
              optionsMenu={menu}
              onReset={() => {
                field.onChange(defaultValue)
              }}
            />
          )
        ) : (
          <></>
        )
      }
    />
  )
}
