import { Box, Button, Tooltip } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { getContainerTypeParameters } from '@planning/components/ContainerTypesMap'
import { GridToolbar } from '@planning/components/GridToolbar'
import { GridColumn, StickyHeaderTable } from '@planning/components/PaginatedTable'
import { emptyIndicator } from '@planning/constants'
import { PATH_PLANNING } from '@planning/page-url-paths'
import { INnrOrderItem } from '@planning/rt-stores/nnrOrder/NnrOrderItem'
import { IPaginatedStoreWithItems } from '@planning/stores/PaginatedStore'
import { useTranslate } from '@tolgee/react'
import { EditIcon, IconButton } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'
import { CreateNNROrderForm } from './CreateNNROrderForm'
import { EditNNROrderForm } from './EditNNROrderForm'

interface Props {
  viewStore: IPaginatedStoreWithItems<INnrOrderItem>
}

export const NNROrdersTable = observer(({ viewStore }: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslate()
  const { drawerStore } = usePlanningStore()

  const columns: GridColumn[] = [
    {
      field: 'shippingLine',
      headerName: 'Shipping Line',
      flex: 1,
      minWidth: 100,
      valueGetter: params => params.row.data.shippingLine,
      sortable: true,
      orderBy: 'shippingLine',
    },
    {
      field: 'referenceNumber',
      headerName: 'Reference no',
      flex: 1,
      minWidth: 100,
      valueGetter: params => params.row.data.referenceNumber,
      sortable: true,
      orderBy: 'referenceNumber',
    },
    {
      field: 'containerLength',
      headerName: 'Length',
      flex: 1,
      minWidth: 100,
      valueGetter: params =>
        params.row.data.containerLength ? `${params.row.data.containerLength}ft` : emptyIndicator,
      sortable: true,
      orderBy: 'containerLength',
    },
    {
      field: 'containerHeight',
      headerName: 'Height',
      flex: 1,
      minWidth: 100,
      valueGetter: params => params.row.data.containerHeight,
      sortable: true,
      orderBy: 'containerHeight',
    },
    {
      field: 'containerType',
      headerName: 'Type',
      flex: 1,
      minWidth: 100,
      valueGetter: params => getContainerTypeParameters(params.row.data.containerType, t)['label'],
      sortable: true,
      orderBy: 'containerType',
    },
    {
      field: 'containerIsoCode',
      headerName: 'ISO Code',
      flex: 1,
      minWidth: 100,
      valueGetter: params => params.row.data.containerIsoCode,
      sortable: true,
      orderBy: 'containerIsoCode',
    },
    {
      field: 'containerMaxPayload',
      headerName: 'Max Payload(kg)',
      flex: 1,
      minWidth: 100,
      valueGetter: params => params.row.data.containerMaxPayload,
      sortable: true,
      orderBy: 'containerMaxPayload',
    },
    {
      field: 'fulfilled',
      headerName: 'Fulfilled',
      flex: 1,
      minWidth: 100,
      valueGetter: params => `${params.row.data.fulfilled} / ${params.row.data.amount}`,
      orderBy: 'fulfilled',
      sortable: false,
    },
    {
      field: 'actions',
      headerName: 'Action',
      flex: 0.5,
      minWidth: 75,
      sortable: false,
      renderCell: params => {
        const uneditable =
          params.row.data.fulfilled > 0 ||
          viewStore.pageItems.find(o => o.id === params.row.id)?.data.isClosed
        if (uneditable) return <></>

        return (
          <Box sx={{ display: 'flex' }}>
            <Tooltip arrow placement='bottom' title='Edit'>
              <IconButton
                onClick={() =>
                  drawerStore.showView(<EditNNROrderForm order={params.row.data} />, {
                    title: t('edit', 'Edit'),
                    subTitle: t('non-numericReleases', 'Non-numeric releases'),
                    primaryActionLabel: t('submit', 'Submit'),
                    closeActionLabel: t('close', 'Close'),
                    formId: 'edit-non-numeric-release',
                  })
                }
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )
      },
    },
  ]

  return (
    <StickyHeaderTable
      store={viewStore}
      columns={columns}
      toolBar={
        <GridToolbar
          title={`${t('non-numericReleases', 'Non-numeric releases')}`}
          store={viewStore}
          actions={
            <Button
              variant='contained'
              type='submit'
              onClick={() =>
                drawerStore.showView(<CreateNNROrderForm />, {
                  title: t('create', 'Create'),
                  subTitle: t('non-numericReleases', 'Non-numeric releases'),
                  primaryActionLabel: t('submit', 'Submit'),
                  closeActionLabel: t('close', 'Close'),
                  formId: 'create-non-numeric-release',
                })
              }
            >
              Create
            </Button>
          }
        />
      }
      rowsPerPageOptions={[10, 25, 50]}
      onCellClick={(column: any, event: any) => {
        if (column.field === 'actions') {
          event.stopPropagation()
        }
        event.preventDefault()
      }}
      onRowClick={(params: any) => {
        navigate(`${PATH_PLANNING.nnrOrders}/${params.id}`)
      }}
    />
  )
})
