import { Box, Grid, Typography } from '@mui/material'
import { OrderResponseDto } from '@planning/app/api'
import { OrderHandlingInstructionContainer } from '@planning/components/OrderHandlingInstructionContainer'
import { IContainerItem } from '@planning/rt-stores/container/ContainerItem'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC, ReactElement } from 'react'
import { OrderHandlingInstructionChipBox } from './OrderHandlingInstructionChipBox'

interface IProps {
  order: OrderResponseDto
  container?: IContainerItem
  onClick?: (order: OrderResponseDto) => void
  actions?: ReactElement[] | ReactElement
  useChips?: boolean
}

export const OrderInfoBox: FC<IProps> = observer(
  ({ order, container, onClick, actions, useChips }: IProps) => {
    const { t } = useTranslate()
    const isNonNumeric = !order.containerNumber

    const getMainText = () => {
      if (isNonNumeric) return `${order.referenceNumber} - ${order.operator}`

      return order.containerNumber
    }

    // TODO add 'Unfulfillable' indicator
    return (
      <Box display='flex' flexDirection='column'>
        <Grid container sx={{ mb: '1rem' }}>
          <Grid item xs>
            <Box
              display='flex'
              flexDirection='column'
              onClick={() => {
                if (onClick) onClick(order)
              }}
              ml='0.5rem'
            >
              <Typography variant='h4'>{getMainText()}</Typography>
            </Box>
          </Grid>
          <Grid item xs={4} display='flex' justifyContent='flex-end'>
            {actions}
          </Grid>
        </Grid>

        {useChips ? (
          <OrderHandlingInstructionChipBox
            order={order}
            onClick={() => {
              if (onClick) onClick(order)
            }}
          />
        ) : (
          <OrderHandlingInstructionContainer
            order={order}
            container={container}
            onClick={() => {
              if (onClick) onClick(order)
            }}
          />
        )}
      </Box>
    )
  },
)
