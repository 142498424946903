import { CarrierType, ContainerHeight, ContainerStatus, ContainerType } from '@storage/app/api'
import { AllSelectOption, BooleanSelectOption } from '@storage/app/models'
import * as yup from 'yup'
import {
  ContainerTurnoversFilterFormProfile,
  defaultValues,
} from './container-turnovers-filter-form.profile'

export const schema: yup.ObjectSchema<ContainerTurnoversFilterFormProfile> = yup.object({
  isEmpty: yup.string<BooleanSelectOption | AllSelectOption>().default(defaultValues.isEmpty),
  isDamaged: yup.string<BooleanSelectOption | AllSelectOption>().default(defaultValues.isDamaged),
  isReefer: yup.string<BooleanSelectOption | AllSelectOption>().default(defaultValues.isReefer),
  isInTerminal: yup
    .string<BooleanSelectOption | AllSelectOption>()
    .default(defaultValues.isInTerminal),
  isDangerous: yup
    .string<BooleanSelectOption | AllSelectOption>()
    .default(defaultValues.isDangerous),
  isRestow: yup.string<BooleanSelectOption | AllSelectOption>().default(defaultValues.isRestow),
  size: yup.string().default(defaultValues.size),
  containerIsoCode: yup.string().default(defaultValues.containerIsoCode),
  weightClasses: yup.array().default(defaultValues.weightClasses),
  containerOperator: yup.string().default(defaultValues.containerOperator),
  portOfDischarge: yup.string().default(defaultValues.portOfDischarge),
  portOfLoading: yup.string().default(defaultValues.portOfLoading),
  typeCode: yup.string().default(defaultValues.typeCode),
  imoClasses: yup.array().default(defaultValues.imoClasses),
  inboundCarrier: yup.string().default(defaultValues.inboundCarrier),
  inboundCarrierType: yup
    .string<CarrierType | AllSelectOption>()
    .default(defaultValues.inboundCarrierType),
  outboundCarrier: yup.string().default(defaultValues.outboundCarrier),
  outboundCarrierType: yup
    .string<CarrierType | AllSelectOption>()
    .default(defaultValues.outboundCarrierType),
  containerOrReferenceNumber: yup.string().default(defaultValues.containerOrReferenceNumber),
  locationStatus: yup
    .string<ContainerStatus | AllSelectOption>()
    .default(defaultValues.locationStatus),
  containerType: yup.string<ContainerType | AllSelectOption>().default(defaultValues.containerType),
  containerHeight: yup
    .string<ContainerHeight | AllSelectOption>()
    .default(defaultValues.containerHeight),
})
