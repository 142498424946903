import * as signalR from '@microsoft/signalr'
import { authStore } from '@tom-ui/utils'
import { action, autorun, makeObservable, observable, runInAction } from 'mobx'
import { Event, IMessageBus } from './messageBus'

export interface IHubConnection {
  signalRType: string
  hubType: string
}

enum ConnectionState {
  Offline = 'offline',
  Connecting = 'connecting',
  Connected = 'connected',
}

export class HubContext {
  private connection: signalR.HubConnection
  connectionState = ConnectionState.Offline
  joinedGroup = false
  isLoggingIn = false

  constructor(private messageBus: IMessageBus) {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl('/operationsHub', {
        withCredentials: false,
      })
      .withAutomaticReconnect([
        1000, 2000, 2000, 3000, 3000, 5000, 5000, 10000, 20000, 20000, 30000, 30000,
      ]) //TOLATER: maybe find a better policy
      .build()

    this.connection.onreconnected(async () => {
      if (this.isLoggingIn) {
        await this.authenticate()
      }
    })

    makeObservable(this, {
      connectionState: observable,
      loggedIn: action,
    })

    if (authStore.isLoaded) {
      this.loggedIn(true)
    } else {
      autorun(() => {
        this.loggedIn(authStore.isLoaded)
      })
    }
  }

  loggedIn = (loggedIn: boolean) => {
    if (!this.isLoggingIn && loggedIn) {
      this.isLoggingIn = true
      if (this.connectionState === ConnectionState.Connected) {
        this.authenticate()
      }
    }
  }

  startConnection = async () => {
    if (this.connectionState === ConnectionState.Offline) {
      runInAction(() => {
        this.connectionState = ConnectionState.Connecting
      })
      try {
        await this.connection.start()
        runInAction(() => {
          this.connectionState = ConnectionState.Connected
        })
        if (this.isLoggingIn) {
          await this.authenticate()
        }
      } catch (error) {
        runInAction(() => {
          this.connectionState = ConnectionState.Offline
        })
        console.error(error)
      }
    }
  }

  registerPassThroughConnection(type: string) {
    return this.registerHubConnection({
      signalRType: type,
      hubType: type,
    })
  }

  registerHubConnection(hubConnection: IHubConnection) {
    this.connection.on(hubConnection.signalRType, (data: any) => {
      console.log('backend published event ' + hubConnection.hubType)
      const messageRes = new Event(hubConnection.hubType, data)
      this.messageBus.dispatchEvent(messageRes)
    })
  }

  authenticate = async () => {
    try {
      await this.connection.send('Authenticate', authStore.token)
      console.log('HubContext authenticates user')
    } catch (error) {
      console.log(error)
    }
  }
}
