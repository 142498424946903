import {
  ContainerStowageLocationDto,
  ContainerStowageLocationsApi,
  ContainerVesselDirectionType,
} from '@operations/app/api'
import { createApiClient } from '@operations/app/http-client'
import _ from 'lodash'
import { computed, makeObservable, observable, runInAction } from 'mobx'
import { BaseEntityStore } from './base/BaseEntityStore'

export class ContainerStowageLocationStore extends BaseEntityStore<ContainerStowageLocationDto> {
  selectedVesselId?: number

  constructor() {
    super()
    makeObservable(this, {
      selectedVesselId: observable,
      transhipmentContainers: computed,
    })
  }

  async load(vesselVisitId: number) {
    const { data } = await createApiClient(ContainerStowageLocationsApi).get(vesselVisitId)

    this.updateStoreItems(data)

    runInAction(() => (this.selectedVesselId = vesselVisitId))
  }

  public get transhipmentContainers() {
    return _(this.items)
      .filter(i => i.operationType === ContainerVesselDirectionType.RemainOnBoard)
      .value()
  }
}
