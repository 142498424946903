import { Stack } from '@mui/system'
import { ContainerDamageResponseDto } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { IInspectContainerFormData } from '@planning/pages/GateControl/Components/InspectContainer'
import { useTranslate } from '@tolgee/react'
import { FieldBox, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { Control, Controller, FormState, UseFormWatch } from 'react-hook-form'
import { YesNoChipBox } from '../dangerous-goods/YesNoChipBox'
import { IContainerDamageFormData } from './ContainerDamage.model'
import { ContainerDamageReport } from './ContainerDamageReport'
import { ContainerDamageReportForm } from './ContainerDamageReportForm'

interface IProps {
  control: Control<any>
  formState: FormState<IContainerDamageFormData>
  containerId: number
  damages: ContainerDamageResponseDto[]
  watch: UseFormWatch<IInspectContainerFormData>
}

export const ControlledDamageReports = observer(
  ({ control, formState, containerId, damages, watch }: IProps) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()

    const { containerDamageReportViewStore, tenantStore } = usePlanningStore()

    return (
      <FieldBox
        label={t('damageRecording', 'Damage recording')}
        hasDivider={watch('hasDamage')}
        rightRender={
          <Controller
            control={control}
            name={'hasDamage'}
            render={({ field: { onChange, value } }) => (
              <YesNoChipBox
                activeOption={value ? 'Yes' : 'No'}
                onClickYes={() => onChange(true)}
                onClickNo={() => {
                  onChange(false)
                }}
              />
            )}
          />
        }
      >
        {watch('hasDamage') && (
          <>
            <Stack gap={theme.customSpacing.m}>
              {damages?.length > 0 && (
                <ContainerDamageReport
                  containerId={containerId ?? 0}
                  damages={damages ?? []}
                  isMobile
                />
              )}

              <ContainerDamageReportForm
                control={control}
                formState={formState}
                containerDamageLocations={containerDamageReportViewStore.locationOptions}
                containerDamageTypes={containerDamageReportViewStore.typeOptions}
                hasDamageCatalogue={tenantStore.hasDamageCatalogue}
              />
            </Stack>
          </>
        )}
      </FieldBox>
    )
  },
)
