import globalAxios, { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios'
import { Configuration } from './api'
import { BASE_PATH, BaseAPI, RequestArgs } from './api/base'
import {
  DUMMY_BASE_URL,
  createRequestFunction,
  setOAuthToObject,
  setSearchParams,
  toPathString,
} from './api/common'

/**
 * InternalApiApi - axios parameter creator
 * @export
 */
export const InternalApiApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/InternalApi/ResetData`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions?.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveDemoData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/InternalApi/SaveDemoData`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions?.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * InternalApiApi - functional programming interface
 * @export
 */
export const InternalApiApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = InternalApiApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async resetData(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.resetData(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveDemoData(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveDemoData(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * InternalApiApi - factory interface
 * @export
 */
export const InternalApiApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = InternalApiApiFp(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetData(options?: any): AxiosPromise<void> {
      return localVarFp.resetData(options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveDemoData(options?: any): AxiosPromise<void> {
      return localVarFp.saveDemoData(options).then(request => request(axios, basePath))
    },
  }
}

/**
 * InternalApiApi - object-oriented interface
 * @export
 * @class InternalApiApi
 * @extends {BaseAPI}
 */
export class InternalApiApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InternalApiApi
   */
  public resetData(options?: AxiosRequestConfig) {
    return InternalApiApiFp(this.configuration)
      .resetData(options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InternalApiApi
   */
  public saveDemoData(options?: AxiosRequestConfig) {
    return InternalApiApiFp(this.configuration)
      .saveDemoData(options)
      .then(request => request(this.axios, this.basePath))
  }
}
