import { ContainerEventDto } from '@planning/app/api'
import { containerEventsSortDelegate } from '@planning/components/ContainerHistoryLinkIconButton'
import { IListStore } from '@planning/components/list/SimpleListStore'
import { EventTypes } from '@planning/messages/eventsTypes'
import { GetContainerEventsQuery } from '@planning/messages/queries/getContainerEventsQueryHandler'
import { SortingModel } from '@planning/stores/PaginatedStore'
import _ from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import { IEvent, IMessageBus } from '../../messages/messageBus'
import { ItemStore } from '../base/ItemStore'
import { ContainerEventItem, IContainerEventItem } from './ContainerEventItem'

export class ContainerEventItemStore
  extends ItemStore<ContainerEventDto, IContainerEventItem>
  implements IListStore<IContainerEventItem>
{
  filter = ''
  isLoading = false
  isNoMatches = false
  private sortingModel = {
    field: 'time',
    orderBy: 'time',
    isDescending: true,
  } as SortingModel<IContainerEventItem>
  containerNumber = ''
  fetchedContainerNumber: Set<string> = new Set()

  constructor(private messageBus: IMessageBus) {
    super((key, data) => new ContainerEventItem(key, data))
    makeObservable(this, {
      filter: observable,
      isLoading: observable,
      containerNumber: observable,
      items: computed,
      containerEventsByContainerNumber: computed,
      setContainerNumber: action,
      setLoading: action,
      setFilter: action,
      receiveContainerEventsMessage: action,
    })

    messageBus.subscribeEvent(GetContainerEventsQuery.type, this.receiveContainerEventsMessage)
    messageBus.subscribeEvent(
      EventTypes.ContainerEventsUpsertedEvent,
      this.receiveContainerEventsMessage,
    )
  }

  fetch = async () => {
    if (!this.containerNumber || this.fetchedContainerNumber.has(this.containerNumber)) return

    this.fetchedContainerNumber.add(this.containerNumber)

    this.setLoading(true)

    await this.messageBus.dispatchQuery(new GetContainerEventsQuery(this.containerNumber))
  }

  get items() {
    return this.containerEventsByContainerNumber
      .filter((v, _) => v.data.type.toLowerCase().includes(this.filter.toLowerCase()))
      .sort((a, b) => containerEventsSortDelegate(this.sortingModel, a, b))
  }

  get containerEventsByContainerNumber() {
    return _(this.elements)
      .filter(item => item.data.containerNumber === this.containerNumber)
      .value()
  }

  setContainerNumber = (containerNumber: string) => {
    this.containerNumber = containerNumber
  }

  setLoading(isLoading: boolean) {
    this.isLoading = isLoading
  }

  setFilter = (filter: string) => {
    this.filter = filter
  }

  receiveContainerEventsMessage = (event: IEvent<ContainerEventDto[]>): void => {
    this.setLoading(true)

    if (event.payload) {
      this.upsertBulk(event.payload)
      this.isNoMatches = !this.items.length
    }
  }
}
