import { Autocomplete, Box, Stack, TextField, Typography } from '@mui/material'
import { CommodityResponseDto } from '@planning/app/api'
import { IAutocompleteViewStore } from '@planning/stores/autocomplete/AutocompleteViewStore'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

interface Props {
  store: IAutocompleteViewStore<CommodityResponseDto>
  selectedCommodity?: CommodityResponseDto
  handleOnChange?: (value?: CommodityResponseDto) => void
  error?: boolean
  helperText?: React.ReactNode
  label?: string
  disable?: boolean
}

export const CommodityAutoComplete: FC<Props> = observer(
  ({ store, selectedCommodity, handleOnChange, error, helperText, label, disable }) => {
    const { t } = useTranslate()

    return (
      <Autocomplete
        disablePortal
        disabled={disable}
        getOptionLabel={commodity => commodity.name}
        open={store.isOpen}
        onOpen={() => store.setOpen(true)}
        onClose={() => store.setOpen(false)}
        options={store.items}
        renderOption={(props, commodity) => (
          <Box component='li' {...props} key={commodity.id}>
            <Stack>
              <Typography variant='subtitle1'>{commodity.name}</Typography>
              <Typography variant='caption' color='secondary'>
                {commodity.attributes?.map(ca => `${ca.name}: ${ca.value}`).join(' | ')}
              </Typography>
            </Stack>
          </Box>
        )}
        isOptionEqualToValue={(option: CommodityResponseDto, value: CommodityResponseDto) =>
          option.id === value.id
        }
        value={selectedCommodity ?? null}
        onChange={(_, commodity) => {
          if (handleOnChange) handleOnChange(commodity ?? undefined)
        }}
        renderInput={params => (
          <TextField
            {...params}
            label={label ?? t('commodity', 'Commodity')}
            error={error}
            helperText={helperText}
            onChange={e => {
              store.setFilter(e.target.value)
            }}
          />
        )}
      />
    )
  },
)
