import { Box, SxProps, Theme, Typography } from '@mui/material'
import { OrderResponseDto } from '@planning/app/api'
import { ValidateOutboundOrderFunc } from '@planning/stores/gateControl/ValidateOutboundDto'
import { useTranslate } from '@tolgee/react'
import { GeneralCargoOrderIcon, HighlightedText, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'

interface IProps {
  order?: OrderResponseDto
  damageAmount?: number
  actions?: JSX.Element[]
  sx?: SxProps<Theme>
  highlight?: string
  validateOutboundRequest?: ValidateOutboundOrderFunc
}

export const GeneralCargoOrderCard = observer(
  ({ order, damageAmount, sx, highlight, validateOutboundRequest }: IProps) => {
    const theme = useMinimalsTheme()
    const { t } = useTranslate()

    return (
      <Box
        sx={{
          width: '100%',
          padding: theme.customSpacing.xs,
          ...sx,
        }}
      >
        {/* HEADER */}
        <Box
          sx={{
            display: 'flex',
            gap: theme.customSpacing.xs,
            alignItems: 'center',
            mb: theme.customSpacing.xs,
          }}
        >
          <GeneralCargoOrderIcon />
          <Typography variant='subtitle1'>
            <HighlightedText highlight={highlight} color='green'>
              {order?.referenceNumber}
            </HighlightedText>
          </Typography>
        </Box>

        {/* CONTENT */}
        <Box
          sx={{
            mb: theme.customSpacing.xs,
            color: theme.palette.grey[600],
          }}
        >
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between', mb: theme.customSpacing.xxxs }}
          >
            <Typography variant='body2' fontWeight={'600'}>
              <HighlightedText highlight={highlight} color='green'>
                {order?.commodityName}
              </HighlightedText>
            </Typography>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
              <Typography variant='body2'>
                {order?.packageName ? (
                  <Typography variant='body2'>
                    {t('package', 'Package')}: <b>{order?.packageName}</b>
                  </Typography>
                ) : (
                  ''
                )}
              </Typography>
              {order?.plannedCargoAmount ? (
                <Typography variant='body2'>
                  {t('planned', 'Planned')}: <b>{order?.plannedCargoAmount}</b>
                </Typography>
              ) : (
                ''
              )}
              {order?.status === 'Fulfilled' ? (
                <Typography variant='body2'>
                  {t('actual', 'Actual')}: <b>{order?.actualCargoAmount}</b>
                </Typography>
              ) : (
                ''
              )}
              {!!damageAmount && (
                <Typography variant='body2'>
                  {t('damaged', 'Damaged')}: <b>{damageAmount}</b>
                </Typography>
              )}
            </Box>
          </Box>

          {/* ATTRIBUTES */}
          {order?.commodityAttributes && order?.commodityAttributes.length > 0 ? (
            <Typography
              variant='body2'
              sx={{
                width: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                mb: theme.customSpacing.xxxs,
              }}
            >
              {order?.commodityAttributes
                ?.map(
                  item =>
                    `${item.name!.charAt(0).toUpperCase() + item.name?.slice(1)}: ${item.value}`,
                )
                .join(' | ')}
            </Typography>
          ) : (
            ''
          )}
          <Typography variant='body2'>
            <HighlightedText highlight={highlight} color='green'>
              {order?.customerName}
            </HighlightedText>
          </Typography>
        </Box>

        {order &&
          validateOutboundRequest &&
          validateOutboundRequest({
            orderId: order.id,
            isOutbound: order.direction === 'Outbound',
            commodityId: order.commodityId ?? undefined,
            customerId: order.customerReferenceId ?? undefined,
            lotNumber: order.lotNumber ?? undefined,
            quantity: order.plannedCargoAmount ?? undefined,
            unitIds: order.identifiableItems ?? undefined,
            packageId: order.packageId ?? undefined,
            imoClasses: order.imoClasses,
          })}
      </Box>
    )
  },
)
