import * as api from '@storage/app/api'
import { tolgee } from '@storage/app/translation'
import ContextMenuIcon from '@storage/components/icons/svg/ContextMenuIcon.svg'
import { KonvaText } from '@storage/components/konva-text'
import useContextMenu from '@storage/hooks/use-context-menu.hook'
import { Menu, MenuItem } from '@mui/material'
import { ThemeProvider, useMinimalsTheme } from '@tom-ui/ui'
import { YardElement, YardElementType } from '@storage/pages/yard-setup/types/yard-setup.types'
import { IYardBlockSummary } from '@storage/stores/yard-block-summary.store'
import { TolgeeProvider, useTranslate } from '@tolgee/react'
import Konva from 'konva'
import { Vector2d } from 'konva/lib/types'
import { useRef } from 'react'
import { Group, Image, Rect, Transformer } from 'react-konva'
import { Html } from 'react-konva-utils'
import useImage from 'use-image'
import { stackHeightInPixels, stackWidthInPixels } from '../constants'
import { useTransformer } from '../hooks'
import { AllocationSpace } from '../stores/stacks-allocation.store'
import { YardBlockGrid } from './YardBlockGrid'
import { YardBlockStacks } from './YardBlockStacks'
import { StackSizeDigit } from './stack'
import { YardBlockSummary } from './widgets'

interface Props {
  blockDto: api.YardBlockDto
  bays: api.YardBlockBayDto[]
  rows: api.YardBlockRowDto[]
  stacks: api.StackDto[]
  stacksFromYardFilter?: api.StackDto[]
  allocatedSpace: AllocationSpace[]
  allocationSize?: StackSizeDigit
  isSelected: boolean
  inEditMode: boolean
  displayAllocations: boolean
  yardBlockSummary?: IYardBlockSummary
  stagePosition: Vector2d
  onBlockStackClick?: (blockId: string, stackId: string, bayId: string) => void
  onBlockStackDblClick?: (blockId: string, bayId: string, rowId: string) => void
  onClick?: (e: any) => void
  onGridCellClick?: (bayId: string, rowId: string) => void
  onMenuAction?: (action: string) => void
  onShowSummary: (yardBlockId: string) => void | Promise<void>
  onGeometryChange: (element: YardElement) => void
  onBayLabelClicked: (bay: api.YardBlockBayDto) => void
  onRowLabelClicked: (row: api.YardBlockRowDto) => void
  onClickSummary: () => void
}

export const YardBlock = ({
  blockDto,
  bays,
  rows,
  stacks,
  allocatedSpace,
  allocationSize,
  stacksFromYardFilter,
  isSelected,
  inEditMode,
  displayAllocations,
  yardBlockSummary,
  stagePosition,
  onClick,
  onBlockStackClick,
  onBlockStackDblClick,
  onMenuAction,
  onShowSummary,
  onGeometryChange,
  onBayLabelClicked,
  onRowLabelClicked,
  onClickSummary,
}: Props) => {
  const yardBlockRef = useRef<Konva.Group>(null)
  const yardBlockRectRef = useRef<Konva.Rect>(null)
  const [contextMenuIcon] = useImage(ContextMenuIcon)
  const { menuProps, handleOpenMenu } = useContextMenu()
  const { palette } = useMinimalsTheme()
  const { t } = useTranslate()

  const { handleTransformEnd, refreshTransformer, transformerProps } = useTransformer({
    refreshDeps: [isSelected],
    stagePosition: stagePosition,
    transformerFor: yardBlockRef,
    currentRotation: blockDto.geometry?.rotation,
    onRotate: (x, y, rotation) => {
      onGeometryChange({
        type: YardElementType.YardBlock,
        id: blockDto.id,
        geometry: {
          left: x,
          top: y,
          rotation: Math.round(rotation),
        },
      })
    },
  })

  const handleDragEnd = async (p: Vector2d) => {
    onGeometryChange({
      type: YardElementType.YardBlock,
      id: blockDto.id,
      geometry: {
        top: Math.round(p.y),
        left: Math.round(p.x),
      },
    })
  }

  return (
    <>
      <Group
        x={blockDto.geometry?.left}
        y={blockDto.geometry?.top}
        ref={yardBlockRef}
        onTransformEnd={handleTransformEnd}
        rotation={blockDto.geometry?.rotation}
        onDragEnd={e => handleDragEnd(e.currentTarget.position())}
        draggable={inEditMode && isSelected}
      >
        <Rect
          ref={yardBlockRectRef}
          width={blockDto.bayCount * stackWidthInPixels}
          height={blockDto.rowCount * stackHeightInPixels}
          fill={palette.common.white}
          stroke={palette.grey[400]}
          strokeWidth={1}
          hitStrokeWidth={0}
          onClick={onClick}
          listening={isSelected}
          cornerRadius={2}
        />
        {inEditMode && isSelected && (
          <>
            <Image
              image={contextMenuIcon}
              x={stackWidthInPixels * blockDto.bayCount}
              onClick={({ evt }) => handleOpenMenu(evt.clientX, evt.clientY)}
            />
            <Html>
              <Menu {...menuProps}>
                <MenuItem onClick={() => onMenuAction && onMenuAction('EDIT')}>
                  {t('edit', 'Edit')}
                </MenuItem>
                <MenuItem onClick={() => onMenuAction && onMenuAction('DELETE')}>
                  {t('delete', 'Delete')}
                </MenuItem>
              </Menu>
            </Html>
          </>
        )}
        <Group x={0} y={isSelected && (inEditMode || displayAllocations) ? -50 : -25}>
          <KonvaText
            text={blockDto.name}
            fontSize={14}
            fontFamily='Arial'
            fontStyle='bold'
            fill='#8A4BAC'
            width={blockDto.bayCount * stackWidthInPixels}
            align='center'
            onClick={() => onMenuAction?.('INFO')}
            onShowInfo={() => onShowSummary(blockDto.id)}
          />
        </Group>

        <Group>
          <YardBlockGrid
            block={blockDto}
            bays={bays}
            rows={rows}
            isSelected={isSelected}
            inEditMode={inEditMode}
            displayAllocations={displayAllocations}
            onRedraw={refreshTransformer}
            onBayLabelClicked={onBayLabelClicked}
            onRowLabelClicked={onRowLabelClicked}
          />

          <YardBlockStacks
            stacks={stacks}
            allocatedSpace={allocatedSpace}
            stacksMatchingFilter={stacksFromYardFilter}
            onClickStack={(stackId, bayId) => onBlockStackClick?.(blockDto.id, stackId, bayId)}
            onDblClickStack={(bayId, rowId) => onBlockStackDblClick?.(blockDto.id, bayId, rowId)}
            maxBlockTier={blockDto.maxTier}
            allocationSize={allocationSize}
          />
        </Group>

        {yardBlockSummary && (
          <Html transformFunc={attrs => ({ ...attrs, rotation: 0 })}>
            <TolgeeProvider tolgee={tolgee}>
              <ThemeProvider>
                <YardBlockSummary
                  summary={yardBlockSummary}
                  position={{
                    y: 0,
                    x: yardBlockRectRef.current
                      ? yardBlockRectRef.current.getClientRect().width
                      : 0,
                  }}
                  onClick={onClickSummary}
                />
              </ThemeProvider>
            </TolgeeProvider>
          </Html>
        )}
      </Group>

      {inEditMode && isSelected && <Transformer {...transformerProps} />}
    </>
  )
}
