import { OrderStatus, OrdersApi, ReleaseOrderDto } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'
import { Event, IEvent, IQueryHandler, IQueryMessage } from '../messageBus'

export class GetReleaseOrdersQuery implements IQueryMessage {
  static type = 'GetReleaseOrdersQuery'
  type = GetReleaseOrdersQuery.type

  constructor(
    public page: number,
    public pageSize: number,
    public orderStatus?: OrderStatus,
    public filter?: string,
    public includeCompleted?: boolean,
  ) {}

  getKey = () => {
    return JSON.stringify(this)
  }
}

const httpClient = createApiClient(OrdersApi)

export const getReleaseOrdersQueryHandler: IQueryHandler<
  GetReleaseOrdersQuery,
  IEvent<ReleaseOrderDto[]>
> = async query => {
  const { data } = await httpClient.releaseOrders(
    query.page,
    query.pageSize,
    query.orderStatus,
    query.filter,
    query.includeCompleted,
  )

  return new Event(GetReleaseOrdersQuery.type, data.payload)
}
