import { CommodityDto } from '@billing/app/api'
import { FormProps, SelectOption } from '@billing/app/models'
import { ControlledNumberField } from '@billing/components/form-controls'
import { ControlledAutocomplete } from '@billing/components/form-controls/ControlledAutocomplete'
import { ControlledTextField } from '@billing/components/form-controls/ControlledTextField'
import useFormWithSchema from '@billing/hooks/use-form-with-schema.hook'
import { Alert, Box, Grid } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { CustomInputAdornment, useMinimalsTheme } from '@tom-ui/ui'
import { useEffect } from 'react'
import { CommodityAttributeForm } from './CommodityAttributeForm'
import { CommodityFormMapper } from './commodity-form.mapper'
import { CommodityFormProfile, defaultValues } from './commodity-form.profile'
import { schema } from './commodity-form.scheme'

interface Props extends FormProps {
  commodity?: CommodityDto
  packageOptions: SelectOption[]
  onSubmit: (formValues: CommodityFormProfile) => Promise<void> | void
}

export const CommodityForm = ({ id, commodity, packageOptions, onSubmit }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const { handleSubmit, formState, control, reset } = useFormWithSchema<CommodityFormProfile>(
    schema(),
    defaultValues,
  )

  useEffect(() => {
    if (commodity) {
      reset(
        CommodityFormMapper.mapDtoToFormValues(
          commodity,
          packageOptions.filter(x => commodity.packageIds.find(p => p === +x.value)),
        ),
      )
    }
  }, [reset, commodity, packageOptions])

  return (
    <Box
      id={id}
      component='form'
      noValidate
      autoComplete='off'
      onSubmit={handleSubmit(onSubmit)}
      pb={theme.customSpacing.xs}
    >
      <Grid container spacing={theme.customSpacing.m}>
        {commodity?.usedByOrder && (
          <Grid item xs={12}>
            <Alert severity='warning'>
              {t(
                'cannotEditCommodity',
                'You cannot edit this Commodity. (You could only update packages)',
              )}{' '}
              <br />
              {t('usedByOrders', 'It is already being used by existing orders.')}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <ControlledTextField<CommodityFormProfile>
            name='name'
            formState={formState}
            control={control}
            label={t('name', 'Name')}
            fullWidth
            showAsterisk
            disabled={commodity?.usedByOrder}
          />
        </Grid>

        <Grid item xs={12}>
          <ControlledAutocomplete<CommodityFormProfile>
            name='packageIds'
            formState={formState}
            control={control}
            label={t('packages', 'Packages')}
            multiple
            options={packageOptions}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <ControlledNumberField<CommodityFormProfile>
            name='length'
            formState={formState}
            control={control}
            label={t('length', 'Length')}
            fullWidth
            endAdornment={<CustomInputAdornment position='end'>mm</CustomInputAdornment>}
            disabled={commodity?.usedByOrder}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <ControlledNumberField<CommodityFormProfile>
            name='width'
            formState={formState}
            control={control}
            label={t('width', 'Width')}
            fullWidth
            endAdornment={<CustomInputAdornment position='end'>mm</CustomInputAdornment>}
            disabled={commodity?.usedByOrder}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ControlledNumberField<CommodityFormProfile>
            name='height'
            formState={formState}
            control={control}
            label={t('height', 'Height')}
            fullWidth
            endAdornment={<CustomInputAdornment position='end'>mm</CustomInputAdornment>}
            disabled={commodity?.usedByOrder}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ControlledNumberField<CommodityFormProfile>
            name='weight'
            formState={formState}
            control={control}
            label={t('weight', 'Weight')}
            fullWidth
            endAdornment={<CustomInputAdornment position='end'>kg</CustomInputAdornment>}
            disabled={commodity?.usedByOrder}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ControlledTextField<CommodityFormProfile>
            name='classDefinition'
            formState={formState}
            control={control}
            label={t('class', 'Class')}
            fullWidth
            disabled={commodity?.usedByOrder}
          />
        </Grid>

        <Grid item xs={12}>
          <ControlledTextField<CommodityFormProfile>
            name='instructions'
            formState={formState}
            control={control}
            label={t('instructions', 'Instructions')}
            multiline
            minRows={2}
            maxRows={3}
            fullWidth
            disabled={commodity?.usedByOrder}
          />
        </Grid>
      </Grid>
      <CommodityAttributeForm
        control={control}
        formState={formState}
        disabled={commodity?.usedByOrder}
      />
    </Box>
  )
}
