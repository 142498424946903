import { CreateUserCommand, RoleDto, UpdateUserCommand, UserDto, UsersApi } from '@admin/app/api'
import { BaseDataStore } from '@admin/app/store/basedata.store'
import { makeObservable, observable, runInAction } from 'mobx'

export class UserStore extends BaseDataStore<UserDto> {
  selectedUserRoles: RoleDto[] = []
  constructor(private api: UsersApi) {
    super()
    makeObservable(this, {
      selectedUserRoles: observable,
    })
  }

  async load() {
    const { data } = await this.api.usersGet()
    runInAction(() => {
      this.data = data
    })
  }

  async fetchUserRoles(userId: string) {
    const { data } = await this.api.usersGetUserRoles(userId)
    runInAction(() => {
      this.selectedUserRoles = data
    })
  }

  async add(createUserCommand: CreateUserCommand) {
    const { data } = await this.api.usersPost(createUserCommand)

    runInAction(() => (this.data = [...this.data, data]))
  }

  async update(updateUserCommand: UpdateUserCommand) {
    const { data } = await this.api.usersPut(updateUserCommand.userId, updateUserCommand)

    runInAction(
      () => (this.data = [...this.data.filter(e => e.user_id != updateUserCommand.userId), data]),
    )
  }

  async delete(userId: string) {
    await this.api.usersDelete(userId)
    runInAction(() => (this.data = [...this.data.filter(e => e.user_id != userId)]))
  }

  async updateUserRoles(userId: string, roleIds: string[]) {
    await this.api.usersUpdateUserRoles(userId, { userId, roleIds })
  }
}
