import {
  carrierVisitScheduleDefaultValues,
  ICarrierVisitSchedule,
} from '@planning/components/CarrierVisitSchedule'
import { ITripId } from '@planning/components/visit/VisitTripIdsFields'
import { action, makeObservable, observable } from 'mobx'

export class VisitPlanningStore {
  schedule: ICarrierVisitSchedule = carrierVisitScheduleDefaultValues

  isEditMode = false
  loading = true

  constructor() {
    makeObservable(this, {
      schedule: observable,
      loading: observable,
      isEditMode: observable,

      setSchedule: action,
      setLoading: action,
      setIsEditMode: action,
    })
  }

  setSchedule = (schedule: ICarrierVisitSchedule) => {
    this.schedule = schedule
  }

  setLoading = (loading: boolean) => (this.loading = loading)

  setIsEditMode = (isEditMode: boolean) => (this.isEditMode = isEditMode)

  protected scheduleTiming = () => {
    return this.schedule.showTimingOptions
      ? {
          repeatEvery: parseFloat(this.schedule.repeatEvery.toString()),
          repeatBy: this.schedule.repeatBy,
          ...(this.schedule.endsOn &&
            this.schedule.selectedRadioOption === 'on' && {
              endsOn: this.schedule.endsOn.toISOString(),
            }),
          ...(this.schedule.endsAfter &&
            this.schedule.selectedRadioOption === 'after' && {
              endsAfter: parseFloat(this.schedule.endsAfter.toString()),
            }),
        }
      : undefined
  }

  protected resetVisit() {
    this.schedule = carrierVisitScheduleDefaultValues
  }

  protected getInboundsFromTripIds(tripIds: ITripId[]) {
    return tripIds.filter(obj => obj.inbound).map(obj => obj.inbound)
  }

  protected getOutboundsFromTripIds(tripIds: ITripId[]) {
    return tripIds.filter(obj => obj.outbound).map(obj => obj.outbound)
  }
}
