import { BooleanSelectOption, SelectOption } from '@storage/app/models'
import { useTranslate } from '@tolgee/react'
import { useMemo } from 'react'

export const useRestowOptions = (): SelectOption[] => {
  const { t } = useTranslate()

  return useMemo(
    () => [
      { label: t(`restow`), value: BooleanSelectOption.True },
      { label: t(`nonRestow`), value: BooleanSelectOption.False },
    ],
    [t],
  )
}
