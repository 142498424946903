import { Box, Tooltip } from '@mui/material'
import { BinIcon, ConfirmationDialog, IconButton } from '@tom-ui/ui'
import { usePlanningStore } from '@planning/AppProvider'
import { OrderResponseDto } from '@planning/app/api'
import nnrOrderService from '@planning/services/nnrOrderService'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'

interface Props {
  order: OrderResponseDto
  isNNROrderClosed?: boolean
  isTruckVisitArrived?: boolean
}

export const UnassignContainerButton = observer(
  ({ order, isNNROrderClosed, isTruckVisitArrived }: Props) => {
    const { appViewStore } = usePlanningStore()
    const { t } = useTranslate()

    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

    const onClickDelete = async () =>
      isTruckVisitArrived ? setIsDialogOpen(true) : await onConfirmDelete()

    const onConfirmDelete = async () => {
      try {
        await nnrOrderService.unassignContainer({
          nonNumericOrderId: order.nonNumericOrderId!,
          orderId: order.id,
        })
        appViewStore.setShowAlert('success', t('containerUnassigned', 'Container unassigned'))
      } catch (error) {
        appViewStore.setShowAlert(
          'error',
          t('failedToUnassignContainer', 'Failed to unassign container'),
        )
      }
    }

    return (
      (order.status === 'Open' && !isNNROrderClosed && !!order.nonNumericOrderId && (
        <>
          <Box sx={{ display: 'flex' }}>
            <Tooltip arrow placement='bottom' title={'Delete'}>
              <IconButton color='error' onClick={onClickDelete}>
                <BinIcon />
              </IconButton>
            </Tooltip>
          </Box>

          <ConfirmationDialog
            open={isDialogOpen}
            title={`${t(
              'truckAlreadyArrivedForPickUpSureYouWantToUnassign',
              'Truck already arrived for pick up. Sure you want to unassign',
            )} ${order.containerNumber}?`}
            primaryActionText={t('remove', 'Remove')}
            closeLabel={t('cancel', 'Cancel')}
            onConfirm={onConfirmDelete}
            onClose={() => setIsDialogOpen(false)}
          />
        </>
      )) || <></>
    )
  },
)
