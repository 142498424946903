import { createSvgIcon } from '@mui/material'

export const TankIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M18.667 11.9994C18.667 15.6813 15.6822 18.6661 12.0003 18.6661C8.3184 18.6661 5.33363 15.6813 5.33363 11.9994C5.33363 8.31751 8.3184 5.33274 12.0003 5.33274C15.6822 5.33274 18.667 8.31751 18.667 11.9994Z'
      fill='currentColor'
    />
    <path
      d='M16.6671 12.0005C16.6671 14.5778 14.5778 16.6671 12.0005 16.6671C9.42315 16.6671 7.33381 14.5778 7.33381 12.0005C7.33381 9.42315 9.42315 7.33381 12.0005 7.33381C14.5778 7.33381 16.6671 9.42315 16.6671 12.0005Z'
      fill='white'
      fillOpacity='0.24'
    />
    <path
      d='M14.6664 11.9998C14.6664 13.4725 13.4725 14.6664 11.9998 14.6664C10.527 14.6664 9.3331 13.4725 9.3331 11.9998C9.3331 10.527 10.527 9.3331 11.9998 9.3331C13.4725 9.3331 14.6664 10.527 14.6664 11.9998Z'
      fill='white'
      fillOpacity='0.24'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.25 2C2.55964 2 2 2.55964 2 3.25V20.75C2 21.4404 2.55964 22 3.25 22H20.75C21.4404 22 22 21.4404 22 20.75V3.25C22 2.55964 21.4404 2 20.75 2H3.25ZM3.875 3.875V6.57411L6.57411 3.875H3.875ZM3.875 15.658V8.34187L8.34187 3.875H15.6581L20.125 8.34187V15.658L15.658 20.125H8.342L3.875 15.658ZM3.875 17.4258V20.125H6.57423L3.875 17.4258ZM20.125 6.57411L17.4259 3.875H20.125V6.57411ZM17.4258 20.125H20.125V17.4258L17.4258 20.125Z'
      fill='currentColor'
    />
    <path
      d='M12.6666 11.9991C12.6666 12.3672 12.3681 12.6657 11.9999 12.6657C11.6318 12.6657 11.3333 12.3672 11.3333 11.9991C11.3333 11.6309 11.6318 11.3324 11.9999 11.3324C12.3681 11.3324 12.6666 11.6309 12.6666 11.9991Z'
      fill='white'
      fillOpacity='0.24'
    />
  </svg>,
  'TankIcon',
)
