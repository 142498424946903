import { Box, Button, FormControlLabel, TextField } from '@mui/material'
import { YardPositionDto } from '@storage/app/api'
import { YardPositionInputContainer } from '@storage/features/yard-operation-control/Variants/YardPositionInput/YardPositionInputContainer'
import { ContainerAutoCompleteStore } from '@storage/stores/container-auto-complete.store'
import { ContainerShiftingsFormStore } from '@storage/stores/container-shiftings-form.store'
import { ContainerShiftingsStore } from '@storage/stores/container-shiftings.store'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { ShiftedContainerDto } from '../SearchContainerAutoComplete'

interface Props {
  item: ShiftedContainerDto
  autoCompleteStore: ContainerAutoCompleteStore
  formStore: ContainerShiftingsFormStore
  shiftingsStore: ContainerShiftingsStore
  onPositionUpdate?: (id: string) => void
}

export const ContainerShiftingsForm = observer(
  ({ item, formStore, shiftingsStore, onPositionUpdate }: Props) => {
    const { t } = useTranslate()
    const { palette } = useMinimalsTheme()

    const handleOnPositionChange = (position?: YardPositionDto) => {
      formStore.setContainerCurrentPosition(item.id, position)
      formStore.disableConfirmButton(item.id, false)
    }

    const updateContainerPosition = () => {
      if (onPositionUpdate) {
        onPositionUpdate(item.id)
      }
    }

    useEffect(() => {
      const loadTurnover = async () => {
        shiftingsStore.fetchTurnover(item.containerNumber)
      }
      loadTurnover()
    }, [item.containerNumber, shiftingsStore])

    return (
      <Box sx={{ display: 'flex', alignContent: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 1,
          }}
        >
          <Box sx={{ padding: '1rem' }}>
            <FormControlLabel
              control={
                <TextField
                  label={t('containerNumber', 'Container number')}
                  value={item.containerNumber}
                  sx={{
                    input: {
                      color: palette.grey[600],
                      fontSize: 24,
                      fontWeight: 600,
                      textTransform: 'uppercase',
                    },
                  }}
                />
              }
              disabled
              label={''}
            />
          </Box>
          <YardPositionInputContainer
            plannedPosition={item.previousLocation}
            disableSelectFlexibleZones={true}
            label={t('previousLocation', 'Previous Location')}
            disabled
          />
          <YardPositionInputContainer
            disableSelectFlexibleZones={true}
            onChange={handleOnPositionChange}
            label={t('currentLocation', 'Current Location')}
            turnover={shiftingsStore.turnover}
            alertSx={{ width: '335px', height: '60px' }}
          />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', marginTop: '30px' }}>
          <Button
            variant='contained'
            disabled={
              formStore.containerCurrentPositions.get(item.id) === undefined ||
              formStore.containersToBeShiftedFormItems.get(item.id)?.isConfirmButtonDisabled
            }
            onClick={updateContainerPosition}
          >
            Confirm
          </Button>
          <Box>
            <Button
              variant='text'
              color='inherit'
              onClick={() => formStore.removeFormItem(item.id)}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Box>
    )
  },
)
