import { Box } from '@mui/material'
import { ReactNode } from 'react'

interface Props {
  children: ReactNode
  setMouseHover: (value: boolean) => void
}

export const Hover = ({ children, setMouseHover }: Props) => {
  let timeoutToClearMouseOver: NodeJS.Timeout

  const handleMouseEnter = () => {
    if (timeoutToClearMouseOver) {
      clearTimeout(timeoutToClearMouseOver)
    }

    setMouseHover(true)
  }

  const handleMouseLeave = () => {
    timeoutToClearMouseOver = setTimeout(() => {
      setMouseHover(false)
    }, 3000)
  }

  return (
    <Box onMouseEnter={() => handleMouseEnter()} onMouseLeave={() => handleMouseLeave()}>
      {children}
    </Box>
  )
}
