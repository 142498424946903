import { StorageSummaryDto } from '@billing/app/api'
import StorageReportMode from '@billing/app/models/storage-report-mode.enum'
import exportService from '@billing/services/ExportService'
import { Dayjs } from 'dayjs'
import _ from 'lodash'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'

export class StorageReportUIStore {
  storageReportMode?: StorageReportMode

  from: Dayjs | null = null
  to: Dayjs | null = null
  summary: StorageSummaryDto[] = []

  constructor() {
    makeObservable(this, {
      storageReportMode: observable,
      setStorageReportMode: action,

      from: observable,
      setFrom: action,

      to: observable,
      setTo: action,

      summary: observable,
      loadSummary: action,
      totalDays: computed,
    })
  }

  setStorageReportMode(storageReportType?: StorageReportMode): void {
    this.storageReportMode = storageReportType
  }

  setFrom(from: Dayjs | null): void {
    this.from = from
  }

  setTo(to: Dayjs | null): void {
    this.to = to
  }

  loadSummary = async () => {
    const result = await exportService.getNonVesselSummary(
      this.from!,
      this.to!,
      this.storageReportMode!,
    )

    runInAction(() => {
      this.summary = result
    })
  }

  setSummary(dto?: StorageSummaryDto[]): void {
    this.summary = dto ?? []
  }

  public get totalDays(): number {
    return _.sumBy(this.summary, x => _.sumBy(x.containers, c => c.amount))
  }

  public resetData(): void {
    this.setStorageReportMode()
    this.setFrom(null)
    this.setTo(null)
    this.setSummary()
  }
}
