import { Box, Button, Grid, Paper, TextField, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { YesNoChipBox } from '@planning/components/dangerous-goods/YesNoChipBox'
import { useTranslate } from '@tolgee/react'
import { BinIcon } from '@tom-ui/ui'
import { FC } from 'react'
import { Controller, FieldValues, Path, UseControllerProps } from 'react-hook-form'

interface IProps<T extends FieldValues> extends UseControllerProps<T> {
  sealsName: Path<T>
  hasSealsName: Path<T>
}

const Seal: FC<{
  seal: string
  index: number
  value: string[]
  onChange: (newSeals: string[]) => void
}> = ({ seal, index, value, onChange }) => {
  return (
    <Grid container key={index} alignItems='center'>
      <Grid item xs={11}>
        <TextField
          sx={{ width: '100%' }}
          type='text'
          value={seal}
          onChange={e => {
            const newSeals = [...value]
            newSeals[index] = e.target.value
            onChange(newSeals)
          }}
        />
      </Grid>
      <Grid item xs={1} sx={{ display: 'flex', placeContent: 'center' }}>
        <Button
          color='error'
          type='button'
          onClick={() => {
            const newSeals = value.filter((_: string, i: number) => i !== index)
            onChange(newSeals)
          }}
        >
          <BinIcon />
        </Button>
      </Grid>
    </Grid>
  )
}

const SealsList: FC<{
  value: string[]
  onChange: (...event: any[]) => void
}> = ({ value, onChange }) => {
  const { t } = useTranslate()

  return (
    <Stack gap={1} marginTop={1.5}>
      {value?.map((seal: string, index: number) => (
        <Seal key={index} seal={seal} index={index} value={value} onChange={onChange} />
      ))}
      <Button
        type='button'
        onClick={() => {
          onChange([...value, ''])
        }}
      >
        {t('addSealNo', 'Add Seal No.')}
      </Button>
    </Stack>
  )
}

const ContainerSeals: FC<{
  hasSeals: boolean
  onChangeHasSeals: (value: boolean) => void
  sealsName: string
  control: any
}> = ({ hasSeals, onChangeHasSeals, sealsName, control }) => {
  const { t } = useTranslate()

  return (
    <>
      <Box display='flex' alignItems={'center'} justifyContent='space-between'>
        <Typography variant='body1' fontWeight='bold'>
          {t('seals', 'Seals')}?
        </Typography>
        <YesNoChipBox
          activeOption={hasSeals ? 'Yes' : 'No'}
          onClickYes={() => onChangeHasSeals(true)}
          onClickNo={() => onChangeHasSeals(false)}
        />
      </Box>
      {hasSeals && (
        <Controller
          control={control}
          name={sealsName}
          render={({ field: { onChange, value } }) => (
            <SealsList value={value} onChange={onChange} />
          )}
        />
      )}
    </>
  )
}

export const ControlledContainerSeals = <T extends FieldValues>({
  hasSealsName,
  control,
  sealsName,
}: IProps<T>) => {
  return (
    <Paper variant='outlined' sx={{ p: '1rem' }}>
      <Box>
        <Stack direction='column' gap={0.5}>
          <Controller
            control={control}
            name={hasSealsName}
            render={({ field: { onChange: onChangeHasSeals, value: hasSeals } }) => (
              <ContainerSeals
                hasSeals={hasSeals}
                onChangeHasSeals={onChangeHasSeals}
                sealsName={sealsName}
                control={control}
              />
            )}
          />
        </Stack>
      </Box>
    </Paper>
  )
}
