import { CommodityDto, CreateCommodityCommand, UpdateCommodityCommand } from '@billing/app/api'
import { SelectOption } from '@billing/app/models'
import { CommodityAttributeFormProfile, CommodityFormProfile } from './commodity-form.profile'

const mapFormValuesToCreateDto = (profile: CommodityFormProfile): CreateCommodityCommand => {
  return {
    name: profile.name,
    packageIds: profile.packageIds?.map(x => +x.value) ?? [],
    attributes: getAttributesByCommodityFormProfile(profile),
  }
}

const mapFormValuesToUpdateDto = (profile: CommodityFormProfile): UpdateCommodityCommand => ({
  id: profile.id!,
  name: profile.name,
  packageIds: profile.packageIds?.map(x => +x.value) ?? [],
  attributes: getAttributesByCommodityFormProfile(profile),
})

const mapDtoToFormValues = (
  { id, name, attributes }: CommodityDto,
  packageIds: SelectOption[],
): CommodityFormProfile => {
  const possibleFields = ['width', 'height', 'length', 'weight', 'classDefinition', 'instructions']
  const width = attributes.find(x => x.name === 'width')?.value,
    length = attributes.find(x => x.name === 'length')?.value,
    height = attributes.find(x => x.name === 'height')?.value,
    weight = attributes.find(x => x.name === 'weight')?.value,
    classDefinition = attributes.find(x => x.name === 'classDefinition')?.value,
    instructions = attributes.find(x => x.name === 'instructions')?.value

  return {
    id,
    name,
    packageIds: packageIds,
    attributes: attributes.filter(x => !possibleFields.includes(x.name)),
    width: width ? +width : undefined,
    height: height ? +height : undefined,
    length: length ? +length : undefined,
    weight: weight ? +weight : undefined,
    instructions: instructions,
    classDefinition: classDefinition,
  }
}

export const CommodityFormMapper = {
  mapFormValuesToCreateDto,
  mapFormValuesToUpdateDto,
  mapDtoToFormValues,
}

const getAttributesByCommodityFormProfile = ({
  height,
  width,
  length,
  classDefinition,
  weight,
  instructions,
  attributes,
}: CommodityFormProfile) => {
  const completeAttributes: CommodityAttributeFormProfile[] = []

  if (length !== undefined) {
    completeAttributes.push({ name: 'length', value: length.toString() })
  }

  if (width !== undefined) {
    completeAttributes.push({ name: 'width', value: width.toString() })
  }

  if (height !== undefined) {
    completeAttributes.push({ name: 'height', value: height.toString() })
  }

  if (weight !== undefined) {
    completeAttributes.push({ name: 'weight', value: weight.toString() })
  }

  if (classDefinition !== undefined) {
    completeAttributes.push({ name: 'classDefinition', value: classDefinition.toString() })
  }

  if (instructions !== undefined) {
    completeAttributes.push({ name: 'instructions', value: instructions.toString() })
  }

  completeAttributes.push(...attributes)

  return completeAttributes
}
