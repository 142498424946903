import { Button } from '@mui/material'
import { Box } from '@mui/system'
import { useStores } from '@storage/hooks/use-stores.hook'
import { StockDto } from '@storage/types'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'

export const GeneralCargoStockOccupancyList = observer(
  ({
    customerId,
    handleOnSelect,
  }: {
    customerId?: number
    handleOnSelect: (stock: StockDto) => void
  }) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()

    // TODO: check if needed?
    // useHubConnection(yardManagementHubConnection)

    const { generalCargoInventoryStore, generalCargoInventoryUIStore } = useStores()

    const columns = useMemo<MRT_ColumnDef<StockDto>[]>(
      () => [
        {
          accessorKey: 'commodityName',
          header: t('commodity', 'Commodity'),
        },
        {
          accessorKey: 'lotNumber',
          header: t('lotNumber', 'Lot Number'),
        },
        {
          accessorKey: 'packageName',
          header: t('package', 'Package'),
        },
        {
          accessorKey: 'totalQuantity',
          header: t('inStock', 'In stock'),
        },
      ],
      [t],
    )

    useEffect(() => {
      generalCargoInventoryStore.loadByQuery({ customerId, withOccupancyOnly: true })
    }, [customerId, generalCargoInventoryStore])

    return (
      <Box my={theme.customSpacing.l}>
        <MaterialReactTable
          columns={columns}
          data={generalCargoInventoryUIStore.generalCargoStock}
          autoResetPageIndex={false}
          enableTopToolbar={false}
          enableRowActions
          positionActionsColumn='last'
          renderRowActions={({ row }) => (
            <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
              <Button variant='text' color='secondary' onClick={() => handleOnSelect(row.original)}>
                {t('select', 'Select')}
              </Button>
            </Box>
          )}
        />
      </Box>
    )
  },
)
