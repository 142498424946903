import { useBillingServiceStore } from '@billing/AppProvider'
import { getPackageName } from '@billing/utils/helper'
import { useTranslate } from '@tolgee/react'
import { BinIcon, ConfirmationDialog, IconButton } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { CommodityForm } from '../components/form/CommodityForm'
import { CommodityFormMapper } from '../components/form/commodity-form.mapper'
import { CommodityFormProfile } from '../components/form/commodity-form.profile'

export const CommoditiesDialogContainer = observer(() => {
  const { t } = useTranslate()
  const { appStore, commodityStore, packageStore, commodityDialogUIStore } =
    useBillingServiceStore()

  const handleSubmit = async (formValues: CommodityFormProfile) => {
    if (commodityDialogUIStore.formType === 'Add') {
      appStore.showAlertForCancellableRequest(
        'success',
        `${formValues.name} ${t('isBeingCreated', 'is being created')}`,
        () =>
          appStore.triggerRequestWithoutLoader(
            async () =>
              await commodityStore.add(CommodityFormMapper.mapFormValuesToCreateDto(formValues)),
          ),
        `${formValues.name} ${t('creationCancelled', 'creation cancelled')}`,
      )
    } else {
      appStore.showAlertForCancellableRequest(
        'success',
        `${formValues.name} ${t('isBeingUpdated', 'is being updated')}`,
        () =>
          appStore.triggerRequestWithoutLoader(
            async () =>
              await commodityStore.update(
                CommodityFormMapper.mapFormValuesToUpdateDto(formValues),
                !!commodityDialogUIStore.editingEntity?.usedByOrder,
              ),
          ),
        `${formValues.name} ${t('updateCancelled', 'update cancelled')}`,
      )
    }

    commodityDialogUIStore.toggle(commodityDialogUIStore.editingEntity?.id)
  }

  const handleClose = () => {
    commodityDialogUIStore.toggle(commodityDialogUIStore.editingEntity?.id)
  }

  const handleToggleDelete = () => {
    commodityDialogUIStore.toggle(commodityDialogUIStore.editingEntity?.id)
    commodityDialogUIStore.toggleDelete()
  }

  const handleCloseDelete = () => {
    commodityDialogUIStore.toggleDelete()
  }

  const handleDelete = async () => {
    if (commodityDialogUIStore.editingEntity) {
      const commodityName = commodityDialogUIStore.editingEntity.name
      await commodityStore.delete(commodityDialogUIStore.editingEntity.id)
      handleCloseDelete()

      appStore.setShowAlert('success', `${commodityName} ${t('deleted', 'deleted')}`)
    }
  }

  return (
    <>
      <ConfirmationDialog
        open={commodityDialogUIStore.isOpen}
        title={`${
          commodityDialogUIStore.formType === 'Add' ? t('addNew', 'Add new') : t('edit', 'Edit')
        } ${t('commodity', 'Commodity')}`}
        primaryActionText={t('save', 'Save')}
        closeLabel={t('cancel', 'Cancel')}
        formId='commodity-form'
        maxWidth='sm'
        rightRenderOption={
          commodityDialogUIStore.formType === 'Edit' ? (
            <IconButton variant='contained' color='default' onClick={handleToggleDelete}>
              <BinIcon />
            </IconButton>
          ) : undefined
        }
        onClose={handleClose}
        hasBorderTop
      >
        <CommodityForm
          id='commodity-form'
          commodity={commodityDialogUIStore.editingEntity}
          packageOptions={packageStore.items.map(x => ({ value: x.id, label: getPackageName(x) }))}
          onSubmit={handleSubmit}
        />
      </ConfirmationDialog>

      <ConfirmationDialog
        open={commodityDialogUIStore.isDeleteOpen}
        title={t('deleteCommodity', 'Delete commodity')}
        message={t(
          'entityWillBeDeleted',
          '{name} will be deleted and you cannot undo this action!',
          {
            name: commodityDialogUIStore.editingEntity?.name ?? t('commodity'),
          },
        )}
        primaryActionText={t('delete', 'Delete')}
        closeLabel={t('keepEditing', 'Keep editing')}
        maxWidth='xs'
        blockClickOutside
        onConfirm={handleDelete}
        onClose={handleToggleDelete}
      />
    </>
  )
})
