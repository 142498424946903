import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { CargoType, CarrierType, CarrierVisitDirection, VesselVisitDto } from '@planning/app/api'
import { YesNoChipBox } from '@planning/components/dangerous-goods/YesNoChipBox'
import IconTruck from '@planning/components/icon/IconTruck'
import { RailVisitAutoComplete } from '@planning/components/visit/RailVisitAutoComplete'
import { VesselVisitAutoComplete } from '@planning/components/visit/VesselVisitAutoComplete'
import { GeneralCargoViewStore } from '@planning/stores/generalCargo/GeneralCargoViewStore'
import { useTranslate } from '@tolgee/react'
import { FieldBox, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import { Control, Controller, FieldError, useFormContext } from 'react-hook-form'
import { IOrderWithVisit } from '../../stores/SelectOrderViewStore'
import { ICreateGeneralCargoOrderFormData } from '../CreateGeneralCargoOrderPageForm'

interface IProps {
  store: GeneralCargoViewStore
  isOrderFulfilled?: boolean
  order?: any // TODO Fix type
  visitData?: IOrderWithVisit
  isTruckAppointment?: boolean
  disableCarrierVisitSelection?: boolean
  onValidationParamsChanged: (params: any) => void
}

const RenderDirectionExtraField: FC<{
  control: Control<ICreateGeneralCargoOrderFormData>
  carrierVisit: any
  error?: FieldError
  disabled?: boolean
  carrierType?: CarrierType
}> = ({ control, error, carrierVisit, carrierType, disabled }) => {
  const { t } = useTranslate()

  if (carrierType === CarrierType.Vessel || carrierType === CarrierType.Train) {
    return (
      <Controller
        control={control}
        name={'carrierVisit'}
        rules={{ required: true }}
        render={({ field: { onChange } }) => {
          const vesselVisitAutoComplete = (
            <VesselVisitAutoComplete
              selectedVesselVisit={carrierVisit}
              handleOnChange={visit => {
                onChange(visit)
              }}
              error={!!error}
              helperText={error ? t('fieldIsRequired', 'Field is required.') : ''}
              disabled={disabled}
              cargoType={CargoType.GeneralCargo}
            />
          )

          const railVisitAutoComplete = (
            <RailVisitAutoComplete
              selectedRailVisit={carrierVisit}
              handleOnChange={visit => {
                onChange(visit)
              }}
              error={!!error}
              helperText={error ? t('fieldIsRequired', 'Field is required.') : ''}
              disabled={disabled}
              cargoType={CargoType.GeneralCargo}
            />
          )

          switch (carrierType) {
            case CarrierType.Vessel:
              return vesselVisitAutoComplete
            case CarrierType.Train:
              return railVisitAutoComplete
            default:
              return <></>
          }
        }}
      />
    )
  }

  if (carrierType === CarrierType.Truck) {
    return (
      <Box sx={{ display: 'flex', height: '100%', alignItems: 'center' }}>
        <IconTruck zoom={150} />
        <Typography variant='subtitle1' ml='.5rem'>
          {t('truck', 'Truck')}
        </Typography>
      </Box>
    )
  }
}

export const HandlingDirectionControl: FC<IProps> = observer(
  ({
    store,
    order,
    visitData,
    isTruckAppointment,
    isOrderFulfilled,
    disableCarrierVisitSelection,
    onValidationParamsChanged,
  }) => {
    const theme = useMinimalsTheme()
    const { t } = useTranslate()

    const {
      control,
      register,
      getValues,
      formState: { errors },
      setValue,
    } = useFormContext<ICreateGeneralCargoOrderFormData>()

    const [direction, setDirection] = useState<CarrierVisitDirection>(
      getValues('direction') ?? CarrierVisitDirection.Inbound,
    )

    const [carrierType, setCarrierType] = useState(
      visitData?.order?.carrierVisitType ?? order?.data.carrierVisitType,
    )
    const [carrierVisit, setCarrierVisit] = useState<VesselVisitDto | undefined>(
      (visitData?.visit ?? order?.visit) as VesselVisitDto,
    )

    const resetTransportFields = () => {
      setValue('carrierVisit', undefined)
      setValue('carrierVisitType', undefined)
      setValue('waggon', undefined)
      setCarrierType(undefined)
      setCarrierVisit(undefined)
    }

    return (
      <Stack gap={2}>
        <FieldBox
          titleColor={store.selectedOrder || visitData ? theme.palette.text.disabled : undefined}
          label={t('handlingDirection', 'Handling direction')}
          rightRender={
            <Controller
              control={control}
              name={`direction`}
              render={({ field: { value, onChange } }) => (
                <Box>
                  <Chip
                    label={t('inbound', 'Inbound')}
                    color='primary'
                    sx={{ padding: '0 1rem', mr: '.75rem' }}
                    variant={value === CarrierVisitDirection.Inbound ? undefined : 'outlined'}
                    onClick={() => {
                      onChange(CarrierVisitDirection.Inbound)
                      setDirection(CarrierVisitDirection.Inbound)
                      resetTransportFields()
                      onValidationParamsChanged({ isOutbound: false })
                    }}
                    disabled={isTruckAppointment || !!store.selectedOrder || !!visitData}
                  />
                  <Chip
                    label={t('outbound', 'Outbound')}
                    color='primary'
                    sx={{ padding: '0 1rem' }}
                    variant={value === CarrierVisitDirection.Outbound ? undefined : 'outlined'}
                    onClick={() => {
                      onChange(CarrierVisitDirection.Outbound)
                      setDirection(CarrierVisitDirection.Outbound)
                      resetTransportFields()
                      onValidationParamsChanged({ isOutbound: true })
                    }}
                    disabled={isTruckAppointment || !!store.selectedOrder || !!visitData}
                  />
                </Box>
              )}
            />
          }
        />

        <Controller
          control={control}
          name={`transportAssigned`}
          render={({ field: { value, onChange } }) => (
            <Paper variant='outlined' sx={{ p: '1rem' }}>
              <Box display='flex' alignItems={'center'} justifyContent='space-between'>
                <Typography
                  variant='subtitle2'
                  color={isOrderFulfilled || !!visitData ? theme.palette.text.disabled : ''}
                >
                  {t('transportAssigned', 'Transport assigned')}?
                </Typography>
                <YesNoChipBox
                  disableYes={isTruckAppointment || isOrderFulfilled || !!visitData}
                  disableNo={isTruckAppointment || isOrderFulfilled || !!visitData}
                  activeOption={value ? 'Yes' : 'No'}
                  onClickYes={() => {
                    onChange(true)
                  }}
                  onClickNo={() => {
                    resetTransportFields()
                    onChange(false)
                  }}
                />
              </Box>
              {value && (
                <Grid sx={{ marginTop: '0px' }} container spacing={2}>
                  {direction === CarrierVisitDirection.Inbound && (
                    <>
                      <Grid item sm={4} xs={12}>
                        <Controller
                          control={control}
                          name={`carrierVisitType`}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <FormControl fullWidth error={!!errors.carrierVisitType}>
                              <InputLabel>{t('inbound', 'Inbound')}</InputLabel>
                              <Select
                                defaultValue=''
                                value={value ?? ''}
                                label={t('inbound', 'Inbound')}
                                disabled={
                                  isTruckAppointment ||
                                  isOrderFulfilled ||
                                  !!visitData ||
                                  disableCarrierVisitSelection
                                }
                                onChange={(e: any) => {
                                  onChange(e.target.value)
                                  setCarrierType(e.target.value)
                                  setCarrierVisit(undefined)
                                  setValue('carrierVisit', undefined)
                                  setValue('waggon', undefined)
                                }}
                              >
                                <MenuItem value='Train'>{t('train', 'Train')}</MenuItem>
                                <MenuItem value='Truck'>{t('truck', 'Truck')}</MenuItem>
                                <MenuItem value='Vessel'>{t('vessel', 'Vessel')}</MenuItem>
                              </Select>
                              {errors.carrierVisitType && (
                                <FormHelperText>
                                  {t('fieldIsRequired', 'Field is required.')}
                                </FormHelperText>
                              )}
                            </FormControl>
                          )}
                        />
                      </Grid>

                      <Grid item sm={carrierType === 'Train' ? 5 : 8} xs={12}>
                        <RenderDirectionExtraField
                          error={errors.carrierVisitType}
                          carrierVisit={carrierVisit}
                          control={control}
                          disabled={isOrderFulfilled || !!visitData || disableCarrierVisitSelection}
                          carrierType={carrierType}
                        />
                      </Grid>

                      {carrierType === 'Train' && (
                        <Grid item sm={3} xs={12}>
                          <TextField
                            disabled={isOrderFulfilled || !!visitData}
                            fullWidth
                            label={`${t('waggon', 'Waggon')}`}
                            {...register('waggon')}
                            variant='outlined'
                            type='text'
                          />
                        </Grid>
                      )}
                    </>
                  )}
                  {direction === CarrierVisitDirection.Outbound && (
                    <>
                      <Grid item sm={4} xs={12}>
                        <Controller
                          control={control}
                          name={`carrierVisitType`}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <FormControl fullWidth error={!!errors.carrierVisitType}>
                              <InputLabel>{t('outbound', 'Outbound')}</InputLabel>
                              <Select
                                defaultValue=''
                                value={value ?? ''}
                                label={t('outbound', 'Outbound')}
                                disabled={
                                  isTruckAppointment ||
                                  isOrderFulfilled ||
                                  !!visitData ||
                                  disableCarrierVisitSelection
                                }
                                onChange={(e: any) => {
                                  onChange(e.target.value)
                                  setCarrierType(e.target.value)
                                  setCarrierVisit(undefined)
                                  setValue('carrierVisit', undefined)
                                  setValue('waggon', undefined)
                                }}
                              >
                                <MenuItem value='Train'>{t('train', 'Train')}</MenuItem>
                                <MenuItem value='Truck'>{t('truck', 'Truck')}</MenuItem>
                                <MenuItem value='Vessel'>{t('vessel', 'Vessel')}</MenuItem>
                              </Select>
                              {errors.carrierVisitType ? (
                                <FormHelperText>
                                  {t('fieldIsRequired', 'Field is required.')}
                                </FormHelperText>
                              ) : (
                                ''
                              )}
                            </FormControl>
                          )}
                        />
                      </Grid>

                      <Grid item sm={carrierType === 'Train' ? 5 : 8} xs={12}>
                        <RenderDirectionExtraField
                          error={errors.carrierVisitType}
                          carrierVisit={carrierVisit}
                          control={control}
                          disabled={isOrderFulfilled || !!visitData || disableCarrierVisitSelection}
                          carrierType={carrierType}
                        />
                      </Grid>

                      {carrierType === 'Train' && (
                        <Grid item sm={3} xs={12}>
                          <TextField
                            disabled={isOrderFulfilled || !!visitData}
                            fullWidth
                            label={`${t('waggon', 'Waggon')}`}
                            {...register('waggon')}
                            variant='outlined'
                            type='text'
                          />
                        </Grid>
                      )}
                    </>
                  )}
                </Grid>
              )}
            </Paper>
          )}
        />
      </Stack>
    )
  },
)
