import { EquipmentDto, EquipmentType, EquipmentTypes, YardBlockDto } from '@operations/app/api'
import { FormProps, SelectOption } from '@operations/app/models'
import { ControlledCheckbox, ControlledNumberField } from '@operations/components/form-controls'
import { ControlledSelect } from '@operations/components/form-controls/ControlledSelect'
import { ControlledTextField } from '@operations/components/form-controls/ControlledTextField'
import useFormWithSchema from '@operations/hooks/use-form-with-schema.hook'
import { Box, FormGroup, Grid, Stack, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { canBeAssignedToYardBlock } from '@operations/utils/helper'
import _ from 'lodash'
import { useEffect } from 'react'
import { ControlledYardBlock } from '../controlledYardBlock/ControlledYardBlock'
import { EquipmentFormMapper } from './equipment-form.mapper'
import { EquipmentFormProfile, defaultValues } from './equipment-form.profile'
import { schema } from './equipment-form.scheme'
import { CustomInputAdornment, useMinimalsTheme } from '@tom-ui/ui'

interface Props extends FormProps {
  equipment?: EquipmentDto
  yardBlocks: YardBlockDto[]
  yardBlocksWithEquipmentsDictionary: Record<string, number[]>
  onSubmit: (formValues: EquipmentFormProfile) => Promise<void> | void
}

export const EquipmentForm = ({
  id,
  equipment,
  yardBlocks,
  yardBlocksWithEquipmentsDictionary,
  onSubmit,
}: Props) => {
  const { handleSubmit, formState, control, reset, watch } =
    useFormWithSchema<EquipmentFormProfile>(schema(), defaultValues)

  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const equipmentType = watch('equipmentType')

  const equipmentTypeOptions: SelectOption[] = _(
    Object.values(EquipmentType)
      .filter(x => x !== EquipmentType.Sc)
      .map(x => ({
        label: t(x),
        value: x,
      })),
  )
    .sortBy(i => i.label)
    .value()

  const yardBlockOptions: SelectOption[] = _(
    yardBlocks
      .filter(
        x =>
          (x.equipmentTypes.includes(equipmentType.toString()) ||
            (x.equipmentTypes.includes(EquipmentTypes.Rs) && //TOLATER: ECH for now can assigned to RS yard, but in future?
              equipmentType === EquipmentType.Ech)) &&
          (!x.equipmentTypes.includes(EquipmentTypes.Rtg) || //For RTG only show yard blocks that does not have an RTG yet (RTG to Yard Blocks is one to many; RS/ECH to yard Blocks is many to many)
            !yardBlocksWithEquipmentsDictionary[x.id] ||
            (!!equipment && yardBlocksWithEquipmentsDictionary[x.id].includes(equipment.id))),
      )
      .map(({ id, name }) => ({ label: name, value: id })),
  )
    .sortBy(i => i.label)
    .value()

  useEffect(() => {
    if (equipment) {
      reset(EquipmentFormMapper.mapDtoToFormValues(equipment))
    }
  }, [reset, equipment])

  return (
    <Box id={id} component='form' noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
      <FormGroup sx={{ gap: theme.customSpacing.l }}>
        <ControlledTextField<EquipmentFormProfile>
          name='name'
          formState={formState}
          control={control}
          label={t('name')}
          fullWidth
        />
        <ControlledSelect<EquipmentFormProfile>
          name='equipmentType'
          formState={formState}
          control={control}
          label={t('equipmentType')}
          options={equipmentTypeOptions}
          disabled={!!equipment}
        />

        <Grid container spacing={theme.customSpacing.l}>
          <Grid item xs={6}>
            <ControlledNumberField<EquipmentFormProfile>
              name='maxWeight'
              formState={formState}
              control={control}
              label={t('maxWeight', 'Max weight')}
              fullWidth
              endAdornment={<CustomInputAdornment position='end'>kg</CustomInputAdornment>}
            />
          </Grid>

          <Grid item xs={6}>
            <ControlledNumberField<EquipmentFormProfile>
              name='maxHeight'
              formState={formState}
              control={control}
              label={t('maxHeight', 'Max height')}
              fullWidth
              endAdornment={<CustomInputAdornment position='end'>m</CustomInputAdornment>}
            />
          </Grid>
        </Grid>

        {canBeAssignedToYardBlock(equipmentType) && (
          <ControlledYardBlock<EquipmentFormProfile>
            name='yardBlockIds'
            formState={formState}
            control={control}
            label={t('workAreas', 'Work areas')}
            options={yardBlockOptions}
          />
        )}

        <Stack>
          <Typography variant='subtitle2'>{t('status', 'Status')}</Typography>
          <ControlledCheckbox
            name='isOnMaintenance'
            formState={formState}
            control={control}
            label={t('unavailableForOperations', 'Unavailable for operations')}
          />
        </Stack>
      </FormGroup>
    </Box>
  )
}
