import StorageReportMode from '@billing/app/models/storage-report-mode.enum'
import { useBillingServiceStore } from '@billing/AppProvider'
import { Asterisk } from '@billing/components/asterisk/Asterisk'
import { AlwaysHoveredOutlinedButton } from '@billing/components/button/AlwaysHoveredOutlinedButton'
import { Stack, Typography, styled } from '@mui/material'
import { MobileDatePicker } from '@mui/x-date-pickers'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useTranslate } from '@tolgee/react'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'

const StorageReportModeButtonContent = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(3),
  width: theme.spacing(20),
  height: theme.spacing(12),
}))

export const StorageReportFilter = observer(() => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const localFormate = dayjs.Ls[dayjs.locale()].formats.L

  const { storageReportUIStore, reportsUIStore } = useBillingServiceStore()

  const billingModeButton = (storageReportMode: StorageReportMode) => (
    <AlwaysHoveredOutlinedButton
      color='primary'
      variant='outlined'
      theme={theme}
      selected={storageReportMode === storageReportUIStore.storageReportMode}
      onClick={() => storageReportUIStore.setStorageReportMode(storageReportMode)}
      error={
        reportsUIStore.nextButtonWasBlocked && !storageReportUIStore.storageReportMode
          ? 'true'
          : 'false'
      }
      sx={{
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          width: '268px',
        },
        height: theme.spacing(10),
      }}
    >
      <StorageReportModeButtonContent direction='column' alignItems='center'>
        <Typography variant='subtitle2' color='black'>
          {t(storageReportMode)}
        </Typography>
        <Typography variant='body2' color='gray'>
          {storageReportMode === StorageReportMode.Standard
            ? t('allContainers', 'All containers')
            : t('containersOut', 'Containers out')}
        </Typography>
      </StorageReportModeButtonContent>
    </AlwaysHoveredOutlinedButton>
  )

  return (
    <Stack gap={theme.customSpacing.m} width='100%'>
      <Stack gap={theme.customSpacing.xs}>
        <Typography variant='subtitle2'>
          {t('billingMode', 'Billing mode')}
          {Asterisk}
        </Typography>
        <Stack
          gap={theme.customSpacing.m}
          sx={{
            [theme.breakpoints.up('sm')]: {
              flexDirection: 'row',
            },
          }}
        >
          {billingModeButton(StorageReportMode.Standard)}
          {billingModeButton(StorageReportMode.ExitDate)}
        </Stack>
      </Stack>

      <Stack gap={theme.customSpacing.xs}>
        <Typography variant='subtitle2'>
          {t('period', 'Period')} {Asterisk}
        </Typography>
        <Stack
          gap={theme.customSpacing.m}
          sx={{
            [theme.breakpoints.up('sm')]: {
              flexDirection: 'row',
            },
          }}
        >
          <MobileDatePicker
            label={t('from', 'From')}
            value={storageReportUIStore.from}
            onChange={newValue => storageReportUIStore.setFrom(newValue)}
            disableFuture={true}
            maxDate={storageReportUIStore.to}
            slotProps={{
              actionBar: {
                actions: ['clear', 'accept', 'today'],
              },
              textField: {
                required: true,
                error:
                  reportsUIStore.nextButtonWasBlocked && !storageReportUIStore.from
                    ? true
                    : undefined,
                helperText: localFormate,
              },
            }}
            sx={{
              width: '100%',
              [theme.breakpoints.up('sm')]: {
                width: '268px',
              },
            }}
          />
          <MobileDatePicker
            label={t('to', 'To')}
            value={storageReportUIStore.to}
            onChange={newValue => storageReportUIStore.setTo(newValue)}
            disableFuture={true}
            minDate={storageReportUIStore.from}
            slotProps={{
              actionBar: {
                actions: ['clear', 'accept', 'today'],
              },
              textField: {
                required: true,
                error:
                  reportsUIStore.nextButtonWasBlocked && !storageReportUIStore.to
                    ? true
                    : undefined,
                helperText: localFormate,
              },
            }}
            sx={{
              width: '100%',
              [theme.breakpoints.up('sm')]: {
                width: '268px',
              },
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  )
})
