import { ExpandMore } from '@mui/icons-material'
import { IconButton, Menu, MenuItem } from '@mui/material'
import { useState } from 'react'

interface Props {
  items: any[]
  onSelect: (item?: any) => void
}

export const ExpandableMenu = ({ items, onSelect }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton onClick={handleClick}>
        <ExpandMore />
      </IconButton>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {items.map(item => (
          <MenuItem
            key={item.id}
            onClick={() => {
              onSelect(item)
              handleClose()
            }}
          >
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
