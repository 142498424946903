import { VesselIcon } from '@tom-ui/ui'
import { FC } from 'react'
import { IconColor } from './IconColor'

export const IconVessel: FC<{ zoom?: number; size?: number; color?: IconColor }> = ({
  zoom,
  size,
  color,
}) => {
  return (
    <VesselIcon
      viewBox='0 0 20 14'
      color={color}
      sx={{
        height: `${size ?? 24}px`,
        width: `${size ?? 24}px`,
        transform: `scale(${(zoom ?? 100) / 100})`,
      }}
    />
  )
}
export default IconVessel
