import { Box, Card, CardContent, Typography } from '@mui/material'
import { CarrierVisitDirection, OrderResponseDto } from '@planning/app/api'
import { ContainerDirectionChip } from '@planning/components/ContainerDirectionChip'
import { CustomAlert } from '@planning/pages/TallymanV2/Components'
import { ValidateOutboundOrderFunc } from '@planning/stores/gateControl/ValidateOutboundDto'
import { useTranslate } from '@tolgee/react'
import { GeneralCargoOrderIcon, HighlightedText, useMinimalsTheme } from '@tom-ui/ui'
import { FC } from 'react'

interface Props {
  highlight?: string
  onClick?: () => void
  children?: React.ReactNode
  direction?: CarrierVisitDirection
  order?: OrderResponseDto
  validateOutboundRequest?: ValidateOutboundOrderFunc
}

export const GateClerkGeneralCargoResult: FC<Props> = ({
  highlight,
  onClick,
  children,
  direction,
  order,
  validateOutboundRequest,
}) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const referenceNumber = order?.referenceNumber

  const CargoAmountDiscrepancyAlert = () => (
    <CustomAlert
      message={t('cargoAmountDiscrepancyDetected', 'Cargo amount discrepancy detected')}
      severity='warning'
    />
  )

  return (
    <Card
      sx={{
        mb: '1rem',
      }}
      onClick={() => {
        onClick?.()
      }}
    >
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', gap: theme.customSpacing.s }}>
            <GeneralCargoOrderIcon />
            <Typography variant='h3'>
              <HighlightedText highlight={highlight}>{referenceNumber}</HighlightedText>
            </Typography>
          </Box>

          {direction ? (
            <ContainerDirectionChip
              direction={direction}
              label={direction === 'Inbound' ? t('dropOff', 'Drop Off') : t('pickUp', 'Pick Up')}
            />
          ) : (
            ''
          )}
        </Box>
        <Box>
          <Box
            sx={{
              mb: theme.customSpacing.xs,
              color: theme.palette.grey[600],
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mb: theme.customSpacing.xxxs,
              }}
            >
              <Typography variant='body2' fontWeight={'600'}>
                {order?.commodityName}
              </Typography>

              {order?.plannedCargoAmount ? (
                <Typography variant='body2'>
                  {t('planned', 'Planned')}: <b>{order?.plannedCargoAmount}</b>
                </Typography>
              ) : (
                ''
              )}
            </Box>

            {/* ATTRIBUTES */}
            {order?.commodityAttributes && order?.commodityAttributes.length > 0 ? (
              <Typography
                variant='body2'
                sx={{
                  width: '100%',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  mb: theme.customSpacing.xxxs,
                }}
              >
                {order?.commodityAttributes
                  ?.map(
                    item =>
                      `${item.name!.charAt(0).toUpperCase() + item.name?.slice(1)}: ${item.value}`,
                  )
                  .join('\n')}
              </Typography>
            ) : (
              ''
            )}
            <Typography variant='body2'>{order?.customerName}</Typography>
          </Box>
        </Box>
        {order?.direction === 'Outbound' &&
          validateOutboundRequest &&
          validateOutboundRequest({
            orderId: order.id,
            isOutbound: true,
            commodityId: order.commodityId ?? undefined,
            customerId: order.customerReferenceId ?? undefined,
            lotNumber: order.lotNumber ?? undefined,
            quantity: order.plannedCargoAmount ?? undefined,
            unitIds: order.identifiableItems ?? undefined,
            packageId: order.packageId ?? undefined,
            imoClasses: order.imoClasses,
          })}
        {children}
      </CardContent>
    </Card>
  )
}
