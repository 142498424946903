import { Table, TableBody, TableRow, TableCell, Typography } from '@mui/material'
import { tableCellClasses } from '@mui/material/TableCell'
import { GeneralCargoItemPlanningDto } from '@storage/app/api'
import { LabelBox } from '@storage/components/LabelBox'
import { useMinimalsTheme } from '@tom-ui/ui'

interface GeneralCargoReservationTableProps {
  rows: GeneralCargoItemPlanningDto[]
}
const GeneralCargoReservationTable = ({ rows }: GeneralCargoReservationTableProps) => {
  const theme = useMinimalsTheme()

  const borderStyle = {
    [`& .${tableCellClasses.root}`]: {
      py: 1,
      px: 3,
      borderTop: `1px solid ${theme.palette.grey[300]}`,
    },
  }
  return (
    <Table sx={borderStyle}>
      <TableBody>
        {rows?.map(row => (
          <TableRow key={row.id}>
            <TableCell component='th' scope='row'>
              <Typography variant='subtitle2'>{row.quantity}</Typography>
            </TableCell>
            <TableCell align='right'>
              <LabelBox
                label={`${row.generalCargoArea} ${row.generalCargoLocation}`}
                color={theme.palette.warning.dark}
                bgColor={theme.palette.warning.lighter}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default GeneralCargoReservationTable
