import { Button, Drawer } from '@mui/material'
import { useOperationsStore } from '@operations/AppProvider'
import { StepperContentWrapper } from '@operations/features/equipmentOperator/components/Stepper/StepperContentWrapper'
import { ReeferMonitoringFormMapper } from '@operations/features/reeferMonitoring/components/form/reefer-monitoring-form.mapper'
import { ReeferMonitoringFormProfile } from '@operations/features/reeferMonitoring/components/form/reefer-monitoring-form.profile'
import { ReeferMonitoringForm } from '@operations/features/reeferMonitoring/components/form/ReeferMonitoringForm'
import { formatContainerNumber } from '@operations/utils/helper'
import { useTranslate } from '@tolgee/react'
import { CloseButton, Header } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'

export const CoolingOrderStepperContainer = observer(() => {
  const { coolingOrdersUIStore, reeferTemperatureStore, appStore } = useOperationsStore()
  const { t } = useTranslate()

  const coolingOrder = coolingOrdersUIStore.selectedCoolingOrder

  if (!coolingOrder) return <></>

  const handleSubmit = async (formValues: ReeferMonitoringFormProfile) => {
    await reeferTemperatureStore.create(
      ReeferMonitoringFormMapper.mapFormValuesToCreateDto(formValues),
    )

    coolingOrdersUIStore.toggleStepper()
    appStore.setShowAlert('success', t('recordSaved', 'Record Saved'))
  }

  return (
    <Drawer
      open={coolingOrdersUIStore.isStepperOpen}
      anchor='right'
      PaperProps={{
        sx: {
          width: '100vw',
        },
      }}
    >
      <Header
        leftRenderOption={<CloseButton onClose={() => coolingOrdersUIStore.toggleStepper()} />}
        title={
          coolingOrder.containerNumber
            ? formatContainerNumber(coolingOrder.containerNumber)
            : t('noContainerNumber', 'No container no.')
        }
        titleColor='secondary'
        rightRenderOption={
          <Button
            variant='contained'
            color='primary'
            type='submit'
            form='cooling-order-form'
            data-cy='confirm-container-job-stepper-btn'
          >
            {t('confirm', 'Confirm')}
          </Button>
        }
      />

      <StepperContentWrapper title={t('reeferInspection', 'Reefer inspection')}>
        <ReeferMonitoringForm
          id='cooling-order-form'
          cargoUnitId={coolingOrder.cargoUnitId}
          containerNumber={coolingOrder.containerNumber}
          requestedTemperature={coolingOrder.requestedTemperature}
          lastCheck={coolingOrder.lastRecordedTemperatureDateTime}
          isTemperatureRequired={coolingOrder.isTemperatureRequired}
          onSubmit={handleSubmit}
          hideAdditionalInfoField
          hideContainerNumberField
          isCurrentlyPluggedIn={coolingOrder.isPluggedIn}
        />
      </StepperContentWrapper>
    </Drawer>
  )
})
