import { TextField, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { Controller, useFormContext } from 'react-hook-form'

export const TrainFields = observer(() => {
  const { t } = useTranslate()

  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <>
      <Typography variant='subtitle1' marginTop='1rem' marginBottom='0.5rem'>
        {t('train', 'Train')}
      </Typography>
      <Stack spacing={1}>
        <Controller
          control={control}
          name='name'
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              required
              label={t('trainName', 'Train name')}
              variant='outlined'
              data-cy='train-name-field'
              value={field.value ?? ''}
              error={!!errors.name}
              helperText={errors.name ? t('fieldIsRequired', 'Field is required.') : ''}
            />
          )}
        />

        <Controller
          control={control}
          name='trainOperator'
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label={t('trainOperator', 'Train operator')}
              variant='outlined'
              value={field.value ?? ''}
              data-cy='train-operator-field'
            />
          )}
        />
      </Stack>
    </>
  )
})
