import { Splitscreen } from '@mui/icons-material'
import { Box, Dialog, DialogContent, Stack, Tooltip, Typography, styled } from '@mui/material'
import { WorkQueue } from '@operations/features/craneSplit/models/work-queue.model'
import { convertOperationType, getThemePalleteByColorName } from '@operations/utils/helper'
import { useTranslate } from '@tolgee/react'
import { BinIcon, CloseButton, EditIcon, IconButton, MovesIcon, YardIcon } from '@tom-ui/ui'
import dayjs from 'dayjs'

const ContentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
}))

const Square = styled(Box)<{ colorname?: string }>(({ theme, colorname }) => ({
  flex: 'none',
  marginTop: '.4rem',
  height: '24px',
  width: '24px',
  borderRadius: '2px',
  background: getThemePalleteByColorName(theme, colorname!).main,
}))

interface Props {
  open: boolean
  workQueue: WorkQueue
  onEdit: (workQueue: WorkQueue) => Promise<void> | void
  onSplit: (workQueue: WorkQueue) => void
  onRemove: (id: number) => Promise<void> | void
  onClose: () => void
}

export const WorkQueueDetailsDialog = ({
  open,
  workQueue,
  onEdit,
  onSplit,
  onRemove,
  onClose,
}: Props) => {
  const { t } = useTranslate()
  const hourFormat = 'hh:mm A'
  const dayFormat = 'MM/DD/YYYY, ' + hourFormat

  if (!workQueue) return <></>

  const startTimeInfo = `${dayjs(workQueue.startTime).format(dayFormat)}`

  const endTimeInfo = `${
    workQueue.endDate.getHours() < workQueue.startTime.getHours()
      ? dayjs(workQueue.endDate).format(dayFormat)
      : dayjs(workQueue.endDate).format(hourFormat)
  }`

  const bayInfo = `${t('bay', 'Bay')} ${workQueue.bay} - ${workQueue.vesselArea}`

  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullWidth
      maxWidth={'xs'}
      PaperProps={{ sx: { borderRadius: '8px !important' } }}
    >
      <DialogContent sx={{ padding: 1.5 }}>
        <Stack flexDirection='row' justifyContent='flex-end' gap={1}>
          {workQueue.canEdit && (
            <Tooltip placement='top' title={t('edit', 'Edit')}>
              <IconButton size='small' onClick={() => onEdit(workQueue)}>
                <EditIcon fontSize='small' />
              </IconButton>
            </Tooltip>
          )}

          {workQueue.canEdit && workQueue.isMergeable && !workQueue.isUnknownBay && (
            <Tooltip placement='top' title={t('remove', 'Remove')}>
              <IconButton size='small' onClick={() => onRemove(+workQueue.id)}>
                <BinIcon fontSize='small' />
              </IconButton>
            </Tooltip>
          )}

          {workQueue.containersLeft > 0 &&
            workQueue.containersAmount > 1 &&
            !workQueue.isUnknownBay && (
              <Tooltip placement='top' title={t('split', 'Split')}>
                <IconButton size='small' onClick={() => onSplit(workQueue)}>
                  <Splitscreen fontSize='small' />
                </IconButton>
              </Tooltip>
            )}

          <CloseButton tooltip={t('close', 'Close')} size='small' onClose={() => onClose()} />
        </Stack>

        <Stack gap={2}>
          <ContentContainer>
            <Square colorname={workQueue.color} />

            <Stack>
              <Typography variant='subtitle2'>
                {`${t(convertOperationType(workQueue.operationType))} - ${workQueue.crane}`}
              </Typography>
              <Typography variant='caption' sx={{ whiteSpace: 'nowrap' }}>
                {`${startTimeInfo} - ${endTimeInfo}, ${bayInfo}`}
              </Typography>
            </Stack>
          </ContentContainer>

          <ContentContainer>
            <Stack alignItems={'center'} justifyContent={'center'}>
              <YardIcon />
            </Stack>
            <Stack>
              <Typography variant='caption'>{t('containers', 'Containers')}</Typography>
              <Typography variant='subtitle1'>{`${workQueue.containersAmountCompleted} / ${workQueue.containersAmount}`}</Typography>
            </Stack>
          </ContentContainer>

          <ContentContainer>
            <Stack alignItems={'center'} justifyContent={'center'}>
              <MovesIcon />
            </Stack>
            <Stack>
              <Typography variant='caption'>{t('moves', 'moves')}</Typography>
              <Typography variant='subtitle1'>{`${workQueue.movesCompleted} / ${workQueue.moves}`}</Typography>
            </Stack>
          </ContentContainer>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
