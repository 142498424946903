import { Card, CardContent, CardHeader } from '@mui/material'
import { IconButton, PlusIcon } from '@tom-ui/ui'
import { ContainerDamageResponseDto } from '@planning/app/api'
import { DamageReportViewStore } from '@planning/rt-stores/tallyman/DamageReportViewStore'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC, useMemo } from 'react'
import { DamageReportCard } from './DamageReportCard'
import { DamageReportDialog } from './DamageReportDialog'

interface IProps {
  containerId: number
  damages?: ContainerDamageResponseDto[]
  onEditDamageReport: (
    editedReport: Partial<ContainerDamageResponseDto>,
    initialReport: ContainerDamageResponseDto,
  ) => Promise<void> | void
  onCreateDamageReport: (
    containerId: number,
    damageReport: Partial<ContainerDamageResponseDto>,
  ) => Promise<void> | void
}

export const DamageReportBox: FC<IProps> = observer(
  ({ containerId, damages, onCreateDamageReport, onEditDamageReport }) => {
    const { t } = useTranslate()

    // [JH] TODO: Move to AppProvider and pass through props
    const damageReportStore = useMemo(
      () => new DamageReportViewStore(containerId, onCreateDamageReport, onEditDamageReport),
      [containerId, onCreateDamageReport, onEditDamageReport],
    )

    // const resolveDamageStore = useMemo(() => new ResolveDamageViewStore(containerId), [containerId])

    return (
      <Card variant='outlined' sx={{ minWidth: '12rem', boxShadow: 'none' }}>
        <CardHeader
          action={
            <IconButton onClick={() => damageReportStore.setOpen(true)}>
              <PlusIcon />
            </IconButton>
          }
          title={t('damageReport', 'Damage report')}
          sx={{ p: '1rem' }}
          titleTypographyProps={{ variant: 'subtitle2' }}
        />
        <CardContent sx={{ p: '0px!important' }}>
          {damages?.map((damageReport, i) => (
            <DamageReportCard
              key={`container-damage-report-${i}`}
              store={damageReportStore}
              damageReport={damageReport}
            />
          ))}
        </CardContent>
        <DamageReportDialog store={damageReportStore} />
        {/* <DamageReportResolveDialog store={resolveDamageStore} /> */}
      </Card>
    )
  },
)
