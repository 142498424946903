import { Box, Button, Card, CardActions, CardContent, Typography } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { ContainerDamageResponseDto } from '@planning/app/api'
import { IDamageReportViewStore } from '@planning/rt-stores/tallyman/DamageReportViewStore'
import { formatDateTime } from '@planning/utils'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

interface IProps {
  damageReport: ContainerDamageResponseDto
  store: IDamageReportViewStore
}

export const DamageReportCard: FC<IProps> = observer(({ damageReport, store }) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const isResolved = damageReport.status === 'Resolved'

  const descriptorColor = () => (isResolved ? 'primary' : 'inherit')

  const timestampDescriptor = () =>
    isResolved ? t('resolved', 'Resolved') : t('reported', 'Reported')

  const timestamp = () => (isResolved ? damageReport.resolved : damageReport.created)

  const TimestampTypography = () => {
    return (
      <Box display='flex'>
        <Typography variant='caption' color={descriptorColor()}>
          {timestampDescriptor()}
        </Typography>
        &nbsp;
        <Typography variant='caption'>{formatDateTime(timestamp())}</Typography>
      </Box>
    )
  }

  const onEdit = () => {
    store.setDamageReport(damageReport)
    store.setOpen(true)
  }

  // const onResolve = () => {
  //   store.setDamageReport(damageReport)
  //   store.setOpenResolve(true)
  // }

  // const onUnresolve = () => {
  //   store.setDamageReport(damageReport)
  //   store.unresolve()
  // }

  const Actions = () =>
    !isResolved && (
      <Button size='small' onClick={onEdit} sx={{ color: theme.palette.grey[800] }}>
        {t('edit', 'Edit')}
      </Button>
    )

  return (
    <>
      <Card variant='outlined' sx={{ borderRadius: 0, width: '100%' }}>
        <CardContent sx={{ p: '1rem 1rem .5rem 1rem !important' }}>
          <Typography sx={{ wordWrap: 'break-word', mb: '1rem' }} variant='body2'>
            {damageReport.description}
          </Typography>
          <TimestampTypography />
        </CardContent>
        <CardActions sx={{ p: '0 1rem 1rem !important' }}>
          <Actions />
        </CardActions>
      </Card>
    </>
  )
})
