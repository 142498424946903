import { Box, Button } from '@mui/material'
import { CheckmarkIcon, CloseButton, useMinimalsTheme } from '@tom-ui/ui'
import { useTranslate } from '@tolgee/react'
import { FC } from 'react'
import { OrderResponseDto } from '@planning/app/api'
import { GeneralCargoOrderCard } from '@planning/pages/GateControl/Components/GeneralCargoOrderCard'
import { theme } from 'antd'

interface Props {
  order: OrderResponseDto
  showThreeDotMenu?: boolean
  onCheck?: (order: any) => void
  onRemove?: (order: any) => void
  isChecked?: boolean
}

export const GCOrderListItem: FC<Props> = ({
  order,
  showThreeDotMenu,
  onCheck,
  onRemove,
  isChecked,
}) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  return (
    <Box display='flex' flexDirection='column'>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: '1rem',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mr: 5 }}>
          {isChecked && (
            <CheckmarkIcon
              sx={{ color: theme.palette.success.main, mr: '.75rem', fontSize: '2rem' }}
            />
          )}
          <GeneralCargoOrderCard order={order} />
        </Box>

        {!!onCheck && !isChecked && (
          <Button variant='contained' sx={{ mr: '.75rem' }} onClick={() => onCheck(order)}>
            {t('check', 'Check')}
          </Button>
        )}

        {!!onRemove && !showThreeDotMenu && (
          <CloseButton onClose={() => onRemove(order)} tooltip={t('remove', 'Remove')} />
        )}
      </Box>
    </Box>
  )
}
