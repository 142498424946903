import { Card } from '@mui/material'
import Box from '@mui/material/Box'
import Step from '@mui/material/Step'
import StepContent from '@mui/material/StepContent'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
import Typography from '@mui/material/Typography'
import { usePlanningStore } from '@planning/AppProvider'
import { InitializationWrapper } from '@planning/components/InitializationWrapper'
import { VesselTallyViewStore } from '@planning/rt-stores/tallyman/VesselTallyViewStore'
import { IVesselVisitItem } from '@planning/rt-stores/vesselVisit/VesselVisitItem'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import VesselIcon from './VesselIcon'

export type VesselVisitOperation = 'Discharge' | 'Load'
interface IStepItemProps<T> {
  label: string
  showIcon?: boolean
  data: T
  testId?: string
  onSelect?: (item: T) => void
}

const StepItem = <_, T>(props: IStepItemProps<T>) => {
  const theme = useMinimalsTheme()

  return (
    props.label && (
      <Card
        data-cy={props.testId}
        sx={{
          p: '1rem',
          m: '.5rem 0',
          borderRadius: theme.customRadius.medium,
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'space-between',
        }}
        onClick={() => props.onSelect?.(props.data)}
      >
        <Typography fontWeight='bold'>{props.label}</Typography>
        {props.showIcon && <VesselIcon />}
      </Card>
    )
  )
}

const SelectedItem: FC<{ item: IVesselVisitItem; handleBack: () => void }> = ({
  item,
  handleBack,
}) => {
  const theme = useMinimalsTheme()

  return (
    <Card
      sx={{
        p: '1rem',
        m: '-2rem .5rem .5rem 2rem',
        borderRadius: theme.customRadius.medium,
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
      }}
      onClick={() => {
        handleBack()
      }}
    >
      <Box>
        <Typography fontSize={'.8rem'}>Vessel Selection</Typography>
        <Typography fontWeight='bold'>{item.vessels[0].data.name}</Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography>Edit</Typography>
      </Box>
    </Card>
  )
}

interface IProps {
  store: VesselTallyViewStore
  onSelectOperation: (op: VesselVisitOperation) => void
}

export const VesselSelectionStepper: FC<IProps> = observer(({ store, onSelectOperation }) => {
  const { vesselVisitItemStore } = usePlanningStore()
  const { t } = useTranslate()

  const [activeStep, setActiveStep] = useState(store.selectedVisitId ? 1 : 0)

  const handleNext = () => setActiveStep(prevActiveStep => prevActiveStep + 1)
  const handleBack = () => setActiveStep(prevActiveStep => prevActiveStep - 1)

  const handleSelectVesselVisit = (item: IVesselVisitItem) => {
    store.selectVesselVisit(item.id)
    handleNext()
  }

  return (
    <InitializationWrapper isInitializing={!vesselVisitItemStore.hasBeenInitialized}>
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep} orientation='vertical' connector={null}>
          <Step>
            <StepLabel>{activeStep !== 1 ? t('Vessel selection') : ''} </StepLabel>

            <StepContent>
              {store.vesselVisits
                .filter(
                  item =>
                    item.discharge.orderCounts.container.confirmed <
                      item.discharge.orderCounts.container.total ||
                    item.load.orderCounts.container.confirmed <
                      item.load.orderCounts.container.total,
                )
                .map((item, index) => {
                  return (
                    <StepItem
                      testId='vessel-visit-item'
                      key={index}
                      label={item.vessels[0]?.data.name ?? ''}
                      data={item}
                      onSelect={handleSelectVesselVisit}
                      showIcon
                    />
                  )
                })}
            </StepContent>

            {activeStep === 1 && store.selectedVisit ? (
              <SelectedItem item={store.selectedVisit} handleBack={handleBack} />
            ) : (
              ''
            )}
          </Step>

          {/* Operation selection */}
          <Step>
            <StepLabel>Operation selection</StepLabel>
            <StepContent>
              {store.selectedVisit &&
              store.selectedVisit?.discharge.orderCounts.container.confirmed <
                store.selectedVisit?.discharge.orderCounts.container.total ? (
                <StepItem
                  testId='discharge-operation-item'
                  label={'Discharge'}
                  data={'Discharge'}
                  onSelect={onSelectOperation}
                />
              ) : (
                ''
              )}

              {store.selectedVisit &&
              store.selectedVisit?.load.orderCounts.container.confirmed <
                store.selectedVisit?.load.orderCounts.container.total ? (
                <StepItem
                  testId='load-operation-item'
                  label={'Load'}
                  data={'Load'}
                  onSelect={onSelectOperation}
                />
              ) : (
                ''
              )}
            </StepContent>
          </Step>
        </Stepper>
      </Box>
    </InitializationWrapper>
  )
})
