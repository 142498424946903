import {
  BerthDto,
  BerthGeometriesApi,
  BerthsApi,
  BerthsPutRequest,
  CreateBerthDto,
  YardElementGeometryDto,
} from '@storage/app/api'
import { createApiClient } from '@storage/app/http-client'
import { EntityStore } from '@storage/app/store/entity.store'
import { YardManagementHubConnection } from '@storage/hubs/yard-management.hub-connection'

export class BerthStore extends EntityStore<BerthDto> {
  private readonly MinGeometryWidth = 160

  constructor(
    private readonly api: BerthsApi,
    yardManagementConnection: YardManagementHubConnection,
  ) {
    super()

    yardManagementConnection.onBerthCreated(berth => this.addOrUpdate(berth))
    yardManagementConnection.onBerthUpdated(berth => this.addOrUpdate(berth))
    yardManagementConnection.onBerthDeleted(berthId => this.remove(berthId))
  }

  public async loadList(): Promise<void> {
    const { data: berths } = await this.api.getAll()

    this.setAll(berths)
  }

  public async load(id: string): Promise<void> {
    const { data: berth } = await this.api.get({ id })

    this.addOrUpdate(berth)
  }

  public async add(dto: CreateBerthDto): Promise<BerthDto> {
    const { data: berth } = await this.api.create({ berthsCreateRequest: dto })

    this.addOrUpdate(berth)

    return berth
  }

  public async update(dto: BerthsPutRequest): Promise<void> {
    const { data: berth } = await this.api.put({ berthsPutRequest: dto })

    this.addOrUpdate(berth)
  }

  public async delete(berthId: string): Promise<void> {
    await this.api._delete({ berthId })

    this.remove(berthId)
  }

  public get(id: string) {
    return this.data.get(id)
  }

  public set(berthDto: BerthDto) {
    this.addOrUpdate(berthDto)
  }

  public async saveBerthGeometry(id: string, geometry: YardElementGeometryDto) {
    const existingBerth = this.get(id)
    if (!existingBerth) {
      return
    }
    const { data: updatedBerth } = await createApiClient(BerthGeometriesApi).set({
      berthGeometriesSetRequest: {
        berthId: existingBerth.id,
        left: Math.round(geometry?.left) ?? 10,
        top: Math.round(geometry?.top) ?? 10,
        width: Math.round(geometry?.width ?? this.MinGeometryWidth),
        rotation: Math.round(geometry.rotation),
      },
    })

    this.addOrUpdate(updatedBerth)
  }
}
