import { CarrierVisitDirection, CarrierVisitStatus, OrderStatus } from './app/api'

export const CarrierVisitViewStatus = {
  Expected: 'Expected',
  Arrived: 'Arrived',
  Berthed: 'Berthed',
  InOperation: 'InOperation',
  OperationCompleted: 'OperationCompleted',
  Departed: 'Departed',
  Completed: 'Completed',
} as const

export type CarrierVisitViewStatus =
  (typeof CarrierVisitViewStatus)[keyof typeof CarrierVisitViewStatus]

type enumTypes =
  | CarrierVisitDirection
  | CarrierVisitStatus
  | CarrierVisitViewStatus
  | OrderStatus
  | 'IN'
  | 'OUT'
  | 'Discharge'
  | 'Load'
export type IEnumKeyValue = {
  id: enumTypes
  value: string
}

export type FormType = 'Add' | 'Edit' | 'Delete'
export interface SelectOption {
  label: string
  value: string
}

export const carrierVisitTypes = [
  { key: 1, name: 'Truck' },
  { key: 2, name: 'Vessel' },
  { key: 3, name: 'Train' },
]

export const containerHeights = [
  { key: 1, name: 'Standard' },
  { key: 2, name: 'High' },
  { key: 3, name: 'Low' },
]

export const containerTypes = [
  { key: 1, name: 'Unknown' },
  { key: 2, name: 'General' },
  { key: 3, name: 'GeneralVentilated' },
  { key: 4, name: 'Reefer' },
  { key: 5, name: 'OpenTop' },
  { key: 6, name: 'Platform' },
  { key: 7, name: 'Tank' },
  { key: 8, name: 'Bulk' },
  { key: 9, name: 'Foldable' },
  { key: 10, name: 'AirSurface' },
  { key: 11, name: 'Livestock' },
  { key: 12, name: 'Auto' },
  { key: 13, name: 'Fish' },
  { key: 14, name: 'NonCargoCarrying' },
]

export const visitDirection: IEnumKeyValue[] = [
  { id: CarrierVisitDirection.Inbound, value: CarrierVisitDirection.Inbound },
  { id: CarrierVisitDirection.Outbound, value: CarrierVisitDirection.Outbound },
]

export const visitDirectionShort: IEnumKeyValue[] = [
  { id: 'IN', value: 'IN' },
  { id: 'OUT', value: 'OUT' },
  { id: 'Discharge', value: 'Discharge' },
  { id: 'Load', value: 'Load' },
]

export const orderStatus: IEnumKeyValue[] = [
  { id: OrderStatus.Open, value: 'Open' },
  { id: OrderStatus.Fulfilled, value: 'Fulfilled' },
]

export const carrierVisitStatus: IEnumKeyValue[] = [
  { id: CarrierVisitStatus.Expected, value: 'Expected' },
  { id: CarrierVisitStatus.Arrived, value: 'Arrived' },
  { id: CarrierVisitStatus.InOperation, value: 'In Operation' },
  { id: CarrierVisitStatus.Departed, value: 'Departed' },
  { id: CarrierVisitStatus.Completed, value: 'Completed' },
]

export const carrierVisitViewStatus: IEnumKeyValue[] = [
  { id: CarrierVisitViewStatus.Expected, value: 'expected' },
  { id: CarrierVisitViewStatus.Arrived, value: 'arrived' },
  { id: CarrierVisitViewStatus.InOperation, value: 'inOperation' },
  { id: CarrierVisitViewStatus.Berthed, value: 'berthed' },
  { id: CarrierVisitViewStatus.OperationCompleted, value: 'completed' },
  { id: CarrierVisitViewStatus.Departed, value: 'departed' },
  { id: CarrierVisitViewStatus.Completed, value: 'completed' },
]

// reference: https://www.searates.com/de/reference/imo
export const ImdgCodes = [
  '1.1',
  '1.2',
  '1.3',
  '1.4',
  '1.5',
  '1.6',
  '2.1',
  '2.2',
  '2.3',
  '3',
  '4.1',
  '4.2',
  '4.3',
  '5.1',
  '5.2',
  '6.1',
  '6.2',
  '7',
  '8',
  '9',
]

export const emptyIndicator = '-'
