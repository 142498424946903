import { Stack } from '@mui/material'
import { useStores } from '@storage/hooks/use-stores.hook'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import YardPlanningDashboardBlock from './YardPlanningDashboardBlock'

const YardPlanningDashboardBlocks = observer(() => {
  const { yardBlockStore, yardBlockBayStore, yardBlockRowStore, yardBlockStackStore } = useStores()

  return (
    <Stack gap={4} sx={{ height: '100%', overflow: 'auto' }}>
      {_(yardBlockStore.entries)
        .orderBy(yardBlock => yardBlock.name)
        .value()
        .map(yardBlock => (
          <YardPlanningDashboardBlock
            key={`yardBlock_${yardBlock.id}`}
            yardBlock={yardBlock}
            bays={yardBlockBayStore.getForBlock(yardBlock.id)}
            rows={yardBlockRowStore.getForBlock(yardBlock.id)}
            stacks={yardBlockStackStore.getForBlock(yardBlock.id)}
          />
        ))}
    </Stack>
  )
})

export default YardPlanningDashboardBlocks
