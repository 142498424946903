import { FilterList, Search } from '@mui/icons-material'
import {
  Box,
  DialogActions,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  SxProps,
  Tooltip,
} from '@mui/material'
import { FieldValues, UseControllerProps } from 'react-hook-form'
import { ControlledTextField } from './form-controls'

interface Props<T extends FieldValues> extends UseControllerProps<T> {
  children?: JSX.Element[]
  actions?: JSX.Element
  toggleDropDown?: () => void
  placeholder: string
  filtersButtonTooltip?: string
  showDropDown?: boolean
  paperSx?: SxProps
}

export const AdvancedSearchField = <T extends FieldValues>({
  control,
  name,
  toggleDropDown,
  placeholder,
  filtersButtonTooltip,
  children,
  actions,
  showDropDown,
  paperSx,
}: Props<T>) => {
  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      <Paper
        elevation={5}
        sx={{
          display: 'flex',
          padding: 1.5,
          ...paperSx,
        }}
      >
        <ControlledTextField
          inputType='base'
          control={control}
          name={name}
          variant='standard'
          startAdornment={
            <InputAdornment position='start'>
              <Search />
            </InputAdornment>
          }
          placeholder={placeholder}
          fullWidth
          {...(toggleDropDown
            ? {
                endAdornment: (
                  <InputAdornment position='end'>
                    <Tooltip title={filtersButtonTooltip}>
                      <IconButton onClick={toggleDropDown}>
                        <FilterList />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }
            : {})}
        />
      </Paper>
      {showDropDown && (
        <Paper
          elevation={5}
          sx={{
            marginTop: '1px',
            position: 'absolute',
            zIndex: 999,
            padding: 1,
            width: 'inherit',
          }}
        >
          <Grid container direction='column'>
            <Grid
              item
              mb={2}
              p={1}
              sx={{
                maxHeight: 500,
                overflowY: 'auto',
              }}
            >
              {children}
            </Grid>
            <Grid item>
              <Grid container justifyContent='flex-end'>
                <DialogActions sx={{ padding: 0 }}>{actions}</DialogActions>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      )}
    </Box>
  )
}
