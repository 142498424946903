import { NonNumericOrderDto, NonNumericOrdersApi } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'
import { Event, IEvent, IQueryHandler, IQueryMessage } from '../messageBus'

export class GetNnrOrderByIdQuery implements IQueryMessage {
  static type = 'GetNnrOrderByIdQuery'
  type = GetNnrOrderByIdQuery.type

  constructor(public id: number) {}

  getKey = () => {
    return JSON.stringify(this)
  }
}

const httpClient = createApiClient(NonNumericOrdersApi)

export const getNnrOrderByIdQueryHandler: IQueryHandler<
  GetNnrOrderByIdQuery,
  IEvent<NonNumericOrderDto>
> = async query => {
  const { data } = await httpClient.getById(query.id)

  return new Event(GetNnrOrderByIdQuery.type, data)
}
