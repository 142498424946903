import { alpha, LinearProgressProps } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { COLORS } from '../palette'

// ----------------------------------------------------------------------

export default function Progress(theme: Theme) {
  const rootStyle = (ownerState: LinearProgressProps) => {
    const bufferVariant = ownerState.variant === 'buffer'

    const defaultStyle = {
      borderRadius: theme.customRadius.small,
      '& .MuiLinearProgress-bar': {
        borderRadius: theme.customRadius.small,
      },
      ...(bufferVariant && {
        backgroundColor: 'transparent',
      }),
    }

    const colorStyle = COLORS.map(color => ({
      ...(ownerState.color === color && {
        backgroundColor: alpha(theme.palette[color].main, 0.24),
      }),
    }))

    return [...colorStyle, defaultStyle]
  }

  return {
    MuiLinearProgress: {
      styleOverrides: {
        root: ({ ownerState }: { ownerState: LinearProgressProps }) => rootStyle(ownerState),
      },
    },
  }
}
