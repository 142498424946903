import { OperationType } from '@operations/app/api'
import IconDryContainer from '@operations/assets/icons/handlingInstructions/IconDryContainer'
import IconFlatRack from '@operations/assets/icons/handlingInstructions/IconFlatRack'
import IconHighCube from '@operations/assets/icons/handlingInstructions/IconHighCube'
import IconOutOfGauge from '@operations/assets/icons/handlingInstructions/IconOutOfGauge'
import IconRestowSvg from '@operations/assets/icons/handlingInstructions/IconRestowSvg'

import IconEmpty from '@operations/assets/icons/handlingInstructions/IconEmpty'
import IconFull from '@operations/assets/icons/handlingInstructions/IconFull'

import IconDangerous from '@operations/assets/icons/handlingInstructions/IconDangerous'
import IconHolds from '@operations/assets/icons/handlingInstructions/IconHolds'
import IconReefer from '@operations/assets/icons/handlingInstructions/IconReefer'
import { ExpandButtonWithSelect } from '@operations/components/expand-button-with-select/ExpandButtonWithSelect'
import { Sticky } from '@operations/components/sticky/Stick'
import { LoadingColors } from '@operations/features/craneOperator/utils'
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Stack,
  SxProps,
  Theme,
  Tooltip,
  Typography,
  styled,
} from '@mui/material'
import {
  ButtonOption,
  ChevronLeftIcon,
  ChevronRightIcon,
  IconButton,
  OptionsButton,
  useMinimalsTheme,
} from '@tom-ui/ui'
import { useTranslate } from '@tolgee/react'
import { useEffect, useState } from 'react'
import { LegendItem } from './LegentItem'
import { BayLegend, BayLegendOptions, BayLegendPortsToLoad } from './legend.model'

const getColorOptionsByLegend = (
  legendOptions: BayLegendOptions,
  theme: Theme,
  t: any,
  loadPorts?: BayLegendPortsToLoad[],
) => {
  const coloursLegend: BayLegend[] = []
  if (legendOptions.hasDischarge)
    coloursLegend.push({
      label: t('toBeDischarged', 'To be discharged'),
      component: <Square color={theme.palette.info.light} />,
    })

  if (legendOptions.hasLoading && loadPorts?.length)
    loadPorts.forEach(port => {
      coloursLegend.push({
        label: `${t('toBeLoaded', 'To be loaded')} (${port.name})`,
        component: <Square color={LoadingColors[port.index]} />,
      })
    })

  if (legendOptions.hasTransit)
    coloursLegend.push({
      label: t('transit', 'Transit'),
      component: <Square color={theme.palette.grey[300]} />,
    })

  if (legendOptions.hasOtherCrane)
    coloursLegend.push({
      label: t('otherCrane', 'Other crane'),
      component: <Square color={theme.palette.grey[700]} />,
    })

  return coloursLegend
}

const getTypeOptionsByLegend = (legendOptions: BayLegendOptions, theme: Theme, t: any) => {
  const typesLegend: BayLegend[] = []
  if (legendOptions.hasFull)
    typesLegend.push({
      label: t('full', 'Full'),
      component: <IconFull size={24} />,
    })

  if (legendOptions.hasEmpty)
    typesLegend.push({
      label: t('empty', 'Empty'),
      component: <IconEmpty size={24} />,
    })

  if (legendOptions.hasRestow)
    typesLegend.push({
      label: t('restow', 'Restow'),
      component: <IconRestowSvg size={24} />,
    })

  if (legendOptions.hasDangerous)
    typesLegend.push({
      label: t('dangerous', 'Dangerous'),
      component: <IconDangerous size={24} />,
    })

  if (legendOptions.hasReefer)
    typesLegend.push({
      label: t('reefer', 'Reefer'),
      component: <IconReefer size={24} />,
    })

  if (legendOptions.hasOutOfGauge)
    typesLegend.push({
      label: t('outOfGauge', 'Out of Gauge'),
      component: <IconOutOfGauge size={24} />,
    })

  if (legendOptions.hasHighCube)
    typesLegend.push({
      label: t('highCube', 'High Cube'),
      component: <IconHighCube size={24} />,
    })

  if (legendOptions.hasDryContainer)
    typesLegend.push({
      label: t('dryContainer', 'Dry Container'),
      component: <IconDryContainer size={24} />,
    })

  if (legendOptions.hasFlatRack)
    typesLegend.push({
      label: t('flatRack', 'Flat Rack'),
      component: <IconFlatRack size={24} />,
    })

  if (legendOptions.hasActiveHolds)
    typesLegend.push({
      label: t('hold', 'Hold'),
      component: <IconHolds size={24} />,
    })

  return typesLegend
}

const Square = styled(Box)<{ color: string }>(({ color }) => ({
  width: '24px',
  height: '24px',
  backgroundColor: color,
}))

interface Props {
  legendOptions: BayLegendOptions
  bays?: number[]
  currentBay?: number
  loadPorts?: BayLegendPortsToLoad[]
  operationType?: OperationType
  availableOperationTypes?: OperationType[]
  hasNext?: boolean
  hasPrevious?: boolean
  onBayChange?: (bayNumber: number) => void
  onNext?: () => void
  onPrevious?: () => void
  onOperationTypeChange?: (operation: OperationType) => void
  onOpenOverview?: () => void
}

export const LegendHeader = ({
  legendOptions,
  bays,
  currentBay,
  loadPorts,
  operationType,
  availableOperationTypes,
  hasNext,
  hasPrevious,
  onBayChange,
  onNext,
  onPrevious,
  onOperationTypeChange,
  onOpenOverview,
}: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const [stickyStyling, setStickyStyling] = useState<SxProps | undefined>()
  const [headerHeight, setHeaderHeight] = useState<number>(0)
  const [displayLegend, setDisplayLegend] = useState(true)

  useEffect(() => {
    const fixingStickyBayHeaderPosition = () => {
      const mainElement = document.querySelector(`main`)
      let top = '0px'
      let maxWidth = '0px'

      if (mainElement) {
        top = mainElement.style.paddingTop
        maxWidth = mainElement.style.marginLeft
      }

      setStickyStyling({
        maxWidth: `calc(100% - ${maxWidth}) !important`,
        top: `calc(0px + ${top}) !important`,
      })
    }

    fixingStickyBayHeaderPosition()
  }, [])

  const options: ButtonOption[] = [
    displayLegend
      ? {
          label: t('hideLegend', 'Hide Legend'),
          onClick: () => {
            setDisplayLegend(false)
          },
        }
      : {
          label: t('showLegend', 'Show Legend'),
          onClick: () => {
            setDisplayLegend(true)
          },
        },
  ]

  if (onOpenOverview && !!currentBay) {
    options.push({
      label: t('seeOverview', 'See overview'),
      onClick: () => {
        onOpenOverview()
      },
    })
  }

  const coloursLegend: BayLegend[] = getColorOptionsByLegend(legendOptions, theme, t, loadPorts)

  const typesLegend: BayLegend[] = getTypeOptionsByLegend(legendOptions, theme, t)

  useEffect(() => {
    const legendHeaderHeight = document
      .querySelector(`#legend-header`)
      ?.getBoundingClientRect().height
    if (legendHeaderHeight) {
      setHeaderHeight(legendHeaderHeight)
    }
  }, [coloursLegend.length, displayLegend])

  return (
    <Box height={headerHeight}>
      <Sticky overrideStickStyle={stickyStyling}>
        <Paper
          elevation={3}
          sx={{ height: '100%', backgroundColor: 'white', borderRadius: 0, padding: '1rem' }}
          id='legend-header'
        >
          <Stack flexDirection='row' justifyContent='space-between' paddingBottom={2}>
            {!currentBay && <Typography variant='h3'>{t('bayView', 'Bay view')}</Typography>}
            {!!currentBay && (
              <Stack
                flexDirection='row'
                justifyContent='space-between'
                width='100%'
                alignItems='center'
                paddingRight={2}
              >
                <Stack flexDirection='row' gap={1} alignItems='center'>
                  <Tooltip title={t('goToPreviousBay', 'Got to previous bay')}>
                    <IconButton
                      size='small'
                      onClick={() => {
                        if (onPrevious) onPrevious()
                      }}
                      disabled={!hasPrevious}
                    >
                      <ChevronLeftIcon fontSize='small' />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title={t('goToNextBay', 'Got to next bay')}>
                    <IconButton
                      size='small'
                      onClick={() => {
                        if (onNext) onNext()
                      }}
                      disabled={!hasNext}
                    >
                      <ChevronRightIcon fontSize='small' />
                    </IconButton>
                  </Tooltip>

                  <Typography variant='h3'>
                    {t('bay', 'Bay')} {currentBay}
                  </Typography>

                  {bays && bays.length > 0 && onBayChange && (
                    <ExpandButtonWithSelect
                      tooltip={t('selectBay', 'Select bay')}
                      selectedOption={currentBay}
                      options={bays.map(x => ({
                        label: `${t('bay', 'Bay')} ${x}`,
                        value: x,
                      }))}
                      onChange={option => onBayChange(+option.value)}
                    />
                  )}
                </Stack>

                {onOperationTypeChange && (
                  <FormControl sx={{ minWidth: '10rem' }}>
                    <Select
                      value={operationType ?? OperationType.Inbound}
                      size='small'
                      onChange={event => onOperationTypeChange(event.target.value as OperationType)}
                    >
                      {availableOperationTypes?.find(a => a === OperationType.Inbound) && (
                        <MenuItem key='discharge' value={OperationType.Inbound}>
                          {t('discharge')}
                        </MenuItem>
                      )}
                      {availableOperationTypes?.find(a => a === OperationType.Outbound) && (
                        <MenuItem key='load' value={OperationType.Outbound}>
                          {t('load')}
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                )}
              </Stack>
            )}
            <OptionsButton tooltip={t('moreOptions', 'More options')} options={options} />
          </Stack>

          {displayLegend && (
            <Stack flexDirection='row' gap={4}>
              <Stack>
                <Typography variant='caption' sx={{ paddingBottom: '4px' }}>
                  {t('colours', 'Colours')}
                </Typography>
                <Grid container spacing={2}>
                  {coloursLegend.map(option => (
                    <Grid key={option.label} item xs={coloursLegend.length > 3 ? 6 : 12}>
                      <LegendItem title={option.label}>{option.component}</LegendItem>
                    </Grid>
                  ))}
                </Grid>
              </Stack>

              <Stack width='100%'>
                <Typography variant='caption' sx={{ paddingBottom: '4px' }}>
                  {t('types', 'Types')}
                </Typography>
                <Grid container spacing={2}>
                  {typesLegend.map(option => (
                    <Grid
                      key={option.label}
                      item
                      xs={coloursLegend.length <= 3 || typesLegend?.length <= 4 ? 3 : 4}
                    >
                      <LegendItem title={option.label}>{option.component}</LegendItem>
                    </Grid>
                  ))}
                </Grid>
              </Stack>
            </Stack>
          )}
        </Paper>
      </Sticky>
    </Box>
  )
}
