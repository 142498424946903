import { FormType } from '@planning/constants'
import { makeAutoObservable } from 'mobx'

export type DialogTitles = Record<FormType, string>

export class FormDialogUtilStore {
  dialogAction: FormType = 'Add'
  isDialogOpen = false
  dialogEntityId?: number

  constructor() {
    makeAutoObservable(this)
  }

  toggleDialog(action?: FormType, dialogEntityId?: number) {
    if (action) {
      this.dialogAction = action
    }
    this.dialogEntityId = dialogEntityId
    this.isDialogOpen = !this.isDialogOpen
  }
}
