import { Box } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { AdvancedFilter } from '@tom-ui/ui'
import { useRef, useState } from 'react'
import { FormProps } from 'react-router-dom'
import { SearchInput } from './SearchInput'

interface Props {
  form?: React.ReactElement<FormProps>
  label: string
  onSearch: (value: string) => void
  onReset?: () => void
  dataCy?: string
}

export const SearchWithFilter = ({ form, label, onSearch, onReset, dataCy }: Props) => {
  const { t } = useTranslate()
  const [search, setSearch] = useState<string>('')
  const [open, setOpen] = useState(false)
  const searchRef = useRef<HTMLDivElement>(null)

  const handleSearch = (value: string) => {
    setSearch(value)
    onSearch(value)
  }

  const handleClear = () => {
    setSearch('')
    onSearch('')
  }

  const openFilter = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleFilterEmpty = () => {
    handleClear()
    handleClose()
  }

  const handleReset = () => {
    if (onReset) {
      onReset()
    }

    handleFilterEmpty()
  }

  return (
    <Box position='relative'>
      <SearchInput
        searchRef={searchRef}
        search={search}
        label={label}
        showFilter={!!form}
        onSearch={handleSearch}
        onClearSearch={handleClear}
        dataCy={dataCy}
        advancedFilter={
          form && (
            <AdvancedFilter
              open={open}
              formId={form?.props.id}
              filterTooltip={t('advancedFilter', 'Advanced filter')}
              onOpenFilter={openFilter}
              onClose={handleClose}
              filterActionLabel={t('apply', 'Apply')}
              resetActionLabel={t('reset', 'Reset')}
              onFilter={handleFilterEmpty}
              onReset={handleReset}
            >
              {form}
            </AdvancedFilter>
          )
        }
      />
    </Box>
  )
}
