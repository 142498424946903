import { NavigateNext } from '@mui/icons-material'
import { Alert, Box, Button, Card, Divider, IconButton, Tooltip, Typography } from '@mui/material'
import { CarrierVisitDirection, CarrierVisitStatus } from '@storage/app/api'
import { LabelBox } from '@storage/components/LabelBox'
import StackedProgressBar from '@storage/components/StackedProgressBar'
import { useStores } from '@storage/hooks/use-stores.hook'
import { CarrierVisitArrivalStatusStore } from '@storage/pages/yard-planning-dashboard-details/stores'
import { CarrierVisitInfo, CarrierVisitStore } from '@storage/stores/carrier-visit.store'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import { VesselVisitHeader } from '../../../components/VesselVisit/VesselVisitHeader'
interface CarrierVisitSummaryProps {
  carrierVisitInfo: CarrierVisitInfo
  onPlan?: (
    vesselVisitId: number,
    handlingDirection: CarrierVisitDirection,
    discriminator: string,
  ) => void
  onActionNavigate?: (vesselVisitId: number, discriminator: string) => void
}

export const CarrierVisitSummary = observer(
  ({ carrierVisitInfo, onPlan, onActionNavigate }: CarrierVisitSummaryProps) => {
    const { t } = useTranslate()
    const { palette } = useMinimalsTheme()

    const { carrierVisitStore, issueStore } = useStores()

    const carrierVisitArrivalStatusStore = useMemo(() => new CarrierVisitArrivalStatusStore(), [])

    useEffect(() => {
      const resolveCarrierArrival = () => {
        carrierVisitArrivalStatusStore.resolveVesselArrival(carrierVisitInfo)
      }
      resolveCarrierArrival()
    }, [carrierVisitInfo, carrierVisitArrivalStatusStore])

    useEffect(() => {
      const loadCarrierVisitSummary = async () => {
        await carrierVisitStore.loadCarrierVisitSummary(carrierVisitInfo.id)
      }

      const loadCarrierVisitIssues = () => carrierVisitStore.loadCarrierVisitsIssues()

      loadCarrierVisitSummary()
      loadCarrierVisitIssues()
    }, [carrierVisitStore, carrierVisitInfo.id, issueStore])

    return (
      <Card>
        {carrierVisitInfo.summary && (
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <VesselVisitHeader
              name={carrierVisitInfo.name}
              discriminator={carrierVisitInfo.discriminator}
              arrivalLabel={carrierVisitArrivalStatusStore.arrivalLabel}
              arrivalValue={carrierVisitArrivalStatusStore.arrivalValue}
            />
            <Divider variant='fullWidth' />

            {(CarrierVisitStore.isLoadingDataNotEmpty(carrierVisitInfo.summary) ||
              CarrierVisitStore.isDischargeDataNotEmpty(carrierVisitInfo.summary)) && (
              <>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }} px={3} py={2}>
                  {CarrierVisitStore.isLoadingDataNotEmpty(carrierVisitInfo.summary) && (
                    <>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <LabelBox label={t('load', 'Load')} />

                        <Button
                          color='primary'
                          onClick={() =>
                            onPlan?.(
                              carrierVisitInfo.id,
                              CarrierVisitDirection.Outbound,
                              carrierVisitInfo.discriminator,
                            )
                          }
                          data-cy='carrier-visit-loading-button'
                        >
                          {t('plan', 'Plan')}
                        </Button>
                      </Box>
                      {carrierVisitInfo.status === CarrierVisitStatus.InOperation ? (
                        <Alert
                          severity={carrierVisitInfo.summary.inYardLoad > 0 ? 'info' : 'success'}
                        >
                          {t('containersToBeLoaded', '{n} containers remaining', {
                            n: carrierVisitInfo.summary.inYardLoad,
                          })}
                        </Alert>
                      ) : (
                        <StackedProgressBar
                          elements={[
                            {
                              label: t('stacked', 'Stacked'),
                              value: carrierVisitInfo.summary.inYardLoad,
                              color: palette.primary.main,
                            },
                            {
                              label: t('planned', 'Planned'),
                              value: carrierVisitInfo.summary.plannedLoad,
                              color: palette.info.main,
                            },

                            {
                              label: t('unplanned', 'Unplanned'),
                              value: carrierVisitInfo.summary.unplannedLoad,
                              color: palette.grey[300],
                            },
                          ]}
                        />
                      )}
                    </>
                  )}

                  {CarrierVisitStore.isDischargeDataNotEmpty(carrierVisitInfo.summary) && (
                    <>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <LabelBox label={t('discharge', 'Discharge')} />
                        <Button
                          color='primary'
                          onClick={() =>
                            onPlan?.(
                              carrierVisitInfo.id,
                              CarrierVisitDirection.Inbound,
                              carrierVisitInfo.discriminator,
                            )
                          }
                          data-cy='carrier-visit-discharge-button'
                        >
                          {t('plan', 'Plan')}
                        </Button>
                      </Box>
                      <StackedProgressBar
                        elements={
                          carrierVisitInfo.status === CarrierVisitStatus.InOperation
                            ? [
                                {
                                  label: t('stacked', 'Stacked'),
                                  value: carrierVisitInfo.summary.inYardDischarge,
                                  color: palette.primary.main,
                                },
                                {
                                  label: t('inProgress', 'In Progress'),
                                  value:
                                    carrierVisitInfo.summary.plannedDischarge +
                                    carrierVisitInfo.summary.unplannedDischarge,
                                  color: palette.grey[300],
                                },
                              ]
                            : [
                                {
                                  label: t('planned', 'Planned'),
                                  value: carrierVisitInfo.summary.plannedDischarge,
                                  color: palette.info.main,
                                },
                                {
                                  label: t('unplanned', 'Unplanned'),
                                  value: carrierVisitInfo.summary.unplannedDischarge,
                                  color: palette.grey[300],
                                },
                              ]
                        }
                      />
                    </>
                  )}
                </Box>
              </>
            )}
          </Box>
        )}

        {CarrierVisitStore.isDischargeAndLoadingEmpty(carrierVisitInfo.summary) &&
          carrierVisitInfo.status !== CarrierVisitStatus.InOperation && (
            <Box
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              px={3}
              py={1}
            >
              <Typography>{t('noContainersToBePlanned', 'No containers to be planned')}</Typography>
              <Tooltip title={t('addContainersNow', 'Add Containers Now')}>
                <IconButton
                  onClick={() =>
                    onActionNavigate?.(carrierVisitInfo.id, carrierVisitInfo.discriminator)
                  }
                >
                  <NavigateNext />
                </IconButton>
              </Tooltip>
            </Box>
          )}
      </Card>
    )
  },
)
