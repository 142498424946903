import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
  debounce,
} from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { CargoType, UpdateVesselVisitCommand, VesselDto, VesselVisitDto } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { CustomDateTimePicker } from '@planning/components/CustomDateTimePicker'
import { CargoTypeSelect } from '@planning/components/visit/CargoTypeSelect'
import vesselService from '@planning/services/vesselService'
import vesselVisitService from '@planning/services/vesselVisitService'
import { useTranslate } from '@tolgee/react'
import { BinIcon } from '@tom-ui/ui'
import dayjs, { Dayjs } from 'dayjs'
import { ChangeEvent, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

interface Props {
  vesselVisit: VesselVisitDto | undefined
  vessel: VesselDto[] | undefined
  onClose?: () => void
  showAlert?: (severity: 'success' | 'info' | 'warning' | 'error', message: string) => void
}

export interface IUpdateVesselVisitFormData {
  id: number
  eta: Dayjs | null
  etb: Dayjs | null
  etd: Dayjs | null
  vesselIds: number[]
  importVoyage: string | null
  exportVoyage: string | null
  importDraft: number | null
  exportDraft: number | null
  dischargeEstimate: number | null
  loadEstimate: number | null
  cargoType: CargoType
}

export const EditVesselVisitForm = ({ vesselVisit, vessel, onClose, showAlert }: Props) => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: vesselVisit?.id,
      vesselIds: vesselVisit?.carrierIds,
      eta: vesselVisit?.eta ?? null,
      etb: vesselVisit?.etb ?? null,
      etd: vesselVisit?.etd ?? null,
      importDraft: vesselVisit?.importDraft === 0 ? null : vesselVisit?.importDraft,
      exportDraft: vesselVisit?.exportDraft === 0 ? null : vesselVisit?.exportDraft,
      dischargeEstimate:
        vesselVisit?.dischargeEstimate === 0 ? null : vesselVisit?.dischargeEstimate,
      loadEstimate: vesselVisit?.loadEstimate === 0 ? null : vesselVisit?.loadEstimate,
      cargoType: vesselVisit?.cargoType,
    } as IUpdateVesselVisitFormData,
  })
  const { t } = useTranslate()
  const { tenantStore } = usePlanningStore()

  const onFormSubmit = async (data: IUpdateVesselVisitFormData) => {
    const updatedVesselVisit = {
      ...data,
      inboundTripIds: tripIdsInputs?.flatMap(obj => [obj.inbound]),
      outboundTripIds: tripIdsInputs?.flatMap(obj => [obj.outbound]),
    } as UpdateVesselVisitCommand
    try {
      await vesselVisitService.put(updatedVesselVisit)
      showAlert?.(
        'success',
        t('vesselVisitUpdatedSuccessfully', 'Vessel Visit updated successfully'),
      )
      onClose?.()
    } catch (error) {
      showAlert?.('error', t('failedToSave', 'Failed to save'))
    }
  }

  const handleDateChange = (newValue: dayjs.Dayjs | null, onChange: any) => {
    if (newValue) {
      onChange(newValue.toISOString())
    }
  }

  const buildStateForTripIds = (vesselVisit: VesselVisitDto | undefined) => {
    if (vesselVisit && vesselVisit.inboundTripIds && vesselVisit.outboundTripIds) {
      const output = []
      for (let i = 0; i < vesselVisit.inboundTripIds.length; i++) {
        const id = i + 1
        const inbound = vesselVisit.inboundTripIds[i]
        const outbound = vesselVisit.outboundTripIds[i]
        output.push({ id, inbound, outbound })
      }

      return output.length ? output : [{ id: 1, inbound: '', outbound: '' }]
    }
  }

  const [tripIdsInputs, setTripIdsInputs] = useState(buildStateForTripIds(vesselVisit))

  const duplicateTripIdsInputs = () => {
    if (tripIdsInputs) {
      const newId = Math.max(...tripIdsInputs.map(ti => ti.id), 0) + 1
      setTripIdsInputs([...tripIdsInputs, { id: newId, inbound: '', outbound: '' }])
    }
  }

  const removeTripIds = (idToRemove: number) => {
    if (tripIdsInputs && tripIdsInputs.length === 1) {
      setTripIdsInputs([{ id: 1, inbound: '', outbound: '' }])
    } else {
      const filteredInputs = tripIdsInputs?.filter(input => input.id !== idToRemove)
      setTripIdsInputs(filteredInputs)
    }
  }

  const handleTripIdInputChanges = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    id: number,
    type: string,
  ) => {
    const { value } = event.target
    const updatedTripIds = tripIdsInputs?.map(tripId => {
      if (tripId.id === id) {
        return { ...tripId, [type]: value }
      }
      return tripId
    })

    setTripIdsInputs(updatedTripIds)
  }

  const OnSearchFilterChange = debounce(async (filter?: string) => {
    setOptions((await vesselService.get(1, 10, filter)).payload)
  }, 500)

  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState<readonly VesselDto[]>([])

  useEffect(() => {
    if (open) {
      const fetch = async () => {
        return (await vesselService.get(1, 10)).payload
      }

      fetch().then(payload => setOptions(payload))
    } else {
      setOptions([])
    }
  }, [open])

  return (
    <>
      {vesselVisit && (
        <form id='edit-vessel-visit' onSubmit={handleSubmit(onFormSubmit)}>
          <Controller
            control={control}
            name={`vesselIds`}
            rules={{ required: true }}
            render={({ field: { onChange } }) => (
              <Autocomplete
                sx={{ mb: '1rem' }}
                disablePortal
                multiple
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                options={options}
                getOptionLabel={(vessel: any) =>
                  vessel && vessel.data !== undefined ? vessel?.data.name : vessel.name
                }
                defaultValue={vessel}
                isOptionEqualToValue={(option: VesselDto, value: VesselDto) =>
                  option.id === value.id
                }
                onChange={(_, selectedVessel: VesselDto[] | null) => {
                  onChange(selectedVessel?.map(v => v.id) ?? -1)
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={t('vessel', 'Vessel')}
                    helperText={errors.vesselIds ? t('fieldIsRequired', 'Field is required.') : ''}
                    error={!!errors.vesselIds}
                    onChange={e => OnSearchFilterChange(e.target.value)}
                  />
                )}
              />
            )}
          />
          <Controller
            control={control}
            name={`cargoType`}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <CargoTypeSelect disabled value={value} onChange={onChange} />
            )}
          />
          <Typography variant='subtitle1' marginTop='1rem' marginBottom='0.5rem'>
            {t('timingsEstimated', 'Timings (Estimated)')}
          </Typography>
          <Grid container direction='row' columnSpacing={{ xs: 1 }}>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  control={control}
                  name={`eta`}
                  render={({ field: { onChange, value } }) => (
                    <CustomDateTimePicker
                      label={t('eta', 'ETA')}
                      value={value?.toString() ?? null}
                      popperPlacement='left-start'
                      onChange={newValue => handleDateChange(newValue, onChange)}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            {!tenantStore.skipBerthTimestamp && (
              <Grid item xs={12} mt={'1rem'}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Controller
                    control={control}
                    name={`etb`}
                    render={({ field: { onChange, value } }) => (
                      <CustomDateTimePicker
                        label={t('etb', 'ETB')}
                        value={value?.toString() ?? null}
                        popperPlacement='left-start'
                        onChange={newValue => handleDateChange(newValue, onChange)}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            )}
            <Grid item xs={12} mt={'1rem'}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  control={control}
                  name={`etd`}
                  render={({ field: { onChange, value } }) => (
                    <CustomDateTimePicker
                      label={t('etd', 'ETD')}
                      value={value?.toString() ?? null}
                      popperPlacement='left-start'
                      onChange={newValue => handleDateChange(newValue, onChange)}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>

          <Typography variant='subtitle1' marginTop='1rem' marginBottom='0.5rem'>
            {t('tripIds', 'Trip IDs')}
          </Typography>
          <Grid container>
            {tripIdsInputs?.map((input, index) => (
              <Grid key={index} container direction='row' columnSpacing={{ xs: 1 }} mb={'.5rem'}>
                <Grid item xs={5}>
                  <TextField
                    size='small'
                    fullWidth
                    value={input.inbound}
                    label={t('inboundTripId', 'Inbound Trip ID')}
                    variant='outlined'
                    name='importVoyage'
                    onChange={event => handleTripIdInputChanges(event, input.id, 'inbound')}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    size='small'
                    fullWidth
                    value={input.outbound}
                    label={t('outboundTripId', 'Outbound Trip ID')}
                    variant='outlined'
                    name='exportVoyage'
                    onChange={event => handleTripIdInputChanges(event, input.id, 'outbound')}
                  />
                </Grid>
                <Grid item xs={2}>
                  <IconButton color='error' onClick={() => removeTripIds(input.id)}>
                    <BinIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}

            <Grid item mt={'.5rem'}>
              <Button size='small' onClick={duplicateTripIdsInputs}>
                + Add
              </Button>
            </Grid>
          </Grid>
          <Typography variant='subtitle1' marginTop='1rem' marginBottom='0.5rem'>
            {t('vesselDrafts', 'Vessel Drafts')}
          </Typography>
          <Grid container direction='row' columnSpacing={{ xs: 1 }}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type='number'
                defaultValue={vesselVisit.importDraft}
                data-cy='edit-vessel-visit-form-import-draft-field'
                inputProps={{
                  min: 0.1,
                  max: 13,
                  step: '0.01',
                }}
                label={t('importDraftM', 'Import Draft (m)')}
                variant='outlined'
                {...register('importDraft')}
                name='importDraft'
                onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type='number'
                defaultValue={vesselVisit.exportDraft}
                data-cy='edit-vessel-visit-form-export-draft-field'
                inputProps={{
                  min: 0.1,
                  max: 13,
                  step: '0.01',
                }}
                label={t('exportDraftM', 'Export Draft (m)')}
                variant='outlined'
                {...register('exportDraft')}
                name='exportDraft'
                onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
              />
            </Grid>
          </Grid>
          <Typography variant='subtitle1' marginTop='1rem' marginBottom='0.5rem'>
            {t('dischargeEstimates', 'Discharge Estimates')}
          </Typography>
          <TextField
            fullWidth
            type='number'
            defaultValue={vesselVisit.dischargeEstimate}
            label={t('dischargeEstimate', 'Discharge Estimate')}
            data-cy='edit-vessel-visit-form-discharge-estimate-field'
            variant='outlined'
            {...register('dischargeEstimate')}
            name='dischargeEstimate'
            onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
            inputProps={{ min: 1 }}
          />
          <Typography variant='subtitle1' marginTop='1rem' marginBottom='0.5rem'>
            {t('loadEstimates', 'Load Estimates')}
          </Typography>
          <TextField
            fullWidth
            type='number'
            defaultValue={vesselVisit.loadEstimate}
            label={t('loadEstimate', 'Load Estimate')}
            data-cy='edit-vessel-visit-form-load-estimate-field'
            variant='outlined'
            {...register('loadEstimate')}
            name='loadEstimate'
            onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
            inputProps={{ min: 1 }}
          />
        </form>
      )}
    </>
  )
}
