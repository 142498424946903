import { Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { Controller, useFormContext } from 'react-hook-form'
import { CargoTypeSelectChip } from './CargoTypeSelectChip'

interface Props {
  isEditMode?: boolean
}
export const CargoTypeField = observer(({ isEditMode }: Props) => {
  const { t } = useTranslate()

  const { control } = useFormContext()

  return (
    <>
      <Typography variant='subtitle1' marginTop='1rem' marginBottom='0.5rem'>
        {t('cargoType', 'Cargo Type')}
      </Typography>

      <Controller
        control={control}
        name={`cargoType`}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <CargoTypeSelectChip disabled={isEditMode} value={value} onChange={onChange} />
        )}
      />
    </>
  )
})
