export interface UserFormProfile {
  id?: string
  email: string
  firstName: string
  lastName: string
  tenantId?: number | string
}

export const defaultValues: UserFormProfile = {
  email: '',
  firstName: '',
  lastName: '',
  tenantId: '',
}
