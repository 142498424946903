import { TruckDto, TruckVisitDto, VesselDto, VesselVisitDto } from '@planning/app/api'
import {
  truckService,
  truckVisitService,
  vesselService,
  vesselVisitService,
} from '@planning/services'
import { ILocalDataStore } from '@planning/stores/PaginatedLocalStore'
import _ from 'lodash'
import { computed, makeObservable, observable, reaction, runInAction } from 'mobx'
import { IOrderItem } from '../order/OrderItem'
import { ITruckItem } from '../truck/TruckItem'
import { ITruckVisitItem } from '../truckVisit/TruckVisitItem'
import { IVesselItem } from '../vessel/VesselItem'
import { IVesselVisitItem } from '../vesselVisit/VesselVisitItem'
import { NnrOrderItemStore } from './NnrOrderItemStore'

export class NnrOrderLocalStore implements ILocalDataStore<IOrderItem> {
  items: IOrderItem[] = []
  trucks: TruckDto[] = []
  vessels: VesselDto[] = []
  vesselVisits: VesselVisitDto[] = []
  truckVisits: TruckVisitDto[] = []

  constructor(
    private itemStore: NnrOrderItemStore,
    private nnrOrderId: number,
  ) {
    makeObservable(this, {
      items: observable,
      nnrOrder: computed,
    })

    // TODO: using the ItemStore to fetch carrierVisits leads to cascade of be calls (returning unneeded data)
    // Either do not use ItemStore here, or make ItemStore "smarter"
    reaction(
      () => this.nnrOrder?.orders,
      () => this.fetch(),
    )
  }

  get nnrOrder() {
    return this.itemStore.elements[this.nnrOrderId]
  }

  getVesselVisits = async (ids?: number[]) => {
    if (!ids?.length) return []

    const idsToFetch = ids.filter(id => !this.vesselVisits.some(vv => vv.id === id))

    if (idsToFetch.length) {
      const { data } = await vesselVisitService.getByIds(idsToFetch)
      this.vesselVisits = [...this.vesselVisits, ...data]
    }
    return this.vesselVisits.filter(vv => ids.includes(vv.id))
  }

  getTruckVisits = async (ids?: number[]) => {
    if (!ids?.length) return []

    const idsToFetch = ids.filter(id => !this.truckVisits.some(tv => tv.id === id))

    if (idsToFetch.length) {
      const { data } = await truckVisitService.getByIds(idsToFetch)
      this.truckVisits = [...this.truckVisits, ...data]
    }
    return this.truckVisits.filter(tv => ids.includes(tv.id))
  }

  createVisitItem = (visitDto: TruckVisitDto | VesselVisitDto) =>
    'importVoyage' in visitDto
      ? ({
          id: visitDto?.id,
          data: visitDto,
          vessels: [
            {
              data: this.vessels.find(t => visitDto?.carrierIds.includes(t.id)),
            },
          ] as IVesselItem[],
        } as IVesselVisitItem)
      : ({
          id: visitDto?.id,
          data: visitDto,
          truck: {
            data: this.trucks.find(t => visitDto?.carrierIds.includes(t.id)),
          } as ITruckItem,
        } as ITruckVisitItem)

  getOrdersWithVisitData = async () => {
    if (!this.nnrOrder?.orders.length) return []

    const orders = this.nnrOrder.orders

    const carrierVisitIdsByType = _(orders)
      .filter(o => !!o.data.carrierVisitId)
      .groupBy(o => o.data.carrierVisitType)
      .mapValues(o => o.map(o => o.data.carrierVisitId!))
      .value()

    const truckVisits = await this.getTruckVisits(carrierVisitIdsByType['Truck'])
    const vesselVisits = await this.getVesselVisits(carrierVisitIdsByType['Vessel'])
    await this.fetchTrucks()
    await this.fetchVessels()

    return orders.map(o => {
      if (!o.data.carrierVisitId) return o

      const isTruckVisit = o.data.carrierVisitType === 'Truck'
      const visitDto = isTruckVisit
        ? truckVisits.find(v => v.id === o.data.carrierVisitId)
        : vesselVisits.find(v => v.id === o.data.carrierVisitId)

      if (!visitDto) return o

      const visitItem = this.createVisitItem(visitDto)

      return {
        ...o,
        visit: visitItem,
      } as IOrderItem
    })
  }

  fetch = async () => {
    await this.itemStore.fetchById(this.nnrOrderId)

    const items = await this.getOrdersWithVisitData()
    runInAction(() => {
      this.items = items
    })
  }

  fetchTrucks = async () => {
    if (!this.trucks.length) {
      const { payload: trucks } = await truckService.get(1, 1000)
      this.trucks = trucks
    }
  }

  fetchVessels = async () => {
    if (!this.vessels.length) {
      const { payload: vessels } = await vesselService.get(1, 1000)
      this.vessels = vessels
    }
  }
}
