import { createSvgIcon } from '@mui/material'

export const VisitIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='18' viewBox='0 0 20 18' fill='none'>
    <path
      d='M12.3231 7.44204V3.31189C12.3231 2.96308 12.0377 2.6777 11.6889 2.6777H6.13974V0.632437C6.13974 0.378761 5.98516 0.148867 5.7513 0.0497754C5.51745 -0.0493166 5.24395 0.0022112 5.06162 0.180577L0.190257 4.92114C0.0673826 5.04005 0 5.20256 0 5.37696C0 5.55137 0.0673826 5.71388 0.190257 5.83279L5.06162 10.5773C5.18053 10.6962 5.34304 10.7557 5.50555 10.7557C5.58879 10.7557 5.67203 10.7398 5.7513 10.7042C5.98516 10.6051 6.13974 10.3752 6.13974 10.1215V8.07623H11.6889C12.0377 8.07623 12.3231 7.79085 12.3231 7.44204Z'
      fill='currentColor'
    />
    <path
      d='M19.8086 12.1311L14.9372 7.38654C14.7549 7.20817 14.4814 7.15665 14.2476 7.25574C14.0137 7.35483 13.8591 7.58472 13.8591 7.8384V9.88366H8.30997C7.96117 9.88366 7.67578 10.169 7.67578 10.5178V14.648C7.67578 14.9968 7.96117 15.2822 8.30997 15.2822H13.8591V17.3275C13.8591 17.5811 14.0137 17.811 14.2476 17.9101C14.3268 17.9418 14.4101 17.9616 14.4933 17.9616C14.6558 17.9616 14.8144 17.8982 14.9372 17.7833L19.8086 13.0388C19.9315 12.9198 19.9989 12.7573 19.9989 12.5829C19.9989 12.4085 19.9315 12.25 19.8086 12.1311Z'
      fill='currentColor'
    />
  </svg>,
  'VisitIcon',
)
