import { useBillingServiceStore } from '@billing/AppProvider'
import ReportStep from '@billing/app/models/report-step.enum'
import { Button, Stack } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'

interface Props {
  disableNext?: boolean
  disableDownload?: boolean
  downloadAction?: () => void
  finishAction?: () => void
}

export const ReportNavigationButtonContainer = observer(
  ({ disableNext, disableDownload, downloadAction, finishAction }: Props) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()

    const { reportsUIStore } = useBillingServiceStore()

    return (
      <Stack direction='row' paddingY={theme.customSpacing.xl} gap={theme.customSpacing.m}>
        {(reportsUIStore.currentStep === ReportStep.SelectADataType ||
          reportsUIStore.currentStep === ReportStep.Filter) && (
          <Button
            color='primary'
            variant='contained'
            onClick={() =>
              disableNext
                ? reportsUIStore.setNextButtonWasBlocked(true)
                : reportsUIStore.setNextStep()
            }
          >
            {t('next', 'Next')}
          </Button>
        )}
        {reportsUIStore.currentStep === ReportStep.Report && (
          <>
            <Button
              color='primary'
              variant='contained'
              onClick={() => downloadAction?.()}
              disabled={disableDownload}
            >
              {t('download', 'Download')}
            </Button>

            <Button color='secondary' variant='contained' onClick={() => finishAction?.()}>
              {t('finish', 'Finish')}
            </Button>
          </>
        )}
        {(reportsUIStore.currentStep === ReportStep.Filter ||
          reportsUIStore.currentStep === ReportStep.Report) && (
          <Button color='inherit' variant='text' onClick={() => reportsUIStore.setPreviousStep()}>
            {t('back', 'Back')}
          </Button>
        )}
      </Stack>
    )
  },
)
