import { Shuffle } from '@mui/icons-material'
import { Typography, Button } from '@mui/material'
import { Stack } from '@mui/system'
import { useTranslate } from '@tolgee/react'

interface CarrierVisitAllocationRulesContainerHeaderProps {
  onClick: () => void
}
const CarrierVisitAllocationRulesContainerHeader = ({
  onClick,
}: CarrierVisitAllocationRulesContainerHeaderProps) => {
  const { t } = useTranslate()
  return (
    <Stack mx={2} my={1} gap={1} direction='row' alignItems='center' justifyContent='space-between'>
      <Stack direction='row' gap={1}>
        <Shuffle />
        <Typography variant='subtitle1'>{t('allocationRules', 'Allocation rules')}</Typography>
      </Stack>
      <Button onClick={onClick} variant='contained' color='inherit'>
        {t('library', 'Library')}
      </Button>
    </Stack>
  )
}

export default CarrierVisitAllocationRulesContainerHeader
