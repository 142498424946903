import { tolgee } from '@storage/app/translation'
import * as yup from 'yup'
import { BerthFormProfile } from './berth-form.profile'
import IsUniqueValidator from './is-unique.validator'

export const schema: yup.ObjectSchema<BerthFormProfile> = yup.object({
  id: yup.string().optional(),
  name: yup
    .string()
    .label('Name')
    .emptyAsUndefined()
    .test(IsUniqueValidator.name, IsUniqueValidator.errorMessage, async (name, context) => {
      const { id } = context.parent as BerthFormProfile

      return await IsUniqueValidator.validate({ id, name })
    })
    .nonNullable()
    .required(),
  maxDraft: yup
    .number()
    .label(tolgee.t('maxDraft', 'Max draft'))
    .emptyAsUndefined()
    .min(1)
    .optional(),
  pierId: yup.string().label('Pier').emptyAsUndefined().required(),
  quayMeterMarkFrom: yup
    .number()
    .label(tolgee.t('quayMeterMarkFrom', 'Quay metermark from'))
    .emptyAsUndefined()
    .min(1)
    .required(),
  quayMeterMarkTo: yup
    .number()
    .label(tolgee.t('quayMeterMarkTo', 'Quay metermark to'))
    .emptyAsUndefined()
    .min(1)
    .required(),
  bollards: yup
    .array()
    .of(
      yup.object({
        meterMark: yup
          .number()
          .integer()
          .required(tolgee.t('meterMarkIsRequired', 'Meter mark is required')),
        name: yup.string().nullable().optional(),
      }),
    )
    .required()
    .test('unique-value', tolgee.t('duplicateValue', 'Duplicate value'), bollards => {
      if (Array.isArray(bollards)) {
        const values = bollards.map(m => m.meterMark)
        return new Set(values).size === values.length
      }
      return false
    })
    .test(
      'within-quay-meter-mark-range',
      tolgee.t(
        'invalidMeterMarkRange',
        'One or more cleat meter marks are not within the quay meter mark range',
      ),
      function (bollards) {
        const { quayMeterMarkFrom, quayMeterMarkTo } = this.parent

        if (Array.isArray(bollards)) {
          return !bollards.some(
            m => m.meterMark < quayMeterMarkFrom || m.meterMark > quayMeterMarkTo,
          )
        }
        return false
      },
    ),
})
