import { Button, Tooltip } from '@mui/material'
import { GridRowParams, GridRowSelectionModel } from '@mui/x-data-grid'
import { usePlanningStore } from '@planning/AppProvider'
import { UpdateReleaseOrdersCommand } from '@planning/app/api'
import { GridToolbar } from '@planning/components/GridToolbar'
import { OrderStatusChip } from '@planning/components/OrderStatusChip'
import { GridColumn, StickyHeaderTable } from '@planning/components/PaginatedTable'
import { ReleaseOrderFilter } from '@planning/components/ReleaseOrderFilter'
import { emptyIndicator } from '@planning/constants'
import { ReleaseOrderEditStore } from '@planning/rt-stores/releaseOrder/ReleaseOrderEditStore'
import { IReleaseOrderItem } from '@planning/rt-stores/releaseOrder/ReleaseOrderItem'
import orderService from '@planning/services/orderService'
import { IPaginatedStoreWithItems } from '@planning/stores/PaginatedStore'
import { formatDateTime } from '@planning/utils'
import { useTranslate } from '@tolgee/react'
import { EditIcon, IconButton } from '@tom-ui/ui'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { ReleaseOrderForm } from '../ReleaseOrderForm'

interface Props {
  viewStore: IPaginatedStoreWithItems<IReleaseOrderItem>
  editStore: ReleaseOrderEditStore
}

export const ReleaseOrdersTable = observer(({ viewStore, editStore }: Props) => {
  const { drawerStore } = usePlanningStore()
  const { t } = useTranslate()
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([])

  // todo: [north] find a better way
  useEffect(() => {
    if (!drawerStore.open) {
      editStore.setSelectedIds([])
      editStore.setSelectedOrders([])
    }
  }, [drawerStore.open, editStore])

  const handleOnClose = () => {
    editStore.setSelectedIds([])
    editStore.setSelectedOrders([])
  }

  useEffect(() => {
    setSelectionModel(editStore.selectedIds)
  }, [editStore.selectedIds])

  const columns: GridColumn<IReleaseOrderItem>[] = [
    {
      field: 'referenceNumber',
      headerName: t('orderNo', 'Order no'),
      flex: 1,
      minWidth: 100,
      valueGetter: params => params.row.data.orderReferenceNumber ?? emptyIndicator,
      sortable: true,
      orderBy: 'orderReferenceNumber',
    },
    {
      field: 'containerNumber',
      headerName: t('containerNo', 'Container no'),
      flex: 1,
      minWidth: 100,
      valueGetter: params => params.row.data.containerNumber ?? emptyIndicator,
      sortable: true,
      orderBy: 'containerNumber',
    },
    {
      field: 'pickupWindow',
      headerName: t('pickUpWindow', 'Pick-up window'),
      flex: 1,
      minWidth: 100,
      // todo: [north]
      // sortable: true,
      // orderBy: 'pickupWindow',
      valueGetter: params =>
        params.row.data.releaseStart && params.row.data.releaseEnd
          ? formatDateTime(params.row.data.releaseStart) +
            ' - ' +
            formatDateTime(params.row.data.releaseEnd)
          : emptyIndicator,
    },

    {
      field: 'status',
      headerName: t('status', 'Status'),
      flex: 0.5,
      minWidth: 75,
      sortable: true,
      orderBy: 'status',
      renderCell: params => {
        return <OrderStatusChip status={params.row.data.status} />
      },
    },
    {
      field: 'action',
      headerName: t('action', 'Action'),
      flex: 1,
      minWidth: 75,
      sortable: false,
      renderCell: params => {
        return (
          <>
            {params.row.data.isReleased && (
              <>
                <Button
                  variant='contained'
                  color='warning'
                  onClick={async () => {
                    const cmd: UpdateReleaseOrdersCommand = {
                      ids: [Number(params.row.data.id)],
                      containerNos: [],
                      releaseEnd: null,
                      releaseStart: null,
                      isReleased: false,
                    }

                    await orderService.updateReleaseOrders(cmd)
                    editStore.setSelectedIds([])
                    editStore.setSelectedOrders([])
                  }}
                  disabled={editStore.selectedIds.length > 0}
                  sx={{ margin: '0.5rem' }}
                >
                  {t('undoRelease', 'Undo release')}
                </Button>
                <Tooltip
                  arrow
                  placement='bottom'
                  title={t('editReleaseWindow', 'Edit Release window')}
                >
                  <IconButton
                    onClick={() => {
                      editStore.setSelectedIds([params.row.id])
                      editStore.setSelectedOrders([params.row])
                      drawerStore.showView(
                        <ReleaseOrderForm
                          selectedOrders={editStore.selectedOrders}
                          onClose={handleOnClose}
                        />,
                        {
                          title: t('edit', 'Edit'),
                          subTitle: t('pick-upOrder', 'Pick-up order'),
                          primaryActionLabel: t('submit', 'Submit'),
                          closeActionLabel: t('close', 'Close'),
                          formId: 'pick-up-order',
                        },
                      )
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
            {!params.row.data.isReleased && (
              <Button
                variant='contained'
                color='primary'
                onClick={() => {
                  editStore.setSelectedIds([params.row.id])
                  editStore.setSelectedOrders([params.row])
                  drawerStore.showView(
                    <ReleaseOrderForm
                      selectedOrders={editStore.selectedOrders}
                      onClose={handleOnClose}
                    />,
                    {
                      title: t('release', 'Release'),
                      subTitle: t('pick-upOrder', 'Pick-up order'),
                      primaryActionLabel: t('submit', 'Submit'),
                      closeActionLabel: t('close', 'Close'),
                      formId: 'pick-up-order',
                    },
                  )
                }}
                disabled={!!editStore.selectedIds.find(id => params.row.id === id)}
                sx={{ margin: '0.5rem' }}
              >
                {t('release', 'Release')}
              </Button>
            )}
          </>
        )
      },
    },
  ]

  return (
    <StickyHeaderTable
      store={viewStore}
      columns={columns}
      toolBar={
        <GridToolbar
          title={t('pick-upOrders', 'Pick-up orders')}
          store={viewStore}
          actions={
            <Button
              variant='contained'
              onClick={() => {
                drawerStore.showView(
                  <ReleaseOrderForm
                    selectedOrders={editStore.selectedOrders}
                    onClose={handleOnClose}
                  />,
                  {
                    title: t('release', 'Release'),
                    subTitle: t('pick-upOrder', 'Pick-up order'),
                    primaryActionLabel: t('submit', 'Submit'),
                    closeActionLabel: t('close', 'Close'),
                    formId: 'pick-up-order',
                  },
                )
              }}
              disabled={editStore.selectedIds.length === 0}
            >
              {t('release', 'Release')}
            </Button>
          }
          onShowFilter={() => drawerStore.showView(<ReleaseOrderFilter store={viewStore} />)}
        />
      }
      rowsPerPageOptions={[10, 25, 50]}
      checkboxSelection={true}
      isRowSelectable={(params: GridRowParams) => !params.row.data.isReleased}
      keepNonExistentRowsSelected={true}
      selectionModel={selectionModel}
      onSelectionModelChange={(newSelectionModel: any) => {
        setSelectionModel(newSelectionModel)
        // TODO: Refactor to make it simpler
        const add: string[] = _.differenceWith(newSelectionModel, editStore.selectedIds, _.isEqual)
        const remove: string[] = _.differenceWith(
          editStore.selectedIds,
          newSelectionModel,
          _.isEqual,
        )
        if (add.length > 0) {
          editStore.setSelectedIds([...editStore.selectedIds, ...add])

          add.forEach(id => {
            const newSelectedOrder = viewStore.pageItems.find(item => item.id === id)
            if (newSelectedOrder) {
              editStore.setSelectedOrders([...editStore.selectedOrders, { ...newSelectedOrder }])
            }
          })
        } else if (remove.length > 0) {
          remove.forEach(id => {
            editStore.setSelectedIds(editStore.selectedIds.filter(o => o !== id))
            editStore.setSelectedOrders(editStore.selectedOrders.filter(o => o.id !== id))
          })
        }
      }}
    />
  )
})
