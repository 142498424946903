import { Paper, SxProps, Theme, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { ReactNode } from 'react'

interface Props {
  children: ReactNode
  sx?: SxProps<Theme>
}

export const FilterPaper = ({ children, sx }: Props) => {
  const { t } = useTranslate()
  return (
    <Paper sx={{ ...sx, display: 'flex', flexDirection: 'column' }}>
      <Typography variant='h3' margin='1rem'>
        {t('filters', 'Filters')}
      </Typography>
      {children}
    </Paper>
  )
}
