import { CreateRailcarCommand, RailcarsApi, UpdateRailcarCommand } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'

class RailcarService {
  httpClient = createApiClient(RailcarsApi)

  get = async (page: number, pageSize: number, filter?: string) => {
    const { data } = await this.httpClient.get(page, pageSize, filter)

    return data
  }

  post = async (cmd: CreateRailcarCommand) => {
    await this.httpClient.create(cmd)
  }

  put = async (cmd: UpdateRailcarCommand) => {
    await this.httpClient.update(cmd)
  }

  delete = async (id: number) => {
    await this.httpClient._delete(id)
  }
}

const railcarService = new RailcarService()

export default railcarService
