import { Typography, styled } from '@mui/material'
import Stack from '@mui/material/Stack'
import { Box } from '@mui/system'
import { VisitTableViewStore } from '@planning/rt-stores/visit/VisitTableViewStore'
import { useTranslate } from '@tolgee/react'
import { ArrowDownRightIcon, ArrowUpLeftIcon } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'

interface Props {
  store: VisitTableViewStore
}

const Item = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  backgroundColor: theme.palette.grey[200],
  width: '50%',
  height: theme.spacing(6),
}))

export const VisitCounts = observer(({ store }: Props) => {
  const { t } = useTranslate()
  return (
    <Stack
      direction='row'
      alignItems='center'
      sx={{
        border: `1px solid rgba(145, 158, 171, 0.24)`,
      }}
    >
      <Item
        sx={{
          borderRight: `1px solid rgba(145, 158, 171, 0.24)`,
        }}
      >
        <Stack spacing={1} direction='row' alignItems='center' sx={{ margin: 1 }}>
          <Typography variant='subtitle2'>{t('containers', 'Containers')}</Typography>
          <ArrowDownRightIcon /> <Typography variant='subtitle2'> {t('in', 'In')}</Typography>
          <Typography variant='h4'>{store.summaryCount?.containerInCount}</Typography>
          <ArrowUpLeftIcon /> <Typography variant='subtitle2'> {t('out', 'Out')}</Typography>
          <Typography variant='h4'>{store.summaryCount?.containerOutCount}</Typography>
        </Stack>
      </Item>
      <Item>
        <Stack spacing={1} direction='row' alignItems='center' sx={{ margin: 1 }}>
          <Typography variant='subtitle2'>{t('breakbulk', 'Breakbulk')}</Typography>
          <ArrowDownRightIcon /> <Typography variant='subtitle2'> {t('in', 'In')}</Typography>
          <Typography variant='h4'>
            {store.summaryCount?.breakbulkInPcs} {t('pcs', 'Pcs')}
          </Typography>
          <ArrowUpLeftIcon /> <Typography variant='subtitle2'> {t('out', 'Out')}</Typography>
          <Typography variant='h4'>
            {store.summaryCount?.breakbulkOutPcs} {t('pcs', 'Pcs')}
          </Typography>
        </Stack>
      </Item>
    </Stack>
  )
})
