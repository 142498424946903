import { Autocomplete, List, Popper, TextField, Typography } from '@mui/material'
import { BerthResponseDto } from '@planning/app/api'
import { useTranslate } from '@tolgee/react'
import _, { sortBy } from 'lodash'
import { FC } from 'react'
import { BerthAssignmentOptionListItem } from './BerthAssignmentOptionListItem'
interface Props {
  berths: BerthResponseDto[]
  assignedBerths?: BerthResponseDto[]
  onBerthAssignmentChange: (assignedBerths: BerthResponseDto[]) => Promise<void>
}

// React components should not be nested typescript:S6478
const PopperWithPlacement = (props: any) => {
  return <Popper {...props} placement='bottom-start' />
}

export const BerthAssignmentAutoComplete: FC<Props> = ({
  berths,
  assignedBerths,
  onBerthAssignmentChange,
}) => {
  const { t } = useTranslate()
  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      fullWidth
      ListboxComponent={List}
      //TODO: make berthItemStore computed property
      options={sortBy(
        _.filter(berths, b => !b.isDeleted),
        [b => b.quayMeterMarkFrom],
      )}
      getOptionLabel={berth =>
        `${berth.name ?? ''} (${t('from', 'From')}: ${berth.quayMeterMarkFrom} ${t('to', 'To')}: ${
          berth.quayMeterMarkTo
        } ${t('length', 'Length')}: ${berth.meterSize ?? 0} ${t('mMaxDraft', 'm. Max Draft')}: ${
          berth.maxDraft
        })`
      }
      PopperComponent={PopperWithPlacement}
      slotProps={{
        popper: {
          sx: { minWidth: '16rem' },
        },
      }}
      renderOption={(props, berth) => (
        <BerthAssignmentOptionListItem
          berth={berth}
          assignedBerths={assignedBerths}
          props={props}
          key={`berth-assignment-list-option-${berth.id}`}
        />
      )}
      value={assignedBerths}
      renderTags={(value, getTagProps) => (
        <Typography variant='body2'>
          {value
            .map((berth: BerthResponseDto) => berth.name)
            .sort((a, b) => a.localeCompare(b))
            .join(', ')}
        </Typography>
      )}
      onChange={(_, assignedBerths) => {
        onBerthAssignmentChange(assignedBerths)
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={params => (
        <TextField
          data-cy='berth-assignment-autocomplete'
          {...params}
          placeholder={assignedBerths?.length ? '' : t('berths', 'Berths')}
        />
      )}
    />
  )
}
