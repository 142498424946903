import { Card, CardContent, CardHeader } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { IconButton, PlusIcon } from '@tom-ui/ui'
import { DamageReport } from '../../stores/DamageReportStore'
import { DamageReportList } from './DamageReportList'

interface Props {
  damageReports: DamageReport[]
  hasActions?: boolean
  onOpen?: () => void
  onEdit?: (damageReport: DamageReport) => void
  onRemove?: (damageReport: DamageReport) => void
  onDownloadFiles?: (paths: string[]) => void
}
export const DamageReportBox = ({
  damageReports,
  hasActions,
  onOpen,
  onEdit,
  onRemove,
  onDownloadFiles,
}: Props) => {
  const { t } = useTranslate()

  return (
    <Card variant='outlined' sx={{ minWidth: '100%', boxShadow: 'none' }}>
      <CardHeader
        action={
          hasActions ? (
            <IconButton onClick={onOpen}>
              <PlusIcon />
            </IconButton>
          ) : undefined
        }
        title={t('damageReport', 'Damage report')}
        titleTypographyProps={{ variant: 'subtitle2' }}
      />
      <CardContent sx={{ padding: '0 !important' }}>
        <DamageReportList
          damageReports={damageReports}
          hasActions={hasActions}
          onEdit={onEdit}
          onRemove={onRemove}
          onDownloadFiles={onDownloadFiles}
        />
      </CardContent>
    </Card>
  )
}
