import { Box, Grid, Tooltip, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { BollardResponseDto } from '@planning/app/api'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { FC } from 'react'

export type Props = {
  name: string
  qmmFrom: number
  qmmTo: number
  maxDraft?: number | null
  pixelPerWaterMark: number
  bollards?: BollardResponseDto[] | null
}

interface BollardProps {
  qmmFrom: number
  qmmTo: number
  bollards?: BollardResponseDto[] | null
}

export const calculateBollardPosition = (qmmFrom: number, qmmTo: number, bollard: number) =>
  ((bollard - qmmFrom) / (qmmTo - qmmFrom)) * (qmmTo - qmmFrom)

const bollardTitle = (bollard: BollardResponseDto) => {
  let title = ''
  if (bollard.name) title = `${bollard.name} - `

  title += `${bollard.meterMark}m`

  return title
}

const Bollards: FC<BollardProps> = ({ bollards, qmmFrom, qmmTo }) => {
  const theme = useMinimalsTheme()

  if (!bollards) return

  return bollards.map(bollard => (
    <Box
      key={`bollard-${bollard.meterMark}`}
      position={'relative'}
      top={`${calculateBollardPosition(qmmFrom, qmmTo, bollard.meterMark)}px`}
      px={theme.customSpacing.xs}
      lineHeight={'0px'}
      color={theme.palette.grey[500]}
      sx={{ cursor: 'pointer' }}
      zIndex={999}
    >
      <Tooltip title={bollardTitle(bollard)}>
        <span>●</span>
      </Tooltip>
    </Box>
  ))
}

export const Berth: FC<Props> = ({
  name,
  qmmFrom,
  qmmTo,
  maxDraft,
  pixelPerWaterMark,
  bollards,
}: Props) => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()
  const height = (qmmTo - qmmFrom) * pixelPerWaterMark

  return (
    <Grid container>
      <Grid item sm={11}>
        <Stack
          gap={theme.customSpacing.xxs}
          position={'relative'}
          height={height}
          py={theme.customSpacing.m}
          px={theme.customSpacing.s}
          overflow='hidden'
        >
          <Typography variant='h6'>{name}</Typography>
          {height > 50 && (
            <>
              <Typography variant='body2'>
                {t('draft', 'Draft')}: {maxDraft ?? '-'}m
              </Typography>
              <Typography variant='body2'>
                {t('qmm', 'QMM')}: {qmmFrom}m {t('to', 'To').toLocaleLowerCase()} {qmmTo}m
              </Typography>
            </>
          )}
        </Stack>
      </Grid>
      <Grid
        item
        sm={1}
        sx={{
          display: 'flex',
          alignItems: 'end',
          flexDirection: 'column',
        }}
      >
        <Bollards bollards={bollards} qmmFrom={qmmFrom} qmmTo={qmmTo} />
      </Grid>
    </Grid>
  )
}
