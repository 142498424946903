import { computed, makeObservable, observable, runInAction } from 'mobx'

export type IssueType = 'planning' | 'yard'
export type IssueSeverityType = 'minor' | 'moderate' | 'critical'

export interface Issue {
  id: string
  description: string
  type: IssueType
  severity: IssueSeverityType
  status: 'open' | 'resolved'
  reportedAt: Date
  context?: Record<string, any>
}

const fetchedData: Issue[] = [
  {
    id: 'YPI-1132',
    description: '10 containers overlapping in Block A',
    type: 'yard',
    severity: 'moderate',
    status: 'open',
    reportedAt: new Date('2023-11-08'),
  },
  {
    id: 'YPI-2111',
    description: '3 containers should be planned now',
    type: 'planning',
    severity: 'moderate',
    status: 'open',
    reportedAt: new Date('2023-09-23'),
    context: {
      vesselVisitId: 769,
    },
  },
  {
    id: 'YPI-9232',
    description: '2 new bookings',
    type: 'planning',
    severity: 'moderate',
    status: 'open',
    reportedAt: new Date('2023-09-23'),
    context: {
      vesselVisitId: 741,
    },
  },
  {
    id: 'YPI-8732',
    description: '53 containers should be planned now',
    type: 'planning',
    severity: 'moderate',
    status: 'open',
    reportedAt: new Date('2023-09-23'),
    context: {
      vesselVisitId: 741,
    },
  },
  {
    id: 'YPI-7132',
    description: '53 containers should be planned now',
    type: 'planning',
    severity: 'critical',
    status: 'open',
    reportedAt: new Date('2023-09-23'),
    context: {
      vesselVisitId: 761,
    },
  },
]

export class IssueStore {
  issues: Issue[] = []

  constructor() {
    makeObservable(this, {
      issues: observable,
      total: computed,
    })
  }

  loadAll() {
    // Simulating an API call with a setTimeout
    setTimeout(() => {
      // Mimic data received from the API
      runInAction(() => {
        this.issues = fetchedData
      })
    }, 1000)
  }

  get total() {
    return fetchedData.length
  }

  loadByType(type: IssueType) {
    setTimeout(() => {
      // Mimic data received from the API
      runInAction(() => {
        this.issues = fetchedData.filter(i => i.type === type && i.status === 'open')
      })
    }, 1000)
  }
}
