import { SelectOption } from '@operations/app/models'
import { EquipmentStore } from '@operations/stores/EquipmentStore'
import { WorkInstructionStore } from '@operations/stores/WorkInstructionStore'

import { FilterChip } from '@operations/components/filter/filter.model'
import { lowerFirstCase } from '@operations/utils/helper'
import _ from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import {
  WorkInstructionFilter,
  defaultValues,
} from '../components/Filters/work-instructions-filters.model'

export class WorkInstructionFiltersUIStore {
  isOpen = false
  filter?: WorkInstructionFilter
  containerNumber?: string

  constructor(
    private equipmentStore: EquipmentStore,
    private workInstructionsStore: WorkInstructionStore,
  ) {
    makeObservable(this, {
      isOpen: observable,
      filter: observable,
      containerNumber: observable,
      toggle: action,
      setFilter: action,
      setContainerNumber: action,
      removeFilter: action,
      equipments: computed,
      isFiltersEmpty: computed,
      hasJobs: computed,
      isFilterFilled: computed,
      filterChips: computed,
    })
  }

  public setFilter(filter?: WorkInstructionFilter) {
    if (!filter) {
      this.filter = defaultValues
    } else {
      this.filter = filter
    }
  }

  public setContainerNumber(containerNumber?: string) {
    if (this.containerNumber !== containerNumber) {
      this.containerNumber = containerNumber
    }
  }

  public toggle(): void {
    this.isOpen = !this.isOpen
  }

  public removeFilter(filter: FilterChip): void {
    const key = filter.key as keyof WorkInstructionFilter

    if (this.filter) {
      const filterValue = this.filter[key]

      if (Array.isArray(filterValue)) {
        this.filter[key] = (filterValue as any).filter((x: string) => x !== filter.value)
      } else {
        this.filter[key] = null
      }
    }
  }

  public get isFiltersEmpty() {
    return !this.isFilterFilled && !this.containerNumber
  }

  public get isFilterFilled(): boolean {
    return (
      !!this.filter &&
      (!!this.filter.destination ||
        !!this.filter.origin ||
        !!this.filter.equipment ||
        !!this.filter.status?.length ||
        !!this.filter.process?.length)
    )
  }

  public get filterChips() {
    const filterChips: FilterChip[] = []

    if (this.filter) {
      for (const propName in this.filter) {
        const key = propName as keyof WorkInstructionFilter
        const filterValue = this.filter[key]

        if (!filterValue) continue

        if (Array.isArray(filterValue)) {
          filterValue.forEach(value =>
            filterChips.push({ key, value, label: `ops_${lowerFirstCase(value)}` }),
          )
        } else {
          const value = typeof filterValue === 'string' ? filterValue : filterValue.label
          filterChips.push({ key, value })
        }
      }
    }

    return filterChips
  }

  public get hasJobs() {
    return this.workInstructionsStore.items.some(x => x.jobs && x.jobs.length > 0)
  }

  public get equipments(): SelectOption[] {
    const equipmentIds = _(this.workInstructionsStore.items.flatMap(x => x.jobs))
      .uniqBy(x => x?.equipmentId)
      .map(x => x?.equipmentId)

    return _(this.equipmentStore.items)
      .filter(x => equipmentIds.includes(x.id))
      .sortBy(x => x.name)
      .map(x => ({ label: x.name, value: x.id, groupBy: x.equipmentType }))
      .value()
  }
}
