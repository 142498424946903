import { Alert } from '@mui/material'
import { GeneralCargoOutboundValidationResultDto } from '@storage/app/api'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useEffect, useState } from 'react'

export interface OutboundRequestValidationControlProps {
  isOutbound: boolean
  orderId?: number
  customerId?: number
  commodityId?: number
  lotNumber?: string
  packageId?: number
  quantity?: number
  unitIds?: string[]
  imoClasses?: string[]
  hideSuccessfulValidation?: boolean
}

export const OutboundRequestValidationControl = ({
  isOutbound,
  orderId,
  customerId,
  commodityId,
  lotNumber,
  packageId,
  quantity,
  unitIds,
  imoClasses,
  hideSuccessfulValidation,
}: OutboundRequestValidationControlProps) => {
  const { palette } = useMinimalsTheme()
  const { t } = useTranslate()

  const { generalCargoStockStore } = useStores()

  const doValidate = isOutbound && !!customerId && !!commodityId && (quantity ?? 0) > 0

  const [validationResult, setValidationResult] = useState<
    GeneralCargoOutboundValidationResultDto | undefined
  >()

  useEffect(() => {
    const validate = async () => {
      if (doValidate) {
        setValidationResult(
          await generalCargoStockStore.validateOutboundRequest({
            customerId: customerId ?? 0,
            commodityId: commodityId ?? 0,
            lotNumber: lotNumber,
            packageId: packageId,
            quantity: quantity ?? 0,
            unitIds: unitIds,
            imoClasses: imoClasses,
          }),
        )
      } else setValidationResult(undefined)
    }

    validate()
  }, [
    isOutbound,
    orderId,
    customerId,
    commodityId,
    lotNumber,
    packageId,
    quantity,
    unitIds,
    imoClasses,
    generalCargoStockStore,
    doValidate,
  ])

  const validationHint = () => {
    if (!doValidate) return null

    if (validationResult?.status)
      return t(
        'outboundRequestValidationPassed',
        'Order is fulfillable, available quantity in storage is {available}',
        { available: validationResult.availableQuantity },
      )

    if (
      validationResult?.fails.stockImoClasses &&
      validationResult?.fails.stockImoClasses.length > 0
    )
      return t(
        'outboundRequestValidationFailedOnImo',
        'Requested IMO classes do not match with IMO classes of items in storage: {imo}',
        { imo: validationResult.fails.stockImoClasses.join(', ') },
      )

    if (validationResult?.fails.requestedOrderQuantity)
      return t(
        'outboundRequestValidationFailedOnOrderQuantity',
        'Requested quantity exceeds available quantity of {available} in storage',
        { available: validationResult.availableQuantity },
      )

    if (validationResult?.fails.requestedTotalQuantity)
      return t(
        'outboundRequestValidationFailedOnTotalQuantity',
        'The amount of all outbound requests is {requestedTotal} and exceeds the available quantity in storage by {lacking}',
        {
          requestedTotal: validationResult.fails.requestedTotalQuantity,
          lacking:
            validationResult.fails.requestedTotalQuantity -
            (validationResult.availableQuantity ?? 0),
        },
      )

    if (
      validationResult?.fails.unavailableUnitIds &&
      validationResult?.fails.unavailableUnitIds.length > 0
    )
      return t(
        'outboundRequestValidationFailedOnOUnitIds',
        'One or more item IDs not available in storage: {unitIds}',
        { unitIds: validationResult.fails.unavailableUnitIds.join(', ') },
      )

    return t('outboundRequestValidationFailedOnEmptyStock', 'No items currently in storage')
  }

  return !!validationResult &&
    doValidate &&
    (!validationResult.status || !hideSuccessfulValidation) ? (
    <Alert severity={!validationResult?.status ? 'warning' : 'success'}>
      {validationHint() ?? ''}
    </Alert>
  ) : null
}
