import { Stack } from '@mui/material'
import { ReactElement } from 'react'

interface Props {
  children: ReactElement | ReactElement[]
}

export const EmptyStateView = ({ children }: Props) => {
  return (
    <Stack
      marginTop={'1rem'}
      display='flex'
      flexDirection='column'
      alignItems='center'
      maxHeight='30rem'
    >
      {children}
    </Stack>
  )
}
