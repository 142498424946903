import {
  CreateYardBlockRowDto,
  UpdateYardBlockRowDto,
  YardBlockRowDto,
  YardBlockRowsApi,
} from '@storage/app/api'
import { SelectOption } from '@storage/app/models'
import { EntityStore } from '@storage/app/store/entity.store'
import { YardBlockSlotStore } from './yard-block-slot.store'

export class YardBlockRowStore extends EntityStore<YardBlockRowDto> {
  constructor(
    private api: YardBlockRowsApi,
    private yardBlockSlotStore: YardBlockSlotStore,
  ) {
    super()
  }

  public projectToOptions(entries: YardBlockRowDto[]): SelectOption[] {
    return entries.map(({ id, name }) => ({
      label: name,
      value: id,
    }))
  }

  public filterByYardBlock(yardBlockId: string): YardBlockRowDto[] {
    return this.entries.filter(row => row.yardBlockId === yardBlockId)
  }

  sorter(entityA: YardBlockRowDto, entityB: YardBlockRowDto): number {
    return entityA.sequenceNumber - entityB.sequenceNumber
  }

  public async loadAll(): Promise<void> {
    const { data: yardBlockRows } = await this.api.getAll()

    this.setAll(yardBlockRows)
  }

  public async loadForYardBlock(yardBlockId: string): Promise<void> {
    const { data: yardBlockRows } = await this.api.get({ yardBlockId })

    const rows = this.getForBlock(yardBlockId)
    rows.forEach(row => this.remove(row.id))

    this.addOrUpdateMany(yardBlockRows)
  }

  public async add(dto: CreateYardBlockRowDto): Promise<void> {
    const { data: yardBlock } = await this.api.create({ yardBlockRowsCreateRequest: dto })

    this.addOrUpdate(yardBlock)
  }

  public async update(dto: UpdateYardBlockRowDto): Promise<void> {
    const { data: yardBlock } = await this.api.update({ yardBlockRowsUpdateRequest: dto })

    this.addOrUpdate(yardBlock)
  }

  public async delete(yardBlockRowId: string): Promise<void> {
    await this.api._delete({ yardBlockRowId })

    this.remove(yardBlockRowId)
  }

  public async lock(id: string, isLocked: boolean): Promise<void> {
    const { data: yardBlockSlots } = await this.api.setLockStatus({
      lockStatus: isLocked,
      yardBlockRowId: id,
    })

    this.addOrUpdate({
      ...this.data.get(id)!,
      isLocked,
    })

    this.yardBlockSlotStore.addOrUpdateList(yardBlockSlots)
  }

  public getSlots(id: string) {
    return this.yardBlockSlotStore.entries.filter(s => s.yardBlockRowId === id)
  }

  public getForBlock(blockId: string) {
    const rows = this.entries.filter(row => row.yardBlockId === blockId)
    rows.sort((r1, r2) => r1.sequenceNumber - r2.sequenceNumber)

    return rows
  }
}
