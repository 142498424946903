import {
  Box,
  Chip,
  ClickAwayListener,
  Divider,
  Grow,
  Paper,
  Popper,
  Stack,
  Typography,
} from '@mui/material'
import { JobDto, YardBlockEquipmentAssignmentDto } from '@operations/app/api'
import { SearchInput } from '@operations/components/filter/SearchInput'
import { useTranslate } from '@tolgee/react'
import { ChevronDownIcon, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import { YardBlockAssignment } from './YardBlockAssignment'

interface Props {
  jobs: JobDto[]
  selectedYardBlockId?: string
  yardBlocks: YardBlockEquipmentAssignmentDto[]
  onSelect: (selectedYardBlockId?: string) => void
}
export const JobFilterByArea = observer(
  ({ jobs, selectedYardBlockId, yardBlocks, onSelect }: Props) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()

    const anchorRef = useRef<HTMLDivElement>(null)
    const [open, setOpen] = useState(false)
    const [search, setSearch] = useState<string>('')
    const searchRef = useRef<HTMLDivElement>(null)

    const [filteredYardBlocks, setFilteredYardBlocks] = useState<YardBlockEquipmentAssignmentDto[]>(
      [],
    )

    useEffect(() => {
      setFilteredYardBlocks(yardBlocks)
    }, [yardBlocks, setFilteredYardBlocks])

    const toggle = () => {
      setOpen(value => !value)
    }

    const handleSearch = (value: string) => {
      setSearch(value)
      setFilteredYardBlocks(
        yardBlocks.filter(
          yardBlock =>
            !value || yardBlock.yardBlockName.toLowerCase().includes(value.toLowerCase()),
        ),
      )
    }

    return (
      <Box>
        <Chip
          label={
            selectedYardBlockId
              ? yardBlocks.find(x => x.yardBlockId === selectedYardBlockId)?.yardBlockName
              : t('filterByArea', 'Filter by area')
          }
          color={selectedYardBlockId ? 'primary' : undefined}
          onClick={() => toggle()}
          deleteIcon={<ChevronDownIcon />}
          onDelete={() => toggle()}
          ref={anchorRef}
          data-cy='yard-block-select-chip'
        />
        <Popper
          open={open}
          placement='bottom-start'
          anchorEl={anchorRef.current}
          transition
          sx={{
            width: '100%',
            [theme.breakpoints.up('sm')]: {
              width: '35rem',
            },
          }}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Paper
                sx={{
                  marginLeft: theme.customSpacing.xs,
                  boxShadow: theme.customShadows.dropdown,
                }}
              >
                <ClickAwayListener onClickAway={toggle}>
                  <Stack divider={<Divider />}>
                    <Box>
                      <SearchInput
                        searchRef={searchRef}
                        search={search}
                        label={t('ASpecificBlock', 'a specific block')}
                        onSearch={handleSearch}
                        removeElevation
                      />
                    </Box>
                    <Stack maxHeight='25rem' divider={<Divider />} sx={{ overflowY: 'auto' }}>
                      {filteredYardBlocks.map(yardBlock => (
                        <YardBlockAssignment
                          key={yardBlock.yardBlockId}
                          dataCy={`yard-block-select-chip-${yardBlock.yardBlockName}-option`}
                          jobs={jobs}
                          yardBlockAssignment={yardBlock}
                          onClick={yardBlockId => {
                            onSelect(yardBlockId)
                            toggle()
                          }}
                        />
                      ))}

                      {filteredYardBlocks.length === 0 && (
                        <Stack alignItems='center' padding={theme.customSpacing.m}>
                          <Typography variant='body1' color='secondary'>
                            {t('noBlockFound', 'No block found')}
                          </Typography>
                        </Stack>
                      )}
                    </Stack>
                  </Stack>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
    )
  },
)
