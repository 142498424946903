import { FormControl, FormControlLabel, styled, Switch, TextField } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { CustomInputAdornment } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { ServiceOrdersViewStore } from '../../Stores/ServiceOrdersViewStore'

interface IProps {
  store: ServiceOrdersViewStore
}

const CustomFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  width: '100%',
  justifyContent: 'space-between',
  margin: theme.spacing(1, 0),
  '& .MuiFormControlLabel-label': {
    fontSize: '1rem',
    fontWeight: 'bold',
    color: theme.palette.text.primary,
  },
}))

export const CoolingOrderForm: FC<IProps> = observer(({ store }) => {
  const { t } = useTranslate()

  return (
    <FormControl
      fullWidth
      sx={{
        marginTop: 0,
      }}
    >
      <TextField
        type='number'
        required
        sx={{ mb: '1rem' }}
        fullWidth
        label={t('RequiredTemperature', 'Required temperature')}
        variant='outlined'
        value={store.coolingOrder?.requestedTemperature ?? ''}
        onChange={e =>
          store.setCoolingOrder({
            ...store.coolingOrder,
            requestedTemperature: e.target.value as unknown as number,
          })
        }
        InputProps={{
          endAdornment: (
            <CustomInputAdornment position='end' color='secondary'>
              °C
            </CustomInputAdornment>
          ),
        }}
      />
      <CustomFormControlLabel
        value='start'
        control={
          <Switch
            color='primary'
            checked={store.coolingOrder?.isMonitoringRequired ?? false}
            onChange={e => {
              store.setCoolingOrder({
                ...store.coolingOrder,
                monitoringFrequency: e.target.checked
                  ? store.coolingOrder?.monitoringFrequency
                  : undefined,
                isMonitoringRequired: e.target.checked,
              })
            }}
          />
        }
        label={t('requireMonitoring', 'Require monitoring')}
        labelPlacement='start'
      />
      {store.coolingOrder?.isMonitoringRequired && (
        <TextField
          required
          sx={{ mb: '1rem' }}
          fullWidth
          type='number'
          label={t('Frequency', 'Frequency')}
          variant='outlined'
          value={store.coolingOrder?.monitoringFrequency ?? ''}
          onChange={e => {
            if (e.target.value.match(/[^0-9]/)) {
              e.target.value = Math.floor(Number(e.target.value)).toString()
            }
            store.setCoolingOrder({
              ...store.coolingOrder,
              monitoringFrequency: e.target.value as unknown as number,
            })
          }}
          InputProps={{
            endAdornment: (
              <CustomInputAdornment position='end' color='secondary'>
                Hours
              </CustomInputAdornment>
            ),
          }}
        />
      )}
      <CustomFormControlLabel
        value='start'
        control={
          <Switch
            color='primary'
            checked={store.coolingOrder?.isPlugInRequired ?? false}
            onChange={e =>
              store.setCoolingOrder({
                ...store.coolingOrder,
                isPlugInRequired: e.target.checked,
              })
            }
          />
        }
        label={t('requirePlugIn', 'Require plug in')}
        labelPlacement='start'
      />
    </FormControl>
  )
})
