import { Typography } from '@mui/material'
import { YardPositionDto } from '@storage/app/api'
import { useStorageStore } from '@storage/AppProvider'
import { YardOperationControlProps } from '@storage/features/yard-operation-control/interface/yard-operation-control-interface'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect } from 'react'
import { DropDownSelectContainer } from './Variants/DropDownSelect/DropDownSelectContainer'
import { YardPositionInputContainer } from './Variants/YardPositionInput/YardPositionInputContainer'
import { YardPositionSelectContainer } from './Variants/YardPositionSelect/YardPositionSelectContainer'
import { YardPositionInputMode } from './yard-operation-control.store'

const YardOperationControl = observer(
  ({ containerNumber, hasError, handleChange, orderId }: YardOperationControlProps) => {
    const variant = 3

    const { t } = useTranslate()
    const { palette } = useMinimalsTheme()
    const { yardOperationControlStore: store } = useStorageStore()
    const { snackbarStore } = useStores()

    const handleError = useCallback(
      (msg: string) => {
        snackbarStore.showMessage(t(msg, `An error occurred: ${msg}`), 'error')
      },
      [snackbarStore, t],
    )

    const onChange = (value?: YardPositionDto) => {
      if (store.handleChange) {
        store.setLocation(value)
        store.handleChange(value)
      }
    }

    useEffect(() => {
      store.setContext(containerNumber, YardPositionInputMode.StackIn, orderId ?? undefined)
      store.setCallback(handleChange, handleError)
    }, [containerNumber, orderId, handleChange, handleError, store])

    useEffect(() => {
      const loadData = async () => {
        try {
          const data = await store.loadTurnoverData()
          store.setTurnoverData(data)
        } catch (_) {
          handleError(t('databaseIOError', 'database read/write error'))
        }
      }

      loadData()
    }, [store, handleError, t])

    const featureComponent = (variant: number) => {
      switch (variant) {
        case 1:
          return (
            <DropDownSelectContainer turnoverData={store.turnoverData} handleError={handleError} />
          )
        case 2:
          return (
            <YardPositionSelectContainer
              plannedPosition={store.turnoverData?.plannedPosition ?? undefined}
              locationSize={store.turnoverData?.locationSize}
              handleError={handleError}
              onChange={onChange}
            />
          )
        case 3:
          return (
            <YardPositionInputContainer
              plannedPosition={store.turnoverData?.plannedPosition ?? undefined}
              hasError={hasError}
              handleError={handleError}
              onChange={onChange}
              turnover={store.turnoverData?.turnover ?? undefined}
            />
          )
        default:
          return <Typography variant='h1'>component not defined</Typography>
      }
    }

    return (
      <>
        {store.turnoverData &&
          (store.turnoverData?.turnover ? (
            featureComponent(variant)
          ) : (
            <Typography variant='h6' color={palette.error.main}>
              {t(
                'containerNotInYard',
                `The container ${containerNumber} is not located on terminal.`,
              )}
            </Typography>
          ))}
      </>
    )
  },
)

export default YardOperationControl
