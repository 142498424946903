import { Button, Typography } from '@mui/material'
import { Box } from '@mui/system'

import { CarrierVisitDirection } from '@planning/app/api'
import { ContainerDamageRecordStep } from '@planning/components'
import { useNavigationStore } from '@planning/stores'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { RailTallyViewStore } from '../../Stores/RailTallyViewStore'
import { IInspectContainerFormData } from '../InspectContainer/InspectContainerForm'
import { CreateRailcarContainer } from './CreateRailcarContainer'

interface IProps {
  store: RailTallyViewStore
}

export const InspectRailcarEmptyMessage: FC<IProps> = observer(({ store }) => {
  const { t } = useTranslate()
  const navigationStore = useNavigationStore()

  const handleReportDamage = (
    orderData: IInspectContainerFormData,
    onConfirm: (orderData: IInspectContainerFormData) => void,
  ) => {
    navigationStore.push(
      <ContainerDamageRecordStep
        orderData={orderData}
        containerNumber={orderData?.containerNumber}
        onSubmit={(data, damages) => {
          data.damagesReported = damages
          navigationStore.pop()

          onConfirm(data)
        }}
      />,
    )
  }

  return (
    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'70vh'}>
      <Box textAlign={'center'}>
        <Typography variant='subtitle1' mb={'1rem'}>
          {t('noData', 'No data')}
        </Typography>
        <Typography variant='body1'>
          {t('submitAsEmptyRailcar', 'Submit as empty railcar')}
        </Typography>
        <Typography variant='body1' mb={'1rem'}>
          {t('orAddYourFirstContainer', 'or add your first container')}
        </Typography>

        <Button
          color='secondary'
          onClick={() => {
            navigationStore.push(
              <CreateRailcarContainer
                orders={store.uncheckedDischargeOrdersOnRailVisit}
                onSubmit={data => {
                  const handleSubmit = (orderData: IInspectContainerFormData) => {
                    store.upsertOrders(orderData)
                    store.setCurrentStep(1)
                    navigationStore.pop()
                  }

                  if (data.hasDamage) {
                    handleReportDamage(data, handleSubmit)
                  } else {
                    handleSubmit(data)
                  }
                }}
                direction={CarrierVisitDirection.Inbound}
                submitButtonText={t('done', 'Done')}
              />,
            )
          }}
        >
          {t('addContainer', 'Add container')}
        </Button>
      </Box>
    </Box>
  )
})
