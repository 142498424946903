import * as yup from 'yup'
import IsUniqueValidator from '../yard-block-form/is-unique.validator'
import { YardFlexibleZoneFormProfile } from './yard-flexible-zone-form.profile'
export const schema: yup.Schema<YardFlexibleZoneFormProfile> = yup.object({
  id: yup.string().optional(),
  name: yup
    .string()
    .label('Name')
    .emptyAsUndefined()
    .required()
    .matches(/^[a-zA-Z0-9]+$/, 'yardBlockNameIncorrect')
    .test(IsUniqueValidator.name, IsUniqueValidator.errorMessage, async (name, context) => {
      const id = (context.parent as YardFlexibleZoneFormProfile).id

      return await IsUniqueValidator.validate({ id, name })
    }),
})
