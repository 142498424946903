/* eslint-disable react/display-name */
import { Box } from '@mui/material'
import { ZoomToolbar } from '@storage/pages/yard-management/components/ZoomToolbar'
import { Stage as StageType } from 'konva/lib/Stage'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect, useRef } from 'react'
import { Stage } from 'react-konva'
import { StageBackgroundGrid } from './StageBackgroundGrid'
import { StageStore } from './stage.store'
import debounce from 'lodash/debounce'
import { calculateScalePosition, isBoundingBoxEmpty } from '@storage/utils/geometry/geometry.util'
import { BoundingBox } from '@storage/types'

interface Props {
  stageStore: StageStore
  children: React.ReactNode
  boundingBox: BoundingBox
}

export const StageWrapper = observer(({ stageStore, boundingBox, children }: Props) => {
  const stageRef = useRef<StageType>(null)
  const workspaceColumnRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleResize = debounce(() => {
      if (workspaceColumnRef.current) {
        stageStore.onResize(
          workspaceColumnRef.current.offsetWidth,
          workspaceColumnRef.current.offsetHeight,
        )
      }
    }, 100)

    if (!workspaceColumnRef.current) return

    const resizeObserver = new ResizeObserver(handleResize)

    resizeObserver.observe(workspaceColumnRef.current)

    return () => resizeObserver.disconnect()
  }, [stageStore])

  const fitToBoundingBox = useCallback(() => {
    const container = workspaceColumnRef.current
    const stage = stageRef.current

    if (stage && container) {
      const { scale, position } = calculateScalePosition(
        boundingBox!,
        container.clientWidth,
        container.clientHeight,
      )
      stageStore.setPosition(position)
      stageStore.setZoom(scale)
      stage.scale({ x: scale, y: scale })
      stage.position(position)
      stage.batchDraw()
    }
  }, [boundingBox, stageStore])

  useEffect(() => {
    if (isBoundingBoxEmpty(stageStore.initialBoundingBox!) && !isBoundingBoxEmpty(boundingBox!)) {
      stageStore.setInitialBoundingBox(boundingBox!)
      fitToBoundingBox()
    }
  }, [boundingBox, fitToBoundingBox, stageStore])

  return (
    <Box
      ref={workspaceColumnRef}
      id='workspace'
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: stageStore.showGrid ? '#f2f2f2' : undefined,
      }}
    >
      <Stage
        ref={stageRef}
        container='workspace'
        width={stageStore.width}
        height={stageStore.height}
        scaleX={stageStore.zoom}
        scaleY={stageStore.zoom}
        onWheel={({ evt }) => {
          evt.preventDefault()
          stageStore.onZoom(evt.deltaY)
        }}
        draggable
        onDragEnd={({ currentTarget }) => {
          stageStore.onDragEnd({ x: currentTarget.x(), y: currentTarget.y() })
        }}
      >
        {stageStore.showGrid && (
          <StageBackgroundGrid
            stageWidth={stageStore.width}
            stageHeight={stageStore.height}
            scale={stageStore.zoom}
            offsetX={stageStore.position.x}
            offsetY={stageStore.position.y}
          />
        )}

        {children}
      </Stage>

      {stageStore.showZoomToolbar && (
        <ZoomToolbar
          scale={stageStore.zoom}
          setScale={(value: number) => stageStore.setZoom(value)}
          fitToScreen={fitToBoundingBox}
        />
      )}
    </Box>
  )
})
