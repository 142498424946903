import { Container } from '@mui/system'
import { useTranslate } from '@tolgee/react'
import { ConfirmationDialog } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

import { ContainerDialog } from '../ContainerDialog'

import { CreateCoolingOrderCommand, UpdateCoolingOrderCommand } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { serviceOrdersService } from '@planning/services'
import { ICoolingOrder, ServiceOrdersViewStore } from '../../Stores/ServiceOrdersViewStore'
import { CoolingOrderDialogRender } from './CoolingOrderDialogRender'

export const CoolingOrderDialog: FC<{
  store: ServiceOrdersViewStore
  onClose: () => void
}> = observer(({ store, onClose }) => {
  const { t } = useTranslate()
  const { appViewStore } = usePlanningStore()

  const handleOnSubmit = async () => {
    if (!store.selectedContainerJourney) return
    const journey = store.selectedContainerJourney
    const coolingOrder = store.coolingOrder
    try {
      if (journey.coolingOrderId) {
        await submitUpdateCoolingOrder(coolingOrder)
      } else {
        await submitCreateCoolingOrder(coolingOrder, journey.id)
      }
      appViewStore.setShowAlert(
        'success',
        t('coolingOrderSavedSuccessfully', 'Cooling order saved successfully'),
      )
      onClose()
    } catch (error) {
      appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
    }
  }

  const submitCreateCoolingOrder = async (
    coolingOrder: ICoolingOrder,
    containerJourneyId: number,
  ) => {
    const cmd: CreateCoolingOrderCommand = {
      containerJourneyId: containerJourneyId,
      isMonitoringRequired: coolingOrder.isMonitoringRequired ?? false,
      isPlugInRequired: coolingOrder.isPlugInRequired ?? false,
      requestedTemperature: coolingOrder.requestedTemperature!,
      monitoringFrequency: coolingOrder.monitoringFrequency,
    }

    await serviceOrdersService.createCoolingOrder(cmd)
  }

  const submitUpdateCoolingOrder = async (coolingOrder: ICoolingOrder) => {
    const cmd: UpdateCoolingOrderCommand = {
      id: coolingOrder.id!,
      isMonitoringRequired: coolingOrder.isMonitoringRequired ?? false,
      isPlugInRequired: coolingOrder.isPlugInRequired ?? false,
      requestedTemperature: coolingOrder.requestedTemperature!,
      monitoringFrequency: coolingOrder.monitoringFrequency,
    }

    await serviceOrdersService.updateCoolingOrder(cmd)
  }

  const handleOnDelete = async () => {
    if (!store.selectedContainerJourney) return
    const journey = store.selectedContainerJourney
    if (journey.coolingOrderId) {
      try {
        await serviceOrdersService.deleteCoolingOrder(journey.coolingOrderId)
        appViewStore.setShowAlert(
          'success',
          t('coolingOrderDeletedSuccessfully', 'Cooling Order deleted successfully!'),
        )
        onClose()
      } catch (e) {
        appViewStore.setShowAlert(
          'error',
          t('failedToDeleteCoolingOrder', 'Failed to delete Cooling Order'),
        )
      }
    }
  }

  return (
    <Container>
      <ContainerDialog
        open={store.showContainerDialog}
        containerJourneys={store?.containerVisits}
        onSelectContainer={containerJourney => {
          store.closeActiveDialog()
          store.setSelectedContainerJourney(containerJourney)
        }}
        onClose={() => store.closeActiveDialog()}
      />

      <ConfirmationDialog
        open={store.showServiceOrderDeleteConfirmationDialog}
        title={t('confirmation', 'Confirmation')}
        message={t(
          'doYouWantToRemoveThisCoolingOrder',
          'Do you want to remove this cooling order?',
        )}
        primaryActionText={t('yes', 'Yes')}
        closeLabel={t('no', 'No')}
        onConfirm={() => {
          handleOnDelete()
          store.closeActiveDialog()
        }}
        onClose={() => store.closeActiveDialog()}
      />

      <CoolingOrderDialogRender
        onDelete={() => store.setActiveDialog('serviceOrderDeleteConfirmation')}
        onClose={onClose}
        onSubmit={handleOnSubmit}
        store={store}
      />
    </Container>
  )
})
