import { Stack, Typography } from '@mui/material'

interface Props {
  label: string
  value: string | number
  nowrap?: boolean
  dataCy?: string
}
export const Tile = ({ label, value, nowrap, dataCy }: Props) => (
  <Stack>
    <Typography variant='caption' whiteSpace={nowrap ? 'nowrap' : 'normal'}>
      {label}
    </Typography>
    <Typography variant='subtitle2' whiteSpace={nowrap ? 'nowrap' : 'normal'} data-cy={dataCy}>
      {value}
    </Typography>
  </Stack>
)
