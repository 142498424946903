import { CargoUnitDto } from '@operations/app/api'
import { useOperationsStore } from '@operations/AppProvider'
import { DrawerFormContainer } from '@tom-ui/ui'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { JobForm } from '../components/JobForm/JobForm'
import { JobFormProfile } from '../components/JobForm/job-form.profile'

export const JobMonitoringDrawerContainer = observer(() => {
  const { t } = useTranslate()
  const { jobMonitoringDrawerUIStore, appStore } = useOperationsStore()

  const handleSubmit = async (job: JobFormProfile) => {
    await jobMonitoringDrawerUIStore.createWorkInstruction(job)
    jobMonitoringDrawerUIStore.toggle()
    appStore.setShowAlert('success', t('jobCreated', 'Job created successfully'))
  }

  const handleContainerChange = (container?: CargoUnitDto) => {
    jobMonitoringDrawerUIStore.selectContainer(container)
  }

  return (
    <DrawerFormContainer
      open={jobMonitoringDrawerUIStore.isOpen}
      title={t('createJob', 'Create job')}
      primaryActionLabel={t('save', 'Save')}
      closeActionLabel={t('close', 'Close')}
      formId='job-form'
      onClose={() => jobMonitoringDrawerUIStore.toggle()}
    >
      <JobForm
        id='job-form'
        onSubmit={handleSubmit}
        onContainerChange={handleContainerChange}
        containers={jobMonitoringDrawerUIStore.containers}
        containerOptions={jobMonitoringDrawerUIStore.containerOptions}
        yardBlockOptions={jobMonitoringDrawerUIStore.yardBlockOptions}
        equipmentOptions={jobMonitoringDrawerUIStore.equipmentOptions}
      />
    </DrawerFormContainer>
  )
})
