import { withAuthenticationRequired } from '@auth0/auth0-react'
import { CommoditiesAndPackagingContainer } from '@billing/features/commodity/containers/CommoditiesAndPackagingContainer'
import { CommoditiesDialogContainer } from '@billing/features/commodity/containers/CommoditiesDialogContainer'
import { PackagesDialogContainer } from '@billing/features/packaging/containers/PackagesDialogContainer'
import { useTranslate } from '@tolgee/react'
import { FC } from 'react'
import { Helmet } from 'react-helmet-async'

const CommoditiesAndPackaging: FC = withAuthenticationRequired(() => {
  const { t } = useTranslate()

  return (
    <>
      <Helmet>
        <title>
          {t(
            'commoditiesAndPackagingTomBillingService',
            'Commodities & Packaging - TOM Billing Service',
          )}
        </title>
      </Helmet>

      <CommoditiesAndPackagingContainer />
      <CommoditiesDialogContainer />
      <PackagesDialogContainer />
    </>
  )
})

export default CommoditiesAndPackaging
