import { CarrierVisitDto, CarrierVisitStatus } from '@storage/app/api'
import { CarrierVisitInfo } from '@storage/stores/carrier-visit.store'
import { action, makeObservable, observable } from 'mobx'

export class CarrierVisitArrivalStatusStore {
  arrivalValue?: string | null
  arrivalLabel = 'ETA'

  constructor() {
    makeObservable(this, {
      arrivalLabel: observable,
      arrivalValue: observable,
      setArrivalLabel: action,
      setArrivalValue: action,
      resolveVesselArrival: action,
    })
  }

  setArrivalLabel(arrivalStatus: string) {
    this.arrivalLabel = arrivalStatus
  }

  setArrivalValue(arrivalValue?: string | null) {
    this.arrivalValue = arrivalValue
  }

  resolveVesselArrival(vesselVisit: CarrierVisitInfo | CarrierVisitDto) {
    switch (vesselVisit.status) {
      case CarrierVisitStatus.Expected:
        this.setArrivalLabel('ETA')
        this.setArrivalValue(vesselVisit.eta)
        break
      case CarrierVisitStatus.Arrived:
        this.setArrivalLabel('ATA')
        this.setArrivalValue(vesselVisit.ata)
        break
      default:
        this.setArrivalLabel('ETD')
        this.setArrivalValue(vesselVisit.etd)
    }
  }
}
