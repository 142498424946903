import {
  EquipmentType,
  OperationType,
  WorkInstructionStatus,
  WorkQueueActionType,
  WorkQueueDto,
} from '@operations/app/api'
import { SelectOption } from '@operations/app/models'
import { EquipmentStore } from '@operations/stores/EquipmentStore'
import { WorkInstructionStore } from '@operations/stores/WorkInstructionStore'
import { WorkQueueStore } from '@operations/stores/WorkQueueStore'
import { BaseDrawerStore } from '@operations/stores/base/BaseDrawerStore'
import dayjs from 'dayjs'
import _ from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import { SplitFormProfile } from '../components/SplitForm/split-form.profile'
import { WorkQueueFormProfile } from '../components/WorkQueueForm/workqueue-form.profile'
import { WorkQueue, WorkQueueStackChange } from '../models/work-queue.model'
import { CraneSplitContainerUIStore, calculateEndTime } from './crane-split-container.ui-store'

export class CraneSplitDrawerUIStore extends BaseDrawerStore<WorkQueueDto> {
  vesselId?: number
  processingTimeIsOpen = false
  splitIsOpen = false
  workQueue?: WorkQueue

  constructor(
    protected workQueueStore: WorkQueueStore,
    private equipmentStore: EquipmentStore,
    private workInstructionStore: WorkInstructionStore,
    public craneSplitContainerUIStore: CraneSplitContainerUIStore,
  ) {
    super(workQueueStore)
    makeObservable(this, {
      workQueue: observable,
      vesselId: observable,
      processingTimeIsOpen: observable,
      splitIsOpen: observable,

      toggleProcessingTime: action,
      toggleSplit: action,
      setProcessingTime: action,
      toggleEditDrawer: action,
      setVesselId: action,
      editWorkQueue: action,
      splitWorkQueue: action,

      equipmentOptions: computed,
      processingTime: computed,
      workQueueDefaultValues: computed,
    })
  }

  public setVesselId(vesselId: number): void {
    if (this.vesselId != vesselId) this.vesselId = vesselId
  }

  public setProcessingTime(processingTime: number) {
    this.craneSplitContainerUIStore.setProcessingTime(processingTime)
  }

  public toggleProcessingTime() {
    this.processingTimeIsOpen = !this.processingTimeIsOpen
  }

  public toggleSplit(workQueue?: WorkQueue) {
    this.workQueue = workQueue
    this.splitIsOpen = !this.splitIsOpen
  }

  public toggleEditDrawer(workQueue?: WorkQueue) {
    this.workQueue = workQueue
    this.toggle()
  }

  public async editWorkQueue(newWorkQueueData: WorkQueueFormProfile) {
    if (!this.workQueue) {
      return
    }

    const workQueue = this.workQueue
    const workQueueDto = this.workQueueStore.items.find(x => x.id === workQueue.id)

    if (!workQueueDto) {
      return
    }

    const changes: WorkQueueStackChange[] = []
    const change: WorkQueueStackChange = {
      action: WorkQueueActionType.Edit,
      previous: { workQueue: { ...workQueueDto } },
    }

    const containersAmountDiff = workQueue.containersAmount - newWorkQueueData.containersAmount

    workQueueDto.containersAmount = newWorkQueueData.containersAmount
    workQueueDto.isTwinContainers = newWorkQueueData.twinContainers
    workQueueDto.moves = newWorkQueueData.totalMoves
    workQueueDto.startTime = newWorkQueueData.startDate.toString()
    workQueueDto.craneId = +newWorkQueueData.craneId

    this.workQueueStore.updateStoreItem(workQueueDto, +workQueue.id)

    change.current = { workQueue: { ...workQueueDto } }

    changes.push({ ...change })

    let workQueueId = workQueueDto.id
    let updateNext = false

    if (containersAmountDiff > 0) {
      change.previous!.workInstructionsIds =
        this.craneSplitContainerUIStore.getWorkInstructionsIdsByWorkQueueId(+workQueue.id)

      const nextWorkQueue = this.addNewWorkQueue(workQueueDto, containersAmountDiff)
      workQueueId = nextWorkQueue.current!.workQueue.id
      updateNext = true

      change.current.workInstructionsIds =
        this.craneSplitContainerUIStore.getWorkInstructionsIdsByWorkQueueId(+workQueue.id)

      changes.push(nextWorkQueue)
    }

    const bay = this.craneSplitContainerUIStore.calculatedBays.find(x => x.bay === workQueue.bay)
    const bayWorkQueue = bay?.workQueues.find(x => x.id === workQueueId)

    if (!bayWorkQueue) {
      return
    }

    const nextWorkQueues = bay?.workQueues.filter(
      x => x.bay === workQueue.bay && x.order >= bayWorkQueue?.order && x.id != bayWorkQueue?.id,
    )

    const firstNextWorkQueue = _(nextWorkQueues)
      .sortBy(x => x.startTime)
      .first()

    const bayWorkQueueEndDate = dayjs(bayWorkQueue.endDate).set('second', 0)

    if (
      nextWorkQueues &&
      firstNextWorkQueue &&
      (updateNext ||
        bayWorkQueueEndDate.isAfter(dayjs(firstNextWorkQueue.startTime).set('second', 0)))
    ) {
      const firstNextWorkQueueStartTime = dayjs(firstNextWorkQueue.startTime).set('second', 0)

      let lastOrder = bayWorkQueue.order
      let minutesToAdd = 0

      if (bayWorkQueueEndDate.isAfter(firstNextWorkQueueStartTime)) {
        minutesToAdd = dayjs(bayWorkQueue.endDate).diff(
          firstNextWorkQueue.startTime,
          'minute',
          true,
        )
      }

      nextWorkQueues.forEach(nextWorkQueue => {
        const workQueueDto = this.workQueueStore.items.find(x => x.id === nextWorkQueue.id)

        if (workQueueDto) {
          change.previous = { workQueue: { ...workQueueDto } }

          workQueueDto.order = lastOrder + 1
          workQueueDto.startTime = dayjs(workQueueDto.startTime)
            .add(minutesToAdd, 'minute')
            .toString()

          this.workQueueStore.updateStoreItem(workQueueDto, workQueueDto.id)
          change.current = { workQueue: { ...workQueueDto } }
          changes.push({ ...change })

          lastOrder = workQueueDto.order
        }
      })
    }

    this.craneSplitContainerUIStore.addWorkQueuesToStack(changes)
  }

  public splitWorkQueue(splitData: SplitFormProfile) {
    if (this.workQueue) {
      this.editWorkQueue({
        containersAmount: this.workQueue.containersAmount - splitData.containersAmount,
        totalMoves: splitData.totalMoves,
        twinContainers: this.workQueue.isTwinContainers,
        bay: this.workQueue.bay,
        craneId: this.workQueue.craneId,
        operationType: this.workQueue.operationType,
        position: this.workQueue.vesselArea,
        startDate: this.workQueue.startTime,
      })
    }
  }

  public get equipmentOptions(): SelectOption[] {
    return _(
      this.equipmentStore.items
        .filter(eq => eq.equipmentType === EquipmentType.Sts)
        .map(({ id, name }) => ({ label: name, value: id })),
    )
      .sortBy(i => i.label)
      .value()
  }

  public get operationTypeOptions(): SelectOption[] {
    return [
      {
        label: 'Discharge',
        value: OperationType.Inbound,
      },
      {
        label: 'Loading',
        value: OperationType.Outbound,
      },
    ]
  }

  public get processingTime() {
    return this.craneSplitContainerUIStore.processingTime
  }

  public get workQueueDefaultValues(): WorkQueueFormProfile {
    if (!this.workQueue) {
      return {
        bay: '',
        position: '',
        operationType: '',
        containersAmount: 1,
        twinContainers: false,
        totalMoves: 1,
        startDate: new Date(),
        craneId: '',
      }
    }

    return {
      bay: this.workQueue.bay,
      position: this.workQueue.vesselArea,
      operationType: this.workQueue.operationType,
      containersAmount: this.workQueue.containersAmount,
      twinContainers: this.workQueue.isTwinContainers,
      totalMoves: this.workQueue.moves,
      startDate: this.workQueue.startTime,
      craneId: this.workQueue.craneId,
    }
  }

  private addNewWorkQueue(
    baseWorkQueue: WorkQueueDto,
    containersAmount: number,
  ): WorkQueueStackChange {
    const newWorkQueueId = +_(this.workQueueStore.items).maxBy(x => x.id)!.id + 1

    const workQueueWorkInstructions = this.workInstructionStore.items.filter(
      x => x.workQueueId === baseWorkQueue.id && x.status !== WorkInstructionStatus.Finished,
    )

    const newWorkQueueContainers = _(workQueueWorkInstructions)
      .orderBy(x => x.workQueueOrder, 'desc')
      .take(containersAmount)
      .value()

    newWorkQueueContainers.forEach(x => (x.workQueueId = newWorkQueueId))

    const endTime = calculateEndTime(
      dayjs(baseWorkQueue.startTime).toDate(),
      baseWorkQueue.moves,
      baseWorkQueue.processingTimeSeconds,
    )

    const newWorkQueue: WorkQueueDto = {
      ...baseWorkQueue,
      id: newWorkQueueId,
      containersAmount: containersAmount,
      startTime: dayjs(endTime).set('second', 0).toString(),
      order: baseWorkQueue.order + 1,
    }

    newWorkQueue.moves = this.craneSplitContainerUIStore.calculateMoves(newWorkQueue)

    this.workQueueStore.items.push(newWorkQueue)

    return {
      action: WorkQueueActionType.Add,
      current: {
        workQueue: { ...newWorkQueue },
        workInstructionsIds: newWorkQueueContainers.map(x => x.id),
      },
    }
  }
}
