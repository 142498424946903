import { GeneralCargoApi } from '@billing/app/api'
import { createApiClient } from '@billing/app/http-client'

class OrdersService {
  async getFulfilledInboundOrders(customerId?: number, from?: string, to?: string) {
    const { data } = await createApiClient(GeneralCargoApi).getCompletedInboundOrders(
      customerId,
      from,
      to,
    )

    return data
  }

  async getStockInventory(customerId?: number, from?: string, to?: string) {
    const { data } = await createApiClient(GeneralCargoApi).getStockInventory(customerId, from, to)
    return data
  }

  async getFulfilledOrders(customerId?: number, from?: string, to?: string) {
    const { data } = await createApiClient(GeneralCargoApi).getCompletedHandlingOrders(
      customerId,
      from,
      to,
    )

    return data
  }
}

const ordersService = new OrdersService()

export default ordersService
