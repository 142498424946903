import { useAuth0 } from '@auth0/auth0-react'
import LogoutIcon from '@mui/icons-material/Logout'
import {
  AppBar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Toolbar,
} from '@mui/material'
import { ArrowLeftIcon, IconButton, useMinimalsTheme } from '@tom-ui/ui'
import { BurgerMenu } from '@planning/components/BurgerMenu'
import { useTranslate } from '@tolgee/react'
import { FC, ReactElement } from 'react'
import { useNavigate } from 'react-router'
import { qiankunWindow } from 'vite-plugin-qiankun/dist/helper'

interface Props {
  title: string
  subTitle?: string
  hideMenu?: boolean
  backButton?: boolean
  onGoBack?: () => Promise<void> | void
  children?: ReactElement
}

export const MobileAppBar: FC<Props> = ({
  title,
  subTitle,
  hideMenu,
  backButton,
  onGoBack,
  children,
}: Props) => {
  const { logout, isAuthenticated } = useAuth0()
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const navigate = useNavigate()

  const defaultOnGoBack = () => navigate(-1)

  return (
    <AppBar position='static' style={{ background: 'black' }}>
      <Toolbar>
        {!backButton && !hideMenu && isAuthenticated && !qiankunWindow.__POWERED_BY_QIANKUN__ && (
          <BurgerMenu>
            <MenuItem
              onClick={async () => {
                logout({ logoutParams: { returnTo: window.location.origin } })
              }}
            >
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              {t('logout', 'Logout')}
            </MenuItem>
          </BurgerMenu>
        )}
        {backButton && (
          <IconButton
            sx={{ color: theme.palette.common.white }}
            onClick={onGoBack ?? defaultOnGoBack}
          >
            <ArrowLeftIcon />
          </IconButton>
        )}
        <List sx={{ width: '100%' }}>
          <ListItem disablePadding={hideMenu}>
            <ListItemText
              primary={title}
              primaryTypographyProps={{ variant: 'h6' }}
              secondary={subTitle}
            />
          </ListItem>
        </List>
        {children}
      </Toolbar>
    </AppBar>
  )
}
