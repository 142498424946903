import { FC, ReactNode, useEffect, useState } from 'react'
import DevCycleProvider from '@devcycle/openfeature-web-provider'
import { OpenFeatureProvider, OpenFeature, EvaluationContextValue } from '@openfeature/react-sdk'
import { Loader } from '@tom-ui/ui'
import { getEnvName } from '../lib/get-env-name'

const getKey = () => {
  switch (getEnvName()) {
    case 'prod':
      return 'dvc_client_6139416c_ed46_4091_8dfb_c6844feda734_642016e'
    case 'stage':
      return 'dvc_client_669e47e9_ddb9_4992_bb83_952d309102a1_349a307'
    case 'demo':
      return 'dvc_client_4e167a91_3bc1_45db_805b_b5043c9748c3_96a6600'
    default:
      return 'dvc_client_12b691ad_cfe7_4ad4_9097_e6cf273419ed_37feade'
  }
}

const devcycleProvider = new DevCycleProvider(getKey())

export type FeatureFlagContext = {
  user_id: string
} & Record<string, EvaluationContextValue>

export const FeatureFlagProvider: FC<{ children: ReactNode; context: FeatureFlagContext }> = ({
  children,
  context,
}) => {
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    const init = async () => {
      OpenFeature.setContext(context)
      await OpenFeature.setProviderAndWait(devcycleProvider)
      setInitialized(true)
    }
    init()
  }, [context])

  if (!initialized) return <Loader show />

  return <OpenFeatureProvider>{children}</OpenFeatureProvider>
}
