import { VesselVisitDto } from '@planning/app/api'
import { GetVesselVisitsQuery, IEvent, IMessageBus } from '@planning/messages'
import { EventTypes } from '@planning/messages/eventsTypes'
import { GetVesselVisitsByIdsQuery } from '@planning/messages/queries'
import { GetVesselVisitByIdQuery } from '@planning/messages/queries/getVesselVisitByIdQueryHandler'
import _ from 'lodash'
import { action, computed, makeObservable } from 'mobx'
import { ItemStore } from '../base/ItemStore'
import { IBerthItem } from '../berth/BerthItem'
import { OrderItemStore } from '../order/OrderItemStore'
import { IEntityStore } from '../types'
import { IVesselItem } from '../vessel/VesselItem'
import { IVesselVisitItem, VesselVisitItem } from './VesselVisitItem'

export class VesselVisitItemStore
  extends ItemStore<VesselVisitDto, IVesselVisitItem>
  implements IEntityStore<IVesselVisitItem>
{
  private vesselStore?: IEntityStore<IVesselItem>
  private berthStore?: IEntityStore<IBerthItem>
  private orderItemStore?: OrderItemStore

  constructor(private messageBus: IMessageBus) {
    super((key, data) => new VesselVisitItem(key, data, this, this.vesselStore, this.berthStore), {
      messageBus,
      fetchFunc: (id: number) => new GetVesselVisitByIdQuery(id),
      bulkFetchFunc: (ids: number[]) => new GetVesselVisitsByIdsQuery(ids),
    })
    makeObservable(this, {
      receiveVesselVisitsMessage: action,
      receiveVesselVisitUpsertMessage: action,
      receiveVesselVisitDeletedMessage: action,
      ordersByVesselVisitId: computed,
    })

    messageBus.subscribeEvent(GetVesselVisitsQuery.type, this.receiveVesselVisitsMessage)
    messageBus.subscribeEvent(GetVesselVisitsByIdsQuery.type, this.receiveVesselVisitsMessage)
    messageBus.subscribeEvent(GetVesselVisitByIdQuery.type, this.receiveVesselVisitUpsertMessage)
    messageBus.subscribeEvent(EventTypes.VesselVisitCreated, this.receiveVesselVisitUpsertMessage)
    messageBus.subscribeEvent(EventTypes.VesselVisitUpdated, this.receiveVesselVisitUpsertMessage)
    messageBus.subscribeEvent(
      EventTypes.CarrierVisitConflictUpdated,
      this.receiveVesselVisitConflictUpdatedMessage,
    )
    messageBus.subscribeEvent(EventTypes.CarrierVisitDeleted, this.receiveVesselVisitDeletedMessage)
  }

  connect = async (
    vesselStore: IEntityStore<IVesselItem>,
    berthStore: IEntityStore<IBerthItem>,
    orderItemStore: OrderItemStore,
  ) => {
    this.vesselStore = vesselStore
    this.berthStore = berthStore
    this.orderItemStore = orderItemStore
  }

  get ordersByVesselVisitId() {
    return this.orderItemStore?.ordersByCarrierVisitId
  }

  fetch = async (from: Date, to: Date) => {
    return this.messageBus.dispatchQuery(new GetVesselVisitsQuery(from, to))
  }

  getById = (id: number) => {
    return _.get<IVesselVisitItem | undefined>(this.elements, id)
  }

  receiveVesselVisitsMessage = (event: IEvent<VesselVisitDto[]>): void => {
    if (event.payload) {
      this.upsertBulk(event.payload)
    }

    this.hasBeenInitialized = true
  }

  receiveVesselVisitUpsertMessage = (event: IEvent<VesselVisitDto>): void => {
    if (event.payload) {
      this.upsert(event.payload)
    }
  }

  receiveVesselVisitDeletedMessage = (event: IEvent<number>): void => {
    if (event.payload && _.has(this.elements, event.payload)) {
      this.delete(event.payload)
    }
  }

  receiveVesselVisitConflictUpdatedMessage = (
    event: IEvent<{ carrierVisitId: number; isConflicted: boolean }>,
  ): void => {
    if (event.payload) {
      const vesselVisit = this.getById(event.payload.carrierVisitId)
      if (vesselVisit) {
        vesselVisit.data.isConflicted = event.payload.isConflicted
      }
    }
  }
}
