import { WorkInstructionDto } from '@operations/app/api'
import { useOperationsStore } from '@operations/AppProvider'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { WorkInstructionsContainer } from '@operations/features/job/containers/WorkInstructionsContainer'
import { WorkInstructionsTypeDropdownContainer } from '@operations/features/job/containers/WorkInstructionsTypeDropdownContainer'
import { WorkInstructionTypes } from '@operations/features/job/stores/work-instruction.ui-store'
import { Container } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { MRT_TableInstance } from 'material-react-table'
import { FC, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet-async'

const WorkInstructions: FC = withAuthenticationRequired(() => {
  const { t } = useTranslate()

  const workInstructionsTableInstanceRef = useRef<MRT_TableInstance<WorkInstructionDto> | null>(
    null,
  )

  const {
    workInstructionUIStore,
    workInstructionFiltersUIStore,
    workInstructionStore,
    equipmentStore,
  } = useOperationsStore()

  useEffect(() => {
    workInstructionUIStore.setWorkInstructionType(WorkInstructionTypes.vessels)
    workInstructionUIStore.setVesselId()
    const fetchData = async () => {
      await Promise.all([workInstructionStore.load(), equipmentStore.load()])
    }
    fetchData()
  }, [workInstructionStore, workInstructionUIStore, equipmentStore])

  return (
    <>
      <Helmet>
        <title>{t('WorkInstructionsTomOperation', 'Work Instructions - Tom Operation')}</title>
      </Helmet>
      <Container maxWidth='xl'>
        <h2>{t('containerWorkInstructions', 'Container Work Instructions')}</h2>
        <WorkInstructionsContainer
          store={workInstructionUIStore}
          filterStore={workInstructionFiltersUIStore}
          subMenu={
            <WorkInstructionsTypeDropdownContainer
              workInstructionsTableInstanceRef={workInstructionsTableInstanceRef}
            />
          }
          workInstructionsTableInstanceRef={workInstructionsTableInstanceRef}
        />
      </Container>
    </>
  )
})

export default WorkInstructions
