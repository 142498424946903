import { ContainerJourneyDto, NonNumericOrderDto, OrderResponseDto } from '@planning/app/api'
import { IListStore } from '@planning/components/list/SimpleListStore'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { ContainerSearchLoader } from '../TallymanV2/Components/ContainerSearchLoader'
import { CustomAlert } from '../TallymanV2/Components/CustomAlert'

type Props = {
  store: IListStore<ContainerJourneyDto | OrderResponseDto | NonNumericOrderDto>
}

export const SearchOrdersState: FC<Props> = observer(({ store }) => {
  const { t } = useTranslate()

  return (
    <>
      {!store.filter && (
        <CustomAlert
          message={t(
            'searchTheLast4DigitsOfTheContainerOrReferenceNo',
            'Search the last 4 digits of the container or reference no',
          )}
          severity='info'
        />
      )}
      {!!store.filter && !store.isLoading && store.isNoMatches && (
        <CustomAlert
          message={t('noOrdersFoundContactControlRoom', 'No orders found, contact control room')}
          severity='warning'
        />
      )}
      {store.isLoading && <ContainerSearchLoader />}
    </>
  )
})
