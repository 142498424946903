import { Theme } from '@mui/material/styles'

// ----------------------------------------------------------------------

export default function Breadcrumbs(theme: Theme) {
  return {
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: {
          marginLeft: theme.customSpacing.m,
          marginRight: theme.customSpacing.m,
        },
        li: {
          display: 'inline-flex',
          margin: `0 ${theme.customSpacing.xxxs} 0`,
          '& > *': {
            ...theme.typography.body2,
          },
        },
      },
    },
  }
}
