import { Box, SxProps, Theme } from '@mui/material'
import { DamagedIcon, useMinimalsTheme } from '@tom-ui/ui'

export const DamagedContainerIcon = ({
  iconHeight,
  iconWeight,
  sx,
}: {
  iconHeight?: string
  iconWeight?: string
  sx?: SxProps<Theme>
}) => {
  const theme = useMinimalsTheme()

  return (
    <Box
      sx={{
        color: theme.palette.grey[600],
        background: theme.palette.grey[200],
        padding: '4px 8px',
        borderRadius: '4px',
        display: 'flex',
        ...sx,
      }}
    >
      <DamagedIcon sx={{ height: iconHeight, width: iconWeight }} />
    </Box>
  )
}
