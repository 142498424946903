import { Chip, Divider } from '@mui/material'

interface Props {
  qmmAt: number
  pixelPerWaterMark: number
  startWaterMark: number
  berthViewTotalWidth: number
}
export const BerthDivider = ({
  qmmAt,
  pixelPerWaterMark,
  startWaterMark,
  berthViewTotalWidth,
}: Props) => {
  return (
    <Divider
      textAlign='left'
      sx={{
        position: 'absolute',
        left: 0,
        top: `calc(${(qmmAt - startWaterMark) * pixelPerWaterMark}px - 12px)`,
        width: `calc(${berthViewTotalWidth}px + 100%)`,
        height: '24px',
        '::before': {
          width: '8%',
        },
        zIndex: 100,
      }}
    >
      <Chip label={qmmAt} size='small' />
    </Divider>
  )
}
