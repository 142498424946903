import { Stack } from '@mui/material'
import { Header } from '@tom-ui/ui'
import { ReactNode } from 'react'
import { HeaderAlert } from './HeaderAlert'
import { HeaderAvatar } from './HeaderAvatar'

interface Props {
  title: string
  subtitle?: string
  hideNotification?: boolean
  showSelectEquipment?: boolean
  showCorrectPosition?: boolean
  action?: ReactNode
}

export const HeaderWithLogout = ({
  title,
  subtitle,
  hideNotification,
  showSelectEquipment,
  showCorrectPosition,
  action,
}: Props) => {
  return (
    <Header
      title={title}
      subTitle={subtitle}
      rightRenderOption={
        <Stack flexDirection='row' gap={theme => theme.customSpacing.xs}>
          {action}
          {!hideNotification && <HeaderAlert />}
          <HeaderAvatar
            showCorrectPosition={showCorrectPosition}
            showSelectEquipment={showSelectEquipment}
          />
        </Stack>
      }
    />
  )
}
