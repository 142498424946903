import { Button, Grid, IconButton, TextField, Typography } from '@mui/material'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { usePlanningStore } from '@planning/AppProvider'
import { CargoType, CreateRailVisitCommand, RepeatByDateKind } from '@planning/app/api'
import { CarrierVisitNavigateButton } from '@planning/components/CarrierVisitNavigateButton'
import {
  CarrierVisitSchedule,
  ICarrierVisitSchedule,
  carrierVisitScheduleDefaultValues,
} from '@planning/components/CarrierVisitSchedule'
import { CargoTypeSelect } from '@planning/components/visit/CargoTypeSelect'
import railVisitService from '@planning/services/railVisitService'
import { useTranslate } from '@tolgee/react'
import { BinIcon } from '@tom-ui/ui'
import dayjs, { Dayjs } from 'dayjs'
import { observer } from 'mobx-react-lite'
import { ChangeEvent, FC, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

export interface IRailVisitFormData {
  name: string
  eta?: Dayjs
  etd?: Dayjs
  importVoyage: string
  exportVoyage: string
  importDraft: string
  exportDraft: string
  cargoType: CargoType
  repeatEvery: number
  repeatBy: RepeatByDateKind | ''
  endsOn?: Dayjs
  endsAfter?: number
  dischargeEstimate: string
  loadEstimate: string
}

export const CreateRailVisitForm: FC<{ onClose?: () => void }> = observer(({ onClose }) => {
  const { appViewStore } = usePlanningStore()
  const { t } = useTranslate()
  const [schedule, setSchedule] = useState<ICarrierVisitSchedule>(carrierVisitScheduleDefaultValues)

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      eta: dayjs(),
      etd: dayjs().add(1, 'day'),
      importVoyage: '',
      exportVoyage: '',
      importDraft: '',
      exportDraft: '',
      repeatingVisit: '',
      cargoType: CargoType.Container,
      repeatEvery: 1,
      repeatBy: RepeatByDateKind.Week,
      endsOn: dayjs().add(1, 'day'),
      endsAfter: 1,
      dischargeEstimate: '',
      loadEstimate: '',
    } as IRailVisitFormData,
  })

  const onFormSubmit = async (data: IRailVisitFormData) => {
    try {
      const createCmd = {
        ...data,
        inboundTripIds: tripIdsInputs.flatMap(obj => [obj.inbound]),
        outboundTripIds: tripIdsInputs.flatMap(obj => [obj.outbound]),
        loadEstimate: data.loadEstimate ? parseInt(data.loadEstimate.toString()) : 0,
        dischargeEstimate: data.dischargeEstimate ? parseInt(data.dischargeEstimate.toString()) : 0,
        railTrackIds: [],
        schedule: schedule.showTimingOptions
          ? {
              repeatEvery: parseFloat(schedule.repeatEvery.toString()),
              repeatBy: schedule.repeatBy,
              ...(schedule.endsOn &&
                schedule.selectedRadioOption === 'on' && { endsOn: schedule.endsOn }),
              ...(schedule.endsAfter &&
                schedule.selectedRadioOption === 'after' && {
                  endsAfter: parseFloat(schedule.endsAfter.toString()),
                }),
            }
          : null,
      } as CreateRailVisitCommand
      const responseDto = await railVisitService.post(createCmd)

      appViewStore.setCreatedRailVisitId(responseDto[0].id)
      appViewStore.setShowAlert(
        'success',
        t('railVisitCreatedSuccessfully', 'Rail visit created successfully'),
        <CarrierVisitNavigateButton />,
      )
      onClose?.()
    } catch (error) {
      appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
    }
  }

  const handleDateChange = (newValue: dayjs.Dayjs | null, onChange: any) => {
    if (newValue) {
      onChange(newValue)
    }
  }

  const [tripIdsInputs, setTripIdsInputs] = useState([{ id: 1, inbound: '', outbound: '' }])

  const duplicateTripIdsInputs = () => {
    const newId = Math.max(...tripIdsInputs.map(ti => ti.id), 0) + 1
    setTripIdsInputs([...tripIdsInputs, { id: newId, inbound: '', outbound: '' }])
  }

  const removeTripIds = (idToRemove: number) => {
    if (tripIdsInputs.length === 1) {
      setTripIdsInputs([{ id: 1, inbound: '', outbound: '' }])
    } else {
      const filteredInputs = tripIdsInputs.filter(input => input.id !== idToRemove)
      setTripIdsInputs(filteredInputs)
    }
  }

  const handleTripIdInputChanges = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    id: number,
    type: string,
  ) => {
    const { value } = event.target
    const updatedTripIds = tripIdsInputs.map(tripId => {
      if (tripId.id === id) {
        return { ...tripId, [type]: value }
      }
      return tripId
    })

    setTripIdsInputs(updatedTripIds)
  }

  return (
    <form id='rail-visit' onSubmit={handleSubmit(onFormSubmit)}>
      <TextField
        sx={{ mb: '1rem' }}
        fullWidth
        type='text'
        label={t('trainName', 'Train Name')}
        variant='outlined'
        {...register('name')}
        name='name'
        onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
        inputProps={{ min: 1 }}
        data-cy='train-name-field'
      />
      <Controller
        control={control}
        name={`cargoType`}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <CargoTypeSelect value={value} onChange={onChange} />
        )}
      />

      <Typography variant='subtitle1' marginTop='1rem' marginBottom='0.5rem'>
        {t('timingsEstimated', 'Timings (Estimated)')}
      </Typography>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container direction='row' columnSpacing={{ xs: 1 }}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={`eta`}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <DateTimePicker
                  label={t('eta', 'ETA')}
                  value={value}
                  onChange={newValue => handleDateChange(newValue, onChange)}
                  slotProps={{
                    textField: {
                      variant: 'outlined',
                      helperText: errors.eta ? t('fieldIsRequired', 'Field is required.') : '',
                      error: !!errors.eta,
                      sx: { width: '100%', marginTop: '10px' },
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={`etd`}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <DateTimePicker
                  label={t('etd', 'ETD')}
                  value={value}
                  onChange={newValue => handleDateChange(newValue, onChange)}
                  slotProps={{
                    textField: {
                      variant: 'outlined',
                      helperText: errors.etd ? t('fieldIsRequired', 'Field is required.') : '',
                      error: !!errors.etd,
                      sx: { width: '100%', marginTop: '10px' },
                    },
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        <CarrierVisitSchedule schedule={schedule} handleSchedule={setSchedule} />
      </LocalizationProvider>

      <Typography variant='subtitle1' marginTop='1rem' marginBottom='0.5rem'>
        {t('tripIds', 'Trip IDs')}
      </Typography>
      <Grid container>
        {tripIdsInputs.map((input, index) => (
          <Grid key={index} container direction='row' columnSpacing={{ xs: 1 }} mb={'.5rem'}>
            <Grid item xs={5}>
              <TextField
                size='small'
                fullWidth
                value={input.inbound}
                label={t('inboundTripId', 'Inbound Trip ID')}
                variant='outlined'
                name='importVoyage'
                onChange={event => handleTripIdInputChanges(event, input.id, 'inbound')}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                size='small'
                fullWidth
                value={input.outbound}
                label={t('outboundTripId', 'Outbound Trip ID')}
                variant='outlined'
                name='exportVoyage'
                onChange={event => handleTripIdInputChanges(event, input.id, 'outbound')}
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton color='error' onClick={() => removeTripIds(input.id)}>
                <BinIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}

        <Grid item mt={'.5rem'}>
          <Button size='small' onClick={duplicateTripIdsInputs}>
            + Add
          </Button>
        </Grid>
      </Grid>

      <Typography variant='subtitle1' marginTop='1rem' marginBottom='0.5rem'>
        {t('dischargeEstimates', 'Discharge Estimates')}
      </Typography>
      <TextField
        fullWidth
        type='number'
        label={t('dischargeEstimate', 'Discharge Estimate')}
        variant='outlined'
        {...register('dischargeEstimate')}
        name='dischargeEstimate'
        onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
        inputProps={{ min: 1 }}
      />
      <Typography variant='subtitle1' marginTop='1rem' marginBottom='0.5rem'>
        {t('loadEstimates', 'Load Estimates')}
      </Typography>
      <TextField
        fullWidth
        type='number'
        label={t('loadEstimate', 'Load Estimate')}
        variant='outlined'
        {...register('loadEstimate')}
        name='loadEstimate'
        onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
        inputProps={{ min: 1 }}
      />
    </form>
  )
})
