import { Button, PopperPlacementType } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { Dayjs } from 'dayjs'
import { FC, useState } from 'react'
import { CustomDateTimePicker } from './CustomDateTimePicker'

interface Props {
  label: string
  value?: string | null
  popperPlacement?: PopperPlacementType
  pickerButtonTestId?: string
  confirmButtonTestId?: string
  onChange: (value: Dayjs | null) => void
}

export const CustomDateTimePickerButton: FC<Props> = ({
  label,
  value,
  popperPlacement,
  pickerButtonTestId,
  confirmButtonTestId,
  onChange,
}) => {
  const [edit, setEdit] = useState(false)
  const { t } = useTranslate()
  return (
    <>
      {(edit && (
        <CustomDateTimePicker
          confirmButtonTestId={confirmButtonTestId}
          label={`${label}`}
          value={value}
          onChange={onChange}
          onClose={() => setEdit(false)}
          defaultOpen
          popperPlacement={popperPlacement}
        />
      )) || (
        <Button
          data-cy={pickerButtonTestId}
          variant='outlined'
          color='secondary'
          size='large'
          sx={{ height: '3.5rem' }}
          onClick={() => setEdit(true)}
        >
          {t('set', 'Set')} {label}
        </Button>
      )}
    </>
  )
}
