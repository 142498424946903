import { BerthsDialogContainer, BerthsListContainer } from '@storage/features/berths/containers'
import { BerthsFeatureStore } from '@storage/features/berths/stores'
import { useStores } from '@storage/hooks/use-stores.hook'
import { Box, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { useEffect } from 'react'

const Berths = () => {
  const { berthStore, pierStore } = useStores()
  const { t } = useTranslate()
  const featureStore = new BerthsFeatureStore(berthStore, pierStore)

  useEffect(() => {
    const loadPageData = async () => {
      await berthStore.loadList()
      await pierStore.loadList()
    }

    loadPageData()
  }, [berthStore, pierStore])

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Box textAlign='center'>
        <Typography variant='h3'>{t('berths', 'Berths')}</Typography>
      </Box>

      <BerthsListContainer featureStore={featureStore} />

      <BerthsDialogContainer featureStore={featureStore} />
    </Box>
  )
}

export default Berths
