import { createSvgIcon } from '@mui/material'

export const ChevronDownIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M11.9881 16C11.7548 16.0004 11.5288 15.9191 11.3492 15.77L5.35967 10.77C4.93515 10.4165 4.87705 9.78525 5.22989 9.35999C5.58274 8.93473 6.21291 8.87653 6.63743 9.22999L11.9881 13.71L17.3387 9.38999C17.5449 9.22222 17.8094 9.14372 18.0736 9.17188C18.3378 9.20003 18.5798 9.33251 18.7462 9.53999C18.931 9.74785 19.021 10.0235 18.9945 10.3006C18.9679 10.5776 18.8273 10.8311 18.6065 11L12.617 15.83C12.4322 15.9555 12.2108 16.0154 11.9881 16Z'
      fill='currentColor'
    />
  </svg>,
  'ChevronDownIcon',
)
