import { createSvgIcon } from '@mui/material'

export const DamagedIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M2.02772 9.66186C2.58258 8.08374 4.14617 7 5.86325 7C7.4535 7 8.75328 7.97773 9.35064 9.35295H14.6493C15.2467 7.97773 16.5463 7 18.1367 7C19.854 7 21.4175 8.08391 21.9722 9.66186C22.0233 9.80597 22.0032 9.96698 21.9182 10.0929C21.8334 10.2188 21.6945 10.2938 21.5461 10.2941H19.0388L18.4918 12L19.0388 13.7059H21.5461C21.6928 13.7071 21.8297 13.7813 21.9142 13.9052C21.9987 14.0291 22.0204 14.1877 21.9722 14.331C21.4174 15.9089 19.8539 17 18.1367 17C16.5487 17 15.2479 16.019 14.6493 14.647H9.35064C8.75203 16.019 7.45126 17 5.86325 17C4.14601 17 2.58242 15.9089 2.02772 14.331C1.97968 14.1877 2.00124 14.0291 2.08574 13.9052C2.17023 13.7813 2.30737 13.7073 2.45385 13.7061H4.96118L5.50813 12.0002L4.96118 10.2943H2.45385C2.30546 10.2939 2.16659 10.2188 2.08177 10.0929C1.99696 9.96697 1.97667 9.80596 2.02772 9.66186Z'
      fill='currentColor'
    />
  </svg>,
  'DamagedIcon',
)
