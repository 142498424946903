import { DrawerFormContainer } from '@tom-ui/ui'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useAdminStore } from '@admin/AppProvider'
import { UserForm } from '../components/form/UserForm'
import { UserFormMapper } from '../components/form/user-form.mapper'
import { UserFormProfile } from '../components/form/user-form.profile'

export const UserDrawerContainer = observer(() => {
  const { userStore, userDrawerUIStore } = useAdminStore()

  const handleSubmit = async (formValues: UserFormProfile) => {
    if (userDrawerUIStore.formType === 'Add') {
      await userStore.add(UserFormMapper.mapFormValuesToCreateDto(formValues))
    } else {
      await userStore.update(UserFormMapper.mapFormValuesToUpdateDto(formValues))
    }

    userDrawerUIStore.toggle()
  }

  const { t } = useTranslate()

  return (
    <DrawerFormContainer
      open={userDrawerUIStore.isOpen}
      title={`${userDrawerUIStore.formType} ${t('user', 'User')}`}
      primaryActionLabel={userDrawerUIStore.formType}
      closeActionLabel={t('close', 'Close')}
      formId='user-form'
      onClose={() => userDrawerUIStore.toggle()}
    >
      <UserForm
        id='user-form'
        user={userDrawerUIStore.editingEntity}
        tenantOptions={userDrawerUIStore.tenantOptions}
        onSubmit={handleSubmit}
      />
    </DrawerFormContainer>
  )
})
