import { Stack, Typography } from '@mui/material'
import { RailTrackResponseDto } from '@planning/app/api'
import { RailTrackChips } from '@planning/components/visit/RailTrackChips'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { Controller, useFormContext } from 'react-hook-form'

interface Props {
  railTracks: RailTrackResponseDto[]
  allocatedRailTrackIds?: string[]
  isEditMode?: boolean
}
export const RailTrackField = observer(
  ({ isEditMode, railTracks, allocatedRailTrackIds }: Props) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()

    const {
      control,
      formState: { errors },
    } = useFormContext()

    return (
      <>
        <Stack flexDirection='row' alignItems='center'>
          <Typography variant='subtitle1' marginTop='1rem' marginBottom='0.5rem'>
            {t('tracksSelection', 'Tracks selection')}
          </Typography>
          <Typography sx={{ color: `${theme.palette.error.main} !important` }}> *</Typography>
        </Stack>

        <Controller
          control={control}
          name={`railTrackIds`}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <RailTrackChips
              railTracks={railTracks}
              allocatedRailTrackIds={allocatedRailTrackIds}
              disabled={isEditMode}
              value={value}
              onChange={onChange}
            />
          )}
        />

        {errors.railTrackIds && (
          <Typography variant='body2' color='error'>
            {t('fieldIsRequired', 'Field is required.')}
          </Typography>
        )}
      </>
    )
  },
)
