import { Box, Card, Divider, Typography } from '@mui/material'
import { PreFilterBox } from '@storage/components/PreFilterBox'
import {
  ContainerTurnoversFilterFormProfile,
  defaultValues,
} from '@storage/pages/container-turnovers/components/container-turnovers-filter-form'
import { ContainerTurnoversFilterStore } from '@storage/pages/container-turnovers/stores/container-turnovers-filter.store'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { PrefiltersStore } from '../stores'

interface PreFilterWidgetProps {
  prefiltersStore: PrefiltersStore
  containerTurnoversFilterStore: ContainerTurnoversFilterStore
  filterDefaultValues: Partial<ContainerTurnoversFilterFormProfile>
}
export const PreFilterWidget = observer(
  ({
    prefiltersStore,
    containerTurnoversFilterStore,
    filterDefaultValues,
  }: PreFilterWidgetProps) => {
    const { t } = useTranslate()
    const { palette } = useMinimalsTheme()

    return (
      <Card sx={{ borderRadius: '8px' }}>
        <Box
          sx={{ padding: '16px 16px 11px 16px', borderBottom: `1px solid ${palette.grey[300]}` }}
        >
          <Typography variant='h6' color={palette.text.primary}>
            {t('workload', 'Workload')}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }} data-cy='workload-box'>
          {prefiltersStore.prefilters?.map((filter, i) => (
            <Box key={i} data-cy='workload-prefilter'>
              <PreFilterBox
                selected={prefiltersStore.selectedPrefilter?.index === i}
                selectedLocationStatus={
                  prefiltersStore.selectedPrefilter?.index === i
                    ? prefiltersStore.selectedPrefilter.locationStatus
                    : undefined
                }
                filter={filter}
                onClick={() => {
                  prefiltersStore.setSelectedPrefilter({ index: i })
                  // Case of clicking on all or or other prefilters
                  // All case is identified by empty settings
                  filter.settings
                    ? containerTurnoversFilterStore.setFilter({
                        ...defaultValues,
                        ...filterDefaultValues,
                        ...filter.settings,
                        locationStatus: 'NotStackedIn',
                      })
                    : containerTurnoversFilterStore.setFilter({
                        ...defaultValues,
                        ...filterDefaultValues,
                        locationStatus: 'NotStackedIn',
                      })
                }}
                onShowPlanned={() => {
                  prefiltersStore.setSelectedPrefilter({ index: i, locationStatus: 'Reserved' })

                  filter.settings
                    ? containerTurnoversFilterStore.setFilter({
                        ...defaultValues,
                        ...filterDefaultValues,
                        ...filter.settings,
                        locationStatus: 'Reserved',
                      })
                    : containerTurnoversFilterStore.setFilter({
                        ...defaultValues,
                        ...filterDefaultValues,
                        locationStatus: 'Reserved',
                      })
                }}
                onShowUnplanned={() => {
                  prefiltersStore.setSelectedPrefilter({
                    index: i,
                    locationStatus: 'NotReserved',
                  })

                  filter.settings
                    ? containerTurnoversFilterStore.setFilter({
                        ...defaultValues,
                        ...filterDefaultValues,
                        ...filter.settings,
                        locationStatus: 'NotReserved',
                      })
                    : containerTurnoversFilterStore.setFilter({
                        ...defaultValues,
                        ...filterDefaultValues,
                        locationStatus: 'NotReserved',
                      })
                }}
              />
              {i === 0 && (
                <Divider variant='middle' sx={{ py: 1 }}>
                  <Typography variant='button'>{t('breakDown', 'Breakdown')}</Typography>
                </Divider>
              )}
            </Box>
          ))}
        </Box>
      </Card>
    )
  },
)
