import { AppViewStore } from '@planning/stores/AppViewStore'
import { InternalAxiosRequestConfig } from 'axios'
import { ENDPOINTS_EXCLUDED_FROM_LOADING } from '../exclude-from-loading'

export const onFulfilled = (appStore: AppViewStore) => {
  return (request: InternalAxiosRequestConfig<any>) => {
    if (
      !ENDPOINTS_EXCLUDED_FROM_LOADING.some(
        (endpoint: any) => new URL(request.url!).pathname === endpoint,
      )
    ) {
      appStore.showLoader()
    }

    return request
  }
}

export const onRejected = (appStore: AppViewStore) => {
  return (error: Error) => {
    appStore.hideLoader()
    throw error
  }
}
