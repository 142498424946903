import { NorthEast, SouthEast } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import {
  CarrierVisitDirection,
  OrderResponseDto,
  OrderStatus,
  RailVisitResponseDto,
  TruckVisitDto,
  VesselVisitDto,
} from '@planning/app/api'
import { simplifyWeightMeasurementUnit } from '@planning/app/utils'
import { CarrierTypeIcon } from '@planning/components/CarrierTypeIcon'
import { formatDateTime } from '@planning/utils'
import { useTranslate } from '@tolgee/react'
import { ColoredLabel, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'

interface IProps {
  order: OrderResponseDto
  damageAmount?: number
  carrierVisits?: VesselVisitDto[] | TruckVisitDto[] | RailVisitResponseDto[]
  renderReservations?: (order: OrderResponseDto) => React.ReactElement
  onEdit: (order: OrderResponseDto) => void
}

export const GeneralCargoOrdersCard = observer(
  ({ order, damageAmount, carrierVisits, renderReservations, onEdit }: IProps) => {
    const theme = useMinimalsTheme()
    const { t } = useTranslate()
    const carrierVisitId = order.carrierVisitId !== null ? order.carrierVisitId : undefined
    const actualVisit = carrierVisits?.filter(
      (v: { id: number | undefined }) => v.id === carrierVisitId,
    )[0]

    const CommodityCharacteristics = () => (
      <Box display='flex' alignItems='center' gap={theme.customSpacing.m} mb={0.5}>
        <Typography
          sx={{
            maxWidth: '300px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          variant='h6'
        >
          {order.commodityName}
        </Typography>
        <Typography
          sx={{
            maxWidth: '400px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          variant='body1'
          color={theme.palette.secondary.main}
        >
          {order.commodityAttributes?.map(ca => `${ca.name}: ${ca.value}`).join(' | ')}
        </Typography>
        {order.grossWeight != null ? (
          <Typography variant='body1' color={theme.palette.secondary.dark}>
            {order.grossWeight}
            {simplifyWeightMeasurementUnit(order.weightUnit)}
          </Typography>
        ) : (
          ''
        )}
      </Box>
    )

    const OrderDetails = () => (
      <Box display='flex' gap={theme.customSpacing.m}>
        <Typography variant='body1'>{order.customerName}</Typography>
        <Typography variant='body1'>{order.referenceNumber}</Typography>
        {/* <Typography variant='body1'>
        {t('orderNo', 'Order no')}: {order.orderNumber}
      </Typography>
      <Typography variant='body1'>
        {t('b/l', 'B/L')}: {order.billOfLading}
      </Typography> */}
        {order.lotNumber != null ? (
          <Typography variant='body1'>
            {t('lot', 'LOT')}: {order.lotNumber}
          </Typography>
        ) : (
          ''
        )}
        {order.packageName && (
          <Typography variant='body1'>
            {t('package', 'Package')}: {order.packageName}
          </Typography>
        )}
      </Box>
    )

    const CarrierVisitDetails = () => (
      <Box display='flex' gap={theme.customSpacing.s}>
        {CarrierTypeIcon({ type: order.carrierVisitType })}
        <Typography variant='body1'>{actualVisit?.identifier}</Typography>

        {actualVisit?.ata || actualVisit?.eta ? (
          <Typography variant='body1'>
            {formatDateTime(new Date(actualVisit?.ata ?? actualVisit?.eta ?? ''))}
          </Typography>
        ) : (
          ''
        )}
      </Box>
    )

    const CarrierVisitDirectionDetails = () => (
      <Box display='flex' gap={theme.customSpacing.s}>
        {order.direction === CarrierVisitDirection.Inbound ? <SouthEast /> : <NorthEast />}

        <Typography variant='subtitle1'>
          {order.direction === CarrierVisitDirection.Inbound
            ? t('inbound', 'Inbound')
            : t('outbound', 'Outbound')}
        </Typography>
      </Box>
    )

    const QuantityAndStatusDetails = () => (
      <>
        {order.plannedCargoAmount ? (
          <Typography variant='body1'>
            {t('planned', 'Planned')}: <b>{order.plannedCargoAmount}</b>
          </Typography>
        ) : (
          ''
        )}

        {order.actualCargoAmount || order.actualCargoAmount == 0 ? (
          <Typography
            variant='body1'
            color={
              order.actualCargoAmount == order.plannedCargoAmount
                ? theme.palette.success.main
                : theme.palette.warning.dark
            }
          >
            {t('actual', 'Actual')}: <b>{order.actualCargoAmount}</b>
          </Typography>
        ) : (
          ''
        )}

        {!!damageAmount && (
          <Typography variant='body1' color={theme.palette.error.main}>
            {t('damaged', 'Damaged')}: <b>{damageAmount}</b>
          </Typography>
        )}

        <ColoredLabel
          label={
            order.status === OrderStatus.Open ? t('open', 'Open') : t('fulfilled', 'Fulfilled')
          }
          color={order.status === OrderStatus.Open ? 'info' : 'success'}
        />
      </>
    )

    return (
      <Box
        // onClick={() => onEdit(order)}
        sx={{
          mb: '1rem',
        }}
      >
        {/* CARD HEADER */}
        <Box
          onClick={() => onEdit(order)}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: theme.customSpacing.m,
            borderTopRightRadius: theme.customRadius.medium,
            borderTopLeftRadius: theme.customRadius.medium,
            borderBottom: `1px solid ${theme.palette.divider}`,
            backgroundColor: '#fff',
            boxShadow: theme.customShadows.card,
          }}
        >
          {/* Commodity characteritics */}
          <Box>
            <CommodityCharacteristics />
            <OrderDetails />
          </Box>

          <Box display='flex' gap={theme.customSpacing.m} alignItems={'center'}>
            <QuantityAndStatusDetails />
          </Box>
        </Box>

        {/* RESERVATION DETAILS */}
        {renderReservations && (
          <Box
            sx={{
              borderBottom: `1px solid ${theme.palette.divider}`,
              backgroundColor: '#fff',
            }}
          >
            {renderReservations(order)}
          </Box>
        )}

        {/* CARD CONTENT */}
        <Box
          onClick={() => onEdit(order)}
          sx={{
            display: 'flex',
            gap: theme.customSpacing.xl,
            padding: theme.customSpacing.m,
            borderBottomRightRadius: theme.customRadius.medium,
            borderBottomLeftRadius: theme.customRadius.medium,
            backgroundColor: '#F4F6F8',
            boxShadow: theme.customShadows.card,
          }}
        >
          {/* DIRECTION */}
          <CarrierVisitDirectionDetails />

          {/* CARRIER VISIT */}
          <CarrierVisitDetails />
        </Box>
      </Box>
    )
  },
)
