import { EquipmentType } from '@storage/app/api'
import { FormType } from '@storage/app/models'

export enum LabelingPatternType {
  Numeric = 'Numeric',
  Alphabetic = 'Alphabetic',
}

export enum StackingStrategy {
  BayRowTier = 'BayRowTier',
  RowBayTier = 'RowBayTier',
  TierBayRow = 'TierBayRow',
  TierRowBay = 'TierRowBay',
}

export interface YardBlockFormProfile {
  id?: string
  name: string
  maxTier: number
  rowsPatternType?: LabelingPatternType
  rowsFrom?: string
  rowsTo?: string
  baysPatternType?: LabelingPatternType
  baysFrom?: string
  baysTo?: string
  equipmentTypes: EquipmentType[]
  usageOptions?: string[]
  stackingStrategy: StackingStrategy
}

export interface YardBlockFormContext {
  type: FormType
}

export const defaultValues: YardBlockFormProfile = {
  name: '',
  maxTier: 1,
  rowsPatternType: LabelingPatternType.Alphabetic,
  rowsFrom: 'A',
  rowsTo: 'D',
  baysPatternType: LabelingPatternType.Numeric,
  baysFrom: '01',
  baysTo: '08',
  equipmentTypes: [EquipmentType.Rs],
  stackingStrategy: StackingStrategy.TierBayRow,
}
