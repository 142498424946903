import { useStores } from '@storage/hooks/use-stores.hook'
import { YardBayViewStore } from '@storage/pages/yard-management/stores/YardBayViewStore'
import { YardDataStore } from '@storage/pages/yard-management/stores/YardDataStore'
import { useMemo } from 'react'
import { YardSetupStore } from '../stores/yard-setup.store'

export const useYardSetupStores = () => {
  const rootStore = useStores()

  const yardDataStore = useMemo(() => new YardDataStore(rootStore), [rootStore])

  const yardBayViewStore = useMemo(() => new YardBayViewStore(yardDataStore), [yardDataStore])

  const yardSetupStore = useMemo(
    () => new YardSetupStore(yardDataStore, yardBayViewStore),
    [yardBayViewStore, yardDataStore],
  )
  return {
    yardDataStore,
    yardSetupStore,
    yardBayViewStore,
  }
}
