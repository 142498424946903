import Permission from '@admin/app/models/permission.enum'
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { ConfirmationDialog } from '@tom-ui/ui'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useAdminStore } from '@admin/AppProvider'

export interface AssignRoleDialogProps {
  selectedRoles: string[]
  userId: string
  open: boolean
  onClose: () => void
}

export const AssignRoleDialog = observer(
  ({ selectedRoles, userId, open, onClose }: AssignRoleDialogProps) => {
    const { t } = useTranslate()
    const { userStore, roleStore, authStore } = useAdminStore()

    const [roleValues, setRoleValues] = useState(selectedRoles)

    useEffect(() => {
      setRoleValues(selectedRoles)
    }, [selectedRoles])

    const handleSave = async () => {
      userStore.updateUserRoles(userId, [...roleValues])
      handleClose()
      await userStore.load()
    }

    const handleClose = () => {
      onClose()
    }

    const handleUserRoleChange = (event: SelectChangeEvent<typeof roleValues>) => {
      const newRoles = typeof event.target.value === 'string' ? [] : event.target.value
      setRoleValues(newRoles)
    }

    return (
      <ConfirmationDialog
        open={open}
        title={t('assignRoles', 'Assign Roles')}
        primaryActionText={t('save', 'Save')}
        closeLabel={t('close', 'Close')}
        onConfirm={handleSave}
        onClose={handleClose}
        maxWidth='sm'
      >
        {authStore.hasPermission(Permission.AssignRoles) && (
          <FormControl fullWidth sx={{ paddingX: 2 }}>
            <Select
              multiple
              value={roleValues}
              onChange={handleUserRoleChange}
              renderValue={selectedRoleIds => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selectedRoleIds.map(roleId => (
                    <Chip key={roleId} label={roleStore.data.find(r => r.id === roleId)?.name} />
                  ))}
                </Box>
              )}
            >
              {roleStore.data.map(role => (
                <MenuItem key={role.id} value={role.id}>
                  <ListItemIcon>
                    <Checkbox checked={roleValues.indexOf(role.id.toString()) > -1} />
                  </ListItemIcon>
                  <ListItemText primary={role.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </ConfirmationDialog>
    )
  },
)
