import _ from 'lodash'
import { action, makeObservable, observable } from 'mobx'
import { JobNotificationDto, JobNotificationUpsertedDto } from '../models/job-notification.model'

export class EquipmentOperatorNotificationUIStore {
  jobNotifications: JobNotificationDto[] = []

  constructor() {
    makeObservable(this, {
      jobNotifications: observable,

      addNotification: action,
      deleteJobsFromNotificationByWorkInstructionId: action,
      markNotificationsAsRead: action,
      clearNotifications: action,
    })
  }

  public addNotification(notification: JobNotificationUpsertedDto) {
    const currentNotification = this.jobNotifications.find(x =>
      _.isEqual(x.jobWorkInstructionIds, notification.jobWorkInstructionIds),
    )

    if (currentNotification) {
      this.deleteJobsFromNotificationByWorkInstructionId(currentNotification.jobWorkInstructionIds)
    }

    this.jobNotifications.push({
      ...notification,
      notificationDateTime: new Date(),
      isRead: false,
    })
  }

  public deleteJobsFromNotificationByWorkInstructionId(workInstructionIds: number[]) {
    const notificationToUpdate = this.jobNotifications.filter(x =>
      x.jobWorkInstructionIds.some(y => workInstructionIds.includes(y)),
    )

    if (!notificationToUpdate.length) {
      return
    }

    const notificationToNotUpdate = this.jobNotifications.filter(
      x => !x.jobWorkInstructionIds.some(y => workInstructionIds.includes(y)),
    )

    const updatedNotifications = notificationToUpdate.reduce(
      (newArray: JobNotificationDto[], item) => {
        item.jobWorkInstructionIds = item.jobWorkInstructionIds.filter(
          i => !workInstructionIds.includes(i),
        )

        if (item.jobWorkInstructionIds.length) {
          newArray.push(item)
        }
        return newArray
      },
      [],
    )

    this.jobNotifications = _.union(notificationToNotUpdate, updatedNotifications)
  }

  public markNotificationsAsRead() {
    this.jobNotifications = this.jobNotifications.map(x => {
      x.isRead = true
      return x
    })
  }

  public clearNotifications() {
    this.jobNotifications = []
  }
}
