import { Autocomplete, FormControl, FormHelperText, TextField } from '@mui/material'
import { ContainerPositionType, JobDto } from '@operations/app/api'
import { SelectOption } from '@operations/app/models'
import { useTranslate } from '@tolgee/react'
import { matchSorter } from 'match-sorter'
import { JobInformationJourney } from '../JobInformation/JobInformationJourney'
import { JobInformationOperationalInstructions } from '../JobInformation/JobInformationOperationalInstructions'
import { JobInformationWrapper } from '../JobInformation/JobInformationWrapper'
import { ConfirmPicks } from './ConfirmPicks'
import { EquipmentOperatorProps } from './edit-destination.interface'

interface Props extends EquipmentOperatorProps {
  job: JobDto
  yardBlockOptions: SelectOption[]
  selectedYardBlock?: SelectOption | null
  containerOrigin?: string
  currentStep: number
  stepsTotal: number
  skippedEquipmentAssignment?: boolean
  hasError?: boolean
  selectedContainerNumber?: string | null
  handleChange: (newValue?: SelectOption | null) => void
}

export const EditDestination = ({
  job,
  yardBlockOptions,
  selectedYardBlock,
  containerOrigin,
  currentStep,
  stepsTotal,
  skippedEquipmentAssignment,
  hasError,
  EditDestinationComponent,
  ConfirmPickupComponent,
  selectedContainerNumber,
  handleChange,
  handlePicks,
}: Props) => {
  const { t } = useTranslate()

  const handleEditDestination = (event: any, value: SelectOption | null) => {
    handleChange(value)
  }

  return (
    <JobInformationWrapper job={job} currentStep={currentStep} stepsTotal={stepsTotal}>
      {job.from.type === ContainerPositionType.Yard && skippedEquipmentAssignment && (
        <ConfirmPicks
          DestinationComponent={ConfirmPickupComponent}
          containerNumber={job.container?.containerNumber ?? ''}
          handlePicks={handlePicks}
        />
      )}

      {(!currentStep || currentStep === 1) && <JobInformationOperationalInstructions job={job} />}

      <JobInformationJourney job={job} origin={containerOrigin}>
        {EditDestinationComponent ? (
          <EditDestinationComponent
            containerNumber={selectedContainerNumber ?? job.container!.containerNumber!}
            hasError={hasError}
            handleChange={handleChange}
            orderId={job.order?.externalId}
          />
        ) : (
          <FormControl fullWidth error={hasError}>
            <Autocomplete
              options={yardBlockOptions}
              value={selectedYardBlock}
              onChange={handleEditDestination}
              fullWidth
              getOptionLabel={option =>
                option?.label ?? t('noOptionSelected', 'no option selected')
              }
              isOptionEqualToValue={(option, value) => option?.value === value?.value}
              renderInput={params => (
                <TextField
                  {...params}
                  error={hasError}
                  required
                  label={'🪄 Destination from magic team 🪄'}
                />
              )}
              filterOptions={(options, { inputValue }) => {
                return matchSorter(options, inputValue.replace(/\s/g, ''), {
                  keys: [item => item.label.replace(/\./g, '').replace(/\s/g, '')],
                  threshold: matchSorter.rankings.CONTAINS,
                })
              }}
            />
            {hasError && (
              <FormHelperText>
                {t('destinationIsMandatory', 'Destination is mandatory')}
              </FormHelperText>
            )}
          </FormControl>
        )}
      </JobInformationJourney>
    </JobInformationWrapper>
  )
}
