import { StageWrapper } from '@storage/components/stage'
import { useHubConnection } from '@storage/hooks/use-hub-connection.hook'
import { yardManagementHubConnection } from '@storage/hubs/yard-management.hub-connection'
import { Box } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { FlexibleZoneLayer } from './components/FlexibleZoneLayer'
import { YardBlockBayOverviewDialog } from './components/YardBlockBayOverviewDialog'
import { YardBlocksLayer } from './components/YardBlocksLayer'
import { YardStateToolbar } from './components/YardStateToolbar'
import { YardSummary } from './components/widgets'
import { useYardManagementStores } from './hooks'
import { useHandleYardFlow } from './hooks/use-handle-yard-flow.hook'
import { YardBerthsLayer } from './layers/berths'

const YardManagement = observer(() => {
  const [queryParams] = useSearchParams()

  const yardManagementQueryParams: Record<string, string> = Object.fromEntries(
    queryParams.entries(),
  )

  const { yardDataStore, yardViewStore, stacksAllocationStore } = useYardManagementStores()

  const containerRef = useRef<any>()

  useHandleYardFlow({ yardViewStore })

  useHubConnection(yardManagementHubConnection)

  useEffect(() => {
    const loadData = async () => {
      await yardDataStore.load()
    }

    loadData()
  }, [yardDataStore, yardViewStore])

  return (
    <Box sx={{ display: 'flex', flexFlow: 'row', width: '100%', height: '100vh' }}>
      <Box sx={{ display: 'flex', flexFlow: 'column', flexGrow: 1 }}>
        <Box sx={{ height: '100%', position: 'relative' }} ref={containerRef}>
          <StageWrapper
            stageStore={yardViewStore.yardStageStore}
            boundingBox={yardDataStore.yardBlockStore.yardBlocksBoundingBox}
          >
            <YardBlocksLayer
              yardStore={yardViewStore}
              isAllocating={yardViewStore.isPlanning}
              stacksAllocationStore={stacksAllocationStore}
            />
            <FlexibleZoneLayer yardStore={yardViewStore} />
            <YardBerthsLayer yardStore={yardViewStore} />
          </StageWrapper>

          <YardStateToolbar
            yardViewStore={yardViewStore}
            stacksAllocationStore={stacksAllocationStore}
            yardManagementQueryParams={yardManagementQueryParams}
          />

          {yardViewStore.isBayViewDialogOpen && (
            <YardBlockBayOverviewDialog
              open={true}
              block={yardViewStore.selectedYardBlock!}
              bay={yardViewStore.selectedYardBlockBay!}
              onClose={() => yardViewStore.setIsBayViewDialogOpen(false)}
              bayViewStore={yardViewStore.yardBayViewStore}
            />
          )}

          {yardViewStore.yardDataStore.yardBlockSummaryStore.yardSummary && (
            <YardSummary {...yardViewStore.yardDataStore.yardBlockSummaryStore.yardSummary} />
          )}
        </Box>
      </Box>
    </Box>
  )
})

export default YardManagement
