import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { GeneralCargoInventoryDto } from '@storage/app/api'
import { useHubConnection } from '@storage/hooks/use-hub-connection.hook'
import { useStores } from '@storage/hooks/use-stores.hook'
import { yardManagementHubConnection } from '@storage/hubs/yard-management.hub-connection'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import GeneralCargoStockOccupancyCard from './GeneralCargoStockOccupancyCard'

const GeneralCargoInventoryTable = observer(() => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  useHubConnection(yardManagementHubConnection)

  const { generalCargoInventoryStore, generalCargoInventoryUIStore } = useStores()

  const overwriteMinimalTableHeadCellStyle = {
    sx: {
      backgroundColor: theme.palette.background.neutral,
    },
  }

  const columns = useMemo<MRT_ColumnDef<GeneralCargoInventoryDto>[]>(
    () => [
      {
        accessorKey: 'customer.name',
        header: t('customer', 'Customer'),
      },
      {
        accessorKey: 'commodity.name',
        header: t('commodity', 'Commodity'),
      },
      {
        accessorKey: 'lotNumber',
        header: t('lotNumber', 'Lot Number'),
      },
    ],
    [t],
  )

  useEffect(() => {
    const loadGeneralCargoStocks = () => {
      generalCargoInventoryStore.loadByQuery({ withOccupancyOnly: true })
    }

    loadGeneralCargoStocks()
  }, [generalCargoInventoryStore])

  return (
    <Box m={theme.customSpacing.l}>
      <MaterialReactTable
        muiTableHeadCellProps={overwriteMinimalTableHeadCellStyle}
        columns={columns}
        data={generalCargoInventoryUIStore.generalCargoInventory}
        autoResetPageIndex={false}
        enableTopToolbar={false}
        muiTableDetailPanelProps={() => ({
          sx: theme => ({
            backgroundColor: theme.palette.background.neutral,
          }),
        })}
        muiExpandButtonProps={({ row }) => ({
          sx: {
            transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
            transition: 'transform 0.2s',
          },
        })}
        renderDetailPanel={({ row }) =>
          row.original.occupancies ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
              }}
            >
              {row.original.occupancies?.length > 0 ? (
                row.original.occupancies.map((occupancy, index) => (
                  <GeneralCargoStockOccupancyCard
                    key={index}
                    generalCargoInventoryOccupancy={occupancy}
                    onSeeDamageDetails={damages => generalCargoInventoryUIStore.openDialog(damages)}
                  />
                ))
              ) : (
                <Typography>{t('noOccupanciesAvailable', 'No Occupancies available')}</Typography>
              )}
            </Box>
          ) : null
        }
      />
    </Box>
  )
})

export default GeneralCargoInventoryTable
