import { useOperationsStore } from '@operations/AppProvider'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import CraneOperatorLandingContainer from '@operations/features/craneOperator/containers/CraneOperatorLandingContainer'
import { useTranslate } from '@tolgee/react'
import { FC, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'

const CraneOperatorLanding: FC = withAuthenticationRequired(() => {
  const { craneOperatorLandingContainerUIStore } = useOperationsStore()

  const { t } = useTranslate()

  useEffect(() => {
    const loadLandingPageData = async () => {
      await craneOperatorLandingContainerUIStore.loadNotLeftTerminalVesselVisits()
    }

    loadLandingPageData()
  }, [craneOperatorLandingContainerUIStore])

  return (
    <>
      <Helmet>
        <title>{t('sts', 'STS')}</title>
      </Helmet>
      <CraneOperatorLandingContainer uiStore={craneOperatorLandingContainerUIStore} />
    </>
  )
})

export default CraneOperatorLanding
