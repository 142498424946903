import { Stack } from '@mui/system'
import { useTranslate } from '@tolgee/react'
import { useEffect, useMemo } from 'react'
import { DangerousGoodsDefinitionDto } from '@planning/app/api'
import { ImdgCodes, SelectOption } from '@planning/constants'
import { Controller, useForm } from 'react-hook-form'
import { Autocomplete, Chip, TextField } from '@mui/material'
import {
  DangerousGoodsDefinitionFormProfile,
  defaultValues,
  mapDangerousGoodsDefinitionDtoToDangerousGoodsDefinitionFormProfile,
} from '../utils/dangerousGoodsDefinitionsTableFormUtils'

interface DangerousGoodsDefinitionsTableFormProps {
  id: string
  dangerousGoodsDefinition?: DangerousGoodsDefinitionDto
  onSubmit: (formValues: DangerousGoodsDefinitionFormProfile) => Promise<void>
}

const DangerousGoodsDefinitionsTableForm = ({
  id,
  onSubmit,
  dangerousGoodsDefinition,
}: DangerousGoodsDefinitionsTableFormProps) => {
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    reset,
  } = useForm<DangerousGoodsDefinitionFormProfile>({
    defaultValues,
  })

  const imoClassOptions: SelectOption[] = useMemo(() => {
    return ImdgCodes.map(ic => ({ label: ic, value: ic }))
  }, [])

  const { t } = useTranslate()

  useEffect(() => {
    if (dangerousGoodsDefinition)
      reset(
        mapDangerousGoodsDefinitionDtoToDangerousGoodsDefinitionFormProfile(
          dangerousGoodsDefinition,
        ),
      )
  }, [reset, dangerousGoodsDefinition])

  return (
    <Stack
      id={id}
      component='form'
      noValidate
      autoComplete='off'
      onSubmit={handleSubmit(onSubmit)}
      my={1}
    >
      <Stack gap={1}>
        <Stack direction='row' gap={1}>
          <TextField
            fullWidth
            label={`${t('unNumber', 'UN Number')} *`}
            {...register('unNumber', {
              required: t('fieldIsRequired', 'Field is required.'),
              pattern: {
                value: /^\d{4}$/,
                message: t('mustBe4Digits', 'Must be exactly 4 digits.'),
              },
            })}
            error={!!errors.unNumber}
            helperText={errors.unNumber?.message}
          />

          <Controller
            name='primaryImoClass'
            control={control}
            rules={{ required: t('fieldIsRequired', 'Field is required.') }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                fullWidth
                options={imoClassOptions}
                getOptionLabel={(option: SelectOption) => option.label}
                value={imoClassOptions.find(option => option.value === value) || null}
                onChange={(_, newValue) => {
                  onChange(newValue ? newValue.value : null)
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={`${t('primaryImoClass', 'Primary IMO Class')} *`}
                    error={!!error}
                    helperText={error ? error.message : ''}
                    fullWidth
                  />
                )}
              />
            )}
          />
        </Stack>

        <Controller
          name='secondaryImoClasses'
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Autocomplete
              multiple
              options={imoClassOptions}
              getOptionLabel={(option: SelectOption) => option.label}
              value={value ? imoClassOptions.filter(option => value.includes(option.value)) : []}
              onChange={(_, newValue) => {
                onChange(newValue.map(item => item.value))
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label={t('secondaryImoClasses', 'Secondary IMO Classes')}
                  error={!!error}
                  helperText={error ? error.message : ''}
                />
              )}
              renderTags={tagValue =>
                tagValue.map(option => (
                  <Chip
                    key={option.value}
                    label={option.label}
                    onDelete={() => {
                      const newValue = value.filter((val: string | number) => val !== option.value)
                      onChange(newValue)
                    }}
                  />
                ))
              }
            />
          )}
        />

        <TextField
          fullWidth
          label={`${t('material', 'Material')} *`}
          {...register('material', {
            required: t('fieldIsRequired', 'Field is required.'),
          })}
          error={!!errors.material}
          helperText={errors.material?.message}
        />

        <TextField
          fullWidth
          label={t('handlingNotes', 'Handling Notes')}
          {...register('handlingNotes')}
          multiline
          minRows={2}
          error={!!errors.handlingNotes}
          helperText={errors.handlingNotes?.message}
        />
      </Stack>
    </Stack>
  )
}

export default DangerousGoodsDefinitionsTableForm
