import {
  ContainerVesselDirectionType,
  OperationType,
  VesselBaySlotDto,
  WorkInstructionDto,
} from '@operations/app/api'

import { getBarotiText, rowSeq } from '@operations/features/craneOperator/utils'
import { Typography } from '@mui/material'
import { Box, styled } from '@mui/system'
import { SlotPanel } from './SlotPanel'

interface Props {
  bay: number
  tier: number
  maxRow: number
  hasZero: boolean
  slots: VesselBaySlotDto[]
  currentEquipmentId?: number
  restowWorkInstructions?: WorkInstructionDto[]
  zoom: number
  operationType: OperationType
}

const TierLabel = styled(Typography)<{ zoom: number }>(({ zoom }) => ({
  flex: 'none',
  height: `${7 * zoom}rem`,
  width: `${3 * zoom}rem`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 'bold',
  fontSize: `${1 * zoom}rem`,
}))

export const TierPanel = ({
  bay,
  tier,
  maxRow,
  hasZero,
  slots,
  currentEquipmentId,
  restowWorkInstructions,
  operationType,
  zoom,
}: Props) => {
  return (
    <Box
      sx={{
        marginTop: `${0.5 * zoom}rem`,
        display: 'flex',
        gap: `${0.5 * zoom}rem`,
        flexWrap: 'nowrap',
      }}
    >
      <TierLabel zoom={zoom}>{tier}</TierLabel>

      {rowSeq(maxRow, hasZero).map(row => {
        const slot = slots.find(s => s.row === row && s.tier === tier)
        const slotBaroti = getBarotiText(bay, row, tier)

        const slotContainer =
          (operationType === OperationType.Inbound
            ? slot?.inboundContainer
            : slot?.outboundContainer) ?? slot?.remainOnBoardContainer
        const containerVesselDirectionType = (
          operationType === OperationType.Inbound ? slot?.inboundContainer : slot?.outboundContainer
        )
          ? (operationType as ContainerVesselDirectionType)
          : ContainerVesselDirectionType.RemainOnBoard
        const isRestore = restowWorkInstructions?.some(
          i =>
            i.cargoUnit?.containerNumber &&
            i.cargoUnit.containerNumber === slotContainer?.containerNumber,
        )
        return (
          <SlotPanel
            key={`slot-${row}-${tier}`}
            isHull={!slot || slot.isHull}
            slotContainer={slotContainer}
            slotBaroti={slotBaroti}
            operationType={containerVesselDirectionType}
            currentEquipmentId={currentEquipmentId}
            isRestore={!!isRestore}
            zoom={zoom}
          />
        )
      })}
      <TierLabel zoom={zoom}>{tier}</TierLabel>
    </Box>
  )
}
