import { IconButton, Stack, Tooltip } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { ReactElement } from 'react'

interface YardToolbarProps {
  title: string
  icon: ReactElement
  disabled: boolean
  onClick?: () => void
}

export const ToolbarButton = ({ title, icon, disabled, onClick }: YardToolbarProps) => {
  const { palette } = useMinimalsTheme()
  return (
    <Tooltip title={title}>
      <Stack direction='column' justifyContent='center' alignItems='center'>
        <IconButton
          onClick={onClick}
          disabled={disabled}
          sx={{
            backgroundColor: palette.background.neutral,
            borderRadius: '20%',
            color: palette.common.black,
          }}
        >
          {icon}
        </IconButton>
      </Stack>
    </Tooltip>
  )
}
