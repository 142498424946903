import {
  useCargoStatusOptions,
  useDamagedOptions,
  useDangerousOptions,
  useReeferOptions,
} from '@storage/app/container-turnovers/hooks'
import { useRestowOptions } from '@storage/app/container-turnovers/hooks/use-restow-options.hook'
import { BooleanSelectOption } from '@storage/app/models'
import { ControlledAutocomplete } from '@storage/components/form-controls/ControlledAutocomplete'
import { ControlledRadioGroup } from '@storage/components/form-controls/ControlledRadioGroup'
import { ImoClasses } from '@storage/components/types/ImoClasses'
import { Box, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { useFormContext, useWatch } from 'react-hook-form'
import { ContainerTurnoversFilterFormProfile } from '../container-turnovers-filter-form.profile'

export const HandlingInstructionsControls = () => {
  const { t } = useTranslate()
  const { control, formState } = useFormContext<ContainerTurnoversFilterFormProfile>()

  const cargoStatusOptions = useCargoStatusOptions()
  const reeferOptions = useReeferOptions()
  const damagedOptions = useDamagedOptions()
  const dangerousOptions = useDangerousOptions()
  const restowOptions = useRestowOptions()

  const isDangerous = useWatch({
    control,
    name: 'isDangerous',
  })

  return (
    <Box>
      <Typography variant='h6' mt={1}>
        {t('handlingInstructions', 'Handling Instructions')}
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <ControlledRadioGroup
            formControlSx={{ mt: 1 }}
            allOption
            formState={formState}
            control={control}
            label={t('cargoStatus', 'Cargo Status')}
            name='isEmpty'
            options={cargoStatusOptions}
          />

          <ControlledRadioGroup
            formControlSx={{ mt: 1 }}
            allOption
            formState={formState}
            control={control}
            label={t('reefer', 'Reefer')}
            name='isReefer'
            options={reeferOptions}
          />
          <ControlledRadioGroup
            formControlSx={{ mt: 1 }}
            allOption
            formState={formState}
            control={control}
            fullWidth
            label={t('restow', 'Restow')}
            name='isRestow'
            options={restowOptions}
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <ControlledRadioGroup
            formControlSx={{ mt: 1 }}
            allOption
            formState={formState}
            control={control}
            label={t('damaged', 'Damaged')}
            name='isDamaged'
            options={damagedOptions}
          />

          <ControlledRadioGroup
            formControlSx={{ mt: 1 }}
            allOption
            formState={formState}
            control={control}
            label={t('dangerous', 'Dangerous')}
            name='isDangerous'
            options={dangerousOptions}
          />

          <ControlledAutocomplete
            formControlSx={{ mt: 1 }}
            multiple
            formState={formState}
            control={control}
            disabled={isDangerous !== BooleanSelectOption.True}
            label={t('imoClasses', 'IMO Classes')}
            name='imoClasses'
            options={[
              ...ImoClasses.map(imo => ({
                label: imo,
                value: imo,
              })),
            ]}
          />
        </Box>
      </Box>
    </Box>
  )
}
