import { Container } from '@mui/system'
import { usePlanningStore } from '@planning/AppProvider'
import {
  ContainerJourneyResponseDto,
  CreateStuffingOrderCommand,
  UpdateStuffingOrderCommand,
} from '@planning/app/api'
import { serviceOrdersService } from '@planning/services'
import { useTranslate } from '@tolgee/react'
import { ConfirmationDialog } from '@tom-ui/ui'
import { isNumber } from 'lodash'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { ServiceOrdersViewStore } from '../../Stores/ServiceOrdersViewStore'
import { ContainerDialog } from './../ContainerDialog'
import { CreateServiceOrderDialogRender } from './../CreateServiceOrderDialogRender'
import { StuffingCommodityDto } from './../SelectedCommoditiesTable'

export const StuffingOrderDialog: FC<{
  store: ServiceOrdersViewStore
  onClose: () => void
}> = observer(({ store, onClose }) => {
  const { t } = useTranslate()
  const { appViewStore } = usePlanningStore()

  const isPackingListValid = () => {
    const emptyCargoAmount = store.selectedStuffingCommodities.filter(
      x => x.plannedAmount === 0,
    ).length
    if (emptyCargoAmount > 0) {
      appViewStore.setShowAlert(
        'warning',
        t(
          'youHaveXItemsOnThePackingListWithoutPlannedQuantity',
          'You have {amount} items on the packing list without planned quantity',
          { amount: emptyCargoAmount },
        ),
      )
    }

    const greaterThanStock = store.selectedStuffingCommodities.filter(
      x => x.plannedAmount > x.inStockQuantity,
    ).length
    if (greaterThanStock > 0) {
      appViewStore.setShowAlert(
        'warning',
        t(
          'youHaveXItemsOnThePackingListWithPlannedQuantityGreaterThanInStock',
          'You have {amount} items on the packing list with planned quantity greater than in stock',
          { amount: greaterThanStock },
        ),
      )
    }

    return !(greaterThanStock || emptyCargoAmount)
  }

  const removeSelectedCommodity = () => {
    if (store.itemToBeDeleted) {
      store.removeSelectedStuffingCommodity(store.itemToBeDeleted as StuffingCommodityDto)
    }

    store.setItemToBeDeleted()
  }

  const handleOnSubmit = async () => {
    if (
      !store.selectedContainerJourney ||
      !store.selectedStuffingCommodities ||
      !isPackingListValid()
    )
      return

    const journey = store.selectedContainerJourney

    try {
      if (journey.stuffingOrderId) {
        await submitUpdateStuffingOrder(journey, journey.stuffingOrderId)
      } else {
        await submitCreateStuffingOrder(journey)
      }

      appViewStore.setShowAlert(
        'success',
        t('stuffingOrderSavedSuccessfully', 'Stuffing order saved successfully'),
      )

      onClose()
    } catch (error) {
      appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
    }
  }

  const submitCreateStuffingOrder = async (journey: ContainerJourneyResponseDto) => {
    const cmd: CreateStuffingOrderCommand = {
      containerJourneyId: journey.id,
      packingList: store.selectedStuffingCommodities.map(c => ({
        ...c,
        handlingInstructions: store.handlingInstructions,
      })),
    }

    await serviceOrdersService.createStuffingOrder(cmd)
  }

  const submitUpdateStuffingOrder = async (
    journey: ContainerJourneyResponseDto,
    stuffingOrderId: number,
  ) => {
    const cmd: UpdateStuffingOrderCommand = {
      id: stuffingOrderId,
      containerJourneyId: journey.id,
      packingList: store.selectedStuffingCommodities.map(c => ({
        ...c,
        id: isNumber(c.id) ? c.id : undefined,
        handlingInstructions: store.handlingInstructions,
      })),
    }

    await serviceOrdersService.updateStuffingOrder(cmd)
  }

  const handleOnDelete = async () => {
    if (!store.selectedContainerJourney) return
    const journey = store.selectedContainerJourney

    if (journey.stuffingOrderId) {
      try {
        await serviceOrdersService.deleteStuffingOrder(journey.stuffingOrderId)
        appViewStore.setShowAlert(
          'success',
          t('stuffingOrderDeletedSuccessfully', 'Stuffing Order deleted successfully!'),
        )
        onClose()
      } catch (e) {
        appViewStore.setShowAlert(
          'error',
          t('failedToDeleteStuffingOrder', 'Failed to delete Stuffing Order'),
        )
      }
    }
  }

  return (
    <Container>
      <ContainerDialog
        open={store.showContainerDialog}
        containerJourneys={store?.containerVisits}
        onSelectContainer={containerJourney => {
          store.closeActiveDialog()

          store.setSelectedContainerJourney(containerJourney)
        }}
        onClose={() => store.closeActiveDialog()}
      />

      <ConfirmationDialog
        open={store.showConfirmationDialog}
        title={t('confirmation', 'Confirmation')}
        message={t(
          'doYouWantToRemoveThisItemFromTheList',
          'Do you want to remove this item from the list?',
        )}
        primaryActionText={t('yes', 'Yes')}
        closeLabel={t('no', 'No')}
        onConfirm={() => {
          removeSelectedCommodity()
          store.closeActiveDialog()
        }}
        onClose={() => store.closeActiveDialog()}
      />

      <ConfirmationDialog
        open={store.showServiceOrderDeleteConfirmationDialog}
        title={t('confirmation', 'Confirmation')}
        message={t(
          'doYouWantToRemoveThisStuffingOrder',
          'Do you want to remove this stuffing order?',
        )}
        primaryActionText={t('yes', 'Yes')}
        closeLabel={t('no', 'No')}
        onConfirm={() => {
          handleOnDelete()
          store.closeActiveDialog()
        }}
        onClose={() => store.closeActiveDialog()}
      />

      <CreateServiceOrderDialogRender
        onDelete={() => store.setActiveDialog('serviceOrderDeleteConfirmation')}
        isStuffing={true}
        onClose={onClose}
        onSubmit={handleOnSubmit}
        store={store}
      />
    </Container>
  )
})
