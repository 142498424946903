import { Box, Button, Card, CardContent, Typography } from '@mui/material'
import { TruckVisitDto } from '@planning/app/api'
import { useTranslate } from '@tolgee/react'
import { HighlightedText } from '@tom-ui/ui'
import { FC } from 'react'

interface Props {
  visit: TruckVisitDto
  highlight: string
  onClick: () => void
}

export const TruckVisitSearchResult: FC<Props> = ({ visit, highlight, onClick }) => {
  const { t } = useTranslate()

  return (
    <Card
      sx={{
        mb: '1rem',
      }}
      onClick={() => {
        onClick()
      }}
    >
      <CardContent>
        <Box display='flex' flexDirection='column' alignItems='left'>
          <Typography variant='h3'>
            <HighlightedText highlight={highlight}>{visit.identifier ?? ''}</HighlightedText>
          </Typography>
          <Button
            key={visit.id}
            variant='contained'
            size='large'
            sx={{ width: '100%', mt: '1rem' }}
            data-cy={`gate-out-check-truck-${visit.identifier}-details-btn`}
          >
            {t('checkDetails', 'Check details')}
          </Button>
        </Box>
      </CardContent>
    </Card>
  )
}
