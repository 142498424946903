import _ from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'

export class EquipmentOperatorSearchUIStore {
  searchText?: string
  selectedJobWorkInstructionIds?: number[]
  isSearchOpen = false

  constructor() {
    makeObservable(this, {
      isSearchOpen: observable,
      searchText: observable,
      selectedJobWorkInstructionIds: observable,

      toggleSearch: action,
      setSearchText: action,
      setSelectedJobWorkInstructionIds: action,

      isSearching: computed,
    })
  }

  public toggleSearch() {
    this.isSearchOpen = !this.isSearchOpen
    this.setSearchText()
  }

  public setSearchText(newValue?: string) {
    if (this.searchText !== newValue) {
      this.searchText = newValue
    }
  }

  public setSelectedJobWorkInstructionIds(jobWorkInstructionIds?: number[]) {
    if (!_.isEqual(this.selectedJobWorkInstructionIds, jobWorkInstructionIds)) {
      this.selectedJobWorkInstructionIds = jobWorkInstructionIds
    }
  }

  public get isSearching() {
    return !!this.searchText || !!this.selectedJobWorkInstructionIds
  }
}
