import { Drawer } from '@mui/material'
import { DrawerFormContainer } from '@tom-ui/ui'
import { usePlanningStore } from '@planning/AppProvider'
import { observer } from 'mobx-react-lite'

export const DrawerStack = observer(() => {
  const { drawerStore } = usePlanningStore()
  const drawerOptions = drawerStore.drawerOptions
  if (drawerOptions.title) {
    return (
      <DrawerFormContainer
        open={drawerStore.open}
        anchor={drawerStore.anchor}
        onClose={() => drawerStore.close()}
        formId={drawerOptions.formId || ''}
        title={drawerOptions.title || ''}
        subTitle={drawerOptions.subTitle || ''}
        primaryActionLabel={drawerOptions.primaryActionLabel || ''}
        closeActionLabel={drawerOptions.closeActionLabel || ''}
      >
        {drawerStore.view}
      </DrawerFormContainer>
    )
  } else {
    return (
      <Drawer
        open={drawerStore.open}
        anchor={drawerStore.anchor}
        onClose={() => drawerStore.close()}
        PaperProps={
          (drawerStore.fullscreen && {
            sx: { height: '100vh', width: '90vw' },
          }) ||
          undefined
        }
      >
        {drawerStore.view}
      </Drawer>
    )
  }
})
