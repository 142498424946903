import { useNavigateWithQueryParams } from '@storage/hooks/use-navigate-with-query-params.hook'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useSyncQueryParams } from '@storage/hooks/use-sync-query-params.hook'
import {
  ContainersAllocationByFilterFlow,
  YardManagementFlows,
} from '@storage/pages/yard-management/flows'
import { PATH_STORAGE } from '@storage/routes'
import { ContainerTurnoversFilterStore } from '../stores/container-turnovers-filter.store'

interface Deps {
  containerTurnoversFilterStore: ContainerTurnoversFilterStore
}

export const useHandlePlanOnYard = ({ containerTurnoversFilterStore }: Deps) => {
  const { containerTurnoverStore } = useStores()
  const navigateWithQueryParams = useNavigateWithQueryParams()

  useSyncQueryParams(containerTurnoversFilterStore.filterAsQueryParams)

  return (containerTurnoverId?: string) => {
    navigateWithQueryParams(
      PATH_STORAGE.yardManagement,
      containerTurnoverId
        ? { flow: YardManagementFlows.SingleContainerAllocation, containerTurnoverId }
        : {
            flow: YardManagementFlows.ContainersAllocationByFilter,
            [ContainersAllocationByFilterFlow.Keys.numberOfContainers]:
              containerTurnoverStore.pagination.totalCount.toString(),
            ...containerTurnoversFilterStore.filterAsQueryParams,
          },
    )
  }
}
