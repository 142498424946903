import { CarrierType, OperationType, WorkInstructionStatus } from '@operations/app/api'
import { VesselVisitStore } from '@operations/stores/VesselVisitStore'
import { WorkInstructionStore } from '@operations/stores/WorkInstructionStore'
import { action, computed, makeObservable, observable } from 'mobx'

export class EquipmentPlanningInfoUIStore {
  vesselId?: number

  constructor(
    private vesselVisitStore: VesselVisitStore,
    private workInstructionStore: WorkInstructionStore,
  ) {
    makeObservable(this, {
      vesselId: observable,
      vesselVisit: computed,
      setVesselId: action,
      expectedDischargeCount: computed,
      dischargedCount: computed,
      expectedLoadCount: computed,
      loadedCount: computed,
    })
  }

  public get vesselVisit() {
    return this.vesselId
      ? this.vesselVisitStore.items.find(
          i => i.id === this.vesselId && i.type === CarrierType.Vessel,
        )
      : undefined
  }

  public get expectedDischargeCount(): number | undefined {
    return this.vesselId
      ? this.workInstructionStore.items.filter(
          i => i.carrierVisitId === this.vesselId && i.operationType === OperationType.Inbound,
        ).length
      : undefined
  }

  public get dischargedCount(): number | undefined {
    return this.vesselId
      ? this.workInstructionStore.items.filter(
          i =>
            i.carrierVisitId === this.vesselId &&
            i.operationType === OperationType.Inbound &&
            i.status === WorkInstructionStatus.Finished,
        ).length
      : undefined
  }

  public get expectedLoadCount(): number | undefined {
    return this.vesselId
      ? this.workInstructionStore.items.filter(
          i => i.carrierVisitId === this.vesselId && i.operationType === OperationType.Outbound,
        ).length
      : undefined
  }

  public get loadedCount(): number | undefined {
    return this.vesselId
      ? this.workInstructionStore.items.filter(
          i =>
            i.carrierVisitId === this.vesselId &&
            i.operationType === OperationType.Outbound &&
            i.status === WorkInstructionStatus.Finished,
        ).length
      : undefined
  }

  public setVesselId(vesselId: number): void {
    if (this.vesselId !== vesselId) this.vesselId = vesselId
  }
}
