export enum ComponentStatus {
  None,
  Success,
  Warning,
  Error,
  Info,
}

export enum Size {
  Small,
  Medium,
  Large,
}
