import {
  ContainerDamageLocationResponseDto,
  ContainerDamageTypeResponseDto,
} from '@planning/app/api'
import { IContainerDamageOption } from '@planning/components'
import { containerDamageLocationService, containerDamageTypeService } from '@planning/services'
import { computed, makeObservable, observable, runInAction } from 'mobx'

export class ContainerDamageReportViewStore {
  containerDamageLocations: ContainerDamageLocationResponseDto[] = []
  containerDamageTypes: ContainerDamageTypeResponseDto[] = []

  constructor() {
    makeObservable(this, {
      containerDamageLocations: observable,
      containerDamageTypes: observable,

      locationOptions: computed,
      typeOptions: computed,
    })
  }

  async fetch() {
    await Promise.all([this.getLocations(), this.getTypes()])
  }

  get locationOptions(): IContainerDamageOption[] {
    return this.containerDamageLocations.map(
      x => ({ id: x.id, name: x.name, code: x.code }) as IContainerDamageOption,
    )
  }

  get typeOptions(): IContainerDamageOption[] {
    return this.containerDamageTypes.map(
      x => ({ id: x.id, name: x.name, code: x.code }) as IContainerDamageOption,
    )
  }

  private async getLocations() {
    if (this.containerDamageLocations.length) return

    const locations = await containerDamageLocationService.get()
    runInAction(() => {
      this.containerDamageLocations = locations
    })
  }

  private async getTypes() {
    if (this.containerDamageTypes.length) return

    const types = await containerDamageTypeService.get()
    runInAction(() => {
      this.containerDamageTypes = types
    })
  }
}
