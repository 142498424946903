import { PackageDto } from '@billing/app/api'
import { PackageStore } from '@billing/stores/PackageStore'
import { BaseDrawerStore } from '@billing/stores/base/BaseDrawerStore'
import { action, makeObservable, observable } from 'mobx'

export class PackageDialogUIStore extends BaseDrawerStore<PackageDto> {
  isDeleteOpen = false

  constructor(private packageStore: PackageStore) {
    super(packageStore)
    makeObservable(this, {
      isDeleteOpen: observable,

      toggleDelete: action,
    })
  }

  public toggleDelete(): void {
    this.isDeleteOpen = !this.isDeleteOpen
  }
}
