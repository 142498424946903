import { useOperationsStore } from '@operations/AppProvider'
import { FinishWorkInstructionJobDto } from '@operations/features/equipmentOperator/models/finish-job.model'
import { Header } from '@tom-ui/ui'
import { CloseWorkInstructionStepper } from './CloseWorkInstructionStepper'
import { ConfirmWorkInstructionStepper } from './ConfirmWorkInstructionStepper'

interface Props {
  onConfirm: (currentJob: FinishWorkInstructionJobDto) => Promise<void>
  onClose: () => Promise<void> | null
}
export const WorkInstructionJobStepperHeader = ({ onConfirm, onClose }: Props) => {
  const {
    equipmentOperatorWorkInstructionStepperUIStore,
    equipmentOperatorWorkInstructionsUIStore,
  } = useOperationsStore()

  const handleConfirm = async (currentJob: FinishWorkInstructionJobDto) => {
    await onConfirm(currentJob)
    equipmentOperatorWorkInstructionsUIStore.closeSearchIfFinishingLastNotification(
      currentJob.workInstructionId,
    )
  }

  return (
    <Header
      leftRenderOption={
        <CloseWorkInstructionStepper
          uiStore={equipmentOperatorWorkInstructionStepperUIStore}
          onClose={onClose}
        />
      }
      title={equipmentOperatorWorkInstructionStepperUIStore.commodityName}
      titleColor='secondary'
      rightRenderOption={
        <ConfirmWorkInstructionStepper
          uiStore={equipmentOperatorWorkInstructionStepperUIStore}
          onConfirm={handleConfirm}
        />
      }
    />
  )
}
