import { Box, Paper, Stack, Typography, styled } from '@mui/material'
import { EquipmentDto } from '@operations/app/api'
import { Hover } from '@operations/components/hover/Hover'
import { EquipmentTypeIcon } from '@operations/features/equipmentPlanning/components/EquipmentTypeIcon/EquipmentTypeIcon'
import { useTranslate } from '@tolgee/react'
import { ButtonOption, OptionsButton } from '@tom-ui/ui'
import { useState } from 'react'
import { EquipmentStatusChip } from '../statusChip/EquipmentStatus'

interface Props {
  equipment: EquipmentDto
  showAssign?: boolean
  assignOptions?: ButtonOption[]
  showRemove?: boolean
  isInOperation?: boolean
  onAssignItem?: (equipmentId: number, workAreaId: number) => Promise<void> | void
  onUnassignItem?: (id: number) => Promise<void> | void
  dataCy?: string
  //onMove: (cardValues: EquipmentCardProfile) => Promise<void> | void;
}

const EquipmentPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  background: 'white',
  width: '100%',
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  position: 'relative',
}))

const EquipmentName = styled(Typography)(() => ({
  width: '100%',
  wordBreak: 'break-word',
}))

const OptionsIconButton = styled(Box)(() => ({
  position: 'absolute',

  right: 2,
}))

export const EquipmentCard = ({
  equipment,
  showAssign,
  assignOptions,
  showRemove,
  isInOperation,
  onAssignItem,
  onUnassignItem,
  dataCy,
}: Props) => {
  const [isMouseOver, setIsMouseOver] = useState(false)
  const { t } = useTranslate()

  return (
    <Hover setMouseHover={val => setIsMouseOver(val)}>
      <EquipmentPaper variant='outlined' data-cy={dataCy}>
        <Box flex='none'>
          <EquipmentTypeIcon type={equipment.equipmentType} zoom={120} />
        </Box>
        <Stack maxWidth={showRemove ? '55%' : '95%'}>
          <EquipmentName variant='subtitle2'>{equipment.name}</EquipmentName>

          <EquipmentStatusChip
            isInOperation={isInOperation}
            isUnavailable={equipment.isOnMaintenance}
          />
        </Stack>

        {showAssign && assignOptions && assignOptions.length > 0 && (
          <OptionsIconButton>
            <OptionsButton
              testId='assign-equipment-menu-btn'
              tooltip={t('moreOptions', 'More options')}
              position='right'
              options={assignOptions}
            ></OptionsButton>
          </OptionsIconButton>
        )}

        {showRemove && onUnassignItem && (
          <OptionsIconButton sx={{ visibility: !isMouseOver ? 'hidden' : 'initial' }}>
            <OptionsButton
              tooltip={t('moreOptions', 'More options')}
              position='right'
              options={[
                {
                  label: t('remove'),
                  color: 'error',
                  onClick: () => {
                    onUnassignItem(equipment.id)
                  },
                },
              ]}
            ></OptionsButton>
          </OptionsIconButton>
        )}
      </EquipmentPaper>
    </Hover>
  )
}
