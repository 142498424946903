import {
  CarrierVisitDirection,
  CarrierVisitStatus,
  HoldStatus,
  NonNumericOrderDto,
  OrderResponseDto,
  TruckAppointmentOrderDto,
  TruckVisitDto,
} from '@planning/app/api'
import { NonNumericOrderWithPickUpAmount } from '@planning/stores/truckAppointment/TruckAppointmentDetailsViewStore'
import { TruckAppointmentOrderStore } from '@planning/stores/truckAppointment/TruckAppointmentOrderViewStore'
import _ from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'

export class GateClerkTruckVisitEditStore {
  isSelectingOrder = false
  truckVisit: TruckVisitDto | undefined
  inboundOrders: OrderResponseDto[] = []
  outboundOrders: OrderResponseDto[] = []
  nnrOrders: NonNumericOrderWithPickUpAmount[] = []
  lastDirection: CarrierVisitDirection = CarrierVisitDirection.Inbound
  selectedOrder: OrderResponseDto | undefined
  unconfirmRemovedInboundOrders: TruckAppointmentOrderDto[] = []
  unconfirmRemovedOutboundOrders: TruckAppointmentOrderDto[] = []

  constructor(public truckAppointmentOrderStore: TruckAppointmentOrderStore) {
    makeObservable(this, {
      isSelectingOrder: observable,
      truckVisit: observable,
      inboundOrders: observable,
      outboundOrders: observable,
      nnrOrders: observable,
      lastDirection: observable,
      selectedOrder: observable,
      unconfirmRemovedInboundOrders: observable,
      unconfirmRemovedOutboundOrders: observable,

      setIsSelectingOrder: action,
      setTruckVisit: action,
      setInboundOrders: action,
      setOutboundOrders: action,
      addOrder: action,
      removeOrder: action,
      addNnrOrder: action,
      setDirection: action,
      setSelectedOrder: action,
      reset: action,

      isExit: computed,
      isEntryWithoutOrders: computed,
      ordersWithoutContainerNumber: computed,
      outboundOrdersNotOnTerminal: computed,
      passageDirection: computed,
      ordersHaveActiveHolds: computed,
      availableOrders: computed,
    })
  }

  setIsSelectingOrder = (isSelectingOrder: boolean) => {
    this.isSelectingOrder = isSelectingOrder
  }

  setTruckVisit = (truckVisit?: TruckVisitDto) => {
    this.truckVisit = truckVisit ? { ...truckVisit } : undefined
  }

  setInboundOrders = (orders?: OrderResponseDto[]) => {
    this.inboundOrders = orders?.length ? [...orders] : []
  }

  setOutboundOrders = (orders?: OrderResponseDto[]) => {
    this.outboundOrders = orders?.length ? [...orders] : []
  }

  setDirection = (carrierVisitDirection: CarrierVisitDirection) => {
    this.lastDirection = carrierVisitDirection
  }

  setSelectedOrder = (order: OrderResponseDto | undefined) => {
    this.selectedOrder = order
  }

  addOrder = (order: TruckAppointmentOrderDto) => {
    if (order.carrierVisitDirection === CarrierVisitDirection.Inbound) {
      this.inboundOrders.push(mapToOrderResponseDto(order))
      this.unconfirmRemovedInboundOrders = this.unconfirmRemovedInboundOrders.filter(
        o => o.orderId !== order.orderId,
      )
    } else {
      this.outboundOrders.push(mapToOrderResponseDto(order))
      this.unconfirmRemovedOutboundOrders = this.unconfirmRemovedOutboundOrders.filter(
        o => o.orderId !== order.orderId,
      )
    }
  }

  removeOrder = (order: OrderResponseDto) => {
    if (order.id) {
      if (order.direction === CarrierVisitDirection.Inbound) {
        this.inboundOrders = this.inboundOrders.filter(o => o.id != order.id)
        this.unconfirmRemovedInboundOrders.push(mapToTruckAppointmentOrderDto(order))
      } else {
        this.outboundOrders = this.outboundOrders.filter(o => o.id != order.id)
        this.unconfirmRemovedOutboundOrders.push(mapToTruckAppointmentOrderDto(order))
      }
    } else {
      const referenceNumber = order.referenceNumber
      const nnrOrder = this.nnrOrders.find(nnr => nnr.referenceNumber === referenceNumber)

      if (!nnrOrder) return

      nnrOrder.pickUpAmount -= 1

      if (nnrOrder.pickUpAmount === 0)
        this.nnrOrders = [...this.nnrOrders.filter(nnr => nnr.referenceNumber !== referenceNumber)]
      else
        this.nnrOrders = [
          ...this.nnrOrders.filter(nnr => nnr.referenceNumber !== referenceNumber),
          nnrOrder,
        ]
    }
  }

  addNnrOrder = (nnrOrder: NonNumericOrderDto, pickUpAmount: number) => {
    this.nnrOrders.push({ ...nnrOrder, pickUpAmount: pickUpAmount })
  }

  reset = () => {
    this.isSelectingOrder = false
    this.truckVisit = undefined
    this.inboundOrders = []
    this.outboundOrders = []
    this.nnrOrders = []
    this.lastDirection = CarrierVisitDirection.Inbound
    this.selectedOrder = undefined
    this.unconfirmRemovedInboundOrders = []
    this.unconfirmRemovedOutboundOrders = []
  }

  get isExit() {
    return this.truckVisit && this.truckVisit.status === CarrierVisitStatus.Arrived
  }

  get isEntryWithoutOrders() {
    return (
      this.truckVisit &&
      !this.inboundOrders.length &&
      !this.outboundOrders.length &&
      !this.nnrOrders.length &&
      !this.isExit
    )
  }

  get ordersWithoutContainerNumber() {
    return this.outboundOrders.filter(o => !o.containerNumber) ?? []
  }

  get outboundOrdersNotOnTerminal() {
    return this.outboundOrders.filter(o => !o.isOnTerminal) ?? []
  }

  get passageDirection() {
    return this.isExit ? CarrierVisitDirection.Outbound : CarrierVisitDirection.Inbound
  }

  get ordersHaveActiveHolds() {
    return (
      this.passageDirection === CarrierVisitDirection.Inbound
        ? this.inboundOrders
        : this.outboundOrders
    )
      .flatMap(o => o.holds)
      .filter(h => h.status === HoldStatus.Active).length
  }

  get availableOrders() {
    const isOutbound = this.truckVisit?.status !== CarrierVisitStatus.Expected
    return _([
      ...this.truckAppointmentOrderStore.relevantOrdersForPassageCheck
        .filter(
          o =>
            ![...this.outboundOrders, ...this.inboundOrders].map(io => io.id).includes(o.orderId),
        )
        .filter(o => !isOutbound || o.carrierVisitDirection === CarrierVisitDirection.Outbound),
      ...(isOutbound ? this.unconfirmRemovedOutboundOrders : this.unconfirmRemovedInboundOrders),
    ])
      .uniqBy(o => o.orderId)
      .value()
  }
}

const mapToOrderResponseDto = (order: TruckAppointmentOrderDto): OrderResponseDto => {
  return {
    ...order,
    id: order.orderId,
    direction: order.carrierVisitDirection,
    status: order.orderStatus,
    isRestowed: false,
    containerLength: order.containerLength,
    containerHeight: order.containerHeight,
    containerType: order.containerType,
    hasServiceOrders: false,
  }
}

const mapToTruckAppointmentOrderDto = (order: OrderResponseDto): TruckAppointmentOrderDto => {
  return {
    ...order,
    orderId: order.id,
    carrierVisitDirection: order.direction,
    orderStatus: order.status,
    isEmpty: order.isEmpty ?? false,
    isDangerous: order.isDangerous ?? false,
    isDamaged: order.isDamaged ?? false,
  }
}
