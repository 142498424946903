import { withAuthenticationRequired } from '@auth0/auth0-react'
import { useTranslate } from '@tolgee/react'
import { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import { ContainerDamageLocationsDialogContainer } from './containers/ContainerDamageLocationDialogContainer'
import { ContainerDamageTypesDialogContainer } from './containers/ContainerDamageTypeDialogContainer'
import { DamageCatalogueContainer } from './containers/DamageCatalogueContainer'

export const DamageCatalogue: FC = withAuthenticationRequired(() => {
  const { t } = useTranslate()

  return (
    <>
      <Helmet>
        <title>{t('DamageCatalogue', 'Damage catalogue')}</title>
      </Helmet>
      <DamageCatalogueContainer />
      <ContainerDamageLocationsDialogContainer />
      <ContainerDamageTypesDialogContainer />
    </>
  )
})
