import {
  CreateEquipmentCommand,
  EquipmentDto,
  EquipmentType,
  UpdateEquipmentCommand,
} from '@operations/app/api'
import { EquipmentFormProfile } from './equipment-form.profile'

const mapFormValuesToCreateDto = ({
  name,
  equipmentType,
  isOnMaintenance,
  maxHeight,
  maxWeight,
  yardBlockIds,
}: EquipmentFormProfile): CreateEquipmentCommand => ({
  name,
  equipmentType: equipmentType as EquipmentType,
  isOnMaintenance,
  yardBlockIds,
  maxHeight,
  maxWeight,
})

const mapFormValuesToUpdateDto = ({
  id,
  name,
  isOnMaintenance,
  maxHeight,
  maxWeight,
  yardBlockIds,
}: EquipmentFormProfile): UpdateEquipmentCommand => ({
  id: id!,
  name,
  isOnMaintenance,
  yardBlockIds: yardBlockIds,
  maxHeight,
  maxWeight,
})

const mapDtoToFormValues = ({
  id,
  name,
  equipmentType,
  isOnMaintenance,
  maxHeight,
  maxWeight,
  yardBlocks,
}: EquipmentDto): EquipmentFormProfile => ({
  id,
  name,
  equipmentType,
  isOnMaintenance,
  maxHeight,
  maxWeight,
  yardBlockIds: yardBlocks?.map(y => y.id),
})

export const EquipmentFormMapper = {
  mapFormValuesToCreateDto,
  mapFormValuesToUpdateDto,
  mapDtoToFormValues,
}
