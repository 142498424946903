import { Box, Button, Grid, TextField } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import {
  CarrierType,
  CarrierVisitDirection,
  ContainerDamageResponseDto,
  CreateContainerOrderDto,
  CreateOrdersCommand,
} from '@planning/app/api'
import { DangerousGoodAutoComplete } from '@planning/components/dangerous-goods/DangerousGoodsAutoComplete'
import { validateContainerNumber, validateIsoCode } from '@planning/rt-stores/helpers'
import { useTranslate } from '@tolgee/react'
import { Header } from '@tom-ui/ui'
import _ from 'lodash'
import { computed } from 'mobx'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { Controller, FieldErrors, useForm } from 'react-hook-form'
import { DamageReportBox } from './Components/DamageReportBox'
import { NavigationStackCloseButton } from './Components/HeaderComponents/NavigationStackCloseButton'

interface IProps {
  visitId: number
  onSubmit: (cmd: CreateOrdersCommand) => Promise<void> | void
  onClose: () => void
}

export const ReportAccidentallyDischargedContainer: FC<IProps> = observer(
  ({ visitId, onSubmit, onClose }) => {
    const { t } = useTranslate()
    const { appViewStore, vesselVisitItemStore, vesselVisitQueryService } = usePlanningStore()

    useEffect(() => {
      vesselVisitQueryService.fetchById(visitId)
    }, [visitId, vesselVisitQueryService])

    const vesselVisitItem = computed(() => vesselVisitItemStore.elements[visitId]).get()

    const validate = (order: CreateContainerOrderDto) => {
      const isAlreadyInTheVisit = vesselVisitItem.discharge.orders.find(
        o => o.container?.data.number === order.containerNumber,
      )

      if (isAlreadyInTheVisit) {
        setError('containerNumber', {
          type: 'validate',
          message: t('containerIsAlreadyInThisVisit', 'Container is already in this visit'),
        })

        return false
      }

      return true
    }

    const onFormSubmit = async (data: any) => {
      const order = data as CreateContainerOrderDto

      if (!validate(order)) return

      order.damages = _.get(data, 'damages', []) as ContainerDamageResponseDto[]
      const cmd: CreateOrdersCommand = {
        orders: [order],
        carrierVisitType: CarrierType.Vessel,
      }
      try {
        onSubmit(cmd)
      } catch (error) {
        appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
      }
    }

    const containerNumberErrorText = (errors: FieldErrors) => {
      const containerNumberError = errors.containerNumber
      if (containerNumberError) {
        const errorType = containerNumberError.type
        if (errorType === 'required') return t('mandatoryField', 'Mandatory field')
        if (errorType === 'validate')
          return `${t('mustMatchPattern', 'Must match pattern')}: ABCU1234567`

        return containerNumberError.message?.toString()
      }
    }

    const containerIsoCodeErrorText = (errors: FieldErrors) => {
      const isoCodeError = errors.containerIsoCode
      if (isoCodeError) {
        const errorType = isoCodeError.type
        if (errorType === 'required') return t('mandatoryField', 'Mandatory field')
        if (errorType === 'validate') return t('isoCodeNotFound', 'ISO Code not found')

        return isoCodeError.message?.toString()
      }
    }

    const {
      handleSubmit,
      register,
      control,
      getValues,
      setValue,
      formState: { errors },
      setError,
    } = useForm({
      defaultValues: {
        containerNumber: '',
        containerIsoCode: '',
        direction: CarrierVisitDirection.Inbound,
        carrierVisitId: visitId,
        isAccidentalDischarge: true,
        imoClasses: [] as string[],
        damages: [] as ContainerDamageResponseDto[],
      },
    })
    const [isDangerous, setIsDangerous] = useState<boolean>(!!getValues('imoClasses')?.length)

    const onEdit = (
      editedReport: Partial<ContainerDamageResponseDto>,
      initialReport: ContainerDamageResponseDto,
    ): void => {
      setValue('damages', [
        ...getValues('damages').filter(d => d.description !== initialReport.description),
        editedReport as ContainerDamageResponseDto,
      ])
    }

    return (
      <>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Header
            leftRenderOption={<NavigationStackCloseButton onClose={onClose} />}
            title={t('reportContainer', 'Report container')}
            rightRenderOption={
              <Button variant='contained' type='submit' sx={{ width: '15%', minWidth: '100px' }}>
                {t('submit', 'Submit')}
              </Button>
            }
          />
          <Grid container spacing='0.5rem' p='1rem'>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={`${t('containerNumber', 'Container number')}*`}
                variant='outlined'
                {...register('containerNumber', {
                  required: true,
                  validate: v => validateContainerNumber(v),
                })}
                name='containerNumber'
                sx={{ marginTop: '1rem' }}
                error={!!errors.containerNumber}
                helperText={containerNumberErrorText(errors)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={`${t('iso', 'ISO')}*`}
                variant='outlined'
                {...register('containerIsoCode', {
                  required: true,
                  validate: v => validateIsoCode(v),
                })}
                name='containerIsoCode'
                sx={{ marginTop: '1rem' }}
                error={!!errors.containerIsoCode}
                helperText={containerIsoCodeErrorText(errors)}
              />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ marginTop: '1rem' }}>
                <Controller
                  control={control}
                  name={`imoClasses`}
                  render={({ field: { onChange } }) => (
                    <DangerousGoodAutoComplete
                      assignedCodes={getValues(`imoClasses`) ?? []}
                      enableSelect={isDangerous}
                      onToggleSelectDangerousGoods={() => setIsDangerous(!isDangerous)}
                      onDangerousGoodChange={newValues => {
                        onChange(newValues)
                      }}
                      error={!!errors.imoClasses}
                      helperText={
                        errors.imoClasses ? t('fieldIsRequired', 'Field is required.') : ''
                      }
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ marginTop: '1rem' }}>
                <Controller
                  control={control}
                  name={`damages`}
                  render={({ field: { onChange } }) => (
                    <DamageReportBox
                      containerId={0}
                      damages={getValues('damages')}
                      onCreateDamageReport={(_, data) =>
                        onChange([
                          ...getValues('damages'),
                          {
                            ...(data as ContainerDamageResponseDto),
                            created: new Date().toISOString(),
                          },
                        ])
                      }
                      onEditDamageReport={onEdit}
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>
        </form>
      </>
    )
  },
)
