import { AddOutlined } from '@mui/icons-material'
import { IconButton, Menu, MenuItem, Stack, Tooltip } from '@mui/material'
import { OrderStatus } from '@planning/app/api'
import { ContainerJourney } from '@planning/pages/ServiceOrders/Stores/ContainerJourneyDataStore'
import { ServiceOrdersType } from '@planning/pages/ServiceOrders/Stores/ServiceOrdersViewStore'
import { useTranslate } from '@tolgee/react'
import { ColoredLabel, DamagedIcon, useMinimalsTheme } from '@tom-ui/ui'
import { FC, MouseEventHandler, useState } from 'react'
import {
  canAssignCoolingOrder,
  canAssignStrippingOrder,
  canAssignStuffingOrder,
} from './ContainerJourneyHelper'

const AddServiceIconButton: FC<{
  title: string
  onClick: MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
}> = ({ title, onClick, disabled }) => {
  const styles = disabled
    ? {
        '&:disabled': {
          cursor: 'not-allowed',
          pointerEvents: 'all !important',
        },
      }
    : {
        cursor: 'pointer',
      }

  return (
    <Tooltip title={title}>
      <span>
        <IconButton
          size='small'
          disabled={disabled}
          onClick={disabled ? undefined : onClick}
          sx={{ ...styles }}
        >
          <AddOutlined fontSize='small' />
        </IconButton>
      </span>
    </Tooltip>
  )
}

export const AddServiceOrderButton: FC<{
  journey: ContainerJourney
  onClickAddService: (serviceOrdersType: ServiceOrdersType) => void
  isCriticalContainerDamage: boolean
}> = ({ journey, onClickAddService, isCriticalContainerDamage }) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const servicesToBeAdded: ServiceOrdersType[] = []
  if (canAssignStrippingOrder(journey)) servicesToBeAdded.push('stripping')
  if (canAssignStuffingOrder(journey)) servicesToBeAdded.push('stuffing')
  if (canAssignCoolingOrder(journey)) servicesToBeAdded.push('cooling')

  const getTooltip = (serviceType?: ServiceOrdersType) => {
    switch (serviceType) {
      case 'stripping':
        return t('addStrippingOrder', 'Add stripping order')
      case 'stuffing':
        return t('addStuffingOrder', 'Add stuffing order')
      case 'cooling':
        return t('addCoolingOrder', 'Add cooling order')
      default:
        return t('addServiceOrder', 'Add service order')
    }
  }

  const getServiceTranslation = (serviceType: ServiceOrdersType) => {
    switch (serviceType) {
      case 'stripping':
        return t('strippingOrder', 'Stripping order')
      case 'stuffing':
        return t('stuffingOrder', 'Stuffing order')
      case 'cooling':
        return t('coolingOrder', 'Cooling order')
      default:
        return ''
    }
  }

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleSelectServiceType = (serviceType: ServiceOrdersType) => {
    if (serviceType) onClickAddService(serviceType)
    setAnchorEl(null)
  }

  const blockCreatingServiceForCriticalContainerDamage = (serviceType: ServiceOrdersType) =>
    serviceType === 'stuffing' && isCriticalContainerDamage

  if (journey.outboundOrder?.order?.status === OrderStatus.Fulfilled) return <></>

  const renderServiceMenuItem = (serviceType: ServiceOrdersType) => {
    const blockCreation = blockCreatingServiceForCriticalContainerDamage(serviceType)
    return (
      <MenuItem
        sx={
          blockCreation
            ? {
                cursor: 'not-allowed !important',
                pointerEvents: 'all !important',
              }
            : { cursor: 'pointer' }
        }
        disabled={blockCreation}
        key={`add-service-menu-item-${serviceType}`}
        onClick={() => (blockCreation ? undefined : handleSelectServiceType(serviceType))}
      >
        {(blockCreation && (
          <Stack direction='row' gap={theme.customSpacing.xs} alignItems='center'>
            {getServiceTranslation(serviceType)}
            <ColoredLabel
              label={t('criticallyDamaged', 'Critically damaged')}
              color='secondary'
              startIcon={<DamagedIcon />}
            ></ColoredLabel>
          </Stack>
        )) || <>{getServiceTranslation(serviceType)}</>}
      </MenuItem>
    )
  }
  const renderSingleServiceButton = (
    <>
      <AddServiceIconButton
        title={getTooltip(servicesToBeAdded[0])}
        onClick={() => onClickAddService(servicesToBeAdded[0])}
        disabled={blockCreatingServiceForCriticalContainerDamage(servicesToBeAdded[0])}
      />
      {blockCreatingServiceForCriticalContainerDamage(servicesToBeAdded[0]) && (
        <ColoredLabel
          label={t('criticallyDamaged', 'Critically damaged')}
          color='secondary'
          startIcon={<DamagedIcon />}
        ></ColoredLabel>
      )}
    </>
  )
  return (
    <>
      {servicesToBeAdded.length === 1 && renderSingleServiceButton}
      {servicesToBeAdded.length > 1 && (
        <>
          <AddServiceIconButton title={getTooltip()} onClick={handleClick} />
          <Menu anchorEl={anchorEl} open={open} onClose={() => handleSelectServiceType(null)}>
            {servicesToBeAdded.map(renderServiceMenuItem)}
          </Menu>
        </>
      )}
    </>
  )
}
