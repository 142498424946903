import { ContainerDamageTypesApi } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'

class ContainerDamageTypeService {
  httpClient = createApiClient(ContainerDamageTypesApi)

  get = async () => {
    const { data } = await this.httpClient.get()
    return data
  }
}

const containerDamageTypeService = new ContainerDamageTypeService()

export default containerDamageTypeService
