import { HoldsIcon } from '@tom-ui/ui'
import { FC } from 'react'

export const IconHolds: FC<{ zoom?: number; size?: number }> = ({ zoom, size }) => {
  return (
    <HoldsIcon
      sx={{
        height: `${size ?? 32}px`,
        width: `${size ?? 32}px`,
        transform: `scale(${(zoom ?? 100) / 100})`,
      }}
      color='error'
    />
  )
}

export default IconHolds
