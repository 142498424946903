import { createSvgIcon } from '@mui/material'

export const ReeferIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15 5L15 15.3542C15.6224 16.0593 16 16.9856 16 18C16 20.2091 14.2091 22 12 22C9.79086 22 8 20.2091 8 18C8 16.9856 8.37764 16.0593 9 15.3542L9 5C9 3.34315 10.3431 2 12 2C13.6569 2 15 3.34315 15 5ZM13 8L13 16.2676C13.5978 16.6134 14 17.2597 14 18C14 19.1046 13.1046 20 12 20C10.8954 20 10 19.1046 10 18C10 17.2597 10.4022 16.6134 11 16.2676L11 8C11 7.44771 11.4477 7 12 7C12.5523 7 13 7.44771 13 8Z'
      fill='currentColor'
    />
  </svg>,
  'ReeferIcon',
)
