import { EquipmentType } from '@operations/app/api'
import { ContainerStowageLocationStore } from '@operations/stores/ContainerStowageLocationStore'
import { EquipmentStore } from '@operations/stores/EquipmentStore'
import { VesselVisitStore } from '@operations/stores/VesselVisitStore'
import { WorkInstructionStore } from '@operations/stores/WorkInstructionStore'
import { WorkQueueStore } from '@operations/stores/WorkQueueStore'
import _ from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'

export class SelectCranesDialogUIStore {
  vesselId?: number
  selectedCranes: number[] = []
  dialog = false
  redirectOnCancel = false

  constructor(
    private equipmentStore: EquipmentStore,
    private vesselVisitStore: VesselVisitStore,
    private workQueueStore: WorkQueueStore,
    private workInstructionStore: WorkInstructionStore,
    private containerStowageLocationStore: ContainerStowageLocationStore,
  ) {
    makeObservable(this, {
      redirectOnCancel: observable,
      selectedCranes: observable,
      vesselId: observable,
      dialog: observable,
      toggle: action,
      toggleWithSelectedCranes: action,
      toggleWithRedirect: action,
      setVesselId: action,
      setCranes: action,
      vesselVisit: computed,
      cranes: computed,
      hasWorkInstructions: computed,
    })
  }

  public setVesselId(vesselId: number): void {
    if (this.vesselId != vesselId) this.vesselId = vesselId
  }
  public setCranes(craneIds: number[]): void {
    this.selectedCranes = craneIds
  }

  public toggleWithSelectedCranes(craneIds: number[]) {
    this.setCranes(craneIds)
    this.redirectOnCancel = false
    this.toggle()
  }

  public toggleWithRedirect(craneIds: number[] = []) {
    this.setCranes(craneIds)
    this.redirectOnCancel = true

    this.dialog = true
  }

  public toggle(state?: boolean) {
    if (state !== undefined) {
      this.dialog = state
    } else {
      this.dialog = !this.dialog
    }
  }

  public async plan(craneIds: number[]) {
    if (this.vesselId) {
      this.setCranes(craneIds)

      await this.workQueueStore.autoGenerateWorkQueues({
        craneIds: this.selectedCranes,
        carrierVisitId: this.vesselId,
      })
      await Promise.all([
        this.workQueueStore.load(this.vesselId),
        this.workInstructionStore.load(this.vesselId),
        this.containerStowageLocationStore.load(this.vesselId),
      ])

      this.toggle()
    }
  }

  public get vesselVisit() {
    return this.vesselVisitStore.items.find(vesselVisit => vesselVisit.id === this.vesselId)
  }

  public get cranes() {
    return _(this.equipmentStore.items.filter(eq => eq.equipmentType === EquipmentType.Sts))
      .sortBy(eq => eq.name)
      .value()
  }

  public get hasWorkInstructions() {
    return !!this.workInstructionStore.items.length
  }
}
