import { SelectOption } from '@storage/app/models'

export const transform = <T extends { [key: number]: string | number }>(
  enumeration: T,
  labelKeyPrefix: string,
): SelectOption[] =>
  Object.values(enumeration).map(type => ({
    label: `${labelKeyPrefix}${type.toString()}`,
    value: type.toString(),
  }))
