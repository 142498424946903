import { Grid, Paper } from '@mui/material'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { IVesselVisitItem } from '@planning/rt-stores/vesselVisit/VesselVisitItem'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { VesselVisitHeaderVisit } from '../Header/VesselVisitHeaderVisit'
import { VesselVisitHeaderBerth } from './VesselVisitHeaderBerth'
import { VesselVisitHeaderTime } from './VesselVisitHeaderTime'
import { VesselVisitHeaderVessel } from './VesselVisitHeaderVessel'

interface Props {
  vesselVisitItem: IVesselVisitItem
}

// todo: [north] storybook
export const VesselVisitHeader: FC<Props> = observer(({ vesselVisitItem }) => {
  const theme = useMinimalsTheme()

  const isBerthPlanningFeatureActive = useBooleanFlagValue('vessel-visit-berth-planning', false)

  if (!vesselVisitItem || !vesselVisitItem.vessels[0]) return <></>

  const vesselVisit = vesselVisitItem.data
  const vessels = vesselVisitItem.vessels
  const firstVessel = vessels[0].data

  return (
    <Paper
      className='vessel-visit-details-header'
      square
      elevation={1}
      sx={{ backgroundColor: theme.palette.grey[200] }}
    >
      <Grid container direction={{ xs: 'column', sm: 'row' }} justifyContent='space-between'>
        <Grid item sm={isBerthPlanningFeatureActive ? 6 : 3}>
          <VesselVisitHeaderVisit
            vesselVisit={vesselVisit}
            vessel={firstVessel}
            vessels={vessels}
            status={vesselVisitItem.status}
          />
        </Grid>
        <Grid item sm={isBerthPlanningFeatureActive ? 6 : 9}>
          <Grid container direction={{ xs: 'column', sm: 'row' }} justifyContent='space-between'>
            <Grid item sm={isBerthPlanningFeatureActive ? 6 : 4}>
              <VesselVisitHeaderTime vesselVisit={vesselVisit} />
            </Grid>
            {!isBerthPlanningFeatureActive && (
              <Grid item sm={4}>
                <VesselVisitHeaderBerth
                  vesselVisit={vesselVisit}
                  assignedBerths={vesselVisitItem.berths}
                />
              </Grid>
            )}
            <Grid item sm={isBerthPlanningFeatureActive ? 6 : 4}>
              <VesselVisitHeaderVessel vesselVisit={vesselVisit} vessel={firstVessel} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
})
