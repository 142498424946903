export const PATH_PLANNING = {
  vesselVisits: '/vessel-visits',
  railVisits: '/rail-visits',
  truckVisits: '/truck-appointments',
  nnrOrders: '/nnr-orders',
  nnrOrdersPageForVisit: '/nnr-orders/vesselVisit',
  tallymanv2: '/tallymanv2',
  serviceOrders: '/service-orders',
  containerVisits: '/container-visits',
}
