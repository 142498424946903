import { Box, Tooltip, Typography } from '@mui/material'
import { CloseButton, EditIcon, IconButton } from '@tom-ui/ui'
import { useTranslate } from '@tolgee/react'
import { FC } from 'react'
import { IInspectContainerFormData } from './InspectContainer'
import { OperationTypeChip } from './OperationTypeChip'
interface Props {
  order: IInspectContainerFormData
  onEdit?: (order: IInspectContainerFormData) => void
  onRemove?: (order: IInspectContainerFormData) => void
}

export const OrderListItem: FC<Props> = ({ order, onEdit, onRemove }) => {
  const { t } = useTranslate()

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: '1rem',
      }}
    >
      <Box>
        <Typography variant='subtitle1'>{order.containerNumber}</Typography>
        <OperationTypeChip direction={order.direction} />
      </Box>

      <Box>
        <Tooltip title={t('edit', 'Edit')}>
          <IconButton sx={{ mr: '.5rem' }} onClick={() => onEdit?.(order)}>
            <EditIcon />
          </IconButton>
        </Tooltip>

        {onRemove && <CloseButton onClose={() => onRemove(order)} />}
      </Box>
    </Box>
  )
}
