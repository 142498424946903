import { Chip } from '@mui/material'
import { Stack } from '@mui/system'
import {
  ContainerHeight,
  ContainerType,
  OrderResponseDto,
  TruckAppointmentOrderDto,
} from '@planning/app/api'
import { useTranslate } from '@tolgee/react'
import { DamagedIcon, DangerousIcon, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { ContainerHeightChip } from './ContainerHeightChip'
import { ContainerLengthChip } from './ContainerLengthChip'
import { ContainerTypeChip } from './ContainerTypeChip'

interface Props {
  order: OrderResponseDto | TruckAppointmentOrderDto
  onClick?: () => void
}

export const OrderHandlingInstructionChipBox = observer(({ order, onClick }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const renderChip = (label: string, icon?: JSX.Element) => (
    <Chip label={label} icon={icon} variant='filled' sx={{ marginBottom: '0.25rem' }} />
  )

  return (
    <Stack
      spacing={0.5}
      direction='row'
      sx={{ svg: { verticalAlign: 'bottom' }, flexWrap: 'wrap' }}
      onClick={() => {
        if (onClick) onClick()
      }}
    >
      {order.operator && renderChip(order.operator)}

      {order.containerIsoCode && renderChip(order.containerIsoCode)}

      {order.containerLength && <ContainerLengthChip containerLength={order.containerLength} />}

      <ContainerTypeChip containerType={order.containerType ?? ContainerType.Unknown} />
      {order.containerHeight && order.containerHeight !== ContainerHeight.Standard && (
        <ContainerHeightChip containerHeight={order.containerHeight} />
      )}

      {!!order.grossWeight && renderChip(`${order.grossWeight} kg`)}

      {(order.isEmpty && renderChip(t('empty', 'Empty'))) || renderChip(t('full', 'Full'))}

      {order.isDangerous && renderChip(t('dangerous', 'Dangerous'), <DangerousIcon />)}

      {order.isDamaged && renderChip(t('damaged', 'Damaged'), <DamagedIcon />)}

      {'isRestowed' in order && order.isRestowed && (
        <Chip
          label={t('restow', 'Restow')}
          variant='filled'
          size='medium'
          sx={{
            borderRadius: '6px',
            color: 'white',
            backgroundColor: theme.palette.grey[600],
            fontWeight: 'bold',
          }}
        />
      )}
    </Stack>
  )
})
