import { List, ListItem, Skeleton, Typography } from '@mui/material'
import { FC } from 'react'

export const ContainerAutocompleteListItemLoader: FC = () => {
  return (
    <List>
      <ListItem>
        <Skeleton>
          <Typography variant='subtitle2'>Card 0</Typography>
        </Skeleton>
        <Skeleton
          sx={{
            margin: '0 10px',
          }}
        >
          <Typography variant='body2'>fooo</Typography>
        </Skeleton>
      </ListItem>
      <ListItem>
        <Skeleton>
          <Typography variant='subtitle2'>Card 0</Typography>
        </Skeleton>
        <Skeleton
          sx={{
            margin: '0 10px',
          }}
        >
          <Typography variant='body2'>fooo</Typography>
        </Skeleton>
      </ListItem>
    </List>
  )
}
