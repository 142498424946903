import { Stack } from '@mui/material'
import { JobTypes } from '@operations/app/models/operator-pages'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { EquipmentOperatorUIStore } from '../../stores/equipment-operator.ui-store'
import { JobsNavigationV2 } from '../JobsNavigation/JobsNavigationV2'
import { JobFilterByArea } from './JobFilterByArea'
import { YardAssignedEquipments } from './YardAssignedEquipments'

interface Props {
  store: EquipmentOperatorUIStore
}
export const JobsFilter = observer(({ store }: Props) => {
  const theme = useMinimalsTheme()

  const handleNavChange = (newValue: JobTypes) => {
    store.setJobType(newValue)
  }

  const handleNonNumeric = (value?: boolean) => {
    store.setNonNumeric(value)
  }

  const handleNotPlanned = (value?: boolean) => {
    store.setNotPlanned(value)
  }

  const handleContainerSizeSelectionChange = (value?: number) => {
    store.setSelectedContainerSize(value)
  }

  return (
    <Stack gap={theme.customSpacing.m}>
      <Stack flexDirection='row' gap={theme.customSpacing.xs} flexWrap='wrap'>
        <JobFilterByArea
          jobs={store.jobs}
          yardBlocks={store.yardBlocksAssignments}
          selectedYardBlockId={store.selectedYardBlockId}
          onSelect={id => {
            store.setYardBlockId(id)
          }}
        />

        <JobsNavigationV2
          jobs={store.jobs}
          filteredJobs={store.selectedYardBlockJobs}
          jobType={store.jobType}
          nonNumeric={store.nonNumeric}
          notPlanned={store.notPlanned}
          selectedEquipmentType={store.typeOfSelectedEquipment}
          selectedContainerSize={store.selectedContainerSize}
          nonNumericChange={handleNonNumeric}
          containerSizeSelectionChange={handleContainerSizeSelectionChange}
          navChange={handleNavChange}
          notPlannedChange={handleNotPlanned}
        />
      </Stack>
      <YardAssignedEquipments
        equipments={
          store.yardBlocksAssignments.find(x => x.yardBlockId === store.selectedYardBlockId)
            ?.equipments
        }
      />
    </Stack>
  )
})
