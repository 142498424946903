import { CarrierVisitDirection } from '@storage/app/api'
import { useTranslate } from '@tolgee/react'
import { useMemo } from 'react'

const PREFIX_KEY = 'HANDLING_DIRECTION'

export const useHandlingDirectionTranslation = (handlingDirection: CarrierVisitDirection) => {
  const { t } = useTranslate()

  return useMemo(() => t(`${PREFIX_KEY}.${handlingDirection}`), [t, handlingDirection])
}
