import { withAuthenticationRequired } from '@auth0/auth0-react'
import { ReportsContainer } from '@billing/features/reports/containers/ReportsContainer'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { useTranslate } from '@tolgee/react'
import dayjs from 'dayjs'
import 'dayjs/locale/de'
import 'dayjs/locale/es'
import 'dayjs/locale/ru'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { FC } from 'react'
import { Helmet } from 'react-helmet-async'

const Reports: FC = withAuthenticationRequired(() => {
  const { t } = useTranslate()

  dayjs().locale(navigator.language) //TODO: apply it globally to main in host ui
  dayjs.extend(localizedFormat)

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={dayjs().locale()}>
      <Helmet>
        <title>{t('ReportsTomBillingService', 'Reports - TOM Billing Service')}</title>
      </Helmet>

      <ReportsContainer />
    </LocalizationProvider>
  )
})

export default Reports
