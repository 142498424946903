import {
  Autocomplete,
  Box,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  debounce,
} from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { ContainerResponseDto, ContainerType, OrderResponseDto } from '@planning/app/api'
import { getContainerTypeParameters } from '@planning/components/ContainerTypesMap'
import { containerHeights, containerTypes } from '@planning/constants'
import { validateContainerNumber, validateIsoCode } from '@planning/rt-stores/helpers'
import containerService from '@planning/services/containerService'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Controller, FieldErrors, useForm } from 'react-hook-form'
import { DangerousGoodAutoComplete } from './Components/DangerousGoodsAutoComplete'

interface Props {
  order?: OrderResponseDto
  onSubmit: (data: any) => Promise<void> | void
}

export const EditOrderForm = observer(({ order, onSubmit }: Props) => {
  if (!order) {
    return <></>
  }

  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: order.id,
      referenceNumber: order.referenceNumber,
      containerNumber: order.containerNumber,
      containerIsoCode: order.containerIsoCode,
      containerLength: order.containerLength,
      containerHeight: order.containerHeight,
      containerType: order.containerType,
      containerMaxGross: order.containerMaxGross,
      containerTare: order.containerTare,
      carrierVisitId: order.carrierVisitId,
      grossWeight: order.grossWeight,
      isDamaged: order.isDamaged,
      direction: order.direction,
      operator: order.operator,
      isEmpty: order.isEmpty,
      imoClasses: order.imoClasses,
    },
  })
  const { appViewStore } = usePlanningStore()
  const [isEmptyOrder, setIsEmptyOrder] = useState(order.isEmpty)
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState<ContainerResponseDto[]>([])
  const [existingContainer, setExistingContainer] = useState<ContainerResponseDto | undefined>(
    undefined,
  )
  const [containerNumberInput, setContainerNumberInput] = useState<string | undefined>(undefined)
  const [isIsoCodeToggled, setIsIsoCodeToggled] = useState(!!order.containerIsoCode)

  const { t } = useTranslate()

  const OnSearchFilterChange = debounce(async (filter?: string) => {
    setOptions((await containerService.get(1, 10, filter)).payload)
  }, 500)

  useEffect(() => {
    if (open) {
      const fetch = async () => {
        return (await containerService.get(1, 10)).payload.filter(c => !!c.number)
      }

      fetch().then(payload => setOptions(payload))
    } else {
      setOptions([])
    }
  }, [open])

  const onSubmitForm = (order: any) => {
    if (existingContainer && existingContainer.isOnTerminal) {
      appViewStore.setShowAlert(
        'error',
        t('failedToSaveContainerIsOnTerminal', 'Container is already On Terminal'),
      )
    } else {
      onSubmit({
        ...order,
        containerIsoCode: isIsoCodeToggled ? order.containerIsoCode : undefined,
        containerAttributes: isIsoCodeToggled
          ? undefined
          : {
              length: order.containerLength ? order.containerLength : undefined,
              height: order.containerHeight ? order.containerHeight : undefined,
              type: order.containerType ? order.containerType : undefined,
            },
      })
    }
  }

  const containerNumberErrorText = (errors: FieldErrors) => {
    const containerNumberError = errors.containerNumber
    if (containerNumberError) {
      const errorType = containerNumberError.type
      if (errorType === 'required') return t('fieldIsRequired', 'Field is required.')
      if (errorType === 'validate')
        return `${t('mustMatchPattern', 'Must match pattern')}: ABCU1234567`

      return containerNumberError.message?.toString()
    }
  }

  const containerIsoCodeErrorText = (errors: FieldErrors) => {
    const isoCodeError = errors.containerIsoCode
    if (isoCodeError) {
      const errorType = isoCodeError.type
      if (errorType === 'required') return t('fieldIsRequired', 'Field is required.')
      if (errorType === 'validate') return t('isoCodeNotFound', 'ISO Code not found')

      return isoCodeError.message?.toString()
    }
  }

  const validateContainerAttributes = (f: any) =>
    !!f.containerLength || !!f.containerHeight || !!f.containerType

  const requiredAtLeastOneContainerAttributeError =
    !!errors.containerLength && !!errors.containerHeight && !!errors.containerType

  return (
    <form id='edit-order-vessel-visit' onSubmit={handleSubmit(onSubmitForm)}>
      <Grid container direction='column' rowSpacing={{ xs: 1.5 }}>
        <Grid item>
          <Controller
            control={control}
            name={`containerNumber`}
            rules={{ required: true, validate: v => !!v && validateContainerNumber(v) }}
            render={({ field: { onChange } }) => (
              <Autocomplete
                freeSolo
                disablePortal
                defaultValue={order.containerNumber}
                getOptionLabel={(container: ContainerResponseDto | string) =>
                  typeof container === 'string' ? container : container.number ?? ''
                }
                renderOption={(props, container) => {
                  let text = ''
                  if (typeof container === 'string') {
                    text = container
                  } else {
                    const isOnTerminalText = container.isOnTerminal ? 'On Terminal' : 'Off Terminal'
                    text = `${container.number} : ${isOnTerminalText}`
                  }

                  return (
                    <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {text}
                    </Box>
                  )
                }}
                isOptionEqualToValue={(
                  option: ContainerResponseDto | string,
                  value: ContainerResponseDto | string,
                ) => {
                  if (typeof option !== 'string' && typeof value !== 'string') {
                    return option.id === value.id
                  }

                  return option === value
                }}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                options={options}
                onInputChange={(_, value) => {
                  setContainerNumberInput(value)
                }}
                onBlur={() => {
                  if (existingContainer && existingContainer.number !== containerNumberInput) {
                    setValue('containerIsoCode', '')
                    setValue('operator', '')
                    setExistingContainer(undefined)
                  }
                }}
                onChange={(_, selectedContainer: ContainerResponseDto | null | string) => {
                  if (selectedContainer && typeof selectedContainer !== 'string') {
                    setValue('containerIsoCode', selectedContainer.isoCode)
                    setValue('operator', selectedContainer.operator)
                    setValue('containerLength', selectedContainer.length)
                    setValue('containerHeight', selectedContainer.height)
                    setValue('containerType', selectedContainer.type)

                    setExistingContainer(selectedContainer)
                    onChange(selectedContainer.number)

                    if (selectedContainer.isoCode) setIsIsoCodeToggled(true)
                    else if (
                      selectedContainer.length ||
                      selectedContainer.height ||
                      selectedContainer.type !== ContainerType.Unknown
                    )
                      setIsIsoCodeToggled(false)
                  } else {
                    setValue('containerIsoCode', '')
                    setValue('operator', '')
                    setValue('containerLength', order.containerLength)
                    setValue('containerHeight', order.containerHeight)
                    setValue('containerType', order.containerType)

                    setExistingContainer(undefined)
                    onChange(selectedContainer)
                  }
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={t('containerNumber', 'Container Number')}
                    helperText={containerNumberErrorText(errors)}
                    error={!!errors.containerNumber}
                    onChange={e => {
                      onChange(e)
                      OnSearchFilterChange(e.target.value)
                    }}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      e.target.value = ('' + e.target.value).toUpperCase()
                    }}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            {...register(`referenceNumber`)}
            label={`${t('referenceNumber', 'Reference Number')}`}
          />
        </Grid>
        <Typography variant='h4' sx={{ marginTop: '1rem' }}>
          {t('container', 'Container')}
        </Typography>

        <Grid container direction='row' columnGap={1}>
          <Chip
            key='iso-code-chip'
            label={t('isoCode', 'ISO Code')}
            sx={{ mt: '1rem', mb: '1rem' }}
            variant={!isIsoCodeToggled ? 'outlined' : undefined}
            onClick={() => setIsIsoCodeToggled(true)}
          />
          <Chip
            key='container-attributes-chip'
            label={t('containerAttributes', 'Container Attributes')}
            sx={{ m: '1rem 1rem 1rem 0' }}
            variant={isIsoCodeToggled ? 'outlined' : undefined}
            onClick={() => setIsIsoCodeToggled(false)}
            disabled={!!existingContainer?.isoCode}
          />
        </Grid>

        {isIsoCodeToggled ? (
          <TextField
            fullWidth
            {...register('containerIsoCode', {
              required: true,
              validate: v => !!v && validateIsoCode(v),
            })}
            label={`${t('isoCode', 'ISO Code')} *`}
            disabled={!!order.containerIsoCode || !!existingContainer?.isoCode}
            helperText={containerIsoCodeErrorText(errors)}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
              e.target.value = ('' + e.target.value).toUpperCase()
            }}
          />
        ) : (
          <>
            <TextField
              fullWidth
              label={t('length', 'Length')}
              variant='outlined'
              type='number'
              {...register('containerLength', {
                valueAsNumber: true,
                validate: (_, formData) => validateContainerAttributes(formData),
              })}
              name='containerLength'
              onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
              inputProps={{ min: 1 }}
              error={requiredAtLeastOneContainerAttributeError}
            />
            <Controller
              control={control}
              name={`containerHeight`}
              rules={{ validate: (_, formData) => validateContainerAttributes(formData) }}
              render={({ field: { onChange } }) => (
                <FormControl fullWidth sx={{ marginTop: '1rem' }}>
                  <InputLabel sx={{ verticalAlign: 'center' }}>{t('height', 'Height')}</InputLabel>
                  <Select
                    label={t('height', 'Height')}
                    {...register('containerHeight')}
                    value={getValues(`containerHeight`)?.toString() ?? ''}
                    onChange={(e: any) => {
                      onChange(e)
                    }}
                    error={requiredAtLeastOneContainerAttributeError}
                  >
                    <MenuItem value={''}>
                      <em>None</em>
                    </MenuItem>
                    {containerHeights.map(d => {
                      return (
                        <MenuItem key={d.key} value={d.name}>
                          {d.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name={`containerType`}
              rules={{ validate: (_, formData) => validateContainerAttributes(formData) }}
              render={({ field: { onChange } }) => (
                <FormControl fullWidth sx={{ marginTop: '1rem' }}>
                  <InputLabel sx={{ verticalAlign: 'center' }}>{t('type', 'Type')}</InputLabel>
                  <Select
                    label={t('type', 'Type')}
                    {...register('containerType')}
                    value={getValues(`containerType`)?.toString() ?? ''}
                    onChange={(e: any) => {
                      onChange(e)
                    }}
                    error={requiredAtLeastOneContainerAttributeError}
                  >
                    <MenuItem value={''}>
                      <em>None</em>
                    </MenuItem>
                    {containerTypes.map(d => {
                      return (
                        <MenuItem key={d.key} value={d.name}>
                          {getContainerTypeParameters(d.name, t)['label']}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              )}
            />
          </>
        )}

        <Grid item>
          <TextField
            fullWidth
            label={t('grossWeightKg', 'Gross Weight (kg)')}
            InputLabelProps={{ shrink: true }}
            type='number'
            {...register('grossWeight', { valueAsNumber: true })}
            onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
            inputProps={{ min: 0 }}
          />
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            {...register(`operator`)}
            label={t('operator', 'Operator')}
            disabled={!!order.operator || !!existingContainer?.operator}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item>
          <Controller
            control={control}
            name={`isEmpty`}
            render={({ field: { onChange } }) => (
              <FormControl fullWidth>
                <InputLabel color='secondary'>{t('filledState', 'Filled State')}</InputLabel>
                <Select
                  label={t('filledState', 'Filled State')}
                  value={getValues(`isEmpty`)}
                  onChange={(e: any) => {
                    onChange(e)
                    const newValue = e.target.value
                    setIsEmptyOrder(newValue)
                    if (newValue) {
                      setValue(`imoClasses`, [])
                    }
                  }}
                >
                  <MenuItem value={true as any}>{t('empty', 'Empty')}</MenuItem>
                  <MenuItem value={false as any}>{t('full', 'Full')}</MenuItem>
                </Select>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item>
          <Typography variant='subtitle1' marginTop={'10px'}>
            {t('handlingInstructions', 'Handling Instructions')}
          </Typography>
        </Grid>
        <Grid item sx={{ marginTop: '-1rem' }}>
          <Controller
            control={control}
            name={`imoClasses`}
            render={({ field: { onChange } }) => (
              <DangerousGoodAutoComplete
                assignedCodes={getValues(`imoClasses`) ?? []}
                onDangerousGoodChange={newValues => {
                  onChange(newValues)
                }}
                disabled={isEmptyOrder}
              />
            )}
          />
        </Grid>
        <Grid item>
          <Controller
            control={control}
            name={`isDamaged`}
            render={({ field: { onChange } }) => (
              <FormControl fullWidth>
                <InputLabel color='secondary'>{t('isDamaged', 'Is Damaged')}</InputLabel>
                <Select
                  disabled
                  label={t('isDamaged', 'Is Damaged')}
                  value={getValues(`isDamaged`)}
                  onChange={(e: any) => {
                    onChange(e)
                  }}
                >
                  <MenuItem value={true as any}>{t('yes', 'Yes')}</MenuItem>
                  <MenuItem value={false as any}>{t('no', 'No')}</MenuItem>
                </Select>
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
    </form>
  )
})
