import { createSvgIcon } from '@mui/material'

export const CheckmarkIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M9.86009 18C9.58292 17.9991 9.31856 17.8832 9.13009 17.68L4.27009 12.51C3.89177 12.1068 3.91192 11.4733 4.31509 11.095C4.71826 10.7167 5.35177 10.7368 5.73009 11.14L9.85009 15.53L18.2601 6.33C18.4926 6.04041 18.8665 5.90357 19.2311 5.97461C19.5956 6.04565 19.8908 6.31287 19.9976 6.66859C20.1044 7.02431 20.0052 7.40991 19.7401 7.67L10.6001 17.67C10.4134 17.8769 10.1488 17.9965 9.87009 18H9.86009Z'
      fill='currentColor'
    />
  </svg>,
  'CheckmarkIcon',
)
