import { SelectOption } from '@operations/app/models'
import _ from 'lodash'

export const enumToList = (enumObj: any): string[] => {
  return Object.keys(enumObj).map(key => enumObj[key])
}

/* Here is a workaround for now,
 we need to add translations(i18n) to translate it to readable label in english/german ... */
export const translateEnumValue = (value: string): string => value.replace(/([A-Z])/g, ' $1').trim()

export const enumToOptions = (enumObj: object): SelectOption[] =>
  _(
    Object.values(enumObj).map(value => ({
      label: translateEnumValue(value),
      value: value,
    })),
  )
    .sortBy(i => i.label)
    .value()
