import { Stack, Typography } from '@mui/material'
import { VesselDto, VesselVisitDto } from '@planning/app/api'
import { CargoTypeIconLabel } from '@planning/components/CargoTypeIconLabel'
import { CarrierVisitStatusChip } from '@planning/components/CarrierVisitStatusChip'
import { CarrierVisitViewStatus, emptyIndicator } from '@planning/constants'
import { IVesselItem } from '@planning/rt-stores/vessel/VesselItem'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { VesselVisitHeaderContainer } from './VesselVisitHeaderContainer'

export const VesselVisitHeaderVisit: FC<{
  vessel: Pick<VesselDto, 'name' | 'shippingLine'>
  vesselVisit: Pick<VesselVisitDto, 'status' | 'inboundTripIds' | 'outboundTripIds' | 'cargoType'>
  vessels?: IVesselItem[]
  status: CarrierVisitViewStatus
}> = observer(({ vessels, vessel, vesselVisit, status }) => {
  const { t } = useTranslate()

  if (!vessel || !vesselVisit) return <></>

  let vesselName = vessel.name.toUpperCase()
  let vesselShippingLine = vessel.shippingLine

  if (vessels && vessels.length <= 4) {
    vesselName = vessels.map(v => v.data.name).join(', ')
    vesselShippingLine = vessels.map(v => v.data.shippingLine).join(', ')
  }

  return (
    <VesselVisitHeaderContainer title={vesselName}>
      <Stack alignItems='space' justifyContent='space-between' sx={{ height: '100%' }}>
        <CargoTypeIconLabel cargoType={vesselVisit.cargoType} />
        <Stack spacing={0}>
          <Typography variant='body2' color='secondary'>
            {vesselShippingLine ?? '-'}
            {' / '}
            {vesselVisit.inboundTripIds &&
            vesselVisit.inboundTripIds.length &&
            vesselVisit.inboundTripIds[0] !== ''
              ? `${t('tripIds', 'Trip IDs')} In: ${vesselVisit.inboundTripIds
                  ?.filter(id => id.trim() !== '')
                  .join(', ')}`
              : emptyIndicator}
            {' / '}
            {vesselVisit.outboundTripIds &&
            vesselVisit.outboundTripIds.length &&
            vesselVisit.outboundTripIds[0] !== ''
              ? `${t('tripIds', 'Trip IDs')} Out: ${vesselVisit.outboundTripIds
                  ?.filter(id => id.trim() !== '')
                  .join(', ')}`
              : emptyIndicator}
          </Typography>
        </Stack>
        <CarrierVisitStatusChip status={status} />
      </Stack>
    </VesselVisitHeaderContainer>
  )
})
