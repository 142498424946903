/**
 * Local Storage Service
 *
 * A utility class for managing data in the browser's local storage.
 * It provides methods to save, retrieve, and clear data associated with a specific key.
 *
 * @template T - The type of data to be stored in local storage.
 */
export class LocalStorageService<T> {
  private readonly localStorageKey: string

  constructor(key: string) {
    this.localStorageKey = key
  }

  public saveData(data: T): void {
    localStorage.setItem(this.localStorageKey, JSON.stringify(data))
  }

  public getData(): T | null {
    const storedData = localStorage.getItem(this.localStorageKey)
    if (storedData) {
      try {
        return JSON.parse(storedData) as T
      } catch (error) {
        throw new Error('Failed local storage data parsing')
      }
    }
    return null
  }

  public clearData(): void {
    localStorage.removeItem(this.localStorageKey)
  }
}
