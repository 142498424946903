import { EquipmentDto, EquipmentYardBlockDto } from '@operations/app/api'
import { EquipmentStore } from '@operations/stores/EquipmentStore'

import { action, computed, makeObservable, observable } from 'mobx'
import { EquipmentFiltersUIStore } from './equipment-filters.ui-store'

export class EquipmentsUIStore {
  toBeRemoveEquipmentId?: number
  toBeUnAssignYardBlockEquipment?: EquipmentDto

  constructor(
    private equipmentStore: EquipmentStore,
    private filtersUIStore: EquipmentFiltersUIStore,
  ) {
    makeObservable(this, {
      sortedItems: computed,
      equipments: computed,
      toBeRemoveEquipmentId: observable,
      toBeUnAssignYardBlockEquipment: observable,
      setToBeRemoveEquipmentId: action,
      setToBeUnAssignYardBlockEquipment: action,
    })
  }

  public setToBeRemoveEquipmentId(toBeRemoveEquipmentId?: number) {
    this.toBeRemoveEquipmentId = toBeRemoveEquipmentId
  }

  public setToBeUnAssignYardBlockEquipment(toBeUnAssignYardBlockEquipment?: EquipmentDto) {
    this.toBeUnAssignYardBlockEquipment = toBeUnAssignYardBlockEquipment
  }

  public get sortedItems(): EquipmentDto[] {
    return [...this.equipmentStore.items].sort(i => i.id)
  }

  public get equipments(): EquipmentDto[] {
    if (this.filtersUIStore.areFiltersAndSearchEmpty) {
      return this.sortedItems
    }

    if (this.filtersUIStore.equipmentName) {
      return this.sortedItems.filter(eq =>
        this.doesEquipmentHaveName(eq, this.filtersUIStore.equipmentName!),
      )
    }

    const filters = this.filtersUIStore.filter!
    return this.sortedItems.filter(
      eq =>
        this.doesEquipmentHaveMaintenanceStatus(eq, filters.isOnMaintenance) &&
        this.doesEquipmentHaveType(eq, filters.equipmentTypes) &&
        this.doesEquipmentHaveYardBlock(eq.yardBlocks, filters.yardBlockIds),
    )
  }

  private doesEquipmentHaveName(equipment: EquipmentDto, name: string) {
    return equipment.name.toLowerCase().includes(name.toLowerCase())
  }

  private doesEquipmentHaveMaintenanceStatus(
    equipment: EquipmentDto,
    maintenance?: boolean | null,
  ) {
    return !maintenance || equipment.isOnMaintenance === maintenance
  }

  private doesEquipmentHaveType(equipment: EquipmentDto, types?: string[] | null) {
    return !types?.length || types.includes(equipment.equipmentType)
  }

  private doesEquipmentHaveYardBlock(
    yardBlocks?: EquipmentYardBlockDto[] | null,
    selectedYardBlockIds?: string[] | null,
  ) {
    return (
      !selectedYardBlockIds?.length ||
      yardBlocks?.some(yardBlock => selectedYardBlockIds.includes(yardBlock.id))
    )
  }
}
