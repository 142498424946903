import { Box, Button, Chip, Typography } from '@mui/material'
import { ErrorCodes } from '@storage/app/api'
import { FormProps } from '@storage/app/models'
import StackedAlert from '@storage/components/StackedAlert'
import { ContainerTurnoverAttributes } from '@storage/components/container-turnovers-list/components/ContainerTurnoverAttributes'
import { ControlledTextField } from '@storage/components/form-controls'
import useFormWithSchema from '@storage/hooks/use-form-with-schema.hook'
import { useNavigateWithQueryParams } from '@storage/hooks/use-navigate-with-query-params.hook'
import { useStores } from '@storage/hooks/use-stores.hook'
import { Hint } from '@storage/pages/container-turnovers/stores/manual-input-validation.store'
import { ManualPlanningDialogStore } from '@storage/pages/container-turnovers/stores/manual-planning-dialog.store'
import { PATH_STORAGE } from '@storage/routes'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useWatch } from 'react-hook-form'
import { ContainerTurnoverFilterAttributes } from '../ContainerTurnoverFilterAttributes'
import { mapFormValuesToQueryParams } from '../container-turnovers-filter-form/container-turnovers-filter-form.mapper'
import { ManualPlanningFormText } from './ManualPlanningFormText'
import { ManualPlanningFormProfile, defaultValues } from './manual-reservation-form.profile'
import { schema } from './manual-reservation-form.scheme'

interface Props extends FormProps {
  onSubmit: (formValues: ManualPlanningFormProfile) => Promise<void> | void
  title: string
  manualPlanningDialogStore: ManualPlanningDialogStore
}

export const ManualPlanningForm = observer(
  ({ id, onSubmit, title, manualPlanningDialogStore }: Props) => {
    const { tenantConfigStore } = useStores()
    const { handleSubmit, formState, control } = useFormWithSchema<ManualPlanningFormProfile>(
      schema,
      defaultValues,
    )

    const navigateWithQueryParams = useNavigateWithQueryParams()

    const { t } = useTranslate()
    const { palette } = useMinimalsTheme()

    const input = useWatch({ control, name: 'manualPosition' })

    const planSingleContainerText = tenantConfigStore.isBayRowUniqueIdentificationModeActivated
      ? 'Enter manually the position to plan this container. You can enter a Bay, Bay.Row or Bay.Row.Tier'
      : 'Enter manually the position to plan this container. You can enter a Block, Block.Bay, Block.Bay.Row or Block.Bay.Row.Tier'

    const planMultipleContainersText = tenantConfigStore.isBayRowUniqueIdentificationModeActivated
      ? 'Enter manually the position to plan these containers. You can enter a Bay or Bay.Row'
      : 'Enter manually the position to plan these containers. You can enter a Block, Block.Bay or Block.Bay.Row'

    const manualPositionHelperSingleText =
      tenantConfigStore.isBayRowUniqueIdentificationModeActivated
        ? '(e.g. 01, 01.01, 01-03.01-02 or 01.01.1)'
        : ''

    const manualPositionHelperMultipleText =
      tenantConfigStore.isBayRowUniqueIdentificationModeActivated
        ? '(e.g. 01, 01.01 or 01-03.01-02)'
        : '(e.g. A, A.01, A.01.01 or A.01-03.01-02)'
    useEffect(() => {
      if (
        manualPlanningDialogStore.doesPlanningAlertsExist([
          ManualPlanningDialogStore.INSUFFICIENT_PLANNING_SPACE_ALERT_KEY,
          ManualPlanningDialogStore.DANGEROUS_GOODS_STACKING_VIOLATION_ALERT_KEY,
          ManualPlanningDialogStore.DANGEROUS_GOODS_SEGREGATION_VIOLATION_ALERT_KEY,
        ])
      ) {
        manualPlanningDialogStore.clearPlanningAlert(
          ManualPlanningDialogStore.INSUFFICIENT_PLANNING_SPACE_ALERT_KEY,
        )
        manualPlanningDialogStore.clearPlanningAlert(
          ManualPlanningDialogStore.DANGEROUS_GOODS_STACKING_VIOLATION_ALERT_KEY,
        )
        manualPlanningDialogStore.clearPlanningAlert(
          ManualPlanningDialogStore.DANGEROUS_GOODS_SEGREGATION_VIOLATION_ALERT_KEY,
        )
      }

      if (manualPlanningDialogStore.parser) {
        manualPlanningDialogStore.parser.validate(input.trim().toUpperCase())
        const { isValidDangerousGoods, isDangerousAlertMessage } =
          manualPlanningDialogStore.parser.validateDGLocation(
            manualPlanningDialogStore.isSingleContainer
              ? manualPlanningDialogStore.containerTurnover?.isDangerous
              : manualPlanningDialogStore.filter?.isDangerous,
          )

        if (!isValidDangerousGoods) {
          manualPlanningDialogStore.showPlanningAlert(
            ErrorCodes.PreplanningMixedDangerousareaNonDangerouscontainers,
            isDangerousAlertMessage,
          )
        } else {
          manualPlanningDialogStore.hidePlanningAlert(
            ErrorCodes.PreplanningMixedDangerousareaNonDangerouscontainers,
          )
        }
      }
    }, [
      input,
      manualPlanningDialogStore,
      manualPlanningDialogStore.containerSize,
      manualPlanningDialogStore.parser,
    ])

    const showHint = (hint: Hint) => {
      const label = hint.type.split('.')
      return `${t(label[0], label[1] ?? '')}${hint.text.length > 0 ? ':' : ''} ${hint.text}`
    }

    return (
      <Box id={id} component='form' noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
        <Typography variant='subtitle2'>{title}</Typography>

        {manualPlanningDialogStore.isSingleContainer &&
        manualPlanningDialogStore.containerTurnover ? (
          <ManualPlanningFormText text={t('planSingleContainer', planSingleContainerText)}>
            <ContainerTurnoverAttributes item={manualPlanningDialogStore.containerTurnover} />
          </ManualPlanningFormText>
        ) : (
          <ManualPlanningFormText text={t('planMultipleContainers', planMultipleContainersText)}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Chip label={manualPlanningDialogStore.numberOfMatchingFilters} />
              <ContainerTurnoverFilterAttributes item={manualPlanningDialogStore.filter} />
              <Button
                onClick={() =>
                  navigateWithQueryParams(
                    PATH_STORAGE.yardManagement,
                    mapFormValuesToQueryParams({
                      ...manualPlanningDialogStore.filterFormProfile,
                      locationStatus: 'All',
                    }),
                    true,
                  )
                }
              >
                {t('showOnYard', 'Show on yard')}
              </Button>
            </Box>
          </ManualPlanningFormText>
        )}

        <ControlledTextField<ManualPlanningFormProfile>
          name='manualPosition'
          formState={formState}
          control={control}
          placeholder={
            manualPlanningDialogStore.isSingleContainer
              ? t('manualPositionHelperSingle', manualPositionHelperSingleText)
              : t('manualPositionHelperMultiple', manualPositionHelperMultipleText)
          }
          fullWidth
          autoFocus
          fontColor={manualPlanningDialogStore.parser?.isInvalid ? palette.error.main : ''}
          uppercase
          helperText={
            !manualPlanningDialogStore.parser.isAccepted ||
            manualPlanningDialogStore.parser.optionCount > 1
              ? showHint(manualPlanningDialogStore.parser.hint)
              : ''
          }
        />
        <Box my={1}>
          {!!manualPlanningDialogStore.planningAlerts.length && (
            <StackedAlert alerts={manualPlanningDialogStore.planningAlerts} />
          )}
        </Box>
      </Box>
    )
  },
)
