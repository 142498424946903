import {
  AllocationRulesTemplateCreateRequest,
  AllocationRulesTemplateUpdateRequest,
  AllocationRuleTemplateDto,
  CarrierVisitDirection,
  YardPositionDto,
} from '@storage/app/api'
import { EntityStore } from '@storage/app/store/entity.store'
import { AllocationRuleTemplatesService } from '@storage/services/allocation-rule-templates.service'

export class AllocationRuleTemplatesV2Store extends EntityStore<AllocationRuleTemplateDto> {
  constructor(private readonly _allocationRuleTemplatesService: AllocationRuleTemplatesService) {
    super()
  }

  async loadAll() {
    const allocationRuleTemplates = await this._allocationRuleTemplatesService.getAll()
    this.setAll(allocationRuleTemplates)
  }

  async add(
    allocationRulesTemplateCreateRequest: AllocationRulesTemplateCreateRequest,
  ): Promise<AllocationRuleTemplateDto> {
    const allocationRuleTemplate = await this._allocationRuleTemplatesService.create(
      allocationRulesTemplateCreateRequest,
    )
    this.addOrUpdate(allocationRuleTemplate)
    return allocationRuleTemplate
  }

  async update(
    allocationRuleTemplateDto: AllocationRulesTemplateUpdateRequest,
  ): Promise<AllocationRuleTemplateDto> {
    const allocationRuleTemplate =
      await this._allocationRuleTemplatesService.update(allocationRuleTemplateDto)
    this.addOrUpdate(allocationRuleTemplate)
    return allocationRuleTemplate
  }

  async copyRuleTemplateToCarrierVisit(
    carrierVisitId: number,
    allocationRuleId: string,
    carrierVisitDirection: CarrierVisitDirection,
    destination: YardPositionDto,
  ) {
    const carrierVisitAllocationRule =
      await this._allocationRuleTemplatesService.copyRuleTemplateToCarrierVisit({
        carrierVisitId,
        allocationRuleId,
        carrierVisitDirection,
        destination,
      })
    return carrierVisitAllocationRule
  }

  async delete(id: string): Promise<void> {
    await this._allocationRuleTemplatesService.delete(id)
    this.remove(id)
  }
}
