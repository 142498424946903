import { NorthEast, SouthEast } from '@mui/icons-material'
import { Box, Card, Chip, Stack, Typography } from '@mui/material'
import { HandlingDirection } from '@storage/app/api'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'

interface GeneralCargoLocationCardProps {
  active?: boolean
  title: string
  handlingDirection: HandlingDirection
  subtitle?: string
  existingQuantity?: number
  reservedQuantity?: number
  newQuantity?: number
  onClick: () => void
}
const GeneralCargoLocationCard = ({
  active = false,
  title,
  handlingDirection,
  subtitle,
  existingQuantity,
  reservedQuantity,
  newQuantity,
  onClick,
}: GeneralCargoLocationCardProps) => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()

  const subtitleContent = () => {
    switch (true) {
      case !!existingQuantity && !reservedQuantity:
        return t('containsSimilarItems', 'Contains similar items')
      case !existingQuantity && !!reservedQuantity:
        return t('hasReservation', 'Has reservation')
      case !!existingQuantity && !!reservedQuantity:
        return t('containsSimilarItems&HasReservation', 'Contains similar items & has reservation')
      default:
        return t('newLocation', 'New location')
    }
  }

  return (
    <Stack
      onClick={onClick}
      component={Card}
      justifyContent='space-between'
      gap={theme.customSpacing.xxs}
      p={2}
      sx={{
        height: 165,
        flexShrink: 0,
        boxShadow: 'none',
        borderRadius: theme.customRadius.large,
        width: 191,
        bgcolor: active ? theme.palette.success.lighter : theme.palette.background.neutral,
        border: active ? `1px solid ${theme.palette.success.darkest}` : 'none',
        cursor: 'pointer',
        userSelect: 'none',
        '&:hover': {
          boxShadow: theme.shadows[3],
        },
      }}
    >
      <Box>
        <Stack direction='row' gap={theme.customSpacing.xxs} alignItems='center'>
          <Typography variant='subtitle2' color={theme.palette.success.darkest}>
            {title}
          </Typography>
          {!!newQuantity && (
            <Chip
              size='small'
              icon={handlingDirection === HandlingDirection.Inbound ? <SouthEast /> : <NorthEast />}
              label={newQuantity}
            />
          )}
        </Stack>
        <Typography variant='caption' color={theme.palette.text.secondary}>
          {subtitle || subtitleContent()}
        </Typography>
      </Box>

      <Stack direction='row' gap={theme.customSpacing.xxs} justifyContent='space-between'>
        {!!existingQuantity && (
          <Box>
            <Typography variant='caption' color={theme.palette.text.secondary}>
              {t('existing', 'Existing')}
            </Typography>

            <Typography variant='subtitle2' color={theme.palette.text.primary}>
              {existingQuantity}
            </Typography>
          </Box>
        )}
        {!!reservedQuantity && (
          <Box>
            <Typography variant='caption' color={theme.palette.text.secondary}>
              {t('reserved', 'Reserved')}
            </Typography>

            <Typography variant='subtitle2' color={theme.palette.text.primary}>
              {reservedQuantity}
            </Typography>
          </Box>
        )}
      </Stack>
    </Stack>
  )
}

export default GeneralCargoLocationCard
