import { IMessageBus, IQueryMessage } from '@planning/messages'
import { calculateDateRangeDiff } from '@planning/rt-stores/helpers'
import { IDateRange, IEntityQueryService } from '@planning/rt-stores/types'
import { makeObservable, observable, runInAction } from 'mobx'
import moment from 'moment'

export class EntityQueryService implements IEntityQueryService {
  loadedDateRange = {
    from: moment().endOf('d').toDate(),
    to: moment().endOf('d').toDate(),
  }
  fetchedIds: Set<number> = new Set()

  constructor(
    private messageBus: IMessageBus,
    private queryFunc: (query: IDateRange) => IQueryMessage,
    private fetchFunc?: (id: number) => IQueryMessage,
    private bulkFetchFunc?: (ids: number[]) => IQueryMessage,
  ) {
    makeObservable(this, {
      loadedDateRange: observable,
    })
  }

  fetch = async (query: IDateRange) => {
    if (!query.from || !query.to) return

    const toLoadDateRange = calculateDateRangeDiff(this.loadedDateRange, query)
    if (
      toLoadDateRange.from >= this.loadedDateRange.from &&
      toLoadDateRange.to <= this.loadedDateRange.to
    ) {
      return
    }

    runInAction(() => {
      if (this.loadedDateRange.from > toLoadDateRange.from) {
        this.loadedDateRange.from = toLoadDateRange.from
      }

      if (toLoadDateRange.to > this.loadedDateRange.to) {
        this.loadedDateRange.to = toLoadDateRange.to
      }
    })

    console.log('fetching', toLoadDateRange)
    await this.messageBus.dispatchQuery(this.queryFunc(toLoadDateRange))
  }

  fetchByIds = async (ids: number[]) => {
    if (this.bulkFetchFunc) {
      ids.forEach(id => {
        this.fetchedIds.add(id)
      })
      await this.messageBus.dispatchQuery(this.bulkFetchFunc(ids))
    } else {
      for (const id of ids) {
        await this.fetchById(id)
      }
    }
  }

  fetchById = async (id: number) => {
    if (!this.fetchFunc || this.fetchedIds.has(id)) return

    this.fetchedIds.add(id)

    await this.messageBus.dispatchQuery(this.fetchFunc(id))
  }
}
