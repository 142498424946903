import { BerthsApi } from '@storage/app/api'
import { createApiClient } from '@storage/app/http-client'
import { AsyncValidator } from '@storage/app/models'

interface Options {
  name?: string
  id?: string
}

const IsUniqueValidator: AsyncValidator<Options> = {
  name: 'is-unique',
  errorMessage: 'berthWithNameAlreadyExists', //'A berth with the same name already exists.',
  validate: async ({ name, id }) => (await createApiClient(BerthsApi).isUnique({ name, id })).data,
}

export default IsUniqueValidator
