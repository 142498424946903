import { Box, Typography, keyframes, styled } from '@mui/material'
import { OperationType } from '@operations/app/api'
import { WorkQueue } from '@operations/features/craneSplit/models/work-queue.model'
import { getRGBFromThemeByColorName } from '@operations/utils/helper'
import { Instance } from '@popperjs/core'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useRef } from 'react'
import Draggable from 'react-draggable'

const scaleTransform = keyframes`
  0%  { transform: scaleY(0);  }
  100% { transform: scaleY(1);  }
`

const showBorder = keyframes`
  0%  { border-bottom-style: hidden; }
  100% { border-bottom-style: inset; }
`

const fadeIn = keyframes`
  0%  { opacity: 0; }
  70%  { opacity: 0; }
  100% { opacity: 1; }
`

const AnimatedCard = styled(Box)<{ colorname: string; isfinished: string }>(
  ({ theme, colorname, isfinished }) => ({
    backgroundImage: colorname,
    color: isfinished ? '#c7c7c7' : theme.palette.common.white,
    position: 'relative',
    padding: '2px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: '1px solid white',
    boxSizing: 'border-box',
    transition: 'height 1s',
    transformOrigin: 'top',
    animation: `${scaleTransform} 1.5s ease-in-out, ${showBorder} 1.5s`,
    filter: `grayscale(${isfinished ? '.7' : '0'})`,
  }),
)

const AnimatedTypography = styled(Typography)(() => ({
  animation: `${fadeIn} 1.5s ease-in-out`,
}))

interface Props {
  workQueue: WorkQueue
  isDragging: boolean
  onStop: (workQueue: WorkQueue, currentPosition: number) => void
  onStart: () => void
  onOpenDetails: (workQueue: WorkQueue) => void
}

export const WorkQueueCard = ({ workQueue, isDragging, onStop, onStart, onOpenDetails }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const positionRef = useRef<{ x: number; y: number }>({ x: 0, y: 0 })
  const popperRef = useRef<Instance>(null)
  const areaRef = useRef<HTMLDivElement>(null)

  const handleMouseMove = (event: React.MouseEvent) => {
    positionRef.current = { x: event.clientX, y: event.clientY }

    if (popperRef.current != null) {
      popperRef.current.update()
    }
  }

  const color = `linear-gradient(to bottom, ${getRGBFromThemeByColorName(theme, workQueue.color)} 
  ${workQueue.completeness}%, 
  ${getRGBFromThemeByColorName(theme, workQueue.color, 0.75)} ${workQueue.completeness}%)`

  return (
    <Draggable
      key={workQueue.id}
      axis='y'
      disabled={!workQueue.canEdit}
      bounds={workQueue.boundary}
      position={{ x: 0, y: workQueue.currentPosition }}
      cancel='button'
      onStart={() => onStart()}
      onStop={(e, data) => onStop(workQueue, data.y)}
      onMouseDown={!workQueue.canEdit ? () => onOpenDetails(workQueue) : undefined}
    >
      <Box
        ref={areaRef}
        onMouseMove={handleMouseMove}
        sx={{
          position: 'relative',
          width: '9.5rem',
          cursor: workQueue.canEdit ? (isDragging ? 'grabbing' : 'grab') : 'default',
        }}
        data-cy={`workqueue-${workQueue.operationType.toLowerCase()}`}
      >
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
          }}
        >
          <AnimatedCard
            height={workQueue.height}
            colorname={color}
            isfinished={workQueue.completeness === 100 ? 'true' : ''}
          >
            {workQueue.height > 20 && (
              <AnimatedTypography>
                {workQueue.operationType === OperationType.Inbound
                  ? t('discharge', 'Discharge')
                  : t('load', 'Load')}
              </AnimatedTypography>
            )}
            {workQueue.height > 40 && <AnimatedTypography>{workQueue.crane}</AnimatedTypography>}
          </AnimatedCard>
        </Box>
      </Box>
    </Draggable>
  )
}
