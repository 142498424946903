import { Entities, Filter, GenericFilter, Group, GroupMap, Id, Item, SequenceMap } from '../types'

export interface MultiDragAwareReorderProps {
  entities: Entities
  filteredItems?: Item[]
  selectedItemIds: Id[]
  sourceGroupId: Id
  sourceItemIndex: number
  destinationGroupId: Id
  destinationItemIndex: number
}

export interface Result {
  entities: Entities
  selectedItemIds: Id[]
}

const withNewTaskIds = (column: Group, taskIds: Id[], sequence: SequenceMap): Group => ({
  id: column.id,
  name: column.name,
  ItemIds: taskIds,
  sequenced: column.sequenced,
  sequence: sequence,
  note: column.note,
  defaultGroup: column.defaultGroup,
})

const reorderIds = (list: Id[], startIndex: number, endIndex: number): any[] => {
  if (endIndex > list.length) endIndex = list.length

  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const reorderSequence = (
  sequenceMap: SequenceMap,
  startIndex: number,
  endIndex: number,
): SequenceMap => {
  if (!startIndex && startIndex != 0) return sequenceMap

  const result = Object.entries(sequenceMap)
  result.sort((a, b) => a[1] - b[1])

  const [removed] = result.splice(startIndex, 1)

  result.forEach(([key, value], index) => {
    if (value >= startIndex) {
      result[index] = [key, value - 1]
    }
  })

  result.forEach(([key, value], index) => {
    if (value >= endIndex) {
      result[index] = [key, value + 1]
    }
  })

  const updatedRemoved: [string, number] = [removed[0], endIndex]

  result.splice(endIndex, 0, updatedRemoved)

  const resultMap = result.reduce((previous: SequenceMap, [key, value], index) => {
    previous[key] = value
    return previous
  }, {})

  return resultMap
}

const updateNextSequence = (sequenceMap: SequenceMap, startIndex: number, modifierValue = 1) => {
  const result = Object.entries(sequenceMap)
  result.sort((a, b) => a[1] - b[1])

  result.forEach(([key, value], index) => {
    if (value >= startIndex) {
      sequenceMap[key] = value + modifierValue
    }
  })

  return sequenceMap
}

const reorderSingleDrag = ({
  entities,
  selectedItemIds,
  sourceGroupId,
  sourceItemIndex,
  destinationGroupId,
  destinationItemIndex,
}: MultiDragAwareReorderProps): Result => {
  // moving in the same list
  if (sourceGroupId === destinationGroupId) {
    const group: Group = entities.groups[sourceGroupId]
    const reorderedIds: Id[] = reorderIds(group.ItemIds, sourceItemIndex, destinationItemIndex)
    const reorderedSequence: SequenceMap = reorderSequence(
      group.sequence,
      sourceItemIndex,
      destinationItemIndex,
    )

    const updated: Entities = {
      ...entities,
      groups: {
        ...entities.groups,
        [group.id]: withNewTaskIds(group, reorderedIds, reorderedSequence),
      },
    }

    return {
      entities: updated,
      selectedItemIds: selectedItemIds,
    }
  }

  // moving to a new list
  const home: Group = entities.groups[sourceGroupId]
  const foreign: Group = entities.groups[destinationGroupId]

  // the id of the task to be moved
  const taskId: Id = home.ItemIds[sourceItemIndex]

  // remove from home column
  const newHomeTaskIds: Id[] = [...home.ItemIds]
  newHomeTaskIds.splice(sourceItemIndex, 1)

  const newHomeSequence: SequenceMap = { ...home.sequence }
  delete newHomeSequence[taskId]

  const updatedHomeSequence = updateNextSequence(newHomeSequence, sourceItemIndex, -1)

  // add to foreign column
  const newForeignTaskIds: Id[] = [...foreign.ItemIds]
  newForeignTaskIds.splice(destinationItemIndex, 0, taskId)

  const newForeignSequence = { ...foreign.sequence }
  const updatedForeignSequence = updateNextSequence(newForeignSequence, destinationItemIndex)

  newForeignSequence[taskId] = destinationItemIndex

  const updated: Entities = {
    ...entities,
    groups: {
      ...entities.groups,
      [home.id]: withNewTaskIds(home, newHomeTaskIds, updatedHomeSequence),
      [foreign.id]: withNewTaskIds(foreign, newForeignTaskIds, updatedForeignSequence),
    },
  }

  return {
    entities: updated,
    selectedItemIds: selectedItemIds,
  }
}

type TaskId = Id

export const getHomeColumn = (entities: Entities, taskId: TaskId): Group => {
  const columnId = entities.groupOrder.find((id: Id) => {
    const column: Group = entities.groups[id]
    return column.ItemIds.includes(taskId)
  })

  return entities.groups[columnId!]
}

const reorderMultiDrag = ({
  entities,
  selectedItemIds,
  sourceGroupId,
  sourceItemIndex,
  destinationGroupId,
  destinationItemIndex,
}: MultiDragAwareReorderProps): Result => {
  const sourceGroup: Group = entities.groups[sourceGroupId]
  const dragged: TaskId = sourceGroup.ItemIds[sourceItemIndex]

  const insertAtIndex: number = (() => {
    const destinationIndexOffset: number = selectedItemIds.reduce(
      (previous: number, current: TaskId): number => {
        if (current === dragged) {
          return previous
        }

        const final: Group = entities.groups[destinationGroupId]
        const column: Group = getHomeColumn(entities, current)

        if (column !== final) {
          return previous
        }

        const index: number = column.ItemIds.indexOf(current)

        if (index >= destinationItemIndex) {
          return previous
        }

        // the selected item is before the destination index
        // we need to account for this when inserting into the new location
        return previous + 1
      },
      0,
    )

    const result: number = destinationItemIndex - destinationIndexOffset
    return result
  })()

  // doing the ordering now as we are required to look up columns
  // and know original ordering
  const orderedSelectedTaskIds: TaskId[] = [...selectedItemIds]
  orderedSelectedTaskIds.sort((a: TaskId, b: TaskId): number => {
    // moving the dragged item to the top of the list
    if (a === dragged) {
      return -1
    }
    if (b === dragged) {
      return 1
    }

    // sorting by their natural indexes
    const columnForA: Group = getHomeColumn(entities, a)
    const indexOfA: number = columnForA.ItemIds.indexOf(a)
    const columnForB: Group = getHomeColumn(entities, b)
    const indexOfB: number = columnForB.ItemIds.indexOf(b)

    if (indexOfA !== indexOfB) {
      return indexOfA - indexOfB
    }

    // sorting by their order in the selectedTaskIds list
    return -1
  })

  // we need to remove all of the selected items from their groups
  const withRemovedTasks: GroupMap = entities.groupOrder.reduce(
    (previous: GroupMap, columnId: Id): GroupMap => {
      const group: Group = entities.groups[columnId]

      //get the selected group sequence
      const selectedGroupSequence = Object.entries(group.sequence).reduce(
        (previous: SequenceMap, [key, value]): SequenceMap => {
          if (selectedItemIds.includes(key)) {
            previous[key] = value
          }
          return previous
        },
        {},
      )

      if (Object.keys(selectedGroupSequence).length == 0) return previous

      // remove the id's of the items that are selected
      const remainingTaskIds: TaskId[] = group.ItemIds.filter(
        (id: TaskId): boolean => !selectedItemIds.includes(id),
      )

      // remove the id's of the items that are selected
      let remainingSequence: SequenceMap = Object.entries(group.sequence).reduce(
        (previous: SequenceMap, [key, value]): SequenceMap => {
          if (selectedItemIds.includes(key)) {
            return previous
          }

          previous[key] = value
          return previous
        },
        {},
      )

      orderedSelectedTaskIds
        .map((taskId: TaskId): number => group.sequence[taskId])
        .forEach((value, index) => {
          remainingSequence = updateNextSequence(remainingSequence, value - index, -1)
        })

      previous[group.id] = withNewTaskIds(group, remainingTaskIds, remainingSequence)
      return previous
    },
    entities.groups,
  )

  const final: Group = withRemovedTasks[destinationGroupId]
  const withInserted: TaskId[] = (() => {
    const base: TaskId[] = [...final.ItemIds]
    base.splice(insertAtIndex, 0, ...orderedSelectedTaskIds)
    return base
  })()

  const finalSequence: SequenceMap = { ...final.sequence }

  const updatedRemainingSequence = updateNextSequence(
    finalSequence,
    insertAtIndex,
    orderedSelectedTaskIds.length,
  )

  orderedSelectedTaskIds.forEach((taskId: TaskId, index: number): void => {
    updatedRemainingSequence[taskId] = insertAtIndex + index
  })

  // insert all selected tasks into final column
  const withAddedTasks: GroupMap = {
    ...withRemovedTasks,
    [final.id]: withNewTaskIds(final, withInserted, updatedRemainingSequence),
  }

  const updated: Entities = {
    ...entities,
    groups: withAddedTasks,
  }

  return {
    entities: updated,
    selectedItemIds: orderedSelectedTaskIds,
  }
}

export const multiDragAwareReorder = (args: MultiDragAwareReorderProps): Result => {
  if (args.selectedItemIds.length > 1) {
    return reorderMultiDrag(args)
  }
  return reorderSingleDrag(args)
}

export const multiSelectTo = (
  entities: Entities,
  selectedTaskIds: Id[],
  newTaskId: TaskId,
  filters?: Filter[],
  genericFilter?: GenericFilter,
): Id[] | null => {
  if (!selectedTaskIds.length) {
    return [newTaskId]
  }

  const columnOfNew: Group = getHomeColumn(entities, newTaskId)
  const indexOfNew: number = columnOfNew.ItemIds.indexOf(newTaskId)

  const lastSelected: Id = selectedTaskIds[selectedTaskIds.length - 1]
  const columnOfLast: Group = getHomeColumn(entities, lastSelected)
  const indexOfLast: number = columnOfLast.ItemIds.indexOf(lastSelected)

  if (columnOfNew !== columnOfLast) {
    return columnOfNew.ItemIds.slice(0, indexOfNew + 1)
  }

  if (indexOfNew === indexOfLast) {
    return null
  }

  const isSelectingForwards: boolean = indexOfNew > indexOfLast
  const start: number = isSelectingForwards ? indexOfLast : indexOfNew
  const end: number = isSelectingForwards ? indexOfNew : indexOfLast

  const inBetween: Id[] = columnOfNew.ItemIds.slice(start, end + 1)

  const filteredItems = getFilteredItems(entities, columnOfNew.id, filters, genericFilter)

  const toAdd: Id[] = inBetween.filter((taskId: Id): boolean => {
    if (
      selectedTaskIds.includes(taskId) ||
      !filteredItems.some((item: Item) => item.id === taskId)
    ) {
      return false
    }
    return true
  })

  const sorted: Id[] = isSelectingForwards ? toAdd : [...toAdd].reverse()
  const combined: Id[] = [...selectedTaskIds, ...sorted]

  return combined
}

export const getFilteredItems = (
  entities: Entities,
  groupId: Id,
  filters?: Filter[],
  genericFilter?: GenericFilter,
) => {
  return entities.groups[groupId].ItemIds.filter((itemId: Id) =>
    filterItems(entities.items[itemId], filters),
  )
    .filter((itemId: Id) => genericFilterItems(entities.items[itemId], genericFilter))
    .reduce((previous: any, current: Id) => {
      previous.push(entities.items[current])
      return previous
    }, [])
}

const filterItems = (item: Item, filters?: Filter[]) => {
  return filters && filters.length > 0
    ? filters.some(filter => item.content[filter.key] === filter.value)
    : true
}

const genericFilterItems = (item: Item, genericFilter?: GenericFilter) => {
  if (!genericFilter) return true
  if (!genericFilter.value) return true
  if (!genericFilter?.keys.length) return true

  const terms = genericFilter.value.split(' ')

  return genericFilter.keys.some(key => containsKeyValue(item.content, key, terms))
}

const containsKeyValue = (obj: any, key: string, terms: string[]): boolean => {
  if (typeof obj !== 'object' || obj === null) {
    return false
  }

  if (obj.hasOwnProperty(key)) {
    return terms.some(term => obj[key]?.toLocaleLowerCase().includes(term.toLocaleLowerCase()))
  }

  return containsKeyValueInObjectOrArray(obj, key, terms)
}

const containsKeyValueInObjectOrArray = (obj: any, key: string, terms: string[]): boolean => {
  for (const k in obj) {
    if (obj.hasOwnProperty(k)) {
      if (
        isArrayAndContainsKeyValue(obj[k], key, terms) ||
        isObjectAndContainsKeyValue(obj[k], key, terms)
      ) {
        return true
      }
    }
  }
  return false
}

const isArrayAndContainsKeyValue = (element: any, key: string, terms: string[]) => {
  if (Array.isArray(element)) {
    return element.some(item => containsKeyValue(item, key, terms))
  }
  return false
}

const isObjectAndContainsKeyValue = (element: any, key: string, terms: string[]) => {
  if (typeof element === 'object') {
    return containsKeyValue(element, key, terms)
  }
  return false
}

export const insertDefaultGroup = (entities: Entities, defaultGroupId: string) => {
  const ungroupedItems = Object.keys(entities.items).filter(
    (itemId: Id) =>
      !Object.values(entities.groups).some((group: Group) => group.ItemIds.includes(itemId)),
  )

  const sequenceMap = ungroupedItems.reduce(
    (previous: SequenceMap, current: string, index): SequenceMap => {
      previous[current] = index
      return previous
    },
    {},
  )

  return {
    ...entities,
    groups: {
      [defaultGroupId]: {
        id: defaultGroupId,
        ItemIds: ungroupedItems,
        name: 'Ungrouped',
        note: '',
        sequenced: false,
        sequence: sequenceMap,
        defaultGroup: true,
      },
      ...entities.groups,
    },
    groupOrder: [defaultGroupId, ...entities.groupOrder],
  }
}
