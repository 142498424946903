import { YardBlockDto } from '@storage/app/api'
import ContainerIcon from '@storage/components/icons/svg/ContainerIcon.svg'
import ContextMenuIcon from '@storage/components/icons/svg/ContextMenuIcon.svg'
import { KonvaText } from '@storage/components/konva-text'
import useContextMenu from '@storage/hooks/use-context-menu.hook'
import { Menu, MenuItem } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { YardElement, YardElementType } from '@storage/pages/yard-setup/types/yard-setup.types'
import { useTranslate } from '@tolgee/react'
import Konva from 'konva'
import { Vector2d } from 'konva/lib/types'
import { useRef } from 'react'
import { Group, Image, Rect, Transformer } from 'react-konva'
import { Html, useImage } from 'react-konva-utils'
import { FLEXIBLE_ZONE_HEIGHT, FLEXIBLE_ZONE_ICON_SIZE, FLEXIBLE_ZONE_WIDTH } from '../constants'
import { useTransformer } from '../hooks'
import { BERTH_DIMENSIONS } from '../layers/berths/constants'

interface Props {
  flexibleZone: YardBlockDto
  stagePosition: Vector2d
  isSelected: boolean
  inEditMode: boolean
  onGeometryChange: (element: YardElement) => void
  onSelect: () => void
  onEdit: () => void
  onDelete: () => void
  onResize: (width: number) => void
}

export const FlexibleZoneElement = ({
  flexibleZone,
  isSelected,
  inEditMode,
  stagePosition,
  onGeometryChange,
  onSelect,
  onEdit,
  onDelete,
  onResize,
}: Props) => {
  const { palette } = useMinimalsTheme()
  const [contextMenuIcon] = useImage(ContextMenuIcon)
  const [containerIcon] = useImage(ContainerIcon)
  const flexibleZoneRef = useRef<Konva.Group>(null)

  const { handleOpenMenu, menuProps } = useContextMenu()

  const { t } = useTranslate()

  const { transformerProps, handleTransformEnd } = useTransformer({
    refreshDeps: [isSelected],
    stagePosition,
    transformerFor: flexibleZoneRef,
    onRotate: (x, y, rotation) =>
      onGeometryChange({
        type: YardElementType.FlexibleZone,
        id: flexibleZone.id,
        geometry: {
          left: x,
          top: y,
          rotation: Math.round(rotation),
        },
      }),
    currentRotation: flexibleZone.geometry?.rotation,
    currentWidth: flexibleZone.geometry?.width ?? FLEXIBLE_ZONE_WIDTH,
    onResize,
    resizeAngles: ['middle-left', 'middle-right'],
    maxResizeWidth: BERTH_DIMENSIONS.MAX_WIDTH,
    minResizeWidth: BERTH_DIMENSIONS.MIN_WIDTH,
  })

  const handleDragEnd = async (p: Vector2d) => {
    onGeometryChange({
      type: YardElementType.FlexibleZone,
      id: flexibleZone.id,
      geometry: {
        top: Math.round(p.y),
        left: Math.round(p.x),
      },
    })
  }

  if (!flexibleZone.geometry) {
    return <></>
  }

  return (
    <>
      <Group
        x={flexibleZone.geometry?.left}
        y={flexibleZone.geometry?.top}
        width={flexibleZone.geometry.width ?? FLEXIBLE_ZONE_WIDTH}
        height={FLEXIBLE_ZONE_HEIGHT}
        ref={flexibleZoneRef}
        rotation={flexibleZone.geometry?.rotation}
        onTransformEnd={handleTransformEnd}
        draggable={inEditMode && isSelected}
        onDragEnd={({ currentTarget }) => handleDragEnd(currentTarget.position())}
      >
        <Rect
          height={FLEXIBLE_ZONE_HEIGHT}
          width={flexibleZone.geometry.width ?? FLEXIBLE_ZONE_WIDTH}
          fill={palette.secondary.light}
          cornerRadius={8}
          onClick={onSelect}
        />
        <Image
          image={containerIcon}
          onClick={onSelect}
          width={FLEXIBLE_ZONE_ICON_SIZE}
          height={FLEXIBLE_ZONE_ICON_SIZE}
          x={((flexibleZone.geometry.width ?? FLEXIBLE_ZONE_WIDTH) - FLEXIBLE_ZONE_ICON_SIZE) / 2}
          y={(FLEXIBLE_ZONE_HEIGHT - FLEXIBLE_ZONE_ICON_SIZE) / 2}
        />
        {!inEditMode && (
          <KonvaText
            text={flexibleZone.capacityOccupied + flexibleZone.capacityReserved}
            fontSize={14}
            x={
              ((flexibleZone.geometry.width ?? FLEXIBLE_ZONE_WIDTH) - FLEXIBLE_ZONE_ICON_SIZE) / 2 +
              8 +
              FLEXIBLE_ZONE_ICON_SIZE
            }
            y={(FLEXIBLE_ZONE_HEIGHT - 12) / 2}
          />
        )}

        {inEditMode && isSelected && (
          <>
            <Image
              image={contextMenuIcon}
              x={flexibleZone.geometry.width ?? FLEXIBLE_ZONE_WIDTH}
              onClick={({ evt }) => handleOpenMenu(evt.clientX, evt.clientY)}
            />
            <Html>
              <Menu {...menuProps}>
                <MenuItem onClick={onEdit}>{t('edit', 'Edit')}</MenuItem>
                <MenuItem onClick={onDelete}>{t('delete', 'Delete')}</MenuItem>
              </Menu>
            </Html>
          </>
        )}
        <Group x={0} y={-25}>
          <KonvaText
            text={flexibleZone.name}
            fontSize={14}
            fontFamily='Arial'
            fontStyle='bold'
            fill='#8A4BAC'
            align='center'
            width={flexibleZone.geometry.width ?? FLEXIBLE_ZONE_WIDTH}
          />
        </Group>
      </Group>
      {inEditMode && isSelected && <Transformer {...transformerProps} />}
    </>
  )
}
