import { TextField, TextFieldProps } from '@mui/material'
import { Controller, FieldValues, FormState, UseControllerProps } from 'react-hook-form'

type FieldProps = Pick<TextFieldProps, 'label' | 'disabled' | 'fullWidth' | 'variant'>

interface Props<T extends FieldValues> extends UseControllerProps<T>, FieldProps {
  formState: FormState<T>
  min?: number | string | null
  max?: number | string | null
}

export const ControlledNumberField = <T extends FieldValues>({
  name,
  control,
  label,
  fullWidth,
  disabled,
  min,
  max,
  variant,
  formState: { errors },
}: Props<T>) => {
  return (
    <Controller
      render={({ field }) => (
        <TextField
          {...field}
          value={field.value ?? ''}
          error={!!errors[name]}
          helperText={errors[name]?.message as string}
          label={label}
          inputProps={{ min, max }}
          type='number'
          fullWidth={fullWidth}
          disabled={disabled}
          variant={variant ?? 'outlined'}
        />
      )}
      name={name}
      control={control}
    />
  )
}
