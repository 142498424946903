import { createSvgIcon } from '@mui/material'

export const ReplaceIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M21.3377 10.6583C19.0433 6.5532 16.6716 2.68562 13.9471 8.359C13.8382 8.57209 13.738 8.84391 13.6354 9.12196C13.3869 9.7956 13.125 10.5058 12.6942 10.5058V10.4986C12.2635 10.4986 12.0016 9.78845 11.7531 9.11484C11.6506 8.83676 11.5503 8.56491 11.4414 8.3518C8.71686 2.67842 6.34514 6.546 4.05072 10.6511L3.88642 10.9453L3.88637 10.9454C3.77356 11.1472 3.66641 11.3389 3.57025 11.5129L3.58768 11.5016L0 17.8243C1.44454 18.7184 3.31516 18.6846 4.81992 17.9614C5.53568 17.6653 6.11767 17.152 6.65115 16.474C6.93407 16.1146 7.51316 15.12 8.3885 13.4901C8.55291 13.8098 8.71374 14.1413 8.87542 14.4746C9.82206 16.4261 10.798 18.4379 12.6942 18.4739V18.4811C14.5905 18.4451 15.5664 16.4332 16.5131 14.4817C17.7174 11.9991 18.8742 9.61427 21.8182 11.52C21.7195 11.3413 21.6091 11.1439 21.4929 10.936L21.4922 10.9348L21.3377 10.6583ZM24 16.0471C24 17.3913 22.9343 18.4811 21.6198 18.4811C20.3053 18.4811 19.2397 17.3913 19.2397 16.0471C19.2397 14.703 20.3053 13.6132 21.6198 13.6132C22.9343 13.6132 24 14.703 24 16.0471Z'
      fill='currentColor'
    />
  </svg>,
  'ReplaceIcon',
)
