import { ControlledTextField } from '@storage/components/form-controls'
import { Box, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { useFormContext } from 'react-hook-form'
import { ContainerTurnoversFilterFormProfile } from '../container-turnovers-filter-form.profile'

export const DestinationControls = () => {
  const { t } = useTranslate()
  const { control, formState } = useFormContext<ContainerTurnoversFilterFormProfile>()

  return (
    <Box>
      <Typography variant='h6' my={1}>
        {t('destination', 'Destination')}
      </Typography>

      <Box sx={{ gap: 1, display: 'flex', flexWrap: 'wrap', maxWidth: '50%' }}>
        <Box flex={1}>
          <ControlledTextField
            name='portOfLoading'
            formState={formState}
            fullWidth
            control={control}
            label={t('portOfLoading', 'Port of loading')}
          />
        </Box>
        <Box flex={1}>
          <ControlledTextField
            name='portOfDischarge'
            formState={formState}
            fullWidth
            control={control}
            label={t('portOfDischarge', 'Port of discharge')}
          />
        </Box>
      </Box>
    </Box>
  )
}
