import { Button, Menu, MenuItem } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { ServiceOrdersType } from 'modules/planning/src/pages/ServiceOrders/Stores/ServiceOrdersViewStore'
import { FC, useState } from 'react'

export const CreateServiceOrderButton: FC<{
  onSelectServiceType: (serviceType: ServiceOrdersType) => void
}> = ({ onSelectServiceType }) => {
  const { t } = useTranslate()

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleSelectServiceType = (serviceType: ServiceOrdersType) => {
    onSelectServiceType(serviceType)
    setAnchorEl(null)
  }

  return (
    <>
      <Button variant='contained' size='large' onClick={handleClick}>
        {t('create', 'Create')}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={() => handleSelectServiceType(null)}>
        <MenuItem onClick={() => handleSelectServiceType('stuffing')}>
          {t('stuffing', 'Stuffing')}
        </MenuItem>
        <MenuItem onClick={() => handleSelectServiceType('stripping')}>
          {t('stripping', 'Stripping')}
        </MenuItem>
        {
          <MenuItem onClick={() => handleSelectServiceType('cooling')}>
            {t('cooling', 'Cooling')}
          </MenuItem>
        }
      </Menu>
    </>
  )
}
