import { Box, Container } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { IssuesPageHeader } from './Components/IssuesPageHeader'
import { IssuesTypeFilter } from './Components/IssuesTypeFilter'

export const IssuesPage = observer(() => {
  const theme = useMinimalsTheme()

  const { issuesViewStore } = usePlanningStore()

  useEffect(() => {
    issuesViewStore.fetchData()
  }, [issuesViewStore])

  return (
    <Box
      sx={{
        height: '100%',
        bgcolor: theme.palette.grey[100],
      }}
    >
      <IssuesPageHeader store={issuesViewStore} />
      <IssuesTypeFilter store={issuesViewStore} />

      <Container
        sx={{
          paddingY: theme.customSpacing.m,
        }}
      >
        wip
      </Container>
    </Box>
  )
})
