import { Alert, Drawer, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useOperationsStore } from '@operations/AppProvider'
import { useTranslate } from '@tolgee/react'
import { BackButton, Header, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { CoolingOrderList } from '../components/CoolingOrderList/CoolingOrderList'

export const CoolingOrderSearchContainer = observer(() => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const { coolingOrdersUIStore } = useOperationsStore()

  const handleSearch = (newValue: string) => {
    coolingOrdersUIStore.setSearchText(newValue)
  }

  const handleReturn = () => {
    coolingOrdersUIStore.toggleSearch()
  }

  return (
    <Drawer
      open={coolingOrdersUIStore.isSearchOpen}
      anchor='right'
      PaperProps={{
        sx: {
          width: '100vw',
        },
      }}
    >
      <Header
        leftRenderOption={<BackButton tooltip={t('return', 'Return')} onGoBack={handleReturn} />}
        searchInputType='text'
        onSearchFieldChange={handleSearch}
        enableSearchField
        searchInputLabel={t('searchFor', 'Search for {label}', {
          label: t('aContainerNumber', 'a container number'),
        })}
      />
      <Container sx={{ paddingY: theme.customSpacing.m }}>
        {!coolingOrdersUIStore.isSearching && (
          <Alert severity='info'>
            <Typography variant='subtitle2'>
              {t(
                'hintTryTheLastFourDigitsOfTheContainerNumber',
                'Hint, try the last 4 digits of the container number',
              )}
            </Typography>
          </Alert>
        )}

        {coolingOrdersUIStore.isSearching &&
          coolingOrdersUIStore.searchedCoolingOrders.length === 0 && (
            <Alert severity='warning'>
              <Typography variant='subtitle2'>
                {t('noMatchingResults', 'No matching results')}
              </Typography>
            </Alert>
          )}

        <CoolingOrderList
          orders={coolingOrdersUIStore.searchedCoolingOrders}
          highlightText={coolingOrdersUIStore.searchText}
        />
      </Container>
    </Drawer>
  )
})
