import { BerthDto, YardBlockDto } from '@storage/app/api'

export enum YardElementType {
  YardBlock,
  Berth,
  FlexibleZone,
}

export interface YardElementGeometry {
  top?: number
  left?: number
  rotation?: number
  width?: number | null
}

export type YardElementDto = YardBlockDto | BerthDto

export interface YardElement {
  type: YardElementType
  id: string
  geometry: YardElementGeometry
}
