import { useNavigate } from 'react-router'
import { PATH_STORAGE } from '@storage/routes'

export const useGoBack = () => {
  const navigate = useNavigate()

  return () => {
    navigate(PATH_STORAGE.yardPlanningDashboard.root)
  }
}
