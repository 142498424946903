import { Box, Card, CardContent, CardHeader, Stack, Typography } from '@mui/material'
import { colorEmptyOccupancy, colorFullOccupancy } from '@storage/pages/yard-management/constants'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'

interface LegendProps {
  title: string
  color: string
}

export const LegendItem = ({ title, color }: LegendProps) => {
  const { palette, customRadius } = useMinimalsTheme()

  return (
    <Stack flexDirection='row' gap='1rem' alignItems='center'>
      <Box
        sx={{
          width: '24px',
          height: '24px',
          border: `1px solid ${palette.grey[600]}`,
          backgroundColor: color,
          borderRadius: customRadius.small,
        }}
      ></Box>
      <Typography variant='body2'>{title}</Typography>
    </Stack>
  )
}

const YardPlanningDashboardLegend = observer(() => {
  const { t } = useTranslate()
  const { palette, customSpacing, zIndex } = useMinimalsTheme()
  const [showContent, setShowContent] = useState(true)

  return (
    <Card
      sx={{
        position: 'absolute',
        bottom: customSpacing.l,
        right: customSpacing.l,
        zIndex: zIndex.mobileStepper,
        cursor: 'pointer',
        width: '12rem',
      }}
      onClick={() => setShowContent(value => !value)}
    >
      <CardHeader
        title={t('legend', 'Legend')}
        sx={{ paddingBottom: !showContent ? customSpacing.s : undefined }}
      />
      {showContent && (
        <CardContent>
          <Stack gap={1}>
            <LegendItem title={t('empty', 'Empty')} color={colorEmptyOccupancy} />

            <LegendItem title={t('occupied', 'Occupied')} color={colorFullOccupancy} />

            <LegendItem title={t('selected', 'Selected')} color={palette.info.light} />
          </Stack>
        </CardContent>
      )}
    </Card>
  )
})

export default YardPlanningDashboardLegend
