import { Box, FormControlLabel, Radio, TextField, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'

export interface YardPositionInputProps {
  position?: string
  enableEnterPosition: boolean
  disableSelectFlexibleZones?: boolean
  areas: string[]
  radio: string
  hint: string
  onChangePosition: (value: string) => void
  onChangeRadio: (value: string) => void
  label?: string
  disabled?: boolean
}

export const YardPositionInput = ({
  position,
  enableEnterPosition,
  disableSelectFlexibleZones,
  areas,
  radio,
  hint,
  onChangePosition,
  onChangeRadio,
  disabled,
  label,
}: YardPositionInputProps) => {
  const { t } = useTranslate()
  const { palette } = useMinimalsTheme()
  const radioProps = (item: string) => ({
    checked: item === radio,
    value: item,
    sx: { width: '4rem' },
  })

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        alignItems: 'flex-start',
        justifyContent: 'center',
        width: 'inherited',
        height: 'inherited',
      }}
    >
      {(enableEnterPosition || !disableSelectFlexibleZones) && (
        <FormControlLabel
          value={'#'}
          label={''}
          control={
            <>
              {!disableSelectFlexibleZones && (
                <Radio {...radioProps('#')} onChange={e => onChangeRadio(e.target.value)} />
              )}
              <TextField
                value={position}
                sx={{
                  input: {
                    color: palette.grey[600],
                    fontSize: 24,
                    fontWeight: 600,
                    textTransform: 'uppercase',
                  },
                }}
                label={label ?? t('enterYardPosition', 'Enter Yard Position')}
                error={hint !== ''}
                helperText={hint}
                onChange={e => onChangePosition(e.target.value.toUpperCase())}
                onClick={() => onChangeRadio('#')}
                disabled={disabled}
              />
            </>
          }
        />
      )}
      {!disableSelectFlexibleZones &&
        areas.map(area => (
          <FormControlLabel
            key={area}
            value={area}
            sx={{ height: '48px' }}
            label={
              <Typography variant='h2' sx={{ color: palette.grey[600] }}>
                {area}
              </Typography>
            }
            control={<Radio {...radioProps(area)} onChange={e => onChangeRadio(e.target.value)} />}
          />
        ))}
    </Box>
  )
}
