import { ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material'
import { ContainerJourney } from '@planning/pages/ServiceOrders/Stores/ContainerJourneyDataStore'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { ContainerVisitsCardBody } from './ContainerVisitsCardBody'

interface IProps {
  visits: ContainerJourney[]
  onClickEdit?: (journey: ContainerJourney) => void
  isInsideIssueCard?: boolean
}

export const AnnouncedContainerVisitsAccordion: FC<IProps> = observer(
  ({ visits, onClickEdit, isInsideIssueCard }) => {
    const theme = useMinimalsTheme()
    const { t } = useTranslate()

    return (
      <Box sx={{ borderTop: `1px solid ${theme.palette.grey[300]}` }}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            sx={{
              bgcolor: theme.palette.grey[200],
            }}
          >
            <Typography variant='body1'>
              {t('viewAllContainerJourneys', 'View all container journeys')}
            </Typography>
          </AccordionSummary>

          <AccordionDetails
            sx={{
              bgcolor: theme.palette.grey[100],
              padding: 0,
            }}
          >
            {visits.map((cv, index) => (
              <Box
                key={'container-visits-card-body' + index}
                sx={{
                  borderTop: `1px solid ${theme.palette.grey[300]}`,
                  borderBottom: `1px solid ${theme.palette.grey[300]}`,
                  '&:last-child': {
                    borderBottom: '0',
                  },
                }}
              >
                <ContainerVisitsCardBody
                  isInsideIssueCard={isInsideIssueCard}
                  containerVisit={cv}
                  onClickEdit={onClickEdit}
                />
              </Box>
            ))}
          </AccordionDetails>
        </Accordion>
      </Box>
    )
  },
)
