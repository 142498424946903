import { Alert, Box, Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { CraneSplitMessageUIStore } from '../stores/crane-split-message.ui-store'

interface Props {
  uiStore: CraneSplitMessageUIStore
}

export const CraneSplitErrorMessageContainer = observer(({ uiStore }: Props) => {
  return (
    <>
      {uiStore.message && (
        <Grid item={true} xs={12} margin={'0.5rem'}>
          <Alert onClose={() => uiStore.setMessage()} severity={uiStore.severity} variant='filled'>
            <Box sx={{ whiteSpace: 'pre-wrap' }}>{uiStore.message}</Box>
          </Alert>
        </Grid>
      )}
    </>
  )
})
