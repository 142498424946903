import { TEntity } from '@storage/app/models'
import { PaginatedEntityStore } from '@storage/app/store/paginated-entity.store'
import { PaginationStore } from '@storage/app/store/pagination.store'
import { TablePagination } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useTranslate } from '@tolgee/react'

interface Props<T extends TEntity> {
  paginationStore: PaginationStore
  paginatedEntityStore: PaginatedEntityStore<T>
}

export const Pagination = <T extends TEntity>({
  paginatedEntityStore,
  paginationStore,
}: Props<T>) => {
  const { palette } = useMinimalsTheme()
  const { t } = useTranslate()

  return (
    <TablePagination
      sx={{
        position: 'absolute',
        width: 'fill-available',
        bottom: 0,
        zIndex: 1,
        backgroundColor: palette.common.white,
        boxShadow: `0px 2px 16px ${palette.grey[300]}`,
      }}
      page={paginatedEntityStore.pagination.pageNumber - 1}
      count={paginatedEntityStore.pagination.totalCount}
      onPageChange={(_, page) => paginationStore.paginate(page + 1)}
      onRowsPerPageChange={({ target }) => paginationStore.changeSize(Number(target.value))}
      rowsPerPage={paginationStore.pagination.pageSize}
      rowsPerPageOptions={paginationStore.itemsPerPage}
      labelRowsPerPage={t('itemsPerPage', 'Items Per Page')}
      component='div'
    />
  )
}
