import { FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import { DoorDirection } from '@planning/app/api'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Control, Controller } from 'react-hook-form'
import { ICreateOrderFormData } from './CreateOrderPageForm'

type Props = {
  control: Control<ICreateOrderFormData>
}

export const DoorDirectionField: FC<Props> = observer(({ control }) => {
  const { t } = useTranslate()

  return (
    <Grid container spacing={2} sx={{ mt: 1 }}>
      <Grid item xs={12}>
        <Typography variant='subtitle1'>{t('doorDirection', 'Door Direction')}</Typography>
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel>{t('selectTheDoorDirection', 'Select the door direction')}</InputLabel>
          <Controller
            name={'doorDirection'}
            control={control}
            render={({ field }) => (
              <Select label={t('selectTheDoorDirection', 'Select the door direction')} {...field}>
                <MenuItem value={DoorDirection.Anyway}>{t('anyway', 'Anyway')}</MenuItem>
                <MenuItem value={DoorDirection.Inward}>{t('inward', 'Inward')}</MenuItem>
                <MenuItem value={DoorDirection.Outward}>{t('outward', 'Outward')}</MenuItem>
              </Select>
            )}
          />
        </FormControl>
      </Grid>
    </Grid>
  )
})
