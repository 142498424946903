import { YardSummaryDto } from '@storage/app/api'
import { useStores } from '@storage/hooks/use-stores.hook'
import { Box, Paper, Typography } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'

type Props = YardSummaryDto

export const YardSummary = observer(({ free, total, occupied, reserved }: Props) => {
  const { palette } = useMinimalsTheme()
  const { t } = useTranslate()
  const { tenantConfigStore } = useStores()

  return (
    <Paper
      sx={{
        position: 'fixed',
        bottom: 10,
        right: 15,
        display: 'flex',
        flexFlow: 'column',
        padding: '1rem',
        gap: '0.5rem',
        background: palette.background.neutral,
      }}
    >
      <Box sx={{ display: 'flex', flexFlow: 'column' }}>
        <Typography variant='subtitle2'>
          {t('total', 'Total')}: {total}
        </Typography>
        <Typography color={palette.error.dark} variant='subtitle2'>
          {t('occupied', 'Occupied')}: {occupied}
        </Typography>
        {!tenantConfigStore.skipYardPlanning && (
          <Typography variant='subtitle2'>
            {t('reserved', 'Reserved')}: {reserved}
          </Typography>
        )}

        <Typography variant='subtitle2'>
          {t('free', 'Free')}: {free}
        </Typography>
      </Box>
    </Paper>
  )
})
