import { Box, Grid, Typography } from '@mui/material'
import { CarrierType, CarrierVisitDirection, HoldStatus, OrderStatus } from '@planning/app/api'
import { CarrierTypeIcon } from '@planning/components/CarrierTypeIcon'
import { getContainerTypeParameters } from '@planning/components/ContainerTypesMap'
import { ContainerVisitStatusChip } from '@planning/components/ContainerVisitStatusChip'
import { emptyIndicator } from '@planning/constants'
import { formatDateTime } from '@planning/utils'
import { useTranslate } from '@tolgee/react'
import { BinIcon, EditIcon, IconButton, useMinimalsTheme } from '@tom-ui/ui'
import _, { Dictionary } from 'lodash'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { ContainerVisit, IContainerVisitOrderItem } from '../stores/ContainerVisitItem'

type Props = {
  containerVisit: ContainerVisit
  onClickEdit?: () => void
  onClickDelete?: () => void
  orderCreationOnly?: boolean
}

const templateTranslationMaps: Dictionary<{ key: string; defaultText: string }> = {
  [`${CarrierType.Vessel}_${CarrierType.Train}`]: { key: 'import', defaultText: 'Import' },
  [`${CarrierType.Vessel}_${CarrierType.Truck}`]: { key: 'import', defaultText: 'Import' },
  [`${CarrierType.Vessel}_${CarrierType.Vessel}`]: {
    key: 'transshipment',
    defaultText: 'Transshipment',
  },
  [`${CarrierType.Vessel}_undefined`]: { key: 'storage', defaultText: 'Storage' },

  [`${CarrierType.Truck}_${CarrierType.Train}`]: { key: 'intermodal', defaultText: 'Intermodal' },
  [`${CarrierType.Truck}_${CarrierType.Truck}`]: { key: 'intermodal', defaultText: 'Intermodal' },
  [`${CarrierType.Truck}_${CarrierType.Vessel}`]: { key: 'export', defaultText: 'Export' },
  [`${CarrierType.Truck}_undefined`]: { key: 'storage', defaultText: 'Storage' },

  [`${CarrierType.Train}_${CarrierType.Train}`]: { key: 'intermodal', defaultText: 'Intermodal' },
  [`${CarrierType.Train}_${CarrierType.Truck}`]: { key: 'intermodal', defaultText: 'Intermodal' },
  [`${CarrierType.Train}_${CarrierType.Vessel}`]: { key: 'export', defaultText: 'Export' },
  [`${CarrierType.Train}_undefined`]: { key: 'storage', defaultText: 'Storage' },

  [`undefined_${CarrierType.Train}`]: { key: 'import', defaultText: 'Import' },
  [`undefined_${CarrierType.Truck}`]: { key: 'import', defaultText: 'Import' },
  [`undefined_${CarrierType.Vessel}`]: { key: 'export', defaultText: 'Export' },
}

export const ContainerVisitStatus = {
  Expected: 'Expected',
  OnTerminal: 'OnTerminal',
  Departed: 'Departed',
}

export type ContainerVisitStatus = (typeof ContainerVisitStatus)[keyof typeof ContainerVisitStatus]

export const OrdersListCard: FC<Props> = observer(
  ({ containerVisit, onClickEdit, onClickDelete, orderCreationOnly }) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()

    const getStatus = () => {
      if (!inbound || inbound?.data.status === OrderStatus.Open)
        return ContainerVisitStatus.Expected
      if (!inbound || inbound?.data.status === OrderStatus.Fulfilled)
        return ContainerVisitStatus.OnTerminal
      if (outbound?.data.status === OrderStatus.Open) return ContainerVisitStatus.OnTerminal
      return ContainerVisitStatus.Departed
    }

    const renderContainerAttr = (label: string) => (
      <span
        style={{
          fontSize: '.875rem',
          color: theme.palette.grey[600],
          marginRight: '.5rem',
          fontWeight: 'bold',
        }}
      >
        {label}
      </span>
    )

    const inbound = containerVisit?.[0]
    const outbound = containerVisit?.[1]
    const order = (inbound ?? outbound)?.data

    if (!order) return <></>

    const { label, icon } = getContainerTypeParameters(order.containerType, t)

    const template = (
      inboundCarrierVisitType?: CarrierType,
      outboundCarrierVisitType?: CarrierType,
    ) => {
      return (
        templateTranslationMaps[`${inboundCarrierVisitType}_${outboundCarrierVisitType}`] ?? {
          key: '',
          defaultText: '',
        }
      )
    }

    const InboundAndOutboundInfo: FC<{
      direction: CarrierVisitDirection
      containerData?: IContainerVisitOrderItem
    }> = observer(({ direction, containerData }) => {
      const displyVisitDateTime = () => {
        if (direction === CarrierVisitDirection.Inbound) {
          if (containerData?.visit?.data.ata)
            return `${t('ata', 'ATA')}: ${formatDateTime(containerData?.visit?.data.ata)}`
          else if (containerData?.visit?.data.eta)
            return `${t('eta', 'ETA')}: ${formatDateTime(containerData?.visit?.data.eta)}`
          else if (containerData?.data.carrierVisitType)
            return t('awaitingDropOff', 'Awaiting drop off')
        } else if (direction === CarrierVisitDirection.Outbound) {
          if (containerData?.visit?.data.atd)
            return `${t('atd', 'ATD')}: ${formatDateTime(containerData?.visit?.data.atd)}`
          else if (containerData?.visit?.data.etd)
            return `${t('etd', 'ETD')}: ${formatDateTime(containerData?.visit?.data.etd)}`
          else if (containerData?.data.carrierVisitType)
            return t('awaitingPickUp', 'Awaiting pick up')
        }

        return ''
      }

      return (
        <Box sx={{ display: 'flex', padding: '1rem' }}>
          {CarrierTypeIcon({ type: containerData?.data.carrierVisitType })}
          <Box sx={{ ml: '.75rem' }}>
            <Typography variant='subtitle1'>
              {/* todo: get vessel name if visit type is vessel */}
              {containerData?.visit?.data.identifier ??
                containerData?.data.carrierVisitType ??
                t('unknown', 'Unknown')}
            </Typography>
            <Typography variant='body2' color={theme.palette.grey[600]}>
              {displyVisitDateTime()}
            </Typography>
          </Box>
        </Box>
      )
    })

    const numberOfActiveHolds =
      (inbound?.data.holds.filter(h => h.status === HoldStatus.Active).length ?? 0) +
      (outbound?.data.holds.filter(h => h.status === HoldStatus.Active).length ?? 0)

    const ContainerInformation = () => (
      <Box sx={{ padding: '1rem', display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: '.25rem' }}>
            {icon}
            <Typography variant='subtitle1' sx={{ ml: '.5rem' }}>
              {order.containerNumber}
            </Typography>
          </Box>

          {renderContainerAttr(order.containerIsoCode || emptyIndicator)}
          {renderContainerAttr(
            `${order.containerLength ? order.containerLength + 'ft' : emptyIndicator}`,
          )}
          {renderContainerAttr(label)}
          {renderContainerAttr(order.containerHeight || emptyIndicator)}
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: theme.customSpacing.m }}>
          <Typography variant='body2'>
            {numberOfActiveHolds} {t('holds', 'Holds')}
          </Typography>
          <ContainerVisitStatusChip label={t(_(getStatus()).lowerFirst())} status={getStatus()} />
          {!orderCreationOnly && onClickEdit && (
            <IconButton variant='contained' onClick={onClickEdit}>
              <EditIcon />
            </IconButton>
          )}

          {!orderCreationOnly &&
            onClickDelete &&
            outbound?.data.status !== OrderStatus.Fulfilled &&
            inbound?.data.status !== OrderStatus.Fulfilled && (
              <IconButton variant='contained' color='error' onClick={onClickDelete}>
                <BinIcon />
              </IconButton>
            )}
        </Box>
      </Box>
    )

    const templateText = () => {
      const { key, defaultText } = template(
        inbound?.data?.carrierVisitType,
        outbound?.data?.carrierVisitType,
      )

      return t(key, defaultText)
    }

    const TransportInformation = () => (
      <Box
        sx={{
          bgcolor: theme.palette.grey[200],
          borderTop: `1px solid ${theme.palette.grey[300]}`,
          display: 'flex',
        }}
      >
        <Box
          sx={{
            borderRight: `2px solid ${theme.palette.grey[300]}`,
            padding: '1rem',
            width: '220px',
          }}
        >
          <Typography variant='subtitle1'>{templateText()}</Typography>
          <Typography variant='body2' color={theme.palette.grey[600]}>
            {order.referenceNumber ? `Ref: ${order.referenceNumber}` : ''}
          </Typography>
          <Typography variant='body2' color={theme.palette.grey[600]}>
            {order.customerName}
          </Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InboundAndOutboundInfo direction='Inbound' containerData={inbound} />
          </Grid>

          <Grid item xs={6}>
            <InboundAndOutboundInfo direction='Outbound' containerData={outbound} />
          </Grid>
        </Grid>
      </Box>
    )

    return (
      <Box
        sx={{
          border: `1px solid ${theme.palette.grey[300]}`,
          borderRadius: theme.customRadius.medium,
          m: '1rem 0',
        }}
      >
        <ContainerInformation />
        <TransportInformation />
      </Box>
    )
  },
)
