import { Typography } from '@mui/material'
import { Stack } from '@mui/system'
import DGStackingRulesTable from '@storage/features/dg-stacking-rules/components/DGStackingRulesTable'
import DGStackingRulesTableDialog from '@storage/features/dg-stacking-rules/components/DGStackingRulesTableDialog'
import { useTranslate } from '@tolgee/react'

const DGStackingRulesPage = () => {
  const { t } = useTranslate()
  return (
    <Stack gap={2}>
      <Typography>
        {t(
          'dgStackingRulesTableDescription',
          'For each IMO class you can define whether any container can be stacked on top of it, and the maximum tier at which a container of that IMO class can be placed',
        )}
      </Typography>

      <DGStackingRulesTable />
      <DGStackingRulesTableDialog />
    </Stack>
  )
}

export default DGStackingRulesPage
