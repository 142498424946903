import { BerthDetails } from '@storage/features/berths/models'
import { Grid } from '@mui/material'
import { BerthsListItem } from './BerthsListItem'

interface Props {
  items: BerthDetails[]
  canWrite: boolean
  onDeleteItem: (id: string) => Promise<void> | void
  onEditItem: (id: string) => Promise<void> | void
}

export const BerthsList = ({ items, canWrite, onDeleteItem, onEditItem }: Props) => {
  return (
    <Grid container spacing={1.5}>
      {items.map(item => (
        <Grid xs={6} item key={item.id}>
          <BerthsListItem
            canWrite={canWrite}
            item={item}
            onDelete={onDeleteItem}
            onEdit={onEditItem}
          />
        </Grid>
      ))}
    </Grid>
  )
}
