import { EquipmentType } from '@operations/app/api'
import { EquipmentRadioOption } from '@operations/app/models/operator-pages'
import { FormControl, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { EquipmentRadio } from './EquipmentRadio'
import { StepperContentWrapper } from './StepperContentWrapper'

interface Props {
  currentEquipmentId?: number
  assignedEquipmentId?: number
  hasError?: boolean
  equipmentOptions: EquipmentRadioOption[]
  canCurrentEquipmentBeAssignedToNextJob: boolean
  stepsTotal: number
  currentStep: number
  handleEquipment: (id?: number) => void
}

export const AssignToEquipment = ({
  currentEquipmentId,
  assignedEquipmentId,
  hasError,
  equipmentOptions,
  canCurrentEquipmentBeAssignedToNextJob,
  stepsTotal,
  currentStep,
  handleEquipment,
}: Props) => {
  const { t } = useTranslate()

  const options = [...equipmentOptions]

  if (canCurrentEquipmentBeAssignedToNextJob) {
    options.push({
      id: currentEquipmentId!,
      type: EquipmentType.Rs,
      title: t('none', 'None'),
      subtitle: t('useMyEquipment', 'Use my equipment'),
    })
  }

  return (
    <StepperContentWrapper
      title={t('assignEquipment', 'Assign equipment')}
      steps={`${currentStep} ${t('of', 'of')} ${stepsTotal}`}
    >
      {options.length > 0 && (
        <FormControl sx={{ width: '100%' }}>
          <EquipmentRadio
            value={assignedEquipmentId}
            equipments={options}
            hasError={hasError}
            onEquipmentChange={(id: number) => handleEquipment(id)}
          />
        </FormControl>
      )}
      {!options.length && !canCurrentEquipmentBeAssignedToNextJob && (
        <Typography fontWeight='bold'>{t('noEquipmentFound', 'No equipment found')}</Typography>
      )}
    </StepperContentWrapper>
  )
}
