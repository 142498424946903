import { Tab, Tabs } from '@mui/material'
import { Box } from '@mui/system'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { useTranslate } from '@tolgee/react'
import { DangerousGoodsDefinitionsPage } from '@tom-ui/planning'
import { DGSegregationRulesPage, DGStackingRulesPage } from '@tom-ui/storage'
import { Header } from '@tom-ui/ui'
import { TabPanel } from 'modules/planning/src/components/TabPanel'
import { useState } from 'react'

const DGSettingsPage = () => {
  const [value, setValue] = useState(0)
  const { t } = useTranslate()

  const handleChange = (_e: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  const isDGStackingRulesFeatureEnabled = useBooleanFlagValue(
    'dangerous-goods-stacking-rules',
    false,
  )

  const isDGSegregationRulesFeatureEnabled = useBooleanFlagValue(
    'dangerous-goods-segregation-rules',
    false,
  )

  return (
    <Box>
      <Header title={t('dgSettings', 'Dangerous Goods Settings')} />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
          <Tab label={t('dgDefinitions', 'Definitions')} sx={{ mx: 1 }} />
          {isDGStackingRulesFeatureEnabled && (
            <Tab label={t('dgStackingRules', 'Stacking Rules')} sx={{ mx: 1 }} />
          )}
          {isDGSegregationRulesFeatureEnabled && (
            <Tab label={t('dgSegregationRules', 'Segregation Rules')} sx={{ mx: 1 }} />
          )}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <DangerousGoodsDefinitionsPage />
      </TabPanel>
      {isDGStackingRulesFeatureEnabled && (
        <TabPanel value={value} index={1}>
          <DGStackingRulesPage />
        </TabPanel>
      )}
      <TabPanel value={value} index={2}>
        {isDGSegregationRulesFeatureEnabled && <DGSegregationRulesPage />}
      </TabPanel>
    </Box>
  )
}

export default DGSettingsPage
