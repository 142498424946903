import { GeneralCargoStockOccupanciesApi } from '../api'
import { createApiClient } from '../http-client'

class GeneralCargoDamageService {
  httpClient = createApiClient(GeneralCargoStockOccupanciesApi)

  async getOrderDamageAmount(orderIds: number[]) {
    const { data } = await this.httpClient.getDamagesByOrderIds({ orderIds })

    return data
  }

  async getOrderDamage(orderId: number) {
    const { data } = await this.httpClient.getDamageInfoByOrderId({ orderId })

    return data
  }
}

export default new GeneralCargoDamageService()
