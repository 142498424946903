import SouthEastIcon from '@mui/icons-material/SouthEast' // todo: use from minimals-theme
import { Box, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import {
  CarrierType,
  OrderResponseDto,
  OrderStatus,
  VesselDto,
  VesselVisitDto,
} from '@planning/app/api'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { DropOffOrderCard } from './DropOffOrderCard'

type Props = {
  dropOffOrder: OrderResponseDto
  theOtherDirectionStatus?: OrderStatus
  theOtherDirectionVisitType?: CarrierType
  theOtherDirectionVisit?: VesselVisitDto
  theOtherDirectionCarriers?: VesselDto[]
  withBorderLineStyle?: boolean
  withBorderAtTheEnd?: boolean
}

export const DropOffOrderStateCard: FC<Props> = observer(
  ({
    dropOffOrder,
    theOtherDirectionStatus,
    theOtherDirectionVisitType,
    theOtherDirectionVisit,
    theOtherDirectionCarriers,
    withBorderLineStyle = true,
    withBorderAtTheEnd = false,
  }) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()

    return (
      <Stack
        direction='row'
        bgcolor={theme.palette.grey[100]}
        sx={{ display: 'flex', height: '100%', minHeight: '0' }}
      >
        <Box
          display='flex'
          alignItems='center'
          sx={{
            borderWidth: withBorderLineStyle ? '1px 0px 1px 1px' : '0px 1px 0px 0px',
            borderStyle: 'solid',
            borderColor: `${theme.palette.grey[300]}`,
            p: '8px 16px 8px 16px',
          }}
        >
          <Stack>
            <Typography
              color={dropOffOrder.status === OrderStatus.Fulfilled ? 'secondary' : ''}
              sx={{ margin: 0, padding: 0, display: 'flex', placeContent: 'center' }}
            >
              <SouthEastIcon />
            </Typography>

            <Typography
              variant='caption'
              fontWeight='bold'
              color={dropOffOrder.status === OrderStatus.Fulfilled ? 'secondary' : ''}
            >
              {t('drop', 'Drop').toUpperCase()}
            </Typography>
          </Stack>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            border: withBorderLineStyle ? `1px` : '0px',
            borderRightWidth: withBorderAtTheEnd ? `1px` : '0px',
            borderStyle: 'solid',
            borderColor: `${theme.palette.grey[300]}`,
            p: '8px 16px 8px 8px',
            width: '100%',
            minHeight: '85px',
          }}
        >
          <DropOffOrderCard
            dropOffOrder={dropOffOrder}
            theOtherDirectionStatus={theOtherDirectionStatus}
            theOtherDirectionVisitType={theOtherDirectionVisitType}
            theOtherDirectionVisit={theOtherDirectionVisit}
            theOtherDirectionCarriers={theOtherDirectionCarriers}
          />
        </Box>
      </Stack>
    )
  },
)
