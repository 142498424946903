import { WeightClassSelectOption } from '@storage/app/models'
import { tolgee } from '@storage/app/translation'
import { AlertStore } from '@storage/stores/alert.store'
import { SnackbarStore } from '@storage/stores/snackbar.store'
import { computed, makeAutoObservable } from 'mobx'
import { mapWeightClassFormProfileToCreateWeightClassDto } from '../forms/weight-classes-form/weight-classes-form.mapper'
import { WeightClassFormProfile } from '../forms/weight-classes-form/weight-classes-form.profile'
import { WeightClassStore } from './weight-class.store'

export class WeightClassContainerUIStore {
  constructor(
    private readonly _weightClassStore: WeightClassStore,
    private readonly _alertStore: AlertStore,
    private readonly _snackbarStore: SnackbarStore,
  ) {
    makeAutoObservable(this, {
      weightClassesOptions: computed,
    })
  }

  loadWeightClasses() {
    this._weightClassStore.loadAll()
  }

  get weightClasses() {
    return this._weightClassStore.entries
  }

  get weightClassesOptions(): WeightClassSelectOption[] {
    return this._weightClassStore.entries.map(c => ({
      label: c.name,
      value: c.name,
      minWeight: c.minWeight,
      maxWeight: c.maxWeight,
    }))
  }

  areFormValuesValid(formValues: WeightClassFormProfile): boolean {
    this.clearAlerts()

    const { weightClasses } = formValues

    // Check if the first weight class starts from 0
    if (weightClasses[0].min !== 0) {
      this._alertStore.showAlert(
        'firstClassMinValue',
        tolgee.t('weightClassesFirstClassMinValue', 'The first class should start from 0'),
      )
      return false
    }

    // Check if the last class max field is marked as infinite
    const lastWeightClass = weightClasses[weightClasses.length - 1]
    if (!lastWeightClass.maxFieldIsInfinite) {
      this._alertStore.showAlert(
        'lastClassMaxValue',
        tolgee.t(
          'weightClassesLastClassMaxValue',
          'The last class "To" field should be marked as infinite',
        ),
      )
      return false
    }

    // Check for gaps or overlaps between weight classes
    for (let i = 0; i < weightClasses.length - 1; i++) {
      const currentClass = weightClasses[i]
      const nextClass = weightClasses[i + 1]

      if (currentClass.max! >= nextClass.min!) {
        this._alertStore.showAlert(
          'noOverlaps',
          tolgee.t(
            'weightClassesNoOverlaps',
            'There should be no overlaps between the weight classes',
          ),
        )
        return false
      }

      if (currentClass.max! + 1 !== nextClass.min!) {
        this._alertStore.showAlert(
          'noGaps',
          tolgee.t('weightClassesNoGaps', 'There should be no gaps between the weight classes'),
        )
        return false
      }
    }

    return true
  }

  async update(formValues: WeightClassFormProfile) {
    await this._weightClassStore
      .add(mapWeightClassFormProfileToCreateWeightClassDto(formValues))
      .then(() =>
        this._snackbarStore.showMessage(
          tolgee.t('weightClassesUpdateSuccess', 'The weight classes are successfully updated'),
          'success',
        ),
      )
      .catch(() =>
        this._snackbarStore.showMessage(
          tolgee.t(
            'weightClassesUpdateFailure',
            'An unexpected error occurred while updating the weight classes',
          ),
          'error',
        ),
      )
  }

  showAlert(key: string, message: string) {
    this._alertStore.showAlert(key, message)
  }

  get validationAlerts() {
    return this._alertStore.getAlerts()
  }

  clearAlerts() {
    this._alertStore.clearAlerts()
  }
}
