import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import {
  LengthMeasurementUnit,
  OrderStatus,
  PackageResponseDto,
  WeightMeasurementUnit,
} from '@planning/app/api'
import { simplifyLengthMeasurementUnit } from '@planning/app/utils'
import { YesNoChipBox } from '@planning/components/dangerous-goods/YesNoChipBox'
import { PackageDto } from '@planning/stores/generalCargo/PackageDto'
import { useTranslate } from '@tolgee/react'
import { FieldBox, useMinimalsTheme } from '@tom-ui/ui'
import _ from 'lodash'
import { matchSorter } from 'match-sorter'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { Controller, FieldError, useFormContext } from 'react-hook-form'
import { ICreateGeneralCargoOrderFormData } from '../CreateGeneralCargoOrderPageForm'

interface IProps {
  isOrderFulfilled?: boolean
  packageOptions: PackageDto[]
  packageId?: number | null
  packageName?: string | null
  canUpdateActualAmountAndStatus?: boolean
  onValidationParamsChanged?: (params: any) => void
}

export const PackageControl: FC<IProps> = observer(
  ({
    isOrderFulfilled,
    packageOptions,
    packageId,
    packageName,
    canUpdateActualAmountAndStatus,
    onValidationParamsChanged,
  }) => {
    const theme = useMinimalsTheme()
    const { t } = useTranslate()

    const [hasPackage, setHasPackage] = useState(false)
    const [selectedPackage, setSelectedPackage] = useState<PackageDto | undefined>()

    const [options, setOptions] = useState<PackageDto[]>([])

    const {
      control,
      register,
      formState: { errors },
      setValue,
      resetField,
    } = useFormContext<ICreateGeneralCargoOrderFormData>()

    useEffect(() => {
      if (packageId) {
        setHasPackage(true)
        setSelectedPackage(
          packageOptions.find(x => x.id === packageId) ??
            ({
              id: packageId,
              name: packageName,
            } as any),
        )
      }
    }, [packageId, packageName])

    useEffect(() => {
      setOptions(packageOptions)
    }, [packageOptions])

    const resetPackage = () => {
      setValue('packageId', undefined)
      setSelectedPackage(undefined)
    }

    const showPackageGroup = () => {
      return (
        _(packageOptions)
          .uniqBy(x => x.assignedToCommodity)
          .value().length > 1
      )
    }

    const isAllowGeneralCargoOrderToChangeStatusFeatureEnabled = useBooleanFlagValue(
      'gc-update-order-status',
      false,
    )

    const helperText = (fieldError: FieldError | undefined) => {
      if (fieldError?.type === 'required') return t('fieldIsRequired', 'Field is required.')

      if (fieldError?.type === 'min') return t('fieldInvalidQuantity', 'Invalid quantity.')

      return ''
    }

    const getPackageField = (
      label: string,
      value?: number | null,
      unit?: LengthMeasurementUnit,
    ) => {
      if (!value) return <></>

      return (
        <Stack flexDirection='row' gap={0.5}>
          <Typography variant='caption' color='secondary'>
            {label}
          </Typography>
          <Typography variant='captionBold' color='secondary'>
            {`${value}${unit ? simplifyLengthMeasurementUnit(unit) : ''}`}
          </Typography>
        </Stack>
      )
    }

    return (
      <FieldBox
        titleColor={isOrderFulfilled ? theme.palette.text.disabled : undefined}
        label={t('packaging', 'Packaging?')}
        required
        rightRender={
          <YesNoChipBox
            disableYes={isOrderFulfilled}
            disableNo={isOrderFulfilled}
            activeOption={hasPackage ? 'Yes' : 'No'}
            onClickYes={() => {
              setHasPackage(true)
            }}
            onClickNo={() => {
              resetPackage()
              setHasPackage(false)
              if (onValidationParamsChanged) {
                onValidationParamsChanged({ packageId: undefined })
              }
            }}
          />
        }
      >
        <Stack gap={2}>
          <Grid container spacing={2}>
            {hasPackage && (
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name='packageId'
                  disabled={isOrderFulfilled}
                  rules={{ required: true }}
                  render={({ field: { onChange } }) => (
                    <Autocomplete
                      getOptionLabel={packaging => packaging.name}
                      options={options}
                      PaperComponent={({ children }) => {
                        return (
                          <Paper>
                            {children}
                            <Button
                              color='primary'
                              fullWidth
                              sx={{ justifyContent: 'flex-start', pl: 3 }}
                              onMouseDown={() => {
                                window.open(
                                  `${new URL('registers/commodities-packaging', window.location.origin)}`,
                                  '_blank',
                                )
                              }}
                            >
                              {t('addNew', 'Add new')}
                            </Button>
                          </Paper>
                        )
                      }}
                      renderOption={(props, packaging) => (
                        <MenuItem {...props} key={packaging.id} value={packaging.id}>
                          <Stack>
                            <Typography variant='subtitle1'>{packaging.name}</Typography>
                            <Stack flexDirection='row' gap={1}>
                              {getPackageField(
                                t('height', 'Height'),
                                packaging.height,
                                packaging.lengthUnit,
                              )}
                              {getPackageField(
                                t('width', 'Width'),
                                packaging.width,
                                packaging.lengthUnit,
                              )}
                              {getPackageField(
                                t('length', 'Length'),
                                packaging.length,
                                packaging.lengthUnit,
                              )}
                            </Stack>
                          </Stack>
                        </MenuItem>
                      )}
                      isOptionEqualToValue={(
                        option: PackageResponseDto,
                        value: PackageResponseDto,
                      ) => option.id === value.id}
                      value={selectedPackage ?? null}
                      onChange={(_, packaging) => {
                        onChange(packaging?.id ?? undefined)
                        setSelectedPackage(packaging ?? undefined)
                        if (onValidationParamsChanged) {
                          onValidationParamsChanged({ packageId: packaging?.id })
                        }

                        if (packaging) {
                          resetField('weightUnit')
                          setValue('weightUnit', packaging.weightUnit)
                        }
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label={t('package', 'Package')}
                          error={!!errors.packageId}
                          helperText={
                            errors.packageId ? t('fieldIsRequired', 'Field is required.') : ''
                          }
                        />
                      )}
                      groupBy={option =>
                        showPackageGroup() && option.assignedToCommodity
                          ? option.assignedToCommodity
                          : ''
                      }
                      renderGroup={params => (
                        <Stack key={params.key}>
                          <Typography variant='subtitle2' color='secondary' padding={1}>
                            {t(params.group)}
                          </Typography>
                          {params.children}
                        </Stack>
                      )}
                      filterOptions={(options, { inputValue }) => {
                        return matchSorter(options, inputValue.replace(/\s/g, ''), {
                          sorter: rankedItems => rankedItems,
                          keys: [item => item.name.replace(/\./g, '').replace(/\s/g, '')],
                          threshold: matchSorter.rankings.CONTAINS,
                        })
                      }}
                    />
                  )}
                />
              </Grid>
            )}

            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                disabled={isOrderFulfilled}
                label={`${t('plannedQuantity', 'Planned Quantity')}`}
                {...register('plannedCargoAmount', { required: true, min: 1 })}
                error={!!errors.plannedCargoAmount}
                helperText={helperText(errors.plannedCargoAmount)}
                variant='outlined'
                type='number'
                name='plannedCargoAmount'
                onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
                onBlur={e =>
                  !!onValidationParamsChanged &&
                  onValidationParamsChanged({ quantity: parseInt(e.currentTarget.value) ?? 0 })
                }
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                disabled={isOrderFulfilled}
                label={t('totalGrossWeight', 'Total Gross Weight')}
                variant='outlined'
                {...register('grossWeight', { required: true, valueAsNumber: true, min: 0 })}
                name='grossWeight'
                type='number'
                error={!!errors.grossWeight}
                helperText={helperText(errors.grossWeight)}
                onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Controller
                control={control}
                name='weightUnit'
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <FormControl fullWidth error={!!errors.weightUnit}>
                    <InputLabel>{t('unit', 'Unit')}</InputLabel>
                    <Select
                      defaultValue=''
                      value={value ?? ''}
                      label={t('unit', 'Unit')}
                      name='weightUnit'
                      onChange={(e: any) => {
                        onChange(e.target.value)
                      }}
                      disabled={isOrderFulfilled}
                    >
                      <MenuItem value={WeightMeasurementUnit.Kilogram}>
                        {t('kilogram', 'Kilogram (kg)')}
                      </MenuItem>
                      <MenuItem value={WeightMeasurementUnit.Ton}>{t('ton', 'Ton (t)')}</MenuItem>
                    </Select>
                    {errors.weightUnit && (
                      <FormHelperText>{t('fieldIsRequired', 'Field is required.')}</FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
          {canUpdateActualAmountAndStatus && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  disabled={isOrderFulfilled}
                  label={`${t('actualQuantity', 'Actual Quantity')}`}
                  {...register('actualCargoAmount', { valueAsNumber: true, min: 0 })}
                  variant='outlined'
                  type='number'
                  error={!!errors.actualCargoAmount}
                  helperText={
                    errors.actualCargoAmount?.type === 'min'
                      ? t('fieldInvalidQuantity', 'Invalid quantity.')
                      : ''
                  }
                  name='actualCargoAmount'
                  onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <Controller
                  control={control}
                  name={`status`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl fullWidth>
                      <InputLabel>{t('status', 'Status')}</InputLabel>
                      <Select
                        defaultValue=''
                        value={value ?? ''}
                        label={t('status', 'Status')}
                        name='status'
                        onChange={(e: any) => {
                          onChange(e.target.value)
                        }}
                        disabled={
                          isOrderFulfilled && !isAllowGeneralCargoOrderToChangeStatusFeatureEnabled
                        }
                      >
                        <MenuItem value={OrderStatus.Open}>{t('open', 'Open')}</MenuItem>
                        <MenuItem value={OrderStatus.Fulfilled}>
                          {t('fulfilled', 'Fulfilled')}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>
          )}
        </Stack>
      </FieldBox>
    )
  },
)
