import { tolgee } from '@storage/app/translation'
import { Locale, formatDistanceToNow } from 'date-fns'
import { de, enUS } from 'date-fns/locale'

/**
 * Return human readable date (e.g. in 19 days, in 1 month)
 * @param date string
 */
export const getHumanReadableDate = (date: string | Date) => {
  const lang = tolgee.getLanguage()

  const locales: Record<string, Locale> = {
    en: enUS,
    de: de,
  }

  return lang
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
        locale: locales[lang],
      })
    : formatDistanceToNow(new Date(date), {
        addSuffix: true,
        locale: locales.en,
      })
}
