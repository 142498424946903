import { createSvgIcon } from '@mui/material'

export const StuffingIcon = createSvgIcon(
  <svg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <mask id='path-1-inside-1_2462_1210' fill='white'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3 1C1.89543 1 1 1.89543 1 3V16C1 17.1046 1.89543 18 3 18H13C14.1046 18 15 17.1046 15 16V3C15 1.89543 14.1046 1 13 1H3ZM3.7998 3C3.24752 3 2.7998 3.44772 2.7998 4V15C2.7998 15.5523 3.24752 16 3.7998 16H12.2998C12.8521 16 13.2998 15.5523 13.2998 15V4C13.2998 3.44772 12.8521 3 12.2998 3H3.7998Z'
      />
    </mask>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3 1C1.89543 1 1 1.89543 1 3V16C1 17.1046 1.89543 18 3 18H13C14.1046 18 15 17.1046 15 16V3C15 1.89543 14.1046 1 13 1H3ZM3.7998 3C3.24752 3 2.7998 3.44772 2.7998 4V15C2.7998 15.5523 3.24752 16 3.7998 16H12.2998C12.8521 16 13.2998 15.5523 13.2998 15V4C13.2998 3.44772 12.8521 3 12.2998 3H3.7998Z'
      fill='currentColor'
    />
    <path
      d='M2 3C2 2.44772 2.44772 2 3 2V0C1.34315 0 0 1.34315 0 3H2ZM2 16V3H0V16H2ZM3 17C2.44771 17 2 16.5523 2 16H0C0 17.6569 1.34315 19 3 19V17ZM13 17H3V19H13V17ZM14 16C14 16.5523 13.5523 17 13 17V19C14.6569 19 16 17.6569 16 16H14ZM14 3V16H16V3H14ZM13 2C13.5523 2 14 2.44772 14 3H16C16 1.34315 14.6569 0 13 0V2ZM3 2H13V0H3V2ZM3.7998 4V2C2.69524 2 1.7998 2.89543 1.7998 4H3.7998ZM3.7998 15V4H1.7998V15H3.7998ZM3.7998 15H1.7998C1.7998 16.1046 2.69523 17 3.7998 17V15ZM12.2998 15H3.7998V17H12.2998V15ZM12.2998 15V17C13.4044 17 14.2998 16.1046 14.2998 15H12.2998ZM12.2998 4V15H14.2998V4H12.2998ZM12.2998 4H14.2998C14.2998 2.89543 13.4044 2 12.2998 2V4ZM3.7998 4H12.2998V2H3.7998V4Z'
      fill='currentColor'
      mask='url(#path-1-inside-1_2462_1210)'
    />
    <rect x='19' y='18' width='4' height='4' rx='1' fill='currentColor' />
    <path
      d='M21 18C21 18 21 19.5 21 15.5C21 11.5 16.5 12.5 16.5 12.5'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
    <rect x='3.5' y='11' width='4' height='4' rx='1' fill='currentColor' />
    <path
      d='M18.5358 10L16.0002 12.5355L18.5358 15.0711'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
  </svg>,
  'StuffingIcon',
)
