import { VesselVisitDto, VesselVisitsApi } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'
import dayjs from 'dayjs'
import { Event, IEvent, IQueryHandler, IQueryMessage } from '../messageBus'

export class GetVesselVisitsQuery implements IQueryMessage {
  static readonly type = 'GetVesselVisitsQuery'
  type = GetVesselVisitsQuery.type

  constructor(
    public from: Date,
    public to: Date,
    public includeCompleted = true,
    public orderBy = 'eta',
    public isDescending = false,
    public filter?: string,
  ) {}

  getKey = () => {
    return JSON.stringify(this)
  }
}

const httpClient = createApiClient(VesselVisitsApi)

export const getVesselVisitsQueryHandler: IQueryHandler<
  GetVesselVisitsQuery,
  IEvent<VesselVisitDto[]>
> = async query => {
  // TODO: remove page and pageSize
  const { data } = await httpClient.get(
    1,
    1000,
    undefined,
    undefined,
    query.filter,
    query.includeCompleted,
    query.orderBy,
    query.isDescending,
    dayjs(query.from).toISOString(),
    dayjs(query.to).toISOString(),
  )

  return new Event(GetVesselVisitsQuery.type, data.payload)
}
