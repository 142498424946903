import { Button, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { WorkInstructionJobDto } from '@operations/app/api'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { EquipmentOperatorWorkInstructionStepperUIStore } from '../../stores/equipment-operator-work-instruction-stepper.ui-store'
import { WorkInstructionJobInformationWrapper } from '../JobInformation/WorkInstructionJobInformationWrapper'
import { SignPad } from '../Stepper/SignPad'

interface Props {
  job: WorkInstructionJobDto
  stepperUiStore: EquipmentOperatorWorkInstructionStepperUIStore
}

export const ConfirmSignature = observer(({ job, stepperUiStore: uiStore }: Props) => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()

  const handleOperatorSignChange = (png?: string) => {
    uiStore.setOperatorSign(png)
  }

  const handleDriverSignChange = (png?: string) => {
    uiStore.setDriverSign(png)
  }

  return (
    <WorkInstructionJobInformationWrapper
      job={job}
      currentStep={uiStore.currentStepNumber}
      stepsTotal={uiStore.stepsTotal}
    >
      <Stack gap={theme.customSpacing.m} marginY={theme.customSpacing.m} alignItems={'flex-start'}>
        <Typography variant='subtitle1'>
          {t('forkliftDriveOrSame', 'Forklift driver or representative signature')}
        </Typography>
        <SignPad handleChange={handleOperatorSignChange} />

        {uiStore.driverSignPadOpen && (
          <>
            <Typography variant='subtitle1'>{t('truckDriver', 'Driver signature')}</Typography>
            <SignPad handleChange={handleDriverSignChange} />
            <Button onClick={() => uiStore.closeDriverSign()}>
              {t('removeDriverSign', 'Remove driver signature')}
            </Button>
          </>
        )}
        {!uiStore.driverSignPadOpen && (
          <Button onClick={() => uiStore.openDriverSign()}>
            {t('addDriverSign', 'Add driver signature')}
          </Button>
        )}
      </Stack>
    </WorkInstructionJobInformationWrapper>
  )
})
