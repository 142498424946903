import {
  CarrierVisitDirection,
  NonNumericOrderDto,
  OrderResponseDto,
  OrderStatus,
} from '@planning/app/api'
import { SimpleListStore } from '@planning/components/list/SimpleListStore'
import { TenantStore } from '@planning/rt-stores/tenant/TenantStore'
import { nnrOrderService, orderService } from '@planning/services'
import generalCargoService from '@planning/services/generalCargoService'
import { computed, makeObservable, observable } from 'mobx'

export class PickUpOrderSearchStore extends SimpleListStore<OrderResponseDto | NonNumericOrderDto> {
  ordersByContainerNumber: OrderResponseDto[] = []

  constructor(private tenantStore: TenantStore) {
    super(async (filter: string) => {
      const orders = await orderService.pickUpOrders(filter)
      this.ordersByContainerNumber = await orderService.getByContainerNumber(
        filter,
        OrderStatus.Open,
      )
      const nnrOrders = await nnrOrderService.getByReferenceNumber(filter)

      let generalCargoOrders: OrderResponseDto[] = []
      if (this.tenantStore.hasGeneralCargo) {
        generalCargoOrders = await generalCargoService.getByCustomerOrReferenceNumber(
          filter,
          CarrierVisitDirection.Outbound,
        )
      }

      return [...orders, ...nnrOrders, ...generalCargoOrders]
    })
    makeObservable(this, {
      orders: computed,
      nnrOrders: computed,
      ordersByContainerNumber: observable,
    })
  }

  private isNonNumericOrder = (item: OrderResponseDto | NonNumericOrderDto) => 'amount' in item

  get orders() {
    return this.items.filter(o => !this.isNonNumericOrder(o)).map(o => o as OrderResponseDto)
  }

  get nnrOrders() {
    return this.items.filter(this.isNonNumericOrder).map(o => o as NonNumericOrderDto)
  }
}
