import { StackDto } from '@storage/app/api'
import { useMinimalsTheme } from '@tom-ui/ui'
import {
  colorEmptyOccupancy,
  colorFullOccupancy,
  stackHeightInPixels,
  stackWidthInPixels,
} from '@storage/pages/yard-management/constants'
import { pickGradient } from '@storage/utils/colors'
import { Vector2d } from 'konva/lib/types'
import { Group, Line, Rect } from 'react-konva'

export enum StackSizeDigit {
  Single = 1,
  Double = 2,
  Triple = 3,
}

interface Props {
  isAllocated?: boolean
  isMatchingFilter?: boolean
  stack: StackDto
  maxTier: number
  allocationSize?: StackSizeDigit
  onClick?: (id: string, bayId: string) => void
  onDblClick?: (bayId: string, rowId: string) => void
}

export const Stack = ({
  stack,
  maxTier,
  isAllocated,
  isMatchingFilter,
  allocationSize,
  onClick,
  onDblClick,
}: Props) => {
  const { palette } = useMinimalsTheme()

  const fillColor = pickGradient(
    colorEmptyOccupancy,
    colorFullOccupancy,
    stack.numberOfOccupiedSlots * (1 / maxTier),
  )

  const lockColor = pickGradient(
    palette.grey[600],
    palette.grey[200],
    stack.numberOfOccupiedSlots * (1 / maxTier),
  )

  const upperLeft: Vector2d = {
    x: (stack.baySequenceNumber - 1) * stackWidthInPixels + 2,
    y: (stack.rowSequenceNumber - 1) * stackHeightInPixels + 2,
  }

  const lockedStack = (
    <>
      <Line
        points={[
          upperLeft.x + 2,
          upperLeft.y + 2,
          upperLeft.x + stackWidthInPixels - 6,
          upperLeft.y + stackHeightInPixels - 6,
        ]}
        stroke={lockColor}
        strokeWidth={1}
        onDblClick={() => onDblClick?.(stack.bayId, stack.rowId)}
      />
      <Line
        points={[
          upperLeft.x + stackWidthInPixels - 6,
          upperLeft.y + 2,
          upperLeft.x + 2,
          upperLeft.y + stackHeightInPixels - 6,
        ]}
        stroke={lockColor}
        strokeWidth={1}
        onDblClick={() => onDblClick?.(stack.bayId, stack.rowId)}
      />
    </>
  )

  return (
    <Group>
      <Rect
        x={upperLeft.x}
        y={upperLeft.y}
        width={stackWidthInPixels * stack.stackSizeDigit - 4}
        stroke={isMatchingFilter ? 'red' : palette.grey[400]}
        hitStrokeWidth={0}
        height={stackHeightInPixels - 4}
        fill={fillColor}
        opacity={1}
        cornerRadius={2}
        onClick={() => onClick?.(stack.id, stack.bayId)}
        onDblClick={() => onDblClick?.(stack.bayId, stack.rowId)}
      />
      {stack.isLocked && lockedStack}
      {stack.stackSizeDigit > 1 && (
        <Rect
          x={upperLeft.x + stackWidthInPixels}
          y={upperLeft.y}
          width={stackWidthInPixels - 4}
          height={stackHeightInPixels - 4}
          onClick={() => {
            if (!stack.nextBayId) return

            onClick?.(stack.id, stack.nextBayId)
          }}
        />
      )}

      {isAllocated && allocationSize && (
        <Rect
          x={upperLeft.x}
          y={upperLeft.y}
          strokeWidth={2}
          width={stackWidthInPixels * allocationSize - 4}
          stroke={'teal'}
          opacity={1}
          cornerRadius={2}
          height={stackHeightInPixels - 4}
          onClick={() => onClick?.(stack.id, stack.bayId)}
        />
      )}
    </Group>
  )
}
