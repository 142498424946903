import { ReactNode } from 'react'
import { ColorSchema } from '../../palette'
import { Header } from '../header/Header'
import { DrawerContainer, DrawerProps } from './Drawer'
import { DrawerActions } from './DrawerActions'
import { DrawerContent } from './DrawerContent'

interface Props extends DrawerProps {
  title: string
  subTitle?: string
  formId?: string

  primaryActionLabel?: string
  disablePrimaryAction?: boolean

  secondaryActionLabel?: string
  secondaryActionColor?: ColorSchema
  disableSecondaryAction?: boolean

  closeActionLabel?: string

  onPrimaryAction?: () => void
  onSecondaryAction?: () => void

  leftRenderOption?: ReactNode
  rightRenderOption?: ReactNode
}

export const DrawerFormContainer = ({
  children,
  anchor,
  open,
  title,
  subTitle,
  formId,
  primaryActionLabel,
  disablePrimaryAction,
  secondaryActionLabel,
  secondaryActionColor,
  disableSecondaryAction,
  closeActionLabel,
  onClose,
  onPrimaryAction,
  onSecondaryAction,
  leftRenderOption,
  rightRenderOption,
}: Props) => {
  return (
    <DrawerContainer anchor={anchor} onClose={onClose} open={open}>
      <Header
        title={title}
        subTitle={subTitle}
        rightRenderOption={rightRenderOption}
        leftRenderOption={leftRenderOption}
      />
      <DrawerContent>{children}</DrawerContent>

      <DrawerActions
        formId={formId}
        primaryActionLabel={primaryActionLabel}
        onPrimaryAction={onPrimaryAction}
        disablePrimaryAction={disablePrimaryAction}
        secondaryActionLabel={secondaryActionLabel}
        secondaryActionColor={secondaryActionColor}
        onSecondaryAction={onSecondaryAction}
        disableSecondaryAction={disableSecondaryAction}
        closeActionLabel={closeActionLabel}
        onClose={onClose}
      />
    </DrawerContainer>
  )
}
