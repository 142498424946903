import { TenantDto } from '@admin/app/api'
import { ControlledTextField } from '@admin/components/form-controls/ControlledTextField'
import { Box, Button, IconButton, Stack, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { BinIcon } from 'modules/ui/src'
import { useState } from 'react'
import { Control, FormState, UseFormGetValues, UseFormSetValue } from 'react-hook-form'
import { TenantConfigFormProfile } from './tenant-config-form.profile'

interface Props {
  tenant?: TenantDto
  formState: FormState<TenantConfigFormProfile>
  control: Control<TenantConfigFormProfile>
  setValue: UseFormSetValue<TenantConfigFormProfile>
  getValues: UseFormGetValues<TenantConfigFormProfile>
  formErrors: any
}

export const TenantLocationCodeField = observer(
  ({ tenant, formState, control, setValue, getValues, formErrors }: Props) => {
    const { t } = useTranslate()

    const unloCodesInitialState =
      tenant?.unloCodes && tenant?.unloCodes.length > 0
        ? tenant?.unloCodes.map((_, index) => ({
            id: index,
          }))
        : [{ id: 0 }]

    const [locationCodeFields, setLocationCodeFields] = useState(unloCodesInitialState)

    const addField = () => {
      setLocationCodeFields(prevFields => [...prevFields, { id: prevFields.length }])
    }

    const removeField = (id: number) => {
      if (locationCodeFields.length <= 1) {
        return
      }

      setLocationCodeFields(prevFields => prevFields.filter((_, index) => index !== id))

      const currentLocationCodes = getValues('unloCodes')
      const updatedLocationCodes = currentLocationCodes?.filter(
        (_: any, index: number) => index !== id,
      )
      setValue('unloCodes', updatedLocationCodes)
    }

    return (
      <Box>
        <Typography variant='subtitle2'>
          {t('terminalLocationCodes', 'Terminal location codes')}
        </Typography>

        {locationCodeFields.map((field, index) => (
          <Stack key={index} direction='row' spacing={'1rem'} margin={'1rem 0'}>
            <ControlledTextField<TenantConfigFormProfile>
              name={`unloCodes.${index}`}
              formState={formState}
              control={control}
              label={t('locationCode', 'Location Code')}
              fullWidth
              uppercase
              maxLength={5}
              endAdornment={
                <IconButton color='error' onClick={() => removeField(index)}>
                  <BinIcon />
                </IconButton>
              }
            />
          </Stack>
        ))}

        {formErrors.hasDuplicateLocationCodes && (
          <Typography variant='body2' color='error'>
            {t('duplicateLocationCodesAreNotAllowed', 'Duplicate location codes are not allowed')}
          </Typography>
        )}

        {formErrors.isRequiredLocationCode && (
          <Typography variant='body2' color='error'>
            {t('atLeastOneLocationCodeIsRequired', 'At least one location code is required')}
          </Typography>
        )}

        <Button onClick={addField}>+ {t('add', 'Add')}</Button>
      </Box>
    )
  },
)
