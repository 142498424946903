import { action, makeObservable, observable } from 'mobx'
import { ReactNode } from 'react'

type AnchorString = 'right' | 'left'
interface DrawerOptions {
  title?: string
  subTitle?: string
  formId?: string
  primaryActionLabel?: string
  secondaryActionLabel?: string
  closeActionLabel?: string
}
export class DrawerStore {
  view: ReactNode | undefined = undefined
  open = false
  anchor: AnchorString = 'right'
  fullscreen = false
  drawerOptions: DrawerOptions = {}

  constructor() {
    makeObservable(this, {
      view: observable,
      open: observable,
      anchor: observable,
      drawerOptions: observable,
      showView: action,
      close: action,
    })
  }

  showView(
    drawer: ReactNode,
    drawerOptions?: DrawerOptions,
    anchor?: AnchorString,
    fullscreen?: boolean,
  ) {
    this.view = drawer
    this.anchor = anchor ?? 'right'
    this.fullscreen = fullscreen ?? false
    this.open = true
    this.drawerOptions = drawerOptions ?? {}
  }

  close = () => {
    this.open = false
  }
}
