import {
  CargoType,
  CarrierVisitDirection,
  CarrierVisitStatus,
  RailTrackResponseDto,
  RailVisitResponseDto,
} from '@planning/app/api'
import _ from 'lodash'
import { computed, makeObservable } from 'mobx'
import { EntityItem } from '../base/EntityItem'
import { IOrderItem } from '../order/OrderItem'
import { IRailcarTrackPositionItem } from '../railTrack/RailcarTrackPositionItem'
import { RailcarTrackPositionItemStore } from '../railTrack/RailcarTrackPositionItemStore'
import { RailTrackItemStore } from '../railTrack/RailTrackItemStore'
import { IEntity } from '../types'
import { DirectionData, IDirectionData, IOrderCounts } from '../vesselVisit/VesselVisitItem'
import { RailVisitItemStore } from './RailVisitItemStore'

export interface IRailVisitItem extends IEntity<RailVisitResponseDto> {
  orders: IOrderItem[]
  arrival: string | null | undefined
  departure: string | null | undefined
  load: IDirectionData
  discharge: IDirectionData
  getCount: (
    countType: keyof IOrderCounts,
    direction: CarrierVisitDirection,
    cargoType: CargoType,
  ) => number
  railTracks: RailTrackResponseDto[]
  railcarTrackPositions: IRailcarTrackPositionItem[]
  hasOperationStarted: boolean
}

export class RailVisitItem extends EntityItem<RailVisitResponseDto> implements IRailVisitItem {
  public readonly load: DirectionData
  public readonly discharge: DirectionData
  constructor(
    id: number,
    data: RailVisitResponseDto,
    private itemStore: RailVisitItemStore,
    private railTrackStore: RailTrackItemStore,
    private railcarTrackPositionItemStore: RailcarTrackPositionItemStore,
  ) {
    super(id, data)
    makeObservable(this, {
      orders: computed,
      arrival: computed,
      departure: computed,
      railTracks: computed,
      railcarTrackPositions: computed,
      hasOperationStarted: computed,
    })

    this.load = new DirectionData(CarrierVisitDirection.Outbound, this, this.itemStore)
    this.discharge = new DirectionData(CarrierVisitDirection.Inbound, this, this.itemStore)
  }

  get orders() {
    return _.get(this.itemStore.ordersByRailVisitId, this.id) ?? []
  }

  get arrival() {
    return this.data.ata ?? this.data.eta
  }

  get departure() {
    return this.data.atd ?? this.data.etd
  }

  get railTracks() {
    // TODO: [JH] remove railTrackIds once we migrate an existing data from railcar_track_position to rail_track_rail_visit
    const railTrackIds = _([...this.data.railTrackIds, ...this.data.assignedRailTrackIds])
      .uniq()
      .value()
    if (railTrackIds.length) {
      return _(this.railTrackStore.elements)
        .filter(e => railTrackIds.includes(e.id))
        .map(e => e.data)
        .value()
    }

    return []
  }

  get railcarTrackPositions() {
    return _(this.railcarTrackPositionItemStore.elements)
      .filter(e => e.data.railVisitId === this.data.id)
      .value()
  }

  get hasOperationStarted() {
    return (
      this.data.status === CarrierVisitStatus.InOperation &&
      this.railcarTrackPositions.some(
        rtp => rtp.data.checkinDate || rtp.data.loadPreparationCheckedDate || rtp.data.checkoutDate,
      )
    )
  }

  getCount = (
    countType: keyof IOrderCounts,
    direction: CarrierVisitDirection,
    cargoType: CargoType,
  ) => {
    return direction === CarrierVisitDirection.Inbound
      ? this.discharge.getCount(countType, cargoType)
      : this.load.getCount(countType, cargoType)
  }
}
