import { Box, Typography } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { AlwaysHoveredOutlinedButton } from './AlwaysHoveredOutlinedButton'

interface Props {
  title: string
  subtitle: string
  onClick: () => void
  isSelected: boolean
  hasError?: boolean
}

export const ModeButton = ({ title, subtitle, hasError, isSelected, onClick }: Props) => {
  const theme = useMinimalsTheme()

  return (
    <AlwaysHoveredOutlinedButton
      color='primary'
      variant='outlined'
      theme={theme}
      selected={isSelected}
      onClick={onClick}
      error={hasError ? 'true' : 'false'}
      sx={{
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          width: '268px',
        },
        height: theme.spacing(10),
      }}
    >
      <Box
        sx={{
          padding: 3,
          width: '100%',
          height: 12,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant='subtitle2' color='black'>
          {title}
        </Typography>
        <Typography variant='body2' color='gray'>
          {subtitle}
        </Typography>
      </Box>
    </AlwaysHoveredOutlinedButton>
  )
}
