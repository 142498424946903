import {
  ContainerTurnoverDto,
  YardBlockSlotOccupanciesApi,
  YardPositionDto,
} from '@storage/app/api'
import { createApiClient } from '@storage/app/http-client'
import { containerShiftingsService } from '@storage/services'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { AlertStore } from './alert.store'

export class ContainerShiftingsStore {
  containersToBeShifted: ContainerTurnoverDto[] = []
  turnover?: ContainerTurnoverDto
  numberOfShiftingsDone = 0

  constructor(private readonly _alertStore: AlertStore) {
    makeObservable(this, {
      containersToBeShifted: observable,
      numberOfShiftingsDone: observable,
      turnover: observable,
      resetNumberOfShiftings: action,
      incrementShiftings: action,
      numberOfContainersToBeShifted: computed,
    })
  }

  async fetchContainersToBeShifted(containerNumber: string) {
    const response = await containerShiftingsService.fetchShiftedContainers(containerNumber)
    runInAction(() => {
      this.containersToBeShifted = response
    })
  }

  async fetchTurnover(containerNumber: string) {
    try {
      const response = await containerShiftingsService.fetchTurnover(containerNumber)
      runInAction(() => {
        this.turnover = response
      })
    } catch (e) {
      this._alertStore.showAlert('fetchTurnover', 'Turnover not found', 'error')
    }
  }

  get numberOfContainersToBeShifted() {
    return this.containersToBeShifted.length
  }

  resetNumberOfShiftings() {
    this.numberOfShiftingsDone = 0
  }

  incrementShiftings() {
    this.numberOfShiftingsDone++
  }

  async updateContainerPosition(containerTurnoverId: string, position: YardPositionDto) {
    await createApiClient(YardBlockSlotOccupanciesApi).updateContainerPosition({
      yardBlockSlotOccupanciesUpdateContainerPositionRequest: {
        containerTurnoverId: containerTurnoverId,
        location: { ...position },
      },
    })
    this.incrementShiftings()
  }
}
