import {
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
} from '@mui/material'
import { BinIcon, FileIcon, IconButton } from '@tom-ui/ui'
import { FC } from 'react'
import { DropzoneRootProps } from 'react-dropzone'

export const DropzoneFilePreview: FC<{
  file: { name: string; size: number }
  rootProps: DropzoneRootProps

  setFile: (file: File | undefined) => void
}> = ({ file, rootProps, setFile }) => {
  return (
    <Paper variant='outlined' {...rootProps} sx={{ height: '100%' }}>
      <List>
        <ListItem key={file.name}>
          <ListItemIcon>
            <FileIcon />
          </ListItemIcon>
          <ListItemText primary={file.name} secondary={`${(file.size / 1024).toFixed(2)} kB`} />
          <ListItemSecondaryAction>
            <IconButton
              edge='end'
              onClick={(e: { stopPropagation: () => void }) => {
                e.stopPropagation()
                setFile(undefined)
              }}
            >
              <BinIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </Paper>
  )
}
