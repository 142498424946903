import { ContainerBlockInfoDto, YardBlocksApi } from '@storage/app/api'

export class ContainerRoutingInfoService {
  constructor(private api: YardBlocksApi) {}

  async fetchContainerInfo(containerNumbers: string[]): Promise<ContainerBlockInfoDto[]> {
    const { data: info } = await this.api.getContainersBlockNames({ containerNumbers })

    return info
  }
}
