import { Box, FormGroup, TextField } from '@mui/material'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'

export interface GroupFormProfile {
  name: string
  note: string
}

const defaultValues: GroupFormProfile = {
  name: '',
  note: '',
}

interface Props {
  id: string
  groupName: string
  groupNote: string
  allowGroupNotes?: boolean
  areaLabel: string
  onSubmit: (formValues: GroupFormProfile) => Promise<void> | void
}

const GroupingForm = ({
  id,
  groupName,
  groupNote,
  allowGroupNotes,
  areaLabel,
  onSubmit,
}: Props) => {
  const { control, handleSubmit, reset } = useForm<GroupFormProfile>({ defaultValues })

  useEffect(() => {
    if (groupName) reset({ name: groupName, note: groupNote })
  }, [groupName, groupNote, reset])

  return (
    <Box
      id={id}
      component='form'
      noValidate
      autoComplete='off'
      my={1}
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormGroup sx={{ gap: '1rem' }}>
        <Controller
          render={({ field }) => (
            <TextField
              label={`${areaLabel} group name`}
              required
              {...field}
              value={field.value ?? ''}
              variant='outlined'
            />
          )}
          name='name'
          control={control}
        />
        {allowGroupNotes && (
          <Controller
            render={({ field }) => (
              <TextField
                label={'Note'}
                {...field}
                value={field.value ?? ''}
                variant='outlined'
                multiline
                minRows={3}
              />
            )}
            name='note'
            control={control}
          />
        )}
      </FormGroup>
    </Box>
  )
}

export default GroupingForm
