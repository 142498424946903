import { Box, Button } from '@mui/material'
import { CarrierVisitDirection } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { NavigationStackBackButton } from '@planning/pages/TallymanV2/Components'
import { useTranslate } from '@tolgee/react'
import { ContainerMobile, Header, StepperInfo, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
  IContainerDamage,
  IContainerDamageFormData,
  getDefaultContainerDamage,
} from './ContainerDamage.model'
import { ContainerDamageReport } from './ContainerDamageReport'
import { ContainerDamageReportForm } from './ContainerDamageReportForm'

interface IProps {
  orderData: any
  containerNumber?: string | null
  hasNextStep?: boolean
  direction?: CarrierVisitDirection
  onSubmit: (orderData: any, damages?: IContainerDamage[]) => void
}
export const ContainerDamageRecordStep = observer(
  ({ orderData, containerNumber, hasNextStep, direction, onSubmit }: IProps) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()
    const { tenantStore, containerDamageReportViewStore, containerItemStore } = usePlanningStore()

    const { control, formState, handleSubmit } = useForm<IContainerDamageFormData>({
      defaultValues: {
        damagesReported: orderData.damagesReported?.length
          ? orderData.damagesReported
          : [getDefaultContainerDamage()],
      },
    })

    const getButtonText = () => {
      if (hasNextStep) return t('next', 'Next')
      else if (!direction) return t('done', 'Done')
      else
        return `${t('check', 'Check')} ${direction === CarrierVisitDirection.Inbound ? t('in', 'in') : t('out', 'out')}`
    }

    useEffect(() => {
      if (orderData.containerId) containerItemStore.fetchById(orderData.containerId)
    }, [containerItemStore, orderData.containerId])

    const containerItem = containerItemStore.elements[orderData.containerId ?? 0]

    return (
      <>
        <Header
          leftRenderOption={<NavigationStackBackButton />}
          title={containerNumber ?? ''}
          rightRenderOption={
            <Button
              data-cy='check-container-btn'
              variant='contained'
              onClick={handleSubmit(damageFormData => {
                onSubmit(orderData, damageFormData.damagesReported)
              })}
              sx={{ flex: 'none' }}
            >
              {getButtonText()}
            </Button>
          }
        />
        <StepperInfo
          title={t('damageReport', 'Damage report')}
          steps={`2 ${t('of', 'of')} ${hasNextStep ? 3 : 2}`}
        ></StepperInfo>

        <ContainerMobile>
          {containerItem?.activeDamages.length > 0 && (
            <Box paddingTop={theme.customSpacing.m}>
              <ContainerDamageReport
                containerId={orderData.containerId ?? 0}
                damages={containerItem.activeDamages}
                isMobile
              />
            </Box>
          )}

          <Box component='form' noValidate autoComplete='off' paddingY={theme.customSpacing.l}>
            <ContainerDamageReportForm
              control={control}
              formState={formState}
              containerDamageLocations={containerDamageReportViewStore.locationOptions}
              containerDamageTypes={containerDamageReportViewStore.typeOptions}
              hasDamageCatalogue={tenantStore.hasDamageCatalogue}
            />
          </Box>
        </ContainerMobile>
      </>
    )
  },
)
