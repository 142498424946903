import { MoreVert } from '@mui/icons-material'
import { Box, Menu, MenuItem } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { CarrierType, ContainerJourneyDto, LinkedOrderResponseDto } from '@planning/app/api'
import { IInspectContainerFormData } from '@planning/pages/GateClerk/Components/InspectContainer'
import { useTranslate } from '@tolgee/react'
import { IconButton, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { DropOffAutoCompleteRenderOption } from './DropOffAutoCompleteRenderOption'

interface IProps {
  order: IInspectContainerFormData
  onEdit?: (order: IInspectContainerFormData) => void
  onRemove?: (order: IInspectContainerFormData) => void
}

export const GateInOrderListItem = observer(({ order, onEdit, onRemove }: IProps) => {
  const { orderItemStore, vesselVisitItemStore, truckVisitItemStore, railVisitItemStore } =
    usePlanningStore()
  const theme = useMinimalsTheme()
  const { t } = useTranslate()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const dropOffOrder = { ...order } as ContainerJourneyDto
  if (order.linkedOrderId) {
    const linkedOrder = orderItemStore.elements[order.linkedOrderId]?.data
    if (linkedOrder) {
      dropOffOrder.linkedOrder = {
        ...linkedOrder,
        carrierVisit: linkedOrder.carrierVisitId
          ? linkedOrder.carrierVisitType === CarrierType.Vessel
            ? vesselVisitItemStore.elements[linkedOrder.carrierVisitId]?.data
            : linkedOrder.carrierVisitType === CarrierType.Train
              ? railVisitItemStore.elements[linkedOrder.carrierVisitId]?.data
              : truckVisitItemStore.elements[linkedOrder.carrierVisitId]?.data
          : null,
      } as LinkedOrderResponseDto
    }
  }

  return (
    <Box mb={theme.customSpacing.m}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box sx={{ fontSize: '1rem !important' }}>
          <DropOffAutoCompleteRenderOption
            key={`order-card-${order.id}`}
            dropOffOrder={dropOffOrder}
          />
        </Box>

        <Box>
          <IconButton
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)}
          >
            <MoreVert />
          </IconButton>
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            <MenuItem onClick={() => onEdit!(order)}>
              {t('updateContainer', 'Update Container')}
            </MenuItem>
            <MenuItem onClick={() => onRemove!(order)}>{t('remove', 'Remove')}</MenuItem>
          </Menu>
        </Box>
      </Box>
    </Box>
  )
})
