import { SelectOption } from '@storage/app/models'
import { useTranslate } from '@tolgee/react'
import { useMemo } from 'react'

export const useYardBlockUsageOptions = (): SelectOption[] => {
  const { t } = useTranslate()

  return useMemo(() => {
    const options: SelectOption[] = [
      { value: 'railHandover', label: t('railHandover', 'Rail Handover') },
      { value: 'quayHandover', label: t('quayHandover', 'Quay Handover') },
      { value: 'truckHandover', label: t('truckHandover', 'Truck Handover') },
      { value: 'temporaryStowage', label: t('temporaryStowage', 'Temporary Stowage') },
      {
        value: 'dangerousGoodsHandling',
        label: t('DangerousGoodsHandling', 'Dangerous Goods Handling'),
      },
    ]

    return options
  }, [t])
}
