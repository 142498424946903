import { Box, Button, Grid } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { RailVisitResponseDto } from '@planning/app/api'
import { CustomDateTimePicker } from '@planning/components/CustomDateTimePicker'
import { CustomDateTimePickerButton } from '@planning/components/CustomDateTimePickerButton'
import railVisitService from '@planning/services/railVisitService'
import { useTranslate } from '@tolgee/react'
import dayjs, { Dayjs } from 'dayjs'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { RailVisitTimeDetailsBox } from '../../RailVisitTimeDetailsBox'
import { EditRailVisitTimesForm } from './EditRailVisitTimesForm'
import { RailVisitHeaderContainer } from './RailVisitHeaderContainer'

interface Props {
  railVisit: RailVisitResponseDto
}

export const RailVisitHeaderTime: FC<Props> = observer(({ railVisit }) => {
  const { drawerStore } = usePlanningStore()
  const { t } = useTranslate()

  const actions = (
    <Button
      color='primary'
      size='small'
      onClick={() =>
        drawerStore.showView(<EditRailVisitTimesForm railVisit={railVisit} />, {
          title: t('timings', 'Timings'),
          subTitle: t('railVisits', 'Rail Visits'),
        })
      }
    >
      {t('view', 'View')}
    </Button>
  )

  if (!railVisit) return <></>

  return (
    <RailVisitHeaderContainer title='Timings' actions={actions}>
      {(railVisit.atd && (
        <Box>
          <RailVisitTimeDetailsBox
            message={t('trainDeparted', 'Train departed')}
            time={railVisit.atd}
          />
        </Box>
      )) || (
        <Grid container justifyContent='space-between' spacing={4}>
          {(railVisit.ata && (
            <>
              <Grid item xs={12} lg={7}>
                <RailVisitTimeDetailsBox
                  message={t('trainArrived', 'Train arrived')}
                  time={railVisit.ata}
                />
              </Grid>
              <Grid item xs={12} lg={5} display='flex' justifyContent='flex-end'>
                <CustomDateTimePickerButton
                  label={t('atd', 'ATD')}
                  onChange={async (value: Dayjs | null) => {
                    const atd = dayjs(value) as any
                    await railVisitService.put({ ...railVisit, atd })
                  }}
                  value={railVisit.atd}
                  popperPlacement='bottom-end'
                />
              </Grid>
            </>
          )) || (
            <>
              <Grid item xs={12} lg={7}>
                <CustomDateTimePicker
                  label={t('eta', 'ETA')}
                  value={railVisit.eta}
                  onChange={async (value: Dayjs | null) => {
                    const eta = dayjs(value) as any
                    await railVisitService.put({ ...railVisit, eta })
                  }}
                  popperPlacement='bottom-end'
                />
              </Grid>
              <Grid item xs={12} lg={5} display='flex' justifyContent='flex-end'>
                <CustomDateTimePickerButton
                  label={t('ata', 'ATA')}
                  onChange={async (value: Dayjs | null) => {
                    const ata = dayjs(value) as any
                    await railVisitService.put({ ...railVisit, ata })
                  }}
                  value={railVisit.ata}
                  popperPlacement='bottom-end'
                  pickerButtonTestId='set-ata-btn'
                  confirmButtonTestId='set-ata-confirm-btn'
                />
              </Grid>
            </>
          )}
        </Grid>
      )}
    </RailVisitHeaderContainer>
  )
})
