import { createSvgIcon } from '@mui/material'

export const HeightIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.1738 2.60868C16.4619 2.60868 16.6955 2.84227 16.6955 3.13042L16.6955 20.8696C16.6955 21.1577 16.4619 21.3913 16.1738 21.3913C15.8856 21.3913 15.652 21.1577 15.652 20.8696L15.652 3.13042C15.652 2.84227 15.8856 2.60868 16.1738 2.60868Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18.1079 4.54283C17.9042 4.74658 17.5738 4.74658 17.3701 4.54283L16.1738 3.34653L14.9775 4.54283C14.7737 4.74658 14.4434 4.74658 14.2396 4.54283C14.0359 4.33907 14.0359 4.00873 14.2396 3.80498L15.8049 2.23976C16.0086 2.03601 16.339 2.03601 16.5427 2.23976L18.1079 3.80498C18.3117 4.00873 18.3117 4.33908 18.1079 4.54283Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.2396 19.4571C14.4434 19.2534 14.7737 19.2534 14.9775 19.4571L16.1738 20.6534L17.3701 19.4571C17.5738 19.2534 17.9042 19.2534 18.1079 19.4572C18.3117 19.6609 18.3117 19.9912 18.1079 20.195L16.5427 21.7602C16.339 21.964 16.0086 21.964 15.8049 21.7602L14.2396 20.195C14.0359 19.9912 14.0359 19.6609 14.2396 19.4571Z'
      fill='currentColor'
    />
    <path
      d='M12.5216 3.13042C12.5216 2.55413 12.0544 2.08694 11.4781 2.08694L7.30422 2.08694C6.72792 2.08694 6.26074 2.55413 6.26074 3.13042L6.26074 20.8696C6.26074 21.4459 6.72792 21.913 7.30422 21.913H11.4781C12.0544 21.913 12.5216 21.4459 12.5216 20.8696V20.0869H10.4347C10.0024 20.0869 9.65205 19.7366 9.65205 19.3043C9.65205 18.8721 10.0024 18.5217 10.4347 18.5217H12.5216V16.4348H11.4781C11.0459 16.4348 10.6955 16.0844 10.6955 15.6522C10.6955 15.2199 11.0459 14.8696 11.4781 14.8696H12.5216V12.7826H10.4347C10.0024 12.7826 9.65205 12.4322 9.65205 12C9.65205 11.5678 10.0024 11.2174 10.4347 11.2174H12.5216V9.13042H11.4781C11.0459 9.13042 10.6955 8.78004 10.6955 8.34781C10.6955 7.91559 11.0459 7.56521 11.4781 7.56521H12.5216V5.47825H10.4347C10.0024 5.47825 9.65205 5.12786 9.65205 4.69564C9.65205 4.26342 10.0024 3.91303 10.4347 3.91303L12.5216 3.91303L12.5216 3.13042Z'
      fill='currentColor'
    />
  </svg>,
  'HeightIcon',
)
