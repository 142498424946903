import { Box } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { VesselVisitTable } from '../components/VesselVisitTable/VesselVisitTable'
import { VesselVisitTableUIStore } from '../stores/vessel-visit-table.ui-store'

interface Props {
  uiStore: VesselVisitTableUIStore
}

export const VesselVisitTableContainer = observer((uiStore: Props) => {
  const theme = useMinimalsTheme()
  return (
    <Box padding={theme.spacing(2)}>
      <VesselVisitTable items={uiStore.uiStore.sortedItems} />
    </Box>
  )
})
