import { Card, CardActionArea, Stack, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
interface Props {
  railcarNumber: string
  railcarLength?: number | null
  onClickEdit?: () => void
}
export const RailcarsCard = observer(({ railcarNumber, railcarLength, onClickEdit }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  return (
    <CardActionArea
      sx={{
        '.MuiCardActionArea-focusHighlight': {
          background: 'transparent',
        },
        mb: theme.customSpacing.m,
      }}
      onClick={onClickEdit}
    >
      <Card
        sx={{
          padding: theme.customSpacing.m,
        }}
      >
        <Typography variant='h2'>{railcarNumber}</Typography>
        <Stack mt={theme.customSpacing.s}>
          {
            <Typography variant='body1'>
              {t('length', 'Length')}: {railcarLength ?? t('unknown', 'Unknown')}
            </Typography>
          }
        </Stack>
      </Card>
    </CardActionArea>
  )
})
