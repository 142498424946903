import { TextField, TextFieldProps } from '@mui/material'
import { Controller, FieldValues, FormState, UseControllerProps } from 'react-hook-form'

type FieldProps = Pick<TextFieldProps, 'label' | 'disabled' | 'fullWidth' | 'variant'>

interface Props<T extends FieldValues> extends UseControllerProps<T>, FieldProps {
  formState: FormState<T>
  min?: number
  max?: number
  formArrayIndex?: number
  endAdornment?: React.ReactNode
  onChange?: (value: string | null) => Promise<void> | void
}

export const ControlledNumberField = <T extends FieldValues>({
  name,
  control,
  label,
  fullWidth,
  disabled,
  min,
  max,
  variant,
  formArrayIndex,
  endAdornment,
  formState: { errors },
  onChange,
}: Props<T>) => {
  const error =
    errors['values'] && formArrayIndex !== undefined && (errors['values'] as any)[formArrayIndex]
      ? (errors['values'] as any)[formArrayIndex]['name']
      : errors[name]

  return (
    <Controller
      render={({ field }) => (
        <TextField
          {...field}
          error={!!error}
          helperText={error?.message as string}
          label={label}
          inputProps={{ min, max }}
          type='number'
          fullWidth={fullWidth}
          disabled={disabled}
          variant={variant ?? 'outlined'}
          onChange={event => {
            if (onChange) {
              onChange(event.target.value)
            }

            field.onChange(event)
          }}
          InputProps={{
            endAdornment: endAdornment,
          }}
        />
      )}
      name={name}
      control={control}
    />
  )
}
