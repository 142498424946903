import { StockDto } from '@billing/app/api'
import { Box, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'

interface IProps {
  stock: StockDto
}

export const GCStockCard = ({ stock }: IProps) => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()

  const CommodityCharacteristics = () => (
    <Box display='flex' alignItems='center' gap={theme.customSpacing.m} mb={0.5}>
      <Typography
        sx={{
          maxWidth: '300px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        variant='h6'
      >
        {stock.commodity}
      </Typography>
      {stock.packaging != null ? (
        <Typography variant='body1' color={theme.palette.secondary.dark}>
          {stock.packaging}
        </Typography>
      ) : (
        ''
      )}
    </Box>
  )

  const StockDetails = () => (
    <Box display='flex' gap={theme.customSpacing.m}>
      <Typography variant='body1'>{stock.customer}</Typography>
      {stock.lotNumber != null ? (
        <Typography variant='body1'>
          {t('lot', 'LOT')}: {stock.lotNumber}
        </Typography>
      ) : (
        ''
      )}
    </Box>
  )

  return (
    <Box
      sx={{
        mb: '1rem',
        width: '1000px',
      }}
    >
      {/* CARD HEADER */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: theme.customSpacing.m,
          borderTopRightRadius: theme.customRadius.medium,
          borderTopLeftRadius: theme.customRadius.medium,
          borderBottom: `1px solid ${theme.palette.divider}`,
          backgroundColor: '#fff',
          boxShadow: theme.customShadows.card,
        }}
      >
        {/* Commodity characteritics */}
        <Box>
          <CommodityCharacteristics />
          <StockDetails />
        </Box>
      </Box>
    </Box>
  )
}
