import { Box } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { TallymanV2ViewStore } from '@planning/rt-stores/tallyman/TallymanV2ViewStore'
import { Header, IconButton, MenuIcon, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import { CheckSuccessAlert } from './Components/CheckSuccessAlert'
import { ContainerSearchBox } from './Components/ContainerSearchBox'
import { HeaderAvatar } from './Components/HeaderComponents/HeaderAvatar'
import { ReportSuccessAlert } from './Components/ReportSuccessAlert'

interface IProps {
  store: TallymanV2ViewStore
  onSearchClick: () => void
  onCancelCheckRequest: () => void
  openDrawer: () => void
}

export const ContainerOperations: FC<IProps> = observer(
  ({ store, onSearchClick, onCancelCheckRequest, openDrawer }) => {
    const theme = useMinimalsTheme()
    const { drawerStore } = usePlanningStore()
    const { vesselTallyStore } = store
    const { checkNotificationStore, reportNotificationStore } = vesselTallyStore

    const operation = vesselTallyStore.selectedOperation
    const vesselVisit = vesselTallyStore.selectedVisit

    const vesselData = vesselVisit?.vessels[0]?.data
    const headerTitle = vesselData?.name
    const headerSubtitle = `${operation}`

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    return (
      <>
        <Header
          title={headerTitle}
          subTitle={headerSubtitle}
          leftRenderOption={
            <IconButton onClick={openDrawer}>
              <MenuIcon />
            </IconButton>
          }
          rightRenderOption={<HeaderAvatar />}
        />

        <Box
          sx={{
            backgroundColor: theme.palette.grey[200],
            minHeight: 'calc(100vh - 65px)',
            padding: '1rem',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <ContainerSearchBox store={store} onSearchClick={onSearchClick} />
          {checkNotificationStore.request && (
            <CheckSuccessAlert store={checkNotificationStore} onCancel={onCancelCheckRequest} />
          )}
          {reportNotificationStore.request && (
            <ReportSuccessAlert store={reportNotificationStore} />
          )}
        </Box>
      </>
    )
  },
)
