import { Box, Typography } from '@mui/material'
import { HoldResponseDto, HoldStatus } from '@planning/app/api'
import { HoldsIcon, useMinimalsTheme } from '@tom-ui/ui'
import { FC, useMemo } from 'react'

interface Props {
  holds: HoldResponseDto[]
  onClick?: () => Promise<void> | void
}

export const HoldsChip: FC<Props> = ({ holds, onClick }) => {
  const theme = useMinimalsTheme()

  const activeHoldsCount = useMemo(
    () => holds.filter(h => h.status === HoldStatus.Active).length,
    [holds],
  )

  const chipColor = () => (activeHoldsCount ? theme.palette.error.dark : theme.palette.success.dark)
  const chipBgColor = () =>
    activeHoldsCount ? theme.palette.error.lighter : theme.palette.success.lighter

  return (
    <Box
      onClick={onClick}
      sx={{
        cursor: 'pointer',
        background: chipBgColor(),
        padding: '4px 8px',
        borderRadius: '4px',
        display: 'flex',
        height: '32px',
      }}
    >
      <Typography
        display={'flex'}
        fontSize={'14px'}
        alignSelf={'center'}
        alignItems={'center'}
        fontWeight={500}
        color={chipColor()}
      >
        {activeHoldsCount}
        <HoldsIcon sx={{ marginLeft: '4px' }} />
      </Typography>
    </Box>
  )
}
