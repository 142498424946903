import { Box } from '@mui/system'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Id } from './types'

interface IProp {
  children?: React.ReactNode
  groupId: Id
  currentTabId: Id
}

export const GroupingTabPanel: FC<IProp> = observer(({ children, groupId, currentTabId }) => {
  return (
    <Box role='tabpanel' hidden={currentTabId !== groupId} id={groupId}>
      {currentTabId == groupId && children}
    </Box>
  )
})
