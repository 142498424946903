import { SelectOption } from '@operations/app/models'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from '@mui/material'
import { Controller, FieldValues, FormState, UseControllerProps } from 'react-hook-form'

export type AllSelectOption = 'All'

type FieldProps = Pick<SelectProps, 'disabled' | 'multiple' | 'size'>

interface Props<T extends FieldValues> extends UseControllerProps<T>, FieldProps {
  formState: FormState<T>
  label: string
  fullWidth?: boolean
  allOption?: boolean
  options: SelectOption[]
  formArrayIndex?: number
  onChange?: (option: string | null) => Promise<void> | void
}

export const ControlledSelect = <T extends FieldValues>({
  name,
  control,
  label,
  disabled,
  multiple,
  fullWidth,
  options,
  size,
  allOption,
  formArrayIndex,
  onChange,
  formState: { errors },
}: Props<T>) => {
  const error =
    errors['values'] && formArrayIndex !== undefined && (errors['values'] as any)[formArrayIndex]
      ? (errors['values'] as any)[formArrayIndex]['name']
      : errors[name]

  return (
    <FormControl fullWidth={fullWidth ?? true}>
      <InputLabel>{label}</InputLabel>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Select
            {...field}
            error={!!error}
            size={size}
            multiple={multiple}
            disabled={disabled}
            onChange={event => {
              if (onChange) {
                onChange(event.target.value)
              }

              field.onChange(event.target.value)
            }}
            label={label}
          >
            {allOption && <MenuItem value='All'>All</MenuItem>}
            {options.map(({ value, label }) => (
              <MenuItem key={label} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        )}
      />
      {error && <FormHelperText error={!!error}>{error?.message as string}</FormHelperText>}
    </FormControl>
  )
}
