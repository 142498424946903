import { ServiceOrdersType } from '@planning/pages/ServiceOrders/Stores/ServiceOrdersViewStore'
import { useTranslate } from '@tolgee/react'
import { StrippingIcon, StuffingIcon } from '@tom-ui/ui'
import { FC } from 'react'
import { TooltipChip } from './TooltipChip'

export const PackingServiceTooltipChip: FC<{
  isStuffing: boolean
  onClick: (serviceOrdersType: ServiceOrdersType) => void
}> = ({ isStuffing, onClick }) => {
  const { t } = useTranslate()

  const title = isStuffing
    ? t('stuffingOrder', 'Stuffing order')
    : t('strippingOrder', 'Stripping order')

  return (
    <TooltipChip
      title={title}
      onClick={() => (isStuffing ? onClick('stuffing') : onClick('stripping'))}
    >
      {isStuffing ? <StuffingIcon /> : <StrippingIcon />}
    </TooltipChip>
  )
}
