import { MoreVert } from '@mui/icons-material'
import { IconButtonProps, MenuItem, Tooltip } from '@mui/material'
import Menu from '@mui/material/Menu'
import { useTranslate } from '@tolgee/react'
import { IconButton } from '@tom-ui/ui'
import { cloneElement, useState } from 'react'

interface IconActionMenuProps {
  icon?: React.ReactElement<IconButtonProps>
  onEdit: () => void
  onDelete: () => void
}

const IconActionMenu = ({ icon, onEdit, onDelete }: IconActionMenuProps) => {
  const { t } = useTranslate()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setAnchorEl(e.currentTarget)
  }

  const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setAnchorEl(null)
  }

  const handleOnEdit = (e: React.MouseEvent<any>) => {
    handleClose(e)
    onEdit()
  }

  const handleOnDelete = (e: React.MouseEvent<any>) => {
    handleClose(e)
    onDelete()
  }

  return (
    <div>
      {cloneElement(
        icon || (
          <Tooltip title={t('more', 'More')}>
            <IconButton
              aria-label='more'
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup='true'
              onClick={handleClick}
            >
              <MoreVert />
            </IconButton>
          </Tooltip>
        ),
        { onClick: handleClick },
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleOnEdit}>{t('edit', 'Edit')}</MenuItem>
        <MenuItem onClick={handleOnDelete}>{t('remove', 'Remove')}</MenuItem>
      </Menu>
    </div>
  )
}

export default IconActionMenu
