import { Box, Stack, Typography } from '@mui/material'

interface TileWithIconProps {
  icon: React.ReactNode
  label: string
  value: string
  iconAfterLabel?: boolean
}

export const TileWithIcon = ({ icon, label, value, iconAfterLabel = false }: TileWithIconProps) => {
  return (
    <Stack>
      {iconAfterLabel ? (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Typography variant='caption'>{label}</Typography>
          {icon}
        </Box>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          {icon}
          <Typography variant='caption'>{label}</Typography>
        </Box>
      )}
      <Typography variant='subtitle2'>{value}</Typography>
    </Stack>
  )
}
