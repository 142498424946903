import { Box, Chip, Typography } from '@mui/material'
import { ContainerDto, ContainerResponseDto } from '@planning/app/api'

interface IProps {
  container: ContainerDto | ContainerResponseDto
}

export const ContainerAutocompleteListItem = ({ container }: IProps) => {
  return (
    <Box display='flex' alignItems='center'>
      <Typography mr='10px' variant='subtitle2'>
        {container.number}
      </Typography>
      {container.operator && (
        <Chip
          sx={{
            m: '0 5px',
          }}
          label={container.operator}
        />
      )}
      <Chip
        sx={{
          m: '0 5px',
        }}
        label={container.isoCode}
      />
    </Box>
  )
}
