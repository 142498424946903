import { FormType } from '@storage/app/models'
import { action, computed, makeObservable, observable } from 'mobx'
import { GeneralCargoAreaStore } from './general-cargo-area.store'

export class GeneralCargoAreaUIStore {
  dialogAction: FormType = 'Add'
  isDialogOpen = false
  dialogEntityId?: string

  searchQuery = ''

  constructor(private readonly _generalCargoAreaStore: GeneralCargoAreaStore) {
    makeObservable(this, {
      dialogAction: observable,
      isDialogOpen: observable,
      toggleDialog: action,

      searchQuery: observable,
      setSearchQuery: action,

      generalCargoAreas: computed,
    })
  }

  toggleDialog(action?: FormType, dialogEntityId?: string) {
    if (action) {
      this.dialogAction = action
    }
    this.dialogEntityId = dialogEntityId
    this.isDialogOpen = !this.isDialogOpen
  }

  get generalCargoAreas() {
    if (!this.searchQuery) return this._generalCargoAreaStore.entries
    return this._generalCargoAreaStore.entries.filter(
      entry =>
        entry.name.toLowerCase().includes(this.searchQuery) ||
        entry.locations.some(location =>
          location.name.toLocaleLowerCase().includes(this.searchQuery),
        ),
    )
  }

  setSearchQuery(searchQuery: string) {
    const trimmedSearchQuery = searchQuery.trim()
    if (this.searchQuery !== trimmedSearchQuery) {
      this.searchQuery = trimmedSearchQuery.toLocaleLowerCase()
    }
  }
}
