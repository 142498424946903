import { SxProps, Theme } from '@mui/material'
import { visitDirectionShort } from '@planning/constants'
import { EnumChip } from './EnumChip'

export const CarrierVisitDirectionChip = ({
  direction,
  variant,
  size,
  sx,
}: {
  direction: string
  variant?: 'filled' | 'outlined' | 'soft'
  size?: 'small' | 'medium'
  sx?: SxProps<Theme>
}) => {
  const chipColor = (value: string) => {
    switch (value) {
      case 'IN':
        return 'warning'
      case 'OUT':
      case 'Discharge':
      case 'Load':
        return 'secondary'
      default:
        return 'default'
    }
  }

  return (
    <EnumChip
      value={direction}
      options={visitDirectionShort}
      color={chipColor}
      variant={variant}
      size={size}
      sx={{ borderRadius: 1, width: '80px', m: 0.1, ...sx }}
    />
  )
}
