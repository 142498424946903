enum ReportType {
  VesselList = 'vesselListContainers',
  Storage = 'containerStorage',
  Containers = 'Containers',
  CustomerBilling = 'customerBillingContainers',
  GCStoragePreBilling = 'generalCargoStorage',
  GCServicesPreBilling = 'generalCargoServices',
  DeliveryNote = 'deliveryNote',
  HandlingServicesReport = 'handling Services',
  BillableItemsReport = 'billableItems',
}

export const ReportTypes: string[] = Object.values(ReportType)

export default ReportType
