import { VesselVisitDto, VesselVisitStore } from '@operations/stores/VesselVisitStore'

import { computed, makeObservable } from 'mobx'

export class VesselVisitTableUIStore {
  constructor(private vesselVisitStore: VesselVisitStore) {
    makeObservable(this, {
      sortedItems: computed,
    })
  }

  public get sortedItems(): VesselVisitDto[] {
    return [...this.vesselVisitStore.items].sort(i => i.id)
  }
}
