import { MetricItem } from '@operations/features/craneSplit/models/metric.model'
import {
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Typography,
  linearProgressClasses,
  styled,
} from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { getRGBFromThemeByColorName } from '@operations/utils/helper'
import React from 'react'

const MetricProgress = styled(LinearProgress)<{ colorname?: string }>(({ theme, colorname }) => ({
  flex: 'auto',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[300],
  },
  [`& .${linearProgressClasses.bar}`]: {
    background: colorname,
  },
}))

interface Props {
  title: string
  total: number
  items: MetricItem[]
}

export const MetricCard = ({ title, total, items }: Props) => {
  const theme = useMinimalsTheme()

  const getColor = (item: MetricItem) => {
    const completedPercentageBasedOnTotal = (100 * item.completed) / total
    const itemPercentage = (100 * item.total) / total
    const completeness = itemPercentage - completedPercentageBasedOnTotal

    return `linear-gradient(to left, ${getRGBFromThemeByColorName(theme, item.color, 0.45)} 
    ${completeness}%, 
    ${getRGBFromThemeByColorName(theme, item.color)} ${completeness}%)`
  }

  return (
    <Card variant='outlined'>
      <CardContent>
        <Typography variant='h2'>
          {title} ({Math.round((total + Number.EPSILON) * 100) / 100})
        </Typography>
        <Grid container columnSpacing={2} width={'100%'} alignItems={'center'}>
          {items.map(
            item =>
              item.label && (
                <React.Fragment key={item.label}>
                  <Grid item={true} xs={3} lg={2}>
                    <Typography variant='body2'>{item.label}</Typography>
                  </Grid>
                  <Grid item={true} xs={8} lg={9}>
                    <MetricProgress
                      variant='determinate'
                      value={(100 * item.total) / total}
                      colorname={getColor(item)}
                    />
                  </Grid>
                  <Grid item={true} xs={1}>
                    <Typography variant='body2'>
                      {Math.round((item.total + Number.EPSILON) * 100) / 100}
                    </Typography>
                  </Grid>
                </React.Fragment>
              ),
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}
