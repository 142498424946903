import { Autocomplete, TextField } from '@mui/material'
import { CustomerResponseDto } from '@planning/app/api'
import { IAutocompleteViewStore } from '@planning/stores/autocomplete/AutocompleteViewStore'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

interface Props {
  store: IAutocompleteViewStore<CustomerResponseDto>
  selectedCustomer?: CustomerResponseDto
  handleOnChange?: (value?: CustomerResponseDto) => void
  error?: boolean
  helperText?: React.ReactNode
  label?: string
  disable?: boolean
}

export const CustomerAutoComplete: FC<Props> = observer(
  ({ store, selectedCustomer, handleOnChange, error, helperText, label, disable }) => {
    const { t } = useTranslate()

    return (
      <Autocomplete
        disablePortal
        disabled={disable}
        getOptionLabel={customer => customer.name}
        open={store.isOpen}
        onOpen={() => store.setOpen(true)}
        onClose={() => store.setOpen(false)}
        options={store.items}
        isOptionEqualToValue={(option: CustomerResponseDto, value: CustomerResponseDto) =>
          option.id === value.id
        }
        value={selectedCustomer ?? null}
        onChange={(_, customer) => {
          if (handleOnChange) handleOnChange(customer ?? undefined)
        }}
        renderInput={params => (
          <TextField
            {...params}
            data-cy='customer-autocomplete'
            label={label ?? t('customer', 'Customer')}
            error={error}
            helperText={helperText}
            onChange={e => {
              store.setFilter(e.target.value)
            }}
          />
        )}
      />
    )
  },
)
