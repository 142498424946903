import {
  ContainerYardOperationDto,
  StackDto,
  YardBlockSlotLocation,
  YardBlockStacksApi,
} from '@storage/app/api'
import { createApiClient } from '@storage/app/http-client'
import { SelectOption } from '@storage/app/models'
import { action, computed, makeObservable, observable } from 'mobx'

interface StackSlotLocation {
  id: string
  block: string
  bay: string
  row: string
  tier: number
}

export class DropDownSelectStore {
  turnoverData?: ContainerYardOperationDto
  selectedSlots: SelectOption[] = []

  stacks: StackDto[] = []

  constructor() {
    makeObservable(this, {
      turnoverData: observable,
      selectedSlots: observable,
      stacks: observable,
      setTurnoverData: action,
      setStacks: action,
      locations: computed,
      location: computed,
    })
  }

  async loadStacks(): Promise<StackDto[]> {
    const { data: stacks } = await createApiClient(YardBlockStacksApi).getYardBlockStacks({})

    return stacks
  }

  setTurnoverData(data?: ContainerYardOperationDto) {
    if (!data?.turnover) {
      this.turnoverData = undefined
    } else {
      this.turnoverData = { ...data }
    }
  }

  setStacks(stacks: StackDto[]) {
    this.stacks = [...stacks]
  }

  private sortStacks = (s1: StackDto, s2: StackDto) => {
    let res = (s1.yardPosition.block ?? '').localeCompare(s2.yardPosition.block ?? '')
    if (res === 0) {
      res = s1.baySequenceNumber - s2.baySequenceNumber
      if (res === 0) {
        res = s1.rowSequenceNumber - s2.rowSequenceNumber
      }
    }

    return res
  }

  get locations(): SelectOption[] {
    const stacks = [...this.stacks.filter(e => !e.isLocked)].sort(this.sortStacks)
    const slots = stacks.reduce((list, stack) => {
      const stackSlots = Array.from({ length: stack.numberOfSlots }, (_, i) => i + 1).map(n => {
        return {
          id: `${stack.id}-${n}`,
          block: stack.yardPosition.block,
          bay: stack.yardPosition.bay,
          row: stack.yardPosition.row,
          tier: n,
        } as StackSlotLocation
      })
      return [...list, ...stackSlots]
    }, [] as StackSlotLocation[])
    return slots.map(e => {
      return {
        value: e.id,
        label: `${e.block}.${e.bay}.${e.row}.${e.tier}`,
      }
    })
  }

  setSelectedSlots(slots: SelectOption[]) {
    this.selectedSlots = [...slots]
  }

  get location() {
    const loc = this.selectedSlots.reduce((locs, e) => {
      const segments = e.label.split('.')
      return [
        ...locs,
        {
          block: segments[0],
          bay: segments[1],
          row: segments[2],
          tier: parseInt(segments[3]),
        } as YardBlockSlotLocation,
      ]
    }, [] as YardBlockSlotLocation[])

    return loc
  }
}
