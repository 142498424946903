import { BooleanSelectOption, SelectOption } from '@storage/app/models'
import { useMemo } from 'react'

const KEY_PREFIX = 'DANGEROUS'

export const useDangerousOptions = (): SelectOption[] =>
  useMemo(
    () => [
      { label: `${KEY_PREFIX}.DANGEROUS`, value: BooleanSelectOption.True },
      { label: `${KEY_PREFIX}.NON_DANGEROUS`, value: BooleanSelectOption.False },
    ],
    [],
  )
