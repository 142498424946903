import { Box, Card, CardContent, Stack, Typography } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { ColorSchema } from '@tom-ui/ui/dist/theme/palette'

interface Props {
  title?: string
  totalNumber?: string | number
  numberDescription?: string
  icon?: JSX.Element
  color?: ColorSchema
}

export const ReportSummaryCard = ({
  title,
  totalNumber,
  numberDescription,
  icon,
  color = 'primary',
}: Props) => {
  const theme = useMinimalsTheme()

  return (
    <Card
      sx={{
        width: '100%',
        backgroundColor: theme.palette[color].lighter,
        borderRadius: theme.customRadius.large,
      }}
    >
      <CardContent sx={{ padding: theme.customSpacing.xl + ' !important' }}>
        <Stack direction='row' justifyContent='space-between' alignItems='flex-start'>
          <Stack gap={theme.customSpacing.l}>
            <Typography variant='subtitle2' color={theme.palette[color].darker}>
              {title}
            </Typography>

            <Stack direction='row' gap={theme.customSpacing.m} alignItems='baseline'>
              <Typography variant='h1' color={theme.palette[color].darker}>
                {totalNumber}
              </Typography>

              <Typography variant='body2' color={theme.palette[color].darker}>
                {numberDescription}
              </Typography>
            </Stack>
          </Stack>

          {icon && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '44px',
                height: '44px',
                borderRadius: theme.customRadius.oval,
                backgroundColor: theme.palette[color].dark,
                'svg > *': {
                  fill: theme.palette[color].lighter,
                },
              }}
            >
              {icon}
            </Box>
          )}
        </Stack>
      </CardContent>
    </Card>
  )
}
