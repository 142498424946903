import { useOperationsStore } from '@operations/AppProvider'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { VesselVisitTableContainer } from '@operations/features/vesselVisit/containers/VesselVisitTableContainer'
import { useTranslate } from '@tolgee/react'
import { FC, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
const VesselVisit: FC = withAuthenticationRequired(() => {
  const { vesselVisitStore, vesselVisitTableUIStore } = useOperationsStore()
  useEffect(() => {
    vesselVisitStore.load()
  }, [vesselVisitStore])
  const { t } = useTranslate()

  return (
    <>
      <Helmet>
        <title>{t('vesselVisits', 'Vessel Visits')}</title>
      </Helmet>
      <VesselVisitTableContainer uiStore={vesselVisitTableUIStore} />
    </>
  )
})
export default VesselVisit
