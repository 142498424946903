import { createSvgIcon } from '@mui/material'

export const VesselIcon = createSvgIcon(
  <svg width='20' height='14' viewBox='0 0 20 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11 1.16667C11 0.522335 11.4477 0 12 0H14C14.5523 0 15 0.522335 15 1.16667V2.33333H15.5C16.0523 2.33333 16.5 2.85567 16.5 3.5V4.66667H17C17.5523 4.66667 18 5.189 18 5.83334V6.22221H19.0476C19.3907 6.22221 19.7073 6.43754 19.8764 6.78588C20.0454 7.13422 20.0408 7.56173 19.8643 7.90499L17.0071 13.4605C16.835 13.7952 16.525 14 16.1905 14H3.33333C3.00496 14 2.69975 13.8026 2.52572 13.4778L0.144764 9.03332C-0.0387293 8.6908 -0.0484454 8.25909 0.119388 7.9058C0.287222 7.55252 0.606167 7.33332 0.952381 7.33332V2.88888C0.952381 2.27523 1.37878 1.77777 1.90476 1.77777H4.76191C5.28789 1.77777 5.71429 2.27523 5.71429 2.88888V3.44446H3.80969C3.54669 3.44446 3.3335 3.69319 3.3335 4.00002C3.3335 4.30684 3.54669 4.55557 3.80969 4.55557L5.71429 4.55557V7.33332H8.04141C8.01447 7.22771 8 7.11583 8 7V5.83334C8 5.189 8.44771 4.66667 9 4.66667H9.5V3.5C9.5 2.85567 9.94771 2.33333 10.5 2.33333H11V1.16667ZM14 6.22221V5.83334H13.5H12.5H12V7H12.5464C12.6369 6.87872 12.7435 6.75261 12.8649 6.6393C13.0507 6.46587 13.3781 6.22221 13.8095 6.22221H14ZM15 4.66667H15.5V3.5H14H13.5V4.66667H14H15ZM17 6.22221V5.83334H15.5H15V6.22221H17ZM14 1.16667V2.33333H13.5H12.5H12V1.16667H14ZM10.5 3.5H12H12.5V4.66667H12H11H10.5V3.5ZM9 5.83334H10.5H11V7H9V5.83334Z'
      fill='currentColor'
    />
  </svg>,
  'VesselIcon',
)
