import { Box, styled } from '@mui/material'
import { ChevronDownIcon, ChevronRightIcon, useMinimalsTheme } from '@tom-ui/ui'

const Badge = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.customSpacing.xxs,
  right: theme.customSpacing.xxs,
  width: theme.customSpacing.s,
  height: theme.customSpacing.s,
  borderRadius: theme.customRadius.oval,
  backgroundColor: theme.palette.common.white,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

interface Props {
  open?: boolean
  small?: boolean
}
export const NavArrow = ({ open, small }: Props) => {
  const theme = useMinimalsTheme()
  const size = small ? theme.customSpacing.s : undefined

  const arrow = open ? (
    <ChevronDownIcon
      sx={{
        width: size,
        height: size,
      }}
    />
  ) : (
    <ChevronRightIcon
      sx={{
        width: size,
        height: size,
      }}
    />
  )

  return small ? <Badge>{arrow}</Badge> : arrow
}
