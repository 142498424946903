import { EmptyIcon } from '@tom-ui/ui'
import { FC } from 'react'

export const IconEmpty: FC<{ zoom?: number; size?: number }> = ({ zoom, size }) => {
  return (
    <EmptyIcon
      sx={{
        height: `${size ?? 32}px`,
        width: `${size ?? 32}px`,
        transform: `scale(${(zoom ?? 100) / 100})`,
      }}
    />
  )
}

export default IconEmpty
