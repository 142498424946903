import { Done } from '@mui/icons-material'
import { Chip } from '@mui/material'
import { Stack } from '@mui/system'
import { usePlanningStore } from '@planning/AppProvider'
import {
  CarrierVisitDirection,
  ContainerJourneyDto,
  NonNumericOrderDto,
  OrderResponseDto,
} from '@planning/app/api'
import { GateInViewStore } from '@planning/stores/gateControl/GateInViewStore'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import { CustomAlert } from '../../TallymanV2/Components'
import { DropOffAutoCompleteRenderOption } from './DropOffAutoCompleteRenderOption'
import { GateInAutoComplete } from './GateInAutoComplete'
import { GeneralCargoOrderCard } from './GeneralCargoOrderCard'
import { IInspectContainerFormData } from './InspectContainer'

interface IProps {
  store: GateInViewStore
}

export const DropOffAutoComplete: FC<IProps> = observer(({ store }) => {
  const { t } = useTranslate()
  const { tenantStore } = usePlanningStore()

  const [open, setOpen] = useState<boolean>(false)

  const dropOffSearchType = store.searchType
  const dropOffContainerSearchType = store.containerSearchType

  const isGeneralCargoOrder = (c: OrderResponseDto | ContainerJourneyDto) => {
    return 'commodityId' in c && c.commodityId !== null
  }

  const openInboundForADifferentCarrierValidation = (
    order: ContainerJourneyDto | OrderResponseDto,
  ) => {
    return !!store.ordersByContainerNumber.find(
      item =>
        item.containerNumber === order.containerNumber &&
        item.direction === CarrierVisitDirection.Inbound &&
        item.carrierVisitId !== null,
    )
  }

  const alreadyOnTerminalValidation = (
    order: ContainerJourneyDto | OrderResponseDto | NonNumericOrderDto,
  ) => {
    if (order && 'isOnTerminal' in order) {
      return (order as OrderResponseDto).isOnTerminal !== undefined && !!order.isOnTerminal
    }

    return false
  }

  const AlreadyOnTerminalAlert = () => (
    <CustomAlert
      message={t(
        'containerIsAlreadyOnTerminalContactControlRoom',
        'Container is already on terminal, contact control room',
      )}
      severity='warning'
    />
  )

  const OpenInboundWithDifferentCarrierAlert = () => (
    <CustomAlert
      message={t(
        'containerHasOpenOrderForADifferentCarrier',
        'Container has an open order for a different carrier',
      )}
      severity='warning'
    />
  )

  const CargoAmountDiscrepancyAlert = () => (
    <CustomAlert
      message={t('cargoAmountDiscrepancyDetected', 'Cargo amount discrepancy detected')}
      severity='warning'
    />
  )

  const renderOption = (order: ContainerJourneyDto | OrderResponseDto) => {
    const dropOffOrder = order

    if (isGeneralCargoOrder(dropOffOrder) && dropOffSearchType === 'generalCargo') {
      return (
        <GeneralCargoOrderCard key={`order-card-${order.id}`} order={order as OrderResponseDto} />
      )
    }

    return (
      <DropOffAutoCompleteRenderOption
        key={`order-card-${order.id}`}
        dropOffOrder={dropOffOrder}
        alertMessage={[
          alreadyOnTerminalValidation(dropOffOrder) ? <AlreadyOnTerminalAlert /> : <></>,
          openInboundForADifferentCarrierValidation(dropOffOrder) ? (
            <OpenInboundWithDifferentCarrierAlert />
          ) : (
            <></>
          ),
        ]}
      />
    )
  }

  return (
    <>
      {tenantStore.hasGeneralCargo && (
        <Stack direction='row' spacing={1} mb={1}>
          <Chip
            icon={dropOffSearchType === 'container' ? <Done /> : <></>}
            variant={dropOffSearchType === 'container' ? 'filled' : 'outlined'}
            label={t('container', 'Container')}
            onClick={() => store.setSearchType('container')}
          />
          <Chip
            icon={dropOffSearchType === 'generalCargo' ? <Done /> : <></>}
            label={t('generalCargo', 'General Cargo')}
            variant={dropOffSearchType === 'generalCargo' ? 'filled' : 'outlined'}
            onClick={() => store.setSearchType('generalCargo')}
          />
        </Stack>
      )}
      <GateInAutoComplete
        open={open}
        setOpen={setOpen}
        getOptionDisabled={option => alreadyOnTerminalValidation(option)}
        placeholder={
          dropOffContainerSearchType === 'containerNumber'
            ? t('searchContainerNumber', 'Search container number')
            : t('searchReferenceNumber', 'Search reference number')
        }
        store={store.dropOffOrderSearchStore}
        setContainerSearchType={store.setContainerSearchType}
        containerSearchType={store.containerSearchType}
        searchType={store.searchType}
        type={'DropOff'}
        options={store.dropOffOrderSearchStore.items.filter(
          item =>
            !store.dropOffOrders.find(order => order.id === item.id) &&
            !store.dropOffGeneralCargoOrders.find(order => order.id === item.id),
        )}
        renderOption={renderOption}
        getOptionLabel={c => c.containerNumber ?? c.referenceNumber ?? ''}
        handleOnChange={(order?: ContainerJourneyDto | OrderResponseDto) => {
          if (order && isGeneralCargoOrder(order)) {
            store.upsertDropOffGeneralCargoOrder(order as IInspectContainerFormData)
          } else if (order) {
            store.upsertDropOffOrder(order as IInspectContainerFormData)
          }
        }}
      />
    </>
  )
})
