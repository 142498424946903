import { useOperationsStore } from '@operations/AppProvider'
import { FinishWorkInstructionJobDto } from '@operations/features/equipmentOperator/models/finish-job.model'

import { useAuth0 } from '@auth0/auth0-react'
import { Button, Typography } from '@mui/material'
import { OperationType } from '@operations/app/api'
import { useTranslate } from '@tolgee/react'
import { ConfirmationDialog } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { EquipmentOperatorWorkInstructionStepperUIStore } from '../../stores/equipment-operator-work-instruction-stepper.ui-store'

interface Props {
  uiStore: EquipmentOperatorWorkInstructionStepperUIStore
  onConfirm: (currentJob: FinishWorkInstructionJobDto) => Promise<void>
}
export const ConfirmWorkInstructionStepper = observer(({ uiStore, onConfirm }: Props) => {
  const { t } = useTranslate()
  const { user, isAuthenticated } = useAuth0()
  const {
    appStore,
    equipmentOperatorWorkInstructionStepperUIStore,
    equipmentOperatorWorkInstructionsUIStore,
  } = useOperationsStore()

  const handleConfirm = async () => {
    if (!uiStore.isValid) {
      uiStore.setHasError(true)
      return
    }

    if (uiStore.hasNextStep) {
      uiStore.handleNextStep()
      return
    }

    if (!uiStore.hasQuantity) {
      uiStore.toggleConfirmationDialog()
      return
    }

    confirm()
  }

  const handleToggleConfirmation = () => {
    uiStore.toggleConfirmationDialog()
  }

  const confirm = async () => {
    if (uiStore.isConfirmationDialogOpen) {
      uiStore.toggleConfirmationDialog()
    }

    const currentJob = equipmentOperatorWorkInstructionStepperUIStore.getCurrentFinishJobDto(
      isAuthenticated ? `${user?.given_name} ${user?.family_name}` : undefined,
    )

    if (!currentJob) {
      return
    }

    equipmentOperatorWorkInstructionsUIStore.hideJob(currentJob.workInstructionId)

    appStore.showAlertForCancellableRequest(
      'success',
      `${equipmentOperatorWorkInstructionStepperUIStore.commodityName} ${t(
        'isBeingConfirmed',
        'is being confirmed',
      )}`,
      async () => await onConfirm(currentJob),
      undefined,
      () => {
        equipmentOperatorWorkInstructionsUIStore.showJob(currentJob.workInstructionId)
      },
    )

    equipmentOperatorWorkInstructionStepperUIStore.closeStepper()
  }

  return (
    <>
      <Button
        disabled={!uiStore.isValid}
        variant='contained'
        color='primary'
        onClick={handleConfirm}
      >
        {uiStore.hasNextStep ? t('next', 'Next') : t('confirm', 'Confirm')}
      </Button>

      <ConfirmationDialog
        open={uiStore.isConfirmationDialogOpen}
        title={t('confirmJob', 'Confirm Job?')}
        message={t('aboutToConfirmThis', 'You are about to confirm this:')}
        primaryActionText={t('confirm', 'Confirm')}
        closeLabel={t('keepEditing', 'Keep editing')}
        maxWidth='xs'
        blockClickOutside
        onConfirm={confirm}
        onClose={handleToggleConfirmation}
      >
        <Typography marginTop={2} variant='body1'>
          {uiStore.selectedJob?.operationType === OperationType.Inbound &&
            t('unloadedQuantity', 'Unloaded quantity: 0')}

          {uiStore.selectedJob?.operationType === OperationType.Outbound &&
            t('loadedQuantity', 'Loaded quantity: 0')}
        </Typography>
      </ConfirmationDialog>
    </>
  )
})
