import { CarrierVisitDirection, CarrierVisitStatus } from '@storage/app/api'
import { CarrierVisitStore } from '@storage/stores/carrier-visit.store'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import CarrierVisitsWidget from './CarrierVisitsWidget'

interface Props {
  carrierVisitStore: CarrierVisitStore
  handlePlan?: (
    carrierVisitId: number,
    handlingDirection: CarrierVisitDirection,
    discriminator: string,
  ) => void
  navigateToCarrierVisitDetails?: (carrierVisitId: number, discriminator: string) => void
}

const ExpectedCarrierVisitsWidget = observer(
  ({ carrierVisitStore, handlePlan, navigateToCarrierVisitDetails }: Props) => {
    const { t } = useTranslate()
    return (
      <CarrierVisitsWidget
        title={t('expectedCarrierVisits', 'Expected Carrier Visits')}
        carrierVisits={carrierVisitStore.sortActiveCarrierVisits(CarrierVisitStatus.Expected)}
        handlePlan={handlePlan}
        navigateToCarrierVisitDetails={navigateToCarrierVisitDetails}
      />
    )
  },
)

export default ExpectedCarrierVisitsWidget
