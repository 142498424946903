import { Button } from '@mui/material'
import { Box } from '@mui/system'
import AllocationRuleTemplatesTable from '@storage/features/carrier-visit-allocation-rules/components/AllocationRuleTemplatesTable'
import AllocationRuleTemplatesTableDialog from '@storage/features/carrier-visit-allocation-rules/components/AllocationRuleTemplatesTableDialog'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { Header, PlusIcon } from '@tom-ui/ui'

const AllocationRuleTemplatesPage = () => {
  const { t } = useTranslate()
  const { allocationRuleTemplatesV2UIStore } = useStores()
  const dialogUtilStore = allocationRuleTemplatesV2UIStore.dialogUtilStore

  return (
    <>
      <Header
        title={t('allocationRuleTemplates', 'Allocation Rule Templates')}
        searchInputLabel={t('searchByName', 'Search by name')}
        displaySearchIcon
        enableSearchField
        onSearchFieldChange={value => allocationRuleTemplatesV2UIStore.setSearchQuery(value)}
        rightRenderOption={
          <Box flex='none'>
            <Button
              color='inherit'
              variant='contained'
              startIcon={<PlusIcon />}
              onClick={() => dialogUtilStore.toggleDialog('Add')}
            >
              {t('addNew', 'Add new')}
            </Button>
          </Box>
        }
      />
      <AllocationRuleTemplatesTable />
      <AllocationRuleTemplatesTableDialog />
    </>
  )
}

export default AllocationRuleTemplatesPage
