import { FC } from 'react'

export const CraneOperatorLandingIcon: FC<{ zoom?: number }> = ({ zoom }) => {
  return (
    <svg
      width='622'
      height='436'
      viewBox='0 0 622 436'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ transform: `scale(${(zoom ?? 100) / 100})` }}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 240.746C0 290.955 19.3667 336.708 51.236 371.286C87.7972 410.968 140.815 435.916 199.912 436C225.739 436.036 250.435 431.292 273.12 422.634C284.849 418.155 297.977 418.669 309.336 423.975C322.662 430.191 337.583 433.677 353.339 433.677C359.848 433.677 366.222 433.078 372.387 431.941C389.986 428.706 406.001 421.053 419.153 410.213C427.419 403.398 438.005 399.972 448.824 399.984H449.008C484.759 399.984 517.967 389.409 545.538 371.286C570.187 355.105 590.315 332.898 603.726 306.825C615.418 284.104 622 258.437 622 231.271C622 138.1 544.543 62.558 449.008 62.558C439.269 62.558 429.727 63.3602 420.419 64.8572C395.77 25.9421 351.631 0 301.279 0C280.021 0 259.867 4.62364 241.827 12.9119C224.67 20.7685 209.417 31.9325 196.939 45.5378C154.569 46.1966 115.391 59.6956 83.3259 82.2241C32.8512 117.666 0 175.469 0 240.746Z'
        fill='url(#paint0_linear_4391_24252)'
      />
      <path
        d='M276.57 128.075H247.843C244.881 128.075 242.486 125.521 242.486 122.364V68.6359C242.486 65.4787 244.881 62.9253 247.843 62.9253H276.57C279.532 62.9253 281.927 65.4787 281.927 68.6359V122.372C281.927 125.521 279.532 128.075 276.57 128.075ZM253.199 108.654C253.199 113.072 256.781 116.654 261.199 116.654H263.214C267.632 116.654 271.214 113.072 271.214 108.654V82.3464C271.214 77.9281 267.632 74.3464 263.214 74.3464H261.199C256.781 74.3464 253.199 77.9281 253.199 82.3464V108.654Z'
        fill='#212B36'
      />
      <path
        d='M276.57 427.603H247.843C244.881 427.603 242.486 425.05 242.486 421.893V197.777C242.486 194.62 244.881 192.067 247.843 192.067H276.57C279.532 192.067 281.927 194.62 281.927 197.777C281.927 200.935 279.532 203.488 276.57 203.488H261.199C256.781 203.488 253.199 207.07 253.199 211.488V408.19C253.199 412.608 256.781 416.19 261.199 416.19H263.214C267.632 416.19 271.214 412.608 271.214 408.19V230.54C271.214 227.383 273.609 224.829 276.57 224.829C279.532 224.829 281.927 227.383 281.927 230.54V421.893C281.927 425.05 279.532 427.603 276.57 427.603Z'
        fill='#212B36'
      />
      <path
        d='M214.282 203.478H150.598C147.636 203.478 145.241 200.924 145.241 197.767V122.371C145.241 119.214 147.636 116.661 150.598 116.661H214.282C217.244 116.661 219.639 119.214 219.639 122.371V197.775C219.639 200.924 217.244 203.478 214.282 203.478ZM155.954 184.056C155.954 188.475 159.536 192.056 163.954 192.056H200.925C205.344 192.056 208.925 188.475 208.925 184.056V136.082C208.925 131.663 205.344 128.082 200.925 128.082H163.954C159.536 128.082 155.954 131.663 155.954 136.082V184.056Z'
        fill='#212B36'
      />
      <path
        d='M238.194 178.136H214.287C211.326 178.136 208.931 175.582 208.931 172.425V147.845C208.931 144.688 211.326 142.135 214.287 142.135H238.194C241.156 142.135 243.551 144.688 243.551 147.845V172.425C243.551 175.574 241.156 178.136 238.194 178.136ZM219.644 160.135C219.644 163.769 222.607 166.715 226.241 166.715C229.874 166.715 232.837 163.769 232.837 160.135C232.837 156.502 229.874 153.556 226.241 153.556C222.607 153.556 219.644 156.502 219.644 160.135Z'
        fill='#212B36'
      />
      <path
        d='M410.21 178.137H286.208C283.247 178.137 280.852 175.583 280.852 172.426V147.846C280.852 144.689 283.247 142.136 286.208 142.136H393.084C396.046 142.136 398.441 144.689 398.441 147.846C398.441 151.003 396.046 153.557 393.084 153.557H298.145C294.511 153.557 291.565 156.503 291.565 160.136C291.565 163.77 294.511 166.716 298.145 166.716H396.854C401.272 166.716 404.854 163.134 404.854 158.716V147.846C404.854 144.689 407.249 142.136 410.21 142.136C413.172 142.136 415.567 144.689 415.567 147.846V172.426C415.567 175.575 413.172 178.137 410.21 178.137Z'
        fill='#212B36'
      />
      <path
        d='M477.671 178.136H443.571C440.609 178.136 438.214 175.582 438.214 172.425V147.845C438.214 144.688 440.609 142.135 443.571 142.135H477.671C480.632 142.135 483.027 144.688 483.027 147.845V172.425C483.027 175.574 480.632 178.136 477.671 178.136ZM448.927 160.135C448.927 163.769 451.873 166.715 455.507 166.715H465.734C469.368 166.715 472.314 163.769 472.314 160.135C472.314 156.502 469.368 153.556 465.734 153.556H455.507C451.873 153.556 448.927 156.502 448.927 160.135Z'
        fill='#212B36'
      />
      <path
        d='M443.548 213.894H410.175C407.214 213.894 404.818 211.341 404.818 208.183V139.314C404.818 136.157 407.214 133.604 410.175 133.604H443.548C446.51 133.604 448.905 136.157 448.905 139.314V208.183C448.905 211.341 446.51 213.894 443.548 213.894ZM415.532 194.473C415.532 198.891 419.114 202.473 423.532 202.473H430.191C434.609 202.473 438.191 198.891 438.191 194.473V153.025C438.191 148.606 434.609 145.025 430.191 145.025H423.532C419.114 145.025 415.532 148.606 415.532 153.025V194.473Z'
        fill='#212B36'
      />
      <path
        d='M490.403 145.016C489.033 145.016 487.663 144.461 486.615 143.344L410.217 61.9005C408.705 60.2884 406.593 59.3738 404.382 59.3738H295.737C292.775 59.3738 290.38 56.8203 290.38 53.6632C290.38 50.5061 292.775 47.9526 295.737 47.9526H410.066C411.489 47.9526 412.852 48.5563 413.854 49.625L494.191 135.267C496.28 137.494 496.28 141.116 494.191 143.344C493.142 144.461 491.772 145.016 490.403 145.016Z'
        fill='#212B36'
      />
      <path
        d='M182.438 128.086C181.359 128.086 180.265 127.735 179.324 127.017C176.921 125.182 176.37 121.609 178.092 119.047L224.298 50.3489C226.02 47.7873 229.372 47.1999 231.775 49.0355C234.177 50.871 234.728 54.4442 233.007 57.0058L186.8 125.696C185.752 127.254 184.107 128.086 182.438 128.086Z'
        fill='#212B36'
      />
      <path
        d='M511.028 363.591H342.71C339.748 363.591 337.353 361.037 337.353 357.88V327.606C337.353 324.449 339.748 321.896 342.71 321.896H511.028C513.99 321.896 516.385 324.449 516.385 327.606V357.88C516.385 361.029 513.99 363.591 511.028 363.591ZM348.067 344.17C348.067 348.588 351.648 352.17 356.067 352.17H497.671C502.089 352.17 505.671 348.588 505.671 344.17V341.317C505.671 336.898 502.089 333.317 497.671 333.317H356.067C351.648 333.317 348.067 336.898 348.067 341.317V344.17Z'
        fill='#212B36'
      />
      <path
        d='M426.899 333.313C423.938 333.313 421.542 330.759 421.542 327.602V276.28C421.542 273.123 423.938 270.57 426.899 270.57C429.861 270.57 432.256 273.123 432.256 276.28V327.602C432.256 330.751 429.861 333.313 426.899 333.313Z'
        fill='#212B36'
      />
      <path
        d='M426.899 236.251C423.938 236.251 421.542 233.698 421.542 230.541V208.18C421.542 205.023 423.938 202.469 426.899 202.469C429.861 202.469 432.256 205.023 432.256 208.18V230.541C432.256 233.698 429.861 236.251 426.899 236.251Z'
        fill='#212B36'
      />
      <path
        d='M385.475 333.311C384.534 333.311 383.578 333.05 382.713 332.495C380.18 330.864 379.361 327.356 380.891 324.655L409.895 273.334C411.425 270.633 414.716 269.761 417.249 271.392C419.782 273.024 420.601 276.532 419.07 279.232L390.067 330.554C389.064 332.324 387.297 333.311 385.475 333.311Z'
        fill='#212B36'
      />
      <path
        d='M468.292 333.307C466.478 333.307 464.703 332.32 463.7 330.541L434.689 279.22C433.159 276.519 433.978 273.011 436.511 271.38C439.044 269.748 442.334 270.621 443.865 273.322L472.876 324.643C474.406 327.343 473.587 330.851 471.054 332.483C470.182 333.038 469.233 333.307 468.292 333.307Z'
        fill='#212B36'
      />
      <path
        d='M295.769 74.3445H228.687C225.725 74.3445 223.33 71.791 223.33 68.6339V38.7106C223.33 35.5534 225.725 33 228.687 33H243.923C246.885 33 249.28 35.5534 249.28 38.7106C249.28 41.8677 246.885 44.4211 243.923 44.4211H242.044C237.625 44.4211 234.044 48.0028 234.044 52.4211V54.9233C234.044 59.3416 237.625 62.9234 242.044 62.9234H282.412C286.831 62.9234 290.412 59.3416 290.412 54.9234V52.4211C290.412 48.0029 286.831 44.4211 282.412 44.4211H273.202C270.24 44.4211 267.845 41.8677 267.845 38.7106C267.845 35.5534 270.24 33 273.202 33H295.769C298.731 33 301.126 35.5534 301.126 38.7106V68.6339C301.126 71.7829 298.731 74.3445 295.769 74.3445Z'
        fill='#212B36'
      />
      <path
        d='M258.466 44.4241C257.051 44.4241 255.673 43.8122 254.678 42.7517C254.426 42.4825 254.204 42.1888 254.013 41.8788C253.814 41.5688 253.653 41.2343 253.515 40.8917C253.385 40.549 253.278 40.1901 253.209 39.823C253.14 39.4559 253.102 39.0806 253.102 38.7053C253.102 37.2043 253.676 35.7358 254.678 34.6672C255.918 33.3456 257.77 32.7337 259.507 33.109C259.852 33.1824 260.188 33.2966 260.51 33.4353C260.839 33.5821 261.145 33.7535 261.436 33.9574C261.734 34.1695 262.002 34.4061 262.247 34.6672C263.249 35.7358 263.823 37.2043 263.823 38.7053C263.823 39.0806 263.785 39.4559 263.716 39.823C263.647 40.1901 263.548 40.549 263.41 40.8917C263.272 41.2343 263.112 41.5688 262.92 41.8788C262.721 42.1888 262.499 42.4825 262.247 42.7517C262.002 43.0127 261.734 43.2493 261.436 43.4533C261.145 43.6654 260.839 43.8367 260.51 43.9835C260.188 44.1222 259.852 44.2364 259.507 44.3098C259.163 44.3833 258.811 44.4241 258.466 44.4241Z'
        fill='#212B36'
      />
      <path
        d='M286.212 203.485H238.215C235.254 203.485 232.858 200.931 232.858 197.774V122.37C232.858 119.213 235.254 116.66 238.215 116.66H286.212C289.174 116.66 291.569 119.213 291.569 122.37V197.774C291.569 200.923 289.174 203.485 286.212 203.485ZM243.572 184.064C243.572 188.482 247.154 192.064 251.572 192.064H272.855C277.274 192.064 280.855 188.482 280.855 184.064V136.081C280.855 131.663 277.274 128.081 272.855 128.081H251.572C247.154 128.081 243.572 131.663 243.572 136.081V184.064Z'
        fill='#212B36'
      />
      <path
        d='M511.02 186.679H477.647C474.686 186.679 472.291 184.126 472.291 180.969V139.314C472.291 136.157 474.686 133.604 477.647 133.604H511.02C513.982 133.604 516.377 136.157 516.377 139.314V180.969C516.377 184.118 513.982 186.679 511.02 186.679ZM483.004 167.258C483.004 171.676 486.586 175.258 491.004 175.258H497.663C502.082 175.258 505.663 171.676 505.663 167.258V153.025C505.663 148.606 502.082 145.025 497.663 145.025H491.004C486.586 145.025 483.004 148.606 483.004 153.025V167.258Z'
        fill='#212B36'
      />
      <path
        d='M439.255 281.987H414.483C412.57 281.987 410.802 280.902 409.846 279.132L397.464 256.265C396.507 254.495 396.507 252.325 397.464 250.555L409.846 227.688C410.802 225.918 412.57 224.833 414.483 224.833H439.255C441.168 224.833 442.936 225.918 443.892 227.688L456.282 250.555C457.238 252.325 457.238 254.503 456.282 256.265L443.892 279.132C442.936 280.902 441.168 281.987 439.255 281.987ZM415.306 266.375C416.704 268.957 419.404 270.566 422.341 270.566H431.399C434.335 270.566 437.034 268.958 438.433 266.378L443.395 257.222C444.684 254.844 444.684 251.976 443.395 249.598L438.433 240.442C437.034 237.861 434.335 236.254 431.399 236.254H422.341C419.404 236.254 416.704 237.862 415.306 240.444L410.348 249.6C409.061 251.977 409.061 254.843 410.348 257.219L415.306 266.375Z'
        fill='#212B36'
      />
      <defs>
        <linearGradient
          id='paint0_linear_4391_24252'
          x1='710.529'
          y1='-381.356'
          x2='108.201'
          y2='414.378'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F9FAFB' />
          <stop offset='1' stopColor='#F4F6F8' />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default CraneOperatorLandingIcon
