import { ContainerType } from '@storage/app/api'
import { SelectOption } from '@storage/app/models'
import { EnumAsSelectOptionsUtil } from '@storage/utils/enums'
import { useMemo } from 'react'

const KEY_PREFIX = 'containerType'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { Reefer, ...ModifiedContainerType } = ContainerType

export const useContainerTypeOptions = (): SelectOption[] =>
  useMemo(
    () =>
      EnumAsSelectOptionsUtil.transform(ModifiedContainerType, KEY_PREFIX).map(option => ({
        ...option,
        label: option.label,
      })),
    [],
  )
