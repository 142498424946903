import { Box, Button } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { CarrierVisitStatus } from '@planning/app/api'
import { HeaderWithActions } from '@planning/components/HeaderWithActions'
import { GetTruckVisitsQuery } from '@planning/messages/queries'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { useEffect } from 'react'
import { GatePassageResultAlert } from '../GateClerk/Components/GatePassageResultAlert'
import { GateInDialog } from './Components/GateInDialog'
import { TabData, TabsList } from './Components/TabsList'

export const GateControlHome = observer(() => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const { truckVisitItemStore, gateInViewStore, generalCargoViewStore } = usePlanningStore()

  const todayStartDate = moment().startOf('d')
  const todayEndDate = moment().endOf('d')
  const sevenDaysAgoStartDate = moment().add(-7, 'd').startOf('d')
  const sevenDaysAgoEndDate = moment().endOf('d')
  const nextSevenDaysStartDate = moment().startOf('d')
  const nextSevenDaysEndDate = moment().add(7, 'd').endOf('d')
  const includeCompleted = true
  const orderBy: 'eta' | 'ata' | 'atd' = 'ata'
  const isDescending = false
  let filterBy: 'eta' | 'ata' | 'atd' = 'eta'

  useEffect(() => {
    truckVisitItemStore.fetch(
      new GetTruckVisitsQuery(
        todayStartDate.toDate(),
        todayEndDate.toDate(),
        includeCompleted,
        orderBy,
        isDescending,
        'eta',
      ),
    )

    truckVisitItemStore.fetch(
      new GetTruckVisitsQuery(
        todayStartDate.toDate(),
        todayEndDate.toDate(),
        includeCompleted,
        orderBy,
        isDescending,
        'atd',
      ),
    )

    truckVisitItemStore.fetch(
      new GetTruckVisitsQuery(
        undefined,
        undefined,
        includeCompleted,
        orderBy,
        isDescending,
        filterBy,
        undefined,
        true,
      ),
    )
  }, [includeCompleted, isDescending, todayEndDate, todayStartDate, truckVisitItemStore, filterBy])

  const handleFilterChange = (value: any) => {
    if (
      value === 'expectedToday' ||
      value === 'expectedLast7Days' ||
      value === 'expectedNext7Days'
    ) {
      filterBy = 'eta'
    } else if (value === 'departedToday' || value === 'departedLast7Days') {
      filterBy = 'atd'
    } else {
      filterBy = 'ata'
    }

    truckVisitItemStore.setFilterBy(filterBy)

    if (value === 'expectedToday' || value === 'departedToday') {
      truckVisitItemStore.setDateRange(todayStartDate.toDate(), todayEndDate.toDate())
      truckVisitItemStore.fetch(
        new GetTruckVisitsQuery(
          todayStartDate.toDate(),
          todayEndDate.toDate(),
          includeCompleted,
          orderBy,
          isDescending,
          filterBy,
        ),
      )
    } else if (value === 'onTerminal') {
      truckVisitItemStore.setDateRange()
      truckVisitItemStore.fetch(
        new GetTruckVisitsQuery(
          undefined,
          undefined,
          includeCompleted,
          orderBy,
          isDescending,
          filterBy,
          undefined,
          true,
        ),
      )
    } else if (value === 'expectedLast7Days' || value === 'departedLast7Days') {
      truckVisitItemStore.setDateRange(sevenDaysAgoStartDate.toDate(), sevenDaysAgoEndDate.toDate())

      truckVisitItemStore.fetch(
        new GetTruckVisitsQuery(
          sevenDaysAgoStartDate.toDate(),
          sevenDaysAgoEndDate.toDate(),
          includeCompleted,
          orderBy,
          isDescending,
          filterBy,
        ),
      )
    } else if (value === 'expectedNext7Days') {
      truckVisitItemStore.setDateRange(
        nextSevenDaysStartDate.toDate(),
        nextSevenDaysEndDate.toDate(),
      )

      truckVisitItemStore.fetch(
        new GetTruckVisitsQuery(
          nextSevenDaysStartDate.toDate(),
          nextSevenDaysEndDate.toDate(),
          includeCompleted,
          orderBy,
          isDescending,
          filterBy,
        ),
      )
    }
  }

  const handleSortingChange = (value: any) => {
    truckVisitItemStore.setSorting(value)
  }

  const handleTextFilterChange = (value: any) => {
    truckVisitItemStore.setFilter(value.target.value)
  }

  const tabs: TabData[] = [
    {
      title: t('expected', 'Expected'),
      key: 'expected',
      data: truckVisitItemStore.filteredTruckVisits.expected,
      carrierVisitStatus: CarrierVisitStatus.Expected,
    },
    {
      title: t('onTerminal', 'On Terminal'),
      key: 'onTerminal',
      data: truckVisitItemStore.filteredTruckVisits.arrived,
      carrierVisitStatus: CarrierVisitStatus.Arrived,
    },
    {
      title: t('departed', 'Departed'),
      key: 'departed',
      data: truckVisitItemStore.filteredTruckVisits.departed,
      carrierVisitStatus: CarrierVisitStatus.Departed,
    },
  ]

  const onClickGateIn = async () => {
    await generalCargoViewStore.getPackages()
    gateInViewStore.setIsTruckAppointment(false)
    gateInViewStore.toggleGateInDialog(true)
  }

  const onClickTruckAppointment = async () => {
    await generalCargoViewStore.getPackages()
    gateInViewStore.setIsTruckAppointment(true)
    gateInViewStore.toggleGateInDialog(true)
  }

  return (
    <Box
      sx={{ backgroundColor: theme.palette.grey[200], paddingBottom: '16px', minHeight: '100%' }}
    >
      <HeaderWithActions
        title={t('gateControl', 'Gate Control')}
        actions={[
          <Button
            key='createAppointment'
            variant='outlined'
            size='large'
            onClick={onClickTruckAppointment}
          >
            {t('createAppointmentButton', 'Create appointment')}
          </Button>,
          <Button
            data-cy='gate-control-gate-in-btn'
            key='gateInButton'
            variant='contained'
            size='large'
            onClick={onClickGateIn}
          >
            {t('gateIn', 'Gate In')}
          </Button>,
        ]}
      />

      <Box
        sx={{
          padding: `0 ${theme.customSpacing.l}`,
        }}
      >
        <TabsList
          tabs={tabs}
          handleFilterChange={handleFilterChange}
          handleSortingChange={handleSortingChange}
          handleTextFilterChange={handleTextFilterChange}
        />

        <GateInDialog store={gateInViewStore} />
        <GatePassageResultAlert store={gateInViewStore.notificationStore} />
      </Box>
    </Box>
  )
})
