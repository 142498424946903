import { DangerousGoodsDefinitionDto } from '@planning/app/api'

export interface DangerousGoodsDefinitionFormProfile {
  id?: number
  unNumber: string
  material: string
  primaryImoClass: string
  secondaryImoClasses: string[]
  handlingNotes?: string
}

export const defaultValues: DangerousGoodsDefinitionFormProfile = {
  unNumber: '',
  material: '',
  primaryImoClass: '',
  secondaryImoClasses: [],
  handlingNotes: '',
}

export const mapDangerousGoodsDefinitionDtoToDangerousGoodsDefinitionFormProfile = ({
  id,
  unNumber,
  material,
  primaryImoClass,
  secondaryImoClasses,
  handlingNotes,
}: DangerousGoodsDefinitionDto): DangerousGoodsDefinitionFormProfile => ({
  id,
  unNumber: unNumber ?? '',
  material: material ?? '',
  primaryImoClass: primaryImoClass ?? '',
  secondaryImoClasses: secondaryImoClasses ?? [],
  handlingNotes: handlingNotes ?? undefined,
})
