import { Box, Stack, Typography } from '@mui/material'
import { AllocationRuleTemplateDtoFacets } from '@storage/app/api'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'

interface AllocationRuleTemplateFacetListProps {
  facets: AllocationRuleTemplateDtoFacets
}

const AllocationRuleTemplateFacetList = ({ facets }: AllocationRuleTemplateFacetListProps) => {
  const { t } = useTranslate()
  const { palette } = useMinimalsTheme()

  const facetElements = [
    facets.size && `${facets.size}′`,
    facets.isEmpty != null && (facets.isEmpty ? t('empty', 'Empty') : t('full', 'Full')),
    facets.isReefer != null &&
      (facets.isReefer ? t('reefer', 'Reefer') : t('nonReefer', 'Non Reefer')),
    facets.isDangerous != null &&
      (facets.isDangerous ? t('dangerous', 'Dangerous') : t('nonDangerous', 'Non Dangerous')),
    facets.containerOperator,
    facets.containerHeight,
    facets.containerType,
    facets.weightClasses?.length && facets.weightClasses.join(', '),
  ].filter(Boolean)

  return (
    <Box sx={{ overflowX: 'auto', maxWidth: '270px' }}>
      <Stack direction='row' spacing={1}>
        {facetElements.map((element, index) => (
          <Typography
            key={index}
            color={palette.text.secondary}
            whiteSpace='nowrap'
            variant='body1'
          >
            {element}
          </Typography>
        ))}
      </Stack>
    </Box>
  )
}

export default AllocationRuleTemplateFacetList
