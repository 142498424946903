import {
  ContainerDamageResponseDto,
  ContainerDamageStatus,
  ContainerResponseDto,
} from '@planning/app/api'
import { computed, makeObservable } from 'mobx'
import { EntityItem } from '../base/EntityItem'
import { IEntity } from '../types'

export interface IContainerItem extends IEntity<ContainerResponseDto> {
  activeDamages: ContainerDamageResponseDto[]
  resolvedDamages: ContainerDamageResponseDto[]
}

export class ContainerItem extends EntityItem<ContainerResponseDto> implements IContainerItem {
  constructor(id: number, data: ContainerResponseDto) {
    super(id, data)
    makeObservable(this, {
      activeDamages: computed,
      resolvedDamages: computed,
    })
  }

  get activeDamages() {
    return (this.data.damages ?? []).filter(
      damage => damage.status === ContainerDamageStatus.Active,
    )
  }

  get resolvedDamages() {
    return (this.data.damages ?? []).filter(
      damage => damage.status === ContainerDamageStatus.Resolved,
    )
  }
}
