import { Box, TextField } from '@mui/material'
import { IconButton, MinusIcon, PlusIcon } from '@tom-ui/ui'

interface Props {
  value: number
  minValue?: number
  maxValue?: number
  onValueChange: (newValue: number) => void | Promise<void>
}

export const NumberSelect = ({ value, minValue, maxValue, onValueChange }: Props) => {
  const handleIncrement = () => {
    if (maxValue !== undefined && Number(maxValue) <= value) return
    onValueChange(value + 1)
  }

  const handleDecrement = () => {
    if (minValue !== undefined && Number(minValue) >= value) return
    onValueChange(value - 1)
  }

  return (
    <Box display='flex' alignItems='center' justifyContent='center' sx={{ m: '1rem' }}>
      <IconButton onClick={handleDecrement}>
        <MinusIcon />
      </IconButton>
      <TextField
        value={value}
        size='small'
        variant='outlined'
        sx={{ maxWidth: '4rem' }}
        contentEditable={false}
      />
      <IconButton onClick={handleIncrement}>
        <PlusIcon />
      </IconButton>
    </Box>
  )
}
