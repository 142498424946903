import { Typography } from '@mui/material'
import { WorkInstructionDto, WorkInstructionStatus } from '@operations/app/api'
import { EntityTable } from '@operations/components/table/EntityTable'
import { translateEnumValue } from '@operations/utils/enum-extensions'
import { convertOperationType } from '@operations/utils/helper'
import { useTranslate } from '@tolgee/react'
import { MRT_ColumnDef, MRT_TableInstance } from 'material-react-table'
import { MutableRefObject, useMemo } from 'react'
import { JobDetailsTable } from './JobDetailsTable'

interface Props {
  items: WorkInstructionDto[]
  isFiltering: boolean
  workInstructionsTableInstanceRef: MutableRefObject<MRT_TableInstance<WorkInstructionDto> | null>
  onResetFilters: () => void
}

export const WorkInstructionsTable = ({
  items,
  isFiltering,
  workInstructionsTableInstanceRef,
  onResetFilters,
  ...handlers
}: Props) => {
  const { t } = useTranslate()
  const columns = useMemo<MRT_ColumnDef<WorkInstructionDto>[]>(
    () => [
      {
        accessorFn: row => row.cargoUnit?.containerNumber ?? t('emptyContainer', 'EMPTY CONTAINER'),
        header: t('containerNo.'),
      },
      {
        accessorFn: row => `${t(convertOperationType(row.operationType))}`,
        header: t('process'),
      },
      {
        accessorFn: row => row.cargoUnit?.currentLocation.locationName,
        header: t('currentLocation', 'Current location'),
      },
      {
        accessorFn: row => `${t(row.status, translateEnumValue(row.status))}`,
        header: t('status', 'Status'),
      },
    ],
    [t],
  )

  return (
    <EntityTable
      items={items}
      columns={columns}
      {...handlers}
      expandElement={wi =>
        wi.status === WorkInstructionStatus.Finished && !wi.jobs?.length ? (
          <Typography variant='h4' textAlign='center'>
            {t('noJobNeeded', 'No job needed')}
          </Typography>
        ) : (
          <JobDetailsTable jobs={wi.jobs} />
        )
      }
      tableInstanceRef={workInstructionsTableInstanceRef}
      stopAutoResetPageIndex={true}
      isFiltering={isFiltering}
      onClearFilters={onResetFilters}
    ></EntityTable>
  )
}
