import { Box, Grid, MenuItem, Typography } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { ContainerVisitStatusChip } from '@planning/components/ContainerVisitStatusChip'
import { HoldsChip } from '@planning/components/hold/DisplayHoldsButton'
import { PATH_PLANNING } from '@planning/page-url-paths'
import { ContainerJourney } from '@planning/pages/ServiceOrders/Stores/ContainerJourneyDataStore'
import {
  ServiceOrdersType,
  ServiceOrdersViewStore,
} from '@planning/pages/ServiceOrders/Stores/ServiceOrdersViewStore'
import { useTranslate } from '@tolgee/react'
import { ThreeDotMenu, useMinimalsTheme } from '@tom-ui/ui'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { useNavigate } from 'react-router'
import { ContainerVisitsCardCarrierVisitInfo } from './ContainerVisitsCardCarrierVisitInfo'
import { ContainerVisitsCardServicesInfo } from './ContainerVisitsCardServicesInfo'

type Props = {
  containerVisit: ContainerJourney
  onClickEdit?: (journey: ContainerJourney) => void
  isInsideIssueCard?: boolean
}

export const ContainerVisitsCardBody: FC<Props> = observer(
  ({ containerVisit, onClickEdit, isInsideIssueCard }) => {
    const theme = useMinimalsTheme()
    const { t } = useTranslate()
    const navigate = useNavigate()
    const { serviceOrdersViewStore } = usePlanningStore()

    const inbound = containerVisit?.inboundOrder
    const outbound = containerVisit?.outboundOrder
    const inboundOrderId = containerVisit?.inboundOrderId
    const outboundOrderId = containerVisit?.outboundOrderId
    const order = (inbound ?? outbound)?.order

    const navigateToServiceOrderForm = (
      containerVisit: ContainerJourney,
      serviceOrdersViewStore: ServiceOrdersViewStore,
      serviceOrdersType: ServiceOrdersType,
    ) => {
      if (containerVisit.container) {
        serviceOrdersViewStore.setCallbackUrlOnClose(
          `${PATH_PLANNING.containerVisits}/${containerVisit.container.number}`,
        )

        serviceOrdersViewStore.setMainServiceType(serviceOrdersType)
        serviceOrdersViewStore.setSelectedContainerJourney({
          ...containerVisit,
        })
      }

      navigate(PATH_PLANNING.serviceOrders)
    }

    if (!order && !inboundOrderId && !outboundOrderId) return <></>

    return (
      <Grid container spacing={0}>
        <Grid item xs={isInsideIssueCard ? 4 : 2}>
          <Box
            sx={{
              padding: theme.customSpacing.s,
              height: '100%',
            }}
          >
            <Typography variant='subtitle1'>
              {order?.referenceNumber ? `Ref: ${order?.referenceNumber}` : ''}
            </Typography>
            <Typography variant='body1'>{order?.customerName}</Typography>
          </Box>
        </Grid>
        <Grid item xs={isInsideIssueCard ? 4 : 3}>
          <Box
            sx={{
              borderLeft: `1px solid ${theme.palette.divider}`,
              display: inbound ? 'flex' : 'block',
              padding: theme.customSpacing.s,
              height: '100%',
            }}
          >
            <Typography variant='subtitle1' mr={theme.customSpacing.m}>
              {t('in', 'In')}
            </Typography>
            <ContainerVisitsCardCarrierVisitInfo direction='Inbound' containerData={inbound} />
          </Box>
        </Grid>
        {!isInsideIssueCard && (
          <Grid item xs={2}>
            <Box
              sx={{
                borderLeft: `1px solid ${theme.palette.divider}`,
                padding: theme.customSpacing.s,
                height: '100%',
              }}
            >
              <Typography variant='subtitle1'>{t('services', 'Services')}</Typography>
              <ContainerVisitsCardServicesInfo
                data={containerVisit}
                onClickAddService={(serviceOrdersType: ServiceOrdersType) => {
                  navigateToServiceOrderForm(
                    containerVisit,
                    serviceOrdersViewStore,
                    serviceOrdersType,
                  )
                }}
                onClickService={(serviceOrdersType: ServiceOrdersType) => {
                  navigateToServiceOrderForm(
                    containerVisit,
                    serviceOrdersViewStore,
                    serviceOrdersType,
                  )
                }}
              />
            </Box>
          </Grid>
        )}

        <Grid item xs={isInsideIssueCard ? 4 : 3}>
          <Box
            sx={{
              borderLeft: `1px solid ${theme.palette.divider}`,
              display: outbound ? 'flex' : 'block',
              height: '100%',
              padding: theme.customSpacing.s,
            }}
          >
            <Typography variant='subtitle1' mr={theme.customSpacing.m}>
              {t('out', 'Out')}
            </Typography>

            <ContainerVisitsCardCarrierVisitInfo direction='Outbound' containerData={outbound} />
          </Box>
        </Grid>
        {!isInsideIssueCard && (
          <Grid item xs={2}>
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                height: '100%',
                alignItems: 'flex-start',
                placeContent: 'flex-end',
                borderLeft: `1px solid ${theme.palette.divider}`,
                padding: theme.customSpacing.s,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: theme.customSpacing.m,
                }}
              >
                <HoldsChip
                  holds={inbound?.order?.holds.concat(outbound?.order?.holds || []) ?? []}
                />
                <ContainerVisitStatusChip
                  label={t(_(containerVisit.status).lowerFirst())}
                  status={containerVisit.status}
                />
                {onClickEdit && (
                  <ThreeDotMenu>
                    <MenuItem onClick={() => onClickEdit(containerVisit)}>
                      {t('updateVisit', 'Update Visit')}
                    </MenuItem>
                  </ThreeDotMenu>
                )}
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>
    )
  },
)
