import { useContainerSizeOptions } from '@storage/app/container-turnovers/hooks'
import { ControlledSelect, ControlledTextField } from '@storage/components/form-controls'
import { Box, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { useFormContext } from 'react-hook-form'
import { ContainerTurnoversFilterFormProfile } from '../container-turnovers-filter-form.profile'
import { useContainerTypeOptions } from '@storage/app/container-turnovers/hooks/use-container-type-options.hook'
import { useContainerHeightOptions } from '@storage/app/container-turnovers/hooks/use-container-height-options.hook'

export const GeneralAttributesControls = () => {
  const { t } = useTranslate()
  const { formState, control } = useFormContext<ContainerTurnoversFilterFormProfile>()

  const containerSizeOptions = useContainerSizeOptions()
  const containerTypeOptions = useContainerTypeOptions()
  const containerHeightOptions = useContainerHeightOptions()

  return (
    <Box>
      <Typography variant='h6' my={2}>
        {t('generalAttributes', 'General Attributes')}
      </Typography>
      <Box display='flex' flexDirection='row' sx={{ gap: 1 }}>
        <Box flex={1}>
          <ControlledSelect
            allOption
            formState={formState}
            control={control}
            fullWidth
            options={containerSizeOptions}
            label={t('size', 'Size')}
            name='size'
          />
        </Box>
        <Box flex={1}>
          <ControlledSelect
            allOption
            formState={formState}
            control={control}
            fullWidth
            options={containerTypeOptions}
            label={t('type', 'Type')}
            name='containerType'
          />
        </Box>
        <Box flex={1}>
          <ControlledSelect
            allOption
            formState={formState}
            control={control}
            fullWidth
            options={containerHeightOptions}
            label={t('height', 'Height')}
            name='containerHeight'
          />
        </Box>
        <Box flex={1}>
          <ControlledTextField
            name='containerIsoCode'
            formState={formState}
            control={control}
            fullWidth
            label={t('isoCode', 'ISO Code')}
          />
        </Box>
      </Box>
      <Box display='flex' sx={{ flex: 1, width: '25%' }}>
        <ControlledTextField
          name='typeCode'
          formState={formState}
          control={control}
          fullWidth
          label={t('typeCode', 'Type Code')}
        />
      </Box>
    </Box>
  )
}
