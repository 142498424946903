import {
  ContainerDto,
  OrderResponseDto,
  RailVisitResponseDto,
  TruckVisitDto,
  VesselVisitDto,
} from '@planning/app/api'
import { ContainerJourneyDataStore } from '@planning/pages/ServiceOrders/Stores/ContainerJourneyDataStore'
import { OrderItemStore } from '@planning/rt-stores/order/OrderItemStore'
import { action, computed, makeObservable, observable, reaction } from 'mobx'
import { EditOrCreateOrderViewStore } from './EditOrCreateOrderViewStore'

export interface IOrderWithVisit {
  order?: OrderResponseDto
  visit?: TruckVisitDto | VesselVisitDto | RailVisitResponseDto
}

export type IAmAContainerVisitToo = [
  inboundOrder?: IOrderWithVisit,
  outboundOrder?: IOrderWithVisit,
]

export class SelectOrderViewStore {
  selectedContainer?: ContainerDto
  selectedContainerId = 0
  selectedContainerVisit?: IAmAContainerVisitToo

  editOrCreateOrderStore: EditOrCreateOrderViewStore

  constructor(
    // [TODO]: Remove OrderItemStore
    private dataStore: OrderItemStore,
    public containerJourneyDataStore: ContainerJourneyDataStore,
  ) {
    makeObservable(this, {
      selectedContainer: observable,
      selectedContainerId: observable,
      selectedContainerVisit: observable,
      isNoMatches: computed,
      selectContainer: action,
      setContainerId: action,
      selectContainerVisit: action,
    })

    reaction(
      () => this.selectedContainer,
      async container => {
        if (container?.number) {
          this.dataStore.setActiveOrderIdsByContainerNumber(
            container.number,
            container.activeOrderIds,
          )
        }

        if (container?.activeOrderIds.length) {
          this.dataStore.fetchByIds(container.activeOrderIds).catch(error => {
            // TODO: Handle error properly
            console.log(error)
          })
        }
      },
    )

    this.editOrCreateOrderStore = new EditOrCreateOrderViewStore(this)
  }

  get isNoMatches() {
    return !this.selectedContainer?.activeOrderIds.length
  }

  get containerJourneyListItems() {
    return [...this.containerJourneyDataStore.items]
  }

  selectContainer = (container?: ContainerDto) => {
    this.selectedContainer = container
    this.setContainerId(container?.id ?? 0)
  }

  setContainerId = (id: number) => {
    this.selectedContainerId = id
  }

  selectContainerVisit = (containerVisit?: IAmAContainerVisitToo) => {
    this.selectedContainerVisit = containerVisit
  }

  reset = () => {
    this.selectContainer()
    this.selectContainerVisit()
    this.setContainerId(0)
    // this.containerJourneyDataStore.reset()
  }
}
