import gcsService from '@storage/app/gcs/gcs.service'
import generalCargoDamageService from '@storage/app/general-cargo/general-cargo-damage.service'
import { DamageReport, DamageReportBox } from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

interface Props {
  orderId: number
}
const GeneralCargoDamage = observer(({ orderId }: Props) => {
  const [damages, setDamages] = useState<DamageReport[]>()

  const handleDamageImagesDownload = async (paths: string[]) => {
    await gcsService.downloadFiles(paths)
  }

  useEffect(() => {
    const fetchDamage = async () => {
      const orderDamages = await generalCargoDamageService.getOrderDamage(orderId)

      setDamages(
        orderDamages.map(x => ({
          created: new Date(x.createdAt),
          description: x.description,
          quantity: x.quantity,
          imagePaths: x.imagePaths,
        })),
      )
    }

    fetchDamage()
  }, [orderId])

  return damages?.length ? (
    <DamageReportBox damageReports={damages} onDownloadFiles={handleDamageImagesDownload} />
  ) : (
    <></>
  )
})

export default GeneralCargoDamage
