import { useOperationsStore } from '@operations/AppProvider'
import { Box } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { Helmet } from 'react-helmet-async'

export const SelectedEquipmentHelmet = observer(() => {
  const { t } = useTranslate()
  const { equipmentOperatorLandingUIStore, equipmentOperatorUIStore } = useOperationsStore()

  return (
    <Box>
      <Helmet>
        <title>
          {equipmentOperatorLandingUIStore.selectedEquipmentTypes?.length &&
          equipmentOperatorUIStore.typeOfSelectedEquipment
            ? t(equipmentOperatorUIStore.typeOfSelectedEquipment)
            : t('operator', 'Operator')}
        </title>
      </Helmet>
    </Box>
  )
})
