import { OrderResponseDto, OrdersApi } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'
import { Event, IEvent, IQueryHandler, IQueryMessage } from '../messageBus'

export class GetOrdersByContainerNumberQuery implements IQueryMessage {
  static type = 'GetOrdersByContainerNumberQuery'
  type = GetOrdersByContainerNumberQuery.type

  constructor(public containerNumber: string) {}

  getKey = () => {
    return JSON.stringify(this)
  }
}

const httpClient = createApiClient(OrdersApi)

export const getOrdersByContainerNumberQueryHandler: IQueryHandler<
  GetOrdersByContainerNumberQuery,
  IEvent<OrderResponseDto[]>
> = async query => {
  const { data } = await httpClient.getByContainerNumber(query.containerNumber)

  return new Event(GetOrdersByContainerNumberQuery.type, data)
}
