import { PierDto } from '@storage/app/api'
import { Grid } from '@mui/material'
import { PiersListItem } from './PiersListItem'

interface Props {
  items: PierDto[]
  canWrite: boolean
  onDeleteItem: (id: string) => Promise<void> | void
  onEditItem: (id: string) => Promise<void> | void
}

export const PiersList = ({ items, canWrite, onDeleteItem, onEditItem }: Props) => {
  return (
    <Grid container spacing={1.5}>
      {items.map(item => (
        <Grid xs={6} md={3} item key={item.id}>
          <PiersListItem
            item={item}
            canWrite={canWrite}
            onDelete={onDeleteItem}
            onEdit={onEditItem}
          />
        </Grid>
      ))}
    </Grid>
  )
}
