import { withAuthenticationRequired } from '@auth0/auth0-react'
import { Box, Button, Stack } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { BerthSide, CargoType, VesselVisitDto } from '@planning/app/api'
import { HeaderWithActions, InitializationWrapper } from '@planning/components'
import { CarrierVisitNavigateButton } from '@planning/components/CarrierVisitNavigateButton'
import { validateVisitDates } from '@planning/components/carrier-visit-planning/carrier-visit-planning.helper'
import { getDefaultTripId } from '@planning/components/visit/VisitTripIdsFields'
import { IVesselVisitPlanningFormData } from '@planning/rt-stores/vesselVisit/VesselVisitPlanningStore'
import { useTranslate } from '@tolgee/react'
import { ConfirmationDialog } from '@tom-ui/ui'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router'
import { BerthPlanning } from '../BerthPlanning/BerthPlanning'
import { BerthPlanningViewStore } from '../BerthPlanning/Stores/BerthPlanningViewStore'
import { VesselVisitPlanningForm } from './Components/VesselVisitPlanning/VesselVisitPlanningForm'

export const VesselVisitPlanningPage: FC = withAuthenticationRequired(
  observer(() => {
    const { id } = useParams()
    const { t } = useTranslate()
    const navigate = useNavigate()
    const {
      appViewStore,
      vesselVisitPlanningStore,
      vesselVisitItemStore,
      berthItemStore,
      tenantStore,
    } = usePlanningStore()

    const berthPlanningViewStore = useMemo(
      () => new BerthPlanningViewStore(berthItemStore, vesselVisitItemStore, tenantStore),
      [berthItemStore, vesselVisitItemStore, tenantStore],
    )

    const actions = [
      <Button
        key='cancelButton'
        variant='contained'
        color='inherit'
        size='large'
        onClick={() => {
          vesselVisitPlanningStore.reset()
          navigate(`/visits`)
        }}
      >
        {t('cancel', 'Cancel')}
      </Button>,
      <Button
        data-cy='vessel-visit-planning-submit-button'
        key='addButton'
        variant='contained'
        size='large'
        onClick={() => methods.handleSubmit(onFormSubmit)()}
      >
        {t('submit', 'Submit')}
      </Button>,
    ]

    const onFormSubmit = async (data: IVesselVisitPlanningFormData) => {
      try {
        if (
          !validateVisitDates(
            appViewStore,
            tenantStore.skipBerthTimestamp,
            t,
            data.eta,
            data.etd,
            data.etb,
          )
        ) {
          return
        }

        if (vesselVisitPlanningStore.isEditMode && data.id) {
          await vesselVisitPlanningStore.updateVesselVisit(data)
          appViewStore.setCreatedVesselVisitId(data.id)
          appViewStore.setShowAlert(
            'success',
            'Vessel visit updated successfully',
            <CarrierVisitNavigateButton />,
          )
        } else {
          const responseDto = await vesselVisitPlanningStore.createVesselVisit(data)
          appViewStore.setCreatedVesselVisitId(responseDto[0].id)
          appViewStore.setShowAlert(
            'success',
            'Vessel visit created successfully',
            <CarrierVisitNavigateButton />,
          )
        }
        vesselVisitPlanningStore.reset()
        navigate(`/visits`)
      } catch (error) {
        if (error instanceof AxiosError && error.response?.data?.detail) {
          appViewStore.setShowAlert('error', error.response?.data?.detail)
        } else {
          appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
        }
      }
    }

    const methods = useForm<IVesselVisitPlanningFormData>({
      defaultValues: {
        eta: dayjs(),
        etb: dayjs(),
        etd: dayjs().add(1, 'day'),
        vesselIds: [],
        cargoType: CargoType.Container,
        berthSide: BerthSide.Port,
      },
    })

    useEffect(() => {
      const { unsubscribe } = methods.watch((formValues, { type }) => {
        if (type === 'change') {
          berthPlanningViewStore.setFormValues(formValues as IVesselVisitPlanningFormData)
        }
      })

      return () => unsubscribe()
    }, [berthPlanningViewStore, methods])

    useEffect(() => {
      const requests = [berthPlanningViewStore.fetchPiers(), berthPlanningViewStore.fetchVisits()]
      methods.setValue('tripIds', [getDefaultTripId()])

      if (id) {
        vesselVisitPlanningStore.setIsEditMode(true)
        const vesselVisitId = Number(id)

        requests.push(vesselVisitItemStore.fetchById(vesselVisitId))

        Promise.all(requests).then(() => {
          const vesselVisitItem = vesselVisitItemStore.getById(vesselVisitId)
          vesselVisitPlanningStore.setVesselVisit(vesselVisitItem)

          const vesselVisit = vesselVisitItem?.data

          if (vesselVisit) {
            updateForm(vesselVisit)
            berthPlanningViewStore.setVesselVisitSelectedId(vesselVisitId)
            berthPlanningViewStore.setSelectedVessels(
              vesselVisitPlanningStore.vesselVisit?.vessels.map(v => v.data) ?? [],
            )
            berthPlanningViewStore.setStartDateIfNewDateHasADifferentStart(
              vesselVisit.etb ?? vesselVisit.eta,
            )
            berthPlanningViewStore.setPierByWaterMark(vesselVisit.qmmFrom ?? 0)
          }
          vesselVisitPlanningStore.setLoading(false)
        })
      } else {
        vesselVisitPlanningStore.setIsEditMode(false)
        vesselVisitPlanningStore.setLoading(false)
        Promise.all(requests)
      }
    }, [id])

    const updateForm = (vesselVisit: VesselVisitDto) => {
      methods.setValue('id', vesselVisit.id)
      if (vesselVisit.eta) methods.setValue('eta', dayjs(vesselVisit.eta))
      if (vesselVisit.etb) methods.setValue('etb', dayjs(vesselVisit.etb))
      if (vesselVisit.etd) methods.setValue('etd', dayjs(vesselVisit.etd))
      if (vesselVisit.carrierIds) methods.setValue('vesselIds', vesselVisit.carrierIds)
      if (vesselVisit.importDraft) methods.setValue('importDraft', vesselVisit.importDraft)
      if (vesselVisit.exportDraft) methods.setValue('exportDraft', vesselVisit.exportDraft)
      if (vesselVisit.dischargeEstimate)
        methods.setValue('dischargeEstimate', vesselVisit.dischargeEstimate)
      if (vesselVisit.loadEstimate) methods.setValue('loadEstimate', vesselVisit.loadEstimate)
      if (vesselVisit.cargoType) methods.setValue('cargoType', vesselVisit.cargoType)
      if (vesselVisit.berthSide) methods.setValue('berthSide', vesselVisit.berthSide)
      if (vesselVisit.qmmFrom) methods.setValue('qmmFrom', vesselVisit.qmmFrom)
      if (vesselVisit.qmmTo) methods.setValue('qmmTo', vesselVisit.qmmTo)
      if (vesselVisit.inboundTripIds?.length && vesselVisit.outboundTripIds?.length)
        methods.setValue(
          'tripIds',
          vesselVisit.inboundTripIds.map((x, index) => ({
            inbound: x,
            outbound: vesselVisit.outboundTripIds![index] ?? '',
          })),
        )
    }
    const title = vesselVisitPlanningStore.isEditMode
      ? t('editVesselVisit', 'Edit vessel visit')
      : t('createVesselVisit', 'Create vessel visit')

    return (
      <InitializationWrapper isInitializing={vesselVisitPlanningStore.loading}>
        <Box sx={{ height: '100%' }}>
          <HeaderWithActions
            noMarginBottom={true}
            noBorder={true}
            title={title}
            actions={actions}
          />
          <Stack
            direction='row'
            spacing={1}
            sx={{
              height: 'calc(100% - 80px)',
            }}
          >
            <Stack
              sx={{
                padding: '0 1rem 1rem 1rem',
                height: '100%',
                overflowY: 'auto',
                flex: 2,
              }}
            >
              <FormProvider {...methods}>
                <VesselVisitPlanningForm
                  isEditMode={vesselVisitPlanningStore.isEditMode}
                  store={vesselVisitPlanningStore}
                  vessel={vesselVisitPlanningStore.vesselVisit?.vessels.map(v => v.data)}
                  onFormSubmit={onFormSubmit}
                  onVesselChange={vessels => berthPlanningViewStore.setSelectedVessels(vessels)}
                />
              </FormProvider>
            </Stack>
            <Stack
              sx={{
                flex: 5,
                height: '100%',
                overflowY: 'auto',
                padding: 1,
              }}
            >
              <BerthPlanning store={berthPlanningViewStore} />
            </Stack>
          </Stack>
          <ConfirmationDialog
            open={berthPlanningViewStore.isDialogOpen}
            title={t('youHaveUnsavedChanges', 'You have unsaved changes')}
            message={t(
              'doYouWantToSaveBeforeEditingVisit',
              'Do you want to save your changes before editing another visit?',
            )}
            primaryActionText={t('saveChanges', 'Save changes')}
            closeLabel={t('discardChanges', 'Discard Changes')}
            onConfirm={async () => {
              const data = methods.getValues()
              await vesselVisitPlanningStore.updateVesselVisit(data)
              berthPlanningViewStore.toggleConfirmationDialog(false)
              berthPlanningViewStore.setFormValues()

              navigate(
                `/vessel-visits-planning/${berthPlanningViewStore.nextVesselVisitIdToBeEdited}`,
              )
            }}
            onClose={() => {
              berthPlanningViewStore.setFormValues()
              berthPlanningViewStore.toggleConfirmationDialog(false)
              navigate(
                `/vessel-visits-planning/${berthPlanningViewStore.nextVesselVisitIdToBeEdited}`,
              )
            }}
          />
        </Box>
      </InitializationWrapper>
    )
  }),
)
