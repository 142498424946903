import { Box, Button } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import CustomTabsContainer from './CustomTabsContainer'

interface Props {
  children: JSX.Element
  onClearAll: () => void
  showClearAll: boolean
}

const QuickFilterPanel = ({ showClearAll, children, onClearAll }: Props) => {
  const { t } = useTranslate()

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <CustomTabsContainer>{children}</CustomTabsContainer>

      {showClearAll && (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Button variant='text' size='small' color='primary' onClick={onClearAll}>
            {t('clearAll', 'Clear All')}
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default QuickFilterPanel
