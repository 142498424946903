import { Chip, Typography } from '@mui/material'
import { Box } from '@mui/system'
import {
  GeneralCargoInventoryOccupancyDto,
  GeneralCargoStockOccupancyDamageDto,
  LengthMeasurementUnit,
} from '@storage/app/api'
import { useHubConnection } from '@storage/hooks/use-hub-connection.hook'
import { useStores } from '@storage/hooks/use-stores.hook'
import { yardManagementHubConnection } from '@storage/hubs/yard-management.hub-connection'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table'
import { useMemo } from 'react'
import { GeneralCargoInventoryPreviewColumn } from './GeneralCargoInventoryPreviewColumn'
import GeneralCargoStockOccupancyDamageDialog from './GeneralCargoStockOccupancyDamageDialog'

interface GeneralCargoInventoryStockTableProps {
  occupancies: GeneralCargoInventoryOccupancyDto[]
  imoClasses: string[]
}
const GeneralCargoInventoryStockTable = ({
  occupancies,
  imoClasses,
}: GeneralCargoInventoryStockTableProps) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  useHubConnection(yardManagementHubConnection)

  const overwriteMinimalTableHeadCellStyle = {
    sx: {
      backgroundColor: theme.palette.background.neutral,
    },
  }

  const { generalCargoInventoryUIStore } = useStores()

  const openDamageDialog = (damages: GeneralCargoStockOccupancyDamageDto[]) => {
    if (damages.length > 0) generalCargoInventoryUIStore.openDialog(damages)
  }

  const getExtension = (value?: number | null, unit?: LengthMeasurementUnit | null) => {
    if (!value) return ''
    let abbr = ''
    switch (unit) {
      case LengthMeasurementUnit.Millimeters:
        abbr = 'mm'
        break
      case LengthMeasurementUnit.Centimeter:
        abbr = 'cm'
        break
      case LengthMeasurementUnit.Meter:
        abbr = 'm'
        break
      case LengthMeasurementUnit.Inches:
        abbr = 'in'
        break
      case LengthMeasurementUnit.Feet:
        abbr = 'ft'
        break
      default:
        abbr = ''
    }
    return `${value}${abbr}`
  }

  const columns = useMemo<MRT_ColumnDef<GeneralCargoInventoryOccupancyDto>[]>(
    () => [
      {
        id: 'package',
        accessorFn: row => row.package?.name,
        header: t('packageType', 'Package type'),
      },
      {
        id: 'quantity',
        accessorFn: row => row.quantity,
        header: t('quantity', 'Quantity'),
      },
      {
        id: 'location',
        accessorFn: row => `${row.generalCargoArea}.${row.generalCargoLocation}`,
        header: t('generalCargoLocation', 'Location'),
      },
      {
        id: 'length',
        accessorFn: row => getExtension(row.package?.length, row.package?.lengthUnit),
        header: t('length', 'Length'),
      },
      {
        id: 'width',
        accessorFn: row => getExtension(row.package?.width, row.package?.lengthUnit),
        header: t('width', 'Width'),
      },
      {
        id: 'height',
        accessorFn: row => getExtension(row.package?.height, row.package?.lengthUnit),
        header: t('height', 'Height'),
      },
      {
        id: 'unitId',
        accessorFn: row => (
          <GeneralCargoInventoryPreviewColumn
            items={row.properties.map(e => (
              <Chip key={e.unitId} label={e.unitId} />
            ))}
            preview={2}
          />
        ),
        header: t('generalCargoUnitIds', 'Unit IDs'),
      },
      {
        id: 'imoClasses',
        accessorFn: _ => (
          <GeneralCargoInventoryPreviewColumn
            items={imoClasses.map(e => (
              <Chip key={e} label={e} />
            ))}
            preview={3}
          />
        ),
        header: t('imoClasses', 'Imo classes'),
      },
      {
        id: 'damage',
        accessorFn: row =>
          row.damages.length > 0 && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
              <Typography variant='body2'>{row.damages[0].description}</Typography>
              <Typography
                variant='subtitle2'
                color={theme.palette.grey[600]}
                onClick={() => openDamageDialog(row.damages)}
                sx={{ cursor: 'pointer' }}
              >
                {t('open', 'open')}
              </Typography>
              <GeneralCargoStockOccupancyDamageDialog />
            </Box>
          ),
        header: t('damages', 'Damages'),
      },
    ],
    [t, imoClasses, theme],
  )

  return (
    <Box m={theme.customSpacing.l}>
      <MaterialReactTable
        muiTableHeadCellProps={overwriteMinimalTableHeadCellStyle}
        columns={columns}
        data={occupancies}
        enableTopToolbar={false}
        muiTableDetailPanelProps={() => ({
          sx: theme => ({
            backgroundColor: theme.palette.background.neutral,
          }),
        })}
        muiExpandButtonProps={({ row }) => ({
          sx: {
            transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
            transition: 'transform 0.2s',
          },
        })}
        enablePagination={false}
        enableColumnActions={false}
        enableStickyHeader={true}
        enableColumnFilters={false}
        enableBottomToolbar={false}
      />
    </Box>
  )
}

export default GeneralCargoInventoryStockTable
