import { MenuItem, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { OrderResponseDto } from '@planning/app/api'
import { OrderCard } from '@planning/components/OrderCard'
import { VerticalOverflowBox } from '@planning/components/VerticalOverflowBox'
import { HoldsChip } from '@planning/components/hold/DisplayHoldsButton'
import { useTranslate } from '@tolgee/react'
import { ThreeDotMenu } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { TabPanel } from '../../../components/TabPanel'

interface Props {
  index: number
  orders: OrderResponseDto[]
  value: number
  handleEditOrder: (order: OrderResponseDto) => void
  handleRemoveOrder: (order: OrderResponseDto) => void
}

export const TruckAppointmentOrdersTabPanel: FC<Props> = observer(
  ({ index, orders, value, handleEditOrder, handleRemoveOrder }: Props) => {
    const { t } = useTranslate()

    const menu = (order: OrderResponseDto) => {
      const items: JSX.Element[] = []
      if (order.containerNumber) {
        items.push(
          <MenuItem key={`edit-${order.id}`} onClick={() => handleEditOrder(order)}>
            {t('edit', 'Edit')}
          </MenuItem>,
        )
      }

      items.push(
        <MenuItem key={`delete-${order.id}`} onClick={() => handleRemoveOrder(order)}>
          {t('delete', 'Delete')}
        </MenuItem>,
      )
      return (
        <Box>
          <HoldsChip holds={order.holds} />
          <ThreeDotMenu color='info'>{items.map(item => item)}</ThreeDotMenu>
        </Box>
      )
    }

    return (
      <TabPanel value={value} index={index}>
        <VerticalOverflowBox sx={{ height: '100%', minWidth: '20rem' }}>
          {orders.length ? (
            orders.map(o => {
              return <OrderCard key={o.id} order={o} fullWidth actions={menu(o)} />
            })
          ) : (
            <Typography
              variant='body2'
              sx={{ width: '100%', minHeight: '8.25rem', mb: '0.5rem' }}
              display='flex'
              flexWrap='wrap'
              alignContent='center'
              justifyContent='center'
            >
              {t('noOrders', 'No Orders')}
            </Typography>
          )}
        </VerticalOverflowBox>
      </TabPanel>
    )
  },
)
