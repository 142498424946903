import { createSvgIcon } from '@mui/material'

export const LocationIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <circle cx='12' cy='9.5' r='1.5' fill='currentColor' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4 9.92C4.04387 5.533 7.61278 1.99978 12 2C16.3872 1.99978 19.9561 5.533 20 9.92C20 15.4 13 21.5 12.65 21.76C12.2758 22.0801 11.7242 22.0801 11.35 21.76L11.3484 21.7586C11.0254 21.4787 4 15.3904 4 9.92ZM8.5 9.5C8.5 11.433 10.067 13 12 13C12.9283 13 13.8185 12.6313 14.4749 11.9749C15.1313 11.3185 15.5 10.4283 15.5 9.5C15.5 7.567 13.933 6 12 6C10.067 6 8.5 7.567 8.5 9.5Z'
      fill='currentColor'
    />
  </svg>,
  'LocationIcon',
)
