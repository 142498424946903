export type AllSelectOption = 'All'

export enum BooleanSelectOption {
  True = 'True',
  False = 'False',
}

export type SelectValue = string | BooleanSelectOption

export interface SelectOption {
  label: string
  value: SelectValue
}

export interface WeightClassSelectOption extends SelectOption {
  minWeight: number
  maxWeight?: number | null
}
