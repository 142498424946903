import {
  Alert,
  Box,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import {
  CargoData,
  CarrierType,
  CarrierVisitDirection,
  ContainerType,
  CustomerResponseDto,
  DangerousGoodsDefinitionDto,
  DoorDirection,
  OrderStatus,
  RailVisitResponseDto,
  VesselVisitDto,
} from '@planning/app/api'
import { ControlledContainerSeals } from '@planning/components/ControlledContainerSeals'
import { CustomDateTimePicker } from '@planning/components/CustomDateTimePicker'
import { FeatureFlag } from '@planning/components/FeatureFlags'
import { CustomerAutoComplete } from '@planning/components/customer/CustomerAutoComplete'
import { ContainerDamageReport } from '@planning/components/damage-report/ContainerDamageReport'
import IconTruck from '@planning/components/icon/IconTruck'
import { RailVisitAutoComplete } from '@planning/components/visit/RailVisitAutoComplete'
import { VesselVisitAutoComplete } from '@planning/components/visit/VesselVisitAutoComplete'
import customerService from '@planning/services/customerService'
import { CustomerAutocompleteViewStore } from '@planning/stores/customer/CustomerAutocompleteViewStore'
import { useTranslate } from '@tolgee/react'
import { Dayjs } from 'dayjs'
import { computed } from 'mobx'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useMemo, useState } from 'react'
import { Control, Controller, useForm } from 'react-hook-form'
import { SelectOrderViewStore } from '../stores/SelectOrderViewStore'
import { ContainerSummary } from './ContainerSummary'
import { CreateOrderFormCargoStatusFieldBox } from './CreateOrderFormCargoStatusFieldBox'
import { CreateOrderFormCargoTabs } from './CreateOrderFormCargoTabs'
import { DoorDirectionField } from './DoorDirectionField'
import RailTrackAutocomplete from './RailTrackAutocomplete'
import RailcarAutocomplete from './RailcarAutocomplete'
interface IProps {
  store: SelectOrderViewStore
  forcedDirection?: CarrierVisitDirection
  isTruckAppointment?: boolean
  onSubmit: (data: ICreateOrderFormData) => Promise<void> | void
}

export interface IFormCargoData extends CargoData {
  isEmpty: boolean
  grossWeight?: number
  content?: string
  dangerousGoodsDefinitions: DangerousGoodsDefinitionDto[]
}
export interface ICreateOrderFormData {
  containerId: number
  containerNumber: string
  containerIsoCode: string
  referenceNumber?: string
  consignee?: string
  customer?: CustomerResponseDto

  notes?: string
  operationalInstructions?: string

  containerIsReleasedForPickUp: boolean
  inboundOrderId?: number
  outboundOrderId?: number
  inboundVisit?: VesselVisitDto
  outboundVisit?: VesselVisitDto
  inboundCarrierType?: CarrierType
  outboundCarrierType?: CarrierType
  inboundCargo?: IFormCargoData
  outboundCargo?: IFormCargoData
  releaseExpires?: string
  inboundRailTrackId?: string
  outboundRailTrackId?: string
  inboundRailcarId?: number
  outboundRailcarId?: number
  inboundRailcarTrackPositionId?: number
  outboundRailcarTrackPositionId?: number
  doorDirection?: DoorDirection
  inboundHasSeals: boolean
  outboundHasSeals: boolean
  inboundSeals: string[]
  outboundSeals: string[]
}

const OrderFormSeal: FC<{
  prefix: 'inbound' | 'outbound'
  control: Control<ICreateOrderFormData, any>
}> = ({ prefix, control }) => {
  return (
    <Box mt={'1rem'}>
      <ControlledContainerSeals
        name={`${prefix}Seals`}
        control={control}
        sealsName={`${prefix}Seals`}
        hasSealsName={`${prefix}HasSeals`}
      />
    </Box>
  )
}

export const CreateOrderPageForm: FC<IProps> = observer(
  ({ store, forcedDirection, onSubmit, isTruckAppointment }) => {
    const {
      containerItemStore,
      tenantStore,
      railcarTrackPositionItemStore,
      railcarItemStore,
      railVisitItemStore,
    } = usePlanningStore()
    const { t } = useTranslate()

    const autocompleteStore = useMemo(() => new CustomerAutocompleteViewStore(), [])
    useEffect(() => {
      const fetch = async () => {
        await autocompleteStore.fetch(autocompleteStore.filter)
      }

      fetch().catch(error => console.log(error.message))
    }, [autocompleteStore])

    useEffect(() => {
      if (store.selectedContainerId) {
        containerItemStore.fetchById(store.selectedContainerId)
      }
    }, [containerItemStore, store.selectedContainerId])

    useEffect(() => {
      const fetch = async () => {
        if (store.selectedContainerVisit) {
          const customerId =
            store.selectedContainerVisit[0]?.order?.customerId ??
            store.selectedContainerVisit[1]?.order?.customerId
          if (customerId) {
            return await customerService.getById(customerId)
          }
        }
      }

      fetch().then(customer => {
        if (customer) setCustomer(customer)
      })
    }, [store])

    const container = containerItemStore.elements[store.selectedContainerId]
    const fetchRailcarTrackPositions = (visit?: RailVisitResponseDto) => {
      if (visit?.railcarTrackPositionIds?.length)
        railcarTrackPositionItemStore.fetchByIds(visit.railcarTrackPositionIds)
    }

    const getContainerVisitInfo = () => {
      if (!store.selectedContainerVisit)
        return {
          inboundOrder: undefined,
          outboundOrder: undefined,
        }

      const inbound = store.selectedContainerVisit[0]
      const outbound = store.selectedContainerVisit[1]

      return {
        inboundOrder: inbound,
        outboundOrder: outbound,
      }
    }

    const { inboundOrder, outboundOrder } = getContainerVisitInfo()

    useEffect(() => {
      if (inboundOrder?.visit)
        fetchRailcarTrackPositions(inboundOrder.visit as RailVisitResponseDto)

      if (outboundOrder?.visit)
        fetchRailcarTrackPositions(outboundOrder.visit as RailVisitResponseDto)
    }, [fetchRailcarTrackPositions, inboundOrder, outboundOrder])

    const commonOrderData = inboundOrder?.order ?? outboundOrder?.order

    const [inboundCarrierType, setInbound] = useState(
      isTruckAppointment && forcedDirection === 'Inbound'
        ? CarrierType.Truck
        : inboundOrder?.order?.carrierVisitType,
    )

    const [inboundVisit, setInboundVisit] = useState<VesselVisitDto | undefined>(
      inboundOrder?.visit as VesselVisitDto,
    )

    const [outboundCarrierType, setOutbound] = useState(
      isTruckAppointment && forcedDirection === 'Outbound'
        ? CarrierType.Truck
        : outboundOrder?.order?.carrierVisitType,
    )

    const [outboundVisit, setOutboundVisit] = useState<VesselVisitDto | undefined>(
      outboundOrder?.visit as VesselVisitDto,
    )
    const [customer, setCustomer] = useState<CustomerResponseDto | undefined>(undefined)

    function getDirection(forcedDirection?: CarrierVisitDirection) {
      if (!forcedDirection) {
        return ['Inbound', 'Outbound']
      }

      if (forcedDirection === 'Inbound') {
        return ['Inbound']
      } else if (forcedDirection === 'Outbound') {
        return ['Outbound']
      }
    }

    useEffect(() => {
      railcarItemStore.fetch()
    }, [railcarItemStore])

    const getRailTracksByVisit = (visitId?: number) => {
      const railTracks = railcarTrackPositionItemStore.getRailTracks()

      if (visitId) {
        const railTrackIds = railVisitItemStore.getById(visitId)?.data.assignedRailTrackIds || []
        return railTracks.filter(x => railTrackIds.includes(x.id))
      }

      return railTracks
    }

    const inboundRailTracks = computed(() => getRailTracksByVisit(inboundVisit?.id)).get()
    const outboundRailTracks = computed(() => getRailTracksByVisit(outboundVisit?.id)).get()

    const getRailcars = computed(() => railcarItemStore.getRailcars()).get()

    const {
      register,
      control,
      handleSubmit,
      getValues,
      setValue,
      formState: { errors },
    } = useForm({
      defaultValues: {
        containerId: container?.id ?? store.selectedContainer?.id ?? 0,
        containerNumber: container?.data.number ?? store.selectedContainer?.number,
        containerIsoCode: container?.data.isoCode ?? store.selectedContainer?.isoCode,
        referenceNumber: commonOrderData?.referenceNumber,
        consignee: commonOrderData?.consignee,
        notes: commonOrderData?.notes,
        operationalInstructions: commonOrderData?.operationalInstructions,
        customer: commonOrderData?.customerId
          ? ({
              id: commonOrderData.customerId,
              name: commonOrderData.customerName,
            } as CustomerResponseDto)
          : undefined,
        containerIsReleasedForPickUp: !!commonOrderData?.releaseEnd, // dont need on the form
        inboundOrderId: inboundOrder?.order?.id,
        outboundOrderId: outboundOrder?.order?.id,
        inboundVisit: inboundOrder?.visit,
        outboundVisit: outboundOrder?.visit,
        inboundCarrierType: inboundOrder?.order?.carrierVisitType ?? inboundCarrierType,
        outboundCarrierType: outboundOrder?.order?.carrierVisitType ?? outboundCarrierType,
        releaseExpires: !tenantStore.skipReleaseOrder
          ? outboundOrder?.order?.releaseEnd
          : undefined,
        inboundRailcarId: inboundOrder?.order?.railcarId,
        inboundRailTrackId: inboundOrder?.order?.railTrackId,
        inboundCargo:
          !forcedDirection || forcedDirection === 'Inbound'
            ? {
                isEmpty: inboundOrder?.order?.isEmpty,
                imoClasses: inboundOrder?.order?.imoClasses ?? [],
                temperature: inboundOrder?.order?.temperature,
                dangerousGoodsDefinitions: inboundOrder?.order?.dangerousGoodsDefinitions,
                grossWeight: inboundOrder?.order?.grossWeight,
                content: inboundOrder?.order?.content,
              }
            : undefined,
        outboundRailcarId: outboundOrder?.order?.railcarId,
        outboundRailTrackId: outboundOrder?.order?.railTrackId,
        outboundCargo:
          !forcedDirection || forcedDirection === 'Outbound'
            ? {
                isEmpty: outboundOrder?.order?.isEmpty,
                imoClasses: outboundOrder?.order?.imoClasses ?? [],
                temperature: outboundOrder?.order?.temperature,
                dangerousGoodsDefinitions: outboundOrder?.order?.dangerousGoodsDefinitions,
                grossWeight: outboundOrder?.order?.grossWeight,
                content: outboundOrder?.order?.content,
              }
            : undefined,
        doorDirection: outboundOrder?.order?.doorDirection,
        inboundHasSeals: inboundOrder?.order?.hasSeals,
        outboundHasSeals: outboundOrder?.order?.hasSeals,
        inboundSeals: inboundOrder?.order?.seals ?? [],
        outboundSeals: outboundOrder?.order?.seals ?? [],
      } as ICreateOrderFormData,
    })

    const [showDatetimeForPickUp, setShowDatetimeForPickUp] = useState<boolean>(
      !!outboundOrder?.order?.releaseEnd,
    )

    const isReeferContainer = container?.data.type === ContainerType.Reefer

    const RenderDirectionExtraField: FC<{
      carrierType?: CarrierType
      direction: CarrierVisitDirection
      disabled?: boolean
    }> = ({ carrierType, direction, disabled }) => {
      if (carrierType === CarrierType.Vessel || carrierType === CarrierType.Train) {
        const isInbound = direction === CarrierVisitDirection.Inbound
        const fieldError = isInbound ? errors.inboundVisit : errors.outboundVisit

        return (
          <Controller
            control={control}
            name={isInbound ? 'inboundVisit' : 'outboundVisit'}
            rules={{ required: true }}
            render={({ field: { onChange } }) => {
              const selectedVesselVisit = isInbound ? inboundVisit : outboundVisit
              const setVisitFn = (visit: VesselVisitDto | RailVisitResponseDto | undefined) => {
                if (isInbound) {
                  setValue('inboundRailcarTrackPositionId', undefined)
                  setInboundVisit(visit)
                } else {
                  setValue('outboundRailcarTrackPositionId', undefined)
                  setOutboundVisit(visit)
                }
              }

              const vesselVisitAutoComplete = (
                <VesselVisitAutoComplete
                  selectedVesselVisit={selectedVesselVisit}
                  handleOnChange={visit => {
                    onChange(visit)
                    setVisitFn(visit)
                  }}
                  error={!!fieldError}
                  helperText={fieldError ? t('fieldIsRequired', 'Field is required.') : ''}
                  disabled={disabled}
                  cargoType='Container'
                />
              )

              const railVisitAutoComplete = (
                <RailVisitAutoComplete
                  selectedRailVisit={selectedVesselVisit as RailVisitResponseDto}
                  handleOnChange={visit => {
                    onChange(visit)
                    setVisitFn(visit)
                    fetchRailcarTrackPositions(visit)
                  }}
                  error={!!fieldError}
                  helperText={fieldError ? t('fieldIsRequired', 'Field is required.') : ''}
                  disabled={disabled}
                  cargoType='Container'
                />
              )

              switch (carrierType) {
                case CarrierType.Vessel:
                  return vesselVisitAutoComplete
                case CarrierType.Train:
                  return railVisitAutoComplete
                default:
                  return <></>
              }
            }}
          />
        )
      }

      if (carrierType === 'Truck') {
        return (
          <Box sx={{ display: 'flex', height: '100%', alignItems: 'center' }}>
            <IconTruck zoom={150} />
            <Typography variant='subtitle1' ml='.5rem'>
              {t('truck', 'Truck')}
            </Typography>
          </Box>
        )
      }
    }

    const InboundTransportDataSelection = () => {
      return (
        <>
          {(!forcedDirection || forcedDirection === 'Inbound') && (
            <Grid container spacing={2}>
              <Grid item sm={2} xs={12}>
                <Controller
                  control={control}
                  name={`inboundCarrierType`}
                  rules={{ required: !outboundCarrierType }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl fullWidth error={!!errors.inboundCarrierType}>
                      <InputLabel>{t('inbound', 'Inbound')}</InputLabel>
                      <Select
                        defaultValue=''
                        value={value || ''}
                        label={t('inbound', 'Inbound')}
                        onChange={(e: any) => {
                          onChange(e.target.value)
                          setInbound(e.target.value)
                          setInboundVisit(undefined)
                          setValue('inboundRailcarTrackPositionId', undefined)
                        }}
                        disabled={
                          inboundOrder?.order?.status === OrderStatus.Fulfilled || !!forcedDirection
                        }
                        data-cy='inbound-transport-select'
                      >
                        <MenuItem value=''>
                          <em>{t('none', 'None')}</em>
                        </MenuItem>
                        <MenuItem value='Train'>{t('train', 'Train')}</MenuItem>
                        <MenuItem value='Truck'>{t('truck', 'Truck')}</MenuItem>
                        <MenuItem value='Vessel'>{t('vessel', 'Vessel')}</MenuItem>
                      </Select>

                      {errors.inboundCarrierType && (
                        <FormHelperText>
                          {t('fieldIsRequired', 'Field is required.')}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>

              <Grid item sm={inboundCarrierType === 'Train' ? 4 : 8} xs={12}>
                <RenderDirectionExtraField
                  carrierType={inboundCarrierType}
                  direction={CarrierVisitDirection.Inbound}
                  disabled={
                    inboundOrder?.order?.status === OrderStatus.Fulfilled || !!forcedDirection
                  }
                />
              </Grid>

              {inboundCarrierType === 'Train' && (
                <>
                  <RailTrackAutocomplete
                    control={control}
                    errors={errors}
                    name='inboundRailTrackId'
                    label='Rail Track'
                    options={inboundRailTracks}
                  />
                  <RailcarAutocomplete
                    control={control}
                    errors={errors}
                    name='inboundRailcarId'
                    label='Railcar'
                    options={getRailcars}
                  />
                </>
              )}

              {!inboundCarrierType && inboundOrder?.order?.hasServiceOrders && (
                <Grid item xs={12}>
                  <Alert severity='warning'>
                    {t(
                      'settingInboundTransportToNoneWillDeleteThisOrderAndAnyAssociatedStrippingOrStuffingOrders',
                      'Setting inbound transport to none will delete this order & any associated stripping/stuffing orders',
                    )}
                  </Alert>
                </Grid>
              )}
            </Grid>
          )}
        </>
      )
    }

    const OutboundTranpostDataSelection = () => {
      return (
        <>
          {(!forcedDirection || forcedDirection === 'Outbound') && (
            <Grid container spacing={2}>
              <Grid item sm={2} xs={12}>
                <Controller
                  control={control}
                  name={`outboundCarrierType`}
                  rules={{ required: !inboundCarrierType }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl fullWidth error={!!errors.inboundCarrierType}>
                      <InputLabel>{t('outbound', 'Outbound')}</InputLabel>
                      <Select
                        defaultValue=''
                        value={value || ''}
                        label={t('outbound', 'Outbound')}
                        onChange={(e: any) => {
                          onChange(e.target.value)
                          setOutbound(e.target.value)
                          setOutboundVisit(undefined)
                          setValue('outboundRailcarTrackPositionId', undefined)
                        }}
                        disabled={
                          outboundOrder?.order?.status === OrderStatus.Fulfilled ||
                          !!forcedDirection
                        }
                      >
                        <MenuItem value=''>
                          <em>{t('none', 'None')}</em>
                        </MenuItem>
                        <MenuItem value='Train'>{t('train', 'Train')}</MenuItem>
                        <MenuItem value='Truck'>{t('truck', 'Truck')}</MenuItem>
                        <MenuItem value='Vessel'>{t('vessel', 'Vessel')}</MenuItem>
                      </Select>
                      {errors.outboundCarrierType ? (
                        <FormHelperText>
                          {t('fieldIsRequired', 'Field is required.')}
                        </FormHelperText>
                      ) : (
                        ''
                      )}
                    </FormControl>
                  )}
                />
              </Grid>

              <Grid item sm={outboundCarrierType === 'Train' ? 4 : 8} xs={12}>
                <RenderDirectionExtraField
                  carrierType={outboundCarrierType}
                  direction={CarrierVisitDirection.Outbound}
                  disabled={
                    outboundOrder?.order?.status === OrderStatus.Fulfilled || !!forcedDirection
                  }
                />
              </Grid>

              {outboundCarrierType === 'Train' && (
                <>
                  <RailTrackAutocomplete
                    control={control}
                    errors={errors}
                    name='outboundRailTrackId'
                    label='Rail Track'
                    options={outboundRailTracks}
                  />
                  <RailcarAutocomplete
                    control={control}
                    errors={errors}
                    name='outboundRailcarId'
                    label='Railcar'
                    options={getRailcars}
                  />
                </>
              )}

              {outboundCarrierType === 'Truck' && !tenantStore.skipReleaseOrder ? (
                <Grid item xs={12}>
                  <Box sx={{ mt: '1rem' }}>
                    <Controller
                      control={control}
                      name={`containerIsReleasedForPickUp`}
                      render={({ field: { onChange } }) => (
                        <Box sx={{ mb: '1rem' }}>
                          <Typography variant='subtitle2' mb={'.75rem'}>
                            {`${t(
                              'containerIsReleasedForPickUp',
                              'Container is released for pick-up',
                            )}?`}
                          </Typography>
                          <Chip
                            label={t('yes', 'Yes')}
                            color='primary'
                            sx={{ padding: '0 1rem', mr: '.75rem' }}
                            variant={!showDatetimeForPickUp ? 'outlined' : undefined}
                            onClick={() => {
                              onChange(true)
                              setShowDatetimeForPickUp(true)
                              setValue(
                                'releaseExpires',
                                outboundOrder?.order?.releaseEnd ?? new Date().toISOString(),
                              )
                            }}
                          />
                          <Chip
                            label={t('no', 'No')}
                            color='primary'
                            sx={{ padding: '0 1rem' }}
                            variant={showDatetimeForPickUp ? 'outlined' : undefined}
                            onClick={() => {
                              onChange(false)
                              setShowDatetimeForPickUp(false)
                              setValue('releaseExpires', undefined)
                            }}
                          />
                        </Box>
                      )}
                    />

                    {showDatetimeForPickUp && !tenantStore.skipReleaseOrder ? (
                      <Box sx={{ mb: '1rem' }}>
                        <Controller
                          control={control}
                          name={`releaseExpires`}
                          render={({ field: { onChange, value } }) => (
                            <CustomDateTimePicker
                              label={t('releaseExpiresAfter', 'Release expires after')}
                              value={value}
                              onChange={async (value: Dayjs | null) => {
                                onChange(value)
                              }}
                              popperPlacement={'top-end'}
                            />
                          )}
                        />
                      </Box>
                    ) : (
                      ''
                    )}
                  </Box>
                </Grid>
              ) : (
                ''
              )}
            </Grid>
          )}
        </>
      )
    }

    function renderCargoTabs(forcedDirection?: CarrierVisitDirection) {
      const labels = getDirection(forcedDirection)

      switch (forcedDirection) {
        case 'Inbound':
          return (
            <CreateOrderFormCargoTabs labels={labels}>
              <>
                <InboundTransportDataSelection />
                {inboundCarrierType && (
                  <>
                    <CreateOrderFormCargoStatusFieldBox
                      control={control}
                      register={register}
                      getValues={getValues}
                      setValue={(value: any) => {
                        setValue('inboundCargo.imoClasses', value)
                      }}
                      errors={errors}
                      isDangerousContainer={!!getValues('inboundCargo.imoClasses')?.length}
                      isReeferContainer={isReeferContainer}
                      isEmpty={!inboundOrder?.order?.isEmpty}
                      prefix='inboundCargo'
                    />
                    <OrderFormSeal prefix='inbound' control={control} />
                  </>
                )}
              </>
              <></>
            </CreateOrderFormCargoTabs>
          )

        case 'Outbound':
          return (
            <CreateOrderFormCargoTabs labels={labels}>
              <>
                <OutboundTranpostDataSelection />
                {outboundCarrierType && (
                  <>
                    <CreateOrderFormCargoStatusFieldBox
                      control={control}
                      register={register}
                      getValues={getValues}
                      errors={errors}
                      isDangerousContainer={!!getValues('outboundCargo.imoClasses')?.length}
                      isReeferContainer={isReeferContainer}
                      isEmpty={!outboundOrder?.order?.isEmpty}
                      prefix='outboundCargo'
                      setValue={(value: any) => {
                        setValue('outboundCargo.imoClasses', value)
                      }}
                    />
                    <OrderFormSeal prefix='outbound' control={control} />
                    <DoorDirectionField control={control} />
                  </>
                )}
              </>
              <></>
            </CreateOrderFormCargoTabs>
          )

        default:
          return (
            <CreateOrderFormCargoTabs labels={labels}>
              <>
                {/* Inbound Section */}
                <InboundTransportDataSelection />
                {inboundCarrierType && (
                  <>
                    <CreateOrderFormCargoStatusFieldBox
                      control={control}
                      register={register}
                      getValues={getValues}
                      errors={errors}
                      isDangerousContainer={!!getValues('inboundCargo.imoClasses')?.length}
                      isReeferContainer={isReeferContainer}
                      isEmpty={!inboundOrder?.order?.isEmpty}
                      prefix='inboundCargo'
                      setValue={(value: any) => {
                        setValue('inboundCargo.imoClasses', value)
                      }}
                    />
                    <OrderFormSeal prefix='inbound' control={control} />
                  </>
                )}
              </>
              <>
                {/* Outbound Section */}
                <OutboundTranpostDataSelection />
                {outboundCarrierType && (
                  <>
                    <CreateOrderFormCargoStatusFieldBox
                      control={control}
                      register={register}
                      getValues={getValues}
                      errors={errors}
                      isDangerousContainer={!!getValues('outboundCargo.imoClasses')?.length}
                      isReeferContainer={isReeferContainer}
                      isEmpty={!outboundOrder?.order?.isEmpty}
                      prefix='outboundCargo'
                      setValue={(value: any) => {
                        setValue('outboundCargo.imoClasses', value)
                      }}
                    />
                    <OrderFormSeal prefix='outbound' control={control} />
                    <DoorDirectionField control={control} />
                  </>
                )}
              </>
            </CreateOrderFormCargoTabs>
          )
      }
    }

    return (
      <form onSubmit={handleSubmit(onSubmit)} id='order-form'>
        <Stack gap={4}>
          <Stack gap={2}>
            <Typography variant='subtitle1'>{t('container', 'Container')}</Typography>
            <ContainerSummary container={container?.data} />
          </Stack>

          <Stack gap={2}>
            <Typography variant='subtitle1'>{t('addingNotes', 'Adding notes')}</Typography>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={`${t('notes', 'Control room notes')}`}
                {...register('notes')}
                variant='outlined'
                helperText={t('notesForControlRoom', 'General notes for the control room')}
                type='text'
                name='notes'
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={`${t('operationalInstructions', 'Operational instructions')}`}
                {...register('operationalInstructions')}
                variant='outlined'
                helperText={t(
                  'thisNoteWillAppearOnOperatorsCard',
                  `This note will appear on operator's card`,
                )}
                type='text'
                name='operationalInstructions'
              />
            </Grid>
          </Stack>

          {/* CLIENT DETAILS */}
          <Stack gap={2}>
            <Typography variant='subtitle1'>{t('clientDetails', 'Client Details')}</Typography>
            <Controller
              control={control}
              name='customer'
              rules={{ required: true }}
              render={({ field: { onChange } }) => (
                <CustomerAutoComplete
                  store={autocompleteStore}
                  selectedCustomer={customer}
                  handleOnChange={customer => {
                    onChange(customer)
                    setCustomer(customer)
                  }}
                  error={!!errors.customer}
                  helperText={errors.customer ? t('fieldIsRequired', 'Field is required.') : ''}
                />
              )}
            />
            <TextField
              fullWidth
              label={`${t('referenceNumber', 'Reference Number')}`}
              {...register('referenceNumber')}
              variant='outlined'
              type='text'
              name='referenceNumber'
            />

            <TextField
              fullWidth
              label={`${t('consignee', 'Consignee')}`}
              {...register('consignee')}
              variant='outlined'
              type='text'
              name='consignee'
            />
          </Stack>

          {/* TRANSPORT DETAILS */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='subtitle1'>
                {t('transportDetails', 'Transport Details')}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              {/* CARGO DETAILS */}
              {renderCargoTabs(forcedDirection)}
            </Grid>
          </Grid>

          <FeatureFlag name='damage-recording'>
            <ContainerDamageReport
              containerId={container?.id ?? 0}
              damages={container?.data?.damages ?? []}
            />
          </FeatureFlag>
        </Stack>
      </form>
    )
  },
)
