enum TeamName {
  planning,
  storage,
}

const getTeamInfo = (teamName: TeamName) => {
  let name, port

  switch (teamName) {
    case TeamName.planning:
      name = 'planning'
      port = '3003'
      break
    case TeamName.storage:
      name = 'storage'
      port = '3007'
      break
  }

  return {
    name,
    port,
  }
}

const getUrl = (teamName: TeamName): string => {
  const teamInfo = getTeamInfo(teamName)
  const currentHost = window.location.hostname

  if (currentHost.includes('.demo.omoqo.io')) {
    return `https://tom-${teamInfo.name}.demo.omoqo.io`
  }

  if (currentHost.includes('.dev.omoqo.io')) {
    return `https://tom-${teamInfo.name}.dev.omoqo.io`
  }

  return `http://localhost:${teamInfo.port}`
}

const isRunningInHostUI = (): boolean => {
  try {
    const currentHost = window.location.host
    if (
      currentHost === 'tom.dev.omoqo.io' ||
      currentHost === 'tom.demo.omoqo.io' ||
      currentHost === 'tom.stage.omoqo.io' ||
      currentHost === 'localhost:3015'
    ) {
      return true
    }

    return false
  } catch (error) {
    return false
  }
}

export const inHostUI = isRunningInHostUI()

export const getPlanningUrl = (route: string) => {
  return inHostUI ? `/${route}` : `${new URL(route, getUrl(TeamName.planning))}`
}

export const getStorageUrl = (route: string) => {
  return inHostUI ? `/${route}` : `${new URL(route, getUrl(TeamName.storage))}`
}
