import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import { useTranslate } from '@tolgee/react'
import { FC } from 'react'
import { Helmet } from 'react-helmet-async'

const Home: FC = withAuthenticationRequired(() => {
  const { user, isAuthenticated } = useAuth0()
  const { t } = useTranslate()

  return (
    <>
      <Helmet>
        <title>{t('homeTomOperation', 'Home - Tom Operation')}</title>
      </Helmet>
      {isAuthenticated && (
        <div>
          <h2>{user?.name}</h2>
          <p>{user?.email}</p>
        </div>
      )}
    </>
  )
})

export default Home
