import { useBillingServiceStore } from '@billing/AppProvider'
import ReportStep, { ReportSteps } from '@billing/app/models/report-step.enum'
import ReportType from '@billing/app/models/report-type.enum'
import { Container, Stack, Typography } from '@mui/material'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { BillableItemsServicesContainer } from './BillableItemsServicesContainer'
import { CTReportContainer } from './CTReportContainer'
import { CustomerBillingContainer } from './CustomerBillingContainer'
import { DeliveryNoteContainer } from './DeliveryNoteContainer'
import { GCServicesPrebillingContainer } from './GCServicesPreBillingContainer'
import { GCStoragePreBillingContainer } from './GCStoragePreBillingContainer'
import { HandlingServicesContainer } from './HandlingServicesContainer'
import { ReportHeaderContainer } from './ReportHeaderContainer'
import { ReportTypesContainer } from './ReportTypesContainer'
import { StorageReportContainer } from './StorageReportContainer'
import { VesselReportContainer } from './VesselReportContainer'

export const ReportsContainer = observer(() => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const { reportsUIStore, vesselReportUIStore, storageReportUIStore } = useBillingServiceStore()

  const isPreBillingStorageEnabled = useBooleanFlagValue('prebilling-storage', false)
  const isPrebillingServicesEnabled = useBooleanFlagValue('prebilling-services', false)
  const isHandlingPrebillingEnabled = useBooleanFlagValue('handling-prebilling', false)
  const isBillableItemsReportEnabled = useBooleanFlagValue('billable-items-report', false)

  useEffect(() => {
    return () => {
      vesselReportUIStore.resetData()
      storageReportUIStore.resetData()

      reportsUIStore.resetStepper()
    }
  }, [reportsUIStore, storageReportUIStore, vesselReportUIStore])

  return (
    <>
      <ReportHeaderContainer />

      <Container sx={{ paddingY: theme.customSpacing.m }}>
        <Stack alignItems='start' gap={theme.customSpacing.m}>
          <Stack paddingY={theme.customSpacing.xs} gap={theme.customSpacing.xs}>
            <Typography variant='subtitle1' color={theme.palette.text.secondary}>
              {t('step', 'Step')} {ReportSteps.indexOf(reportsUIStore.currentStep) + 1}
            </Typography>
            <Typography variant='h3'> {t(reportsUIStore.currentStep)}</Typography>
          </Stack>

          {reportsUIStore.currentStep === ReportStep.SelectADataType && <ReportTypesContainer />}

          {reportsUIStore.currentStep !== ReportStep.SelectADataType &&
            reportsUIStore.currentType === ReportType.VesselList && <VesselReportContainer />}

          {reportsUIStore.currentStep !== ReportStep.SelectADataType &&
            reportsUIStore.currentType === ReportType.Storage && <StorageReportContainer />}

          {reportsUIStore.currentStep !== ReportStep.SelectADataType &&
            reportsUIStore.currentType === ReportType.Containers && <CTReportContainer />}

          {reportsUIStore.currentStep !== ReportStep.SelectADataType &&
            reportsUIStore.currentType === ReportType.CustomerBilling && (
              <CustomerBillingContainer />
            )}

          {isPreBillingStorageEnabled &&
            reportsUIStore.currentStep !== ReportStep.SelectADataType &&
            reportsUIStore.currentType === ReportType.GCStoragePreBilling && (
              <GCStoragePreBillingContainer />
            )}

          {isPrebillingServicesEnabled &&
            reportsUIStore.currentStep !== ReportStep.SelectADataType &&
            reportsUIStore.currentType === ReportType.GCServicesPreBilling && (
              <GCServicesPrebillingContainer />
            )}

          {reportsUIStore.currentStep !== ReportStep.SelectADataType &&
            reportsUIStore.currentType === ReportType.DeliveryNote && <DeliveryNoteContainer />}

          {isHandlingPrebillingEnabled &&
            reportsUIStore.currentStep !== ReportStep.SelectADataType &&
            reportsUIStore.currentType === ReportType.HandlingServicesReport && (
              <HandlingServicesContainer />
            )}

          {isBillableItemsReportEnabled &&
            reportsUIStore.currentStep !== ReportStep.SelectADataType &&
            reportsUIStore.currentType === ReportType.BillableItemsReport && (
              <BillableItemsServicesContainer />
            )}
        </Stack>
      </Container>
    </>
  )
})
