import { GateInViewStore } from '@planning/stores/gateControl/GateInViewStore'
import { useTranslate } from '@tolgee/react'
import { ConfirmationDialog } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { ManualGateOutWarningDialog } from './ManualGateOutWarningDialog'

export const GateOutWarningDialogs: FC<{
  viewStore: GateInViewStore
}> = observer(({ viewStore }) => {
  const { t } = useTranslate()

  return (
    <>
      <ManualGateOutWarningDialog viewStore={viewStore} />

      <ConfirmationDialog
        title='Gate out confirmation'
        open={viewStore.isGateOutConfirmationDialogOpen}
        onClose={() => viewStore.closeGateOutConfirmationDialog()}
        onConfirm={() => viewStore.gateOut()}
        closeLabel={t('no', 'No')}
        primaryActionText={t('yes', 'Yes')}
        maxWidth='xs'
      >
        {t(
          'truckVisitWillBeFinishedProceedQuestion',
          'This Truck Visit will be finished. Are you sure you want to proceed?',
        )}
      </ConfirmationDialog>
    </>
  )
})
