import {
  ContainerTurnoverBasicInfoDto,
  ContainerTurnoverDtoPaginatedList,
  ContainerTurnoversApi,
  ContainerTurnoversApiGetAllBasicInfoRequest,
  ContainerTurnoversApiGetAllRequest,
  ContainerTurnoversApiGetContainerPositionByNumberRequest,
  ContainerTurnoversApiGetDetailsRequest,
} from '@storage/app/api'

export class ContainerTurnoverService {
  constructor(private api: ContainerTurnoversApi) {}

  async getAll(
    containerTurnoversApiGetAllRequest: ContainerTurnoversApiGetAllRequest,
  ): Promise<ContainerTurnoverDtoPaginatedList> {
    const { data: containerTurnovers } = await this.api.getAll(containerTurnoversApiGetAllRequest)

    return containerTurnovers
  }

  async getTurnover(containerTurnoverGetDetailsRequest: ContainerTurnoversApiGetDetailsRequest) {
    const { data: containerTurnover } = await this.api.getDetails(
      containerTurnoverGetDetailsRequest,
    )

    return containerTurnover
  }

  async getContainerPositionByNumber(
    containerTurnoverGetDetailsRequest: ContainerTurnoversApiGetContainerPositionByNumberRequest,
  ) {
    const { data: containers } = await this.api.getContainerPositionByNumber(
      containerTurnoverGetDetailsRequest,
    )

    return containers
  }

  async getAllBasicInfo(
    containerTurnoversApiGetAllBasicInfoRequest: ContainerTurnoversApiGetAllBasicInfoRequest,
  ): Promise<ContainerTurnoverBasicInfoDto[]> {
    const { data: containerTurnovers } = await this.api.getAllBasicInfo(
      containerTurnoversApiGetAllBasicInfoRequest,
    )

    return containerTurnovers
  }
}
