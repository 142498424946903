import { ContainerEventDto } from '@planning/app/api'
import { makeObservable } from 'mobx'
import { EntityItem } from '../base/EntityItem'
import { IEntity } from '../types'

export type IContainerEventItem = IEntity<ContainerEventDto>

export class ContainerEventItem
  extends EntityItem<ContainerEventDto>
  implements IContainerEventItem
{
  constructor(id: number, data: ContainerEventDto) {
    super(id, data)
    makeObservable(this, {})
  }
}
