import { useBillingServiceStore } from '@billing/AppProvider'
import { GCStorageReportMode } from '@billing/app/models/gc-storage-report-mode.enum'
import ReportStep from '@billing/app/models/report-step.enum'
import exportService from '@billing/services/ExportService'
import { Box } from '@mui/system'
import { observer } from 'mobx-react-lite'
import { StoragePreBillingReportFilter } from '../components/storagePreBillingReport/storagePreBillingReportFilter'
import { StoragePreBillingReportForm } from '../components/storagePreBillingReport/storagePreBillingReportForm'
import { ReportNavigationButtonContainer } from './ReportNavigationButtonContainer'

export const GCStoragePreBillingContainer = observer(() => {
  const { reportsUIStore, gcPreBillingStorageUIStore } = useBillingServiceStore()

  const finish = () => {
    gcPreBillingStorageUIStore.resetFilter()
    reportsUIStore.resetStepper()
  }

  return (
    <Box>
      {reportsUIStore.currentStep === ReportStep.Filter && <StoragePreBillingReportFilter />}
      {reportsUIStore.currentStep === ReportStep.Report && (
        <StoragePreBillingReportForm store={gcPreBillingStorageUIStore} />
      )}
      <ReportNavigationButtonContainer
        disableNext={!gcPreBillingStorageUIStore.from && !gcPreBillingStorageUIStore.to}
        downloadAction={() =>
          exportService.exportStoragePreBillingData(
            gcPreBillingStorageUIStore.ordersToBePreBilled!,
            gcPreBillingStorageUIStore.reportMode === GCStorageReportMode.InboundOrder
              ? false
              : true,
          )
        }
        finishAction={finish}
        disableDownload={
          !gcPreBillingStorageUIStore.ordersToBePreBilled ||
          gcPreBillingStorageUIStore.ordersToBePreBilled.length === 0
        }
      />
    </Box>
  )
})
