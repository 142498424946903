import { CarrierVisitDto } from '@storage/app/api'
import { CarrierVisitStore } from '@storage/stores/carrier-visit.store'
import { makeObservable, observable, runInAction } from 'mobx'

export class VesselVisitDetailsStore {
  vesselVisitDetails?: CarrierVisitDto

  constructor(
    private readonly _vesselVisitId: number,
    private readonly _vesselVisitStore: CarrierVisitStore,
  ) {
    makeObservable(this, {
      vesselVisitDetails: observable,
    })
  }

  public async load(): Promise<void> {
    const vesselVisit = await this._vesselVisitStore.load(this._vesselVisitId)

    runInAction(() => {
      this.vesselVisitDetails = vesselVisit
    })
  }
}
