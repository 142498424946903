import { CarrierVisitsApi } from '@billing/app/api'
import { createApiClient } from '@billing/app/http-client'

class CarrierVisitsService {
  async getTruckVisitOrders(truckVisitId: number) {
    const { data } = await createApiClient(CarrierVisitsApi).getTruckVisitOrders(truckVisitId)
    return data
  }

  async getTruckVisitSignatures(truckVisitId: number) {
    const { data } = await createApiClient(CarrierVisitsApi).getTruckVisitSignatures(truckVisitId)
    return data
  }
}

const carrierVisitsService = new CarrierVisitsService()

export default carrierVisitsService
