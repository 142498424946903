import { Room, Warehouse } from '@mui/icons-material'
import { Button, Card, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import {
  GeneralCargoInventoryOccupancyDto,
  GeneralCargoStockOccupancyDamageDto,
} from '@storage/app/api'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import _ from 'lodash'
import GeneralCargoStockOccupancyItems from './GeneralCargoStockOccupancyItems'
import PackagingInfoCard from './PackagingInfoCard'

interface Props {
  generalCargoInventoryOccupancy: GeneralCargoInventoryOccupancyDto
  onSeeDamageDetails: (damages: GeneralCargoStockOccupancyDamageDto[]) => void
  onSelect?: (occupancy: GeneralCargoInventoryOccupancyDto) => void
}

const GeneralCargoStockOccupancyCard = ({
  generalCargoInventoryOccupancy,
  onSeeDamageDetails,
  onSelect,
}: Props) => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()

  const cargoItemProperties = generalCargoInventoryOccupancy.properties.filter(
    e => e.unitId.length > 0,
  )

  const getDamageAmount = () => {
    const damageAmount = _(generalCargoInventoryOccupancy.damages).sumBy(x => x.quantity)

    return generalCargoInventoryOccupancy.quantity >= damageAmount
      ? damageAmount
      : generalCargoInventoryOccupancy.quantity
  }

  return (
    <Box component={Card}>
      <Stack direction='row' display={'flex'} justifyContent={'space-between'}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            p: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: theme.palette.primary.lighter,
              borderRadius: theme.customRadius.medium,
              py: 1,
              px: 2,
            }}
          >
            <Typography variant='subtitle2' color={theme.palette.primary.dark}>
              {t('quantity', 'Quantity')}
            </Typography>
            <Typography variant='h5' fontWeight='bold' color={theme.palette.primary.dark}>
              {generalCargoInventoryOccupancy.quantity}
            </Typography>
          </Box>

          <Box>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mb: 1 }}>
              <Warehouse color='secondary' />
              <Typography variant='subtitle2'>
                {generalCargoInventoryOccupancy.generalCargoArea}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              <Room color='secondary' />
              <Typography variant='subtitle2'>
                {generalCargoInventoryOccupancy.generalCargoLocation}
              </Typography>
            </Box>
          </Box>

          <Stack ml={4} justifyContent='center' sx={{ minWidth: '10rem' }}>
            {generalCargoInventoryOccupancy.damages.length > 0 && (
              <>
                <Typography
                  variant='subtitle1'
                  color='error'
                  sx={{ display: 'flex', justifyContent: 'center' }}
                >
                  {t('totalDamaged', 'Total damaged')}: {getDamageAmount()}
                </Typography>
                <Button
                  onClick={() => onSeeDamageDetails(generalCargoInventoryOccupancy.damages)}
                  color='secondary'
                >
                  {t('seeDetails', 'See details')}
                </Button>
              </>
            )}
          </Stack>

          {generalCargoInventoryOccupancy.package && (
            <PackagingInfoCard data={generalCargoInventoryOccupancy.package} />
          )}

          {!!cargoItemProperties.length && (
            <GeneralCargoStockOccupancyItems items={cargoItemProperties} />
          )}
        </Box>

        {onSelect && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              p: 2,
            }}
          >
            <Button
              variant='text'
              color='secondary'
              onClick={() => onSelect(generalCargoInventoryOccupancy)}
            >
              {t('select', 'Select')}
            </Button>
          </Box>
        )}
      </Stack>
    </Box>
  )
}

export default GeneralCargoStockOccupancyCard
