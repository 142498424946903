import { Button, Typography } from '@mui/material'
import { useOperationsStore } from '@operations/AppProvider'
import { FinishJobDto } from '@operations/features/equipmentOperator/models/finish-job.model'
import {
  EquipmentOperatorStepperUIStore,
  JobSteps,
} from '@operations/features/equipmentOperator/stores/equipment-operator-stepper.ui-store'
import { useTranslate } from '@tolgee/react'
import { ConfirmationDialog } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'

interface Props {
  uiStore: EquipmentOperatorStepperUIStore
  onConfirm: (currentJob: FinishJobDto) => Promise<void>
}
export const ConfirmStepper = observer(({ uiStore, onConfirm }: Props) => {
  const { t } = useTranslate()
  const { appStore } = useOperationsStore()

  const isValid = () => {
    switch (uiStore.activeStep?.step) {
      case JobSteps.editDestination:
        return uiStore.selectedYardBlock //TODO: check with storage

      case JobSteps.plugReefer:
        return uiStore.isPluggedIn !== undefined

      case JobSteps.assignEquipment:
        return uiStore.assignedEquipmentId

      case JobSteps.selectContainer:
        return uiStore.selectedContainerNumber

      case JobSteps.confirmWeight:
        return uiStore.grossWeight

      case JobSteps.confirmWagonNumber:
        return uiStore.railcarTrackPositionId

      case JobSteps.confirmPickup:
      case JobSteps.checkInformation:
        return true
    }
  }

  const handleConfirm = async () => {
    if (!isValid()) {
      uiStore.setHasError(true)
      return
    }

    if (uiStore.hasNextStep) {
      uiStore.nextStep()
      return
    }

    confirm()
  }

  const confirm = async () => {
    if (uiStore.isConfirmationDialogOpen) {
      uiStore.toggleConfirmationDialog()
    }

    const currentJob = uiStore.getCurrentFinishJobDto()

    if (!currentJob) {
      return
    }

    uiStore.equipmentOperatorUIStore.hideJob(currentJob.workInstructionId)

    appStore.showAlertForCancellableRequest(
      'success',
      `${uiStore.containerNumber} ${t('isBeingConfirmed', 'is being confirmed')}`,
      async () => await onConfirm(currentJob),
      undefined,
      () => {
        uiStore.equipmentOperatorUIStore.showJob(currentJob.workInstructionId)
        uiStore.cancelStartJob(currentJob.workInstructionId)
      },
    )

    uiStore.closeStepper()
  }

  const handleToggleConfirmation = () => {
    uiStore.toggleConfirmationDialog()
  }

  return (
    <>
      <Button
        variant='contained'
        color='primary'
        onClick={handleConfirm}
        data-cy='confirm-container-job-stepper-btn'
      >
        {uiStore.hasNextStep ? t('next', 'Next') : t('confirm', 'Confirm')}
      </Button>

      <ConfirmationDialog
        open={uiStore.isConfirmationDialogOpen}
        title={t('confirmJob', 'Confirm Job?')}
        message={t('aboutToConfirmThis', 'You are about to confirm this:')}
        primaryActionText={t('confirm', 'Confirm')}
        closeLabel={t('keepEditing', 'Keep editing')}
        maxWidth='xs'
        blockClickOutside
        onConfirm={confirm}
        onClose={handleToggleConfirmation}
      >
        <Typography marginTop={2} variant='body1'>
          {t(
            'pluggedContainerOutboundConfirmation',
            'When the job is confirmed the reefer will be unplugged automatically',
          )}
        </Typography>
      </ConfirmationDialog>
    </>
  )
})
