import { CreatePierDto, PierDto, UpdatePierDto } from '@storage/app/api'
import { PierFormProfile } from './pier-form.profile'

const mapFormValuesToCreateDto = ({ name }: PierFormProfile): CreatePierDto => ({
  name,
})

const mapFormValuesToUpdateDto = ({ id, name }: PierFormProfile): UpdatePierDto => ({
  id: id!,
  name,
})

const mapDtoToFormValues = ({ id, name }: PierDto): PierFormProfile => ({
  name,
  id,
})

export const PierFormMapper = {
  mapFormValuesToCreateDto,
  mapFormValuesToUpdateDto,
  mapDtoToFormValues,
}
