import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'
import {
  DataGrid,
  GridCellEditStopParams,
  GridColDef,
  GridRenderCellParams,
  MuiEvent,
} from '@mui/x-data-grid'
import { WeightMeasurementUnit } from '@planning/app/api'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'

export type StrippingCommodityDto = {
  cargoItemId?: number
  customerId: number
  customerName: string
  commodityId: number
  commodityName: string
  referenceNumber?: string | null
  lotNumber?: string | null
  packageId?: number | null
  packageName?: string | null
  weightUnit?: WeightMeasurementUnit
  plannedAmount: number
}

interface IProps {
  strippingOrderId?: number | null
  commodities: StrippingCommodityDto[]
  onClickDelete: (data: any) => void
  renderReservations?: (orderId: number, plannedAmout: number, cargoItemId: number) => JSX.Element
}

export const StrippingOrderSelectedCommoditiesTable = observer(
  ({ strippingOrderId, commodities, onClickDelete, renderReservations }: IProps) => {
    const { t } = useTranslate()

    const renderCellWithEmptyIndicator = (params: GridRenderCellParams) => {
      return params.value ? params.value : '-'
    }

    const columns: GridColDef[] = [
      {
        field: 'customerName',
        headerName: t('customer', 'Customer'),
        flex: 2,
        renderCell: renderCellWithEmptyIndicator,
      },
      {
        field: 'commodityName',
        headerName: t('commodity', 'Commodity'),
        flex: 2,
        renderCell: renderCellWithEmptyIndicator,
      },
      {
        field: 'referenceNumber',
        editable: false,
        headerName: t('refNumber', 'Ref. Number'),
        flex: 1,
        renderCell: renderCellWithEmptyIndicator,
      },
      {
        field: 'lotNumber',
        headerName: t('lotNumber', 'Lot number'),
        flex: 1,
        renderCell: renderCellWithEmptyIndicator,
      },
      {
        field: 'plannedAmount',
        editable: false,
        headerName: t('quantity', 'Quantity'),
        flex: 1,
        renderCell: renderCellWithEmptyIndicator,
      },
    ]

    if (renderReservations)
      columns.push({
        field: 'planLocation',
        headerName: t('planLocation', 'Plan Location'),
        flex: 2,
        renderCell: (params: GridRenderCellParams) => {
          if (strippingOrderId && params.row.id) {
            return renderReservations(strippingOrderId, params.row.plannedAmount, params.row.id)
          } else {
            return '-'
          }
        },
      })

    columns.push({
      field: 'actions',
      headerName: t('actions', 'Actions'),
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <IconButton onClick={() => onClickDelete(params.row)}>
          <DeleteIcon />
        </IconButton>
      ),
      width: 100,
      align: 'center',
      headerAlign: 'center',
    })

    const rows = commodities.map(commodity => ({
      id: `${commodity.customerId}_${commodity.commodityId}_${commodity.lotNumber}_${commodity.packageId}`,
      cargoItemId: commodity.cargoItemId,
      ...commodity,
    }))

    return (
      <div style={{ width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          onCellEditStop={(params: GridCellEditStopParams, event: MuiEvent) => {
            console.log(params, event) // TODO: Do we need this log?
          }}
        />
      </div>
    )
  },
)
