import { createApiClient } from '@planning/app/http-client'
import { InternalApiApi } from '@planning/app/reset-data-api'

class PlanningResetDataService {
  httpClient = createApiClient(InternalApiApi)

  async resetData() {
    await this.httpClient.resetData()
  }

  async saveSnapshot() {
    await this.httpClient.saveDemoData()
  }
}

const planningResetDataService = new PlanningResetDataService()

export default planningResetDataService
