import { Box } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { FC } from 'react'
import { CustomAlert } from '../TallymanV2/Components/CustomAlert'

export const NoOrdersHint: FC<{ show?: boolean }> = ({ show }) => {
  const { t } = useTranslate()

  return show ? (
    <Box mb={'1rem'}>
      <CustomAlert
        severity='error'
        message={t(
          'visitMustContainerADropOffOrPickUp',
          'Visit must contain a drop off or pick up',
        )}
      />
    </Box>
  ) : (
    <></>
  )
}
