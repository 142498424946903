import { OrderResponseDto } from '@planning/app/api'
import { IOrderItem } from '@planning/rt-stores/order/OrderItem'
import { IFilterDelegate } from '@planning/stores/PaginatedLocalStore'

export const simpleSearchFilterDelegate =
  (filterableProperties: string[]): IFilterDelegate<IOrderItem | OrderResponseDto> =>
  (
    filter: string,
    item: IOrderItem | OrderResponseDto,
    _?: boolean,
    __?: Date,
    ___?: Date,
    filterStatus?: string,
  ) => {
    const order = 'data' in item ? item.data : item

    if (filterStatus && order.status !== filterStatus) {
      return false
    }
    if (filter !== '')
      return filterableProperties
        .map(p => p as keyof OrderResponseDto)
        .some(p => {
          const prop = order[p]
          if (typeof prop === 'string')
            return prop?.toLocaleLowerCase().includes(filter.toLocaleLowerCase())

          return false
        })

    return true
  }
