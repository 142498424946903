import { ContainerDto, ContainerType } from '@planning/app/api'
import { containerService } from '@planning/services'
import { SearchAutocompleteStore } from '@planning/stores/SearchAutocompleteStore'
import { action, makeObservable, observable } from 'mobx'

const fetch = async (
  filter: string,
  isEmpty?: boolean,
  hasServiceOrders?: boolean,
  canAssignServiceOrders?: boolean,
  type?: ContainerType,
) =>
  await containerService.getContainersByPartialNumber(
    filter,
    isEmpty,
    hasServiceOrders,
    canAssignServiceOrders,
    type,
  )

export class ContainerAutocompleteStore extends SearchAutocompleteStore<ContainerDto> {
  isEmpty?: boolean
  hasServiceOrders?: boolean
  canAssignServiceOrders?: boolean
  type?: ContainerType

  constructor() {
    super((query: string) =>
      fetch(query, this.isEmpty, this.hasServiceOrders, this.canAssignServiceOrders, this.type),
    )
    makeObservable(this, {
      isEmpty: observable,
      hasServiceOrders: observable,
      canAssignServiceOrders: observable,
      type: observable,
      setIsEmpty: action,
      setHasServiceOrders: action,
      setCanAssignServiceOrders: action,
      setType: action,
      reset: action,
    })
  }

  setType = (type?: ContainerType) => (this.type = type)
  setIsEmpty = (isEmpty?: boolean) => (this.isEmpty = isEmpty)
  setHasServiceOrders = (hasServiceOrders?: boolean) => (this.hasServiceOrders = hasServiceOrders)
  setCanAssignServiceOrders = (canAssingServiceOrders?: boolean) =>
    (this.canAssignServiceOrders = canAssingServiceOrders)

  reset = () => {
    this.setFilter('')
    this.setValue()
    this.items = []
    this.setIsEmpty()
    this.setHasServiceOrders()
    this.setCanAssignServiceOrders()
    this.setType()
  }
}
