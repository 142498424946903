import { ContainerTurnoversReportMode } from '@billing/app/models/container-turnovers-report-mode.enum'
import { action, makeObservable, observable } from 'mobx'
import {
  CTReportFieldsFormProfile,
  defaultValues,
} from '../forms/ct-report-fields-form/ct-report-fields-form.profile'

export class ContainerTurnoversUIStore {
  reportMode: ContainerTurnoversReportMode = ContainerTurnoversReportMode.Standard
  fieldsToExport: CTReportFieldsFormProfile = defaultValues

  constructor() {
    makeObservable(this, {
      reportMode: observable,
      fieldsToExport: observable,
      setReportMode: action,
      setFieldsToExport: action,
    })
  }

  setReportMode(reportMode: ContainerTurnoversReportMode): void {
    this.reportMode = reportMode
  }

  setFieldsToExport(fieldsToExport: CTReportFieldsFormProfile) {
    this.fieldsToExport = fieldsToExport
  }

  public resetData(): void {
    this.setReportMode(ContainerTurnoversReportMode.Standard)
    this.setFieldsToExport(defaultValues)
  }
}
