import { Button, CircularProgress, SxProps, Theme } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { observer } from 'mobx-react-lite'
import { FC, MouseEventHandler } from 'react'

interface Props {
  children: string
  variant?: 'text' | 'outlined' | 'contained'
  size?: 'small' | 'medium' | 'large'
  type?: 'submit' | 'reset' | 'button'
  disabled?: boolean
  sx?: SxProps<Theme>
  fullWidth?: boolean
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'
  onClick?: MouseEventHandler<any>
}

export const LoaderButton: FC<Props> = observer(
  ({ children, size, variant, type, sx, onClick, disabled, fullWidth, color }: Props) => {
    const { appViewStore } = usePlanningStore()

    const isLoading = appViewStore.isLoading
    return (
      <Button
        sx={sx}
        startIcon={isLoading && <CircularProgress size='1rem' />}
        variant={variant}
        size={size}
        onClick={onClick}
        type={type}
        fullWidth={fullWidth}
        disabled={isLoading || disabled}
        color={color}
      >
        {children}
      </Button>
    )
  },
)
