import { TenantConfigDto } from '@storage/app/api'
import { computed, makeObservable, observable, runInAction } from 'mobx'
import { tenantConfigService } from '@storage/services'

export class TenantConfigStore {
  tenantConfig?: TenantConfigDto

  constructor() {
    makeObservable(this, {
      tenantConfig: observable,
      skipYardPlanning: computed,
      isBayRowUniqueIdentificationModeActivated: computed,
    })
  }

  async loadAppConfigs() {
    const data = await tenantConfigService.loadAppConfigs()
    runInAction(() => {
      this.tenantConfig = data
    })
  }

  public get skipYardPlanning() {
    return this.tenantConfig?.skipYardPlanning ?? false
  }

  public get isBayRowUniqueIdentificationModeActivated() {
    return this.tenantConfig?.bayRowUniqueIdentificationMode ?? false
  }
}
