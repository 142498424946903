import { observer } from 'mobx-react-lite'
import { EquipmentPlanningHeader } from '../components/EquipmentPlanningHeader/EquipmentPlanningHeader'
import { EquipmentPlanningInfoUIStore } from '../stores/equipment-planning-info-ui-store'

interface Props {
  uiStore: EquipmentPlanningInfoUIStore
}

export const EquipmentPlanningHeaderContainer = observer(({ uiStore: { vesselVisit } }: Props) => {
  return <EquipmentPlanningHeader vesselVisit={vesselVisit} />
})
