import { Box, Tooltip } from '@mui/material'
import { FilterIcon } from '../../icons'
import { IconButton } from '../button/IconButton'
import { ButtonSize } from '../button/button.model'
import { FilterActionsProps } from './FilterActions'
import { FilterPopUp, FilterProps } from './FilterPopUp'

export interface AdvancedFilterProps extends FilterProps, FilterActionsProps {
  filterTooltip: string
  filterSize?: ButtonSize
  onOpenFilter: () => void
}
export const AdvancedFilter = ({
  filterTooltip,
  filterSize,
  open,
  children,
  width,
  filterActionLabel,
  resetActionLabel,
  formId,
  popUpMarginTop,
  onFilter,
  onClose,
  onReset,
  onOpenFilter,
}: AdvancedFilterProps) => {
  return (
    <Box>
      <Tooltip title={filterTooltip}>
        <IconButton size={filterSize ?? 'medium'} onClick={onOpenFilter}>
          <FilterIcon fontSize={filterSize ?? 'medium'} />
        </IconButton>
      </Tooltip>
      <Box width='100%'>
        <FilterPopUp
          open={open}
          width={width}
          filterActionLabel={filterActionLabel}
          resetActionLabel={resetActionLabel}
          formId={formId}
          popUpMarginTop={popUpMarginTop}
          onFilter={onFilter}
          onClose={onClose}
          onReset={onReset}
        >
          {children}
        </FilterPopUp>
      </Box>
    </Box>
  )
}
