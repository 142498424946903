import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { Stack } from '@mui/system'
import { HandlingDirection } from '@storage/app/api'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { PlusIcon, useMinimalsTheme } from '@tom-ui/ui'
import { DamageReport, DamageReportCard, damageReportStore } from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'
import { GeneralCargoStorageTrackerContext } from '../interfaces/general-cargo-storage-tracker-interface'
import { OccupancyItem, StockOccupancy, StockTrackingMethod } from '../interfaces/occupancy-item'
import GeneralCargoIdentifiableItems from './GeneralCargoIdentifiableItems'
import QuantityInputField from './QuantityInputField'

interface GeneralCargoLocationContentProps {
  handlingDirection: HandlingDirection
  context: GeneralCargoStorageTrackerContext
  occupancyItem: OccupancyItem
}
const GeneralCargoLocationContent = observer(
  ({ handlingDirection, context, occupancyItem }: GeneralCargoLocationContentProps) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()
    const { generalCargoStorageTrackerUIStore } = useStores()

    const quantityFieldLabel =
      handlingDirection === 'Inbound'
        ? t('amountToDropOff', 'Amount to drop off')
        : handlingDirection === 'Outbound'
          ? t('amountToPickUp', 'Amount to pick up')
          : ''

    const isGeneralCargoPositionEditable =
      context === 'occupancy' ? (occupancyItem as StockOccupancy).isNewOccupancy : true

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const updatedQuantity = parseInt(event.target.value, 10)

      if (!isNaN(updatedQuantity)) {
        generalCargoStorageTrackerUIStore.updateOccupancyItem(
          occupancyItem.id,
          'newQuantity',
          updatedQuantity,
        )
      } else {
        generalCargoStorageTrackerUIStore.updateOccupancyItem(occupancyItem.id, 'newQuantity', 0)
      }
    }

    const handleIncrease = () => {
      generalCargoStorageTrackerUIStore.updateOccupancyItem(
        occupancyItem.id,
        'newQuantity',
        occupancyItem.newQuantity + 1,
      )
    }

    const handleDecrease = () => {
      if (occupancyItem.newQuantity && occupancyItem.newQuantity > 0) {
        generalCargoStorageTrackerUIStore.updateOccupancyItem(
          occupancyItem.id,
          'newQuantity',
          occupancyItem.newQuantity - 1,
        )
      }
    }

    const handleOnDamage = (damage?: DamageReport) => {
      generalCargoStorageTrackerUIStore.updateOccupancyItem(occupancyItem.id, 'isDamaged', !!damage)
      generalCargoStorageTrackerUIStore.updateOccupancyItem(
        occupancyItem.id,
        'damageDescription',
        damage?.description ?? '',
      )
    }

    const handleUploadDamageImageFiles = async (damageImageFiles: File[]) => {
      const filesToUpload = await generalCargoStorageTrackerUIStore.resolveFilesToUpload(
        occupancyItem.id,
        damageImageFiles,
      )
      const filesUploadResult =
        await generalCargoStorageTrackerUIStore.uploadDamageFiles(filesToUpload)

      generalCargoStorageTrackerUIStore.addOrUpdateFiles(occupancyItem.id, filesUploadResult)

      generalCargoStorageTrackerUIStore.updateOccupancyItem(
        occupancyItem.id,
        'damageImagePaths',
        generalCargoStorageTrackerUIStore.getAllPathToFiles(occupancyItem.id),
      )
    }

    const handleOnAreaChange = (event: SelectChangeEvent) => {
      generalCargoStorageTrackerUIStore.updateOccupancyItem(
        occupancyItem.id,
        'areaName',
        event.target.value,
      )
      // set default value for location
      generalCargoStorageTrackerUIStore.updateOccupancyItem(
        occupancyItem.id,
        'locationName',
        generalCargoStorageTrackerUIStore.areaLocationsAsOptions(occupancyItem.areaName)[0].value,
      )
    }

    const handleOnLocationChange = (event: SelectChangeEvent) => {
      generalCargoStorageTrackerUIStore.updateOccupancyItem(
        occupancyItem.id,
        'locationName',
        event.target.value,
      )
    }

    return (
      <>
        <Stack gap={theme.customSpacing.xl}>
          {/* Location input section */}
          <Stack direction='row' gap={theme.customSpacing.s}>
            {generalCargoStorageTrackerUIStore.areasAsOptions.length && (
              <FormControl fullWidth sx={{ m: 0 }}>
                <InputLabel id='general-cargo-area'>{t('generalCargoArea', 'Area')}</InputLabel>
                <Select
                  labelId='general-cargo-area'
                  value={occupancyItem.areaName}
                  label={t('generalCargoArea', 'Area')}
                  onChange={handleOnAreaChange}
                  disabled={!isGeneralCargoPositionEditable}
                >
                  {generalCargoStorageTrackerUIStore.areasAsOptions.map(area => (
                    <MenuItem key={area.value} value={area.value}>
                      {area.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {generalCargoStorageTrackerUIStore.areasAsOptions.length && (
              <FormControl fullWidth sx={{ m: 0 }}>
                <InputLabel id='general-cargo-area-location'>
                  {t('generalCargoAreaLocation', 'Location')}
                </InputLabel>
                <Select
                  labelId='general-cargo-area-location'
                  value={occupancyItem.locationName}
                  label={t('generalCargoAreaLocation', 'Location')}
                  onChange={handleOnLocationChange}
                  disabled={!isGeneralCargoPositionEditable}
                >
                  {generalCargoStorageTrackerUIStore
                    .areaLocationsAsOptions(occupancyItem.areaName)
                    .map(area => (
                      <MenuItem key={area.value} value={area.value}>
                        {area.label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
          </Stack>

          {/* Identifiable items section */}
          {generalCargoStorageTrackerUIStore.getStockTrackingMethod(handlingDirection) ===
            StockTrackingMethod.IDENTIFIABLE_ITEMS && (
            <GeneralCargoIdentifiableItems
              plannedItems={generalCargoStorageTrackerUIStore.plannedIdentifiableItems}
              occupancyItemId={occupancyItem.id}
              handlingDirection={handlingDirection}
            />
          )}

          {/* Quantity input section */}
          {generalCargoStorageTrackerUIStore.getStockTrackingMethod(handlingDirection) ===
            StockTrackingMethod.QUANTITY && (
            <QuantityInputField
              label={quantityFieldLabel}
              value={occupancyItem.newQuantity}
              onChange={handleOnChange}
              OnIncrease={handleIncrease}
              OnDecrease={handleDecrease}
            />
          )}

          {generalCargoStorageTrackerUIStore.activeOccupancyItem !== undefined &&
            generalCargoStorageTrackerUIStore.activeOccupancyItem.properties.length > 0 &&
            handlingDirection === 'Outbound' && (
              <GeneralCargoIdentifiableItems
                plannedItems={generalCargoStorageTrackerUIStore.plannedIdentifiableItems}
                occupancyItemId={occupancyItem.id}
                handlingDirection={handlingDirection}
              />
            )}

          {/* Damage section */}
          {context === 'occupancy' && handlingDirection === 'Inbound' && (
            <Stack sx={{ borderTop: `${theme.palette.grey[400]} solid 1px` }}>
              <Stack
                sx={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: theme.customSpacing.xs,
                  height: theme.customSpacing.xxl,
                  borderBottom: occupancyItem.isDamaged
                    ? `${theme.palette.grey[400]} solid 1px`
                    : undefined,
                }}
              >
                <Typography variant='subtitle2'>{t('reportDamage', 'Report damage')}</Typography>

                {!occupancyItem.isDamaged && (
                  <IconButton
                    onClick={() =>
                      damageReportStore.openDialog(filledDamage => handleOnDamage(filledDamage))
                    }
                  >
                    <PlusIcon />
                  </IconButton>
                )}
              </Stack>

              {occupancyItem.isDamaged && (
                <DamageReportCard
                  onUpdateDamagedFiles={files => handleUploadDamageImageFiles(files)}
                  damageReport={{
                    description: occupancyItem.damageDescription,
                    damageImageFiles: generalCargoStorageTrackerUIStore.getFiles(occupancyItem.id),
                  }}
                  onRemove={() => {
                    handleUploadDamageImageFiles([])
                    handleOnDamage(undefined)
                  }}
                  onEdit={() =>
                    damageReportStore.openDialog(filledDamage => handleOnDamage(filledDamage), {
                      description: occupancyItem.damageDescription,
                    })
                  }
                  hasActions
                />
              )}
            </Stack>
          )}
        </Stack>
      </>
    )
  },
)

export default GeneralCargoLocationContent
