import { FC } from 'react'

export const IconEmptyList: FC<{ zoom?: number }> = ({ zoom }) => {
  return (
    <svg
      width='129'
      height='128'
      viewBox='0 0 129 128'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ transform: `scale(${(zoom ?? 100) / 100})` }}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M101.607 62.694C107.679 62.58 113.668 65.862 117.011 71.84C121.881 80.558 119.144 91.424 110.982 96.298C102.821 101.162 92.3246 98.194 87.4683 89.484C82.5985 80.766 85.3563 69.9 93.5173 65.026C96.0618 63.508 98.839 62.748 101.607 62.694ZM101.424 55.164C97.4756 55.236 93.496 56.3 89.8577 58.476C78.1922 65.436 74.3134 81.092 81.1207 93.268C87.4742 104.654 101.042 109.196 112.304 104.06L120.093 117.996C121.101 119.81 123.345 120.436 125.102 119.394C126.861 118.34 127.455 116.028 126.446 114.218L118.497 99.978C127.061 92.164 129.344 78.778 123.363 68.06C118.689 59.684 110.111 55.006 101.424 55.164Z'
        fill='#919EAB'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.1418 8.02734C8.98079 8.02734 2.06104 14.3613 2.06104 22.4653V91.7473C2.06104 99.8513 8.98079 106.185 17.1418 106.185H78.3335C82.2433 106.185 82.2433 100.131 78.3335 100.131H17.1418C11.8375 100.131 7.91994 96.2893 7.91994 91.7473V30.8413H100.051V48.6333C100.051 52.6633 105.918 52.6633 105.918 48.6333V22.4653C105.918 14.3613 98.99 8.02734 90.8271 8.02734H17.1418Z'
        fill='#919EAB'
      />
      <path
        d='M44.1113 50.2468C40.8162 50.2468 39.193 54.3828 41.5668 56.7488L48.8182 64.2368L41.5668 71.7268C40.1316 73.2068 40.1219 75.6008 41.5571 77.0808C42.9903 78.5588 45.3137 78.5588 46.7469 77.0808L53.9906 69.5908L61.2342 77.0808C62.6693 78.5588 64.9908 78.5588 66.4259 77.0808C67.8611 75.6008 67.8611 73.2068 66.4356 71.7268L59.1823 64.2368L66.4356 56.7488C68.8482 54.3408 67.1105 50.1228 63.7593 50.2468C62.7973 50.2788 61.8994 50.6948 61.2322 51.4048L53.9886 58.8848L46.745 51.4048C46.0623 50.6628 45.1023 50.2468 44.1113 50.2468Z'
        fill='#919EAB'
      />
    </svg>
  )
}
export default IconEmptyList
