import { createSvgIcon } from '@mui/material'

export const MovesIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='16' viewBox='0 0 20 16' fill='none'>
    <path
      d='M15.2819 10.4437H1.51683C0.829206 10.4437 0.266602 11.0263 0.266602 11.7384C0.266602 12.4505 0.829206 13.0331 1.51683 13.0331H15.2819V15.3507C15.2819 15.9333 15.957 16.2181 16.3446 15.8038L19.8202 12.1916C20.0578 11.9326 20.0578 11.5313 19.8202 11.2723L16.3446 7.6601C15.957 7.24579 15.2819 7.54357 15.2819 8.11325V10.4437Z'
      fill='currentColor'
    />
    <path
      d='M4.71617 2.97705H18.4812C19.1688 2.97705 19.7314 3.55967 19.7314 4.27176C19.7314 4.98385 19.1688 5.56647 18.4812 5.56647H4.71617V7.884C4.71617 8.46662 4.04104 8.75146 3.65347 8.33715L0.17783 4.72491C-0.0597153 4.46597 -0.0597153 4.06461 0.17783 3.80567L3.65347 0.193423C4.04104 -0.220884 4.71617 0.0768983 4.71617 0.646571V2.97705Z'
      fill='currentColor'
    />
  </svg>,
  'MovesIcon',
)
