import { Box, Chip, ListItemText, MenuItem, Paper, Typography } from '@mui/material'
import { EquipmentType, JobDto } from '@operations/app/api'
import {
  getJobAmountByType,
  getJobAmountByTypeAndSize,
  JobTypes,
} from '@operations/app/models/operator-pages'
import { isCraneEquipmentType } from '@operations/features/equipmentOperator/utils'
import { useTranslate } from '@tolgee/react'
import { ChipWithOptions, TrainIcon, TruckIcon, useMinimalsTheme, VesselIcon } from '@tom-ui/ui'
import { isJobNotPlanned } from '../../stores/equipment-operator.ui-store'

interface Props {
  jobs: JobDto[]
  filteredJobs: JobDto[]
  notPlanned?: boolean
  nonNumeric?: boolean
  jobType: JobTypes
  selectedContainerSize?: number
  selectedEquipmentType: EquipmentType
  nonNumericChange: (value?: boolean) => void
  notPlannedChange: (value?: boolean) => void
  containerSizeSelectionChange: (value?: number) => void
  navChange: (newValue: JobTypes) => void
}

interface JobTypeOption {
  value: JobTypes
  icon?: JSX.Element
  label?: string
}

interface SizeOption {
  value?: number
  label: string
}

export const JobsNavigationV2 = ({
  jobs,
  filteredJobs,
  jobType,
  nonNumeric,
  notPlanned,
  selectedEquipmentType,
  selectedContainerSize,
  navChange,
  notPlannedChange,
  nonNumericChange,
  containerSizeSelectionChange,
}: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const jobTypeOptions: JobTypeOption[] = [
    {
      value: JobTypes.all,
      label: t('all', 'All'),
    },
    {
      value: JobTypes.vessel,
      icon: <VesselIcon />,
    },
    {
      value: JobTypes.truck,
      icon: <TruckIcon />,
    },
    {
      value: JobTypes.train,
      icon: <TrainIcon />,
    },
    {
      label: t('internal', 'Internal'),
      value: JobTypes.internal,
    },
    {
      label: t('service', 'Service'),
      value: JobTypes.service,
    },
  ]

  const sizeOptions: SizeOption[] = [
    {
      value: undefined,
      label: 'All',
    },
    {
      value: 20,
      label: '20’',
    },
    {
      value: 40,
      label: '40’',
    },
    {
      value: 45,
      label: '45’',
    },
  ]

  const getNotPlannedAmount = () => {
    return jobs.filter(x => isJobNotPlanned(x)).length
  }

  const getNonNumericAmount = () => {
    return filteredJobs.filter(x => !x.container?.containerNumber).length
  }

  const jobTypeOptionsElement = jobTypeOptions.map(option => (
    <Chip
      sx={{
        borderRadius: 1,
      }}
      key={option.value}
      label={`${option.label ?? ''} ${getJobAmountByType(
        option.value as JobTypes,
        filteredJobs.filter(x => !nonNumeric || !x.container?.containerNumber),
      )}`}
      icon={option.icon ? option.icon : undefined}
      color={option.value === jobType ? 'primary' : undefined}
      onClick={() => navChange(option.value as JobTypes)}
      data-cy={`operator-select-carrier-type-${option.value}-option`}
    />
  ))

  const sizeOptionsElement = (
    <Box position='relative'>
      <Paper
        elevation={2}
        sx={{
          position: 'absolute',
          top: 0,
          width: '150px',
          zIndex: theme.zIndex.fab,
          borderRadius: theme.customRadius.medium,
          overflow: 'hidden',
        }}
      >
        {sizeOptions.map(option => (
          <MenuItem
            key={option.value ?? 0}
            selected={option.value === selectedContainerSize}
            value={option.value}
            onClick={() => {
              containerSizeSelectionChange(option.value as number)
            }}
          >
            <ListItemText primary={option.label} primaryTypographyProps={{ variant: 'body2' }} />
            <Typography variant='body2' color='secondary'>
              {getJobAmountByTypeAndSize(
                jobType,
                filteredJobs.filter(x => !nonNumeric || !x.container?.containerNumber),
                option.value,
              )}
            </Typography>
          </MenuItem>
        ))}
      </Paper>
    </Box>
  )

  return (
    <>
      <Chip
        label={`${t('notPlanned', 'Not planned')} ${getNotPlannedAmount()}`}
        color={notPlanned ? 'primary' : undefined}
        onClick={() => notPlannedChange(true)}
        onDelete={notPlanned ? () => notPlannedChange(false) : undefined}
      />
      {notPlanned && (
        <Chip
          label={`${t('nonNumerics', 'Non numerics')} ${getNonNumericAmount()}`}
          color={nonNumeric ? 'primary' : undefined}
          onClick={() => nonNumericChange(true)}
          onDelete={nonNumeric ? () => nonNumericChange(false) : undefined}
        />
      )}

      {!isCraneEquipmentType(selectedEquipmentType) && jobTypeOptionsElement}

      <ChipWithOptions
        options={sizeOptionsElement}
        value={
          !selectedContainerSize
            ? undefined
            : sizeOptions.find(x => x.value === selectedContainerSize)?.label
        }
        label={t('size', 'Size')}
      />
    </>
  )
}
