export const getEnvName = (): string => {
  const selectedEnv = import.meta.env.VITE_SELECTED_ENV
  if (selectedEnv) {
    return selectedEnv
  }

  const currentHost = window.location.hostname
  if (currentHost.includes('.stage.')) {
    return 'stage'
  }

  if (currentHost.includes('.demo.')) {
    return 'demo'
  }

  if (currentHost.includes('.dev.')) {
    return 'dev'
  }

  if (currentHost.includes('localhost') || currentHost.includes('127.0.0.1')) {
    return 'local'
  }

  //TOLATER add prod
  return 'unknown'
}
