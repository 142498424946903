import { withAuthenticationRequired } from '@auth0/auth0-react'
import { useOperationsStore } from '@operations/AppProvider'
import { ReeferMonitoringContainer } from '@operations/features/reeferMonitoring/containers/ReeferMonitoringContainer'
import { ReeferMonitoringDrawerContainer } from '@operations/features/reeferMonitoring/containers/ReeferMonitoringDrawerContainer'
import { useTranslate } from '@tolgee/react'
import { FC, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'

const ReeferMonitoring: FC = withAuthenticationRequired(() => {
  const { t } = useTranslate()

  const { reeferTemperatureStore } = useOperationsStore()

  useEffect(() => {
    reeferTemperatureStore.loadInProgressCoolingOrders()
  }, [reeferTemperatureStore])

  return (
    <>
      <Helmet>
        <title>{t('reeferMonitoringTomOperation', 'Reefer Monitoring - Tom Operation')}</title>
      </Helmet>

      <ReeferMonitoringContainer />
      <ReeferMonitoringDrawerContainer />
    </>
  )
})

export default ReeferMonitoring
