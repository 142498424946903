import { Box, SxProps } from '@mui/material'
import { camelToKebab } from '@operations/utils/camel-to-kebab'
import { ReactNode, useEffect, useState } from 'react'
import './Stick.css'

interface Props {
  children: ReactNode
  overrideStickStyle?: SxProps
  display?: boolean
}

export const Sticky = ({ children, overrideStickStyle, display }: Props) => {
  const [sidebarTop, setSidebarTop] = useState<number | undefined>(undefined)
  const stickyClass = 'is-sticky'
  const stickyElement = `.stick-element`

  useEffect(() => {
    const element = document.querySelector(stickyElement)!.getBoundingClientRect()
    setSidebarTop(element.top)
  }, [stickyElement, display])

  useEffect(() => {
    if (!sidebarTop) return
    window.addEventListener('scroll', handleIsSticky)
    return () => {
      window.removeEventListener('scroll', handleIsSticky)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebarTop])

  const handleIsSticky = () => {
    const element = document.querySelector(stickyElement) as HTMLElement
    const scrollTop = window.scrollY

    if (scrollTop >= sidebarTop!) {
      element.classList.add(stickyClass)

      const styles: string[] = []
      for (const key in overrideStickStyle) {
        if (Object.prototype.hasOwnProperty.call(overrideStickStyle, key)) {
          styles.push(`${camelToKebab(key)}: ${overrideStickStyle[key as keyof SxProps]}`)
        }
      }

      element.setAttribute('style', styles.join('; '))
    } else {
      element.classList.remove(stickyClass)
      element.setAttribute('style', `max-width: inherit`)
    }
  }

  return (
    <Box sx={{ position: 'relative', width: 'inherit', maxWidth: 'inherit' }}>
      <Box className='stick-element'>{children}</Box>
    </Box>
  )
}
