import DashboardCard from '@storage/components/DashboardCard'
import { Box } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { PATH_STORAGE } from '@storage/routes'
import { DashboardCardType } from '../types/yard-planning-dashboard.types'
import { getDashboardCardSubtitle } from '../utils/yard-planning-dashboard.util'

interface YardPlanningDashboardHeaderProps {
  totalYardBlock: number
  totalContainerTurnovers: number
  totalIssues?: number
  yardInconsistencies: number
}
const YardPlanningDashboardHeader = observer(
  ({
    totalYardBlock,
    totalContainerTurnovers,
    yardInconsistencies,
  }: YardPlanningDashboardHeaderProps) => {
    const { t } = useTranslate()

    return (
      <Box sx={{ display: 'flex', gap: 3, p: 1 }}>
        <DashboardCard
          sxProps={{ minWidth: '382px' }}
          title={t('yardView', 'Yard View')}
          subtitle={getDashboardCardSubtitle(DashboardCardType.Block, totalYardBlock)}
          actionRoute={PATH_STORAGE.yardManagement}
        />

        <DashboardCard
          sxProps={{ minWidth: '382px' }}
          title={t('containerTurnovers', 'Container Turnovers')}
          subtitle={getDashboardCardSubtitle(DashboardCardType.Container, totalContainerTurnovers)}
          actionRoute={PATH_STORAGE.containerTurnovers}
        />

        {/* <DashboardCard
        sxProps={{ width: '382px' }}
        title={t('issues', 'Issues')}
        subtitle={<LabelBox label={`${totalIssues} ${t('inTotal', 'in Total')}`} />}
        icon={ReportProblem}
        actionRoute={PATH_STORAGE.yardPlanningIssues}
      /> */}

        <DashboardCard
          sxProps={{ minWidth: '382px' }}
          title={t('yardInconsistencies', 'Yard Inconsistencies')}
          subtitle={getDashboardCardSubtitle(DashboardCardType.Issue, yardInconsistencies)}
          subtitleColor={yardInconsistencies > 0 ? 'warning' : 'secondary'}
          actionRoute={PATH_STORAGE.yardInconsistencies}
          isActionDisabled={yardInconsistencies === 0}
        />
      </Box>
    )
  },
)

export default YardPlanningDashboardHeader
