import { observer } from 'mobx-react-lite'

import { Container, debounce, Stack } from '@mui/material'
import Permission from '@operations/app/models/permission.enum'
import { useOperationsStore } from '@operations/AppProvider'
import { FilterChip } from '@operations/components/filter/filter.model'
import { FilterChips } from '@operations/components/filter/FilterChips'
import { useTranslate } from '@tolgee/react'
import { Header, useMinimalsTheme } from '@tom-ui/ui'
import { useEffect } from 'react'
import { ReeferMonitoringFilters } from '../components/filter/ReeferMonitoringFilters'
import { CoolingOrderTable } from '../components/table/CoolingOrderTable'

export const ReeferMonitoringContainer = observer(() => {
  const {
    authStore,
    reeferMonitoringUIStore,
    reeferMonitoringFiltersUIStore,
    reeferMonitoringDrawerUIStore,
  } = useOperationsStore()
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const canEdit = authStore.hasPermission(Permission.WriteReeferTemperatures)

  useEffect(() => {
    const clearFilters = () => {
      reeferMonitoringFiltersUIStore.setContainerNumber()
    }

    return clearFilters()
  }, [reeferMonitoringFiltersUIStore])

  const handleSearch = debounce((containerNumber: string) => {
    reeferMonitoringFiltersUIStore.setContainerNumber(containerNumber)
    reeferMonitoringUIStore.getCoolingOrdersHistoryByContainer()
  }, 500)

  const handleReset = () => {
    reeferMonitoringFiltersUIStore.setFilter()
    reeferMonitoringFiltersUIStore.toggle()
  }

  const handleRemoveFilter = (value: FilterChip) => {
    reeferMonitoringFiltersUIStore.removeFilter(value)
  }

  const handleToggle = (id?: number, recordId?: number) => {
    if (canEdit) {
      reeferMonitoringDrawerUIStore.toggle(id, recordId)
    }
  }

  return (
    <>
      <Header
        title={t('reeferMonitoring', 'Reefer monitoring')}
        searchInputLabel={t('searchFor', 'Search for {label}', {
          label: t('aContainerNumber', 'a container number'),
        })}
        onSearchFieldChange={handleSearch}
        displaySearchIcon
        enableSearchField
        rightRenderOption={
          <Stack flexDirection='row' gap={theme => theme.customSpacing.xs}>
            <ReeferMonitoringFilters />
          </Stack>
        }
      />

      <Container sx={{ paddingY: theme => theme.customSpacing.m }}>
        <Stack gap={theme.customSpacing.xs}>
          <FilterChips
            filters={reeferMonitoringFiltersUIStore.filterChips}
            onRemoveFilter={handleRemoveFilter}
            onClearAll={handleReset}
          />
          <CoolingOrderTable
            items={reeferMonitoringUIStore.items}
            onAddItem={canEdit ? handleToggle : undefined}
          />
        </Stack>
      </Container>
    </>
  )
})
