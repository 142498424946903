import { FormDialogUtilStore } from '@planning/stores/utils/FormDialogUtilStore'
import debounce from 'lodash/debounce'
import { MRT_PaginationState } from 'material-react-table'
import { makeAutoObservable, runInAction } from 'mobx'
import { DangerousGoodsDefinitionDataStore } from './DangerousGoodsDefinitionDataStore'

export class DangerousGoodsDefinitionTableViewStore {
  searchQuery = ''
  pagination: MRT_PaginationState = {
    pageIndex: 0,
    pageSize: 5,
  }

  constructor(
    private readonly dangerousGoodsDefinitionDataStore: DangerousGoodsDefinitionDataStore,
    public readonly formDialogUtilStore: FormDialogUtilStore,
  ) {
    makeAutoObservable(this)
  }

  setPagination = (
    updater: MRT_PaginationState | ((prevState: MRT_PaginationState) => MRT_PaginationState),
  ) => {
    runInAction(() => {
      this.pagination = typeof updater === 'function' ? updater(this.pagination) : updater
    })
    this.loadData()
  }

  async loadData() {
    if (this.pagination.pageSize !== this.dangerousGoodsDefinitionDataStore.pageSize) {
      this.dangerousGoodsDefinitionDataStore.setPageSize(this.pagination.pageSize)
    }
    if (this.pagination.pageIndex !== this.dangerousGoodsDefinitionDataStore.currentPageIndex) {
      this.dangerousGoodsDefinitionDataStore.setCurrentPageIndex(this.pagination.pageIndex)
    }

    if (this.searchQuery !== this.dangerousGoodsDefinitionDataStore.filter) {
      this.dangerousGoodsDefinitionDataStore.setFilter(this.searchQuery)
    }
  }

  get pageItems() {
    return this.dangerousGoodsDefinitionDataStore.pageItems
  }

  get totalCount() {
    return this.dangerousGoodsDefinitionDataStore.totalCount
  }

  setSearchQuery = (searchQuery: string) => {
    const trimmedSearchQuery = searchQuery.trim()

    if (this.searchQuery !== trimmedSearchQuery) {
      runInAction(() => {
        this.searchQuery = trimmedSearchQuery
        this.pagination.pageIndex = 0
      })
      this.debouncedLoadData()
    }
  }

  private debouncedLoadData = debounce(() => {
    this.loadData()
  }, 400)
}
