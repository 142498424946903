import { Button, Card, CardContent, CardHeader, Stack, TextField } from '@mui/material'
import { CarrierVisitDirection } from '@planning/app/api'
import { useTranslate } from '@tolgee/react'
import { Controller, useForm } from 'react-hook-form'

interface Props {
  direction?: CarrierVisitDirection
  estimate?: number | null
  onSubmit: (newEstimate: number) => void | Promise<void>
  onCancelEdit?: () => void
}

export const EditVesselVisitEstimateForm = ({
  direction,
  estimate,
  onSubmit,
  onCancelEdit,
}: Props) => {
  const { handleSubmit, control, setValue } = useForm()
  const { t } = useTranslate()

  const getTitle = () => {
    switch (direction) {
      case CarrierVisitDirection.Inbound:
        return t('discharge', 'Discharge')
      case CarrierVisitDirection.Outbound:
        return t('loading', 'Loading')
      default:
        return t('restows', 'Restows')
    }
  }

  return (
    <form onSubmit={handleSubmit(data => onSubmit(data.estimate))}>
      <Card sx={{ minWidth: 360 }}>
        <CardHeader
          action={
            <Stack direction='row'>
              <Button type='submit'>{t('submit', 'Submit')}</Button>
              <Button color='error' onClick={onCancelEdit}>
                {t('cancel', 'Cancel')}
              </Button>
            </Stack>
          }
          title={getTitle()}
        />
        <CardContent>
          <Stack>
            <Controller
              control={control}
              name={`estimate`}
              rules={{ required: true }}
              render={() => (
                <TextField
                  label={t('estimate', 'Estimate')}
                  defaultValue={estimate}
                  onChange={event => {
                    setValue('estimate', Number(event.target.value))
                  }}
                />
              )}
            />
          </Stack>
        </CardContent>
      </Card>
    </form>
  )
}
