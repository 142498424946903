import { FormType, TEntity } from '@admin/app/models'
import { action, computed, makeObservable, observable } from 'mobx'
import { BaseEntityStore } from './BaseEntityStore'

export abstract class BaseDrawerStore<T extends TEntity> {
  isOpen = false
  editingEntityId?: number

  constructor(private entityStore: BaseEntityStore<T>) {
    makeObservable(this, {
      isOpen: observable,
      editingEntityId: observable,

      toggle: action,

      editingEntity: computed,
      formType: computed,
    })
  }

  public get editingEntity(): T | undefined {
    return this.editingEntityId
      ? this.entityStore.items.find(i => i.id === this.editingEntityId)
      : undefined
  }

  public get formType(): FormType {
    return this.editingEntityId ? 'Edit' : 'Add'
  }

  public toggle(id?: number): void {
    this.editingEntityId = id
    this.isOpen = !this.isOpen
  }
}
