import { Alert } from '@mui/material'
import { CarrierVisitDirection, RailcarTrackPositionResponseDto } from '@planning/app/api'
import { IRailVisitItem } from '@planning/rt-stores/railVisit/RailVisitItem'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { ReactNode } from 'react'

interface IDuplicateRailcarProps {
  railcars: string
  railTracks: string
}
const DuplicatedRailcarAlert = ({ railTracks, railcars }: IDuplicateRailcarProps) => {
  const { t } = useTranslate()

  return (
    <Alert
      severity='warning'
      sx={{
        mb: '1rem',
      }}
    >
      {t(
        'theRailcarIsCurrentlyAllocatedToMultipleTracks',
        'The Railcar {railcarName} is currently allocated to tracks {tracks}, please remove the duplicate',
        {
          railcarName: railcars,
          tracks: railTracks,
        },
      )}
    </Alert>
  )
}

interface IDuplicateRailcarAlertsProps {
  railVisitItem: IRailVisitItem
  direction: CarrierVisitDirection
}
export const DuplicatedRailcarAlerts = observer(
  ({ railVisitItem, direction }: IDuplicateRailcarAlertsProps) => {
    const railcarTrackPositions = railVisitItem?.railcarTrackPositions
      .filter(x => x.data.direction === direction)
      .map(x => x.data)

    const generateSharedTracksMessage = (railcarTracks: RailcarTrackPositionResponseDto[]) => {
      // Map to store occurrences of railcarId and the set of railTrackIds using that railcar
      const railcarMap = new Map<number, Set<string>>()

      // Populate the map with railcarId as key and a set of railTrackIds as value
      for (const { railcarId, railTrackId } of railcarTracks) {
        if (!railcarMap.has(railcarId)) {
          railcarMap.set(railcarId, new Set<string>())
        }
        railcarMap.get(railcarId)?.add(railTrackId)
      }

      // Group railcars by the same set of railTracks
      const groupMap = new Map<string, Set<number>>()

      railcarMap.forEach((railTrackIds, railcarId) => {
        if (railTrackIds.size > 1) {
          const key = Array.from(railTrackIds)
            .sort((a, b) => a.localeCompare(b))
            .join('_')

          if (!groupMap.has(key)) {
            groupMap.set(key, new Set<number>())
          }
          groupMap.get(key)?.add(railcarId)
        }
      })

      const alerts: ReactNode[] = []

      groupMap.forEach((cars, railTrackKey) => {
        const railTracks = railTrackKey.split('_')
        const railcars = Array.from(cars)

        alerts.push(
          <DuplicatedRailcarAlert
            key={`key${railcars.join('_')}_${railTrackKey}`}
            railTracks={railTracks
              .map(x => railcarTrackPositions.find(rtp => rtp.railTrackId === x)?.railTrackName)
              .join(', ')}
            railcars={railcars
              .map(x => railcarTrackPositions.find(rtp => rtp.railcarId === +x)?.railcarName)
              .join(', ')}
          />,
        )
      })

      return alerts
    }

    return <>{generateSharedTracksMessage(railcarTrackPositions)}</>
  },
)
