import { Grid } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { IOrderItem } from '@planning/rt-stores/order/OrderItem'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { InspectRailcarContainerCard } from './InspectRailcarContainerCard'
import { InspectRailcarEmptyMessage } from './InspectRailcarEmptyMessage'

interface IProps {
  railcarOrders: IOrderItem[]
}

export const InspectRailcarContainers: FC<IProps> = observer(({ railcarOrders }) => {
  const { tallymanSelectVesselAndOperationViewStore } = usePlanningStore()
  const store = tallymanSelectVesselAndOperationViewStore.railTallyStore

  if (!railcarOrders.length && !store.upsertedOrders.length) {
    return <InspectRailcarEmptyMessage store={store} />
  }

  return (
    <Grid container spacing='0.5rem' p='1rem'>
      {_.sortBy(railcarOrders, [
        (o: IOrderItem) => {
          return o.container?.data.number
        },
      ]).map((order, index) => {
        const upsertedOrder = store.upsertedOrders.find(
          o => o.containerNumber === order.container?.data.number,
        )
        return (
          <>
            {(upsertedOrder && (
              <InspectRailcarContainerCard
                key={upsertedOrder.containerNumber}
                containerNumber={upsertedOrder.containerNumber}
                isoCode={upsertedOrder.containerIsoCode}
                type={upsertedOrder.containerType}
                length={upsertedOrder.containerLength}
                height={upsertedOrder.containerHeight}
                isEmpty={upsertedOrder.isEmpty}
                grossWeight={upsertedOrder.containerMaxGross}
                containerTare={upsertedOrder.containerTare}
                imoClasses={upsertedOrder.imoClasses}
                isDamaged={upsertedOrder.hasDamage}
                upsertedOrder={upsertedOrder}
              />
            )) || (
              <InspectRailcarContainerCard
                key={index}
                containerNumber={order.container?.data.number}
                isoCode={order.container?.data.isoCode}
                type={order.container?.data.type}
                length={order.container?.data.length}
                height={order.container?.data.height}
                isEmpty={order.container?.data.isEmpty}
                grossWeight={order.container?.data.maxGross}
                containerTare={order.data.containerTare}
                imoClasses={order.data.imoClasses}
                isDamaged={order.data.isDamaged}
                railcarOrder={order}
              />
            )}
          </>
        )
      })}
    </Grid>
  )
})
