import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { Stack } from '@mui/system'
import { CommodityResponseDto, CustomerResponseDto, WeightMeasurementUnit } from '@planning/app/api'
import { PackageDto } from '@planning/stores/generalCargo/PackageDto'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { StrippingOrderCommoditySelection } from './StrippingOrderCommoditySelection'
import { StrippingOrderCustomerSelection } from './StrippingOrderCustomerSelection'

interface IProps {
  open: boolean
  customerId?: number
  packageOptions: PackageDto[]
  onCommodityChange: (commodity?: CommodityResponseDto) => void
  onSubmit: (data: IStrippingOrderCommodityFormData) => void
  onClose: () => void
}

export interface IStrippingOrderCommodityFormData {
  id?: number
  referenceNumber?: string
  lotNumber?: string

  commodity?: CommodityResponseDto
  plannedCargoAmount?: number

  customer?: CustomerResponseDto

  grossWeight?: number
  weightUnit?: WeightMeasurementUnit
  packageId?: number
}

export const StrippingOrderCommoditySelectionDialog = observer(
  ({ open, packageOptions, onCommodityChange, onClose, onSubmit }: IProps) => {
    const { t } = useTranslate()

    const form = useForm<IStrippingOrderCommodityFormData>({
      defaultValues: {
        grossWeight: 0,
        weightUnit: WeightMeasurementUnit.Kilogram,
      },
    })

    const { handleSubmit } = form

    useEffect(() => {
      form.reset()
    }, [open, form])

    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth='lg'>
        <DialogTitle
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          {t('selectCustomerCommodity', 'Select Commodity')}
          <Button color='secondary' size='large' onClick={onClose}>
            {t('close', 'Close')}
          </Button>
        </DialogTitle>

        <DialogContent>
          <FormProvider {...form}>
            <form onSubmit={handleSubmit(onSubmit)} id='strippingOrderCommoditySelectionForm'>
              <Stack gap={4}>
                <StrippingOrderCustomerSelection />
                <StrippingOrderCommoditySelection
                  packageOptions={packageOptions}
                  onCommodityChange={onCommodityChange}
                />
              </Stack>
            </form>
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <Button
            type='submit'
            form='strippingOrderCommoditySelectionForm'
            variant='contained'
            color='primary'
          >
            {t('save', 'Save')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  },
)
