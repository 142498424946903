import { TruckDto, TrucksApi } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'
import { Event, IEvent, IQueryHandler, IQueryMessage } from '../messageBus'

export class GetTrucksQuery implements IQueryMessage {
  static type = 'GetTrucksQuery'
  type = GetTrucksQuery.type

  constructor(
    public page: number,
    public pageSize: number,
  ) {}

  getKey = () => {
    return JSON.stringify(this)
  }
}

const httpClient = createApiClient(TrucksApi)

export const getTrucksQueryHandler: IQueryHandler<
  GetTrucksQuery,
  IEvent<TruckDto[]>
> = async query => {
  const { data } = await httpClient.get(query.page, query.pageSize)

  return new Event(GetTrucksQuery.type, data.payload)
}
