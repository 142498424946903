import { Step, StepLabel, Stepper } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'

interface Props {
  steps: string[]
  activeStep: string
}

export const StepperWithAlternativeLabel = observer(({ steps, activeStep }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  return (
    <Stepper
      style={{
        background: theme.palette.common.white,
        color: theme.palette.common.black,
        borderBottom: '1px solid #919EAB3D',
        padding: theme.spacing(4),
      }}
      activeStep={steps.indexOf(activeStep)}
      alternativeLabel
    >
      {steps.map(label => (
        <Step key={label}>
          <StepLabel>{t(label)}</StepLabel>
        </Step>
      ))}
    </Stepper>
  )
})
