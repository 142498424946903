import { FlexibleZone } from '@storage/components/icons/FlexibleZone'
import BlockIcon from '@mui/icons-material/CalendarViewMonth'
import BerthIcon from '@mui/icons-material/PowerInput'
import { Paper, Stack } from '@mui/material'
import { YardSetupStore } from '@storage/pages/yard-setup/stores/yard-setup.store'
import { useTranslate } from '@tolgee/react'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import { ToolbarButton } from './ToolbarButton'

interface YardSetupToolbarProps {
  setupStore: YardSetupStore
}

export const YardSetupToolbar = observer(({ setupStore }: YardSetupToolbarProps) => {
  const { t } = useTranslate()

  const handleCommand = (command: string) => {
    if (command.toUpperCase() === 'CREATE') setupStore.setDrawerAction('Add')
  }

  const handleOnAddBerth = () => {
    setupStore.setSelectedBerth()
    runInAction(() => {
      setupStore.drawerAction = 'Add'
    })
    setupStore.toggleBerthDialog()
  }

  const handleOnAddFlexibleZone = () => {
    setupStore.setSelectedFlexibleZone()
    runInAction(() => {
      setupStore.drawerAction = 'Add'
    })
    setupStore.toggleFlexibleZoneDialog()
  }

  return (
    <Paper
      elevation={4}
      sx={{
        position: 'absolute',
        top: 15,
        left: 15,
        padding: 1,
        display: 'flex',
      }}
    >
      <Stack direction='row' spacing={1} alignItems='center'>
        <ToolbarButton
          title={t('addBlock', 'Add block')}
          icon={<BlockIcon />}
          disabled={false}
          onClick={() => handleCommand('CREATE')}
        />

        <ToolbarButton
          title={t('addFlexibleZone', 'Add flexible zone')}
          icon={<FlexibleZone />}
          disabled={false}
          onClick={handleOnAddFlexibleZone}
        />

        <ToolbarButton
          onClick={handleOnAddBerth}
          title={t('addBerth', 'Add berth')}
          icon={<BerthIcon />}
          disabled={false}
        />
      </Stack>
    </Paper>
  )
})
