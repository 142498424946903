import { UserDto } from '@admin/app/api'
import { FormType, SelectOption } from '@admin/app/models'
import { TenantStore } from '@admin/stores/tenant.store'
import { UserStore } from '@admin/stores/user.store'
import { action, computed, makeObservable, observable } from 'mobx'

export class UserDrawerUIStore {
  isOpen = false
  editingEntityId?: string

  constructor(
    private userStore: UserStore,
    private tenantStore: TenantStore,
  ) {
    makeObservable(this, {
      isOpen: observable,
      editingEntityId: observable,
      toggle: action,

      editingEntity: computed,
      tenantOptions: computed,
      formType: computed,
    })
  }

  public get editingEntity(): UserDto | undefined {
    return this.editingEntityId
      ? this.userStore.data.find(i => i.user_id === this.editingEntityId)
      : undefined
  }

  public get tenantOptions(): SelectOption[] {
    return this.tenantStore.items
      .slice()
      .sort((a, b) => a.id - b.id)
      .map(({ id, name }) => ({ label: `${id} - ${name}`, value: id }))
  }

  public get formType(): FormType {
    return this.editingEntityId ? 'Edit' : 'Add'
  }

  public toggle(id?: string): void {
    this.editingEntityId = id
    this.isOpen = !this.isOpen
  }
}
