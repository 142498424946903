import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { YardManagementFlowKeys } from '../flows'
import { YardManagementFlows } from '../flows/flows.enum'

export const useYardManagementFlow = (): YardManagementFlows | undefined => {
  const [queryParams] = useSearchParams()

  return useMemo(
    () =>
      queryParams.has(YardManagementFlowKeys.flow)
        ? (queryParams.get(YardManagementFlowKeys.flow) as YardManagementFlows)
        : undefined,
    [queryParams],
  )
}
