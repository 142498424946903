import { tolgee } from '@storage/app/translation'
import { DashboardCardType } from '../types/yard-planning-dashboard.types'

const dashboardSubtitleLabels = {
  [DashboardCardType.Block]: {
    single: tolgee.t('block', 'Block'),
    multiple: tolgee.t('blocks', 'Blocks'),
  },
  [DashboardCardType.Container]: {
    single: tolgee.t('container', 'Container'),
    multiple: tolgee.t('containers', 'Containers'),
  },
  [DashboardCardType.Issue]: {
    single: tolgee.t('issue', 'Issue'),
    multiple: tolgee.t('issues', 'Issues'),
  },
}

export const getDashboardCardSubtitle = (dashboardCardType: DashboardCardType, value: number) => {
  const labels = dashboardSubtitleLabels[dashboardCardType]
  const label = value === 1 ? labels.single : labels.multiple

  return `${value} ${label}`
}
