import { alpha } from '@mui/material/styles'

// ----------------------------------------------------------------------

export type ColorSchema =
  | 'primary'
  | 'secondary'
  | 'info'
  | 'success'
  | 'warning'
  | 'error'
  | 'busy'

export const COLORS = [
  'primary',
  'secondary',
  'info',
  'success',
  'warning',
  'error',
  'busy',
] as const

// SETUP COLORS

const WHITE = '#fff'
const BLACK = '#000'

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
}

const PURPLE = {
  lighter: '#E5D1FF',
  light: '#B073FF',
  main: '#7C17FF',
  dark: '#5911B8',
  darker: '#3B0B7A',
  contrastText: WHITE,
}

const GREEN_DULL = {
  lighter: '#C8FACD',
  light: '#5BE584',
  main: '#00AB55',
  dark: '#00964B',
  darker: '#006E37',
  contrastText: WHITE,
}

const GREEN_VIBRANT = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
  darkest: '#005249',
  contrastText: WHITE,
}

const BLUE = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: WHITE,
}

const YELLOW = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800],
}

const RED = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: WHITE,
}

const PRIMARY = {
  lighter: GREEN_DULL.lighter,
  light: GREEN_DULL.light,
  main: GREEN_DULL.main,
  dark: GREEN_DULL.dark,
  darker: GREEN_DULL.darker,
  contrastText: WHITE,
}

const SECONDARY = {
  lighter: GREY[200],
  light: GREY[300],
  main: GREY[500],
  dark: GREY[600],
  darker: GREY[800],
  contrastText: WHITE,
}

const INFO = {
  lighter: BLUE.lighter,
  light: BLUE.light,
  main: BLUE.main,
  dark: BLUE.dark,
  darker: BLUE.darker,
  contrastText: WHITE,
}

const SUCCESS = {
  lighter: GREEN_VIBRANT.lighter,
  light: GREEN_VIBRANT.light,
  main: GREEN_VIBRANT.main,
  dark: GREEN_VIBRANT.dark,
  darker: GREEN_VIBRANT.darker,
  darkest: GREEN_VIBRANT.darkest,
  contrastText: WHITE,
}

const WARNING = {
  lighter: YELLOW.lighter,
  light: YELLOW.light,
  main: YELLOW.main,
  dark: YELLOW.dark,
  darker: YELLOW.darker,
  contrastText: GREY[800],
}

const ERROR = {
  lighter: RED.lighter,
  light: RED.light,
  main: RED.main,
  dark: RED.dark,
  darker: RED.darker,
  contrastText: WHITE,
}

const BUSY = {
  lighter: PURPLE.lighter,
  light: PURPLE.light,
  main: PURPLE.main,
  dark: PURPLE.dark,
  darker: PURPLE.darker,
  contrastText: WHITE,
}

const COMMON = {
  common: { black: BLACK, white: WHITE },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  busy: BUSY,
  grey: GREY,
  purple: PURPLE,
  blue: BLUE,
  yellow: YELLOW,
  red: RED,
  greenDull: GREEN_DULL,
  greenVibrant: GREEN_VIBRANT,
  divider: alpha(GREY[500], 0.24),
  action: {
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
}

export default function palette(themeMode: 'light' | 'dark') {
  const light = {
    ...COMMON,
    mode: 'light',
    text: {
      primary: GREY[800],
      secondary: GREY[600],
      disabled: GREY[500],
    },
    background: { paper: WHITE, default: WHITE, neutral: GREY[200] },
    action: {
      ...COMMON.action,
      active: GREY[600],
    },
  } as const

  const dark = {
    ...COMMON,
    mode: 'dark',
    text: {
      primary: WHITE,
      secondary: GREY[500],
      disabled: GREY[600],
    },
    background: {
      paper: GREY[800],
      default: GREY[900],
      neutral: alpha(GREY[500], 0.16),
    },
    action: {
      ...COMMON.action,
      active: GREY[500],
    },
  } as const

  return themeMode === 'light' ? light : dark
}
