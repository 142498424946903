import { CreateCustomerCommand, CustomerDto, UpdateCustomerCommand } from '@billing/app/api'
import { CustomerFormProfile } from './customer-form.profile'

const mapFormValuesToCreateDto = ({
  name,
  abbreviation,
  address,
}: CustomerFormProfile): CreateCustomerCommand => ({
  name,
  abbreviation,
  address,
})

const mapFormValuesToUpdateDto = ({
  id,
  name,
  abbreviation,
  address,
}: CustomerFormProfile): UpdateCustomerCommand => ({
  id: id!,
  name,
  abbreviation,
  address,
})

const mapDtoToFormValues = ({
  id,
  name,
  abbreviation,
  address,
}: CustomerDto): CustomerFormProfile => ({
  id,
  name,
  abbreviation,
  address,
})

export const CustomerFormMapper = {
  mapFormValuesToCreateDto,
  mapFormValuesToUpdateDto,
  mapDtoToFormValues,
}
