import { ContainerStatus, YardPositionDto } from '@storage/app/api'
import { usePositionLabel } from '@storage/app/container-turnovers/hooks/use-position-label.hook'
import { LabelBox } from '@storage/components/LabelBox'
import { Box, Tooltip, Typography } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'

interface ContainerPositionProps {
  position?: YardPositionDto[]
  status: ContainerStatus
  tooltip?: string
  label?: string
}

export const ContainerPositionChip = observer(
  ({ position, status, tooltip, label }: ContainerPositionProps) => {
    const { palette } = useMinimalsTheme()
    const positionLabel = usePositionLabel(position)
    const labelColors: Record<ContainerStatus, string> = {
      OnTheYard: palette.primary.main,
      Reserved: palette.info.main,
      NotReserved: palette.warning.dark,
      NotStackedIn: palette.text.primary,
    }

    const labelBgColors: Record<ContainerStatus, string> = {
      OnTheYard: `${palette.primary.main}14`,
      Reserved: `${palette.info.main}14`,
      NotReserved: palette.warning.lighter,
      NotStackedIn: palette.grey[300],
    }

    return (
      <Tooltip title={tooltip} arrow>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          {label && (
            <Typography variant='body2' mb={0.5}>
              {label}
            </Typography>
          )}
          <LabelBox
            label={positionLabel}
            bgColor={!position?.length ? labelBgColors.NotReserved : labelBgColors[status]}
            color={!position?.length ? labelColors.NotReserved : labelColors[status]}
          />
        </Box>
      </Tooltip>
    )
  },
)
