import { YardPositionDto } from '@storage/app/api'
import { PositionContext } from '@storage/features/yard-operation-control/Variants/YardPositionSelect/yard-position-select.store'
import { Box } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { YardPositionSelectHeaderField } from './YardPositionSelectHeaderField'

interface YardPositionSelectHeaderProps {
  location?: YardPositionDto
  context: PositionContext
  isFlexibleZone?: boolean
  onChangeContext: (context: PositionContext) => void
}

export const YardPositionSelectHeader = ({
  location,
  context,
  isFlexibleZone,
  onChangeContext,
}: YardPositionSelectHeaderProps) => {
  const { palette } = useMinimalsTheme()
  const getColor = (part: PositionContext) => {
    return context === part ? palette.info.dark : palette.info.light
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        gap: '1rem',
        width: '100%',
        height: 'inherited',
        justifyContent: 'flex',
        alignItems: 'center',
      }}
    >
      <YardPositionSelectHeaderField
        label={'Block'}
        descriptor={location?.block ?? undefined}
        color={getColor(PositionContext.Block)}
        textColor={palette.getContrastText(getColor(PositionContext.Block))}
        onChangeContext={() => onChangeContext(PositionContext.Block)}
      />
      {!isFlexibleZone && (
        <>
          <YardPositionSelectHeaderField
            label={'Bay'}
            descriptor={location?.bay ?? undefined}
            color={getColor(PositionContext.Bay)}
            textColor={palette.getContrastText(getColor(PositionContext.Bay))}
            onChangeContext={() => onChangeContext(PositionContext.Bay)}
          />
          <YardPositionSelectHeaderField
            label={'Row'}
            descriptor={location?.row ?? undefined}
            color={getColor(PositionContext.Row)}
            textColor={palette.getContrastText(getColor(PositionContext.Row))}
            onChangeContext={() => onChangeContext(PositionContext.Row)}
          />
          <YardPositionSelectHeaderField
            label={'Tier'}
            descriptor={location?.tier?.toString()}
            color={getColor(PositionContext.Tier)}
            textColor={palette.getContrastText(getColor(PositionContext.Tier))}
            onChangeContext={() => onChangeContext(PositionContext.Tier)}
          />
        </>
      )}
    </Box>
  )
}
