import { Checkbox, Grid, Paper, Typography } from '@mui/material'
import { ContainerDamageResponseDto } from '@planning/app/api'
import { FC } from 'react'

interface Props {
  damage: ContainerDamageResponseDto
  checked?: boolean
  onCheck?: (requestId: number) => void
}

export const DamageListItem: FC<Props> = ({ damage, checked, onCheck }) => {
  return (
    <Paper variant='outlined' sx={{ width: '100%', mt: 1, pt: '0.25rem', pb: '0.25rem' }}>
      <Grid container>
        <Grid item xs={1} display='flex' justifyContent='center'>
          {checked !== undefined && onCheck && (
            <Checkbox checked={checked} onClick={() => onCheck(damage.id)} />
          )}
        </Grid>
        <Grid item xs display='flex' flexDirection='column' justifyContent='center'>
          <Typography variant='subtitle2'>{damage.description}</Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}
