import { ColoredLabel } from '@tom-ui/ui'
import { CarrierVisitDirection } from '@planning/app/api'

export const ContainerDirectionChip = ({
  direction,
  label,
}: {
  direction: CarrierVisitDirection
  label: string
}) => {
  const chipColor = () => {
    switch (direction) {
      case 'Inbound':
        return 'info'
      case 'Outbound':
        return 'primary'
      default:
        return 'secondary'
    }
  }

  return <ColoredLabel color={chipColor()} label={label}></ColoredLabel>
}
