import { useBillingServiceStore } from '@billing/AppProvider'
import Permission from '@billing/app/models/permission.enum'
import { PackagesContainer } from '@billing/features/packaging/containers/PackagesContainer'
import { Box, Container, Tab, Tabs } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { ButtonWithDropdown, Header, PlusIcon } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { CommoditiesContainer } from './CommoditiesContainer'

export const CommoditiesAndPackagingContainer = observer(() => {
  const { t } = useTranslate()
  const {
    commodityStore,
    packageStore,
    commodityListUIStore,
    commodityDialogUIStore,
    packageDialogUIStore,
    packageListUIStore,
    authStore,
  } = useBillingServiceStore()
  const canEdit = authStore.hasPermission(Permission.WriteCommodities)

  const [selectedTab, setSelectedTab] = useState<'commodity' | 'package'>('commodity')
  const { closeSnackbar } = useSnackbar()

  useEffect(() => {
    async function fetchData() {
      await Promise.all([commodityStore.load(), packageStore.load()])
    }

    fetchData()
  }, [commodityStore, packageStore])

  const handleAddCommodityClick = () => {
    closeSnackbar()
    commodityDialogUIStore.toggle()
  }

  const handleAddPackageClick = () => {
    closeSnackbar()
    packageDialogUIStore.toggle()
  }

  const handleSearch = (name: string) => {
    if (selectedTab === 'commodity') {
      commodityListUIStore.setSearchCommodity(name)
    } else {
      packageListUIStore.setSearchPackage(name)
    }
  }

  return (
    <>
      <Header
        title={t('commoditiesAndPackaging', 'Commodities & Packaging')}
        searchInputLabel={t('searchForA', 'Search for a {name}', {
          name: t(selectedTab).toLowerCase(),
        })}
        onSearchFieldChange={handleSearch}
        displaySearchIcon
        enableSearchField
        searchFilter={
          selectedTab === 'commodity'
            ? commodityListUIStore.searchCommodity
            : packageListUIStore.searchPackage
        }
        rightRenderOption={
          canEdit ? (
            <Box flex='none'>
              <ButtonWithDropdown
                label={t('addNew', 'Add new')}
                startIcon={<PlusIcon />}
                color='inherit'
                options={[
                  {
                    label: t('commodity', 'Commodity'),
                    onClick: handleAddCommodityClick,
                  },
                  {
                    label: t('package', 'Package'),
                    onClick: handleAddPackageClick,
                  },
                ]}
              />
            </Box>
          ) : undefined
        }
      />
      <Container>
        <Tabs
          value={selectedTab}
          onChange={(_, value) => setSelectedTab(value)}
          aria-label='basic tabs example'
        >
          <Tab label={t('commodities', 'Commodities')} value='commodity' />
          <Tab label={t('packages', 'Packages')} value='package' />
        </Tabs>

        {selectedTab === 'commodity' && <CommoditiesContainer />}
        {selectedTab === 'package' && <PackagesContainer />}
      </Container>
    </>
  )
})
