import { CraneSplitContainerUIStore } from '@operations/features/craneSplit/stores/crane-split-container.ui-store'
import { CraneSplitDialogUIStore } from '@operations/features/craneSplit/stores/crane-split-dialog.ui-store'
import { CraneSplitDrawerUIStore } from '@operations/features/craneSplit/stores/crane-split-drawer.ui-store'
import { CraneSplitMessageUIStore } from '@operations/features/craneSplit/stores/crane-split-message.ui-store'
import { SelectCranesDialogUIStore } from '@operations/features/craneSplit/stores/select-cranes-dialog.ui-store'
import { WorkInstructionFiltersUIStore } from '@operations/features/job/stores/work-instruction-filters.ui-store'
import { WorkInstructionUIStore } from '@operations/features/job/stores/work-instruction.ui-store'
import { ContainerStowageLocationStore } from '@operations/stores/ContainerStowageLocationStore'
import { EquipmentStore } from '@operations/stores/EquipmentStore'
import { VesselVisitStore } from '@operations/stores/VesselVisitStore'
import { WorkAreaStore } from '@operations/stores/WorkAreaStore'
import { WorkInstructionStore } from '@operations/stores/WorkInstructionStore'
import { WorkQueueStore } from '@operations/stores/WorkQueueStore'
import { makeObservable, observable, runInAction } from 'mobx'
import { EquipmentPlanningEquipmentsUIStore } from './equipment-planning-equipments-ui-store'
import { EquipmentPlanningInfoUIStore } from './equipment-planning-info-ui-store'

export class EquipmentPlanningUIStore {
  vesselVisitId?: number

  constructor(
    private equipmentStore: EquipmentStore,
    private workAreaStore: WorkAreaStore,
    private vesselVisitStore: VesselVisitStore,
    private workInstructionStore: WorkInstructionStore,
    private workQueueStore: WorkQueueStore,
    private containerStowageLocationStore: ContainerStowageLocationStore,
    public equipmentPlanningInfoUIStore: EquipmentPlanningInfoUIStore,
    public equipmentPlanningEquipmentsUIStore: EquipmentPlanningEquipmentsUIStore,
    public craneSplitContainerUIStore: CraneSplitContainerUIStore,
    public craneSplitDialogUIStore: CraneSplitDialogUIStore,
    public craneSplitDrawerUIStore: CraneSplitDrawerUIStore,
    public craneSplitMessageUIStore: CraneSplitMessageUIStore,
    public selectCranesDialogUIStore: SelectCranesDialogUIStore,
    public workInstructionUIStore: WorkInstructionUIStore,
    public workInstructionFiltersUIStore: WorkInstructionFiltersUIStore,
  ) {
    makeObservable(this, {
      vesselVisitId: observable,
    })
  }

  async fetchData(id: number) {
    runInAction(() => {
      this.vesselVisitId = id
    })

    const fetches = [
      this.equipmentStore.loadIdsAlreadyInUseByOtherVisits(id),
      this.vesselVisitStore.loadOne(id),
      this.equipmentStore.load(),
      this.workAreaStore.load(id),
      this.workInstructionStore.load(id),
      this.containerStowageLocationStore.load(id),
      this.workQueueStore.load(id),
    ]

    const [equipmentIdsInUse] = await Promise.all(fetches)

    this.equipmentPlanningInfoUIStore.setVesselId(id)
    this.equipmentPlanningEquipmentsUIStore.setCarrierVisitId(id)
    this.equipmentPlanningEquipmentsUIStore.setEquipmentIdsInUseForOtherVisits(
      equipmentIdsInUse as number[],
    )
    this.craneSplitContainerUIStore.initVesselVisit(id)
    this.craneSplitDrawerUIStore.setVesselId(id)
    this.craneSplitDialogUIStore.setVesselId(id)
    this.selectCranesDialogUIStore.setVesselId(id)
    this.workInstructionUIStore.setVesselId(id)
    this.workInstructionUIStore.setWorkInstructionType()
  }
}
