import { Stack, Typography } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { CarrierVisitStatus, VesselVisitDto } from '@planning/app/api'
import { CustomDateTimePicker } from '@planning/components/CustomDateTimePicker'
import { CustomDateTimePickerButton } from '@planning/components/CustomDateTimePickerButton'
import vesselVisitService from '@planning/services/vesselVisitService'
import { useTranslate } from '@tolgee/react'
import dayjs, { Dayjs } from 'dayjs'
import { FC, useMemo } from 'react'

interface Props {
  vesselVisit?: VesselVisitDto
}

export const EditVesselVisitTimesForm: FC<Props> = ({ vesselVisit }) => {
  const { appViewStore, drawerStore, tenantStore } = usePlanningStore()
  const { t } = useTranslate()

  const skipVesselVisitReadyForOperation = useMemo(
    () => tenantStore.configs?.skipVesselVisitReadyForOperation ?? false,
    [tenantStore.configs?.skipVesselVisitReadyForOperation],
  )

  const handleATAChange = async (value: Dayjs | null) => {
    if (vesselVisit) {
      try {
        const ata = dayjs(value) as any
        await vesselVisitService.put({ ...vesselVisit, ata, vesselIds: vesselVisit.carrierIds })
        appViewStore.setShowAlert('success', t('savedSuccessfully', 'Saved successfully'))
        drawerStore.close()
      } catch (error) {
        appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
      }
    }
  }

  const handleATDChange = async (value: Dayjs | null) => {
    if (vesselVisit) {
      if (
        skipVesselVisitReadyForOperation === false &&
        vesselVisit.status !== CarrierVisitStatus.Completed
      ) {
        const isConfirmed = await appViewStore.setOpenConfirmDialog(
          true,
          t(
            'endOfOperationsHasNotNeenRecordedAndItWillBeRecoredAsATDConfirmation',
            'End of operations has not been recorded. The system will therefore set end of operations to the same date and time as ATD.',
          ),
          undefined,
        )
        if (!isConfirmed) return
      }

      try {
        const atd = dayjs(value) as any
        await vesselVisitService.put({ ...vesselVisit, atd, vesselIds: vesselVisit.carrierIds })
        appViewStore.setShowAlert('success', t('savedSuccessfully', 'Saved successfully'))
        drawerStore.close()
      } catch (error) {
        appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
      }
    }
  }

  return (
    <>
      {vesselVisit && (
        <Stack spacing={3}>
          {/* <Typography variant='h3'>{t('timings', 'Timings')}</Typography> */}
          <Stack spacing={2}>
            <Typography variant='subtitle1'>{t('estimatedTimes', 'Estimated times')}</Typography>
            <CustomDateTimePicker
              label={t('eta', 'ETA')}
              value={vesselVisit.eta}
              popperPlacement='left-start'
              onChange={async (value: Dayjs | null) => {
                const eta = dayjs(value) as any
                await vesselVisitService.put({
                  ...vesselVisit,
                  eta,
                  vesselIds: vesselVisit.carrierIds,
                })
              }}
            />
            <CustomDateTimePicker
              label={t('etd', 'ETD')}
              value={vesselVisit.etd}
              popperPlacement='left-start'
              onChange={async (value: Dayjs | null) => {
                const etd = dayjs(value) as any
                await vesselVisitService.put({
                  ...vesselVisit,
                  etd,
                  vesselIds: vesselVisit.carrierIds,
                })
              }}
            />
          </Stack>
          <Stack spacing={2}>
            <Typography variant='subtitle1'>{t('actualTimes', 'Actual times')}</Typography>
            {(vesselVisit.ata && (
              <CustomDateTimePicker
                label={t('ata', 'ATA')}
                value={vesselVisit.ata}
                popperPlacement='left-start'
                onChange={handleATAChange}
              />
            )) || (
              <CustomDateTimePickerButton
                popperPlacement='left-start'
                label={t('ata', 'ATA')}
                onChange={handleATAChange}
              />
            )}
            {(vesselVisit.atd && (
              <CustomDateTimePicker
                popperPlacement='left-start'
                label={t('atd', 'ATD')}
                value={vesselVisit.atd}
                onChange={handleATDChange}
              />
            )) || (
              <CustomDateTimePickerButton
                popperPlacement='left-start'
                label={t('atd', 'ATD')}
                onChange={handleATDChange}
              />
            )}
          </Stack>
        </Stack>
      )}
    </>
  )
}
