import { Box, Typography, styled } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { VesselVisitDto } from '@operations/stores/VesselVisitStore'
import { useTranslate } from '@tolgee/react'
import { translateEnumValue } from '@operations/utils/enum-extensions'
import { formatStringDate } from '@operations/utils/format-date'

interface Props {
  vesselVisit?: VesselVisitDto
}

const FieldLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 14,
}))

const FieldValue = styled(Typography)(() => ({
  fontSize: 14,
}))

const ContentCard = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '2rem',
  [theme.breakpoints.down('sm')]: {
    gap: '1rem',
    justifyContent: 'space-between',
  },
  alignItems: 'center',
  border: '1px solid rgba(145, 158, 171, 0.24)',
  borderRadius: '8px',
  padding: '10px',
}))

const ContentTitle = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 600,
}))

const ContentBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
}))

export const EquipmentPlanningHeader = ({ vesselVisit }: Props) => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()

  return (
    <>
      {vesselVisit && (
        <Box
          sx={{
            display: 'flex',
            gap: '2vw',
            justifyContent: 'space-between',
            padding: '1rem',
            backgroundColor: '#F4F6F8',
            [theme.breakpoints.down('sm')]: {
              gap: '1rem',
              flexDirection: 'column',
              justifyContent: 'space-between',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              gap: '.2rem',
            }}
          >
            <ContentTitle>{vesselVisit?.carrierName ?? '-'}</ContentTitle>
            <Typography fontSize={14}>
              {t('line')}: {vesselVisit?.shippingLine ?? '-'}
            </Typography>
            <Typography fontSize={14}>
              {t('voyage')}: {vesselVisit?.imo ?? '-'}
            </Typography>

            <Box
              sx={{
                backgroundColor: 'rgba(24, 144, 255, 0.16)',
                color: '#0C53B7',
                padding: '1px 8px',
                borderRadius: '6px',
                width: 'auto',
                marginTop: '.3rem',
              }}
            >
              <Typography fontWeight={600} fontSize={12}>
                {t(vesselVisit?.status, translateEnumValue(vesselVisit?.status)) ?? '-'}
              </Typography>
            </Box>
          </Box>

          <ContentBox>
            <ContentTitle>{t('timings')}</ContentTitle>
            <ContentCard>
              <Box>
                <FieldLabel>{t('eta')}</FieldLabel>
                <FieldValue>{formatStringDate(vesselVisit?.eta)}</FieldValue>
              </Box>
              <Box>
                <FieldLabel>{t('etd')}</FieldLabel>
                <FieldValue>{formatStringDate(vesselVisit?.etd)}</FieldValue>
              </Box>
            </ContentCard>
          </ContentBox>

          <ContentBox>
            <ContentTitle>{t('berth')}</ContentTitle>
            <ContentCard>
              <Box>
                <FieldLabel>{t('berth')}</FieldLabel>
                <FieldValue>Pier 1, B1</FieldValue>
              </Box>
            </ContentCard>
          </ContentBox>

          <ContentBox>
            <ContentTitle>{t('vessel')}</ContentTitle>
            <ContentCard>
              <Box>
                <FieldLabel>{t('lengthInMeters')}</FieldLabel>
                <FieldValue>110m</FieldValue>
              </Box>
              <Box>
                <FieldLabel>{t('maxDraftInMeters')}</FieldLabel>
                <FieldValue>14m</FieldValue>
              </Box>
              <Box>
                <FieldLabel>{t('beamInMeters')}</FieldLabel>
                <FieldValue>20m</FieldValue>
              </Box>
            </ContentCard>
          </ContentBox>
        </Box>
      )}
    </>
  )
}
