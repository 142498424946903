import { RailVisitResponseDto, RailVisitsApi } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'
import dayjs from 'dayjs'
import { Event, IEvent, IQueryHandler, IQueryMessage } from '../messageBus'

export class GetRailVisitsQuery implements IQueryMessage {
  static readonly type = 'GetRailVisitsQuery'
  type = GetRailVisitsQuery.type

  constructor(
    public from: Date,
    public to: Date,
    public includeAtaDateRangeFilter = false,
    public includeCompleted = true,
    public orderBy = 'eta',
    public isDescending = false,
    public filter?: string,
  ) {}

  getKey = () => {
    return JSON.stringify(this)
  }
}

const httpClient = createApiClient(RailVisitsApi)

export const getRailVisitsQueryHandler: IQueryHandler<
  GetRailVisitsQuery,
  IEvent<RailVisitResponseDto[]>
> = async query => {
  // TODO: remove page and pageSize
  const { data } = await httpClient.get(
    1,
    1000,
    undefined,
    undefined,
    query.includeAtaDateRangeFilter,
    query.filter,
    query.includeCompleted,
    query.orderBy,
    query.isDescending,
    dayjs(query.from).toISOString(),
    dayjs(query.to).toISOString(),
  )

  return new Event(GetRailVisitsQuery.type, data.payload)
}
