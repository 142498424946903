import { Alert, Box, SxProps } from '@mui/material'
import { ContainerTurnoverDto, TurnoverDto, YardPositionDto } from '@storage/app/api'
import { useStores } from '@storage/hooks/use-stores.hook'
import { AppAlert } from '@storage/stores/alert.store'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import { YardPositionInput } from './YardPositionInput'
import {
  ValidationResult,
  YardPositionInputValidationStore,
} from './yard-position-input-validation.store'

export interface YardPositionInputContainerProps {
  plannedPosition?: YardPositionDto
  turnover?: ContainerTurnoverDto | TurnoverDto
  disableSelectFlexibleZones?: boolean
  hasError?: boolean
  handleError?: (text: string) => void
  onChange?: (value?: YardPositionDto) => void
  label?: string
  disabled?: boolean
  alertSx?: SxProps
  validationAlerts?: AppAlert[]
}

export const YardPositionInputContainer = observer(
  ({
    plannedPosition,
    turnover,
    disableSelectFlexibleZones,
    hasError,
    handleError,
    onChange,
    label,
    disabled,
    alertSx,
    validationAlerts,
  }: YardPositionInputContainerProps) => {
    const { t } = useTranslate()
    const [radio, setRadio] = useState<string>('#')
    const [isValidDangerousGoods, setIsValidDangerousGoods] = useState<boolean>(true)
    const [isDangerousAlertMessage, setIsDangerousAlertMessage] = useState<string>('')

    const { yardBlockStore, yardBlockStackStore, tenantConfigStore } = useStores()
    const store = useMemo(
      () => new YardPositionInputValidationStore(yardBlockStackStore, yardBlockStore),
      [yardBlockStackStore, yardBlockStore],
    )

    const onChangeRadio = (value: string) => {
      if (value !== radio) {
        setRadio(value)

        const position =
          value === '#'
            ? store.position
            : {
                ...yardBlockStackStore.entries.find(e => e.yardPosition.block === value)
                  ?.yardPosition,
                tier: 1,
              }
        if (onChange) onChange(position)
      }
    }

    const onChangePosition = (value: string) => {
      if (value !== store.input) {
        store.setInput(value)
        if (onChange) onChange(store.position)
        const { isValidDangerousGoods, isDangerousAlertMessage } = store.validateDGPosition(
          turnover?.isDangerous,
        )
        setIsValidDangerousGoods(isValidDangerousGoods)
        setIsDangerousAlertMessage(isDangerousAlertMessage)
      }
    }

    useEffect(() => {
      const loadBlocks = async () => {
        if (yardBlockStore.entries.length === 0) {
          await yardBlockStore.loadList()
        }
      }

      loadBlocks()

      tenantConfigStore.loadAppConfigs()
      store.setBayRowUniqueMode(tenantConfigStore.isBayRowUniqueIdentificationModeActivated)
      store.setIncludeFlexibleZones(disableSelectFlexibleZones ?? false)

      store
        .loadStacks()
        .then(() => {
          const isFlexibleZone =
            !!plannedPosition?.block && yardBlockStore.isFlexibleZone(plannedPosition.block)
          setRadio(isFlexibleZone ? plannedPosition.block ?? '#' : '#')
          store.initPosition(plannedPosition, isFlexibleZone)
        })
        .catch(_ => handleError && handleError('databaseIOError'))
    }, [
      store,
      yardBlockStore,
      handleError,
      plannedPosition,
      disableSelectFlexibleZones,
      tenantConfigStore,
    ])

    const getFlexibleZones = () => {
      return yardBlockStackStore.entries
        .filter(e => e.isFlexibleZone && !!e.yardPosition.block)
        .map(e => e.yardPosition.block) as string[]
    }

    const getHint = () => {
      if (store.validate === ValidationResult.IsInvalid)
        return t('invalidYardPosition', 'The entered position is invalid.')
      if (hasError) return t('incompleteYardPosition', 'The entered position is incomplete.')

      return ''
    }

    return (
      <Box
        sx={{
          padding: '1rem',
        }}
      >
        <YardPositionInput
          position={store.input}
          enableEnterPosition={!!yardBlockStackStore.entries.find(e => !e.isFlexibleZone)}
          disableSelectFlexibleZones={disableSelectFlexibleZones ?? false}
          hint={getHint()}
          areas={getFlexibleZones()}
          radio={radio}
          onChangePosition={onChangePosition}
          onChangeRadio={onChangeRadio}
          label={label}
          disabled={disabled}
        />
        {!isValidDangerousGoods && (
          <Alert severity='warning' sx={{ marginTop: 1, ...alertSx }}>
            {isDangerousAlertMessage}
          </Alert>
        )}
        {!!validationAlerts?.length &&
          validationAlerts.map((alert, index) => (
            <Alert key={index} severity='error' sx={{ marginTop: 1, ...alertSx }}>
              {alert.message}
            </Alert>
          ))}
      </Box>
    )
  },
)
