import { GeneralCargoInventoryDto, GeneralCargoStockDto } from '@storage/app/api'
import { EntityStore } from '@storage/app/store/entity.store'
import { GeneralCargoStockService } from '@storage/services/general-cargo-stock.service'
import { QueryParams } from '../models/query-params'

export class GeneralCargoInventoryStore extends EntityStore<GeneralCargoInventoryDto> {
  constructor(private readonly _generalCargoStockService: GeneralCargoStockService) {
    super()
  }

  public async loadByQuery({ commodityId, customerId, lotNumber }: QueryParams) {
    const inventory = await this._generalCargoStockService.getInventory({
      commodityId,
      customerId,
      lotNumber: lotNumber ? lotNumber : undefined,
      withOccupancyOnly: true,
    })
    this.setAll(inventory)
  }

  public clearAll() {
    this.removeAll()
  }

  public async stockUpserted(stock: GeneralCargoStockDto) {
    const inventoryItem = await this._generalCargoStockService.getInventory({
      commodityId: stock.commodityId,
      customerId: stock.customerId,
      lotNumber: stock.lotNumber ?? undefined,
      withOccupancyOnly: true,
    })

    if (inventoryItem.length > 0) this.addOrUpdate(inventoryItem[0])
  }
}
