import { PackagesApi } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'

class PackageService {
  httpClient = createApiClient(PackagesApi)

  async getAll() {
    const { data } = await this.httpClient.get()

    return data
  }
}

const packageService = new PackageService()

export default packageService
