import { ContainerYardOperationDto } from '@storage/app/api'
import { SelectOption } from '@storage/app/models'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import { DropDownSelect } from './DropDownSelect'
import { DropDownSelectStore } from './drop-down-select.store'

interface DropDownSelectContainerProps {
  turnoverData?: ContainerYardOperationDto
  handleError: (text: string) => void
}
export const DropDownSelectContainer = observer(
  ({ turnoverData, handleError }: DropDownSelectContainerProps) => {
    const store = useMemo(() => new DropDownSelectStore(), [])

    useEffect(() => {
      store.setTurnoverData(turnoverData)

      if (store.stacks.length === 0) {
        store
          .loadStacks()
          .then(data => store.setStacks(data))
          .catch(_ => handleError('databaseIOError'))
      }
    }, [store, handleError, turnoverData])

    const onChange = (value: SelectOption[]) => {
      store.setSelectedSlots(value)
    }

    return (
      <DropDownSelect
        options={store.locations}
        selected={store.selectedSlots}
        slotLimitReached={store.selectedSlots.length === turnoverData?.locationSize}
        onChange={onChange}
      />
    )
  },
)
