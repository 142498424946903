import _ from 'lodash'

export type ICommandHandler<TCommand extends IMessage> = (cmd: TCommand) => Promise<void>
export type IQueryHandler<TQuery extends IQueryMessage, TResponse extends IEvent<any>> = (
  query: TQuery,
) => Promise<TResponse>
export type IEventHandler<T> = (res: IEvent<T>) => void

export interface IMessage {
  type: string
}

export interface IQueryMessage extends IMessage {
  getKey: () => string
}

export interface IEvent<T> extends IMessage {
  payload: T | null
}

export class Event<T> implements IEvent<T> {
  constructor(
    public type: string,
    public payload: T,
  ) {}
}

export interface IMessageBus {
  subscribeCommand<TCommand extends IMessage>(
    type: string,
    handler: ICommandHandler<TCommand>,
  ): () => void
  subscribeQuery<TCommand extends IQueryMessage, TResponse extends IEvent<any>>(
    type: string,
    handler: IQueryHandler<TCommand, TResponse>,
  ): () => void
  subscribeEvent<T>(type: string, handler: IEventHandler<T>): () => void
  dispatchCommand(message: IMessage): Promise<void>
  dispatchQuery(message: IQueryMessage): Promise<void>
  dispatchEvent<T>(message: IEvent<T>): void
}

export class MessageBus implements IMessageBus {
  commandSubscribers: _.Dictionary<ICommandHandler<IMessage>[]> = {}
  querySubscribers: _.Dictionary<IQueryHandler<IQueryMessage, IEvent<any>>[]> = {}
  eventSubscribers: _.Dictionary<IEventHandler<any>[]> = {}

  subscribeCommand<TCommand extends IMessage>(type: string, handler: ICommandHandler<TCommand>) {
    return this.subscribeToList<any>(type, handler, this.commandSubscribers)
  }

  subscribeQuery<TQuery extends IQueryMessage, TResponse extends IEvent<any>>(
    type: string,
    handler: IQueryHandler<TQuery, TResponse>,
  ) {
    return this.subscribeToList<any>(type, handler, this.querySubscribers)
  }

  subscribeEvent<T>(type: string, handler: IEventHandler<T>) {
    return this.subscribeToList(type, handler, this.eventSubscribers)
  }

  async dispatchCommand(message: IMessage) {
    const handlers = this.commandSubscribers[message.type] ?? []

    const responses = handlers.map(async handler => {
      await handler(message)
    })

    await Promise.all(responses)
  }

  async dispatchQuery(message: IQueryMessage) {
    const handlers = this.querySubscribers[message.type] ?? []

    const responses = handlers.map(async handler => {
      const response = await handler(message)
      this.dispatchEvent(response)
    })

    await Promise.all(responses)
  }

  dispatchEvent<T>(message: IEvent<T>) {
    const handlers = this.eventSubscribers[message.type] ?? []
    handlers.forEach(handler => {
      handler(message)
    })
  }

  private subscribeToList<T>(type: string, handler: T, dict: _.Dictionary<T[]>) {
    if (!dict[type]) {
      dict[type] = [handler]
    } else {
      dict[type].push(handler)
    }
    return () => {
      dict[type] = _.filter(dict[type], h => h !== handler)
    }
  }
}
