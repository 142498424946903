import { createSvgIcon } from '@mui/material'

export const DangerousIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.4149 1.41422C12.6338 0.633168 11.3675 0.633168 10.5865 1.41422L1.41489 10.5858C0.633839 11.3668 0.63384 12.6332 1.41489 13.4142L10.5865 22.5858C11.3675 23.3668 12.6338 23.3668 13.4149 22.5858L22.5865 13.4142C23.3675 12.6332 23.3675 11.3668 22.5865 10.5858L13.4149 1.41422ZM12.7765 16V14.344H11.1325V16H12.7765ZM12.4345 13.54L12.8185 7.324H11.0965L11.4985 13.54H12.4345Z'
      fill='currentColor'
    />
  </svg>,
  'DangerousIcon',
)
