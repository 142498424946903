import { Box } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { CargoTypeField } from '@planning/components/visit/CargoTypeField'
import { VisitEstimatesFields } from '@planning/components/visit/VisitEstimatesFields'
import { VisitTimestampsFields } from '@planning/components/visit/VisitTimestampsFields'
import { VisitTripIdsFields } from '@planning/components/visit/VisitTripIdsFields'
import { RailVisitPlanningStore } from '@planning/rt-stores/railVisit/RailVisitPlanningStore'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useFormContext } from 'react-hook-form'
import { RailcarEstimateField } from './RailcarEstimateField'
import { RailTrackField } from './RailTrackField'
import { TrainFields } from './TrainFields'

interface Props {
  store: RailVisitPlanningStore
  isEditMode: boolean
  onFormSubmit: any
}
export const RailVisitForm = observer(({ isEditMode, store, onFormSubmit }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const { railTrackItemStore } = usePlanningStore()

  const { handleSubmit } = useFormContext()

  return (
    <Box
      component='form'
      id='vessel-visit-planning'
      onSubmit={handleSubmit(onFormSubmit)}
      padding={theme.customSpacing.m}
    >
      <CargoTypeField isEditMode={isEditMode} />

      <TrainFields />

      <VisitTripIdsFields />
      <VisitEstimatesFields />
      <VisitTimestampsFields store={store} hideEtb />

      <RailcarEstimateField />

      <RailTrackField
        railTracks={railTrackItemStore.activeRailTracks}
        allocatedRailTrackIds={store.railTrackIdsWithOrders}
      />
    </Box>
  )
})
