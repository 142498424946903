import {
  Autocomplete,
  FormControl,
  InputAdornment,
  ListItem,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { OrderResponseDto } from '@planning/app/api'
import { IListStore } from '@planning/components/list/SimpleListStore'

import {
  PickUpOrderContainerSearchType,
  PickUpOrderSearchType,
} from '@planning/stores/gateControl/PickUpOrderSearchStore'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { ReactNode } from 'react'

interface Props<T> {
  handleOnChange?: (value?: T) => void
  renderOption: (option: T, props?: React.HTMLAttributes<HTMLLIElement>) => ReactNode
  getOptionLabel: (option: T) => string
  options?: T[]
  placeholder?: string
  error?: boolean
  helperText?: React.ReactNode
  disable?: boolean
  setContainerSearchType?: (value: PickUpOrderContainerSearchType) => void
  containerSearchType?: PickUpOrderContainerSearchType
  searchType?: PickUpOrderSearchType
  type: 'PickUp' | 'DropOff'
  store: IListStore<T>
  disableCloseOnSelect?: boolean
  ignoreResetFilter?: boolean
  open: boolean
  setOpen: (value: boolean) => void
  getOptionDisabled?: (option: T) => boolean
  handleInputOnChange?: (value: string | null) => void
}

export const GateInAutoComplete = observer(
  <_, T>({
    handleOnChange,
    error,
    helperText,
    store,
    options,
    disable,
    setContainerSearchType,
    containerSearchType,
    searchType,
    type,
    placeholder,
    disableCloseOnSelect,
    ignoreResetFilter,
    open,
    setOpen,
    renderOption,
    getOptionLabel,
    getOptionDisabled,
    handleInputOnChange,
  }: Props<T>) => {
    const { t } = useTranslate()

    const renderListItem = (option: T, props?: React.HTMLAttributes<HTMLLIElement>) => {
      const id = (option as OrderResponseDto).id
      const containerNumber = (option as OrderResponseDto).containerNumber
      const propsWithRenamedKey = { ...props, key: `${containerNumber}-${id}` }

      return (
        <ListItem
          sx={{
            '&[aria-disabled="true"]': {
              opacity: '1 !important',
            },
          }}
          {...propsWithRenamedKey}
        >
          {renderOption?.(option, propsWithRenamedKey)}
        </ListItem>
      )
    }

    const CustomInputAdornment = () => (
      <InputAdornment
        position='start'
        sx={{
          backgroundColor: '#ebeff3',
          height: '100%',
          maxHeight: 'none',
          borderTopLeftRadius: '8px',
          borderBottomLeftRadius: '8px',
        }}
      >
        <FormControl size='small'>
          <Select
            disabled={
              searchType === 'generalCargo' || (searchType === 'container' && type === 'DropOff')
            }
            defaultValue={containerSearchType}
            onChange={e =>
              setContainerSearchType &&
              setContainerSearchType(e.target.value as PickUpOrderContainerSearchType)
            }
            sx={{
              boxShadow: 'none',
              '.MuiOutlinedInput-notchedOutline': { border: '0 !important' },
              '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                border: '0 !important',
              },
              '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: '0 !important',
              },
            }}
          >
            <MenuItem value={'containerNumber'}>Container No.</MenuItem>
            <MenuItem value={'referenceNumber'}>Reference No.</MenuItem>
          </Select>
        </FormControl>
      </InputAdornment>
    )

    return (
      <Autocomplete
        getOptionDisabled={o => getOptionDisabled?.(o) ?? false}
        freeSolo
        value={store.filter}
        disabled={disable}
        getOptionLabel={o => {
          if (typeof o === 'string') return o

          return getOptionLabel(o)
        }}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        disableCloseOnSelect={disableCloseOnSelect}
        options={options ?? store.items}
        onChange={(_, option) => {
          if (!ignoreResetFilter) {
            store.setFilter('')
          }

          if (typeof option === 'string') return option
          if (option === null) setOpen(false)

          if (handleOnChange) {
            handleOnChange(option ?? undefined)
          }
        }}
        renderOption={(props, o) => renderListItem(o, props)}
        renderInput={params => (
          <TextField
            data-cy='gate-in-autocomplete'
            {...params}
            error={error}
            helperText={helperText}
            onChange={e => {
              store.setFilter(e.target.value)
              if (handleInputOnChange) handleInputOnChange(e.target.value)
            }}
            placeholder={placeholder ?? t('search', 'Search')}
            InputProps={{
              ...params.InputProps,
              style: {
                padding: setContainerSearchType ? 0 : '',
              },
              startAdornment: (
                <>
                  {setContainerSearchType ? <CustomInputAdornment /> : ''}
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
          />
        )}
      />
    )
  },
)
