import Permission from '@admin/app/models/permission.enum'
import { useAdminStore } from '@admin/AppProvider'
import { Stack } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { NoListResult } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useSnackbar } from 'notistack'
import { ContainerDamageCatalogueCard } from '../components/containerDamageCatalogueCard/ContainerDamageCatalogueCard'

export const ContainerDamageLocationsContainer = observer(() => {
  const { t } = useTranslate()
  const { containerDamageLocationListUIStore, containerDamageLocationDialogUIStore, authStore } =
    useAdminStore()
  const canEdit = authStore.hasPermission(Permission.WriteDamageCatalogue)

  const { closeSnackbar } = useSnackbar()

  const handleEditClick = (id: number) => {
    closeSnackbar()
    containerDamageLocationDialogUIStore.toggle(id)
  }

  return (
    <Stack gap={2} paddingY={4}>
      {containerDamageLocationListUIStore.containerDamageLocations.map(x => (
        <ContainerDamageCatalogueCard
          key={x.id}
          containerDamage={x}
          canEdit={canEdit}
          onClick={c => (canEdit ? handleEditClick(c.id) : false)}
        />
      ))}
      {!containerDamageLocationListUIStore.containerDamageLocations.length && (
        <NoListResult
          result={t('noLocationFound', 'No location found')}
          filtering={!!containerDamageLocationListUIStore.searchField}
          onClearFilter={() => containerDamageLocationListUIStore.setSearchField('')}
        />
      )}
    </Stack>
  )
})
