import { AxiosResponse } from 'axios'

export const downloadFile = (content: Blob, fileName: string) => {
  const link = document.createElement('a')

  link.download = fileName
  link.href = URL.createObjectURL(content)
  link.click()

  URL.revokeObjectURL(link.href)
}

export const getFileNameFromAxiosResponse = (response: AxiosResponse) => {
  return response.headers['content-disposition']
    .split(';')
    .find((n: any) => n.includes('filename='))
    .replace('filename=', '')
    .replaceAll('"', '')
    .trim()
}
