import { observer } from 'mobx-react-lite'

import { Button, Container, Stack } from '@mui/material'
import { EquipmentDto } from '@operations/app/api'
import Permission from '@operations/app/models/permission.enum'
import { useOperationsStore } from '@operations/AppProvider'
import { FilterChip } from '@operations/components/filter/filter.model'
import { FilterChips } from '@operations/components/filter/FilterChips'
import { useTranslate } from '@tolgee/react'
import { Header, NoListResult, PlusIcon, useMinimalsTheme } from '@tom-ui/ui'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { Equipment } from '../components/card/Equipment'
import { EquipmentFilters } from '../components/filter/EquipmentFilters'

export const EquipmentsContainer = observer(() => {
  const { t } = useTranslate()
  const {
    equipmentStore,
    yardBlockStore,
    equipmentFiltersUIStore,
    equipmentDialogUIStore,
    equipmentsUIStore,
    authStore,
  } = useOperationsStore()
  const canEdit = authStore.hasPermission(Permission.WriteEquipments)
  const theme = useMinimalsTheme()
  const { closeSnackbar } = useSnackbar()

  useEffect(() => {
    const loadPageData = async () => {
      const requests = [equipmentStore.load()]

      if (authStore.permissions.includes(Permission.WriteEquipments))
        requests.push(yardBlockStore.loadYardBlocks())

      await Promise.all(requests)
    }

    loadPageData()
  }, [equipmentStore, yardBlockStore, authStore])

  useEffect(() => {
    const clearFilters = () => {
      equipmentFiltersUIStore.setFilter()
      equipmentFiltersUIStore.setEquipmentName()
    }

    return clearFilters()
  }, [equipmentFiltersUIStore])

  const handleReset = () => {
    equipmentFiltersUIStore.setFilter()
    equipmentFiltersUIStore.toggle()
  }

  const handleRemoveFilter = (value: FilterChip) => {
    equipmentFiltersUIStore.removeFilter(value)
  }

  const handleToggle = (id?: number) => {
    closeSnackbar()
    if (canEdit) {
      equipmentDialogUIStore.toggle(id)
    }
  }

  return (
    <>
      <Header
        title={t('equipments', 'Equipments')}
        searchInputLabel={t('searchForAName', 'Search for a name')}
        onSearchFieldChange={value => equipmentFiltersUIStore.setEquipmentName(value)}
        displaySearchIcon
        enableSearchField
        rightRenderOption={
          <Stack flexDirection='row' gap={theme => theme.customSpacing.xs}>
            <EquipmentFilters />
            {canEdit && (
              <Button
                color='inherit'
                variant='contained'
                sx={{ flex: 'none' }}
                startIcon={<PlusIcon />}
                onClick={() => handleToggle()}
              >
                {t('addNew', 'Add new')}
              </Button>
            )}
          </Stack>
        }
      />

      <Container sx={{ paddingY: theme.customSpacing.m }}>
        <Stack gap={theme.customSpacing.xs}>
          <FilterChips
            filters={equipmentFiltersUIStore.filterChips}
            onRemoveFilter={handleRemoveFilter}
            onClearAll={handleReset}
          />
          {equipmentsUIStore.equipments.map(x => (
            <Equipment
              key={x.id}
              equipment={x}
              onClick={
                canEdit ? (equipment: EquipmentDto) => handleToggle(equipment.id) : undefined
              }
            />
          ))}
          {!equipmentsUIStore.equipments.length && (
            <NoListResult
              result={t('noEquipmentFound', 'No equipment found')}
              filtering={equipmentFiltersUIStore.isFilterFilled}
              onClearFilter={handleReset}
            />
          )}
        </Stack>
      </Container>
    </>
  )
})
