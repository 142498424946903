import { MoreHoriz } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'
import React, { ReactElement } from 'react'

export interface WidgetHeaderProps {
  icon: ReactElement
  title: string
  onAction?: () => void
}

export const WidgetHeader = ({ icon, title, onAction }: WidgetHeaderProps) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Box
        sx={{
          flexDirection: 'column',
          display: 'flex',
        }}
      >
        {icon &&
          React.cloneElement(icon, {
            style: { width: '2rem', height: '2rem' },
          })}

        <Typography variant='h6' sx={{ mb: '1.2rem' }}>
          {title}
        </Typography>
      </Box>
      <Box>
        {onAction && (
          <IconButton size='large' onClick={onAction}>
            <MoreHoriz fontSize='inherit' />
          </IconButton>
        )}
      </Box>
    </Box>
  )
}
