import { Button } from '@mui/material'
import { MobileAppBar } from '@planning/pages/Shell'
import { GateClerkTruckVisitEditStore } from '@planning/rt-stores/gateClerk/GateClerkTruckVisitEditStore'
import { useNavigationStore } from '@planning/stores/NavigationStack'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { FullWidthFooter } from '../../components/FullWidthFooter'
import { TruckAppointmentOrderSelectionTabs } from './components/TruckAppointmentOrderSelectionTabs'
interface Props {
  editStore: GateClerkTruckVisitEditStore
}

export const TruckAppointmentOrderSelectionMobile = observer(({ editStore }: Props) => {
  const { t } = useTranslate()
  const navigationStore = useNavigationStore()
  useEffect(() => {
    editStore.truckAppointmentOrderStore.setFilter()
  }, [editStore.truckAppointmentOrderStore])

  const onGoBack = () => {
    editStore.setIsSelectingOrder(false)
    editStore.truckAppointmentOrderStore.setFilter()
    navigationStore.pop()
  }

  return (
    <>
      <MobileAppBar
        title={t('containers', 'Containers')}
        backButton={navigationStore.canGoBack}
        onGoBack={navigationStore.pop}
      />
      <TruckAppointmentOrderSelectionTabs editStore={editStore} onBack={onGoBack} />
      <FullWidthFooter>
        <Button variant='outlined' color='primary' onClick={onGoBack} sx={{ m: 2 }}>
          {t('back', 'Back')}
        </Button>
      </FullWidthFooter>
    </>
  )
})
