import { Box } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

interface Props {
  children?: React.ReactNode
  index: number
  value: number
}

export const TabPanel: FC<Props> = observer(({ children, index, value, ...other }: Props) => {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
})
