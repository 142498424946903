import { Tabs } from '@mui/material'

interface CustomTabsContainerProps {
  children: JSX.Element
  maxWidth?: string
}
const CustomTabsContainer = ({ children, maxWidth }: CustomTabsContainerProps) => {
  return (
    <Tabs
      value={false}
      variant='scrollable'
      scrollButtons='auto'
      sx={{
        flexGrow: 1,
        minHeight: '0px',
        '.MuiTabs-flexContainer': {
          maxWidth: maxWidth ? maxWidth : '200px',
          display: 'flex',
          gap: 2,
        },
        '.MuiTabs-scrollableX': {
          margin: 'auto',
          mb: 'auto',
        },
        '.MuiTabs-scrollButtons': {
          width: '30px',
          height: '30px',
          alignSelf: 'self-end',
        },
        '.MuiTabs-scrollButtons.Mui-disabled': {
          opacity: 0.3,
        },
      }}
    >
      {children}
    </Tabs>
  )
}

export default CustomTabsContainer
