import { IssueStatus } from '@planning/app/api'
import { action, makeObservable, observable } from 'mobx'
import { IssueDataStore } from './IssueDataStore'

export type IssuesTypeCount = {
  all: number
  unresolved: number
  followUp: number
}
export class IssuesViewStore {
  issueFilterType?: IssueStatus

  constructor(private issueDataStore: IssueDataStore) {
    makeObservable(this, {
      issueFilterType: observable,

      setFilter: action,
      setIssueFilterType: action,
    })
  }

  setFilter(filter: string) {
    this.issueDataStore.setFilter(filter)
  }

  fetchData = async () => {
    await this.issueDataStore.fetch()
  }

  setIssueFilterType(issueFilterType?: IssueStatus) {
    if (this.issueFilterType !== issueFilterType) {
      this.issueFilterType = issueFilterType
    }
  }

  get issuesTypeCount(): IssuesTypeCount {
    const items = this.issueDataStore.items
    let unresolved = 0
    let followUp = 0

    items.forEach(item => {
      if (item.issueStatus === IssueStatus.Unresolved) {
        unresolved++
      } else if (item.issueStatus === IssueStatus.FollowUp) {
        followUp++
      }
    })

    return {
      all: items.length,
      unresolved: unresolved,
      followUp: followUp,
    }
  }
}
