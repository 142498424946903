import {
  CommoditiesApi,
  CommodityDto,
  CreateCommodityCommand,
  UpdateCommodityCommand,
} from '@billing/app/api'
import { createApiClient } from '@billing/app/http-client'
import { BaseEntityStore } from './base/BaseEntityStore'

export class CommodityStore extends BaseEntityStore<CommodityDto> {
  async load() {
    const { data } = await createApiClient(CommoditiesApi).get()
    this.updateStoreItems(data)
  }

  async add(commodity: CreateCommodityCommand) {
    const { data } = await createApiClient(CommoditiesApi).create(commodity)

    this.updateStoreItem({ ...commodity, id: data, usedByOrder: false }, data)
  }

  async update(commodity: UpdateCommodityCommand, usedByOrder: boolean) {
    const { data } = await createApiClient(CommoditiesApi).update(commodity)

    this.updateStoreItem({ ...commodity, id: data, usedByOrder: usedByOrder }, data)
  }

  async delete(id: number) {
    await createApiClient(CommoditiesApi)._delete(id)

    this.deleteStoreItem(id)
  }
}
