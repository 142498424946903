import { createSvgIcon } from '@mui/material'

export const EyeHideIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M12 13.5C12.8284 13.5 13.5 12.8284 13.5 12C13.5 11.1716 12.8284 10.5 12 10.5C11.1716 10.5 10.5 11.1716 10.5 12C10.5 12.8284 11.1716 13.5 12 13.5Z'
      fill='currentColor'
    />
    <path
      d='M15.2901 18.12L14.0001 16.78L13.9301 16.71L12.6601 15.44C12.4585 15.4753 12.2546 15.4954 12.0501 15.5C11.5863 15.5066 11.1257 15.421 10.6953 15.2481C10.2649 15.0751 9.87313 14.8184 9.54281 14.4927C9.21249 14.1671 8.9502 13.779 8.77119 13.351C8.59218 12.9231 8.50002 12.4639 8.50007 12C8.50471 11.7955 8.52476 11.5915 8.56007 11.39L6.56007 9.39L5.00007 7.87C3.87139 8.93347 2.90448 10.1564 2.13007 11.5C2.0423 11.652 1.99609 11.8245 1.99609 12C1.99609 12.1755 2.0423 12.348 2.13007 12.5C2.76007 13.59 6.13007 19 12.0201 19H12.2701C13.3776 18.9671 14.4708 18.7404 15.5001 18.33L15.2901 18.12Z'
      fill='currentColor'
    />
    <path
      d='M8.59 5.76L11.39 8.56C11.5915 8.5247 11.7954 8.50464 12 8.5C12.9283 8.5 13.8185 8.86875 14.4749 9.52513C15.1313 10.1815 15.5 11.0717 15.5 12C15.4954 12.2046 15.4753 12.4085 15.44 12.61L18.12 15.29L18.96 16.13C20.1028 15.0693 21.0834 13.8462 21.87 12.5C21.9578 12.348 22.004 12.1755 22.004 12C22.004 11.8245 21.9578 11.652 21.87 11.5C21.23 10.39 17.71 4.82 11.73 5C10.6225 5.03285 9.52924 5.25962 8.5 5.67L8.59 5.76Z'
      fill='currentColor'
    />
    <path
      d='M20.7102 19.29L19.4102 18L17.4102 16L7.89019 6.47L6.42019 5L4.71019 3.29C4.61695 3.19676 4.50626 3.1228 4.38443 3.07234C4.26261 3.02188 4.13204 2.99591 4.00019 2.99591C3.86833 2.99591 3.73776 3.02188 3.61594 3.07234C3.49411 3.1228 3.38342 3.19676 3.29019 3.29C3.10188 3.47831 2.99609 3.7337 2.99609 4C2.99609 4.2663 3.10188 4.5217 3.29019 4.71L5.53019 7L7.28019 8.7L14.5902 16L14.6602 16.07L16.0002 17.41L16.5902 18L19.2902 20.71C19.3831 20.8037 19.4937 20.8781 19.6156 20.9289C19.7375 20.9797 19.8682 21.0058 20.0002 21.0058C20.1322 21.0058 20.2629 20.9797 20.3848 20.9289C20.5066 20.8781 20.6172 20.8037 20.7102 20.71C20.8039 20.617 20.8783 20.5064 20.9291 20.3846C20.9798 20.2627 21.006 20.132 21.006 20C21.006 19.868 20.9798 19.7373 20.9291 19.6154C20.8783 19.4936 20.8039 19.383 20.7102 19.29Z'
      fill='currentColor'
    />
  </svg>,
  'EyeHideIcon',
)
