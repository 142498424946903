import { Box, Typography } from '@mui/material'
import WeightClassesContainer from '@storage/features/weight-classes/components/WeightClassesContainer'
import { useTranslate } from '@tolgee/react'
import { Header, useMinimalsTheme } from '@tom-ui/ui'

const WeightClassesPage = () => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  return (
    <>
      <Header title={t('weightClasses', 'Weight Classes')} />
      <Box m={theme.customSpacing.l}>
        <Typography>
          {t(
            'weightClassesPageDescription',
            'Define and manage your custom weight classes for container categorization in Kg.',
          )}
        </Typography>
        <WeightClassesContainer />
      </Box>
    </>
  )
}

export default WeightClassesPage
