import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { SearchIcon } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { ContainerJourney } from '../Stores/ContainerJourneyDataStore'
import { ContainerJourneyOptions } from './ContainerJourneyOptions'

interface IProps {
  open: boolean
  containerJourneys: ContainerJourney[]
  onSelectContainer: (containerJourney: ContainerJourney) => void
  onClose: () => void
}

const filterOptions = ['Last 7 Days']

export const ContainerDialog = observer(
  ({ open, containerJourneys, onClose, onSelectContainer }: IProps) => {
    const { t } = useTranslate()
    const [containerNumber, setContainerNumber] = useState('')

    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth='lg'>
        <DialogTitle
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          {t('selectContainer', 'Select Container')}
          <Button color='secondary' size='large' onClick={onClose}>
            {t('close', 'Close')}
          </Button>
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <TextField
                label={t('search', 'Search')}
                fullWidth
                value={containerNumber}
                onChange={event => setContainerNumber(event.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel>{t('filter', 'Filter')}</InputLabel>
                <Select label='Filter'>
                  {filterOptions.map(option => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ContainerJourneyOptions
                containerNumber={containerNumber}
                options={containerJourneys}
                onSelect={containerJourney => onSelectContainer(containerJourney)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    )
  },
)
