import { createSvgIcon } from '@mui/material'

export const MenuIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <rect x='3' y='11' width='18' height='2' rx='0.94' fill='currentColor' />
    <rect x='3' y='16' width='18' height='2' rx='0.94' fill='currentColor' />
    <rect x='3' y='6' width='18' height='2' rx='0.94' fill='currentColor' />
  </svg>,
  'MenuIcon',
)
