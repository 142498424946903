import { Autocomplete, Box, TextField } from '@mui/material'
import { YardPositionDto } from '@storage/app/api'
import { ContainerAutoCompleteStore } from '@storage/stores/container-auto-complete.store'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'

export interface ShiftedContainerDto {
  id: string
  containerNumber: string
  previousLocation?: YardPositionDto
}

interface Props {
  store: ContainerAutoCompleteStore
}

export const SearchContainerAutocomplete = observer(({ store }: Props) => {
  const { t } = useTranslate()

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    store.setFilter(e.target.value)
    await store.fetchContainers(e.target.value)
  }

  return (
    <Autocomplete
      id='container-auto-complete'
      onChange={(event, value) => store.setValue(value)}
      options={store.items}
      value={store.value}
      inputValue={store.filter}
      onInputChange={(event, value) => store.setFilter(value)}
      freeSolo
      renderInput={params => (
        <TextField
          {...params}
          label={t('searchContainerNumber', 'Search container number')}
          onChange={handleChange}
          sx={{ backgroundColor: 'white', borderRadius: theme => theme.customRadius.medium }}
        />
      )}
      getOptionLabel={(container: ShiftedContainerDto | string) =>
        typeof container === 'string' ? container : container.containerNumber ?? ''
      }
      renderOption={(props, container) => {
        return (
          <Box
            component='li'
            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
            {...props}
            key={typeof container === 'string' ? container : container.id}
          >
            {typeof container === 'string' ? container : container.containerNumber}
          </Box>
        )
      }}
    />
  )
})
