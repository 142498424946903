import { CarrierVisitStatus, PierResponseDto, VesselDto, VesselVisitDto } from '@planning/app/api'
import {
  getPixelsPerHour,
  getXAxisAndWidthForVisit,
  isDateWithinRange,
  isNumberWithinRange,
} from '@planning/components/carrier-visit-planning/carrier-visit-planning.helper'
import { IVesselPlanning } from '@planning/components/carrier-visit-planning/CarrierVisitPlanning.model'
import { BerthItemStore } from '@planning/rt-stores/berth/BerthItemStore'
import { TenantStore } from '@planning/rt-stores/tenant/TenantStore'
import { VesselVisitItemStore } from '@planning/rt-stores/vesselVisit/VesselVisitItemStore'
import { IVesselVisitPlanningFormData } from '@planning/rt-stores/vesselVisit/VesselVisitPlanningStore'
import { pierService } from '@planning/services'
import dayjs from 'dayjs'
import _ from 'lodash'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'

// [Test] TODO: UT the stores pls!
export class BerthPlanningViewStore {
  pixelPerWaterMark = 1
  startDate = dayjs().startOf('day').toDate()
  daysVisualization = 4
  berthViewTotalWidth = 700
  selectedPierId = ''
  isDialogOpen = false
  vesselVisitSelectedId = 0
  selectedVessels: VesselDto[] = []
  nextVesselVisitIdToBeEdited = 0
  piers: PierResponseDto[] = []

  currentFormValues?: IVesselVisitPlanningFormData

  constructor(
    private berthItemStore: BerthItemStore,
    private vesselVisitItemStore: VesselVisitItemStore,
    private tenantStore: TenantStore,
  ) {
    makeObservable(this, {
      startDate: observable,
      daysVisualization: observable,
      berthViewTotalWidth: observable,
      selectedPierId: observable,
      currentFormValues: observable,
      isDialogOpen: observable,
      vesselVisitSelectedId: observable,
      selectedVessels: observable,
      nextVesselVisitIdToBeEdited: observable,
      piers: observable,

      startWaterMark: computed,
      endWaterMark: computed,
      plannedVisits: computed,
      endDate: computed,
      pixelPerHour: computed,

      toggleConfirmationDialog: action,
      setDaysVisualization: action,
      setBerthViewTotalWidth: action,
      setStartDate: action,
      loadNextDate: action,
      loadPrevDate: action,
      setSelectPierId: action,
      setFormValues: action,
      setVesselVisitSelectedId: action,
      setSelectedVessels: action,
      setNextVesselVisitIdToBeEdited: action,
      setPierByWaterMark: action,
    })
  }

  toggleConfirmationDialog = (shouldOpen: boolean) => {
    this.isDialogOpen = shouldOpen
  }

  setDaysVisualization(value: number) {
    this.daysVisualization = value
  }

  setBerthViewTotalWidth(value: number) {
    this.berthViewTotalWidth = value
  }

  setStartDate(date: Date) {
    const midnightDate = dayjs(date).startOf('day').toDate()
    this.startDate = midnightDate
    this.fetchVisits()
  }

  loadNextDate() {
    const newDate = dayjs(this.startDate).add(this.daysVisualization, 'day').toDate()
    this.setStartDate(newDate)
  }

  loadPrevDate() {
    const newDate = dayjs(this.startDate).subtract(this.daysVisualization, 'day').toDate()
    this.setStartDate(newDate)
  }

  setSelectPierId(id: string) {
    this.selectedPierId = id
  }

  setVesselVisitSelectedId(id: number) {
    this.vesselVisitSelectedId = id
  }

  setSelectedVessels(vessels: VesselDto[]) {
    this.selectedVessels = vessels
  }

  setNextVesselVisitIdToBeEdited(id: number) {
    this.nextVesselVisitIdToBeEdited = id
  }

  setFormValues(value?: IVesselVisitPlanningFormData) {
    this.currentFormValues = value

    if (value?.eta && value?.etd && value.qmmFrom && value.qmmTo) {
      const date = this.tenantStore.skipBerthTimestamp ? value.eta.format() : value.etb!.format()
      this.setStartDateIfNewDateHasADifferentStart(date)
      this.setPierByWaterMark(value.qmmFrom)
    }
  }

  public setStartDateIfNewDateHasADifferentStart(date?: string) {
    if (
      date &&
      dayjs(this.startDate.toDateString()).diff(dayjs(date).toDate().toDateString(), 'days') !== 0
    ) {
      this.setStartDate(dayjs(date).toDate())
    }
  }

  public setPierByWaterMark(waterMark: number) {
    if (!isNumberWithinRange(waterMark, this.startWaterMark, this.endWaterMark)) {
      for (const pier in this.berthItemStore.berthsByPier) {
        if (Object.prototype.hasOwnProperty.call(this.berthItemStore.berthsByPier, pier)) {
          const berths = this.berthItemStore.getBerthsByPier(pier)
          const min = berths[0]?.quayMeterMarkFrom ?? 0
          const max = berths[berths.length - 1]?.quayMeterMarkTo ?? 0

          if (isNumberWithinRange(waterMark, min, max) && pier !== this.selectedPierId) {
            this.setSelectPierId(pier)
            break
          }
        }
      }
    }
  }

  async fetchVisits() {
    const fromDate = dayjs(this.startDate).toDate()
    const toDate = dayjs(this.startDate).add(this.daysVisualization, 'day').toDate()

    await this.vesselVisitItemStore.fetch(fromDate, toDate)
  }

  public async fetchPiers() {
    if (!this.piers.length) {
      await this.berthItemStore.fetch()
      const piers = await pierService.get()
      const berthsByPier = this.berthItemStore.berthsByPier

      runInAction(() => {
        this.piers = _.keys(berthsByPier).map(pierId => ({
          id: pierId,
          name: piers.find(x => x.id === pierId)?.name ?? '',
        }))

        const pierId = _.keys(berthsByPier)[0]
        if (pierId && !this.selectedPierId) {
          this.setSelectPierId(pierId)
        }
      })
    }
  }

  public get plannedVisits(): IVesselPlanning[] {
    const formVisit = this.getFormValuesMappedToVesselVisitDto()
    let exisitingVisits = this.vesselVisits

    if (formVisit) {
      exisitingVisits = exisitingVisits.filter(x => x.id !== formVisit?.id)
      exisitingVisits = [...exisitingVisits, formVisit]
    }

    const visits = _(exisitingVisits)
      .filter(visit => {
        const isOutsideDateRange =
          dayjs(visit.eta).diff(this.endDate, 'hours') >= 0 ||
          dayjs(visit.etd).diff(this.startDate, 'hours') <= 0
        const isOutsideWaterMarkRange =
          (visit && visit.qmmFrom && visit.qmmFrom >= this.endWaterMark) ||
          (visit && visit.qmmTo && visit.qmmTo <= this.startWaterMark)

        return !(isOutsideDateRange || isOutsideWaterMarkRange)
      })
      .orderBy(x => x.eta)
      .value()

    return visits
      .map(visit => {
        const xAxis = this.getXAxisPositionsForVisit(visit)
        const yAxis = this.getYAxisPositionsForVisit(visit)

        return {
          id: visit.id,
          name: visit.identifier ?? '-',
          x: xAxis.x,
          width: xAxis.width,
          y: yAxis.y,
          height: yAxis.height,
          cargoType: visit.cargoType,
          status: visit.status,
          eta: visit.eta ?? '',
          etd: visit.etd ?? '',
          etb: visit.etb ?? '',
          draftIn: visit.importDraft ?? 0,
          draftOut: visit.exportDraft ?? 0,
          qmmFrom: visit.qmmFrom ?? 0,
          qmmTo: visit.qmmTo ?? 0,
          loadEstimate: visit.loadEstimate ?? 0,
          dischargeEstimate: visit.dischargeEstimate ?? 0,
          overlaps: this.doesVisitHasAnOverlapWithAnotherVisit(visits, visit),
          selected: visit.id === this.vesselVisitSelectedId,
        }
      })
      .filter(visit => visit.width > 0 && visit.height > 0)
  }

  public get endDate() {
    return dayjs(this.startDate).add(this.daysVisualization, 'day').subtract(1, 'minute').toDate()
  }

  public get startWaterMark() {
    return this.berths[0]?.quayMeterMarkFrom ?? 1
  }

  public get endWaterMark() {
    return this.berths[this.berths.length - 1]?.quayMeterMarkTo ?? 1000
  }

  public get pixelPerHour() {
    return getPixelsPerHour(this.berthViewTotalWidth, this.daysVisualization)
  }

  public get berths() {
    return this.berthItemStore.getBerthsByPier(this.selectedPierId)
  }

  public get vesselVisits() {
    return (
      _(this.vesselVisitItemStore.elements)
        ?.map(b => b.data)
        .value() ?? []
    )
  }

  private getPixelsBetweenWaterMarks(startWaterMark: number, endWaterMark: number) {
    return this.pixelPerWaterMark * (endWaterMark - startWaterMark)
  }

  private getXAxisPositionsForVisit(visit: VesselVisitDto) {
    const etaOrEtb = this.tenantStore.skipBerthTimestamp ? visit.eta : visit.etb

    return getXAxisAndWidthForVisit(
      this.startDate,
      this.endDate,
      this.pixelPerHour,
      etaOrEtb,
      visit.etd,
    )
  }

  private getYAxisPositionsForVisit(visit: VesselVisitDto) {
    let waterMarkFrom = visit.qmmFrom ?? 0
    const waterMarkTo = visit.qmmTo ?? 0
    let yAxis = this.getPixelsBetweenWaterMarks(this.startWaterMark, waterMarkFrom)

    if (yAxis < 0) {
      yAxis = 0
      waterMarkFrom = this.startWaterMark
    }

    let height = this.getPixelsBetweenWaterMarks(waterMarkFrom, waterMarkTo)
    if (this.getPixelsBetweenWaterMarks(this.endWaterMark, waterMarkTo) > 0) {
      height = this.getPixelsBetweenWaterMarks(waterMarkFrom, this.endWaterMark)
    }

    return {
      y: yAxis,
      height: height,
    }
  }

  private doesVisitHasAnOverlapWithAnotherVisit(
    visits: VesselVisitDto[],
    visit: VesselVisitDto,
  ): boolean {
    return visits
      .filter(x => x.id !== visit.id)
      .some(x => {
        if (!visit.qmmFrom || !visit.qmmTo || !x.qmmFrom || !x.qmmTo) {
          return false
        }

        const overlapsOnXAxis =
          isDateWithinRange(visit.eta, x.eta, x.etd) ||
          isDateWithinRange(visit.etd, x.eta, x.etd) ||
          isDateWithinRange(x.eta, visit.eta, visit.etd)

        const overlapsOnYAxis =
          isNumberWithinRange(visit.qmmFrom, x.qmmFrom, x.qmmTo) ||
          isNumberWithinRange(visit.qmmTo, x.qmmFrom, x.qmmTo) ||
          isNumberWithinRange(x.qmmFrom, visit.qmmFrom, visit.qmmTo)

        return overlapsOnXAxis && overlapsOnYAxis
      })
  }

  private getFormValuesMappedToVesselVisitDto(): VesselVisitDto | undefined {
    if (!this.currentFormValues) return

    const existingVisit = this.vesselVisits.find(x => x.id === this.currentFormValues?.id)

    return {
      id: this.currentFormValues.id ?? 0,
      cargoType: this.currentFormValues.cargoType,
      status: existingVisit?.status ?? CarrierVisitStatus.Expected,
      eta: this.currentFormValues.eta?.format(),
      etb: this.currentFormValues.etb?.format(),
      etd: this.currentFormValues.etd?.format(),
      importDraft: this.currentFormValues.importDraft,
      exportDraft: this.currentFormValues.exportDraft,
      qmmFrom: this.currentFormValues.qmmFrom,
      qmmTo: this.currentFormValues.qmmTo,
      identifier:
        this.selectedVessels.find(x => this.currentFormValues?.vesselIds.includes(x.id))?.name ??
        '-',
      carrierIds: existingVisit?.carrierIds ?? [],
      dischargeConfirmed: existingVisit?.dischargeConfirmed ?? 0,
      dischargeTotal: existingVisit?.dischargeTotal ?? 0,
      dischargeEstimate: this.currentFormValues?.dischargeEstimate ?? 0,
      loadEstimate: this.currentFormValues?.loadEstimate ?? 0,
      loadConfirmed: existingVisit?.loadConfirmed ?? 0,
      loadTotal: existingVisit?.loadTotal ?? 0,
      orderIds: [],
    }
  }
}
