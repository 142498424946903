import { MultiColumnSelectOption } from '@storage/app/models/multi-column-select-option.model'
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  SxProps,
} from '@mui/material'
import { Controller, FieldValues, FormState, UseControllerProps } from 'react-hook-form'
import { AllValue } from './constants'

type FieldProps = Pick<SelectProps, 'disabled' | 'multiple' | 'size' | 'margin'>

interface Props<T extends FieldValues> extends UseControllerProps<T>, FieldProps {
  formState: FormState<T>
  label: string
  fullWidth?: boolean
  columnWidths?: string[]
  options: MultiColumnSelectOption[]
  formControlSx?: SxProps
  autoFocus?: boolean
  maxMenuHeight?: string | number
  selectLabel?: string
}

export const ControlledMultiColumnSelect = <T extends FieldValues>({
  name,
  control,
  label,
  disabled,
  autoFocus,
  multiple,
  fullWidth,
  columnWidths,
  options,
  size,
  formState: { errors },
  formControlSx,
  margin,
  maxMenuHeight,
}: Props<T>) => {
  const columnWidth = (index: number) => {
    if (columnWidths && columnWidths.length > index)
      return { width: columnWidths[index], flexGrow: 0, flexShrink: 0 }
    else return { flexGrow: 1, flexShrink: 1 }
  }

  return (
    <FormControl margin={margin} sx={formControlSx} fullWidth={fullWidth}>
      <InputLabel>{label}</InputLabel>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Select
            {...field}
            value={field.value ?? AllValue}
            error={!!errors[name]}
            size={size}
            multiple={multiple}
            disabled={disabled}
            label={label}
            autoFocus={autoFocus}
            MenuProps={maxMenuHeight ? { sx: { maxHeight: maxMenuHeight } } : {}}
            renderValue={selected => {
              return options.find(x => String(x.value) === String(selected))?.displayText
            }}
          >
            {options.map(({ value, columns }) => (
              <MenuItem key={value} value={value}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                  }}
                >
                  {columns.map((col, index) => (
                    <Box
                      key={index}
                      sx={{
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        ...col.sx,
                        display: 'flex',
                        ...columnWidth(index),
                      }}
                    >
                      {col.label}
                    </Box>
                  ))}
                </Box>
              </MenuItem>
            ))}
          </Select>
        )}
      />
      {errors[name] && (
        <FormHelperText error={!!errors[name]}>{errors[name]?.message as string}</FormHelperText>
      )}
    </FormControl>
  )
}
