import { CreateRailTrackDto, RailTrackDto, RailTracksApi } from '@storage/app/api'
import { AxiosPromise } from 'axios'

export class RailTracksService {
  constructor(private api: RailTracksApi) {}

  async getAll(): Promise<Array<RailTrackDto>> {
    const { data: railTracks } = await this.api.getAll()
    return railTracks
  }

  async create(createRailTrackDto: CreateRailTrackDto): Promise<RailTrackDto> {
    const { data: railTrack } = await this.api.create({
      railTracksCreateRequest: createRailTrackDto,
    })
    return railTrack
  }

  async update(railTrackDto: RailTrackDto): Promise<RailTrackDto> {
    const { data: railTrack } = await this.api.update({
      railTracksUpdateRequest: railTrackDto,
    })
    return railTrack
  }

  async isNameUnique(name: string, id?: string): AxiosPromise<boolean> {
    return await this.api.isUnique({
      name,
      id,
    })
  }

  async delete(id: string) {
    await this.api._delete({ id })
  }
}
