import { YardBlockBayDto, YardBlockDto, YardBlockRowDto } from '@storage/app/api'
import React from 'react'
import { YardBlockGridBay } from './YardBlockGridBay'
import { YardBlockGridRow } from './YardBlockGridRow'

interface Props {
  block: YardBlockDto
  isSelected: boolean
  bays: YardBlockBayDto[]
  rows: YardBlockRowDto[]
  inEditMode: boolean
  displayAllocations: boolean
  onRedraw: () => void
  onBayLabelClicked: (bay: YardBlockBayDto) => void
  onRowLabelClicked: (row: YardBlockRowDto) => void
}

export const YardBlockGrid = ({
  block,
  isSelected,
  bays,
  rows,
  inEditMode,
  displayAllocations,
  onRedraw,
  onBayLabelClicked,
  onRowLabelClicked,
}: Props) => {
  const showLabels = (inEditMode || displayAllocations) && isSelected

  const nextLabel = (labels: string[]) => {
    if (labels.length === 0) return undefined

    const firstLabel = labels[0]
    const lastLabel = labels[labels.length - 1]
    const isNumeric = !isNaN(parseInt(firstLabel))
    const length = Math.min(...labels.map(e => e.length))

    const firstValue = isNumeric ? parseInt(firstLabel) : firstLabel.charCodeAt(0)
    const lastValue = isNumeric ? parseInt(lastLabel) : lastLabel.charCodeAt(0)
    const isAscending = firstValue <= lastValue

    const nextValue = isAscending ? lastValue + 1 : lastValue - 1
    if (isNumeric) {
      return nextValue > 0 ? nextValue.toString().padStart(length, '0') : undefined
    }

    return nextValue <= 'Z'.charCodeAt(0) && nextValue >= 'A'.charCodeAt(0)
      ? String.fromCharCode(nextValue)
      : undefined
  }

  return (
    <>
      {bays.map(bay =>
        rows.map(row => (
          <React.Fragment key={`grid-cell-${block.id}-${bay.sequenceNumber}-${row.sequenceNumber}`}>
            {showLabels && row.sequenceNumber === 1 && (
              <YardBlockGridBay
                blockDto={block}
                bays={bays}
                bay={bay}
                rowCount={rows.length}
                nextName={nextLabel(bays.map(e => e.name))}
                inEditMode={inEditMode}
                onRedraw={onRedraw}
                onBayLabelClicked={onBayLabelClicked}
              />
            )}

            {showLabels && bay.sequenceNumber === 1 && (
              <YardBlockGridRow
                blockDto={block}
                rows={rows}
                row={row}
                nextName={nextLabel(rows.map(e => e.name))}
                inEditMode={inEditMode}
                onRedraw={onRedraw}
                onRowLabelClicked={onRowLabelClicked}
              />
            )}
          </React.Fragment>
        )),
      )}
    </>
  )
}
