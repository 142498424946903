import { PackageDto } from '@billing/app/api'
import { AttributesContainer } from '@billing/components/AttributesContainer/AttributesContainer'
import { simplifyLengthMeasurementUnit, simplifyWeightUnit } from '@billing/utils/helper'
import { Card, CardHeader, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { Tile } from '@tom-ui/ui'

interface Props {
  packageItem: PackageDto
  canEdit: boolean
  onClick: (packageItem: PackageDto) => void
}
export const PackageCard = ({ packageItem, canEdit, onClick }: Props) => {
  const { t } = useTranslate()

  const getFieldWithUnit = (field: number) => {
    return `${field} ${simplifyLengthMeasurementUnit(packageItem.lengthUnit)}`
  }

  return (
    <Card onClick={() => onClick(packageItem)} sx={{ cursor: canEdit ? 'pointer' : undefined }}>
      <CardHeader
        title={<Typography variant='h4'>{packageItem.name}</Typography>}
        sx={{ paddingBottom: theme => theme.customSpacing.m }}
      />

      <AttributesContainer>
        {!!packageItem.height && (
          <Tile label={t('height', 'Height')} value={getFieldWithUnit(packageItem.height)} />
        )}

        {!!packageItem.width && (
          <Tile label={t('width', 'Width')} value={getFieldWithUnit(packageItem.width)} />
        )}

        {!!packageItem.length && (
          <Tile label={t('length', 'Length')} value={getFieldWithUnit(packageItem.length)} />
        )}

        {packageItem.weightUnit && (
          <Tile
            label={t('weightUnit', 'Weight unit')}
            value={simplifyWeightUnit(packageItem.weightUnit)}
          />
        )}
      </AttributesContainer>
    </Card>
  )
}
