import { Autocomplete, Grid, TextField } from '@mui/material'
import { RailTrackResponseDto } from '@planning/app/api'
import { observer } from 'mobx-react-lite'
import { Controller } from 'react-hook-form'

interface IProps {
  control: any
  errors: any
  name: string
  label: string
  options: RailTrackResponseDto[]
}

const RailTrackAutocomplete = observer(({ control, errors, name, label, options }: IProps) => {
  const singleOption = options.length === 1 ? options[0] : null

  return (
    <Grid item sm={3} xs={12}>
      <Controller
        control={control}
        name={name}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => {
          if (singleOption && !value) {
            onChange(singleOption.id)
          }

          return (
            <Autocomplete
              disablePortal
              getOptionLabel={railTrack => railTrack?.name}
              options={options}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={singleOption ?? options.find((x: any) => x?.id === value) ?? null}
              onChange={(_, railTrack) => {
                onChange(railTrack?.id)
              }}
              disabled={!!singleOption}
              renderInput={params => (
                <TextField
                  {...params}
                  data-cy='rail-track-autocomplete'
                  label={label}
                  error={!!errors[name]}
                  helperText={errors[name] ? 'Field is required.' : undefined}
                />
              )}
            />
          )
        }}
      />
    </Grid>
  )
})

export default RailTrackAutocomplete
