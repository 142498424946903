import {
  EquipmentsApi,
  WorkAreaDto,
  WorkAreaEquipmentBindingTypes,
  WorkAreasApi,
} from '@operations/app/api'
import { createApiClient } from '@operations/app/http-client'

import { runInAction } from 'mobx'
import { BaseEntityStore } from './base/BaseEntityStore'

export class WorkAreaStore extends BaseEntityStore<WorkAreaDto> {
  async load(carrierVisitId?: number) {
    const { data } = await createApiClient(WorkAreasApi).get(carrierVisitId)
    this.updateStoreItems(data)
  }

  async assignEquipmentToWorkArea(
    eqId: number,
    assignmentType: WorkAreaEquipmentBindingTypes,
    waId: number | null,
    waOriginId: number | null,
  ) {
    const addEquipmentToWorkArea = async (
      waId: number,
      eqId: number,
      assigmentType: WorkAreaEquipmentBindingTypes,
    ) => {
      const wa = this.items.find(wa => wa.id === waId)
      if (
        !wa ||
        wa.workAreaEquipments.some(
          eq => eq.equipmentId === eqId && eq.assignmentType.includes(assigmentType),
        )
      )
        return undefined

      const eqIndex = wa.workAreaEquipments.findIndex(opt => opt.equipmentId == eqId)
      const newWorkArea = {
        ...wa,
        workAreaEquipments: [...wa.workAreaEquipments],
      }

      if (eqIndex === -1) {
        newWorkArea.workAreaEquipments.push({
          equipmentId: eqId,
          workAreaId: waId,
          assignmentType: assigmentType,
        })
      } else {
        newWorkArea.workAreaEquipments[eqIndex] = {
          ...newWorkArea.workAreaEquipments[eqIndex],
          assignmentType:
            `${WorkAreaEquipmentBindingTypes.Discharge}, ${WorkAreaEquipmentBindingTypes.Load}` as any,
        }
      }

      createApiClient(EquipmentsApi).assign({
        equipmentId: eqId,
        workingAreaId: waId,
        assignmentType: assignmentType,
      })

      return newWorkArea
    }

    const isMovementWithinSameWorkArea = (waId: number | null, waOriginId: number | null) => {
      return waId === waOriginId
    }

    if (isMovementWithinSameWorkArea(waId, waOriginId)) {
      return
    }

    const newWorkAreas: WorkAreaDto[] = []

    if (waOriginId) {
      const newUnassignWorkArea = await this.unassignEquipmentFromWorkArea(
        eqId,
        waOriginId,
        assignmentType,
      )
      if (newUnassignWorkArea) newWorkAreas.push(newUnassignWorkArea)
    }

    if (!waId) {
      return
    }

    const newAssignWorkArea = await addEquipmentToWorkArea(waId, eqId, assignmentType)
    if (newAssignWorkArea) newWorkAreas.push(newAssignWorkArea)

    runInAction(() => {
      this.items = this.items.map(x => {
        const wa = newWorkAreas.find(y => y.id === x.id)
        return wa ?? x
      })
    })
  }

  private async spliceEquipmentFromWorkArea(
    eqId: number,
    waId: number,
    assigmentType: WorkAreaEquipmentBindingTypes,
  ) {
    const wa = this.items.find(wa => wa.id === waId)
    if (!wa) return undefined

    const eqIndex = wa.workAreaEquipments.findIndex(opt => opt.equipmentId == eqId)
    const eq = wa.workAreaEquipments[eqIndex]
    const newWorkArea = { ...wa, workAreaEquipments: [...wa.workAreaEquipments] }

    if (eq.assignmentType === assigmentType) {
      newWorkArea.workAreaEquipments.splice(eqIndex, 1)
    } else {
      newWorkArea.workAreaEquipments[eqIndex] = {
        ...eq,
        assignmentType: eq.assignmentType
          .split(', ')
          .find(a => a !== assigmentType) as WorkAreaEquipmentBindingTypes,
      }
    }

    createApiClient(EquipmentsApi).unassign({
      equipmentId: eqId,
      workingAreaId: waId,
      assignmentType: assigmentType,
    })

    return newWorkArea
  }

  async unassignEquipmentFromWorkArea(
    eqId: number,
    waId: number,
    assigmentType: WorkAreaEquipmentBindingTypes,
  ) {
    const newItem = await this.spliceEquipmentFromWorkArea(eqId, waId, assigmentType)

    if (!newItem) return undefined

    this.updateStoreItem(newItem, waId)
  }
}
