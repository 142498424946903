import { Button, CardContent, CardHeader, Stack, Typography } from '@mui/material'
import Card from '@mui/material/Card'
import { OperatorFields, WorkInstructionJobDto } from '@operations/app/api'
import { useTranslate } from '@tolgee/react'
import { HighlightedText } from '@tom-ui/ui'
import { JobJourney } from './JobJourney'
import { WorkInstructionJobDescription } from './WorkInstructionJobDescription'

interface Props {
  workInstructionJob: WorkInstructionJobDto
  isConfirm?: boolean
  onConfirm: (workInstructionjob: WorkInstructionJobDto) => void
  highlightText?: string
  fieldsToShow?: OperatorFields
}

export const WorkInstructionJobCard = ({
  workInstructionJob,
  isConfirm,
  onConfirm,
  highlightText,
  fieldsToShow,
}: Props) => {
  const { t } = useTranslate()

  const handleConfirm = () => {
    onConfirm(workInstructionJob)
  }

  return (
    <Card sx={{ width: '100%', height: '100%' }}>
      <CardHeader
        title={
          <Stack direction='row' alignItems='center' justifyContent='space-between'>
            <Typography variant='h3'>
              <HighlightedText highlight={highlightText}>
                {workInstructionJob.displayName}
              </HighlightedText>
            </Typography>
          </Stack>
        }
        sx={{ paddingBottom: theme => theme.customSpacing.xs }}
      />

      <WorkInstructionJobDescription job={workInstructionJob} fieldsToShow={fieldsToShow} />

      <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: '.5rem', height: '100%' }}>
        <JobJourney
          origin={workInstructionJob.origin}
          destination={workInstructionJob.destination}
        />

        <Stack gap={1} paddingY={theme => theme.customSpacing.xs}>
          <Button color='primary' variant='contained' size='large' onClick={handleConfirm}>
            {isConfirm ? t('confirmMove', 'Confirm move') : t('startMove', 'Start move')}
          </Button>
        </Stack>
      </CardContent>
    </Card>
  )
}
