import { YardBlockStackStore } from '@storage/stores/yard-block-stack.store'
import { action, computed, makeObservable, observable } from 'mobx'

export interface AllocationSpace {
  headStackId: string
  bodyStackId?: string
}

export class StacksAllocationStore {
  allocatedSpace: AllocationSpace[] = []

  constructor(private readonly _stacksStore: YardBlockStackStore) {
    makeObservable(this, {
      allocatedSpace: observable,
      toggleAllocationFor20: action,
      toggleAllocationFor40: action,
      numberOfAllocatedStacks: computed,
    })
  }

  get numberOfAllocatedStacks() {
    return this._stacksStore.entries
      .filter(({ id }) =>
        this.allocatedSpace.some(
          ({ headStackId, bodyStackId }) => id === headStackId || id === bodyStackId,
        ),
      )
      .reduce((result, stack) => result + stack.numberOfSlots, 0)
  }

  toggleAllocationFor20(stackId: string) {
    if (this.allocatedSpace.find(({ headStackId }) => headStackId === stackId)) {
      this.allocatedSpace = this.allocatedSpace.filter(({ headStackId }) => headStackId !== stackId)
    } else {
      this.allocatedSpace = [
        ...this.allocatedSpace,
        {
          headStackId: stackId,
        },
      ]
    }
  }

  toggleAllocationFor40(stackId: string) {
    const stack = this._stacksStore.entries.find(({ id }) => stackId === id)

    if (!stack) return

    const nextStack = this._stacksStore.entries.find(
      ({ baySequenceNumber, rowId }) =>
        stack.rowId === rowId && baySequenceNumber == stack.baySequenceNumber + 1,
    )

    const existingAllocation = this.allocatedSpace.find(
      ({ headStackId, bodyStackId }) => headStackId === stackId || bodyStackId === stackId,
    )

    if (existingAllocation) {
      this.allocatedSpace = this.allocatedSpace.filter(
        ({ headStackId, bodyStackId }) => !(stackId === headStackId || stackId === bodyStackId),
      )
    } else {
      if (!nextStack || this.allocatedSpace.find(({ headStackId }) => nextStack.id === headStackId))
        return

      this.allocatedSpace = [
        ...this.allocatedSpace,
        {
          headStackId: stackId,
          bodyStackId: nextStack.id,
        },
      ]
    }
  }
}
