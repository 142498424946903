import { RailcarResponseDto } from '@planning/app/api'
import { IEvent, IMessageBus } from '@planning/messages'
import { EventTypes } from '@planning/messages/eventsTypes'
import { GetRailcarsQuery } from '@planning/messages/queries/getRailcarsQueryHandler'
import _ from 'lodash'
import { action, makeObservable } from 'mobx'
import { ItemStore } from '../base/ItemStore'
import { IEntityStore } from '../types'
import { IRailcarItem, RailcarItem } from './RailcarItem'

export class RailcarItemStore
  extends ItemStore<RailcarResponseDto, IRailcarItem>
  implements IEntityStore<IRailcarItem>
{
  private hasBeenLoaded = false

  constructor(private messageBus: IMessageBus) {
    super((key, data) => new RailcarItem(key, data), {
      messageBus,
      bulkFetchFunc: (_: number[]) => new GetRailcarsQuery(1, 10000),
    })
    makeObservable(this, {
      receiveRailcarsMessage: action,
      receiveRailcarsUpsertedEventMessage: action,
      receiveRailcarsDeletedEventMessage: action,
    })

    messageBus.subscribeEvent(GetRailcarsQuery.type, this.receiveRailcarsMessage)
    messageBus.subscribeEvent(
      EventTypes.RailcarUpsertedEvent,
      this.receiveRailcarsUpsertedEventMessage,
    )
    messageBus.subscribeEvent(
      EventTypes.RailcarDeletedEvent,
      this.receiveRailcarsDeletedEventMessage,
    )
  }

  getById = (id: number) => {
    return _.get(this.elements, id)
  }

  receiveRailcarsMessage = (event: IEvent<RailcarResponseDto[]>): void => {
    if (event.payload) {
      this.upsertBulk(event.payload)
    }
  }

  receiveRailcarsUpsertedEventMessage = (event: IEvent<RailcarResponseDto>): void => {
    if (event.payload) {
      this.upsert(event.payload)
    }
  }

  receiveRailcarsDeletedEventMessage = (event: IEvent<number>): void => {
    if (event.payload && _.has(this.elements, event.payload)) {
      this.delete(event.payload)
    }
  }

  fetch = async (query?: GetRailcarsQuery) => {
    if (this.hasBeenLoaded) return

    this.hasBeenLoaded = true

    await this.messageBus.dispatchQuery(query ?? new GetRailcarsQuery(1, 1000))
  }

  getRailcars = () => {
    return _(this.elements)
      .map(x => x.data)
      .value()
  }
}
