import { ContainerTurnoverDto, ContainerTurnoversApi, DeviceOperationMode } from '@storage/app/api'

export class ContainerShiftingsService {
  constructor(private api: ContainerTurnoversApi) {}

  async fetchShiftedContainers(containerNumber: string): Promise<ContainerTurnoverDto[]> {
    const { data: yardOperation } = await this.api.getYardOperationData({
      containerNumber,
      isStackIn: false,
      mode: DeviceOperationMode.Bay,
    })

    return yardOperation.shuffleContainers ?? []
  }

  async fetchTurnover(containerNumber: string): Promise<ContainerTurnoverDto> {
    const { data } = await this.api.getContainerTurnoverByContainerNumber({ containerNumber })
    return data
  }
}
