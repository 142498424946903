import {
  CreateYardBlockDto,
  UpdateYardBlockDto,
  YardBlockDto,
  YardBlockUsageOptions,
} from '@storage/app/api'
import { generateLabelRange } from './utils/yard-block-form.util'
import { StackingStrategy, YardBlockFormProfile, defaultValues } from './yard-block-form.profile'

export const mapFormValuesToYardBlockUsageOptions = (
  usageOptions?: string[],
): YardBlockUsageOptions => {
  const initialUsageOptions: YardBlockUsageOptions = {
    railHandover: false,
    quayHandover: false,
    truckHandover: false,
    temporaryStowage: false,
    dangerousGoodsHandling: false,
  }

  if (!usageOptions) {
    return initialUsageOptions
  }

  const usageOptionsObj = usageOptions.reduce<YardBlockUsageOptions>((acc, usageOption) => {
    if (Object.keys(initialUsageOptions).includes(usageOption)) {
      acc[usageOption as keyof YardBlockUsageOptions] = true
    }
    return acc
  }, initialUsageOptions)

  return usageOptionsObj
}

export const mapUsageOptions = (usageOptions?: YardBlockUsageOptions | null): string[] => {
  if (!usageOptions) {
    return []
  }

  return Object.entries(usageOptions).reduce<string[]>((acc, [key, value]) => {
    if (value) {
      acc.push(key)
    }
    return acc
  }, [])
}

const mapFormValuesToCreateDto = ({
  name,
  maxTier,
  rowsPatternType,
  rowsFrom,
  rowsTo,
  baysPatternType,
  baysFrom,
  baysTo,
  equipmentTypes,
  usageOptions,
  stackingStrategy,
}: YardBlockFormProfile): CreateYardBlockDto => ({
  name,
  maxTier,
  isFlexibleZone: false,
  bayNames: generateLabelRange(baysFrom!, baysTo!, baysPatternType!),
  rowNames: generateLabelRange(rowsFrom!, rowsTo!, rowsPatternType!),
  equipmentTypes,
  usageOptions: mapFormValuesToYardBlockUsageOptions(usageOptions),
  stackingStrategy,
})

const mapFormValuesToUpdateDto = ({
  id,
  name,
  maxTier,
  equipmentTypes,
  usageOptions,
  stackingStrategy,
}: YardBlockFormProfile): UpdateYardBlockDto => ({
  name,
  maxTier,
  yardBlockId: id!,
  equipmentTypes,
  usageOptions: mapFormValuesToYardBlockUsageOptions(usageOptions),
  stackingStrategy,
})

const mapDtoToFormValues = ({
  id,
  name,
  maxTier,
  equipmentTypes,
  usageOptions,
  stackingStrategy,
}: YardBlockDto): YardBlockFormProfile => ({
  ...defaultValues,
  name,
  maxTier,
  id,
  equipmentTypes,
  usageOptions: mapUsageOptions(usageOptions),
  stackingStrategy: stackingStrategy as StackingStrategy,
})

export const YardBlockFormMapper = {
  mapFormValuesToCreateDto,
  mapFormValuesToUpdateDto,
  mapDtoToFormValues,
}
