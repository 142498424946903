import { useMinimalsTheme } from '../../../hooks'

interface Props {
  children?: string | null
  highlight?: string
  color?: string
}

export const HighlightedText = ({ children, highlight, color }: Props) => {
  const theme = useMinimalsTheme()

  if (!highlight?.trim() || !children) {
    return <>{children}</>
  }

  const regex = new RegExp(`(${highlight.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&')})`, 'gi')
  const parts = children.split(regex)

  const getColor = (isHighlighted: boolean) => {
    if (isHighlighted) {
      return color && color !== '' ? color : theme.palette.grey[600]
    }

    return 'unset'
  }

  return (
    <>
      {parts.map((part, i) => (
        <span
          key={`highlight-text-part-${i.toString()}`}
          style={{
            color: getColor(regex.test(part)),
          }}
        >
          {part}
        </span>
      ))}
    </>
  )
}
