import { Alert, Box, Button, Typography } from '@mui/material'
import {
  CarrierVisitStatus,
  ContainerStatus,
  ContainerTurnoversApiGetAllBasicInfoRequest,
  YardInternalMovesApiCreateMoveRequest,
} from '@storage/app/api'
import { ConfirmationDialogContainer } from '@storage/components/ConfirmationDialogContainer'
import CarrierVisitAllocationRulePanel from '@storage/features/allocation-rules/components/CarrierVisitAllocationRulePanel'
import useFormWithSchema from '@storage/hooks/use-form-with-schema.hook'
import { useStores } from '@storage/hooks/use-stores.hook'
import { containerTurnoverService, moveContainerService } from '@storage/services'
import { TenantConfigStore } from '@storage/stores/tenantConfig.store'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { ContainerMovementInfo } from '@tom-ui/utils'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { ManualPlanningDialogStore } from '../../stores/manual-planning-dialog.store'
import { CTAdvancedSearchField } from './CTAdvancedSearchField'
import { ContainerTurnoversQuickFilter } from './components'
import { mapFormValuesToFilterDto } from './container-turnovers-filter-form.mapper'
import {
  ContainerTurnoversFilterFormProfile,
  ContainerTurnoversFilterFormProfileKey,
  defaultValues,
} from './container-turnovers-filter-form.profile'
import { schema } from './container-turnovers-filter-form.schema'

export const ContainerTurnoversAdvancedFilterFormId = 'ContainerTurnoversAdvancedFilterForm'

interface Props {
  filterDefaultValues?: Partial<ContainerTurnoversFilterFormProfile>
  containersNumberMatchingFilter: number
  filter: ContainerTurnoversFilterFormProfile
  advancedFiltersShown: boolean
  disabledFilters?: ContainerTurnoversFilterFormProfileKey[]
  onToggleAdvancedFilters: () => void
  onPlanManually?: () => void
  onPlanOnYard?: () => void
  onMoveManually?: (containers: ContainerMovementInfo[]) => void
  onSubmit: (values: ContainerTurnoversFilterFormProfile) => void
  tenantConfigStore?: TenantConfigStore
  manualPlanningDialogStore?: ManualPlanningDialogStore
}

export const ContainerTurnoversFilterForm = observer(
  ({
    filterDefaultValues,
    filter,
    advancedFiltersShown,
    containersNumberMatchingFilter,
    disabledFilters,
    onToggleAdvancedFilters,
    onPlanManually,
    onMoveManually,
    onSubmit,
    tenantConfigStore,
    manualPlanningDialogStore,
  }: Props) => {
    const { carrierVisitAllocationRulesV2UIStore } = useStores()

    const { palette } = useMinimalsTheme()
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false)
    const form = useFormWithSchema<ContainerTurnoversFilterFormProfile>(schema, filter)

    const { control, formState, reset, handleSubmit, watch, setValue } = form

    const { t } = useTranslate()

    const { carrierVisitStore, snackbarStore } = useStores()

    const handleClearAll = useCallback(() => {
      for (const key of Object.keys(filter)) {
        const profileKey = key as keyof ContainerTurnoversFilterFormProfile

        setValue(profileKey, filter[profileKey])
      }
    }, [filter, setValue])

    useEffect(() => {
      handleClearAll()
    }, [setValue, filter, handleClearAll])

    const debouncedSubmit = debounce((formValues: ContainerTurnoversFilterFormProfile) => {
      onSubmit(formValues)
    }, 500)

    useEffect(() => {
      const { unsubscribe } = watch((formValues, { name, type }) => {
        if (!advancedFiltersShown) {
          if (type === 'change' && name === 'containerOrReferenceNumber') {
            debouncedSubmit(formValues as ContainerTurnoversFilterFormProfile)
          } else {
            onSubmit(formValues as ContainerTurnoversFilterFormProfile)
          }
        }
      })

      return () => {
        unsubscribe()
        debouncedSubmit.cancel()
      }
    }, [reset, onSubmit, watch, advancedFiltersShown, debouncedSubmit])

    useEffect(() => {
      const loadCarrierVisitOptions = async () => {
        await carrierVisitStore.loadList({
          query: [
            CarrierVisitStatus.Arrived,
            CarrierVisitStatus.Expected,
            CarrierVisitStatus.InOperation,
          ],
        })
      }

      loadCarrierVisitOptions()
    }, [carrierVisitStore])

    const handleToggleAdvancedFilters = () => {
      if (advancedFiltersShown) {
        handleClearAll()
      }

      onToggleAdvancedFilters()
    }

    // Reset all fields to default except containerNumber
    useEffect(() => {
      const resetAllExceptContainerOrReferenceNumber = () => {
        reset({
          ...defaultValues,
          ...filterDefaultValues,
          locationStatus: defaultValues.locationStatus,
          containerOrReferenceNumber: filter.containerOrReferenceNumber,
        })
      }

      if (filter.containerOrReferenceNumber) {
        resetAllExceptContainerOrReferenceNumber()
      }
    }, [filter.containerOrReferenceNumber, filterDefaultValues, reset, setValue])

    const handleMoveContainers = async () => {
      const request: YardInternalMovesApiCreateMoveRequest = {
        yardInternalMovesCreateMoveRequest: {
          filter: mapFormValuesToFilterDto(filter),
        },
      }
      try {
        await moveContainerService.moveContainer(request)
        snackbarStore.showMessage(
          t('containersSuccessfullyMoved', 'The containers moves have been triggered'),
          'success',
        )
        handleClearAll()
      } catch (e) {
        snackbarStore.showMessage(
          t('containersMovedFailed', 'The containers moves were not triggered'),
          'error',
        )
      } finally {
        setIsConfirmationDialogOpen(false)
      }
    }

    const isFilterEqualDefault = useMemo(() => isEqual(filter, defaultValues), [filter])

    const onAllocateHandler = async () => {
      await carrierVisitAllocationRulesV2UIStore.validateDangerousGoodsPlanning(
        filter,
        containersNumberMatchingFilter,
        () => manualPlanningDialogStore?.planningSuccessfulEffect(),
      )
    }

    const handleConfirmAllocation = () => {
      carrierVisitAllocationRulesV2UIStore.toggleDangerousGoodsDialog()
      carrierVisitAllocationRulesV2UIStore.allocationRequest(
        mapFormValuesToFilterDto(filter),
        containersNumberMatchingFilter,
        () => manualPlanningDialogStore?.planningSuccessfulEffect(),
      )
    }

    const handleManualMoveClick = async () => {
      if (!onMoveManually) return

      //TODO: check if it is ok to call the service directly here -- also change it to not call this again if the filter does not change
      const containerTurnovers = await containerTurnoverService.getAllBasicInfo({
        ...mapFormValuesToFilterDto(filter),
      } as ContainerTurnoversApiGetAllBasicInfoRequest)

      const containerMovementInfo = containerTurnovers
        .filter(x => x.status === ContainerStatus.OnTheYard && x.currentPosition.length > 0)
        .map<ContainerMovementInfo>(x => {
          return {
            containerNumber: x.containerNumber,
            isDangerous: x.isDangerous,
            isEmpty: x.isEmpty ?? false,
            size: x.size,
          }
        })

      onMoveManually(containerMovementInfo)
    }

    return (
      <FormProvider {...form}>
        <Box sx={{ display: 'flex', flexFlow: 'column', gap: 2 }}>
          <Box
            id={ContainerTurnoversAdvancedFilterFormId}
            onReset={() => reset()}
            component='form'
            autoComplete='off'
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <CTAdvancedSearchField
              control={control}
              formState={formState}
              handleToggleAdvancedFilters={handleToggleAdvancedFilters}
              advancedFiltersShown={advancedFiltersShown}
              tenantConfigStore={tenantConfigStore}
            />
          </Box>
          {!advancedFiltersShown && (
            <ContainerTurnoversQuickFilter
              disabledFilters={disabledFilters}
              showClearAll={
                !isFilterEqualDefault &&
                !carrierVisitAllocationRulesV2UIStore.selectedAllocationRule
              }
              onClearAll={() => reset()}
              tenantConfigStore={tenantConfigStore}
            />
          )}
          {/* TODO: make one component for the panel (for allocation rules: AllocationRulePanel) */}
          {containersNumberMatchingFilter > 1 &&
            !isFilterEqualDefault &&
            !carrierVisitAllocationRulesV2UIStore.showAllocationPanel && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: 1,
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                    textAlign: 'center',
                    borderRadius: '8px',
                    p: 1,
                    bgcolor: palette.grey[200],
                  }}
                >
                  <Typography variant='subtitle2' data-cy='container-selection'>
                    {t('nContainersAreSelected', { n: containersNumberMatchingFilter })}
                  </Typography>
                </Box>
                {(tenantConfigStore && !tenantConfigStore.skipYardPlanning) ||
                !tenantConfigStore ? (
                  <>
                    <Button
                      variant='contained'
                      onClick={onPlanManually}
                      data-cy='container-turnovers-plan-all'
                    >
                      {t('planAll', 'Plan all')}
                    </Button>
                    <Button variant='contained' onClick={handleManualMoveClick}>
                      {t('moveAll', 'Move all')}
                    </Button>
                  </>
                ) : (
                  tenantConfigStore.skipYardPlanning &&
                  filter.locationStatus === ContainerStatus.OnTheYard &&
                  containersNumberMatchingFilter > 1 && (
                    <Button variant='contained' onClick={() => setIsConfirmationDialogOpen(true)}>
                      {t('triggerMoves', 'Trigger moves')}
                    </Button>
                  )
                )}
              </Box>
            )}
          {carrierVisitAllocationRulesV2UIStore.showAllocationPanel &&
            !!containersNumberMatchingFilter && (
              <CarrierVisitAllocationRulePanel
                actionButtonLabel={carrierVisitAllocationRulesV2UIStore.panelActionButtonLabel}
                isLoading={carrierVisitAllocationRulesV2UIStore.isAllocationRequestLoading}
                ruleName={carrierVisitAllocationRulesV2UIStore.selectedAllocationRule?.name ?? ''}
                filteredContainersCount={containersNumberMatchingFilter}
                position={carrierVisitAllocationRulesV2UIStore.selectedAllocationRulePosition}
                alerts={carrierVisitAllocationRulesV2UIStore.alerts}
                onAllocate={onAllocateHandler}
              />
            )}
          <ConfirmationDialogContainer
            open={isConfirmationDialogOpen}
            title={t('confirmMoves', 'Confirm Moves')}
            content={
              <Typography>
                {t(
                  'confirmMoveText',
                  'Are you sure you want to move these containers? Only containers that have no triggered move will be triggered',
                )}
              </Typography>
            }
            onClose={() => setIsConfirmationDialogOpen(false)}
            onConfirm={handleMoveContainers}
          />
          <ConfirmationDialogContainer
            open={carrierVisitAllocationRulesV2UIStore.isDangerousGoodsDialogOpen}
            title={t('confirmAllocation', 'Confirm Allocation')}
            content={
              <Alert severity='warning'>{carrierVisitAllocationRulesV2UIStore.dialogText}</Alert>
            }
            onClose={() => carrierVisitAllocationRulesV2UIStore.toggleDangerousGoodsDialog()}
            onConfirm={handleConfirmAllocation}
          />
        </Box>
      </FormProvider>
    )
  },
)
