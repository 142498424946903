import { YardPositionDto } from '@storage/app/api'
import { ShiftedContainerDto } from '@storage/components/container-shiftings/SearchContainerAutoComplete'
import { action, computed, makeObservable, observable } from 'mobx'
import { ContainerAutoCompleteStore } from './container-auto-complete.store'

interface ShiftedFormItems extends ShiftedContainerDto {
  isConfirmButtonDisabled?: boolean
}
export class ContainerShiftingsFormStore {
  containersToBeShiftedFormItems: Map<string, ShiftedFormItems> = new Map()
  containerCurrentPositions: Map<string, YardPositionDto | undefined> = new Map()

  constructor(private autoCompleteStore: ContainerAutoCompleteStore) {
    makeObservable(this, {
      containersToBeShiftedFormItems: observable,
      containerCurrentPositions: observable,
      setContainerCurrentPosition: action,
      disableConfirmButton: action,
      addNewFormItem: action,
      removeFormItem: action,
      resetFormItems: action,
      formItems: computed,
    })
  }

  resetFormItems() {
    this.containersToBeShiftedFormItems = new Map()
  }
  addNewFormItem() {
    this.containersToBeShiftedFormItems.set(this.autoCompleteStore.value!.id, {
      id: this.autoCompleteStore.value!.id,
      containerNumber: this.autoCompleteStore.value!.containerNumber,
      previousLocation: this.autoCompleteStore.value!.previousLocation,
    })
  }
  removeFormItem(id: string) {
    this.containersToBeShiftedFormItems.delete(id)
  }

  get formItems() {
    return Array.from(this.containersToBeShiftedFormItems.values())
  }

  setContainerCurrentPosition(id: string, position?: YardPositionDto) {
    this.containerCurrentPositions.set(id, position)
  }

  disableConfirmButton(id: string, disable: boolean) {
    const formItem = this.containersToBeShiftedFormItems.get(id)
    if (!formItem) {
      return
    }
    this.containersToBeShiftedFormItems.set(id, { ...formItem, isConfirmButtonDisabled: disable })
  }
}
