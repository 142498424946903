import { FormProps } from '@storage/app/models'
import { ControlledCheckbox } from '@storage/components/form-controls'
import useFormWithSchema from '@storage/hooks/use-form-with-schema.hook'
import { useStores } from '@storage/hooks/use-stores.hook'
import { Box, FormGroup } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { useEffect } from 'react'
import { ContainerInformationWidgetProfile } from './container-information-widget-form.profile'
import {
  schema,
  yupContainerInformationValidationField,
} from './container-information-widget-form.scheme'

interface Props extends FormProps {
  onSubmit: (formValues: ContainerInformationWidgetProfile) => Promise<void> | void
  id: string
  defaultValues: ContainerInformationWidgetProfile
}

export const ContainerInformationWidgetForm = ({ id, defaultValues, onSubmit }: Props) => {
  const { handleSubmit, formState, control } = useFormWithSchema<ContainerInformationWidgetProfile>(
    schema,
    defaultValues,
  )
  const { t } = useTranslate()
  const { snackbarStore } = useStores()
  const { errors } = formState

  useEffect(() => {
    // handle one possible validation for unchecking all checkboxes
    if (Object.keys(errors).length) {
      snackbarStore.showMessage(
        t(yupContainerInformationValidationField, 'At least one attribute should be selected'),
        'error',
      )
    }
  }, [errors, snackbarStore, t])

  return (
    <Box
      id={id}
      sx={{ display: 'flex' }}
      component='form'
      noValidate
      autoComplete='off'
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormGroup sx={{ flex: 1 }}>
        <ControlledCheckbox
          name='isSizeSelected'
          label={t('size', 'Size')}
          formState={formState}
          control={control}
        />
        <ControlledCheckbox
          name='isCargoStatusSelected'
          label={t('cargoStatus', 'Cargo Status')}
          formState={formState}
          control={control}
        />
      </FormGroup>
      <FormGroup sx={{ flex: 1 }}>
        <ControlledCheckbox
          name='isOperatorSelected'
          label={t('operator', 'Operator')}
          formState={formState}
          control={control}
        />
        <ControlledCheckbox
          name='isPortOfDischargeSelected'
          label={t('portOfDischarge', 'Port of Discharge')}
          formState={formState}
          control={control}
        />
      </FormGroup>
    </Box>
  )
}
