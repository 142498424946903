import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import { BerthResponseDto, BerthSide } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { IVesselVisitItem } from '@planning/rt-stores/vesselVisit/VesselVisitItem'
import { vesselVisitService } from '@planning/services'
import { useTranslate } from '@tolgee/react'
import { Dayjs } from 'dayjs'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { BerthAssignmentAutoComplete } from './Berth/BerthAssignmentAutoComplete'

export interface ICreateVisitBreakFormData {
  breakFrom: Dayjs
  breakTo: Dayjs
  description: string
}

interface IProps {
  vesselVisitItem: IVesselVisitItem
}

export const BerthPlanningDialog = observer(({ vesselVisitItem }: IProps) => {
  const { t } = useTranslate()

  const { data: vesselVisit, berths: assignedBerths } = vesselVisitItem

  const { appViewStore, berthItemStore, dialogStore } = usePlanningStore()

  const berths = useMemo(
    () => _.values(berthItemStore.elements)?.map(item => item.data) ?? [],
    [berthItemStore.elements],
  )

  const title = t('updateBerthData', 'Update berth data')

  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent
        sx={{
          width: '560px',
        }}
      >
        <Grid item xs={12} lg={7}>
          <BerthAssignmentAutoComplete
            berths={berths}
            assignedBerths={assignedBerths}
            onBerthAssignmentChange={async (assignedBerths: BerthResponseDto[]) => {
              try {
                await vesselVisitService.assignBerths(vesselVisit.id, assignedBerths)
              } catch (error) {
                appViewStore.setShowAlert(
                  'error',
                  t('failedToAssignBerths', 'Failed to assign berths'),
                )
              }
            }}
          />
        </Grid>

        <Grid item xs={12} lg={5}>
          <FormControl fullWidth>
            <InputLabel>{t('side', 'Side')}</InputLabel>
            <Select
              data-cy='berth-side-select'
              value={vesselVisit.berthSide ?? ''}
              label='Side'
              onChange={async e => {
                if (e.target.value && vesselVisit) {
                  const berthSide = e.target.value as BerthSide
                  await vesselVisitService.put({
                    ...vesselVisit,
                    berthSide: berthSide,
                    vesselIds: vesselVisit.carrierIds,
                  })
                }
              }}
            >
              <MenuItem value={BerthSide.Port}>{BerthSide.Port}</MenuItem>
              <MenuItem value={BerthSide.Starboard}>{BerthSide.Starboard}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <DialogActions>
          <Button onClick={dialogStore.close}>{t('close', 'Close')}</Button>
        </DialogActions>
      </DialogContent>
    </>
  )
})
