import { OperationType, WorkInstructionStatus } from '@operations/app/api'
import { FormProps, SelectOption } from '@operations/app/models'
import { ControlledCheckbox, ControlledTextField } from '@operations/components/form-controls'
import { ControlledAutocomplete } from '@operations/components/form-controls/ControlledAutocomplete'
import { Grid, Stack, Tab, Tabs, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { WorkInstructionFilter, defaultValues } from './work-instructions-filters.model'

interface Props extends FormProps {
  filter?: WorkInstructionFilter
  equipments: SelectOption[]
  hasJobs: boolean
  onSubmit: (formValues: WorkInstructionFilter) => Promise<void> | void
}

export const WorkInstructionFilterForm = ({ id, filter, equipments, hasJobs, onSubmit }: Props) => {
  const { t } = useTranslate()
  const { handleSubmit, formState, control, reset } = useForm<WorkInstructionFilter>({
    defaultValues: defaultValues,
  })
  const [currentTab, setCurrentTab] = useState(0)

  const handleChange = (_: any, newValue: number) => {
    setCurrentTab(newValue)
  }

  useEffect(() => {
    if (filter) {
      reset(filter)
    }
  }, [filter, reset])

  const processOptions: SelectOption[] = [
    { label: t('ops_inbound', 'Discharge'), value: OperationType.Inbound },
    { label: t('ops_outbound', 'Load'), value: OperationType.Outbound },
  ]

  const statusOptions: SelectOption[] = [
    { label: t('ops_todo', 'Pending'), value: WorkInstructionStatus.Todo },
    { label: t('ops_inProgress', 'In progress'), value: WorkInstructionStatus.InProgress },
    { label: t('ops_finished', 'Finished'), value: WorkInstructionStatus.Finished },
  ]

  return (
    <Stack
      id={id}
      component='form'
      noValidate
      autoComplete='off'
      spacing={1}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Tabs value={currentTab} onChange={handleChange}>
        <Tab label={t('instructions', 'Instructions')} />
        {hasJobs && <Tab label={t('jobs', 'Jobs')} />}
      </Tabs>
      {currentTab === 0 && (
        <Stack padding={1}>
          <ControlledCheckbox
            name='process'
            formState={formState}
            control={control}
            options={processOptions}
            multiple
            label={t('process', 'Process')}
          />
        </Stack>
      )}
      {currentTab === 1 && (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant='subtitle2'>{t('equipmentName', 'Equipment name')}</Typography>
          </Grid>
          <Grid item xs={6}>
            <ControlledAutocomplete
              name='equipment'
              formState={formState}
              control={control}
              label={t('equipments', 'Equipments')}
              options={equipments.map(x => ({ ...x, groupBy: t(x.groupBy!) }))}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant='subtitle2'>{t('position', 'Position')}</Typography>
          </Grid>

          <Grid item xs={3}>
            <ControlledTextField<WorkInstructionFilter>
              name='origin'
              formState={formState}
              control={control}
              label={t('origin', 'Origin')}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <ControlledTextField<WorkInstructionFilter>
              name='destination'
              formState={formState}
              control={control}
              label={t('destination', 'Destination')}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledCheckbox
              name='status'
              formState={formState}
              control={control}
              options={statusOptions}
              multiple
              label={t('status', 'Status')}
            />
          </Grid>
        </Grid>
      )}
    </Stack>
  )
}
