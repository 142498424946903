import { CargoUnitDto, CargoUnitsApi } from '@operations/app/api'

import { createApiClient } from '@operations/app/http-client'

import { BaseEntityStore } from './base/BaseEntityStore'

export class CargoUnitStore extends BaseEntityStore<CargoUnitDto> {
  async loadEmpties(cargoUnitId?: number) {
    const { data } = await createApiClient(CargoUnitsApi).getAvailableEmpties(cargoUnitId)

    this.updateStoreItems(data)
  }

  async loadReefersOnTerminal() {
    const { data } = await createApiClient(CargoUnitsApi).getReefersOnTerminal()

    this.updateStoreItems(data)
  }

  async loadOnTerminal() {
    const { data } = await createApiClient(CargoUnitsApi).getOnTerminal()

    this.updateStoreItems(data)
  }
}
