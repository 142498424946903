import { Button, Stack, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'

interface Props {
  result: string
  filtering?: boolean
  onClearFilter?: () => void
}
export const NoListResult = ({ filtering, result, onClearFilter }: Props) => {
  const { t } = useTranslate()

  const handleClearClick = () => {
    if (onClearFilter) onClearFilter()
  }

  return (
    <Stack
      justifyContent='center'
      alignItems={'center'}
      gap={theme => theme.customSpacing.s}
      minHeight='50vh'
    >
      <Typography textAlign='center' variant='subtitle1'>
        {result}
      </Typography>
      {filtering && (
        <>
          <Typography align='center' variant='body1' width='18rem'>
            {t(
              'tryAdjustingYourSearchOptions',
              'Try adjusting your search to find what you are looking for.',
            )}
          </Typography>
          <Button color='inherit' onClick={handleClearClick}>
            {t('clearSearch', 'Clear search')}
          </Button>
        </>
      )}
    </Stack>
  )
}
