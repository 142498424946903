import { NamedId, TenantsApi } from '@admin/app/api'
import { makeObservable, observable, runInAction } from 'mobx'

export class TimezonesStore {
  timezones: NamedId[] = []
  cultures: NamedId[] = []

  constructor(private api: TenantsApi) {
    makeObservable(this, {
      timezones: observable,
      cultures: observable,
    })
  }

  async load() {
    if (this.timezones.length > 0 && this.cultures.length > 0) return

    const { data } = await this.api.tenantsGetTimezonesAndCulture()

    runInAction(() => {
      this.timezones = data.timezones
      this.cultures = data.cultureCodes
    })
  }
}
