import { Drawer } from '@mui/material'
import { useTolgee } from '@tolgee/react'
import { JobStepper, JobStepperHeader, useOperationsStore } from '@tom-ui/operations'
import { orderService } from '@tom-ui/planning'
import {
  ContainerShiftings,
  YardOperationControl,
  useStorageStore,
  useStores,
} from '@tom-ui/storage'
import { observer } from 'mobx-react-lite'

export const EquipmentOperatorStepper = observer(() => {
  const { equipmentOperatorStepperUIStore } = useOperationsStore()
  const { yardOperationControlStore } = useStorageStore()
  const { containerShiftingsStore, containerShiftingsFormStore, snackbarStore } = useStores()
  const { t } = useTolgee()

  const handleConfirm = async (currentJob: any) => {
    const jobOriginType = equipmentOperatorStepperUIStore.getOriginType(
      currentJob.workInstructionId,
    )
    const jobDestinationType = equipmentOperatorStepperUIStore.getDestinationType(
      currentJob.workInstructionId,
    )
    try {
      if (currentJob.orderId && currentJob.grossWeight) {
        await orderService.updateWeight({
          id: currentJob.orderId,
          grossWeight: currentJob.grossWeight,
        })
      }

      await yardOperationControlStore.validateDGStackingAndSegregationRulesViolation()

      await equipmentOperatorStepperUIStore.finishJob(currentJob)

      if (jobDestinationType === 'Yard') {
        await yardOperationControlStore.onConfirm()
      }

      if (jobOriginType === 'Yard') {
        containerShiftingsFormStore.resetFormItems()
        containerShiftingsStore.resetNumberOfShiftings()
      }
    } catch (ex: any) {
      equipmentOperatorStepperUIStore.equipmentOperatorUIStore.showJob(currentJob.workInstructionId)
      equipmentOperatorStepperUIStore.cancelStartJob(currentJob.workInstructionId)
    }
  }

  const handleOnClose = () => {
    containerShiftingsFormStore.resetFormItems()
  }

  const handlePicks = async (turnoverId: string) => {
    try {
      await containerShiftingsStore.updateContainerPosition(
        turnoverId,
        containerShiftingsFormStore.containerCurrentPositions.get(turnoverId)!,
      )
      equipmentOperatorStepperUIStore.setPicks(containerShiftingsStore.numberOfShiftingsDone)
      snackbarStore.showMessage(
        t('updateContainerPositionSuccess', 'The container position was successfully updated'),
        'success',
      )
      containerShiftingsFormStore.disableConfirmButton(turnoverId, true)
    } catch (ex: any) {
      snackbarStore.showMessage(
        t('updateContainerPositionWarning', 'The container position was not successfully updated'),
        'warning',
      )
    }
  }

  return (
    <Drawer
      open={equipmentOperatorStepperUIStore.isOpen}
      anchor='right'
      PaperProps={{
        sx: {
          width: '100vw',
        },
      }}
      data-cy='job-container-stepper'
    >
      <JobStepperHeader onConfirm={handleConfirm} onClose={handleOnClose} />

      <JobStepper
        EditDestinationComponent={YardOperationControl}
        ConfirmPickupComponent={ContainerShiftings}
        handlePicks={handlePicks}
      />
    </Drawer>
  )
})
