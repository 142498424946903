import { Box } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { ReactNode } from 'react'

interface Props {
  children: ReactNode
}
export const AttributesContainer = ({ children }: Props) => {
  const theme = useMinimalsTheme()

  return (
    <Box
      sx={{
        padding: theme.customSpacing.m,
        gap: theme.customSpacing.xl,
        display: 'flex',
        backgroundColor: theme.palette.grey[100],
        overflowX: 'auto',
      }}
    >
      {children}
    </Box>
  )
}
