import { Box } from '@mui/material'
import { RoutingInfoStore } from '@storage/stores/routing-info.store'
import { useTolgee } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import LocationInfo from './LocationInfo'
import { NonNumeric } from './RoutingInfo'

interface Props {
  pickUpContainers: string[]
  nonNumeric: NonNumeric[]
  routingInfoStore: RoutingInfoStore
}

const ContainerPickUpInfo = observer(
  ({ pickUpContainers, routingInfoStore, nonNumeric }: Props) => {
    const { t } = useTolgee()

    useEffect(() => {
      const loadPickUpInfo = async () => {
        await routingInfoStore.fetchPickUpInfo(pickUpContainers)
      }
      loadPickUpInfo()
    }, [pickUpContainers, routingInfoStore])

    return (
      <Box>
        {nonNumeric.length > 0 &&
          nonNumeric.map((nn, index) => (
            <Box key={index} sx={{ marginBottom: '16px' }}>
              <LocationInfo key={index} isNonNumeric nonNumeric={nn} action={'Pick up'} />
            </Box>
          ))}
        {routingInfoStore.pickUpInfo.length > 0 &&
          routingInfoStore.pickUpInfo.map(info => {
            if (!info.blockName) {
              routingInfoStore.incrementWarnings()
            }
            return (
              <Box key={info.containerNumber} sx={{ marginBottom: '16px' }}>
                <LocationInfo
                  key={info.containerNumber}
                  blockName={info.blockName ?? t('noLocation', 'No Location')}
                  containerNumber={info.containerNumber}
                  action={'Pick up'}
                />
              </Box>
            )
          })}
      </Box>
    )
  },
)

export default ContainerPickUpInfo
