import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

export const FeatureFlag: FC<{ children: React.ReactNode; name: string }> = observer(
  ({ children, name: featureName }) => {
    const active = useBooleanFlagValue(featureName, false)

    if (active) {
      return <>{children}</>
    }
    return <></>
  },
)
