import { Point } from '@storage/app/models'
import { BoundingBox, Line, ScalePosition } from '@storage/types'

const adjustGridSizeForScale = (scale: number) => {
  const scaleThresholds = [0.2, 0.5, 1, 1.5, 2]
  const gridSizeOptions = [400, 300, 100, 50, 20]

  for (let i = 0; i < scaleThresholds.length; i++) {
    if (scale <= scaleThresholds[i]) {
      return gridSizeOptions[i]
    }
  }

  return gridSizeOptions[gridSizeOptions.length - 1]
}

/**
 * Calculates the start and end points for grid lines based on the stage's dimensions,
 * zoom level, and current offsets. This function generates both horizontal and
 * vertical lines to cover the entire visible area of the stage, taking into account
 * the current scale (zoom level) and pan offsets. The grid lines are spaced according
 * to a predefined grid size.
 *
 * @param {number} stageWidth - The current width of the stage (viewport)
 * @param {number} stageHeight - The current height of the stage (viewport)
 * @param {number} scale - The current zoom level of the stage
 * @param {number} offsetX - The current horizontal pan offset of the stage.
 * @param {number} offsetY - The current vertical pan offset of the stage.
 *
 * @returns {Array<Line>} An array of line objects, where each object contains
 *                          the start and end points (`startX`, `startY`, `endX`, `endY`)
 *                          for a line. This array includes both horizontal and vertical
 *                          lines needed to render a grid covering the visible area of
 *                          the stage, adjusted for the current scale and pan offsets.
 */
export const calculateGridLines = (
  stageWidth: number,
  stageHeight: number,
  scale: number,
  offsetX: number,
  offsetY: number,
): Array<Line> => {
  const lines = []
  const dynamicGridSize = adjustGridSizeForScale(scale)

  const startX = Math.floor(-offsetX / scale / dynamicGridSize) * dynamicGridSize
  const endX = startX + stageWidth / scale + dynamicGridSize
  const startY = Math.floor(-offsetY / scale / dynamicGridSize) * dynamicGridSize
  const endY = startY + stageHeight / scale + dynamicGridSize

  // Horizontal lines
  for (let y = startY; y < endY; y += dynamicGridSize) {
    lines.push({ startX, startY: y, endX, endY: y })
  }

  // Vertical lines
  for (let x = startX; x < endX; x += dynamicGridSize) {
    lines.push({ startX: x, startY, endX: x, endY })
  }

  return lines
}

/**
 * Calculates the bounding box for a set of points.
 *
 * @param points Array of points
 * @returns The bounding box as {minX, maxX, minY, maxY}
 */
export const calculateBoundingBox = (points: Point[]): BoundingBox => {
  if (points.length === 0) {
    return { minX: 0, maxX: 0, minY: 0, maxY: 0 }
  }

  const minX = Math.min(...points.map(p => p.x))
  const maxX = Math.max(...points.map(p => p.x))
  const minY = Math.min(...points.map(p => p.y))
  const maxY = Math.max(...points.map(p => p.y))

  return { minX, maxX, minY, maxY }
}

/**
 * Calculates the scale and position to fit and center a bounding box on a Konva stage.
 *
 * @param points Array of points to include in the view
 * @param stageWidth Width of the Konva stage
 * @param stageHeight Height of the Konva stage
 * @param margin Optional margin around the bounding box
 * @returns The scale and position for the stage
 */
export function calculateScalePosition(
  boundingBox: BoundingBox,
  stageWidth: number,
  stageHeight: number,
  margin = 500,
): ScalePosition {
  const { minX, maxX, minY, maxY } = boundingBox

  // Adjust the bounding box by the margin
  const adjustedMinX = minX
  const adjustedMaxX = maxX + margin
  const adjustedMinY = minY - margin
  const adjustedMaxY = maxY + margin

  // Calculate the width and height of the adjusted bounding box
  const boxWidth = adjustedMaxX - adjustedMinX
  const boxHeight = adjustedMaxY - adjustedMinY

  // Calculate the scale to fit the adjusted bounding box in the viewport
  const scaleX = stageWidth / boxWidth
  const scaleY = stageHeight / boxHeight
  const scaleToFit = Math.min(scaleX, scaleY)

  // Calculate the center of the adjusted bounding box
  const boxCenterX = (adjustedMinX + adjustedMaxX) / 2
  const boxCenterY = (adjustedMinY + adjustedMaxY) / 2

  // Calculate the offset to center the adjusted bounding box in the stage
  const offsetX = stageWidth / 2 - boxCenterX * scaleToFit
  const offsetY = stageHeight / 2 - boxCenterY * scaleToFit

  return {
    scale: scaleToFit,
    position: { x: offsetX, y: offsetY },
  }
}

export const isBoundingBoxEmpty = (boundingBox: BoundingBox) => {
  return Object.values(boundingBox).every(value => value === 0)
}
