import _ from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import { OrderListUploadViewStoreV2 } from './OrderListUploadViewStoreV2'

export class OrderListUploadGroupStore {
  isAddMode = false
  addedGroups: string[] = []
  selectedGroup: string = OrderListUploadViewStoreV2.defaultListName
  currentGroup = ''

  constructor(private parentStore: OrderListUploadViewStoreV2) {
    makeObservable(this, {
      addedGroups: observable,
      currentGroup: observable,
      selectedGroup: observable,
      isAddMode: observable,
      groups: computed,
      addGroup: action,
      setSelectedGroup: action,
      setAddMode: action,
      setCurrentGroup: action,
      setAddedGroups: action,
    })
  }

  get groups() {
    const exisitingGroups = _(this.parentStore.dataStore.ordersFromVisit)
      .map(o => o.listName ?? OrderListUploadViewStoreV2.defaultListName)
      // .filter(group => group !== OrderListUploadViewStoreV2.defaultListName)
      .uniq()
      .value()

    if (exisitingGroups.length) this.setSelectedGroup(exisitingGroups[0])

    return exisitingGroups.concat(this.addedGroups)
  }

  addGroup = () => {
    if (!this.currentGroup.trim().length) return

    this.setSelectedGroup(this.currentGroup)
    this.addedGroups.unshift(this.currentGroup)
    this.setCurrentGroup('')
    this.setAddMode(false)
  }

  setSelectedGroup = (group: string) => {
    this.selectedGroup = group
  }

  setAddMode = (enabled: boolean) => {
    this.isAddMode = enabled
  }

  setCurrentGroup = (group: string) => {
    this.currentGroup = group
  }

  setAddedGroups = (groups: string[]) => {
    this.addedGroups = groups
  }

  reset = () => {
    this.setCurrentGroup('')
    this.setSelectedGroup('')
    this.setAddMode(false)
    this.setAddedGroups([])
  }
}
