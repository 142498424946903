import {
  BerthsApi,
  CarrierVisitsApi,
  PiersApi,
  YardBlockBaysApi,
  YardBlockGeometriesApi,
  YardBlockRowsApi,
  YardBlocksApi,
  YardBlockSlotsApi,
  YardBlockStacksApi,
} from '@storage/app/api'
import { createApiClient } from '@storage/app/http-client'
import { LS_DASHBOARD_CONTAINER_INFORMATION_WIDGET_KEY } from '@storage/constants/local-storage.const'
import { DGStackingRuleStore } from '@storage/features/dg-stacking-rules/stores/dg-stacking-rule.store'
import { DGStackingRulesTableUIStore } from '@storage/features/dg-stacking-rules/stores/dg-stacking-rules-table.ui-store'
import { GeneralCargoOrderReservationUIStore } from '@storage/features/general-cargo-order-reservation/stores/general-cargo-order-reservation.ui-store'
import { GeneralCargoStorageTrackerUIStore } from '@storage/features/general-cargo-storage-tracker/stores/general-cargo-storage-tracker.ui-store'
import { RailTracksStore } from '@storage/features/rail-tracks/stores/rail-tracks.store'
import { RailTracksUIStore } from '@storage/features/rail-tracks/stores/rail-tracks.ui-store'
import { WeightClassContainerUIStore } from '@storage/features/weight-classes/stores/weight-class-container-ui-store'
import { WeightClassStore } from '@storage/features/weight-classes/stores/weight-class.store'
import { yardManagementHubConnection } from '@storage/hubs/yard-management.hub-connection'
import { ContainerInformationWidgetProfile } from '@storage/pages/container-turnovers/components/container-information-widget-form/container-information-widget-form.profile'
import { ManualInputValidationStore } from '@storage/pages/container-turnovers/stores/manual-input-validation.store'
import { GeneralCargoAreaUIStore } from '@storage/pages/general-cargo-areas/stores/general-cargo-area.ui-store'
import { GeneralCargoInventoryStore } from '@storage/pages/general-cargo-inventory/stores/general-cargo-inventory.store'
import { GeneralCargoInventoryUIStore } from '@storage/pages/general-cargo-inventory/stores/general-cargo-inventory.ui-store'
import { GeneralCargoStockStore } from '@storage/pages/general-cargo-inventory/stores/general-cargo-stock.store'
import { OperatorContainerTurnoverStore } from '@storage/pages/operator-container-turnovers-list/stores/operator-container-turnover.store'
import {
  allocationRuleTemplatesService,
  carrierVisitsService,
  containerPlanningService,
  containerTurnoverService,
  dgSegregationRuleService,
  dgStackingRuleService,
  generalCargoAreaService,
  generalCargoStockService,
  railTracksService,
  weightClassService,
  yardBlockService,
} from '@storage/services'
import { LocalStorageService } from '@storage/services/local-storage.service'
import { authStore, AuthStore, damageReportStore, DamageReportStore } from '@tom-ui/utils'
import { GeneralCargoAreaStore } from '../pages/general-cargo-areas/stores/general-cargo-area.store'
import { AlertStore } from './alert.store'
import { AppStore } from './app.store'
import { BerthStore } from './berth.store'
import { CarrierVisitStore } from './carrier-visit.store'
import { ConfirmationDialogStore } from './confirmation-dialog.store'
import { ContainerAutoCompleteStore } from './container-auto-complete.store'
import { ContainerInformationWidgetStore } from './container-information-widget.store'
import { ContainerShiftingsFormStore } from './container-shiftings-form.store'
import { ContainerShiftingsStore } from './container-shiftings.store'
import { ContainerTurnoverStore } from './container-turnover.store'
import { DialogUtilStore } from './dialog.util-store'
import { GeneralCargoPlanningStore } from './general-cargo-planning.store'
import { IssueStore } from './issue.store'
import { PierStore } from './pier.store'
import { RoutingInfoStore } from './routing-info.store'
import { SnackbarStore } from './snackbar.store'
import { TenantConfigStore } from './tenantConfig.store'
import { YardBlockBayStore } from './yard-block-bay.store'
import { YardBlockRowStore } from './yard-block-row.store'
import { YardBlockSlotStore } from './yard-block-slot.store'
import { YardBlockStackStore } from './yard-block-stack.store'
import { YardBlockSummaryStore } from './yard-block-summary.store'
import { YardBlockStore } from './yard-block.store'
import { YardInconsistenciesStore } from './yard-inconsistencies-store'
import { DGSegregationRulesTableUIStore } from '@storage/features/dg-segregation-rules/stores/dg-segregation-rules-table.ui-store'
import { DGSegregationRuleDataStore } from './dg-segregation-rule.data-store'
import { CarrierVisitAllocationRulesV2Store } from '@storage/features/carrier-visit-allocation-rules/stores/carrier-visit-allocation-rules-v2.store'
import { CarrierVisitAllocationRulesV2UIStore } from '@storage/features/carrier-visit-allocation-rules/stores/carrier-visit-allocation-rules-v2.ui-store'
import { AllocationRuleTemplatesV2UIStore } from '@storage/features/carrier-visit-allocation-rules/stores/allocation-rule-templates-v2.ui-store'
import { AllocationRuleTemplatesV2Store } from '@storage/features/carrier-visit-allocation-rules/stores/allocation-rule-templates-v2.store'

export class RootStore {
  appStore: AppStore

  authStore: AuthStore

  damageReportStore: DamageReportStore

  yardBlockStore: YardBlockStore

  yardBlockRowStore: YardBlockRowStore

  yardBlockBayStore: YardBlockBayStore

  yardBlockSlotStore: YardBlockSlotStore

  pierStore: PierStore

  berthStore: BerthStore

  containerTurnoverStore: ContainerTurnoverStore

  carrierVisitStore: CarrierVisitStore

  snackbarStore: SnackbarStore

  confirmationDialogStore: ConfirmationDialogStore

  yardBlockStackStore: YardBlockStackStore

  yardBlockSummaryStore: YardBlockSummaryStore

  containerInformationWidgetStore: ContainerInformationWidgetStore

  routingInfoStore: RoutingInfoStore

  issueStore: IssueStore

  yardInconsistenciesStore: YardInconsistenciesStore

  tenantConfigStore: TenantConfigStore

  operatorContainerTurnoverStore: OperatorContainerTurnoverStore

  generalCargoAreaStore: GeneralCargoAreaStore

  generalCargoAreaUIStore: GeneralCargoAreaUIStore

  generalCargoStockStore: GeneralCargoStockStore

  generalCargoInventoryStore: GeneralCargoInventoryStore

  generalCargoPlanningStore: GeneralCargoPlanningStore

  generalCargoInventoryUIStore: GeneralCargoInventoryUIStore

  generalCargoStorageTrackerUIStore: GeneralCargoStorageTrackerUIStore

  generalCargoOrderReservationUIStore: GeneralCargoOrderReservationUIStore

  allocationRuleTemplatesV2Store: AllocationRuleTemplatesV2Store

  dialogUtilStore: DialogUtilStore

  manualInputValidationStore: ManualInputValidationStore

  containerShiftingsStore: ContainerShiftingsStore

  containerAutoCompleteStore: ContainerAutoCompleteStore

  containerShiftingsFormStore: ContainerShiftingsFormStore

  alertStore: AlertStore

  railTracksStore: RailTracksStore

  railTracksUIStore: RailTracksUIStore

  allocationRuleTemplatesV2UIStore: AllocationRuleTemplatesV2UIStore

  carrierVisitAllocationRulesV2Store: CarrierVisitAllocationRulesV2Store

  carrierVisitAllocationRulesV2UIStore: CarrierVisitAllocationRulesV2UIStore

  weightClassStore: WeightClassStore

  weightClassContainerUIStore: WeightClassContainerUIStore

  dgStackingRuleStore: DGStackingRuleStore

  dgStackingRulesTableUIStore: DGStackingRulesTableUIStore

  dgSegregationRuleDataStore: DGSegregationRuleDataStore

  dgSegregationRulesTableUIStore: DGSegregationRulesTableUIStore

  constructor() {
    this.appStore = new AppStore()
    this.authStore = authStore
    this.dialogUtilStore = new DialogUtilStore()
    this.damageReportStore = damageReportStore
    this.alertStore = new AlertStore()
    this.snackbarStore = new SnackbarStore()
    this.confirmationDialogStore = new ConfirmationDialogStore()
    this.issueStore = new IssueStore()
    this.yardInconsistenciesStore = new YardInconsistenciesStore()

    this.yardBlockSummaryStore = new YardBlockSummaryStore(yardBlockService)

    this.containerInformationWidgetStore = new ContainerInformationWidgetStore(
      yardBlockService,
      new LocalStorageService<ContainerInformationWidgetProfile>(
        LS_DASHBOARD_CONTAINER_INFORMATION_WIDGET_KEY,
      ),
    )

    this.yardBlockStackStore = new YardBlockStackStore(
      createApiClient(YardBlockStacksApi),
      this.snackbarStore,
      yardManagementHubConnection,
      this.yardBlockSummaryStore,
    )

    this.yardBlockSlotStore = new YardBlockSlotStore(
      createApiClient(YardBlockSlotsApi),
      yardManagementHubConnection,
    )

    this.yardBlockRowStore = new YardBlockRowStore(
      createApiClient(YardBlockRowsApi),
      this.yardBlockSlotStore,
    )

    this.yardBlockBayStore = new YardBlockBayStore(
      createApiClient(YardBlockBaysApi),
      this.yardBlockSlotStore,
    )

    this.yardBlockStore = new YardBlockStore(
      createApiClient(YardBlocksApi),
      createApiClient(YardBlockGeometriesApi),
      yardManagementHubConnection,
      this.yardBlockBayStore,
      this.yardBlockRowStore,
      this.yardBlockSlotStore,
      this.yardBlockStackStore,
    )

    this.pierStore = new PierStore(createApiClient(PiersApi))
    this.berthStore = new BerthStore(createApiClient(BerthsApi), yardManagementHubConnection)
    this.carrierVisitStore = new CarrierVisitStore(
      createApiClient(CarrierVisitsApi),
      this.issueStore,
      yardManagementHubConnection,
    )
    this.containerTurnoverStore = new ContainerTurnoverStore(
      containerTurnoverService,
      this.carrierVisitStore,
      yardManagementHubConnection,
    )

    this.routingInfoStore = new RoutingInfoStore()
    this.tenantConfigStore = new TenantConfigStore()

    this.operatorContainerTurnoverStore = new OperatorContainerTurnoverStore(
      containerPlanningService,
      this.snackbarStore,
      this.alertStore,
    )

    this.generalCargoAreaStore = new GeneralCargoAreaStore(generalCargoAreaService)

    this.generalCargoAreaUIStore = new GeneralCargoAreaUIStore(this.generalCargoAreaStore)

    this.generalCargoInventoryStore = new GeneralCargoInventoryStore(generalCargoStockService)

    this.generalCargoStockStore = new GeneralCargoStockStore(
      generalCargoStockService,
      this.generalCargoInventoryStore,
      yardManagementHubConnection,
    )

    this.generalCargoPlanningStore = new GeneralCargoPlanningStore(generalCargoStockService)

    this.generalCargoInventoryUIStore = new GeneralCargoInventoryUIStore(
      this.generalCargoInventoryStore,
    )

    this.generalCargoStorageTrackerUIStore = new GeneralCargoStorageTrackerUIStore(
      this.generalCargoAreaStore,
      this.generalCargoStockStore,
      this.generalCargoPlanningStore,
    )

    this.generalCargoOrderReservationUIStore = new GeneralCargoOrderReservationUIStore(
      this.generalCargoPlanningStore,
      this.generalCargoStorageTrackerUIStore,
      this.snackbarStore,
    )

    this.manualInputValidationStore = new ManualInputValidationStore(
      this.yardBlockStore,
      this.yardBlockBayStore,
      this.yardBlockRowStore,
      this.tenantConfigStore,
    )

    this.containerAutoCompleteStore = new ContainerAutoCompleteStore()

    this.containerShiftingsStore = new ContainerShiftingsStore(this.alertStore)

    this.containerShiftingsFormStore = new ContainerShiftingsFormStore(
      this.containerAutoCompleteStore,
    )

    this.railTracksStore = new RailTracksStore(railTracksService)

    this.railTracksUIStore = new RailTracksUIStore(this.railTracksStore, this.dialogUtilStore)

    this.allocationRuleTemplatesV2Store = new AllocationRuleTemplatesV2Store(
      allocationRuleTemplatesService,
    )

    this.carrierVisitAllocationRulesV2Store = new CarrierVisitAllocationRulesV2Store(
      carrierVisitsService,
    )

    this.allocationRuleTemplatesV2UIStore = new AllocationRuleTemplatesV2UIStore(
      this.allocationRuleTemplatesV2Store,
      this.carrierVisitAllocationRulesV2Store,
      this.snackbarStore,
      this.dialogUtilStore,
    )

    this.carrierVisitAllocationRulesV2UIStore = new CarrierVisitAllocationRulesV2UIStore(
      this.carrierVisitAllocationRulesV2Store,
      this.alertStore,
      this.snackbarStore,
      containerPlanningService,
      new DialogUtilStore(),
      new DialogUtilStore(),
    )

    this.weightClassStore = new WeightClassStore(weightClassService)

    this.weightClassContainerUIStore = new WeightClassContainerUIStore(
      this.weightClassStore,
      this.alertStore,
      this.snackbarStore,
    )

    this.dgStackingRuleStore = new DGStackingRuleStore(dgStackingRuleService)

    this.dgStackingRulesTableUIStore = new DGStackingRulesTableUIStore(
      this.dgStackingRuleStore,
      this.snackbarStore,
      this.dialogUtilStore,
    )

    this.dgSegregationRuleDataStore = new DGSegregationRuleDataStore(dgSegregationRuleService)

    this.dgSegregationRulesTableUIStore = new DGSegregationRulesTableUIStore(
      this.dgSegregationRuleDataStore,
      this.dialogUtilStore,
      this.snackbarStore,
    )
  }
}
