import CloseIcon from '@mui/icons-material/Close'
import { Card, CardContent, CardHeader, IconButton } from '@mui/material'
import { ReactNode } from 'react'

interface PopupProps {
  children: ReactNode
  onClose: () => void
  position?: any
  title?: string
}

export const Popup = ({ children, onClose, position, title }: PopupProps) => {
  return (
    <Card
      sx={{
        left: position.x,
        top: position.y,
        position: 'absolute',
        width: '165px',
      }}
    >
      <CardHeader
        action={
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        }
        title={title}
      />
      <CardContent>{children}</CardContent>
    </Card>
  )
}
