import { BreakTimesApi, CreateBreakTimeCommand, UpdateBreakTimeCommand } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'

class BreakTimeService {
  httpClient = createApiClient(BreakTimesApi)

  async post(cmd: CreateBreakTimeCommand) {
    const { data } = await this.httpClient.create(cmd)
    return data
  }

  async put(cmd: UpdateBreakTimeCommand) {
    const { data } = await this.httpClient.update(cmd)
    return data
  }

  async delete(id: number) {
    const { data } = await this.httpClient._delete(id)
    return data
  }
}

const breakTimeService = new BreakTimeService()

export default breakTimeService
