import { LengthMeasurementUnit, PackageDto, WeightMeasurementUnit } from '@billing/app/api'
import { FormProps, SelectOption } from '@billing/app/models'
import { ControlledNumberField } from '@billing/components/form-controls'
import { ControlledSelect } from '@billing/components/form-controls/ControlledSelect'
import { ControlledTextField } from '@billing/components/form-controls/ControlledTextField'
import useFormWithSchema from '@billing/hooks/use-form-with-schema.hook'
import { Box, Grid, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useEffect, useState } from 'react'
import { PackageFormMapper } from './package-form.mapper'
import { PackageFormProfile, defaultValues } from './package-form.profile'
import { schema } from './package-form.scheme'

interface Props extends FormProps {
  packageItem?: PackageDto
  onSubmit: (formValues: PackageFormProfile) => Promise<void> | void
}

export const PackageForm = ({ id, packageItem, onSubmit }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const { formState, control, watch, handleSubmit, reset } = useFormWithSchema<PackageFormProfile>(
    schema(),
    defaultValues,
  )
  const [disabled, setDisabled] = useState(false)

  const lengthOptions: SelectOption[] = [
    { value: LengthMeasurementUnit.Millimeters, label: t('millimeter', 'Millimeter (mm)') },
    { value: LengthMeasurementUnit.Centimeter, label: t('centimeter', 'Centimeter (cm)') },
    { value: LengthMeasurementUnit.Meter, label: t('meter', 'Meter (m)') },
    { value: LengthMeasurementUnit.Inches, label: t('inches', 'Inches (in)') },
    { value: LengthMeasurementUnit.Feet, label: t('feet', 'Feet (ft)') },
  ]

  const weightOptions: SelectOption[] = [
    { value: WeightMeasurementUnit.Kilogram, label: t('kilogram', 'Kilogram (kg)') },
    { value: WeightMeasurementUnit.Ton, label: t('ton', 'Ton (t)') },
  ]

  const height = watch('height')
  const length = watch('length')
  const width = watch('width')

  useEffect(() => {
    if (packageItem) {
      reset(PackageFormMapper.mapDtoToFormValues(packageItem))
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [reset, packageItem])

  return (
    <Box
      id={id}
      component='form'
      noValidate
      autoComplete='off'
      onSubmit={handleSubmit(onSubmit)}
      pb={theme.customSpacing.xs}
    >
      <Grid container spacing={theme.customSpacing.m}>
        <Grid item xs={12}>
          <ControlledTextField<PackageFormProfile>
            name='name'
            formState={formState}
            control={control}
            label={t('name', 'Name')}
            fullWidth
            showAsterisk
            disabled={disabled}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography>{t('dimensions', 'Dimensions')}</Typography>
        </Grid>

        <Grid item xs={12} sm={3}>
          <ControlledNumberField<PackageFormProfile>
            name='length'
            formState={formState}
            control={control}
            label={t('length', 'Length')}
            disabled={disabled}
            fullWidth
            integerOnly
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <ControlledNumberField<PackageFormProfile>
            name='width'
            formState={formState}
            control={control}
            label={t('width', 'Width')}
            disabled={disabled}
            fullWidth
            integerOnly
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <ControlledNumberField<PackageFormProfile>
            name='height'
            formState={formState}
            control={control}
            label={t('height', 'Height')}
            disabled={disabled}
            fullWidth
            integerOnly
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <ControlledSelect<PackageFormProfile>
            name={'lengthUnit'}
            formState={formState}
            control={control}
            label={t('unit', 'Unit')}
            options={lengthOptions}
            disabled={disabled}
            required={!!height || !!length || !!width}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ControlledSelect<PackageFormProfile>
            name={'weightUnit'}
            formState={formState}
            control={control}
            label={t('weightUnit', 'Weight unit')}
            options={weightOptions}
            disabled={disabled}
            required
          />
        </Grid>
      </Grid>
    </Box>
  )
}
