import { Box, Card, Divider, Slide, Typography } from '@mui/material'
import { CarrierVisitSummaryDto, CarrierVisitSummaryFilterDto } from '@storage/app/api'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import UnAllocatedContainerGroupDetails from './UnAllocatedContainerGroupDetails'
import UnAllocatedContainersGroup from './UnAllocatedContainersGroup'
import UnAllocatedContainersHeader from './UnAllocatedContainersHeader'

interface Props {
  carrierVisitSummary: CarrierVisitSummaryDto
}

const UnAllocatedContainers = observer(({ carrierVisitSummary }: Props) => {
  const { breakpoints } = useMinimalsTheme()
  const { t } = useTranslate()
  const { carrierVisitAllocationRulesV2UIStore } = useStores()

  const [unallocatedContainers, setUnallocatedContainers] = useState<number>(0)
  const [openGroupDetailsSlide, setOpenGroupDetailsSlide] = useState<boolean>(false)
  const [filterDetails] = useState<CarrierVisitSummaryFilterDto | null>(null)
  const [variant] = useState<'standard' | 'special'>('standard')

  useEffect(() => {
    if (!carrierVisitSummary.filters) return
    const unallocatedContainers = carrierVisitSummary.filters
      .filter(filter => filter.settings === null)
      .map(filter => filter.unplanned)[0]

    setUnallocatedContainers(unallocatedContainers)
  }, [carrierVisitSummary.filters])

  const standardContainers = useMemo(() => {
    return carrierVisitSummary.filters?.filter(
      filter =>
        filter.settings &&
        filter.unplanned > 0 &&
        !filter.settings['isDangerous'] &&
        !filter.settings['isReefer'],
    )
  }, [carrierVisitSummary.filters])

  const specialContainers = useMemo(() => {
    return carrierVisitSummary.filters?.filter(
      filter =>
        filter.settings &&
        filter.unplanned > 0 &&
        (filter.settings['isDangerous'] || filter.settings['isReefer']),
    )
  }, [carrierVisitSummary.filters])

  // const handleOnContainerGroupClick = (
  //   filter: CarrierVisitSummaryFilterDto,
  //   variant: 'standard' | 'special',
  // ) => {
  //   setOpenGroupDetailsSlide(true)
  //   setFilterDetails(filter)
  //   setVariant(variant)
  // }

  const handleOnContainerGroupCreateRuleClick = (filter: CarrierVisitSummaryFilterDto) =>
    carrierVisitAllocationRulesV2UIStore.openAddDialogWithSettings(filter.settings)

  return (
    <Card>
      {unallocatedContainers === 0 ? (
        <Typography my={1} mx={2} variant='body1' textAlign='center'>
          {t('noUnAllocatedContainers', 'No unallocated containers')}
        </Typography>
      ) : (
        <>
          <UnAllocatedContainersHeader numberOfUnallocatedContainers={unallocatedContainers} />
          <Divider />

          <Slide direction='left' in={!openGroupDetailsSlide} mountOnEnter unmountOnExit>
            <Box
              sx={{
                overflowY: 'auto',
                [breakpoints.up('sm')]: {
                  height: '40vh',
                },
              }}
            >
              {standardContainers.length > 0 && (
                <UnAllocatedContainersGroup
                  carrierVisitSummaryFilters={standardContainers}
                  variant='standard'
                  onCreateNewRuleClick={handleOnContainerGroupCreateRuleClick}
                  // onClick={handleOnContainerGroupClick} //add the drill down later, when it is clearer what it needs to have
                />
              )}
              {specialContainers.length > 0 && (
                <UnAllocatedContainersGroup
                  carrierVisitSummaryFilters={specialContainers}
                  variant='special'
                  onCreateNewRuleClick={handleOnContainerGroupCreateRuleClick}
                  // onClick={handleOnContainerGroupClick} //add the drill down later, when it is clearer what it needs to have
                />
              )}
            </Box>
          </Slide>
          <Slide direction='right' in={openGroupDetailsSlide} mountOnEnter unmountOnExit>
            <Box>
              <UnAllocatedContainerGroupDetails
                filter={filterDetails!}
                onClick={() => setOpenGroupDetailsSlide(false)}
                variant={variant}
              />
            </Box>
          </Slide>
        </>
      )}
    </Card>
  )
})

export default UnAllocatedContainers
