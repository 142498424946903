import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { Stack } from '@mui/system'
import { formatMonthAndYear } from '@planning/utils'
import { useTranslate } from '@tolgee/react'
import { ChevronLeftIcon, ChevronRightIcon, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'

interface IProps {
  title: string
  visitDate: Date
  onClickToday: () => void
  onClickPrev: () => void
  onClickNext: () => void
  onChangeDateRange: (value: number) => void
}

export const CarrierVisitPlanningHeader = observer(
  ({ onClickToday, onClickPrev, onClickNext, onChangeDateRange, visitDate, title }: IProps) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()

    const handleChange = (event: SelectChangeEvent) =>
      onChangeDateRange(parseInt(event.target.value))

    const LeftAndRightIcons = () => (
      <Stack spacing={1} direction='row'>
        <IconButton sx={{ border: `1px solid ${theme.palette.divider}` }} onClick={onClickPrev}>
          <ChevronLeftIcon />
        </IconButton>

        <IconButton sx={{ border: `1px solid ${theme.palette.divider}` }} onClick={onClickNext}>
          <ChevronRightIcon />
        </IconButton>
      </Stack>
    )

    return (
      <Box>
        <Grid container alignItems='center' pb={1}>
          <Grid item xs={2}>
            <Typography variant='h4'>{title}</Typography>
          </Grid>

          <Grid item xs={6}>
            <Stack direction='row' alignItems='center' spacing={2}>
              <Typography variant='h4'>{formatMonthAndYear(visitDate)}</Typography>

              <LeftAndRightIcons />

              <Button variant='outlined' onClick={onClickToday}>
                {t('today', 'Today')}
              </Button>
            </Stack>
          </Grid>

          <Grid item xs={4} container justifyContent='flex-end'>
            <Select onChange={handleChange} defaultValue={'4'} size='small'>
              <MenuItem value={4}>4 {t('days', 'days')}</MenuItem>
              <MenuItem value={7}>7 {t('days', 'days')}</MenuItem>
            </Select>
          </Grid>
        </Grid>
      </Box>
    )
  },
)
