import { Box, Button, Stack, TextField, Typography } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { FullWidthFooter } from '@planning/components/FullWidthFooter'
import holdService from '@planning/services/holdService'
import { HoldViewStore } from '@planning/stores/hold/HoldViewStore'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { HoldTabs } from './HoldTabs'

interface Props {
  store: HoldViewStore
  onCancel?: () => void
}

export const SearchableHoldTabsBox: FC<Props> = observer(({ store, onCancel }) => {
  const { t } = useTranslate()
  const { drawerStore } = usePlanningStore()

  const handleApproveHolds = async () => {
    if (!store.selectedHoldIds.length) return

    await holdService.approve(store.selectedHoldIds)
    store.setSearchFilter()
    drawerStore.close()
  }

  const handleSearchFilterChange = (value?: string) => {
    store.setSearchFilter(value)
  }

  const defaultHandleCancel = () => {
    store.setSearchFilter()
    drawerStore.close()
  }

  const handleAddHold = () => store.setIsCreateMode(true)

  const enableEditingHolds =
    store.selectedOrder?.status === 'Open' || store.selectedLinkedOrder?.status === 'Open'

  return (
    <Box>
      <Stack display='flex' flexDirection='row' justifyContent='space-between'>
        <Box pl='1rem'>
          <Typography variant='h3'>{t('holds', 'Holds')}</Typography>
          <Typography variant='h4'>{store.selectedOrder?.containerNumber}</Typography>
        </Box>
        {enableEditingHolds && (
          <Box p='1rem'>
            <Button variant='contained' onClick={handleAddHold}>
              {t('add', 'Add')}
            </Button>
          </Box>
        )}
      </Stack>
      <Box maxHeight='20vh' p='1rem' display='flex' justifyContent='center' alignItems='center'>
        <TextField
          label={t('search', 'Search')}
          defaultValue={store.searchFilter}
          sx={{ width: '100%', mr: '0.25rem', mt: '0.5rem' }}
          onChange={event => handleSearchFilterChange(event.currentTarget.value)}
        />
      </Box>
      <HoldTabs store={store} />
      <FullWidthFooter>
        {(enableEditingHolds && (
          <Button variant='contained' sx={{ ml: '1rem', mr: '1rem' }} onClick={handleApproveHolds}>
            {t('resolve', 'Resolve')}
          </Button>
        )) || <></>}
        <Button variant='outlined' color='error' onClick={onCancel ?? defaultHandleCancel}>
          {t('close', 'Close')}
        </Button>
      </FullWidthFooter>
    </Box>
  )
})
