import { Chip, Stack, Typography } from '@mui/material'
import { EquipmentType, WorkInstructionEquipmentDto } from '@operations/app/api'
import { EquipmentTypeIcon } from '@operations/features/equipmentPlanning/components/EquipmentTypeIcon/EquipmentTypeIcon'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'

interface Props {
  equipments?: WorkInstructionEquipmentDto[]
}
export const YardAssignedEquipments = observer(({ equipments }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  if (!equipments?.length) return <></>

  return (
    <Stack gap={theme.customSpacing.m}>
      <Typography variant='subtitle1'>{t('assignedEquipment', 'Assigned equipment')}</Typography>
      <Stack flexDirection='row' gap={theme.customSpacing.xs} flexWrap='wrap'>
        {_(equipments)
          .uniqBy(x => x.id)
          .value()
          .map(equipment => (
            <Chip
              key={equipment.id}
              label={equipment.name}
              icon={
                <EquipmentTypeIcon
                  type={equipment.equipmentType as EquipmentType}
                  backgroundColorTransparent
                  skipPadding
                />
              }
            />
          ))}
      </Stack>
    </Stack>
  )
})
