import { action, makeObservable, observable, runInAction } from 'mobx'

export interface IPaginatedStore<T> {
  currentPageIndex: number
  pageSize: number
  filter?: string
  totalCount: number
  sortingModel: SortingModel<T>
  filterStatus?: string
  showCompleted: boolean
  setFilterStatus: (status?: string) => Promise<void>
  setCurrentPageIndex: (pageIndex: number) => Promise<void>
  setPageSize: (pageSize: number) => Promise<void>
  setFilter: (filter: string) => Promise<void>
  toggleShowCompleted: () => Promise<void>
  loadCurrentPage: () => Promise<void>
  setSortingModel: (sortingModel: SortingModel<T>) => Promise<void>
  // TODO: make required if all paginated stores have been applied store pattern
  from?: Date
  to?: Date
  setDateRange: (from?: Date, to?: Date) => Promise<void>
  isInitializing: boolean
  setIsInitializing: (loading: boolean) => void
}

export interface IPaginatedStoreWithItems<T> extends IPaginatedStore<T> {
  pageItems: T[]
}

export interface SortingModel<T = any> {
  orderBy?: string
  field: string
  isDescending: boolean
}

export class PaginatedStore<T> implements IPaginatedStoreWithItems<T> {
  pageItems: T[] = []
  currentPageIndex = 0
  pageSize = 25
  filter?: string
  totalCount = 0
  isCreateDialogOpen = false
  filterStatus?: string
  showCompleted = false
  sortingModel = {
    field: '',
    orderBy: undefined,
    isDescending: false,
  } as SortingModel<T>
  from?: Date
  to?: Date
  isInitializing = true

  constructor(
    private loadDataHandler: (
      currentPageIndex: number,
      pageSize: number,
      sortingModel: SortingModel<T>,
      filter?: string,
      showCompleted?: boolean,
      from?: Date,
      to?: Date,
    ) => Promise<DtoPaginatedList<T>>,
  ) {
    makeObservable(this, {
      pageItems: observable,
      currentPageIndex: observable,
      pageSize: observable,
      filter: observable,
      filterStatus: observable,
      totalCount: observable,
      isCreateDialogOpen: observable,
      showCompleted: observable,
      sortingModel: observable,
      from: observable,
      to: observable,
      isInitializing: observable,

      setCurrentPageIndex: action,
      setPageSize: action,
      setFilter: action,
      setFilterStatus: action,
      setCreateDialogOpen: action,
      loadCurrentPage: action,
      toggleShowCompleted: action,
      setSortingModel: action,
      setDateRange: action,
      setIsInitializing: action,
    })
  }

  setCreateDialogOpen(open: boolean) {
    this.isCreateDialogOpen = open
  }

  async setCurrentPageIndex(pageIndex: number) {
    this.currentPageIndex = pageIndex
    await this.loadCurrentPage()
  }

  async setPageSize(pageSize: number) {
    this.pageSize = pageSize
    await this.loadCurrentPage()
  }

  async setFilter(filter?: string) {
    this.filter = filter
    await this.reloadFirstPage()
  }

  async setFilterStatus(status?: string) {
    this.filterStatus = status
    await this.reloadFirstPage()
  }

  async toggleShowCompleted() {
    this.showCompleted = !this.showCompleted
    await this.reloadFirstPage()
  }

  async setSortingModel(sortingModel: SortingModel<T>) {
    this.sortingModel = sortingModel
    await this.reloadFirstPage()
  }

  async loadCurrentPage() {
    const { payload, totalCount } = await this.loadDataHandler(
      this.currentPageIndex,
      this.pageSize,
      this.sortingModel,
      this.filter,
      this.showCompleted,
      this.from,
      this.to,
    )

    runInAction(() => {
      this.pageItems = payload
      this.totalCount = totalCount
    })
  }

  setIsInitializing = (isInitializing: boolean) => {
    this.isInitializing = isInitializing
  }

  setDateRange = async (from?: Date, to?: Date) => {
    this.from = from
    this.to = to
    await this.reloadFirstPage()
  }

  private async reloadFirstPage() {
    this.currentPageIndex = 0
    await this.loadCurrentPage()
  }
}

export interface DtoPaginatedList<T> {
  payload: T[]
  totalCount: number
}
