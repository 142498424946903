export interface CTReportFieldsFormProfile {
  includeContainerNumber: boolean
  includeIsoCode: boolean
  includeOperator: boolean
  includeGrossWeight: boolean
  includeIsOOG: boolean
  includeIsReefer: boolean
  includeIsEmpty: boolean
  includeIsDangerous: boolean
  includeEntryDateTime: boolean
  includeIsDamaged: boolean
}

export const defaultValues: CTReportFieldsFormProfile = {
  includeContainerNumber: true,
  includeIsoCode: true,
  includeOperator: true,
  includeGrossWeight: true,
  includeIsOOG: true,
  includeIsReefer: true,
  includeIsEmpty: true,
  includeIsDangerous: true,
  includeEntryDateTime: true,
  includeIsDamaged: true,
}
