import { Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { EmptyStateView } from './EmptyStateView'
import IconSearchForResults from './icon/IconSearchForResults'

interface Props {
  text?: string
}

export const SearchForResultsView = ({ text }: Props) => {
  const { t } = useTranslate()
  return (
    <EmptyStateView>
      <IconSearchForResults zoom={40} />
      <Typography variant='h4'>
        {text ?? t('searchToViewResults', 'Search to view results')}
      </Typography>
    </EmptyStateView>
  )
}
