import { DevTools, FormatSimple, Tolgee } from '@tolgee/react'
import deLang from '../../i18n/de.json'
import enLang from '../../i18n/en.json'

export const tolgee = Tolgee()
  .use(DevTools())
  .use(FormatSimple())
  .init({
    language: 'en',

    // for development
    apiUrl: 'https://app.tolgee.io',
    apiKey: 'tgpak_gi2tsms7gi3ha23gnntdgnzunnrdo4teofrximbtgnsheojynu',

    // for production
    staticData: {
      en: enLang,
      de: deLang,
    },
  })
