import { useAuth0 } from '@auth0/auth0-react'
import LogoutIcon from '@mui/icons-material/Logout'
import { Avatar, ListItemIcon, Menu, MenuItem } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useTranslate } from '@tolgee/react'
import { useState } from 'react'

export const HeaderAvatar = () => {
  const { logout, user, isAuthenticated } = useAuth0()
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)
  const handleClick = (e: any) => {
    setAnchorEl(e.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const avatarInitials = user?.name
    ?.split(' ')
    .map(word => word.charAt(0))
    .join('')

  return (
    <>
      <Avatar
        id='logout-menu'
        sx={{ bgcolor: theme.palette.grey[400], cursor: 'pointer', fontSize: '0.9rem' }}
        onClick={handleClick}
      >
        {avatarInitials}
      </Avatar>

      <Menu id='logout-menu' anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={async () => {
            logout({ logoutParams: { returnTo: window.location.origin } })
          }}
        >
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          {t('logout', 'Logout')}
        </MenuItem>
      </Menu>
    </>
  )
}
