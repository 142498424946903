import { yupResolver } from '@hookform/resolvers/yup'
import { FieldValues, useForm } from 'react-hook-form'
import { Schema } from 'yup'

interface Options<TContext> {
  context?: TContext
}

const useFormWithSchema = <T extends FieldValues, TContext = unknown>(
  schema: Schema<T>,
  defaultValues: any,
  { context }: Options<TContext> = {},
) =>
  useForm<T>({
    resolver: yupResolver(schema),
    defaultValues,
    context,
  })

export default useFormWithSchema
