import { Box, Card, CardContent, Skeleton, Typography } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useTranslate } from '@tolgee/react'

export const ContainerSearchLoader = () => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  return (
    <>
      <Box display='flex' justifyContent='center' mb='1rem'>
        <Typography variant='subtitle1' color={theme.palette.grey[500]}>
          {t('searching', 'Searching')}
        </Typography>
      </Box>
      <Card>
        <CardContent>
          <Skeleton variant='rectangular' width='100%' height={48} sx={{ mb: '1rem' }} />
          <Skeleton variant='rounded' width='100%' height={48} />
        </CardContent>
      </Card>
    </>
  )
}
