import { Box, Button, TextField, Typography } from '@mui/material'
import { FullWidthFooter } from '@planning/components/FullWidthFooter'
import { IContainerItem } from '@planning/rt-stores/container/ContainerItem'
import containerService from '@planning/services/containerService'
import { useNavigationStore } from '@planning/stores/NavigationStack'
import { useTranslate } from '@tolgee/react'
import { computed } from 'mobx'
import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import { ContainerDamageTabs, CreateContainerDamageForm } from './components'

export const ContainerDamagesPage: FC<{ container: IContainerItem }> = observer(({ container }) => {
  const navigationStore = useNavigationStore()
  const { t } = useTranslate()
  const [selectedDamageIds, setSelectedDamageIds] = useState<number[]>([])

  const [filter, setFilter] = useState<string>('')

  const filtedActiveDamages = computed(() => {
    return container.activeDamages.filter(damage => damage.description.includes(filter))
  }).get()

  const filtedResolvedDamages = computed(() => {
    return container.resolvedDamages.filter(damage => damage.description.includes(filter))
  }).get()

  const onResolveHandler = async () => {
    await containerService.resolveDamages({
      containerId: container.data.id,
      damageIds: selectedDamageIds,
    })
  }

  const toggleSelectDamage = (id: number) => {
    if (selectedDamageIds.includes(id))
      setSelectedDamageIds(selectedDamageIds.filter(existingId => existingId !== id))
    else setSelectedDamageIds([...selectedDamageIds, id])
  }

  const toggleSelectAllDamages = (isSelectAll: boolean) => {
    if (isSelectAll) setSelectedDamageIds([])
    else setSelectedDamageIds(container.activeDamages.map(damage => damage.id))
  }

  return (
    <Box minWidth='30rem' display='flex' flexDirection='column' overflow='clip'>
      <Typography variant='h3' pl='1rem'>
        {t('damages', 'Damages')}
      </Typography>
      <Typography variant='h4' pl='1rem'>
        {container.data?.number}
      </Typography>
      <Box maxHeight='20vh' p='1rem' display='flex' justifyContent='center' alignItems='center'>
        <TextField
          label={t('search', 'Search')}
          defaultValue={''}
          sx={{ width: '100%', mr: '0.25rem', mt: '0.5rem' }}
          onChange={event => setFilter(event.currentTarget.value ?? '')}
        />
        <Button
          onClick={() =>
            navigationStore.push(<CreateContainerDamageForm containerId={container.data.id!} />)
          }
        >
          {t('add', 'Add')}
        </Button>
      </Box>
      <ContainerDamageTabs
        activeDamages={filtedActiveDamages}
        resolvedDamages={filtedResolvedDamages}
        selected={selectedDamageIds}
        onToggleSelectDamage={toggleSelectDamage}
        onSelectAllDamages={toggleSelectAllDamages}
      />
      <FullWidthFooter minHeight='5rem'>
        <Button
          variant='contained'
          sx={{ ml: '1rem', mr: '1rem' }}
          disabled={!selectedDamageIds.length}
          onClick={onResolveHandler}
        >
          {t('resolve', 'Resolve')}
        </Button>
        <Button variant='outlined' color='error' onClick={() => navigationStore.pop()}>
          {t('close', 'Close')}
        </Button>
      </FullWidthFooter>
    </Box>
  )
})
