import { PierDto } from '@storage/app/api'
import { Delete, Edit } from '@mui/icons-material'
import { Card, CardActions, CardHeader, IconButton } from '@mui/material'

interface Props {
  item: PierDto
  canWrite: boolean
  onDelete: (id: string) => Promise<void> | void
  onEdit: (id: string) => Promise<void> | void
}

export const PiersListItem = ({ item, canWrite, onDelete, onEdit }: Props) => {
  return (
    <Card>
      <CardHeader title={item.name}></CardHeader>
      {canWrite && (
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <IconButton onClick={() => onEdit(item.id)}>
            <Edit />
          </IconButton>

          <IconButton color='error' onClick={() => onDelete(item.id)}>
            <Delete />
          </IconButton>
        </CardActions>
      )}
    </Card>
  )
}
