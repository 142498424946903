import { YardElementGeometryDto } from '@storage/app/api'
import { FormType, TEntity } from '@storage/app/models'
import { action, computed, makeObservable, observable } from 'mobx'
import { EntityStore } from './entity.store'

export abstract class EntityDialogFeatureStore<T extends TEntity> {
  isOpen = false
  editingEntityId?: string
  geometry?: YardElementGeometryDto

  constructor(private entityStore: EntityStore<T>) {
    makeObservable(this, {
      isOpen: observable,
      editingEntityId: observable,

      geometry: observable,

      toggle: action,

      editingEntity: computed,
      formType: computed,
    })
  }

  public get editingEntity(): T | undefined {
    return this.editingEntityId ? this.entityStore.data.get(this.editingEntityId) : undefined
  }

  public get formType(): FormType {
    return this.editingEntityId ? 'Edit' : 'Add'
  }

  public toggle(id?: string, geometry?: YardElementGeometryDto): void {
    this.editingEntityId = id
    this.isOpen = !this.isOpen

    this.geometry = geometry
  }
}
