import {
  Box,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
} from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { AddAPhotoIcon, ConfirmationDialog, IconButton, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

interface ImageUploaderProps {
  damageImageFiles?: File[]
  onUpdateDamagedFiles?: (files: File[]) => void
}
export const ImageUploader = observer(
  ({ onUpdateDamagedFiles, damageImageFiles }: ImageUploaderProps) => {
    const [isCameraOpen, setIsCameraOpen] = useState(false)
    const videoRef = useRef<HTMLVideoElement>(null)
    const canvasRef = useRef<HTMLCanvasElement>(null)
    const inputFileRef = useRef<HTMLInputElement>(null)

    const { t } = useTranslate()
    const theme = useMinimalsTheme()

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const filesArray = Array.from(e.target.files)
        if (onUpdateDamagedFiles) {
          onUpdateDamagedFiles(
            damageImageFiles?.length ? [...damageImageFiles, ...filesArray] : filesArray,
          )
        }
      }
      // need to clean upload buffer
      e.currentTarget.value = ''
    }

    const openCamera = async () => {
      setIsCameraOpen(true)
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          facingMode: 'environment',
        },
      })
      if (videoRef.current) {
        videoRef.current.srcObject = stream
        videoRef.current.onloadedmetadata = () => videoRef.current!.play()
      }
    }

    const closeCamera = () => {
      setIsCameraOpen(false)
      if (videoRef.current && videoRef.current.srcObject) {
        const stream = videoRef.current.srcObject as MediaStream
        const tracks = stream.getTracks()
        tracks.forEach(track => track.stop())
        videoRef.current.srcObject = null
      }
    }

    const captureImage = () => {
      if (videoRef.current && canvasRef.current) {
        const context = canvasRef.current.getContext('2d')
        if (context) {
          context.drawImage(
            videoRef.current,
            0,
            0,
            canvasRef.current.width,
            canvasRef.current.height,
          )
          canvasRef.current.toBlob(blob => {
            if (blob) {
              const file = new File([blob], `${uuidv4()}.png`, { type: 'image/png' })
              if (onUpdateDamagedFiles) {
                onUpdateDamagedFiles(
                  damageImageFiles?.length ? [...damageImageFiles, file] : [file],
                )
              }
            }
          })
        }
      }

      closeCamera()
    }

    const handleChoosePhoto = () => {
      inputFileRef?.current?.click()
    }

    const [open, setOpen] = useState(false)
    const anchorRef = useRef<HTMLButtonElement>(null)

    const handleToggle = () => {
      setOpen(prevOpen => !prevOpen)
    }

    const handleClose = (event: Event) => {
      if (anchorRef?.current?.contains(event.target as HTMLElement)) {
        return
      }

      setOpen(false)
    }

    return (
      <>
        <Stack flexDirection='row' gap={2}>
          <Stack flexDirection='row' alignItems='center' gap={1}>
            <IconButton ref={anchorRef} onClick={handleToggle}>
              <AddAPhotoIcon></AddAPhotoIcon>
            </IconButton>
          </Stack>

          <Popper
            open={open}
            anchorEl={anchorRef.current}
            transition
            sx={{ zIndex: theme.zIndex.tooltip }}
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps}>
                <Paper
                  sx={{
                    boxShadow: theme.customShadows.dropdown,
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList autoFocusItem={open} disablePadding>
                      <MenuItem onClick={openCamera} sx={{ whiteSpace: 'normal' }}>
                        {t('takePhoto', 'Take photo')}
                      </MenuItem>
                      <MenuItem onClick={handleChoosePhoto} sx={{ whiteSpace: 'normal' }}>
                        {t('choosePhoto', 'Choose photo')}
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>

          <input
            ref={inputFileRef}
            type='file'
            accept='image/*'
            multiple
            onChange={handleImageChange}
            style={{ display: 'none' }}
          />
        </Stack>

        <ConfirmationDialog
          open={isCameraOpen}
          title=''
          primaryActionText={t('capture', 'Capture')}
          closeLabel={t('closeCamera', 'Close camera')}
          onClose={closeCamera}
          onConfirm={captureImage}
          hasBorderTop
          fullScreen
        >
          <Box height='100%' width='100%'>
            <video
              ref={videoRef}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              playsInline
            >
              <track kind='captions'></track>
            </video>
          </Box>

          <canvas ref={canvasRef} style={{ display: 'none' }} />
        </ConfirmationDialog>
      </>
    )
  },
)
