import { ContainerType } from '@planning/app/api'
import { ContainerAutoComplete } from '@planning/components'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { ServiceOrdersViewStore } from '../Stores/ServiceOrdersViewStore'
import { ContainerJourneyOptions } from './ContainerJourneyOptions'

export const NumericForm: FC<{
  store: ServiceOrdersViewStore
  isEmpty: boolean
  type?: ContainerType
}> = observer(({ store, isEmpty, type }) => {
  return (
    <>
      {store.selectedContainerJourney ? (
        <ContainerJourneyOptions
          options={[store.selectedContainerJourney]}
          isEdited
          onSelect={() => store.setSelectedContainerJourney()}
        />
      ) : (
        <>
          <ContainerAutoComplete
            isEmpty={isEmpty}
            type={type}
            onChange={async (_, value) => {
              if (value && typeof value != 'string' && value.id) {
                store.fetchContainerVisits(value.id)
              }
            }}
            canAssignServiceOrders
          />
          {store.containerVisits && (
            <ContainerJourneyOptions
              options={store.containerVisits}
              onSelect={store.setSelectedContainerJourney}
            />
          )}
        </>
      )}
    </>
  )
})
