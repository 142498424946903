import { useOperationsStore } from '@operations/AppProvider'
import { Card, CardContent, Grid, Stack, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'

export const CraneSplitSummary = observer(() => {
  const { equipmentsVesselVisitUIStore, tenantConfigStore } = useOperationsStore()
  if (tenantConfigStore.skipCraneSplit) return <></>

  const { t } = useTranslate()

  const getContent = (label: string, equipments: string[]) => {
    return (
      <Grid item xs={12} sm={6}>
        <Typography variant='subtitle2'>{label}</Typography>
        <Typography variant='h4'>{equipments.join(', ')}</Typography>
        {equipments.length === 0 && (
          <Typography variant='body2'>{t('nothingPlanned', 'Nothing planned')}</Typography>
        )}
      </Grid>
    )
  }

  return (
    <Card>
      <CardContent>
        <Stack gap={1}>
          <Typography variant='h4'>{t('craneSplit', 'Crane split')}</Typography>

          <Grid container>
            {getContent(t('discharge', 'Discharge'), equipmentsVesselVisitUIStore.dischargeCranes)}

            {getContent(t('load', 'Load'), equipmentsVesselVisitUIStore.loadCranes)}
          </Grid>
        </Stack>
      </CardContent>
    </Card>
  )
})
