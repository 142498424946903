import { BooleanSelectOption, SelectOption } from '@storage/app/models'
import { useMemo } from 'react'

const KEY_PREFIX = 'cargoStatus'

export const useCargoStatusOptions = (): SelectOption[] =>
  useMemo(
    () => [
      { label: `${KEY_PREFIX}.full`, value: BooleanSelectOption.False },
      { label: `${KEY_PREFIX}.empty`, value: BooleanSelectOption.True },
    ],
    [],
  )
