import { Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Widget } from './Widget'
import { useTranslate } from '@tolgee/react'
import { LabeledValueBox } from './LabeledValueBox'
import { ComponentStatus } from '@storage/helpers/enums'
import { ContainerInformationWidgetStore } from '@storage/stores/container-information-widget.store'
import { useEffect } from 'react'
import { ContainerInformationWidgetDialog } from '@storage/pages/container-turnovers/components/ContainerInformationWidgetDialog'
import { YardIcon } from '@tom-ui/ui'

interface ContainerInformationWidgetProps {
  containerInformationWidgetStore: ContainerInformationWidgetStore
}

export const ContainerInformationWidget = observer(
  ({ containerInformationWidgetStore }: ContainerInformationWidgetProps) => {
    const { t } = useTranslate()

    useEffect(() => {
      const loadData = async () => {
        await containerInformationWidgetStore.loadYardBlockSummaries()
      }
      loadData()
    }, [containerInformationWidgetStore])

    const containerSizeLabels: Record<string, string> = {
      '20': "20'",
      '40': "40'",
    }

    const cargoStatusLabels: Record<string, string> = {
      Full: t('full', 'Full'),
      Empty: t('empty', 'Empty'),
    }

    return (
      <>
        <Widget
          expandable={false}
          title={t('containerInformation', 'Container Information')}
          icon={<YardIcon />}
          onAction={() => containerInformationWidgetStore.toggleEditMode()}
        >
          <Grid container spacing={2.3} justifyContent='space-between'>
            {containerInformationWidgetStore.groupedYardBlockSummaries.map(e => (
              <Grid key={e.name} item xs={6}>
                <LabeledValueBox
                  label={
                    Object.keys(containerSizeLabels).includes(e.name)
                      ? containerSizeLabels[e.name]
                      : Object.keys(cargoStatusLabels).includes(e.name)
                        ? cargoStatusLabels[e.name]
                        : e.name
                  }
                  value={e.value}
                  state={ComponentStatus.Info}
                />
              </Grid>
            ))}
          </Grid>
        </Widget>
        <ContainerInformationWidgetDialog
          containerInformationWidgetStore={containerInformationWidgetStore}
        />
      </>
    )
  },
)
