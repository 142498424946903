import _ from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'

export interface ContainerMovementInfo {
  containerNumber: string
  size: number
  isEmpty: boolean
  isDangerous: boolean
}

export class ContainerMovementDialogStore {
  isOpen = false
  containers: ContainerMovementInfo[] = []
  selectedContainerNumbers: string[] = []

  constructor() {
    makeObservable(this, {
      isOpen: observable,
      containers: observable,
      selectedContainerNumbers: observable,

      closeDialog: action,
      openDialog: action,
      setContainers: action,
      setSelectedContainerNumbers: action,

      selectedContainers: computed,
      hasSelectedMixedSizes: computed,
    })
  }

  openDialog(containers?: ContainerMovementInfo[]) {
    this.isOpen = true
    this.setContainers(containers)
    this.setSelectedContainerNumbers(containers?.map(x => x.containerNumber))
  }

  closeDialog() {
    this.isOpen = false

    this.reset()
  }

  setContainers(containers?: ContainerMovementInfo[]) {
    this.containers = containers ?? []
  }

  setSelectedContainerNumbers(containerNumbers?: string[]) {
    this.selectedContainerNumbers = containerNumbers ?? []
  }

  get selectedContainers() {
    return this.containers.filter(x => this.selectedContainerNumbers.includes(x.containerNumber))
  }

  get hasSelectedMixedSizes() {
    const sizes = _.uniq(this.selectedContainers.map(x => x.size))

    return sizes.length > 1
  }

  private reset() {
    this.setContainers()
    this.setSelectedContainerNumbers()
  }
}

export const containerMovementDialogStore = new ContainerMovementDialogStore()
