import NotificationsIcon from '@mui/icons-material/Notifications'
import {
  Badge,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Link,
  Popover,
  Stack,
  Typography,
} from '@mui/material'
import { useOperationsStore } from '@operations/AppProvider'
import { OperationType } from '@operations/app/api'
import { formatContainerNumber } from '@operations/utils/helper'
import { useTranslate } from '@tolgee/react'
import { ColoredLabel, IconButton } from '@tom-ui/ui'
import dayjs from 'dayjs'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { JobNotificationDto } from '../../models/job-notification.model'

export const HeaderAlert = observer(() => {
  const { t } = useTranslate()
  const { equipmentOperatorNotificationUIStore, equipmentOperatorSearchUIStore } =
    useOperationsStore()

  const notifications = _.orderBy(
    equipmentOperatorNotificationUIStore.jobNotifications,
    x => x.notificationDateTime,
    'desc',
  )

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)
  const id = open ? 'notification-popover' : undefined

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    equipmentOperatorNotificationUIStore.markNotificationsAsRead()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const getTimePassedDisplay = (dateTime: Date) => {
    const dateNow = dayjs()

    const hoursDiff = dateNow.diff(dateTime, 'hour')
    const minutesDiff = dateNow.diff(dateTime, 'minute')

    if (hoursDiff >= 1) {
      return hoursDiff === 1
        ? t('about1HourAgo', 'about 1 hour ago')
        : t('aboutXHoursAgo', 'about {amount} hours ago', { amount: hoursDiff })
    } else if (minutesDiff >= 1) {
      return minutesDiff === 1
        ? t('about1MinuteAgo', 'about 1 minute ago')
        : t('aboutXMinutesAgo', 'about {amount} minutes ago', { amount: minutesDiff })
    } else {
      return t('lessThan1MinuteAgo', 'less than 1 minute ago')
    }
  }

  const handleClearAll = () => {
    equipmentOperatorNotificationUIStore.clearNotifications()
  }

  const handleShowJob = (jobWorkInstructionIds: number[]) => {
    handleClose()

    equipmentOperatorSearchUIStore.setSelectedJobWorkInstructionIds(jobWorkInstructionIds)
    equipmentOperatorSearchUIStore.toggleSearch()
  }

  const getNameForNotification = (notification: JobNotificationDto) => {
    if (notification.commodity) return notification.commodity

    return notification.containerNumber
      ? formatContainerNumber(notification.containerNumber)
      : t('noContainerNumber', 'No container no.')
  }

  const getDescription = (notification: JobNotificationDto) => {
    let operation = ''
    let destination = notification.destination ?? t('unknown', 'Unknown')

    switch (notification.operationType) {
      case OperationType.Inbound:
        operation = t('dischargedToThe', 'discharged to the')
        break

      case OperationType.Outbound:
        operation = t('loadedOn', 'loaded on the')
        if (notification.carrierType && notification.carrierVisit)
          destination = `${t(notification.carrierType)} ${notification.carrierVisit}`
        break

      case OperationType.Internal:
      case OperationType.Service:
        operation = t('movedToThe', 'moved to the')
        break
    }

    return t('notificationReadyToBe', 'Ready to be {operation} {destination}', {
      operation: operation,
      destination: destination,
    })
  }

  return (
    <>
      <IconButton onClick={handleClick}>
        <Badge badgeContent={notifications.filter(x => !x.isRead).length} color='error'>
          <NotificationsIcon></NotificationsIcon>
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            style: {
              width: '400px',
            },
          },
        }}
      >
        <Card>
          <CardHeader
            title={
              <Stack direction={'row'} justifyContent='space-between' alignItems='center'>
                <Typography variant='h6'>
                  {t('notifications', 'Notifications')} ({notifications.length})
                </Typography>
                <Link component='button' variant='captionBold' onClick={handleClearAll}>
                  Clear all
                </Link>
              </Stack>
            }
          />
          <CardContent>
            {notifications.length ? (
              <Stack
                gap={theme => theme.customSpacing.m}
                divider={<Divider orientation='horizontal' flexItem />}
              >
                {notifications.map(notification => (
                  <Stack
                    direction={'row'}
                    justifyContent='space-between'
                    alignItems='center'
                    key={notification.jobWorkInstructionIds.join('|')}
                    gap={theme => theme.customSpacing.m}
                  >
                    <Stack>
                      {!notification.hasMultipleJobs ? (
                        <>
                          <Stack flexDirection='row' alignItems='center' gap={1}>
                            <Typography variant='subtitle2'>
                              {getNameForNotification(notification)}
                            </Typography>
                            {notification.isOptional && (
                              <ColoredLabel label={t('optional', 'Optional')} color='secondary' />
                            )}
                          </Stack>
                          <Typography variant='body2'>{getDescription(notification)}</Typography>
                        </>
                      ) : (
                        <Typography variant='subtitle2'>
                          {t('xNewJobsHaveBeenCreated', '{amount} new jobs have been created!', {
                            amount: notification.jobWorkInstructionIds.length,
                          })}
                        </Typography>
                      )}

                      <Typography variant='caption' color='secondary'>
                        {getTimePassedDisplay(notification.notificationDateTime)}
                      </Typography>
                    </Stack>
                    <Button
                      color='inherit'
                      variant='text'
                      size='large'
                      sx={{ flex: 'none' }}
                      onClick={() => handleShowJob(notification.jobWorkInstructionIds)}
                    >
                      {!notification.hasMultipleJobs
                        ? t('showJob', 'Show job')
                        : t('showJobs', 'Show jobs')}
                    </Button>
                  </Stack>
                ))}
              </Stack>
            ) : (
              <Typography variant='subtitle1' color='secondary'>
                {t('noNotifications', 'No notifications')}
              </Typography>
            )}
          </CardContent>
        </Card>
      </Popover>
    </>
  )
})
