import { Stack } from '@mui/material'
import { BerthResponseDto } from '@planning/app/api'
import { observer } from 'mobx-react-lite'
import { Berth } from './Berth'
import { BerthDivider } from './BerthDivider'
import { EmptyBerth } from './EmptyBerth'

interface Props {
  berths?: BerthResponseDto[]
  pixelPerWaterMark: number
  startWaterMark: number
  berthViewTotalWidth: number
}
export const BerthLayout = observer(
  ({ berths, pixelPerWaterMark, startWaterMark, berthViewTotalWidth }: Props) => {
    return (
      <Stack position='relative'>
        {berths?.map((berth, i) => {
          const prevBerthQmmTo = i > 0 ? berths[i - 1].quayMeterMarkTo : berth.quayMeterMarkFrom
          const gap = berth.quayMeterMarkFrom - prevBerthQmmTo

          return (
            <Stack key={'berth_' + i}>
              {gap > 25 && (
                <>
                  <EmptyBerth
                    qmmFrom={prevBerthQmmTo}
                    qmmTo={berth.quayMeterMarkFrom}
                    pixelPerWaterMark={pixelPerWaterMark}
                    berthViewTotalWidth={berthViewTotalWidth}
                  />
                  <BerthDivider
                    pixelPerWaterMark={pixelPerWaterMark}
                    startWaterMark={startWaterMark}
                    berthViewTotalWidth={berthViewTotalWidth}
                    qmmAt={berth.quayMeterMarkFrom}
                  />
                </>
              )}
              <Berth
                name={berth.name}
                maxDraft={berth.maxDraft}
                qmmFrom={berth.quayMeterMarkFrom}
                qmmTo={berth.quayMeterMarkTo}
                pixelPerWaterMark={pixelPerWaterMark}
                bollards={berth.bollards}
              />
              <BerthDivider
                pixelPerWaterMark={pixelPerWaterMark}
                startWaterMark={startWaterMark}
                berthViewTotalWidth={berthViewTotalWidth}
                qmmAt={berth.quayMeterMarkTo}
              />
            </Stack>
          )
        })}
      </Stack>
    )
  },
)
