import { Container, Grid, Typography } from '@mui/material'
import { BackButton } from '@tom-ui/ui'
import { usePlanningStore } from '@planning/AppProvider'
import { PATH_PLANNING } from '@planning/page-url-paths'
import { NnrOrderLocalStore } from '@planning/rt-stores/nnrOrder/NnrOrderLocalStore'
import { IOrderItem } from '@planning/rt-stores/order/OrderItem'
import { ISortDelegate, PaginatedLocalStore } from '@planning/stores/PaginatedLocalStore'
import { simpleSearchFilterDelegate } from '@planning/stores/simpleSearchFilterDelegate'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { NNROrderDetailsTable } from './Components/NNROrderDetailsTable'

const orderSortingDelegate: ISortDelegate<IOrderItem> = (sortingModel, a, b) => {
  const sort = (a.data.containerNumber ?? '').localeCompare(b.data.containerNumber ?? '')
  return sortingModel.isDescending ? -sort : sort
}

const orderFilterDelegate = simpleSearchFilterDelegate(['containerNumber'])

export const NNROrderDetailsPage = observer(() => {
  const { t } = useTranslate()

  const { nnrOrderItemStore } = usePlanningStore()

  const { nnrOrderId } = useParams()

  const navigate = useNavigate()
  const location = useLocation()
  const hasPreviousState = location.key !== 'default'

  if (!Number(nnrOrderId) || Number(nnrOrderId) < 0) {
    navigate(PATH_PLANNING.nnrOrders)
  }

  const id = Number(nnrOrderId)

  const nnrOrderLocalStore = useMemo(
    () => new NnrOrderLocalStore(nnrOrderItemStore, id),
    [nnrOrderItemStore, id],
  )

  const orderLocalPaginationStore = new PaginatedLocalStore(
    nnrOrderLocalStore,
    orderSortingDelegate,
    orderFilterDelegate,
  )

  useEffect(() => {
    const fetch = async () => {
      await nnrOrderLocalStore.fetch()
    }

    fetch().then(() => console.log('fetched orders & visits'))
  }, [id, nnrOrderLocalStore])

  if (!nnrOrderLocalStore.nnrOrder) return <></>

  return (
    <Container sx={{ padding: '2rem', display: 'flex', flexDirection: 'column' }}>
      <Container>
        <Grid container>
          <Grid item xs>
            <Typography variant='h4'>
              {t('non-numericRelease', 'Non-numeric release')}:{' '}
              {nnrOrderLocalStore.nnrOrder.data.referenceNumber}
            </Typography>
          </Grid>
          <Grid item xs={2} display='flex' justifyContent='flex-end'>
            <BackButton
              onGoBack={() => {
                if (hasPreviousState) {
                  navigate(-1)
                } else {
                  navigate(PATH_PLANNING.nnrOrders)
                }
              }}
              size='large'
              tooltip={t('return', 'Return')}
            />
          </Grid>
        </Grid>
        <Typography variant='subtitle1'>{nnrOrderLocalStore.nnrOrder.data.shippingLine}</Typography>
      </Container>
      <NNROrderDetailsTable
        nnrOrder={nnrOrderLocalStore.nnrOrder.data}
        orderStore={orderLocalPaginationStore}
      />
    </Container>
  )
})
