import { AppStore } from '@tom-ui/utils'
import { InternalAxiosRequestConfig } from 'axios'

export const onFulfilled = (appStore: AppStore) => {
  return (request: InternalAxiosRequestConfig<any>) => {
    appStore.increaseLoadingCounter()
    return request
  }
}

export const onRejected = (appStore: AppStore) => {
  return (error: any) => {
    appStore.decreaseLoadingCounter()
    return Promise.reject(error)
  }
}
