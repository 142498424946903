import { FC } from 'react'

export const TruckIlustration: FC<{ zoom?: number; horizontalFlip?: boolean }> = ({
  zoom,
  horizontalFlip,
}) => {
  return (
    <svg
      width='132'
      height='94'
      viewBox='0 0 132 94'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ transform: horizontalFlip ? 'scaleX(-1)' : '' }}
    >
      <path
        d='M77.9024 68.5417C77.9024 73.9495 73.4156 78.3334 67.8808 78.3334C62.346 78.3334 57.8592 73.9495 57.8592 68.5417C57.8592 63.134 62.346 58.7501 67.8808 58.7501C73.4156 58.7501 77.9024 63.134 77.9024 68.5417Z'
        fill='#919EAB'
      />
      <path
        d='M53.8505 15.6667C49.4227 15.6667 45.8333 19.1739 45.8333 23.5001V62.6667C45.8333 66.993 49.4227 70.5001 53.8505 70.5001H53.9926C53.899 69.8605 53.8505 69.2066 53.8505 68.5417C53.8505 60.9708 60.1321 54.8334 67.8808 54.8334C75.6295 54.8334 81.911 60.9708 81.911 68.5417C81.911 69.2066 81.8626 69.8605 81.7689 70.5001H90.0704C89.9767 69.8605 89.9283 69.2066 89.9283 68.5417C89.9283 60.9708 96.2099 54.8334 103.959 54.8334C111.707 54.8334 117.989 60.9708 117.989 68.5417C117.989 69.1442 117.949 69.7376 117.872 70.3196C122.054 69.4281 124.911 65.3647 123.995 61.0691L117.314 29.7358C116.54 26.1033 113.263 23.5001 109.465 23.5001H101.954C101.954 19.1739 98.3648 15.6667 93.9369 15.6667H53.8505Z'
        fill='#919EAB'
      />
      <path
        d='M113.98 68.5417C113.98 73.9495 109.493 78.3334 103.959 78.3334C98.4238 78.3334 93.9369 73.9495 93.9369 68.5417C93.9369 63.134 98.4238 58.7501 103.959 58.7501C109.493 58.7501 113.98 63.134 113.98 68.5417Z'
        fill='#919EAB'
      />
      <path d='M38 31L7 31' stroke='#919EAB' strokeWidth='2' strokeLinecap='round' />
      <path d='M38 39L16 39' stroke='#919EAB' strokeWidth='2' strokeLinecap='round' />
      <path d='M38 48L1 48' stroke='#919EAB' strokeWidth='2' strokeLinecap='round' />
    </svg>
  )
}

export default TruckIlustration
