import { Box, styled } from '@mui/material'
import { ReactNode } from 'react'

interface Props {
  children: ReactNode
  isForStepper?: boolean
}
export const DescriptionContainer = ({ children, isForStepper }: Props) => {
  const ContainerDescription = styled(Box)(({ theme }) => ({
    padding: `${theme.customSpacing.m} ${isForStepper ? '0' : ''}`,
    gap: theme.customSpacing.xl,
    display: 'flex',
    backgroundColor: isForStepper ? 'transparent' : theme.palette.grey[100],
    overflowX: 'auto',
  }))

  return <ContainerDescription>{children}</ContainerDescription>
}
