import { Metrics } from '@operations/features/craneSplit/models/metric.model'
import { Grid } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import _ from 'lodash'
import { MetricCard } from '../MetricCard/MetricCard'

interface Props {
  metrics: Metrics
}

export const MetricList = ({ metrics }: Props) => {
  const { t } = useTranslate()

  return (
    <Grid container spacing={4} id='metric-list'>
      <Grid item={true} xs={12} sm={4}>
        <MetricCard
          title={t('moves', 'Moves')}
          total={_.sumBy(metrics.moves, x => x.total)}
          items={metrics.moves}
        ></MetricCard>
      </Grid>
      <Grid item={true} xs={12} sm={4}>
        <MetricCard
          title={t('containers', 'Containers')}
          total={_.sumBy(metrics.containers, x => x.total)}
          items={metrics.containers}
        ></MetricCard>
      </Grid>
      <Grid item={true} xs={12} sm={4}>
        <MetricCard
          title={t('hours', 'Hours')}
          total={_.sumBy(metrics.hours, x => x.total)}
          items={metrics.hours}
        ></MetricCard>
      </Grid>
    </Grid>
  )
}
