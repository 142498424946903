import { Chip, SelectProps, SxProps } from '@mui/material'
import { CarrierVisitStatus } from '@storage/app/api'
import { MultiColumnSelectOption } from '@storage/app/models/multi-column-select-option.model'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { TrainIcon, VesselIcon } from '@tom-ui/ui'
import { FieldValues, FormState, UseControllerProps } from 'react-hook-form'
import { ControlledMultiColumnSelect } from '../ControlledMultiColumnSelect'

type FieldProps = Pick<SelectProps, 'disabled' | 'multiple' | 'size' | 'margin'>

interface Props<T extends FieldValues> extends UseControllerProps<T>, FieldProps {
  label: string
  formState: FormState<T>
  fullWidth?: boolean
  formControlSx?: SxProps
  autoFocus?: boolean
  maxMenuHeight?: string | number
  activeVesselVisitsOnly?: boolean
  allOption?: boolean
  discriminator: string
}

export const ControlledCarrierVisitSelect = <T extends FieldValues>({
  name,
  label,
  control,
  disabled,
  multiple,
  fullWidth,
  size,
  formState,
  formControlSx,
  margin,
  maxMenuHeight,
  activeVesselVisitsOnly,
  allOption,
  discriminator,
}: Props<T>) => {
  const { t } = useTranslate()

  const { carrierVisitStore } = useStores()

  const chipColor = (status: CarrierVisitStatus) => {
    switch (status) {
      case CarrierVisitStatus.Expected:
        return 'info'
      case CarrierVisitStatus.Arrived:
        return 'secondary'
      case CarrierVisitStatus.InOperation:
        return 'warning'
      case CarrierVisitStatus.Departed:
        return 'primary'
      default:
        return 'default'
    }
  }

  const carrierVisitSelectOptions = (): MultiColumnSelectOption[] => {
    const allVisits = activeVesselVisitsOnly
      ? carrierVisitStore.getActiveCarrierVisits()
      : carrierVisitStore.entries

    const visits =
      discriminator === 'Train'
        ? allVisits.filter(x => x.discriminator === 'RailVisit')
        : allVisits.filter(x => x.discriminator === 'VesselVisit')
    const regardAnyOption = allOption
      ? [
          {
            value: 'All',
            columns: [
              { label: discriminator === 'Train' ? <TrainIcon /> : <VesselIcon /> },
              { label: 'All' },
            ],
            displayText: 'All',
          },
        ]
      : []
    return [
      ...regardAnyOption,
      ...visits.map(visit => {
        const arrival = visit.ata ?? visit.eta
        const arrivalTimeToStr = arrival ? carrierVisitStore.getArrivalTimeToString(arrival) : ''

        return {
          value: visit.id,
          columns: [
            {
              label: (
                <Chip
                  color={chipColor(visit.status)}
                  label={t(visit.status, visit.status)}
                  size='small'
                  sx={{ fontSize: 10 }}
                />
              ),
            },
            { label: visit.name },
            {
              label: `ETA: ${arrivalTimeToStr}`,
              sx: { fontSize: 12, justifyContent: 'flex-end' },
            },
          ],
          displayText: visit.name,
        }
      }),
    ]
  }

  return (
    <ControlledMultiColumnSelect<T>
      name={name}
      formState={formState}
      control={control}
      label={label}
      fullWidth={fullWidth}
      columnWidths={['20%', '50%', '30%']}
      options={carrierVisitSelectOptions()}
      disabled={disabled}
      multiple={multiple}
      size={size}
      formControlSx={formControlSx}
      margin={margin}
      maxMenuHeight={maxMenuHeight}
    />
  )
}
