import { Box, Chip } from '@mui/material'
import { CarrierVisitSummaryFilterDto, ContainerStatus } from '@storage/app/api'
import { BooleanSelectOption } from '@storage/app/models'
import { ComponentStatus, Size } from '@storage/helpers/enums'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { ValueBox } from './ValueBox'

interface PreFilterBoxProps {
  filter: CarrierVisitSummaryFilterDto
  selected?: boolean
  selectedLocationStatus?: ContainerStatus
  onClick: () => void
  onShowPlanned: () => void
  onShowUnplanned: () => void
}

export const PreFilterBox = ({
  filter,
  selected,
  selectedLocationStatus,
  onClick,
  onShowPlanned,
  onShowUnplanned,
}: PreFilterBoxProps) => {
  const { palette } = useMinimalsTheme()
  const { t } = useTranslate()

  /**
   * Mapping for the prefilter box component
   * @param filter
   * @returns list of labels
   */
  const mapFilterToLabels = (filter: { [key: string]: string } | null | undefined): string[] => {
    if (!filter) {
      return [t('all', 'All')]
    }

    const labels: string[] = []

    if (filter.isEmpty) {
      filter.isEmpty === BooleanSelectOption.True
        ? labels.push(t('empty', 'Empty'))
        : labels.push(t('full', 'Full'))
    }

    if (filter.isReefer === BooleanSelectOption.True) {
      labels.push(t('Reefer', 'Reefer'))
    }

    if (filter.isDangerous === BooleanSelectOption.True) {
      labels.push(t('dangerous', 'Dangerous'))
    }

    if (!filter.size) {
      labels.push(...[`20'`, `40'`])
    } else {
      filter.size === '20' ? labels.push(`20'`) : labels.push(`40'`)
    }
    return labels
  }

  const selectedPrefilterBorderStyle = `1px ${palette.text.primary} solid`

  return (
    <Box
      onClick={onClick}
      py={1}
      px={2}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        bgcolor: selected ? '#1890FF14' : '',
        '&:hover': {
          backgroundColor: '#1890FF14',
        },
      }}
    >
      <Box sx={{ display: 'flex', gap: 1 }}>
        {mapFilterToLabels(filter.settings).map(label => (
          <Chip key={label} size='small' label={label} />
        ))}
      </Box>

      <Box sx={{ display: 'flex', gap: 1 }} data-cy='workload-value-boxes'>
        <ValueBox
          onClick={e => {
            e.stopPropagation()

            onShowUnplanned()
          }}
          size={Size.Small}
          value={filter.unplanned}
          color={palette.grey[300]}
          sx={{
            minWidth: '30px',
            border: selectedLocationStatus === 'NotReserved' ? selectedPrefilterBorderStyle : '',
          }}
          valueColor={palette.text.primary}
        />
        <ValueBox
          size={Size.Small}
          value={filter.planned}
          color={`${palette.info.main}14`}
          state={ComponentStatus.Info}
          valueColor={palette.info.main}
          onClick={e => {
            e.stopPropagation()

            onShowPlanned()
          }}
          sx={{
            minWidth: '30px',
            border: selectedLocationStatus === 'Reserved' ? selectedPrefilterBorderStyle : '',
          }}
        />
      </Box>
    </Box>
  )
}
