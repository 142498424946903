import { Box, Stack, styled } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { Tile, Timeline } from '@tom-ui/ui'

const DirectionLabel = styled(Box)(() => ({
  position: 'absolute',
  left: '20px',
}))

interface Props {
  origin: string
  destination: string
  requireYardReservation?: boolean
}
export const JobJourney = ({ origin, destination, requireYardReservation }: Props) => {
  const { t } = useTranslate()

  return (
    <Stack position='relative' paddingY={theme => theme.customSpacing.m}>
      <DirectionLabel sx={{ top: 0 }}>
        <Tile label={t('origin', 'Origin')} value={origin} dataCy='job-card-origin' />
      </DirectionLabel>

      <Timeline size='90px' direction='vertical' />

      <DirectionLabel sx={{ bottom: 0 }}>
        <Tile
          label={t('destination', 'Destination')}
          value={requireYardReservation ? t('noReservation', 'No reservation') : destination}
          dataCy='job-card-destination'
        />
      </DirectionLabel>
    </Stack>
  )
}
