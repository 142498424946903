import { QueryParams } from '@storage/app/models'
import { YardManagementFlows } from './flows.enum'

export interface YardManagementFlowOptions extends QueryParams {
  readonly flow: YardManagementFlows
}

export const YardManagementFlowKeys = {
  flow: 'flow',
}
