import { IListStore, SimpleListStore } from '@planning/components/list/SimpleListStore'
import { action, makeObservable, observable } from 'mobx'

export interface IAutocompleteViewStore<T> extends IListStore<T> {
  isOpen: boolean
  setOpen: (open: boolean) => void
}

export class AutocompleteViewStore<T>
  extends SimpleListStore<T>
  implements IAutocompleteViewStore<T>
{
  isOpen = false

  constructor(fetchFunc: (filter?: string) => Promise<T[]>) {
    super(fetchFunc)

    makeObservable(this, {
      isOpen: observable,
      setOpen: action,
    })
  }

  setOpen = (open: boolean) => {
    this.isOpen = open
  }

  reset = () => {
    super.reset()
    this.isOpen = false
  }
}
