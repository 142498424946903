import { createApiClient } from '@operations/app/http-client'

import { InternalApiApi } from '@operations/app/http-client/reset-data-api'

export class DataSnapshotStore {
  async saveSnapshot() {
    await createApiClient(InternalApiApi).saveDemoData()
  }

  async resetSnapshot() {
    await createApiClient(InternalApiApi).resetData()
  }
}
