import { action, computed, makeObservable, observable } from 'mobx'

export type SeverityType = 'success' | 'info' | 'warning' | 'error'

interface ShowOptions {
  entityId?: string
}

const defaultShowOptions: ShowOptions = {
  entityId: '',
}

export class ConfirmationDialogStore {
  private resolver?: (value: boolean | PromiseLike<boolean>) => void

  entityId = defaultShowOptions.entityId
  title = ''
  message = ''

  constructor() {
    makeObservable(this, {
      isOpen: computed,

      entityId: observable,
      title: observable,
      message: observable,

      confirm: action,
      answer: action,
    })
  }

  get isOpen(): boolean {
    return !!this.message
  }

  confirm(message: string, title = '', { entityId }: ShowOptions = defaultShowOptions) {
    this.entityId = entityId
    this.title = title
    this.message = message

    const promise = new Promise<boolean>(resolve => {
      this.resolver = resolve
    })

    return promise
  }

  answer(value: boolean) {
    this.entityId = defaultShowOptions.entityId
    this.title = ''
    this.message = ''

    this.resolver?.(value)
  }
}
