import { Paper } from '@mui/material'
import { CarrierVisitDirection } from '@planning/app/api'
import { RailcarSequencingStore } from '@planning/stores/railcarSequencing/RailcarSequencingStore'
import { FC } from 'react'
import { ObserverGrouping } from './Components/Grouping/ObserverGrouping'

interface IProps {
  store: RailcarSequencingStore
  direction: CarrierVisitDirection
}

export const RailOrderGrouping: FC<IProps> = ({ store, direction }) => {
  return (
    <Paper variant='elevation' elevation={1}>
      <ObserverGrouping store={store} direction={direction} />
    </Paper>
  )
}
