import { SelectOption } from '@billing/app/models'

export interface CommodityFormProfile {
  id?: number
  name: string
  height?: number
  length?: number
  width?: number
  weight?: number
  classDefinition?: string
  packageIds?: SelectOption[]
  instructions?: string
  attributes: CommodityAttributeFormProfile[]
}

export interface CommodityAttributeFormProfile {
  name: string
  value: string
}

export const defaultValues: CommodityFormProfile = {
  name: '',
  attributes: [],
  packageIds: [],
}

export const defaultAttributeValues: CommodityAttributeFormProfile = {
  name: '',
  value: '',
}
