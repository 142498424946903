import { useBillingServiceStore } from '@billing/AppProvider'
import gcStorageReportModes, {
  GCStorageReportMode,
} from '@billing/app/models/gc-storage-report-mode.enum'
import { Asterisk } from '@billing/components/asterisk/Asterisk'
import { ModeButton } from '@billing/components/button/ModeButton'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { Box, Stack } from '@mui/system'
import { MobileDatePicker } from '@mui/x-date-pickers'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

export const StoragePreBillingReportFilter = observer(() => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const { gcPreBillingStorageUIStore, reportsUIStore, customerStore } = useBillingServiceStore()
  const localFormat = dayjs.Ls[dayjs.locale()].formats.L

  useEffect(() => {
    async function fetchData() {
      await customerStore.load()
    }

    fetchData()
  }, [customerStore])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }} gap={theme.customSpacing.m}>
      <Box sx={{ display: 'flex', marginBottom: '16px' }} gap={theme.customSpacing.m}>
        {gcStorageReportModes.map((reportMode: GCStorageReportMode) => (
          <ModeButton
            key={reportMode}
            title={
              reportMode === GCStorageReportMode.InboundOrder
                ? t('byInboundOrder', 'By Inbound Order')
                : t('byStock', 'By Stock')
            }
            subtitle={
              reportMode === GCStorageReportMode.InboundOrder
                ? t('allCompletedInboundOrder', 'All Completed Inbound Orders')
                : t('allStockInventory', 'All Stock inventory')
            }
            onClick={() => gcPreBillingStorageUIStore.setReportMode(reportMode)}
            isSelected={reportMode === gcPreBillingStorageUIStore.reportMode}
          />
        ))}
      </Box>
      <FormControl fullWidth>
        <InputLabel id='customer-select-label'>{t('customer', 'Customer')}</InputLabel>
        <Select
          labelId='customer-select-label'
          value={gcPreBillingStorageUIStore.customerId}
          label={t('customer', 'Customer')}
          onChange={(event: SelectChangeEvent) =>
            gcPreBillingStorageUIStore.setCustomerId(event.target.value)
          }
        >
          {gcPreBillingStorageUIStore.customerOptions.map(c => (
            <MenuItem key={c.value} value={c.value}>
              {c.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Stack gap={theme.customSpacing.xs}>
        <Typography variant='subtitle2'>
          {t('period', 'Period')} {Asterisk}
        </Typography>
        <Stack
          gap={theme.customSpacing.m}
          sx={{
            [theme.breakpoints.up('sm')]: {
              flexDirection: 'row',
            },
          }}
        >
          <MobileDatePicker
            label={t('from', 'From')}
            value={gcPreBillingStorageUIStore.from}
            onChange={newValue => gcPreBillingStorageUIStore.setFrom(newValue)}
            disableFuture={true}
            maxDate={gcPreBillingStorageUIStore.to}
            slotProps={{
              actionBar: {
                actions: ['clear', 'accept', 'today'],
              },
              textField: {
                required: true,
                error:
                  reportsUIStore.nextButtonWasBlocked && !gcPreBillingStorageUIStore.from
                    ? true
                    : undefined,
                helperText: localFormat,
              },
            }}
            sx={{
              width: '100%',
              [theme.breakpoints.up('sm')]: {
                width: '268px',
              },
            }}
          />
          <MobileDatePicker
            label={t('to', 'To')}
            value={gcPreBillingStorageUIStore.to}
            onChange={newValue => gcPreBillingStorageUIStore.setTo(newValue)}
            disableFuture={true}
            minDate={gcPreBillingStorageUIStore.from}
            slotProps={{
              actionBar: {
                actions: ['clear', 'accept', 'today'],
              },
              textField: {
                required: true,
                error:
                  reportsUIStore.nextButtonWasBlocked && !gcPreBillingStorageUIStore.to
                    ? true
                    : undefined,
                helperText: localFormat,
              },
            }}
            sx={{
              width: '100%',
              [theme.breakpoints.up('sm')]: {
                width: '268px',
              },
            }}
          />
        </Stack>
      </Stack>
    </Box>
  )
})
