import { Button } from '@mui/material'
import Box from '@mui/material/Box'
import { CarrierVisitDirection } from '@planning/app/api'
import TruckIlustration from '@planning/components/icon/ilustration/TruckIlustration'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { FC } from 'react'

interface Props {
  direction: CarrierVisitDirection
  onClick: () => void
}

export const TruckDirection: FC<Props> = ({ direction, onClick }) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const directionLabel =
    direction === CarrierVisitDirection.Inbound ? t('gateIn', 'Gate in') : t('gateOut', 'Gate out')

  return (
    <Box
      sx={{
        maxWidth: '420px',
        width: '100%',
        padding: '1rem',
        borderRadius: theme.customRadius.medium,
        margin: '0 auto 1rem auto!important',
        textAlign: 'center',
        bgcolor: theme.palette.common.white,
        boxShadow: '0px 8px 16px 0px rgba(145, 158, 171, 0.16)',
      }}
    >
      <TruckIlustration
        horizontalFlip={direction === CarrierVisitDirection.Outbound ? true : false}
      ></TruckIlustration>
      <Button
        variant='contained'
        size='large'
        sx={{ width: '100%', mt: '1rem' }}
        data-cy={`gate-passage-truck-button-${direction.toLocaleLowerCase()}`}
        onClick={onClick}
      >
        {directionLabel}
      </Button>
    </Box>
  )
}
