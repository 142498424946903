import {
  CarrierVisitDirection,
  ContainerJourneyDto,
  OrderResponseDto,
  OrderStatus,
  RailVisitResponseDto,
  TruckVisitDto,
  VesselVisitDto,
} from '@planning/app/api'
import { SimpleListStore } from '@planning/components/list/SimpleListStore'
import { IInspectContainerFormData } from '@planning/pages/GateClerk/Components/InspectContainer'
import { IOrderWithVisit } from '@planning/pages/Order/stores/SelectOrderViewStore'
import { orderService, truckVisitService } from '@planning/services'
import generalCargoService from '@planning/services/generalCargoService'
import _ from 'lodash'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import moment from 'moment'
import { GateOperationViewStore } from '../gateClerk/GateOperationViewStore'
import { GatePassageNotificationStore } from '../gateClerk/GatePassageNotificationStore'
import { NonNumericOrderWithPickUpAmount } from '../truckAppointment/TruckAppointmentDetailsViewStore'
import { ContainerStackOutFunc, ContainerStackOutSequenceDto } from './ContainerStackOutDto'
import { PickUpOrderSearchStore } from './PickUpOrderSearchStore'
import { ValidateOutboundOrderFunc } from './ValidateOutboundDto'

export type TruckOrderType = 'pickUp' | 'dropOff'
export type EditedType = 'pickUp' | 'dropOff' | null
export type EditedOrder = IInspectContainerFormData | null
export type DropOffOrderSearchType = 'container' | 'generalCargo' | null
export type DropOffOrderContainerSearchType = 'referenceNumber' | 'containerNumber' | null
export class GateInViewStore extends GateOperationViewStore {
  licensePlate = ''
  driverName?: string
  isTruckAppointment = false
  truckAppointmentDate?: string | null = null
  truckAppointmentStartTime?: string | null = null
  truckAppointmentEndTime?: string | null = null

  dropOffOrders: IInspectContainerFormData[] = []
  pickUpGeneralCargoOrders: IInspectContainerFormData[] = []
  dropOffGeneralCargoOrders: IInspectContainerFormData[] = []
  pickUpOrders: IInspectContainerFormData[] = []
  nnrOrders: NonNumericOrderWithPickUpAmount[] = []
  ordersByContainerNumber: OrderResponseDto[] = []
  vesselVisits: VesselVisitDto[] = []
  railVisits: RailVisitResponseDto[] = []
  isGateInDialogOpen = false
  isDialogEditMode = false
  editedType: EditedType = null
  editedOrder: EditedOrder = null
  containerSearchType: DropOffOrderContainerSearchType = 'containerNumber'
  searchType: DropOffOrderSearchType = 'container'
  truckVisitId?: number
  selectedVisitWithOrders: IOrderWithVisit[] = []

  containerShifts: ContainerStackOutSequenceDto[] = []
  referenceNumber: string | null = null
  getContainersWithStackOutSequenceByReference?: ContainerStackOutFunc
  validateOutboundRequest?: ValidateOutboundOrderFunc

  // todo: store tests
  public pickUpOrderSearchStore: PickUpOrderSearchStore
  // todo: store tests
  public dropOffOrderSearchStore: SimpleListStore<ContainerJourneyDto | OrderResponseDto>
  // todo: store tests
  public notificationStore: GatePassageNotificationStore

  constructor() {
    super()

    makeObservable(this, {
      licensePlate: observable,
      driverName: observable,
      truckAppointmentDate: observable,
      truckAppointmentStartTime: observable,
      truckAppointmentEndTime: observable,
      dropOffOrders: observable,
      dropOffGeneralCargoOrders: observable,
      pickUpGeneralCargoOrders: observable,
      pickUpOrders: observable,
      nnrOrders: observable,
      ordersByContainerNumber: observable,
      vesselVisits: observable,
      railVisits: observable,
      truckVisitId: observable,
      selectedVisitWithOrders: observable,

      isGateInDialogOpen: observable,
      isDialogEditMode: observable,
      editedOrder: observable,
      editedType: observable,
      containerShifts: observable,
      referenceNumber: observable,
      containerSearchType: observable,
      searchType: observable,
      isTruckAppointment: observable,

      setIsTruckAppointment: action,
      setEditedOrder: action,
      setLicensePlate: action,
      setTruckVisitId: action,
      setTruckAppointmentDate: action,
      setTruckAppointmentStartTime: action,
      setTruckAppointmentEndTime: action,
      setReferenceNumberAndGetContainerShifts: action,
      setDriverName: action,
      upsertPickUpOrderById: action,
      toggleGateInDialog: action,
      toggleEditDialogVisibility: action,
      upsertDropOffOrder: action,
      upsertPickUpOrder: action,
      upsertNNROrder: action,
      upsertPickUpGeneralCargoOrder: action,
      upsertDropOffGeneralCargoOrder: action,
      deleteDropOffOrder: action,
      deletePickUpOrder: action,
      deleteNNROrder: action,
      deletePickUpGeneralCargoOrder: action,
      deleteDropOffGeneralCargoOrder: action,
      setContainerSearchType: action,
      setSearchType: action,
      resetEditMode: action,
      reset: action,

      isSearchingByReferenceNumber: computed,
      truckAppointmentEstimatedTimes: computed,
    })

    this.pickUpOrderSearchStore = new PickUpOrderSearchStore()
    this.dropOffOrderSearchStore = new SimpleListStore(async (filter?: string) => {
      if (filter && filter.trim() !== '') {
        if (this.searchType === 'generalCargo') {
          const generalCargoOrders = (
            await generalCargoService.getByCustomerOrReferenceNumber(
              filter,
              CarrierVisitDirection.Inbound,
            )
          ).filter(o => !o.carrierVisitId && o.status === OrderStatus.Open) as ContainerJourneyDto[]

          return _(generalCargoOrders)
            .sortBy(o => o.referenceNumber)
            .value()
        } else {
          const dropOffOrders = await orderService.dropOffOrdersContainerJourney(filter)

          const orders = await orderService.getByContainerNumber(filter, OrderStatus.Open)
          runInAction(() => {
            this.ordersByContainerNumber = orders
          })

          const filteredData: Record<string, ContainerJourneyDto> = {}
          const getItemKey = (direction: CarrierVisitDirection, id?: number, linkedId?: number) => {
            const inboundId = direction === CarrierVisitDirection.Inbound ? id : linkedId
            const outboundId = direction === CarrierVisitDirection.Outbound ? id : linkedId
            return `${inboundId}_${outboundId}`
          }

          dropOffOrders.forEach((item: ContainerJourneyDto) => {
            if (item.containerNumber) {
              const itemKey = getItemKey(item.direction, item.id, item.linkedOrder?.id)
              if (filteredData[itemKey]) {
                if (item.direction === 'Inbound' && item.linkedOrder !== null) {
                  filteredData[itemKey] = item
                }
              } else {
                filteredData[itemKey] = item
              }
            }
          })

          return Object.values(filteredData)
        }
      }

      return []
    })
    this.notificationStore = new GatePassageNotificationStore()
    this.notificationStore.setDirection(CarrierVisitDirection.Inbound)
  }

  setLicensePlate = (licensePlate: string) => {
    this.licensePlate = licensePlate
  }

  setDriverName = (driverName: string) => {
    this.driverName = driverName
  }

  setTruckVisitId = (truckVisitId: number) => {
    this.truckVisitId = truckVisitId
  }

  setTruckAppointmentDate = (timestamp: string) => {
    this.truckAppointmentDate = timestamp
  }

  setTruckAppointmentStartTime = (timestamp: string) => {
    this.truckAppointmentStartTime = timestamp
  }

  setTruckAppointmentEndTime = (timestamp: string) => {
    this.truckAppointmentEndTime = timestamp
  }

  setReferenceNumberAndGetContainerShifts = async (referenceNumber: string | null) => {
    this.referenceNumber = referenceNumber

    if (this.getContainersWithStackOutSequenceByReference && referenceNumber) {
      const shifts = await this.getContainersWithStackOutSequenceByReference(referenceNumber)

      runInAction(() => {
        this.containerShifts = shifts
      })
    }
  }

  upsertPickUpOrderById = async (orderId: number) => {
    const order = await orderService.getById(orderId)

    if (order) {
      this.upsertPickUpOrder(order as IInspectContainerFormData)
    }
  }

  upsertDropOffOrder = (order: IInspectContainerFormData) => {
    this.dropOffOrders = [...this.dropOffOrders.filter(o => o.id !== order.id), order]
  }

  deleteDropOffOrder = (orderId: number) => {
    this.dropOffOrders = [...this.dropOffOrders.filter(o => o.id !== orderId)]
  }

  upsertPickUpOrder = (order: IInspectContainerFormData) => {
    this.pickUpOrders = [...this.pickUpOrders.filter(o => o.id !== order.id), order]
  }

  deletePickUpOrder = (orderId: number) => {
    this.pickUpOrders = [...this.pickUpOrders.filter(o => o.id !== orderId)]
  }

  upsertPickUpGeneralCargoOrder = (order: IInspectContainerFormData) => {
    this.pickUpGeneralCargoOrders = [
      ...this.pickUpGeneralCargoOrders.filter(o => o.id !== order.id),
      order,
    ]
  }

  deletePickUpGeneralCargoOrder = (orderId: number) => {
    this.pickUpGeneralCargoOrders = [...this.pickUpGeneralCargoOrders.filter(o => o.id !== orderId)]
  }

  upsertDropOffGeneralCargoOrder = (order: IInspectContainerFormData) => {
    this.dropOffGeneralCargoOrders = [
      ...this.dropOffGeneralCargoOrders.filter(o => o.id !== order.id),
      order,
    ]
  }

  deleteDropOffGeneralCargoOrder = (orderId: number) => {
    this.dropOffGeneralCargoOrders = [
      ...this.dropOffGeneralCargoOrders.filter(o => o.id !== orderId),
    ]
  }

  upsertNNROrder = (order: NonNumericOrderWithPickUpAmount) => {
    this.nnrOrders = [...this.nnrOrders.filter(o => o.id !== order.id), order]
  }

  deleteNNROrder = (orderId: number) => {
    this.nnrOrders = [...this.nnrOrders.filter(o => o.id !== orderId)]
  }

  setIsTruckAppointment = (isTruckAppointment: boolean) => {
    this.isTruckAppointment = isTruckAppointment
  }

  toggleGateInDialog = (isOpen: boolean) => {
    this.isGateInDialogOpen = isOpen
  }

  toggleEditDialogVisibility = (isOpen: boolean) => {
    this.isDialogEditMode = isOpen
  }

  setEditedOrder = (order: EditedOrder) => {
    this.editedOrder = order
  }

  setEditedType = (type: EditedType) => {
    this.editedType = type
  }

  setContainerSearchType = (searchType: DropOffOrderContainerSearchType) => {
    this.containerSearchType = searchType
  }

  setSearchType = (searchType: DropOffOrderSearchType) => {
    this.dropOffOrderSearchStore.reset()
    this.searchType = searchType

    if (searchType === 'generalCargo') {
      this.setContainerSearchType('referenceNumber')
    } else {
      this.setContainerSearchType('containerNumber')
    }
  }

  resetEditMode = () => {
    this.toggleEditDialogVisibility(false)
    this.setEditedOrder(null)
    this.setEditedType(null)
  }

  reset = () => {
    this.resetDialogs()
    this.toggleGateInDialog(false)
    this.resetEditMode()
    this.dropOffOrderSearchStore.reset()
    this.pickUpOrderSearchStore.reset()
    this.licensePlate = ''
    this.dropOffOrders = []
    this.pickUpOrders = []
    this.nnrOrders = []
    this.pickUpGeneralCargoOrders = []
    this.dropOffGeneralCargoOrders = []
    this.ordersByContainerNumber = []
    this.referenceNumber = null
    this.truckAppointmentDate = null
    this.truckAppointmentStartTime = null
    this.truckAppointmentEndTime = null
    this.truckVisitId = undefined
    this.driverName = ''
    this.selectedVisitWithOrders = []
  }

  get isSearchingByReferenceNumber() {
    return (
      this.pickUpOrderSearchStore.containerSearchType === 'referenceNumber' &&
      this.pickUpOrderSearchStore.searchType === 'container' &&
      !this.referenceNumber
    )
  }

  get truckAppointmentEstimatedTimes() {
    if (!this.truckAppointmentDate) return { eta: null, etd: null }

    const etaTimeBase = moment(this.truckAppointmentDate).startOf('d')
    let etdTimeBase = moment(this.truckAppointmentDate).endOf('d')

    if (this.truckAppointmentStartTime) {
      const start = moment(this.truckAppointmentStartTime)
      etaTimeBase.add(start.hour(), 'h')
      etaTimeBase.add(start.minute(), 'm')
    }

    if (this.truckAppointmentEndTime) {
      const end = moment(this.truckAppointmentEndTime)
      etdTimeBase = moment(this.truckAppointmentDate).startOf('d')
      etdTimeBase.add(end.hour(), 'h')
      etdTimeBase.add(end.minute(), 'm')
    }

    return {
      eta: etaTimeBase.toLocaleString(),
      etd: etdTimeBase.toLocaleString(),
    }
  }

  setSelectedVisitWithOrders = (orders: IOrderWithVisit[]) => {
    this.selectedVisitWithOrders = orders
  }

  clearSelectedOrders = () => {
    this.selectedVisitWithOrders = []
  }

  gateOut = async () => {
    if (!this.selectedVisitWithOrders[0].visit) return

    const orderIds = this.selectedVisitWithOrders
      .filter(o => !!o.order)
      .map(order => order.order!.id)

    await truckVisitService.allowExit({
      id: this.selectedVisitWithOrders[0].visit.id,
      orderIds: orderIds,
    })

    this.resetDialogs()
    this.clearSelectedOrders()
  }

  validateManualGateOut = (truckVisit: TruckVisitDto, selectedOrders: OrderResponseDto[]) => {
    this.setSelectedVisitWithOrders(
      selectedOrders.map(order => ({
        visit: truckVisit,
        order,
      })),
    )

    const ordersWithPendingJobs = this.selectedVisitWithOrders
      .map(order => order.order)
      .filter(order => order?.isJobFinished === false)

    if (ordersWithPendingJobs.length > 0) {
      this.openGateOutWithPendenciesConfirmationDialog()
    } else {
      this.openGateOutConfirmationDialog()
    }
  }
}
