import { Button, Stack, Typography } from '@mui/material'
import {
  overwriteMinimalTableBodyCellStyle,
  overwriteMinimalTableBodyRowStyle,
  overwriteMinimalTableDetailPanelStyle,
  overwriteMinimalTableHeadCellStyle,
} from '@operations/utils/minimal-theme-overwrite'
import { useTranslate } from '@tolgee/react'
import { ButtonOption, OptionsButton } from '@tom-ui/ui'
import MaterialReactTable, { MRT_ColumnDef, MRT_TableInstance } from 'material-react-table'
import { MutableRefObject } from 'react'

interface Props<TData extends Record<string, any>> {
  items: TData[]
  caption?: string
  columns: MRT_ColumnDef<TData>[]
  stopAutoResetPageIndex?: boolean
  tableInstanceRef?: MutableRefObject<MRT_TableInstance<TData> | null>
  isFiltering?: boolean
  getRowId?: (item: TData) => string
  onClearFilters?: () => void
  expandElement?: (item: TData) => JSX.Element
  onDeleteItem?: (id: number) => Promise<void> | void
  onEditItem?: (id: number) => Promise<void> | void
  customizeRowActions?: (row: any) => JSX.Element
}

export const EntityTable = <T extends Record<string, any>>({
  items,
  caption,
  columns,
  stopAutoResetPageIndex,
  tableInstanceRef,
  isFiltering,
  getRowId,
  onClearFilters,
  expandElement,
  onDeleteItem,
  onEditItem,
  customizeRowActions,
}: Props<T>) => {
  const { t } = useTranslate()

  const noResult = (
    <Stack justifyContent='center' alignItems={'center'} component='span' spacing={1}>
      <Typography component='span' variant='subtitle1'>
        {t('noResultFound', 'No result found')}
      </Typography>
      {isFiltering && !!onClearFilters && (
        <>
          <Typography component='span' align='center' variant='body1' width='18rem'>
            {t(
              'tryAdjustingYourSearchOrFilterOptions',
              'Try adjusting your search or filter options to find what you are looking for.',
            )}
          </Typography>
          <Button color='info' onClick={onClearFilters}>
            {t('clearFilter', 'Clear filter')}
          </Button>
        </>
      )}
    </Stack>
  ) as any

  return (
    <>
      {caption ? <h2>{caption}</h2> : <></>}

      <MaterialReactTable
        tableInstanceRef={tableInstanceRef}
        muiTableHeadCellProps={overwriteMinimalTableHeadCellStyle}
        muiTableDetailPanelProps={overwriteMinimalTableDetailPanelStyle}
        muiTableBodyRowProps={({ row }) => overwriteMinimalTableBodyRowStyle(row.getIsExpanded())}
        muiTableBodyCellProps={expandElement ? undefined : overwriteMinimalTableBodyCellStyle}
        muiTablePaginationProps={{
          backIconButtonProps: { color: 'default' },
          nextIconButtonProps: { color: 'default' },
        }}
        columns={columns}
        enableDensityToggle={false}
        enableHiding={false}
        enableColumnActions={false}
        enableTopToolbar={false}
        autoResetPageIndex={!stopAutoResetPageIndex}
        data={items}
        enableRowActions={!!customizeRowActions}
        positionActionsColumn='last'
        localization={{
          noRecordsToDisplay: noResult,
        }}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            header: '',
            muiTableBodyCellProps: {
              align: 'right',
            },
          },
        }}
        getRowId={getRowId ? row => getRowId(row) : undefined}
        renderDetailPanel={
          expandElement ? ({ row }: any) => expandElement(row.original) : undefined
        }
        renderRowActions={
          customizeRowActions
            ? ({ row }: any) => customizeRowActions(row)
            : ({ row }: any) => (
                <OptionsButton
                  tooltip={t('moreOptions', 'More options')}
                  size='medium'
                  options={[
                    ...(onEditItem
                      ? [
                          {
                            label: t('edit', 'Edit'),
                            onClick: () => onEditItem(row.original.id),
                          } as ButtonOption,
                        ]
                      : []),
                    ...(onDeleteItem
                      ? [
                          {
                            label: t('remove', 'Remove'),
                            onClick: () => onDeleteItem(row.original.id),
                            color: 'error',
                          } as ButtonOption,
                        ]
                      : []),
                  ]}
                />
              )
        }
      />
    </>
  )
}
