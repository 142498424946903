import _ from 'lodash'
import { action, makeObservable, observable } from 'mobx'
import { IElementWithId, IEntity } from '../types'

export class EntityItem<U extends IElementWithId<V>, V = number> implements IEntity<U, V> {
  lastUpdated = Date.now()
  constructor(
    public id: V,
    public data: U,
  ) {
    makeObservable(this, {
      id: observable,
      data: observable,
      lastUpdated: observable,
      update: action,
    })
  }

  update = (data: U) => {
    if (_.isEqual(this.data, data)) return false
    _.assign(this.data, data)
    this.lastUpdated = Date.now()
    return true
  }
}
