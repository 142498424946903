import { Card, CardContent, Typography } from '@mui/material'

interface Props {
  label: string
  value: number
}

export const WorkInstructionInfoCard = ({ label, value }: Props) => {
  return (
    <Card>
      <CardContent>
        <Typography textAlign='center' variant='h2'>
          {value}
        </Typography>
        <Typography textAlign='center' variant='body1'>
          {label}
        </Typography>
      </CardContent>
    </Card>
  )
}
