import { alpha, Box } from '@mui/system'
import { useMinimalsTheme } from '@tom-ui/ui'

const StackHighlightLayer = () => {
  const { palette } = useMinimalsTheme()

  return (
    <Box
      position='absolute'
      top={0}
      left={0}
      right={0}
      bottom={0}
      sx={{
        backgroundColor: alpha(palette.info.light, 0.4),
        animation: 'fadeIn 0.3s ease-in-out forwards',
        '@keyframes fadeIn': {
          '0%': {
            backgroundColor: alpha(palette.info.light, 0),
          },
          '100%': {
            backgroundColor: alpha(palette.info.light, 0.4),
          },
        },
      }}
    />
  )
}

export default StackHighlightLayer
