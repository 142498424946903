import { Button, Container, TextField, Typography } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { CreateVesselCommand, UpdateVesselCommand, VesselDto } from '@planning/app/api'
import { vesselService } from '@planning/services'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useForm } from 'react-hook-form'

interface IVesselFormProps {
  vessel?: VesselDto
  vesselId?: number
  editMode?: boolean
}

export const VesselForm = observer(({ vessel, vesselId, editMode }: IVesselFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const { appViewStore, drawerStore } = usePlanningStore()
  const { t } = useTranslate()

  const createVessel = async (vessel: CreateVesselCommand) => {
    try {
      await vesselService.post(vessel)
      appViewStore.setShowAlert(
        'success',
        t('vesselCreatedSuccessfully', 'Vessel created successfully'),
      )
      drawerStore.close()
    } catch (error) {
      appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
    }
  }

  const updateVessel = async (vessel: UpdateVesselCommand) => {
    try {
      await vesselService.put(vessel)
      appViewStore.setShowAlert(
        'success',
        t('vesselUpdatedSuccessfully', 'Vessel updated successfully'),
      )
      drawerStore.close()
    } catch (error) {
      appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
    }
  }

  const onFormSubmit = async (data: any) => {
    if (editMode) {
      const updateCommand = { ...data, id: vesselId } as UpdateVesselCommand
      await updateVessel(updateCommand)
    } else {
      const createCommand = data as CreateVesselCommand
      await createVessel(createCommand)
    }
  }

  const title = editMode ? t('editVessel', 'Edit Vessel') : t('createVessel', 'Create Vessel')

  return (
    <Container sx={{ width: '700px', padding: '1rem' }}>
      <Typography variant='h3'>{title}</Typography>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <TextField
          fullWidth
          label={t('name', 'Name')}
          variant='outlined'
          {...register('name', { required: true })}
          name='name'
          defaultValue={vessel?.name || ''}
          error={!!errors.name}
          helperText={errors.name ? t('fieldIsRequired', 'Field is required.') : ''}
          sx={{ marginTop: '1rem', marginBottom: '1rem' }}
        />
        <TextField
          fullWidth
          label={t('shippingLine', 'Shipping Line')}
          variant='outlined'
          {...register('shippingLine')}
          name='shippingLine'
          defaultValue={vessel?.shippingLine || ''}
          sx={{ marginTop: '1rem', marginBottom: '1rem' }}
        />
        <TextField
          fullWidth
          label={t('imoNumber', 'IMO Number')}
          variant='outlined'
          {...register('imoNumber', { required: true })}
          name='imoNumber'
          defaultValue={vessel?.imoNumber || ''}
          error={!!errors.imoNumber}
          helperText={errors.imoNumber ? t('fieldIsRequired', 'Field is required.') : ''}
          sx={{ marginTop: '1rem', marginBottom: '1rem' }}
        />
        <TextField
          fullWidth
          label={t('mmsi', 'MMSI')}
          variant='outlined'
          {...register('mmsi')}
          name='mmsi'
          defaultValue={vessel?.mmsi || ''}
          sx={{ marginTop: '1rem', marginBottom: '1rem' }}
        />
        <TextField
          fullWidth
          label={t('callSign', 'Call Sign')}
          variant='outlined'
          {...register('callsign')}
          name='callsign'
          defaultValue={vessel?.callSign || ''}
          sx={{ marginTop: '1rem', marginBottom: '1rem' }}
        />
        <TextField
          fullWidth
          label={t('width', 'Width')}
          variant='outlined'
          type='number'
          {...register('width', { required: true, valueAsNumber: true })}
          name='width'
          defaultValue={vessel?.width || ''}
          error={!!errors.width}
          helperText={errors.width ? t('fieldIsRequired', 'Field is required.') : ''}
          sx={{ marginTop: '1rem', marginBottom: '1rem' }}
          onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
          inputProps={{ min: 1 }}
        />
        <TextField
          fullWidth
          label={t('length', 'Length')}
          variant='outlined'
          type='number'
          {...register('length', { required: true, valueAsNumber: true })}
          name='length'
          defaultValue={vessel?.length || ''}
          error={!!errors.length}
          helperText={errors.length ? t('fieldIsRequired', 'Field is required.') : ''}
          sx={{ marginTop: '1rem', marginBottom: '1rem' }}
          onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
          inputProps={{ min: 1 }}
        />
        <Button variant='contained' type='submit' sx={{ marginLeft: '5px' }}>
          {t('submit', 'Submit')}
        </Button>
      </form>
    </Container>
  )
})
