import { Tooltip } from '@mui/material'
import { ArrowLeftIcon } from '../../icons'
import { IconButton } from './IconButton'
import { ButtonSize } from './button.model'

interface IProps {
  onGoBack: () => Promise<void> | void
  tooltip?: string
  size?: ButtonSize
}

export const BackButton = ({ onGoBack, size, tooltip }: IProps) => {
  return (
    <Tooltip title={tooltip ?? 'return'}>
      <IconButton color='default' size={size ?? 'medium'} onClick={onGoBack}>
        <ArrowLeftIcon fontSize={size ?? 'medium'} />
      </IconButton>
    </Tooltip>
  )
}
