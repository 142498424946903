import {
  Autocomplete,
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import {
  CarrierVisitStatus,
  CreateNonNumericOrderCommand,
  VesselDto,
  VesselVisitDto,
} from '@planning/app/api'
import { getContainerTypeParameters } from '@planning/components/ContainerTypesMap'
import { ErrorBox } from '@planning/components/ErrorBox'
import { RailVisitAutoComplete } from '@planning/components/visit/RailVisitAutoComplete'
import { carrierVisitTypes, containerHeights, containerTypes } from '@planning/constants'
import { validateIsoCode } from '@planning/rt-stores/helpers'
import { vesselService, vesselVisitService } from '@planning/services'
import nnrOrderService from '@planning/services/nnrOrderService'
import { formatDateTime } from '@planning/utils'
import { useTranslate } from '@tolgee/react'
import { debounce } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Controller, FieldErrors, useForm } from 'react-hook-form'

export const CreateNNROrderForm = observer(() => {
  const { t } = useTranslate()

  const {
    register,
    handleSubmit,
    control,
    getValues,
    watch,
    formState: { errors },
  } = useForm()

  const watchModeOfTransport = watch('modeOfTransport', 0)
  const { appViewStore, drawerStore } = usePlanningStore()

  const onFormSubmit = async (data: any) => {
    const cmd = {
      shippingLine: data.shippingLine,
      referenceNumber: data.referenceNumber,
      amount: data.amount,
      modeOfTransport: data.modeOfTransport,
      carrierVisitId: data.carrierVisitId,
      containerAttributes: isIsoCodeToggled
        ? undefined
        : {
            length: data.containerLength ? data.containerLength : undefined,
            height: data.containerHeight ? data.containerHeight : undefined,
            type: data.containerType ? data.containerType : undefined,
          },
      containerIsoCode: isIsoCodeToggled ? data.containerIsoCode : undefined,
    } as CreateNonNumericOrderCommand

    try {
      await nnrOrderService.post(cmd)
      appViewStore.setShowAlert('success', 'NNR order created successfully')
      drawerStore.close()
    } catch (error) {
      appViewStore.setShowAlert('error', 'Failed to save')
    }
  }

  const fetch = async (filter?: string) => {
    return (await vesselVisitService.get(1, 10, filter)).payload ?? []
  }

  const onSearchFilterChange = debounce(async (filter?: string) => {
    setOptions(await fetch(filter))
  }, 500)

  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState<VesselVisitDto[]>([])
  const [isIsoCodeToggled, setIsIsoCodeToggled] = useState(false)

  useEffect(() => {
    if (open) {
      fetch().then(payload => setOptions(payload))
    } else {
      setOptions([])
    }
  }, [open])

  // todo: [north] will replace once there is vesselItemStore
  const [vessels, setVessels] = useState<VesselDto[]>([])
  useEffect(() => {
    vesselService.get(1, 1000).then(v => setVessels(v.payload))
  }, [])

  const containerIsoCodeErrorText = (errors: FieldErrors) => {
    const isoCodeError = errors.containerIsoCode
    if (isoCodeError) {
      const errorType = isoCodeError.type
      if (errorType === 'required') return t('fieldIsRequired', 'Field is required.')
      if (errorType === 'validate') return t('isoCodeNotFound', 'ISO Code not found')

      return isoCodeError.message?.toString()
    }
  }

  const validateContainerAttributes = (f: any) =>
    !!f.containerLength || !!f.containerHeight || !!f.containerType

  const requiredAtLeastOneContainerAttributeError =
    !!errors.containerLength && !!errors.containerHeight && !!errors.containerType

  return (
    <form id='create-non-numeric-release' onSubmit={handleSubmit(onFormSubmit)}>
      <TextField
        fullWidth
        label={t('shippingLine', 'Shipping Line')}
        variant='outlined'
        {...register('shippingLine', { required: true })}
        name='shippingLine'
        sx={{ marginTop: '1rem' }}
        error={!!errors.shippingLine}
        helperText={errors.shippingLine ? 'Field is required.' : ''}
      />
      <TextField
        fullWidth
        label={t('referenceNumber', 'Reference Number')}
        variant='outlined'
        {...register('referenceNumber', { required: true })}
        name='referenceNumber'
        error={!!errors.referenceNumber}
        helperText={errors.referenceNumber ? 'Field is required.' : ''}
        sx={{ marginTop: '1rem' }}
      />
      <Typography variant='h4' sx={{ marginTop: '1rem' }}>
        {t('containerRequirements', 'Container requirements')}
      </Typography>

      <Chip
        label={t('containerAttributes', 'Container Attributes')}
        sx={{ m: '1rem 1rem 1rem 0' }}
        variant={isIsoCodeToggled ? 'outlined' : undefined}
        onClick={() => setIsIsoCodeToggled(false)}
      />

      <Chip
        label={t('isoCode', 'ISO Code')}
        sx={{ mt: '1rem', mb: '1rem' }}
        variant={!isIsoCodeToggled ? 'outlined' : undefined}
        onClick={() => setIsIsoCodeToggled(true)}
      />

      {isIsoCodeToggled ? (
        <TextField
          fullWidth
          label={`${t('isoCode', 'ISO Code')}*`}
          variant='outlined'
          {...register('containerIsoCode', {
            required: true,
            validate: v => !!v && validateIsoCode(v),
          })}
          name='containerIsoCode'
          sx={{ marginTop: '.5rem' }}
          error={!!errors.containerIsoCode}
          helperText={containerIsoCodeErrorText(errors)}
          onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
            e.target.value = ('' + e.target.value).toUpperCase()
          }}
        />
      ) : (
        <>
          <TextField
            fullWidth
            label={t('length', 'Length')}
            variant='outlined'
            type='number'
            {...register('containerLength', {
              valueAsNumber: true,
              validate: (_, formData) => validateContainerAttributes(formData),
            })}
            name='containerLength'
            sx={{ marginTop: '.5rem' }}
            onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
            inputProps={{ min: 1 }}
            error={requiredAtLeastOneContainerAttributeError}
          />
          <Controller
            control={control}
            name={`containerHeight`}
            rules={{ validate: (_, formData) => validateContainerAttributes(formData) }}
            render={({ field: { onChange } }) => (
              <FormControl fullWidth sx={{ marginTop: '1rem' }}>
                <InputLabel sx={{ verticalAlign: 'center' }}>{t('height', 'Height')}</InputLabel>
                <Select
                  label={t('height', 'Height')}
                  {...register('containerHeight')}
                  value={getValues(`containerHeight`)?.toString() ?? ''}
                  onChange={(e: any) => {
                    onChange(e)
                  }}
                  error={requiredAtLeastOneContainerAttributeError}
                >
                  <MenuItem value={''}>
                    <em>None</em>
                  </MenuItem>
                  {containerHeights.map(d => {
                    return (
                      <MenuItem key={d.key} value={d.name}>
                        {d.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            )}
          />
          <Controller
            control={control}
            name={`containerType`}
            rules={{ validate: (_, formData) => validateContainerAttributes(formData) }}
            render={({ field: { onChange } }) => (
              <FormControl fullWidth sx={{ marginTop: '1rem' }}>
                <InputLabel sx={{ verticalAlign: 'center' }}>{t('type', 'Type')}</InputLabel>
                <Select
                  label={t('type', 'Type')}
                  {...register('containerType')}
                  value={getValues(`containerType`)?.toString() ?? ''}
                  onChange={(e: any) => {
                    onChange(e)
                  }}
                  error={requiredAtLeastOneContainerAttributeError}
                >
                  <MenuItem value={''}>
                    <em>None</em>
                  </MenuItem>
                  {containerTypes
                    .filter(t => t.name !== 'Unknown')
                    .map(d => {
                      return (
                        <MenuItem key={d.key} value={d.name}>
                          {getContainerTypeParameters(d.name, t)['label']}
                        </MenuItem>
                      )
                    })}
                </Select>
              </FormControl>
            )}
          />
        </>
      )}

      <Typography variant='h4' sx={{ marginTop: '1rem' }}>
        {t('quantity', 'Quantity')}
      </Typography>
      <TextField
        fullWidth
        label={t('amount', 'Amount')}
        variant='outlined'
        type='number'
        {...register('amount', { required: true, valueAsNumber: true })}
        name='amount'
        error={!!errors.amount}
        helperText={errors.amount ? 'Field is required.' : ''}
        sx={{ marginTop: '1rem' }}
        onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
        inputProps={{ min: 1 }}
      />
      <Typography variant='h4' sx={{ marginTop: '1rem' }}>
        {t('modeOfTransport', 'Mode of transport')}
      </Typography>
      <Controller
        control={control}
        name={`modeOfTransport`}
        rules={{ required: true }}
        render={({ field: { onChange } }) => (
          <FormControl fullWidth sx={{ marginTop: '1rem' }}>
            <InputLabel sx={{ verticalAlign: 'center' }}>
              {t('modeOfTransport', 'Mode of transport')}
            </InputLabel>
            <Select
              label='Mode of transport'
              {...(register('modeOfTransport'), { required: true })}
              value={getValues(`modeOfTransport`)?.toString() ?? ''}
              onChange={(e: any) => {
                onChange(e)
              }}
              error={!!errors.modeOfTransport}
            >
              {carrierVisitTypes.map(d => {
                return (
                  <MenuItem key={d.key} value={d.key}>
                    {d.name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        )}
      />
      {watchModeOfTransport === 2 && (
        <Controller
          control={control}
          name={`carrierVisitId`}
          rules={{ required: true }}
          render={({ field: { onChange } }) => (
            <Autocomplete
              disablePortal
              getOptionLabel={(vv: VesselVisitDto) => {
                const vessel = vessels.find(
                  v => vv.carrierIds.includes(v.id) && vv.status !== CarrierVisitStatus.Departed,
                )
                const voyageNumber = vessel?.imoNumber ?? vessel?.mmsi ?? vessel?.callSign
                const eta_ata = vv.eta ?? vv.ata
                return `${vessel?.name} - ${voyageNumber} - ${formatDateTime(eta_ata)}`
              }}
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              options={options}
              isOptionEqualToValue={(option: VesselVisitDto, value: VesselVisitDto) =>
                option.id === value.id
              }
              onChange={(_, selectedVessel: VesselVisitDto | null) => onChange(selectedVessel?.id)}
              renderInput={params => (
                <TextField
                  {...params}
                  label={t('vessel', 'Vessel')}
                  helperText={errors.carrierVisitId ? 'Field is required.' : ''}
                  error={!!errors.carrierVisitId}
                  onChange={async (event: any) => {
                    onSearchFilterChange(event.target.value)
                  }}
                />
              )}
              sx={{ marginTop: '1rem' }}
            />
          )}
        />
      )}
      {watchModeOfTransport === 3 && (
        <Box sx={{ marginTop: '1rem' }}>
          <Controller
            control={control}
            name={`carrierVisitId`}
            rules={{ required: true }}
            render={({ field: { onChange } }) => (
              <RailVisitAutoComplete
                handleOnChange={visit => {
                  onChange(visit?.id)
                }}
                error={!!errors.carrierVisitId}
                helperText={errors.carrierVisitId ? t('fieldIsRequired', 'Field is required.') : ''}
                cargoType='Container'
              />
            )}
          />
        </Box>
      )}
      {requiredAtLeastOneContainerAttributeError && (
        <ErrorBox
          message={t(
            'requiresAtLeast1ContainerAttribute',
            'Requires at least 1 container attribute',
          )}
        />
      )}
    </form>
  )
})
