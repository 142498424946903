import { CreatePackageCommand, PackageDto, PackagesApi } from '@billing/app/api'
import { createApiClient } from '@billing/app/http-client'
import { BaseEntityStore } from './base/BaseEntityStore'

export class PackageStore extends BaseEntityStore<PackageDto> {
  async load() {
    const { data } = await createApiClient(PackagesApi).get()
    this.updateStoreItems(data)
  }

  async add(packageItem: CreatePackageCommand) {
    const { data } = await createApiClient(PackagesApi).create(packageItem)
    this.updateStoreItem({ ...packageItem, id: data }, data)
  }

  async delete(id: number) {
    await createApiClient(PackagesApi)._delete(id)
    this.deleteStoreItem(id)
  }
}
