import { ConfirmationDialog } from '@tom-ui/ui'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { WorkQueueDetailsDialog } from '../components/WorkQueueDetailsDialog/WorkQueueDetailsDialog'
import { WorkQueue } from '../models/work-queue.model'
import { CraneSplitDialogUIStore } from '../stores/crane-split-dialog.ui-store'
import { CraneSplitDrawerUIStore } from '../stores/crane-split-drawer.ui-store'

interface Props {
  uiStore: CraneSplitDialogUIStore
  uiDrawerStore: CraneSplitDrawerUIStore
}

export const CraneSplitDialogContainer = observer(({ uiStore, uiDrawerStore }: Props) => {
  const { t } = useTranslate()

  const handleReset = async () => {
    uiStore.reset()
    uiStore.toggleResetDialog()
  }

  const handleRemove = async () => {
    await uiStore.removeWorkQueue()
    uiStore.toggleRemoveDialog()
  }

  const handleEdit = (workQueue: WorkQueue) => {
    handleCloseDetails()
    uiDrawerStore.toggleEditDrawer(workQueue)
  }

  const handleSplit = (workQueue: WorkQueue) => {
    handleCloseDetails()
    uiDrawerStore.toggleSplit(workQueue)
  }

  const handleOpenRemove = (id: number) => {
    handleCloseDetails()
    uiStore.toggleRemoveDialog(id)
  }

  const handleCloseDetails = () => {
    uiStore.toggleDetailsDialog()
  }

  return (
    <>
      <ConfirmationDialog
        open={uiStore.removeDialog}
        title={t('removeWorkQueueConfirmationTitle', 'You are about to remove a queue')}
        message={t(
          'removeWorkQueueConfirmationMessage',
          'All containers of this queue will be merged to the previous one in this bay and will use its data: twin containers, start time and crane.',
        )}
        primaryActionText={t('remove', 'Remove')}
        closeLabel={t('close', 'Close')}
        maxWidth='sm'
        onClose={() => uiStore.toggleRemoveDialog()}
        onConfirm={handleRemove}
      />
      <ConfirmationDialog
        open={uiStore.resetDialog}
        title={t('resetCraneSplitConfirmationTitle', 'You are about to reset the changes')}
        message={t('resetCraneSplitConfirmationMessage', 'All changes not saved will be lost.')}
        primaryActionText={t('reset', 'Reset')}
        closeLabel={t('close', 'Close')}
        onClose={() => uiStore.toggleResetDialog()}
        onConfirm={handleReset}
      />
      <WorkQueueDetailsDialog
        open={uiStore.detailsDialog}
        workQueue={uiStore.workQueue!}
        onClose={handleCloseDetails}
        onEdit={handleEdit}
        onSplit={handleSplit}
        onRemove={handleOpenRemove}
      />
    </>
  )
})
