import { SelectOption } from '@operations/app/models'
import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Typography,
} from '@mui/material'
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
  FormState,
  Path,
  UseControllerProps,
} from 'react-hook-form'

type FieldProps = Pick<CheckboxProps, 'disabled'>

interface Props<T extends FieldValues> extends UseControllerProps<T>, FieldProps {
  formState: FormState<T>
  options?: SelectOption[]
  multiple?: boolean
  label: string
}

export const ControlledCheckbox = <T extends FieldValues>({
  name,
  control,
  label,
  options,
  multiple,
  disabled,
  formState: { errors },
}: Props<T>) => {
  const handleOnChange = (field: ControllerRenderProps<T, Path<T>>, option: SelectOption) => {
    const newValue = [...field.value]

    if (newValue.length > 0) {
      const index = newValue.findIndex(x => x === option.value)

      if (index === -1) {
        newValue.push(option.value)
      } else {
        newValue.splice(index, 1)
      }
    } else {
      newValue.push(option.value)
    }

    field.onChange(newValue)
  }

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field }) =>
          multiple ? (
            <FormControl component='fieldset' variant='standard'>
              <Typography variant='subtitle2'>{label}</Typography>
              {options?.map(option => (
                <FormControlLabel
                  key={option.value}
                  control={
                    <Checkbox
                      {...field}
                      checked={field.value.some((x: any) => x === option.value)}
                      disabled={disabled}
                      onChange={() => {
                        handleOnChange(field, option)
                      }}
                    />
                  }
                  label={option.label}
                />
              ))}
            </FormControl>
          ) : (
            <FormControlLabel
              control={<Checkbox {...field} checked={field.value} disabled={disabled} />}
              label={label}
            />
          )
        }
      />
      {errors[name] && <FormHelperText>{errors[name]?.message as string}</FormHelperText>}
    </>
  )
}
