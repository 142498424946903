import { Autocomplete, List, Popper, TextField, Typography } from '@mui/material'
import { ImdgCodes } from '@planning/constants'
import { FC } from 'react'
import { DangerousGoodOptionListItem } from './DangerousGoodsOptionListItem'

interface Props {
  assignedCodes?: string[]
  onDangerousGoodChange: (assignedCodes: string[]) => void
  disabled?: boolean
}

// React components should not be nested typescript:S6478
export const PopperWithPlacement = (props: any) => {
  return <Popper {...props} placement='bottom-start' />
}

export const DangerousGoodAutoComplete: FC<Props> = ({
  assignedCodes,
  onDangerousGoodChange,
  disabled,
}) => {
  return (
    <Autocomplete
      disabled={disabled ?? false}
      multiple
      disableCloseOnSelect
      fullWidth
      ListboxComponent={List}
      options={ImdgCodes}
      getOptionLabel={code => code}
      PopperComponent={PopperWithPlacement}
      slotProps={{
        popper: {
          sx: { minWidth: '16rem' },
        },
      }}
      renderOption={(props, code) => (
        <DangerousGoodOptionListItem
          code={code}
          assignedCodes={assignedCodes}
          props={props}
          key={`codes-list-option-${code}`}
        />
      )}
      value={assignedCodes}
      renderTags={value => (
        <Typography variant='body2'>
          {value.toSorted((a, b) => a.localeCompare(b)).join(', ')}
        </Typography>
      )}
      onChange={(_, assignedCodes) => {
        onDangerousGoodChange(assignedCodes)
      }}
      renderInput={params => (
        <TextField
          {...params}
          placeholder={assignedCodes?.length ? '' : 'IMO Class'}
          sx={{ marginTop: '1rem' }}
          label='IMO Class'
        />
      )}
    />
  )
}
