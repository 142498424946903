import { CancellableTimeoutAlert } from '@planning/pages/GateClerk/Components/CancellableTimeoutAlert'
import { CheckOrderNotificationStore } from '@planning/rt-stores/tallyman/CheckOrderNotificationStore'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

interface IProps {
  store: CheckOrderNotificationStore
  onCancel: () => void
}

export const CheckSuccessAlert: FC<IProps> = observer(({ store, onCancel }) => {
  const { t } = useTranslate()

  const checkText = () =>
    `${store.order?.containerNumber} ${t('checked', 'Checked')} ${
      store.order?.direction === 'Inbound' ? t('in', 'in') : t('out', 'out')
    }`

  const cancelledText = () =>
    `${t('check', 'Check')} ${
      store.order?.direction === 'Inbound' ? t('in', 'in') : t('out', 'out')
    } ${t('cancelled', 'cancelled')}: ${store.order?.containerNumber}`

  const errorText = () =>
    `${t('check', 'Check')} ${
      store.order?.direction === 'Inbound' ? t('in', 'in') : t('out', 'out')
    } ${t('failed', 'failed')}: ${store.order?.containerNumber}`

  return (
    <CancellableTimeoutAlert
      testId='check-success-alert'
      store={store}
      successText={checkText()}
      cancelledText={cancelledText()}
      errorText={errorText()}
      cancellable={store.cancellable}
      onCancel={onCancel}
    />
  )
})
