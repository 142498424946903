import { calculateGridLines } from '@storage/utils/geometry/geometry.util'
import Konva from 'konva'
import { RefObject, useEffect, useMemo, useRef } from 'react'
import { Layer } from 'react-konva/lib/ReactKonvaCore'
interface StageBackgroundGridProps {
  stageWidth: number
  stageHeight: number
  scale: number
  offsetX: number
  offsetY: number
}

export const StageBackgroundGrid = ({
  stageWidth,
  stageHeight,
  scale,
  offsetX,
  offsetY,
}: StageBackgroundGridProps) => {
  const gridLayerRef: RefObject<Konva.Layer> | null = useRef(null)

  const gridLines = useMemo(() => {
    return calculateGridLines(stageWidth, stageHeight, scale, offsetX, offsetY)
  }, [stageWidth, stageHeight, scale, offsetX, offsetY])

  useEffect(() => {
    const layer = gridLayerRef.current
    if (!layer) return

    layer.removeChildren()
    layer.clear()

    layer.add(
      new Konva.Shape({
        sceneFunc(context) {
          context.strokeStyle = '#D3D3D3'
          context.lineWidth = 0.6 / scale
          gridLines.forEach(({ startX, startY, endX, endY }) => {
            context.beginPath()
            context.moveTo(startX, startY)
            context.lineTo(endX, endY)
            context.stroke()
          })
        },
      }),
    )

    layer.batchDraw()
  }, [gridLines, scale])

  return <Layer ref={gridLayerRef} listening={false}></Layer>
}
