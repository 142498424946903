import { Stack } from '@mui/material'
import { CarrierVisitDescriptionField } from '@planning/components/carrier-visit-planning/CarrierVisitDescriptionField'
import { IRailPlanning } from '@planning/components/carrier-visit-planning/CarrierVisitPlanning.model'
import { formatTime } from '@planning/utils'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'

interface Props {
  visit: IRailPlanning
}
export const RailVisitCardDescription = observer(({ visit }: Props) => {
  const { t } = useTranslate()

  return (
    <Stack>
      <Stack flexDirection='row' gap={1}>
        <CarrierVisitDescriptionField label={t('eta', 'ETA')} value={formatTime(visit?.eta)} />
        <CarrierVisitDescriptionField label={t('etd', 'ETD')} value={formatTime(visit?.etd)} />
      </Stack>
      <Stack flexDirection='row' gap={1}>
        <CarrierVisitDescriptionField
          label={t('off', 'Off')}
          value={`${visit?.dischargeEstimate}`}
        />
        <CarrierVisitDescriptionField label={t('in', 'In')} value={`${visit?.loadEstimate}`} />
      </Stack>
      <Stack flexDirection='row' gap={1}>
        <CarrierVisitDescriptionField
          label={t('railcarsEstimation', 'Railcars estimation')}
          value={`${visit?.railcarEstimate}`}
        />
      </Stack>
    </Stack>
  )
})
