import { Box, Typography } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { ComponentStatus, Size } from '@storage/helpers/enums'
import { ValueBox } from './ValueBox'

interface LabeledValueBoxProps {
  value: number
  state: ComponentStatus
  label: string
}

export const LabeledValueBox = ({ value, state, label }: LabeledValueBoxProps) => {
  const { palette } = useMinimalsTheme()
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        flexBasis: '30%',
      }}
    >
      <ValueBox
        value={value}
        state={state}
        size={Size.Small}
        valueColor='#0C53B7'
        sx={{ width: '30px' }}
      />
      <Typography variant='body1' color={palette.text.secondary}>
        {label}
      </Typography>
    </Box>
  )
}
