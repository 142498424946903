import { useStores } from '@storage/hooks/use-stores.hook'
import { useMemo } from 'react'
import { YardBayViewStore } from '../stores/YardBayViewStore'
import { YardDataStore } from '../stores/YardDataStore'
import { YardViewStore } from '../stores/YardViewStore'
import { StacksAllocationStore } from '../stores/stacks-allocation.store'

export const useYardManagementStores = () => {
  const rootStore = useStores()

  const stacksAllocationStore = useMemo(
    () => new StacksAllocationStore(rootStore.yardBlockStackStore),
    [rootStore],
  )

  const yardDataStore = useMemo(() => new YardDataStore(rootStore), [rootStore])

  const yardBayViewStore = useMemo(() => new YardBayViewStore(yardDataStore), [yardDataStore])

  const yardViewStore = useMemo(
    () => new YardViewStore(yardDataStore, yardBayViewStore),
    [yardDataStore, yardBayViewStore],
  )

  return {
    yardDataStore,
    yardViewStore,
    yardBayViewStore,
    stacksAllocationStore,
  }
}
