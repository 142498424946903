import { Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { ContainerHeight, DoorDirection } from '@planning/app/api'
import { DamagedContainerIcon } from '@planning/components/DamagedContainerIcon'
import { OrderItemChip } from '@planning/pages/Order/components/OrderItemChip'
import { useTranslate } from '@tolgee/react'
import { DangerousIcon, ReeferIcon } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

interface IProps {
  isoCode?: string | null
  type?: string | null
  length?: number | null
  height?: ContainerHeight | null
  isEmpty?: boolean | null
  grossWeight?: number | null
  containerTare?: number | null
  imoClasses?: string[] | null
  isDamaged?: boolean | null
  seals?: string[] | null
  hasSeals?: boolean
  doorDirection?: DoorDirection
  operationalInstructions?: string | null
}

export const InspectRailcarContainerInfo: FC<IProps> = observer(
  ({
    isoCode,
    type,
    isEmpty,
    grossWeight,
    containerTare,
    imoClasses,
    isDamaged,
    seals,
    hasSeals,
    doorDirection,
    operationalInstructions,
  }) => {
    const { t } = useTranslate()

    return (
      <Stack direction={'row'} flexWrap={'wrap'} gap={0.5}>
        {isoCode && <OrderItemChip label={`${t('iso', 'ISO')}: ${isoCode}`} />}
        <OrderItemChip label={`${isEmpty ? t('empty', 'Empty') : t('full', 'Full')}`} />
        {grossWeight && (
          <OrderItemChip
            label={`${t('maxGross', 'Max gross')}: ${grossWeight.toLocaleString(undefined, { maximumFractionDigits: 0 })}kg`}
          />
        )}
        {containerTare && (
          <OrderItemChip
            label={`${t('tare', 'Tare')}: ${containerTare.toLocaleString(undefined, { maximumFractionDigits: 0 })}kg`}
          />
        )}
        {type === 'Reefer' && (
          <OrderItemChip
            label={
              <Typography
                display={'flex'}
                gap={'4px'}
                fontSize={'14px'}
                sx={{ alignItems: 'center' }}
                component={'div'}
                variant='body2'
              >
                <ReeferIcon sx={{ height: '16px', width: '16px' }} />
              </Typography>
            }
          />
        )}
        {imoClasses?.map(imoClass => (
          <OrderItemChip
            key={imoClass}
            label={
              <Typography
                display={'flex'}
                gap={'4px'}
                fontSize={'14px'}
                sx={{ alignItems: 'center' }}
                component={'div'}
                variant='body2'
              >
                <DangerousIcon sx={{ height: '16px', width: '16px', marginRight: '4px' }} />
                {imoClass}
              </Typography>
            }
          />
        ))}

        {isDamaged && (
          <DamagedContainerIcon
            iconHeight={'16px'}
            iconWeight={'16px'}
            sx={{
              borderRadius: '2px',
              background: '#919eab29',
            }}
          />
        )}
        {hasSeals && (!seals || seals.length === 0) && (
          <OrderItemChip label={t(`hasSeals`, 'Has Seals')} />
        )}
        {hasSeals && seals && seals.length > 0 && (
          <OrderItemChip label={`${t('seals', 'Seals')}: ${seals.join(', ')}`} />
        )}
        {doorDirection && (
          <OrderItemChip label={`${t('doorDirection', 'Door direction')}: ${doorDirection}`} />
        )}
        {operationalInstructions && (
          <OrderItemChip label={`${t('operationalInstructions', 'Operational Instructions')}`} />
        )}
      </Stack>
    )
  },
)
