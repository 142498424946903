import { tolgee } from '@storage/app/translation'
import { DialogUtilStore } from '@storage/stores/dialog.util-store'
import { SnackbarStore } from '@storage/stores/snackbar.store'
import { makeAutoObservable } from 'mobx'
import { DGStackingRuleFormProfile } from '../forms/dg-stacking-rules-table-form/dg-stacking-rules-table-form.profile'
import { DGStackingRuleStore } from './dg-stacking-rule.store'

export class DGStackingRulesTableUIStore {
  constructor(
    private readonly _dgStackingRuleStore: DGStackingRuleStore,
    private readonly _snackbarStore: SnackbarStore,
    public readonly dialogUtilStore: DialogUtilStore,
  ) {
    makeAutoObservable(this)
    this.dialogUtilStore.setDialogConfigs({
      Edit: { title: tolgee.t('editDGStackingRule', 'Edit DG Stacking Rule') },
      Delete: {
        title: tolgee.t('deleteDGStackingRule', 'Clear DG Stacking Rule?'),
        primaryButtonLabel: tolgee.t('clear', 'Clear'),
      },
    })
  }

  get dgStackingRules() {
    return this._dgStackingRuleStore.entries
  }

  loadAllDGStackingRules = async () => await this._dgStackingRuleStore.loadAll()

  get dialogEntity() {
    if (!this.dialogUtilStore.dialogEntityId) {
      return undefined
    }

    return this._dgStackingRuleStore.getById(this.dialogUtilStore.dialogEntityId)
  }

  update = async (
    { isParentClass, id, isStackingContainerOnTopAllowed, maxStack }: DGStackingRuleFormProfile,
    onFinally: () => void,
  ) => {
    try {
      await this._dgStackingRuleStore.update(
        isParentClass!,
        id!,
        isStackingContainerOnTopAllowed,
        maxStack,
      )
      this._snackbarStore.showMessage(
        tolgee.t('dgStackingRuleUpdateSuccess', 'The DG stacking rule is successfully update'),
        'success',
      )
      await this._dgStackingRuleStore.loadAll()
    } catch (e) {
      this._snackbarStore.showMessage(
        tolgee.t(
          'dgStackingRuleUpdateFailure',
          'An unexpected error occurred while updating the DG stacking rule',
        ),
        'error',
      )
    } finally {
      onFinally()
    }
  }

  clear = async (onFinally: () => void) => {
    if (!this.dialogEntity) {
      return
    }

    try {
      const { id, imoClass } = this.dialogEntity
      await this._dgStackingRuleStore.update(!imoClass.includes('.'), id!, true)

      this._snackbarStore.showMessage(
        tolgee.t('dgStackingRuleClearSuccess', 'The DG stacking rule is successfully cleared'),
        'success',
      )
      await this._dgStackingRuleStore.loadAll()
    } catch (e) {
      this._snackbarStore.showMessage(
        tolgee.t(
          'dgStackingRuleClearFailure',
          'An unexpected error occurred while clearing the DG stacking rule',
        ),
        'error',
      )
    } finally {
      onFinally()
    }
  }
}
