import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt' // TODO: use from minimal theme
import { Box, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import {
  CarrierType,
  CarrierVisitStatus,
  OrderResponseDto,
  OrderStatus,
  RailVisitResponseDto,
  VesselDto,
  VesselVisitDto,
} from '@planning/app/api'
import IconFullOrEmptyContainer from '@planning/components/icon/IconFullOrEmptyContainer'
import IconVessel from '@planning/components/icon/IconVessel'
import { ContainerLocation } from '@planning/pages/Container/components/ContainerLocation'
import { formatDate } from '@planning/utils'
import { useTranslate } from '@tolgee/react'
import { TrainIcon, TruckIcon } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

type Props = {
  dropOffOrder: OrderResponseDto
  theOtherDirectionStatus?: OrderStatus
  theOtherDirectionVisitType?: CarrierType
  theOtherDirectionVisit?: VesselVisitDto | RailVisitResponseDto
  theOtherDirectionCarriers?: VesselDto[]
  alertMessage?: JSX.Element[]
}

export const DropOffOrderCard: FC<Props> = observer(
  ({
    dropOffOrder,
    theOtherDirectionStatus,
    theOtherDirectionVisitType,
    theOtherDirectionVisit,
    theOtherDirectionCarriers,
    alertMessage,
  }) => {
    const { t } = useTranslate()

    const renderCarrierIcon = () => {
      switch (theOtherDirectionVisitType) {
        case CarrierType.Vessel:
          return <IconVessel color='secondary' />

        case CarrierType.Train:
          return <TrainIcon color='secondary' sx={{ width: 24, height: 24 }} />

        case CarrierType.Truck:
          return <TruckIcon color='secondary' />
      }

      return <></>
    }

    const renderCarrierInformation = () => {
      const mainCarrier = theOtherDirectionCarriers?.length
        ? theOtherDirectionCarriers[0]
        : undefined

      if (theOtherDirectionVisitType === CarrierType.Vessel && mainCarrier) {
        return (
          mainCarrier && (
            <Typography variant='caption' color='secondary'>
              {mainCarrier.name}
            </Typography>
          )
        )
      }

      return theOtherDirectionVisit?.identifier ? (
        <Typography variant='caption' color='secondary'>
          {theOtherDirectionVisit?.identifier}
        </Typography>
      ) : (
        <></>
      )
    }

    const renderOpenDropOffOrder = () => (
      <Stack direction='row' spacing='8px' alignItems='center'>
        <Stack direction='row' spacing='4px'>
          <IconFullOrEmptyContainer isEmpty={dropOffOrder.isEmpty} color='secondary' />
          <ArrowRightAltIcon color='secondary' />
          {renderCarrierIcon()}
        </Stack>
        {theOtherDirectionVisit ? (
          <>
            {renderCarrierInformation()}

            <Typography variant='caption' color='secondary'>
              {formatDate(theOtherDirectionVisit.ata ?? theOtherDirectionVisit.eta)}
            </Typography>
          </>
        ) : (
          <ContainerLocation color='secondary' />
        )}
      </Stack>
    )

    const renderFulfilledDropOffOrder = () => (
      <Stack direction='row' spacing='8px' alignItems='center'>
        <IconFullOrEmptyContainer isEmpty={dropOffOrder.isEmpty} color='secondary' />

        {theOtherDirectionVisit?.status === CarrierVisitStatus.InOperation ||
        theOtherDirectionStatus === OrderStatus.Fulfilled ? (
          <>
            {renderCarrierIcon()}
            {renderCarrierInformation()}
          </>
        ) : (
          <ContainerLocation color='secondary' />
        )}
      </Stack>
    )

    return (
      <Stack>
        <Typography
          variant='subtitle2'
          color={dropOffOrder.status === OrderStatus.Fulfilled ? 'secondary' : ''}
        >
          {dropOffOrder.containerNumber?.toUpperCase()}
        </Typography>
        <Stack>
          {dropOffOrder.status === OrderStatus.Open
            ? renderOpenDropOffOrder()
            : renderFulfilledDropOffOrder()}
          {dropOffOrder.referenceNumber && (
            <Typography variant='caption' color='secondary'>
              {t('ref', 'Ref')}: {dropOffOrder.referenceNumber}
            </Typography>
          )}
          {dropOffOrder.yardLocation && (
            <Typography variant='caption' color='secondary'>
              {t('location', 'Location')}: {dropOffOrder.yardLocation}
            </Typography>
          )}
        </Stack>
        <Stack spacing={1}>
          {alertMessage?.map((alert, index) => <Box key={`alert-${index}`}>{alert}</Box>)}
        </Stack>
      </Stack>
    )
  },
)
