import { usePlanningStore } from '@planning/AppProvider'
import { CarrierVisitDirection, NonNumericOrderDto } from '@planning/app/api'
import { ConfirmationDrawerMobile } from '@planning/components/ConfirmationDrawerMobile'
import { PaginatedList } from '@planning/components/PaginatedList'
import { GateClerkTruckVisitEditStore } from '@planning/rt-stores/gateClerk/GateClerkTruckVisitEditStore'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { NumberSelect } from '../../../components/NumberSelect'
import { NNROrderListCard } from './NNROrderListCard'

interface Props {
  editStore: GateClerkTruckVisitEditStore
  onBack: () => void
}

export const TruckAppointmentNNROrderListMobile = observer(({ editStore, onBack }: Props) => {
  const { t } = useTranslate()
  const { gateClerkNnrOrderViewStore } = usePlanningStore()

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)
  // TODO Move to store
  const [pickUpAmount, setPickUpAmount] = useState<number>(1)
  const [nnrOrder, setNNROrder] = useState<NonNumericOrderDto>()

  const amountLeft = () => (nnrOrder ? nnrOrder?.amount - nnrOrder?.orderCount : undefined)

  const handleConfirm = async () => {
    if (!nnrOrder) return

    editStore.addNnrOrder(nnrOrder, pickUpAmount)
    editStore.setDirection(CarrierVisitDirection.Outbound)
    editStore.setIsSelectingOrder(false)
    setDrawerOpen(false)
    gateClerkNnrOrderViewStore.setFilter()
    onBack()
  }

  return (
    <>
      <PaginatedList
        filterable
        paginatedStore={gateClerkNnrOrderViewStore}
        hidePaginationControl
        startEmpty
        searchForResultsText={t('searchToViewNNROrders', 'Search to view non-numeric orders')}
        searchPlaceholderText={t('shippingLineOrderReference', 'Shipping line, Order reference')}
        isNoResults={() => !gateClerkNnrOrderViewStore.pageItems.length}
      >
        {gateClerkNnrOrderViewStore.pageItems.map(order => {
          return (
            <NNROrderListCard
              order={order}
              key={`order-${order.referenceNumber}-${order.shippingLine}`}
              onClick={async () => {
                setNNROrder(order)
                setDrawerOpen(true)
              }}
            />
          )
        })}
      </PaginatedList>
      <ConfirmationDrawerMobile
        open={drawerOpen}
        text={`${t('selectPickUpAmount', 'Select the pick up amount')} (${amountLeft()} ${t(
          'containersLeft',
          'containers left',
        )} )`}
        onClose={() => {
          setDrawerOpen(false)
        }}
        onConfirm={handleConfirm}
      >
        <NumberSelect
          value={pickUpAmount}
          minValue={1}
          maxValue={amountLeft()}
          onValueChange={value => setPickUpAmount(value)}
        />
      </ConfirmationDrawerMobile>
    </>
  )
})
