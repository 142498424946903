import { NonNumericOrderDto, OrderResponseDto } from '@planning/app/api'
import { action, makeObservable, observable } from 'mobx'

export type OrderData = Pick<OrderResponseDto, 'id' | 'direction' | 'containerNumber'>
export type NNROrderData = Pick<NonNumericOrderDto, 'id' | 'referenceNumber'>
export type DeleteFrom = 'PickUp' | 'DropOff' | null
export class GateOperationViewStore {
  selectedOrder?: OrderData
  selectedNNROrder?: NNROrderData
  isRemoveDialogOpen = false
  isCancelGateDialogOpen = false
  isGateOutConfirmationDialogOpen = false
  isGateOutWithPendenciesConfirmationDialogOpen = false
  deleteFrom?: DeleteFrom = null

  constructor() {
    makeObservable(this, {
      isRemoveDialogOpen: observable,
      isCancelGateDialogOpen: observable,
      isGateOutConfirmationDialogOpen: observable,
      isGateOutWithPendenciesConfirmationDialogOpen: observable,
      resetDialogs: action,
      openRemoveDialog: action,
      openCancelGateDialog: action,
    })
  }

  resetDialogs = () => {
    this.isRemoveDialogOpen = false
    this.isCancelGateDialogOpen = false
    this.selectedOrder = undefined
    this.selectedNNROrder = undefined
    this.deleteFrom = null

    this.closeGateOutConfirmationDialog()
    this.closeGateOutWithPendenciesConfirmationDialog()
  }

  openRemoveDialog = (order: OrderData | NNROrderData, deleteFrom?: DeleteFrom) => {
    this.isRemoveDialogOpen = true
    if ('containerNumber' in order) this.selectedOrder = order
    if ('referenceNumber' in order) this.selectedNNROrder = order
    if (deleteFrom) this.deleteFrom = deleteFrom
  }

  openCancelGateDialog = () => {
    this.isCancelGateDialogOpen = true
  }

  openGateOutConfirmationDialog = () => {
    this.isGateOutConfirmationDialogOpen = true
  }

  closeGateOutConfirmationDialog = () => {
    this.isGateOutConfirmationDialogOpen = false
  }

  openGateOutWithPendenciesConfirmationDialog = () => {
    this.isGateOutWithPendenciesConfirmationDialogOpen = true
  }

  closeGateOutWithPendenciesConfirmationDialog = () => {
    this.isGateOutWithPendenciesConfirmationDialogOpen = false
  }
}
