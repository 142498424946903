import { Box, Modal, Paper, Tooltip } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { ContainerHistoryList } from '@planning/components/ContainerHistoryList'
import { IContainerEventItem } from '@planning/rt-stores/container/ContainerEventItem'
import { IFilterDelegate, ISortDelegate } from '@planning/stores/PaginatedLocalStore'
import { useTranslate } from '@tolgee/react'
import { CloseButton, IconButton, RegistersIcon } from '@tom-ui/ui'
import moment from 'moment'
import React from 'react'

interface Props {
  containerNumber?: string | null
}

export const containerEventsSortDelegate: ISortDelegate<IContainerEventItem> = (_, a, b) => {
  const dateDiff = moment(a.data.time).diff(moment(b.data.time))

  return dateDiff === 0 ? (a.id > b.id ? -1 : 1) : -dateDiff
}

export const containerEventsFilterDelegate: IFilterDelegate<IContainerEventItem> = (
  filter: string,
  item: IContainerEventItem,
) => {
  return item.data.type.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
}

export const ContainerHistoryLinkIconButton = ({ containerNumber }: Props) => {
  const { t } = useTranslate()

  const { containerEventItemStore, containerEventViewStore } = usePlanningStore()

  const [open, setOpen] = React.useState<boolean>(false)

  const handleClick = async () => {
    if (!containerNumber) return

    containerEventItemStore.setContainerNumber(containerNumber)
    await containerEventItemStore.fetch()

    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    (containerNumber && (
      <>
        <Tooltip title={t('history', 'History')}>
          <IconButton onClick={async () => await handleClick()}>
            <RegistersIcon />
          </IconButton>
        </Tooltip>
        <Modal
          open={open}
          onClose={handleClose}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Paper
            sx={{
              p: '1rem',
              height: '90vh',
              minWidth: '70vw',
              maxWidth: '90vw',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box display='flex' justifyContent='flex-end'>
              <CloseButton onClose={handleClose} tooltip={t('close', 'Close')} />
            </Box>
            <ContainerHistoryList
              containerNumber={containerNumber}
              store={containerEventViewStore}
            />
          </Paper>
        </Modal>
      </>
    )) || <></>
  )
}
