import Plus from '@mui/icons-material/AddCircleOutline'
import FitScreen from '@mui/icons-material/FitScreen'
import Minus from '@mui/icons-material/RemoveCircleOutline'
import { IconButton, Paper, Slider, Stack, Tooltip } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { FC } from 'react'
import { ZOOM_MAX, ZOOM_MIN, ZOOM_STEP } from '../constants'

export const ZoomToolbar: FC<{
  scale: number
  setScale: (value: number) => void
  fitToScreen: () => void
}> = ({ scale, setScale, fitToScreen }) => {
  const { t } = useTranslate()

  const handleZoomChange = (_event: Event, value: number | number[]) => {
    doZoom(Number(value))
  }

  const doZoom = (value: number) => {
    setScale(value)
  }

  return (
    <Paper
      sx={{
        position: 'fixed',
        bottom: 10,
        left: '45%',
        width: 300,
        opacity: 0.8,
        backgroundColor: 'white',
        zIndex: 999,
      }}
      elevation={5}
    >
      <Stack direction='row' spacing={3} justifyContent='center' alignItems='center'>
        <Tooltip title={t('fitToScreen', 'Fit to screen')}>
          <IconButton
            aria-label='Fit to screen'
            onClick={() => {
              fitToScreen()
            }}
          >
            <FitScreen />
          </IconButton>
        </Tooltip>
        <Stack direction='row' alignItems='center' width={200}>
          <Tooltip title={t('zoomOut', 'Zoom out')}>
            <IconButton
              onClick={() => {
                if (scale > ZOOM_MIN) {
                  doZoom(scale - ZOOM_STEP)
                }
              }}
            >
              <Minus />
            </IconButton>
          </Tooltip>
          <Slider
            aria-label='Zoom'
            defaultValue={1}
            step={ZOOM_STEP}
            min={ZOOM_MIN}
            max={ZOOM_MAX}
            valueLabelDisplay='auto'
            value={scale}
            valueLabelFormat={value => <div>{Math.floor(value * 100)}%</div>}
            onChange={handleZoomChange}
          />
          <Tooltip title={t('zoomIn', 'Zoom in')}>
            <IconButton
              onClick={() => {
                if (scale < ZOOM_MAX) {
                  doZoom(scale + ZOOM_STEP)
                }
              }}
            >
              <Plus />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
    </Paper>
  )
}
