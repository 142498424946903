import { PierDto } from '@storage/app/api'
import { FormProps } from '@storage/app/models'
import { ControlledTextField } from '@storage/components/form-controls/ControlledTextField'
import useFormWithSchema from '@storage/hooks/use-form-with-schema.hook'
import { Box, FormGroup } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { useEffect } from 'react'
import { PierFormMapper } from './pier-form.mapper'
import { PierFormProfile, defaultValues } from './pier-form.profile'
import { schema } from './pier-form.scheme'

interface Props extends FormProps {
  pier?: PierDto
  onSubmit: (formValues: PierFormProfile) => Promise<void> | void
}

export const PierForm = ({ id, pier, onSubmit }: Props) => {
  const { handleSubmit, formState, control, reset } = useFormWithSchema<PierFormProfile>(
    schema,
    defaultValues,
  )
  const { t } = useTranslate()
  useEffect(() => {
    if (pier) reset(PierFormMapper.mapDtoToFormValues(pier))
  }, [reset, pier])

  return (
    <Box id={id} component='form' noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
      <FormGroup sx={{ gap: '1rem', padding: '1rem' }}>
        <ControlledTextField<PierFormProfile>
          name='name'
          formState={formState}
          control={control}
          label={t('name', 'Name')}
          fullWidth
          autoFocus
        />
      </FormGroup>
    </Box>
  )
}
