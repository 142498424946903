import { OrderResponseDto, OrdersApi } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'
import { Event, IEvent, IQueryHandler, IQueryMessage } from '../messageBus'

export class GetOrderByIdQuery implements IQueryMessage {
  static type = 'GetOrderByIdQuery'
  type = GetOrderByIdQuery.type

  constructor(public id: number) {}

  getKey = () => {
    return JSON.stringify(this)
  }
}

const httpClient = createApiClient(OrdersApi)

export const getOrderByIdQueryHandler: IQueryHandler<
  GetOrderByIdQuery,
  IEvent<OrderResponseDto>
> = async query => {
  const { data } = await httpClient.getById(query.id)

  return new Event(GetOrderByIdQuery.type, data)
}
