import { Box, styled } from '@mui/material'
import { EquipmentDto } from '@operations/app/api'
import { EquipmentCard } from '@operations/features/equipments/components/card/EquipmentCard'
import { ButtonOption } from '@tom-ui/ui'
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DraggingStyle,
  NotDraggingStyle,
} from 'react-beautiful-dnd'

interface Props {
  draggableId: string
  index: number
  equipment: EquipmentDto
  isDragDisabled: boolean
  isAvailable?: boolean
  isInOperation?: boolean
  assignOptions?: ButtonOption[]
  onUnassignItem?: (id: number) => Promise<void> | void
  dataCy?: string
}

export const lockTransform = (
  isDragging: boolean,
  style: DraggingStyle | NotDraggingStyle,
): DraggingStyle | NotDraggingStyle => {
  const transform = isDragging ? style.transform : 'none !important'
  return { ...style, transform }
}

const Clone = styled(Box)`
  ~ div:last-of-type {
    display: none !important;
  }
`

export const DraggableEquipmentCard = ({
  draggableId,
  index,
  equipment,
  isDragDisabled,
  isAvailable,
  isInOperation,
  assignOptions,
  onUnassignItem,
  dataCy,
}: Props) => {
  return (
    <Draggable
      key={draggableId}
      draggableId={draggableId}
      index={index}
      isDragDisabled={isDragDisabled}
    >
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
        return (
          <>
            <Box
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={lockTransform(snapshot.isDragging, provided.draggableProps.style!)}
              sx={{ transform: snapshot.isDragging ? 'initial' : 'none !important' }}
            >
              <EquipmentCard
                dataCy={dataCy}
                equipment={equipment}
                isInOperation={isInOperation}
                showAssign={isAvailable && !isDragDisabled}
                assignOptions={assignOptions}
                showRemove={!isAvailable && !isDragDisabled}
                onUnassignItem={onUnassignItem}
              ></EquipmentCard>
            </Box>
            {isAvailable && snapshot.isDragging && (
              <Clone>
                <EquipmentCard
                  equipment={equipment}
                  isInOperation={isInOperation}
                  onUnassignItem={onUnassignItem}
                ></EquipmentCard>
              </Clone>
            )}
          </>
        )
      }}
    </Draggable>
  )
}
