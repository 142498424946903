import { PaginationQuery } from '@storage/app/api'
import { action, makeObservable, observable } from 'mobx'

const initialState: PaginationQuery = {
  pageNumber: 1,
  pageSize: 10,
}

const DEFAULT_ITEMS_PER_PAGE = [3, 6, 10, 15]

export abstract class PaginationStore {
  itemsPerPage: number[] = []

  pagination = initialState

  constructor(initialStateValues: Partial<PaginationQuery>, itemsPerPage?: number[]) {
    this.pagination = { ...initialState, ...initialStateValues }
    this.itemsPerPage = itemsPerPage ?? DEFAULT_ITEMS_PER_PAGE

    makeObservable(this, {
      pagination: observable,
      paginate: action,
      reset: action,
      changeSize: action,
    })
  }

  paginate(pageNumber: number) {
    this.pagination = { ...this.pagination, pageNumber }
  }

  changeSize(pageSize: number) {
    this.pagination = { ...initialState, pageSize }
  }

  reset() {
    this.pagination = { ...this.pagination, pageNumber: initialState.pageNumber }
  }
}
