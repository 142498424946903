import { ContainerSection, StackDto } from '@storage/app/api'
import { AllocationSpace } from '../stores/stacks-allocation.store'
import { Stack, StackSizeDigit } from './stack'

interface Props {
  maxBlockTier: number
  stacks: StackDto[]
  stacksMatchingFilter?: StackDto[]
  allocatedSpace: AllocationSpace[]
  allocationSize?: StackSizeDigit
  onClickStack?: (stackId: string, bayId: string) => void
  onDblClickStack?: (bayId: string, rowId: string) => void
}

export const YardBlockStacks = ({
  maxBlockTier,
  stacks,
  stacksMatchingFilter,
  allocatedSpace,
  allocationSize,
  onClickStack,
  onDblClickStack,
}: Props) => {
  return (
    <>
      {stacks.map(stack => {
        return (
          stack.section === ContainerSection.Head && (
            <Stack
              key={stack.id}
              maxTier={maxBlockTier}
              stack={stack}
              isAllocated={!!allocatedSpace.find(({ headStackId }) => headStackId === stack.id)}
              isMatchingFilter={stacksMatchingFilter?.map(({ id }) => id).includes(stack.id)}
              allocationSize={allocationSize}
              onClick={onClickStack}
              onDblClick={onDblClickStack}
            />
          )
        )
      })}
    </>
  )
}
