import { CarrierType } from '@storage/app/api'
import { SelectOption } from '@storage/app/models'
import { useMemo } from 'react'

const KEY_PREFIX = 'CARRIER_TYPE'

export const useCarrierTypeOptions = (): SelectOption[] =>
  useMemo(
    () => [
      { label: `${KEY_PREFIX}.VESSEL`, value: CarrierType.Vessel },
      { label: `${KEY_PREFIX}.TRAIN`, value: CarrierType.Train },
      { label: `${KEY_PREFIX}.TRUCK`, value: CarrierType.Truck },
    ],
    [],
  )
