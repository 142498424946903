import { ContainerHeight, ContainerType } from '@storage/app/api'
import { AllSelectOption, BooleanSelectOption } from '@storage/app/models'

export interface FacetsFormProfile {
  size: string
  containerOperator: string
  isEmpty: BooleanSelectOption | AllSelectOption
  isReefer: BooleanSelectOption
  isDangerous: BooleanSelectOption
  containerHeight: ContainerHeight | AllSelectOption
  containerType: ContainerType | AllSelectOption
  weightClasses: string[]
}

export interface AllocationRuleTemplateFormProfile {
  id?: string
  name: string
  facets: FacetsFormProfile
  destination?: string
}

export const defaultValues: AllocationRuleTemplateFormProfile = {
  name: '',
  destination: '',
  facets: {
    size: '20',
    containerOperator: '',
    isEmpty: 'All',
    isReefer: BooleanSelectOption.False,
    isDangerous: BooleanSelectOption.False,
    containerHeight: 'All',
    containerType: 'All',
    weightClasses: [],
  },
}
