type RGB = {
  r: number
  g: number
  b: number
}

function byteToHex(b: number) {
  const hex = b.toString(16)
  return hex.length == 1 ? '0' + hex : hex
}

function rgbToHex(rgb: RGB) {
  return '#' + byteToHex(rgb.r) + byteToHex(rgb.g) + byteToHex(rgb.b)
}

function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}

export const pickGradient = (sourceColor: string, destColor: string, ratio: number): string => {
  const sourceRgb = hexToRgb(sourceColor) ?? { r: 255, g: 255, b: 255 }
  const destRgb = hexToRgb(destColor) ?? { r: 0, g: 0, b: 0 }

  const rgb = {
    r: Math.round(sourceRgb.r * (1 - ratio)) + Math.round(destRgb.r * ratio),
    g: Math.round(sourceRgb.g * (1 - ratio)) + Math.round(destRgb.g * ratio),
    b: Math.round(sourceRgb.b * (1 - ratio)) + Math.round(destRgb.b * ratio),
  }

  return rgbToHex(rgb)
}
