import { Stack, Typography } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { RailVisitResponseDto } from '@planning/app/api'
import { CustomDateTimePicker } from '@planning/components/CustomDateTimePicker'
import { CustomDateTimePickerButton } from '@planning/components/CustomDateTimePickerButton'
import railVisitService from '@planning/services/railVisitService'
import { useTranslate } from '@tolgee/react'
import dayjs, { Dayjs } from 'dayjs'
import { FC } from 'react'

interface Props {
  railVisit?: RailVisitResponseDto
}

export const EditRailVisitTimesForm: FC<Props> = ({ railVisit: railVisit }) => {
  const { appViewStore, drawerStore } = usePlanningStore()
  const { t } = useTranslate()

  const handleATAChange = async (value: Dayjs | null) => {
    if (railVisit) {
      try {
        const ata = dayjs(value) as any
        await railVisitService.put({ ...railVisit, ata })
        appViewStore.setShowAlert('success', t('savedSuccessfully', 'Saved successfully'))
        drawerStore.close()
      } catch (error) {
        appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
      }
    }
  }

  const handleATDChange = async (value: Dayjs | null) => {
    if (railVisit) {
      try {
        const atd = dayjs(value) as any
        await railVisitService.put({ ...railVisit, atd })
        appViewStore.setShowAlert('success', t('savedSuccessfully', 'Saved successfully'))
        drawerStore.close()
      } catch (error) {
        appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
      }
    }
  }

  return (
    <>
      {railVisit && (
        <Stack spacing={3}>
          {/* <Typography variant='h3'>{t('timings', 'Timings')}</Typography> */}
          <Stack spacing={2}>
            <Typography variant='subtitle1'>{t('estimatedTimes', 'Estimated times')}</Typography>
            <CustomDateTimePicker
              label={t('eta', 'ETA')}
              value={railVisit.eta}
              popperPlacement='left-start'
              onChange={async (value: Dayjs | null) => {
                const eta = dayjs(value) as any
                await railVisitService.put({ ...railVisit, eta })
              }}
            />
            <CustomDateTimePicker
              label={t('etd', 'ETD')}
              value={railVisit.etd}
              popperPlacement='left-start'
              onChange={async (value: Dayjs | null) => {
                const etd = dayjs(value) as any
                await railVisitService.put({ ...railVisit, etd })
              }}
            />
          </Stack>
          <Stack spacing={2}>
            <Typography variant='subtitle1'>{t('actualTimes', 'Actual times')}</Typography>
            {(railVisit.ata && (
              <CustomDateTimePicker
                label={t('ata', 'ATA')}
                value={railVisit.ata}
                popperPlacement='left-start'
                onChange={handleATAChange}
              />
            )) || (
              <CustomDateTimePickerButton
                popperPlacement='left-start'
                label={t('ata', 'ATA')}
                onChange={handleATAChange}
              />
            )}
            {(railVisit.atd && (
              <CustomDateTimePicker
                popperPlacement='left-start'
                label={t('atd', 'ATD')}
                value={railVisit.atd}
                onChange={handleATDChange}
              />
            )) || (
              <CustomDateTimePickerButton
                popperPlacement='left-start'
                label={t('atd', 'ATD')}
                onChange={handleATDChange}
              />
            )}
          </Stack>
        </Stack>
      )}
    </>
  )
}
