import { useBillingServiceStore } from '@billing/AppProvider'
import ReportType from '@billing/app/models/report-type.enum'
import { AlwaysHoveredOutlinedButton } from '@billing/components/button/AlwaysHoveredOutlinedButton'
import { Receipt } from '@mui/icons-material'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'
import { Alert, Grid, Stack, Typography, styled } from '@mui/material'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { useTranslate } from '@tolgee/react'
import { GeneralIcon, VesselIcon, YardIcon, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { ReportNavigationButtonContainer } from './ReportNavigationButtonContainer'

const ReportTypeSelectButtonContent = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(3),
  width: theme.spacing(20),
  height: theme.spacing(12),
}))

export const ReportTypesContainer = observer(() => {
  const { reportsUIStore } = useBillingServiceStore()
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const isPreBillingStorageEnabled = useBooleanFlagValue('prebilling-storage', false)
  const isPrebillingServicesEnabled = useBooleanFlagValue('prebilling-services', false)
  const isHandlingPrebillingEnabled = useBooleanFlagValue('handling-prebilling', false)
  const isBillableItemsReportEnabled = useBooleanFlagValue('billable-items-report', false)

  const reportTypeButton = (reportType: ReportType, reportIcon: JSX.Element) => (
    <AlwaysHoveredOutlinedButton
      color='primary'
      variant='outlined'
      theme={theme}
      selected={reportType === reportsUIStore.currentType}
      error={!reportsUIStore.currentType && reportsUIStore.nextButtonWasBlocked ? 'true' : 'false'}
      onClick={() => reportsUIStore.setType(reportType)}
    >
      <ReportTypeSelectButtonContent direction='column' alignItems='center'>
        {reportIcon}
        <Typography variant='body2' color='black'>
          {t(reportType)}
        </Typography>
      </ReportTypeSelectButtonContent>
    </AlwaysHoveredOutlinedButton>
  )

  return (
    <>
      {!reportsUIStore.currentType && reportsUIStore.nextButtonWasBlocked && (
        <Alert severity='error'>
          {t(
            'pleaseSelectADataTypeFromTheFollowingList',
            'Please select a data type from the following list',
          )}
        </Alert>
      )}
      <Grid container spacing={2}>
        <Grid item>{reportTypeButton(ReportType.VesselList, <VesselIcon color='action' />)}</Grid>
        <Grid item>{reportTypeButton(ReportType.Storage, <YardIcon color='action' />)}</Grid>
        <Grid item>{reportTypeButton(ReportType.Containers, <GeneralIcon color='action' />)}</Grid>
        <Grid item>{reportTypeButton(ReportType.CustomerBilling, <Receipt color='action' />)}</Grid>
        {isPreBillingStorageEnabled && (
          <Grid item>
            {reportTypeButton(ReportType.GCStoragePreBilling, <Receipt color='action' />)}
          </Grid>
        )}
        {isPrebillingServicesEnabled && (
          <Grid item>
            {reportTypeButton(ReportType.GCServicesPreBilling, <Receipt color='action' />)}
          </Grid>
        )}
        {isHandlingPrebillingEnabled && (
          <Grid item>
            {reportTypeButton(ReportType.HandlingServicesReport, <Receipt color='action' />)}
          </Grid>
        )}
        {
          <Grid item>
            {reportTypeButton(
              ReportType.DeliveryNote,
              <DriveFileRenameOutlineIcon color='action' />,
            )}
          </Grid>
        }
        {isBillableItemsReportEnabled && (
          <Grid item>
            {reportTypeButton(ReportType.BillableItemsReport, <Receipt color='action' />)}
          </Grid>
        )}
      </Grid>
      <ReportNavigationButtonContainer disableNext={!reportsUIStore.currentType} />
    </>
  )
})
