import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import { LangSelector } from '@host/components/LangSelector'
import { Container, IconButton, Stack, Tooltip } from '@mui/material'
import { Header, MenuIcon, Tile } from '@tom-ui/ui'
import { appStore } from '@host/stores/AppStore'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Helmet } from 'react-helmet-async'

const Profile: FC = withAuthenticationRequired(
  observer(() => {
    const { user, isAuthenticated } = useAuth0()
    const { t } = useTranslate()

    return (
      <>
        <Helmet>
          <title>Profile</title>
        </Helmet>
        {isAuthenticated && (
          <>
            <Header
              leftRenderOption={
                appStore.isMobile && (
                  <Tooltip title={t('openMenu', 'Open menu')}>
                    <IconButton onClick={() => appStore.toggleMenu(true)}>
                      <MenuIcon />
                    </IconButton>
                  </Tooltip>
                )
              }
              title={t('profile', 'Profile')}
            />
            <Container sx={{ paddingY: 2 }}>
              <Stack gap={4}>
                <Stack flexDirection='row' gap={8}>
                  <Tile label={t('name', 'Name')} value={user?.given_name ?? ' - '} />
                  <Tile label={t('email', 'Email')} value={user?.email ?? ' - '} />
                  <Tile
                    label={t('phoneNumber', 'Phone number')}
                    value={user?.phone_number ?? ' - '}
                  />
                </Stack>
                <LangSelector />
              </Stack>
            </Container>
          </>
        )}
      </>
    )
  }),
)

export default Profile
