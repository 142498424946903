import { Checkbox, CheckboxProps, FormControlLabel, FormHelperText } from '@mui/material'
import { Controller, FieldValues, FormState, UseControllerProps } from 'react-hook-form'

type FieldProps = Pick<CheckboxProps, 'disabled'>

interface Props<T extends FieldValues> extends UseControllerProps<T>, FieldProps {
  formState: FormState<T>
  label?: string
}

export const ControlledCheckbox = <T extends FieldValues>({
  name,
  control,
  label,
  disabled,
  formState: { errors },
}: Props<T>) => {
  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <FormControlLabel
            value={field.value ?? false}
            control={
              <Checkbox
                {...field}
                onChange={(_, checked) => field.onChange(checked)}
                checked={field.value}
                disabled={disabled}
              />
            }
            label={label}
          />
        )}
      />
      {errors[name] && <FormHelperText>{errors[name]?.message as string}</FormHelperText>}
    </>
  )
}
