import {
  Autocomplete,
  Box,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { CargoUnitDto, JobDto } from '@operations/app/api'
import { SelectOption } from '@operations/app/models'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import _ from 'lodash'
import { matchSorter } from 'match-sorter'
import { JobInformationJourney } from '../JobInformation/JobInformationJourney'
import { JobInformationWrapper } from '../JobInformation/JobInformationWrapper'
import { ConfirmPicks } from './ConfirmPicks'
import { ConfirmPickUpProps } from './confirm-pickup.interface'

interface Props {
  job: JobDto
  cargoUnits: CargoUnitDto[]
  selectedContainerNumber?: SelectOption | null
  containerOrigin?: string
  hasError?: boolean
  stepsTotal?: number
  currentStep?: number
  showJourney?: boolean
  handleSelectCargoUnit: (cargoUnit?: SelectOption, origin?: string) => void
  handlePicks?: (id: string) => void
  DestinationComponent?: React.ComponentType<ConfirmPickUpProps>
}

export const SelectContainer = ({
  job,
  cargoUnits,
  selectedContainerNumber,
  containerOrigin,
  hasError,
  stepsTotal,
  currentStep,
  showJourney,
  handleSelectCargoUnit,
  handlePicks,
  DestinationComponent,
}: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const options = _(cargoUnits)
    .map(x => ({ label: x.containerNumber, value: x.id }) as SelectOption)
    .value()

  const handleChangeContainer = (event: any, newValue: SelectOption | null) => {
    let origin: string = job.from.locationName

    if (newValue) {
      const cargoUnit = cargoUnits.find(x => x.id === newValue.value)

      if (cargoUnit?.currentLocation.locationName) {
        origin = cargoUnit.currentLocation.locationName
      }
    }

    handleSelectCargoUnit(newValue ?? undefined, origin)
  }

  return (
    <JobInformationWrapper job={job} currentStep={currentStep} stepsTotal={stepsTotal}>
      <Stack gap={theme.customSpacing.m} marginY={theme.customSpacing.m}>
        <Typography variant='subtitle1'>
          {t('containerInformation', 'Container information')}
        </Typography>

        <FormControl fullWidth error={hasError}>
          <Autocomplete
            options={options}
            onChange={handleChangeContainer}
            defaultValue={selectedContainerNumber}
            fullWidth
            getOptionLabel={option => option?.label ?? t('noOptionSelected', 'no option selected')}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            renderInput={params => (
              <TextField
                {...params}
                error={hasError}
                required
                label={t('containerNumber', 'Container no.')}
              />
            )}
            filterOptions={(options, { inputValue }) => {
              return matchSorter(options, inputValue.replace(/\s/g, ''), {
                keys: [item => item.label.replace(/\./g, '').replace(/\s/g, '')],
                threshold: matchSorter.rankings.CONTAINS,
              })
            }}
            data-cy='select-container-for-job'
            renderOption={(props, option) => (
              <Box
                component='li'
                {...props}
                key={option.value}
                data-cy={`select-container-${option.label}-option`}
              >
                {option.label}
              </Box>
            )}
          />
          {hasError && <FormHelperText>{t('containerNumber', 'Container number')}</FormHelperText>}
        </FormControl>

        <ConfirmPicks
          handlePicks={handlePicks}
          DestinationComponent={DestinationComponent}
          containerNumber={job.container?.containerNumber ?? ''}
        />
      </Stack>

      {showJourney && <JobInformationJourney job={job} origin={containerOrigin} />}
    </JobInformationWrapper>
  )
}
