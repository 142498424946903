export enum Permission {
  ReadYardBlocks = 'read:yard-blocks',
  WriteYardBlocks = 'write:yard-blocks',

  ReadPiers = 'read:piers',
  WritePiers = 'write:piers',

  ReadBerths = 'read:berths',
  WriteBerths = 'write:berths',

  ReadRules = 'read:rules',
  WriteRules = 'write:rules',
}
