import { Box, Typography } from '@mui/material'
import { ComponentStatus } from '@storage/helpers/enums'
import { LinearProgressBar } from './LinearProgressBar'
import { TextButton } from './TextButton'

interface LinearProgressBarWithTextAndButtonProps {
  text: string
  buttonLabel?: string
  progressBarValue: number
  state: ComponentStatus
  buttonOnClickAction?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export const LinearProgressBarWithTextAndButton = ({
  text,
  buttonLabel,
  buttonOnClickAction,
  progressBarValue,
  state,
}: LinearProgressBarWithTextAndButtonProps) => {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant='subtitle2' sx={{ mb: buttonLabel ? '' : '0.5rem' }}>
          {text}
        </Typography>
        <TextButton label={buttonLabel} onClick={buttonOnClickAction} />
      </Box>
      <Box>
        <LinearProgressBar value={progressBarValue} state={state} />
      </Box>
    </Box>
  )
}
