import { Button, Card, CardContent, Grid, Stack, Typography } from '@mui/material'
import { useOperationsStore } from '@operations/AppProvider'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'
import { EquipmentsPlanned } from './equipment.model'

interface Props {
  navigateToUrl: string
}

export const EquipmentPlanningSummary = observer(({ navigateToUrl }: Props) => {
  const navigate = useNavigate()
  const { equipmentsVesselVisitUIStore, tenantConfigStore } = useOperationsStore()
  if (tenantConfigStore.skipCraneSplit) return <></>

  const { t } = useTranslate()

  const getContent = (label: string, equipmentsPlanned: EquipmentsPlanned[]) => {
    return (
      <Grid item xs={12} sm={6}>
        <Stack gap={1}>
          <Typography variant='subtitle2'>{label}</Typography>
          <Stack flexDirection='row' gap={2}>
            {equipmentsPlanned.map(eq => (
              <Stack key={eq.type}>
                <Typography variant='body2'>{t(eq.type)}</Typography>
                <Typography variant='subtitle2'>{eq.equipmentCount}</Typography>
              </Stack>
            ))}
            {equipmentsPlanned.length === 0 && (
              <Typography variant='body2'>{t('nothingPlanned', 'Nothing planned')}</Typography>
            )}
          </Stack>
        </Stack>
      </Grid>
    )
  }

  return (
    <Card>
      <CardContent>
        <Stack gap={1}>
          <Stack flexDirection='row' justifyContent='space-between'>
            <Typography variant='h4'>{t('equipment', 'Equipment')}</Typography>
            <Button
              color='primary'
              variant='contained'
              sx={{ flex: 'none' }}
              onClick={() => navigate(navigateToUrl)}
            >
              {t('planEquipment', 'Plan Equipment')}
            </Button>
          </Stack>
          <Grid container>
            {getContent(
              t('discharge', 'Discharge'),
              equipmentsVesselVisitUIStore.dischargeEquipments,
            )}

            {getContent(t('load', 'Load'), equipmentsVesselVisitUIStore.loadEquipments)}
          </Grid>
        </Stack>
      </CardContent>
    </Card>
  )
})
