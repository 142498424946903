import {
  CarrierType,
  OperationType,
  WorkInstructionDto,
  WorkInstructionListJobDto,
  WorkInstructionStatus,
} from '@operations/app/api'
import { CarrierVisitRefreshEventPayload } from '@operations/app/api/signalRDtos/carrierVisitRefreshEventPayload'
import { CarrierVisitWorkInstructionUpsertedPayload } from '@operations/app/api/signalRDtos/carrierVisitWorkInstructionUpsertedPayload'
import { WorkInstructionDeletedEventPayload } from '@operations/app/api/signalRDtos/workInstructionDeletedEventPayload'
import { WorkInstructionUpsertedEventPayload } from '@operations/app/api/signalRDtos/workInstructionUpsertedEventPayload'
import { EventTypes } from '@operations/messages/eventsTypes'
import { IEvent, IMessageBus } from '@operations/messages/messageBus'
import { WorkInstructionStore } from '@operations/stores/WorkInstructionStore'
import { AppStore } from '@tom-ui/utils'
import _ from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import { WorkInstructionFiltersUIStore } from './work-instruction-filters.ui-store'

export enum WorkInstructionTypes {
  vessels = 'vessels',
  trains = 'trains',
  externalTrucks = 'externalTrucks',
  internalMoves = 'internalMoves',
  services = 'services',
}

export class WorkInstructionUIStore {
  loadingSignalR = false

  visitId: number | undefined
  workInstructionType: WorkInstructionTypes | undefined

  constructor(
    private messageBus: IMessageBus,
    private workInstructionStore: WorkInstructionStore,
    private workInstructionFilterUIStore: WorkInstructionFiltersUIStore,
    private appStore: AppStore,
  ) {
    makeObservable(this, {
      visitId: observable,
      workInstructionType: observable,
      setVesselId: action,
      setWorkInstructionType: action,
      items: computed,
      completedAmount: computed,
      inProgressAmount: computed,
      pendingAmount: computed,
      workInstructions: computed,
    })

    this.messageBus.subscribeEvent(
      EventTypes.CarrierVisitRefresh,
      async (res: IEvent<CarrierVisitRefreshEventPayload>) => {
        const blockRefresh =
          !!res.payload?.carrierVisits?.length &&
          !res.payload?.carrierVisits.find(
            c => c.carrierType === CarrierType.Vessel && c.carrierVisitId === this.visitId,
          )
        await this.reloadWorkInstructionBySignalR(blockRefresh)
      },
    )

    this.messageBus.subscribeEvent(
      EventTypes.CarrierVisitWorkInstructionUpserted,
      async (res: IEvent<CarrierVisitWorkInstructionUpsertedPayload>) => {
        await this.reloadWorkInstructionBySignalR(res.payload?.carrierVisitId !== this.visitId)
      },
    )

    this.messageBus.subscribeEvent(EventTypes.WorkInstructionDeleted, this.deleteWorkInstructions)

    this.messageBus.subscribeEvent(EventTypes.WorkInstructionFinished, this.finishWorkInstruction)

    this.messageBus.subscribeEvent<WorkInstructionUpsertedEventPayload>(
      EventTypes.WorkInstructionUpserted,
      this.upsertWorkInstruction,
    )
  }

  public setVesselId(visitId?: number): void {
    if (this.visitId !== visitId) this.visitId = visitId
  }

  public setWorkInstructionType(type?: WorkInstructionTypes): void {
    if (this.workInstructionType !== type) this.workInstructionType = type
  }

  reloadWorkInstructionBySignalR = async (blockRefresh: boolean) => {
    if (!this.visitId) {
      //on all work instructions page, refresh always
      if (!this.loadingSignalR) {
        this.loadingSignalR = true
        await this.appStore.triggerReloadBySignalR(
          this.reloadWorkInstruction,
          '/jobs/workInstructions',
        )
        this.loadingSignalR = false
      }
    } else {
      if (blockRefresh) return

      if (!this.loadingSignalR) {
        this.loadingSignalR = true
        await this.appStore.triggerReloadBySignalR(
          this.reloadWorkInstruction,
          '/EquipmentPlanning',
          '/jobs',
        )
        this.loadingSignalR = false
      }
    }
  }

  deleteWorkInstructions = (res: IEvent<WorkInstructionDeletedEventPayload>) => {
    if (!this.visitId || (this.visitId && res.payload?.carrierVisitIds.includes(this.visitId))) {
      this.workInstructionStore.deleteStoreItems(res.payload?.ids)
    }
  }

  finishWorkInstruction = (res: IEvent<number>) => {
    if (!res.payload) return

    const workInstruction = this.workInstructionStore.items.find(wi => wi.id === res.payload)

    if (!workInstruction) return

    this.workInstructionStore.updateStoreItem(
      { ...workInstruction, status: WorkInstructionStatus.Finished },
      res.payload,
    )
  }

  upsertWorkInstruction = (res: IEvent<WorkInstructionUpsertedEventPayload>) => {
    if (!res.payload) {
      return
    }

    const existingItem = this.workInstructionStore.items.find(
      wi => wi.id === res.payload!.workInstruction.id,
    )

    let workInstructionToUpdate

    if (existingItem) {
      workInstructionToUpdate = {
        ...existingItem,
        cargoUnit: res.payload.workInstruction.cargoUnit,
        status: res.payload.workInstruction.status ?? existingItem.status,
        jobs: existingItem.jobs ? [...existingItem.jobs] : [],
      }
    } else {
      workInstructionToUpdate = res.payload.workInstruction
    }

    if (
      res.payload?.newJob &&
      !workInstructionToUpdate.jobs?.some(x => x.id === res.payload?.newJob?.id)
    ) {
      if (!workInstructionToUpdate?.jobs) workInstructionToUpdate.jobs = []

      workInstructionToUpdate.jobs.push(res.payload.newJob)
    }

    this.workInstructionStore.updateStoreItem(
      workInstructionToUpdate,
      res.payload.workInstruction.id,
    )
  }

  public reloadWorkInstruction = async (): Promise<void> => {
    await this.workInstructionStore.load(this.visitId)
  }

  public get items() {
    return _(
      this.workInstructionStore.items.filter(
        wi =>
          (!this.visitId &&
            ((this.workInstructionType === WorkInstructionTypes.vessels &&
              wi.carrierVisitType === CarrierType.Vessel) ||
              (this.workInstructionType === WorkInstructionTypes.trains &&
                wi.carrierVisitType === CarrierType.Train) ||
              (this.workInstructionType === WorkInstructionTypes.externalTrucks &&
                wi.carrierVisitType === CarrierType.Truck) ||
              (this.workInstructionType === WorkInstructionTypes.internalMoves &&
                wi.operationType === OperationType.Internal) ||
              (this.workInstructionType === WorkInstructionTypes.services &&
                wi.operationType === OperationType.Service))) ||
          (this.visitId && wi.carrierVisitId === this.visitId),
      ),
    )
      .sortBy(wi => wi.operationType)
      .value()
  }

  public get pendingAmount() {
    return this.workInstructions.filter(wi => wi.status === WorkInstructionStatus.Todo).length
  }

  public get inProgressAmount() {
    return this.workInstructions.filter(wi => wi.status === WorkInstructionStatus.InProgress).length
  }

  public get completedAmount() {
    return this.workInstructions.filter(wi => wi.status === WorkInstructionStatus.Finished).length
  }

  public get workInstructions() {
    if (this.workInstructionFilterUIStore.isFiltersEmpty) {
      return this.items
    }

    if (this.workInstructionFilterUIStore.containerNumber) {
      return this.items.filter(wi =>
        this.doesWorkInstructionHaveContainer(
          wi,
          this.workInstructionFilterUIStore.containerNumber,
        ),
      )
    }

    const filters = this.workInstructionFilterUIStore.filter!
    return this.items.filter(
      wi =>
        this.doesWorkInstructionHaveOperationType(wi, filters.process) &&
        this.doesWorkInstructionHaveStatus(wi, filters.status) &&
        this.doesWorkInstructionJobsHaveOriginOrDestinationOrEquipment(
          wi.jobs,
          filters.origin,
          filters.destination,
          filters.equipment?.value,
        ),
    )
  }

  private doesWorkInstructionHaveContainer(
    workInstruction: WorkInstructionDto,
    container?: string | null,
  ) {
    return (
      !container ||
      workInstruction.cargoUnit?.containerNumber?.toLowerCase().includes(container.toLowerCase())
    )
  }

  private doesWorkInstructionHaveOperationType(
    workInstruction: WorkInstructionDto,
    operationTypes?: string[] | null,
  ) {
    return !operationTypes?.length || operationTypes.includes(workInstruction.operationType)
  }

  private doesWorkInstructionHaveStatus(
    workInstruction: WorkInstructionDto,
    status?: string[] | null,
  ) {
    return !status?.length || status.includes(workInstruction.status)
  }

  private doesWorkInstructionJobsHaveOriginOrDestinationOrEquipment(
    jobs?: WorkInstructionListJobDto[] | null,
    origin?: string | null,
    destination?: string | null,
    equipmentId?: number | string,
  ) {
    if (!destination && !origin && !equipmentId) return true

    return jobs?.some(
      job =>
        (!destination || job.to.locationName.toLowerCase().includes(destination.toLowerCase())) &&
        (!origin || job.from.locationName.toLowerCase().includes(origin.toLowerCase())) &&
        (!equipmentId || equipmentId === job.equipmentId),
    )
  }
}
