import { TruckDto } from '@planning/app/api'
import { EntityItem } from '../base/EntityItem'
import { IEntity } from '../types'

export type ITruckItem = IEntity<TruckDto>

export class TruckItem extends EntityItem<TruckDto> implements ITruckItem {
  constructor(id: number, data: TruckDto) {
    super(id, data)
  }
}
