import {
  CreateDangerousGoodsDefinitionCommand,
  DangerousGoodsDefinitionDto,
  DangerousGoodsDefinitionDtoPaginatedList,
  DangerousGoodsDefinitionsApi,
  UpdateDangerousGoodsDefinitionCommand,
} from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'

export class DangerousGoodsDefinitionService {
  private api = createApiClient(DangerousGoodsDefinitionsApi)

  async getAll(
    pageNumber: number,
    pageSize: number,
    unNumber?: string,
  ): Promise<DangerousGoodsDefinitionDtoPaginatedList> {
    const { data: paginatedDGDs } = await this.api.getAll(pageNumber, pageSize, unNumber)

    return paginatedDGDs
  }

  async create(
    createDangerousGoodsDefinitionCommand: CreateDangerousGoodsDefinitionCommand,
  ): Promise<DangerousGoodsDefinitionDto> {
    const { data: dangerousGoodsDefinition } = await this.api.create(
      createDangerousGoodsDefinitionCommand,
    )
    return dangerousGoodsDefinition
  }

  async update(
    dangerousGoodsInfoUpdateRequest: UpdateDangerousGoodsDefinitionCommand,
  ): Promise<DangerousGoodsDefinitionDto> {
    const { data: dangerousGoodsDefinition } = await this.api.update(
      dangerousGoodsInfoUpdateRequest,
    )
    return dangerousGoodsDefinition
  }

  async delete(id: number) {
    await this.api._delete(id)
  }
}

const dangerousGoodsDefinitionService = new DangerousGoodsDefinitionService()

export default dangerousGoodsDefinitionService
