import { Stack, Typography } from '@mui/material'
import { ReactNode } from 'react'

interface Props {
  title: string
  children: ReactNode
}

export const LegendItem = ({ title, children }: Props) => {
  return (
    <Stack flexDirection='row' gap='1rem' alignItems='center'>
      {children}
      <Typography variant='body2'>{title}</Typography>
    </Stack>
  )
}
