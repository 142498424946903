import { Checkbox, Grid, Paper, Tooltip, Typography } from '@mui/material'
import { CheckOvalIcon, RemoveIcon, useMinimalsTheme } from '@tom-ui/ui'
import { CarrierVisitDirection, HoldStatus } from '@planning/app/api'
import { HoldWithDirection } from '@planning/stores/hold/HoldViewStore'
import { useTranslate } from '@tolgee/react'
import { FC } from 'react'
import { ContainerDirectionChip } from '../ContainerDirectionChip'

interface Props {
  hold: HoldWithDirection
  showDirection?: boolean
  checked?: boolean
  onCheck?: (requestId: number) => void
}

export const HoldListCard: FC<Props> = ({ hold, showDirection, checked, onCheck }) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const getIcon = () => {
    const isActive = hold.status === HoldStatus.Active
    return (
      <Tooltip title={isActive ? t('active', 'Active') : t('resolved', 'Resolved')}>
        {isActive ? <RemoveIcon /> : <CheckOvalIcon />}
      </Tooltip>
    )
  }

  return (
    <Paper variant='outlined' sx={{ width: '100%', mt: 1, py: theme.customSpacing.xxs }}>
      <Grid container>
        <Grid item xs={1} mr={theme.customSpacing.xs}>
          {(checked !== undefined && onCheck && (
            <Checkbox checked={checked} onClick={() => onCheck(hold.id)} />
          )) || <></>}
        </Grid>
        <Grid item xs display='flex' flexDirection='column' justifyContent='center'>
          <Typography variant='subtitle2'>{hold.reason}</Typography>
          <Typography variant='body2'>{`${t('contact', 'Contact')}: ${hold.contact}`}</Typography>
        </Grid>
        {showDirection && (
          <Grid item xs={2} display='flex' justifyContent='center' alignItems='center'>
            <ContainerDirectionChip
              direction={hold.direction}
              label={
                hold.direction === CarrierVisitDirection.Inbound ? t('in', 'In') : t('out', 'Out')
              }
            />
          </Grid>
        )}
        <Grid item xs={2} display='flex' justifyContent='center' alignItems='center'>
          {getIcon()}
        </Grid>
      </Grid>
    </Paper>
  )
}
