import DeleteIcon from '@mui/icons-material/Delete'
import { Stack, TextField, Tooltip, Typography } from '@mui/material'
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { WeightMeasurementUnit } from '@planning/app/api'
import { useTranslate } from '@tolgee/react'
import { CloseIcon, EditIcon, ErrorIcon, IconButton, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'

export type StuffingCommodityDto = {
  id?: number
  customerId: number
  customerName: string
  commodityId: number
  commodityName: string
  referenceNumber?: string | null
  lotNumber?: string | null
  packageId?: number | null
  packageName?: string | null
  weightUnit?: WeightMeasurementUnit
  plannedAmount: number
  actualAmount?: number | null
  inStockQuantity: number
}

interface IEditCellProps {
  cell: GridRenderCellParams
  onChange: (row: StuffingCommodityDto) => void
  isEditable: boolean
}
const EditCell = ({ cell, onChange, isEditable }: IEditCellProps) => {
  const theme = useMinimalsTheme()
  const isPlannedAmount = cell.field === 'plannedAmount'
  // const notCompleted = !cell.row.actualAmount

  const { t } = useTranslate()
  const [isEditing, setIsEditing] = useState(isPlannedAmount && isEditable)
  const [value, setValue] = useState(cell.value)

  return (
    <Stack
      flexDirection='row'
      alignItems='center'
      gap={theme.customSpacing.xxs}
      justifyContent='space-between'
      width='100%'
    >
      {isEditing ? (
        <TextField
          fullWidth
          variant='outlined'
          type={isPlannedAmount ? 'number' : 'text'}
          value={value}
          onChange={event => {
            let val: any = event.target.value

            if (isPlannedAmount) {
              let plannedAmount = +val
              if (plannedAmount < 0) plannedAmount = 0
              if (plannedAmount > cell.row.inStockQuantity) plannedAmount = cell.row.inStockQuantity
              val = plannedAmount
            }

            setValue(val)

            const newRow = {
              ...cell.row,
            }
            newRow[cell.field] = val
            onChange(newRow)
          }}
          sx={{ margin: 0 }}
          inputProps={{
            style: {
              paddingTop: theme.customSpacing.xxxs,
              paddingBottom: theme.customSpacing.xxxs,
              paddingLeft: theme.customSpacing.xxs,
            },
          }}
        />
      ) : (
        <Typography maxWidth='80%' overflow='hidden' whiteSpace='nowrap' textOverflow='ellipsis'>
          {cell.value ?? ' - '}
        </Typography>
      )}
      <Stack flexDirection='row' alignItems='center' gap={theme.customSpacing.xxs}>
        {isEditable &&
          (isEditing ? (
            <Tooltip title={t('stopEditing', 'Stop editing')}>
              <IconButton onClick={() => setIsEditing(false)} size='small'>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title={t('edit', 'Edit')}>
              <IconButton onClick={() => setIsEditing(true)} size='small'>
                <EditIcon />
              </IconButton>
            </Tooltip>
          ))}

        {isPlannedAmount && isEditable && (
          <>
            {!!cell.row.inStockQuantity && cell.row.plannedAmount > cell.row.inStockQuantity && (
              <Tooltip
                title={t(
                  'notEnoughStockForCommodityLotNumberPackaging',
                  'Not enough stock for {commodity}',
                  {
                    commodity: cell.row.commodityName,
                  },
                )}
                color='warning'
              >
                <ErrorIcon />
              </Tooltip>
            )}

            {cell.row.plannedAmount === 0 && (
              <Tooltip
                title={t('pleaseProvideAValidQuantity', 'Please provide a valid quantity')}
                color='warning'
              >
                <ErrorIcon />
              </Tooltip>
            )}
          </>
        )}
      </Stack>
    </Stack>
  )
}

interface IProps {
  isInProgress: boolean
  commodities: StuffingCommodityDto[]
  onClickDelete: (data: StuffingCommodityDto) => void
  onCommodityUpdate: (data: StuffingCommodityDto) => void
}

export const SelectedCommoditiesTable = observer(
  ({ isInProgress, commodities, onClickDelete, onCommodityUpdate }: IProps) => {
    const { t } = useTranslate()

    const renderCellWithEmptyIndicator = (params: GridRenderCellParams) => {
      return params.value ? params.value : '-'
    }

    const columns: GridColDef[] = [
      {
        field: 'commodityName',
        headerName: t('commodity', 'Commodity'),
        flex: 1,
        renderCell: renderCellWithEmptyIndicator,
      },
      {
        field: 'referenceNumber',
        headerName: t('referenceNumber', 'Reference number'),
        width: 150,
        renderCell: params => (
          <EditCell
            cell={params}
            onChange={row => {
              onCommodityUpdate(row)
            }}
            isEditable={!isInProgress}
          />
        ),
      },
      {
        field: 'lotNumber',
        headerName: t('lotNumber', 'Lot number'),
        flex: 1,
        renderCell: renderCellWithEmptyIndicator,
      },
      {
        field: 'packageName',
        headerName: t('packageName', 'Package'),
        flex: 1,
        renderCell: renderCellWithEmptyIndicator,
      },
      {
        field: 'inStockQuantity',
        headerName: t('inStock', 'In stock'),
        flex: 1,
        renderCell: renderCellWithEmptyIndicator,
      },
      {
        field: 'plannedAmount',
        headerName: t('plannedQuantity', 'Planned quantity'),
        minWidth: 150,
        renderCell: params => (
          <EditCell
            cell={params}
            onChange={row => {
              onCommodityUpdate(row)
            }}
            isEditable={!isInProgress}
          />
        ),
      },
      {
        field: 'actualAmount',
        headerName: t('actualAmount', 'Actual amount'),
        flex: 1,
        renderCell: renderCellWithEmptyIndicator,
      },
      {
        field: 'actions',
        headerName: t('actions', 'Actions'),
        sortable: false,
        renderCell: (params: GridRenderCellParams) =>
          isInProgress ? (
            <>-</>
          ) : (
            <IconButton onClick={() => onClickDelete(params.row)}>
              <DeleteIcon />
            </IconButton>
          ),
        width: 100,
        align: 'center',
        headerAlign: 'center',
      },
    ]

    const rows = commodities.map(commodity => ({
      id: `${commodity.customerId}_${commodity.commodityId}_${commodity.lotNumber}_${commodity.packageId}`,
      ...commodity,
    }))

    return (
      <div style={{ width: '100%' }}>
        <DataGrid rows={rows} columns={columns} rowSelection={false} />
      </div>
    )
  },
)
