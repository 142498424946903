import { Box, InputAdornment } from '@mui/material'
import { ReactNode } from 'react'
import { useMinimalsTheme } from '../../../hooks'
import { ColorSchema } from '../../palette'

interface Props {
  children: ReactNode
  color?: ColorSchema
  padding?: string
  position?: 'start' | 'end'
}

export const CustomInputAdornment = ({ children, color, padding, position }: Props) => {
  const theme = useMinimalsTheme()

  return (
    <InputAdornment position={position ?? 'end'}>
      <Box width={'1.4rem'}>
        <Box
          sx={{
            backgroundColor: `${theme.palette[color ?? 'secondary'].lighter} !important`,
            padding: padding ?? `0 ${theme.customSpacing.s}`,
            position: 'absolute',
            top: 0,
            bottom: 0,
            ...(!position || position === 'end'
              ? {
                  right: 0,
                  borderTopRightRadius: theme.customRadius.medium,
                  borderBottomRightRadius: theme.customRadius.medium,
                }
              : {
                  left: 0,
                  borderTopLeftRadius: theme.customRadius.medium,
                  borderBottomLeftRadius: theme.customRadius.medium,
                }),
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {children}
        </Box>
      </Box>
    </InputAdornment>
  )
}
