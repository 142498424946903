import { AppViewStore } from '@planning/stores/AppViewStore'
import axios, { AxiosInstance } from 'axios'
import { Configuration } from '../api'
import {
  SetAccessTokenRequestInterceptor,
  ToggleLoadingRequestInterceptor,
  ToggleLoadingResponseInterceptor,
} from './interceptors'

const httpClient = axios.create()

export const setupInterceptors = (appStore: AppViewStore) => {
  httpClient.interceptors.request.use(SetAccessTokenRequestInterceptor.onFulfilled)
  httpClient.interceptors.request.use(
    ToggleLoadingRequestInterceptor.onFulfilled(appStore),
    ToggleLoadingRequestInterceptor.onRejected(appStore),
  )

  httpClient.interceptors.response.use(
    ToggleLoadingResponseInterceptor.onFulfilled(appStore),
    ToggleLoadingResponseInterceptor.onRejected(appStore),
  )
}

export const createApiClient = <T>(
  service: new (configuration: Configuration, basePath: string, axios: AxiosInstance) => T,
): T => {
  return new service(new Configuration(), '', httpClient)
}

export default httpClient
