import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material'
import { EquipmentOperatorLandingUIStore } from '@operations/features/equipmentOperator/stores/equipment-operator-landing.ui-store'
import { EquipmentStatusChip } from '@operations/features/equipments/components/statusChip/EquipmentStatus'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'

interface Props {
  uiStore: EquipmentOperatorLandingUIStore
}

export const SelectEquipment = observer(({ uiStore }: Props) => {
  const { t } = useTranslate()

  const handleEquipmentChange = (event: SelectChangeEvent) => {
    uiStore.selectEquipment(+event.target.value)
  }

  return (
    <FormControl fullWidth>
      <InputLabel>{t('equipmentName', 'Equipment name')}</InputLabel>
      <Select
        label={t('equipmentName', 'Equipment name')}
        value={uiStore.selectedEquipmentId?.toString() ?? ''}
        onChange={handleEquipmentChange}
        renderValue={selected =>
          uiStore.equipmentOptions.find(x => x.id.toString() === selected)?.name
        }
        data-cy='operator-select-equipment-field'
      >
        {uiStore.equipmentOptions.map(option => (
          <MenuItem key={option.id} value={option.id} data-cy='operator-select-equipment-option'>
            <Stack gap={theme => theme.customSpacing.xs}>
              <Typography variant='subtitle1'>{option.name}</Typography>
              <EquipmentStatusChip isUnavailable={option.isOnMaintenance} isInOperation={false} />
            </Stack>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
})
