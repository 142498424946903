import { Box, Stack, Typography } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'

interface IProps {
  itemCount: number
  startDate: Date
  pixelPerHour: number
}

export const CarrierVisitPlanningDateAxis = observer(
  ({ startDate, itemCount, pixelPerHour }: IProps) => {
    const theme = useMinimalsTheme()

    const dates = Array.from({ length: itemCount }, (_, index) =>
      dayjs(startDate).add(index, 'days'),
    )

    const gridItems = dates.map((date, index) => {
      return (
        <Box
          key={'dates_' + index}
          sx={{
            borderRight: `1px solid ${theme.palette.divider}`,
            padding: theme.customSpacing.m,
            minWidth: 24 * pixelPerHour,
            maxWidth: 24 * pixelPerHour,
            boxSizing: 'border-box',
          }}
        >
          <Typography variant='subtitle2'>{date.format('D ddd')}</Typography>
          <Stack direction='row' mt={1}>
            <Typography width='50%' variant='body2'>
              00:00
            </Typography>
            <Typography width='50%' variant='body2'>
              12:00
            </Typography>
          </Stack>
        </Box>
      )
    })

    return (
      <Stack direction={'row'} spacing={0}>
        {gridItems}
      </Stack>
    )
  },
)
