import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from '@mui/material'
import { PaginatedList } from '@planning/components/PaginatedList'
import { IContainerEventItem } from '@planning/rt-stores/container/ContainerEventItem'
import { IPaginatedStoreWithItems } from '@planning/stores/PaginatedStore'
import { formatDateTime } from '@planning/utils'
import { useTranslate } from '@tolgee/react'
import { ChevronDownIcon } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

interface Props {
  containerNumber?: string | null
  store: IPaginatedStoreWithItems<IContainerEventItem>
}

export const ContainerHistoryList: FC<Props> = observer(({ containerNumber, store }) => {
  const { t } = useTranslate()

  const prettyPrint = (json?: string | null) => {
    if (!json) return ''

    const jsonObj = JSON.parse(json)
    return JSON.stringify(jsonObj, null, 2)
  }

  if (!containerNumber) return <></>

  return (
    <Box
      sx={{
        overflowY: 'auto',
        height: '100%',
      }}
    >
      <PaginatedList
        title={t('containerHistory', `Container History - ${containerNumber}`)}
        titleVariant='h4'
        filterable
        paginatedStore={store}
        hidePaginationControl
      >
        {store.pageItems.map(item => (
          <Accordion key={item.id} sx={{ border: '1px solid', mt: '0.5rem' }}>
            <AccordionSummary
              expandIcon={<ChevronDownIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Grid container>
                <Grid item xs={3}>
                  <Typography variant='subtitle1'>{formatDateTime(item.data.time)}</Typography>
                </Grid>
                <Grid item xs>
                  <Typography variant='subtitle1'>{item.data.type}</Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <pre>{prettyPrint(item.data.payload)}</pre>
            </AccordionDetails>
          </Accordion>
        ))}
      </PaginatedList>
    </Box>
  )
})
