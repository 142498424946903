import { RailcarResponseDto } from '@planning/app/api'
import { EntityItem } from '../base/EntityItem'
import { IEntity } from '../types'

export type IRailcarItem = IEntity<RailcarResponseDto>

export class RailcarItem extends EntityItem<RailcarResponseDto> implements IRailcarItem {
  constructor(id: number, data: RailcarResponseDto) {
    super(id, data)
  }
}
