import { FormProps, SelectOption } from '@operations/app/models'
import { ControlledCheckbox } from '@operations/components/form-controls'
import { Stack, Typography } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useTranslate } from '@tolgee/react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { ControlledEquipmentType } from '../form/controlledEquipmentType/ControlledEquipmentType'
import { ControlledYardBlock } from '../form/controlledYardBlock/ControlledYardBlock'
import { EquipmentFilter, defaultValues } from './equipment-filter.model'

interface Props extends FormProps {
  filter?: EquipmentFilter
  yardBlocks: SelectOption[]
  equipmentTypeOptions: SelectOption[]
  onSubmit: (formValues: EquipmentFilter) => Promise<void> | void
}

export const EquipmentFiltersForm = ({
  id,
  filter,
  yardBlocks,
  equipmentTypeOptions,
  onSubmit,
}: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const { handleSubmit, formState, control, reset } = useForm<EquipmentFilter>({
    defaultValues: defaultValues,
  })

  useEffect(() => {
    if (filter) {
      reset(filter)
    }
  }, [filter, reset])

  return (
    <Stack
      id={id}
      component='form'
      noValidate
      autoComplete='off'
      gap={theme.customSpacing.l}
      onSubmit={handleSubmit(onSubmit)}
    >
      <ControlledEquipmentType
        name='equipmentTypes'
        formState={formState}
        control={control}
        options={equipmentTypeOptions}
        label={t('equipmentType', 'Equipment type')}
      />

      {yardBlocks.length > 0 && (
        <ControlledYardBlock
          name='yardBlockIds'
          formState={formState}
          control={control}
          label={t('workAreas', 'Work areas')}
          options={yardBlocks}
        />
      )}

      <Stack>
        <Typography variant='subtitle2'>{t('status', 'Status')}</Typography>
        <ControlledCheckbox
          name='isOnMaintenance'
          formState={formState}
          control={control}
          label={t('unavailableForOperations', 'Unavailable for operations')}
        />
      </Stack>
    </Stack>
  )
}
