import { Alert, Box } from '@mui/material'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { usePlanningStore } from '@planning/AppProvider'
import { CargoType, CarrierType, OrderResponseDto } from '@planning/app/api'
import { InitializationWrapper } from '@planning/components'
import {
  GateInGeneralCargoOrderListItem,
  OrderGrouping,
  orderService,
  RailOrderGrouping,
} from '@planning/index'
import { PATH_PLANNING } from '@planning/page-url-paths'
import { OrderGroupingStore, useWrappedLocalPagination } from '@planning/stores'
import { ValidateOutboundOrderFunc } from '@planning/stores/gateControl/ValidateOutboundDto'
import { GetOrderDamagesAmountFunc } from '@planning/stores/generalCargo/OrderDamageDto'
import { RailcarSequencingStore } from '@planning/stores/railcarSequencing/RailcarSequencingStore'
import { useTranslate } from '@tolgee/react'
import { GroupMap } from '@tom-ui/ui'
import { computed } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router'
import { CreateGeneralCargoOrder } from '../Order/CreateGeneralCargoOrders'
import { CarrierVisitActionBar } from '../VesselVisit/Components/CarrierVisitActionBar'
import { CarrierVisitDashboard } from '../VesselVisit/Components/CarrierVisitDashboard'
import { DuplicatedRailcarAlerts } from './Components/Details/DuplicateRailcarsAlert'
import { RailVisitHeader } from './Components/Header/RailVisitHeader'

// [OCTA-948] TODO: Adjust for GC
export const orderSortingDelegate = (_: any, a: OrderResponseDto, b: OrderResponseDto) => {
  return (a.containerNumber ?? '').localeCompare(b.containerNumber ?? '')
}

export const orderFilterDelegate = (
  filter: string,
  item: OrderResponseDto,
  _?: boolean,
  __?: Date,
  ___?: Date,
  filterStatus?: string,
) => {
  if (filterStatus && item.status !== filterStatus) {
    return false
  }

  if (item.containerNumber) return item.containerNumber.toLowerCase().includes(filter.toLowerCase())
  if (item.commodityName)
    return (
      (item.commodityName.toLowerCase().includes(filter.toLowerCase()) ||
        item.referenceNumber?.toLowerCase().includes(filter.toLowerCase()) ||
        item.customerName?.toLowerCase().includes(filter.toLowerCase())) ??
      false
    )

  return false
}

interface Props {
  renderDamage?: (orderId: number) => React.ReactElement
  getOrderDamagesAmountFunc?: GetOrderDamagesAmountFunc
  validateOutboundRequest?: ValidateOutboundOrderFunc
  equipmentPlanningSummary?: React.ReactElement
}
export const RailVisitDetailsPage = observer(
  ({
    renderDamage,
    getOrderDamagesAmountFunc,
    validateOutboundRequest,
    equipmentPlanningSummary,
  }: Props) => {
    const { railVisitItemStore, railVisitQueryService, dialogStore, generalCargoViewStore } =
      usePlanningStore()
    const { id, page } = useParams()
    const { t } = useTranslate()
    const navigate = useNavigate()
    const isRailSequenceEnabled = useBooleanFlagValue('rail-orders', false)

    const detailPages = {
      dashboard: 'dashboard',
      discharge: 'discharge',
      load: 'load',
    }

    const currentPage =
      Object.keys(detailPages).find(key => key === page?.toLowerCase()) ?? detailPages.dashboard

    const railVisitId = Number(id)
    if (!railVisitId) {
      navigate(PATH_PLANNING.railVisits)
    }

    const handlePageChange = (page: string) => {
      navigate(`${PATH_PLANNING.railVisits}/${railVisitId}/${page}`)
    }

    const handleEditOrder = async (order: OrderResponseDto) => {
      await generalCargoViewStore.getPackages()
      generalCargoViewStore.selectOrder(order)

      // [OCTA-948] TODO: Review and rework the store logic involved in lines below
      if (order.referenceNumber) {
        generalCargoViewStore.generalCargoSearchStore.setFilter(order.referenceNumber)
        await generalCargoViewStore.generalCargoSearchStore.fetch(
          generalCargoViewStore.generalCargoSearchStore.filter,
        )
      }

      dialogStore.openDialog(
        <CreateGeneralCargoOrder
          disableCarrierVisitSelection
          renderDamage={renderDamage}
          validateOutboundRequest={validateOutboundRequest}
        />,
      )
    }

    const handleDeleteOrder = async (id: number) => await orderService.delete(id)

    //TODO: the 'railVisitItem' is also a computed prop in RailcarSequencingStore. After integrating the feature flag, get rid of the computed value
    const railVisitItem = computed(() => railVisitItemStore.elements[railVisitId]).get()

    useEffect(() => {
      railVisitQueryService.fetchById(railVisitId)
    }, [railVisitId, railVisitQueryService])

    const orders = computed(() =>
      railVisitItemStore.ordersByRailVisitId
        ? railVisitItemStore.ordersByRailVisitId[railVisitId]
        : [],
    ).get()

    //Used to fetch orders damage when the visit is GC and the order list is available
    useEffect(() => {
      const fetchDamages = async () => {
        if (
          railVisitItem &&
          railVisitItem.data.cargoType === CargoType.GeneralCargo &&
          orders?.length &&
          getOrderDamagesAmountFunc
        ) {
          generalCargoViewStore.setDamageOrders(
            await getOrderDamagesAmountFunc(orders.map(x => x.id)),
          )
        }
      }

      fetchDamages()
    }, [generalCargoViewStore, orders, railVisitItem])

    const isGeneralCargoVisit =
      railVisitItem && railVisitItem.data.cargoType === CargoType.GeneralCargo

    const getDischargeOrders = () =>
      isGeneralCargoVisit
        ? railVisitItem.discharge.generalCargoOrders.map(o => o.data)
        : railVisitItem.discharge.containerOrders.map(o => o.data)

    const getLoadOrders = () =>
      isGeneralCargoVisit
        ? railVisitItem.load.generalCargoOrders.map(o => o.data)
        : railVisitItem.load.containerOrders.map(o => o.data)

    const dischargeOrdersGroupingStore = useMemo(
      () =>
        // [OCTA-1256] Remove the condition after feature flag is removed
        isRailSequenceEnabled
          ? new RailcarSequencingStore(railVisitId, railVisitItemStore, () =>
              railVisitItem ? railVisitItem.orders.map(o => o.data) : [],
            )
          : new OrderGroupingStore(() =>
              railVisitItem ? railVisitItem.discharge.containerOrders.map(o => o.data) : [],
            ),
      [isRailSequenceEnabled, railVisitId, railVisitItem, railVisitItemStore],
    )

    const dischargeOrdersListStore = useWrappedLocalPagination(
      () => (railVisitItem ? getDischargeOrders() : []),
      undefined,
      orderSortingDelegate,
      orderFilterDelegate,
    )

    const loadOrdersGroupingStore = useMemo(
      () =>
        // [OCTA-948] Remove the condition after feature flag is removed
        isRailSequenceEnabled
          ? new RailcarSequencingStore(railVisitId, railVisitItemStore, () =>
              railVisitItem ? railVisitItem.orders.map(o => o.data) : [],
            )
          : new OrderGroupingStore(() =>
              railVisitItem ? railVisitItem.load.containerOrders.map(o => o.data) : [],
            ),
      [isRailSequenceEnabled, railVisitId, railVisitItem, railVisitItemStore],
    )

    const loadOrdersListStore = useWrappedLocalPagination(
      () => (railVisitItem ? getLoadOrders() : []),
      undefined,
      orderSortingDelegate,
      orderFilterDelegate,
    )

    const checkIfSequenceHasErrors = (items: GroupMap): boolean => {
      for (const itemId in items) {
        const item = items[itemId]
        const sequenceValues = Object.values(item.sequence)

        // Check for negative values or duplicates
        const hasInvalidValues = sequenceValues.some(value => value < 0)
        const hasDuplicates = new Set(sequenceValues).size !== sequenceValues.length

        if (hasInvalidValues || hasDuplicates) {
          return true
        }

        // Check if the sequence is consecutive
        const sortedValues = [...sequenceValues].sort((a, b) => a - b)
        for (let i = 1; i < sortedValues.length; i++) {
          if (sortedValues[i] !== sortedValues[i - 1] + 1) {
            return true
          }
        }
      }

      return false
    }

    const OutOfOrderAlert = () => {
      return (
        <Alert
          severity='warning'
          sx={{
            mb: '1rem',
          }}
        >
          {t(
            'theSequenceNumberIsOutOfOrder',
            'The sequence number is out of order. Please review and manually adjust the sequence to ensure it aligns correctly.',
          )}
        </Alert>
      )
    }

    const RailcarLengthHigherThanTrackLengthAlert = () => {
      return (
        <Alert
          severity='warning'
          sx={{
            mb: '1rem',
          }}
        >
          {t(
            'railcarLengthHigherThanTrackLength',
            'Total length of railcars allocated to track exceeds the length of the rail track',
          )}
        </Alert>
      )
    }

    const LoadList = observer(() => {
      return (
        <>
          {!isGeneralCargoVisit && (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                padding: '1.5rem !important',
                paddingTop: '0 !important',
              }}
            >
              {railVisitItem.data.isOutboundRailcarLengthConflicted && (
                <RailcarLengthHigherThanTrackLengthAlert />
              )}

              {/* TODO: Remove cast after feature flag is removed */}
              {checkIfSequenceHasErrors(
                (dischargeOrdersGroupingStore as RailcarSequencingStore).entities.load.groups,
              ) && <OutOfOrderAlert />}

              <DuplicatedRailcarAlerts direction='Outbound' railVisitItem={railVisitItem} />

              {/* [OCTA-1256] Remove the condition and cast after feature flag is removed */}
              {isRailSequenceEnabled ? (
                <RailOrderGrouping
                  direction={'Outbound'}
                  store={loadOrdersGroupingStore as RailcarSequencingStore}
                />
              ) : (
                <OrderGrouping
                  store={loadOrdersGroupingStore as OrderGroupingStore}
                  disableSequencing
                />
              )}
            </Box>
          )}
          {isGeneralCargoVisit && (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                padding: '1.5rem !important',
              }}
            >
              {loadOrdersListStore.pageItems.map(o => {
                return (
                  <GateInGeneralCargoOrderListItem
                    key={`gate-in-order-list-item-${o.id}`}
                    order={{ ...o, carrierType: CarrierType.Train, damages: [] }}
                    damageAmount={
                      generalCargoViewStore.damageOrders.find(x => x.orderId === o.id)?.amount
                    }
                    highlight={loadOrdersListStore.filter}
                    onEdit={handleEditOrder}
                    onRemove={o => handleDeleteOrder(o.id)}
                  />
                )
              })}
            </Box>
          )}
        </>
      )
    })

    const DischargeList = observer(() => (
      <>
        {!isGeneralCargoVisit && (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              padding: '1.5rem !important',
              paddingTop: '0 !important',
            }}
          >
            {/* TODO: Remove cast after feature flag is removed */}
            {checkIfSequenceHasErrors(
              (dischargeOrdersGroupingStore as RailcarSequencingStore).entities.discharge.groups,
            ) && <OutOfOrderAlert />}

            <DuplicatedRailcarAlerts direction='Inbound' railVisitItem={railVisitItem} />
            {railVisitItem.data.isInboundRailcarLengthConflicted && (
              <RailcarLengthHigherThanTrackLengthAlert />
            )}

            {/* [OCTA-1256] Remove the condition and cast after feature flag is removed */}
            {isRailSequenceEnabled ? (
              <RailOrderGrouping
                direction={'Inbound'}
                store={dischargeOrdersGroupingStore as RailcarSequencingStore}
              />
            ) : (
              <OrderGrouping
                store={dischargeOrdersGroupingStore as OrderGroupingStore}
                disableSequencing
              />
            )}
          </Box>
        )}
        {isGeneralCargoVisit && (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              padding: '1.5rem !important',
            }}
          >
            {dischargeOrdersListStore.pageItems.map(o => {
              return (
                <GateInGeneralCargoOrderListItem
                  key={`gate-in-order-list-item-${o.id}`}
                  order={{ ...o, carrierType: CarrierType.Train, damages: [] }}
                  damageAmount={
                    generalCargoViewStore.damageOrders.find(x => x.orderId === o.id)?.amount
                  }
                  highlight={dischargeOrdersListStore.filter}
                  onEdit={handleEditOrder}
                  onRemove={o => handleDeleteOrder(o.id)}
                />
              )
            })}
          </Box>
        )}
      </>
    ))

    return (
      <InitializationWrapper isInitializing={!railVisitItem}>
        <>
          <RailVisitHeader railVisitItem={railVisitItem} currentPage={currentPage} />
          <CarrierVisitActionBar
            visitType={CarrierType.Train}
            railVisitItem={railVisitItem}
            detailPages={detailPages}
            currentPage={currentPage}
            handlePageChange={(page: string) => handlePageChange(page)}
            dischargeOrdersListStore={
              !isGeneralCargoVisit ? dischargeOrdersGroupingStore : dischargeOrdersListStore
            }
            loadOrdersListStore={
              !isGeneralCargoVisit ? loadOrdersGroupingStore : loadOrdersListStore
            }
            validationOutboundRequest={validateOutboundRequest}
          />

          {currentPage === detailPages.dashboard && (
            <CarrierVisitDashboard
              visitType={CarrierType.Train}
              detailPages={detailPages}
              railVisitItem={railVisitItem}
              handlePageChange={handlePageChange}
            />
          )}
          {currentPage !== detailPages.dashboard &&
            railVisitItem &&
            railVisitItem.hasOperationStarted && <OperationStartedAlert />}
          {currentPage === detailPages.discharge && railVisitItem && railVisitItem.orders && (
            <DischargeList />
          )}
          {currentPage === detailPages.load && railVisitItem && railVisitItem.orders && (
            <LoadList />
          )}
          {currentPage === detailPages.dashboard && equipmentPlanningSummary ? (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                padding: '1.5rem !important',
                paddingTop: '0 !important',
              }}
            >
              {equipmentPlanningSummary}
            </Box>
          ) : (
            <></>
          )}
        </>
      </InitializationWrapper>
    )
  },
)

const OperationStartedAlert = () => {
  const { t } = useTranslate()
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '1.5rem !important',
        paddingTop: '0 !important',
      }}
    >
      <Alert severity='warning'>
        {t(
          'operationStartedAnyChangesWillHaveImpactRecordedByRailTally',
          'Operation has already started. Any changes done on this page will have an impact on data recorded by the Rail Tally',
        )}
      </Alert>
    </Box>
  )
}
