import { Box, Typography } from '@mui/material'

interface Props {
  children: React.ReactElement
  text: string
}
export const ManualPlanningFormText = ({ children, text }: Props) => {
  return (
    <Box display={'flex'} flexDirection={'column'} gap={2} marginY='16px'>
      {children}
      <Typography variant='body2'>{text}</Typography>
    </Box>
  )
}
