import { Chip } from '@mui/material'
import { ContainerType } from '@planning/app/api'
import { getContainerTypeParameters } from './ContainerTypesMap'
import { useTranslate } from '@tolgee/react'

interface Props {
  containerType: ContainerType
}

export const ContainerTypeChip = ({ containerType }: Props) => {
  const { t } = useTranslate()
  const { label, icon } = getContainerTypeParameters(containerType, t)

  return (
    <Chip
      label={label}
      icon={icon}
      variant='filled'
      sx={{
        marginBottom: '0.25rem',
        pl: '.5rem',
        '& .MuiChip-label': {
          pl: '.25rem',
        },
      }}
    />
  )
}
