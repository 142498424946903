import { Add } from '@mui/icons-material'
import { Typography, Stack } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'

interface AddNewGeneralCargoLocationCardProps {
  title: string
  onClick: () => void
}
const AddNewGeneralCargoLocationCard = ({
  title,
  onClick,
}: AddNewGeneralCargoLocationCardProps) => {
  const theme = useMinimalsTheme()
  return (
    <Stack
      onClick={onClick}
      gap={theme.customSpacing.l}
      alignItems='center'
      justifyContent='center'
      sx={{ color: theme.palette.text.secondary, width: 120, cursor: 'pointer', flexShrink: 0 }}
    >
      <Add fontSize='large' color='inherit' />
      <Typography variant='button' color='inherit' sx={{ textAlign: 'center', userSelect: 'none' }}>
        {title}
      </Typography>
    </Stack>
  )
}

export default AddNewGeneralCargoLocationCard
