import { Button, Theme, styled } from '@mui/material'

interface AlwaysHoveredOutlinedButtonProps {
  selected: boolean
  theme: Theme
  error: string
}

export const AlwaysHoveredOutlinedButton = styled(Button)<AlwaysHoveredOutlinedButtonProps>(
  ({ theme, selected, error }) => ({
    backgroundColor: selected ? theme.palette.primary.lighter : undefined,
    borderColor:
      error === 'true' ? theme.palette.error.main : selected ? undefined : theme.palette.grey[300],
  }),
)
