import { Tooltip, IconButton, Typography, Chip } from '@mui/material'
import { Box } from '@mui/system'
import { GeneralCargoAreaDto } from '@storage/app/api'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { EditIcon, BinIcon, useMinimalsTheme } from '@tom-ui/ui'
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'

const GeneralCargoAreasTable = observer(() => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const { generalCargoAreaStore, generalCargoAreaUIStore } = useStores()

  const overwriteMinimalTableHeadCellStyle = {
    sx: {
      backgroundColor: theme.palette.background.neutral,
    },
  }

  const columns = useMemo<MRT_ColumnDef<GeneralCargoAreaDto>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('name', 'Name'),
      },
      {
        accessorKey: 'type',
        header: t('type', 'Type'),
      },
    ],
    [t],
  )

  useEffect(() => {
    const loadGeneralCargoAreas = () => {
      generalCargoAreaStore.loadAll()
    }

    loadGeneralCargoAreas()
  }, [generalCargoAreaStore])

  return (
    <Box m={theme.customSpacing.l}>
      <MaterialReactTable
        muiTableHeadCellProps={overwriteMinimalTableHeadCellStyle}
        columns={columns}
        data={generalCargoAreaUIStore.generalCargoAreas}
        autoResetPageIndex={false}
        enableColumnActions={false}
        enableTopToolbar={false}
        muiTableDetailPanelProps={() => ({
          sx: theme => ({
            backgroundColor: theme.palette.background.neutral,
          }),
        })}
        muiExpandButtonProps={({ row }) => ({
          sx: {
            transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
            transition: 'transform 0.2s',
          },
        })}
        renderDetailPanel={({ row }) =>
          row.original.locations ? (
            <Box
              sx={{
                display: 'flex',
                gap: 1,
              }}
            >
              {row.original.locations && row.original.locations.length > 0 ? (
                row.original.locations.map((location, index) => (
                  <Chip key={index} label={location.name} color='secondary' size='small' />
                ))
              ) : (
                <Typography>{t('noLocationsAvailable', 'No locations available')}</Typography>
              )}
            </Box>
          ) : null
        }
        enableRowActions
        renderRowActions={({ row }) => [
          <Box key={0} sx={{ minWidth: '120px' }}>
            <Tooltip title={t('edit', 'Edit')}>
              <IconButton
                aria-label='edit'
                onClick={() => generalCargoAreaUIStore.toggleDialog('Edit', row.original.id)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title={t('remove', 'Remove')}>
              <IconButton
                aria-label='delete'
                onClick={() => generalCargoAreaUIStore.toggleDialog('Delete', row.original.id)}
              >
                <BinIcon />
              </IconButton>
            </Tooltip>
          </Box>,
        ]}
        positionActionsColumn='last'
        displayColumnDefOptions={{
          'mrt-row-actions': {
            header: '',
            muiTableBodyCellProps: {
              align: 'right',
            },
          },
        }}
      />
    </Box>
  )
})

export default GeneralCargoAreasTable
