import { Autocomplete, Box, TextField } from '@mui/material'
import { VesselDto } from '@planning/app/api'
import { VesselAutocompleteViewStore } from '@planning/stores/vessel/VesselAutocompleteViewStore'
import { useTranslate } from '@tolgee/react'
import { CustomInputAdornment, useMinimalsTheme, VesselIcon } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'

interface Props {
  store: VesselAutocompleteViewStore
  value?: any
  defaultValues?: VesselDto[]
  error?: boolean
  helperText?: React.ReactNode
  label?: string
  required?: boolean
  disabled?: boolean
  multiple?: boolean
  onChange: (vessels: VesselDto[]) => void
}

export const VesselAutoComplete: FC<Props> = observer(
  ({ store, value, defaultValues, error, helperText, label, required, disabled, onChange }) => {
    const theme = useMinimalsTheme()
    const { t } = useTranslate()

    const [fieldValue, setFieldValue] = useState<VesselDto[] | undefined | null>(defaultValues)

    useEffect(() => {
      setFieldValue(defaultValues)
    }, [defaultValues])

    return (
      <Autocomplete
        sx={{ mb: '1rem' }}
        disablePortal
        disabled={disabled}
        multiple={true}
        getOptionLabel={(vessel: VesselDto) => vessel.name}
        open={store.open}
        onOpen={() => store.setOpened(true)}
        onClose={() => store.setOpened(false)}
        options={store.options}
        value={fieldValue ?? []}
        isOptionEqualToValue={(option: VesselDto, value: VesselDto) => option.id === value.id}
        onChange={(_, selectedVessel: VesselDto[] | null) => {
          setFieldValue(selectedVessel)
          onChange(selectedVessel ?? [])
        }}
        renderInput={params => (
          <TextField
            {...params}
            required={required}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <Box>
                  <CustomInputAdornment
                    position='start'
                    color='secondary'
                    padding={`0 ${theme.customSpacing.m}`}
                  >
                    <VesselIcon />
                  </CustomInputAdornment>
                  <Box sx={{ marginLeft: '50px' }}>{params.InputProps.startAdornment}</Box>
                </Box>
              ),
            }}
            placeholder={value?.length ? '' : t('vesselName', 'Vessel Name')}
            data-cy='vessel-autocomplete'
            label={label}
            helperText={helperText}
            error={error}
            onChange={e => store.filterOptions(e.target.value)}
          />
        )}
      />
    )
  },
)
