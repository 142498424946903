import { CloseButton } from '@tom-ui/ui'
import { useNavigationStore } from '@planning/stores'
import { useTranslate } from '@tolgee/react'

type Props = {
  onClose?: () => void
}

export const NavigationStackCloseButton = ({ onClose }: Props) => {
  const navigationStore = useNavigationStore()
  const { t } = useTranslate()

  return <CloseButton onClose={onClose ?? navigationStore.pop} tooltip={t('close', 'Close')} />
}
