import { Button } from '@mui/material'
import { CheckType } from '@planning/app/api'
import { useNavigationStore } from '@planning/stores'
import { useTranslate } from '@tolgee/react'
import { BackButton, ContainerMobile, Header, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { ICreateRailcarFormData, RailTallyViewStore } from '../../Stores/RailTallyViewStore'
import { InspectRailcarContainers } from '../InspectRailcar/InspectRailcarContainers'
import { CreateRailcarForm } from './CreateRailcarForm'

interface IProps {
  store: RailTallyViewStore
  onSubmit: (data: ICreateRailcarFormData) => Promise<void> | void
}

export const CreateRailcar: FC<IProps> = observer(({ store, onSubmit }) => {
  const { t } = useTranslate()
  const navigationStore = useNavigationStore()
  const theme = useMinimalsTheme()

  const [titleText, setTitleText] = useState(t('newRailcar', 'New railcar'))
  const [subtitleText, setSubtitleText] = useState('')

  useEffect(() => {
    setTitleText(
      store.currentStep == 0 ? t('newRailcar', 'New railcar') : form.getValues('railcarNumber'),
    )
    setSubtitleText(store.currentStep == 0 ? '' : store.selectedTrack?.data.name || 'Unknown track')
  }, [store.currentStep, t])

  const isLoadPrep = store.selectedOperationType === CheckType.LoadPreparation

  const form = useForm<ICreateRailcarFormData>({
    defaultValues: store.railcarToBeCreated ?? {},
  })

  const handleNextStep =
    store.currentStep === 0 && !isLoadPrep
      ? () => {
          form.trigger().then(isValid => {
            if (isValid) {
              store.setRailcarToBeCreated(form.getValues())
              store.setCurrentStep(1)
            }
          })
        }
      : form.handleSubmit(onSubmit)

  const handlePreviousStep =
    store.currentStep === 0 ? navigationStore.pop : () => store.setCurrentStep(0)

  return (
    <>
      <Header
        title={titleText}
        subTitle={subtitleText}
        leftRenderOption={
          <BackButton onGoBack={handlePreviousStep} tooltip={t('return', 'Return')} />
        }
        rightRenderOption={
          <Button
            data-cy='submit-create-railcar'
            variant='contained'
            onClick={() => {
              store.setRailcarToBeCreated(form.getValues())
              return handleNextStep()
            }}
            sx={{ width: '15%', minWidth: '110px' }}
          >
            {store.currentStep === 1 || isLoadPrep ? t('submit', 'Submit') : t('next', 'Next')}
          </Button>
        }
      />
      <ContainerMobile sx={{ pt: theme.customSpacing.l, height: 'calc(100vh - 65px)' }}>
        <FormProvider {...form}>
          {store.currentStep == 0 && (
            <CreateRailcarForm lastSequenceNumber={store.lastSequenceNumberForSelectedTrack} />
          )}
          {store.currentStep == 1 && <InspectRailcarContainers railcarOrders={[]} />}
        </FormProvider>
      </ContainerMobile>
    </>
  )
})
