import { YardBlockDto } from '@storage/app/api'
import { ExpandableMenu } from '@storage/components/ExpandableMenu'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { Box, IconButton, SxProps, Typography } from '@mui/material'
import { YardBayViewStore } from '@storage/pages/yard-management/stores/YardBayViewStore'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
interface Props {
  block: YardBlockDto
  containerSx?: SxProps
  bayViewStore: YardBayViewStore
}

export const YardBlockBayInfo = observer(({ block, containerSx, bayViewStore }: Props) => {
  const { t } = useTranslate()

  return (
    <Box sx={{ ...containerSx, display: 'flex', flexFlow: 'column' }}>
      <Typography variant='h6' sx={{ marginBottom: '1rem' }}>
        {t('yardBlockBayOverview', 'Yard Block Bay Overview')}
      </Typography>

      <Typography variant='body1'>
        {t('maxStackHeight', 'Max. stack height')} : {block.maxTier}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '1rem', marginLeft: '-17px' }}>
        <IconButton disabled={!bayViewStore.hasPrevious} onClick={() => bayViewStore.previous()}>
          <ChevronLeft />
        </IconButton>
        <IconButton disabled={!bayViewStore.hasNext} onClick={() => bayViewStore.next()}>
          <ChevronRight />
        </IconButton>
        <Typography variant='h6'>
          {block.name}.{bayViewStore.yardBlockBay?.name}
        </Typography>
        <ExpandableMenu
          items={bayViewStore.yardBlockBays}
          onSelect={selectedItem => bayViewStore.setSelectedBay(selectedItem)}
        />
      </Box>
    </Box>
  )
})
