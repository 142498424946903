import * as yup from 'yup'
import { WeightClassFormProfile } from './weight-classes-form.profile'
import { tolgee } from '@storage/app/translation'

export const schema: yup.Schema<WeightClassFormProfile> = yup.object({
  weightClasses: yup
    .array()
    .of(
      yup.object({
        id: yup.string().required(),
        name: yup.string().required(tolgee.t('NameIsRequired', 'Name is required')),
        min: yup
          .number()
          .typeError(tolgee.t('fromMustBeNumber', 'From must be a valid number'))
          .required(tolgee.t('fromIsRequired', 'From is required'))
          .min(0, tolgee.t('fromMustBePositive', 'From must be a positive number')),
        max: yup
          .number()
          .nullable()
          .when('maxFieldIsInfinite', {
            is: false,
            then: schema =>
              schema
                .typeError(tolgee.t('toMustBeNumber', 'To must be a valid number'))
                .required(tolgee.t('toIsRequired', 'To is required'))
                .min(0, tolgee.t('toMustBePositive', 'To must be a positive number')),
            otherwise: schema => schema.notRequired().nullable(),
          }),
        maxFieldIsInfinite: yup.boolean().required(),
      }),
    )
    .required()
    .test(
      'unique-weight-class-names',
      tolgee.t('weightClassNamesMustBeUnique', 'Weight class names must be unique'),
      weightClasses => {
        if (Array.isArray(weightClasses)) {
          const names = weightClasses.map(wc => wc.name.toLowerCase())
          return new Set(names).size === names.length
        }
        return false
      },
    ),
})
