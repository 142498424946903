import { Box } from '@mui/material'
import { StageWrapper } from '@storage/components/stage'
import { useHubConnection } from '@storage/hooks/use-hub-connection.hook'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef } from 'react'
import { YardBlocksLayer } from '../yard-management/components/YardBlocksLayer'
import { YardSetupToolbar } from '../yard-management/components/YardSetupToolbar'
import { YardSummary } from '../yard-management/components/widgets'
import { YardBerthsLayer } from '../yard-management/layers/berths'
import { DeleteYardObjectConfirmationDialog } from './components/DeleteYardObjectConfirmationDialog'
import { YardBlockForm } from './forms/yard-block-form/YardBlockForm'
import { YardBlockFormMapper } from './forms/yard-block-form/yard-block-form.mapper'
import { YardBlockFormProfile } from './forms/yard-block-form/yard-block-form.profile'

import { DialogFormContainer } from '@storage/components/DialogFormContainer'
import { useStores } from '@storage/hooks/use-stores.hook'
import { yardManagementHubConnection } from '@storage/hubs/yard-management.hub-connection'
import { FlexibleZoneLayer } from '@storage/pages/yard-management/components/FlexibleZoneLayer'

import { BerthForm } from '@storage/features/berths/components/berth-form/BerthForm'
import { BerthFormMapper } from '@storage/features/berths/components/berth-form/berth-form.mapper'
import { BerthFormProfile } from '@storage/features/berths/components/berth-form/berth-form.profile'
import YardFlexibleZoneForm from './forms/yard-flexible-zone-form/YardFlexibleZoneForm'
import { YardFlexibleZoneFormProfile } from './forms/yard-flexible-zone-form/yard-flexible-zone-form.profile'
import { YardFlexibleZoneMapper } from './forms/yard-flexible-zone-form/yard-flexible-zone-mapper'
import { useYardSetupStores } from './hooks/use-yard-setup.hook'

const YardSetup = observer(() => {
  const { yardDataStore, yardSetupStore } = useYardSetupStores()
  const { berthStore, snackbarStore, pierStore, yardBlockStore } = useStores()

  const containerRef = useRef<any>()

  useHubConnection(yardManagementHubConnection)

  const { t } = useTranslate()

  useEffect(() => {
    const loadData = async () => {
      await yardDataStore.load()
    }

    loadData()
  }, [yardDataStore])

  const handleSubmitYardBlock = async (formValues: YardBlockFormProfile) => {
    if (yardSetupStore.drawerAction === 'Add') {
      const block = await yardDataStore.yardBlockStore.createYardBlock(
        YardBlockFormMapper.mapFormValuesToCreateDto(formValues),
        { x: 0, y: 0 },
      )

      yardSetupStore.setSelectedYardBlock(block)
    } else {
      await yardSetupStore.yardDataStore.yardBlockStore.updateYardBlock(
        YardBlockFormMapper.mapFormValuesToUpdateDto(formValues),
      )
    }

    yardSetupStore.setDrawerAction()
  }

  const handleSubmitBerth = async (formValues: BerthFormProfile) => {
    if (formValues.id) {
      await berthStore.update(BerthFormMapper.mapFormValuesToUpdateDto(formValues))

      snackbarStore.showMessage(
        t('berthUpdatedSuccessfully', 'A berth is successfully updated'),
        'success',
      )
    } else {
      const berth = await berthStore.add(BerthFormMapper.mapFormValuesToCreateDto(formValues))

      await berthStore.load(berth.id)
      yardSetupStore.setSelectedBerth(berth)

      snackbarStore.showMessage(
        t('berthCreatedSuccessfully', 'A berth is successfully created'),
        'success',
      )
    }

    handleCloseBerthDialog()
  }

  const handleSubmitFlexibleZone = async (formValues: YardFlexibleZoneFormProfile) => {
    if (formValues.id) {
      await yardSetupStore.yardDataStore.yardBlockStore.updateYardBlock(
        YardFlexibleZoneMapper.mapFormValuesToUpdateYardBlockDto(formValues),
      )
      snackbarStore.showMessage(
        t('flexibleZoneUpdatedSuccessfully', 'A flexible zone is successfully updated'),
        'success',
      )
    } else {
      const flexibleZone = await yardDataStore.yardBlockStore.createYardBlock(
        YardFlexibleZoneMapper.mapFormValuesToCreateYardBlockDto(formValues),
        { x: 0, y: 0 },
      )

      yardSetupStore.setSelectedFlexibleZone(flexibleZone)
      snackbarStore.showMessage(
        t('flexibleZoneCreatedSuccessfully', 'A flexible zone is successfully created'),
        'success',
      )
    }
    handleCloseFlexibleZoneDialog()
  }

  const handleCloseBerthDialog = () => {
    yardSetupStore.setSelectedBerth()
    yardSetupStore.toggleBerthDialog()
  }

  const handleCloseFlexibleZoneDialog = () => {
    yardSetupStore.setSelectedFlexibleZone()
    yardSetupStore.toggleFlexibleZoneDialog()
  }

  return (
    <Box sx={{ display: 'flex', flexFlow: 'row', width: '100%', height: '100vh' }}>
      <Box sx={{ display: 'flex', flexFlow: 'column', flexGrow: 1 }}>
        <Box sx={{ height: '100%', position: 'relative' }} ref={containerRef}>
          <StageWrapper
            stageStore={yardSetupStore.yardStageStore}
            boundingBox={yardBlockStore.yardBlocksBoundingBox}
          >
            <YardBlocksLayer yardStore={yardSetupStore} editable />
            <FlexibleZoneLayer yardStore={yardSetupStore} editable />
            <YardBerthsLayer yardStore={yardSetupStore} editable />
          </StageWrapper>

          <YardSetupToolbar setupStore={yardSetupStore} />

          <DialogFormContainer
            form={
              <YardBlockForm
                id='yard-block-form'
                type={yardSetupStore.drawerAction!}
                yardBlock={yardSetupStore.selectedYardBlock}
                onSubmit={handleSubmitYardBlock}
              />
            }
            open={yardSetupStore.isDrawerOpen}
            onClose={() => yardSetupStore.setDrawerAction()}
            dialogTitle={
              yardSetupStore.drawerAction === 'Add'
                ? t('addYardBlock', 'Add yard block')
                : t('updateYardBlock', 'Update yard block')
            }
          />

          <DialogFormContainer
            form={
              <BerthForm
                id='berth-form'
                berth={yardSetupStore.selectedBerth}
                onSubmit={handleSubmitBerth}
                pierOptions={pierStore.entriesAsOptions}
              />
            }
            open={yardSetupStore.isBerthDialogOpen}
            onClose={handleCloseBerthDialog}
            dialogTitle={
              yardSetupStore.drawerAction === 'Add'
                ? t('addBerth', 'Add berth')
                : t('updateBerth', 'Update berth')
            }
          />

          <DialogFormContainer
            form={
              <YardFlexibleZoneForm
                id='flexible-zone-form'
                onSubmit={handleSubmitFlexibleZone}
                flexibleZone={yardSetupStore.selectedFlexibleZone}
              />
            }
            open={yardSetupStore.isFlexibleZoneDialogOpen}
            onClose={handleCloseFlexibleZoneDialog}
            dialogTitle={
              yardSetupStore.drawerAction === 'Add'
                ? t('addFlexibleZone', 'Add flexible zone')
                : t('updateFlexibleZone', 'Update flexible zone')
            }
          />

          {yardDataStore.yardBlockSummaryStore.yardSummary && (
            <YardSummary {...yardDataStore.yardBlockSummaryStore.yardSummary} />
          )}
        </Box>
      </Box>

      <DeleteYardObjectConfirmationDialog
        yardSetupStore={yardSetupStore}
        yardBlockStore={yardBlockStore}
      />
    </Box>
  )
})

export default YardSetup
