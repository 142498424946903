import { createSvgIcon } from '@mui/material'

export const EditIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.6603 4.6L19.4003 7.34C20.1663 8.06976 20.1976 9.28166 19.4703 10.05L10.4703 19.05C10.1443 19.3732 9.7171 19.5745 9.2603 19.62L5.0903 20H5.0003C4.7345 20.0015 4.47902 19.8972 4.2903 19.71C4.07934 19.4998 3.97303 19.2065 4.0003 18.91L4.4303 14.74C4.47581 14.2832 4.67707 13.856 5.0003 13.53L14.0003 4.53C14.7761 3.87455 15.92 3.90465 16.6603 4.6ZM13.3203 8L16.0003 10.68L18.0003 8.73L15.2703 6L13.3203 8Z'
      fill='currentColor'
    />
  </svg>,
  'EditIcon',
)
