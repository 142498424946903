import { Box, Button, Card, SxProps, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { ColorSchema, ColoredLabel } from '@tom-ui/ui'
import { useNavigate } from 'react-router'

interface DashboardCardProps {
  title: string
  subtitle: string
  subtitleColor?: ColorSchema
  actionRoute: string
  sxProps?: SxProps
  isActionDisabled?: boolean
}
const DashboardCard = ({
  title,
  subtitle,
  subtitleColor,
  actionRoute,
  sxProps,
  isActionDisabled,
}: DashboardCardProps) => {
  const { t } = useTranslate()
  const navigate = useNavigate()

  return (
    <Card
      sx={{
        ...sxProps,
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography variant='h4' data-cy='yard-planning-dashboard-title'>
            {title}
          </Typography>
          <Box data-cy='yard-planning-dashboard-label'>
            <ColoredLabel color={subtitleColor ?? 'secondary'} label={subtitle} />
          </Box>
        </Box>
        <Box>
          <Button
            disabled={isActionDisabled}
            onClick={() => navigate(actionRoute)}
            data-cy='yard-planning-dashboard-open'
          >
            {t('open', 'Open')}
          </Button>
        </Box>
      </Box>
    </Card>
  )
}

export default DashboardCard
