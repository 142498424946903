import { ButtonProps } from '@mui/material'
import { alpha, Theme } from '@mui/material/styles'
import { COLORS } from '../palette'

// ----------------------------------------------------------------------

export default function Button(theme: Theme) {
  const rootStyle = (ownerState: ButtonProps) => {
    const inheritColor = ownerState.color === 'inherit'

    const containedVariant = ownerState.variant === 'contained'

    const outlinedVariant = ownerState.variant === 'outlined'

    const textVariant = ownerState.variant === 'text'

    const smallSize = ownerState.size === 'small'

    const largeSize = ownerState.size === 'large'

    const defaultStyle = {
      ...(inheritColor && {
        // CONTAINED
        ...(containedVariant && {
          color: theme.palette.grey[800],
          backgroundColor: theme.palette.grey[200],
          '&:hover': {
            boxShadow: theme.customShadows.z8,
            backgroundColor: theme.palette.grey[300],
          },
        }),
        // OUTLINED
        ...(outlinedVariant && {
          borderColor: alpha(theme.palette.grey[500], 0.32),
          '&:hover': {
            borderColor: theme.palette.text.primary,
            backgroundColor: theme.palette.action.hover,
          },
        }),
        // TEXT
        ...(textVariant && {
          color: theme.palette.grey[800],
          '&:hover': {
            backgroundColor: 'transparent',
            color: theme.palette.grey[600],
          },
          '&:active': {
            backgroundColor: 'transparent',
            color: theme.palette.primary.main,
          },
        }),
      }),
      '&.Mui-disabled .MuiButton-startIcon svg > path': {
        fill: alpha(theme.palette.grey[800], 0.5),
      },
      '&.Mui-disabled .MuiButton-endIcon svg > path': {
        fill: alpha(theme.palette.grey[800], 0.5),
      },
    }

    const colorStyle = COLORS.map(color => ({
      ...(ownerState.color === color && {
        // CONTAINED
        ...(containedVariant && {
          '&:hover': {
            boxShadow: theme.customShadows[color],
          },
          '& .MuiButton-startIcon svg > path': {
            fill: theme.palette[color].contrastText,
          },
          '& .MuiButton-endIcon svg > path': {
            fill: theme.palette[color].contrastText,
          },
        }),
        ...(textVariant && {
          color: theme.palette[color].dark,
        }),
      }),
    }))

    const size = {
      ...(smallSize && {
        height: 30,
      }),
      ...(largeSize && {
        height: 48,
      }),
      ...{
        //default values
        ...theme.typography.button,
        borderRadius: theme.customRadius.medium,
        padding: `${theme.customSpacing.xs} ${theme.customSpacing.s}`,
      },
    }

    return [...colorStyle, defaultStyle, size]
  }

  return {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },

      styleOverrides: {
        root: ({ ownerState }: { ownerState: ButtonProps }) => rootStyle(ownerState),
      },
    },
  }
}
