import { Tab, Tabs } from '@mui/material'
import {
  CarrierVisitDirection,
  CarrierVisitStatus,
  OrderResponseDto,
  TruckVisitDto,
} from '@planning/app/api'
import { NumberBox } from '@planning/components/NumberBox'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { TruckAppointmentOrdersTabPanel } from './TruckAppointmentOrdersTabPanel'

interface Props {
  appointment: TruckVisitDto
  inboundOrders: OrderResponseDto[]
  outboundOrders: OrderResponseDto[]
  selectedDirection: CarrierVisitDirection
  handleEditOrder: (order: OrderResponseDto) => void
  handleRemoveOrder: (order: OrderResponseDto) => void
  handleDirectionChange: (direction: CarrierVisitDirection) => void
}

export const TruckAppointmentOrdersTabs: FC<Props> = observer(
  ({
    appointment,
    inboundOrders,
    outboundOrders,
    selectedDirection,
    handleEditOrder,
    handleRemoveOrder,
    handleDirectionChange,
  }: Props) => {
    const getTabValue = () => {
      if (appointment.status !== CarrierVisitStatus.Expected) return 0
      return selectedDirection === CarrierVisitDirection.Inbound ? 0 : 1
    }

    const getTabIndex = (direction: CarrierVisitDirection) => {
      if (appointment.status !== CarrierVisitStatus.Expected) return 0
      return direction === CarrierVisitDirection.Inbound ? 0 : 1
    }

    const a11yProps = (index: number) => {
      return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
      }
    }

    const { t } = useTranslate()

    return (
      <>
        <Tabs
          value={getTabValue()}
          onChange={(_, newValue) =>
            handleDirectionChange(
              newValue === 0 ? CarrierVisitDirection.Inbound : CarrierVisitDirection.Outbound,
            )
          }
        >
          {appointment.status === CarrierVisitStatus.Expected && (
            <Tab
              icon={<NumberBox number={inboundOrders.length} />}
              iconPosition='start'
              label={t('in', 'In')}
              {...a11yProps(0)}
            />
          )}
          <Tab
            icon={<NumberBox number={outboundOrders.length} />}
            iconPosition='start'
            label={t('out', 'Out')}
            {...a11yProps(1)}
          />
        </Tabs>
        {appointment.status === CarrierVisitStatus.Expected && (
          <TruckAppointmentOrdersTabPanel
            index={getTabIndex(CarrierVisitDirection.Inbound)}
            orders={inboundOrders}
            value={getTabValue()}
            handleEditOrder={handleEditOrder}
            handleRemoveOrder={handleRemoveOrder}
          />
        )}
        <TruckAppointmentOrdersTabPanel
          index={getTabIndex(CarrierVisitDirection.Outbound)}
          orders={outboundOrders}
          value={getTabValue()}
          handleEditOrder={handleEditOrder}
          handleRemoveOrder={handleRemoveOrder}
        />
      </>
    )
  },
)
