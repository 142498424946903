import { PiersApi } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'

class PierService {
  httpClient = createApiClient(PiersApi)

  get = async () => {
    const { data } = await this.httpClient.get()
    return data
  }
}

const pierService = new PierService()

export default pierService
