import { Button, Card, CardContent, Stack, Typography } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import {
  BreakTimeResponseDto,
  CreateBreakTimeCommand,
  UpdateBreakTimeCommand,
} from '@planning/app/api'
import { IRailVisitItem } from '@planning/rt-stores/railVisit/RailVisitItem'
import { IVesselVisitItem } from '@planning/rt-stores/vesselVisit/VesselVisitItem'
import breakTimeService from '@planning/services/breakTimeService'
import { useTranslate } from '@tolgee/react'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { ICreateVisitBreakFormData, VisitBreakDialog } from './VisitBreakDialog'
import { VisitBreakList } from './VisitBreakList'

interface IProps {
  vesselVisitItem?: IVesselVisitItem
  railVisitItem?: IRailVisitItem
}

export const VisitBreak = observer(({ vesselVisitItem, railVisitItem }: IProps) => {
  const { t } = useTranslate()
  const { appViewStore } = usePlanningStore()

  const [toggleDialog, setToggleDialog] = useState(false)
  const [selectedBreak, setSelectedBreak] = useState<BreakTimeResponseDto | null>(null)

  if (!vesselVisitItem && !railVisitItem) return <></>

  const visit = vesselVisitItem ?? railVisitItem
  const carrierId = visit?.id
  const breakTimes = visit?.data.breakTimes

  const handleOpenDialog = (breakData: BreakTimeResponseDto | null, open: boolean) => {
    setSelectedBreak(breakData)
    setToggleDialog(open)
  }

  const handleSubmit = async (formData: ICreateVisitBreakFormData, id?: number) => {
    const editMode = !!selectedBreak

    const data = {
      from: dayjs(formData.breakFrom) as any,
      to: dayjs(formData.breakTo) as any,
      description: formData.description,
      carrierId: carrierId,
    }

    try {
      if (editMode) {
        await breakTimeService.put({ id, ...data } as UpdateBreakTimeCommand)
      } else {
        await breakTimeService.post(data as CreateBreakTimeCommand)
      }

      appViewStore.setShowAlert('success', t('breakSuccessfullySaved', 'Break successfully saved'))
    } catch (error) {
      appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
    }

    handleOpenDialog(null, false)
  }

  const handleDelete = async (id: number) => {
    const isConfirmed = await appViewStore.setOpenConfirmDialog(
      true,
      t(
        'BreakWillBeDeletedAndCannotUndoThisAction',
        'Break will be deleted and you cannot undo this action!',
      ),
      t('deleteBreak', 'Delete break?'),
    )

    if (!isConfirmed) return

    try {
      await breakTimeService.delete(id)
      appViewStore.setShowAlert('success', t('successfullyDeleted', 'Successfully deleted'))
    } catch (e) {
      appViewStore.setShowAlert('error', t('failedToDelete', 'Failed to delete'))
    }

    handleOpenDialog(null, false)
  }

  return (
    <>
      <Card>
        <CardContent>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={1}>
            <Typography variant='h4'>{t('break', 'Break')}</Typography>
            <Button onClick={() => handleOpenDialog(null, true)}>{t('add', 'Add')}</Button>
          </Stack>
          <VisitBreakList
            breakTimes={breakTimes}
            onEdit={(data: BreakTimeResponseDto) => handleOpenDialog(data, true)}
          />
        </CardContent>
      </Card>

      <VisitBreakDialog
        onSubmit={handleSubmit}
        onClose={() => handleOpenDialog(null, false)}
        onDelete={(id: number) => handleDelete(id)}
        open={toggleDialog}
        initialFormData={selectedBreak}
        editMode={!!selectedBreak}
      />
    </>
  )
})
