import { Box, Link, Stack, Typography } from '@mui/material'
import { CarrierType, CarrierVisitDirection } from '@planning/app/api'
import { CarrierTypeIcon } from '@planning/components/CarrierTypeIcon'
import { PATH_PLANNING } from '@planning/page-url-paths'
import { formatDateTime } from '@planning/utils'
import { useTranslate } from '@tolgee/react'
import { DamagedIcon, DangerousIcon, ReeferIcon } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { useNavigate } from 'react-router'
import { IOrderWithVisit } from '../../stores/SelectOrderViewStore'
import { OrderItemChip } from '../OrderItemChip'

type Props = {
  direction: CarrierVisitDirection
  containerData?: IOrderWithVisit
}

const visitDetailsPageLinkMap = new Map([
  [CarrierType.Vessel, PATH_PLANNING.vesselVisits],
  [CarrierType.Train, PATH_PLANNING.railVisits],
  [CarrierType.Universal, undefined],
  [CarrierType.Truck, undefined],
  [undefined, undefined],
])

const VisitHeader: FC<{ containerData?: IOrderWithVisit }> = ({ containerData }) => {
  const { t } = useTranslate()

  return (
    <>
      <Typography variant='body1'>
        {containerData?.visit?.identifier ??
          containerData?.order?.carrierVisitType ??
          t('unknown', 'Unknown')}
      </Typography>
      <CarrierTypeIcon type={containerData?.order?.carrierVisitType} />
    </>
  )
}

export const ContainerVisitsCardCarrierVisitInfo: FC<Props> = observer(
  ({ containerData, direction }) => {
    const { t } = useTranslate()
    const navigate = useNavigate()

    const displyVisitDateTime = () => {
      if (direction === CarrierVisitDirection.Inbound) {
        if (containerData?.visit?.ata)
          return `${t('ata', 'ATA')}: ${formatDateTime(containerData?.visit?.ata)}`
        else if (containerData?.visit?.eta)
          return `${t('eta', 'ETA')}: ${formatDateTime(containerData?.visit?.eta)}`
        else if (containerData?.order?.carrierVisitType)
          return t('awaitingDropOff', 'Awaiting drop off')
      } else if (direction === CarrierVisitDirection.Outbound) {
        if (containerData?.visit?.atd)
          return `${t('atd', 'ATD')}: ${formatDateTime(containerData?.visit?.atd)}`
        else if (containerData?.visit?.etd)
          return `${t('etd', 'ETD')}: ${formatDateTime(containerData?.visit?.etd)}`
        else if (containerData?.order?.carrierVisitType)
          return t('awaitingPickUp', 'Awaiting pick up')
      }

      return ''
    }

    const visitDetailsPageLink = visitDetailsPageLinkMap.get(containerData?.order?.carrierVisitType)

    return (
      <Box>
        <Stack direction={'row'} spacing={1}>
          {(visitDetailsPageLink && (
            <Link
              component='button'
              variant='body2'
              color='default'
              onClick={() => navigate(`${visitDetailsPageLink}/${containerData?.visit?.id}`)}
            >
              <Stack direction={'row'} spacing={1}>
                <VisitHeader containerData={containerData} />
              </Stack>
            </Link>
          )) || <VisitHeader containerData={containerData} />}
        </Stack>
        <Typography variant='body2'>{displyVisitDateTime()}</Typography>
        <Stack direction='row' spacing={'6px'} alignItems={'center'} mt={'6px'} maxWidth={'100%'}>
          <Stack direction={'row'} spacing={0.5}>
            {containerData?.order && (
              <OrderItemChip label={(containerData?.order?.isEmpty && 'E') || 'F'}></OrderItemChip>
            )}
            {containerData?.order?.containerType === 'Reefer' && (
              <OrderItemChip
                label={
                  <Typography
                    display={'flex'}
                    gap={'4px'}
                    fontSize={'14px'}
                    sx={{ alignItems: 'center' }}
                    component={'div'}
                    variant='body2'
                  >
                    <ReeferIcon sx={{ height: '16px', width: '16px' }} />
                  </Typography>
                }
              />
            )}
            {containerData?.order?.imoClasses && containerData?.order.imoClasses.length > 0 && (
              <OrderItemChip
                label={
                  <Typography
                    display={'flex'}
                    gap={'4px'}
                    fontSize={'14px'}
                    sx={{ alignItems: 'center' }}
                    component={'div'}
                    variant='body2'
                  >
                    <DangerousIcon sx={{ height: '16px', width: '16px', marginRight: '4px' }} />
                    {containerData?.order?.imoClasses.join(', ')}
                  </Typography>
                }
              ></OrderItemChip>
            )}
            {containerData?.order?.isDamaged && (
              <OrderItemChip
                label={
                  <Typography
                    display={'flex'}
                    gap={'4px'}
                    fontSize={'14px'}
                    sx={{ alignItems: 'center' }}
                    component={'div'}
                    variant='body2'
                  >
                    <DamagedIcon sx={{ height: '16px', width: '16px' }} />
                  </Typography>
                }
              />
            )}
            {containerData?.order?.grossWeight && (
              <OrderItemChip
                label={`${containerData?.order?.grossWeight.toLocaleString(undefined, { maximumFractionDigits: 0 })}kg`}
              ></OrderItemChip>
            )}
          </Stack>
        </Stack>
      </Box>
    )
  },
)
