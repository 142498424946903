import { ContainerInformationWidgetStore } from '@storage/stores/container-information-widget.store'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { ContainerInformationWidgetProfile } from './container-information-widget-form/container-information-widget-form.profile'
import { ContainerInformationWidgetForm } from './container-information-widget-form/containerInformationWidgetForm'
import { ConfirmationDialog } from '@tom-ui/ui'
import { containerInformationWidgetFormId } from './container-information-widget-form/container-information-widget-form.scheme'

interface ContainerInformationWidgetDialogProps {
  containerInformationWidgetStore: ContainerInformationWidgetStore
}
export const ContainerInformationWidgetDialog = observer(
  ({ containerInformationWidgetStore }: ContainerInformationWidgetDialogProps) => {
    const { t } = useTranslate()

    const handleSubmit = (formValues: ContainerInformationWidgetProfile) => {
      containerInformationWidgetStore.setWidgetsPreferences(formValues)
      containerInformationWidgetStore.toggleEditMode()
    }
    return (
      <ConfirmationDialog
        formId={containerInformationWidgetFormId}
        open={containerInformationWidgetStore.isInEditMode}
        title={t('selectAttributesForWidget', 'Select the attributes to show on the widget')}
        primaryActionText={t('confirm', 'Confirm')}
        closeLabel={t('cancel', 'Cancel')}
        onClose={() => containerInformationWidgetStore.toggleEditMode()}
      >
        <ContainerInformationWidgetForm
          id={containerInformationWidgetFormId}
          defaultValues={containerInformationWidgetStore.getDefaultWidgetPreferences}
          onSubmit={handleSubmit}
        />
      </ConfirmationDialog>
    )
  },
)
