import { useNavigate } from 'react-router'

export const useNavigateWithQueryParams = () => {
  const navigate = useNavigate()

  return (path: string, queryParams?: Record<string, string>, openNewTab = false) => {
    const searchParams = new URLSearchParams(queryParams).toString()
    const search = searchParams ? `?${searchParams}` : location.search
    openNewTab
      ? window.open(searchParams ? `${path}?${searchParams}` : path)
      : navigate({
          pathname: path,
          search,
        })
  }
}
