import { createSvgIcon } from '@mui/material'

export const ArrowLeftIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M12 20L13.41 18.59L7.83 13L20 13L20 11L7.83 11L13.42 5.42L12 4L4 12L12 20Z'
      fill='currentColor'
    />
  </svg>,
  'ArrowLeftIcon',
)
