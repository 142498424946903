import { ReleaseOrderDto } from '@planning/app/api'
import { makeObservable } from 'mobx'
import { EntityItem } from '../base/EntityItem'
import { IEntity } from '../types'

export type IReleaseOrderItem = IEntity<ReleaseOrderDto, string>

export class ReleaseOrderItem
  extends EntityItem<ReleaseOrderDto, string>
  implements IReleaseOrderItem
{
  constructor(id: string, data: ReleaseOrderDto) {
    super(id, data)
    makeObservable(this, {})
  }
}
