import { Box } from '@mui/material'
import { ReactNode } from 'react'
import { ComponentStatus } from '@storage/helpers/enums'
import { LabeledNumberContainer } from './LabeledNumberContainer'
import { LinearProgressBarWithTextAndButton } from './LinearProgressBarWithTextAndButton'
interface LabeledNumberContainerWithInfoProps {
  text: string
  buttonLabel?: string
  buttonOnClickAction?: (e?: any) => void
  children: ReactNode
  progressBarValue: number
  state: ComponentStatus
}

export const LabeledNumberContainerWithInfo = ({
  text,
  buttonLabel,
  buttonOnClickAction,
  children,
  progressBarValue,
  state,
}: LabeledNumberContainerWithInfoProps) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 1.5 }}>
      <LinearProgressBarWithTextAndButton
        text={text}
        progressBarValue={progressBarValue}
        buttonLabel={buttonLabel}
        buttonOnClickAction={buttonOnClickAction}
        state={state}
      />
      <LabeledNumberContainer>{children}</LabeledNumberContainer>
    </Box>
  )
}
