import { Box, Divider, Stack, Typography } from '@mui/material'
import { getHumanReadableDate } from '@storage/utils/date'
import { TrainIcon, VesselIcon, useMinimalsTheme } from '@tom-ui/ui'
interface VesselVisitHeaderProps {
  name: string
  discriminator: string
  arrivalValue: string | undefined | null
  arrivalLabel: string
}

export const VesselVisitHeader = ({
  name,
  discriminator,
  arrivalValue,
  arrivalLabel,
}: VesselVisitHeaderProps) => {
  const { palette } = useMinimalsTheme()

  return (
    <Stack
      direction='row'
      alignItems='center'
      divider={<Divider orientation='vertical' flexItem />}
    >
      <Box sx={{ px: 3, py: 2, width: '200px' }}>
        {discriminator === 'VesselVisit' ? <VesselIcon /> : <TrainIcon />}
        <Typography variant='h6' data-cy='carrier-visit-name'>
          {name}
        </Typography>
      </Box>

      {/* <Divider orientation='vertical' variant='fullWidth' flexItem /> */}

      <Box sx={{ px: 3, py: 2 }}>
        <Typography variant='subtitle1' data-cy='vessel-visit-arrival-status'>
          {arrivalLabel}
        </Typography>
        {arrivalValue && (
          <Typography
            sx={{ textTransform: 'capitalize' }}
            variant='subtitle2'
            color={palette.text.disabled}
          >
            {getHumanReadableDate(arrivalValue)}
          </Typography>
        )}
      </Box>
    </Stack>
  )
}
