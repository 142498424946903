import { Drawer } from '@mui/material'
import { ReactNode } from 'react'
import { useMinimalsTheme } from '../../../hooks'

export interface DrawerProps {
  children: ReactNode
  anchor?: 'right' | 'left'
  open: boolean
  onClose: () => void
}
export const DrawerContainer = ({ children, anchor, open, onClose }: DrawerProps) => {
  const theme = useMinimalsTheme()

  return (
    <Drawer
      anchor={anchor ?? 'right'}
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          width: '90vw',
          [theme.breakpoints.up('md')]: {
            width: '28rem',
          },
        },
      }}
    >
      {children}
    </Drawer>
  )
}
