import * as yup from 'yup'
import { CommodityFormProfile } from './commodity-form.profile'

export const schema = (): yup.Schema<CommodityFormProfile> =>
  yup.object({
    id: yup.number().optional(),
    name: yup.string().label('Name').emptyAsUndefined().required(),
    height: yup.number().label('Height').emptyAsUndefined(),
    length: yup.number().label('Length').emptyAsUndefined(),
    width: yup.number().label('Width').emptyAsUndefined(),
    weight: yup.number().label('Weight').emptyAsUndefined(),
    classDefinition: yup.string().label('Class').emptyAsUndefined(),
    instructions: yup.string().label('Instructions').emptyAsUndefined(),
    packages: yup.array(),
    attributes: yup
      .array()
      .required()
      .of(
        yup.object({
          name: yup.string().label('Name').required(),
          value: yup.string().label('Value').required(),
        }),
      ),
  })
