import { getEquipmentTypesSelectedByPage } from '@operations/app/models/operator-pages'
import { useOperationsStore } from '@operations/AppProvider'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { SelectedEquipmentHelmet } from '@operations/features/equipmentOperator/components/SelectEquipment/SelectedEquipmentHelmet'
import { EquipmentOperatorLandingContainer } from '@operations/features/equipmentOperator/containers/EquipmentOperatorLandingContainer'
import { Container } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { FC, useEffect } from 'react'
import { useParams } from 'react-router'

const OperatorLanding: FC = withAuthenticationRequired(() => {
  const theme = useMinimalsTheme()

  const { page } = useParams()
  const equipmentTypesSelected = getEquipmentTypesSelectedByPage(page)

  const { equipmentStore, equipmentOperatorLandingUIStore } = useOperationsStore()

  useEffect(() => {
    const loadPageData = async () => {
      equipmentOperatorLandingUIStore.selectEquipmentTypes(equipmentTypesSelected)
      equipmentOperatorLandingUIStore.selectVesselVisit(null)
      equipmentOperatorLandingUIStore.loadReadyForOperationVesselVisits()

      await equipmentStore.load()
    }

    loadPageData()
  }, [equipmentTypesSelected, equipmentOperatorLandingUIStore, equipmentStore])

  return (
    <>
      <SelectedEquipmentHelmet />

      <Container sx={{ backgroundColor: theme.palette.grey[200], height: '100vh' }}>
        <EquipmentOperatorLandingContainer />
      </Container>
    </>
  )
})

export default OperatorLanding
