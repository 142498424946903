import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { Box, styled } from '@mui/system'
import { JobStatus, WorkInstructionListJobDto } from '@operations/app/api'
import { EquipmentTypeIcon } from '@operations/features/equipmentPlanning/components/EquipmentTypeIcon/EquipmentTypeIcon'
import { lowerFirstCase } from '@operations/utils/helper'
import { useTranslate } from '@tolgee/react'
import { ColoredLabel } from '@tom-ui/ui'

interface HelperProps {
  job: WorkInstructionListJobDto
}

const StatusChip = ({ job }: HelperProps) => {
  const { t } = useTranslate()

  const getChipColor = () => {
    if (job.status === JobStatus.InProgress) return 'warning'

    if (job.status === JobStatus.Todo || job.status === JobStatus.Pending) return 'secondary'

    return 'success'
  }

  return (
    <ColoredLabel
      label={
        job.status === JobStatus.Todo ? t('pending', 'Pending') : t(lowerFirstCase(job.status))
      }
      color={getChipColor()}
    />
  )
}

const EquipmentTypesIcons = ({ job }: HelperProps) => {
  return (
    job.equipmentType && (
      <Box sx={{ display: 'flex', marginLeft: '1rem', gap: '2px' }}>
        {<EquipmentTypeIcon type={job.equipmentType} />}
      </Box>
    )
  )
}

const TableContainer = styled(Table)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  minWidth: '100%',
}))

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  padding: '1rem 10%',
}))

const TableRowContainer = styled(TableRow)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.grey[400]}`,
}))

interface Props {
  jobs?: WorkInstructionListJobDto[] | null
}

export const JobDetailsTable = ({ jobs }: Props) => {
  const { t } = useTranslate()

  return (
    <Container>
      {jobs?.length ? (
        <TableContainer>
          <TableHead>
            <TableRow>
              <TableCell>{t('equipmentType')}</TableCell>
              <TableCell>{t('origin')}</TableCell>
              <TableCell>{t('destination')}</TableCell>
              <TableCell>{t('equipmentName')}</TableCell>
              <TableCell>{t('status')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {jobs?.map((job, index) => (
              <TableRowContainer key={index}>
                <TableCell
                  component='th'
                  scope='row'
                  data-cy={`job-details-table-row-${index}-column-equipment-types`}
                >
                  <EquipmentTypesIcons job={job} />
                </TableCell>
                <TableCell data-cy={`job-details-table-row-${index}-column-location-from`}>
                  {job.from.locationName}
                </TableCell>
                <TableCell data-cy={`job-details-table-row-${index}-column-location-to`}>
                  {job.to.locationName}
                </TableCell>
                <TableCell data-cy={`job-details-table-row-${index}-column-equipment-name`}>
                  {job.equipmentName}
                </TableCell>
                <TableCell data-cy={`job-details-table-row-${index}-column-job-status`}>
                  <StatusChip job={job} />
                </TableCell>
              </TableRowContainer>
            ))}
          </TableBody>
        </TableContainer>
      ) : (
        <Typography variant='h4' textAlign='center'>
          {t('jobsAreYetToBeCreated')}
        </Typography>
      )}
    </Container>
  )
}
