import {
  StackInconsistencyType,
  YardBlockInconsistencyDto,
  YardInconsistencyDto,
  YardPositionDto,
} from '@storage/app/api'
import { useStores } from '@storage/hooks/use-stores.hook'
import { Box, Chip, Typography } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'

const YardInconsistencies = observer(() => {
  const { t } = useTranslate()
  const { yardInconsistenciesStore } = useStores()
  const { palette } = useMinimalsTheme()

  useEffect(() => {
    const loadInconsistencies = async () => {
      await yardInconsistenciesStore.loadAll()
    }

    loadInconsistencies()
  }, [yardInconsistenciesStore])

  const typeExplanations = useMemo(
    () => ({
      [StackInconsistencyType.Flying]: t(
        'inconsistencyTypeFlying',
        'No container stacked in location underneath',
      ),
      [StackInconsistencyType.MultipleStacking]: t(
        'inconsistencyTypeMultipleStacking',
        'Location is occupied by more than one container',
      ),
      [StackInconsistencyType.SizeMismatch]: t(
        'inconsistencyTypeSizeMismatch',
        'Container size does not match location size',
      ),
      [StackInconsistencyType.InvalidLocation]: t(
        'inconsistencyTypeInvalidLocation',
        'Container occupies non-neighbored locations',
      ),
      [StackInconsistencyType.IllegalStacking]: t(
        'inconsistencyTypeIllegalStacking',
        'Mismatch of container sizes in stack',
      ),
      [StackInconsistencyType.DangerousContainerStackedInNonDangerousBlock]: t(
        'dangerousContainerStackedInNonDangerousBlock',
        'Dangerous container stacked in non dangerous block',
      ),
      [StackInconsistencyType.NonDangerousContainerStackedInDangerousBlock]: t(
        'nonDangerousContainerStackedInDangerousBlock',
        'Non dangerous container stacked in dangerous block',
      ),
    }),
    [t],
  )

  const typeExplanation = (type: StackInconsistencyType) => typeExplanations[type] || ''

  const locationDescriptor = (location: YardPositionDto[]) => {
    if (location.length === 0) return ''

    const bay = location.length === 1 ? location[0].bay : `${location[0].bay}-${location[1].bay}`
    return `${location[0].block}.${bay}.${location[0].row}.${location[0].tier}`
  }

  const blockHeader = (block: string, count: number) => {
    return (
      <Box
        width={'100%'}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '1rem',
          paddingLeft: '0.5rem',
        }}
      >
        <Chip label={count} sx={{ backgroundColor: palette.warning.main }} />
        <Typography variant='h6'>{t('yardBlock', 'Yard Block')}</Typography>
        <Typography variant='h5'>{block}</Typography>
      </Box>
    )
  }

  const issues = (issue: YardBlockInconsistencyDto, index: number) => {
    return (
      <Box
        key={index}
        width={'100%'}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '1rem',
          marginLeft: '0.5rem',
        }}
      >
        <Typography variant='body2'>{locationDescriptor(issue.location)}</Typography>
        <Typography variant='body2'>{issue.containerNumber}</Typography>
        <Typography variant='body2'>{typeExplanation(issue.type)}</Typography>
      </Box>
    )
  }

  const blockIssues = (blockIssues: YardInconsistencyDto) => {
    return (
      <>
        <Box
          width={'100%'}
          height={'3rem'}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: palette.grey[300],
            justifyContent: 'center',
            borderRadius: '10px',
            marginBottom: '0.5rem',
          }}
        >
          {blockHeader(blockIssues.yardBlock, blockIssues.inconsistencies.length)}
        </Box>
        {blockIssues.inconsistencies.map((e, index) => issues(e, index))}
      </>
    )
  }

  return (
    <Box sx={{ width: '100%', height: '100%', p: 3 }}>
      <Typography variant='h4' pb={2}>
        {t('yardInconsistencies', 'Yard Inconsistencies')}
      </Typography>
      {yardInconsistenciesStore.issues.map(e => (
        <Box key={e.yardBlockId} sx={{ borderRadius: '10px', marginBottom: '2rem' }}>
          {blockIssues(e)}
        </Box>
      ))}
    </Box>
  )
})

export default YardInconsistencies
