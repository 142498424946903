import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr'
import { accessTokenProvider } from '@tom-ui/utils'

export class BaseHubConnection {
  protected readonly _connection: HubConnection

  constructor(hubPath: string) {
    this._connection = new HubConnectionBuilder()
      .withUrl(`/storage/hubs/${hubPath}`, {
        withCredentials: false,
        accessTokenFactory: async () => {
          return await (accessTokenProvider.get?.() ?? Promise.reject(new Error('No access token')))
        },
      })
      .withAutomaticReconnect()
      .build()
  }

  public async start(): Promise<void> {
    if (HubConnectionState.Disconnected !== this._connection.state) return

    await this._connection.start()
  }

  public async stop(): Promise<void> {
    if (
      [HubConnectionState.Disconnected, HubConnectionState.Disconnecting].includes(
        this._connection.state,
      )
    )
      return

    await this._connection.stop()
  }
}
