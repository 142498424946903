import { withAuthenticationRequired } from '@auth0/auth0-react'
import { Alert, Box, Button, Grid } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { CargoType, RailVisitResponseDto } from '@planning/app/api'
import { HeaderWithActions, InitializationWrapper } from '@planning/components'
import { CarrierVisitNavigateButton } from '@planning/components/CarrierVisitNavigateButton'
import { validateVisitDates } from '@planning/components/carrier-visit-planning/carrier-visit-planning.helper'
import { getDefaultTripId } from '@planning/components/visit/VisitTripIdsFields'
import { GetRailcarsQuery } from '@planning/messages/queries'
import { IRailVisitPlanningFormData } from '@planning/rt-stores/railVisit/RailVisitPlanningStore'
import { useTranslate } from '@tolgee/react'
import { ConfirmationDialog, useMinimalsTheme } from '@tom-ui/ui'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router'
import { RailVisitForm } from './Components/Forms/RailVisitForm'
import { RailVisitPlanning } from './Components/Planning/RailVisitPlanning'
import { RailVisitPlanningViewStore } from './stores/RailVisitPlanningViewStore'

export const RailVisitPlanningPage: FC = withAuthenticationRequired(
  observer(() => {
    const theme = useMinimalsTheme()
    const { id } = useParams()
    const { t } = useTranslate()
    const navigate = useNavigate()
    const {
      appViewStore,
      railVisitPlanningStore,
      railVisitItemStore,
      railTrackItemStore,
      railcarItemStore,
      railcarTrackPositionItemStore,
    } = usePlanningStore()

    const railVisitPlanningViewStore = useMemo(
      () =>
        new RailVisitPlanningViewStore(
          railTrackItemStore,
          railVisitItemStore,
          railcarTrackPositionItemStore,
        ),
      [railTrackItemStore, railVisitItemStore, railcarTrackPositionItemStore],
    )

    const isRailTrackWithDuplicateRailcars = railVisitPlanningStore.railTrackWithDuplicateRailcars

    const getActions = () => {
      const actions = [
        <Button
          key='cancelButton'
          variant='text'
          color='inherit'
          size='large'
          onClick={() => {
            navigate(`/visits`)
          }}
        >
          {t('cancel', 'Cancel')}
        </Button>,
      ]

      if (railVisitPlanningStore.isEditMode)
        actions.push(
          <Button
            disabled={isRailTrackWithDuplicateRailcars}
            key='allocateButton'
            variant='contained'
            color='inherit'
            size='large'
            onClick={() => methods.handleSubmit(data => onFormSubmit(data, true))()}
          >
            {t('submit&AllocateRailcar', 'Submit & allocate railcar')}
          </Button>,
        )

      actions.push(
        <Button
          disabled={isRailTrackWithDuplicateRailcars}
          data-cy='rail-visit-planning-submit-button'
          key='addButton'
          variant='contained'
          size='large'
          onClick={() => methods.handleSubmit(data => onFormSubmit(data))()}
        >
          {t('submit', 'Submit')}
        </Button>,
      )

      return actions
    }

    const onFormSubmit = async (
      data: IRailVisitPlanningFormData,
      goesToAllocateRailcar?: boolean,
    ) => {
      try {
        let visitId: number | undefined

        if (!validateVisitDates(appViewStore, false, t, data.eta, data.etd)) {
          return
        }

        if (railVisitPlanningStore.isEditMode) {
          await railVisitPlanningStore.updateRailVisit(data)
          appViewStore.setCreatedRailVisitId(data.id!)
          appViewStore.setShowAlert(
            'success',
            t('railVisitUpdatedSuccesfully', 'Rail visit updated successfully'),
            !goesToAllocateRailcar ? <CarrierVisitNavigateButton /> : undefined,
          )

          visitId = data.id
        } else {
          const responseDto = await railVisitPlanningStore.createRailVisit(data)
          appViewStore.setCreatedRailVisitId(responseDto[0].id)
          appViewStore.setShowAlert(
            'success',
            t('railVisitCreatedSuccesfully', 'Rail visit created successfully'),
            <CarrierVisitNavigateButton />,
          )

          visitId = responseDto[0].id
        }
        railVisitPlanningStore.reset()

        if (goesToAllocateRailcar && visitId !== undefined) {
          navigate(`/rail-visits/${visitId}/discharge`)
        } else {
          navigate(`/visits`)
        }
      } catch (error) {
        if (error instanceof AxiosError && error.response?.data?.detail) {
          appViewStore.setShowAlert('error', error.response?.data?.detail)
        } else {
          appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
        }
      }
    }

    const methods = useForm<IRailVisitPlanningFormData>({
      defaultValues: {
        eta: dayjs(),
        etd: dayjs().add(1, 'day'),
        cargoType: CargoType.Container,
        railTrackIds: [],
      },
    })

    useEffect(() => {
      const { unsubscribe } = methods.watch((formValues, { type }) => {
        if (type === 'change') {
          railVisitPlanningViewStore.setFormValues(formValues as IRailVisitPlanningFormData)
        }
      })

      return () => unsubscribe()
    }, [railVisitPlanningViewStore, railVisitPlanningStore, appViewStore, methods, t])

    useEffect(() => {
      const requests = [
        railTrackItemStore.fetch(),
        railVisitPlanningViewStore.fetchVisits(),
        railcarItemStore.fetch(new GetRailcarsQuery(1, 1000)),
      ]
      methods.setValue('tripIds', [getDefaultTripId()])

      if (id) {
        railVisitPlanningStore.setIsEditMode(true)
        const visitId = Number(id)

        requests.push(railVisitItemStore.fetchById(visitId))

        Promise.all(requests).then(() => {
          const visitItem = railVisitItemStore.getById(visitId)
          railVisitPlanningStore.setRailVisit(visitItem)
          const visit = visitItem?.data

          if (visit) {
            updateForm(visit)
            railVisitPlanningViewStore.setVisitSelectedId(visitId)
            railVisitPlanningViewStore.setStartDateIfNewDateHasADifferentStart(visit.eta)
          }

          railVisitPlanningStore.setLoading(false)
        })
      } else {
        railVisitPlanningStore.setIsEditMode(false)
        railVisitPlanningStore.setLoading(false)
        Promise.all(requests)
      }

      return railVisitPlanningStore.reset()
    }, [id])

    const updateForm = (visit: RailVisitResponseDto) => {
      methods.setValue('id', visit.id)

      if (visit.name) methods.setValue('name', visit.name)
      if (visit.eta) methods.setValue('eta', dayjs(visit.eta))
      if (visit.etd) methods.setValue('etd', dayjs(visit.etd))
      if (visit.dischargeEstimate) methods.setValue('dischargeEstimate', visit.dischargeEstimate)
      if (visit.loadEstimate) methods.setValue('loadEstimate', visit.loadEstimate)
      if (visit.cargoType) methods.setValue('cargoType', visit.cargoType)
      if (visit.railcarEstimate) methods.setValue('railcarEstimate', visit.railcarEstimate)
      if (visit.assignedRailTrackIds?.length)
        methods.setValue('railTrackIds', visit.assignedRailTrackIds)
      if (visit.inboundTripIds?.length && visit.outboundTripIds?.length)
        methods.setValue(
          'tripIds',
          visit.inboundTripIds.map((x, index) => ({
            inbound: x,
            outbound: visit.outboundTripIds![index] ?? '',
          })),
        )
      if (visit.trainOperator) methods.setValue('trainOperator', visit.trainOperator)
    }

    const title = railVisitPlanningStore.isEditMode
      ? t('editRailVisit', 'Edit rail visit')
      : t('createRailVisit', 'Create rail visit')

    return (
      <InitializationWrapper isInitializing={railVisitPlanningStore.loading}>
        <Box sx={{ height: '100%' }}>
          <HeaderWithActions
            noMarginBottom={true}
            noBorder={true}
            title={title}
            actions={getActions()}
          />

          <Grid
            container
            spacing={1}
            sx={{
              height: 'calc(100% - 80px)',
            }}
          >
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                overflowY: 'auto',
                [theme.breakpoints.up('md')]: {
                  height: '100%',
                },
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
              }}
            >
              {isRailTrackWithDuplicateRailcars && (
                <Alert severity='warning'>
                  {t(
                    'thereAreCurrentlyDuplicatedRailcarsInThisRailVisitPleaseCorrectThemBeforeMakingAnyChanges',
                    'There are currently duplicated railcars in this Rail Visit. Please correct them before making any changes',
                  )}
                </Alert>
              )}
              {railVisitPlanningViewStore.isRailcarLengthSumExceedsAnyRailtrackLength && (
                <Alert severity='warning'>
                  {t(
                    'railcarLengthHigherThanTrackLength',
                    'Total length of railcars allocated to track exceeds the length of the rail track',
                  )}
                </Alert>
              )}
              {railVisitPlanningViewStore.isAnyRailcarOfVisitWithoutLength && (
                <Alert severity='warning'>
                  {t(
                    'railcarLengthMissing',
                    'Unable to calculate the train length due to railcars with missing lengths',
                  )}
                </Alert>
              )}
              <FormProvider {...methods}>
                <RailVisitForm
                  store={railVisitPlanningStore}
                  isEditMode={railVisitPlanningStore.isEditMode}
                  onFormSubmit={onFormSubmit}
                />
              </FormProvider>
            </Grid>
            <Grid
              item
              xs={12}
              md={9}
              sx={{
                overflowY: 'auto',
                [theme.breakpoints.up('md')]: {
                  height: '100%',
                },
              }}
            >
              <RailVisitPlanning store={railVisitPlanningViewStore} />
            </Grid>
          </Grid>

          <ConfirmationDialog
            open={railVisitPlanningViewStore.isDialogOpen}
            title={t('youHaveUnsavedChanges', 'You have unsaved changes')}
            message={t(
              'doYouWantToSaveBeforeEditingVisit',
              'Do you want to save your changes before editing another visit?',
            )}
            primaryActionText={t('saveChanges', 'Save changes')}
            closeLabel={t('discardChanges', 'Discard Changes')}
            onConfirm={async () => {
              const data = methods.getValues()
              await railVisitPlanningStore.updateRailVisit(data)
              railVisitPlanningViewStore.toggleConfirmationDialog(false)
              railVisitPlanningViewStore.setFormValues()

              navigate(`/rail-visits-planning/${railVisitPlanningViewStore.nextVisitIdToBeEdited}`)
            }}
            onClose={() => {
              railVisitPlanningViewStore.setFormValues()
              railVisitPlanningViewStore.toggleConfirmationDialog(false)
              navigate(`/rail-visits-planning/${railVisitPlanningViewStore.nextVisitIdToBeEdited}`)
            }}
          />
        </Box>
      </InitializationWrapper>
    )
  }),
)
