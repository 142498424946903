import QuickFilterPanel from '@storage/components/QuickFilterPanel'
import {
  ContainerTurnoversFilterFormProfile,
  ContainerTurnoversFilterFormProfileKey,
  defaultValues,
} from '@storage/pages/container-turnovers/components/container-turnovers-filter-form'
import ContainerTurnoversQuickFilterChips from '@storage/pages/container-turnovers/components/container-turnovers-filter-form/components/ContainerTurnoversQuickFilterChips'
import { useFormContext } from 'react-hook-form'

interface YardQuickFilterProps {
  disabledFilters?: ContainerTurnoversFilterFormProfileKey[]
  showClearAll: boolean
  onClearAll: () => void
}

const YardQuickFilter = ({ disabledFilters, showClearAll, onClearAll }: YardQuickFilterProps) => {
  const { control } = useFormContext<ContainerTurnoversFilterFormProfile>()

  return (
    <QuickFilterPanel onClearAll={onClearAll} showClearAll={showClearAll}>
      <ContainerTurnoversQuickFilterChips
        control={control}
        defaultValues={defaultValues}
        disabledFilters={disabledFilters}
        hiddenFilters={['locationStatus']}
      />
    </QuickFilterPanel>
  )
}

export default YardQuickFilter
