import { generatePath, useNavigate } from 'react-router'
import { PATH_STORAGE } from '@storage/routes'

export const useContainerTurnoverPositionUpdate = () => {
  const navigate = useNavigate()

  return (containerTurnoverId: string) => {
    if (!containerTurnoverId) return

    navigate(
      generatePath(PATH_STORAGE.operatorContainerTurnoversList.positionUpdate.root, {
        [PATH_STORAGE.operatorContainerTurnoversList.positionUpdate.params.containerTurnoverId]:
          containerTurnoverId,
      }),
    )
  }
}
