import { Box, Typography } from '@mui/material'
import { CarrierType } from '@storage/app/api'
import { SelectOption } from '@storage/app/models'
import { ControlledSelect } from '@storage/components/form-controls'
import { ControlledCarrierVisitSelect } from '@storage/components/form-controls/store-bound-form-controls/ControlledCarrierVisitSelect'
import { useTranslate } from '@tolgee/react'
import { useFormContext, useWatch } from 'react-hook-form'
import {
  ContainerTurnoversFilterFormProfile,
  ContainerTurnoversFilterFormProfileKey,
} from '../container-turnovers-filter-form.profile'

const carrierTypeOptions: SelectOption[] = [
  { label: 'Vessel', value: CarrierType.Vessel },
  { label: 'Rail', value: CarrierType.Train },
  { label: 'Truck', value: CarrierType.Truck },
]

interface Props {
  disabledFilters?: ContainerTurnoversFilterFormProfileKey[]
}

export const CarrierTypeFilterControls = ({ disabledFilters }: Props) => {
  const { t } = useTranslate()

  const { formState, control } = useFormContext<ContainerTurnoversFilterFormProfile>()

  const outboundCarrierType = useWatch({
    control,
    name: 'outboundCarrierType',
  })

  const inboundCarrierType = useWatch({
    control,
    name: 'inboundCarrierType',
  })

  return (
    <Box>
      <Typography variant='h6' mb={2}>
        {t('carrierType', 'Carrier Type')}
      </Typography>
      <Box display='flex' flexDirection='row' sx={{ gap: 1 }}>
        <Box flex={1}>
          <ControlledSelect
            allOption
            fullWidth
            options={carrierTypeOptions}
            formState={formState}
            name='inboundCarrierType'
            control={control}
            label={t('inboundCarrierType', 'Inbound Carrier Type')}
            disabled={disabledFilters?.includes('inboundCarrierType')}
          />
        </Box>
        <Box flex={1}>
          <ControlledCarrierVisitSelect
            formState={formState}
            name={'inboundCarrier'}
            control={control}
            label={t('inboundCarrier', 'Inbound Carrier')}
            fullWidth
            discriminator={inboundCarrierType}
            activeVesselVisitsOnly
            disabled={
              disabledFilters?.includes('inboundCarrier') ||
              (inboundCarrierType !== CarrierType.Vessel &&
                inboundCarrierType !== CarrierType.Train)
            }
            allOption
            maxMenuHeight={300}
          />
        </Box>
        <Box flex={1}>
          <ControlledSelect
            allOption
            fullWidth
            options={carrierTypeOptions}
            formState={formState}
            name='outboundCarrierType'
            control={control}
            label={t('outboundCarrierType', 'Outbound Carrier Type')}
            disabled={disabledFilters?.includes('outboundCarrierType')}
          />
        </Box>
        <Box flex={1}>
          <ControlledCarrierVisitSelect
            formState={formState}
            name={'outboundCarrier'}
            control={control}
            label={t('outboundCarrier', 'Outbound Carrier')}
            fullWidth
            discriminator={outboundCarrierType}
            activeVesselVisitsOnly
            disabled={
              disabledFilters?.includes('outboundCarrier') ||
              (outboundCarrierType !== CarrierType.Vessel &&
                outboundCarrierType !== CarrierType.Train)
            }
            allOption
            maxMenuHeight={300}
          />
        </Box>
      </Box>
    </Box>
  )
}
