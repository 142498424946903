import { Shuffle } from '@mui/icons-material'
import { Divider, Tooltip, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { CarrierVisitSummaryFilterDto } from '@storage/app/api'
import { BooleanSelectOption } from '@storage/app/models'
import { useTranslate } from '@tolgee/react'
import { ChevronRightIcon, IconButton, useMinimalsTheme } from '@tom-ui/ui'

interface Props {
  carrierVisitSummaryFilter: CarrierVisitSummaryFilterDto
  variant?: 'standard' | 'special'
  onCreateNewRuleClick: (filter: CarrierVisitSummaryFilterDto) => void
  onClick?: (filter: CarrierVisitSummaryFilterDto, variant: 'standard' | 'special') => void
}

const UnAllocatedContainersGroupItem = ({
  carrierVisitSummaryFilter,
  variant = 'standard',
  onCreateNewRuleClick,
  onClick,
}: Props) => {
  const { t } = useTranslate()
  const { palette } = useMinimalsTheme()
  const mapFilterToLabels = (filter: { [key: string]: string }): string => {
    const labels: string[] = []

    if (filter.isEmpty) {
      filter.isEmpty === BooleanSelectOption.True
        ? labels.push(t('empty', 'Empty'))
        : labels.push(t('full', 'Full'))
    }

    if (filter.isReefer === BooleanSelectOption.True) {
      labels.push(t('Reefer', 'Reefer'))
    }

    if (filter.isDangerous === BooleanSelectOption.True) {
      labels.push(t('dangerous', 'Dangerous'))
    }

    return labels.join(' ')
  }

  if (!carrierVisitSummaryFilter.settings) {
    return null
  }

  return (
    <Box
      sx={{
        '&:hover': {
          backgroundColor: palette.grey[200],
        },
      }}
    >
      <Stack
        direction='row'
        alignItems='center'
        gap={1}
        mx={2}
        my={1}
        justifyContent='space-between'
      >
        <Stack direction='row' gap={1} alignItems='center' mx={2} my={1}>
          <Typography variant='body1'>{`${carrierVisitSummaryFilter.settings['size']}ft`}</Typography>
          <Typography variant='body1'>
            {mapFilterToLabels(carrierVisitSummaryFilter.settings)}
          </Typography>
        </Stack>
        <Stack direction='row' gap={1} alignItems='center' mx={2} my={1}>
          <Typography variant='body1'>{carrierVisitSummaryFilter.unplanned}</Typography>
          <Tooltip title={t('createNewRule', 'Create new rule')}>
            <IconButton onClick={() => onCreateNewRuleClick(carrierVisitSummaryFilter)}>
              <Shuffle />
            </IconButton>
          </Tooltip>
          {!!onClick && (
            <Tooltip title={t('seeMoreDetails', 'See more details')}>
              <IconButton onClick={() => onClick(carrierVisitSummaryFilter, variant)}>
                <ChevronRightIcon />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      </Stack>
      <Divider />
    </Box>
  )
}

export default UnAllocatedContainersGroupItem
