import { TruckVisitDto, TruckVisitsApi } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'
import { Event, IEvent, IQueryHandler, IQueryMessage } from '../messageBus'

export class GetTruckVisitsQuery implements IQueryMessage {
  static type = 'GetTruckVisitsQuery'
  type = GetTruckVisitsQuery.type

  constructor(
    public from?: Date,
    public to?: Date,
    public includeCompleted = false,
    public orderBy = 'eta',
    public isDescending = false,
    public filterBy?: string,
    public filter?: string,
    public isOnTerminal?: boolean,
  ) {}

  getKey = () => {
    return JSON.stringify(this)
  }
}

const httpClient = createApiClient(TruckVisitsApi)

export const getTruckVisitsQueryHandler: IQueryHandler<
  GetTruckVisitsQuery,
  IEvent<TruckVisitDto[]>
> = async query => {
  const { data } = await httpClient.get(
    1,
    1000,
    undefined,
    query.filterBy,
    query.isOnTerminal,
    query.filter,
    query.includeCompleted,
    query.orderBy,
    query.isDescending,
    query.from ? query.from.toISOString() : undefined,
    query.to ? query.to.toISOString() : undefined,
  )

  return new Event(GetTruckVisitsQuery.type, data.payload)
}
