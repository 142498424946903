import { Button, CardContent, CardHeader, Stack, Typography } from '@mui/material'
import Card from '@mui/material/Card'
import { JobDto, OperatorFields, WorkInstructionWarningReason } from '@operations/app/api'
import { formatContainerNumber } from '@operations/utils/helper'
import { useTranslate } from '@tolgee/react'
import { ColoredLabel, HighlightedText } from '@tom-ui/ui'
import { JobAlert } from './JobAlert'
import { JobDescription } from './JobDescription'
import { JobJourney } from './JobJourney'

interface Props {
  job: JobDto
  isConfirm?: boolean
  onConfirm: (job: JobDto) => void
  highlightText?: string
  fieldsToShow?: OperatorFields
}

export const JobCard = ({ job, isConfirm, onConfirm, highlightText, fieldsToShow }: Props) => {
  const { t } = useTranslate()

  const handleConfirm = () => {
    onConfirm(job)
  }

  return (
    <Card
      sx={{ width: '100%', height: '100%' }}
      data-cy={`job-card-${job.container?.containerNumber ?? 'non-numeric'}`}
    >
      <CardHeader
        title={
          <Stack direction='row' alignItems='center' justifyContent='space-between'>
            <Typography variant='h3' data-cy='job-card-container-number'>
              <HighlightedText highlight={highlightText}>
                {job.container?.containerNumber
                  ? formatContainerNumber(job.container.containerNumber)
                  : t('noContainerNumber', 'No container no.')}
              </HighlightedText>
            </Typography>

            {job.isOptional && <ColoredLabel label={t('optional', 'Optional')} color='secondary' />}
          </Stack>
        }
        sx={{ paddingBottom: theme => theme.customSpacing.xs }}
      />

      <JobDescription job={job} fieldsToShow={fieldsToShow} />

      <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: '.5rem', height: '100%' }}>
        <JobAlert warningReason={job.warningReason} />

        <JobJourney
          origin={job.from.locationName}
          destination={job.to.locationName}
          requireYardReservation={
            job.warningReason === WorkInstructionWarningReason.RequiresReservation
          }
        />

        <Stack gap={1} paddingY={theme => theme.customSpacing.xs}>
          <Button
            color='primary'
            variant='contained'
            size='large'
            onClick={handleConfirm}
            disabled={job.isPlanned}
            data-cy='confirm-container-job-btn'
          >
            {isConfirm ? t('confirmMove', 'Confirm move') : t('startMove', 'Start move')}
          </Button>
        </Stack>
      </CardContent>
    </Card>
  )
}
