import { Grid, Stack, Tooltip, Typography } from '@mui/material'
import {
  EquipmentType,
  JobDto,
  WorkInstructionEquipmentDto,
  YardBlockEquipmentAssignmentDto,
} from '@operations/app/api'
import { EquipmentTypeIcon } from '@operations/features/equipmentPlanning/components/EquipmentTypeIcon/EquipmentTypeIcon'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import { ReactNode } from 'react'
import { isJobWithinYardBlock } from '../../stores/equipment-operator.ui-store'

interface Props {
  jobs: JobDto[]
  yardBlockAssignment: YardBlockEquipmentAssignmentDto
  onClick: (yardBlockId: string) => void
  dataCy?: string
}
export const YardBlockAssignment = observer(
  ({ yardBlockAssignment, jobs, onClick, dataCy }: Props) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()

    const getPlannedAndActiveTotals = (yardBlockAssignment: YardBlockEquipmentAssignmentDto) => {
      const yardJobs = jobs.filter(x => isJobWithinYardBlock(x, yardBlockAssignment))

      const plannedTotal = yardJobs.filter(x => x.isPlanned).length

      return (
        <Stack alignItems='center' gap={theme.customSpacing.xxxs} data-cy={dataCy}>
          <Typography variant='caption' width='4rem'>
            {t('JobsActive', '{amount} active', { amount: yardJobs.length - plannedTotal })}
          </Typography>
          <Typography variant='caption' width='4rem' color='secondary'>
            {t('JobsPlanned', '{amount} planned', { amount: plannedTotal })}
          </Typography>
        </Stack>
      )
    }

    const getEquipmentsWithIcon = (
      assginedEquipments: WorkInstructionEquipmentDto[],
      yardBlockId: string,
      isPlanned: boolean,
    ) => {
      const color = isPlanned ? 'secondary' : undefined
      const equipmentsComponent: ReactNode[] = []
      let hasExcedingEquipments = false
      const equipmentFiltered = _(assginedEquipments)
        .filter(x => x.isPlanned === isPlanned)
        .uniqBy(x => x.id)
        .orderBy(x => x.equipmentType)
        .filter((_, index) => {
          hasExcedingEquipments = index > 2
          return index <= 2
        })
        .value()
      const equipmentsGroupByType = _(equipmentFiltered)
        .groupBy(x => x.equipmentType)
        .value()

      for (const type in equipmentsGroupByType) {
        if (equipmentsGroupByType[type]) {
          const equipments = equipmentsGroupByType[type]

          if (equipments.length) {
            equipmentsComponent.push(
              <Stack
                key={yardBlockId + '_' + type}
                flexDirection='row'
                gap={theme.customSpacing.xs}
                alignItems='center'
              >
                <EquipmentTypeIcon
                  type={type as EquipmentType}
                  backgroundColorTransparent
                  color={color}
                  skipPadding
                />
                {equipments.map(x => (
                  <Typography key={yardBlockId + '_' + x.id} variant='caption' color={color}>
                    {x.name}
                  </Typography>
                ))}
              </Stack>,
            )
          }
        }
      }

      return (
        <Stack flexDirection='row' gap={theme.customSpacing.xs} alignItems='center'>
          {equipmentsComponent.map(x => x)}
          {hasExcedingEquipments && (
            <Tooltip title={t('moreEquipmentsAssigned', 'More equipments assigned')}>
              <Typography>...</Typography>
            </Tooltip>
          )}
        </Stack>
      )
    }

    return (
      <Grid
        container
        onClick={() => {
          onClick(yardBlockAssignment.yardBlockId)
        }}
        padding={theme.customSpacing.m}
        alignItems='center'
        sx={{ cursor: 'pointer' }}
      >
        <Grid item xs={3}>
          <Typography variant='body2'>{yardBlockAssignment.yardBlockName}</Typography>
        </Grid>
        <Grid item xs={3}>
          {getPlannedAndActiveTotals(yardBlockAssignment)}
        </Grid>
        <Grid item xs={6}>
          {yardBlockAssignment.equipments.length > 0 ? (
            <>
              {getEquipmentsWithIcon(
                yardBlockAssignment.equipments,
                yardBlockAssignment.yardBlockId,
                false,
              )}
              {getEquipmentsWithIcon(
                yardBlockAssignment.equipments,
                yardBlockAssignment.yardBlockId,
                true,
              )}
            </>
          ) : (
            <Typography variant='body2'>
              {t('noEquipmentAssigned', 'No equipment assigned')}
            </Typography>
          )}
        </Grid>
      </Grid>
    )
  },
)
