/* eslint-disable @typescript-eslint/no-unused-vars */
import { YardBlockBayDto, YardBlockDto, YardBlockRowDto } from '@storage/app/api'
import { useStores } from '@storage/hooks/use-stores.hook'
import { YardSetupStore } from '@storage/pages/yard-setup/stores/yard-setup.store'
import { YardElement, YardElementType } from '@storage/pages/yard-setup/types/yard-setup.types'
import { observer } from 'mobx-react-lite'
import { Layer } from 'react-konva'
import { YardViewStore } from '../stores/YardViewStore'
import { StacksAllocationStore } from '../stores/stacks-allocation.store'
import { YardBlock } from './YardBlock'
import { StackSizeDigit } from './stack'

interface Props {
  yardStore: YardViewStore | YardSetupStore
  editable?: boolean
  stacksAllocationStore?: StacksAllocationStore
  isAllocating?: boolean
}

export const YardBlocksLayer = observer(
  ({ yardStore, stacksAllocationStore, isAllocating, editable }: Props) => {
    const { yardBlockStore, yardBlockBayStore, yardBlockRowStore, yardBlockStackStore } =
      useStores()

    const handleMenuAction = async (action: string, blockDto: YardBlockDto) => {
      switch (action.toUpperCase()) {
        case 'EDIT':
          yardStore.setDrawerAction('Edit', blockDto)
          break
        case 'DELETE':
          ;(yardStore as YardSetupStore).setYardBlockId(blockDto.id)
          ;(yardStore as YardSetupStore).toggleDeletionConfirmationDialog(YardElementType.YardBlock)
          break
        case 'INFO':
          yardStore.setSelectedYardBlock(blockDto)
          break
      }
    }

    const handleBayLabelClicked = async (bay: YardBlockBayDto) => {
      if (!yardStore.isAllocationMode) return
    }

    const handleRowLabelClicked = async (row: YardBlockRowDto) => {
      if (!yardStore.isAllocationMode) return
    }

    const handleShowSummary = async (yardBlockId: string) => {
      await yardStore.toggleYardBlockSummary(yardBlockId)
    }

    const handleClickSummary = () => yardStore.toggleYardBlockSummary()

    const handleGeometryChange = (element: YardElement) => {
      ;(yardStore as YardSetupStore).saveTransformation(element)
    }

    const handleToggleStackAllocation = (stackId: string) => {
      if (!stacksAllocationStore) return

      // When reactivating visual allocation:
      // Use specific methods for specific size of containers to allocate
      // (toggleAllocationFor40 also exists in allocation store)
      stacksAllocationStore.toggleAllocationFor20(stackId)
    }

    const handleSelectBlockForEditing = (blockId: string) => {
      yardStore.setSelectedYardBlock(yardBlockStore.get(blockId))
    }

    const handleViewBayDetails = (bayId: string) => {
      yardStore.setSelectedYardBlockBay(bayId)
    }

    const handleToggleStackLock = (bayId: string, rowId: string) => {
      yardStore.yardDataStore.yardBlockStackStore.toggleStackLock(bayId, rowId)
    }

    return (
      <Layer>
        {yardStore.visibleBlocks.map(yardBlock => (
          <YardBlock
            key={yardBlock.id}
            blockDto={yardBlock}
            bays={yardBlockBayStore.getForBlock(yardBlock.id)}
            rows={yardBlockRowStore.getForBlock(yardBlock.id)}
            stacks={yardStore.yardDataStore.yardBlockStackStore.getForBlock(yardBlock.id)}
            allocationSize={StackSizeDigit.Single}
            stacksFromYardFilter={
              !editable
                ? yardBlockStackStore.stacksMatchingFilter.filter(
                    stack => stack.blockId === yardBlock.id,
                  )
                : undefined
            }
            allocatedSpace={stacksAllocationStore?.allocatedSpace ?? []}
            isSelected={yardStore.selectedYardBlock?.id === yardBlock.id}
            inEditMode={!!editable}
            displayAllocations={yardStore.isAllocationMode}
            yardBlockSummary={
              yardStore.yardBlockSummary?.id === yardBlock.id
                ? yardStore.yardDataStore.yardBlockSummaryStore.getYardBlockSummary(yardBlock.id)
                : undefined
            }
            stagePosition={yardStore.yardStageStore.position}
            onBlockStackClick={(blockId, stackId, bayId) => {
              if (isAllocating) return handleToggleStackAllocation(stackId)

              if (editable) return handleSelectBlockForEditing(blockId)

              return handleViewBayDetails(bayId)
            }}
            onBlockStackDblClick={(blockId, bayId, rowId) => {
              if (editable && yardStore.selectedYardBlock?.id === blockId)
                return handleToggleStackLock(bayId, rowId)
            }}
            onMenuAction={action => handleMenuAction(action, yardBlock)}
            onShowSummary={handleShowSummary}
            onClick={() => editable && yardStore.setSelectedYardBlock(yardBlock)}
            onGeometryChange={handleGeometryChange}
            onBayLabelClicked={handleBayLabelClicked}
            onRowLabelClicked={handleRowLabelClicked}
            onClickSummary={handleClickSummary}
          />
        ))}
      </Layer>
    )
  },
)
