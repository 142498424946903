import { Button, DialogActions } from '@mui/material'
import { useMinimalsTheme } from '../../../hooks'

export interface FilterActionsProps {
  filterActionLabel: string
  resetActionLabel: string
  formId?: string
  onFilter: () => void
  onReset: () => void
}
export const FilterActions = ({
  filterActionLabel,
  resetActionLabel,
  formId,
  onFilter,
  onReset,
}: FilterActionsProps) => {
  const theme = useMinimalsTheme()

  return (
    <DialogActions
      sx={{
        Display: 'flex',
        gap: theme.customSpacing.xs,
        padding: 0,
      }}
    >
      <Button variant='contained' color='primary' form={formId} type='submit' onClick={onFilter}>
        {filterActionLabel}
      </Button>

      <Button variant='outlined' color='inherit' onClick={onReset}>
        {resetActionLabel}
      </Button>
    </DialogActions>
  )
}
