import { Box, ImageListItem, Stack, styled } from '@mui/material'
import { IconButton, RemoveIcon, useMinimalsTheme } from '@tom-ui/ui'

interface Props {
  damageImageFiles?: File[]
  onUpdateDamagedFiles?: (files: File[]) => void
}
export const UploadedImageList = ({ damageImageFiles, onUpdateDamagedFiles }: Props) => {
  const theme = useMinimalsTheme()

  const handleRemoveImage = (index: number) => {
    if (onUpdateDamagedFiles) {
      onUpdateDamagedFiles(
        damageImageFiles?.length ? damageImageFiles?.filter((_, i) => i !== index) : [],
      )
    }
  }

  const DeleteIconBox = styled(Box)(() => ({
    position: 'absolute',
    right: -15,
    top: -15,
  }))

  return (
    <>
      {!!damageImageFiles?.length && (
        <Stack flexDirection='row' gap={1} flexWrap='wrap'>
          {damageImageFiles?.map((file, index) => (
            <ImageListItem key={index}>
              <img
                src={URL.createObjectURL(file)}
                alt=''
                style={{ width: 70, height: 70, borderRadius: theme.customRadius.small }}
                loading='lazy'
              />
              <DeleteIconBox>
                <IconButton color='secondary' onClick={() => handleRemoveImage(index)}>
                  <RemoveIcon></RemoveIcon>
                </IconButton>
              </DeleteIconBox>
            </ImageListItem>
          ))}
        </Stack>
      )}
    </>
  )
}
