import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import { ChevronDownIcon } from '@tom-ui/ui'
import { HoldListBox } from '@planning/components/hold/HoldListBox'
import { HoldWithDirection } from '@planning/stores/hold/HoldViewStore'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'

interface IProps {
  holds: HoldWithDirection[]
}

export const HoldsAccordion: FC<IProps> = observer(({ holds }) => {
  const { t } = useTranslate()

  const [expanded, setExpanded] = useState<boolean>(holds.length > 0)

  const toggleExpanded = () => {
    setExpanded(!expanded)
  }

  if (!holds.length) return <></>

  return (
    <Accordion onChange={toggleExpanded} expanded={expanded}>
      <AccordionSummary
        expandIcon={<ChevronDownIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Typography variant='subtitle1'>{t('activeHolds', 'Active holds')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <HoldListBox holds={holds} listMaxHeight='25vh' />
      </AccordionDetails>
    </Accordion>
  )
})
