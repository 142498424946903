import DownloadIcon from '@mui/icons-material/Download'
import { Button, Divider, Stack, SxProps, Theme } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { DamageReport } from '../../stores/DamageReportStore'
import { DamageReportCard } from './DamageReportCard'

interface Props {
  damageReports: DamageReport[]
  hasActions?: boolean
  sx?: SxProps<Theme>
  onEdit?: (damageReport: DamageReport) => void
  onRemove?: (damageReport: DamageReport) => void
  onDownloadFiles?: (paths: string[]) => void
}
export const DamageReportList = ({
  damageReports,
  hasActions,
  sx,
  onEdit,
  onRemove,
  onDownloadFiles,
}: Props) => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()

  const filePaths = damageReports.flatMap(x => x.imagePaths as string[])

  const onDownloadAllFiles = () => {
    if (onDownloadFiles && filePaths) {
      onDownloadFiles(filePaths)
    }
  }

  return (
    <Stack
      gap={0.5}
      padding={theme.customSpacing.m}
      width='100%'
      divider={<Divider flexItem />}
      sx={{ ...sx }}
    >
      {damageReports.map((damageReport, i) => (
        <DamageReportCard
          key={i}
          damageReport={damageReport}
          hasActions={hasActions}
          onEdit={() => onEdit && onEdit(damageReport)}
          onRemove={() => onRemove && onRemove(damageReport)}
          onUpdateDamagedFiles={undefined}
          onDownloadFiles={onDownloadFiles}
        />
      ))}

      {filePaths.length > 0 && damageReports.length > 1 && (
        <Button sx={{ placeSelf: 'end' }} startIcon={<DownloadIcon />} onClick={onDownloadAllFiles}>
          {t('downloadAll', 'Download all')}
        </Button>
      )}
    </Stack>
  )
}
