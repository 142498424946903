import {
  AssignContainerCommand,
  CloseNonNumericOrderCommand,
  CreateNonNumericOrderCommand,
  NonNumericOrdersApi,
  UnassignContainerCommand,
  UpdateNonNumericOrderCommand,
} from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'

class NnrOrderService {
  httpClient = createApiClient(NonNumericOrdersApi)

  async getById(id: number) {
    const { data } = await this.httpClient.getById(id)
    return data
  }

  async getByReferenceNumber(referenceNumber: string) {
    const { data } = await this.httpClient.getByReferenceNumber(referenceNumber)
    return data
  }

  async post(cmd: CreateNonNumericOrderCommand) {
    await this.httpClient.post(cmd)
  }

  async put(cmd: UpdateNonNumericOrderCommand) {
    await this.httpClient.put(cmd.id, cmd)
  }

  async assignContainer(cmd: AssignContainerCommand) {
    await this.httpClient.assignContainer(cmd.nonNumericOrderId, cmd)
  }

  async unassignContainer(cmd: UnassignContainerCommand) {
    await this.httpClient.unassignContainer(cmd)
  }

  async closeOrder(cmd: CloseNonNumericOrderCommand) {
    await this.httpClient.closeOrder(cmd.id, cmd)
  }

  async availableContainers(nnrOrderId: number, remainingOrdersCount?: number) {
    const defaultMaxItems = 10
    const { data } = await this.httpClient.getSuitableContainers(
      nnrOrderId,
      1,
      remainingOrdersCount ?? defaultMaxItems,
      nnrOrderId,
    )

    return data.payload
  }
}

const nnrOrderService = new NnrOrderService()

export default nnrOrderService
