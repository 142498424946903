import { Stack, TextField, Typography } from '@mui/material'
import { JobDto } from '@operations/app/api'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { ReactNode } from 'react'

interface Props {
  job: JobDto
  children?: ReactNode
  origin?: string
}
export const JobInformationJourney = ({ job, children, origin }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  return (
    <Stack gap={theme.customSpacing.m} marginY={theme.customSpacing.m}>
      <Typography variant='subtitle1'>{t('journey', 'Journey')}</Typography>

      <TextField
        label={t('origin', 'Origin')}
        value={origin ?? job.from.locationName}
        disabled
        fullWidth
      />

      {children ?? (
        <TextField
          label={t('destination', 'Destination')}
          value={job.to.locationName}
          disabled
          fullWidth
        />
      )}
    </Stack>
  )
}
