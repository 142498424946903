import { List, Typography } from '@mui/material'
import { ContainerDamageResponseDto } from '@planning/app/api'
import { VerticalOverflowBox } from '@planning/components/VerticalOverflowBox'
import { SelectAllCard } from '@planning/pages/VesselVisit/Components/SelectAllCard'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { DamageListItem } from './DamageListItem'

interface Props {
  damages: ContainerDamageResponseDto[]
  checkedIds?: number[]
  handleCheck?: (requestId: number) => void
  handleSelectAll?: (isSelectAll: boolean) => void
  listMaxHeight?: string
}

export const DamageList: FC<Props> = observer(
  ({ damages, checkedIds, handleCheck, handleSelectAll, listMaxHeight }) => {
    const isSelectAll = () =>
      !!checkedIds && damages.every(damage => checkedIds.includes(damage.id))
    const isChecked = (id: number) => (checkedIds ? checkedIds.includes(id) : false)

    const toggleSelectedAll = () => {
      if (handleSelectAll) {
        handleSelectAll(isSelectAll())
      }
    }

    const { t } = useTranslate()

    return (
      <VerticalOverflowBox
        sx={{ maxHeight: listMaxHeight ?? '60vh', minWidth: '20rem', pb: '3rem' }}
      >
        {(damages.length && (
          <List sx={{ width: '100%' }}>
            {handleSelectAll && (
              <SelectAllCard checked={isSelectAll()} onCheck={toggleSelectedAll} />
            )}
            {damages.map(damage => {
              return (
                <DamageListItem
                  key={`damage-${damage.id}`}
                  damage={damage}
                  checked={isChecked(damage.id)}
                  onCheck={handleCheck}
                />
              )
            })}
          </List>
        )) || (
          <Typography variant='body2'>
            {t('noDamagesToDisplay', 'No damages to display')}
          </Typography>
        )}
      </VerticalOverflowBox>
    )
  },
)
