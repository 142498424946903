import { Box, Card, CardContent, Typography } from '@mui/material'
import { HighlightedText, useMinimalsTheme } from '@tom-ui/ui'
import { usePlanningStore } from '@planning/AppProvider'
import { CarrierVisitDirection, HoldResponseDto } from '@planning/app/api'
import { ContainerDirectionChip } from '@planning/components/ContainerDirectionChip'
import { useTranslate } from '@tolgee/react'
import moment from 'moment'
import { FC } from 'react'
import { CustomAlert } from './CustomAlert'

interface Props {
  containerNumber: string
  holds: HoldResponseDto[]
  direction: CarrierVisitDirection
  isOnTerminal: boolean
  highlight?: string
  onClick?: () => void
  children?: React.ReactNode
  releaseEnd?: string | null
}

export const ContainerSearchResult: FC<Props> = ({
  containerNumber,
  holds,
  highlight,
  onClick,
  children,
  direction,
  releaseEnd,
  isOnTerminal,
}) => {
  const { tenantStore } = usePlanningStore()
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const hasActiveHolds = holds.some(h => h.status === 'Active')
  const isReleaseExpired = !!releaseEnd && moment() > moment(releaseEnd)
  const isValidOnTerminalState =
    direction === CarrierVisitDirection.Inbound ? !isOnTerminal : isOnTerminal
  const isValidState =
    isValidOnTerminalState && !hasActiveHolds && (tenantStore.skipReleaseOrder || !isReleaseExpired)

  const ActiveHoldsAlert = () => (
    <CustomAlert
      message={t(
        'containerHasHoldsContactControlRoom',
        'Container has holds, contact control room',
      )}
      severity='warning'
    />
  )

  const ReleaseExpiredAlert = () => (
    <CustomAlert
      message={t(
        'containerReleaseHasExpiredContactControlRoom',
        'Container release has expired, contact control room',
      )}
      severity='warning'
    />
  )

  const NotOnTerminalAlert = () => (
    <CustomAlert
      message={t(
        'containerNotOnTerminalYetContactControlRoom',
        'Container not on terminal yet, contact control room',
      )}
      severity='warning'
    />
  )

  return (
    <Card
      sx={{
        mb: '1rem',
      }}
      onClick={() => {
        if (isValidState) onClick?.()
      }}
    >
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant='h3'>
            <HighlightedText highlight={highlight}>{containerNumber}</HighlightedText>
          </Typography>
          {direction ? (
            <ContainerDirectionChip
              direction={direction}
              label={direction === 'Inbound' ? t('dropOff', 'Drop Off') : t('pickUp', 'Pick Up')}
            />
          ) : (
            ''
          )}
        </Box>

        {isValidState && children}
        {isOnTerminal !== undefined &&
          !isOnTerminal &&
          direction == CarrierVisitDirection.Outbound && (
            <Box mb='0.5rem'>
              <NotOnTerminalAlert />
            </Box>
          )}
        {hasActiveHolds && (
          <Box mb='0.5rem'>
            <ActiveHoldsAlert />
          </Box>
        )}
        {isReleaseExpired && !tenantStore.skipReleaseOrder && (
          <Box mb='0.5rem'>
            <ReleaseExpiredAlert />
          </Box>
        )}
      </CardContent>
    </Card>
  )
}
