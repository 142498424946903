import { SelectOption } from '@storage/app/models'
import { Autocomplete, FormControl, FormHelperText, TextField } from '@mui/material'
import { useTranslate } from '@tolgee/react'

interface DropDownSelectProps {
  options: SelectOption[]
  selected: SelectOption[]
  slotLimitReached: boolean
  hasError?: boolean
  onChange?: (newValue: SelectOption[]) => void
}

export const DropDownSelect = ({
  options,
  selected,
  slotLimitReached,
  hasError,
  onChange,
}: DropDownSelectProps) => {
  const { t } = useTranslate()

  const checkDisable = (option: SelectOption) => {
    return slotLimitReached && !selected.find(x => x.value === option.value)
  }

  return (
    <FormControl fullWidth error={hasError} sx={{ marginTop: '2rem' }}>
      <Autocomplete
        options={options}
        value={selected}
        onChange={(e, value) => onChange && onChange(value)}
        getOptionDisabled={checkDisable}
        fullWidth
        isOptionEqualToValue={(option, value) => option.value === value.value}
        multiple={true}
        renderInput={params => (
          <TextField
            {...params}
            error={hasError}
            required
            label={t('destination', 'Destination')}
          />
        )}
      />
      {hasError && (
        <FormHelperText>{t('destinationIsMandatory', 'Destination is mandatory')}</FormHelperText>
      )}
    </FormControl>
  )
}
