import { OrderStatus } from '@planning/app/api'
import { IEvent, IMessageBus } from '@planning/messages'
import { EventTypes } from '@planning/messages/eventsTypes'
import packageService from '@planning/services/packageService'
import { PackageDto } from '@planning/stores/generalCargo/PackageDto'
import _ from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import { StuffingCommodityDto } from '../Components/SelectedCommoditiesTable'
import { StrippingCommodityDto } from '../Components/StrippingOrders/StrippingOrderSelectedCommoditiesTable'
import { ContainerJourneyNonNumericFilter } from '../Models/NonNumeric.model'
import { ContainerJourney, ContainerJourneyDataStore } from './ContainerJourneyDataStore'

export type ServiceOrdersType = 'stuffing' | 'stripping' | 'cooling' | null
export type StuffingSearchType = 'numeric' | 'nonNumeric'

export interface ICommodityBase {
  customerId: number
  commodityId: number
  lotNumber?: string | null
  packageId?: number | null
  totalQuantity: number
  customerName: string
  commodityName: string
}

export interface ICoolingOrder {
  id?: number
  requestedTemperature?: number
  isMonitoringRequired?: boolean
  monitoringFrequency?: number
  isPlugInRequired?: boolean
}

export const isEqualCommodityBases = (
  a: Pick<ICommodityBase, 'customerId' | 'commodityId' | 'lotNumber' | 'packageId'>,
  b: Pick<ICommodityBase, 'customerId' | 'commodityId' | 'lotNumber' | 'packageId'>,
) =>
  a.customerId === b.customerId &&
  a.commodityId === b.commodityId &&
  a.lotNumber === b.lotNumber &&
  a.packageId === b.packageId

export type ServiceOrderActiveDialogType =
  | 'confirmation'
  | 'commodity'
  | 'container'
  | 'strippingSelection'
  | 'serviceOrderDeleteConfirmation'
  | null

export class ServiceOrdersViewStore {
  mainServiceType: ServiceOrdersType = null
  selectedContainerJourney?: ContainerJourney
  stuffingSearchType: StuffingSearchType = 'numeric'
  activeDialog: ServiceOrderActiveDialogType = null
  handlingInstructions: string | undefined
  selectedStuffingCommodities: StuffingCommodityDto[] = []
  selectedStrippingCommodities: StrippingCommodityDto[] = []
  itemToBeDeleted: StrippingCommodityDto | StuffingCommodityDto | undefined
  commodityPackages: number[] = []
  coolingOrder: ICoolingOrder = {}
  packages: PackageDto[] = []
  isEditMode = false

  callbackUrlOnClose?: string

  constructor(
    messageBus: IMessageBus,
    private containerJourneyStore: ContainerJourneyDataStore,
  ) {
    makeObservable(this, {
      mainServiceType: observable,
      selectedContainerJourney: observable,
      stuffingSearchType: observable,
      activeDialog: observable,
      handlingInstructions: observable,
      selectedStuffingCommodities: observable,
      selectedStrippingCommodities: observable,
      itemToBeDeleted: observable,
      packages: observable,
      commodityPackages: observable,
      coolingOrder: observable,
      isEditMode: observable,

      fetchContainerVisits: action,
      fetchContainerVisitsByNonNumeric: action,
      setMainServiceType: action,
      setSelectedContainerJourney: action,
      setStuffingSearchType: action,
      reset: action,
      setActiveDialog: action,
      closeActiveDialog: action,
      removeSelectedStrippingCommodity: action,
      removeSelectedStuffingCommodity: action,
      addSelectedStrippingCommodity: action,
      addSelectedStuffingCommodity: action,
      setHandlingInstructions: action,
      setItemToBeDeleted: action,
      updateSelectedStuffingCommodityQuantities: action,
      fetchPackages: action,
      setCommodityPackages: action,
      setCoolingOrder: action,
      updateSelectedStuffingCommodity: action,
      setIsEditMode: action,

      containerVisits: computed,
      selectedCustomerData: computed,
      showConfirmationDialog: computed,
      showCommodityDialog: computed,
      showContainerDialog: computed,
      showStrippingSelectionDialog: computed,
      packagesOptions: computed,
      isInProgress: computed,
      isFulfilled: computed,
      isCriticalContainerDamage: computed,
    })

    messageBus.subscribeEvent(EventTypes.PackageDeleted, this.receivePackageDeletedMessage)
    messageBus.subscribeEvent(EventTypes.PackageUpserted, this.receivePackageUpsertedMessage)
  }

  updateSelectedStuffingCommodityQuantities = (stocks: ICommodityBase[]) => {
    this.selectedStuffingCommodities.forEach(commodity => {
      const stock = stocks.find(s => isEqualCommodityBases(s, commodity))
      if (stock) {
        commodity.inStockQuantity = stock.totalQuantity
      }
    })
  }

  updateSelectedStuffingCommodity = (commodity: StuffingCommodityDto) => {
    const selectedCommodityIndex = this.selectedStuffingCommodities.findIndex(s =>
      isEqualCommodityBases(s, commodity),
    )

    if (selectedCommodityIndex >= 0) {
      const selectedCommodity = { ...commodity }

      this.selectedStuffingCommodities = this.selectedStuffingCommodities.map((commodity, index) =>
        index === selectedCommodityIndex ? selectedCommodity : commodity,
      )
    }
  }

  setActiveDialog = (activeDialog: ServiceOrderActiveDialogType) => {
    this.activeDialog = activeDialog
  }

  closeActiveDialog = () => {
    this.activeDialog = null
  }

  setCallbackUrlOnClose = (url?: string) => {
    this.callbackUrlOnClose = url
  }

  setIsEditMode = (isEditMode: boolean) => {
    this.isEditMode = isEditMode
  }

  get containerVisits() {
    return this.containerJourneyStore.items
  }

  get showConfirmationDialog() {
    return this.activeDialog === 'confirmation'
  }

  get showServiceOrderDeleteConfirmationDialog() {
    return this.activeDialog === 'serviceOrderDeleteConfirmation'
  }

  get showCommodityDialog() {
    return this.activeDialog === 'commodity'
  }

  get showContainerDialog() {
    return this.activeDialog === 'container'
  }

  get showStrippingSelectionDialog() {
    return this.activeDialog === 'strippingSelection'
  }

  get selectedCustomerData() {
    if (!this.selectedContainerJourney?.inboundOrder?.order) {
      return { selectedCustomerReferenceId: null, selectedCustomerName: null }
    }

    const { customerReferenceId, customerName } = this.selectedContainerJourney.inboundOrder.order

    return {
      selectedCustomerReferenceId: customerReferenceId,
      selectedCustomerName: customerName,
    }
  }

  get isStuffing() {
    return this.mainServiceType === 'stuffing'
  }

  get isStripping() {
    return this.mainServiceType === 'stripping'
  }

  get isCooling() {
    return this.mainServiceType === 'cooling'
  }

  get packagesOptions() {
    return _(
      this.packages.map(x => ({
        ...x,
        assignedToCommodity: this.commodityPackages.includes(x.id) ? 'suggested' : 'others',
      })),
    )
      .orderBy(x => x.assignedToCommodity, 'desc')
      .value()
  }

  // [REVIEW] TODO: We need separate properties for stripping / stuffing in progress
  // o.w. it is not possible to add a stuffing order after stripping was started
  get isInProgress() {
    if (!this.selectedContainerJourney || this.isCooling) return false

    const { stuffingOrder, strippingOrder } = this.selectedContainerJourney
    return !!(
      stuffingOrder?.packingList.find(cargoItem => cargoItem.actualAmount) ||
      strippingOrder?.packingList.find(cargoItem => cargoItem.actualAmount)
    )
  }

  get isFulfilled() {
    if (!this.selectedContainerJourney) return false

    const { stuffingOrder, strippingOrder, coolingOrder } = this.selectedContainerJourney

    if (this.isStripping) return strippingOrder?.status === OrderStatus.Fulfilled

    if (this.isStuffing) return stuffingOrder?.status === OrderStatus.Fulfilled

    if (this.isCooling) return coolingOrder?.status === OrderStatus.Fulfilled

    return false
  }

  get isCriticalContainerDamage() {
    if (!this.selectedContainerJourney) return false

    const { container } = this.selectedContainerJourney

    return container.damages.some(d => d.types?.some(t => t.isCriticalDamage))
  }

  fetchPackages = async () => {
    this.packages = await packageService.getAll()
  }

  setCommodityPackages = (commodityPackages: number[]) => {
    this.commodityPackages = [...commodityPackages]
  }

  setCoolingOrder = (coolingOrder: ICoolingOrder) => {
    this.coolingOrder = coolingOrder
  }

  removeSelectedStrippingCommodity = (toBeDeleted: StrippingCommodityDto) => {
    this.selectedStrippingCommodities = this.selectedStrippingCommodities.filter(
      c => !isEqualCommodityBases(c, toBeDeleted),
    )
  }

  removeSelectedStuffingCommodity = (toBeDeleted: StuffingCommodityDto) => {
    this.selectedStuffingCommodities = this.selectedStuffingCommodities.filter(
      c => !isEqualCommodityBases(c, toBeDeleted),
    )
  }

  addSelectedStrippingCommodity = (toBeAdded: StrippingCommodityDto) => {
    this.selectedStrippingCommodities.push(toBeAdded)
  }

  addSelectedStuffingCommodity = (toBeAdded: StuffingCommodityDto) => {
    this.selectedStuffingCommodities.push(toBeAdded)
  }

  upsertSelectedCommodity = (stock: ICommodityBase) => {
    if (this.isStripping) {
      const existing = this.selectedStrippingCommodities.find(c => isEqualCommodityBases(c, stock))
      if (!existing) {
        const commodity: StrippingCommodityDto = {
          ...stock,
          plannedAmount: stock.totalQuantity,
        }
        this.addSelectedStrippingCommodity(commodity)
      }
    } else if (this.isStuffing) {
      const existing = this.selectedStuffingCommodities.find(c => isEqualCommodityBases(c, stock))
      if (!existing) {
        const commodity: StuffingCommodityDto = {
          ...stock,
          plannedAmount: 0,
          inStockQuantity: stock.totalQuantity,
        }
        this.addSelectedStuffingCommodity(commodity)
      }
    }
  }

  setHandlingInstructions = (instructions?: string) => {
    this.handlingInstructions = instructions
  }

  setItemToBeDeleted = (item?: StrippingCommodityDto | StuffingCommodityDto) => {
    this.itemToBeDeleted = item
  }

  fetchContainerVisits = async (containerId: number) =>
    this.containerJourneyStore.fetch({
      containerId,
      activeOrderIds: [],
      includeCompleted: false,
      includeUnlinkedOutboundOrders: false,
    })

  fetchContainerVisitsByNonNumeric = async (filter: ContainerJourneyNonNumericFilter) =>
    this.containerJourneyStore.fetchByContainerAttributes(filter)

  setMainServiceType = (mainServiceType: ServiceOrdersType) => {
    this.mainServiceType = mainServiceType
  }

  setSelectedContainerJourney = (selectedContainerJourney?: ContainerJourney) => {
    this.selectedStuffingCommodities = []
    this.selectedStrippingCommodities = []
    this.setHandlingInstructions()
    this.setIsEditMode(false)

    this.selectedContainerJourney = selectedContainerJourney

    if (this.isCooling) {
      if (this.selectedContainerJourney?.coolingOrder) {
        const coolingOrder = this.selectedContainerJourney?.coolingOrder
        this.setCoolingOrder({
          id: coolingOrder.id,
          isMonitoringRequired: coolingOrder.isMonitoringRequired,
          isPlugInRequired: coolingOrder.isPlugInRequired,
          requestedTemperature: coolingOrder.requestedTemperature,
          monitoringFrequency: coolingOrder.monitoringFrequency!,
        })
      } else {
        this.setCoolingOrder({})
      }
    } else {
      this.selectedContainerJourney?.stuffingOrder?.packingList.forEach(pl =>
        this.addSelectedStuffingCommodity({ ...pl, inStockQuantity: 0 }),
      )
      this.selectedStrippingCommodities = this.selectedContainerJourney?.strippingOrder
        ? [...this.selectedContainerJourney.strippingOrder.packingList]
        : []

      if (this.isStuffing)
        this.setHandlingInstructions(
          this.selectedContainerJourney?.stuffingOrder?.packingList[0].handlingInstructions ??
            undefined,
        )
      else
        this.setHandlingInstructions(
          this.selectedContainerJourney?.strippingOrder?.packingList[0].handlingInstructions ??
            undefined,
        )
    }

    if (
      (this.isStripping && selectedContainerJourney?.strippingOrderId) ||
      (this.isStuffing && selectedContainerJourney?.stuffingOrderId) ||
      (this.isCooling && selectedContainerJourney?.coolingOrderId)
    )
      this.setIsEditMode(true)
  }

  setStuffingSearchType = (stuffingSearchType: StuffingSearchType) => {
    this.stuffingSearchType = stuffingSearchType
    this.containerJourneyStore.reset()
  }

  reset = () => {
    this.containerJourneyStore.reset()
    this.mainServiceType = null
    this.setSelectedContainerJourney()
    this.setStuffingSearchType('numeric')
    this.setItemToBeDeleted()
    this.setHandlingInstructions()
    this.selectedStuffingCommodities = []
    this.selectedStrippingCommodities = []
    this.commodityPackages = []
    this.setCallbackUrlOnClose()
    this.setIsEditMode(false)
    this.setCoolingOrder({})
  }

  receivePackageUpsertedMessage = (event: IEvent<PackageDto>): void => {
    const packageDto = event.payload
    this.packages = [...this.packages.filter(p => packageDto.id !== p.id), packageDto]
  }

  receivePackageDeletedMessage = (event: IEvent<[{ id: number }]>): void => {
    const ids = event.payload.map(p => p.id)
    this.packages = this.packages.filter(p => !ids.includes(p.id))
  }
}
