import { createSvgIcon } from '@mui/material'

export const ReachStackerIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M14 17C14 18.1046 13.1046 19 12 19C10.8954 19 10 18.1046 10 17C10 15.8954 10.8954 15 12 15C13.1046 15 14 15.8954 14 17Z'
      fill='currentColor'
    />
    <path
      d='M11 5C10.4477 5 10 5.44772 10 6C10 6.55228 10.4477 7 11 7H12.0833L10.5469 14.3748C10.9774 14.136 11.4728 14 12 14C13.6569 14 15 15.3431 15 17H17C17 15.3431 18.3431 14 20 14C20.7684 14 21.4692 14.2889 22 14.7639V12C22 10.8954 21.1046 10 20 10H19.3125C18.2079 10 17.3125 9.10457 17.3125 8V7H18C18.5523 7 19 6.55228 19 6C19 5.44772 18.5523 5 18 5H11Z'
      fill='currentColor'
    />
    <path
      d='M10 10V14.7639C9.38625 15.3132 9 16.1115 9 17C9 17.3506 9.06015 17.6872 9.17071 18H3.05538C2.47251 18 2 17.5275 2 16.9446C2 16.4068 2.40436 15.9551 2.93884 15.8957L8 15.3333V10C8 9.44772 8.44772 9 9 9C9.55228 9 10 9.44772 10 10Z'
      fill='currentColor'
    />
    <path
      d='M22 17C22 18.1046 21.1046 19 20 19C18.8954 19 18 18.1046 18 17C18 15.8954 18.8954 15 20 15C21.1046 15 22 15.8954 22 17Z'
      fill='currentColor'
    />
  </svg>,
  'ReachStacker',
)
