import { TruckIcon } from '@tom-ui/ui'
import { FC } from 'react'
import { IconColor } from './IconColor'

export const IconTruck: FC<{
  zoom?: number
  size?: number
  color?: IconColor
}> = ({ zoom, size, color }) => {
  return (
    <TruckIcon
      color={color}
      sx={{
        height: `${size ?? 24}px`,
        width: `${size ?? 24}px`,
        transform: `scale(${(zoom ?? 100) / 100})`,
      }}
    />
  )
}
export default IconTruck
