export interface BollardsDto {
  id?: string | null
  meterMark: number | null
  name?: string | null
}

export interface BerthFormProfile {
  id?: string
  name: string
  quayMeterMarkFrom: number
  quayMeterMarkTo: number
  maxDraft?: number
  pierId: string
  bollards: BollardsDto[]
}

export const defaultValues: BerthFormProfile = {
  name: '',
  pierId: '',
  quayMeterMarkFrom: 1,
  quayMeterMarkTo: 1,
  bollards: [],
}

export const defaultCleatMeterMarks: BollardsDto = {
  id: null,
  meterMark: null,
  name: '',
}
