import { EquipmentType } from '@operations/app/api'
import { SelectOption } from '@operations/app/models'
import { EquipmentTypeIcon } from '@operations/features/equipmentPlanning/components/EquipmentTypeIcon/EquipmentTypeIcon'
import { CheckboxProps, FormControl, FormHelperText, Paper, Stack, Typography } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
  FormState,
  Path,
  UseControllerProps,
} from 'react-hook-form'

type FieldProps = Pick<CheckboxProps, 'disabled'>

interface Props<T extends FieldValues> extends UseControllerProps<T>, FieldProps {
  formState: FormState<T>
  options?: SelectOption[]
  label: string
}

export const ControlledEquipmentType = <T extends FieldValues>({
  name,
  control,
  label,
  options,
  formState: { errors },
}: Props<T>) => {
  const theme = useMinimalsTheme()
  const isSelected = (field: any, option: SelectOption) => {
    return field.value?.some((x: any) => x === option.value)
  }

  const handlePaperClick = (field: ControllerRenderProps<T, Path<T>>, option: SelectOption) => {
    const newValue = [...field.value]

    if (newValue.length > 0) {
      const index = newValue.findIndex(x => x === option.value)

      if (index === -1) {
        newValue.push(option.value)
      } else {
        newValue.splice(index, 1)
      }
    } else {
      newValue.push(option.value)
    }

    field.onChange(newValue)
  }

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <FormControl component='fieldset' variant='standard' fullWidth>
            <Typography variant='subtitle2' marginBottom={theme.customSpacing.m}>
              {label}
            </Typography>
            <Stack flexDirection='row' flexWrap='wrap' gap={theme.customSpacing.m}>
              {options?.map(option => (
                <Paper
                  key={option.value}
                  variant='outlined'
                  onClick={() => handlePaperClick(field, option)}
                  sx={{
                    border: `1px solid ${
                      isSelected(field, option)
                        ? theme.palette.primary.main
                        : theme.palette.grey[300]
                    }`,
                    backgroundColor: isSelected(field, option)
                      ? theme.palette.primary.lighter
                      : theme.palette.common.white,
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    justifyContent: 'space-between',
                    gap: theme.spacing(2),
                    padding: theme.spacing(2),
                    width: '100%',
                    transition: '.2s all ease-in-out',
                    '&:hover': {
                      border: `1px solid ${
                        isSelected(field, option)
                          ? theme.palette.primary.dark
                          : theme.palette.primary.main
                      }`,
                    },
                    [theme.breakpoints.up('sm')]: {
                      width: '170px',
                    },
                  }}
                >
                  <EquipmentTypeIcon
                    type={option.value as EquipmentType}
                    zoom={120}
                    backgroundColorTransparent
                    color={isSelected(field, option) ? 'primary' : undefined}
                  />
                  <Typography
                    variant='subtitle1'
                    color={isSelected(field, option) ? 'primary' : undefined}
                  >
                    {option.label}
                  </Typography>
                </Paper>
              ))}
            </Stack>
          </FormControl>
        )}
      />
      {errors[name] && <FormHelperText>{errors[name]?.message as string}</FormHelperText>}
    </>
  )
}
