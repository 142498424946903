import { BerthDto } from '@storage/app/api'
import { useStores } from '@storage/hooks/use-stores.hook'
import { YardViewStore } from '@storage/pages/yard-management/stores/YardViewStore'
import { YardSetupStore } from '@storage/pages/yard-setup/stores/yard-setup.store'
import { YardElement, YardElementType } from '@storage/pages/yard-setup/types/yard-setup.types'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import { Layer } from 'react-konva'
import { BerthYardElement } from './BerthYardElement'

interface Props {
  yardStore: YardViewStore | YardSetupStore
  editable?: boolean
}

export const YardBerthsLayer = observer(({ yardStore, editable }: Props) => {
  const { berthStore } = useStores()

  const handleResize = (berth: BerthDto, newWidth: number) => {
    ;(yardStore as YardSetupStore).saveTransformation({
      type: YardElementType.Berth,
      id: berth.id,
      geometry: {
        width: Math.round(newWidth),
      },
    })
  }

  const handleGeometryChange = (element: YardElement) => {
    ;(yardStore as YardSetupStore).saveTransformation(element)
  }

  const handleEdit = (berth: BerthDto) => {
    yardStore.setSelectedBerth(berth)
    yardStore.toggleBerthDialog()
    runInAction(() => {
      yardStore.drawerAction = 'Edit'
    })
  }

  const handleDelete = (berth: BerthDto) => {
    yardStore.setSelectedBerth(berth)
    ;(yardStore as YardSetupStore).toggleDeletionConfirmationDialog(YardElementType.Berth)
  }

  const handleSelectBerth = (berth: BerthDto) => {
    yardStore.setSelectedBerth(berth)
  }

  return (
    <Layer>
      {berthStore.entries.map(berth => (
        <BerthYardElement
          key={berth.id}
          berth={berth}
          canBeModified={!!editable && yardStore.selectedBerth?.id === berth.id}
          stagePosition={yardStore.yardStageStore.position}
          onSelect={() => handleSelectBerth(berth)}
          onEdit={() => handleEdit(berth)}
          onDelete={() => handleDelete(berth)}
          onResize={newWidth => handleResize(berth, newWidth)}
          onGeometryChange={handleGeometryChange}
        />
      ))}
    </Layer>
  )
})
