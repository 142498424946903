export interface PackageFormProfile {
  id?: number
  name: string
  height?: number | null
  length?: number | null
  width?: number | null
  lengthUnit?: string | null
  weightUnit?: string | null
}

export const defaultValues: PackageFormProfile = {
  name: '',
  lengthUnit: '',
  weightUnit: '',
}
