import { FormType } from '@storage/app/models'
import { tolgee } from '@storage/app/translation'
import { makeAutoObservable } from 'mobx'

type DialogConfig = {
  title: string
  primaryButtonLabel: string
  secondaryButtonLabel: string
}

type DialogConfigs = Partial<Record<FormType, Partial<DialogConfig>>>

export class DialogUtilStore {
  dialogAction: FormType = 'Add'
  isDialogOpen = false
  dialogEntityId?: string
  private dialogConfigs: DialogConfigs = {}

  constructor() {
    makeAutoObservable(this)
  }

  toggleDialog = (action?: FormType, dialogEntityId?: string) => {
    if (action) {
      this.dialogAction = action
    }
    this.dialogEntityId = dialogEntityId
    this.isDialogOpen = !this.isDialogOpen
  }

  get dialogConfig(): DialogConfig {
    return this.getDialogConfig(this.dialogAction)
  }

  get allDialogConfigs() {
    return this.dialogConfigs
  }

  setDialogConfigs = (dialogConfigs: DialogConfigs) => {
    this.dialogConfigs = dialogConfigs
  }

  private getDialogConfig = (action: FormType): DialogConfig => {
    const defaultConfig = this.getDefaultDialogConfig(action)
    const customConfig = this.dialogConfigs[action] || {}

    return {
      ...defaultConfig,
      ...customConfig,
    }
  }

  private getDefaultDialogConfig = (action: FormType): DialogConfig => {
    const commonLabels = {
      primaryButtonLabel: tolgee.t('save', 'Save'),
      secondaryButtonLabel: tolgee.t('cancel', 'Cancel'),
    }

    const configs: Record<FormType, DialogConfig> = {
      Add: { title: tolgee.t('add', 'Add'), ...commonLabels },
      Edit: { title: tolgee.t('edit', 'Edit'), ...commonLabels },
      Delete: {
        title: tolgee.t('delete', 'Delete'),
        primaryButtonLabel: tolgee.t('delete', 'Delete'),
        secondaryButtonLabel: commonLabels.secondaryButtonLabel,
      },
    }

    return configs[action]
  }
}
