export interface DGStackingRuleFormProfile {
  id?: string
  isParentClass?: boolean
  maxStack?: number | null
  isStackingContainerOnTopAllowed: boolean
}

export const defaultValues: DGStackingRuleFormProfile = {
  isStackingContainerOnTopAllowed: true,
}
