import {
  GeneralCargoOccupancyActionDto,
  UpdateGeneralCargoOrderPlanningDto,
  UpdateGeneralCargoStockOccupancyDto,
} from '@storage/app/api'
import { OccupancyItem, StockOccupancy } from '../interfaces/occupancy-item'

const mapOccupancyItemToGeneralCargoOccupancyActionDto = (
  occupancyItem: OccupancyItem,
): GeneralCargoOccupancyActionDto => {
  return {
    quantity: occupancyItem.newQuantity,
    areaName: occupancyItem.areaName,
    locationName: occupancyItem.locationName,
    damageDescription: occupancyItem.damageDescription,
    isDamaged: occupancyItem.isDamaged,
    damageImagePaths: occupancyItem.damageImagePaths,
    properties: (occupancyItem as StockOccupancy).newProperties,
  }
}

export const mapOccupancyItemsToUpdateGeneralCargoStockOccupancyDto = (
  occupancyItems: OccupancyItem[],
  generalCargoOrderId: number,
  cargoItemId?: number,
): UpdateGeneralCargoStockOccupancyDto | UpdateGeneralCargoOrderPlanningDto => {
  return {
    orderId: generalCargoOrderId,
    cargoItemId: cargoItemId,
    actions: occupancyItems.map(mapOccupancyItemToGeneralCargoOccupancyActionDto),
  }
}
