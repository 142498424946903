import { createSvgIcon } from '@mui/material'

export const UnknowIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM9.05112 9.34133C9.01166 9.23412 8.99476 9.12052 9.00141 9.00706C9.00806 8.89359 9.03813 8.78251 9.08987 8.68018C9.33929 8.17985 9.73128 7.75683 10.2209 7.45965C10.7104 7.16247 11.2778 7.00316 11.8578 7H11.8763C12.6856 7.00309 13.4624 7.30495 14.0443 7.84247C14.6261 8.38 14.968 9.11159 14.9985 9.88434C15.0192 10.5184 14.8256 11.142 14.4463 11.6628C14.0671 12.1836 13.5225 12.5738 12.893 12.7757C12.893 12.7757 12.8026 12.8057 12.8026 12.8568V13.548C12.8026 13.7778 12.707 13.9982 12.537 14.1607C12.3669 14.3232 12.1362 14.4145 11.8956 14.4145C11.6551 14.4145 11.4244 14.3232 11.2543 14.1607C11.0842 13.9982 10.9887 13.7778 10.9887 13.548V12.8568C10.9927 12.4738 11.1224 12.1016 11.3594 11.7926C11.5964 11.4836 11.9288 11.2534 12.3099 11.1343C12.5722 11.0494 12.799 10.8862 12.9568 10.6687C13.1147 10.4513 13.1951 10.1911 13.1864 9.92666C13.1687 9.60647 13.0242 9.30469 12.7823 9.08242C12.5405 8.86016 12.2192 8.73402 11.8836 8.72955C11.6416 8.73202 11.4051 8.79944 11.2011 8.92414C10.9972 9.04883 10.8341 9.22577 10.7303 9.43477C10.6534 9.59127 10.5291 9.72216 10.3736 9.81057C10.218 9.89898 10.0383 9.94085 9.85764 9.9308C9.67697 9.92074 9.50358 9.85922 9.35983 9.75416C9.21608 9.64909 9.10856 9.50531 9.05112 9.34133ZM12.6527 16.6129C12.7528 16.4697 12.8063 16.3013 12.8063 16.1291C12.8063 16.0151 12.7829 15.9023 12.7374 15.797C12.6917 15.6911 12.6246 15.5949 12.54 15.5138C12.4553 15.4328 12.3547 15.3685 12.244 15.3246C12.1333 15.2807 12.0146 15.2581 11.8947 15.2581C11.7144 15.2581 11.5382 15.3092 11.3883 15.4049C11.2384 15.5006 11.1215 15.6366 11.0525 15.7958C10.9835 15.9549 10.9655 16.13 11.0007 16.299C11.0358 16.4679 11.1227 16.6231 11.2501 16.7449C11.3776 16.8667 11.5401 16.9497 11.7169 16.9833C11.8937 17.0169 12.077 16.9996 12.2436 16.9337C12.4101 16.8678 12.5525 16.7562 12.6527 16.6129Z'
      fill='currentColor'
    />
  </svg>,
  'UnknowIcon',
)
