export const DeliveryOrdersIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='transparent'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.25 6C17.4489 6 17.6397 6.07902 17.7803 6.21967C17.921 6.36032 18 6.55109 18 6.75V7.5H19.53C19.8671 7.5003 20.1998 7.57635 20.5036 7.72252C20.8074 7.86869 21.0744 8.08126 21.285 8.3445L23.5065 11.1195C23.8261 11.5188 24.0002 12.015 24 12.5265V15.75C24 16.3467 23.7629 16.919 23.341 17.341C22.919 17.7629 22.3467 18 21.75 18H21C21 18.7956 20.6839 19.5587 20.1213 20.1213C19.5587 20.6839 18.7956 21 18 21C17.2044 21 16.4413 20.6839 15.8787 20.1213C15.3161 19.5587 15 18.7956 15 18H7.5C7.5 18.7956 7.18393 19.5587 6.62132 20.1213C6.05871 20.6839 5.29565 21 4.5 21C3.70435 21 2.94129 20.6839 2.37868 20.1213C1.81607 19.5587 1.5 18.7956 1.5 18C1.10218 18 0.720644 17.842 0.43934 17.5607C0.158035 17.2794 0 16.8978 0 16.5V15H16.5V6.75C16.5 6.55109 16.579 6.36032 16.7197 6.21967C16.8603 6.07902 17.0511 6 17.25 6ZM4.5 16.5C4.10218 16.5 3.72064 16.658 3.43934 16.9393C3.15804 17.2206 3 17.6022 3 18C3 18.3978 3.15804 18.7794 3.43934 19.0607C3.72064 19.342 4.10218 19.5 4.5 19.5C4.89782 19.5 5.27936 19.342 5.56066 19.0607C5.84196 18.7794 6 18.3978 6 18C6 17.6022 5.84196 17.2206 5.56066 16.9393C5.27936 16.658 4.89782 16.5 4.5 16.5ZM18 16.5C17.6022 16.5 17.2206 16.658 16.9393 16.9393C16.658 17.2206 16.5 17.6022 16.5 18C16.5 18.3978 16.658 18.7794 16.9393 19.0607C17.2206 19.342 17.6022 19.5 18 19.5C18.3978 19.5 18.7794 19.342 19.0607 19.0607C19.342 18.7794 19.5 18.3978 19.5 18C19.5 17.6022 19.342 17.2206 19.0607 16.9393C18.7794 16.658 18.3978 16.5 18 16.5ZM20.598 16.5H21.75C21.9489 16.5 22.1397 16.421 22.2803 16.2803C22.421 16.1397 22.5 15.9489 22.5 15.75V12.525C22.4997 12.3548 22.4415 12.1898 22.335 12.057L20.115 9.282C20.0449 9.19416 19.9559 9.12321 19.8546 9.0744C19.7533 9.02559 19.6424 9.00016 19.53 9H18V15C18.5266 15 19.0439 15.1386 19.5 15.4019C19.956 15.6652 20.3347 16.044 20.598 16.5Z'
        fill='currentColor'
      />
      <rect x='0.5' y='4.5' width='15' height='9' rx='1.5' stroke='#637381' />
      <path d='M3.5 6L3.5 12' stroke='#637381' strokeLinecap='round' />
      <path d='M8 12L8 6.5' stroke='#637381' strokeLinecap='round' />
      <path d='M10 8L8 6L6 8' stroke='#637381' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M13 6L13 12' stroke='#637381' strokeLinecap='round' />
    </svg>
  )
}
