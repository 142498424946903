import { createSvgIcon } from '@mui/material'

export const TruckIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M10.1879 17.5C10.1879 18.8807 9.04229 20 7.62916 20C6.21602 20 5.07045 18.8807 5.07045 17.5C5.07045 16.1193 6.21602 15 7.62916 15C9.04229 15 10.1879 16.1193 10.1879 17.5Z'
      fill='currentColor'
    />
    <path
      d='M4.04697 4C2.91646 4 2 4.89543 2 6V16C2 17.1046 2.91646 18 4.04697 18H4.08324C4.05934 17.8367 4.04697 17.6698 4.04697 17.5C4.04697 15.567 5.65077 14 7.62916 14C9.60754 14 11.2113 15.567 11.2113 17.5C11.2113 17.6698 11.199 17.8367 11.1751 18H13.2946C13.2707 17.8367 13.2583 17.6698 13.2583 17.5C13.2583 15.567 14.8621 14 16.8405 14C18.8189 14 20.4227 15.567 20.4227 17.5C20.4227 17.6538 20.4125 17.8053 20.3928 17.9539C21.4607 17.7263 22.1901 16.6888 21.9562 15.5921L20.2504 7.59209C20.0527 6.66465 19.2161 6 18.2465 6H16.3288C16.3288 4.89543 15.4123 4 14.2818 4H4.04697Z'
      fill='currentColor'
    />
    <path
      d='M19.3992 17.5C19.3992 18.8807 18.2536 20 16.8405 20C15.4274 20 14.2818 18.8807 14.2818 17.5C14.2818 16.1193 15.4274 15 16.8405 15C18.2536 15 19.3992 16.1193 19.3992 17.5Z'
      fill='currentColor'
    />
  </svg>,
  'TruckIcon',
)
