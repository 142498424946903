import { Box, Typography } from '@mui/material'

interface InformationCardProps {
  label: any
  value: string | number
}

export const InformationCard = ({ label, value }: InformationCardProps) => {
  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Typography sx={{ width: '50%' }} variant='subtitle2'>
        {label}
      </Typography>
      <Typography variant='body2'>{value}</Typography>
    </Box>
  )
}
