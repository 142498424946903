import { UserDto } from '@admin/app/api'
import { UserStore } from '@admin/stores/user.store'
import { action, computed, makeObservable, observable } from 'mobx'

export class UserUIStore {
  toBeRemoveUserId?: string
  searchUser?: string

  constructor(public store: UserStore) {
    makeObservable(this, {
      toBeRemoveUserId: observable,
      searchUser: observable,

      sortedData: computed,

      setToBeRemoveUserId: action,
      setSearchUser: action,
    })
  }

  setSearchUser(value?: string) {
    if (value !== this.searchUser) {
      this.searchUser = value
    }
  }

  get users() {
    if (!this.searchUser) return this.sortedData

    return this.sortedData.filter(
      x =>
        x.given_name?.toLowerCase().includes(this.searchUser!.toLowerCase()) ||
        x.family_name?.toLowerCase().includes(this.searchUser!.toLowerCase()) ||
        x.email?.toLowerCase().includes(this.searchUser!.toLowerCase()),
    )
  }

  get sortedData() {
    return [...this.store.data].sort((a, b) => (a.email ?? '').localeCompare(b.email ?? ''))
  }

  isUnique(dto: UserDto) {
    return (
      this.store.data.filter(
        e => dto.email.toUpperCase() == e.email.toUpperCase() && dto.user_id != e.user_id,
      ).length == 0
    )
  }

  public setToBeRemoveUserId(toBeRemoveUserId?: string) {
    this.toBeRemoveUserId = toBeRemoveUserId
  }
}
