import { Grid, List, ListItem, ListItemText, Typography } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { UpdateReleaseOrdersCommand } from '@planning/app/api'
import { CustomDateTimePicker } from '@planning/components/CustomDateTimePicker'
import { IReleaseOrderItem } from '@planning/rt-stores/releaseOrder/ReleaseOrderItem'
import orderService from '@planning/services/orderService'
import { useTranslate } from '@tolgee/react'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'

type Props = {
  selectedOrders: IReleaseOrderItem[]
  onClose: () => void
}

export const ReleaseOrderForm: FC<Props> = observer(({ selectedOrders, onClose }) => {
  const { appViewStore, drawerStore } = usePlanningStore()

  const { handleSubmit, control } = useForm({
    defaultValues: { releaseStart: dayjs(), releaseEnd: dayjs() },
  })
  const { t } = useTranslate()
  const onFormSubmit = async (data: any) => {
    const cmd: UpdateReleaseOrdersCommand = data
    cmd.ids = selectedOrders.filter(o => o.data.isOrder).map(o => parseInt(o.id))
    cmd.containerNos = selectedOrders.filter(o => !o.data.isOrder).map(o => o.data.containerNumber)
    cmd.isReleased = true
    try {
      await orderService.updateReleaseOrders(cmd)
      appViewStore.setShowAlert('success', t('orderMarkedAsReleased', 'Order marked as released'))
      onClose()
      drawerStore.close()
    } catch (error) {
      appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
    }
  }

  return (
    <form id='pick-up-order' onSubmit={handleSubmit(onFormSubmit)}>
      <Typography variant='subtitle1' marginTop='1rem'>
        {t('selectedContainers', 'Selected containers')}:
      </Typography>
      <Grid container direction='column' rowSpacing={{ xs: 1 }} marginTop='1rem'>
        <Grid item xs={2}>
          <List dense={true} sx={{ listStyleType: 'disc', pl: 4 }}>
            {selectedOrders.map(order => {
              return (
                <ListItem key={order.id} sx={{ display: 'list-item' }}>
                  <ListItemText primary={order.data.containerNumber} />
                </ListItem>
              )
            })}
          </List>
        </Grid>
        <Typography variant='subtitle1' marginTop='1rem' marginBottom='1rem'>
          {t('pickUpWindow', 'Pick-up window')}:
        </Typography>
        <Grid item xs={6} marginTop='1rem'>
          <Controller
            control={control}
            name={`releaseStart`}
            render={({ field: { onChange, value } }) => (
              <CustomDateTimePicker
                label={t('start', 'Start')}
                value={value.toString()}
                popperPlacement='left-start'
                onChange={newValue => {
                  if (newValue) {
                    onChange(newValue)
                  }
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} marginTop='1rem'>
          <Controller
            control={control}
            name={`releaseEnd`}
            render={({ field: { onChange, value } }) => (
              <CustomDateTimePicker
                label={t('end', 'End')}
                value={value.toString()}
                popperPlacement='left-start'
                onChange={newValue => {
                  if (newValue) {
                    onChange(newValue)
                  }
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </form>
  )
})
