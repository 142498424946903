import { GeneralCargoInventoryDto, GeneralCargoStockOccupancyDamageDto } from '@storage/app/api'
import { StockDto } from '@storage/types'
import _, { Dictionary } from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import { GeneralCargoInventoryStore } from './general-cargo-inventory.store'

export class GeneralCargoInventoryUIStore {
  searchQuery = ''
  isDamageDialogOpen = false
  occupancyDamages: GeneralCargoStockOccupancyDamageDto[] = []

  constructor(private readonly _generalCargoInventoryStore: GeneralCargoInventoryStore) {
    makeObservable(this, {
      searchQuery: observable,
      isDamageDialogOpen: observable,
      occupancyDamages: observable,

      setSearchQuery: action,
      openDialog: action,
      closeDialog: action,

      generalCargoInventory: computed,
      generalCargoStock: computed,
    })
  }

  get generalCargoInventory() {
    if (!this.searchQuery) return this._generalCargoInventoryStore.entries
    return this._generalCargoInventoryStore.entries.filter(inv =>
      this.matchesSearchQuery(inv, this.searchQuery),
    )
  }

  get generalCargoStock() {
    const stock = this.generalCargoInventory.reduce((prev, curr: GeneralCargoInventoryDto) => {
      for (let index = 0; index < curr.occupancies.length; index++) {
        const occupancy = curr.occupancies[index]

        const key = `${curr.customerId}_${curr.commodityId}_${curr.lotNumber ?? undefined}_${occupancy.package?.id ?? undefined}`
        if (!prev[key]) {
          const item: StockDto = {
            customerId: curr.customerId,
            customerName: curr.customer.name,
            commodityId: curr.commodityId,
            commodityName: curr.commodity.name,
            lotNumber: curr.lotNumber,
            packageId: occupancy.package?.id,
            packageName: occupancy.package?.name,
            weightUnit: occupancy.package?.weightUnit,
            IdentifiableItems: [...occupancy.properties.map(p => p.unitId)],
            totalQuantity: occupancy.quantity,
            locations: [`${occupancy.generalCargoArea} - ${occupancy.generalCargoLocation}`],
            occupancies: [occupancy],
          }
          prev[key] = item
        } else {
          prev[key] = {
            ...prev[key],
            totalQuantity: prev[key].totalQuantity + occupancy.quantity,
            IdentifiableItems: [
              ...prev[key].IdentifiableItems,
              ...occupancy.properties.map(p => p.unitId),
            ],
            locations: [
              ...prev[key].locations,
              `${occupancy.generalCargoArea} - ${occupancy.generalCargoLocation}`,
            ],
            occupancies: [...prev[key].occupancies, occupancy],
          }
        }
      }
      return prev
    }, {} as Dictionary<StockDto>)

    return _(stock).values().value()
  }

  setSearchQuery(searchQuery: string) {
    const trimmedSearchQuery = searchQuery.trim()
    if (this.searchQuery !== trimmedSearchQuery) {
      this.searchQuery = trimmedSearchQuery.toLocaleLowerCase()
    }
  }

  openDialog(damages: GeneralCargoStockOccupancyDamageDto[]) {
    this.isDamageDialogOpen = true
    this.occupancyDamages = damages
  }

  closeDialog() {
    this.isDamageDialogOpen = false
    this.occupancyDamages = []
  }

  private matchesSearchQuery(stock: GeneralCargoInventoryDto, query: string) {
    const stockUnitIds = stock.occupancies.flatMap(e =>
      e.properties.map(u => u.unitId.toLowerCase()),
    )

    return (
      (stock.commodity && stock.commodity.name.toLowerCase().includes(query)) ||
      (stock.customer && stock.customer.name.toLowerCase().includes(query)) ||
      (stock.lotNumber && stock.lotNumber?.toLowerCase().includes(query)) ||
      stockUnitIds.filter(e => e.includes(query)).length > 0
    )
  }
}
