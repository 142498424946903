import { Chip, SxProps, Theme } from '@mui/material'

export const FilterChip = ({
  label,
  variant,
  size,
  sx,
  handleFilterChipDelete,
}: {
  label: string
  variant?: 'filled' | 'outlined' | 'soft'
  size?: 'small' | 'medium'
  sx?: SxProps<Theme>
  handleFilterChipDelete?: () => void
}) => {
  return (
    <Chip
      label={label}
      variant={variant ?? 'filled'}
      sx={sx}
      size={size ? size : 'medium'}
      onDelete={handleFilterChipDelete}
    />
  )
}
