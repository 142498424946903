import { Box, Tooltip, Typography } from '@mui/material'
import { ControlledTextField } from '@storage/components/form-controls/ControlledTextField'
import { useTranslate } from '@tolgee/react'
import { BinIcon, IconButton, PlusIcon } from '@tom-ui/ui'
import { Control, FormState, useFieldArray } from 'react-hook-form'
import { BerthFormProfile, BollardsDto, defaultCleatMeterMarks } from './berth-form.profile'

interface Props {
  control: Control<BerthFormProfile>
  formState: FormState<BerthFormProfile>
  defaultValue: BollardsDto
}

const BerthCleatMeterMarks = ({ control, formState }: Props) => {
  const { t } = useTranslate()

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'bollards',
  })

  return (
    <Box>
      <Box sx={{ display: 'flex' }} gap={1} alignItems='center'>
        <Typography variant='subtitle2'>{t('Bollards', 'Bollards')}</Typography>
        <Tooltip title={t('Add a bollard', 'Add a bollard')}>
          <IconButton onClick={() => append(defaultCleatMeterMarks)}>
            <PlusIcon />
          </IconButton>
        </Tooltip>
      </Box>

      {fields.map((field, index) => (
        <Box
          key={field.id}
          sx={{
            display: 'flex',
            gap: 1,
            alignItems: 'flex-start',
            mb: index === fields.length - 1 ? 0 : 1,
          }}
        >
          <ControlledTextField
            name={`bollards.${index}.name`}
            formState={formState}
            control={control}
            label={t('Name', 'Name')}
          />
          <ControlledTextField
            name={`bollards.${index}.meterMark`}
            formState={formState}
            control={control}
            label={t('meterMark', 'Meter mark')}
            type='number'
            required
          />
          <IconButton
            disabled={fields.length === 1}
            sx={{ mt: 2, pointerEvents: 'all' }}
            color='error'
            onClick={() => remove(index)}
          >
            <Tooltip title={t('Remove bollard', 'Remove bollard')}>
              <BinIcon />
            </Tooltip>
          </IconButton>
        </Box>
      ))}

      {!fields.length && (
        <Typography variant='body2'>
          {t('No bollards to display', 'No bollards to display')}
        </Typography>
      )}
    </Box>
  )
}
export default BerthCleatMeterMarks
