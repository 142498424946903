import { VesselVisitDto, VesselVisitsApi } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'
import { Event, IEvent, IQueryHandler, IQueryMessage } from '../messageBus'

export class GetVesselVisitsByIdsQuery implements IQueryMessage {
  static type = 'GetVesselVisitsByIdsQuery'
  type = GetVesselVisitsByIdsQuery.type

  constructor(public ids: number[]) {}

  getKey = () => {
    return JSON.stringify(this)
  }
}

const httpClient = createApiClient(VesselVisitsApi)

export const getVesselVisitsByIdsQueryHandler: IQueryHandler<
  GetVesselVisitsByIdsQuery,
  IEvent<VesselVisitDto[]>
> = async query => {
  const { data } = await httpClient.getByIds(query.ids)

  return new Event(GetVesselVisitsByIdsQuery.type, data)
}
