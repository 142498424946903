import {
  GeneralCargoOutboundValidationResultDto,
  GeneralCargoStockDto,
  UpdateGeneralCargoStockOccupancyDto,
} from '@storage/app/api'
import { EntityStore } from '@storage/app/store/entity.store'
import { YardManagementHubConnection } from '@storage/hubs/yard-management.hub-connection'
import { GeneralCargoStockService } from '@storage/services/general-cargo-stock.service'
import { QueryParams, ValidationParams } from '../models/query-params'
import { GeneralCargoInventoryStore } from './general-cargo-inventory.store'

export class GeneralCargoStockStore extends EntityStore<GeneralCargoStockDto> {
  constructor(
    private readonly _generalCargoStockService: GeneralCargoStockService,
    private readonly _generalCargoInventoryStore: GeneralCargoInventoryStore,
    yardManagementHubConnection: YardManagementHubConnection,
  ) {
    super()

    yardManagementHubConnection.onGeneralCargoStockUpserted(stock => {
      this.addOrUpdate(stock)
      this._generalCargoInventoryStore.stockUpserted(stock)
    })

    yardManagementHubConnection.onGeneralCargoStockDeleted(stockId => {
      this.remove(stockId)
    })
  }

  public async loadAll() {
    const generalCargoStocks = await this._generalCargoStockService.getAll()
    this.setAll(generalCargoStocks)
  }

  public async loadByQuery({
    commodityId,
    customerId,
    lotNumber,
    packageId,
    withOccupancyOnly,
  }: QueryParams) {
    const generalCargoStocks = await this._generalCargoStockService.getByQuery({
      commodityId,
      customerId,
      lotNumber: lotNumber ? lotNumber : undefined,
      packageId,
      withOccupancyOnly,
    })
    this.setAll(generalCargoStocks)
  }

  public async loadByOrderId(orderId: number, cargoItemId?: number) {
    const generalCargoStock = await this._generalCargoStockService.getByOrderId(
      orderId,
      cargoItemId,
    )
    if (generalCargoStock) {
      this.setAll([generalCargoStock])
    } else {
      this.removeAll()
    }
  }

  public async putToStock(dto: UpdateGeneralCargoStockOccupancyDto) {
    const stock = await this._generalCargoStockService.updateOccupancy(dto, true)
    this.addOrUpdate(stock)
  }

  public async takeFromStock(dto: UpdateGeneralCargoStockOccupancyDto) {
    const stock = await this._generalCargoStockService.updateOccupancy(dto, false)
    if (stock.totalQuantity > 0) {
      this.addOrUpdate(stock)
    } else {
      this.remove(stock.id)
    }
  }

  public clearAll() {
    this.removeAll()
  }

  public async uploadDamageFile(file: File) {
    const fileName = await this._generalCargoStockService.uploadDamageFile(file)
    return fileName
  }

  public async validateOutboundRequest(
    params: ValidationParams,
  ): Promise<GeneralCargoOutboundValidationResultDto> {
    const validationResult = await this._generalCargoStockService.validateOutboundRequest(params)

    return validationResult
  }
}
