enum Permission {
  ReadVisits = 'read:visits',
  ReadWorkAreas = 'read:work-areas',
  ReadEquipments = 'read:equipments',
  WriteEquipments = 'write:equipments',
  EquipmentsAssignment = 'equipments-assignment',
  ReadJobs = 'read:jobs',
  StartFinishJobs = 'start-finish-jobs',
  ReadWorkInstructions = 'read:work-instructions',
  ConfirmWorkInstructions = 'confirm-work-instructions',
  ReadYardBlocks = 'read:yard-blocks',
  ReadReeferTemperatures = 'read:reefer-temperatures',
  WriteReeferTemperatures = 'write:reefer-temperatures',
}

export default Permission
