import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { SelectOption } from '@operations/app/models'
import { useLocation, useNavigate } from 'react-router'

interface Props {
  options: SelectOption[]
  dataCy?: string
}

export const Submenu = ({ options, dataCy }: Props) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const currentRoute =
    options.find(opt => pathname === opt.value.toString())?.value?.toString() ??
    options[0]?.value.toLocaleString() ??
    ''

  const handleChange = (event: SelectChangeEvent) => {
    if (event.target.value !== pathname) {
      navigate(event.target.value)
    }
  }

  return (
    <FormControl sx={{ minWidth: '15rem' }}>
      <Select value={currentRoute} onChange={handleChange} sx={{ height: '3rem' }} data-cy={dataCy}>
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
