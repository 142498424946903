import { action, computed, makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react-lite'
import { FC, ReactNode, createContext, useContext, useMemo } from 'react'

export interface INavigationStackStore {
  push(view: ReactNode): void
  pop(): void
  clear(): void
  replace(view: ReactNode): void
  currentView: ReactNode
  canGoBack: boolean
}

export class NavigationStackStore implements INavigationStackStore {
  viewStack: ReactNode[] = []
  constructor() {
    makeObservable(this, {
      viewStack: observable,
      currentView: computed,
      push: action,
      pop: action,
      clear: action,
      replace: action,
    })
  }

  replace = (view: ReactNode) => {
    this.viewStack.pop()
    this.push(view)
  }

  push = (view: ReactNode) => {
    this.viewStack.push(view)
  }

  pop = () => {
    this.viewStack.pop()
  }

  clear = () => {
    this.viewStack = []
  }

  get currentView() {
    return this.viewStack[this.viewStack.length - 1]
  }

  get canGoBack() {
    return this.viewStack.length > 0
  }
}

const NavigationContext = createContext<INavigationStackStore>(new NavigationStackStore())

export const useNavigationStore = () => useContext(NavigationContext)

export const NavigationStack: FC<{ children: ReactNode }> = observer(({ children }) => {
  const navigationStackStore = useMemo(() => new NavigationStackStore(), [])
  return (
    <NavigationContext.Provider value={navigationStackStore}>
      {navigationStackStore.currentView ?? children}
    </NavigationContext.Provider>
  )
})
