import { Button } from '@mui/material'
import { Box } from '@mui/system'
import { useTranslate } from '@tolgee/react'
import { Header, PlusIcon } from '@tom-ui/ui'
import GeneralCargoAreasTable from './components/GeneralCargoAreasTable'
import GeneralCargoAreasTableDialog from './components/GeneralCargoAreasTableDialog'
import { useStores } from '@storage/hooks/use-stores.hook'
import { observer } from 'mobx-react-lite'

const GeneralCargoAreas = observer(() => {
  const { t } = useTranslate()

  const { generalCargoAreaUIStore } = useStores()

  return (
    <>
      <Header
        title={t('generalCargoAreas', 'Areas')}
        searchInputLabel={t('searchForGCAreaOrLocation', 'Search for GC area or location')}
        displaySearchIcon
        enableSearchField
        onSearchFieldChange={value => generalCargoAreaUIStore.setSearchQuery(value)}
        rightRenderOption={
          <Box flex='none'>
            <Button
              color='inherit'
              variant='contained'
              startIcon={<PlusIcon />}
              onClick={() => generalCargoAreaUIStore.toggleDialog('Add')}
            >
              {t('addNew', 'Add new')}
            </Button>
          </Box>
        }
      />
      <GeneralCargoAreasTable />
      <GeneralCargoAreasTableDialog />
    </>
  )
})

export default GeneralCargoAreas
