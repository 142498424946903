import { matchPath, useLocation } from 'react-router-dom'

// ----------------------------------------------------------------------

type ReturnType = {
  active: boolean
  isExternalLink: boolean
}

export default function useActiveLink(path: string, deep = true): ReturnType {
  const { pathname } = useLocation()

  const normalActive = path ? !!matchPath({ path, end: true }, pathname) : false

  const deepActive = path ? !!matchPath({ path, end: false }, pathname) : false

  return {
    active: deep ? deepActive : normalActive,
    isExternalLink: path.includes('http'),
  }
}
