import { createSvgIcon } from '@mui/material'

export const AirSurfaceIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4 2C2.89543 2 2 2.89543 2 4V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V4C22 2.89543 21.1046 2 20 2H4ZM18.3321 14.6667H4.00033C4.00033 14.6667 5.2136 13.23 6.35104 13.23H10.2183L14.3131 8.66667H16.0572L14.0098 13.23C14.0098 13.23 17.5738 13.8216 18.8629 10.9484H20.0003L18.3321 14.6667Z'
      fill='currentColor'
    />
  </svg>,
  'AirSurfaceIcon',
)
