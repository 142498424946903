import { FormControlLabel, FormHelperText, Switch, SwitchProps } from '@mui/material'
import { ReactNode } from 'react'
import { Controller, FieldValues, FormState, UseControllerProps } from 'react-hook-form'

type FieldProps = Pick<SwitchProps, 'disabled'>

interface Props<T extends FieldValues> extends UseControllerProps<T>, FieldProps {
  formState: FormState<T>
  label: ReactNode
  labelPlacement?: 'start' | 'end'
  onChange?: (value: boolean) => Promise<void> | void
}

export const ControlledSwitch = <T extends FieldValues>({
  name,
  control,
  label,
  labelPlacement,
  disabled,
  formState: { errors },
  onChange,
}: Props<T>) => {
  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <FormControlLabel
            control={
              <Switch
                {...field}
                checked={field.value}
                disabled={disabled}
                onChange={(event, val) => {
                  if (onChange) {
                    onChange(val)
                  }

                  return field.onChange(val)
                }}
              />
            }
            labelPlacement={labelPlacement ?? 'start'}
            label={label}
            sx={{ marginLeft: 0 }}
          />
        )}
      />
      {errors[name] && <FormHelperText>{errors[name]?.message as string}</FormHelperText>}
    </>
  )
}
