import { getEnvName } from './get-env-name'

export interface ReactAppAuth0Keys {
  domain: string
  clientId: string
  audience: string
  scope?: string
}

export const getReactAppAuth0Keys = (): ReactAppAuth0Keys => {
  const currentEnvName = getEnvName()
  if (currentEnvName === 'dev') {
    return {
      domain: 'omoqo-tom-demo.eu.auth0.com',
      clientId: 'JPNBtgYSkBW6W9zAo6cv6WR5bREWNl70',
      audience: 'https://tom-api.demo.omoqo.io/',
    }
  }
  if (currentEnvName === 'stage') {
    return {
      domain: 'omoqo-tom-stage.eu.auth0.com',
      clientId: 'aR4YPmkacJnMYgBzTcg9uux83vOQIsoi',
      audience: 'https://tom-api.stage.omoqo.io/',
    }
  }
  if (currentEnvName === 'demo') {
    return {
      domain: 'omoqo-tom-dev.eu.auth0.com',
      clientId: 'aFkLfCQVCica3pWepJayfdrAWEu2VoUT',
      audience: 'https://tom-api.dev.omoqo.io/',
    }
  }
  return {
    domain: 'omoqo-tom-demo.eu.auth0.com',
    clientId: 'JPNBtgYSkBW6W9zAo6cv6WR5bREWNl70',
    audience: 'https://tom-api.demo.omoqo.io/',
  }
}
