import { PaginatedList } from '@planning/components/PaginatedList'
import { GateClerkTruckVisitEditStore } from '@planning/rt-stores/gateClerk/GateClerkTruckVisitEditStore'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { ContainerListCard } from './ContainerListCard'

interface Props {
  editStore: GateClerkTruckVisitEditStore
  onBack: () => void
}

export const TruckAppointmentOrderListMobile = observer(({ editStore, onBack }: Props) => {
  const { t } = useTranslate()

  return (
    <>
      <PaginatedList
        filterable
        paginatedStore={editStore.truckAppointmentOrderStore}
        hidePaginationControl
        startEmpty
        searchForResultsText={t('searchToViewContainers', 'Search to view containers')}
        searchPlaceholderText={t('containerNo.', 'Container No.')}
        isNoResults={() => !editStore.availableOrders.length}
      >
        {editStore.availableOrders.map(order => (
          <ContainerListCard
            order={order}
            direction={order.carrierVisitDirection}
            key={`order-${order.orderId}-${order.carrierVisitDirection}`}
            onClick={async () => {
              editStore.addOrder(order)
              editStore.setDirection(order.carrierVisitDirection)
              editStore.setIsSelectingOrder(false)
              onBack()
              await editStore.truckAppointmentOrderStore.setFilter()
            }}
          />
        ))}
      </PaginatedList>
    </>
  )
})
