import { ContainerMobile, StepperInfo, useMinimalsTheme } from '@tom-ui/ui'
import { ReactNode } from 'react'

interface Props {
  title: string
  steps?: string
  children: ReactNode
}
export const StepperContentWrapper = ({ title, steps, children }: Props) => {
  const theme = useMinimalsTheme()

  return (
    <>
      <StepperInfo title={title} steps={steps} mobile />
      <ContainerMobile sx={{ paddingY: theme.customSpacing.m }}>{children}</ContainerMobile>
    </>
  )
}
