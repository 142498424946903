import { makeObservable, observable, runInAction } from 'mobx'

export class AppStore {
  isLoading = false

  constructor() {
    makeObservable(this, {
      isLoading: observable,
    })
  }

  showLoader = () => {
    runInAction(() => {
      this.isLoading = true
    })
  }

  hideLoader = () => {
    runInAction(() => {
      this.isLoading = false
    })
  }
}
