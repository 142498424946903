import { CarrierVisitDirection, TruckVisitDto } from '@planning/app/api'
import _ from 'lodash'
import { computed, makeObservable } from 'mobx'
import { EntityItem } from '../base/EntityItem'
import { IOrderItem } from '../order/OrderItem'
import { ITruckItem } from '../truck/TruckItem'
import { TruckItemStore } from '../truck/TruckItemStore'
import { IEntity } from '../types'
import { TruckVisitItemStore } from './TruckVisitItemStore'

export interface ITruckVisitItem extends IEntity<TruckVisitDto> {
  orders: IOrderItem[]
  inboundOrders: IOrderItem[]
  outboundOrders: IOrderItem[]
  truck: ITruckItem | undefined
  arrival: string | null | undefined
  departure: string | null | undefined
}
export class TruckVisitItem extends EntityItem<TruckVisitDto> implements ITruckVisitItem {
  constructor(
    id: number,
    data: TruckVisitDto,
    private itemStore: TruckVisitItemStore,
    private truckStore?: TruckItemStore,
  ) {
    super(id, data)
    makeObservable(this, {
      orders: computed,
      inboundOrders: computed,
      outboundOrders: computed,
      truck: computed,
      arrival: computed,
      departure: computed,
    })
  }

  get orders() {
    return _.get(this.itemStore.ordersByTruckVisitId, this.id) ?? []
  }

  get inboundOrders() {
    return _.filter(this.orders, order => order.data.direction === CarrierVisitDirection.Inbound)
  }

  get outboundOrders() {
    return _.filter(this.orders, order => order.data.direction === CarrierVisitDirection.Outbound)
  }

  get truck() {
    return _.get(this.truckStore?.elements, this.data.carrierIds)
  }

  get arrival() {
    return this.data.ata ?? this.data.eta
  }

  get departure() {
    return this.data.atd ?? this.data.etd
  }
}
