import * as yup from 'yup'
import { SplitFormProfile } from './split-form.profile'

export const schema = (containerMin: number, containerMax: number): yup.Schema<SplitFormProfile> =>
  yup.object({
    containersAmount: yup
      .number()
      .label('Number of Containers')
      .min(containerMin)
      .max(containerMax)
      .required(),
    twinContainers: yup.boolean().label('Twin containers').required(),
    totalMoves: yup.number().label('Total moves').required(),
  })
