import { CarrierVisitDto, TruckVisitOrderDto, TruckVisitSignaturesDto } from '@billing/app/api'
import { CarrierVisitStore } from '@billing/stores/CarrierVisitStore'
import { CustomerStore } from '@billing/stores/CustomerStore'
import { TenantStore } from '@billing/stores/TenantStore'
import _ from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'

export class DeliveryNoteReportUIStore {
  selectedTruckVisit?: CarrierVisitDto | null
  truckVisitOrders?: TruckVisitOrderDto[]
  truckVisitSignatures?: TruckVisitSignaturesDto

  constructor(
    private carrierVisitStore: CarrierVisitStore,
    private customerStore: CustomerStore,
    private tenantStore: TenantStore,
  ) {
    makeObservable(this, {
      selectedTruckVisit: observable,
      truckVisitOrders: observable,
      truckVisitSignatures: observable,

      truckVisitsOptions: computed,
      customersFromTruckVisitOrders: computed,
      tenant: computed,

      setSelectedTruckVisit: action,
      setTruckVisitOrders: action,
      setTruckVisitSignatures: action,
    })
  }

  public get truckVisitsOptions(): CarrierVisitDto[] {
    return _(this.carrierVisitStore.items)
      .sortBy(wq => wq.ata)
      .value()
  }

  public get customersFromTruckVisitOrders() {
    if (this.truckVisitOrders?.length) {
      const customerNames = this.truckVisitOrders
        .filter(x => x.customerName)
        .map(x => x.customerName)

      return this.customerStore.items.filter(x => customerNames.includes(x.name))
    }

    return []
  }

  public get tenant() {
    return this.tenantStore.items[0]
  }

  setSelectedTruckVisit(visit?: CarrierVisitDto | null): void {
    this.selectedTruckVisit = visit
  }

  setTruckVisitOrders(dto?: TruckVisitOrderDto[]): void {
    this.truckVisitOrders = dto
  }

  setTruckVisitSignatures(dto?: TruckVisitSignaturesDto): void {
    this.truckVisitSignatures = dto
  }

  public resetData(): void {
    this.setSelectedTruckVisit()
  }
}
