import { Paper } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { FC, ReactElement } from 'react'

interface Props {
  children: ReactElement | ReactElement[]
  minHeight?: string
  p?: string | number
}
export const FullWidthFooter: FC<Props> = observer(({ children, p, minHeight }: Props) => {
  return (
    <Paper
      variant='elevation'
      elevation={2}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        p: p ?? '0.5rem',
        position: 'fixed',
        bottom: 0,
        width: '100%',
        minHeight: minHeight ? minHeight : '5rem',
      }}
    >
      {children}
    </Paper>
  )
})
