import { Box, styled } from '@mui/material'
import { useMinimalsTheme } from '../../../hooks'
import { ColorSchema } from '../../palette'

interface Props {
  size: string
  thickness?: number
  direction?: 'horizontal' | 'vertical'
  color?: ColorSchema
}
export const Timeline = ({ size, direction, color, thickness }: Props) => {
  const theme = useMinimalsTheme()
  const circleThickness = (thickness ?? 0) + 5 + 'px'
  const lineThickness = (thickness ?? 1) * 1 + 'px'

  const Circle = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette[color ?? 'secondary'].dark,
    height: circleThickness,
    width: circleThickness,
    flex: 'none',
    borderRadius: '50%',
  }))

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        ...(direction === 'vertical'
          ? {
              flexDirection: 'column',
              width: '10px',
              height: size,
            }
          : {
              flexDirection: 'row',
              width: size,
              height: '10px',
            }),
      }}
    >
      <Circle></Circle>
      <Box
        sx={{
          ...(direction === 'vertical'
            ? {
                width: lineThickness,
                height: size,
              }
            : {
                width: size,
                height: lineThickness,
              }),
          backgroundColor: theme.palette[color ?? 'secondary'].main,
        }}
      ></Box>
      <Circle></Circle>
    </Box>
  )
}
