import { Box, Typography } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { formatDateTime } from '@planning/utils'
import { FC } from 'react'

interface Props {
  message: string
  time: string
}

export const RailVisitTimeDetailsBox: FC<Props> = ({ message, time }) => {
  const theme = useMinimalsTheme()

  return (
    <Box
      sx={{
        p: '0.375rem',
        height: '100%',
        borderRadius: theme.customRadius.medium,
        bgcolor: theme.palette.info.lighter,
        color: theme.palette.info.dark,
      }}
    >
      <Typography variant='body2'>{message}</Typography>
      <Typography variant='subtitle2'>{formatDateTime(time)}</Typography>
    </Box>
  )
}
