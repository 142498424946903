import { Tooltip } from '@mui/material'
import { MoreIcon } from '../../icons'
import { IconButton } from './IconButton'
import { ButtonSize } from './button.model'

interface Props {
  tooltip: string
  size?: ButtonSize
  testId?: string
  onClick: () => void
}
export const MoreOptionsButton = ({ tooltip, size, testId, onClick }: Props) => {
  return (
    <Tooltip title={tooltip}>
      <IconButton
        data-cy={testId}
        color='default'
        size={size ?? 'medium'}
        onClick={() => onClick()}
      >
        <MoreIcon fontSize={size ?? 'medium'} />
      </IconButton>
    </Tooltip>
  )
}
