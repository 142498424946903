import { Box } from '@mui/material'
import { ReactNode } from 'react'
import { useMinimalsTheme } from '../../../hooks'

interface Props {
  children: ReactNode
}
export const DrawerContent = ({ children }: Props) => {
  const theme = useMinimalsTheme()
  return (
    <Box sx={{ overflowY: 'auto', maxHeight: '100%', padding: theme.customSpacing.m }}>
      {children}
    </Box>
  )
}
