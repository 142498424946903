import { ContainerLength, SummaryEntryDto } from '@billing/app/api'
import { Grid, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useTranslate } from '@tolgee/react'

interface Props {
  nameColumn: string
  valueColumn: string
  entries: SummaryEntryDto[]
}

export const ReportSummarySection = ({ nameColumn, valueColumn, entries }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const entryDescription = (summaryDto: SummaryEntryDto) => {
    const result = []
    if (summaryDto.isPluggedIn) {
      result.push(t('plugged', 'Plugged'))
    }

    if (summaryDto.isDangerous) {
      result.push(t('imdg', 'IMDG'))
    }

    if (result.length > 0) return result.join(' ')
    else return t('standard', 'Standard')
  }

  const entrySize = (summaryDto: SummaryEntryDto) => {
    if (summaryDto.type == ContainerLength.Twenty) {
      return '(20ft)'
    } else if (summaryDto.type == ContainerLength.Forty) {
      return '(40ft)'
    } else if (summaryDto.type == ContainerLength.FortyFive) {
      return '(45ft)'
    }
  }

  const entryNode = (summaryDto: SummaryEntryDto) => (
    <Stack
      direction='row'
      gap={theme.customSpacing.xxs}
      alignItems='center'
      paddingLeft={theme.customSpacing.l}
    >
      <Typography variant='body2'>{entryDescription(summaryDto)}</Typography>
      <Typography variant='caption' color='secondary'>
        {entrySize(summaryDto)}
      </Typography>
    </Stack>
  )

  return (
    <>
      {entries.length > 0 && (
        <>
          <Box padding={theme.customSpacing.m} sx={{ backgroundColor: theme.palette.grey[100] }}>
            <Grid container spacing={theme.customSpacing.m}>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>{nameColumn}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant='caption' color='secondary'>
                  {valueColumn}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box padding={theme.customSpacing.m}>
            <Grid container spacing={theme.customSpacing.m}>
              {entries.map((f, i) => (
                <>
                  <Grid item xs={4} key={`name-${nameColumn}-${i.toString()}`}>
                    {entryNode(f)}
                  </Grid>
                  <Grid item xs={8} key={`value-${nameColumn}-${i.toString()}`}>
                    <Typography variant='captionBold'>{f.amount}</Typography>
                  </Grid>
                </>
              ))}
            </Grid>
          </Box>
        </>
      )}
    </>
  )
}
