import { CarrierVisitsApi, NonNumericOrderDto } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'
import { Event, IEvent, IQueryHandler, IQueryMessage } from '../messageBus'

export class GetNnrOrdersByVisitIdQuery implements IQueryMessage {
  static type = 'GetNnrOrdersByVisitIdQuery'
  type = GetNnrOrdersByVisitIdQuery.type

  constructor(public visitId: number) {}

  getKey = () => {
    return JSON.stringify(this)
  }
}

const httpClient = createApiClient(CarrierVisitsApi)

export const getNnrOrdersByVisitIdQueryHandler: IQueryHandler<
  GetNnrOrdersByVisitIdQuery,
  IEvent<NonNumericOrderDto[]>
> = async query => {
  const { data } = await httpClient.nonNumericOrders(query.visitId)

  return new Event(GetNnrOrdersByVisitIdQuery.type, data)
}
