import { CustomerDto } from '@billing/app/api'
import { CustomerStore } from '@billing/stores/CustomerStore'
import { action, computed, makeObservable, observable } from 'mobx'

export class CustomerTableUIStore {
  searchCustomer = ''

  constructor(private customerStore: CustomerStore) {
    makeObservable(this, {
      searchCustomer: observable,

      customers: computed,

      setSearchCustomer: action,
    })
  }

  public setSearchCustomer(customer: string) {
    if (this.searchCustomer !== customer) {
      this.searchCustomer = customer
    }
  }

  public get customers(): CustomerDto[] {
    return [...this.customerStore.items]
      .sort(i => i.id)
      .filter(eq => this.doesCustomerHaveName(eq, this.searchCustomer))
  }

  private doesCustomerHaveName(customer: CustomerDto, name?: string) {
    return !name || customer.name.toLowerCase().includes(name.toLowerCase())
  }
}
