import { createSvgIcon } from '@mui/material'

export const HoldsIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M8.11098 8.111C7.07198 9.1495 6.49998 10.531 6.49998 12C6.49998 13.216 6.89698 14.368 7.62048 15.3195L15.3195 7.6205C14.367 6.897 13.216 6.5 12 6.5C10.5315 6.5 9.14998 7.0725 8.11098 8.111Z'
      fill='currentColor'
    />
    <path
      d='M12 17.5005C13.4685 17.5005 14.85 16.9285 15.8885 15.8895C16.9275 14.8505 17.5 13.4695 17.4995 12.001C17.4995 10.7855 17.1025 9.633 16.379 8.682L8.68098 16.38C9.63248 17.1035 10.784 17.5005 12 17.5005Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12 5C13.869 5 15.627 5.728 16.949 7.0505C18.272 8.3725 19 10.1305 19 12C19 13.8695 18.272 15.6275 16.949 16.949C15.6275 18.272 13.869 19 12 19C10.1305 19 8.37298 18.272 7.05048 16.949C5.72798 15.6275 4.99998 13.8695 4.99998 12C4.99998 10.1305 5.72798 8.3725 7.05048 7.0505C8.37248 5.728 10.1305 5 12 5Z'
      fill='currentColor'
    />
  </svg>,
  'HoldsIcon',
)
