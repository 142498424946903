import { HandlingDirection } from '@storage/app/api'
import { tolgee } from '@storage/app/translation'
import { v4 as uuidv4 } from 'uuid'
import { mapOccupancyItemsToUpdateGeneralCargoStockOccupancyDto } from '../mappers/general-cargo-storage-tracker.mapper'
import { GeneralCargoStorageTrackerUIStore } from '../stores/general-cargo-storage-tracker.ui-store'
import { GeneralCargoContextStrategy } from './general-cargo-context-strategy'
import { OccupancyItem, PlannedOccupancy } from './occupancy-item'

export class GeneralCargoPlanningStrategy implements GeneralCargoContextStrategy {
  constructor(
    private readonly _generalCargoStorageTrackerUIStore: GeneralCargoStorageTrackerUIStore,
  ) {}

  loadItems(_handlingDirection: HandlingDirection, generalCargoOrderId?: number) {
    this._generalCargoStorageTrackerUIStore.plannedOccupancies.clear()
    const generalCargoPlannedOccupancies =
      this._generalCargoStorageTrackerUIStore.generalCargoPlannedOccupancies

    if (!generalCargoOrderId || !generalCargoPlannedOccupancies?.length) {
      return
    }

    generalCargoPlannedOccupancies.forEach(plannedOccupancy => {
      this._generalCargoStorageTrackerUIStore.plannedOccupancies.set(plannedOccupancy.id, {
        id: plannedOccupancy.id,
        newQuantity: plannedOccupancy.quantity,
        locationName: plannedOccupancy.generalCargoLocation,
        areaName: plannedOccupancy.generalCargoArea,
        quantity: plannedOccupancy.quantity,
        // Recording damages & planning by unit properties r not relevant for GC reservation
        isDamaged: false,
        damageDescription: '',
        properties: [],
        damageImagePaths: [],
      })
    })

    // Choose default active occupancy item
    this._generalCargoStorageTrackerUIStore.activeOccupancyItemId =
      generalCargoPlannedOccupancies[0].id
  }

  addNewItem(generalCargoOrderId?: number) {
    const id = uuidv4()
    const defaultArea = this._generalCargoStorageTrackerUIStore.generalCargoAreas[0]
    if (defaultArea && generalCargoOrderId) {
      this._generalCargoStorageTrackerUIStore.plannedOccupancies.set(id, {
        id,
        newQuantity: 0,
        locationName: defaultArea.locations[0]?.name,
        areaName: defaultArea.name,
        quantity: 0,
        // Recording damages & planning by unit properties r not relevant for GC reservation
        isDamaged: false,
        damageDescription: '',
        properties: [],
        damageImagePaths: [],
      })
    }

    // Choose default active occupancy item
    this._generalCargoStorageTrackerUIStore.activeOccupancyItemId = id
  }

  updateItem<T extends keyof OccupancyItem>(id: string, key: T, value: OccupancyItem[T]) {
    const item = this._generalCargoStorageTrackerUIStore.plannedOccupancies.get(id)
    if (item) {
      item[key] = value as PlannedOccupancy[T]
    }
  }

  dropOffMessage() {
    return this._generalCargoStorageTrackerUIStore.occupancyItems.length
      ? tolgee.t('planDropOffInAnotherLocation', 'Plan drop off in another location')
      : tolgee.t('planDropOffInNewLocation', 'Plan drop off in a new location')
  }

  quantityAlertMessage(enteredQuantity: number, _handlingDirection: HandlingDirection) {
    const amount = this._generalCargoStorageTrackerUIStore.totalNewQuantity
    if (amount === enteredQuantity) {
      return undefined
    }
    switch (true) {
      case this._generalCargoStorageTrackerUIStore.totalNewQuantity > enteredQuantity:
        return tolgee.t(
          'amountGreaterThanOrderPlanned',
          'The entered amount exceeds the order planned amount',
        )

      case this._generalCargoStorageTrackerUIStore.totalNewQuantity === 0:
        return tolgee.t(
          'NoAmountHasBeenEntered',
          'No amount has been entered. Please specify it to proceed',
        )

      case this._generalCargoStorageTrackerUIStore.totalNewQuantity < enteredQuantity:
        return tolgee.t(
          'amountLowerThanOrderPlanned',
          'The entered amount is less than the order planned amount',
        )
    }
  }

  async onConfirm(
    handlingDirection: HandlingDirection,
    generalCargoOrderId: number,
    planDropOff: (dto: any) => Promise<void>,
    _putToStock: (dto: any) => Promise<void>,
    _takeFromStock: (dto: any) => Promise<void>,
    cargoItemId?: number,
  ): Promise<void> {
    // Planning only supported for inbound (drop off)
    if (handlingDirection !== HandlingDirection.Inbound) {
      return
    }

    await planDropOff(
      mapOccupancyItemsToUpdateGeneralCargoStockOccupancyDto(
        this._generalCargoStorageTrackerUIStore.occupancyItems,
        generalCargoOrderId,
        cargoItemId,
      ),
    )
  }
}
