import { Box, Stack, Typography } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'

interface Element {
  label: string
  value: number
  color: string
}

interface StackedProgressBarProps {
  elements: Element[]
}
const StackedProgressBar = ({ elements }: StackedProgressBarProps) => {
  const total = elements.reduce((acc, curr) => acc + curr.value, 0)
  const { palette } = useMinimalsTheme()
  return (
    <Stack spacing={1} data-cy='carrier-visit-status-quantity'>
      <Box sx={{ display: 'flex', borderRadius: '7px', overflow: 'hidden' }}>
        {elements.map((e, i) => (
          <Box
            key={i}
            style={{ height: 4.5, width: `${(e.value / total) * 100}%`, backgroundColor: e.color }}
          />
        ))}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {elements.map((e, i) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
            key={i}
          >
            <Box
              sx={{
                borderRadius: '50%',
                backgroundColor: e.color,
                width: 8,
                height: 8,
              }}
            />

            <Typography
              variant='body2'
              color={palette.grey[600]}
              data-test='stacked-progress-bar-value'
            >
              {e.value}
            </Typography>
            <Typography variant='body2' data-cy='stacked-progress-bar-label'>
              {e.label}
            </Typography>
          </Box>
        ))}
      </Box>
    </Stack>
  )
}

export default StackedProgressBar
