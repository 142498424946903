import { IssuesApi } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'

class IssueService {
  httpClient = createApiClient(IssuesApi)

  async getAll() {
    const { data } = await this.httpClient.getAll()
    return data
  }
}

const issueService = new IssueService()

export default issueService
