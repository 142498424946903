import { ContainerResponseDto, ContainersApi } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'
import { Event, IEvent, IQueryHandler, IQueryMessage } from '../messageBus'

export class GetContainerByIdQuery implements IQueryMessage {
  static type = 'GetContainerByIdQuery'
  type = GetContainerByIdQuery.type

  constructor(public id: number) {}

  getKey = () => {
    return JSON.stringify(this)
  }
}

const httpClient = createApiClient(ContainersApi)

export const getContainerByIdQueryHandler: IQueryHandler<
  GetContainerByIdQuery,
  IEvent<ContainerResponseDto>
> = async query => {
  const { data } = await httpClient.getById(query.id)

  return new Event(GetContainerByIdQuery.type, data)
}
