import { useBillingServiceStore } from '@billing/AppProvider'
import { ControlledCheckbox } from '@billing/components/form-controls'
import useFormWithSchema from '@billing/hooks/use-form-with-schema.hook'
import { Box, Button } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { useEffect, useMemo } from 'react'
import { CTReportFieldsFormProfile } from './ct-report-fields-form.profile'
import { schema } from './ct-report-fields-form.schema'

const CTReportFieldsForm = () => {
  const { containerTurnoversUIStore } = useBillingServiceStore()

  const { setValue, formState, control, watch } = useFormWithSchema<CTReportFieldsFormProfile>(
    schema,
    containerTurnoversUIStore.fieldsToExport,
  )

  const formValues = watch()

  const { t } = useTranslate()

  const fieldNames = useMemo(
    () =>
      Object.keys(containerTurnoversUIStore.fieldsToExport) as (keyof CTReportFieldsFormProfile)[],
    [containerTurnoversUIStore.fieldsToExport],
  )

  const fieldLabels: Record<keyof CTReportFieldsFormProfile, string> = {
    includeContainerNumber: t('containerNumber', 'Container number'),
    includeIsoCode: t('isoCode', 'ISO code'),
    includeOperator: t('operator', 'Operator'),
    includeGrossWeight: t('grossWeight', 'Gross weight'),
    includeIsOOG: t('isOOG', 'Is OOG'),
    includeIsReefer: t('isReefer', 'Is reefer'),
    includeIsEmpty: t('isEmpty', 'Is empty'),
    includeIsDangerous: t('isDangerous', 'Is dangerous'),
    includeEntryDateTime: t('entryDateTime', 'Entry date'),
    includeIsDamaged: t('isDamaged', 'Is damaged'),
  }

  const selectAll = () => {
    fieldNames.forEach(fieldName => {
      setValue(fieldName, true)
    })
  }

  const unselectAll = () => {
    fieldNames.forEach(fieldName => {
      if (fieldName !== 'includeContainerNumber') {
        setValue(fieldName, false)
      }
    })
  }

  useEffect(() => {
    containerTurnoversUIStore.setFieldsToExport(formValues)
  }, [formValues, containerTurnoversUIStore])

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          mb: 1,
        }}
      >
        <Button size='small' variant='outlined' onClick={selectAll}>
          {t('selectAll', 'Select all')}
        </Button>
        <Button size='small' variant='outlined' onClick={unselectAll}>
          {t('unselectAll', 'Unselect all')}
        </Button>
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 300px)',
        }}
        component='form'
        noValidate
        autoComplete='off'
      >
        {fieldNames.map((fieldName: keyof CTReportFieldsFormProfile) => (
          <ControlledCheckbox
            key={fieldName}
            formState={formState}
            control={control}
            label={fieldLabels[fieldName]}
            name={fieldName}
            disabled={fieldName === 'includeContainerNumber'}
          />
        ))}
      </Box>
    </Box>
  )
}

export default CTReportFieldsForm
