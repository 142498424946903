import { CustomersApi } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'

class CustomerService {
  httpClient = createApiClient(CustomersApi)

  async getByPartialName(filter: string) {
    const { data } = await this.httpClient.getByPartialName(filter)

    return data
  }

  async getById(id: number) {
    const { data } = await this.httpClient.getById(id)

    return data
  }
}

const customerService = new CustomerService()

export default customerService
