import { Stack, Typography } from '@mui/material'
import { CommodityResponseDto } from '@planning/app/api'
import { CommodityAutoComplete } from '@planning/components/commodity/CommodityAutoComplete'
import { PackageControl } from '@planning/pages/Order/components/GeneralCargo/PackageControl'
import { CommodityAutocompleteViewStore } from '@planning/stores/commodity/CommodityAutocompleteViewStore'
import { PackageDto } from '@planning/stores/generalCargo/PackageDto'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
// import { PackageControl } from './PackageControl'

interface IProps {
  packageOptions: PackageDto[]
  onCommodityChange: (commodity?: CommodityResponseDto) => void
}

export const StrippingOrderCommoditySelection: FC<IProps> = observer(
  ({ packageOptions, onCommodityChange }) => {
    const { t } = useTranslate()
    const {
      control,
      formState: { errors },
    } = useFormContext()

    const [commodity, setCommodity] = useState<CommodityResponseDto | undefined>()
    const commodityAutocompleteStore = useMemo(() => new CommodityAutocompleteViewStore(), [])

    useEffect(() => {
      const fetch = async () => {
        await commodityAutocompleteStore.fetch(commodityAutocompleteStore.filter)
      }

      fetch().catch(error => console.log(error.message))
    }, [commodityAutocompleteStore])

    return (
      <Stack gap={1}>
        <Typography variant='subtitle1'>{t('cargo', 'Cargo')}</Typography>
        <Stack gap={2}>
          <Controller
            control={control}
            name='commodity'
            rules={{ required: true }}
            render={({ field: { onChange } }) => (
              <CommodityAutoComplete
                store={commodityAutocompleteStore}
                selectedCommodity={commodity}
                handleOnChange={commodity => {
                  onChange(commodity)
                  setCommodity(commodity)
                  onCommodityChange(commodity)
                }}
                error={!!errors.commodity}
                helperText={errors.commodity ? t('fieldIsRequired', 'Field is required.') : ''}
              />
            )}
          />

          <PackageControl packageOptions={packageOptions} />
        </Stack>
      </Stack>
    )
  },
)
