import { useBillingServiceStore } from '@billing/AppProvider'
import { useTranslate } from '@tolgee/react'
import { BinIcon, ConfirmationDialog, IconButton } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { CustomerForm } from '../components/form/CustomerForm'
import { CustomerFormMapper } from '../components/form/customer-form.mapper'
import { CustomerFormProfile } from '../components/form/customer-form.profile'

export const CustomersDialogContainer = observer(() => {
  const { t } = useTranslate()
  const { appStore, customerStore, customerDialogUIStore } = useBillingServiceStore()

  const handleSubmit = async (formValues: CustomerFormProfile) => {
    if (customerDialogUIStore.formType === 'Add') {
      appStore.showAlertForCancellableRequest(
        'success',
        `${formValues.name} ${t('isBeingCreated', 'is being created')}`,
        () =>
          appStore.triggerRequestWithoutLoader(
            async () =>
              await customerStore.add(CustomerFormMapper.mapFormValuesToCreateDto(formValues)),
          ),
        `${formValues.name} ${t('creationCancelled', 'creation cancelled')}`,
      )
    } else {
      appStore.showAlertForCancellableRequest(
        'success',
        `${formValues.name} ${t('isBeingUpdated', 'is being updated')}`,
        () =>
          appStore.triggerRequestWithoutLoader(
            async () =>
              await customerStore.update(CustomerFormMapper.mapFormValuesToUpdateDto(formValues)),
          ),
        `${formValues.name} ${t('updateCancelled', 'update cancelled')}`,
      )
    }

    //pass in customerDialogUIStore.editingEntity?.id is a workaround for title edit change to add before popup closing
    customerDialogUIStore.toggle(customerDialogUIStore.editingEntity?.id)
  }

  const handleClose = () => {
    //pass in customerDialogUIStore.editingEntity?.id is a workaround for title edit change to add before popup closing
    customerDialogUIStore.toggle(customerDialogUIStore.editingEntity?.id)
  }

  const handleTogleDelete = () => {
    customerDialogUIStore.toggle(customerDialogUIStore.editingEntity?.id)
    customerDialogUIStore.toggleDelete()
  }

  const handleCloseDelete = () => {
    customerDialogUIStore.toggleDelete()
  }

  const handleDelete = async () => {
    if (customerDialogUIStore.editingEntity) {
      const customerName = customerDialogUIStore.editingEntity.name
      await customerStore.delete(customerDialogUIStore.editingEntity.id)
      handleCloseDelete()

      appStore.setShowAlert('success', `${customerName} ${t('deleted', 'deleted')}`)
    }
  }

  return (
    <>
      <ConfirmationDialog
        open={customerDialogUIStore.isOpen}
        title={`${
          customerDialogUIStore.formType === 'Add' ? t('addNew', 'Add new') : t('edit', 'Edit')
        } ${t('customer', 'Customer')}`}
        primaryActionText={t('save', 'Save')}
        closeLabel={t('cancel', 'Cancel')}
        formId='customer-form'
        maxWidth='sm'
        rightRenderOption={
          customerDialogUIStore.formType === 'Edit' ? (
            <IconButton variant='contained' color='default' onClick={handleTogleDelete}>
              <BinIcon />
            </IconButton>
          ) : undefined
        }
        onClose={handleClose}
        hasBorderTop
      >
        <CustomerForm
          id='customer-form'
          customer={customerDialogUIStore.editingEntity}
          onSubmit={handleSubmit}
        />
      </ConfirmationDialog>

      <ConfirmationDialog
        open={customerDialogUIStore.isDeleteOpen}
        title={t('deleteCustomer', 'Delete customer')}
        message={t(
          'entityWillBeDeleted',
          '{name} will be deleted and you cannot undo this action!',
          {
            name: customerDialogUIStore.editingEntity?.name ?? t('customer'),
          },
        )}
        primaryActionText={t('delete', 'Delete')}
        closeLabel={t('keepEditing', 'Keep editing')}
        maxWidth='xs'
        blockClickOutside
        onConfirm={handleDelete}
        onClose={handleTogleDelete}
      />
    </>
  )
})
