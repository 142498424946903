import {
  AllocationRuleSummaryDto,
  CarrierVisitAllocationRuleDto,
  CarrierVisitAllocationRulePropertiesDto,
  CarrierVisitDirection,
  CarrierVisitsApi,
  CarrierVisitsCreateCarrierVisitAllocationAndRulesRequest,
  CarrierVisitsCreateCarrierVisitAllocationRuleRequest,
  CarrierVisitsUpdateCarrierVisitAllocationRulesRequest,
} from '@storage/app/api'

export class CarrierVisitsService {
  constructor(private readonly _carrierVisitsApi: CarrierVisitsApi) {}

  async getAllocationRules(
    carrierVisitId: number,
    direction?: CarrierVisitDirection,
  ): Promise<Array<CarrierVisitAllocationRuleDto>> {
    const { data: allocationRules } = await this._carrierVisitsApi.getAllocationRules({
      carrierVisitId,
      direction,
    })
    return allocationRules
  }

  async createCarrierVisitAllocationAndRules(
    carrierVisitsCreateCarrierVisitAllocationAndRulesRequest?: CarrierVisitsCreateCarrierVisitAllocationAndRulesRequest,
  ): Promise<void> {
    await this._carrierVisitsApi.createCarrierVisitAllocationAndRules({
      carrierVisitsCreateCarrierVisitAllocationAndRulesRequest,
    })
  }

  async updateCarrierVisitAllocationRules(
    carrierVisitsUpdateCarrierVisitAllocationRulesRequest: CarrierVisitsUpdateCarrierVisitAllocationRulesRequest,
  ): Promise<CarrierVisitAllocationRuleDto> {
    const { data: allocationRule } = await this._carrierVisitsApi.updateCarrierVisitAllocationRules(
      {
        carrierVisitsUpdateCarrierVisitAllocationRulesRequest,
      },
    )
    return allocationRule
  }

  async createCarrierVisitAllocationRule(
    carrierVisitsCreateCarrierVisitAllocationRuleRequest: CarrierVisitsCreateCarrierVisitAllocationRuleRequest,
  ): Promise<CarrierVisitAllocationRuleDto> {
    const { data: allocationRule } = await this._carrierVisitsApi.createCarrierVisitAllocationRule({
      carrierVisitsCreateCarrierVisitAllocationRuleRequest,
    })
    return allocationRule
  }

  async getAllocationSummary(
    carrierVisitAllocationRulePropertiesDto?: Array<CarrierVisitAllocationRulePropertiesDto>,
  ): Promise<Array<AllocationRuleSummaryDto>> {
    const { data: allocationRuleSummaries } = await this._carrierVisitsApi.allocationSummary({
      carrierVisitAllocationRulePropertiesDto,
    })
    return allocationRuleSummaries
  }

  async deleteAllocationRules(id: string) {
    await this._carrierVisitsApi.deleteAllocationRules({ id })
  }
}
