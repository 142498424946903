import { DangerousGoodsStackingRuleDto } from '@storage/app/api'
import { EntityStore } from '@storage/app/store/entity.store'
import { DGStackingRuleService } from '@storage/services/dg-stacking-rule.service'

export class DGStackingRuleStore extends EntityStore<DangerousGoodsStackingRuleDto> {
  constructor(private readonly _dgStackingRuleService: DGStackingRuleService) {
    super()
  }

  public async loadAll(): Promise<void> {
    const dGStackingRules = await this._dgStackingRuleService.getAll()
    this.setAll(dGStackingRules)
  }

  public getById(id: string): DangerousGoodsStackingRuleDto | undefined {
    const parentRule = this.data.get(id)
    if (parentRule) {
      return parentRule
    }

    for (const parentRule of this.data.values()) {
      if (parentRule.subClasses) {
        const childRule = parentRule.subClasses.find(child => child.id === id)
        if (childRule) {
          return childRule
        }
      }
    }

    return
  }

  public async update(
    isParentClass: boolean,
    id: string,
    isStackingContainerOnTopAllowed: boolean,
    maxStack?: number | null,
  ): Promise<void> {
    if (isParentClass) {
      await this._dgStackingRuleService.updatePrimaryImoClassStackingRule({
        id,
        isStackingContainerOnTopAllowed,
        maxStack,
      })
    } else {
      await this._dgStackingRuleService.updateSecondaryImoClassStackingRule({
        id,
        isStackingContainerOnTopAllowed,
        maxStack,
      })
    }
  }
}
