import { ReactNode } from 'react'
import { Draggable, DraggableStateSnapshot } from 'react-beautiful-dnd'
import { useMinimalsTheme } from '../../../hooks'
import { GroupingContextAction } from './GroupingContextMenu'
import { GroupingNodeItem } from './GroupingNodeItem'
import { Group, Id, Item } from './types'

const primaryButton = 0

interface Props {
  group: Group
  item: Item
  index: number
  isSelected: boolean
  isGhosting: boolean
  selectionCount: number
  actions?: GroupingContextAction[]
  toggleSelection: (itemId: Id) => void
  toggleSelectionInGroup: (itemId: Id) => void
  multiSelectTo: (itemId: Id) => void
  renderData?: (item: Item, group: Group) => ReactNode
}

const GroupingItem = ({
  group,
  item,
  index,
  isSelected,
  isGhosting,
  selectionCount,
  actions,
  toggleSelection,
  toggleSelectionInGroup,
  multiSelectTo,
  renderData,
}: Props) => {
  const { palette } = useMinimalsTheme()
  const onKeyDown = (event: KeyboardEvent, snapshot: DraggableStateSnapshot) => {
    if (event.defaultPrevented) {
      return
    }

    if (snapshot.isDragging) {
      return
    }

    event.preventDefault()

    performAction(event)
  }

  const onClick = (event: MouseEvent) => {
    if (event.defaultPrevented) {
      return
    }

    if (event.button !== primaryButton) {
      return
    }

    event.preventDefault()

    performAction(event)
  }

  const onTouchEnd = (event: TouchEvent) => {
    if (event.defaultPrevented) {
      return
    }

    event.preventDefault()
    toggleSelectionInGroup(item.id)
  }

  const wasToggleInSelectionGroupKeyUsed = (event: MouseEvent | KeyboardEvent) => {
    //Check if using windows or mac
    const isUsingWindows = navigator.userAgent.indexOf('Win') >= 0
    return isUsingWindows ? event.ctrlKey : event.metaKey
  }

  const wasMultiSelectKeyUsed = (event: MouseEvent | KeyboardEvent) => event.shiftKey

  const performAction = (event: MouseEvent | KeyboardEvent) => {
    if (wasToggleInSelectionGroupKeyUsed(event)) {
      toggleSelectionInGroup(item.id)
      return
    }

    if (wasMultiSelectKeyUsed(event)) {
      multiSelectTo(item.id)
      return
    }

    toggleSelection(item.id)
  }

  return (
    <Draggable key={`grp-dbid-${item.id}`} draggableId={item.id} index={index}>
      {(provided, snapshot) => {
        return GroupingNodeItem({
          provided,
          item,
          group,
          palette,
          selectionCount,
          isDragging: snapshot.isDragging,
          isGhosting,
          isSelected,
          snapshot,
          actions,
          renderData,
          onClick,
          onTouchEnd,
          onKeyDown,
        })
      }}
    </Draggable>
  )
}

export default GroupingItem
