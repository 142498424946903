import Permission from '@admin/app/models/permission.enum'
import { AssignRoleDialog } from '@admin/components/AssignRoleDialog'
import { ConfirmationDialog } from '@tom-ui/ui'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useAdminStore } from '@admin/AppProvider'

export const UserDialogContainer = observer(() => {
  const { t } = useTranslate()
  const { userStore, userUIStore, userDialogUIStore, roleStore, tenantStore, authStore } =
    useAdminStore()

  const canManageTenant = authStore.hasPermission(Permission.WriteTenants)

  const handleDelete = async () => {
    await userUIStore.store.delete(userUIStore.toBeRemoveUserId!)

    userUIStore.setToBeRemoveUserId()
  }

  const handleClose = () => {
    userDialogUIStore.toggleAssignRoles()
  }

  useEffect(() => {
    const pageData = async () => {
      const requests = [userUIStore.store.load()]

      if (canManageTenant) {
        requests.push(tenantStore.load())
      }

      await Promise.all(requests)
    }
    pageData()
  }, [canManageTenant, tenantStore, userUIStore.store])

  useEffect(() => {
    const rolesData = async () => {
      await roleStore.load()
    }
    rolesData()
  }, [roleStore])

  return (
    <>
      <AssignRoleDialog
        open={userDialogUIStore.assignRolesOpen}
        selectedRoles={userStore.selectedUserRoles.map(role => role.id)}
        onClose={handleClose}
        userId={userDialogUIStore.userId!}
      />

      <ConfirmationDialog
        open={!!userUIStore.toBeRemoveUserId}
        title={t('youAreAboutToRemoveAnUser', 'You are about to remove an user')}
        message={t('thisActionWillRemoveThisUser', 'This action will remove this user.')}
        primaryActionText={t('remove', 'Remove')}
        closeLabel={t('close', 'Close')}
        onClose={() => userUIStore.setToBeRemoveUserId()}
        onConfirm={handleDelete}
      />
    </>
  )
})
