import { Box, Grid } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import {
  FullScreenDrawer,
  HeaderWithActions,
  ServiceOrdersSearchList,
  usePlanningStore,
} from '@tom-ui/planning'
import { generalCargoStockService } from '@tom-ui/storage'
import { reaction } from 'mobx'
import { observer } from 'mobx-react-lite'
import {
  ServiceOrdersType,
  ServiceOrdersViewStore,
} from 'modules/planning/src/pages/ServiceOrders/Stores/ServiceOrdersViewStore'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { CreateServiceOrderButton } from './Components/CreateServiceOrderButton'
import { CreateServiceOrderDialog } from './Components/CreateServiceOrderDialog'

export const ServiceOrdersPage = observer(() => {
  const { t } = useTranslate()
  const { serviceOrdersViewStore: store, containerAutocompleteStore } = usePlanningStore()

  const navigate = useNavigate()
  const [isInitialized, setIsInitialized] = useState(false)

  function connect(store: ServiceOrdersViewStore) {
    return reaction(
      () => store.selectedContainerJourney,
      () => {
        const firstCargoItem = store.selectedContainerJourney?.stuffingOrder?.packingList[0]

        if (!firstCargoItem) return
        // [Stuffing] TODO: the customerId does not necessarily match the Id in our system (rather referenceId)
        // This leads to the PackingList not being loaded for customers with Id != ReferenceId
        generalCargoStockService
          .getByQuery({
            customerId: firstCargoItem.customerId, //todo [stuffing] check logic of customerId
            withOccupancyOnly: true,
          })
          .then(stocks => {
            store.updateSelectedStuffingCommodityQuantities(stocks)
          })
      },
    )
  }

  const handleSelectServiceType = (serviceType: ServiceOrdersType) => {
    containerAutocompleteStore.reset()
    store.setMainServiceType(serviceType)
  }

  useEffect(() => {
    const fetchData = async () => {
      await store.fetchPackages()
    }

    containerAutocompleteStore.reset()
    if (!store.selectedContainerJourney) store.reset()
    fetchData()

    const reaction = connect(store)

    setIsInitialized(true)

    return reaction
  }, [store, containerAutocompleteStore])

  return isInitialized ? (
    <Box sx={{ paddingBottom: '16px', minHeight: '100%' }}>
      <HeaderWithActions
        title={t('serviceOrders', 'Service orders')}
        actions={[
          <CreateServiceOrderButton
            key='create-service-order-button'
            onSelectServiceType={handleSelectServiceType}
          />,
        ]}
      />

      <Grid container padding={'0 1.5rem '}>
        <Grid item xs={12}>
          <ServiceOrdersSearchList store={store} />
        </Grid>
      </Grid>

      <FullScreenDrawer
        anchor='right'
        open={store.mainServiceType !== null}
        onClose={() => {
          store.reset()
        }}
      >
        <CreateServiceOrderDialog
          store={store}
          onClose={() => {
            const { callbackUrlOnClose } = store
            store.reset()
            containerAutocompleteStore.reset()
            if (callbackUrlOnClose) {
              navigate(callbackUrlOnClose)
            }
          }}
        />
      </FullScreenDrawer>
    </Box>
  ) : (
    <></>
  )
})
