import { usePlanningStore } from '@planning/AppProvider'
import { OrderStatus } from '@planning/app/api'
import { IOrderItem } from '@planning/rt-stores/order/OrderItem'
import { IFilterDelegate } from '@planning/stores/PaginatedLocalStore'
import { entitySortDelegate } from '@planning/stores/entitySortDelegate'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { DeliveryOrderTable } from './Components/DeliveryOrderTable'

export const deliveryOrdersSortingDelegate = entitySortDelegate('referenceNumber')

export const deliveryOrdersFilterDelegate: IFilterDelegate<IOrderItem> = (
  filter: string,
  item: IOrderItem,
  showCompleted?: boolean,
  __?: Date,
  ___?: Date,
  filterStatus?: string,
) => {
  const filterableProperties: string[] = [
    'data.referenceNumber',
    'data.containerNumber',
    'data.containerIsoCode',
    'data.grossWeight',
    'data.status',
  ]

  if (!showCompleted && item.data.status === OrderStatus.Fulfilled) return false

  if (filterStatus && item.data.status !== filterStatus) {
    return false
  }

  if (filter !== '')
    return filterableProperties
      .map(p => p as keyof IOrderItem)
      .some(p => {
        const prop = _.get(item, p)
        if (typeof prop === 'number') return prop === parseFloat(filter)
        else if (typeof prop === 'string')
          return (prop as string).toLowerCase().includes(filter.toLowerCase())
      })

  return true
}

export const DeliveryOrdersPage = observer(() => {
  const { deliveryOrderViewStore } = usePlanningStore()

  useEffect(() => {
    deliveryOrderViewStore.loadCurrentPage()
  }, [deliveryOrderViewStore])

  return <DeliveryOrderTable viewStore={deliveryOrderViewStore} />
})
