import { NavigationStack } from '@tom-ui/planning'
import { observer } from 'mobx-react-lite'
import { TallyclerkHomePage } from './TallyclerkHomePage'

export const TallyclerkPage = observer(() => {
  return (
    <NavigationStack>
      <TallyclerkHomePage />
    </NavigationStack>
  )
})
