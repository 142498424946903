import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import moment from 'moment'
import { useMemo } from 'react'
import { IPaginatedStoreWithItems, SortingModel } from './PaginatedStore'

export type ISortDelegate<T> = (sortingModel: SortingModel<T>, a: T, b: T) => number
export type IFilterDelegate<T> = (
  filter: string,
  item: T,
  showCompleted?: boolean,
  from?: Date,
  to?: Date,
  filterStatus?: string,
) => boolean

export interface ILocalDataStore<T> {
  items: T[]
  fetch: (query: any) => Promise<void>
}

export class LocalDataStoreWrapper<T> implements ILocalDataStore<T> {
  constructor(
    private getFunc: () => T[],
    private fetchFunc?: (query: any) => Promise<void>,
  ) {
    makeObservable(this, {
      items: computed,
    })
  }

  get items() {
    return this.getFunc()
  }

  fetch = async (query: any) => {
    return this.fetchFunc?.(query)
  }
}

export const useWrappedLocalPagination = <T>(
  getFunc: () => T[],
  fetchFunc?: (query: any) => Promise<void>,
  sortDelegate?: ISortDelegate<T>,
  filterDelegate?: IFilterDelegate<T>,
): IPaginatedStoreWithItems<T> => {
  return useMemo(
    () =>
      new PaginatedLocalStore<T>(
        new LocalDataStoreWrapper(getFunc, fetchFunc),
        sortDelegate,
        filterDelegate,
      ),
    [getFunc, fetchFunc, sortDelegate, filterDelegate],
  )
}

export const useLocalPagination = <T>(
  dataStore: ILocalDataStore<T>,
  sortDelegate?: ISortDelegate<T>,
  filterDelegate?: IFilterDelegate<T>,
): IPaginatedStoreWithItems<T> => {
  return useMemo(
    () => new PaginatedLocalStore<T>(dataStore, sortDelegate, filterDelegate),
    [dataStore, sortDelegate, filterDelegate],
  )
}

export class PaginatedLocalStore<T> implements IPaginatedStoreWithItems<T> {
  currentPageIndex = 0
  pageSize = 25
  filter?: string
  isCreateDialogOpen = false
  filterStatus?: string
  showCompleted = false
  sortingModel = {
    field: '',
    orderBy: undefined,
    isDescending: false,
  } as SortingModel<T>
  from = moment().add(-5, 'd').startOf('d').toDate()
  to = moment().add(5, 'd').endOf('d').toDate()
  isInitializing = true

  constructor(
    public dataStore: ILocalDataStore<T>,
    private sortDelegate?: ISortDelegate<T>,
    private filterDelegate?: IFilterDelegate<T>,
  ) {
    makeObservable(this, {
      currentPageIndex: observable,
      pageSize: observable,
      // TODO rename filter / filterStatus to be more distinct
      filter: observable,
      filterStatus: observable,
      isCreateDialogOpen: observable,
      showCompleted: observable,
      sortingModel: observable,
      from: observable,
      to: observable,
      isInitializing: observable,

      filteredItems: computed,
      pageItems: computed,
      totalCount: computed,

      setCurrentPageIndex: action,
      setPageSize: action,
      setFilter: action,
      setFilterStatus: action,
      setCreateDialogOpen: action,
      loadCurrentPage: action,
      toggleShowCompleted: action,
      setSortingModel: action,
      setDateRange: action,
      setIsInitializing: action,
    })
  }

  get filteredItems() {
    let items = this.dataStore.items

    if (this.filterDelegate) {
      items = items.filter(item =>
        this.filterDelegate!(
          this.filter ?? '',
          item,
          this.showCompleted,
          this.from,
          this.to,
          this.filterStatus,
        ),
      )
    }
    if (this.sortDelegate) {
      items = [...items.sort((a, b) => this.sortDelegate!(this.sortingModel, a, b))]
    }

    return items
  }

  get pageItems() {
    return this.filteredItems.slice(
      this.pageSize * this.currentPageIndex,
      this.pageSize * (this.currentPageIndex + 1),
    )
  }

  get totalCount() {
    return this.filteredItems.length
  }

  setCreateDialogOpen(open: boolean) {
    this.isCreateDialogOpen = open
  }

  async setCurrentPageIndex(pageIndex: number) {
    this.currentPageIndex = pageIndex
  }

  async setPageSize(pageSize: number) {
    this.pageSize = pageSize
  }

  async setFilter(filter?: string) {
    this.filter = filter
  }

  async setFilterStatus(status?: string) {
    this.filterStatus = status
  }

  async toggleShowCompleted() {
    this.showCompleted = !this.showCompleted
  }

  async setSortingModel(sortingModel: SortingModel<T>) {
    this.sortingModel = sortingModel
  }

  async setDateRange(from?: Date, to?: Date) {
    if (
      from &&
      to &&
      (Math.abs(this.from.getTime() - from.getTime()) > 1000 ||
        Math.abs(this.to.getTime() - to.getTime()) > 1000)
    ) {
      runInAction(() => {
        this.from = from
        this.to = to
      })
      console.log(`Setting date range from: ${this.from} to: ${this.to}`)
      await this.loadCurrentPage()
    }
  }

  setIsInitializing(isInitializing: boolean) {
    this.isInitializing = isInitializing
  }

  loadCurrentPage() {
    return this.dataStore.fetch({ from: this.from, to: this.to })
  }
}
