import { VesselDto } from '@planning/app/api'
import { vesselService } from '@planning/services'
import { debounce } from 'lodash'
import { action, makeObservable, observable } from 'mobx'

export class VesselAutocompleteViewStore {
  open = false
  options: VesselDto[] = []

  constructor() {
    makeObservable(this, {
      open: observable,
      options: observable,

      filterOptions: action,
      setOptions: action,
      setOpened: action,
    })
  }

  setOpened(open: boolean) {
    this.open = open

    if (open) this.filterOptions()
    else this.setOptions([])
  }

  setOptions(options: VesselDto[]) {
    this.options = options
  }

  filterOptions = debounce(async (filter?: string) => {
    this.setOptions((await vesselService.get(1, 10, filter)).payload)
  }, 500)
}
