import { Grid, Paper } from '@mui/material'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { IRailVisitItem } from '@planning/rt-stores/railVisit/RailVisitItem'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { RailVisitHeaderTime } from './RailVisitHeaderTime'
import { RailVisitHeaderTracks } from './RailVisitHeaderTracks'
import { RailVisitHeaderVisit } from './RailVisitHeaderVisit'

export const RailVisitHeader: FC<{
  railVisitItem: IRailVisitItem
  currentPage: string
}> = observer(({ railVisitItem, currentPage }) => {
  const theme = useMinimalsTheme()

  const isRailPlanningFeatureEnabled = useBooleanFlagValue('rail-visits-planning', true)

  if (!railVisitItem) return <></>

  const railVisit = railVisitItem.data

  const gridSizeMd = currentPage === 'dashboard' ? 6 : 4
  const gridSizeLg = currentPage === 'dashboard' ? 6 : 4

  return (
    <Paper
      className='rail-visit-details-header'
      square
      elevation={1}
      sx={{ backgroundColor: theme.palette.grey[200] }}
    >
      <Grid container>
        <Grid item sm={12} md={gridSizeMd} lg={6}>
          <RailVisitHeaderVisit railVisit={railVisit} />
        </Grid>
        <Grid item sm={12} md={gridSizeMd} lg={gridSizeLg}>
          <RailVisitHeaderTime railVisit={railVisit} />
        </Grid>
        {currentPage !== 'dashboard' && (
          <Grid item sm={12} md={4} lg={2}>
            {isRailPlanningFeatureEnabled && <RailVisitHeaderTracks railVisit={railVisitItem} />}
          </Grid>
        )}
      </Grid>
    </Paper>
  )
})
