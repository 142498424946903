import { Grid, Typography } from '@mui/material'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { usePlanningStore } from '@planning/AppProvider'
import { CarrierVisitSchedule } from '@planning/components/CarrierVisitSchedule'
import { VisitPlanningStore } from '@planning/rt-stores/visit/VisitPlanningStore'
import { useTranslate } from '@tolgee/react'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import { Controller, useFormContext } from 'react-hook-form'

export const VisitTimestampsFields = observer(
  ({ store, hideEtb }: { store: VisitPlanningStore; hideEtb?: boolean }) => {
    const { t } = useTranslate()
    const { tenantStore } = usePlanningStore()

    const {
      control,
      formState: { errors },
    } = useFormContext()

    const handleDateChange = (newValue: dayjs.Dayjs | null, onChange: any) => {
      if (newValue) {
        onChange(newValue)
      }
    }

    return (
      <>
        <Typography variant='subtitle1' marginTop='1rem' marginBottom='0.5rem'>
          {t('timingsEstimated', 'Timings (Estimated)')}
        </Typography>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid container direction='row' columnSpacing={{ xs: 1 }}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={`eta`}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <DateTimePicker
                    label={t('eta', 'ETA')}
                    value={value}
                    onChange={newValue => handleDateChange(newValue, onChange)}
                    slotProps={{
                      textField: {
                        required: true,
                        variant: 'outlined',
                        helperText: errors.eta ? t('fieldIsRequired', 'Field is required.') : '',
                        error: !!errors.eta,
                        sx: { width: '100%', marginTop: '10px' },
                      },
                    }}
                  />
                )}
              />
            </Grid>
            {!hideEtb && !tenantStore.skipBerthTimestamp && (
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name={`etb`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <DateTimePicker
                      label={t('etb', 'ETB')}
                      value={value}
                      onChange={newValue => handleDateChange(newValue, onChange)}
                      slotProps={{
                        textField: {
                          variant: 'outlined',
                          helperText: errors.etb ? t('fieldIsRequired', 'Field is required.') : '',
                          error: !!errors.etb,
                          sx: { width: '100%', marginTop: '10px' },
                        },
                      }}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Controller
                control={control}
                name={`etd`}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <DateTimePicker
                    label={t('etd', 'ETD')}
                    value={value}
                    onChange={newValue => handleDateChange(newValue, onChange)}
                    slotProps={{
                      textField: {
                        required: true,
                        variant: 'outlined',
                        helperText: errors.etd ? t('fieldIsRequired', 'Field is required.') : '',
                        error: !!errors.etd,
                        sx: { width: '100%', marginTop: '10px' },
                      },
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          {!store.isEditMode && (
            <CarrierVisitSchedule schedule={store.schedule} handleSchedule={store.setSchedule} />
          )}
        </LocalizationProvider>
      </>
    )
  },
)
