import { Box, Typography } from '@mui/material'
import { StackDto } from '@storage/app/api'
import { useStores } from '@storage/hooks/use-stores.hook'
import { colorEmptyOccupancy, colorFullOccupancy } from '@storage/pages/yard-management/constants'
import { pickGradient } from '@storage/utils/colors'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import { StackWithOverlapDto } from '../interfaces'
import { isStackHighlighted } from '../utils/yard-planning-dashboard-stack.util'
import StackHighlightLayer from './StackHighlightLayer'

interface YardPlanningDashboardStacksProps {
  stacks: StackDto[]
  stackWidthPixels: string
  maxTier: number
  bayCount: number
}

const YardPlanningDashboardStacks = observer(
  ({ stacks, stackWidthPixels, maxTier, bayCount }: YardPlanningDashboardStacksProps) => {
    const { palette } = useMinimalsTheme()
    const { carrierVisitAllocationRulesV2UIStore } = useStores()

    const [validStacks, setValidStacks] = useState<StackWithOverlapDto[]>([])

    useEffect(() => {
      let stackTotal = 0
      stacks.forEach(stack => (stackTotal += stack.stackSizeDigit))

      setValidStacks(stacks.map(stack => ({ ...stack, overlapAmount: stackTotal - bayCount })))
    }, [bayCount, stacks])

    const stackHighlightStatus = useMemo(
      () =>
        validStacks.map(stack =>
          isStackHighlighted(stack, carrierVisitAllocationRulesV2UIStore.selectedAllocationRule),
        ),
      [validStacks, carrierVisitAllocationRulesV2UIStore.selectedAllocationRule],
    )

    return (
      <>
        {validStacks
          .filter(
            //if a bay has a 40 and the next a 20, it could create an unecessary square, so this code filters it
            (stack, index) =>
              !(
                index === stacks.length - 1 &&
                stack.overlapAmount > 0 &&
                stack.numberOfOccupiedSlots === 0 &&
                stack.stackSizeDigit === 1
              ),
          )
          .map((stack, index) => (
            <Box
              key={`${stack.bayId}_${stack.rowId}`}
              width={`calc(${stackWidthPixels} * ${index === validStacks.length - 1 && stack.overlapAmount > 0 ? 1 : stack.stackSizeDigit})`}
              textAlign='center'
              position='relative'
              sx={{
                backgroundColor: stack.numberOfOccupiedSlots
                  ? pickGradient(
                      colorEmptyOccupancy,
                      colorFullOccupancy,
                      stack.numberOfOccupiedSlots * (1 / maxTier),
                    )
                  : palette.common.white,
                border: `${palette.grey[600]} 1px solid`,
                borderLeft: index === 0 ? `${palette.grey[600]} 1px solid` : undefined,
                flex: 'none',
              }}
            >
              <Typography
                variant='body2'
                color={
                  stack.numberOfOccupiedSlots >= stack.numberOfSlots - 1
                    ? palette.common.white
                    : palette.common.black
                }
              >
                {stack.numberOfOccupiedSlots > 0 ? stack.numberOfOccupiedSlots : ''}
              </Typography>

              {stackHighlightStatus[index] && <StackHighlightLayer />}
            </Box>
          ))}
      </>
    )
  },
)

export default YardPlanningDashboardStacks
