import { PackageDto } from '@billing/app/api'
import { PackageStore } from '@billing/stores/PackageStore'
import { action, computed, makeObservable, observable } from 'mobx'

export class PackageListUIStore {
  searchPackage = ''

  constructor(private packageStore: PackageStore) {
    makeObservable(this, {
      searchPackage: observable,

      packages: computed,

      setSearchPackage: action,
    })
  }

  public setSearchPackage(newPackage: string) {
    if (this.searchPackage !== newPackage) {
      this.searchPackage = newPackage
    }
  }

  public get packages(): PackageDto[] {
    return [...this.packageStore.items]
      .sort(i => i.id)
      .filter(x => this.doesPackageHaveName(x, this.searchPackage))
  }

  private doesPackageHaveName(packageItem: PackageDto, name?: string) {
    return !name || packageItem.name.toLowerCase().includes(name.toLowerCase())
  }
}
