import { ContainerResponseDto } from '@planning/app/api'
import { IEvent, IMessageBus } from '@planning/messages'
import { EventTypes } from '@planning/messages/eventsTypes'
import { GetContainerByIdQuery, GetContainersByVisitIdQuery } from '@planning/messages/queries'
import { action, makeObservable } from 'mobx'
import { ItemStore } from '../base/ItemStore'
import { ContainerItem, IContainerItem } from './ContainerItem'

// TODO: [north] UT
export class ContainerItemStore extends ItemStore<ContainerResponseDto, IContainerItem> {
  fetchedVisitIds: Set<number> = new Set()

  constructor(private messageBus: IMessageBus) {
    super((key, data) => new ContainerItem(key, data), {
      messageBus,
      fetchFunc: (id: number) => new GetContainerByIdQuery(id),
    })
    makeObservable(this, {
      receiveContainersMessage: action,
      receiveContainerUpsertedEventMessage: action,
    })

    messageBus.subscribeEvent(GetContainersByVisitIdQuery.type, this.receiveContainersMessage)
    messageBus.subscribeEvent(GetContainerByIdQuery.type, this.receiveContainerUpsertedEventMessage)
    messageBus.subscribeEvent(
      EventTypes.ContainerUpsertedEvent,
      this.receiveContainerUpsertedEventMessage,
    )
  }

  fetchByVisitId = async (visitId: number) => {
    if (this.fetchedVisitIds.has(visitId)) return

    this.fetchedVisitIds.add(visitId)

    await this.messageBus.dispatchQuery(new GetContainersByVisitIdQuery(visitId))
  }

  fetchByVisitIds = async (visitIds: number[]) => {
    for (const id of visitIds) {
      await this.fetchByVisitId(id)
    }
  }

  receiveContainersMessage = (event: IEvent<ContainerResponseDto[]>): void => {
    if (event.payload) {
      this.upsertBulk(event.payload)
    }
  }

  receiveContainerUpsertedEventMessage = (event: IEvent<ContainerResponseDto>): void => {
    if (event.payload) {
      this.upsert(event.payload)
    }
  }
}
