import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import { CarrierVisitStatusChip } from '@planning/components/CarrierVisitStatusChip'
import IconTruck from '@planning/components/icon/IconTruck'
import { IOrderItem } from '@planning/rt-stores/order/OrderItem'
import { ITruckVisitItem } from '@planning/rt-stores/truckVisit/TruckVisitItem'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'

interface Props {
  truckVisit: ITruckVisitItem
  handleClick: () => void
}

const OrderListItem = ({ order }: { order: IOrderItem }) => {
  return (
    <ListItem key={order.data.containerId} dense sx={{ p: 0 }}>
      <ListItemText primary={order.data.containerNumber} />
    </ListItem>
  )
}

export const TruckAppointmentMobileViewListCard = observer(({ truckVisit, handleClick }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const pickupOrderDetails = truckVisit.outboundOrders.map(order => (
    <OrderListItem order={order} key={order.id} />
  ))

  const pickupNnrOrderDetails = (!!truckVisit.outboundOrders.filter(
    order => !order.data.containerNumber,
  ).length && (
    <ListItem dense sx={{ p: 0 }}>
      <ListItemText
        primary={`${
          truckVisit.outboundOrders.filter(order => !order.data.containerNumber).length
        } ${t('emptyNon-numeric', 'Empty non-numeric')}`}
      />
    </ListItem>
  )) || <></>

  return (
    <Card variant='outlined' sx={{ width: '100%', pb: 0, mt: 1 }} onClick={handleClick}>
      <CardContent sx={{ p: 0, '&:last-child': { paddingBottom: 0 } }}>
        <Stack direction='column' spacing={'2px'}>
          <Stack>
            <Box
              sx={{
                m: 1,
                p: 1,
              }}
            >
              <Grid container direction='row' spacing={1}>
                <Grid item sx={{ pt: 0.5 }} xs={2}>
                  <IconTruck />
                </Grid>
                <Grid item xs>
                  <Typography variant='h4'>
                    {truckVisit.truck?.data.licensePlate?.toUpperCase()}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <CarrierVisitStatusChip status={truckVisit.data.status} />
                </Grid>
              </Grid>
            </Box>
          </Stack>

          <Divider sx={{ borderBottomWidth: 'inherit' }} />
          <Box
            sx={{
              pt: 1,
              borderRadius: 0,
              bgcolor: theme.palette.grey[200],
            }}
          >
            <Stack direction='row' display={'flex'} justifyContent={'space-evenly'}>
              <Stack>
                <Box>
                  <Typography variant='subtitle2'>{t('dropOff', 'Drop-off')}</Typography>
                  <List>
                    {truckVisit.inboundOrders.map(order => (
                      <OrderListItem order={order} key={order.id} />
                    ))}
                  </List>
                </Box>
              </Stack>
              <Stack>
                <Box>
                  <Typography variant='subtitle2'>{t('pickUp', 'Pick-up')}</Typography>
                  <List sx={{ listStyleType: 'square' }}>
                    {pickupOrderDetails}
                    {pickupNnrOrderDetails}
                  </List>
                </Box>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  )
})
