import { mapArrayToQueryParams } from './map-array-to-query-params.mapper'
import { mapBooleanToBooleanSelectOption } from './map-boolean-to-boolean-select-option.mapper'
import { mapBooleanToBooleanOption } from './map-boolean-to-boolean.mapper'
import { mapOptionalFieldValueToBoolean } from './map-field-value-to-boolean.mapper'
import { mapOptionalFieldValueToNumber } from './map-field-value-to-number.mapper'
import { mapOptionalFieldValueToString } from './map-field-value-to-string.mapper'
import { mapSelectValueToLabel } from './map-select-value-to-label.mapper'
import { mapToQueryString } from './map-to-query-string.mapper'

export const UtilityMappers = {
  mapToQueryString,
  mapArrayToQueryParams,
  mapOptionalFieldValueToString,
  mapOptionalFieldValueToNumber,
  mapOptionalFieldValueToBoolean,
  mapBooleanToBooleanSelectOption,
  mapSelectValueToLabel,
  mapBooleanToBooleanOption,
}
