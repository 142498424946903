import { Add } from '@mui/icons-material'
import { Fab } from '@mui/material'

interface Props {
  onAddEntity: () => void
}

export const AddEntityButton = ({ onAddEntity }: Props) => {
  return (
    <Fab
      onClick={onAddEntity}
      sx={{
        position: 'fixed',
        right: 16,
        bottom: 16,
      }}
      color='primary'
    >
      <Add />
    </Fab>
  )
}
