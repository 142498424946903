import { Button } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'

interface TextButtonProps {
  label?: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export const TextButton = ({ label, onClick }: TextButtonProps) => {
  const { palette } = useMinimalsTheme()
  return (
    <Button
      sx={{
        color: palette.info.main,
        '&.MuiButtonBase-root:hover': {
          bgcolor: 'transparent',
        },
        textTransform: 'none',
      }}
      onClick={onClick}
    >
      {label}
    </Button>
  )
}
