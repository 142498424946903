import { Box, List as MuiList, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { Loader, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { ReactNode } from 'react'
import IconEmptyList from '../icon/IconEmptyList'
import { IListStore } from './SimpleListStore'

interface IProps<T> {
  store: IListStore<T>
  renderListItem: (element: T, index: number) => ReactNode
  renderLoadingListItem?: () => ReactNode
  renderEmpty?: () => ReactNode
  showTotalResults?: boolean
  listItems?: any[]
}

const defaultLoadingElement = <Loader show />

export const SimpleList = observer(
  <_, T>({
    store,
    listItems,
    renderListItem,
    renderLoadingListItem,
    renderEmpty,
    showTotalResults,
  }: IProps<T>) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()

    const renderList = () => {
      if (store.isLoading)
        return (
          <MuiList sx={{ minHeight: 200 }}>
            {renderLoadingListItem?.() ?? defaultLoadingElement}
          </MuiList>
        )

      if (store.isNoMatches) {
        return renderEmpty?.() ?? renderEmptyList()
      }

      const itemToMap = listItems ?? store.items

      return (
        <MuiList sx={{ minHeight: 200 }}>
          {showTotalResults && itemToMap.length ? renderTotalResults() : ''}
          {itemToMap.map((item, index) => {
            return (
              <Box mb='1rem' key={index}>
                {renderListItem(item, index)}
              </Box>
            )
          })}
        </MuiList>
      )
    }

    const renderTotalResults = () => (
      <Typography variant='body1' margin='1rem 0' fontWeight='bold'>{`${t(
        'resultsFor',
        'Results for',
      )} '${store.filter}'`}</Typography>
    )

    const renderEmptyList = () => (
      <Box>
        {showTotalResults && renderTotalResults()}
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          mt='4rem'
          color={theme.palette.grey[500]}
        >
          <IconEmptyList />
          <Typography variant='h4'>{t('noResults', 'No Results?')}</Typography>
          <Typography variant='body1' mt='.25rem'>
            {t(
              'noOrderFound',
              'It’s likely the container hasn’t had an order created for your terminal, time to create one?',
            )}
          </Typography>
        </Box>
      </Box>
    )

    return renderList()
  },
)
