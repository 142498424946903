import { Autocomplete, SxProps, TextField, TextFieldProps } from '@mui/material'
import { SelectOption } from '@storage/app/models'
import { Controller, FieldValues, FormState, UseControllerProps } from 'react-hook-form'

type FieldProps = Pick<TextFieldProps, 'label' | 'disabled' | 'fullWidth'>

interface Props<T extends FieldValues> extends UseControllerProps<T>, FieldProps {
  formState: FormState<T>
  options: SelectOption[]
  multiple?: boolean
  onSearch?: (value: string) => void
  loading?: boolean
  onLoadNextPage?: () => void
  formControlSx?: SxProps
  useValueAsSelectedLabel?: boolean
  required?: boolean
}

export const ControlledAutocomplete = <T extends FieldValues>({
  name,
  control,
  label,
  fullWidth,
  disabled,
  formState: { errors },
  options,
  multiple,
  loading,
  onSearch,
  onLoadNextPage,
  formControlSx,
  useValueAsSelectedLabel,
  required,
}: Props<T>) => {
  return (
    <Controller
      render={({ field }) => (
        <Autocomplete
          sx={formControlSx}
          multiple={multiple}
          {...field}
          value={field.value || null}
          fullWidth={fullWidth}
          disabled={disabled}
          onOpen={() => onSearch?.('')}
          loading={loading}
          loadingText={'loading...'}
          onChange={(_, option) => {
            field.onChange(option)
          }}
          ListboxProps={{
            onScroll: (event: React.SyntheticEvent) => {
              if (!onLoadNextPage) return

              const listboxNode = event.currentTarget
              if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                onLoadNextPage()
              }
            },
          }}
          getOptionLabel={option =>
            useValueAsSelectedLabel
              ? options.find(opt => opt.value === option)?.value ?? ''
              : options.find(opt => opt.value === option)?.label ?? ''
          }
          options={options.map(s => s.value)}
          renderOption={(props, option) => (
            <li {...props}>{options.find(opt => opt.value === option)?.label ?? option}</li>
          )}
          renderInput={params => (
            <TextField
              required={required}
              {...params}
              onChange={({ target }) => onSearch?.(target.value)}
              label={label}
              error={!!errors[name]}
              helperText={errors[name]?.message as string}
            />
          )}
        />
      )}
      name={name}
      control={control}
    />
  )
}
