import {
  Autocomplete,
  Box,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  debounce,
} from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import {
  CarrierVisitDirection,
  ContainerHeight,
  ContainerResponseDto,
  ContainerType,
  CreateContainerOrderDto,
} from '@planning/app/api'
import { getContainerTypeParameters } from '@planning/components/ContainerTypesMap'
import { containerHeights, containerTypes } from '@planning/constants'
import { DangerousGoodAutoComplete } from '@planning/pages/DeliveryOrders/Components/DangerousGoodsAutoComplete'
import { validateContainerNumber, validateIsoCode } from '@planning/rt-stores/helpers'
import { containerService, orderService } from '@planning/services'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { Controller, FieldErrors, useForm } from 'react-hook-form'

interface ICreateOrderForm extends CreateContainerOrderDto {
  containerLength?: number | null
  containerHeight?: ContainerHeight | null
  containerType?: ContainerType
}

const defaultFormState: ICreateOrderForm = {
  referenceNumber: '',
  containerNumber: undefined,
  containerIsoCode: undefined,
  containerLength: undefined,
  containerHeight: undefined,
  containerType: undefined,
  carrierVisitId: undefined,
  grossWeight: null,
  direction: 'Inbound',
  operator: undefined,
  isEmpty: false,
  imoClasses: [],
  isAccidentalDischarge: false,
  isToBeStoredInYard: true,
}

interface Props {
  showAccidentalDischargeField?: boolean
  direction: CarrierVisitDirection
  carrierVisitId?: number
  onSubmit: (order: CreateContainerOrderDto) => Promise<void> | void
}

export const CreateOrderForm: FC<Props> = observer(
  ({ showAccidentalDischargeField, direction, carrierVisitId, onSubmit }) => {
    const { appViewStore, vesselVisitItemStore } = usePlanningStore()
    const { t } = useTranslate()
    const {
      handleSubmit,
      control,
      register,
      getValues,
      setValue,
      formState: { errors },
    } = useForm({
      defaultValues: { ...defaultFormState, direction, carrierVisitId },
    })

    const [open, setOpen] = useState(false)
    const [options, setOptions] = useState<ContainerResponseDto[]>([])
    const [isEmpty, setIsEmpty] = useState<boolean>(false)
    const [existingContainer, setExistingContainer] = useState<ContainerResponseDto | undefined>(
      undefined,
    )
    const [containerNumberInput, setContainerNumberInput] = useState<string | undefined>(undefined)
    const [linkedOrderId, setLinkedOrderId] = useState<number | undefined>(undefined)
    const [containersInVisit, setContainersInVisit] = useState<(string | null | undefined)[]>([])
    const [isIsoCodeToggled, setIsIsoCodeToggled] = useState(true)

    const OnSearchFilterChange = debounce(async (filter?: string) => {
      setOptions(await fetch(filter))
    }, 500)

    const fetch = async (filter?: string) => {
      return (await containerService.get(1, 10, filter)).payload.filter(c => !!c.number)
    }

    const fetchOrdersWithMatchingContainerNumber = async (container: ContainerResponseDto) => {
      if (container && container.number) {
        const data = await orderService.getByContainerNumber(container.number)
        const id = data.filter(o => o.linkedOrderId === null)[0].id
        setLinkedOrderId(id)
      }
    }

    useEffect(() => {
      if (open) {
        fetch().then(payload => setOptions(payload))
      } else {
        setOptions([])
      }
    }, [open])

    useEffect(() => {
      const visitItem = vesselVisitItemStore.getById(Number(carrierVisitId))
      if (!visitItem) return

      const orders =
        direction === CarrierVisitDirection.Inbound
          ? visitItem.discharge?.orders
          : visitItem.load?.orders
      if (orders) {
        setContainersInVisit(orders.map(o => o.data?.containerNumber) ?? [])
      }
    }, [carrierVisitId, direction, vesselVisitItemStore])

    const onSubmitForm = (order: ICreateOrderForm) => {
      if (existingContainer?.isOnTerminal && direction === CarrierVisitDirection.Inbound) {
        appViewStore.setShowAlert(
          'error',
          t('containerIsAlreadyOnTerminal', 'Container is already on Terminal'),
        )

        return
      }

      if (containersInVisit.includes(order.containerNumber)) {
        appViewStore.setShowAlert(
          'error',
          t(`containerIsAlreadyInVisit`, 'Container is already in Visit'),
        )

        return
      }

      onSubmit({
        ...order,
        linkedOrderId: linkedOrderId ? linkedOrderId : undefined,
        containerIsoCode: isIsoCodeToggled ? order.containerIsoCode : undefined,
        containerAttributes: isIsoCodeToggled
          ? undefined
          : {
              length: order.containerLength ? order.containerLength : undefined,
              height: order.containerHeight ? order.containerHeight : undefined,
              type: order.containerType ? order.containerType : undefined,
            },
      })
    }

    const containerNumberErrorText = (errors: FieldErrors) => {
      const containerNumberError = errors.containerNumber
      if (containerNumberError) {
        const errorType = containerNumberError.type
        if (errorType === 'required') return t('fieldIsRequired', 'Field is required.')
        if (errorType === 'validate')
          return `${t('mustMatchPattern', 'Must match pattern')}: ABCU1234567`

        return containerNumberError.message?.toString()
      }
    }

    const containerIsoCodeErrorText = (errors: FieldErrors) => {
      const isoCodeError = errors.containerIsoCode
      if (isoCodeError) {
        const errorType = isoCodeError.type
        if (errorType === 'required') return t('fieldIsRequired', 'Field is required.')
        if (errorType === 'validate') return t('isoCodeNotFound', 'ISO Code not found')

        return isoCodeError.message?.toString()
      }
    }

    const validateContainerAttributes = (f: any) =>
      !!f.containerLength || !!f.containerHeight || !!f.containerType

    const requiredAtLeastOneContainerAttributeError =
      !!errors.containerLength && !!errors.containerHeight && !!errors.containerType

    return (
      <form id='create-order-vessel-visit' onSubmit={handleSubmit(onSubmitForm)}>
        <Grid container direction='column' rowSpacing={{ xs: 1.5 }}>
          <Grid item>
            <Controller
              control={control}
              name={`containerNumber`}
              rules={{ required: true, validate: v => !!v && validateContainerNumber(v) }}
              render={({ field: { onChange } }) => (
                <Autocomplete
                  freeSolo
                  disablePortal
                  getOptionLabel={(container: ContainerResponseDto | string) =>
                    typeof container === 'string' ? container : container.number ?? ''
                  }
                  renderOption={(props, container) => (
                    <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {typeof container === 'string'
                        ? container
                        : `${container.number} : ${
                            container.isOnTerminal ? 'On Terminal' : 'Off Terminal'
                          }`}
                    </Box>
                  )}
                  isOptionEqualToValue={(
                    option: ContainerResponseDto | string,
                    value: ContainerResponseDto | string,
                  ) => {
                    if (typeof option !== 'string' && typeof value !== 'string') {
                      return option.id === value.id
                    }

                    return option === value
                  }}
                  open={open}
                  onOpen={() => setOpen(true)}
                  onClose={() => setOpen(false)}
                  options={options}
                  onInputChange={(_, value) => {
                    setContainerNumberInput(value.toUpperCase())
                  }}
                  onBlur={() => {
                    if (existingContainer && existingContainer.number !== containerNumberInput) {
                      setValue('containerIsoCode', '')
                      setValue('operator', '')
                      setExistingContainer(undefined)
                    }
                  }}
                  onChange={(_, selectedContainer: ContainerResponseDto | null | string) => {
                    if (selectedContainer && typeof selectedContainer !== 'string') {
                      setValue('containerIsoCode', selectedContainer.isoCode)
                      setValue('operator', selectedContainer.operator)
                      setValue('containerLength', selectedContainer.length)
                      setValue('containerHeight', selectedContainer.height)
                      setValue('containerType', selectedContainer.type)

                      setExistingContainer(selectedContainer)
                      fetchOrdersWithMatchingContainerNumber(selectedContainer)
                      onChange(selectedContainer.number)
                      if (selectedContainer.isoCode) setIsIsoCodeToggled(true)
                    } else {
                      setValue('containerIsoCode', '')
                      setValue('operator', '')
                      setValue('containerLength', defaultFormState.containerLength)
                      setValue('containerHeight', defaultFormState.containerHeight)
                      setValue('containerType', defaultFormState.containerType)

                      setExistingContainer(undefined)
                      onChange(selectedContainer)
                    }
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label={t('containerNumber', 'Container Number')}
                      helperText={containerNumberErrorText(errors)}
                      error={!!errors.containerNumber}
                      onChange={e => {
                        onChange(e)
                        OnSearchFilterChange(e.target.value)
                      }}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                        e.target.value = ('' + e.target.value).toUpperCase()
                      }}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              {...register(`referenceNumber`)}
              label={`${t('referenceNumber', 'Reference Number')}`}
            />
          </Grid>
          <Typography variant='h4' sx={{ marginTop: '1rem' }}>
            {t('container', 'Container')}
          </Typography>
          <Grid container direction='row' columnGap={1}>
            <Chip
              key='iso-code-chip'
              label={t('isoCode', 'ISO Code')}
              sx={{ mt: '1rem', mb: '1rem' }}
              variant={!isIsoCodeToggled ? 'outlined' : undefined}
              onClick={() => setIsIsoCodeToggled(true)}
            />
            <Chip
              key='container-attributes-chip'
              label={t('containerAttributes', 'Container Attributes')}
              sx={{ m: '1rem 1rem 1rem 0' }}
              variant={isIsoCodeToggled ? 'outlined' : undefined}
              onClick={() => setIsIsoCodeToggled(false)}
              disabled={!!existingContainer?.isoCode}
            />
          </Grid>

          {isIsoCodeToggled ? (
            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              {...register('containerIsoCode', {
                required: true,
                validate: v => !!v && validateIsoCode(v),
              })}
              label={`${t('isoCode', 'ISO Code')} *`}
              disabled={!!existingContainer?.isoCode}
              error={!!errors.containerIsoCode}
              helperText={containerIsoCodeErrorText(errors)}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                e.target.value = ('' + e.target.value).toUpperCase()
              }}
            />
          ) : (
            <>
              <TextField
                fullWidth
                label={t('length', 'Length')}
                variant='outlined'
                type='number'
                {...register('containerLength', {
                  valueAsNumber: true,
                  validate: (_, formData) => validateContainerAttributes(formData),
                })}
                name='containerLength'
                onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
                inputProps={{ min: 1 }}
                error={requiredAtLeastOneContainerAttributeError}
              />
              <Controller
                control={control}
                name={`containerHeight`}
                rules={{ validate: (_, formData) => validateContainerAttributes(formData) }}
                render={({ field: { onChange } }) => (
                  <FormControl fullWidth sx={{ marginTop: '1rem' }}>
                    <InputLabel sx={{ verticalAlign: 'center' }}>
                      {t('height', 'Height')}
                    </InputLabel>
                    <Select
                      label={t('height', 'Height')}
                      {...register('containerHeight')}
                      value={getValues(`containerHeight`)?.toString() ?? ''}
                      onChange={(e: any) => {
                        onChange(e)
                      }}
                      error={requiredAtLeastOneContainerAttributeError}
                    >
                      <MenuItem value={''}>
                        <em>None</em>
                      </MenuItem>
                      {containerHeights.map(d => {
                        return (
                          <MenuItem key={d.key} value={d.name}>
                            {d.name}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                )}
              />
              <Controller
                control={control}
                name={`containerType`}
                rules={{ validate: (_, formData) => validateContainerAttributes(formData) }}
                render={({ field: { onChange } }) => (
                  <FormControl fullWidth sx={{ marginTop: '1rem' }}>
                    <InputLabel sx={{ verticalAlign: 'center' }}>{t('type', 'Type')}</InputLabel>
                    <Select
                      label={t('type', 'Type')}
                      {...register('containerType')}
                      value={getValues(`containerType`)?.toString() ?? ''}
                      onChange={(e: any) => {
                        onChange(e)
                      }}
                      error={requiredAtLeastOneContainerAttributeError}
                    >
                      <MenuItem value={''}>
                        <em>None</em>
                      </MenuItem>
                      {containerTypes.map(d => {
                        return (
                          <MenuItem key={d.key} value={d.name}>
                            {getContainerTypeParameters(d.name, t)['label']}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                )}
              />
            </>
          )}
          <Grid item>
            <TextField
              fullWidth
              {...register('grossWeight', { valueAsNumber: true })}
              type='number'
              label={t('grossWeightKg', 'Gross Weight (kg)')}
              onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
              inputProps={{ min: 1 }}
            />
          </Grid>
          <Grid item>
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              {...register(`operator`)}
              label={t('operator', 'Operator')}
              disabled={!!existingContainer?.operator}
            />
          </Grid>
          <Grid item>
            <Controller
              control={control}
              name={`isEmpty`}
              render={({ field: { onChange } }) => (
                <FormControl fullWidth>
                  <InputLabel color='secondary'>{t('filledState', 'Filled State')}</InputLabel>
                  <Select
                    label={t('filledState', 'Filled State')}
                    value={isEmpty}
                    onChange={(e: any) => {
                      onChange(e)
                      const newValue = e.target.value
                      setIsEmpty(newValue)
                      if (newValue) {
                        setValue(`imoClasses`, [])
                      }
                    }}
                  >
                    <MenuItem value={true as any}>{t('empty', 'Empty')}</MenuItem>
                    <MenuItem value={false as any}>{t('full', 'Full')}</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item>
            <Typography variant='subtitle1' marginTop={'10px'}>
              {t('handlingInstructions', 'Handling Instructions')}
            </Typography>
          </Grid>
          <Grid item sx={{ marginTop: '-1rem' }}>
            <Controller
              control={control}
              name={`imoClasses`}
              render={({ field: { onChange } }) => (
                <DangerousGoodAutoComplete
                  assignedCodes={getValues(`imoClasses`)}
                  onDangerousGoodChange={newValues => {
                    onChange(newValues)
                  }}
                  disabled={isEmpty}
                />
              )}
            />
          </Grid>
          <Grid item>
            <Controller
              control={control}
              name={`isToBeStoredInYard`}
              render={({ field: { onChange } }) => (
                <FormControl fullWidth>
                  <InputLabel color='secondary'>
                    {t('isToBeStoredInYard', 'Is stored in yard')}
                  </InputLabel>
                  <Select
                    label={t('isToBeStoredInYard', 'Is stored in yard')}
                    value={getValues(`isToBeStoredInYard`)}
                    onChange={(e: any) => {
                      onChange(e)
                    }}
                  >
                    <MenuItem value={true as any}>{t('yes', 'Yes')}</MenuItem>
                    <MenuItem value={false as any}>{t('no', 'No')}</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          {direction === CarrierVisitDirection.Inbound && showAccidentalDischargeField && (
            <Grid item>
              <Controller
                control={control}
                name={`isAccidentalDischarge`}
                render={({ field: { onChange } }) => (
                  <FormControl fullWidth>
                    <InputLabel color='secondary'>
                      {t('isAccidentalDischarge', 'Is Accidental')}
                    </InputLabel>
                    <Select
                      label={t('isAccidentalDischarge', 'Is Accidental')}
                      value={getValues(`isAccidentalDischarge`)}
                      onChange={(e: any) => {
                        onChange(e)
                      }}
                    >
                      <MenuItem value={true as any}>{t('yes', 'Yes')}</MenuItem>
                      <MenuItem value={false as any}>{t('no', 'No')}</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
          )}
        </Grid>
      </form>
    )
  },
)
