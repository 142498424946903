import {
  CarrierType,
  CarrierVisitDto,
  CarrierVisitStatus,
  CarrierVisitsApi,
  EquipmentDto,
  EquipmentType,
  OperationType,
  OperatorViewSettings,
} from '@operations/app/api'
import { createApiClient } from '@operations/app/http-client'
import { EquipmentStore } from '@operations/stores/EquipmentStore'
import _ from 'lodash'

import { EventTypes } from '@operations/messages/eventsTypes'
import { IMessageBus } from '@operations/messages/messageBus'
import { TenantConfigStore } from '@operations/stores/TenantConfigStore'
import { AppStore } from '@tom-ui/utils'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'

export class EquipmentOperatorLandingUIStore {
  loadingSignalR = false

  readyForOperationVesselVisits: CarrierVisitDto[] = []

  selectedEquipmentTypes?: EquipmentType[]
  selectedEquipmentId?: number
  selectedVesselVisit: CarrierVisitDto | null = null
  selectedOperationType: OperationType | null = null

  constructor(
    private messageBus: IMessageBus,
    private appStore: AppStore,
    private equipmentStore: EquipmentStore,
    private tenantConfigStore: TenantConfigStore,
  ) {
    makeObservable(this, {
      selectedEquipmentTypes: observable,
      selectedEquipmentId: observable,
      selectedVesselVisit: observable,
      readyForOperationVesselVisits: observable,
      selectedOperationType: observable,

      selectEquipmentTypes: action,
      selectEquipment: action,
      selectVesselVisit: action,
      selectOperationType: action,

      equipmentOptions: computed,
      displayVesselVisitSelector: computed,
      displayOperationsTypeSelector: computed,
      selectedEquipment: computed,
    })

    this.messageBus.subscribeEvent(EventTypes.CarrierVisitRefresh, this.reloadVesselVisitsBySignalR)
  }

  public selectEquipmentTypes(types?: EquipmentType[]): void {
    this.selectEquipment()
    this.selectedEquipmentTypes = types
  }

  public selectEquipment(id?: number): void {
    if (this.selectedEquipmentId !== id) {
      this.selectedEquipmentId = id
    }
  }

  public selectVesselVisit(vesselVisit: CarrierVisitDto | null): void {
    if (this.selectedVesselVisit !== vesselVisit) {
      this.selectedVesselVisit = vesselVisit
    }
  }

  public selectOperationType(operationType: OperationType | null): void {
    if (this.selectedOperationType !== operationType) {
      this.selectedOperationType = operationType
    }
  }

  public get equipmentOptions(): EquipmentDto[] {
    return _(
      this.equipmentStore.items.filter(x => this.selectedEquipmentTypes?.includes(x.equipmentType)),
    )
      .sortBy(i => i.name)
      .value()
  }

  public get selectedEquipment(): EquipmentDto | undefined {
    return this.equipmentStore.items.find(x => x.id === this.selectedEquipmentId)
  }

  private getEquipmentTypeSettings = (): OperatorViewSettings | undefined => {
    const equipmentType = this.selectedEquipment?.equipmentType
    if (!equipmentType) return

    return this.tenantConfigStore.operatorViewSettings?.[equipmentType]
  }

  public get displayVesselVisitSelector(): boolean {
    const equipmentTypeSettings = this.getEquipmentTypeSettings()

    if (!equipmentTypeSettings) {
      return false
    }

    return equipmentTypeSettings.displayVesselVisitSelector
  }

  public get displayOperationsTypeSelector(): boolean {
    const equipmentTypeSettings = this.getEquipmentTypeSettings()

    if (!equipmentTypeSettings) {
      return false
    }

    return equipmentTypeSettings.displayOperationsTypeSelector
  }

  async loadReadyForOperationVesselVisits() {
    const { data } = await createApiClient(CarrierVisitsApi).get(CarrierType.Vessel, undefined, [
      CarrierVisitStatus.InOperation,
    ])
    runInAction(() => (this.readyForOperationVesselVisits = data))
  }

  reloadVesselVisitsBySignalR = async () => {
    if (!this.displayVesselVisitSelector) return
    if (!this.loadingSignalR) {
      this.loadingSignalR = true
      await this.appStore.triggerReloadBySignalR(
        this.loadReadyForOperationVesselVisits,
        '/Operator',
      )
      this.loadingSignalR = false
    }
  }
}
