import { Stack, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'

interface Props {
  label: string
  name: string
  address?: string | null
}
export const DeliveryNoteInfoBox = ({ label, name, address }: Props) => {
  const { t } = useTranslate()

  return (
    <Stack paddingY={1}>
      <Typography variant='subtitle2'>
        {label}: {name}
      </Typography>
      {address && (
        <Typography variant='body2'>
          {t('address', 'Address')}: {address}
        </Typography>
      )}
    </Stack>
  )
}
