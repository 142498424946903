import { Theme } from '@mui/material/styles'

// ----------------------------------------------------------------------

export default function Typography(theme: Theme) {
  return {
    MuiTypography: {
      styleOverrides: {
        paragraph: {
          marginBottom: theme.customSpacing.m,
        },
        gutterBottom: {
          marginBottom: theme.customSpacing.xs,
        },
      },
    },
  }
}
