import { Grid, TextField } from '@mui/material'
import { PinInfo } from '@planning/rt-stores/railTrack/RailcarTrackPositionItem'

import { CheckType } from '@planning/app/api'
import { useTranslate } from '@tolgee/react'
import { CustomInputAdornment } from '@tom-ui/ui'
import {
  notAllowPlusMinusPointSign,
  notAllowPlusMinusSign,
  onlyAllowPositiveInt,
} from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { IInspectRailcarFormData } from '../../InspectRailcar'
import { InspectRailcarPins } from './InspectRailcarPins'

interface IProps {
  operationType?: CheckType
  pins: PinInfo[]
}

export const InspectRailcarForm: FC<IProps> = observer(({ operationType, pins }) => {
  const { t } = useTranslate()

  const {
    register,
    formState: { errors },
  } = useFormContext<IInspectRailcarFormData>()

  return (
    <form>
      <Grid container spacing='0.5rem' p='1rem'>
        <Grid item xs={12}>
          <Controller
            name='sequence'
            rules={{ required: true }}
            render={() => (
              <TextField
                data-cy='railcar-sequence'
                fullWidth
                label={`${t('sequenceNo', 'Sequence no.')}`}
                required
                variant='outlined'
                type='number'
                {...register('sequence', { valueAsNumber: true, min: 1 })}
                name='sequence'
                onKeyDown={notAllowPlusMinusPointSign}
                onChange={onlyAllowPositiveInt}
                error={!!errors.sequence}
                helperText={errors.sequence ? t('fieldIsRequired', 'Field is required.') : ''}
                sx={{ marginTop: '1rem' }}
              />
            )}
          ></Controller>
        </Grid>
        <Grid item xs={12}>
          <TextField
            data-cy='railcar-length'
            fullWidth
            label={`${t('length', 'Length')}`}
            variant='outlined'
            type='number'
            InputProps={{
              endAdornment: (
                <CustomInputAdornment position='end' color='secondary'>
                  m
                </CustomInputAdornment>
              ),
            }}
            inputProps={{
              min: '0',
              step: '0.01',
              pattern: '[0-9]*',
            }}
            {...register('length', { valueAsNumber: true })}
            onKeyDown={notAllowPlusMinusSign}
            name='length'
            sx={{ marginTop: '1rem' }}
          />
        </Grid>

        {operationType === CheckType.LoadPreparation && pins && (
          <Grid item xs={12}>
            <InspectRailcarPins pins={pins} />
          </Grid>
        )}
      </Grid>
    </form>
  )
})
