import { PATHS } from '@host/app/paths'
import { appStore } from '@host/stores/AppStore'
import { Box } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { ReactNode } from 'react'
import { useLocation } from 'react-router'
import { Sidebar } from './components/Sidebar'

interface Props {
  children: ReactNode
}

export const Layout = observer(({ children }: Props) => {
  // TODO: an interim solution to force to hide Header and Sidebar before implementing responseive website
  const location = useLocation()

  const customAppBarPaths = [
    PATHS.tallyclerk,
    PATHS.gateClerk,
    PATHS.ech,
    PATHS.tt,
    PATHS.rs,
    PATHS.rtg,
    PATHS.all,
    PATHS.gc,
    PATHS.reeferOperator,
  ]
  const useCustomAppBar = customAppBarPaths.some(path => location.pathname.startsWith(path))

  return (
    <>
      {(useCustomAppBar && (
        <Box>
          <main>{children}</main>
        </Box>
      )) || (
        <Box sx={{ height: 'inherit' }}>
          <Sidebar />
          <main
            style={{
              height: 'inherit',
              marginLeft: appStore.contentMarginleft,
              transition: 'all 0.2s ease-in-out',
            }}
          >
            {children}
          </main>
        </Box>
      )}
    </>
  )
})
