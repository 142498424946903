import Konva from 'konva'
import { Vector2d } from 'konva/lib/types'
import React, { useEffect, useRef } from 'react'
import { YARD_DIMENSIONS } from '../constants'
import { BERTH_DIMENSIONS } from '../layers/berths/constants'

type TransformerResizeAngle =
  | 'middle-left'
  | 'middle-right'
  | 'top-left'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-center'
  | 'bottom-right'

interface TransformerOptions {
  resizeAngles?: TransformerResizeAngle[]
  transformerFor: React.RefObject<Konva.Node>
  stagePosition: Vector2d
  refreshDeps: unknown[]
  onResize?: (width: number, height: number) => void
  onRotate?: (x: number, y: number, rotation: number) => void
  currentRotation?: number
  currentWidth?: number
  currentHeight?: number
  maxResizeWidth?: number
  minResizeWidth?: number
}

export const useTransformer = ({
  resizeAngles,
  transformerFor,
  stagePosition,
  refreshDeps,
  currentHeight,
  currentWidth,
  minResizeWidth,
  maxResizeWidth,
  currentRotation,
  onRotate,
  onResize,
}: TransformerOptions) => {
  const transformerRef = useRef<Konva.Transformer>(null)

  const handleTransformEnd = () => {
    if (!transformerFor.current) return

    if (onRotate && currentRotation !== Math.round(transformerFor.current.rotation())) {
      return onRotate(
        transformerFor.current.x(),
        transformerFor.current.y(),
        transformerFor.current.rotation(),
      )
    }

    const width = transformerFor.current.width() * transformerFor.current.scaleX()
    const height = transformerFor.current.height() * transformerFor.current.scaleY()

    if (onResize && (currentWidth !== Math.round(width) || currentHeight !== Math.round(height))) {
      transformerFor.current.scaleX(1)
      transformerFor.current.scaleY(1)
      return onResize(width, height)
    }
  }

  const transformerProps: Konva.TransformerConfig = {
    enabledAnchors: resizeAngles ?? [],
    boundBoxFunc: (oldBox, newBox) => {
      const _maxX = YARD_DIMENSIONS.width - newBox.width
      const x = stagePosition.x - newBox.x

      if (maxResizeWidth && newBox.width > BERTH_DIMENSIONS.MAX_WIDTH) {
        return oldBox
      }

      if (minResizeWidth && newBox.width < BERTH_DIMENSIONS.MIN_WIDTH) {
        return oldBox
      }

      // TODO: Verify if element is outside of other corners of our yard
      return x > 0 ? oldBox : newBox
    },
    transformerRef,
    flipEnabled: false,
    borderEnabled: true,
    ref: transformerRef,
  }

  const refreshTransformer = () => {
    if (transformerRef && transformerRef.current && transformerFor.current) {
      transformerRef.current.nodes([transformerFor.current])
      transformerRef.current.getLayer()?.batchDraw()
    }
  }

  useEffect(() => {
    refreshTransformer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...refreshDeps])

  return { transformerProps, handleTransformEnd, refreshTransformer }
}
