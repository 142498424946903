import {
  CreateGeneralCargoAreaDto,
  GeneralCargoAreaDto,
  GeneralCargoAreaType,
} from '@storage/app/api'
import { GeneralCargoAreaFormProfile } from './general-cargo-area-form.profile'

export const mapFormValuesToCreateGeneralCargoAreaDto = ({
  name,
  type,
  geometry,
  locations,
}: GeneralCargoAreaFormProfile): CreateGeneralCargoAreaDto => ({
  name,
  type: type ?? GeneralCargoAreaType.Warehouse,
  geometry: geometry!,
  locations: locations?.filter(l => l.name.trim()).map(l => l.name) ?? [],
})

export const mapFormValuesToUpdateGeneralCargoAreaDto = ({
  id,
  name,
  type,
  locations,
  geometry,
}: GeneralCargoAreaFormProfile): GeneralCargoAreaDto => ({
  id: id!,
  name,
  type: type ?? GeneralCargoAreaType.Warehouse,
  geometry: geometry!,
  locations,
})

export const mapGeneralCargoAreaDtoToFormValues = ({
  id,
  name,
  type,
  geometry,
  locations,
}: GeneralCargoAreaDto): GeneralCargoAreaFormProfile => ({
  id,
  name,
  type,
  geometry,
  locations: locations,
})
