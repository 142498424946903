import { RotateLeft } from '@mui/icons-material'
import { Avatar, Chip, IconButton, Tooltip, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { DangerousGoodsStackingRuleDto } from '@storage/app/api'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { EditIcon, useMinimalsTheme } from '@tom-ui/ui'
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useMemo } from 'react'

const DGStackingRulesTable = observer(() => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const { dgStackingRulesTableUIStore } = useStores()
  const { dialogUtilStore } = dgStackingRulesTableUIStore

  const renderInheritedText = useCallback(
    () => (
      <Typography variant='body2'>
        {t('inheritedFromParent', 'Inherited from parent class')}
      </Typography>
    ),
    [t],
  )

  const isChildRow = useCallback((row: any) => row.id.includes('.'), [])

  const checkInheritance = useCallback(
    (row: any, table: any, key: keyof DangerousGoodsStackingRuleDto) => {
      if (isChildRow(row)) {
        const parentRow = table.getRow(row.id.split('.')[0])
        return parentRow && row.original[key] === parentRow.original[key]
      }
      return false
    },
    [isChildRow],
  )

  const renderMaxStack = useCallback(
    ({ row, table }: any) => {
      if (checkInheritance(row, table, 'maxStack')) {
        return renderInheritedText()
      }
      return row.original.maxStack !== null ? <Avatar>{row.original.maxStack}</Avatar> : null
    },
    [checkInheritance, renderInheritedText],
  )

  const renderIsStackingOnTopAllowed = useCallback(
    ({ row, table }: any) => {
      if (checkInheritance(row, table, 'isStackingContainerOnTopAllowed')) {
        return renderInheritedText()
      }
      return row.original.isStackingContainerOnTopAllowed !== null ? (
        <Chip
          label={row.original.isStackingContainerOnTopAllowed ? t('yes', 'Yes') : t('no', 'No')}
          color={row.original.isStackingContainerOnTopAllowed ? 'secondary' : 'error'}
        />
      ) : null
    },
    [checkInheritance, renderInheritedText, t],
  )

  const renderImoClass = useCallback(
    ({ row }: any) => (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {isChildRow(row) && <Box sx={{ width: '20px' }} />}
        <Chip label={row.original.imoClass} />
      </Box>
    ),
    [isChildRow],
  )

  const columns = useMemo<MRT_ColumnDef<DangerousGoodsStackingRuleDto>[]>(
    () => [
      {
        accessorKey: 'imoClass',
        header: t('imoClass', 'IMO Class'),
        Cell: renderImoClass,
      },
      {
        accessorKey: 'maxStack',
        header: t('maxStack', 'Max Stack'),
        Cell: renderMaxStack,
      },
      {
        accessorKey: 'isStackingContainerOnTopAllowed',
        header: t('isStackingContainerOnTopAllowed', 'Is stacking any container on top allowed?'),
        Cell: renderIsStackingOnTopAllowed,
      },
    ],
    [t, renderImoClass, renderMaxStack, renderIsStackingOnTopAllowed],
  )

  const renderRowActions = useCallback(
    ({ row }: any) => [
      <Box key={0} sx={{ minWidth: '120px' }}>
        <Tooltip title={t('clearRule', 'Clear Rule')}>
          <IconButton
            aria-label='delete'
            onClick={() => dialogUtilStore.toggleDialog('Delete', row.original.id)}
          >
            <RotateLeft />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('edit', 'Edit')}>
          <IconButton
            aria-label='edit'
            onClick={() => dialogUtilStore.toggleDialog('Edit', row.original.id)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Box>,
    ],
    [dialogUtilStore, t],
  )

  useEffect(() => {
    dgStackingRulesTableUIStore.loadAllDGStackingRules()
  }, [dgStackingRulesTableUIStore])

  return (
    <MaterialReactTable
      initialState={{ expanded: true }}
      getSubRows={originalRow => originalRow.subClasses}
      muiTableHeadCellProps={{
        sx: { backgroundColor: theme.palette.background.neutral },
      }}
      columns={columns}
      data={dgStackingRulesTableUIStore.dgStackingRules}
      enableSorting={false}
      autoResetPageIndex={false}
      enablePagination={false}
      enableBottomToolbar={false}
      enableColumnActions={false}
      enableTopToolbar={false}
      enableExpanding
      enableRowActions
      renderRowActions={renderRowActions}
      positionActionsColumn='last'
      displayColumnDefOptions={{
        'mrt-row-actions': {
          header: '',
          muiTableBodyCellProps: { align: 'right' },
        },
        'mrt-row-expand': {
          muiTableHeadCellProps: {
            sx: { display: 'none' },
          },
          muiTableBodyCellProps: {
            sx: { display: 'none', padding: 0 },
          },
        },
      }}
    />
  )
})

export default DGStackingRulesTable
