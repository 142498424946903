import {
  ContainerSlotDto,
  ContainerSlotPositionType,
  VesselBaySlotDto,
  VesselBayType,
} from '@operations/app/api'

export const rowSeq = (maxRow: number, hasZero: boolean) => {
  const rowsAmount: number = maxRow + (hasZero ? 1 : 0) + (maxRow % 2 == 0 ? 0 : 1)
  const leftIndx: number = maxRow % 2 == 0 ? maxRow : maxRow + 1

  const rowIndexz = (i: number): number => {
    if (i < Math.floor(rowsAmount / 2)) return leftIndx - i * 2

    if (i == Math.floor(rowsAmount / 2) && hasZero) return 0

    return hasZero ? (i - Math.floor(rowsAmount / 2)) * 2 - 1 : 1 - leftIndx + 2 * i
  }

  return Array.from(Array(rowsAmount).keys()).map(i => rowIndexz(i))
}

export const tierSeq = (start: number, count: number): number[] =>
  Array.from(Array(count).keys())
    .map(x => count - x + start - 1)
    .filter(x => x % 2 == 0)

export const deckSeq = (deckHeight: number, deckStartAt: number): number[] =>
  Array.from(Array(deckHeight).keys()).map(x => (deckHeight - (x + 1)) * 2 + deckStartAt)

export const getRelatedEvenBay = (bay: number, bayType: VesselBayType): number => {
  switch (bayType) {
    case VesselBayType.Forty:
    case VesselBayType.TwentySingle:
      return bay

    case VesselBayType.TwentyBack:
      return bay - 1

    case VesselBayType.TwentyFront:
      return bay + 1
  }
}

export const getRelatedBays = (bay: number, bayType: VesselBayType): number[] => {
  const bays = [bay]

  if (bayType == VesselBayType.Forty) {
    bays.push(bay + 1)
    bays.push(bay - 1)
  } else if (bayType == VesselBayType.TwentyFront) {
    bays.push(bay + 2)
  }
  if (bayType == VesselBayType.TwentyBack) {
    bays.push(bay - 2)
  }

  return bays
}

export const getBarotiText = (bay: number, row: number, tier: number): string => {
  return `${bay.toString().padStart(2, '0')}${row.toString().padStart(2, '0')}${tier
    .toString()
    .padStart(2, '0')}`
}

export const LoadingColors = ['#E9FCD4', '#00AB5552', '#FFF7CD', '#f2c846', '#9ed1ca']

export const isFull = (c?: ContainerSlotDto | null): boolean => c?.isEmpty === false

export const isEmpty = (c?: ContainerSlotDto | null): boolean => c?.isEmpty === true

export const isDangerous = (c?: ContainerSlotDto | null): boolean => c?.isDangerous === true

export const isReefer = (c?: ContainerSlotDto | null): boolean => c?.isReefer === true

export const isOutOfGauge = (c?: ContainerSlotDto | null): boolean => c?.isOOG === true

export const isHighCube = (c?: ContainerSlotDto | null): boolean => c?.isHighCube === true

export const isDryContainer = (c?: ContainerSlotDto | null): boolean => c?.isDryContainer === true

export const isFlatRack = (c?: ContainerSlotDto | null): boolean => c?.isFlatRack === true

export const is20Ft = (c?: ContainerSlotDto | null): boolean =>
  c != null && c.containerPositionType == ContainerSlotPositionType.Twenty

export const hasActiveHolds = (c?: ContainerSlotDto | null): boolean =>
  c?.workInstruction?.hasActiveHolds === true

export const hasTransit = (s: VesselBaySlotDto): boolean => s.remainOnBoardContainer != null

export const hasLoading = (s: VesselBaySlotDto): boolean => s.outboundContainer != null

export const hasDischarge = (s: VesselBaySlotDto): boolean => s.inboundContainer != null

export const isJobAssignedByEquipment = (
  c?: ContainerSlotDto | null | undefined,
  equipmentId?: number,
) => c?.workInstruction != null && equipmentId != null && c?.workInstruction?.craneId == equipmentId
