import ReportStep, { ReportSteps } from '@billing/app/models/report-step.enum'
import ReportType from '@billing/app/models/report-type.enum'
import { useBillingServiceStore } from '@billing/AppProvider'
import { StepperWithAlternativeLabel } from '@billing/components/stepper/Stepper'
import { Header } from '@tom-ui/ui'
import { useTranslate } from '@tolgee/react'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'

export const ReportHeaderContainer = observer(() => {
  const { reportsUIStore, vesselReportUIStore, storageReportUIStore } = useBillingServiceStore()
  const { t } = useTranslate()

  const getSubtitle = () => {
    if (reportsUIStore.currentStep === ReportStep.Filter && reportsUIStore.currentType) {
      return t(reportsUIStore.currentType)
    }
    if (reportsUIStore.currentStep === ReportStep.Report) {
      if (
        reportsUIStore.currentType === ReportType.VesselList &&
        vesselReportUIStore.selectedVesselVisitId
      ) {
        const vesselVisit = vesselReportUIStore.vesselVisitsOptions.find(
          o => o.id === vesselReportUIStore.selectedVesselVisitId,
        )

        if (vesselVisit)
          return `${vesselVisit.carrierName} - ${t(
            'voyageNo',
            'Voyage no.',
          )}: ${vesselReportUIStore.getVoyageDisplay(vesselVisit)}`
      } else if (reportsUIStore.currentType === ReportType.Storage) {
        return `${t(storageReportUIStore.storageReportMode!)} -  ${new Date(
          storageReportUIStore.from!.toISOString(),
        ).toLocaleDateString(dayjs.locale())} ${t('to', 'to')} ${new Date(
          storageReportUIStore.to!.toISOString(),
        ).toLocaleDateString(dayjs.locale())}`
      }
    }
  }

  return (
    <>
      <Header title={t('reports', 'Reports')} subTitle={getSubtitle()} />
      <StepperWithAlternativeLabel activeStep={reportsUIStore.currentStep} steps={ReportSteps} />
    </>
  )
})
