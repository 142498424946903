import { AppViewStore } from '@planning/stores/AppViewStore'
import { AxiosResponse } from 'axios'

export const onFulfilled = (appStore: AppViewStore) => {
  return (response: AxiosResponse<any>) => {
    appStore.hideLoader()

    return response
  }
}

export const onRejected = (appStore: AppViewStore) => {
  return (error: Error) => {
    appStore.hideLoader()
    throw error
  }
}
