import {
  CarrierType,
  EquipmentType,
  JobDto,
  OperationType,
  WorkInstructionJobDto,
} from '@operations/app/api'

export const operatorPages = {
  tt: 'tt',
  rs: 'rs',
  rtg: 'rtg',
  ech: 'ech',
  sts: 'sts',
  rmg: 'rmg',
  fl: 'fl',
}

export const pageEquipmentType = new Map([
  [operatorPages.tt, EquipmentType.Tt],
  [operatorPages.rs, EquipmentType.Rs],
  [operatorPages.rtg, EquipmentType.Rtg],
  [operatorPages.ech, EquipmentType.Ech],
  [operatorPages.sts, EquipmentType.Sts],
  [operatorPages.rmg, EquipmentType.Rmg],
  [operatorPages.fl, EquipmentType.Fl],
])

export const getEquipmentTypesSelectedByPage = (page?: string): EquipmentType[] => {
  let operators: EquipmentType[] = []

  if (!page) return operators

  const types = page.split('&').map(x => x.toLowerCase())

  operators = Object.keys(operatorPages)
    .filter(key => (page === 'all' ? true : types.includes(key)))
    .map(x => pageEquipmentType.get(x)!)

  return operators.length ? operators : []
}

export interface EquipmentRadioOption {
  id: number
  title: string
  subtitle?: string
  type: EquipmentType
}

export enum JobTypes {
  none = 'none',
  all = 'all',
  vessel = 'vessels',
  truck = 'externalTrucks',
  train = 'train',
  internal = 'internal',
  service = 'service',
}

export const doesJobOperationTypeOrCarrierMatchesJobType = (
  jobType: JobTypes,
  operationType?: OperationType | null,
  carrierVisitType?: CarrierType | null,
): boolean => {
  switch (jobType) {
    case JobTypes.truck:
    case JobTypes.vessel:
    case JobTypes.train:
      return carrierVisitType === convertJobTypeToCarrierType(jobType)
    case JobTypes.internal:
      return operationType === OperationType.Internal
    case JobTypes.service:
      return operationType === OperationType.Service
    case JobTypes.all:
      return true

    default:
      return false
  }
}

export const convertJobTypeToCarrierType = (jobType: JobTypes): CarrierType | null => {
  switch (jobType) {
    case JobTypes.truck:
      return CarrierType.Truck
    case JobTypes.vessel:
      return CarrierType.Vessel
    case JobTypes.train:
      return CarrierType.Train

    default:
      return null
  }
}

export const getJobAmountByType = (jobType: JobTypes, jobs: JobDto[]): number => {
  return jobs.filter(x =>
    doesJobOperationTypeOrCarrierMatchesJobType(jobType, x.operationType, x.carrierVisit?.type),
  ).length
}

export const getJobAmountByTypeAndSize = (
  jobType: JobTypes,
  jobs: JobDto[],
  length?: number,
): number => {
  return jobs.filter(
    x =>
      doesJobOperationTypeOrCarrierMatchesJobType(jobType, x.operationType, x.carrierVisit?.type) &&
      (!length || x.container?.length === length),
  ).length
}

export const getWorkInstructionJobAmountByType = (
  jobType: JobTypes,
  jobs: WorkInstructionJobDto[],
): number => {
  return jobs.filter(x =>
    doesJobOperationTypeOrCarrierMatchesJobType(jobType, x.operationType, x.carrierVisit?.type),
  ).length
}
