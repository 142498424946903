import { Box, LinearProgress, Paper, styled } from '@mui/material'
import {
  DataGrid,
  GridColDef,
  GridRowParams,
  GridRowSelectionModel,
  GridSlotsComponent,
  GridToolbarExport,
  GridValidRowModel,
  MuiEvent,
} from '@mui/x-data-grid'
import { usePlanningStore } from '@planning/AppProvider'
import { IPaginatedStoreWithItems, SortingModel } from '@planning/stores/PaginatedStore'
import { observer } from 'mobx-react-lite'
import { ReactNode } from 'react'

interface Props {
  store: IPaginatedStoreWithItems<any>
  columns: GridColumn[]
  toolBar?: ReactNode
  rowsPerPageOptions?: number[]
  onRowClick?: (params: any) => void | Promise<void>
  getRowId?: (row: any) => any
  getRowHeight?: (row: any) => any
  height?: number
  checkboxSelection?: boolean
  keepNonExistentRowsSelected?: boolean
  selectionModel?: GridRowSelectionModel
  onSelectionModelChange?: (selectionModel: any) => void
  isRowSelectable?: (params: GridRowParams) => boolean
  onCellClick?: (params: any, event: MuiEvent<React.MouseEvent>) => void
  isExportable?: boolean
  exportFileName?: string
  padding?: string
}

export type GridColumn<R extends GridValidRowModel = any, V = any> = GridColDef<R, V> & {
  orderBy?: string
}

const SORTING_MODEL_DESCENDING = 'desc'
const SORTING_MODEL_ASCENDING = 'asc'

export const PaginatedTable = observer(
  ({
    store,
    columns,
    toolBar,
    rowsPerPageOptions,
    onRowClick,
    height,
    getRowId,
    getRowHeight,
    checkboxSelection,
    keepNonExistentRowsSelected,
    selectionModel,
    onSelectionModelChange,
    isRowSelectable,
    onCellClick,
    isExportable,
    exportFileName,
    padding,
  }: Props) => {
    const { appViewStore } = usePlanningStore()

    const componentsWithoutExport: Partial<GridSlotsComponent> = { LoadingOverlay: LinearProgress }

    const componentsWithExport: Partial<GridSlotsComponent> = {
      LoadingOverlay: LinearProgress,
      Toolbar: GridToolbarExport,
    }

    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          height: height,
          flexDirection: 'column',
          padding: padding ?? '1.5rem !important',
        }}
      >
        {toolBar}
        <Paper variant='elevation' elevation={1}>
          <DataGrid
            autoHeight={!height}
            checkboxSelection={checkboxSelection}
            onRowSelectionModelChange={onSelectionModelChange}
            rowSelectionModel={selectionModel}
            keepNonExistentRowsSelected={keepNonExistentRowsSelected}
            rows={store.pageItems}
            columns={columns}
            paginationModel={{ pageSize: store.pageSize, page: store.currentPageIndex }}
            rowCount={store.totalCount}
            paginationMode='server'
            getRowId={getRowId}
            getRowHeight={getRowHeight}
            loading={appViewStore.isLoading}
            components={isExportable ? componentsWithExport : componentsWithoutExport}
            componentsProps={{
              toolbar: {
                csvOptions: {
                  fileName: exportFileName,
                },
                printOptions: {
                  fileName: exportFileName,
                },
              },
            }}
            onPaginationModelChange={(model: { page: number; pageSize: number }) => {
              if (store.currentPageIndex !== model.page) store.setCurrentPageIndex(model.page)
              if (store.pageSize !== model.pageSize) store.setPageSize(model.pageSize)
            }}
            pageSizeOptions={rowsPerPageOptions ?? [10, 25, 50]}
            isCellEditable={() => false}
            isRowSelectable={isRowSelectable ? isRowSelectable : () => false}
            disableColumnFilter
            disableColumnMenu
            onRowClick={onRowClick}
            sortingMode='server'
            sortModel={
              store.sortingModel.orderBy && store.sortingModel.field
                ? [
                    {
                      field: store.sortingModel.field,
                      sort: store.sortingModel.isDescending
                        ? SORTING_MODEL_DESCENDING
                        : SORTING_MODEL_ASCENDING,
                    },
                  ]
                : []
            }
            onSortModelChange={model => {
              if (!model.length) {
                store.setSortingModel({
                  field: '',
                  orderBy: undefined,
                  isDescending: false,
                })
                return
              }

              const item = model[0]
              const column = columns.find(c => c.field === item.field)
              store.setSortingModel({
                field: column?.field,
                orderBy: column?.orderBy,
                isDescending: item.sort === SORTING_MODEL_DESCENDING,
              } as SortingModel<any>)
            }}
            onCellClick={onCellClick}
          />
        </Paper>
      </Box>
    )
  },
)

export const StickyHeaderTable = observer(
  styled(PaginatedTable)(({ theme }) => ({
    '& .MuiDataGrid-columnHeaders': {
      position: 'sticky',
      backgroundColor: theme.palette.background.paper,
      // Display header above grid data, but below any popups
      zIndex: theme.zIndex.mobileStepper - 1,
    },
    '& .MuiDataGrid-virtualScroller': {
      // Undo the margins that were added to push the rows below the previously fixed header
      marginTop: '0 !important',
    },
    '& .MuiDataGrid-main': {
      // Not sure why it is hidden by default, but it prevented the header from sticking
      overflow: 'visible',
    },
  })),
)
