import { AxiosResponse } from 'axios'
import { LengthMeasurementUnit, WeightMeasurementUnit } from './api'

export async function axiosUnwrapper<T>(axiosResponse: Promise<AxiosResponse<T>>): Promise<T> {
  const { data } = await axiosResponse
  return data
}

export const isProblemDetails = (object: any) => {
  return 'detail' in object && 'status' in object
}

export const later = (delay: number) => {
  return new Promise(function (resolve) {
    setTimeout(resolve, delay)
  })
}

export const simplifyLengthMeasurementUnit = (unit?: LengthMeasurementUnit | null) => {
  switch (unit) {
    case LengthMeasurementUnit.Millimeters:
      return 'mm'
    case LengthMeasurementUnit.Centimeter:
      return 'cm'
    case LengthMeasurementUnit.Meter:
      return 'm'
    case LengthMeasurementUnit.Feet:
      return 'ft'
    case LengthMeasurementUnit.Inches:
      return 'in'
    default:
      return ''
  }
}

export const simplifyWeightMeasurementUnit = (unit?: WeightMeasurementUnit | null) => {
  switch (unit) {
    case WeightMeasurementUnit.Kilogram:
      return 'kg'
    case WeightMeasurementUnit.Ton:
      return 't'
    default:
      return ''
  }
}
