import { createSvgIcon } from '@mui/material'

export const ChevronUpIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M4.99872 13.8375C4.9977 13.5349 5.13373 13.2481 5.36872 13.0575L11.3687 8.22749C11.7377 7.92417 12.2697 7.92417 12.6387 8.22749L18.6387 13.2275C19.064 13.581 19.1222 14.2122 18.7687 14.6375C18.4153 15.0627 17.784 15.121 17.3587 14.7675L11.9987 10.2975L6.63872 14.6175C6.339 14.8672 5.92144 14.9198 5.56915 14.7522C5.21686 14.5847 4.99417 14.2276 4.99872 13.8375Z'
      fill='currentColor'
    />
  </svg>,
  'ChevronUpIcon',
)
