import { useBillingServiceStore } from '@billing/AppProvider'
import { useTranslate } from '@tolgee/react'
import { BinIcon, ConfirmationDialog, IconButton } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { PackageForm } from '../components/form/PackageForm'
import { PackageFormMapper } from '../components/form/package-form.mapper'
import { PackageFormProfile } from '../components/form/package-form.profile'

export const PackagesDialogContainer = observer(() => {
  const { t } = useTranslate()
  const { appStore, packageStore, packageDialogUIStore } = useBillingServiceStore()

  const [isSubmitAnother, setIsSubmitAnother] = useState(false)
  const { closeSnackbar } = useSnackbar()

  const handleSubmit = async (formValues: PackageFormProfile) => {
    closeSnackbar()

    if (packageDialogUIStore.formType === 'Add') {
      appStore.showAlertForCancellableRequest(
        'success',
        `${formValues.name} ${t('isBeingCreated', 'is being created')}`,
        () =>
          appStore.triggerRequestWithoutLoader(
            async () =>
              await packageStore.add(PackageFormMapper.mapFormValuesToCreateDto(formValues)),
          ),
        `${formValues.name} ${t('creationCancelled', 'creation cancelled')}`,
      )
    }

    if (!isSubmitAnother) {
      packageDialogUIStore.toggle(packageDialogUIStore.editingEntity?.id)
    }

    setIsSubmitAnother(false)
  }

  const handleClose = () => {
    packageDialogUIStore.toggle(packageDialogUIStore.editingEntity?.id)
  }

  const handleToggleDelete = () => {
    packageDialogUIStore.toggle(packageDialogUIStore.editingEntity?.id)
    packageDialogUIStore.toggleDelete()
  }

  const handleCloseDelete = () => {
    packageDialogUIStore.toggleDelete()
  }

  const handleDelete = async () => {
    if (packageDialogUIStore.editingEntity) {
      const packageName = packageDialogUIStore.editingEntity.name
      await packageStore.delete(packageDialogUIStore.editingEntity.id)
      handleCloseDelete()

      appStore.setShowAlert('success', `${packageName} ${t('deleted', 'deleted')}`)
    }
  }

  return (
    <>
      <ConfirmationDialog
        open={packageDialogUIStore.isOpen}
        title={`${
          packageDialogUIStore.formType === 'Add' ? t('addNew', 'Add new') : t('edit', 'Edit')
        } ${t('package', 'Package')}`}
        primaryActionText={t('submit', 'Submit')}
        secondaryActionText={t('submitAndCreateAnother', 'Submit & create another')}
        onSecondaryAction={
          packageDialogUIStore.formType === 'Add' ? () => setIsSubmitAnother(true) : undefined
        }
        primaryActionDisabled={packageDialogUIStore.formType === 'Edit'}
        secondaryActionColor='info'
        secondaryType='submit'
        closeLabel={t('cancel', 'Cancel')}
        formId='package-form'
        maxWidth='sm'
        rightRenderOption={
          packageDialogUIStore.formType === 'Edit' ? (
            <IconButton variant='contained' color='default' onClick={handleToggleDelete}>
              <BinIcon />
            </IconButton>
          ) : undefined
        }
        onClose={handleClose}
        hasBorderTop
      >
        <PackageForm
          id='package-form'
          packageItem={packageDialogUIStore.editingEntity}
          onSubmit={handleSubmit}
        />
      </ConfirmationDialog>

      <ConfirmationDialog
        open={packageDialogUIStore.isDeleteOpen}
        title={t('deletePackage', 'Delete package')}
        message={t(
          'entityWillBeDeleted',
          '{name} will be deleted and you cannot undo this action!',
          {
            name: packageDialogUIStore.editingEntity?.name ?? t('package'),
          },
        )}
        primaryActionText={t('delete', 'Delete')}
        closeLabel={t('keepEditing', 'Keep editing')}
        maxWidth='xs'
        blockClickOutside
        onConfirm={handleDelete}
        onClose={handleToggleDelete}
      />
    </>
  )
})
