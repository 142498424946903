import { ContainerTurnoversApi, DeviceOperationMode } from '@storage/app/api'
import { createApiClient } from '@storage/app/http-client'

class StackOutSequenceService {
  httpClient = createApiClient(ContainerTurnoversApi)

  async stackOutSequenceForTopOperation(reference: string) {
    const { data } = await this.httpClient.getStackOutSequence({
      referenceNumber: reference,
      mode: DeviceOperationMode.Top,
    })

    return data
  }

  async stackOutSequenceForBayOperation(reference: string) {
    const { data } = await this.httpClient.getStackOutSequence({
      referenceNumber: reference,
      mode: DeviceOperationMode.Bay,
    })

    return data
  }
}

export default new StackOutSequenceService()
