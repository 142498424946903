import NorthWestIcon from '@mui/icons-material/NorthWest'
import { Box, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { OrderResponseDto, OrderStatus, TruckDto } from '@planning/app/api'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { PickUpOrderCard } from './PickUpOrderCard'

type Props = {
  pickUpOrder: OrderResponseDto
  carriers: TruckDto[]
  withBorderLineStyle?: boolean
}

export const PickUpOrderStateCard: FC<Props> = observer(
  ({ pickUpOrder, carriers, withBorderLineStyle = true }) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()

    return (
      <Stack
        direction='row'
        bgcolor={theme.palette.grey[100]}
        sx={{ display: 'flex', height: '100%', minHeight: '0' }}
      >
        <Box
          display='flex'
          alignItems='center'
          sx={{
            borderWidth: withBorderLineStyle ? '1px 0px 1px 1px' : '0px 1px 0px 0px',
            borderStyle: 'solid',
            borderColor: `${theme.palette.grey[300]}`,
            p: '8px 16px 8px 16px',
          }}
        >
          <Stack>
            <Typography
              color={pickUpOrder.status === OrderStatus.Fulfilled ? 'secondary' : ''}
              sx={{ margin: 0, padding: 0, display: 'flex', placeContent: 'center' }}
            >
              <NorthWestIcon />
            </Typography>

            <Typography
              variant='caption'
              fontWeight='bold'
              color={pickUpOrder.status === OrderStatus.Fulfilled ? 'secondary' : ''}
            >
              {t('pick', 'Pick').toUpperCase()}
            </Typography>
          </Stack>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <PickUpOrderCard
            pickUpOrder={pickUpOrder}
            carriers={carriers}
            withBorderLineStyle={withBorderLineStyle}
          />
        </Box>
      </Stack>
    )
  },
)
