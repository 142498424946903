import { createSvgIcon } from '@mui/material'

export const CloseIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M13.4086 11.9991L17.7045 7.71268C18.0962 7.32088 18.0962 6.68565 17.7045 6.29385C17.3127 5.90205 16.6776 5.90205 16.2859 6.29385L12 10.5903L7.71414 6.29385C7.3224 5.90205 6.68726 5.90205 6.29551 6.29385C5.90377 6.68565 5.90377 7.32088 6.29551 7.71268L10.5914 11.9991L6.29551 16.2856C6.10638 16.4732 6 16.7286 6 16.995C6 17.2614 6.10638 17.5168 6.29551 17.7044C6.4831 17.8936 6.73845 18 7.00483 18C7.27121 18 7.52656 17.8936 7.71414 17.7044L12 13.408L16.2859 17.7044C16.4734 17.8936 16.7288 18 16.9952 18C17.2616 18 17.5169 17.8936 17.7045 17.7044C17.8936 17.5168 18 17.2614 18 16.995C18 16.7286 17.8936 16.4732 17.7045 16.2856L13.4086 11.9991Z'
      fill='currentColor'
    />
  </svg>,
  'CloseIcon',
)
