import { ContainerTurnoverFilterFormMapper } from '@storage/pages/container-turnovers/components/container-turnovers-filter-form'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  ContainersAllocationByFilterFlow,
  SingleContainerAllocationFlow,
  YardManagementFlows,
} from '../flows'
import { YardManagementFlowHandlerHook } from '../flows/flow.base'
import { YardViewStore } from '../stores/YardViewStore'
import { useYardManagementFlow } from './use-yard-management-flow.hook'

const flowMappers: Record<YardManagementFlows, YardManagementFlowHandlerHook> = {
  ContainersAllocationByFilter: (yardViewStore: YardViewStore) => {
    const [queryParams] = useSearchParams()

    useEffect(() => {
      const numberOfContainers = queryParams.get(
        ContainersAllocationByFilterFlow.Keys.numberOfContainers,
      )

      const numberOfContainersNormalized = Number(numberOfContainers)

      if (!numberOfContainers || isNaN(numberOfContainersNormalized)) return

      const filter = ContainerTurnoverFilterFormMapper.mapQueryParamsToFilterDto(
        Object.fromEntries(queryParams.entries()),
      )

      yardViewStore.planningStore.setFilter(filter, numberOfContainersNormalized)
    }, [queryParams, yardViewStore.planningStore])
  },
  SingleContainerAllocation: (yardViewStore: YardViewStore) => {
    const [queryParams] = useSearchParams()

    useEffect(() => {
      const containerTurnoverId = queryParams.get(
        SingleContainerAllocationFlow.Keys.containerTurnoverId,
      )

      if (containerTurnoverId) {
        yardViewStore.planningStore.setContainerTurnover(containerTurnoverId)
      }
    }, [queryParams, yardViewStore.planningStore])
  },
}

interface Options {
  yardViewStore: YardViewStore
}

export const useHandleYardFlow = ({ yardViewStore }: Options) => {
  const flow = useYardManagementFlow()

  if (!flow) return

  flowMappers[flow](yardViewStore)
}
