import { TextField } from '@mui/material'
import { Box } from '@mui/system'
import { useTranslate } from '@tolgee/react'
import { ConfirmationDialog, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { damageReportStore } from '../../stores/DamageReportStore'

export const DamageReportDialog = observer(() => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const { editingDamageReport } = damageReportStore

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    reset(editingDamageReport)
  }, [reset, editingDamageReport])

  const data = useWatch({ control })
  damageReportStore.setDamageReport(data)

  const handleClose = () => {
    damageReportStore.closeDialog()
    reset()
  }

  const onSubmit = () => {
    damageReportStore.onSubmit()
    reset()
  }

  return (
    <ConfirmationDialog
      open={damageReportStore.isOpen}
      title={t('recordDamage', 'Record damage')}
      primaryActionText={t('submit', 'Submit')}
      closeLabel={t('cancel', 'Cancel')}
      formId='damage-report-form'
      maxWidth='sm'
      onClose={handleClose}
      mobile
      hasBorderTop
    >
      <Box
        id='damage-report-form'
        component='form'
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit(onSubmit)}
        pb={theme.customSpacing.xs}
      >
        <TextField
          fullWidth
          multiline
          required
          rows={4}
          label={t('description', 'Description')}
          variant='outlined'
          {...register('description', { required: true })}
          error={!!errors.description}
          helperText={errors.description ? t('fieldIsRequired', 'Field is required.') : ''}
          sx={{ marginTop: '1rem' }}
          name={'description'}
        />
      </Box>
    </ConfirmationDialog>
  )
})
