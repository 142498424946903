import { Box, SxProps, Theme } from '@mui/material'
import { FC, ReactElement } from 'react'

interface Props {
  children: ReactElement | ReactElement[]
  sx?: SxProps<Theme>
}

export const VerticalOverflowBox: FC<Props> = ({ children, sx }: Props) => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyItems='flex-start'
      alignItems='flex-start'
      sx={sx}
      overflow='auto'
    >
      {children}
    </Box>
  )
}
