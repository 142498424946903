import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { GeneralCargoInventoryDto } from '@storage/app/api'
import { useHubConnection } from '@storage/hooks/use-hub-connection.hook'
import { useStores } from '@storage/hooks/use-stores.hook'
import { yardManagementHubConnection } from '@storage/hubs/yard-management.hub-connection'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import { GeneralCargoInventoryPreviewColumn } from './GeneralCargoInventoryPreviewColumn'
import GeneralCargoInventoryStockTable from './GeneralCargoInventoryStockTable'

const GeneralCargoInventoryTableNew = observer(() => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  useHubConnection(yardManagementHubConnection)

  const { generalCargoInventoryStore, generalCargoInventoryUIStore } = useStores()

  const overwriteMinimalTableHeadCellStyle = {
    sx: {
      backgroundColor: theme.palette.background.neutral,
    },
  }

  const columns = useMemo<MRT_ColumnDef<GeneralCargoInventoryDto>[]>(
    () => [
      {
        accessorKey: 'customer.name',
        header: t('customer', 'Customer'),
      },
      {
        accessorKey: 'commodity.name',
        header: t('commodity', 'Commodity'),
      },
      {
        accessorKey: 'lotNumber',
        header: t('lotNumber', 'Lot Number'),
      },
      {
        accessorKey: 'dangerous',
        accessorFn: row => (row.imoClasses.length > 0 ? 'yes' : 'no'),
        header: t('dangerous', 'Dangerous'),
      },
      {
        accessorKey: 'attributes',
        accessorFn: row => (
          <GeneralCargoInventoryPreviewColumn
            items={(row.commodity?.attributes ?? []).map(e => (
              <Box
                key={e.name}
                sx={{ display: 'flex', flexDirection: 'row', gap: '0.2rem', width: '100%' }}
              >
                <Typography
                  color={theme.palette.action.disabled}
                  variant='subtitle2'
                  sx={{ fontWeight: 400 }}
                >
                  {e.name}
                </Typography>
                <Typography variant='subtitle2' sx={{ fontWeight: 400 }}>
                  {e.value}
                </Typography>
              </Box>
            ))}
            preview={1}
          />
        ),
        header: t('attributes', 'Attributes'),
      },
    ],
    [t, theme],
  )

  useEffect(() => {
    const loadGeneralCargoStocks = () => {
      generalCargoInventoryStore.loadByQuery({ withOccupancyOnly: true })
    }

    loadGeneralCargoStocks()
  }, [generalCargoInventoryStore])

  return (
    <Box m={theme.customSpacing.l}>
      <MaterialReactTable
        muiTableHeadCellProps={overwriteMinimalTableHeadCellStyle}
        columns={columns}
        data={generalCargoInventoryUIStore.generalCargoInventory}
        autoResetPageIndex={false}
        enableTopToolbar={false}
        enableColumnActions={false}
        enableStickyHeader={true}
        enableStickyFooter={true}
        muiTableDetailPanelProps={() => ({
          sx: theme => ({
            backgroundColor: theme.palette.background.neutral,
          }),
        })}
        muiExpandButtonProps={({ row }) => ({
          sx: {
            transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
            transition: 'transform 0.2s',
          },
        })}
        renderDetailPanel={({ row }) =>
          row.original.occupancies ? (
            <GeneralCargoInventoryStockTable
              occupancies={row.original.occupancies}
              imoClasses={row.original.imoClasses}
            />
          ) : null
        }
      />
    </Box>
  )
})

export default GeneralCargoInventoryTableNew
