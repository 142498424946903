import { Box, Tooltip, Typography } from '@mui/material'
import { BinIcon, IconButton, PlusIcon } from '@tom-ui/ui'
import { useTranslate } from '@tolgee/react'
import { Control, FormState, useFieldArray } from 'react-hook-form'
import { ControlledTextField } from '@storage/components/form-controls/ControlledTextField'
import { GeneralCargoAreaFormProfile } from './general-cargo-area-form.profile'
import { GeneralCargoLocationDto } from '@storage/app/api'

interface Props {
  control: Control<GeneralCargoAreaFormProfile>
  formState: FormState<GeneralCargoAreaFormProfile>
  defaultValue: GeneralCargoLocationDto
}

const GCALocationsFormAttribute = ({ control, formState, defaultValue }: Props) => {
  const { t } = useTranslate()

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'locations',
  })

  return (
    <Box>
      <Box sx={{ display: 'flex' }} gap={1} alignItems='center'>
        <Typography variant='subtitle2'>{t('locations', 'Locations')}</Typography>
        <Tooltip title={t('addLocation', 'Add location')}>
          <IconButton onClick={() => append(defaultValue)}>
            <PlusIcon />
          </IconButton>
        </Tooltip>
      </Box>

      {fields.map((field, index) => (
        <Box
          key={field.id}
          sx={{
            display: 'flex',
            gap: 1,
            alignItems: 'flex-start',
            mb: index === fields.length - 1 ? 0 : 1,
          }}
        >
          <ControlledTextField
            name={`locations.${index}.name`}
            formState={formState}
            control={control}
            label={t('location', 'Location')}
            required
            fullWidth
          />
          <IconButton
            disabled={fields.length === 1}
            sx={{ mt: 2, pointerEvents: 'all' }}
            color='error'
            onClick={() => remove(index)}
          >
            <Tooltip title={t('removeLocation', 'Remove location')}>
              <BinIcon />
            </Tooltip>
          </IconButton>
        </Box>
      ))}

      {!fields.length && (
        <Typography variant='body2'>
          {t('noLocationsToDisplay', 'No locations to display')}
        </Typography>
      )}
    </Box>
  )
}
export default GCALocationsFormAttribute
