import { Add, Remove } from '@mui/icons-material'
import { FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton } from '@mui/material'

interface QuantityInputFieldProps {
  label: string
  value: number
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  OnIncrease: () => void
  OnDecrease: () => void
}
const QuantityInputField = ({
  label,
  value,
  onChange,
  OnIncrease,
  OnDecrease,
}: QuantityInputFieldProps) => {
  return (
    <FormControl sx={{ textAlign: 'center' }} variant='outlined'>
      <InputLabel htmlFor='quantity-input'>{label}</InputLabel>
      <OutlinedInput
        sx={{
          '.MuiOutlinedInput-input': {
            textAlign: 'center',
          },
        }}
        id='quantity-input'
        value={value}
        onChange={onChange}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton aria-label='toggle password visibility' edge='end' onClick={OnIncrease}>
              <Add />
            </IconButton>
          </InputAdornment>
        }
        startAdornment={
          <InputAdornment position='start'>
            <IconButton aria-label='toggle password visibility' edge='start' onClick={OnDecrease}>
              <Remove />
            </IconButton>
          </InputAdornment>
        }
        label={label}
      />
    </FormControl>
  )
}

export default QuantityInputField
