import { CustomerDto } from '@billing/app/api'
import { AttributesContainer } from '@billing/components/AttributesContainer/AttributesContainer'
import { Card, CardHeader, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { Tile } from '@tom-ui/ui'

interface Props {
  customer: CustomerDto
  canEdit: boolean
  onClick: (customer: CustomerDto) => void
}
export const Customer = ({ customer, canEdit, onClick }: Props) => {
  const { t } = useTranslate()

  return (
    <Card onClick={() => onClick(customer)} sx={{ cursor: canEdit ? 'pointer' : undefined }}>
      <CardHeader
        title={<Typography variant='h4'>{customer.abbreviation}</Typography>}
        subheader={customer.name}
        sx={{ paddingBottom: theme => theme.customSpacing.m }}
      />
      {customer.address && (
        <AttributesContainer>
          <Tile label={t('address', 'Address')} value={customer.address} />
        </AttributesContainer>
      )}
    </Card>
  )
}
