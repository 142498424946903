import { FormControl, FormHelperText, Stack, TextField, Typography } from '@mui/material'
import { ContainerPositionType, JobDto } from '@operations/app/api'
import { useTranslate } from '@tolgee/react'
import { CustomInputAdornment, useMinimalsTheme } from '@tom-ui/ui'
import { JobInformationOperationalInstructions } from '../JobInformation/JobInformationOperationalInstructions'
import { JobInformationWrapper } from '../JobInformation/JobInformationWrapper'
import { ConfirmPicks } from './ConfirmPicks'
import { ConfirmPickUpProps } from './confirm-pickup.interface'

interface Props {
  job: JobDto
  hasError?: boolean
  currentStep: number
  stepsTotal: number
  handleWeight: (weight?: number) => void
  handlePicks?: (id: string) => void
  DestinationComponent?: React.ComponentType<ConfirmPickUpProps>
}

export const ConfirmWeight = ({
  job,
  hasError,
  currentStep,
  stepsTotal,
  handleWeight,
  handlePicks,
  DestinationComponent,
}: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const handleChangeContainer = (event: any) => {
    const value = event?.target?.value
    handleWeight(value ? +value : undefined)
  }

  return (
    <JobInformationWrapper job={job} currentStep={currentStep} stepsTotal={stepsTotal}>
      {(!currentStep || currentStep === 1) && <JobInformationOperationalInstructions job={job} />}

      <Stack gap={theme.customSpacing.m} marginY={theme.customSpacing.m}>
        <Typography variant='subtitle1'>{t('weight', 'Weight')}</Typography>

        <FormControl fullWidth error={hasError}>
          <TextField
            type='number'
            label={t('grossWeight', 'Gross weight')}
            onChange={handleChangeContainer}
            InputProps={{
              endAdornment: (
                <CustomInputAdornment position='end' color='secondary'>
                  kg
                </CustomInputAdornment>
              ),
            }}
            data-cy='confirm-container-weight-input'
          />
          {hasError && (
            <FormHelperText>
              {t('grossWeightIsMandatory', 'Gross weight is mandatory')}
            </FormHelperText>
          )}
        </FormControl>
      </Stack>
      {job.from.type === ContainerPositionType.Yard && (
        <ConfirmPicks
          handlePicks={handlePicks}
          DestinationComponent={DestinationComponent}
          containerNumber={job.container?.containerNumber ?? ''}
        />
      )}
    </JobInformationWrapper>
  )
}
