import { Box, Button, Tooltip, Typography } from '@mui/material'
import { HoldStatus } from '@planning/app/api'
import { CustomAlert } from '@planning/pages/TallymanV2/Components/CustomAlert'
import { useTranslate } from '@tolgee/react'
import { CheckmarkIcon, CloseButton, EditIcon, IconButton, useMinimalsTheme } from '@tom-ui/ui'
import { FC } from 'react'
import { IInspectContainerFormData } from './InspectContainer'

interface Props {
  order: IInspectContainerFormData
  onCheck?: (order: IInspectContainerFormData) => void
  onRemove?: (order: IInspectContainerFormData) => void
  error?: boolean
  isChecked?: boolean
}

export const GateOutOrderListItem: FC<Props> = ({ order, onCheck, onRemove, isChecked, error }) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const hasActiveHolds = !!order.holds.filter(o => o.status === HoldStatus.Active).length

  const CheckOrEditAction = () => {
    if (!onCheck || hasActiveHolds) return <></>

    return (
      (isChecked && (
        <Tooltip title={t('edit', 'Edit')}>
          <IconButton sx={{ mr: '.75rem' }} onClick={() => onCheck(order)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      )) || (
        <Button
          variant='contained'
          sx={{ mr: '.75rem' }}
          onClick={() => onCheck(order)}
          data-cy={`gate-out-check-order-${order.containerNumber}-btn`}
        >
          {t('check', 'Check')}
        </Button>
      )
    )
  }

  const Actions = () => (
    <Box>
      <CheckOrEditAction />

      {onRemove && <CloseButton onClose={() => onRemove(order)} />}
    </Box>
  )

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: '1rem',
        }}
      >
        <Box display='flex'>
          {isChecked && <CheckmarkIcon sx={{ color: theme.palette.success.main, mr: '.75rem' }} />}
          <Typography variant='subtitle1' color={error ? theme.palette.error.main : 'inherit'}>
            {order.containerNumber}
          </Typography>
        </Box>

        <Actions />
      </Box>
      <Box
        sx={{
          mb: '1rem',
        }}
      >
        {hasActiveHolds && (
          <CustomAlert
            message={t(
              'containerHasHoldsContactControlRoom',
              'Container has holds, contact control room',
            )}
            severity='warning'
          />
        )}
      </Box>
    </>
  )
}
