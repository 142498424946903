import { IconButton, Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import { AllocationRuleTemplateDto } from '@storage/app/api'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { BinIcon, EditIcon, useMinimalsTheme } from '@tom-ui/ui'
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'

const AllocationRuleTemplatesTable = observer(() => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const { allocationRuleTemplatesV2UIStore, weightClassContainerUIStore } = useStores()
  const dialogUtilStore = allocationRuleTemplatesV2UIStore.dialogUtilStore

  const overwriteMinimalTableHeadCellStyle = {
    sx: {
      backgroundColor: theme.palette.background.neutral,
    },
  }

  const columns = useMemo<MRT_ColumnDef<AllocationRuleTemplateDto>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('name', 'Name'),
      },
    ],
    [t],
  )

  useEffect(() => {
    allocationRuleTemplatesV2UIStore.loadAllocationRuleTemplates()
  }, [allocationRuleTemplatesV2UIStore])

  useEffect(() => {
    weightClassContainerUIStore.loadWeightClasses()
  }, [weightClassContainerUIStore])

  return (
    <Box m={theme.customSpacing.l}>
      <MaterialReactTable
        muiTableHeadCellProps={overwriteMinimalTableHeadCellStyle}
        columns={columns}
        data={allocationRuleTemplatesV2UIStore.allocationRuleTemplates}
        autoResetPageIndex={false}
        enableColumnActions={false}
        enableTopToolbar={false}
        muiTableDetailPanelProps={() => ({
          sx: theme => ({
            backgroundColor: theme.palette.background.neutral,
          }),
        })}
        enableRowActions
        renderRowActions={({ row }) => [
          <Box key={0} sx={{ minWidth: '120px' }}>
            <Tooltip title={t('edit', 'Edit')}>
              <IconButton
                aria-label='edit'
                onClick={() => dialogUtilStore.toggleDialog('Edit', row.original.id)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title={t('remove', 'Remove')}>
              <IconButton
                aria-label='delete'
                onClick={() => dialogUtilStore.toggleDialog('Delete', row.original.id)}
              >
                <BinIcon />
              </IconButton>
            </Tooltip>
          </Box>,
        ]}
        positionActionsColumn='last'
        displayColumnDefOptions={{
          'mrt-row-actions': {
            header: '',
            muiTableBodyCellProps: {
              align: 'right',
            },
          },
        }}
      />
    </Box>
  )
})

export default AllocationRuleTemplatesTable
