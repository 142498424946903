import { tolgee } from '@storage/app/translation'
import { LabelingPatternType } from '../yard-block-form.profile'

export const generateLabelRange = (
  from: string,
  to: string,
  patternType: LabelingPatternType,
): string[] => {
  const range: string[] = []
  if (patternType === LabelingPatternType.Numeric) {
    const maxLength = Math.max(to.length, from.length, 2)
    const start = parseInt(from, 10)
    const end = parseInt(to, 10)
    const step = start <= end ? 1 : -1

    for (let i = start; step > 0 ? i <= end : i >= end; i += step) {
      range.push(i.toString().padStart(maxLength, '0'))
    }
  } else if (patternType === LabelingPatternType.Alphabetic) {
    const start = from.charCodeAt(0)
    const end = to.charCodeAt(0)
    const step = start <= end ? 1 : -1

    for (let i = start; step > 0 ? i <= end : i >= end; i += step) {
      range.push(String.fromCharCode(i))
    }
  }
  return range
}

export const getBoundsHelperText = (patternType: LabelingPatternType) => {
  return patternType === LabelingPatternType.Numeric
    ? tolgee.t('enterANumberBetween1And99', 'Enter a number between 1 and 999')
    : tolgee.t('enterOneLetter', 'Enter one letter between A and Z')
}
