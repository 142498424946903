import { Add } from '@mui/icons-material'
import { Box, Button, Card, Divider, Stack, Typography } from '@mui/material'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import CarrierVisitAllocationRulesListItem from './components/CarrierVisitAllocationRulesListItem'
import { CarrierVisitAllocationRuleDto, CarrierVisitDirection } from '@storage/app/api'

interface CarrierVisitAllocationRulesContainerProps {
  carrierVisitId: number
  carrierVisitDirection: CarrierVisitDirection
  toggleAllocationRule: (allocationRule: CarrierVisitAllocationRuleDto) => void
}
const CarrierVisitAllocationRulesContainer = observer(
  ({
    carrierVisitId,
    carrierVisitDirection,
    toggleAllocationRule,
  }: CarrierVisitAllocationRulesContainerProps) => {
    const { t } = useTranslate()

    const { carrierVisitAllocationRulesV2UIStore } = useStores()
    const dialogUtilStore = carrierVisitAllocationRulesV2UIStore.containerDialogUtilStore

    useEffect(() => {
      carrierVisitAllocationRulesV2UIStore.loadAllocationRules(
        carrierVisitId,
        carrierVisitDirection,
      )

      return () => {
        carrierVisitAllocationRulesV2UIStore.clearAlerts()
      }
    }, [carrierVisitAllocationRulesV2UIStore, carrierVisitDirection, carrierVisitId])

    const handleOnClick = (allocationRule: CarrierVisitAllocationRuleDto) => {
      toggleAllocationRule(allocationRule)
    }

    return (
      <Card>
        <Stack
          mx={2}
          my={1}
          gap={1}
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Typography variant='h6'>{t('allocationRules', 'Allocation rules')}</Typography>
          <Button
            onClick={() => dialogUtilStore.toggleDialog('Add')}
            variant='contained'
            color='inherit'
            startIcon={<Add />}
          >
            {t('add', 'Add')}
          </Button>
        </Stack>
        <Divider />
        <Box sx={{ maxHeight: '30vh', overflow: 'auto' }}>
          {carrierVisitAllocationRulesV2UIStore.allocationRules.length ? (
            carrierVisitAllocationRulesV2UIStore.allocationRules.map((allocationRule, i) => (
              <CarrierVisitAllocationRulesListItem
                key={i}
                allocationRule={allocationRule}
                selected={
                  allocationRule.id ===
                  carrierVisitAllocationRulesV2UIStore.selectedAllocationRule?.id
                }
                onClick={() => handleOnClick(allocationRule)}
              />
            ))
          ) : (
            <Typography m={2} variant='body1'>
              {t('noAllocationRulesAdded', 'No allocation rules added yet')}
            </Typography>
          )}
        </Box>
      </Card>
    )
  },
)

export default CarrierVisitAllocationRulesContainer
