import { Stack, Typography } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'

interface Props {
  label: string
  value?: string | null
}
export const CarrierVisitDescriptionField = ({ value, label }: Props) => {
  const theme = useMinimalsTheme()

  return (
    <Stack flexDirection='row' gap={theme.customSpacing.xxxs}>
      <Typography variant='caption' color='secondary'>
        {label}:
      </Typography>
      <Typography variant='caption'>{value ?? '-'}</Typography>
    </Stack>
  )
}
