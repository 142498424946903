import {
  ContainerTurnoversFilterDto,
  YardBlockDetailsDto,
  YardBlocksApi,
  YardBlocksApiGetYardBlockDetailsRequest,
} from '@storage/app/api'
import { makeObservable, observable, runInAction } from 'mobx'

export class YardBlockDetailsStore {
  yardBlockDetails: YardBlockDetailsDto[] = []
  constructor(private api: YardBlocksApi) {
    makeObservable(this, {
      yardBlockDetails: observable,
    })
  }

  public async load(filter: ContainerTurnoversFilterDto): Promise<void> {
    const { data: yardBlockDetails } = await this.api.getYardBlockDetails(
      filter as YardBlocksApiGetYardBlockDetailsRequest,
    )

    runInAction(() => {
      this.yardBlockDetails = yardBlockDetails
    })
  }
}
