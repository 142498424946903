import { CreateUserCommand, UpdateUserCommand, UserDto } from '@admin/app/api'
import { UserFormProfile } from './user-form.profile'

const mapFormValuesToCreateDto = ({
  email,
  firstName,
  lastName,
  tenantId,
}: UserFormProfile): CreateUserCommand => ({
  email,
  firstName,
  lastName,
  tenantId: tenantId ? +tenantId : null,
})

const mapFormValuesToUpdateDto = ({
  id,
  email,
  firstName,
  lastName,
  tenantId,
}: UserFormProfile): UpdateUserCommand => ({
  userId: id!,
  email,
  firstName,
  lastName,
  tenantId: tenantId ? +tenantId : null,
})

const mapDtoToFormValues = ({
  user_id,
  email,
  given_name,
  family_name,
  tenant_id,
}: UserDto): UserFormProfile => ({
  id: user_id,
  email: email,
  firstName: given_name,
  lastName: family_name,
  tenantId: tenant_id as any,
})

export const UserFormMapper = {
  mapFormValuesToCreateDto,
  mapFormValuesToUpdateDto,
  mapDtoToFormValues,
}
