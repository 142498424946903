export interface ReeferMonitoringFilter {
  yardBlockIds?: string[] | null
  customerIds?: string[] | null
  inboundCarrierType?: string[] | null
  outboundCarrierType?: string[] | null
}

export const defaultValues: ReeferMonitoringFilter = {
  yardBlockIds: null,
  customerIds: null,
  inboundCarrierType: [],
  outboundCarrierType: [],
}
