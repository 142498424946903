import { createSvgIcon } from '@mui/material'

export const EyeShowIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <circle cx='12' cy='12' r='1.5' fill='currentColor' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.7301 4.99999C17.7101 4.81999 21.2301 10.39 21.8701 11.5C22.0487 11.8094 22.0487 12.1906 21.8701 12.5C21.0101 14 17.8001 18.86 12.2701 19H12.0201C6.13007 19 2.76007 13.59 2.13007 12.5C1.95144 12.1906 1.95144 11.8094 2.13007 11.5C3.00007 9.99999 6.20007 5.13999 11.7301 4.99999ZM8.50007 12C8.50007 13.933 10.0671 15.5 12.0001 15.5C13.9331 15.5 15.5001 13.933 15.5001 12C15.5001 10.067 13.9331 8.49999 12.0001 8.49999C10.0671 8.49999 8.50007 10.067 8.50007 12Z'
      fill='currentColor'
    />
  </svg>,
  'EyeShowIcon',
)
