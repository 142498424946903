import { Box, Button, Typography } from '@mui/material'
import { IconButton, MinusIcon, PlusIcon } from '@tom-ui/ui'
import { ContainerDto, NonNumericOrderDto } from '@planning/app/api'
import { nnrOrderService } from '@planning/services'
import { NonNumericOrderWithPickUpAmount } from '@planning/stores/truckAppointment/TruckAppointmentDetailsViewStore'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import { NoContainersAlert } from './NoContainersAlert'

interface IProps {
  order: NonNumericOrderDto
  onClickAdd?: (order: NonNumericOrderWithPickUpAmount) => Promise<void> | void
}

export const NNROrderHandlingBoxFooter: FC<IProps> = observer(({ order: nnrOrder, onClickAdd }) => {
  const { t } = useTranslate()

  const [count, setCount] = useState(1)
  const [availableContainers, setAvailableContainers] = useState<ContainerDto[]>([])
  const [showNotEnoughContainersWarning, setShowNotEnoughContainersWarning] =
    useState<boolean>(false)

  const remainingOrdersCount = nnrOrder.amount - nnrOrder.orderCount

  const handleClickAdd = async () => {
    const containersAvailable = await nnrOrderService.availableContainers(
      nnrOrder.id,
      remainingOrdersCount,
    )

    setAvailableContainers(containersAvailable)
    if (containersAvailable.length >= count) onClickAdd?.({ ...nnrOrder, pickUpAmount: count })
    else setShowNotEnoughContainersWarning(true)
  }

  return (
    <Box p='1rem'>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <IconButton
              onClick={() => {
                setCount(count - 1)
                setShowNotEnoughContainersWarning(false)
              }}
              disabled={count === 1}
            >
              <MinusIcon />
            </IconButton>
            <Typography sx={{ m: '0 1rem' }}>{count}</Typography>
            <IconButton
              onClick={() => setCount(count + 1)}
              disabled={count === remainingOrdersCount || showNotEnoughContainersWarning}
            >
              <PlusIcon />
            </IconButton>
          </Box>
          <Box>
            <Button
              variant='contained'
              onClick={async () => await handleClickAdd()}
              disabled={showNotEnoughContainersWarning}
            >
              {t('add', 'Add')}
            </Button>
          </Box>
        </>
      </Box>
      {showNotEnoughContainersWarning && (
        <Box mt='0.5rem'>
          <NoContainersAlert availableContainers={availableContainers} />
        </Box>
      )}
    </Box>
  )
})
