import { useOperationsStore } from '@operations/AppProvider'
import { CoolingOrderListContainer } from '@operations/features/coolingOperator/containers/CoolingOrderListContainer'
import { CoolingOrderSearchContainer } from '@operations/features/coolingOperator/containers/CoolingOrderSearchContainer'
import { CoolingOrderStepperContainer } from '@operations/features/coolingOperator/containers/CoolingOrderStepperContainer'
import { ReeferMonitoringDrawerContainer } from '@operations/features/reeferMonitoring/containers/ReeferMonitoringDrawerContainer'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

export const ReeferOperator = observer(() => {
  const { reeferTemperatureStore } = useOperationsStore()

  useEffect(() => {
    reeferTemperatureStore.loadInProgressCoolingOrders(true)
  }, [reeferTemperatureStore])

  return (
    <>
      <CoolingOrderListContainer />
      <CoolingOrderSearchContainer />
      <CoolingOrderStepperContainer />
      <ReeferMonitoringDrawerContainer />
    </>
  )
})
