import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'
import { BaseHubConnection } from '@storage/hubs/base.hub-connection'

export const useHubConnection = (connection: BaseHubConnection) => {
  const { isLoading, isAuthenticated } = useAuth0()

  useEffect(() => {
    if (isLoading || !isAuthenticated) {
      return
    }

    connection.start()

    return () => {
      connection.stop()
    }
  }, [connection, isAuthenticated, isLoading])
}
