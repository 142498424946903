import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { Header } from '@tom-ui/ui'
import GeneralCargoInventoryTable from './components/GeneralCargoInventoryTable'
import GeneralCargoInventoryTableNew from './components/GeneralCargoInventoryTableNew'
import GeneralCargoStockOccupancyDamageDialog from './components/GeneralCargoStockOccupancyDamageDialog'

const GeneralCargoInventory = () => {
  const { t } = useTranslate()
  const { generalCargoInventoryUIStore } = useStores()

  const isGCInventoryTableNewEnabled = useBooleanFlagValue('gc-inventory', false)

  return (
    <>
      <Header
        title={t('generalCargoInventory', 'Inventory')}
        searchInputLabel={t(
          'generalCargoInventorySearch',
          'Search for customer, commodity, lot number or unit ID',
        )}
        onSearchFieldChange={value => generalCargoInventoryUIStore.setSearchQuery(value)}
        displaySearchIcon
        enableSearchField
      />

      {isGCInventoryTableNewEnabled ? (
        <GeneralCargoInventoryTableNew />
      ) : (
        <GeneralCargoInventoryTable />
      )}
      <GeneralCargoStockOccupancyDamageDialog />
    </>
  )
}

export default GeneralCargoInventory
