import { UserStore } from '@admin/stores/user.store'
import { action, makeObservable, observable } from 'mobx'

export class UserDialogUIStore {
  assignRolesOpen = false
  userId?: string

  constructor(public store: UserStore) {
    makeObservable(this, {
      assignRolesOpen: observable,
      userId: observable,

      toggleAssignRoles: action,
    })
  }

  toggleAssignRoles(userId?: string) {
    if (userId !== this.userId) {
      this.userId = userId
    }

    this.assignRolesOpen = !this.assignRolesOpen
  }
}
