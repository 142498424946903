import ReportStep from '@billing/app/models/report-step.enum'
import { useBillingServiceStore } from '@billing/AppProvider'
import exportService from '@billing/services/ExportService'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { VesselReport } from '../components/vesselReport/VesselReport'
import { VesselReportFilter } from '../components/vesselReport/VesselReportFilter'
import { ReportNavigationButtonContainer } from './ReportNavigationButtonContainer'

export const VesselReportContainer = observer(() => {
  const { reportsUIStore, carrierVisitStore, vesselReportUIStore } = useBillingServiceStore()

  const finish = () => {
    vesselReportUIStore.resetData()

    reportsUIStore.resetStepper()
  }

  useEffect(() => {
    async function fetchData() {
      carrierVisitStore.getVesselVisits(true)
    }

    fetchData()
  }, [carrierVisitStore])

  return (
    <>
      {reportsUIStore.currentStep === ReportStep.Filter && <VesselReportFilter />}
      {reportsUIStore.currentStep === ReportStep.Report && <VesselReport />}
      <ReportNavigationButtonContainer
        disableNext={!vesselReportUIStore.selectedVesselVisitId}
        downloadAction={() =>
          exportService.exportVesselContainers(+vesselReportUIStore.selectedVesselVisitId!)
        }
        finishAction={finish}
      />
    </>
  )
})
