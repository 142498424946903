import { Autocomplete, Box, Button, Grid, TextField, Typography } from '@mui/material'
import { RailcarResponseDto } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { RailcarsFormDialog } from '@planning/pages/Railcars/components/RailcarsFormDialog'
import { RailcarsViewStore } from '@planning/pages/Railcars/stores/RailcarsViewStore'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { Controller } from 'react-hook-form'

interface IProps {
  control: any
  errors: any
  name: string
  label: string
  options: RailcarResponseDto[]
}

const RailcarAutocomplete = observer(({ control, errors, name, label, options }: IProps) => {
  const { railcarItemStore, appViewStore } = usePlanningStore()
  const { t } = useTranslate()
  const railcarsViewStore = useMemo(
    () => new RailcarsViewStore(railcarItemStore, appViewStore),
    [railcarItemStore, appViewStore],
  )

  return (
    <Grid item sm={3} xs={12}>
      <Controller
        control={control}
        name={name}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            disablePortal
            getOptionLabel={railcarPosition => railcarPosition?.name}
            options={options}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={options.find((x: any) => x?.id === value) ?? null}
            onChange={(_, railcarPosition) => {
              onChange(railcarPosition?.id)
            }}
            noOptionsText={
              <Box p={2} textAlign='center'>
                <Typography variant='body2'>{t('noRailcarFound', 'No railcar found')}</Typography>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    railcarsViewStore.toggleDialog(true)
                  }}
                >
                  {t('createRailcar', 'Create Railcar')}
                </Button>
              </Box>
            }
            renderInput={params => (
              <TextField
                {...params}
                data-cy='railcar-autocomplete'
                label={label}
                error={!!errors[name]}
                helperText={errors[name] ? t('fieldIsRequired', 'Field is required') : undefined}
              />
            )}
          />
        )}
      />
      <RailcarsFormDialog store={railcarsViewStore} />
    </Grid>
  )
})

export default RailcarAutocomplete
