import { CarrierType, JobsApi, OperationType, WorkInstructionJobDto } from '@operations/app/api'
import { createApiClient } from '@operations/app/http-client'
import { WorkInstructionStore } from '@operations/stores/WorkInstructionStore'
import { AppStore } from '@tom-ui/utils'
import { action, computed, makeObservable, observable } from 'mobx'
import { FinishJobSignatureDto, FinishWorkInstructionJobDto } from '../models/finish-job.model'

export enum JobSteps {
  confirmAmount,
  signature,
}

export class EquipmentOperatorWorkInstructionStepperUIStore {
  isOpen = false
  currentStep?: JobSteps
  selectedJob?: WorkInstructionJobDto

  operatorSign?: string
  driverSign?: string
  driverSignPadOpen = false
  isLastJobInCarrierVisit = false

  hasError?: boolean

  hasQuantity?: boolean
  isConfirmationDialogOpen = false

  constructor(
    private workInstructionStore: WorkInstructionStore,
    private appStore: AppStore,
  ) {
    makeObservable(this, {
      currentStep: observable,
      setStep: action,
      hasNextStep: computed,
      isValid: computed,
      currentStepNumber: computed,
      stepsTotal: computed,

      isOpen: observable,
      hasError: observable,
      selectedJob: observable,
      operatorSign: observable,

      hasQuantity: observable,
      isConfirmationDialogOpen: observable,

      openStepper: action,
      closeStepper: action,
      setHasError: action,

      driverSignPadOpen: observable,
      isLastJobInCarrierVisit: observable,
      setOperatorSign: action,
      setDriverSign: action,
      openDriverSign: action,
      closeDriverSign: action,
      setIsLastInCarrierVisit: action,

      setHasQuantity: action,
      toggleConfirmationDialog: action,

      commodityName: computed,
    })
  }

  public get currentStepNumber() {
    return this.currentStep && this.currentStep === JobSteps.signature ? 2 : 1
  }

  public get stepsTotal() {
    return this.needToDisplaySignature ? 2 : 1
  }

  public async openStepper(job: WorkInstructionJobDto) {
    this.selectedJob = job

    this.currentStep = JobSteps.confirmAmount

    this.isOpen = true
  }

  public closeStepper() {
    this.isOpen = false
    this.driverSignPadOpen = false

    this.selectedJob = undefined
    this.currentStep = undefined
    this.hasError = undefined
    this.operatorSign = undefined
    this.driverSign = undefined
  }

  public setStep(step: JobSteps) {
    if (this.currentStep !== step) {
      this.currentStep = step
    }
  }

  public setHasError(value?: boolean) {
    if (value !== this.hasError) {
      this.hasError = value
    }
  }

  public get isValid() {
    switch (this.currentStep) {
      case JobSteps.signature:
        return this.operatorSign !== undefined

      default:
        return true
    }
  }

  public setHasQuantity(quantity: number) {
    this.hasQuantity = quantity > 0
  }

  public toggleConfirmationDialog() {
    this.isConfirmationDialogOpen = !this.isConfirmationDialogOpen
  }

  public setOperatorSign(png?: string) {
    this.operatorSign = png
  }

  public setDriverSign(png?: string) {
    this.driverSign = png
  }

  public openDriverSign() {
    this.driverSignPadOpen = true
  }

  public closeDriverSign() {
    this.driverSignPadOpen = false
  }

  public setIsLastInCarrierVisit(value: boolean) {
    this.isLastJobInCarrierVisit = value
  }

  public handleNextStep() {
    if (this.currentStep === JobSteps.confirmAmount && this.needToDisplaySignature) {
      this.setStep(JobSteps.signature)
    }
  }

  public get hasNextStep() {
    return this.currentStep === JobSteps.confirmAmount && this.needToDisplaySignature
  }

  private get needToDisplaySignature() {
    return (
      this.isLastJobInCarrierVisit &&
      this.selectedJob?.carrierVisit?.type === CarrierType.Truck &&
      this.selectedJob?.operationType === OperationType.Outbound
    )
  }

  async uploadSignatures(dto?: FinishJobSignatureDto) {
    if (!dto || !dto.operatorSignature || !dto.carrierVisitId) return

    await createApiClient(JobsApi).uploadSignatures({
      operatorSignature: dto.operatorSignature!,
      driverSignature: dto.driverSignature,
      carrierVisitId: dto.carrierVisitId,
      operatorName: dto.operatorName,
    })
  }

  async finishJob(jobDto: FinishWorkInstructionJobDto) {
    await this.appStore.triggerRequestWithoutLoader(
      async () =>
        await this.workInstructionStore.finishJob({
          id: jobDto.workInstructionId,
          unitAmount: jobDto.amount,
        }),
    )
  }

  public getCurrentFinishJobDto(operatorName?: string): FinishWorkInstructionJobDto | null {
    if (!this.selectedJob) {
      return null
    }

    const signDto =
      this.operatorSign && this.selectedJob.carrierVisit?.id
        ? {
            carrierVisitId: this.selectedJob.carrierVisit?.id,
            operatorSignature: this.operatorSign,
            driverSignature: this.driverSign,
            operatorName: operatorName,
          }
        : undefined

    return {
      workInstructionId: this.selectedJob.workInstructionId,
      signature: signDto,
    }
  }

  public get commodityName() {
    return this.selectedJob?.displayName
  }
}
