import { TEntity } from '@storage/app/models'
import { PaginatedList } from '@storage/app/models/paginated-list.model'
import { computed, makeObservable, observable, runInAction } from 'mobx'

export abstract class PaginatedEntityStore<T extends TEntity> {
  public pagination: PaginatedList<T> = {
    hasNextPage: true,
    hasPreviousPage: false,
    items: [],
    pageNumber: 1,
    totalCount: 0,
    totalPages: 1,
  }

  constructor() {
    makeObservable(this, {
      pagination: observable,

      entries: computed,
    })
  }

  public get entries(): T[] {
    return Array.from(this.pagination.items.values())
  }

  public paginate(paginatedList: PaginatedList<T>) {
    runInAction(() => {
      this.pagination = paginatedList
    })
  }
}
