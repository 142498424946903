import { CardHeader } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import React from 'react'

interface ListItemCardProps {
  title: string
  icon?: React.ReactElement
  subtitle?: string
  action?: React.ReactElement
}
const ListItemCard = ({ title, subtitle, action, icon }: ListItemCardProps) => {
  const { palette } = useMinimalsTheme()

  return (
    <CardHeader
      sx={{
        border: `1px solid ${palette.grey[300]}`,
        '&:hover': {
          backgroundColor: palette.grey[200],
        },
        borderRadius: '8px',
        '& .MuiCardHeader-title': { fontWeight: 'bold' },
        '& .MuiCardHeader-action': { alignSelf: 'center', ml: '1.5rem' },
        padding: 1,
      }}
      avatar={icon && React.cloneElement(icon, { style: { width: '2rem', height: '2rem' } })}
      action={action}
      title={title}
      subheader={subtitle}
    />
  )
}

export default ListItemCard
