import { withAuthenticationRequired } from '@auth0/auth0-react'
import { authStore } from '../stores/AuthStore'
import { observer } from 'mobx-react-lite'
import { ComponentType, FC } from 'react'

export const AuthGuard: FC<{
  requiredPermission: string
  component: ComponentType
  message?: string
}> = observer(({ requiredPermission, component }) => {
  const Component = withAuthenticationRequired(component)

  if (!authStore.isLoaded) {
    return null
  }

  if (authStore.hasPermission(requiredPermission)) return <Component />

  return (
    <div>
      <div>
        <h2>No permission</h2>
      </div>
      <div>
        <p>The page you are trying access has restricted access.</p>
        <p>Please refer to your system administrator</p>
      </div>
    </div>
  )
})
