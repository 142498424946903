import { CreateVesselCommand, UpdateVesselCommand, VesselsApi } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'

class VesselService {
  httpClient = createApiClient(VesselsApi)

  get = async (page: number, pageSize: number, filter?: string) => {
    const { data } = await this.httpClient.get(page, pageSize, filter)

    return data
  }

  post = async (cmd: CreateVesselCommand) => {
    await this.httpClient.post(cmd)
  }

  put = async (cmd: UpdateVesselCommand) => {
    await this.httpClient.put(cmd.id, cmd)
  }

  delete = async (id: number) => {
    await this.httpClient._delete(id)
  }

  getByIds = async (ids: Array<number>) => {
    const { data } = await this.httpClient.getByIds(ids)

    return data
  }
}

const vesselService = new VesselService()

export default vesselService
