import { AllValue } from '@storage/components/form-controls/constants'
import { addMethod, BooleanSchema, StringSchema } from 'yup'

export const allOptionAsUndefinedTramsform = () => {
  addMethod(StringSchema, 'allOptionAsUndefined', function allOptionAsUndefined() {
    return this.transform(value => (value === AllValue ? undefined : value))
  })

  addMethod(BooleanSchema, 'allOptionAsUndefined', function allOptionAsUndefined() {
    return this.transform(value => (value === AllValue ? undefined : value))
  })
}
