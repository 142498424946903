import { Box, Button, Stack, TextField, debounce } from '@mui/material'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { usePlanningStore } from '@planning/AppProvider'
import { CreateRailVisitForm } from '@planning/pages/RailVisit/Components/Forms/CreateRailVisitForm'
import { CreateVesselVisitForm } from '@planning/pages/VesselVisit/Components/Forms/CreateVesselVisitForm'
import Permission from '@planning/permission.enum'
import { VisitItem } from '@planning/rt-stores/visit/VisitViewStore'
import { IPaginatedStoreWithItems } from '@planning/stores/PaginatedStore'
import { useTranslate } from '@tolgee/react'
import { ButtonWithDropdown, Header, PlusIcon } from '@tom-ui/ui'
import { DatePicker } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'

interface Props {
  store: IPaginatedStoreWithItems<VisitItem>
}
export const VisitsHeader = observer(({ store }: Props) => {
  const { t } = useTranslate()
  const { drawerStore, authStore } = usePlanningStore()
  const navigate = useNavigate()
  const canAddRailVisit = authStore.hasPermission(Permission.WriteRailVisits)

  // TODO [OCTA-1216] Remove this variable after the feature flag is validated
  const vesselVisitBerthPlanningEnabled = useBooleanFlagValue('vessel-visit-berth-planning', false)
  const railPlanningEnabled = useBooleanFlagValue('rail-planning', false)

  const { RangePicker } = DatePicker
  type RangeValue = [from: Dayjs | null, to: Dayjs | null] | null
  const value: RangeValue = [dayjs(store.from), dayjs(store.to)]

  const onAddVesselVisit = () => {
    // TODO [OCTA-1216] Remove this condition after the feature flag is validated
    if (vesselVisitBerthPlanningEnabled) {
      navigate('/vessel-visits-planning')
    } else {
      drawerStore.showView(<CreateVesselVisitForm onClose={drawerStore.close} />, {
        title: t('create', 'Create'),
        subTitle: t('vesselVisit', 'Vessel Visit'),
        primaryActionLabel: t('submit', 'Submit'),
        closeActionLabel: t('close', 'Close'),
        formId: 'create-vessel-visit',
      })
    }
  }

  const onAddRailVisit = () => {
    // TODO Remove this condition after the feature flag is validated
    if (railPlanningEnabled) {
      navigate('/rail-visits-planning')
    } else {
      drawerStore.showView(<CreateRailVisitForm onClose={drawerStore.close} />, {
        title: t('create', 'Create'),
        subTitle: t('railVisit', 'Rail Visit'),
        primaryActionLabel: t('submit', 'Submit'),
        closeActionLabel: t('close', 'Close'),
        formId: 'rail-visit',
      })
    }
  }

  const OnSearchFilterChange = debounce(async (filter: string) => {
    await store.setFilter(filter)
  }, 500)

  const onTodayClick = () => {
    const today = dayjs(new Date()).hour(0).minute(0).toDate()
    store.setDateRange(today, dayjs(today).hour(23).minute(59).toDate())
  }

  const onNextSevenDaysClick = () => {
    const today = dayjs(new Date()).hour(0).minute(0).toDate()
    const nextSevenDays = dayjs(today).hour(23).minute(59).add(7, 'day').toDate()
    store.setDateRange(today, nextSevenDays)
  }

  const getCreateVisitOptions = () => {
    const options = [
      {
        label: t('vesselVisit', 'Vessel visit'),
        testId: 'create-vessel-visit-btn',
        onClick: onAddVesselVisit,
      },
    ]

    if (canAddRailVisit)
      options.push({
        label: t('railVisit', 'Rail visit'),
        testId: 'create-rail-visit-btn',
        onClick: onAddRailVisit,
      })

    return options
  }

  return (
    <Header
      title={t('visits', 'Visits')}
      rightRenderOption={
        <Stack flexDirection='row' alignItems='center' gap={2}>
          <Button color='inherit' variant='contained' sx={{ flex: 'none' }} onClick={onTodayClick}>
            {t('today', 'Today')}
          </Button>
          <Button
            color='inherit'
            variant='contained'
            sx={{ flex: 'none' }}
            onClick={onNextSevenDaysClick}
          >
            {t('nextSevenDays', 'Next 7 days')}
          </Button>

          <Box width='300px'>
            <RangePicker
              style={{ height: '3.5rem' }}
              value={value}
              onChange={(val: any) => {
                store.setDateRange(val[0].toDate(), val[1].toDate())
              }}
            />
          </Box>

          <Box width='300px'>
            <TextField
              label={t('search', 'Search')}
              defaultValue={store.filter}
              sx={{ width: '100%', marginTop: 0 }}
              data-cy='visits-page-search-field'
              onChange={event => OnSearchFilterChange(event.currentTarget.value)}
            />
          </Box>

          <Box flex='none'>
            <ButtonWithDropdown
              testId='add-new-visit-btn'
              label={t('addNew', 'Add new')}
              startIcon={<PlusIcon />}
              color='inherit'
              options={getCreateVisitOptions()}
            />
          </Box>
        </Stack>
      }
    />
  )
})
