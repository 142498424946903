import { Box, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { useMinimalsTheme } from '@tom-ui/ui'
import { OrderResponseDto, OrderStatus, TruckDto } from '@planning/app/api'
import IconFullOrEmptyContainer from '@planning/components/icon/IconFullOrEmptyContainer'
import IconTruck from '@planning/components/icon/IconTruck'
import { ContainerLocation } from '@planning/pages/Container/components/ContainerLocation'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

type Props = {
  pickUpOrder: OrderResponseDto
  carriers: TruckDto[]
  withBorderLineStyle?: boolean
  alertMessage?: JSX.Element[]
  informationMessage?: string
}

export const PickUpOrderCard: FC<Props> = observer(
  ({ pickUpOrder, carriers, withBorderLineStyle = true, alertMessage, informationMessage }) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()

    const mainCarrier = carriers[0]

    const renderOpenPickUpOrder = () => (
      <Stack direction='row' spacing='8px' alignItems='center'>
        <IconFullOrEmptyContainer isEmpty={pickUpOrder.isEmpty} color='secondary' />
        <ContainerLocation color='secondary' />
      </Stack>
    )

    const renderFulfilledPickUpOrder = () => (
      <Stack direction='row' spacing='8px' alignItems='center'>
        <Stack direction='row' spacing='4px'>
          <IconFullOrEmptyContainer isEmpty={pickUpOrder.isEmpty} color='secondary' />
          <IconTruck color='secondary' />
        </Stack>

        <Typography variant='caption' color='secondary'>
          {mainCarrier?.licensePlate ?? 'Unknown'}
        </Typography>
      </Stack>
    )

    return (
      <Box
        sx={{
          border: withBorderLineStyle ? `1px solid ${theme.palette.grey[300]}` : '0px',
          p: '8px 16px 8px 8px',
          width: '100%',
          minHeight: '85px',
        }}
      >
        <Stack>
          <Stack flexDirection='row' justifyContent='space-between'>
            <Typography
              variant='subtitle2'
              color={pickUpOrder.status === OrderStatus.Fulfilled ? 'secondary' : ''}
            >
              {pickUpOrder.containerNumber?.toUpperCase()}
            </Typography>

            {informationMessage && (
              <Typography variant='caption' color='error'>
                {informationMessage}
              </Typography>
            )}
          </Stack>
          <Stack>
            {pickUpOrder.status === OrderStatus.Open
              ? renderOpenPickUpOrder()
              : renderFulfilledPickUpOrder()}
            {pickUpOrder.referenceNumber && (
              <Typography variant='caption' color='secondary'>
                {t('ref', 'Ref')}: {pickUpOrder.referenceNumber}
              </Typography>
            )}
            {pickUpOrder.yardLocation && (
              <Typography variant='caption' color='secondary'>
                {t('location', 'Location')}: {pickUpOrder.yardLocation}
              </Typography>
            )}
            <Stack spacing={1}>
              {alertMessage?.map((alert, index) => <Box key={`alert-${index}`}>{alert}</Box>)}
            </Stack>
          </Stack>
        </Stack>
      </Box>
    )
  },
)
