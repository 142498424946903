import { usePlanningStore } from '@planning/AppProvider'
import { useTranslate } from '@tolgee/react'
import { ConfirmationDialog } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'

export const AppDialog = observer(() => {
  const { appViewStore } = usePlanningStore()
  const { t } = useTranslate()
  return (
    <ConfirmationDialog
      open={appViewStore.openDialog}
      title={appViewStore.dialogTitle ?? t('confirm', 'Confirm')}
      message={
        appViewStore.dialogContent ??
        t('doYouWantToDeleteThisItem', 'Do you want to delete this item?')
      }
      warning={appViewStore.dialogWarning}
      primaryActionText={t('confirm', 'Confirm')}
      primaryActionDataCy='confirm-planning-dialog-btn'
      onConfirm={() => {
        if (appViewStore.confirmHandler) {
          appViewStore.confirmHandler(true)
          appViewStore.setOpenConfirmDialog(false)
        }
      }}
      closeLabel={t('cancel', 'Cancel')}
      onClose={() => {
        if (appViewStore.confirmHandler) {
          appViewStore.confirmHandler(false)
        }
        appViewStore.setOpenConfirmDialog(false)
      }}
    />
  )
})
