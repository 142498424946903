import { Backdrop, Box, keyframes, styled } from '@mui/material'
import { Logo } from '../logo/Logo'

interface Props {
  show: boolean
}

export const Loader = ({ show }: Props) => {
  const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

  const RotatedBox = styled(Box)(() => ({
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    animation: `${spin} 1s linear infinite`,
  }))

  return (
    <Backdrop
      sx={{
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        overflow: 'hidden !important',
        zIndex: theme => theme.zIndex.tooltip + 10,
      }}
      open={show}
    >
      <RotatedBox>
        <Logo zoom={350} />
      </RotatedBox>
    </Backdrop>
  )
}
