import { Grid, Stack, Typography } from '@mui/material'
import {
  ContainerSlotDto,
  ContainerSlotPositionType,
  ContainerVesselDirectionType,
} from '@operations/app/api'
import { isJobAssignedByEquipment } from '@operations/features/craneOperator/utils'
import { SlotIcons } from './SlotIcons'

const showContainerInfo = (
  container: ContainerSlotDto | null | undefined,
  currentEquipmentId: number | undefined,
  operationType: ContainerVesselDirectionType,
): boolean => {
  return (
    operationType == ContainerVesselDirectionType.RemainOnBoard ||
    !currentEquipmentId ||
    isJobAssignedByEquipment(container, currentEquipmentId)
  )
}

interface Props {
  slotContainer?: ContainerSlotDto | null
  operationType: ContainerVesselDirectionType
  zoom: number
  slotBaroti: string
  isRestow: boolean
  currentEquipmentId?: number
}

export const SlotContainer = ({
  slotContainer,
  operationType,
  zoom,
  slotBaroti,
  isRestow,
  currentEquipmentId,
}: Props) => {
  if (!slotContainer) return <></>

  const sizeToText = (containerSize: ContainerSlotPositionType | null | undefined): string => {
    switch (containerSize) {
      case ContainerSlotPositionType.Twenty:
        return '20ft'

      case ContainerSlotPositionType.FortyFront:
        return '40ft'

      case ContainerSlotPositionType.FortyBack:
        return '40ft'

      default:
        return ''
    }
  }

  const typeToText = (container: ContainerSlotDto | null | undefined): string => {
    if (container?.isFlatRack) return 'FR'

    if (container?.isHighCube) return 'HC'

    if (container?.isDryContainer) return 'DC'

    return ''
  }

  return (
    <>
      {zoom < 0.5 ? (
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='flex-end'
          height={`${7 * zoom}rem`}
          width={`${7 * zoom}rem`}
          columnGap={0.2}
        >
          <SlotIcons isRestow={isRestow} size={8} slotContainer={slotContainer} />
        </Grid>
      ) : (
        showContainerInfo(slotContainer, currentEquipmentId, operationType) && (
          <>
            {operationType === ContainerVesselDirectionType.Outbound ||
            operationType === ContainerVesselDirectionType.RemainOnBoard ? (
              <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Typography fontSize={`${12 * zoom}px`}>{slotContainer.portOfDischarge}</Typography>
                <Typography fontSize={`${12 * zoom}px`}>{slotBaroti}</Typography>
              </Stack>
            ) : (
              <Stack justifyContent='center' alignItems='flex-end'>
                <Typography fontSize={`${12 * zoom}px`}>{slotBaroti}</Typography>
              </Stack>
            )}

            <Stack justifyContent='center' alignItems='center'>
              <Typography fontSize={`${14 * zoom}px`} fontWeight='bold'>
                {slotContainer.containerNumber}
              </Typography>
            </Stack>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
              <Typography fontSize={`${12 * zoom}px`}>
                {sizeToText(slotContainer.containerPositionType)}
                {typeToText(slotContainer)}
              </Typography>
              <Typography fontSize={`${12 * zoom}px`}>
                {slotContainer.grossWeight ? `${Number(slotContainer.grossWeight) / 1000}t` : '? t'}
              </Typography>
            </Stack>
            <Stack
              direction='row'
              justifyContent='flex-start'
              alignItems='flex-start'
              spacing={0.2 * zoom}
            >
              <SlotIcons isRestow={isRestow} size={24 * zoom} slotContainer={slotContainer} />
            </Stack>
          </>
        )
      )}
    </>
  )
}
