import { usePlanningStore } from '@planning/AppProvider'
import { OrderResponseDto } from '@planning/app/api'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { HoldsChip } from './DisplayHoldsButton'
import { HoldsDrawerContent } from './HoldsDrawerContent'

interface Props {
  order: OrderResponseDto
  linkedOrder?: OrderResponseDto
}

export const HoldsDrawerButton: FC<Props> = observer(({ order, linkedOrder }) => {
  const { holdViewStore, drawerStore } = usePlanningStore()
  const { t } = useTranslate()

  const handleClick = () => {
    holdViewStore.selectOrder(order, linkedOrder)
    holdViewStore.setSearchFilter()
    drawerStore.showView(<HoldsDrawerContent store={holdViewStore} />, {
      title: t('holds', 'Holds'),
      subTitle: t('vesselVisit', 'Vessel Visit'),
    })
  }

  return <HoldsChip holds={order.holds.concat(linkedOrder?.holds ?? [])} onClick={handleClick} />
})
