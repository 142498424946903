import { createSvgIcon } from '@mui/material'

export const ChevronLeftIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M13.8375 19C13.5349 19.001 13.2481 18.865 13.0575 18.63L8.22749 12.63C7.92417 12.261 7.92417 11.729 8.22749 11.36L13.2275 5.35999C13.581 4.93473 14.2122 4.87653 14.6375 5.22999C15.0627 5.58346 15.121 6.21473 14.7675 6.63999L10.2975 12L14.6175 17.36C14.8672 17.6597 14.9198 18.0773 14.7522 18.4296C14.5847 18.7819 14.2276 19.0046 13.8375 19Z'
      fill='currentColor'
    />
  </svg>,
  'ChevronLeftIcon',
)
