import { action, makeObservable, observable, reaction, runInAction } from 'mobx'

export interface ISearchAutocompleteStore<T> {
  items: T[]
  filter: string
  isLoading: boolean
  value?: T
  fetch: (query: any) => Promise<void>
  setFilter: (filter: string) => Promise<void> | void
  setValue: (value?: T) => void
  reset: () => void
}

export class SearchAutocompleteStore<T> implements ISearchAutocompleteStore<T> {
  items: T[] = []
  filter = ''
  value?: T
  isLoading = false

  constructor(private fetchFunc: (query: any) => Promise<T[]>) {
    makeObservable(this, {
      items: observable,
      value: observable,
      filter: observable,
      isLoading: observable,
      setFilter: action,
      setLoading: action,
      setValue: action,
    })

    reaction(
      () => this.filter,
      filter => {
        if (filter.length > 0) {
          this.fetch(filter.toUpperCase()).catch(error => {
            // TODO: Handle error properly
            console.log(error)
          })
        } else {
          runInAction(() => {
            this.items = []
          })
        }
      },
      { delay: 200 },
    )
  }

  setLoading(isLoading: boolean) {
    this.isLoading = isLoading
  }

  fetch = async (filter: string) => {
    this.setLoading(true)

    const data = await this.fetchFunc(filter)
    runInAction(() => {
      this.items = data
    })

    this.setLoading(false)
  }

  setFilter = (filter: string) => {
    this.filter = filter
  }

  setValue = (value?: T) => {
    this.value = value
  }

  reset = () => {
    this.setFilter('')
    this.setValue()
  }
}
