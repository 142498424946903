import { WorkQueueTiming } from '@operations/features/craneSplit/models/work-queue.model'
import { Box, Divider, Typography } from '@mui/material'
import dayjs from 'dayjs'

interface Props {
  timing: WorkQueueTiming
}

export const HourCard = ({ timing }: Props) => {
  const displayTimeFormat = 'hh:mm A'

  return (
    <Box
      key={timing.time.getTime()}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: timing.size,
        width: '6rem',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '.5rem',
        }}
      >
        <Typography>{dayjs(timing.time).format(displayTimeFormat)}</Typography>
        <Box
          sx={{
            width: '1rem',
            height: '1rem',
            borderRadius: '50%',
            border: '2px solid gray',
          }}
        ></Box>
      </Box>
      {timing.size && (
        <Box sx={{ height: '100%', margin: '.5rem' }}>
          <Divider orientation='vertical' sx={{ borderRightWidth: 2 }} />
        </Box>
      )}
    </Box>
  )
}
