import { Typography } from '@mui/material'
import { WorkInstructionJobDto } from '@operations/app/api'
import { useOperationsStore } from '@operations/AppProvider'
import { useTranslate } from '@tolgee/react'
import { ReactNode } from 'react'
import { WorkInstructionJobDescription } from '../JobCard/WorkInstructionJobDescription'
import { StepperContentWrapper } from '../Stepper/StepperContentWrapper'

interface Props {
  job: WorkInstructionJobDto
  currentStep?: number
  stepsTotal?: number
  children: ReactNode
}
export const WorkInstructionJobInformationWrapper = ({
  job,
  currentStep,
  stepsTotal,
  children,
}: Props) => {
  const { t } = useTranslate()
  const { equipmentOperatorUIStore, tenantConfigStore } = useOperationsStore()

  const fieldsToShow = tenantConfigStore.getOperatorFieldsByEquipmentAndOperationTypeAndCarrierType(
    equipmentOperatorUIStore.typeOfSelectedEquipment,
    job.operationType,
    job.carrierVisit?.type,
  )

  return (
    <StepperContentWrapper
      title={t('checkInformation', 'Check information')}
      steps={
        currentStep !== undefined && stepsTotal !== undefined && stepsTotal > 1
          ? `${currentStep} ${t('of', 'of')} ${stepsTotal}`
          : undefined
      }
    >
      <Typography variant='subtitle1'>{t('generalInformation', 'General information')}</Typography>
      <WorkInstructionJobDescription job={job} fieldsToShow={fieldsToShow} stepper />

      {children}
    </StepperContentWrapper>
  )
}
