import { Stack } from '@mui/system'
import { HandlingDirection } from '@storage/app/api'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { GeneralCargoStorageTrackerContext } from '../interfaces/general-cargo-storage-tracker-interface'
import { OccupancyItem, StockOccupancy } from '../interfaces/occupancy-item'
import AddNewGeneralCargoLocationCard from './AddNewGeneralCargoLocationCard'
import GeneralCargoLocationCard from './GeneralCargoLocationCard'

interface GeneralCargoLocationHeaderProps {
  handlingDirection: HandlingDirection
  context: GeneralCargoStorageTrackerContext
  generalCargoOrderId: number
  occupancyItems?: OccupancyItem[]
}
const GeneralCargoLocationHeader = observer(
  ({
    handlingDirection,
    context,
    generalCargoOrderId,
    occupancyItems,
  }: GeneralCargoLocationHeaderProps) => {
    const { generalCargoStorageTrackerUIStore } = useStores()
    const theme = useMinimalsTheme()
    const dropOffMessage =
      handlingDirection === HandlingDirection.Inbound &&
      generalCargoStorageTrackerUIStore.dropOffMessage()

    const handleDropOff = () => {
      generalCargoStorageTrackerUIStore.addNewOccupancyItem(generalCargoOrderId)
    }

    const handleGeneralCargoLocationCardOnClick = (id: string) => {
      generalCargoStorageTrackerUIStore.setActiveOccupancyItemId(id)
    }

    const parentStackStyle = {
      justifyContent: 'center',
      m: 5,
    }

    return (
      <Stack
        sx={!occupancyItems?.length ? parentStackStyle : undefined}
        direction='row'
        gap={theme.customSpacing.s}
      >
        <Stack direction='row' gap={theme.customSpacing.s} sx={{ overflowX: 'auto' }}>
          {occupancyItems?.map((occupancyItem: OccupancyItem) => (
            <GeneralCargoLocationCard
              key={occupancyItem.id}
              active={occupancyItem.id === generalCargoStorageTrackerUIStore.activeOccupancyItemId}
              handlingDirection={handlingDirection}
              title={`${occupancyItem.areaName} ${occupancyItem.locationName}`}
              existingQuantity={context === 'occupancy' ? occupancyItem.quantity : undefined}
              reservedQuantity={
                context === 'occupancy'
                  ? (occupancyItem as StockOccupancy).reservedQuantity
                  : occupancyItem.quantity
              }
              newQuantity={occupancyItem.newQuantity}
              onClick={() => handleGeneralCargoLocationCardOnClick(occupancyItem.id)}
            />
          ))}
        </Stack>
        {dropOffMessage && (
          <AddNewGeneralCargoLocationCard onClick={handleDropOff} title={dropOffMessage} />
        )}
      </Stack>
    )
  },
)

export default GeneralCargoLocationHeader
