import { RailTrackResponseDto, RailTracksApi } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'
import { Event, IEvent, IQueryHandler, IQueryMessage } from '../messageBus'

export class GetRailTracksQuery implements IQueryMessage {
  static type = 'GetRailTracksQuery'
  type = GetRailTracksQuery.type

  getKey = () => {
    return JSON.stringify(this)
  }
}

const httpClient = createApiClient(RailTracksApi)

export const getRailTracksQueryHandler: IQueryHandler<
  GetRailTracksQuery,
  IEvent<RailTrackResponseDto[]>
> = async _ => {
  const { data } = await httpClient.get()

  return new Event(GetRailTracksQuery.type, data)
}
