import { NonNumericOrderDto, NonNumericOrdersApi } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'
import { Event, IEvent, IQueryHandler, IQueryMessage } from '../messageBus'

export class GetNnrOrdersQuery implements IQueryMessage {
  static type = 'GetNnrOrdersQuery'
  type = GetNnrOrdersQuery.type

  constructor(
    public page: number,
    public pageSize: number,
  ) {}

  getKey = () => {
    return JSON.stringify(this)
  }
}

const httpClient = createApiClient(NonNumericOrdersApi)

export const getNnrOrdersQueryHandler: IQueryHandler<
  GetNnrOrdersQuery,
  IEvent<NonNumericOrderDto[]>
> = async query => {
  const { data } = await httpClient.get(query.page, query.pageSize)

  return new Event(GetNnrOrdersQuery.type, data.payload)
}
