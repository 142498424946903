import {
  Alert,
  Button,
  Chip,
  ClickAwayListener,
  FormControl,
  Grid,
  Grow,
  InputLabel,
  MenuItem,
  Paper,
  Popper,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { usePlanningStore } from '@planning/AppProvider'
import { CarrierType, CarrierVisitDirection } from '@planning/app/api'
import { CustomerAutoComplete } from '@planning/components/customer/CustomerAutoComplete'
import { RailVisitAutoComplete } from '@planning/components/visit/RailVisitAutoComplete'
import { VesselVisitAutoComplete } from '@planning/components/visit/VesselVisitAutoComplete'
import { OrderListUploadViewStoreV2 } from '@planning/pages/Order/stores/OrderListUploadViewStoreV2'
import { OrderListUploadDragAndDropZone } from '@planning/pages/VesselVisit/Components/Upload/OrderListUploadDragAndDropZone'
import { CustomerAutocompleteViewStore } from '@planning/stores/customer/CustomerAutocompleteViewStore'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { Controller } from 'react-hook-form'

interface IProps {
  store: OrderListUploadViewStoreV2
  enableVisitSelectField: boolean
  visitType?: CarrierType
  register: any
  errors: any
  setValue: any
  control: any
}

export const OrderListUploadDocumentsContent: FC<IProps> = observer(
  ({ store, register, errors, control, enableVisitSelectField, visitType }) => {
    const { t } = useTranslate()

    const { uploadFailedReason } = store.parsingStore

    const { vesselVisitItemStore, railVisitItemStore } = usePlanningStore()

    const vesselVisit = vesselVisitItemStore.elements[store.vesselVisitId ?? -1]
    const railVisit = railVisitItemStore.elements[store.railVisitId ?? -1]

    const autocompleteStore = useMemo(() => new CustomerAutocompleteViewStore(), [])
    useEffect(() => {
      const fetch = async () => {
        await autocompleteStore.fetch(autocompleteStore.filter)
      }

      fetch().catch(error => console.log(error.message))
    }, [autocompleteStore])

    const [carrierVisitOption, setCarrierVisitOption] = useState('Train')

    const anchorRef = useRef(null)

    const OrderGroupChip: FC<{ group: string }> = observer(({ group }) => {
      return (
        <Chip
          label={group}
          color={group === store.orderGroupStore.selectedGroup ? 'primary' : 'secondary'}
          size='small'
          sx={{ mr: '.5rem' }}
          onClick={() => store.orderGroupStore.setSelectedGroup(group)}
        />
      )
    })

    const AddOrderGroupChip: FC = observer(() => (
      <>
        <Chip
          sx={{ marginLeft: '1rem' }}
          label={`+ ${t('addGroup', 'Add Group')}`}
          ref={anchorRef}
          color='primary'
          variant='outlined'
          size='small'
          data-cy='upload-documents-add-group-btn'
          onClick={() => store.orderGroupStore.setAddMode(!store.orderGroupStore.isAddMode)}
        />

        <Popper
          open={store.orderGroupStore.isAddMode}
          anchorEl={anchorRef.current}
          role={undefined}
          placement='right'
          transition
          disablePortal
          sx={{
            zIndex: 10000,
            ml: '1rem !important',
          }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom-start' ? 'left bottom' : 'right top',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={() => store.orderGroupStore.setAddMode(false)}>
                  <>
                    <TextField
                      data-cy='upload-documents-add-group-textfield'
                      size='small'
                      onChange={e => store.orderGroupStore.setCurrentGroup(e.target.value)}
                    />
                    <Button
                      data-cy='upload-documents-add-group-save-btn'
                      onClick={() => store.orderGroupStore.addGroup()}
                    >
                      OK
                    </Button>
                  </>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    ))

    return (
      <Box sx={{ padding: '1rem' }}>
        <Typography variant='h4'>{t('fileDetails', 'File details')}</Typography>
        <Box sx={{ mt: '1rem' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} mb={'.5rem'}>
              <Box display={'flex'}>
                <Typography variant='subtitle2'>{t('orderGroups', 'Order Groups')}</Typography>
                <Controller
                  control={control}
                  name='orderGroup'
                  // rules={{ validate: () => store.orderGroupStore.groups.length >= 1 }}
                  render={({ field: { onChange } }) => <AddOrderGroupChip />}
                />
              </Box>

              {errors.orderGroup && errors.orderGroup.type === 'validate' && (
                <Typography
                  variant='caption'
                  display='block'
                  sx={{ color: '#FF4842', mt: '.5rem' }}
                >
                  {t('selectAtLeastOneGroup', 'Select at least one group.')}
                </Typography>
              )}
            </Grid>

            {store.orderGroupStore.groups.length > 0 ? (
              <Grid item xs={12} mb={'1rem'}>
                {store.orderGroupStore.groups.map((group, index) => (
                  <OrderGroupChip key={`order-group-chip-${group}-$`} group={group} />
                ))}
              </Grid>
            ) : (
              ''
            )}

            <Grid item xs={12}>
              {visitType === CarrierType.Train
                ? railVisit &&
                  !enableVisitSelectField && (
                    <RailVisitAutoComplete
                      selectedRailVisit={railVisit.data}
                      disabled={!enableVisitSelectField}
                      label={`${t('railVisit', 'Rail visit')} *`}
                    />
                  )
                : vesselVisit &&
                  !enableVisitSelectField && (
                    <VesselVisitAutoComplete
                      selectedVesselVisit={vesselVisit.data}
                      disabled={!enableVisitSelectField}
                      label={`${t('vesselVisit', 'Vessel visit')} *`}
                    />
                  )}
            </Grid>

            {enableVisitSelectField && (
              <>
                <Grid item xs={4}>
                  <Controller
                    control={control}
                    name={`carrierVisit`}
                    render={({ field: { onChange, value } }) => (
                      <FormControl fullWidth error={!!errors.inboundCarrierType}>
                        <InputLabel>{t('carrierVisit', 'Carrier Visit')}</InputLabel>
                        <Select
                          defaultValue={carrierVisitOption}
                          value={carrierVisitOption}
                          label={t('carrierVisit', 'Carrier Visit')}
                          onChange={(e: any) => {
                            onChange(e.target.value)
                            setCarrierVisitOption(e.target.value)
                          }}
                        >
                          <MenuItem value='Train'>{t('train', 'Train')}</MenuItem>
                          <MenuItem value='Vessel'>{t('vessel', 'Vessel')}</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={8}>
                  <>
                    {carrierVisitOption === 'Train' ? (
                      <RailVisitAutoComplete
                        label={`${t('railVisit', 'Rail visit')} *`}
                        handleOnChange={async visit => {
                          await store.setRailVisitId(visit?.id)
                        }}
                        error={!store.setRailVisitId}
                        helperText={
                          !store.setRailVisitId ? t('fieldIsRequired', 'Field is required.') : ''
                        }
                      />
                    ) : (
                      <VesselVisitAutoComplete
                        label={`${t('vesselVisit', 'Vessel visit')} *`}
                        handleOnChange={async visit => {
                          await store.setVesselVisitId(visit?.id)
                        }}
                        error={!store.setVesselVisitId}
                        helperText={
                          !store.setVesselVisitId ? t('fieldIsRequired', 'Field is required.') : ''
                        }
                      />
                    )}
                  </>
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <Controller
                control={control}
                name='customer'
                rules={{ required: true }}
                render={({ field: { onChange } }) => (
                  <CustomerAutoComplete
                    selectedCustomer={store.customer}
                    store={autocompleteStore}
                    handleOnChange={customer => {
                      onChange(customer)
                      store.setCustomer(customer)
                    }}
                    error={!!errors.customer}
                    helperText={errors.customer ? t('fieldIsRequired', 'Field is required.') : ''}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name={`direction`}
                render={({ field: { onChange } }) => (
                  <FormControl fullWidth>
                    <InputLabel color='secondary'>{t('Operation', 'Operation *')}</InputLabel>
                    <Select
                      label={`${t('direction', 'Direction')} *`}
                      disabled={!enableVisitSelectField}
                      fullWidth
                      value={store.direction}
                      onChange={e => {
                        onChange(e)
                        store.setDirection(e.target.value as CarrierVisitDirection)
                      }}
                    >
                      <MenuItem value={CarrierVisitDirection.Inbound}>
                        {t('discharge', 'Discharge')}
                      </MenuItem>
                      <MenuItem value={CarrierVisitDirection.Outbound}>
                        {t('load', 'Load')}
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <OrderListUploadDragAndDropZone store={store} />
            </Grid>

            {uploadFailedReason && (
              <Grid item>
                <Alert severity='error'>
                  <Typography variant='body2' color='error'>
                    {uploadFailedReason}
                  </Typography>
                </Alert>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    )
  },
)
