import { Box, Button } from '@mui/material'
import { CarrierVisitDirection } from '@planning/app/api'
import { SearchContainerViewStore } from '@planning/rt-stores/tallyman/SearchContainerViewStore'
import { useTranslate } from '@tolgee/react'
import { ContainerMobile, Header, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import { ContainerSearchResult } from './Components/ContainerSearchResult'
import { CustomAlert } from './Components/CustomAlert'
import { NavigationStackBackButton } from './Components/HeaderComponents/NavigationStackBackButton'

interface IProps {
  store: SearchContainerViewStore
  onContainerCardClick: (id: number) => void
  onReportClick: () => void
}

export const SearchContainers: FC<IProps> = observer(
  ({ store, onContainerCardClick, onReportClick }) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    const StartCheckAction: FC<{ direction: CarrierVisitDirection }> = ({ direction }) => {
      return (
        <Button
          data-cy='start-check-in'
          variant='contained'
          size='large'
          sx={{ width: '100%', mt: '1rem' }}
        >
          {direction === 'Inbound'
            ? t('startCheckIn', 'Start check in')
            : t('startCheckOut', 'Start check out')}
        </Button>
      )
    }

    return (
      <>
        <Header
          filterTestId='search-container-autocomplete'
          leftRenderOption={<NavigationStackBackButton />}
          enableSearchField
          onSearchFieldChange={store.setSearchFilter}
          searchFilter={store.searchFilter}
        />

        <Box
          style={{
            backgroundColor: theme.palette.grey[200],
            minHeight: 'calc(100vh - 65px)',
          }}
        >
          <ContainerMobile sx={{ pt: theme.customSpacing.l }}>
            {!store.searchFilter && (
              <CustomAlert
                message={t(
                  'hintTryTheLastFourDigitsOfTheContainerNumber',
                  'Hint, try the last 4 digits of the container number',
                )}
                severity='info'
              />
            )}
            {!!store.searchFilter &&
              store.filteredOrders?.map(o => (
                <ContainerSearchResult
                  key={`container-search-result-${o.data.containerNumber}`}
                  containerNumber={o.data.containerNumber ?? ''}
                  holds={o.data.holds}
                  highlight={store.searchFilter}
                  direction={o.data.direction}
                  isOnTerminal={o.data.isOnTerminal ?? false}
                  onClick={() => onContainerCardClick(o.id)}
                >
                  <StartCheckAction direction={o.data.direction} />
                </ContainerSearchResult>
              ))}

            {store.parentStore.selectedOperation === 'Discharge' &&
              !!store.searchFilter &&
              !store.filteredOrders?.length && (
                <CustomAlert
                  severity='warning'
                  action={
                    <>
                      {/* TODO: enable, when workflow is defined by the product */}
                      {/*  <Button color='inherit' onClick={onReportClick}>
                      {t('report', 'Report')}
                    </Button> */}
                    </>
                  }
                  message={t(
                    'noMatchingContainerForDischarge',
                    'No matching container for discharge',
                  )}
                ></CustomAlert>
              )}
          </ContainerMobile>
        </Box>
      </>
    )
  },
)
