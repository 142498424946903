import { SelectOption } from '@operations/app/models'
import {
  Box,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
} from '@mui/material'
import { ChevronDownIcon, IconButton, useMinimalsTheme } from '@tom-ui/ui'
import { useRef, useState } from 'react'

interface Props {
  options: SelectOption[]
  selectedOption?: string | number
  tooltip: string
  onChange: (option: SelectOption) => void
}

export const ExpandButtonWithSelect = ({ tooltip, options, selectedOption, onChange }: Props) => {
  const [open, setOpen] = useState(false)
  const theme = useMinimalsTheme()
  const anchorRef = useRef<HTMLDivElement>(null)
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = (event: Event) => {
    if (anchorRef?.current?.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  const handleChange = (option: SelectOption) => {
    onChange(option)
    setOpen(false)
  }

  return (
    <Box ref={anchorRef}>
      <Tooltip title={tooltip}>
        <IconButton onClick={handleToggle}>
          <ChevronDownIcon />
        </IconButton>
      </Tooltip>
      <Popper
        sx={{
          zIndex: theme.zIndex.tooltip,
        }}
        open={open}
        placement='bottom'
        anchorEl={anchorRef.current}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id='split-button-menu' autoFocusItem>
                  {options.map(option => (
                    <MenuItem
                      key={option.value}
                      onClick={() => handleChange(option)}
                      selected={option.value === selectedOption}
                      sx={{
                        width: '10rem',
                      }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  )
}
