import { AppStore } from '@storage/stores/app.store'
import { AxiosError, AxiosResponse } from 'axios'

export const onFulfilled = (appStore: AppStore) => {
  return (response: AxiosResponse<any>) => {
    appStore.hideLoader()

    return response
  }
}

export const onRejected = (appStore: AppStore) => {
  return (error: AxiosError) => {
    appStore.hideLoader()

    return Promise.reject(error)
  }
}
