import { ContainerTurnoversFilterDto } from '@storage/app/api'
import { action, makeObservable, observable } from 'mobx'

export class PlanningStore {
  filter?: ContainerTurnoversFilterDto
  numberOfContainersMatchingFilter = -1

  containerTurnoverId?: string

  constructor() {
    makeObservable(this, {
      filter: observable,
      setFilter: action,
      containerTurnoverId: observable,
      setContainerTurnover: action,
      numberOfContainersMatchingFilter: observable,
    })
  }

  setFilter(filter: ContainerTurnoversFilterDto, numberOfContainers: number) {
    this.numberOfContainersMatchingFilter = numberOfContainers
    this.filter = { ...filter }
  }

  setContainerTurnover(containerTurnoverId: string) {
    this.containerTurnoverId = containerTurnoverId
  }
}
