import { FormProps } from '@storage/app/models'
import ListItemCard from '@storage/components/ListItemCard'
import { ControlledCheckbox } from '@storage/components/form-controls'
import useFormWithSchema from '@storage/hooks/use-form-with-schema.hook'
import { Box, Stack } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { DashboardWidgetsVisibilityFormProfile } from './dashboard-preferences-form.profile'
import { schema } from './dashboard-preferences-form.scheme'
import { GeneralIcon, YardIcon } from '@tom-ui/ui'

interface Props extends FormProps {
  onSubmit: (formValues: DashboardWidgetsVisibilityFormProfile) => Promise<void> | void
  id: string
  defaultValues: DashboardWidgetsVisibilityFormProfile
}

export const DashboardPreferencesForm = ({ id, defaultValues, onSubmit }: Props) => {
  const { handleSubmit, formState, control } =
    useFormWithSchema<DashboardWidgetsVisibilityFormProfile>(schema, defaultValues)

  const { t } = useTranslate()

  return (
    <Box id={id} component='form' noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <ListItemCard
          icon={<GeneralIcon />}
          title={t('yardCapacity', 'Yard Capacity')}
          subtitle={t(
            'yardCapacityDescription',
            'You will be able to see the overall capacity of the yard',
          )}
          action={
            <ControlledCheckbox
              name='isYardCapacityWidgetVisible'
              formState={formState}
              control={control}
            />
          }
        />

        <ListItemCard
          icon={<YardIcon />}
          title={t('containerInformation', 'Container Information')}
          subtitle={t(
            'containerAttributesDescription',
            'You will be able to see aggregated data by attributes',
          )}
          action={
            <ControlledCheckbox
              name='isContainerAttributesWidgetVisible'
              formState={formState}
              control={control}
            />
          }
        />
      </Stack>
    </Box>
  )
}
