import { IOrderItem } from '@planning/rt-stores/order/OrderItem'
import { action, computed, makeObservable, observable } from 'mobx'
import { SelectOrderViewStore } from './SelectOrderViewStore'

export class EditOrCreateOrderViewStore {
  selectedOrder?: IOrderItem
  filter = ''
  isLoading = false

  constructor(private parentStore: SelectOrderViewStore) {
    makeObservable(this, {
      selectedOrder: observable,
      selectedContainer: computed,
      selectOrder: action,
    })
  }

  get selectedContainer() {
    return !this.parentStore.selectedContainer?.activeOrderIds.length
  }

  selectOrder(order: IOrderItem) {
    this.selectedOrder = order
  }
}
