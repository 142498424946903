import { Drawer, Typography } from '@mui/material'
import { LoaderButton } from '@planning/components/LoaderButton'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC, ReactElement } from 'react'

interface Props {
  open: boolean
  text: string
  onConfirm: () => void | Promise<void>
  onClose: () => void | Promise<void>
  children?: ReactElement | ReactElement[]
}

export const ConfirmationDrawerMobile: FC<Props> = observer(
  ({ open, text, onConfirm, onClose, children }) => {
    const { t } = useTranslate()
    return (
      <Drawer
        open={open}
        onClose={onClose}
        anchor='bottom'
        PaperProps={{
          sx: { p: '0.5rem' },
        }}
      >
        <Typography variant='subtitle2'>{text}</Typography>
        {children}
        <LoaderButton
          variant='contained'
          sx={{ marginTop: '1rem' }}
          fullWidth
          color='secondary'
          // Needs to be set as OnClick as well, bc the Drawer is rendered outside the <form>
          onClick={onConfirm}
        >
          {t('confirm', 'Confirm')}
        </LoaderButton>
      </Drawer>
    )
  },
)
