import { Box, SxProps } from '@mui/material'
import { ReactNode } from 'react'
import { useMinimalsTheme } from '../../../hooks'

interface Props {
  children: ReactNode
  sx?: SxProps
}
export const ContainerMobile = ({ children, sx }: Props) => {
  const theme = useMinimalsTheme()

  return (
    <Box
      sx={{
        ...sx,
        paddingLeft: theme.customSpacing.m,
        paddingRight: theme.customSpacing.m,
        [theme.breakpoints.up('sm')]: {
          paddingLeft: theme.customSpacing.l,
          paddingRight: theme.customSpacing.l,
        },
        [theme.breakpoints.up('md')]: {
          paddingLeft: '96px',
          paddingRight: '96px',
        },
        [theme.breakpoints.up('xl')]: {
          paddingLeft: '128px',
          paddingRight: '128px',
        },
        maxWidth: `100% !important`,
      }}
    >
      {children}
    </Box>
  )
}
