import { Add, Edit, ExpandMore, Warehouse } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { MouseEvent, useEffect, useState } from 'react'
import GeneralCargoReservationTable from './components/GeneralCargoReservationTable'
interface GeneralCargoOrderReservationProps {
  generalCargoOrderId: number
  cargoItemId?: number
  quantity: number
  showActionButton: boolean
}

const GeneralCargoOrderReservation = observer(
  ({
    generalCargoOrderId,
    cargoItemId,
    quantity,
    showActionButton,
  }: GeneralCargoOrderReservationProps) => {
    const { t } = useTranslate()
    const { generalCargoOrderReservationUIStore } = useStores()

    const [expanded, setExpanded] = useState(false)
    // Handles situations where the order does not exist for some reason in storage
    const [disabled, setDisabled] = useState(false)

    // Load all necessary data
    useEffect(() => {
      const loadPlannedOccupancies = async (orderId: number, cargoItemId?: number) => {
        try {
          await generalCargoOrderReservationUIStore.loadGeneralCargoOrdersReservationData(
            orderId,
            cargoItemId,
          )
        } catch (error) {
          setDisabled(true)
        }
      }

      if (generalCargoOrderId) {
        loadPlannedOccupancies(generalCargoOrderId, cargoItemId)
      }
    }, [cargoItemId, generalCargoOrderId, generalCargoOrderReservationUIStore])

    const generalCargoOrderReservations =
      generalCargoOrderReservationUIStore.generalCargoOrderReservations(
        generalCargoOrderId,
        cargoItemId,
      )

    const accordionStyle = {
      '&.Mui-expanded': {
        boxShadow: 'none',
        borderRadius: 0,
      },
    }

    const handleOnChange = (event: React.SyntheticEvent, expanded: boolean) => {
      if (generalCargoOrderReservations.length) {
        setExpanded(expanded)
      }
    }

    const accordionTitle = generalCargoOrderReservations.length
      ? t('reservationAvailable', 'Reservation Available')
      : t('noReservation', 'No reservation')

    const handleActionClick = (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      generalCargoOrderReservationUIStore.toggleDialog(generalCargoOrderId, cargoItemId, quantity)
    }

    const expandIcon = generalCargoOrderReservations.length ? <ExpandMore /> : undefined

    const actionButton = generalCargoOrderReservations.length ? (
      <IconButton size='small' onClick={handleActionClick}>
        <Edit fontSize='inherit' />
      </IconButton>
    ) : (
      <IconButton size='small' onClick={handleActionClick}>
        <Add fontSize='inherit' />
      </IconButton>
    )

    return (
      <>
        <Accordion
          disabled={disabled}
          sx={accordionStyle}
          disableGutters
          expanded={expanded}
          onChange={handleOnChange}
        >
          <AccordionSummary
            expandIcon={expandIcon}
            sx={{
              '&.MuiAccordionSummary-root:hover': {
                cursor: generalCargoOrderReservations.length ? 'pointer' : 'default',
              },
            }}
          >
            <Stack width='100%' direction='row' gap={1} alignItems='center'>
              <Warehouse />
              <Typography>{accordionTitle}</Typography>
            </Stack>
            {showActionButton && actionButton}
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <GeneralCargoReservationTable rows={generalCargoOrderReservations} />
          </AccordionDetails>
        </Accordion>
      </>
    )
  },
)

export default GeneralCargoOrderReservation
