import { NavigateBefore, NavigateNext, ReportOutlined, WarningAmber } from '@mui/icons-material'
import { Box, Alert, Button, IconButton, Typography } from '@mui/material'
import { AppAlert } from '@storage/stores/alert.store'
import { useTranslate } from '@tolgee/react'
import { useEffect, useState } from 'react'

interface StackedAlertProps {
  alerts: AppAlert[]
}
const StackedAlert = ({ alerts }: StackedAlertProps) => {
  const [index, setIndex] = useState(0)
  const { t } = useTranslate()

  const onClickNext = () => index < alerts.length - 1 && setIndex(index + 1)
  const onClickPrevious = () => index > 0 && setIndex(index - 1)

  useEffect(() => {
    setIndex(0)
  }, [alerts])

  return (
    <Alert
      sx={{ boxShadow: 'none' }}
      iconMapping={{
        warning: (
          <Box sx={{ display: 'flex', gap: 1 }}>
            {alerts.length > 1 && (
              <Typography variant='subtitle2' fontSize={16}>
                {`${index + 1}/${alerts.length}`}
              </Typography>
            )}
            <WarningAmber fontSize='inherit' />
          </Box>
        ),
        error: (
          <Box sx={{ display: 'flex', gap: 1 }}>
            {alerts.length > 1 && (
              <Typography variant='subtitle2' fontSize={16}>
                {`${index + 1}/${alerts.length}`}
              </Typography>
            )}
            <ReportOutlined fontSize='inherit' />
          </Box>
        ),
      }}
      severity={alerts[index].severity}
      action={
        <Box sx={{ display: 'flex' }}>
          {alerts[index].onClickFix && (
            <Button onClick={alerts[index].onClickFix} color='warning' size='small'>
              {t('fix', 'Fix')}
            </Button>
          )}
          {alerts.length > 1 && (
            <>
              <IconButton
                onClick={onClickPrevious}
                size='small'
                disableRipple
                edge='start'
                sx={{ p: 0.5 }}
              >
                <NavigateBefore color='warning' fontSize='inherit' />
              </IconButton>
              <IconButton
                onClick={onClickNext}
                size='small'
                disableRipple
                edge='end'
                sx={{ p: 0.5 }}
              >
                <NavigateNext color='warning' fontSize='inherit' />
              </IconButton>
            </>
          )}
        </Box>
      }
    >
      {alerts[index].message}
    </Alert>
  )
}

export default StackedAlert
