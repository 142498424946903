import { SxProps, Theme } from '@mui/material'
import { OrderStatus } from '@planning/app/api'
import { orderStatus } from '@planning/constants'
import { IOrderItem } from '@planning/rt-stores/order/OrderItem'
import { IPaginatedStoreWithItems } from '@planning/stores/PaginatedStore'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FilterPaper } from './FilterPaper'
import { StatusRadioGroupFilter } from './StatusRadioGroupFilter'

interface Props {
  sx?: SxProps<Theme>
  store: IPaginatedStoreWithItems<IOrderItem>
}
export const DeliveryOrderFilter = observer(({ sx, store }: Props) => {
  const { t } = useTranslate()
  return (
    <FilterPaper sx={{ minWidth: '24rem', ...sx }}>
      <StatusRadioGroupFilter
        store={store}
        options={
          store.showCompleted
            ? orderStatus
            : orderStatus.filter(s => s.id !== OrderStatus.Open && s.id !== OrderStatus.Fulfilled)
        }
        title={t('drop-offOrderStatus', 'Drop-off order status')}
      />
    </FilterPaper>
  )
})
