import { Box } from '@mui/material'
import { RoutingInfoStore } from '@storage/stores/routing-info.store'
import { useTolgee } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import LocationInfo from './LocationInfo'

interface Props {
  dropOffContainers: string[]
  routingInfoStore: RoutingInfoStore
}

const ContainerDropOffInfo = observer(({ dropOffContainers, routingInfoStore }: Props) => {
  const { t } = useTolgee()

  useEffect(() => {
    const loadDropOffInfo = async () => {
      await routingInfoStore.fetchDropOffInfo(dropOffContainers)
    }
    loadDropOffInfo()
  }, [dropOffContainers, routingInfoStore])

  return (
    <Box>
      {routingInfoStore.dropOffInfo.length > 0 &&
        routingInfoStore.dropOffInfo.map(info => {
          if (!info.blockName) {
            routingInfoStore.incrementWarnings()
          }
          return (
            <Box key={info.containerNumber} sx={{ marginBottom: '16px' }}>
              <LocationInfo
                key={info.containerNumber}
                blockName={info.blockName ?? t('noLocation', 'No Location')}
                containerNumber={info.containerNumber}
                action={'Drop off'}
              />
            </Box>
          )
        })}
    </Box>
  )
})

export default ContainerDropOffInfo
