import { CarrierType } from '@planning/app/api'
import { RailTallyViewStore } from '@planning/pages/TallymanV2/Stores/RailTallyViewStore'
import { action, makeObservable, observable } from 'mobx'
import { IRailVisitItem } from '../railVisit/RailVisitItem'
import { IEntityStore } from '../types'
import { IVesselVisitItem } from '../vesselVisit/VesselVisitItem'
import { VesselTallyViewStore } from './VesselTallyViewStore'

export class TallymanV2ViewStore {
  selectedCarrierType: CarrierType = CarrierType.Vessel

  vesselTallyStore: VesselTallyViewStore
  railTallyStore: RailTallyViewStore

  constructor(
    railVisitItemStore: IEntityStore<IRailVisitItem>,
    vesselVisitItemStore: IEntityStore<IVesselVisitItem>,
  ) {
    makeObservable(this, {
      selectedCarrierType: observable,
      selectCarrierType: action,
    })
    this.railTallyStore = new RailTallyViewStore(this, railVisitItemStore)
    this.vesselTallyStore = new VesselTallyViewStore(vesselVisitItemStore)
  }

  selectCarrierType = (carrierType: CarrierType) => {
    this.selectedCarrierType = carrierType
  }
}
