import { AlertProps } from '@mui/material'
import { Theme, styled } from '@mui/material/styles'
//
import { MaterialDesignContent } from 'notistack'
import { ColorSchema } from '../palette'
import { ErrorIcon, InfoIcon, SuccessIcon, WarningIcon } from './CustomIcons'

// ----------------------------------------------------------------------

const getStandardAlertColorStyle = (theme: Theme, color: ColorSchema) => {
  const isLight = theme.palette.mode === 'light'

  return {
    color: theme.palette[color][isLight ? 'dark' : 'lighter'],
    backgroundColor: theme.palette[color][isLight ? 'lighter' : 'darker'],
    '& .MuiAlert-icon': {
      color: theme.palette[color][isLight ? 'dark' : 'light'],
    },
    '.MuiButton-root': {
      color: theme.palette[color].dark + ' !important',
    },
  }
}

const getStandardAlertStyle = (theme: Theme) => {
  return {
    ...theme.typography.subtitle2,
    alignItems: 'center',
    gap: theme.customSpacing.xs,
    boxShadow: theme.customShadows.z8,
    boderRadius: theme.customRadius.medium,
  }
}

export const getStandardAlertIcons = () => {
  return {
    info: <InfoIcon />,
    success: <SuccessIcon />,
    warning: <WarningIcon />,
    error: <ErrorIcon />,
  }
}

// ----------------------------------------------------------------------

export const StyledMaterialDesignContent = styled(MaterialDesignContent)(({ theme }) => ({
  '&.notistack-MuiContent-success': {
    ...getStandardAlertColorStyle(theme, 'success'),
  },
  '&.notistack-MuiContent-info': {
    ...getStandardAlertColorStyle(theme, 'info'),
  },
  '&.notistack-MuiContent-warning': {
    ...getStandardAlertColorStyle(theme, 'warning'),
  },
  '&.notistack-MuiContent-error': {
    ...getStandardAlertColorStyle(theme, 'error'),
  },
  '&.notistack-MuiContent': {
    ...getStandardAlertStyle(theme),
    '& > #notistack-snackbar': {
      gap: theme.customSpacing.xs,
    },
  },
}))

// ----------------------------------------------------------------------
const COLORS = ['info', 'success', 'warning', 'error'] as const

export default function Alert(theme: Theme) {
  const isLight = theme.palette.mode === 'light'

  const rootStyle = (ownerState: AlertProps) => {
    const standardVariant = ownerState.variant === 'standard'

    const filledVariant = ownerState.variant === 'filled'

    const outlinedVariant = ownerState.variant === 'outlined'

    const colorStyle = COLORS.map(color => ({
      ...(ownerState.severity === color && {
        // STANDARD
        ...(standardVariant && {
          ...getStandardAlertColorStyle(theme, color),
        }),
        // FILLED
        ...(filledVariant && {
          color: theme.palette[color].contrastText,
          backgroundColor: theme.palette[color].main,
        }),
        // OUTLINED
        ...(outlinedVariant && {
          color: theme.palette[color][isLight ? 'dark' : 'light'],
          border: `solid 1px ${theme.palette[color].main}`,
          '& .MuiAlert-icon': {
            color: theme.palette[color].main,
          },
        }),
        ...getStandardAlertStyle(theme),
      }),
    }))

    return [...colorStyle]
  }

  return {
    MuiAlert: {
      defaultProps: {
        iconMapping: getStandardAlertIcons(),
      },

      styleOverrides: {
        root: ({ ownerState }: { ownerState: AlertProps }) => rootStyle(ownerState),
        icon: {
          opacity: 1,
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          marginBottom: theme.customSpacing.xxs,
        },
      },
    },
  }
}
