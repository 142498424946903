import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material'
import { BerthSide, EquipmentDto } from '@operations/app/api'
import { EquipmentStatusChip } from '@operations/features/equipments/components/statusChip/EquipmentStatus'
import { useTranslate } from '@tolgee/react'
import { ConfirmationDialog } from '@tom-ui/ui'
import { useEffect, useState } from 'react'

interface Props {
  selectedCranes: number[]
  craneOptions: EquipmentDto[]
  equipmentIdsInUse: number[]
  berths: string[]
  side?: BerthSide
  isOpen: boolean
  hasWorkInstructions: boolean
  existingCraneSplitBerthSide: BerthSide | null | undefined
  onPlan: (cranes: number[]) => Promise<void> | void
  onClose: () => Promise<void> | void
}

export const SelectCranes = ({
  selectedCranes,
  craneOptions,
  equipmentIdsInUse,
  berths,
  side,
  isOpen,
  hasWorkInstructions,
  existingCraneSplitBerthSide,
  onPlan,
  onClose,
}: Props) => {
  const { t } = useTranslate()
  const [cranes, setCranes] = useState<number[]>(selectedCranes)

  const handleCraneChange = (event: SelectChangeEvent<number[]>) => {
    setCranes(event.target.value as number[])
  }

  const handleSubmit = () => {
    onPlan(cranes)
  }

  const handleClose = () => {
    onClose()
  }

  useEffect(() => {
    setCranes(selectedCranes)
  }, [selectedCranes, setCranes])

  return (
    <ConfirmationDialog
      open={isOpen}
      title={t('craneSplit', 'Crane split')}
      primaryActionText={t('plan', 'Plan')}
      primaryActionDataCy={'confirm-crane-split-creation-btn'}
      closeLabel={t('close', 'Close')}
      primaryActionDisabled={!cranes.length || berths.length === 0 || !side}
      maxWidth='xs'
      onClose={handleClose}
      onConfirm={handleSubmit}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
        <Typography variant='body1' textAlign='center'>
          {hasWorkInstructions
            ? side && side !== BerthSide.Unspecified
              ? existingCraneSplitBerthSide === side || !existingCraneSplitBerthSide
                ? t(
                    'craneSplitAdditionalInformation',
                    'In order to automatically plan the crane split, we need some additional information from you',
                  )
                : t(
                    'berthSideGotUpdatedYouNeedToRedoCraneSplit',
                    'berth side got updated, you need to redo the crane split',
                  ) +
                  `
                    ${existingCraneSplitBerthSide} => ${side}`
              : t(
                  'pleaseSpecifyBirthSideBeforePlanOperations',
                  'Please specify the Berth Side before plan operations',
                )
            : t(
                'pleaseInsertTheBapliFileBefore',
                'Please insert the baplie file before planning the operations',
              )}
        </Typography>
        <Box>
          <Typography variant='subtitle1' paddingBottom='.5rem'>
            {t('berth', 'Berth')}
          </Typography>
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <TextField
              label={t('berth', 'Berth')}
              value={berths.length === 0 ? t('unspecified', 'Unspecified') : berths.join(', ')}
              disabled
              fullWidth
            />
            <TextField label={t('side', 'Side')} value={side ?? ''} disabled fullWidth />
          </Box>
          {(berths.length === 0 || !side) && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '.5rem',
                marginTop: '.5rem',
              }}
            >
              <Typography variant='body1' color='red'>
                {t(
                  'pleaseSelectTheBerthAndSide',
                  'Please select the berth and side to the crane split',
                )}
              </Typography>
            </Box>
          )}
        </Box>
        <Box>
          <Typography variant='subtitle1' paddingBottom='.5rem'>
            {t('crane(s)', 'Crane(s)')}
          </Typography>
          <FormControl fullWidth>
            <InputLabel>{t('crane', 'Crane')}</InputLabel>
            <Select
              label={t('crane', 'Crane')}
              value={cranes}
              onChange={handleCraneChange}
              disabled={!hasWorkInstructions || !side || side === BerthSide.Unspecified}
              renderValue={selected =>
                craneOptions
                  .filter(c => selected.includes(c.id))
                  .map(c => c.name)
                  .join(', ')
              }
              multiple
              data-cy='select-cranes-for-split-field'
            >
              {craneOptions.map(crane => (
                <MenuItem
                  key={crane.id}
                  value={crane.id}
                  data-cy={`crane-option-${crane.name.toLowerCase()}`}
                >
                  <Checkbox checked={cranes.indexOf(crane.id) > -1} />
                  <ListItemText primary={crane.name} />
                  <EquipmentStatusChip
                    isUnavailable={crane.isOnMaintenance}
                    isInOperation={equipmentIdsInUse.includes(crane.id)}
                    alignment='end'
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
    </ConfirmationDialog>
  )
}
