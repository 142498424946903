import { Button } from '@mui/material'
import Stack from '@mui/material/Stack'
import { IssueStatus } from '@planning/app/api'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { IssuesViewStore } from '../Stores/IssuesViewStore'

interface Props {
  store: IssuesViewStore
}

export const IssuesTypeFilter = observer(({ store }: Props) => {
  const { t } = useTranslate()

  return (
    <Stack direction='row' alignItems='center' gap={1} padding={2}>
      <Button
        variant='contained'
        size='small'
        color={!store.issueFilterType ? 'secondary' : 'inherit'}
        onClick={() => store.setIssueFilterType()}
      >
        {t('all', 'All')} {store.issuesTypeCount.all}
      </Button>
      <Button
        variant='contained'
        size='small'
        color={store.issueFilterType === IssueStatus.Unresolved ? 'secondary' : 'inherit'}
        onClick={() => store.setIssueFilterType(IssueStatus.Unresolved)}
      >
        {t('unresolved', 'Unresolved')} {store.issuesTypeCount.unresolved}
      </Button>
      <Button
        variant='contained'
        size='small'
        color={store.issueFilterType === IssueStatus.FollowUp ? 'secondary' : 'inherit'}
        onClick={() => store.setIssueFilterType(IssueStatus.FollowUp)}
      >
        {t('needFollowUp', 'Need follow up')} {store.issuesTypeCount.followUp}
      </Button>
    </Stack>
  )
})
