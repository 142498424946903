import { Card } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { ReactNode } from 'react'

interface LabeledNumberContainerProps {
  children: ReactNode
}

export const LabeledNumberContainer = ({ children }: LabeledNumberContainerProps) => {
  const { palette } = useMinimalsTheme()

  return (
    <Card
      sx={{
        borderRadius: 1,
        display: 'flex',
        p: 2,
        width: '322px',
        boxShadow: 0,
        gap: 2,
        justifyContent: 'center',
        backgroundColor: palette.grey[100],
      }}
    >
      {children}
    </Card>
  )
}
