import { MenuItem, Paper, Stack, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { usePlanningStore } from '@planning/AppProvider'
import {
  CargoType,
  CarrierType,
  CarrierVisitStatus,
  RailVisitResponseDto,
  VesselVisitDto,
} from '@planning/app/api'
import { CargoTypeIconLabel } from '@planning/components/CargoTypeIconLabel'
import { CarrierTypeIcon } from '@planning/components/CarrierTypeIcon'
import { CarrierVisitStatusChip } from '@planning/components/CarrierVisitStatusChip'
import { LengthConflictChip } from '@planning/components/LengthConflictChip'
import { MissingRailcarLengthConflictChip } from '@planning/components/MissingRailcarLengthConflictChip'
import { GridColumn } from '@planning/components/PaginatedTable'
import { TrackConflictChip } from '@planning/components/TrackConflictChip'
import { VesselVisitConflictChip } from '@planning/components/VesselVisitConflictChip'
import { emptyIndicator } from '@planning/constants'
import { EditRailVisitForm } from '@planning/pages/RailVisit/Components/Forms/EditRailVisitForm'
import { vesselVisitDetailPages } from '@planning/pages/VesselVisit'
import { EditVesselVisitForm } from '@planning/pages/VesselVisit/Components/Forms/EditVesselVisitForm'
import { VesselVisitItem } from '@planning/rt-stores/vesselVisit/VesselVisitItem'
import { VisitTableViewStore } from '@planning/rt-stores/visit/VisitTableViewStore'
import { VisitItem } from '@planning/rt-stores/visit/VisitViewStore'
import { carrierVisitService } from '@planning/services'
import { IPaginatedStoreWithItems, SortingModel } from '@planning/stores/PaginatedStore'
import { formatDayAndNumber, formatTime } from '@planning/utils'
import { useTranslate } from '@tolgee/react'
import { ThreeDotMenu } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'
import { VisitCarrierTypeFilter } from './VisitCarrierTypeFilter'
import { VisitCounts } from './VisitCounts'

interface CellWithTitleAndSubtitleProps {
  title?: string | null
  subtitle?: string | null
}
const CellWithTitleAndSubtitle = ({ title, subtitle }: CellWithTitleAndSubtitleProps) => {
  return (
    <Stack>
      <Typography variant='subtitle2'>{title ?? emptyIndicator}</Typography>
      {subtitle && (
        <Typography variant='body2' color='secondary'>
          {subtitle}
        </Typography>
      )}
    </Stack>
  )
}

interface Props {
  store: IPaginatedStoreWithItems<VisitItem>
  tableStore: VisitTableViewStore
}
export const VisitsTable = observer(({ store, tableStore }: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslate()
  const { appViewStore, drawerStore } = usePlanningStore()

  // TODO [OCTA-1216] Remove this variable after the feature flag is validated
  const vesselVisitBerthPlanningEnabled = useBooleanFlagValue('vessel-visit-berth-planning', false)
  const railPlanningEnabled = useBooleanFlagValue('rail-planning', false)

  const onEdit = (visit: VisitItem) => {
    switch (visit.carrierType) {
      case CarrierType.Vessel:
        // TODO [OCTA-1216] Remove this condition after the feature flag is validated
        if (vesselVisitBerthPlanningEnabled) {
          navigate(`/vessel-visits-planning/${visit.id}`)
        } else {
          drawerStore.showView(
            <EditVesselVisitForm
              vesselVisit={visit.data}
              vessel={(visit as unknown as VesselVisitItem).vessels.map(v => v.data)}
              onClose={drawerStore.close}
              showAlert={appViewStore.setShowAlert}
            />,
            {
              title: t('edit', 'Edit'),
              subTitle: t('vesselVisit', 'Vessel Visit'),
              primaryActionLabel: t('save', 'Save'),
              closeActionLabel: t('close', 'Close'),
              formId: 'edit-vessel-visit',
            },
          )
        }
        break

      case CarrierType.Train:
        // TODO Remove this condition after the feature flag is validated
        if (railPlanningEnabled) {
          navigate(`/rail-visits-planning/${visit.id}`)
        } else {
          drawerStore.showView(
            <EditRailVisitForm
              onClose={drawerStore.close}
              visit={visit.data as RailVisitResponseDto}
            />,
            {
              title: t('edit', 'Edit'),
              subTitle: t('railVisit', 'Rail Visit'),
              primaryActionLabel: t('save', 'Save'),
              closeActionLabel: t('close', 'Close'),
              formId: 'edit-rail-visit',
            },
          )
        }

        break
    }
  }

  const onCancel = async (visit: VisitItem) => {
    try {
      const confirmed = await appViewStore.setOpenConfirmDialog(
        true,
        t('doYouWantToCancelTheVisit', 'Do you want to cancel the visit?'),
      )
      if (confirmed) {
        await carrierVisitService.delete(visit.id!)
        appViewStore.setShowAlert(
          'success',
          t('VisitWasCancelledSuccessfully', 'Visit was cancelled successfully'),
        )
      }
    } catch (error) {
      appViewStore.setShowAlert('error', t('failedToCancel', 'Failed to cancel'))
    }
  }

  const onViewDashboard = (visit: VisitItem) => {
    switch (visit.carrierType) {
      case CarrierType.Vessel:
        navigate(`/vessel-visits/${visit.id}/${vesselVisitDetailPages.dashboard}`)
        break

      case CarrierType.Train:
        navigate(`/rail-visits/${visit.id}/dashboard`)
        break
    }
  }

  const columns: GridColumn<VisitItem>[] = [
    {
      field: 'carrierName',
      headerName: t('carrier', 'Carrier'),
      minWidth: 200,
      renderCell: ({ row }) => {
        let isBerthConflicted = false
        if (row.carrierType === CarrierType.Vessel) {
          isBerthConflicted = (row.data as VesselVisitDto).isConflicted || false
        }

        let isTrackConflicted = false
        let isRailcarLengthConflict = false
        let hasAssignedRailcarsWithoutLength = false
        if (row.carrierType === CarrierType.Train) {
          const railVisit = row.data as RailVisitResponseDto

          isTrackConflicted = railVisit.isConflicted || false
          isRailcarLengthConflict =
            railVisit.isInboundRailcarLengthConflicted ||
            railVisit.isOutboundRailcarLengthConflicted ||
            false

          hasAssignedRailcarsWithoutLength = railVisit.hasAssignedRailcarsWithoutLength || false
        }

        return (
          <Stack direction='row' spacing={2} alignItems={'center'}>
            <CellWithTitleAndSubtitle
              title={row.carrierName}
              subtitle={row.shippingLine ?? (row.data as RailVisitResponseDto).trainOperator}
            />
            {isBerthConflicted && <VesselVisitConflictChip />}
            {isTrackConflicted && <TrackConflictChip />}
            {isRailcarLengthConflict && <LengthConflictChip />}
            {hasAssignedRailcarsWithoutLength && <MissingRailcarLengthConflictChip />}
          </Stack>
        )
      },
      flex: 1,
      orderBy: 'carrierName',
    },
    {
      field: 'eta',
      headerName: t('timings', 'Timings'),
      orderBy: 'arrival',
      renderCell: ({ row }) => {
        return (
          <Stack flexDirection='row' justifyContent='space-between' gap={2} width='100%'>
            <CellWithTitleAndSubtitle
              title={formatDayAndNumber(row.arrival) ?? emptyIndicator}
              subtitle={`${formatTime(row.arrival)} ${row.data?.ata ? t('ata', 'ATA') : t('eta', 'ETA')}`}
            />
            <CellWithTitleAndSubtitle
              title={formatDayAndNumber(row.departure) ?? emptyIndicator}
              subtitle={`${formatTime(row.departure)} ${row.data?.atd ? t('atd', 'ATD') : t('etd', 'ETD')}`}
            />
          </Stack>
        )
      },
      minWidth: 210,
      flex: 0.5,
    },
    {
      field: 'carrierType',
      headerName: t('type', 'Type'),
      renderCell: ({ row }) => <CarrierTypeIcon type={row.carrierType} />,
      width: 70,
      sortable: false,
    },
    {
      field: 'cargoType',
      headerName: t('cargo', 'Cargo'),
      renderCell: ({ row }) => (
        <CargoTypeIconLabel
          cargoType={row.data?.cargoType ?? CargoType.Unknown}
          showLabel={false}
        />
      ),
      width: 70,
      sortable: false,
    },
    {
      field: 'dischargeEstimate',
      headerName: `${t('in', 'In')} / ${t('out', 'Out')}`,
      valueGetter: ({ row }) => {
        return `${row.dischargeCount} / ${row.loadCount}`
      },
      minWidth: 100,
      sortable: false,
      flex: 0.5,
    },
    {
      field: 'tripIds',
      headerName: t('tripIds', 'Trip IDs'),
      renderCell: ({ row }) => {
        const inboundTripIds =
          row.data?.inboundTripIds &&
          row.data?.inboundTripIds.length > 0 &&
          row.data?.inboundTripIds[0] !== ''

        const outboundTripIds =
          row.data?.outboundTripIds &&
          row.data?.outboundTripIds.length > 0 &&
          row.data?.outboundTripIds[0] !== ''

        return (
          <Stack>
            {inboundTripIds && (
              <Stack flexDirection='row' gap={1}>
                <Typography variant='body2'>{t('in', 'In')}:</Typography>
                <Typography variant='body2'>
                  {row.data?.inboundTripIds?.filter(id => id.trim() !== '').join(', ')}
                </Typography>
              </Stack>
            )}
            {outboundTripIds && (
              <Stack flexDirection='row' gap={1}>
                <Typography variant='body2'>{t('out', 'Out')}:</Typography>
                <Typography variant='body2'>
                  {row.data?.outboundTripIds?.filter(id => id.trim() !== '').join(', ')}
                </Typography>
              </Stack>
            )}
            {!inboundTripIds && !outboundTripIds && emptyIndicator}
          </Stack>
        )
      },
      minWidth: 100,
      sortable: false,
      flex: 0.75,
    },
    {
      field: 'berthOrTrack',
      headerName: t('berthOrTrack', 'Berth/Track'),
      minWidth: 20,
      sortable: false,
      flex: 0.5,
    },
    {
      field: 'status',
      orderBy: 'status',
      headerName: t('status', 'Status'),
      minWidth: 150,
      renderCell: ({ row }) => {
        return <CarrierVisitStatusChip status={row.status} />
      },
      flex: 0.5,
    },
    {
      field: 'actions',
      headerName: '',
      minWidth: 25,
      sortable: false,
      renderCell: ({ row }) => (
        <Stack alignItems='end' width='100%' data-cy='visits-table-dots-menu'>
          <ThreeDotMenu>
            <MenuItem
              onClick={() => onViewDashboard(row)}
              data-cy='visits-table-dots-menu-view-dashboard'
            >
              {t('viewDashboard', 'View dashboard')}
            </MenuItem>
            <MenuItem onClick={() => onEdit(row)} data-cy='visits-table-dots-menu-edit'>
              {t('editVisit', 'Edit visit')}
            </MenuItem>
            {row.data?.status === CarrierVisitStatus.Expected ? (
              <MenuItem onClick={() => onCancel(row)} data-cy='visits-table-dots-menu-cancel'>
                {t('removeVisit', 'Remove visit')}
              </MenuItem>
            ) : (
              <></>
            )}
          </ThreeDotMenu>
        </Stack>
      ),
    },
  ]

  return (
    <Paper variant='elevation' elevation={1} sx={{ overflow: 'hidden' }}>
      <VisitCarrierTypeFilter store={tableStore} />
      <VisitCounts store={tableStore} />
      <DataGrid
        rows={tableStore.visitItems.slice(
          store.pageSize * store.currentPageIndex,
          store.pageSize * (store.currentPageIndex + 1),
        )}
        columns={columns}
        paginationModel={{ pageSize: store.pageSize, page: store.currentPageIndex }}
        rowCount={tableStore.visitItems.length}
        paginationMode='server'
        loading={appViewStore.isLoading}
        onPaginationModelChange={(model: { page: number; pageSize: number }) => {
          if (store.currentPageIndex !== model.page) store.setCurrentPageIndex(model.page)
          if (store.pageSize !== model.pageSize) store.setPageSize(model.pageSize)
        }}
        disableColumnFilter
        disableColumnMenu
        onRowClick={({ row }) => onViewDashboard(row)}
        sortModel={
          store.sortingModel.orderBy && store.sortingModel.field
            ? [
                {
                  field: store.sortingModel.field,
                  sort: store.sortingModel.isDescending ? 'desc' : 'asc',
                },
              ]
            : []
        }
        onSortModelChange={model => {
          if (!model.length) {
            store.setSortingModel({
              field: '',
              orderBy: undefined,
              isDescending: false,
            })
            return
          }

          const item = model[0]
          const column = columns.find(c => c.field === item.field)
          store.setSortingModel({
            field: column?.field,
            orderBy: column?.orderBy,
            isDescending: item.sort === 'desc',
          } as SortingModel<any>)
        }}
        onCellClick={(column, event) => {
          if (column.field === 'actions') {
            event.stopPropagation()
          }
          event.preventDefault()
        }}
        pageSizeOptions={[10, 25, 50]}
      />
    </Paper>
  )
})
