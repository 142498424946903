import { OperationType, VesselBayDto } from '@operations/app/api'
import { Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'

export interface Props {
  operationType: OperationType
  bays: VesselBayDto[]
  workStatusPerBay: any[] | undefined
}

export const BayWorkStatus = ({ operationType, bays, workStatusPerBay }: Props) => {
  const { t } = useTranslate()

  const getWorkStatus = () => {
    if (!workStatusPerBay) return '0/0'

    let total = 0,
      finished = 0

    bays.forEach(x => {
      const bayOperationStatus = workStatusPerBay
        .find(y => y.bay === x.bay)
        ?.operationStatus.find((x: { operationType: string }) => x.operationType === operationType)

      total += bayOperationStatus?.total ?? 0
      finished += bayOperationStatus?.finished ?? 0
    })

    return `${finished}/${total}`
  }

  return (
    <Typography variant='caption' color='secondary'>
      {operationType === OperationType.Inbound
        ? t('discharged', 'Discharged')
        : t('loaded', 'Loaded')}
      &nbsp;
      {getWorkStatus()}
    </Typography>
  )
}
