import { ControlledTextField } from '@billing/components/form-controls'
import { Grid, Stack, Tooltip, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { BinIcon, IconButton, PlusIcon, useMinimalsTheme } from '@tom-ui/ui'
import { Control, FormState, useFieldArray } from 'react-hook-form'
import { CommodityFormProfile, defaultAttributeValues } from './commodity-form.profile'

interface Props {
  control: Control<CommodityFormProfile>
  formState: FormState<CommodityFormProfile>
  disabled?: boolean
}

export const CommodityAttributeForm = ({ control, formState, disabled }: Props) => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'attributes',
  })

  return (
    <Stack mt={theme.customSpacing.m}>
      <Stack flexDirection='row' gap={theme.customSpacing.m} alignItems='center'>
        <Typography variant='subtitle2'>{t('attributes', 'Attributes')}</Typography>
        {!disabled && (
          <Tooltip title={t('add', 'Add')}>
            <IconButton onClick={() => append(defaultAttributeValues)}>
              <PlusIcon />
            </IconButton>
          </Tooltip>
        )}
      </Stack>

      <Stack gap={theme.customSpacing.m}>
        {fields.map((field, index) => (
          <Grid key={field.id} container spacing={theme.customSpacing.m} alignItems='center'>
            <Grid item xs={12} sm={5}>
              <ControlledTextField
                name={`attributes.${index}.name`}
                formState={formState}
                control={control}
                label={t('name', 'Name')}
                fullWidth
                showAsterisk
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={10} sm={5}>
              <ControlledTextField
                name={`attributes.${index}.value`}
                formState={formState}
                control={control}
                label={t('value', 'Value')}
                fullWidth
                showAsterisk
                disabled={disabled}
              />
            </Grid>
            {!disabled && (
              <Grid item xs={12} sm={2}>
                <Tooltip title={t('remove', 'Remove')}>
                  <IconButton color='error' onClick={() => remove(index)}>
                    <BinIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        ))}

        {!fields.length && (
          <Typography variant='body2'>
            {t('noAttributesToDisplay', 'No attributes to display')}
          </Typography>
        )}
      </Stack>
    </Stack>
  )
}
