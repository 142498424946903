import { Stack, Typography } from '@mui/material'
import { CommodityResponseDto } from '@planning/app/api'
import { CommodityAutoComplete } from '@planning/components/commodity/CommodityAutoComplete'
import commodityService from '@planning/services/commodityService'
import { CommodityAutocompleteViewStore } from '@planning/stores/commodity/CommodityAutocompleteViewStore'
import {
  OutboundOrderValidationDto,
  ValidateOutboundOrderFunc,
} from '@planning/stores/gateControl/ValidateOutboundDto'
import { GeneralCargoViewStore } from '@planning/stores/generalCargo/GeneralCargoViewStore'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { PackageControl } from './PackageControl'

interface IProps {
  isOrderFulfilled: boolean
  store: GeneralCargoViewStore
  renderDamage?: (orderId: number) => React.ReactElement
  validationParams: OutboundOrderValidationDto
  onValidationParamsChanged: (params: any) => void
  validateOutboundRequest?: ValidateOutboundOrderFunc
}

export const CargoControl: FC<IProps> = observer(
  ({
    store,
    isOrderFulfilled,
    renderDamage,
    validationParams,
    onValidationParamsChanged,
    validateOutboundRequest,
  }) => {
    const { t } = useTranslate()
    const {
      control,
      formState: { errors },
    } = useFormContext()

    const [commodity, setCommodity] = useState<CommodityResponseDto | undefined>()
    const commodityAutocompleteStore = useMemo(() => new CommodityAutocompleteViewStore(), [])

    useEffect(() => {
      const fetch = async () => {
        await commodityAutocompleteStore.fetch(commodityAutocompleteStore.filter)

        const { selectedOrder } = store
        if (selectedOrder) {
          const commodityId = selectedOrder.commodityId
          if (commodityId) {
            return await commodityService.getByIds([commodityId])
          }
        }
      }

      fetch()
        .then(commodity => {
          if (commodity) setCommodity(commodity[0])
        })
        .catch(error => console.log(error.message))
    }, [commodityAutocompleteStore, store])

    return (
      <Stack gap={1}>
        <Typography variant='subtitle1'>{t('cargo', 'Cargo')}</Typography>
        {validateOutboundRequest && validateOutboundRequest(validationParams)}
        <Stack gap={2}>
          <Controller
            control={control}
            name='commodity'
            rules={{ required: true }}
            render={({ field: { onChange } }) => (
              <CommodityAutoComplete
                store={commodityAutocompleteStore}
                selectedCommodity={commodity}
                handleOnChange={commodity => {
                  onChange(commodity)
                  setCommodity(commodity)
                  store.setCommodityPackages(commodity?.packageIds ?? [])
                  onValidationParamsChanged({ commodityId: commodity?.id })
                }}
                error={!!errors.commodity}
                helperText={errors.commodity ? t('fieldIsRequired', 'Field is required.') : ''}
                disable={isOrderFulfilled}
              />
            )}
          />

          <PackageControl
            packageOptions={store.packagesOptions}
            packageId={store.selectedOrder?.packageId}
            packageName={store.selectedOrder?.packageName}
            canUpdateActualAmountAndStatus={!!store.selectedOrder}
            isOrderFulfilled={isOrderFulfilled}
            onValidationParamsChanged={onValidationParamsChanged}
          />
        </Stack>

        {renderDamage && store.selectedOrder && renderDamage(store.selectedOrder.id)}
      </Stack>
    )
  },
)
