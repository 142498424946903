import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteValue,
  SxProps,
  TextField,
  Theme,
  debounce,
} from '@mui/material'
import truckService from '@planning/services/truckService'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'

interface Props {
  onChange: (
    event: React.SyntheticEvent,
    value?: AutocompleteValue<any, any, any, any>,
    reason?: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any>,
  ) => void | Promise<void>
  value?: string | null
  error?: boolean
  helperText?: string
  disabled?: boolean
  fullWidth?: boolean
  sx?: SxProps<Theme>
}

export const TruckLicensePlateAutocomplete: FC<Props> = observer(
  ({ onChange, value, error, helperText, disabled, fullWidth, sx }) => {
    const { t } = useTranslate()

    const fetch = async (filter?: string) => {
      return (await truckService.get(1, 10, filter)).payload.map(p => p.licensePlate) ?? []
    }

    const OnSearchFilterChange = debounce(async (filter?: string) => {
      setOptions(await fetch(filter))
    }, 500)

    const [open, setOpen] = useState(false)
    const [options, setOptions] = useState<string[]>([])

    useEffect(() => {
      if (open) {
        fetch().then(payload => setOptions(payload))
      } else {
        setOptions([])
      }
    }, [open])

    return (
      <Autocomplete
        freeSolo
        disablePortal
        value={value}
        fullWidth={fullWidth}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        options={options}
        sx={sx}
        disabled={disabled}
        onChange={(e, value) => {
          if (!value) return

          onChange(e, value)
        }}
        renderInput={params => (
          <TextField
            {...params}
            label={t('truckPlate', 'Truck Plate')}
            error={error}
            helperText={helperText}
            disabled={disabled}
            onChange={async (event: any) => {
              onChange(event, event.target.value)
              OnSearchFilterChange(event.target.value)
            }}
          />
        )}
      />
    )
  },
)
