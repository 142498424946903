import { RailcarResponseDto, RailcarsApi } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'
import { Event, IEvent, IQueryHandler, IQueryMessage } from '../messageBus'

export class GetRailcarsQuery implements IQueryMessage {
  static type = 'GetRailcarsQuery'
  type = GetRailcarsQuery.type

  constructor(
    public page: number,
    public pageSize: number,
  ) {}

  getKey = () => {
    return JSON.stringify(this)
  }
}

const httpClient = createApiClient(RailcarsApi)

export const getRailcarsQueryHandler: IQueryHandler<
  GetRailcarsQuery,
  IEvent<RailcarResponseDto[]>
> = async query => {
  const { data } = await httpClient.get(query.page, query.pageSize)

  return new Event(GetRailcarsQuery.type, data.payload)
}
