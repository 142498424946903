import { ContainerDamageResponseDto } from '@planning/app/api'
import { action, makeObservable, observable } from 'mobx'

export interface IDamageReportViewStore {
  isOpen: boolean
  containerId: number
  damageReport?: ContainerDamageResponseDto
  data?: Partial<ContainerDamageResponseDto>
  setOpen: (open: boolean) => void
  setData: (data: Partial<ContainerDamageResponseDto>) => void
  setDamageReport: (damageReport?: ContainerDamageResponseDto) => void
  saveChanges: () => Promise<void>
  reset: () => void
}

export class DamageReportViewStore implements IDamageReportViewStore {
  isOpen = false
  data?: Partial<ContainerDamageResponseDto>
  damageReport?: ContainerDamageResponseDto

  constructor(
    public containerId: number,
    private onSubmitCreate: (
      containerId: number,
      data: Partial<ContainerDamageResponseDto>,
    ) => Promise<void> | void,
    private onSubmitEdit: (
      editedReport: Partial<ContainerDamageResponseDto>,
      initialReport: ContainerDamageResponseDto,
    ) => Promise<void> | void,
  ) {
    makeObservable(this, {
      containerId: observable,
      damageReport: observable,
      data: observable,
      isOpen: observable,
      setOpen: action,
      setData: action,
      setDamageReport: action,
      reset: action,
    })
  }

  private isCreate = () => !this.damageReport
  private isEdited = () =>
    !this.isCreate() && !!this.damageReport && this.damageReport !== this.data

  setDamageReport = (damageReport?: ContainerDamageResponseDto) => {
    this.damageReport = damageReport
  }

  setOpen = (open: boolean) => {
    this.isOpen = open
  }

  setData = (data?: Partial<ContainerDamageResponseDto>) => {
    this.data = data
  }

  saveChanges = async () => {
    if (!this.data) return

    if (this.isCreate()) this.onSubmitCreate(this.containerId, this.data)

    if (this.isEdited()) await this.onSubmitEdit(this.data, this.damageReport!)

    this.reset()
  }

  reset = () => {
    this.setOpen(false)
    this.setDamageReport()
    this.setData()
  }
}
