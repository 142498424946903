import { useOperationsStore } from '@operations/AppProvider'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { JobMonitoringDrawerContainer } from '@operations/features/job/containers/JobDrawerContainer'
import { JobMonitoringContainer } from '@operations/features/job/containers/JobMonitoringContainer'
import { Container } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { FC, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'

const JobMonitoring: FC = withAuthenticationRequired(() => {
  const { t } = useTranslate()

  const { cargoUnitStore, equipmentStore, yardBlockStore } = useOperationsStore()

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        cargoUnitStore.loadOnTerminal(),
        equipmentStore.load(),
        yardBlockStore.loadYardBlocks(),
      ])
    }

    fetchData()
  }, [cargoUnitStore, equipmentStore, yardBlockStore])

  return (
    <>
      <Helmet>
        <title>{t('jobMonitoringTomOperation', 'Job Monitoring - Tom Operation')}</title>
      </Helmet>

      <Container maxWidth='xl'>
        <JobMonitoringContainer />
        <JobMonitoringDrawerContainer />
      </Container>
    </>
  )
})

export default JobMonitoring
