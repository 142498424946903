import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { Box, Grid, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { OperationType } from '@operations/app/api'
import { BayAmount } from '@operations/features/craneSplit/models/bay.model'

const OperationContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '.5rem',
  alignItems: 'center',
  width: '55px',
}))

const ArrowContainer = styled(Box)(() => ({
  display: 'flex',
  gap: '3px',
  alignItems: 'center',
}))

const VesselBayContainer = styled(Box)<{ height?: string }>(({ theme, height }) => ({
  display: 'flex',
  placeContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: height ? height : '25px',
  background: theme.palette.grey[200],
  fontSize: '.75rem',
}))

const VesselHatch = styled(Box)(() => ({
  width: '100%',
  height: '3px',
  background: '#637381',
}))

interface Props {
  operationType: OperationType
  bayAmount: BayAmount
  isSingleTwentyBay: boolean
}

interface BayOperationContainerProps {
  bayAmount: BayAmount
}

const RegularBayOperationContainer = ({ bayAmount }: BayOperationContainerProps) => {
  return (
    <Grid container spacing={0.3}>
      <Grid item={true} xs={6}>
        <VesselBayContainer>{bayAmount.deck.previousBayAmount}</VesselBayContainer>
      </Grid>
      <Grid item={true} xs={6}>
        <VesselBayContainer>{bayAmount.deck.nextBayAmount}</VesselBayContainer>
      </Grid>
      <Grid item={true} xs={12}>
        <VesselBayContainer>{bayAmount.deck.bayAmount}</VesselBayContainer>
      </Grid>
      <Grid item={true} xs={12}>
        <VesselHatch />
      </Grid>
      <Grid item={true} xs={6}>
        <VesselBayContainer>{bayAmount.hold.previousBayAmount}</VesselBayContainer>
      </Grid>
      <Grid item={true} xs={6}>
        <VesselBayContainer>{bayAmount.hold.nextBayAmount}</VesselBayContainer>
      </Grid>
      <Grid item={true} xs={12}>
        <VesselBayContainer borderRadius={'0 0 30% 30%'}>
          {bayAmount.hold.bayAmount}
        </VesselBayContainer>
      </Grid>
    </Grid>
  )
}

const SingleBayOperationContainer = ({ bayAmount }: BayOperationContainerProps) => {
  return (
    <Grid container spacing={0.3}>
      <Grid container sx={{ justifyContent: 'center' }}>
        <Grid item xs={8}>
          <VesselBayContainer height='50px'>{bayAmount.deck.bayAmount}</VesselBayContainer>
        </Grid>
      </Grid>
      <Grid container sx={{ justifyContent: 'center' }}>
        <Grid item xs={8}>
          <VesselHatch />
        </Grid>
      </Grid>
      <Grid container sx={{ justifyContent: 'center' }}>
        <Grid item xs={8}>
          <VesselBayContainer height='50px' borderRadius={'0 0 30% 30%'}>
            {bayAmount.hold.bayAmount}
          </VesselBayContainer>
        </Grid>
      </Grid>
    </Grid>
  )
}

export const BayOperationContainer = ({ operationType, bayAmount, isSingleTwentyBay }: Props) => {
  return (
    <OperationContainer>
      <ArrowContainer>
        {operationType === OperationType.Inbound ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
        <Typography variant='subtitle2'>{bayAmount.totalMoves}</Typography>
      </ArrowContainer>
      {isSingleTwentyBay ? (
        <SingleBayOperationContainer bayAmount={bayAmount} />
      ) : (
        <RegularBayOperationContainer bayAmount={bayAmount} />
      )}
    </OperationContainer>
  )
}
