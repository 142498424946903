import { StaticFieldLabel } from '@storage/components/StaticFieldLabel'
import { BerthDetails } from '@storage/features/berths/models'
import { Delete, Edit } from '@mui/icons-material'
import { Card, CardActions, CardContent, CardHeader, Grid, IconButton } from '@mui/material'
import { useTranslate } from '@tolgee/react'

interface Props {
  item: BerthDetails
  canWrite: boolean
  onDelete: (id: string) => Promise<void> | void
  onEdit: (id: string) => Promise<void> | void
}

export const BerthsListItem = ({ item, canWrite, onDelete, onEdit }: Props) => {
  const { t } = useTranslate()
  return (
    <Card>
      <CardHeader title={item.name}></CardHeader>
      <CardContent>
        <Grid rowSpacing={1.5} container>
          <Grid item xs={6}>
            <StaticFieldLabel label={t('pier', 'Pier')} value={item.pierName} />
          </Grid>

          <Grid item xs={6}>
            <StaticFieldLabel label={t('maxDraft', 'Max draft')} value={item.maxDraft ?? ''} />
          </Grid>

          <Grid item xs={6}>
            <StaticFieldLabel
              label={t('quayMetermarkFrom', 'Quay metermark from')}
              value={item.quayMeterMarkFrom}
            />
          </Grid>

          <Grid item xs={6}>
            <StaticFieldLabel
              label={t('quayMetermarkFrom', 'Quay metermark from')}
              value={item.quayMeterMarkTo}
            />
          </Grid>
        </Grid>
      </CardContent>
      {canWrite && (
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <IconButton onClick={() => onEdit(item.id)}>
            <Edit />
          </IconButton>

          <IconButton color='error' onClick={() => onDelete(item.id)}>
            <Delete />
          </IconButton>
        </CardActions>
      )}
    </Card>
  )
}
