import { Prompt } from '@operations/components/prompt/Prompt'
import { Sticky } from '@operations/components/sticky/Stick'
import { Box, Card, CardContent, SxProps, styled } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { BayCard } from '../components/BayCard/BayCard'
import { HourCard } from '../components/HourCard/HourCard'
import { MetricList } from '../components/MetricList/MetricList'
import { WorkQueueCard } from '../components/WorkQueueCard/WorkQueueCard'
import { WorkQueue } from '../models/work-queue.model'
import { CraneSplitContainerUIStore } from '../stores/crane-split-container.ui-store'
import { CraneSplitDialogUIStore } from '../stores/crane-split-dialog.ui-store'
import { CraneSplitDrawerUIStore } from '../stores/crane-split-drawer.ui-store'
import './CraneSplitContainer.css'

const BackgroundContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '15rem',
  left: '8rem',
  bottom: '1rem',
  right: '1rem',
  borderRadius: '8px',
  zIndex: -1,
  padding: '0 1rem',
  backgroundColor: theme.palette.grey[100],
}))

const BackgroundStriped = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  zIndex: -1,
  background: `repeating-linear-gradient(
    180deg,
    ${theme.palette.grey[100]},
    ${theme.palette.grey[100]} 22px,
    ${theme.palette.grey[200]} 2px,
    ${theme.palette.grey[200]} 25px
  )`,
}))

const CraneSplit = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem',
  paddingBottom: '1rem',
}))

const CraneSplitContent = styled(CardContent)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
}))

const BayHeaderContainer = styled(Box)(() => ({
  height: '13rem',
  width: '100%',
  paddingTop: '.5rem',
  paddingLeft: '7rem',
}))

const BaysContent = styled(Box)(() => ({
  display: 'flex',
  gap: '1rem',
  marginTop: '.5rem',
}))

const BaysContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  overflowX: 'hidden',
  paddingX: '.5rem',
}))

const BayContainer = styled(Box)(() => ({
  marginTop: '.5rem',
  display: 'flex',
  flexDirection: 'column',
}))

const BayColumnsContainer = styled(Box)(() => ({
  display: 'flex',
  gap: '1rem',
  maxWidth: '100%',
}))

const WhiteSquare = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  left: '-7rem',
  width: '7rem',
  height: '100%',
  backgroundColor: 'white',
}))

const HoursContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

interface Props {
  uiContainerStore: CraneSplitContainerUIStore
  uiDrawerStore: CraneSplitDrawerUIStore
  uiDialogStore: CraneSplitDialogUIStore
}

export const CraneSplitContainer = observer(({ uiContainerStore, uiDialogStore }: Props) => {
  const theme = useMinimalsTheme()
  const [isDragging, setIsDragging] = useState(false)
  const [startDraggingTime, setStartDraggingTime] = useState<Date>()

  const [stickyStyling, setStickyStyling] = useState<SxProps | undefined>()

  const bayHeaderRef = useRef<Scrollbars>(null)
  const bayContentRef = useRef<Scrollbars>(null)

  useEffect(() => {
    const fixingStickyBayHeaderPosition = () => {
      const mainElement = document.querySelector(`main`)
      let top = '0px'
      let maxWidth = '0px'

      if (mainElement) {
        top = mainElement.style.paddingTop
        maxWidth = mainElement.style.marginLeft
      }

      setStickyStyling({
        maxWidth: `calc(100% - 10rem - ${maxWidth}) !important`,
        top: `calc(0px + ${top}) !important`,
      })
    }

    fixingStickyBayHeaderPosition()
  }, [])

  const handleHeaderScroll = () => {
    bayContentRef.current!.scrollLeft(bayHeaderRef.current!.getScrollLeft())
  }

  const handleContentScroll = () => {
    bayHeaderRef.current!.scrollLeft(bayContentRef.current!.getScrollLeft())
  }

  const handleDetailsDialog = (workQueue: WorkQueue) => {
    uiDialogStore.toggleDetailsDialog(workQueue)
  }

  const handleStartDrag = () => {
    setStartDraggingTime(new Date())
    setIsDragging(true)
  }

  const handleStopDragging = (workQueue: WorkQueue, currentPosition: number) => {
    setIsDragging(false)
    if (
      workQueue.currentPosition === currentPosition &&
      dayjs(new Date()).diff(startDraggingTime, 'milliseconds') <= 250
    ) {
      handleDetailsDialog(workQueue)
    } else {
      uiContainerStore.updateWorkQueueStartTime(workQueue, currentPosition)
    }
  }

  return (
    <>
      <Prompt when={uiContainerStore.canUndo} />
      {!!uiContainerStore.calculatedBays.length && (
        <CraneSplit id='crane-split' paddingX={theme.spacing(1)}>
          <MetricList metrics={uiContainerStore.metrics} />

          <Card>
            <CraneSplitContent
              sx={{
                cursor: isDragging ? 'grabbing' : 'default',
              }}
              id='crane-split-content'
            >
              <BayHeaderContainer>
                <Sticky overrideStickStyle={stickyStyling} display={!!uiContainerStore.vesselVisit}>
                  <WhiteSquare />
                  <Scrollbars
                    ref={bayHeaderRef}
                    style={{ width: '100%', height: 220, backgroundColor: 'white' }}
                    onScroll={handleHeaderScroll}
                  >
                    <Box sx={{ padding: '.5rem' }}>
                      <BayColumnsContainer>
                        {uiContainerStore.calculatedBays.map(bay => (
                          <BayCard key={bay.bay} bay={bay} />
                        ))}
                      </BayColumnsContainer>
                    </Box>
                  </Scrollbars>
                </Sticky>
              </BayHeaderContainer>
              <BaysContent>
                <HoursContainer id='crane-split-hours'>
                  {uiContainerStore.charTimes.map(timing => (
                    <HourCard key={timing.time.getTime()} timing={timing} />
                  ))}
                </HoursContainer>

                <BaysContainer>
                  <Scrollbars
                    ref={bayContentRef}
                    style={{ width: '100%' }}
                    onScroll={handleContentScroll}
                    renderTrackHorizontal={props => <div {...props} />}
                  >
                    <BayColumnsContainer id='crane-split-bays' paddingLeft='.5rem'>
                      {uiContainerStore.calculatedBays.map(bay => (
                        <BayContainer key={bay.bay}>
                          {bay.workQueues.map(wq => (
                            <WorkQueueCard
                              key={wq.id}
                              workQueue={wq}
                              isDragging={isDragging}
                              onStart={handleStartDrag}
                              onStop={handleStopDragging}
                              onOpenDetails={handleDetailsDialog}
                            />
                          ))}
                        </BayContainer>
                      ))}
                    </BayColumnsContainer>
                  </Scrollbars>
                </BaysContainer>
                <BackgroundContainer>
                  <BackgroundStriped />
                </BackgroundContainer>
              </BaysContent>
            </CraneSplitContent>
          </Card>
        </CraneSplit>
      )}
    </>
  )
})
