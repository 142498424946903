import {
  EquipmentPlannedDto,
  EquipmentType,
  EquipmentWorkAreaDto,
  WorkAreaEquipmentBindingTypes,
} from '@operations/app/api'
import { EquipmentStore } from '@operations/stores/EquipmentStore'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { EquipmentsPlanned } from '../components/equipment.model'

export class EquipmentsVesselVisitUIStore {
  plannedEquipments: EquipmentPlannedDto[] = []

  constructor(private equipmentStore: EquipmentStore) {
    makeObservable(this, {
      plannedEquipments: observable,

      getPlannedEquipments: action,

      dischargeCranes: computed,
      loadCranes: computed,
      dischargeEquipments: computed,
      loadEquipments: computed,
    })
  }

  public async getPlannedEquipments(carrierVisitId: number) {
    const data = await this.equipmentStore.loadPlanned(carrierVisitId)
    runInAction(() => {
      this.plannedEquipments = data
    })
  }

  public get dischargeCranes() {
    return this.cranes(WorkAreaEquipmentBindingTypes.Discharge)
  }

  public get loadCranes() {
    return this.cranes(WorkAreaEquipmentBindingTypes.Load)
  }

  public get dischargeEquipments() {
    return this.equipments.filter(x => x.assignmentType === WorkAreaEquipmentBindingTypes.Discharge)
  }

  public get loadEquipments() {
    return this.equipments.filter(x => x.assignmentType === WorkAreaEquipmentBindingTypes.Load)
  }

  public cranes(assingmentType: WorkAreaEquipmentBindingTypes) {
    return this.plannedEquipments
      .filter(
        x =>
          x.equipmentType === EquipmentType.Sts &&
          x.workAreaEquipments?.some(wa => wa.assignmentType.includes(assingmentType)),
      )
      .map(x => x.name)
  }

  private get equipments() {
    const result: EquipmentsPlanned[] = []

    this.plannedEquipments
      .filter(eq => eq.equipmentType !== EquipmentType.Sts)
      .forEach(equipment => {
        const assignmentTypes = this.getAssignmentTypeFromWorkAreas(equipment.workAreaEquipments)

        assignmentTypes.forEach(assignmentType => {
          const equipmentPlanned = result.find(
            x => x.type === equipment.equipmentType && x.assignmentType === assignmentType,
          )

          if (!equipmentPlanned) {
            result.push({
              type: equipment.equipmentType,
              assignmentType: assignmentType,
              equipmentCount: 1,
            })
          } else {
            equipmentPlanned.equipmentCount++
          }
        })
      })

    return result
  }

  private getAssignmentTypeFromWorkAreas(workAreas?: EquipmentWorkAreaDto[] | null) {
    const assignmentTypes: WorkAreaEquipmentBindingTypes[] = []

    if (workAreas) {
      for (const wa of workAreas) {
        if (
          wa.assignmentType.includes(WorkAreaEquipmentBindingTypes.Discharge) &&
          !assignmentTypes.some(x => x === WorkAreaEquipmentBindingTypes.Discharge)
        ) {
          assignmentTypes.push(WorkAreaEquipmentBindingTypes.Discharge)
        }

        if (
          wa.assignmentType.includes(WorkAreaEquipmentBindingTypes.Load) &&
          !assignmentTypes.some(x => x === WorkAreaEquipmentBindingTypes.Load)
        ) {
          assignmentTypes.push(WorkAreaEquipmentBindingTypes.Load)
        }

        if (assignmentTypes.length === 2) break
      }
    }

    return assignmentTypes
  }
}
