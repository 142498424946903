import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import gcsService from '@storage/app/gcs/gcs.service'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { DamageReportList } from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'

const GeneralCargoStockOccupancyDamageDialog = observer(() => {
  const { t } = useTranslate()
  const { generalCargoInventoryUIStore } = useStores()

  const handleDamageImagesDownload = async (paths: string[]) => {
    await gcsService.downloadFiles(paths)
  }

  return (
    <Dialog
      open={generalCargoInventoryUIStore.isDamageDialogOpen}
      onClose={() => generalCargoInventoryUIStore.closeDialog()}
      maxWidth='md'
      fullWidth
    >
      <DialogTitle>{t('damages', 'Damages')}</DialogTitle>
      <DialogContent sx={{ padding: '0!important' }}>
        <DamageReportList
          damageReports={generalCargoInventoryUIStore.occupancyDamages.map(x => ({
            created: new Date(x.createdAt),
            description: x.description,
            quantity: x.quantity,
            imagePaths: x.imagePaths,
          }))}
          sx={{ paddingY: '0!important' }}
          onDownloadFiles={handleDamageImagesDownload}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => generalCargoInventoryUIStore.closeDialog()} color='inherit'>
          {t('close', 'Close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
})

export default GeneralCargoStockOccupancyDamageDialog
