import { Button } from '@mui/material'
import { CustomAlert } from '@planning/pages/TallymanV2/Components/CustomAlert'
import { ICancellableTimeoutStore } from '@planning/stores/gateClerk/CancellableTimeoutStore'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

interface IProps {
  store: ICancellableTimeoutStore
  successText: string
  cancelledText: string
  errorText: string
  onCancel?: () => void
  cancellable: boolean
  testId?: string
}

export const CancellableTimeoutAlert: FC<IProps> = observer(
  ({ store, successText, cancelledText, errorText, onCancel, cancellable, testId }) => {
    const { t } = useTranslate()

    const { isCancelled, isError, request } = store

    if (!request) return <></>

    const handleCancel = () => {
      store.cancelRequest()
      onCancel?.()
    }

    const Action = () => {
      return (
        (!isError && !isCancelled && cancellable && (
          <Button onClick={handleCancel}>{t('cancel', 'Cancel')}</Button>
        )) || <></>
      )
    }

    const message = () => {
      if (isError) return errorText
      if (isCancelled) return cancelledText

      return successText
    }

    return (
      <CustomAlert
        testId={testId}
        severity={isError ? 'error' : 'success'}
        action={<Action />}
        message={message()}
        margin='0 0 1rem 0'
      />
    )
  },
)
