import { useOperationsStore } from '@operations/AppProvider'
import { Box } from '@mui/material'
import { AdvancedFilter } from '@tom-ui/ui'
import { useTranslate } from '@tolgee/react'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { EquipmentFiltersForm } from './EquipmentFiltersForm'
import { EquipmentFilter } from './equipment-filter.model'

export const EquipmentFilters = () => {
  const { t } = useTranslate()
  const { equipmentFiltersUIStore, equipmentStore } = useOperationsStore()
  const [open, setOpen] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const openFilter = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = (filter: EquipmentFilter) => {
    equipmentFiltersUIStore.setFilter(filter)
    equipmentFiltersUIStore.toggle()
  }

  const handleReset = () => {
    equipmentFiltersUIStore.setFilter()
    equipmentFiltersUIStore.toggle()
    handleClose()
  }

  const getEquipmentTypeOptions = () =>
    _(equipmentStore.items.map(x => x.equipmentType))
      .uniq()
      .sortBy(x => x)
      .map(x => ({
        label: t(x),
        value: x,
      }))
      .value()

  return (
    <Box position={isMobile ? undefined : 'relative'}>
      <AdvancedFilter
        open={open}
        formId='equipment-filters'
        width={isMobile ? '98vw' : 'calc(95vw - 240px)'}
        filterTooltip={t('advancedFilter', 'Advanced filter')}
        onOpenFilter={openFilter}
        onClose={handleClose}
        filterActionLabel={t('showResults', 'Show results')}
        resetActionLabel={t('reset', 'Reset')}
        onFilter={handleClose}
        onReset={handleReset}
      >
        <EquipmentFiltersForm
          id='equipment-filters'
          filter={equipmentFiltersUIStore.filter}
          yardBlocks={equipmentFiltersUIStore.yardBlocks}
          equipmentTypeOptions={getEquipmentTypeOptions()}
          onSubmit={handleSubmit}
        />
      </AdvancedFilter>
    </Box>
  )
}
