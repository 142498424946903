import { CommodityAttributeDto } from '@billing/app/api'
import { useTranslate } from '@tolgee/react'
import { Tile } from '@tom-ui/ui'

interface Props {
  attributes: CommodityAttributeDto[]
}
export const CommodityAttributes = ({ attributes }: Props) => {
  const { t } = useTranslate()

  const formatAttribute = (attribute: CommodityAttributeDto) => {
    if (!attribute.value) return ' - '

    if (['width', 'length', 'height'].includes(attribute.name)) return `${attribute.value} mm`

    if (attribute.name === 'weight') return `${attribute.value} kg`

    return attribute.value
  }

  return (
    <>
      {attributes.map(attribute => (
        <Tile key={attribute.name} label={t(attribute.name)} value={formatAttribute(attribute)} />
      ))}
    </>
  )
}
