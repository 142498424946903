import { CarrierVisitDirection } from '@storage/app/api'
import { Input, Output } from '@mui/icons-material'
import { SxProps } from '@mui/material'

const handlingDirectionIcons = {
  Inbound: Input,
  Outbound: Output,
}

export const get = (direction: CarrierVisitDirection, sxProps?: SxProps) => {
  const Icon = handlingDirectionIcons[direction]

  return <Icon fontSize='small' sx={sxProps} />
}
