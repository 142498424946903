import ReportStep from '@billing/app/models/report-step.enum'
import { useBillingServiceStore } from '@billing/AppProvider'
import { observer } from 'mobx-react-lite'
import { ReportNavigationButtonContainer } from './ReportNavigationButtonContainer'
import { CTReportFilter } from '../components/CTReport/CTReportFilter'
import exportService from '@billing/services/ExportService'
import { mapCTUIStorePropertiesToOptionsDto } from '../mappers/ct-report-mapper'

export const CTReportContainer = observer(() => {
  const { reportsUIStore, containerTurnoversUIStore } = useBillingServiceStore()

  const finish = () => {
    containerTurnoversUIStore.resetData()
    reportsUIStore.resetStepper()
  }

  return (
    <>
      {reportsUIStore.currentStep === ReportStep.Filter && <CTReportFilter />}
      <ReportNavigationButtonContainer
        downloadAction={() =>
          exportService.exportContainerTurnovers(
            mapCTUIStorePropertiesToOptionsDto(containerTurnoversUIStore),
          )
        }
        finishAction={finish}
      />
    </>
  )
})
