import { useOperationsStore } from '@operations/AppProvider'
import { FinishJobDto } from '@operations/features/equipmentOperator/models/finish-job.model'
import { formatContainerNumber } from '@operations/utils/helper'
import { useTranslate } from '@tolgee/react'
import { Header } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { CloseStepper } from './CloseStepper'
import { ConfirmStepper } from './ConfirmStepper'

interface Props {
  onConfirm: (currentJob: FinishJobDto) => Promise<void>
  onClose?: () => void
}
export const JobStepperHeader = observer(({ onConfirm, onClose }: Props) => {
  const { t } = useTranslate()

  const { equipmentOperatorStepperUIStore, equipmentOperatorUIStore } = useOperationsStore()

  const handleConfirm = async (currentJob: FinishJobDto) => {
    await onConfirm(currentJob)
    equipmentOperatorUIStore.closeSearchIfFinishingLastNotification(currentJob.workInstructionId)
  }

  return (
    <Header
      leftRenderOption={
        <CloseStepper uiStore={equipmentOperatorStepperUIStore} onClose={onClose} />
      }
      title={
        equipmentOperatorStepperUIStore.containerNumber
          ? formatContainerNumber(equipmentOperatorStepperUIStore.containerNumber)
          : t('noContainerNumber', 'No container no.')
      }
      titleColor='secondary'
      rightRenderOption={
        <ConfirmStepper uiStore={equipmentOperatorStepperUIStore} onConfirm={handleConfirm} />
      }
    />
  )
})
