import {
  ContainerTurnoversApi,
  ContainerYardOperationDto,
  DeviceOperationMode,
  ErrorCodes,
  StackDto,
  YardBlockSlotOccupanciesApi,
  YardBlockStacksApi,
  YardPositionDto,
} from '@storage/app/api'
import { createApiClient } from '@storage/app/http-client'
import { isApplicationDomainException } from '@storage/app/http-client/interceptors/domain-exception.response-interceptor'
import { tolgee } from '@storage/app/translation'
import { action, makeObservable, observable } from 'mobx'

export enum YardPositionInputMode {
  StackIn,
  StackOut,
  CorrectPosition,
}

export class YardOperationControlStore {
  containerNumber = ''
  mode = YardPositionInputMode.StackIn
  orderId?: number = undefined

  turnoverData?: ContainerYardOperationDto
  location?: YardPositionDto

  handleChange?: (value: any) => void
  handleError?: (msg: string) => void

  constructor() {
    makeObservable(this, {
      setCallback: action,
      setContext: action,
      setTurnoverData: action,
      setLocation: action,
      turnoverData: observable,
    })
  }

  async loadStacks(): Promise<StackDto[]> {
    const { data: stacks } = await createApiClient(YardBlockStacksApi).getYardBlockStacks({})

    return stacks
  }

  async loadTurnoverData(
    mode: DeviceOperationMode = DeviceOperationMode.Bay,
  ): Promise<ContainerYardOperationDto> {
    const { data } = await createApiClient(ContainerTurnoversApi).getYardOperationData({
      containerNumber: this.containerNumber,
      orderId: this.orderId,
      mode: mode,
      isStackIn: this.mode !== YardPositionInputMode.StackOut,
    })

    return data
  }

  async validateDGStackingAndSegregationRulesViolation() {
    if (this.turnoverData?.turnover && !!this.location) {
      await createApiClient(YardBlockSlotOccupanciesApi)
        .validateDGStackingAndSegregationRulesViolation({
          yardBlockSlotOccupanciesUpdateContainerPositionRequest: {
            containerTurnoverId: this.turnoverData.turnover.id,
            location: { ...this.location },
          },
        })
        .catch(error => {
          // Check for dg stacking rules violations
          if (
            isApplicationDomainException(error, ErrorCodes.DgStackingRulesViolationStackingOnTop)
          ) {
            this.handleError &&
              this.handleError(
                tolgee.t(
                  'stackingValidationStackingOnTopOperator',
                  `We can't use his location. There is a stacking rule that prohibits stacking on top`,
                ),
              )
          } else if (
            isApplicationDomainException(error, ErrorCodes.DgStackingRulesViolationMaxAllowedTier)
          ) {
            this.handleError &&
              this.handleError(
                tolgee.t(
                  'stackingValidationMaxStackTierOperator',
                  `We can't use this location. There is a maximum tier stacking rule in place for this position`,
                ),
              )
          } else if (isApplicationDomainException(error, ErrorCodes.DgSegregationRulesViolation)) {
            this.handleError &&
              this.handleError(
                tolgee.t(
                  'segregationRulesViolationOperator',
                  `We can't use this location, you action violates the current segreation rules.`,
                ),
              )
          } else {
            // Generic error
            this.handleError &&
              this.handleError(tolgee.t('rulesValidationError', 'rulesValidationError'))
          }

          throw error
        })
    }
  }

  async onConfirm() {
    if (this.turnoverData?.turnover && !!this.location) {
      await createApiClient(YardBlockSlotOccupanciesApi)
        .updateContainerPosition({
          yardBlockSlotOccupanciesUpdateContainerPositionRequest: {
            containerTurnoverId: this.turnoverData.turnover.id,
            location: { ...this.location },
          },
        })
        .catch(
          () =>
            this.handleError &&
            this.handleError(tolgee.t('databaseIOError', 'database read/write error')),
        )
    }
  }

  setCallback(handleChange?: (value: any) => void, handleError?: (msg: string) => void) {
    this.handleChange = handleChange
    this.handleError = handleError
  }

  setContext(containerNumber: string, mode: YardPositionInputMode, orderId?: number) {
    this.mode = mode
    this.containerNumber = containerNumber
    this.orderId = orderId
  }

  setTurnoverData(turnoverData?: ContainerYardOperationDto) {
    this.turnoverData = turnoverData
    this.location = this.turnoverData?.plannedPosition ?? undefined
    if (this.handleChange) {
      this.handleChange(this.location)
    }
  }

  setLocation(position?: YardPositionDto) {
    this.location = position
  }
}
