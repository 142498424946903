import { DialogFormContainer } from '@storage/components/DialogFormContainer'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import {
  ManualPlanningDialogStore,
  ManualPlanningTypes,
} from '../stores/manual-planning-dialog.store'
import { ManualPlanningForm } from './manual-reservation-form/ManualPlanningForm'
import { ManualPlanningFormProfile } from './manual-reservation-form/manual-reservation-form.profile'

interface ManualPlanningDialogProps {
  manualPlanningDialogStore: ManualPlanningDialogStore
}

export const ManualPlanningDialog = observer(
  ({ manualPlanningDialogStore }: ManualPlanningDialogProps) => {
    const { t } = useTranslate()
    const dialogTitles: Record<ManualPlanningTypes, string> = {
      internalMove: t('createInternalMove', 'Planning an internal move'),
      planning: t('planManually', 'Plan manually'),
    }

    useEffect(() => {
      manualPlanningDialogStore.parser.setIsSingleContainer(
        manualPlanningDialogStore.isSingleContainer,
      )
    }, [manualPlanningDialogStore.isSingleContainer, manualPlanningDialogStore.parser])

    const handleSubmit = async (formValues: ManualPlanningFormProfile) =>
      manualPlanningDialogStore.manualPlanningRequest(formValues)

    return (
      <DialogFormContainer
        open={manualPlanningDialogStore.isOpen}
        dialogTitle={dialogTitles[manualPlanningDialogStore.type]}
        onClose={() =>
          !manualPlanningDialogStore.isPlanningRequestLoading && manualPlanningDialogStore.toggle()
        }
        confirmButtonLabel={
          manualPlanningDialogStore.doesPlanningAlertsExist([
            ManualPlanningDialogStore.INSUFFICIENT_PLANNING_SPACE_ALERT_KEY,
            ManualPlanningDialogStore.DANGEROUS_GOODS_STACKING_VIOLATION_ALERT_KEY,
            ManualPlanningDialogStore.DANGEROUS_GOODS_SEGREGATION_VIOLATION_ALERT_KEY,
          ])
            ? t('applyAnyway', 'Apply Anyway')
            : t('apply', 'Apply')
        }
        isLoading={manualPlanningDialogStore.isPlanningRequestLoading}
        disabled={!manualPlanningDialogStore.parser.isAccepted}
        form={
          <ManualPlanningForm
            title={t('attributesToBePlanned', 'Attributes to be planned')}
            id='manual-planning-form'
            onSubmit={handleSubmit}
            manualPlanningDialogStore={manualPlanningDialogStore}
          />
        }
      />
    )
  },
)
