import { GeneralIcon } from '@tom-ui/ui'
import { TenantConfigStore } from '@storage/stores/tenantConfig.store'
import { YardBlockSummaryStore } from '@storage/stores/yard-block-summary.store'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { ComponentStatus } from '@storage/helpers/enums'
import { ContainerInformationCard } from './ContainerInformationCard'
import { LabeledNumberContainer } from './LabeledNumberContainer'
import { LabeledNumberContainerWithInfo } from './LabeledNumberContainerWithInfo'
import { Popup } from './Popup'
import { Subtitle } from './Subtitle'
import { ValueBoxWithText } from './ValueBoxWithText'
import { Widget } from './Widget'

interface YardCapacityWidgetProps {
  yardCapacityWidgetStore: YardBlockSummaryStore
  tenantConfigStore: TenantConfigStore
}
export const getPopupPosition = (e: any) => {
  const buttonRect = e.currentTarget.getBoundingClientRect()
  const x = buttonRect.left - 100
  const y = buttonRect.top
  return { x, y }
}

export const YardCapacityWidget = observer(
  ({ yardCapacityWidgetStore, tenantConfigStore }: YardCapacityWidgetProps) => {
    const [positionOfDetails, setPositionOfDetails] = useState({})
    const { t } = useTranslate()

    useEffect(() => {
      const loadData = async () => {
        await yardCapacityWidgetStore.loadList()
      }
      loadData()
    }, [yardCapacityWidgetStore])

    const handleClickDetails = (e: any, id: string) => {
      yardCapacityWidgetStore.openContainerDetailsPopup()
      yardCapacityWidgetStore.getYardBlockSummary(id)
      const popupPosition = getPopupPosition(e)
      setPositionOfDetails(popupPosition)
    }

    return (
      <>
        <Widget
          expandable={!tenantConfigStore.isBayRowUniqueIdentificationModeActivated}
          title={t('yardCapacity', 'Yard Capacity')}
          icon={<GeneralIcon />}
          store={yardCapacityWidgetStore}
        >
          <LabeledNumberContainer>
            <ValueBoxWithText
              key={1}
              text={t('total', 'Total')}
              value={yardCapacityWidgetStore.yardSummary.total}
              state={ComponentStatus.None}
            />
            <ValueBoxWithText
              key={2}
              text={t('occupied', 'Occupied')}
              value={yardCapacityWidgetStore.yardSummary.occupied}
              state={yardCapacityWidgetStore.yardSummary.yardState}
            />
            {!tenantConfigStore.skipYardPlanning && (
              <ValueBoxWithText
                key={3}
                text={t('reserved', 'Reserved')}
                value={yardCapacityWidgetStore.yardSummary.reserved}
                state={ComponentStatus.None}
              />
            )}

            <ValueBoxWithText
              key={4}
              text={t('available', 'Available')}
              value={
                tenantConfigStore.skipYardPlanning
                  ? yardCapacityWidgetStore.yardSummary.free +
                    yardCapacityWidgetStore.yardSummary.reserved
                  : yardCapacityWidgetStore.yardSummary.free
              }
              state={ComponentStatus.None}
            />
          </LabeledNumberContainer>
          {!tenantConfigStore.isBayRowUniqueIdentificationModeActivated && (
            <>
              <Subtitle
                subtitle={t('blockCapacity', 'Capacity of each block')}
                bodyText={t('blocksOnTerminal', 'All blocks on the terminal')}
              />

              {yardCapacityWidgetStore.blocks.map(block => (
                <LabeledNumberContainerWithInfo
                  text={block.name}
                  progressBarValue={block.progressBarValue}
                  key={block.id}
                  state={block.state}
                  buttonLabel={t('details', 'Details')}
                  buttonOnClickAction={e => handleClickDetails(e, block.id)}
                >
                  <ValueBoxWithText
                    text={t('total', 'Total')}
                    value={block.slots}
                    state={ComponentStatus.None}
                  />
                  <ValueBoxWithText
                    text={t('occupied', 'Occupied')}
                    value={block.occupiedByContainer}
                    state={block.state}
                  />
                  {!tenantConfigStore.skipYardPlanning && (
                    <ValueBoxWithText
                      text={t('reserved', 'Reserved')}
                      value={block.occupiedByReservation}
                      state={ComponentStatus.None}
                    />
                  )}
                  <ValueBoxWithText
                    text={t('available', 'Available')}
                    value={
                      tenantConfigStore.skipYardPlanning
                        ? block.free + block.occupiedByReservation
                        : block.free
                    }
                    state={ComponentStatus.None}
                  />
                </LabeledNumberContainerWithInfo>
              ))}
            </>
          )}
        </Widget>
        {yardCapacityWidgetStore.isBlockDetailsButtonClicked && (
          <Popup
            onClose={yardCapacityWidgetStore.closeContainerDetailsPopup}
            position={positionOfDetails}
            title={t('details', 'Details')}
          >
            {yardCapacityWidgetStore.blockSummary && (
              <ContainerInformationCard
                groupedBySize={yardCapacityWidgetStore.blockSummary.groupedBySize}
                groupedByOperator={yardCapacityWidgetStore.blockSummary.groupedByOperator}
                fullCount={yardCapacityWidgetStore.blockSummary.fullCount}
                emptyCount={yardCapacityWidgetStore.blockSummary.emptyCount}
              />
            )}
          </Popup>
        )}
      </>
    )
  },
)
