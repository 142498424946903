import React from 'react'
import { TextField, TextFieldProps } from '@mui/material'

interface SimplifiedControlledTextFieldProps extends Omit<TextFieldProps, 'onChange'> {
  value: string
  onChange: (value: string) => void
  uppercase?: boolean
  fontColor?: string
  helperText?: string
}

export const SimplifiedControlledTextField: React.FC<SimplifiedControlledTextFieldProps> = ({
  value,
  onChange,
  label,
  uppercase,
  fontColor,
  helperText,
  ...rest
}) => {
  return (
    <TextField
      sx={{ mt: 1, maxWidth: '200px' }}
      value={value}
      onChange={e => onChange(e.target.value)}
      label={label}
      helperText={helperText}
      InputProps={{
        style: {
          textTransform: uppercase ? 'uppercase' : 'none',
          color: fontColor || 'inherit',
        },
      }}
      {...rest}
    />
  )
}
