import { createSvgIcon } from '@mui/material'

export const UnknowTypeIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4 2C2.89543 2 2 2.89543 2 4V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V4C22 2.89543 21.1046 2 20 2H4ZM12.8235 16.501V14.551H10.8235V16.501H12.8235ZM10.036 7.86979C9.49014 8.22396 9.08389 8.73021 8.81722 9.38854L10.3672 9.76354C10.4589 9.52604 10.5881 9.31979 10.7547 9.14479C10.9256 8.96979 11.1172 8.83437 11.3297 8.73854C11.5422 8.63854 11.761 8.58854 11.986 8.58854C12.3235 8.58854 12.6026 8.67604 12.8235 8.85104C13.0485 9.02604 13.161 9.26146 13.161 9.55729C13.161 9.81563 13.0901 10.0531 12.9485 10.2698C12.811 10.4823 12.6401 10.6948 12.436 10.9073C12.2318 11.1198 12.0256 11.3531 11.8172 11.6073C11.6131 11.8573 11.4401 12.151 11.2985 12.4885C11.161 12.8219 11.0922 13.2177 11.0922 13.676H12.561C12.561 13.2969 12.6422 12.9719 12.8047 12.701C12.9672 12.426 13.1672 12.1781 13.4047 11.9573C13.6464 11.7323 13.886 11.5073 14.1235 11.2823C14.361 11.0531 14.561 10.7948 14.7235 10.5073C14.8901 10.2198 14.9735 9.87188 14.9735 9.46354C14.9735 9.07188 14.8485 8.71563 14.5985 8.39479C14.3526 8.07396 14.0068 7.81771 13.561 7.62604C13.1151 7.43437 12.5943 7.33854 11.9985 7.33854C11.2401 7.33854 10.586 7.51562 10.036 7.86979Z'
      fill='currentColor'
    />
  </svg>,
  'UnknowTypeIcon',
)
