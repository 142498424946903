import { CarrierVisitDirection } from '@storage/app/api'
import { PATH_STORAGE } from '@storage/routes'
import { generatePath, useNavigate } from 'react-router'

export const useHandlePlan = () => {
  const navigate = useNavigate()

  return (
    carrierVisitId: number,
    handlingDirection: CarrierVisitDirection,
    discriminator: string,
  ) => {
    if (!carrierVisitId || !handlingDirection) return

    navigate(
      generatePath(PATH_STORAGE.yardPlanningDashboard.details.root, {
        [PATH_STORAGE.yardPlanningDashboard.details.params.carrierVisitId]:
          carrierVisitId.toString(),
        [PATH_STORAGE.yardPlanningDashboard.details.params.handlingDirection]: handlingDirection,
        [PATH_STORAGE.yardPlanningDashboard.details.params.discriminator]: discriminator,
      }),
    )
  }
}

export const useCarrierVisitDetails = () => {
  const navigate = useNavigate()

  return (carrierVisitId: number, discriminator: string) => {
    if (!carrierVisitId) return

    if (discriminator === 'VesselVisit') {
      navigate(
        generatePath(PATH_STORAGE.vesselVisitDetails.root, {
          [PATH_STORAGE.vesselVisitDetails.params.vesselVisitId]: carrierVisitId.toString(),
        }),
      )
    } else {
      navigate(
        generatePath(PATH_STORAGE.railVisitDetails.root, {
          [PATH_STORAGE.railVisitDetails.params.railVisitId]: carrierVisitId.toString(),
        }),
      )
    }
  }
}
