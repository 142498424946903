export enum ContainerTurnoversReportMode {
  Standard,
  IMDG,
  DAMAGED,
}

export const containerTurnoversReportModes: ContainerTurnoversReportMode[] = [
  ContainerTurnoversReportMode.Standard,
  ContainerTurnoversReportMode.IMDG,
  ContainerTurnoversReportMode.DAMAGED,
]

export default containerTurnoversReportModes
