import { Button } from '@mui/material'
import { Stack } from '@mui/system'
import { HandlingDirection } from '@storage/app/api'
import { SelectOption } from '@storage/app/models'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { OccupancyItem, StockOccupancy } from '../interfaces/occupancy-item'
import MultipleChipSelect from './MultipleChipSelect'

interface GeneralCargoIdentifiableItemsProps {
  plannedItems: string[]
  occupancyItemId: string
  handlingDirection: HandlingDirection
}

const GeneralCargoIdentifiableItems = observer(
  ({ plannedItems, occupancyItemId, handlingDirection }: GeneralCargoIdentifiableItemsProps) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()
    const { generalCargoStorageTrackerUIStore } = useStores()

    const occupancyItems = generalCargoStorageTrackerUIStore.occupancyItems as StockOccupancy[]

    const [selectedItems, setSelectedItems] = useState<string[]>([])
    const [disabledItems, setDisabledItems] = useState<string[]>([])

    const updateOccupancyItem = useCallback(
      (occupancyItemId: string, newProperties: { unitId: string }[]) => {
        const newQuantity = newProperties.length
        generalCargoStorageTrackerUIStore.updateOccupancyItem(
          occupancyItemId,
          'newProperties' as keyof OccupancyItem,
          newProperties,
        )
        generalCargoStorageTrackerUIStore.updateOccupancyItem(
          occupancyItemId,
          'newQuantity' as keyof OccupancyItem,
          newQuantity,
        )
      },
      [generalCargoStorageTrackerUIStore],
    )

    useEffect(() => {
      if (handlingDirection === HandlingDirection.Inbound) {
        const disabledUnitIds = getDisabledUnitIds(occupancyItemId, occupancyItems)
        setDisabledItems(disabledUnitIds)
      }

      const currentOccupancyItem = occupancyItems.find(oi => oi.id === occupancyItemId)
      if (currentOccupancyItem) {
        setSelectedItems(currentOccupancyItem.newProperties.map(p => p.unitId))
      }
    }, [occupancyItemId, occupancyItems, handlingDirection])

    const getDisabledUnitIds = (occupancyItemId: string, occupancyItems: StockOccupancy[]) => {
      return occupancyItems
        .filter(oi => oi.id !== occupancyItemId)
        .flatMap(oi => oi.newProperties.map(p => p.unitId))
    }

    const handleSelectedItemsChange = useCallback(
      (newSelectedItems: string[]) => {
        setSelectedItems(newSelectedItems)
        updateOccupancyItem(
          occupancyItemId,
          newSelectedItems.map(item => ({ unitId: item })),
        )
      },
      [occupancyItemId, updateOccupancyItem],
    )

    const itemsOptions: SelectOption[] = useMemo(() => {
      if (handlingDirection === HandlingDirection.Inbound) {
        return plannedItems?.map(plannedItem => ({
          label: plannedItem,
          value: plannedItem,
          disabled: disabledItems.includes(plannedItem) && !selectedItems.includes(plannedItem),
        }))
      } else {
        const currentOccupancyItem = occupancyItems.find(oi => oi.id === occupancyItemId)
        return (
          currentOccupancyItem?.properties.map(prop => ({
            label: prop.unitId,
            value: prop.unitId,
          })) || []
        )
      }
    }, [
      plannedItems,
      disabledItems,
      selectedItems,
      handlingDirection,
      occupancyItemId,
      occupancyItems,
    ])

    useEffect(() => {
      if (handlingDirection !== HandlingDirection.Inbound) {
        const currentOccupancyItem = occupancyItems.find(oi => oi.id === occupancyItemId)
        if (currentOccupancyItem?.properties?.some(x => plannedItems.includes(x.unitId))) {
          handleSelectedItemsChange(plannedItems)
        }
      }
    }, [
      plannedItems,
      handlingDirection,
      occupancyItemId,
      occupancyItems,
      handleSelectedItemsChange,
    ])

    const handleSelectAll = () => {
      const allSelectableValues = itemsOptions.map(option => option.value)
      setSelectedItems(allSelectableValues)

      updateOccupancyItem(
        occupancyItemId,
        allSelectableValues.map(item => ({ unitId: item })),
      )
    }

    const handleDeselectAll = () => {
      setSelectedItems([])
      updateOccupancyItem(occupancyItemId, [])
    }

    return (
      <>
        <Stack direction='row' gap={theme.customSpacing.xl}>
          <MultipleChipSelect
            items={itemsOptions?.map(option => option.label)}
            selectedItems={selectedItems}
            disabledItems={disabledItems}
            onChange={handleSelectedItemsChange}
          />
        </Stack>

        <Stack direction='row'>
          <Button variant='text' color='primary' onClick={handleSelectAll}>
            {t('selectAll', 'Select All')}
          </Button>
          <Button variant='text' color='secondary' onClick={handleDeselectAll}>
            {t('deselectAll', 'Deselect All')}
          </Button>
        </Stack>
      </>
    )
  },
)

export default GeneralCargoIdentifiableItems
