import { Box, Button, Tooltip } from '@mui/material'
import { BinIcon, EditIcon, IconButton } from '@tom-ui/ui'
import { usePlanningStore } from '@planning/AppProvider'
import {
  CarrierType,
  CarrierVisitDirection,
  CreateContainerOrderDto,
  CreateOrdersCommand,
  UpdateOrderCommand,
} from '@planning/app/api'
import { CreateOrderForm } from '@planning/components/CreateOrderForm'
import { DeliveryOrderFilter } from '@planning/components/DeliveryOrderFilter'
import { GridToolbar } from '@planning/components/GridToolbar'
import { OrderStatusChip } from '@planning/components/OrderStatusChip'
import { GridColumn, StickyHeaderTable } from '@planning/components/PaginatedTable'
import { emptyIndicator } from '@planning/constants'
import { IOrderItem } from '@planning/rt-stores/order/OrderItem'
import orderService from '@planning/services/orderService'
import { IPaginatedStoreWithItems } from '@planning/stores/PaginatedStore'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { EditOrderForm } from '../EditOrderForm'

interface Props {
  viewStore: IPaginatedStoreWithItems<IOrderItem>
}

export const DeliveryOrderTable = observer(({ viewStore }: Props) => {
  const { appViewStore, drawerStore, orderItemStore } = usePlanningStore()
  const { t } = useTranslate()
  const handleDelete = async (id: number) => {
    try {
      const confirmed = await appViewStore.setOpenConfirmDialog(
        true,
        t('doYouWantToDeleteOrder', 'Do you want to delete order?'),
      )
      if (confirmed) {
        await orderService.delete(id)
        appViewStore.setShowAlert(
          'success',
          t('orderDeletedSuccessfully', 'Order deleted successfully'),
        )
      }
    } catch (error) {
      appViewStore.setShowAlert('error', t('failedToDelete', 'Failed to delete'))
    }
  }

  const onEditDeliveryOrder = async (data: any) => {
    const updatedOrder = data as UpdateOrderCommand
    updatedOrder.carrierType = CarrierType.Truck
    if (!updatedOrder.grossWeight) {
      updatedOrder.grossWeight = 0
    }

    try {
      await orderService.update(updatedOrder)
      appViewStore.setShowAlert(
        'success',
        t('orderUpdatedSuccessfully', 'Order updated successfully'),
      )
      drawerStore.close()
    } catch (error) {
      appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
    }
  }

  const columns: GridColumn<IOrderItem>[] = [
    {
      field: 'referenceNumber',
      headerName: t('orderNo', 'Order no'),
      flex: 1,
      minWidth: 150,
      valueGetter: params => params.row.data.referenceNumber ?? emptyIndicator,
      sortable: true,
      orderBy: 'referenceNumber',
    },
    {
      field: 'containerNumber',
      headerName: t('containerNo', 'Container no'),
      flex: 1,
      minWidth: 150,
      valueGetter: params => params.row.data.containerNumber ?? emptyIndicator,
      sortable: true,
      orderBy: 'containerNumber',
    },
    {
      field: 'containerIsoCode',
      headerName: t('iso', 'ISO'),
      flex: 0.5,
      minWidth: 150,
      valueGetter: params => params.row.data.containerIsoCode ?? emptyIndicator,
      sortable: true,
      orderBy: 'containerIsoCode',
    },
    {
      field: 'weight',
      headerName: t('grossWeightKg', 'Gross Weight (kg)'),
      flex: 0.5,
      minWidth: 75,
      valueGetter: params => params.row.data.grossWeight ?? emptyIndicator,
      sortable: true,
      orderBy: 'grossWeight',
    },
    {
      field: 'status',
      headerName: t('status', 'Status'),
      flex: 0.5,
      minWidth: 75,
      renderCell: params => {
        return <OrderStatusChip status={params.row.data.status} />
      },
      sortable: true,
      orderBy: 'status',
    },
    {
      field: 'actions',
      headerName: '',
      flex: 0.5,
      minWidth: 75,
      sortable: false,
      renderCell: params => {
        return (
          <Box sx={{ display: 'flex' }}>
            <Tooltip key='edit-order' arrow placement='bottom' title={t('edit', 'Edit')}>
              <IconButton
                onClick={async () => {
                  // TODO: make sure that the given order is actually loaded in the orderItemStore
                  await orderItemStore.fetchById(Number(params.id))
                  const order = orderItemStore.elements[Number(params.id)]?.data
                  drawerStore.showView(
                    <EditOrderForm order={order} onSubmit={onEditDeliveryOrder} />,
                    {
                      title: t('editOrder', 'Edit order'),
                      subTitle: t('dropOffOrders', 'Drop-off orders'),
                      primaryActionLabel: t('submit', 'Submit'),
                      closeActionLabel: t('close', 'Close'),
                      formId: 'edit-order-vessel-visit',
                    },
                  )
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip key='delete-order' arrow placement='bottom' title={t('delete', 'Delete')}>
              <IconButton color='error' onClick={() => handleDelete(Number(params.id))}>
                <BinIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )
      },
    },
  ]

  const onCreateDeliveryOrder = async (order: CreateContainerOrderDto) => {
    if (!order) return

    order.isDeliveryByTruck = true
    const cmd: CreateOrdersCommand = {
      orders: [order],
      carrierVisitType: CarrierType.Truck,
    }
    try {
      await orderService.create(cmd)
      appViewStore.setShowAlert(
        'success',
        t('orderCreatedSuccessfully', 'Order created successfully'),
      )
      drawerStore.close()
    } catch (error) {
      appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
    }
  }

  const handleCreateDeliveryOrder = () => {
    drawerStore.showView(
      <CreateOrderForm
        direction={CarrierVisitDirection.Inbound}
        onSubmit={onCreateDeliveryOrder}
      />,
      {
        title: t('createOrder', 'Create order'),
        subTitle: t('dropOffOrders', 'Drop-off orders'),
        primaryActionLabel: t('create', 'Create'),
        closeActionLabel: t('cancel', 'Cancel'),
        formId: 'create-order-vessel-visit',
      },
    )
  }

  useEffect(() => {
    viewStore.loadCurrentPage()
  }, [viewStore])

  return (
    <StickyHeaderTable
      store={viewStore}
      columns={columns}
      toolBar={
        <GridToolbar
          title={t('drop-offOrders', 'Drop-off orders')}
          store={viewStore}
          actions={
            <Button variant='contained' onClick={handleCreateDeliveryOrder}>
              {t('create', 'Create')}
            </Button>
          }
          onShowFilter={() => drawerStore.showView(<DeliveryOrderFilter store={viewStore} />)}
        />
      }
      rowsPerPageOptions={[10, 25, 50]}
    />
  )
})
