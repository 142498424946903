import { TenantDto, TenantsApi } from '@billing/app/api'
import { createApiClient } from '@billing/app/http-client'
import { BaseEntityStore } from './base/BaseEntityStore'

export class TenantStore extends BaseEntityStore<TenantDto> {
  async get() {
    const { data } = await createApiClient(TenantsApi).get()
    this.updateStoreItem(data, data.id)
  }
}
