import ReportType from '@billing/app/models/report-type.enum'
import { action, makeObservable, observable } from 'mobx'
import ReportStep, { ReportSteps } from '../../../app/models/report-step.enum'

export class ReportsUIStore {
  currentStep: ReportStep = ReportStep.SelectADataType
  currentType?: ReportType
  nextButtonWasBlocked = false

  constructor() {
    makeObservable(this, {
      currentStep: observable,
      currentType: observable,
      nextButtonWasBlocked: observable,

      setNextStep: action,
      setPreviousStep: action,
      setType: action,
      setNextButtonWasBlocked: action,
    })
  }

  setCurrentStep(step: ReportStep): void {
    this.currentStep = step
    this.setNextButtonWasBlocked(false)
  }

  setNextStep(): void {
    this.currentStep = ReportSteps[ReportSteps.indexOf(this.currentStep) + 1] as ReportStep
    this.setNextButtonWasBlocked(false)
  }

  setPreviousStep(): void {
    this.currentStep = ReportSteps[ReportSteps.indexOf(this.currentStep) - 1] as ReportStep
    this.setNextButtonWasBlocked(false)
  }

  setType(reportType?: ReportType): void {
    this.currentType = reportType
  }

  setNextButtonWasBlocked(nextButtonBlocked: boolean): void {
    this.nextButtonWasBlocked = nextButtonBlocked
  }

  public resetStepper(): void {
    this.setCurrentStep(ReportStep.SelectADataType)
    this.setType()
  }
}
