import { CancellableTimeoutAlert } from '@planning/pages/GateClerk/Components/CancellableTimeoutAlert'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { checkedSuffixText } from '../../InspectRailcar'
import { RailTallyViewStore } from '../../Stores/RailTallyViewStore'

interface IProps {
  store: RailTallyViewStore
  onCancel: () => void
}

export const InspectRailcarCheckSuccessAlert: FC<IProps> = observer(({ store, onCancel }) => {
  const { t } = useTranslate()

  const checkText = () =>
    `${store.selectedRailTrackPosition?.data.railcarName ?? store.railcarToBeCreated?.railcarNumber} ${t('checked', 'Checked')} ${checkedSuffixText(store.selectedOperationType, t)}`

  const cancelledText = () =>
    `${t('check', 'Check')} ${checkedSuffixText(store.selectedOperationType, t)} ${t('cancelled', 'cancelled')}: ${store.selectedRailTrackPosition?.data.railcarName ?? store.railcarToBeCreated?.railcarNumber}`

  const errorText = () =>
    `${t('check', 'Check')} ${checkedSuffixText(store.selectedOperationType, t)} ${t('failed', 'failed')}: ${store.selectedRailTrackPosition?.data.railcarName ?? store.railcarToBeCreated?.railcarNumber}`

  return (
    <CancellableTimeoutAlert
      testId='check-success-alert'
      store={store}
      successText={checkText()}
      cancelledText={cancelledText()}
      errorText={store.requestErrorMessage ?? errorText()}
      cancellable={store.cancellable}
      onCancel={onCancel}
    />
  )
})
