import { createSvgIcon } from '@mui/material'

export const LengthIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19.4571 5.89195C19.6609 5.6882 19.9912 5.6882 20.195 5.89195L21.7602 7.45717C21.858 7.55501 21.913 7.68772 21.913 7.82609C21.913 7.96447 21.858 8.09717 21.7602 8.19502L20.195 9.76023C19.9912 9.96399 19.6609 9.96399 19.4571 9.76023C19.2534 9.55648 19.2534 9.22614 19.4571 9.02238L20.1317 8.34783H3.86824L4.5428 9.02238C4.74655 9.22614 4.74655 9.55648 4.5428 9.76023C4.33904 9.96399 4.0087 9.96399 3.80495 9.76023L2.23973 8.19502C2.14188 8.09717 2.08691 7.96447 2.08691 7.82609C2.08691 7.68772 2.14188 7.55501 2.23973 7.45717L3.80495 5.89195C4.0087 5.6882 4.33904 5.6882 4.5428 5.89195C4.74655 6.0957 4.74655 6.42605 4.5428 6.6298L3.86824 7.30435H20.1317L19.4571 6.6298C19.2534 6.42605 19.2534 6.0957 19.4571 5.89195Z'
      fill='currentColor'
    />
    <path
      d='M3.13039 11.4783C2.5541 11.4783 2.08691 11.9454 2.08691 12.5217V16.6957C2.08691 17.272 2.55409 17.7391 3.13039 17.7391H20.8695C21.4458 17.7391 21.913 17.272 21.913 16.6957V12.5217C21.913 11.9454 21.4458 11.4783 20.8695 11.4783H20.0869V13.5652C20.0869 13.9974 19.7365 14.3478 19.3043 14.3478C18.8721 14.3478 18.5217 13.9974 18.5217 13.5652V11.4783H16.4347V12.5217C16.4347 12.954 16.0844 13.3044 15.6521 13.3044C15.2199 13.3044 14.8695 12.954 14.8695 12.5217V11.4783H12.7826V13.5652C12.7826 13.9974 12.4322 14.3478 12 14.3478C11.5677 14.3478 11.2173 13.9974 11.2173 13.5652V11.4783H9.13039V12.5217C9.13039 12.954 8.78001 13.3044 8.34778 13.3044C7.91556 13.3044 7.56518 12.954 7.56518 12.5217V11.4783H5.47822V13.5652C5.47822 13.9974 5.12783 14.3478 4.69561 14.3478C4.26339 14.3478 3.913 13.9974 3.913 13.5652L3.913 11.4783H3.13039Z'
      fill='currentColor'
    />
  </svg>,
  'LengthIcon',
)
