import {
  BerthDto,
  SlotDto,
  YardBlockBayDto,
  YardBlockDto,
  YardBlockInformationDto,
} from '@storage/app/api'
import { FormType, Point } from '@storage/app/models'
import { action, computed, makeObservable, observable } from 'mobx'
import { YardBayViewStore } from './YardBayViewStore'
import { YardDataStore } from './YardDataStore'
import { YardStageStore } from './YardStageStore'
import { PlanningStore } from './planning.store'

export interface StackIndication {
  blockId: string
  bayId: string
  rowId: string
  bay: number
  row: number
  value: number
  text?: string
  color?: string
  slotSize: number
  highlight: boolean
}

//TODO: To refactor with YardSetupStore
export class YardViewStore {
  yardDataStore: YardDataStore
  yardBayViewStore: YardBayViewStore
  yardStageStore: YardStageStore
  planningStore: PlanningStore

  selectedYardBlock?: YardBlockDto
  selectedFlexibleZone?: YardBlockDto
  selectedYardBlockBay?: YardBlockBayDto
  selectedBerth?: BerthDto
  selectedVesselVisitId?: number

  yardBlockSummary?: YardBlockInformationDto

  isBayViewDialogOpen = false

  drawerAction?: FormType
  isDrawerOpen = false
  initGeometry: Point = { x: 0, y: 0 }

  groundSlotsFromSelectedRules: SlotDto[] = []
  showMoveContainerDialog = false
  showSelectAllocationNotification = false

  constructor(yardDataStore: YardDataStore, yardBayViewStore: YardBayViewStore) {
    this.yardStageStore = new YardStageStore()
    this.planningStore = new PlanningStore()

    makeObservable(this, {
      visibleBlocks: computed,
      visibleFlexibleZones: computed,
      selectedYardBlock: observable,
      selectedFlexibleZone: observable,
      yardBlockSummary: observable,
      setSelectedYardBlock: action,
      setSelectedFlexibleZone: action,
      setSelectedYardBlockBay: action,
      setIsBayViewDialogOpen: action,
      isBayViewDialogOpen: observable,
      drawerAction: observable,
      isDrawerOpen: observable,
      groundSlotsFromSelectedRules: observable,
      showMoveContainerDialog: observable,
      toggleMoveContainerDialog: action,
      setGroundSlotsFromSelectedRules: action,
      showSelectAllocationNotification: observable,
      setShowSelectAllocationNotification: action,
      isAllocationMode: computed,
      selectedVesselVisitId: observable,
      setSelectedVesselVisitId: action,
      toggleYardBlockSummary: action,
      setYardBlockSummary: action,
    })

    this.yardDataStore = yardDataStore
    this.yardBayViewStore = yardBayViewStore
  }

  get isPlanning(): boolean {
    return !!this.planningStore.filter || !!this.planningStore.containerTurnoverId
  }

  toggleBerthDialog() {
    //
  }

  toggleFlexibleZoneDialog() {
    //
  }

  get visibleBlocks() {
    return this.yardDataStore.yardBlocks.filter(e => !!e.geometry && !e.isFlexibleZone)
  }

  get visibleFlexibleZones() {
    return this.yardDataStore.yardBlocks.filter(e => !!e.geometry && e.isFlexibleZone)
  }

  get isAllocationMode(): boolean {
    return false
  }

  deselectYardObjects() {
    this.selectedBerth = undefined
    this.selectedYardBlock = undefined
    this.selectedFlexibleZone = undefined
  }

  setSelectedVesselVisitId(vesselVisitId?: number) {
    this.selectedVesselVisitId = vesselVisitId
  }

  async setSelectedYardBlock(yardBlock?: YardBlockDto) {
    this.deselectYardObjects()
    this.selectedYardBlock = yardBlock
  }

  setSelectedBerth(berth?: BerthDto) {
    this.deselectYardObjects()
    this.selectedBerth = berth
  }

  setSelectedFlexibleZone(flexibleZone?: YardBlockDto) {
    this.deselectYardObjects()
    this.selectedFlexibleZone = flexibleZone
  }

  setYardBlockSummary(summary?: YardBlockInformationDto) {
    this.yardBlockSummary = summary
  }

  setShowSelectAllocationNotification(value: boolean) {
    this.showSelectAllocationNotification = value
  }

  setDrawerAction(action?: FormType, block?: YardBlockDto) {
    this.selectedYardBlock = block
    this.drawerAction = action
    this.isDrawerOpen = !!action
  }

  toggleMoveContainerDialog() {
    this.showMoveContainerDialog = !this.showMoveContainerDialog
  }

  setGroundSlotsFromSelectedRules(slots: string[]) {
    this.groundSlotsFromSelectedRules = this.yardDataStore.yardBlockSlotStore.entries.filter(
      s => slots.includes(s.id) && s.tier === 1,
    )
  }

  isStackSelectedForAllocation(stack?: StackIndication) {
    if (stack) {
      return (
        this.groundSlotsFromSelectedRules.find(
          s => s.yardBlockBayId === stack.bayId && s.yardBlockRowId === stack.rowId,
        ) != undefined
      )
    }

    return false
  }

  setInitGeometry(p: Point) {
    this.initGeometry = p
  }

  setSelectedYardBlockBay(bayId?: string) {
    if (!bayId) {
      this.selectedYardBlock = undefined
      this.selectedYardBlockBay = undefined
      this.isBayViewDialogOpen = false
      return
    }
    this.selectedYardBlockBay = this.yardDataStore.yardBlockBays.find(b => b.id === bayId)
    this.selectedYardBlock = this.yardDataStore.yardBlocks.find(
      b => b.id === this.selectedYardBlockBay?.yardBlockId,
    )

    this.setIsBayViewDialogOpen(true)
  }

  setIsBayViewDialogOpen(open: boolean) {
    this.isBayViewDialogOpen = open
  }

  async toggleYardBlockSummary(yardBlockId?: string) {
    if (yardBlockId === this.yardBlockSummary?.id) return

    this.setYardBlockSummary(
      yardBlockId ? this.yardDataStore.yardBlockSummaryStore.data.get(yardBlockId) : undefined,
    )
  }
}
