import { Paper } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { OrderResponseDto } from '@planning/app/api'
import { observer } from 'mobx-react-lite'
import { FC, ReactElement, useEffect } from 'react'
import { OrderInfoBox } from './OrderInfoBox'

interface Props {
  order: OrderResponseDto
  onClick?: (order: OrderResponseDto) => void
  fullWidth?: boolean
  actions?: ReactElement[] | ReactElement
}

export const OrderCard: FC<Props> = observer(({ order, onClick, fullWidth, actions }: Props) => {
  const { containerItemStore } = usePlanningStore()

  // todo: [north] interim sln to fetch container here until we remove GateClerkTruckVisitEditStore and change OrderResponseDto to IOrderItem connecting to IContainerItem
  useEffect(() => {
    if (order.containerId) {
      containerItemStore.fetchById(order.containerId)
    }
  }, [containerItemStore, order.containerId])

  const container = containerItemStore.elements[order.containerId ?? 0]

  return (
    <Paper
      variant='elevation'
      elevation={1}
      sx={{
        mb: '0.5rem',
        p: '1rem',
        minHeight: '8.25rem',
        width: fullWidth ? '100%' : undefined,
      }}
    >
      <OrderInfoBox order={order} container={container} actions={actions} onClick={onClick} />
    </Paper>
  )
})
