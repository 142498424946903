import { Box, Typography } from '@mui/material'
import { CarrierVisitSummaryFilterDto } from '@storage/app/api'
import { useTranslate } from '@tolgee/react'
import UnAllocatedContainersGroupItem from './UnallocatedContainersGroupItem'

interface Props {
  carrierVisitSummaryFilters: CarrierVisitSummaryFilterDto[]
  variant: 'standard' | 'special'
  onClick?: (filter: CarrierVisitSummaryFilterDto, variant: 'standard' | 'special') => void
  onCreateNewRuleClick: (filter: CarrierVisitSummaryFilterDto) => void
}

const UnAllocatedContainersGroup = ({
  carrierVisitSummaryFilters,
  variant,
  onCreateNewRuleClick,
  onClick,
}: Props) => {
  const { t } = useTranslate()
  return (
    <Box>
      <Typography variant='h6' my={1} mx={4}>
        {variant === 'standard'
          ? t('standardContainers', 'Standard')
          : t('specialContainers', 'Special')}
      </Typography>
      {carrierVisitSummaryFilters.map((filter, i) => {
        return (
          <UnAllocatedContainersGroupItem
            key={`${variant} - ${i}`}
            carrierVisitSummaryFilter={filter}
            variant={variant}
            onCreateNewRuleClick={onCreateNewRuleClick}
            onClick={onClick}
          />
        )
      })}
    </Box>
  )
}

export default UnAllocatedContainersGroup
