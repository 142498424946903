import { Box, Card, CardContent, Stack, Typography } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { CarrierVisitDirection, TruckAppointmentOrderDto } from '@planning/app/api'
import { CarrierVisitDirectionChip } from '@planning/components/CarrierVisitDirectionChip'
import { HoldsChip } from '@planning/components/hold/DisplayHoldsButton'
interface Props {
  order: Pick<TruckAppointmentOrderDto, 'orderId' | 'containerNumber' | 'holds'>
  direction: CarrierVisitDirection
  onClick: () => void
}

export const ContainerListCard = ({ order, onClick, direction }: Props) => {
  const theme = useMinimalsTheme()

  return (
    <Card variant='outlined' sx={{ width: '100%', mt: 1 }} onClick={onClick}>
      <CardContent sx={{ p: 0, '&:last-child': { paddingBottom: 0 } }}>
        <Stack direction='row' spacing={1}>
          <Stack sx={{ width: '80%' }}>
            <Box
              sx={{
                m: 1,
                p: 1,
                borderRadius: 1,
                border: 1,
                borderColor: '#eeeeee',
                bgcolor: theme.palette.grey[200],
              }}
            >
              <Typography variant='h4'>
                {order ? order.containerNumber?.toUpperCase() : '-'}
              </Typography>
            </Box>
          </Stack>
          <Stack>
            <Box sx={{ m: 1, p: 1 }}>
              <Stack direction='row' alignItems='center' spacing={1}>
                <HoldsChip holds={order.holds} />
                <CarrierVisitDirectionChip
                  direction={direction === CarrierVisitDirection.Inbound ? 'IN' : 'OUT'}
                  size='small'
                />
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}
