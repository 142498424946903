import { Chip, FormHelperText, Stack } from '@mui/material'
import { JobDto } from '@operations/app/api'
import { useTranslate } from '@tolgee/react'
import { FieldBox } from '@tom-ui/ui'
import { StepperContentWrapper } from './StepperContentWrapper'

interface Props {
  job?: JobDto
  pluggedIn?: boolean
  stepsTotal: number
  hasError?: boolean
  onPlugReeferJob: (isPluggedIn: boolean) => void
}
export const PlugReefer = ({ job, pluggedIn, stepsTotal, hasError, onPlugReeferJob }: Props) => {
  const { t } = useTranslate()

  return (
    <StepperContentWrapper
      title={t('confirmInformation', 'Confirm information')}
      steps={`${stepsTotal} ${t('of', 'of')} ${stepsTotal}`}
    >
      <Stack gap={theme => theme.customSpacing.m}>
        <Stack>
          <FieldBox
            label={t('containerPluggedIn', 'Container plugged in?')}
            required
            hasError={hasError}
            rightRender={
              <Stack flexDirection='row' gap={theme => theme.customSpacing.xs}>
                <Chip
                  label={t('yes', 'Yes')}
                  color={pluggedIn ? 'primary' : 'default'}
                  onClick={() => onPlugReeferJob(true)}
                />
                <Chip
                  label={t('no', 'No')}
                  color={pluggedIn || pluggedIn === undefined ? 'default' : 'primary'}
                  onClick={() => onPlugReeferJob(false)}
                />
              </Stack>
            }
          />
          {hasError && (
            <FormHelperText error sx={{ marginLeft: '14px' }}>
              {t('selectOneOption', 'Select one option')}
            </FormHelperText>
          )}
        </Stack>
      </Stack>
    </StepperContentWrapper>
  )
}
