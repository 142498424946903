import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { RailTallyViewStore } from '../Stores/RailTallyViewStore'

interface Props {
  store: RailTallyViewStore
}

export const RailcarSearchBox: FC<Props> = observer(({ store }) => {
  const { t } = useTranslate()

  if (!store.selectedOperationType) return <></>

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant='h1'>{`${store.confirmedRailcar}/${store.selectedRailcarsOnSelectedTrack.length}`}</Typography>
      <Typography variant='h6'>{t('railcarsInspected', 'Railcars Inspected')}</Typography>
    </Box>
  )
})
