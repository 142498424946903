import { Container } from '@mui/system'
import { usePlanningStore } from '@planning/AppProvider'
import {
  ContainerJourneyResponseDto,
  CreateStrippingOrderCommand,
  UpdateStrippingOrderCommand,
} from '@planning/app/api'
import { serviceOrdersService } from '@planning/services'
import { useTranslate } from '@tolgee/react'
import { ConfirmationDialog } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { ServiceOrdersViewStore } from '../../Stores/ServiceOrdersViewStore'
import { CreateServiceOrderDialogRender } from './../CreateServiceOrderDialogRender'
import {
  IStrippingOrderCommodityFormData,
  StrippingOrderCommoditySelectionDialog,
} from './../StrippingOrderCommoditySelectionDialog/StrippingOrderCommoditySelectionDialog'

export const StrippingOrderDialog: FC<{
  store: ServiceOrdersViewStore
  onClose: () => void
  renderReservations?: (orderId: number, plannedAmout: number, cargoItemId: number) => JSX.Element
}> = observer(({ store, onClose, renderReservations }) => {
  const { t } = useTranslate()
  const { appViewStore } = usePlanningStore()

  const removeSelectedCommodity = () => {
    if (store.itemToBeDeleted) {
      store.removeSelectedStrippingCommodity(store.itemToBeDeleted)
    }

    store.setItemToBeDeleted()
  }

  const handleOnSubmit = async () => {
    if (!store.selectedContainerJourney || !store.selectedStrippingCommodities) return
    const journey = store.selectedContainerJourney

    try {
      if (journey.strippingOrderId) {
        await submitUpdateStrippingOrder(journey, journey.strippingOrderId)
      } else {
        await submitCreateStrippingOrder(journey)
      }

      appViewStore.setShowAlert(
        'success',
        t('strippingOrderSavedSuccessfully', 'Stripping order saved successfully'),
      )

      onClose()
    } catch (error) {
      appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
    }
  }

  const submitCreateStrippingOrder = async (journey: ContainerJourneyResponseDto) => {
    const cmd: CreateStrippingOrderCommand = {
      containerJourneyId: journey.id,
      packingList: store.selectedStrippingCommodities.map(c => ({
        ...c,
        handlingInstructions: store.handlingInstructions,
      })),
    }

    await serviceOrdersService.createStrippingOrder(cmd)
  }

  const submitUpdateStrippingOrder = async (
    journey: ContainerJourneyResponseDto,
    strippingOrderId: number,
  ) => {
    const cmd: UpdateStrippingOrderCommand = {
      id: strippingOrderId,
      containerJourneyId: journey.id,
      packingList: store.selectedStrippingCommodities.map(c => ({
        ...c,
        handlingInstructions: store.handlingInstructions,
      })),
    }

    await serviceOrdersService.updateStrippingOrder(cmd)
  }

  const handleOnDelete = async () => {
    if (!store.selectedContainerJourney) return
    const journey = store.selectedContainerJourney

    if (journey.strippingOrderId) {
      try {
        await serviceOrdersService.deleteStrippingOrder(journey.strippingOrderId)
        appViewStore.setShowAlert(
          'success',
          t('strippingOrderDeletedSuccessfully', 'Stripping Order deleted successfully!'),
        )
        onClose()
      } catch (e) {
        appViewStore.setShowAlert(
          'error',
          t('failedToDeleteStrippingOrder', 'Failed to delete Stripping Order'),
        )
      }
    }
  }

  return (
    <Container>
      <StrippingOrderCommoditySelectionDialog
        open={store.showStrippingSelectionDialog}
        packageOptions={store.packagesOptions}
        onSubmit={(data: IStrippingOrderCommodityFormData) => {
          if (!data.commodity || !data.customer) {
            appViewStore.setShowAlert(
              'error',
              t('noCommodityOrCustomerSelected', 'No commodity or customer selected!'),
            )
            store.closeActiveDialog()
            return
          }
          store.upsertSelectedCommodity({
            ...data,
            commodityId: data.commodity.id,
            commodityName: data.commodity.name,
            customerId: data.customer.id,
            customerName: data.customer.name,
            totalQuantity: data.plannedCargoAmount ?? 0,
          })
          store.closeActiveDialog()
        }}
        onClose={() => store.closeActiveDialog()}
        onCommodityChange={commodity => store.setCommodityPackages(commodity?.packageIds ?? [])}
      />

      <ConfirmationDialog
        open={store.showConfirmationDialog}
        title={t('confirmation', 'Confirmation')}
        message={t(
          'doYouWantToRemoveThisItemFromTheList',
          'Do you want to remove this item from the list?',
        )}
        primaryActionText={t('yes', 'Yes')}
        closeLabel={t('no', 'No')}
        onConfirm={() => {
          removeSelectedCommodity()
          store.closeActiveDialog()
        }}
        onClose={() => store.closeActiveDialog()}
      />

      <ConfirmationDialog
        open={store.showServiceOrderDeleteConfirmationDialog}
        title={t('confirmation', 'Confirmation')}
        message={t(
          'doYouWantToRemoveThisStrippingOrder',
          'Do you want to remove this stripping order?',
        )}
        primaryActionText={t('yes', 'Yes')}
        closeLabel={t('no', 'No')}
        onConfirm={() => {
          handleOnDelete()
          store.closeActiveDialog()
        }}
        onClose={() => store.closeActiveDialog()}
      />

      <CreateServiceOrderDialogRender
        isStuffing={false}
        onDelete={() => store.setActiveDialog('serviceOrderDeleteConfirmation')}
        onClose={onClose}
        onSubmit={handleOnSubmit}
        store={store}
        renderReservations={renderReservations}
      />
    </Container>
  )
})
