import { CreateRailTrackDto, RailTrackDto } from '@storage/app/api'
import { EntityStore } from '@storage/app/store/entity.store'
import { RailTracksService } from '@storage/services/rail-tracks.service'

export class RailTracksStore extends EntityStore<RailTrackDto> {
  constructor(private readonly _railTracksService: RailTracksService) {
    super()
  }

  public async loadAll(): Promise<void> {
    const railTracks = await this._railTracksService.getAll()
    this.setAll(railTracks)
  }

  public getById(id: string): RailTrackDto | undefined {
    return this.data.get(id)
  }

  public async add(createRailTrackDto: CreateRailTrackDto): Promise<void> {
    const railTrack = await this._railTracksService.create(createRailTrackDto)
    this.addOrUpdate(railTrack)
  }

  public async update(railTrackDto: RailTrackDto): Promise<void> {
    const railTrack = await this._railTracksService.update(railTrackDto)
    this.addOrUpdate(railTrack)
  }

  public async delete(id: string): Promise<void> {
    await this._railTracksService.delete(id)
    this.remove(id)
  }
}
