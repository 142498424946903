import { Box, Paper, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form'
import { YesNoChipBox } from '../dangerous-goods/YesNoChipBox'

type IProps<T extends FieldValues> = UseControllerProps<T>

export const ControlledDamageRecordBox = <T extends FieldValues>({ control, name }: IProps<T>) => {
  const { t } = useTranslate()

  return (
    <Paper variant='outlined' sx={{ p: '1rem' }}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <Box display='flex' alignItems={'center'} justifyContent='space-between'>
            <Typography variant='body1' fontWeight='bold'>
              {t('damageRecording', 'Damage recording')}?
            </Typography>
            <YesNoChipBox
              activeOption={value ? 'Yes' : 'No'}
              onClickYes={() => onChange(true)}
              onClickNo={() => {
                onChange(false)
              }}
            />
          </Box>
        )}
      />
    </Paper>
  )
}
