import { RailcarTrackPositionResponseDto } from '@planning/app/api'
import _ from 'lodash'
import { computed, makeObservable } from 'mobx'
import { EntityItem } from '../base/EntityItem'
import { IEntity } from '../types'
import { RailcarTrackPositionItemStore } from './RailcarTrackPositionItemStore'
import { RailTrackItem } from './RailTrackItemStore'

export interface IRailcarTrackPositionItem extends IEntity<RailcarTrackPositionResponseDto> {
  railTrack?: RailTrackItem
}

export interface PinInfo {
  length: number
  amount: number
}

export class RailcarTrackPositionItem extends EntityItem<RailcarTrackPositionResponseDto, number> {
  constructor(
    private itemStore: RailcarTrackPositionItemStore,
    id: number,
    data: RailcarTrackPositionResponseDto,
  ) {
    super(id, data)
    makeObservable(this, {
      railTrack: computed,
    })
  }

  get railTrack() {
    if (_(this.itemStore.railTracksById).has(this.data.railTrackId))
      return _(this.itemStore.railTracksById).get(this.data.railTrackId)

    return undefined
  }
}
