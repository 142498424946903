import { useMemo } from 'react'
// @mui
import { CssBaseline } from '@mui/material'
import {
  ThemeProvider as MUIThemeProvider,
  StyledEngineProvider,
  ThemeOptions,
  createTheme,
} from '@mui/material/styles'
// components
//
import { SnackbarProvider } from 'notistack'
import customRadius from './customRadius'
import customShadows from './customShadows'
import customSpacing from './customSpacing'
import GlobalStyles from './globalStyles'
import componentsOverride from './overrides'
import { StyledMaterialDesignContent, getStandardAlertIcons } from './overrides/Alert'
import palette from './palette'
import shadows from './shadows'
import typography from './typography'

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode
}

export default function ThemeProvider({ children }: Props) {
  const themeMode = 'light'
  const themeDirection = 'ltr'

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: palette('light'),
      typography,
      shape: { borderRadius: 8 },
      direction: themeDirection,
      shadows: shadows(themeMode),
      customShadows: customShadows(themeMode),
      customSpacing: customSpacing(),
      customRadius: customRadius(),
    }),
    [],
  )

  const theme = createTheme(themeOptions)

  theme.components = componentsOverride(theme)

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        <SnackbarProvider
          Components={{
            default: StyledMaterialDesignContent,
            success: StyledMaterialDesignContent,
            info: StyledMaterialDesignContent,
            warning: StyledMaterialDesignContent,
            error: StyledMaterialDesignContent,
          }}
          iconVariant={{
            ...getStandardAlertIcons(),
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          autoHideDuration={5000}
          variant='success'
          maxSnack={3}
        >
          {children}
        </SnackbarProvider>
      </MUIThemeProvider>
    </StyledEngineProvider>
  )
}
