import QuickFilterPanel from '@storage/components/QuickFilterPanel'
import { TenantConfigStore } from '@storage/stores/tenantConfig.store'
import { useFormContext } from 'react-hook-form'
import {
  ContainerTurnoversFilterFormProfile,
  ContainerTurnoversFilterFormProfileKey,
  defaultValues,
} from '../container-turnovers-filter-form.profile'
import ContainerTurnoversQuickFilterChips from './ContainerTurnoversQuickFilterChips'

interface ContainerTurnoversQuickFilterProps {
  disabledFilters?: ContainerTurnoversFilterFormProfileKey[]
  onClearAll: () => void
  showClearAll: boolean
  showAllocationStatus?: boolean
  tenantConfigStore?: TenantConfigStore
}

export const ContainerTurnoversQuickFilter = ({
  disabledFilters,
  showClearAll,
  onClearAll,
  tenantConfigStore,
}: ContainerTurnoversQuickFilterProps) => {
  const { control } = useFormContext<ContainerTurnoversFilterFormProfile>()

  return (
    <QuickFilterPanel onClearAll={onClearAll} showClearAll={showClearAll}>
      <ContainerTurnoversQuickFilterChips
        control={control}
        defaultValues={defaultValues}
        disabledFilters={disabledFilters}
        tenantConfigStore={tenantConfigStore}
      />
    </QuickFilterPanel>
  )
}
