import { CarrierVisitDto } from '@billing/app/api'
import { useBillingServiceStore } from '@billing/AppProvider'
import { Autocomplete, Box, FormControl, Stack, TextField, Typography } from '@mui/material'
import { CustomInputAdornment, VesselIcon } from '@tom-ui/ui'
import { useTranslate } from '@tolgee/react'
import dayjs from 'dayjs'
import { matchSorter } from 'match-sorter'
import { observer } from 'mobx-react-lite'

export const VesselReportFilter = observer(() => {
  const { t } = useTranslate()

  const { vesselReportUIStore, reportsUIStore } = useBillingServiceStore()

  const selectedValue = vesselReportUIStore.vesselVisitsOptions.find(
    x => x.id === vesselReportUIStore.selectedVesselVisitId,
  )

  const showError =
    reportsUIStore.nextButtonWasBlocked && !vesselReportUIStore.selectedVesselVisitId

  return (
    <FormControl sx={{ width: '350px' }}>
      <Autocomplete
        options={vesselReportUIStore.vesselVisitsOptions}
        value={selectedValue ?? null}
        onChange={(event: any, newValue: CarrierVisitDto | null) => {
          vesselReportUIStore.setSelectedVesselVisitId(newValue?.id)
        }}
        getOptionLabel={option => option.carrierName ?? ''}
        fullWidth
        renderInput={params => (
          <TextField
            {...params}
            label={t('vesselName', 'Vessel name')}
            required
            error={showError}
            helperText={
              showError
                ? `${t('vesselName', 'Vessel name')} ${t('isMandatory', 'is mandatory')}`
                : undefined
            }
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <CustomInputAdornment position='start'>
                  <VesselIcon viewBox='0 0 20 14' sx={{ width: 18 }} />
                </CustomInputAdornment>
              ),
            }}
          />
        )}
        renderOption={(props, option) => (
          <Box component='li' {...props} key={option.id}>
            <Stack>
              <Typography variant='subtitle1'>{option.carrierName}</Typography>

              <Stack direction='row' gap={theme => theme.customSpacing.xxs}>
                <Typography variant='caption' color='secondary'>
                  {t('ata', 'ATA')}:
                </Typography>
                <Typography variant='caption' color='secondary'>
                  {option.ata ? dayjs(option.ata).format('L LT') : '-'}
                </Typography>
              </Stack>

              <Typography variant='caption' color='secondary'>
                {vesselReportUIStore.getVoyageDisplay(option)}
              </Typography>
            </Stack>
          </Box>
        )}
        filterOptions={(options, { inputValue }) => {
          return matchSorter(options, inputValue, {
            keys: ['carrierName', 'importVoyage', 'exportVoyage'],
            threshold: matchSorter.rankings.CONTAINS,
          })
        }}
      />
    </FormControl>
  )
})
