import { TextField, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { Controller, useFormContext } from 'react-hook-form'

export const RailcarEstimateField = observer(() => {
  const { t } = useTranslate()

  const { control } = useFormContext()

  return (
    <>
      <Typography variant='subtitle1' marginTop='1rem' marginBottom='0.5rem'>
        {t('railcars', 'Railcars')}
      </Typography>
      <Stack spacing={1}>
        <Controller
          control={control}
          name='railcarEstimate'
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              type='number'
              label={t('railcarsEstimation', 'Railcars estimation')}
              variant='outlined'
              data-cy='railcar-estimate-field'
              value={field.value ?? ''}
              onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
              inputProps={{ min: 1 }}
            />
          )}
        />
      </Stack>
    </>
  )
})
