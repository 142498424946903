import { GeneralCargoAreaType } from '@storage/app/api'
import { SelectOption } from '@storage/app/models'
import { useTranslate } from '@tolgee/react'
import { useMemo } from 'react'

export const useGeneralCargoAreaTypeOptions = (): SelectOption[] => {
  const { t } = useTranslate()

  return useMemo(
    () => [
      { label: t('warehouse', 'Warehouse'), value: GeneralCargoAreaType.Warehouse },
      { label: t('openArea', 'Open area'), value: GeneralCargoAreaType.OpenArea },
    ],
    [t],
  )
}
