import {
  OrderResponseDto,
  RailVisitResponseDto,
  TruckVisitDto,
  VesselVisitDto,
} from '@planning/app/api'
import { SimpleListStore } from '@planning/components/list/SimpleListStore'
import { IEvent, IMessageBus } from '@planning/messages'
import { EventTypes } from '@planning/messages/eventsTypes'
import { ICreateGeneralCargoOrderFormData } from '@planning/pages/Order/components/CreateGeneralCargoOrderPageForm'
import { railVisitService, truckVisitService, vesselVisitService } from '@planning/services'
import generalCargoService from '@planning/services/generalCargoService'
import packageService from '@planning/services/packageService'
import _ from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import { GetOrderDamagesAmountFunc, OrderDamageDto } from './OrderDamageDto'
import { PackageDto } from './PackageDto'

export class GeneralCargoViewStore {
  orders: OrderResponseDto[] = []
  packages: PackageDto[] = []
  commodityPackages: number[] = []
  selectedOrder?: OrderResponseDto
  carrierVisits?: VesselVisitDto[] | TruckVisitDto[] | RailVisitResponseDto[]
  orderFormData?: ICreateGeneralCargoOrderFormData

  getOrderDamagesAmountByOrderIds?: GetOrderDamagesAmountFunc
  damageOrders: OrderDamageDto[] = []

  generalCargoSearchStore = new SimpleListStore(async (filter?: string) => {
    if (filter) {
      const result = await generalCargoService.getByCustomerOrReferenceNumber(filter)
      this.carrierVisits = await this.filterAndFetchVisits(result)

      if (this.getOrderDamagesAmountByOrderIds) {
        this.damageOrders = await this.getOrderDamagesAmountByOrderIds(result.map(x => x.id))
      }

      return result
    }

    return []
  })

  constructor(messageBus: IMessageBus) {
    makeObservable(this, {
      selectedOrder: observable,
      orders: observable,
      packages: observable,
      commodityPackages: observable,
      damageOrders: observable,

      setDamageOrders: action,
      getPackages: action,
      setCommodityPackages: action,
      receivePackageDeletedMessage: action,
      receivePackageUpsertedMessage: action,

      packagesOptions: computed,
    })

    messageBus.subscribeEvent(EventTypes.PackageDeleted, this.receivePackageDeletedMessage)
    messageBus.subscribeEvent(EventTypes.PackageUpserted, this.receivePackageUpsertedMessage)
  }

  selectOrder = (order?: OrderResponseDto) => {
    this.selectedOrder = order
    this.setCommodityPackages([])
  }

  setOrderFormData = (data: ICreateGeneralCargoOrderFormData) => (this.orderFormData = data)

  getPackages = async () => {
    this.packages = await packageService.getAll()
  }

  setCommodityPackages = (commodityPackages: number[]) => {
    this.commodityPackages = [...commodityPackages]
  }

  async filterAndFetchVisits(orders: OrderResponseDto[]) {
    const groupedIds: { [key: string]: number[] } = {
      vessel: [],
      truck: [],
      train: [],
    }

    orders.forEach((obj: OrderResponseDto) => {
      switch (obj.carrierVisitType) {
        case 'Vessel':
          if (obj.carrierVisitId !== null) groupedIds.vessel.push(obj.carrierVisitId!)
          break
        case 'Truck':
          if (obj.carrierVisitId !== null) groupedIds.truck.push(obj.carrierVisitId!)
          break
        case 'Train':
          if (obj.carrierVisitId !== null) groupedIds.train.push(obj.carrierVisitId!)
          break
        default:
          break
      }
    })

    const carrierVisitData = []

    if (groupedIds.vessel.length) {
      const data = (await vesselVisitService.getByIds(groupedIds.vessel)).data
      carrierVisitData.push(...data)
    }

    if (groupedIds.train.length) {
      const data = await railVisitService.getByIds(groupedIds.train)
      carrierVisitData.push(...data)
    }

    if (groupedIds.truck.length) {
      const data = (await truckVisitService.getByIds(groupedIds.truck)).data
      carrierVisitData.push(...data)
    }

    return carrierVisitData
  }

  setDamageOrders = (damages: OrderDamageDto[]) => {
    this.damageOrders = damages
  }

  get packagesOptions() {
    return _(
      this.packages.map(x => ({
        ...x,
        assignedToCommodity: this.commodityPackages.includes(x.id) ? 'suggested' : 'others',
      })),
    )
      .orderBy(x => x.assignedToCommodity, 'desc')
      .value()
  }

  receivePackageUpsertedMessage = (event: IEvent<PackageDto>): void => {
    const packageDto = event.payload
    this.packages = [...this.packages.filter(p => packageDto.id !== p.id), packageDto]
  }

  receivePackageDeletedMessage = (event: IEvent<[{ id: number }]>): void => {
    const ids = event.payload.map(p => p.id)
    this.packages = this.packages.filter(p => !ids.includes(p.id))
  }
}
